import Layout from "views/layout";
import { RouteObject } from "react-router-dom";
import { KeyWordSearchList } from "views/search/keyword";


export const searchRoutes:RouteObject[] = [
    {
        path: "/search",
        element: <Layout></Layout>,
        children: [
            {
                path: 'keyword',
                element:  <KeyWordSearchList></KeyWordSearchList>
            },
            {
                path: 'tag',
                element:  <KeyWordSearchList></KeyWordSearchList>
            }
        ]
}
]