import { UserBaseInfo } from "types/user";
import { publicUserDetailType, userAllDetail } from "types/user/detail";

export function determineSuperaction(){
    let userBaseInfo: UserBaseInfo = JSON.parse(
        localStorage.getItem("user_info")!
      );  
    if(!userBaseInfo) return false   
    let superPermissions:string[] = ['workRecommend','articleRecommend','workScore']  
    let intersection = userBaseInfo.permissions.filter(item=>superPermissions.includes(item))
    if( intersection.length>0) {
        return true
    }else{
        return false
    }
}  

export function determineUserLocation(userDetail: userAllDetail | publicUserDetailType){
    if(userDetail.userInfo?.regionDictMap?.cityName){
        return userDetail.userInfo.regionDictMap.cityName
    }
    if(userDetail.userInfo?.regionDictMap?.countryName){
        return userDetail.userInfo.regionDictMap.countryName
    }
    if(userDetail.userInfo?.hometown){
        return userDetail.userInfo.hometown
    }
}

export function determineUserProfession(userDetail: userAllDetail | publicUserDetailType){
    if(userDetail.userInfo?.profession){
        return userDetail.userInfo.profession
    }
    if(userDetail.userInfo?.profession==='' || !userDetail.userInfo?.profession){
        return false
    }
}

export function loginPathWithRedirect(){
    const redirectPath = window.location.href
    return `/auth/login?redirect=${redirectPath}`
}