import { ArticleListItem } from "types/article"
import { PublicArticleActionStyle } from "./styled"
import { Button, Space, Tooltip } from "antd";
import { RiDeleteBin2Line, RiEdit2Line, RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const PublicArticleAction = ({ item, deleteWork, workLink }: { item: ArticleListItem, workLink: string, deleteWork: (id: number) => void }) => {
    const navigate = useNavigate()
    const viewWork = () => {
        window.open(workLink)
    }
    const editWork = (item: ArticleListItem) => {
        navigate(`/article/update/${item.twinId}`);
    }
    return <PublicArticleActionStyle>

        <Space>
            <Tooltip title="编辑" className="tooltip">
                <Button
                    size="large"
                    shape="circle"
                    onClick={(e) => { e.stopPropagation(); editWork(item) }}
                >
                    <RiEdit2Line></RiEdit2Line>
                </Button>
            </Tooltip>
            <Tooltip title="删除" className="tooltip">
                <Button
                    size="large"
                    shape="circle"
                    onClick={(e) => { e.stopPropagation(); deleteWork && deleteWork(item.id) }
                    }
                >
                    <RiDeleteBin2Line></RiDeleteBin2Line>
                </Button>
            </Tooltip>
            {item.status === 2 && item?.onSale !== 0 && (
                <Tooltip title="查看" className="tooltip">
                    <Button
                        onClick={(e) => { e.stopPropagation(); viewWork() }}
                        size="large"
                        shape="circle"
                    >
                        <RiEyeLine></RiEyeLine>
                    </Button>
                </Tooltip>
            )}
        </Space>
    </PublicArticleActionStyle>
}

export default PublicArticleAction