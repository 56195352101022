import styled from "styled-components";

export const Regulation2024PreviousStyle = styled.div`
@font-face {
  font-family: "DIN";
  src: url("https://cdn.ggac.com/v2/fonts/DINCondensedC-2.e5aeb766.ttf");
}
  .container-wrap {
  padding: 180px 0 238px;
  background: url('https://cdn.ggac.com/img/regulation_2022_pc_previous_bg.jpg')
    no-repeat center center;
  background-size: 100% 100%;
}

.container-wrap-en {
  .content {
    padding-right: 20px !important;
  }
  .match-text-wrap {
    max-height: 270px;
    overflow-y: auto;
    .match-text {
      padding-right: 15px;
    }
    ::-webkit-scrollbar {
      width: 10px; /* 设置滚动条的宽度 */
      background-color: transparent; /* 滚动条的背景色 */
    }
    ::-webkit-scrollbar-track {
      background: rgb(223, 220, 220); /* 轨道的背景色 */
      border-radius: 10px; /* 轨道的圆角 */
    }
    /* 自定义滚动条的滑块（thumb） */
    ::-webkit-scrollbar-thumb {
      background-color: #7c7b7b; /* 滑块的背景色 */
      border-radius: 10px; /* 滑块的圆角 */
      border: 2px solid #ffffff; /* 滑块边框 */
    }
    
    /* 滑块在悬停或者点击时的样式 */
    ::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8; /* 悬停时滑块的背景色 */
    }
    
    ::-webkit-scrollbar-thumb:active {
      background-color: #919191; /* 点击时滑块的背景色 */
    }
  }
}

.previous_wrapper {
  width: 1430px;
  margin: 0 auto;
  .title {
    font-size: 42px;
    color: #3a5d73;
    text-align: center;
    font-family: zyjx-song;
    &-bottom {
      position: relative;
      width: 750px;
      line-height: 49px;
      margin: 14px auto 64px;
      background: url('https://cdn-prd.ggac.com/ggac/reg/2024/regulation_2022_previous_title.png')
        no-repeat center center;
      background-size: 100%;
      letter-spacing: 2px;
    }
  }
  .match-item {
    width: 1406px;
    height: 524px;
    position: relative;
    margin-bottom: 180px;
    .right {
      right: 3px;
      top: 3px;
    }
    .left {
      left: 3px;
      top: 3px;
    }
    .content {
      width: 719px;
      height: 518px;
      background: #0e1b2b;
      opacity: 0.8;
      position: absolute;
      padding: 54px 46px 55px 43px;
      .match-title {
        font-size: 34px;
        font-weight: 400;
        color: #cab097;
        margin-bottom: 29px;
        font-family: zyjx-song;
      }
      .match-text {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 16px;
        margin-top: 15px;
      }
      .match-text:first-child {
        margin-top: 0;
      }
      .btn {
        display: flex;
        margin-top: 30px;
        a {
          width: 188px;
          text-align: center;
          line-height: 46px;
          background: url('https://cdn-prd.ggac.com/ggac/reg/2024/regulation_2022_pc_previous_tab.png')
            no-repeat center center;
          background-size: 100%;
          font-size: 22px;
          font-family: Fontquan;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          font-family: zyjx-song;
          &:hover {
            background: url('https://cdn-prd.ggac.com/ggac/reg/2024/regulation_2022_pc_previous_tab_hover.png')
              no-repeat center center;
            color: #cab097;
            background-size: 100%;
          }
        }
        a:first-child {
          margin-right: 67px;
        }
      }
    }
  }
  .match-item:last-child {
    margin-bottom: 0;
  }
  .match-five {
    background: url('https://cdn-prd.ggac.com/ggac/reg/2024/regulation_2023_pc_previous_five.png')
      no-repeat center center;
    background-size: 100%;
  }
  .match-four {
    background: url('https://cdn.ggac.com/img/regulation_2022_pc_previous_four.png')
      no-repeat center center;
    background-size: 100%;
  }
  .match-three {
    background: url('https://cdn.ggac.com/img/regulation_2022_pc_previous_three.png')
      no-repeat center center;
    background-size: 100%;
    .match-text {
      line-height: 20px !important;
    }
  }
  .match-two {
    background: url('https://cdn.ggac.com/img/regulation_2022_pc_previous_two.png')
      no-repeat center center;
    background-size: 100%;
    .match-text {
      line-height: 22px !important;
    }
  }
  .match-one {
    background: url('https://cdn.ggac.com/img/regulation_2022_pc_previous_one.png')
      no-repeat center center;
    background-size: 100%;
    .match-text {
      line-height: 22px !important;
    }
  }
}
`