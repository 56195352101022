import styled from "styled-components";

export const RewardOrderDetailStyle = styled.div`
    /* padding: 0px 30px; */
    .list {
        padding: 0 30px;
      &-title {
        padding: ${props => props.theme.space.padding}px 0 ${props => props.theme.space.padding * 2}px;
        display: flex;
        span {
            padding: 0 5px;
        }
        .link {
            color: #808080;
            cursor: pointer;
            &:hover {
                color: ${props => props.theme.colors.colorPrimary};
            }
        }
      }
    }
    
    .word {
        color: #94969f;
        margin: 0 0 ${props => props.theme.space.margin}px 0;
        padding: 0 30px;
    }

    .detail-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 15px;
        padding: 0px 30px
    }

    .image-wrap {
        width: calc(100% - 100px);
    }

    .fileSupplement {
        overflow-wrap: break-word;
    }

    .second-image-list {
        margin-bottom: 100px;
    }

    .image {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        .ant-image {
            width: calc((100% - 20px * 3) / 4 );
        }
        &-item {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }
        .file-item {
            width: calc((100% - 20px * 3) / 4 );
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eee;
            position: relative;
            &:hover {
                .block {
                    z-index: 2;
                }
            }
            .block {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.7);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fff;
                cursor: pointer;
                z-index: -1;
                .word {
                    font-size: 12px;
                }
            }
        }
    }

    .value {
        max-width: calc(100% - 100px);
    }

    .ant-divider-inner-text {
        font-weight: bold;
    }

    .ant-divider {
        margin: 30px 0;
    }


    .deliver-title {
        background-color: #fbfbfd;
        text-align: center;
        line-height: 40px;
        margin: 50px 0;
        font-weight: bold;
        font-size: 15px;
    }

    .pay {
        display: flex;
        &-item {
          width: 150px;
          height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px #e0e0e0;
          margin-right: 40px;
          cursor: pointer;
          border-radius: ${props => props.theme.border.borderRadius}px;
          gap: 10px;
          &:last-child {
            margin-right: 0;
          }
          .type {
            width: 36px;
            height: 36px;
            border-radius: 6px;
            margin-right: 11px;
          }
          div {
            font-size: 16px;
            color: #2b2e36;
          }
        }
        .active {
          border: solid 1px #f4aa2f;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 24px;
            height: 24px;
            background: url("https://cdn.ggac.com/img/copyright-pay-active.png")
              center center no-repeat;
            background-size: 100% 100%;
          }
        }
    }

    .btn {
        text-align: center;
        margin-top: ${props => props.theme.space.marginLG * 2}px;
        button {
            width: 150px;
        }
    }

    .modal{
      .title{
          font-size: 25px;
          text-align: center;
      }
      .code-wrap{
          text-align: center;
          .ant-qrcode {
              margin: 0 auto;
          }
      }
  }
`