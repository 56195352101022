import { PhotoContentDisplayStyle } from "./style"
import { PhotoView } from 'react-photo-view';
import { WorkDetailMedieListItem } from "types/work";
import { useState } from "react";
import AddFavorite from "components/favorite/add-favorite";
import { ImageObjectCloudResize } from "utils/functions/file";

type Props = {
  mediaItem: WorkDetailMedieListItem
}

function PhotoContentDisplay({ mediaItem }: Props) {
  const [isShow, setIsShow] = useState(false)
  const closePopup = () => {
    setIsShow(false)
  }

  return (
    <PhotoContentDisplayStyle>
        {/* <PhotoView src={ImageObjectCloudResize((mediaItem.resizedUrl??mediaItem.url),1000)} >
          <div className="image-wrap">
            <div className="icon-wrap" onClick={(e) => favourImage(e)}>
              <RiStarLine className="icon" />收藏
            </div>
            <img src={ImageObjectCloudResize((mediaItem.resizedUrl??mediaItem.url),1000)} alt={mediaItem.name} className="fit" />

          </div>
         
        </PhotoView> */}

        <PhotoView src={ImageObjectCloudResize((mediaItem.url),7560)} >
          <div className="image-wrap" >
            <div className="operation-wrapper">
              {/* <div className="icon-wrap" onClick={(e) => favourImage(e)}>
                <RiStarLine className="icon" />图片收藏
              </div> */}
              <div className="icon-wrap" >
                点击加载原图
              </div>
            </div>
            <img src={ImageObjectCloudResize((mediaItem.url),2800)} alt={mediaItem.name} className="fit" />
          </div>
        </PhotoView>

      {isShow && <AddFavorite type='media' dataId={mediaItem.id} FavoriteTypeDisabled={true} onClose={() => closePopup()}></AddFavorite>}
    </PhotoContentDisplayStyle>
  )
}

export default PhotoContentDisplay