import { useEffect, useState } from "react";
import { QuestsStyle } from "./style";
import { UserQuest } from "types/user/quest";
import { apiUserQuests } from "api/user/quest";
import { Button, Progress, Space, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import UiCalender from "ui-lib/calender";
import { getEventCheckinWorkList } from "api/events";
import UiAvatar from "ui-lib/avatar";
import {userAllDetail } from "types/user/detail";
import {apiUserDetail } from "api/user/detail";


function Quests() {
  const navigate = useNavigate();
  const [year, setYear] = useState(1);
  const [month, setMonth] = useState(1);
  const [dateList, setDateList] = useState<string[]>([]);
  const [dataList, setDataList] = useState<UserQuest[]>();
  const [userDetail, setUserDetail] = useState<userAllDetail>();

  useEffect(() => {
    apiUserQuests({ type: "1,2" }).then((res) => {
      setDataList(res);
    });
    const currentDate = new Date();
    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth() + 1);
    const firstDate =
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        .toISOString()
        .slice(0, 10) + " 00:00:00";
    const lastDate =
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        .toISOString()
        .slice(0, 10) + " 23:59:59";
    getEventCheckinWorkList({ startTime: firstDate, endTime: lastDate }).then(
      (res) => {
        setDateList(res.checkinDates);
      }
    );
    apiUserDetail().then((userDetail) => {
      setUserDetail(userDetail);
    });
  }, []);

  const getRewardText = (item: UserQuest) => {
    let a = <></>;
    let b = <></>;
    if (item.expReward > 0) {
      a = (
        // <Tag bordered={false} color="orange">

        // </Tag>
        <span
          style={{
            fontSize: 12,
            color: "#4746468b",
            border: "1px solid #83818189",
            padding: "2px",
            borderRadius: 4,
          }}
        >
          {" "}
          {item.expReward} 经验值
        </span>
      );
    }
    if (item.gpReward > 0) {
      b = (
        // <Tag bordered={false} color="volcano">
        //   {item.gpReward} G分
        // </Tag>
        <span
          style={{
            fontSize: 12,
            color: "#4746468b",
            border: "1px solid #83818189",
            padding: "2px",
            borderRadius: 4,
            marginLeft: 4,
          }}
        >
          {" "}
          {item.gpReward} G分
        </span>
      );
    }
    return (
      <>
        <span style={{ color: "#0000008c", paddingRight: 4 }}>奖励</span> {a}
        {b}
      </>
    );
  };

  const listItem = (items: UserQuest[]) => {
    return items.map((item, index) => {
      return (
        <div className="list-item" key={index}>
          <div className="left_">
            <div className="title">{item.name}</div>
            <div className="reward">{getRewardText(item)}</div>
          </div>
          <div className="right_">
            <Space size={"large"}>
              {/* <div className="progress">
                {item.actionCompleteCount}/{item.actionCount}
              </div> */}
              <Button
                type="primary"
                disabled={item.isCompleted === 1}
                onClick={() => navigate(item.jumpUrl)}
              >
                {item.isCompleted === 1 ? "已完成" : "去完成"}
              </Button>
            </Space>
          </div>
        </div>
      );
    });
  };

  const columns: any = [
    {
      title: "等级",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "经验值",
      dataIndex: "exp",
      key: "exp",
    }
    
  ];

  const tableData: any = [
    {
      key: "1",
      level: "1",
      exp: "1",
    },
    {
      key: "2",
      level: "2",
      exp: "5",
    },
    {
      key: "3",
      level: "3",
      exp: "15",
    },
    {
      key: "4",
      level: "4",
      exp: "30",
    },
    {
      key: "5",
      level: "5",
      exp: "50",
    },
    {
      key: "6",
      level: "6",
      exp: "100",
    },
    {
      key: "7",
      level: "7",
      exp: "200",
    },
    {
      key: "8",
      level: "8",
      exp: "500",
    },
    {
      key: "9",
      level: "9",
      exp: "1000",
    },
    {
      key: "10",
      level: "10",
      exp: "2000",
    },
    {
      key: "11",
      level: "11",
      exp: "3000",
    },
    {
      key: "12",
      level: "12",
      exp: "6000",
    },
    {
      key: "13",
      level: "13",
      exp: "10000",
    },
    {
      key: "14",
      level: "14",
      exp: "18000",
    },
    {
      key: "15",
      level: "15",
      exp: "30000",
    },
    {
      key: "16",
      level: "16",
      exp: "60000",
    },
    {
      key: "17",
      level: "17",
      exp: "100000",
    },
    {
      key: "18",
      level: "18",
      exp: "300000",
    }
  ];
  return (
    <QuestsStyle>
      {userDetail && (
        <div className="profile">
          <UiAvatar
            url={userDetail?.avatarUrl}
            username={userDetail?.username}
            certifiedRoleId={userDetail?.certifiedRoleId}
            userRoles={userDetail?.userRoles}
            size={60}
          ></UiAvatar>
          <div className="info">
            <div className="name">{userDetail?.username}</div>
            <div className="level">
              <div> Lv.{userDetail?.level}</div>
              <div style={{ width:100 }}>
                <Tooltip title={`距离下一等级还需${userDetail?.nextLevel.exp-userDetail?.exp}经验值`}>
                <Progress strokeColor="#F5C113" percent={(userDetail?.exp-userDetail?.currentLevel.exp)/(userDetail?.nextLevel.exp-userDetail?.currentLevel.exp)*100} showInfo={false} />
                </Tooltip>
                </div>
              </div>
          </div>
        </div>
      )}
      <div className="quest">
      <div className="left">
        <div className="section-title">新手任务</div>
        <div className="quest-list">
          {dataList && listItem(dataList.filter((item) => item.type === 2))}
        </div>
        <div className="section-title">日常任务</div>
        <div className="quest-list">
          {dataList && listItem(dataList.filter((item) => item.type === 1))}
        </div>
      </div>
      <div className="right">
      <div className="section-title" >打卡日历</div>
        <div className="calendar-wrap">
          <UiCalender
            year={year}
            month={month}
            hightlightItems={dateList}
          ></UiCalender>
         
        </div>
        <div className="section-title" style={{ margin: '28px 0' }}>用户等级经验对照表</div>
          <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      </div>
    </QuestsStyle>
  );
}

export default Quests;
