import SectionTitle from "components/section-title"
import { WorkCollectionStyle } from "./style"
import CollectionCarousel from "./carousel"
import { RiArrowRightSLine } from "react-icons/ri"

function WorkCollection() {
  const toMore = () => {
    window.open("/events/exhibition/list")
  }
  return (
    <WorkCollectionStyle>
      <div className="title-wrap">
        <SectionTitle text={'专题作品'}></SectionTitle>
        <div className="tip" onClick={toMore}>查看更多 <RiArrowRightSLine className="icon"></RiArrowRightSLine></div>
      </div>
      <div className="container_">
        <div className="outer">
          <div className="wrap">
            <CollectionCarousel></CollectionCarousel>
          </div>
        </div>
      </div>

    </WorkCollectionStyle>
  )
}

export default WorkCollection