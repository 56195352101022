import { Button, Pagination, Popconfirm, Space, message } from "antd";
import { TopicDetailStyle } from "./style";
import {
  EditOutlined,
  VerticalAlignTopOutlined,
  StopOutlined, DownOutlined, UpOutlined,
} from "@ant-design/icons";

import UiTag from "ui-lib/tag";
import {
  RiHeartFill,
  RiHeartLine,
  RiShareForwardLine,
  RiEditBoxLine,
  RiDeleteBinLine,
  RiVideoLine,
  RiThumbUpLine,
} from "react-icons/ri";
import UserCardGlobal from "components/userBlock";
import { useEffect, useState } from "react";
import {
  apiDeleteForumTopic,
  apiForumFollow,
  apiForumReplyDetail,
  apiForumReplyList,
  apiForumTop,
  apiForumTopicDetail,
  apiForumTopicRecommend,
} from "api/forum";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ForumReplyListItem,
  ForumReplyListRequest,
  ForumTopicDetail,
} from "api/forum/type";
import ReplyCreateAndUdate from "../reply/reply-edit/reply-create-update";
import ReplyItem from "../reply/reply-item";
import { postLikeWork } from "api/common/favour";
import { canSuperManage, isMe } from "../fn";
import TopicCreateAndUdate from "../topic-edit";
import { NoData } from "components/no-data";
import GoBackTitle from "ui-lib/go-back";
import copyToClipboard from "copy-to-clipboard";
import AnonymousUser from "../anonymous-user";
function TopicDetail() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [detail, setDetail] = useState<ForumTopicDetail>();
  const [replyList, setReplyList] = useState<ForumReplyListItem[]>([]);
  const [showMore, setShowMore] = useState<boolean | null>(null);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const topMineId = searchParams.get("topMineId");
  const [myReply, setMyReply] = useState<ForumReplyListItem>(); //[myReply]
  const navigate = useNavigate();

  const [listParams] = useState<ForumReplyListRequest>({
    pageSize: 20,
    topicId: Number(id),
  });
  const [showEdit, setShowEdit] = useState(false);
  const [showTopicEdit, setShowTopicEdit] = useState(false);

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    getReplyDetail()
  }, [
    topMineId
  ])

  const getReplyDetail = () => {
    topMineId && apiForumReplyDetail({ id: Number(topMineId), collapseTextCount: 400 }).then(reply =>
      setMyReply(reply)
    )
  }

  const getDetail = () => {
    apiForumTopicDetail({ id: Number(id), collapseTextCount: 400 }).then(
      (res) => {  
        if (showMore === null) {
          Boolean(res.collapse) && setShowMore(Boolean(res.collapse));
        }
        setDetail(res);
      }
    );
  };
  useEffect(() => {
    getReplyList();
  }, [listParams, currentPage]);

  const getReplyList = () => {
    apiForumReplyList({
      pageNumber: currentPage,
      collapseTextCount: 400,
      ...listParams,
    }).then((res) => {
      setReplyList(res.pageData);
      setTotalSize(res.totalSize);
    });
  };

  const handleFollow = () => {
    setLoading(true);
    apiForumFollow({ topicId: Number(id) })
      .then((res) => {
        getDetail();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTopicLike = () => {
    postLikeWork({
      dataId: Number(id),
      dataTable: "forum_topic",
      flag: detail?.isLike === 1 ? 0 : 1,
    }).then(() => {
      getDetail();
    });
  };

  // const setLocalList = (item: ForumReplyListItem) => {
  //   setReplyList((prevData) => {
  //     return prevData.map((obj) => {
  //       if (obj.id === item.id) {
  //         return item;
  //       }
  //       return obj;
  //     });
  //   });
  // };

  // const removeFromLocalist = (id: number) => {
  //   setReplyList((prevData) => {
  //     return prevData.filter((obj) => {
  //       return obj.id !== id;
  //     });
  //   });
  // };

  const onDeleteTopic = (id: number) => {
    apiDeleteForumTopic({ id: id }).then(() => {
      message.success("删除成功");
      window.location.href = "/forum/question/my/topic";
    });
  };

  const changePage = (e: any) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(e);
  };

  const handleRecommand = (flag: number) => {
    if (!detail) {
      return
    }
    apiForumTopicRecommend({ topicId: Number(id), flag: flag }).then((res) => {
      message.success("操作成功");
      detail.isRecommend = flag
      setDetail({ ...detail })
    });
  };

  const handleTop = () => {
    if (!detail) {
      return
    }
    const flag = detail?.isTop === 1 ? 0 : 1
    apiForumTop({ topicId: Number(id), flag: flag }).then((res) => {
      message.success("操作成功");
      detail.isTop = flag
      setDetail({ ...detail })
    });
  }

  const link = () => {
    navigate(`/forum/question/index`, { replace: true })
  }

  return (
    <TopicDetailStyle>
      <div className="header">
        <div className="container">
          <div className="list-title">
            <div className="link" onClick={() => link()}>
              返回 话题主页
            </div>
            <span>{`>`}</span>问题详情
          </div>
          <div className="tags">
            {detail?.tagList.map((tag, index) => (
              <UiTag theme="yellow-3" key={index}>
                {tag.name}
              </UiTag>
            ))}
          </div>
          <div className="row_1">
            <div className="title">{detail?.title}</div>
            <div className="data">
              {/* <div className="data-item">
                <div className="text">关注者</div>
                <div className="num">8789</div>
              </div> */}
              <div className="data-item">
                <div className="text">浏览</div>
                <div className="num">{detail?.viewCount}</div>
              </div>
            </div>
          </div>
          <div className="row_2">
            {showMore && (
              <>
                {detail?.coverUrl && detail.coverUrlType === 1 && (
                  <div
                    className="thumbnail"
                    style={{ backgroundImage: `url(${detail?.coverUrl})` }}
                    onClick={() => {
                      setShowMore(false)
                    }}
                  ></div>
                )}
                {detail?.coverUrl && detail.coverUrlType === 2 && (
                  <div
                    className="thumbnail video"
                    style={{ backgroundColor: '#000' }}
                    onClick={() => {
                      setShowMore(false)
                    }}
                  >
                    <RiVideoLine style={{ color: '#fff' }}></RiVideoLine>
                  </div>
                )}
                <div className="desc">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: detail?.contentWords ?? '' }}
                  ></div>
                </div>
              </>
            )}
            {!showMore && detail?.description && (
              <div
                className="desc-1"
                dangerouslySetInnerHTML={{ __html: detail?.description }}
              ></div>
            )}
            {showMore && (

              <span className="more-text" onClick={(e) => {
                e.stopPropagation()
                setShowMore(false)
              }}>
                阅读全文<DownOutlined />
              </span>
            )}
            {!showMore && showMore !== null && (


              <span className="more-text" onClick={(e) => {
                e.stopPropagation();
                setShowMore(true)
              }}>
                收起 <UpOutlined />
              </span>
            )}
          </div>
          <div className="row_3">
            <div className="btns">
              <Space size={"middle"}>
                <Button
                  type="primary"
                  onClick={() => handleFollow()}
                  loading={loading}
                >
                  {detail?.isFollow ? "取消关注" : "关注话题"}
                </Button>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setShowEdit(true)}
                >
                  写回复
                </Button>

                {canSuperManage() && (
                  <>
                    {
                      Boolean(!detail?.isRecommend) && <Button
                        icon={<RiThumbUpLine />}
                        onClick={() => handleRecommand(1)}
                      >
                        推荐
                      </Button>
                    }
                    {Boolean(detail?.isRecommend) && (
                      <Button
                        icon={<StopOutlined />}
                        onClick={() => handleRecommand(0)}
                      >
                        取消推荐
                      </Button>
                    )}
                  </>
                )}

                {canSuperManage() && (
                  <>
                    <Button
                      icon={detail?.isTop === 1 ? <StopOutlined /> : <VerticalAlignTopOutlined />}
                      onClick={() => handleTop()}
                    >
                      {detail?.isTop === 1 ? '取消置顶' : "置顶"}
                    </Button>
                  </>
                )}
              </Space>
            </div>
            <div className="interaction">
              <Space size="large">
                <div
                  className={`icon-btn ${detail?.isLike ? "active" : ""}`}
                  onClick={() => {
                    handleTopicLike();
                  }}
                >
                  {detail?.isLike ? (
                    <RiHeartFill style={{ fontSize: 16 }}></RiHeartFill>
                  ) : (
                    <RiHeartLine style={{ fontSize: 16 }}></RiHeartLine>
                  )}
                  <span>{detail?.isLike ? "取消点赞" : "点赞"}</span>
                </div>
                <div
                  className="icon-btn"
                  onClick={() => {
                    copyToClipboard(window.location.href);
                    message.success("分享链接已复制！");
                  }}
                >
                  <RiShareForwardLine
                    style={{ fontSize: 16 }}
                  ></RiShareForwardLine>{" "}
                  <span>分享</span>
                </div>

                {detail && isMe(detail.userId) && (
                  <div
                    className="icon-btn"
                    onClick={() => {
                      setShowTopicEdit(true);
                    }}
                  >
                    <RiEditBoxLine style={{ fontSize: 16 }}></RiEditBoxLine>{" "}
                    <span>编辑</span>
                  </div>
                )}

                {detail && (isMe(detail.userId) || canSuperManage()) && (
                  <Popconfirm
                    title="确认删除吗？"
                    onConfirm={() => onDeleteTopic(detail.id)}
                    okText="确认"
                    cancelText="取消"
                  >
                    <div className="icon-btn">
                      <RiDeleteBinLine
                        style={{ fontSize: 16 }}
                      ></RiDeleteBinLine>{" "}
                      <span>删除</span>
                    </div>
                  </Popconfirm>
                )}
              </Space>
            </div>
          </div>
        </div>
      </div>
      <div className="container_">
        <div className="left">
          {myReply && (
            <>
              <div className="top-mine">
                <ReplyItem
                  listItem={myReply}
                  onEdit={() => {
                    getReplyDetail();
                    getReplyList();
                  }}
                  onListItemChange={(item_) => {
                    getReplyDetail();
                    getReplyList();
                  }}
                  onDeleteSuccess={(id) => {
                    searchParams.delete('topMineId')
                    setSearchParams(searchParams)
                    window.location.reload()
                    //removeFromLocalist(id);
                  }}
                  onCommentCountChange={() => {
                    getReplyDetail();
                    getReplyList();
                  }}
                ></ReplyItem>

              </div>
              <div className="all-list">-- 全部回答 --</div>
            </>
          )}
          <div className="reply-list">
            {replyList.map((item, index) => {
              return (
                <ReplyItem
                  key={index}
                  listItem={item}
                  onEdit={() => {
                    getReplyList();
                    getReplyDetail();
                  }}
                  onListItemChange={(item_) => {
                    //setLocalList(item_);
                    getReplyList();
                    getReplyDetail();
                  }}
                  onDeleteSuccess={(id) => {
                    searchParams.delete('topMineId')
                    setSearchParams(searchParams)
                    window.location.reload()
                    // removeFromLocalist(id);
                    // getReplyList();
                    // getReplyDetail();
                  }}
                  onCommentCountChange={() => {
                    getReplyList();
                    getReplyDetail();
                  }}
                ></ReplyItem>
              );
            })}
          </div>
          {replyList.length === 0 && !loading && (
            <div style={{ marginTop: "200px" }}>
              <NoData></NoData>
            </div>
          )}
          <Pagination
            style={{ textAlign: "center", margin: "24px 0 12px 0" }}
            hideOnSinglePage={true}
            current={currentPage}
            onChange={changePage}
            pageSize={listParams.pageSize}
            total={totalSize}
            showSizeChanger={false}
            size="default"
          />
        </div>
        <div className="right">
          {Boolean(detail?.userId) &&
            detail?.userId &&
            !detail?.isAnonymity && (
              <UserCardGlobal userId={detail?.userId}></UserCardGlobal>
            )}
          {Boolean(detail?.isAnonymity) && (
            <AnonymousUser size={76}></AnonymousUser>
          )}
        </div>
      </div>
      <ReplyCreateAndUdate
        isModalOpen={showEdit}
        handleOk={(id) => {
          if (id) {
            searchParams.set('topMineId', id)
            setSearchParams(searchParams)
            setShowEdit(false);
            getReplyList();
            return
          }
          getReplyList();
          setShowEdit(false);
        }}
        handleCancel={() => {
          setShowEdit(false);
        }}
        topicId={Number(id)}
      ></ReplyCreateAndUdate>
      {detail && <TopicCreateAndUdate
        isModalOpen={showTopicEdit}
        handleOk={() => {
          //getDetail();

          setShowTopicEdit(false);
          setTimeout(() => {
            window.location.reload();
          }, 50)

        }}
        handleCancel={() => {
          setShowTopicEdit(false);
        }}
        topicItem={detail}
      ></TopicCreateAndUdate>}
    </TopicDetailStyle>
  );
}

export default TopicDetail;
