import styled from "styled-components";

export const CommentsStyle = styled.div`
position: relative;
width: 100%;
  .title {
    font-size: ${(props) => props.theme.fontSizes.fontSizeHeading4};
    font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
    margin-bottom: ${(props) => props.theme.space.padding}px;
    span {
      padding: 0 ${(props) => props.theme.space.paddingXS}px;
      color: ${(props) => props.theme.colors.colorTextTertiary};
    }
  }
  .comment.indent{
    padding-left: ${(props) => props.theme.space.padding + 20}px;
  }
  .indent {
    margin-top: ${(props) => props.theme.space.margin}px;
  }
  .moreReply{
      color: ${(props) => props.theme.colors.colorPrimary};
      padding: ${(props) => props.theme.space.paddingSM}px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 45px;
      &:hover{
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
    .moreComment{
      pointer-events: all;
      position: relative;
      width: 180px;
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      height: 34px;
      display: flex;
      margin-left: calc(50% - 90px);
      margin-top: ${(props) => props.theme.space.marginLG}px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      /* color: ${(props) => props.theme.colors.colorPrimary}; */
      /* background-color: ${(props) => props.theme.colors.colorBlack1Ggac}; */
      &:hover{
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
    .comment {
      display: flex;
      /* padding: ${(props) => props.theme.space.paddingXS}px 0; */
    }
    .commentSection {
      margin-bottom: ${props => props.theme.space.marginLG}px;
    }
`;
