import CommonTabs, { Tab } from "ui-lib/tab/common-tab";
import { ForumIndexStyle } from "./formI-index-style";
import TopicListItem from "./topic-list-item";
import { forumTopicTabs } from "./config";

import { useEffect, useState } from "react";
import { ForumTopicListItem } from "api/forum/type";
import { apiForumTopicList } from "api/forum";
import PublishTopic from "./common/publish";
import { Pagination, Spin } from "antd";
import { NoData } from "components/no-data";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReplyCreateAndUdate from "./reply/reply-edit/reply-create-update";
import SearchInput from "./common/search-input";
import HotTags from "./common/hot-tags";
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
function ForumIndex() {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalSize, setTotalSize] = useState(0);
  const [dataList, setDataList] = useState<ForumTopicListItem[]>([]);
  const [showReplyEdit, setReplyShowEdit] = useState(false);
  const [currentTopicId, setCurrentTopicId] = useState<number | undefined>();
  const navigate = useNavigate();
  const pageSize = 20;
  const checkTab = (item: Tab) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("type", String(item.key));

    switch (item.key) {
      case "following":
        newSearchParams.set("isFollow", "1");
        newSearchParams.set("isRecommend", "0");
        newSearchParams.set("sortField", "lastSubmitTime");
        newSearchParams.set("currentPage", "1");
        setSearchParams(newSearchParams);
        break;
      case "recommend":
        newSearchParams.set("isFollow", "0");
        newSearchParams.set("isRecommend", "1");
        newSearchParams.set("sortField", "lastRecommendTime");
        newSearchParams.set("currentPage", "1");
        setSearchParams(newSearchParams);

        break;
      case "hot":
        newSearchParams.set("isFollow", "0");
        newSearchParams.set("isRecommend", "0");
        newSearchParams.set("sortField", "hot");
        newSearchParams.set("currentPage", "1");
        setSearchParams(newSearchParams);
        break;
      case "new":
        newSearchParams.set("isFollow", "0");
        newSearchParams.set("isRecommend", "0");
        newSearchParams.set("sortField", "lastSubmitTime");
        newSearchParams.set("currentPage", "1");
        setSearchParams(newSearchParams);
        break;
    }
  };
  useEffect(() => {
    if (!searchParams.get("type")) {
      navigate("/forum/question/index?type=new&isFollow=0&isRecommend=0&sortField=lastSubmitTime", { replace: true });
      return
    }
    getList();
  }, [searchParams]);

  const getList = () => {
    setLoading(true);
    const queryParams = Object.fromEntries(searchParams.entries());
    apiForumTopicList({
      pageNumber: Number(searchParams.get('currentPage')) || 1,
      pageSize: pageSize,
      needTotal: 1,
      collapseTextCount: 400,
      ...queryParams,
    }).then((res: any) => { 
      setDataList(res.pageData);
      setTotalSize(res.totalSize);
      setLoading(false);
    });
  };

  const setLocalList = (item: ForumTopicListItem) => {
    setDataList((prevData) => {
      return prevData.map((obj) => {
        if (obj.id === item.id) {
          return item;
        }
        return obj;
      });
    });
  };

  const changePage = (e: any) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('currentPage', e)
    setSearchParams(newSearchParams);
  };

  return (
    <ForumIndexStyle className="ForumIndexStyle">
      <div className="container">
        <div className="left">
          <div className="tabs">
            <CommonTabs
              tabs={forumTopicTabs()}
              onClick={(item) => checkTab(item)}
              defaultKey={searchParams.get("type") ?? ""}
            ></CommonTabs>
          </div>
          <Spin spinning={loading}>
            <div className="topic-list">
              {dataList.map((item, index) => {
                return (
                  <TopicListItem
                    key={index}
                    onLaunchReply={(topicId) => {
                      setCurrentTopicId(topicId)
                      setReplyShowEdit(true)
                    }}
                    listItem={item}
                    onListItemChange={(item_) => {
                      setLocalList(item_);
                    }}
                    onCommentCountChange={() => {
                      getList();
                    }}
                    needRefresh={() => {
                      getList();
                    }}
                  ></TopicListItem>
                );
              })}
            </div>
            {dataList.length === 0 && !loading && (
              <div style={{ marginTop: "200px" }}>
                <NoData></NoData>
              </div>
            )}
          </Spin>
          {dataList.length > 0 && (
            <Pagination
              style={{ textAlign: "center", margin: "24px 0 12px 0" }}
              hideOnSinglePage={true}
              current={Number(searchParams.get('currentPage') || 1)}
              onChange={changePage}
              pageSize={pageSize}
              total={totalSize}
              showSizeChanger={false}
              size="default"
            />
          )}
        </div>
        <div className="right">
          <SearchInput></SearchInput>
          <PublishTopic></PublishTopic>
          <HotTags></HotTags>
        </div>
      </div>
      <ReplyCreateAndUdate
        isModalOpen={showReplyEdit}
        handleOk={() => {
          navigate(`/forum/question/topic/${currentTopicId}`);
          setReplyShowEdit(false);
        }}
        handleCancel={() => {
          setCurrentTopicId(undefined);
          setReplyShowEdit(false);
        }}
        topicId={currentTopicId}
      ></ReplyCreateAndUdate>
    </ForumIndexStyle>
  );
}

export default ForumIndex;
