
import { PreSubmitModalStyle } from "./style"
import { Alert, Button, Modal, Radio, RadioChangeEvent, Space, message } from "antd"
import { useEffect, useState } from "react"
import { UserAddressItem } from "types/user/address"
import { apiGetUserAddressList, apiPostDeleteAddress } from "api/user/address"
import { useLocation, useNavigate } from "react-router-dom"
import AddressPopup from "../../product/components/address-popup"
import { OrderListResponse } from "types/product"
import { postEditMall } from "api/shop"

type Props ={
    orderItem:OrderListResponse
    isShow:boolean
    onCancal:()=>void
    onFinish:()=>void
}

const ModifyAddressModal = ({ orderItem, isShow, onCancal ,onFinish}: Props) => {
    const [addressList, setAddressList] = useState<UserAddressItem[]>([])
    const [addressValue, setAddressValue] = useState(0)
    const [isShowAddressPopup, setIsShowAddressPopup] = useState(false)
    const [updateAddressId, setUpdateAddressId] = useState(0)
    const [isCreate, setIsCreate] = useState(true)
    const getAddress = () => {
        apiGetUserAddressList().then(res => {
            setAddressList(res)
        })
    }

    useEffect(() => {
        getAddress()
    }, [isShow])

    const onChange = (e: RadioChangeEvent) => {
        setAddressValue(e.target.value);
    }

    
    const onAddressCancal = () => {
        setIsShowAddressPopup(false)
    }


    const updateAddress = (e: any, id: number) => {
        e.stopPropagation();
        e.preventDefault()
        setIsShowAddressPopup(true)
        setUpdateAddressId(id)
        setIsCreate(false)
    }

    const addAddress = () => {
        setIsShowAddressPopup(true)
        setIsCreate(true)
    }

    const deleteAddress = (e: any, id: number) => {
        e.preventDefault()
        e.stopPropagation()
        // 删除地址
        Modal.confirm({
            title: "确定删除该地址？",
            content: '删除后无法找回',
            okText: "确定",
            cancelText: false,
            onOk: async () => {
                await apiPostDeleteAddress([Number(id)]);
                message.success("删除成功")
                getAddress();
            },
        });
    }

    const modifyOrderAddress = ()=>{
        postEditMall({
            orderNumber:orderItem.orderNumber,
            shippingAddressId:addressValue
        }).then(() => {
            message.success("修改成功")
            onFinish()
        })
    }

    return <PreSubmitModalStyle>
        <Modal className='modal' centered={true} onCancel={onCancal} getContainer={false} open={isShow} width={800}
        onOk={modifyOrderAddress}
        >
   <div className="item under_line">
            <div className="label">当前收货地址：</div>
            <Space direction="vertical" style={{marginLeft:20}}>
            <div className="value">{orderItem.receiver} {orderItem.phone}</div>
            <div className="value">{orderItem.fullAddress}{orderItem.postalCode?'｜ 邮编：'+orderItem.postalCode:''}</div>
            </Space>
            
   </div>
    <div className="item">
                    <div className="label">请选择收货地址：</div>
                    <div className="value">
                        <div className="address" style={{ display: addressList.length === 0 ? 'flex' : 'block', alignItems: "center" }}>
                            <Radio.Group onChange={onChange} value={addressValue}>
                                {
                                    addressList.map(item => {
                                        return <div className="address-item" key={item.id}>
                                            <Radio value={item.id} className="radio">
                                                <div className="receiver">
                                                    <div className="left"> {item.receiver}<span style={{ marginLeft: "5px" }} className="phone">{item.phone} </span></div>
                                                    <div className="right">
                                                        {
                                                            !!item.isDefault && <div className="tag yellow">默认地址</div>
                                                        }
                                                        <div className="tag color" onClick={(e) => updateAddress(e, item.id)}>修改</div>
                                                        <div className="tag color" onClick={(e) => deleteAddress(e, item.id)}>删除</div>
                                                    </div>
                                                </div>
                                                <div className="desc">
                                                    {item.fullAddress}
                                                </div>
                                            </Radio>
                                        </div>
                                    })
                                }
                            </Radio.Group>
                            {
                                addressList.length < 5 && <div className="add-address" onClick={addAddress}>添加地址</div>
                            }
                        </div>
                    </div>
                </div>
          
           
        </Modal>
        <AddressPopup updateList={getAddress} isCreate={isCreate} onCancal={onAddressCancal} addressId={updateAddressId} isShow={isShowAddressPopup} />
    </PreSubmitModalStyle>
}

export default ModifyAddressModal