import styled from "styled-components";

export const WorkInfoStyle = styled.div`
  padding: ${(props) => props.theme.space.paddingXS}px;
  .workTitle {
    font-size: ${(props) => props.theme.fontSizes.fontSize}px;
    text-align: center;
    font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
    padding-top:  ${(props) => props.theme.space.paddingXXS}px;
  }
  .text {
    padding: 0 ${(props) => props.theme.space.padding}px;
    .ant-typography-expand {
      color: ${props=>props.theme.colors.colorPrimary};
    }
  }
  .meta{
    padding: 0 32px ${(props) => props.theme.space.paddingSM}px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .roundIcon{
        border-radius: 50% 50%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .roundIcon.red{
      background-color: #c20008;
    }
    .roundIcon.red-light{
      background-color: #eb6354;
    }
    .blue {
      background-color: #1296db;
    }
    .roundIcon.yellow{
        background-color: ${(props) => props.theme.colors.colorPrimary};
    }
    .selected {
      background-color: #fa6903;
    }
    .roundIcon.green{
      background-color: #73d13d;
    }
    .rank-yellow {
      background-color: #f4ea2a;
    }
    .blue {
      background-color: #1296db;
    }
    .icon{
      color:${props=> props.theme.colors.colorTextTertiary};
      display: flex;
      align-items: center;
      span{
        padding-left:${(props) => props.theme.space.paddingXXS}px;   
      }
    }
    
  }
  .date{
      color:${props=> props.theme.colors.colorTextTertiary};
      padding: ${(props) => props.theme.space.paddingSM}px 0;
      padding-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        cursor: pointer;
      }
   }
   .category{
      color:${props=> props.theme.colors.colorTextTertiary};
      padding-top: ${(props) => props.theme.space.paddingSM}px;
      text-align: center;
   }
`;
