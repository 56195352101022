import { Form, Button, Input, message } from "antd";
import { RiLockLine } from "react-icons/ri";
import { LoginFormStyle } from "./style";
import { apiGetUserBaseInfo, apiLoginUserByPwd } from "api/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sleep } from "utils/functions/common";
import { useContext } from "react";
import { AgreementContext } from "./login";

function LoginFormPwd({ isModal = false }: { isModal?: boolean }) {
  let [searchParams] = useSearchParams();
  const isAgree = useContext(AgreementContext);

  let navigate = useNavigate();

  const onFinish = async (values: any) => {
    if (!isAgree) {
      message.warning("请先同意用户协议！")
      return
    }
    const token = await apiLoginUserByPwd({
      account: values.mobile.trim(),
      password: values.password,
    });
    localStorage.setItem("auth_token", token);
    const userInfo = await apiGetUserBaseInfo()
    localStorage.setItem("user_info", JSON.stringify(userInfo));
    message.success("登录成功！");
    await sleep(500)
    if (isModal) {
      window.location.href = window.location.href
    } else {
      const redirectUrl = searchParams.get("redirect")
      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        navigate('/home')
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <LoginFormStyle>
      <Form
        name="basic"
        style={{ width: "100%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          name="mobile"
          rules={[{ required: true, message: "请输入手机号码或用户名" }, {
            validator: (rule, val) => {
              if (val.length === 0) {
                return Promise.resolve()
              }
              if (!!val.trim()) {
                return Promise.resolve()
              } else {
                return Promise.reject('请输入手机号码或用户名')
              }
            }
          }]}
          normalize={(value) => value.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, '')}
        >
          <Input placeholder="请输入手机号码或用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "请输入密码" }]}
        >
          <Input.Password
            placeholder="请输入密码"
            prefix={<RiLockLine className="icon" />}
            autoComplete="off"
          />
        </Form.Item>


        <Form.Item className="submit">
          <Button type="primary" htmlType="submit" block>
            登录
          </Button>
        </Form.Item>
      </Form>
    </LoginFormStyle>
  );
}

export default LoginFormPwd;
