import styled from "styled-components";


export const FavoritesCardStyle = styled.div`
  .favorite {
    width: 100%;
    display: grid;

     gap: 1rem;

    .favorite-card{
      border: 1px solid ${(props) => props.theme.colors.colorBorder};
      &:hover{
        box-shadow:1px 0px 1px rgba(0, 0, 0, 0.07);
      }
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      overflow: hidden;
      padding: ${(props) => props.theme.space.paddingSM}px;
      .cover {
        background-color:${(props) => props.theme.colors.colorBgLayout} ;
      width: 100%;
      aspect-ratio: 1 / 1;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      /* display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2px; */
      display: flex;
      flex-wrap: wrap;
      gap: 2px;
      .cover-item {
        /* width: 100%; */
        width: calc(50% - 1px);
            height: calc(50% - 2px);
        aspect-ratio: 1 / 1;
        background-size: cover;
        background-position: center center;
        border-radius: ${(props) => props.theme.border.borderRadiusXS}px;
        box-sizing: border-box;
        /* margin-bottom: ${(props) => props.theme.space.margin}px; */
      }
      &:hover {
        .center {
          display: block;
        }
      }
      .center {
        display: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        align-items: center;
      }
    }
    .info {
      
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: ${(props) => props.theme.space.paddingSM}px;
      .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
       
      }
      .type {
        font-size:12px;
      }
    }
    }

  }
`;
