import { theme } from 'antd';
const { useToken } = theme;

export const UseFontSize = () => {
        const { token } = useToken();

        return {
             fontSize:token.fontSize ,
             fontSizeSM:token.fontSizeSM,
             fontSizeLG:token.fontSizeLG,
             fontSizeXL:token.fontSizeXL,
             fontSizeHeading1:token.fontSizeHeading1,
             fontSizeHeading2:token.fontSizeHeading2,
             fontSizeHeading3:token.fontSizeHeading3,
             fontSizeHeading4:token.fontSizeHeading4,//16px
             fontSizeHeading4_5:18,//18px
             fontSizeHeading5:token.fontSizeHeading5,//20px
             lineHeightHeading5:token.lineHeightHeading5,
             lineHeightHeading4:token.lineHeightHeading4,
             fontWeightHeading4:600     
        }
}