import { useEffect, useState } from "react"
import { SelectAnswererStyle } from "./style"
import { getMyRewardAnsererList, postChooseAnserer, postCreateRewardOrder } from "api/forum"
import { ForumApplicantListItem } from "api/forum/type"
import { Button, Radio } from "antd"
import { useNavigate } from "react-router-dom"

const SelectAnswerer = ({ topicId, close }: { topicId: number, close: () => void }) => {

    const [list, setList] = useState<ForumApplicantListItem[]>([])

    const [initList, setInitList] = useState<ForumApplicantListItem[]>([])

    const [applicantDetail, setApplicantDetail] = useState<ForumApplicantListItem>()

    const [loading, setIsLoading] = useState(false)

    const [userId, setUserId] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        if (!topicId) {
            return
        }
        getList()
    }, [topicId])

    const getList = () => {
        getMyRewardAnsererList({ topicId, pageSize: 20 }).then(res => {
            setInitList(res.pageData)
            const result = res.pageData.filter((item) => item.type === 2);
            if (result.length > 0) {
                setApplicantDetail(result[0])
            }
            const result2 = res.pageData.filter((item) => item.type === 1);
            console.log(result2)
            setList(result2)
        })
    }

    const onChange = () => {

    }

    const radioChange = (e: any) => {
        const value = e.target.value
        const result = initList.find(item => item.userId === value)
        const index = initList.findIndex(item => item.userId === value)
        if (result?.status === 1) {
            postChooseAnserer({ topicId: result.topicId, applyUserId: result.userId }).then(() => {
                console.log(list)
                list[index - 1].status = 2
                setList([...list])
            })
        }
        setUserId(value)
    }

    const submitForm = () => {
        setIsLoading(true)
        postCreateRewardOrder({ sellerUserId: Number(userId), topicId }).then(res => {
            close()
            navigate(`/forum/reward/order/${res.orderNumber}`)
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        })
    }

    return <SelectAnswererStyle>
        <div className="content">
            <Radio.Group onChange={onChange} value={userId}>
                {
                    !!applicantDetail && <>
                        <div className="content-title">我的邀约
                            <span>若选择公开抢答者，即视为取消本次邀约</span>
                        </div>
                        <div className="content-item">
                            <Radio disabled={applicantDetail.status !== 2} value={applicantDetail?.userId} onChange={radioChange}>
                                <div className="user">
                                    <div className="user-info">
                                        <div className="user-avatar" style={{ backgroundImage: `url(${applicantDetail?.userInfo.avatarUrl})` }} />
                                        <div style={{ width: 'calc(100% - 60px)' }}>
                                            <div className="user-name">{applicantDetail?.userInfo.username}</div>
                                            {
                                                applicantDetail.briefIntroduction && <div className="user-introduce" title={applicantDetail.briefIntroduction}>个人简介：{applicantDetail.briefIntroduction}</div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        applicantDetail?.type === 2 && <div className={`btn ${applicantDetail.status === 3 ? 'fail' : ' '}`}>
                                            {
                                                applicantDetail.status === 1
                                                    ? "已邀约，待接受"
                                                    : applicantDetail.status === 2
                                                        ? "邀约成功"
                                                        : "邀约失败"
                                            }
                                        </div>
                                    }
                                </div>
                            </Radio>
                        </div>
                    </>
                }
                {
                    list && list.length > 0 && <>
                        <div className="word">
                            公开抢答
                        </div>
                        {
                            list.map(item => {
                                return <div className="content-item" key={item.id}>
                                    <Radio value={item?.userId} onChange={radioChange}>
                                        <div className="user">
                                            <div className="user-info">
                                                <div className="user-avatar" style={{ backgroundImage: `url(${item?.userInfo.avatarUrl})` }} />
                                                <div style={{ width: 'calc(100% - 60px)' }}>
                                                    <div className="user-name">{item.userInfo.username}</div>
                                                    {
                                                        item.briefIntroduction && <div className="user-introduce" title={item.briefIntroduction}>个人简介：{item.briefIntroduction}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Radio>
                                </div>
                            })
                        }
                    </>
                }
            </Radio.Group>
        </div>
        <div className="btns">
            <Button type="primary" ghost onClick={() => close()}>取消</Button>
            <Button type="primary" onClick={submitForm} loading={loading} disabled={!userId}>提交</Button>
        </div>
    </SelectAnswererStyle>
}

export default SelectAnswerer