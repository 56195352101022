import GoBackTitle from "ui-lib/go-back";
import { ShippingTemplateEditStyle } from "./style";
import { Button, Divider, Form, Input, InputNumber, Popconfirm, Space, message } from "antd";
import { useEffect, useState } from "react";
import ProvinceModal from "./provinceModal";
import { ShippingFeeSpecialItem } from "types/product";
import SpecialInputItem from "./specialInputItem";
import {
  apiCreateShippingFeeTemplate,
  apiGetShippingFeeTemplateDetail,
  apiUpdateShippingFeeTemplate,
} from "api/shop";
import { useParams } from "react-router-dom";

function EditShippingTemplate() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [specialList, setSpecialList] = useState<ShippingFeeSpecialItem[]>([]);
  const [shouldValiate, setShouldValiate] = useState(0);
  const [updateObj,setUpdateObj] = useState<{
    index: number;
    ids:  number[];
  } | undefined>()

  useEffect(() => {
    resetValidate();
    if(!showModal){
      setUpdateObj(undefined);
    }
  }, [showModal]);

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  const getDetail = () => {
    apiGetShippingFeeTemplateDetail({ id: Number(id) }).then((res) => {
      console.log(res);
      form.setFieldsValue({
        baseFee: res.defaultRule.baseFee,
        baseNum: res.defaultRule.baseNum,
        freeNum: res.defaultRule.freeNum,
        incFee: res.defaultRule.incFee,
        incNum: res.defaultRule.incNum,
        name: res.name,
      });
      res.specialRules &&  setSpecialList(res.specialRules);
    });
  };

  const resetValidate = () => {
    setShouldValiate(0);
  };
  const handleCreateModalOk = (val: any) => {
    const newItem = {
      baseFee: undefined,
      baseNum: undefined,
      freeNum: undefined,
      incFee: undefined,
      incNum: undefined,
      provinceList: val,
    };
   
    specialList && setSpecialList([...specialList, newItem]);
    setShowModal(false);
  };

  const handleUpdateModalOk  =((val:any,index:number)=>{
   const updated =  specialList.map((item,i)=>{
      if(i === index){
        item.provinceList = val
      }
      return item
    })
    setSpecialList(updated);
    setShowModal(false);
  })

  const handleSpecialInputChange = (
    val: ShippingFeeSpecialItem,
    index: number
  ) => {
    resetValidate();
    const newList = specialList.map(
      (specialItem: ShippingFeeSpecialItem, i) => {
        if (i === index) {
          specialItem.baseFee = val.baseFee;
          specialItem.baseNum = val.baseNum;
          specialItem.freeNum = val.freeNum;
          specialItem.incFee = val.incFee;
          specialItem.incNum = val.incNum;
          specialItem.provinceList = specialItem.provinceList;
          return specialItem;
        }
        return specialItem;
      }
    );
    newList && setSpecialList(newList);
  };


  const validSpecialList =(list:ShippingFeeSpecialItem[])=>{
    if(!list) return
    let result = true
   
    list.forEach(item=>{
      const values = Object.values(item);
      for (let value of values) {
        if(value === undefined || value === null){
         
          result = false
          return
        };
      }
    })


    return result
  }

  const onSave = () => {
    setShouldValiate(new Date().getTime());
  
    if(!validSpecialList(specialList)) return

    form.validateFields().then((val) => {

      let { baseFee, baseNum, freeNum, incFee, incNum, name } = val;
      const params = {
        defaultRule: {
          baseFee,
          baseNum,
          freeNum,
          incFee,
          incNum,
        },
        name,
        specialRules: specialList,
      };
      if (id) {
        apiUpdateShippingFeeTemplate({ id: Number(id), ...params }).then(() => {
          message.success("更新成功");
          setTimeout(() => {
            window.location.href = "/user-center/shop/shipping-template-list";
          }, 500);
        });
      } else {
        apiCreateShippingFeeTemplate(params).then(() => {
          message.success("创建成功");
          setTimeout(() => {
            window.location.href = "/user-center/shop/shipping-template-list";
          }, 500);
        });
      }
    });
  };


  const deleteRow = (index: number) => {
    setSpecialList(specialList.filter((item, i) => i !== index));
  }
  return (
    <ShippingTemplateEditStyle className="ShippingTemplateEditStyle">
      <GoBackTitle
        onClick={() =>
          (window.location.href = "/user-center/shop/shipping-template-list")
        }
      >
        返回模版列表
      </GoBackTitle>
      <div className="title">运费设置 / 新建运费模版</div>
      <Form
        className="create"
        style={{ marginTop: 20, maxWidth: 900 }}
        labelAlign="left"
        form={form}
      >
        <Form.Item
          name="name"
          label="模版名称"
          rules={[{ required: true, message: "请选择模版名称" }]}
        >
          <Input
            type="text"
            maxLength={20}
            placeholder="请输入模版名称"
            showCount
          />
        </Form.Item>

        <div className="default-fee">
          <div className="form-label"> 默认运费 :</div>
          <Form.Item
            name="baseNum"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber min={1} placeholder="请输入" />
          </Form.Item>
          <span> &nbsp;件内运费&nbsp; </span>
          <Form.Item
            name="baseFee"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber min={0} placeholder="请输入" />
          </Form.Item>
          <span>&nbsp;元，每增加&nbsp;</span>
          <Form.Item
            name="incNum"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber min={1} placeholder="请输入" />
          </Form.Item>
          <span>&nbsp;件，运费增加&nbsp;</span>
          <Form.Item
            name="incFee"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber min={0} placeholder="请输入" />
          </Form.Item>
          <span>&nbsp;元。</span>
        </div>
        <div className="default-fee" style={{ marginLeft: 80 }}>
          <span>多件包邮：满&nbsp;</span>
          <Form.Item
            name="freeNum"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber min={0} placeholder="请输入" />
          </Form.Item>
          <span>&nbsp;件包邮（填0不设置多件包邮）【除指定区域外的运费】</span>
        </div>
        <div className="special-fee">
          <div className="form-label-no"> 指定区域运费 :</div>
          <div className="link" onClick={() => setShowModal(true)}>
            添加指定区域运费
          </div>
        </div>
      </Form>
      <div className="special-fee-list">
        {specialList &&
          specialList.map((item, index) => {
            return (
              <div className="special-fee-list" key={index}>
                <div className="special-fee-list-item">
                  <div className="header">
                    <div className="provinces">
                      {item.provinceList &&
                        item.provinceList.map((i) => i.name).join(",")}
                    </div>
                    <div className="action">
                    <Space split={<Divider type="vertical" />}>
                        <span className="a-like" onClick={()=>{
                          setShowModal(true)
                          item.provinceList && setUpdateObj({
                            index: index,
                            ids:item.provinceList.map(i=>i.id)
                          })
                        }}>
                          编辑
                        </span>
                        <Popconfirm
                          title="确认要删除吗?"
                          onConfirm={() => deleteRow(index)}
                          okText="确认"
                          cancelText="取消"
                        >
                          <span className="a-like"
                          >
                            删除
                          </span>
                        </Popconfirm>
                      </Space>
                    </div>
                  </div>
                  <div className="content">
                    <SpecialInputItem
                      value={item}
                      onChange={(val) => handleSpecialInputChange(val, index)}
                      shouldValiate={shouldValiate}
                    ></SpecialInputItem>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="submit">
        <Space>
          <Button
            className="btn"
            onClick={() =>
              (window.location.href =
                "/user-center/shop/shipping-template-list")
            }
          >
            取消
          </Button>
          <Button type="primary" className="btn" onClick={onSave}>
            保存
          </Button>
        </Space>
      </div>
      {showModal && (
        <ProvinceModal
          isModalOpen={showModal}
          handleOk={handleCreateModalOk}
          handleUpdateOk={handleUpdateModalOk}
          handleCancel={() => {
            setShowModal(false)
          }}
          updateObj={updateObj}
        />
      )}
    </ShippingTemplateEditStyle>
  );
}

export default EditShippingTemplate;
