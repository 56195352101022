import { SimsPopupSyle } from "./style"

const SimsPopup = ({ close }: { close: () => void }) => {

    const closePopup = () => {
        close()
    }

    return <SimsPopupSyle>
        <div className="content-wrap">
            <div className="content">
                <div>
                    <div className="sub-title first">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                        <div className="sub-word">活动简介</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                    </div>
                    <div className="tip">万元创意基金，20个创意席位。</div>
                    <div className="word">
                        <div>我们诚邀所有热爱模拟经营类游戏美术的创作者们，加入这场天马行空激发创意的创作盛宴。</div>
                        <div>在这里我们为您提供万元创意基金的支持，助力您的创意。</div>
                        <div>申请创意席位，提交完成作品即可获得创意基金。</div>
                    </div>
                    <div className="btn" onClick={() => window.open("https://wj.qq.com/s2/14964652/a3a8/")}>立即申请</div>
                    <div className="tip2">获得创意基金的参赛作品，仍可参与赛事其他奖项的评审。</div>
                    <div className="sub-title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                        <div className="sub-word">活动时间</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                    </div>
                    <div className="word">
                        <div>即日起至2024年8月8日</div>
                    </div>
                    <div className="sub-title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                        <div className="sub-word">参与方式</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                    </div>
                    <div className="word">
                        <div>1、点击立即申请，提交个人简介、创意概念、预期成果。</div>
                        <div>2、等待官方进行审核，审核通过后即可锁定「创意席位」。</div>
                        <div>3、发挥你的创意开始创作，赛事截稿前提交完成作品，即可获得500元创意基金。</div>
                    </div>
                    <div className="sub-title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                        <div className="sub-word">活动说明</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-sub-title-icon.png" alt="" />
                    </div>
                    <div className="word">
                        <div>1、参与本活动需进行申请，申请通过后可锁定「创意席位」，视为正式参与。</div>
                        <div>2、锁定「创意席位」后，需根据赛事规则及概念创意完成作品创作并提交后，方可正式</div>
                        <div>获得创意基金；未按照赛事规则及概念创意完成并提交作品，则视为无效参与。 </div>
                        <div>3、赛事组委会将在赛事截稿后与创作者取得联系并发放创意基金。</div>
                        <div>4、赛事组委会对本次活动拥有最终解释权。</div>
                    </div>
                </div>
            </div>
            <div className="close-wrap">
                <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-popup-close1.png" onClick={close} className="close" />
            </div>
        </div>
    </SimsPopupSyle>
}

export default SimsPopup