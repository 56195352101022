import { GlobalSearchImageResponse } from "types/media";
import { HomeMediaStyle } from "./style";
import { Avatar } from "antd";
import { RiStarLine } from "react-icons/ri";
import { useState } from "react";
import AddFavorite from "components/favorite/add-favorite";
import { hasToken } from "utils/functions/common";
import { useNavigate } from "react-router";
import { ImageObjectCloudResize } from "utils/functions/file";
import { loginPathWithRedirect } from "utils/functions/user";

const HomeMediaCard = (props: { info: GlobalSearchImageResponse }) => {
  const [isShow, setIsShow] = useState(false)

  let info = props.info;
  const navigate = useNavigate()

  const favourImage = (e: any) => {
    e.stopPropagation()
    if (!hasToken()) {
      navigate(loginPathWithRedirect())
      return
    }
    setIsShow(true)
  }

  const closePopup = () => {
    setIsShow(false)
  }

  return (
    <HomeMediaStyle>
      <div
        className="container"
      >
        <img src={ImageObjectCloudResize(info.url, 320)} alt="" style={{ objectFit: 'cover', width: '100%' }} />
        <div className="icon-wrap" onClick={(e) => favourImage(e)}>
          <RiStarLine className="icon" />收藏
        </div>
        <div className="bottom">

          <div className="userinfo">
            <a onClick={e => e.stopPropagation()} href={`/user/${info.userInfo.id}/works`} target="_blank">
              <div className="avatar">
                <Avatar src={info.userInfo.avatarUrl} size={26}></Avatar>
              </div>
            </a>
            <a onClick={e => e.stopPropagation()} href={`/user/${info.userInfo.id}/works`} target="_blank">
              <div className="username">{info.userInfo.username}</div>
            </a>
          </div>

          <div className="title"> <span>来自于</span>   {info.work.title}</div>
        </div>
      </div>

      {isShow && <AddFavorite dataId={info.id} type='media' FavoriteTypeDisabled={true} onClose={() => closePopup()}></AddFavorite>}
    </HomeMediaStyle >
  );
};

export default HomeMediaCard;
