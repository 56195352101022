import { Button, Input, message } from "antd"
import { FloatChatInputStyle } from "./style"
import { useState, useCallback, useRef } from 'react'
import { CommentListItem } from "types/comment"
import { postCreateComment } from "api/work/comment";
import { useParams } from 'react-router-dom';
import EmojiPicker from "components/emoji-picker";
import { emojiParse } from "utils/functions/common";

type ChatInputType = {
  params: {
    createComment: (val: CommentListItem) => void,
    dataTable?: string,
    subId?: number
  }
}
function FloatChatInput(props: ChatInputType) {
  const { id } = useParams<any>();
  const [value, setValue] = useState<string>('')
  const changeValue = useCallback((e: any) => {
    setValue(e.target.value)
  }, [])
  const myInput = useRef(null);

  const [loading, setLoading] = useState<boolean>(false)

  const appendEmoji = (item: { url: string, title: string }) => {
    setValue((res) => res + item.title)
  }

  const send = () => {
    if(!value.trim()){
      message.error("请输入评论内容！")
      return
    }
    setLoading(true)
    postCreateComment({
      content: emojiParse(value),
      dataId: (props.params.subId!) || Number(id),
      dataTable: props.params?.dataTable || 'work'
    }).then((res) => {
      message.success('创建成功')
      props.params.createComment(res)
    }).finally(() => {
      setValue('')
      setLoading(false)
    })
  }

  const onKeyDown = (code:number) =>{
    if(code === 13) {
      send()
    }
  }
  return (
    <FloatChatInputStyle className="input-wrap">
      <div className="inputBar">
        <Input
          placeholder="请输入你的评论"
          className="input"
          value={value}
          onChange={changeValue}
          onKeyDown={(e)=>onKeyDown(e.keyCode)}
        />
        <Button loading={loading} type="primary" className="btn" onClick={() => send()}>
          评论
        </Button>
      </div>
      <EmojiPicker appendEmoji={appendEmoji} />

    </FloatChatInputStyle>
  )
}

export default FloatChatInput