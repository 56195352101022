import styled from "styled-components";
import yellowG from "assets/images/big-g-yellow.svg";
import yellowGBright from "assets/images/big-g-yellow-bright.svg";
import silverG from "assets/images/big-g-silver.svg";
import silverGBright from "assets/images/big-g-silver-bright.svg";
import bronzeG from "assets/images/big-g-bronze.svg";
import bronzeGBright from "assets/images/big-g-bronze-bright.svg";

export const UserBillboardStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1540px){
    .work-list {
      width: 380px;
      overflow: auto;
    }
  }

  .billboard-container {
    padding: ${(props) => props.theme.space.paddingLG}px 0;
    max-width: ${(props) => props.theme.space.maxContainer}px;
    width: 93vw;
    display: flex;
    /* min-width: 1450px; */
    .no-data {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .main {
      flex: 1;
      min-height: calc(100vh - 100px);
      .list-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: ${(props) => props.theme.space.paddingLG}px;
        position: relative;
        &:nth-child(2) {
          .rank-num {
            h1{
              color: ${(props) => props.theme.colors.colorPrimary};
              font-size: 48px;
              transform: translateY(-18px);
            }
           
          }
          .upper-info{
            position: relative;
            background-image: url(${yellowG}), url(${yellowGBright});
            background-repeat: no-repeat;
            background-position: right -20px top -30px, left -100px top -380px;
            background-color: #f8d382 ;
            background-size: 300px;
            border: 1px solid #F9BB46 !important;
          }
           
          }
          &:nth-child(3) {
            .rank-num {
            h1{
              color: #d5e1f4;
              font-size: 48px;
              transform: translateY(-18px);
            }
          }
            .upper-info{
            position: relative;
            background-image: url(${silverG}), url(${silverGBright});
            background-repeat: no-repeat;
            background-position: right -20px top -30px, left -100px top -380px;
            background-color: #eceff4;
            background-size: 300px;
            border: 1px solid #D6E1F4 !important;

          }
          }
          &:nth-child(4) {
            .rank-num {
            h1{
              color: #f6cab0;
              font-size: 48px;
              transform: translateY(-18px);
            }
          }
            .upper-info{
            position: relative;
            background-image: url(${bronzeG}), url(${bronzeGBright});
            background-repeat: no-repeat;
            background-position: right -20px top -30px, left -100px top -380px;
            background-color: #ffe0cd;
            background-size: 300px;
            border: 1px solid #f6cab0 !important;
          }
          }
        .rank-num {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 28px;
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          h1{
            font-size: 32px;
            font-weight: 800;
            color: ${(props) => props.theme.colors.colorBlack3Ggac};
            text-align: center;
          }
        }

        .right-part {
          width: 100%;
          margin-left: 180px;
          .upper-info {
            width: 100%;
            overflow: hidden;
            position: relative;
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
            border: 1px solid
              ${(props) => props.theme.colors.colorBorder};
            padding: ${(props) => props.theme.space.paddingLG}px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
              box-shadow: ${(props) => props.theme.shadow.boxShadowThin};
            }


            .main-info {
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding-right: ${(props) => props.theme.space.padding}px;
              padding-left: ${(props) => props.theme.space.padding}px;
              color: ${(props) => props.theme.colors.colorTextSecondary};
              height: 140px;
              .wrapper{
                display: flex;
                align-items: center;
                flex:1;
                .user-info {
                padding: ${(props) => props.theme.space.padding}px;
                display: flex;
                flex-direction: column;
                align-self: space-around;
                justify-content: space-between;           
                height: 100%;
                .avatar {
                 }
                .upper {
                  .title {
                    font-size: large;
                    font-weight: bold;
                    color: ${(props) => props.theme.colors.colorText};
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 180px;
                  }
                  .meta-info {
                    margin: ${(props) => props.theme.space.marginXS}px 0;
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    .category {
                      margin-right: ${(props) => props.theme.space.margin}px;
                    }
                  }
                }
                .down {
                  display: flex;
                  align-items: center;
                  .username {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100 - "60px");
                    margin-right: ${(props) => props.theme.space.margin}px;
                  }
                  .time {
                    font-size: small;
                  }
                  .btn{
                    width: 100px;
                  }
                  .defaultBtn{
                   color: ${(props) => props.theme.colors.colorPrimary};
                   border: 1px solid  ${(props) => props.theme.colors.colorPrimary};
                  }
                }
              }
              .work-list{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap:${(props) => props.theme.space.padding}px;
                .list-item_{
                  aspect-ratio: 223 / 176;
                  width: 180px;
                  border-radius: ${(props) => props.theme.border.borderRadius}px;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  &:hover{
                    box-shadow:  ${(props) => props.theme.shadow.boxShadowThin};
                  }
                }
              }
              }
             
              .right {
                font-size: large;
                font-weight: bold;
                color: ${(props) => props.theme.colors.colorText};
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-bottom: ${(props) => props.theme.space.padding}px;
                text-align: center;
                margin-left:${(props) => props.theme.space.paddingLG}px;
                .show-extra {
                  font-size: ${(props) => props.theme.fontSizes.fontSize}px;
                  color: ${(props) => props.theme.colors.colorTextSecondary};
                  font-weight: 400;
                  display: flex;
                  align-items: center;
                  position: relative;
                  .icon{
                    font-size: 18px;
                    position: absolute;
                    right:-20px;
                    top:0;
                  }
                  &:hover {
                    color: ${(props) => props.theme.colors.colorText};
                  }
                }
              }
            }
          }
        }
      }
    }
    .side-bar {
      width: 280px;
      margin-right: ${(props) => props.theme.space.padding}px;
      border: 1px solid ${(props) => props.theme.colors.colorBorder};
      box-sizing: border-box;
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      padding: ${(props) => props.theme.space.paddingLG}px;
      .ant-menu-inline {
        border-inline-end: none !important;
      }
      .ant-menu-item-selected {
        background-color: ${(props) => props.theme.colors.colorPrimary};
        &:hover {
          background-color: ${(props) =>
            props.theme.colors.colorPrimary} !important;
        }

        .ant-menu-title-content {
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-item {
        color: ${(props) => props.theme.colors.colorTextLightCustom};
        &:hover {
          background-color: transparent;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-submenu-title {
        color: ${(props) => props.theme.colors.colorTextTertiary};
        &:hover {
          background: none !important;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
    }
  }
`;
