import styled from 'styled-components'

export const HelpStyle = styled.div`
/* .wrap{
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
} */
  /* .cont{color:#3c528f;} */
  .headerTitle {
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.8); */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    /* padding:  0 ${(props) => props.theme.space.paddingLG}px ;
    &-left {
      .logo {
        height: 18px;
      }
    }
    &-center {
     display: flex;
     .search-btn{
      padding:  0 ${(props) => props.theme.space.padding}px ;
          margin-right: 30px;
          font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px ;
          line-height: 50px;
          color: ${(props) => props.theme.colors.colorTextLightSolid};
          cursor: pointer;
          transform: translateY(1px);
          &:hover{
            color: ${(props) => props.theme.colors.colorPrimary};
          }
        }
    }
    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    } */
  }
  .Title{
    font-size: 1.3rem;
    font-weight: bold ;
  }
  .content {
    white-space:pre-wrap;
    display: flex;
    font-size: 1.1rem;
    text-align: left;
    margin: 2px;
    /* color:#3c528f; */
    /* flex-direction: row; */
    align-content:flex-start;
  }
  .title {
    display:flex;
    align-content:flex-start;
    font-size: 1.3rem;
  }
  .line{height:1px;border:none;border-top:1px;}
`