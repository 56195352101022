import { TagsStyle } from './style'
import SdTag from 'ui-lib/tag/sd-tag'

function Tags(props: { tagList: { id: number, name: string }[] }) {
  return (
    <TagsStyle>
      <div className='inlineTags'>
        <div className='title'>标签：</div>
        <div className="tag-list">
          {props.tagList && props.tagList.map((item,index) => {
            return  <SdTag key={index}>
              <div onClick={() => window.open(`/search/keyword?type=article&name=${item.name}`)}>{item.name}</div>
            </SdTag>
          })}
        </div>
      </div>
    </TagsStyle>
  )
}

export default Tags