import { WorkContentStyle } from "./style";
import "@egjs/react-view3d/css/view3d-bundle.min.css";
import { WorkDetailMedieListItem, WorkDetailType } from "types/work";
import 'react-photo-view/dist/react-photo-view.css';
import PhotoContentDisplay from "./photo";
import { isImg, isImgEfx, isThreedGlb, isVideo, reorderMediaList, translateContestMediaMark } from "./fn";
import VideoContentDisplay from "./video";
import { Suspense, useEffect, useState } from "react";
import { PhotoProvider } from "react-photo-view";
import { ToolBarControls } from "./photo/style";
import { RiAnticlockwiseLine, RiClockwiseLine, RiZoomInLine, RiZoomOutLine } from "react-icons/ri";
import React from "react";
import { Divider, Space, Spin, Tooltip } from "antd";
import DetailHeader from "../header";
import { isWorkAudio } from "views/work/detail/left/fn";
// import ThreeDContentDisplay from "./3d";
const ThreeDContentDisplayComponent = React.lazy(() => import('./3d'));

function WorkContent(props: { detail: WorkDetailType }) {
  const [dataList, setDatalist] = useState<WorkDetailMedieListItem[]>()
  const mediaMarks: string[] = []
  useEffect(() => {
    //只有新数据的比赛 才需要做reorder处理，老数据及练习作品都直接返回排序
    if (props.detail.isContest && props.detail?.mediaList && props.detail.orderType === 0) {
      setDatalist(reorderMediaList(props?.detail?.mediaList))
    } else {
      setDatalist(props?.detail?.mediaList)
    }
  }, [props.detail])

  const getCategory = () => {
    return <>
      {props.detail.dictMap?.mediaCategory}
      <span> | </span>
      {props.detail.categoryList?.[0]?.name} {
        props.detail.categoryList?.length > 1 && <span> | </span>
      }
      {props.detail.categoryList?.[1]?.name}
    </>
  }


  return (
    <WorkContentStyle>
      <div className="header">
        <div className="header-left">
          <div className="header-top">
            <div className="title">{props.detail.title}</div>
            <div className="meta">
              <Space>
                {props.detail.actionCount?.hotLevel === 1 && (
                  <div className="roundIcon red-light">
                    <Tooltip title="热门作品">
                      <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-hot-icon.png" alt="" />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.hotLevel === 2 && (
                  <div className="roundIcon red">
                    <Tooltip title="超火作品">
                      <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-hot-icon2.png" alt="" />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.hotLevel === 3 && (
                  <div className="roundIcon rank-yellow">
                    <Tooltip title="榜单作品">
                      <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-rank-icon.png" alt="" />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.recommendLevel === 1 && (
                  <div className="roundIcon yellow selected">
                    <Tooltip title="精选作品">
                      <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-photo-wall-icon.png" alt="" />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.recommendLevel === 2 && (
                  <div className="roundIcon blue">
                    <Tooltip title="图墙推荐">
                      <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-photo-wall-icon2.png" alt="" />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.recommendLevel === 3 && (
                  <div className="roundIcon yellow">
                    <Tooltip title="首页推荐">
                      <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-recommend-icon.png" alt="" />
                    </Tooltip>
                  </div>
                )}
              </Space>
            </div>
          </div>
          <div className="category">{getCategory()}</div>
        </div>
        {
          !!props.detail.isContest &&
          <div className="header-right">
            <DetailHeader detail={props.detail}></DetailHeader>
          </div>
        }

      </div>
      <Divider></Divider>
      <div className="desc" style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: props.detail.description && props.detail.description.replace(/\n/g, "<br/>") }}>
      </div>
      {props.detail?.contestWork && props.detail?.contestWork!.content && <div
        className="html-content"
        dangerouslySetInnerHTML={{ __html: props.detail?.contestWork!.content }}
      >
      </div>
      }

      <PhotoProvider speed={() => 400} easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
        toolbarRender={({ onScale, scale, onRotate, rotate }) => {
          return (
            <ToolBarControls>
              <RiZoomInLine className='control-icon' onClick={() => onScale(scale + 1)} ></RiZoomInLine>
              <RiZoomOutLine className='control-icon' onClick={() => onScale(scale - 1)} ></RiZoomOutLine>
              <RiAnticlockwiseLine className='control-icon' onClick={() => onRotate(rotate - 90)} ></RiAnticlockwiseLine>
              <RiClockwiseLine className='control-icon' onClick={() => onRotate(rotate + 90)} ></RiClockwiseLine>
            </ToolBarControls>
          );
        }}
      // overlayRender={({index})=><BottomBar onClick={()=>updateDatelist(index)} item={dataList?.[index] ?? undefined}></BottomBar>}
      >

        {props.detail.orderType === 0 && dataList && dataList.map((item, index) => {
          //这个处理是让比赛图片标签只显示一次
          let node = <div className="contentObject" key={index}>
            {translateContestMediaMark(item) && !mediaMarks.includes(item.mark) && <div className="caption-0">{translateContestMediaMark(item)}</div>}
            <div className="media">
              {isImg(item) &&
                <div className='fit'>
                  <PhotoContentDisplay mediaItem={item}></PhotoContentDisplay>
                </div>
              }
              {isImgEfx(item) && <VideoContentDisplay url={item.params?.specialEffectVideo}></VideoContentDisplay>}
              {isVideo(item) && (
                <div className='fit'>
                  <VideoContentDisplay url={item.resizedUrl ?? item.url} cover={item?.coverUrl}></VideoContentDisplay>
                </div>
              )}
              {isThreedGlb(item) &&
                <Suspense fallback={<Spin />}>
                  <ThreeDContentDisplayComponent mediaItem={item}></ThreeDContentDisplayComponent>
                </Suspense>
              }

              {
                isWorkAudio(item) && <>
                  <div style={{ textAlign: "center" }}>
                    <audio
                      style={{ width: "500px" }}
                      src={item.url}
                      controls
                    />
                  </div>
                </>
              }

            </div>
            <div className="caption">{item.caption}</div>
          </div>
          mediaMarks.push(item.mark) //这个处理是让比赛图片标签只显示一次
          return node
        })}

        {/* //老比赛数据排序 */}
        {props.detail.orderType === 1 && dataList && dataList.map((item, index) => {
          let node = <div className="contentObject" key={index}>
            <div className="media">
              {isImg(item) &&
                <div className='fit'>
                  <PhotoContentDisplay mediaItem={item}></PhotoContentDisplay>
                </div>
              }
              {isVideo(item) && (
                <div className='fit'>
                  <VideoContentDisplay url={item.resizedUrl ?? item.url} cover={item?.coverUrl}></VideoContentDisplay>
                </div>
              )}
              {isThreedGlb(item) &&
                <Suspense fallback={<Spin />}>
                  <ThreeDContentDisplayComponent mediaItem={item}></ThreeDContentDisplayComponent>
                </Suspense>
              }

              {
                isWorkAudio(item) && <>
                  <div style={{ textAlign: "center" }}>
                    <audio
                      style={{ width: "500px" }}
                      src={item.url}
                      controls
                    />
                  </div>
                </>
              }

            </div>
            <div className="caption">{item.caption}</div>
          </div>
          return node
        })}
      </PhotoProvider>
    </WorkContentStyle>
  );
}

export default WorkContent;
