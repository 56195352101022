export const notification= 
{ 
    type: {
        all: '全部西八',
        system: '系统西八',
        favour: '喜欢西八',
        comment: '评论西八',
        subscribe: '关注西八'
    },
    messageBox: '消息盒西八', 
}
