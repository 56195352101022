import styled from "styled-components";

export const ForumLayoutStyle = styled.div`
    .guanggao-banner-wrapper{
        width: 100%;
        padding: ${(props) => props.theme.space.paddingLG}px 0;
    }
    .container{
        width: 1032px;
        margin: 0 auto;
    }

    .tab {
        display: flex;
        justify-content: center;
        background-color: #fff;
        padding: 15px 0 25px;
        margin-bottom: ${(props) => props.theme.space.marginLG}px;
        gap: 50px;
        border-radius: ${props => props.theme.border.borderRadius}px;
        position: relative;
        .btn {
            position: absolute;
            right: ${(props) => props.theme.space.marginLG}px;
            top: 50%;
            transform: translateY(-50%);
        }
        .active {
            color: #2b2e36 !important;
            font-weight: bold;
            &::after{
                position: absolute;
                content: "";
                background-color:#ffc712;
                width: 40px;
                height: 3px;
                left: 50%;
                transform: translateX(-50%);
                bottom: -10px;
            }
        }
        &-item {
            font-size: 18px;
            color: #808080;
            position: relative;
            cursor: pointer;
        }
    }
`