import { Rate } from "antd"
import { ProductReviewListStyle } from "./style"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { getMallReview } from "api/shop"
import { MallReviewListResponse } from "types/product"
import { Image } from 'antd';
import Comments from "components/comments"
import FloatChatInput from "views/work/detail/right/floatChatInput"
import { CommentListItem } from "types/comment"

function ProductReviewList() {
  const [list, setList] = useState<MallReviewListResponse[]>([])
  const { productId } = useParams()
  let [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [commentId, setCommentId] = useState(0)
  useEffect(() => {
    getMallReview({ productId: Number(productId), pageNumber }).then(res => {
      setLoading(true)
      setList((result) => [...result, ...res.pageData])
      setTotal(res.totalPage)
    }).finally(() => {
      setLoading(false)
    })
  }, [productId, pageNumber])

  const loadmore = () => {
    if (loading) {
      return
    }
    pageNumber = pageNumber + 1
    setPageNumber(pageNumber)
  }

  const commentRef = useRef() as React.MutableRefObject<any>;
  // 调用commet创建方法
  const createComment = (val: CommentListItem) => {
    commentRef.current?.addComment(val)
  }

  const showCommentId = (id: number) => {
    if (id === commentId) {
      setCommentId(0)
    } else {
      setCommentId(id)
    }
  }

  return (
    <ProductReviewListStyle>
      {
        list && list.length > 0 && list.map(item => {
          return <div className="row">
            {/* <div className="avatar" style={{ backgroundImage: `url(${item.userInfo.avatarUrl}})` }}></div> */}
            <img className="avatar" src={item.userInfo.avatarUrl} alt="" />
            <div className="right-part">
              <div className="up">
                <div className="name">{item.userInfo.username}</div>
                <div className="rating">
                  <span className="label">满意度</span><Rate disabled value={item.ratings} /></div>
              </div>
              <div className="middle">
                <span>{item.content}</span>
              </div>
              <div className="pictures">
                {
                  item.imageList.map(image => {
                    // return <div className="item" style={{ backgroundImage: `url(${image})` }}></div>
                    return <Image src={image} />
                  })
                }
              </div>
              <div className="bottom">
                <div className="time">{item.beautifyTime}</div>
                <div className="spec">商品属性：{item.skutName}</div>
                <div className={`reply ${commentId === item.id ? 'active' : ''}`} onClick={() => showCommentId(item.id)}>追加评论</div>
              </div>
              {
                commentId === item.id && <>
                  <div id="input-wrap">
                    <FloatChatInput params={{ createComment, subId: item.id, dataTable: "mall_review" }}></FloatChatInput>
                  </div>
                  <Comments ref={commentRef} params={{ type: 'mall_review', id: item.id }}></Comments>
                </>
              }
            </div>
          </div>
        })
      }
      {
        pageNumber < total && <div className="more" onClick={loadmore}>加载更多</div>
      }
    </ProductReviewListStyle>
  )
}

export default ProductReviewList