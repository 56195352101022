import styled from "styled-components";

export const NftAlbumStyle = styled.div`
    .data-list {
        display: flex;
        margin: 0 auto;
        display: grid;
        gap: ${props=>props.theme.space.padding}px;
    }
    
`