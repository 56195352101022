import { myArticleListItem } from "types/article";
import { translateCardCategoryText } from "utils/functions/common";
import CardTitleRender from "components/uni-card/common/card-title/article";
import UniCardLayout from "components/uni-card/layout";
import DraftArticleAction from "./draftArticleAction";
import DraftArticleStatus from "./draftArticleStatus";

const DraftArticleCard = ({ item, deleteWork }: { item: myArticleListItem, deleteWork: (id: number) => void }) => {

    const UiObject = {
        coverUrl: item.coverUrl,
        title: <CardTitleRender  item={item} />,
        subtitle: <span> {translateCardCategoryText(item.categoryList)}</span>,
        view: item.viewCount,
        hot: item.hot,
        workLink: item.isDraft ? "" : `/article/detail/${item.id}`,
        centerNode: <DraftArticleAction item={item} deleteWork={deleteWork}></DraftArticleAction>,
        date:item.updateTime,
        leftBottomNode:<DraftArticleStatus item={item}></DraftArticleStatus>
    }
    return UniCardLayout({ item: UiObject });
}


export default DraftArticleCard