import styled from 'styled-components'

export const NotFoundStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    text-align: center;
    background-color: #000;;
    color:#fff;
    img{
        transform: translateX(-10px);
    }
    .link{
        color: ${props=> props.theme.colors.colorPrimary};
        cursor: pointer;
    }
`