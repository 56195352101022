import styled from "styled-components";

export const ArticleUpdateStyle = styled.div`
 background-color: ${(props) => props.theme.colors.colorBgLayout};
 .form-section{
        background-color: ${(props) => props.theme.colors.colorBgContainer};
        padding:  ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.paddingLG}px  ${(props) => props.theme.space.paddingSM}px   ${(props) => props.theme.space.paddingLG}px ;
        margin-bottom:  ${(props) => props.theme.space.paddingLG}px;
        border-radius:  ${(props) => props.theme.border.borderRadiusLG}px;
    }
    .main-container{
        width:  ${(props) => props.theme.space.mainContainer*0.92}px;
        margin: 0 auto;

        padding:  ${(props) => props.theme.space.paddingLG}px;
        display: flex;
        .left{
            flex: 1;
            .form-area{
                width: 100%;
                padding-right: ${(props) => props.theme.space.paddingLG}px;
            }
        }
        .right{
            width: 320px;
            background-color: ${(props) => props.theme.colors.colorBgContainer};
            padding: ${(props) => props.theme.space.paddingSM}px ${(props) => props.theme.space.paddingSM}px;
            border-radius:  ${(props) => props.theme.border.borderRadiusLG}px;
            .card{
                background-color: ${(props) => props.theme.colors.colorBgContainer};
                margin-bottom:  ${(props) => props.theme.space.paddingSM}px;
                padding-bottom: 10px;
                .header{
                    padding: ${(props) => props.theme.space.paddingSM}px;
                    display: flex;
                    justify-content: space-between;
                }
                .image-crop-wrapper{
                    padding: 10px;
                   .ant-upload-select {
                    width: 100%;
                    height: 216px;
                   }
                   .ant-upload-list-item-container {
                    width: 100%;
                    height: 216px;
                   }
                }
                .cover{
                    height: 216px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    position: relative;
                    .delete{
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: ${(props) => props.theme.space.paddingXXS }px  ${(props) => props.theme.space.paddingXXS}px ;
                        color: ${(props) => props.theme.colors.colorError};
                        font-size:  ${(props) => props.theme.fontSizes.fontSizeLG}px;
                    }
                }
                .footer{
                    padding: ${(props) => props.theme.space.paddingSM}px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .choosePublic{
                        margin-bottom: ${(props) => props.theme.space.margin}px;
                        display: flex;
                        justify-content: center;
                    }
                    .block-btn{
                        width: 100%;
                        margin: ${(props) => props.theme.space.marginXS}px 0;
                    }
                    .wrap{
                        display: flex;
                        margin: ${(props) => props.theme.space.marginXS}px 0;
                        .btn{
                        display: flex;
                        align-items: center;
                        margin-right: ${(props) => props.theme.space.marginXS}px;
                        .btn-text{
                            padding:0 ${(props) => props.theme.space.paddingXXS}px;
                        }
                    }
                    }
                    
                }
                .add-work-wrapper{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 0 12px;
                    margin-top: ${(props) => props.theme.space.paddingLG}px;
                    .add-btn{
                        margin-bottom: ${(props) => props.theme.space.margin}px;
                        width: 100%;
                       
                    }
                }
                .select-list-wrapper{
                    margin-left: 5%;
                    display: flex;
                    width: 90%;
                    overflow-x: auto;
                    
                    .list-item-wrapper{
                        min-width: 100px;
                        margin-left: 10px;
                        margin-bottom: 10px;
                    }
                }

                .select-list-wrapper::-webkit-scrollbar {
                    width: 5px;
                    height: 6px;
                    background-color: ${(props)=>props.theme.colors.colorPrimaryBorder}; /* or add it to the track */
                }

                /* Add a thumb */
                .select-list-wrapper::-webkit-scrollbar-thumb {
                    background: ${props=>props.theme.colors.colorPrimary};
                }
            }
        }
        .custom-title{
            padding-bottom:  ${(props) => props.theme.space.padding}px;
        }
    }
`