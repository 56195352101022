import styled from "styled-components";

export  const  SubChildrenSelectStyle = styled.div`
    .second-select-container {
        .item-wrapper {
            display: flex;
            align-items: center;
            .first-wrapper{
                 /* padding-right: ${props => props.theme.space.padding}px; */
                 width: 120px;
            }
           .second-wrapper{
            display: flex;
            flex-wrap: wrap;
            .second-wrapper-item {
                margin: ${props => props.theme.space.marginXS}px;
                padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
                background-color: ${props => props.theme.colors.colorBgContainer};
                border-radius: ${props => props.theme.border.borderRadius}px;
                border: 1px solid ${props => props.theme.colors.colorBorder};
                position: relative;
                cursor: pointer;
            }
            .active {
                background-color: ${props => props.theme.colors.colorPrimary};
            }
           }
        }
        
    }
`