import { Select } from "antd"
import { useEffect, useMemo, useState } from "react"

import { TagListProps, filterValueType } from "../workType"

const TagList = ({ change }: TagListProps) => {
    const [isPublic, setIsPublic] = useState<number>(2)
    // const [value3, setValue3] = useState('hot')
    const [onSale, setOnSale] = useState(2)
    const filterValue: filterValueType = useMemo(() => {
        var params: filterValueType = { isPublic }
        if (isPublic || isPublic === 0) {
            params.isPublic = isPublic
        }
        if (isPublic === 2) {
            delete params.isPublic
        }
        if (onSale || onSale === 0) {
            params.onSale = onSale
        }
        if (onSale === 2) {
            delete params.onSale
        }
        return params
    }, [isPublic, onSale])
    // const handleChange = (e: number) => {
    //     setOnSale(e)
    // }
    const handleChange2 = (e: number) => {
        setIsPublic(e)
    }
    // const handleChange3 = (e: string) => {
    //     setValue3(e)
    // }
    useEffect(() => {
        change(filterValue)
    }, [filterValue])

    return <div>
        <Select
            defaultValue={2}
            style={{ width: 120 }}
            onChange={handleChange2}
            className="select"
            options={[
                { value: 2, label: "全部" },
                { value: 1, label: "公开" },
                { value: 0, label: "私密" },
            ]}
        />
        {/* <Select
            defaultValue={2}
            style={{ width: 120 }}
            onChange={handleChange}
            className="select"
            options={[
                { value: 2, label: "全部" },
                { value: 1, label: "上架" },
                { value: 0, label: "下架" },
            ]}
        /> */}
        {/* <Select
            defaultValue="hot"
            style={{ width: 120 }}
            onChange={handleChange3}
            options={[
                { value: "date", label: "时间" },
                { value: "hot", label: "热度" },
            ]}
        /> */}
    </div>
}

export default TagList