import { get, post } from "api"
import { UserAddressItem, CreateAddressRequest } from "types/user/address";

//获取用户所有信息
export const apiGetUserAddressList = async () =>
    await get<{}, UserAddressItem[]>(`/api/shipping_address/list`);

// 创建用户地址
export const apiPostCreateUserAddress = async (params: CreateAddressRequest) =>
    await post<CreateAddressRequest, {}>(`/api/shipping_address/create`, params);

// 编辑 用户地址
export const apiPostEditUserAddress = async (params: CreateAddressRequest) =>
    await post<CreateAddressRequest, {}>(`/api/shipping_address/update`, params);

// 删除用户地址
export const apiPostDeleteAddress = async (ids: number[]) =>
    await post<{ ids: number[] }, {}>(`/api/shipping_address/delete`, { ids });

// 获取用户地址详情
export const apiGetUserAddressDetail = async (id: number) =>
    await get<{ id: number }, UserAddressItem>(`/api/shipping_address/detail/${id}`);

// 修改地址是否是默认地址
export const apiUpdateAddressIsDefalt = (id: number) =>
    post<{ id: number }, {}>("/api/shipping_address/change_default", { id })
