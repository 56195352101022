import Layout from "views/layout";
import { Navigate, RouteObject } from "react-router-dom";
import ForumIndex from "views/forum";
import TopicDetail from "views/forum/topic-detail";
import MyForumData from "views/forum/my-data";
import ForumSearch from "views/forum/search";
import ForumLayout from "views/forum/forum-layout";
import RewardIndex from "views/forum/reward";
import RewardLayout from "views/forum/reward/reward-layout";
import MyReward from "views/forum/reward/my-reward";
import RewardOrderDetail from "views/forum/reward/order/detail";
import RewardOrderList from "views/forum/reward/order/list";
import RewardApplicantList from "views/forum/reward/my-applicant";
import RewardInvitationList from "views/forum/reward/my-invitation";
import RewardReceiveOrder from "views/forum/reward/order/receive-order";
import AnswererList from "views/forum/reward/answerer";
import RewardDetail from "views/forum/reward/detail";
import ReceiveOrderDetail from "views/forum/reward/order/receive-order/detail";
import RewardSearch from "views/forum/reward/search";

const search = window.location.search
console.log(search)
export const forumRoutes: RouteObject[] = [
    {
        path: '/forum',
        element: <Navigate to="/forum/question/index"></Navigate>
    },
    {
        path: '/forum/index',
        element: <Navigate to="/forum/question/index"></Navigate>
    },
    {
        path: '/forum/search',
        element: <Navigate to={`/forum/question/index${search}`}></Navigate>
    },
    {
        path: "/forum",
        element: <Layout bgColor="#f6f8fb"></Layout>,
        children: [
            {
                path: "question/topic/:id",
                element: <TopicDetail />,
            },
            {
                path: "question",
                element: <ForumLayout value='question' />,
                children: [
                    {
                        path: "index",
                        element: <ForumIndex />,
                    },
                    {
                        path: "my/:tab",
                        element: <MyForumData />,
                    },
                    {
                        path: "search",
                        element: <ForumSearch />,
                    }
                ]
            },
            {
                path: "reward",
                element: <ForumLayout value='reward' />,
                children: [
                    {
                        path: "",
                        element: <RewardLayout />,
                        children: [
                            {
                                path: "index",
                                element: <RewardIndex />,
                            },
                            {
                                path: "my-reward",
                                element: <MyReward />,
                            },
                            {
                                path: "order/:orderNumber",
                                element: <RewardOrderDetail />
                            },
                            {
                                path: "order/list",
                                element: <RewardOrderList />
                            },
                            {
                                path: "my-applicant",
                                element: <RewardApplicantList />
                            },
                            {
                                path: "my-invitation",
                                element: <RewardInvitationList />
                            },
                            {
                                path: "receive-order",
                                element: <RewardReceiveOrder />
                            },
                            {
                                path: "receive-order/:orderNumber",
                                element: <ReceiveOrderDetail />
                            },
                            {
                                path: "search",
                                element: <RewardSearch />,
                            }
                            
                        ]
                    }
                ]
            },
            {
                path: "answerer",
                element: <ForumLayout value='answerer' />,
                children: [
                    {
                        path: "index",
                        element: <AnswererList />,
                    }
                ]
            },
            {
                path: "reward/detail/:id",
                element: <RewardDetail />
            }
        ]
    }
]