import styled from "styled-components";

export const SecondReplyStyle = styled.div`
    .input-wrap{
        width: 100%;
        margin-top: ${(props) => props.theme.space.margin}px;
        .left-wrap {
            display: flex;
            gap: 15px;
            align-items: center;
        }
        .btn-wrap {
            margin-top: ${(props) => props.theme.space.margin}px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .emoji{
                color: ${(props) => props.theme.colors.colorPrimary};
            }
        }
    }
`