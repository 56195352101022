import { UserDetailStyle } from './style'
import Part1 from './part1';
const UserDetail = () => {
    return <UserDetailStyle>
        <div className="detail">
            <Part1/>
        </div>
    </UserDetailStyle>
}

export default UserDetail