import { RiBilibiliFill, RiCustomerService2Line, RiSmartphoneLine, RiTiktokFill, RiWechatFill, RiWeiboFill } from "react-icons/ri";
import { HomeFooterStyle } from "./style";
import BusinessContact from "components/business-contact";
import { useState } from "react";
import { Button, Modal } from "antd";
import { Tooltip } from 'react-tooltip';



function HomeFooter() {
  const [isShowBusiness, setIsShowBusiness] = useState(false)
  const [modalTitle, setModalTitle] = useState('隐私政策')
  const [wordUrl, setWordUrl] = useState('https://pre-cdn-hz.ggac.com/docx/policy.html')
  const [wordModalShow, setWordModalShow] = useState(false)
  const onClose = () => {
    setIsShowBusiness(false)
  }
  const onClickOpenWordModal = (url: string, title: string) => {
    setWordUrl(url)
    setModalTitle(title)
    setWordModalShow(true)
  }
  return (
    <HomeFooterStyle>
      <div className="container">
        <div className="a">
          <div className="list up">
            <div className="list-item" onClick={() => { window.open('/static/app-download') }}>
              <RiSmartphoneLine
                size={20}
                style={{ paddingBottom: 3, paddingRight: 3 }}
              ></RiSmartphoneLine>
              APP下载
            </div>
            <div className="list-item" onClick={() => onClickOpenWordModal('https://cdn-prd.ggac.com/ggac/assets/html/ggac-user-agreement.html', 'GGAC用户协议及隐私权政策')}>用户协议</div>
            <div className="list-item" onClick={() => onClickOpenWordModal('https://cdn-prd.ggac.com/ggac/assets/html/ggac-user-agreement.html', 'GGAC用户协议及隐私权政策')}>隐私协议</div>
            <div className="list-item">
              上海哈雷绘星网络科技有限公司
            </div>

          </div>
          <div className="list down">
            <div
              className="list-item"
              onClick={() => {
                window.open(
                  "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014065",
                  "_blank"
                );
              }}
            >
              <img
                data-v-1075e518=""
                src="https://cdn.ggac.com/img/netSafeLogo.png"
                alt=""
              />
              &nbsp;&nbsp;沪公网安备 31010402009838号
            </div>
            <div className="list-item">|</div>
            <div
              className="list-item"
              onClick={() => {
                window.open("https://beian.miit.gov.cn/", "_blank");
              }}
            >
              沪ICP备2021016177号-3
            </div>
            <div className="list-item">|</div>
            <div className="list-item">
              Copyright © 2021 - 2023
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="b">
          <div className="list up">
            <div className="list-item">
              <RiCustomerService2Line size={20}
                style={{ paddingBottom: 3, paddingRight: 3 }}></RiCustomerService2Line>
            </div>
            <div className="list-item">商务合作</div>
          </div>
          <div className="list down">
            <div className="list-item"> <span >Email：<span className="mail">vina@ggac.com</span> </span></div>
            <div className="list-item"> &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; </div>
            <div className="list-item contact" onClick={() => window.open("/biz")} > 提交合作信息 </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="c">
          {/* <div className="follow-up">
                <RiContactsLine size={20}></RiContactsLine>
                <span>关注我们</span>
            </div> */}
          <div className="blocks">
            <div className="block" data-tooltip-id="weibo-code">
              <RiWeiboFill className="icon" ></RiWeiboFill>
              <span>微博</span>
            </div>
            <Tooltip id="weibo-code">
              <div style={{ margin: '2px -6px -1px -6px' }}>
                <img src="https://cdn.ggac.com/img/weibo.jpeg" width={120} alt=""></img>
              </div>
            </Tooltip>
            {/* <Tooltip title={<img src="https://cdn.ggac.com/img/wechat.jpeg" width={120}></img>}> */}
            <div className="block" data-tooltip-id="weixin-code">
              <RiWechatFill className="icon"></RiWechatFill>
              <span>微信公众号</span>
            </div>

            <Tooltip id="weixin-code">
              <div style={{ margin: '2px -6px -1px -6px' }}>
                <img src="https://cdn.ggac.com/img/wechat.jpeg" width={120} alt=""></img>
              </div>
            </Tooltip>

            {/* <Tooltip title={<img src="https://cdn.ggac.com/img/douyin.png" width={120}></img>}> */}
            <div className="block" data-tooltip-id="douyin-code">
              <RiTiktokFill className="icon" ></RiTiktokFill>
              <span>抖音</span>
            </div>
            <Tooltip id="douyin-code">
              <div style={{ margin: '2px -6px -1px -6px' }}>
                <img src="https://cdn.ggac.com/img/douyin.png" width={120} alt=""></img>
              </div>
            </Tooltip>
            {/* </Tooltip> */}
            {/* <Tooltip title={<img src="https://cdn-prd.ggac.com/ggac/assets/image/home/bilibili.png" width={120}></img>}> */}
            <div className="block" data-tooltip-id="bilibili-code">
              <RiBilibiliFill className="icon" ></RiBilibiliFill>
              <span>bilibili</span>
            </div>
            <Tooltip id="bilibili-code">
              <div style={{ margin: '2px -6px -2px -6px' }}>
                <img src="https://cdn-prd.ggac.com/ggac/assets/image/home/bilibili.png" width={120} alt=""></img>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <BusinessContact onClose={onClose} isShow={isShowBusiness} />
      <Modal
        centered
        onCancel={() => { setWordModalShow(false) }}
        width="820px"
        open={wordModalShow}
        title={modalTitle}
        footer={<Button type="primary" onClick={() => {
          setWordModalShow(false)
        }}>确认</Button>}
      >
        <div>
          <iframe style={{ width: '750px', height: '700px', border: 'none', marginTop: '10px', marginLeft: '10px' }} src={wordUrl} title="用户协议"></iframe>
        </div>

      </Modal>
    </HomeFooterStyle>
  );
}

export default HomeFooter;
