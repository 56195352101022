import { UserCenterAlbumActionStyle } from "./style";
import { Button, Tooltip } from "antd";
import { RiEyeLine, RiSubtractFill } from "react-icons/ri";
import { WorkListItem } from "types/work";

const UserCenterAlbumAction = ({
  item,
  deleteAlbum,
}: {
  item: WorkListItem;
  deleteAlbum: (item: WorkListItem) => void;
}) => {
  const viewAlbum = (item: WorkListItem) => {
    window.open(`/work/detail/${item.id}`);
  };

  return (
    <UserCenterAlbumActionStyle>
      <div className="action-list">
      <Tooltip title="移除">
        <Button
          size="large"
          shape="circle"
          onClick={() => deleteAlbum && deleteAlbum(item)}
        
        >
           
         <RiSubtractFill style={{marginTop:5}}></RiSubtractFill>
         
        </Button>
        </Tooltip>
        <Tooltip title="查看">
          <Button onClick={() => viewAlbum(item)} size="large" shape="circle">
            <RiEyeLine style={{marginTop:5}}></RiEyeLine>
          </Button>
          </Tooltip>
      </div>
    </UserCenterAlbumActionStyle>
  );
};

export default UserCenterAlbumAction;
