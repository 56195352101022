import { RedeemIndexStyle } from "./style"
import RedeemUserInfo from "./user-info"
import RedeemList from "./redeem-list"
import { useCallback, useEffect, useState } from "react"
import { getWalletDetail } from "api/wallet"
import { getWalletDetailType } from "types/wallet"
import GuanggaoBannerTwo from "components/guanggao-banner-two"

const Redeem = () => {
    const [walletDetail, setWalletDetail] = useState<getWalletDetailType>()
    const getDetail = useCallback(() => {
        getWalletDetail().then(res => {
            setWalletDetail(res)
        })
    }, [])

    useEffect(() => {
        getDetail()
    }, [getDetail])
    return <RedeemIndexStyle>
        <GuanggaoBannerTwo positionCode={8} aspectRatio={10/2}></GuanggaoBannerTwo>
        <RedeemUserInfo walletDetail={walletDetail} />
        <RedeemList updateWalletDetail={getDetail}/>
    </RedeemIndexStyle>
}

export default Redeem