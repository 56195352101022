import { cosConfig, cosCredentials } from "types/common";
import { post, get } from "api";


/**
 * 获取serverCos配置
 * @param params
 */
 export const apiGetCosConfig = async () => await get<any, cosConfig>('/api/v6/nft/admin/cloud_disk/config_get')


 /** COS获取云盘上传临时凭证（过期时间30分钟） */
export const apiGetCosCredential = async () =>
await post<{}, cosCredentials>('/api/cos/get_credential',{})



