import styled from 'styled-components'

export const NftPublishStyle = styled.div`
  .main {
    margin-top: ${(props) => props.theme.space.paddingLG}px;
    padding-bottom: ${(props) => props.theme.space.padding * 2.4}px;
    border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
    background-color: ${(props) => props.theme.colors.colorTextLightSolid};
    border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
    .main-top {
      padding-top: 14px;
      display: flex;
      justify-content: space-between;
      position: relative;
      border-bottom: 1px solid rgb(240, 240, 240);
      padding-right: ${(props) => props.theme.space.padding}px;
      .tabs {
        border-bottom: none;
      }
      .right {
        button {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .main-list {
    padding: ${(props) => props.theme.space.padding}px;
    padding-top: ${(props) => props.theme.space.paddingLG}px;
    .heading {
      background-color: #f1f1f3;
      color: ${(props) => props.theme.colors.colorTextSecondary};
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.5fr;
      text-align: center;
      padding: ${(props) => props.theme.space.paddingXS}px 0;
      padding-left: ${(props) => props.theme.space.paddingLG}px;
    }
    .list {
      .list-item {
        border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
        margin-top: ${(props) => props.theme.space.paddingLG}px;
        border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
        padding-bottom: ${(props) => props.theme.space.paddingLG}px;
        .top {
          padding: ${(props) => props.theme.space.paddingSM}px
            ${(props) => props.theme.space.paddingLG}px;
          border-bottom: 1px solid
            ${(props) => props.theme.colors.colorBorderSecondary};
        }
        .row {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.5fr;
          padding: ${(props) => props.theme.space.paddingLG}px 0;
          padding-left: ${(props) => props.theme.space.paddingLG}px;
          .expand {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: ${(props) => props.theme.colors.colorTextTertiary};
            font-size: 16px;
            cursor: pointer;
            &:hover {
              color: ${(props) => props.theme.colors.colorPrimary};
            }
          }
          .btns {
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
          }

          .row-cell {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .no_ {
              font-size: 20px;
            }
            .refuse {
              display: flex;
              align-content: center;
              gap: 2px;
              &:hover {
                .icon {
                  color: ${(props) => props.theme.colors.colorPrimary};
                }
              }
              .icon {
                color: ${(props) => props.theme.colors.colorError};
                font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
                cursor: pointer;
                padding-top: 3px;
              }
            }
            .on-sale {
              cursor: pointer;
            }
            .cell-card {
              width: 100%;
              display: flex;
              align-items: center;
              gap: ${(props) => props.theme.space.padding}px;
              .title_ {
                word-wrap: break-word;
                width: calc(100% - 80px);
                text-align: left;
                padding-left: 20px;
              }
              .thumbnail {
                width: 80px;
                height: 80px;
                border-radius: ${(props) => props.theme.border.borderRadius}px;
                overflow: hidden;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
`

export const NftSoldStyle = styled.div``
