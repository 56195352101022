import styled from "styled-components";
import { dynamicColumns } from "utils/functions/common";

export const WorkListStyle = styled.div`
    margin: 0 auto;
    .top-bar{
        display: flex;
        justify-content: space-between;
        .segmented {
            .ant-segmented-item-label,.ant-segmented-item-icon {
                display: flex;
                align-items: center;
            }
            .ant-segmented-item-selected {
                color: #fff;
                background-color: ${props=>props.theme.colors.colorPrimary};
            }
        }
        
        .btn{
            display: flex;
            align-items: center;
            background-color: ${(props) => props.theme.colors.colorPrimary};
            color: ${(props) => props.theme.colors.colorTextLightSolid};
            span{
                margin-right: ${(props) => props.theme.space.marginXXS}px;
            }
        }
        .select{
           
        }
    }

    /* @media (max-width: 1600px) and (min-width: 1314px) {
        .list{
            grid-template-columns: repeat(4, 1fr) !important;
        }
    }

    @media (max-width: 1314px) and (min-width: 1050px) {
      .list{
          grid-template-columns: repeat(3, 1fr) !important;
      }
    }
  
    @media (max-width: 1050px) {
      .list{
          grid-template-columns: repeat(3, 1fr) !important;
      }
    } */
    
    .list{
        padding:${(props) => props.theme.space.paddingLG}px 0;
        display: grid;
        //grid-template-columns: repeat(${dynamicColumns()}, 1fr);
        gap:${(props) => props.theme.space.padding}px;
    }
`