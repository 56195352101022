import { post } from "api"
import { WxWebLoginResponse, WxWebRegisterRequest } from "types/wechat"


// 登录
export const apiWeChatLogin = (params: {code: string, appType: number}) =>{
    return  post<{code: string}, WxWebLoginResponse>('/api/wx_user/login', params)
}

// 注册
export const apiWeChatRegister = (params: WxWebRegisterRequest) =>{
    return post<WxWebRegisterRequest, {
        token: string
        isNew: number
    } >('/api/wx_user/register', params)
}
export const apiBindWeixin = (code: string) => {
    return post('/api/wx_user/bind',{code: code, appType: 2})
}


export const apiUnbindWeixin = (appType: number) => {
    return post(`/api/wx_user/unbind/${appType}`)
}