import { useCallback, useState } from "react";
import { ContactInfoStyle } from "./style";
import { publicUserDetailType } from "types/user/detail";
import { useEffect } from "react";
import { useParams } from "react-router";
import { getViewUserHistoryList } from "api/user";
import { UserViewHistoryItem } from "types/user";


function LatestVisits({ userDetail }: { userDetail?: publicUserDetailType }) {
  const [list, setList] = useState<UserViewHistoryItem[]>([])

  const { id } = useParams()

  const getList = useCallback(() => {
    getViewUserHistoryList({
      userId: Number(id),
      pageSize: 8
    }).then(res => {
      setList(res)
    })
  }, [id])

  useEffect(() => {
    getList()
  }, [getList])

  return (
    <ContactInfoStyle>

      
      <div className="block">
        <div className="title">最近访客</div>
        <div className="content">
          <div className="visitor-list">
            {
              list.map((item) => {
                return <div className="list-item" key={item.id} onClick={()=>window.open(`/user/${item.userId}/works`,'_blank')}>
                  <img
                    src={item?.userInfo && item?.userInfo.avatarUrl}
                    alt=""
                    className="avatar"
                  />
                  <div className="user-name" title={item?.userInfo && item?.userInfo.username}>{item?.userInfo && item?.userInfo.username}</div>
                  <div className="time">{item.beautifyTime}</div>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </ContactInfoStyle>
  );
}

export default LatestVisits;
