import styled from "styled-components";

export const WorkIconsStyle = styled.div`

.roundIcon {
  border-radius: 50% 50%;
  width: 25px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
img {
  width: 100%;
  height: 100%;
}
`