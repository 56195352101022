import styled from "styled-components";

export const QuestsStyle = styled.div`
    padding: ${(props) => props.theme.space.paddingLG}px;

    background-color: #fff;
    .profile{
            display: flex;
            align-items: center;
            .info{
                margin-left: ${(props) => props.theme.space.paddingLG}px;
                .level{
                    display: flex;
                    gap: ${(props) => props.theme.space.padding}px;
                    cursor: pointer;
                }
            }
    }
    .quest{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        margin-top: ${(props) => props.theme.space.paddingLG}px;
        
    }
    
    .section-title{
        padding-top: ${(props) => props.theme.space.padding}px;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
    }
    .quest-list{
        padding:  ${(props) => props.theme.space.padding}px ;
        .list-item{
            display: flex;
            align-items: center;
            padding:  ${(props) => props.theme.space.paddingSM}px 0;
            border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorder};
            &:last-child{
                border: none;
            }
            .left_{
                display: grid;
                grid-template-columns: 1fr 2fr ;
                flex:1;
                .reward{
                   
                    .em{
                        color:  ${(props) => props.theme.colors.colorPrimaryText};
                        font-weight:  ${(props) => props.theme.fontSizes.fontWeightHeading4};
                    }
                }
            }
            .right_{
                display: flex;
                align-items: center;
                width: 20%;
                justify-content: flex-end;
            }
        }
    }
`