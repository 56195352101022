import styled from "styled-components";

export const DeliveryOrderStyle = styled.div`
    .btn {
        text-align: center;
    }
    .tip {
        font-size: 12px;
        color: rgba(0,0,0,.5);
        margin: -15px 0 10px;
        margin-left: 80px;
    }
`