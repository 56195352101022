import * as Avatar from "@radix-ui/react-avatar";
import { UiAvatarStyle } from "./style";
import { findUserRole } from "utils/functions/common";
import { Tooltip } from "antd";

type Props = {
  url: string;
  username?: string;
  certifiedRoleId?: number;
  size?: number;
  border?: boolean,
  userRoles?: { //角色列表
    roleId: number //角色Id: 1认证作者, 2签约作者, 3团队账号, 4特邀作者, 5评审团, 6大赛评委
    roleName: string //角色名称
    userId: number //用户ID
  }[]
};
const UiAvatar = ({ url, username, certifiedRoleId, size = 120, userRoles, border = true }: Props) => {
  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
        return 'yellow'

      case 3:
        return 'blue'

      default:
        return 'hide'
    }
  };

  const showUserAll = () => {
    if (!userRoles) {
      return ''
    }
    const result = userRoles.map(item => item.roleName)
    return result.join("、")
  }

  return (
    <UiAvatarStyle size={size}>
      <Avatar.Root className={`AvatarRoot ${border ? 'border' : ''}`} >
        <Avatar.Image className="AvatarImage" src={url} alt="" />
        <Avatar.Fallback className="AvatarFallback" delayMs={600}>
          {username && username.substring(0, 2)}
        </Avatar.Fallback>
        {[5, 6].includes(Number(certifiedRoleId)) &&
          <>
            {
              !!userRoles && <Tooltip
                title={showUserAll}
                placement="bottom"
              >
                <div className={`role orange`}>
                  评
                </div>
              </Tooltip>
            }
            {
              !userRoles && <div className={`role orange`}>
                评
              </div>
            }
          </>
        }

        {![5, 6].includes(Number(certifiedRoleId)) && (
          <Tooltip
            title={findUserRole(Number(certifiedRoleId))?.name}
            placement="bottom"
          >
            <div className={`role ${renderColor(findUserRole(Number(certifiedRoleId))?.value)}`}>
              V
            </div>
          </Tooltip>
        )}
      </Avatar.Root>
    </UiAvatarStyle>
  );
};

export default UiAvatar;
