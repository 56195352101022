import { GlobalSearchPostResponse } from "types/common";
import { MediaIconsStyle } from "./style"
import { RiFileTextLine, RiLiveLine } from "react-icons/ri";
import { MediaType } from "types/enums/media-type";
import { FavoriteDataListItem } from "types/favorite";
import { WorkLikeListsItem } from "types/user/work";

function MediaIconsES({item}:{item: GlobalSearchPostResponse | FavoriteDataListItem | WorkLikeListsItem}) {
  return (
    <MediaIconsStyle>
         {/* 文章类型 */}
         {item.dataTable === "article" && (
            <div className="item" title="文章">
              <RiFileTextLine></RiFileTextLine>{" "}
            </div>
          )}

          {/* 视频图标 */}
          {item.mediaTypeList?.includes(MediaType.video) && (
            <div className="item" title="视频作品">
              <RiLiveLine></RiLiveLine>
            </div>
          )}
           {/* 多图图标 */}
           {item.mediaTypeList?.includes(MediaType.multiImage) && (
            <div className="item" title="多图作品">
             <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/multi-img.svg" alt="" width={17}/>
            </div>
          )}
    </MediaIconsStyle>
  )
}

export default MediaIconsES