import { Button, List } from "antd"
import { WallectInfoStyle } from "./style"
import { useCallback, useEffect, useState } from "react"
import { getRmbFlowList, getWalletDetail } from "api/wallet"
import { WalletRmbFlowListItem, getWalletDetailType } from "types/wallet"
import Withdrawal from "./withdrawal"
import {  RiWalletLine } from "react-icons/ri"

const WallectInfo = () => {
    const [list, setList] = useState<WalletRmbFlowListItem[]>([])
    const [isShow, setIsShow] = useState(false)
    const [wallectDetail, setWallectDetail] = useState<getWalletDetailType>()

    const getList = useCallback(() => {
        getRmbFlowList({}).then(res => {
            setList(res.pageData)
        })
    }, [])

    const getDetail = useCallback(() => {
        getWalletDetail().then(res => {
            setWallectDetail(res)
        })
    }, [])

    useEffect(() => {
        getList()
        getDetail()
    }, [getList, getDetail])

    const toWithdrawal = () => {
        setIsShow(true)
    }

    const updateIsShow = (value: boolean) => {
        setIsShow(value)
    }

    const withdrawalSuccess = () => {
        getList()
        getDetail()
    }

    return <WallectInfoStyle>
        <div className="top">
            <div className="name">
                <RiWalletLine className="icon"></RiWalletLine>
                <span>钱包余额（CNY）：</span><span className="amount">{wallectDetail?.rmbBalance! / 100 || 0}</span></div>
            <Button type="primary" onClick={toWithdrawal}>提现</Button>
        </div>
        <div className="list">
            <div className="title">余额明细</div>

            {
                list.length > 0 && list.map(item => {
                    return <div className="list-item" key={item.id}>
                        <div className="desc">{item.remark}</div>
                        <div className="number">{!!item.amount ? item.amount / 100 : 0}</div>
                        <div className="status">
                            {
                                item.status === 1 ? '执行中' : item.status === 2 ? '成功' : item.status ? '失败' : ''
                            }
                        </div>
                        <div className="date">{item.createTime}</div>
                    </div>
                })
            }
            <List dataSource={list}>
                <List.Item>

                </List.Item>
            </List>
        </div>
        <Withdrawal isShow={isShow} detail={wallectDetail} withdrawalSuccess={withdrawalSuccess} update={updateIsShow} />
    </WallectInfoStyle>
}

export default WallectInfo