import { message, Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import  { useEffect, useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import LoadingIcon from 'ui-lib/loading';


type FileUploadProps = {
    fileType?: string
    limit?: number,
    value?: {
      fileUrl?: string,
      file?: Blob,
    }
    onChange?: (params: {fileUrl: string; file: File | undefined}) => void
    disabled?: boolean
}

export const FileUpload = (props: FileUploadProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    if(props.value?.fileUrl) {
        setFileList([{
          uid: '-1',
          name: '文件',
        }]);
    }
  }, [props.value])
  
  const triggerChange = (changedValue: { fileUrl: string; file: File| undefined }) => {
    props.onChange?.({
      ...props!.value,
      ...changedValue,
    });
  };

    const onRemove = () => {
      setFileList([]);
      triggerChange({
        fileUrl:'',
        file: undefined
      })
    }

    const beforeUpload = (file: File) => {
      setLoading(true)
      let index= file.name.lastIndexOf(".");
      let ext = file.name.substring(index+1);
      let fileType = props.fileType?.split(',')
      if(props.fileType&&!fileType?.includes(ext)) {
        message.error(`上传文件格式须为${props.fileType}`)
        setLoading(false)
        return
      }
      let isLimit =  file.size/ 1024 / 1024 < (props?.limit! ?? 200);
      if (!isLimit) {
        	message.error('图片大小超出限制');
			    setLoading(false)
			return 
    }
      setFileList([{
        uid: '-1',
        name: file.name,
      }]);
      triggerChange({
        fileUrl: file.name,
        file: file
      })
      setLoading(false)
      return false;
    }

    const uploadButton = (
      <div>
        {loading ? <LoadingIcon /> : <RiAddLine />}
        <div style={{ marginTop: 8 , fontSize: '12px' }}>上传文件</div>
      </div>
    );
  

  return (
    <div>
      <Upload
      listType="picture-card"
      fileList={fileList}
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      disabled={props.disabled}
      >
       {fileList.length>0 ? null : uploadButton}
      </Upload>
    </div>
  );
};