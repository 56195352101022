import { HeaderMorphStyle } from "./style";
import Logo from "./logo";
import User from "./user";
import Search from "./search";
import { RiSearchLine } from "react-icons/ri";
import { useState } from "react";
import NavMenuFilter from "views/home/nav-menu/menu";
import { useNavigate } from "react-router";
import { hasToken } from "utils/functions/common";

function MorphHeader({ clickShowLoginModal }: { clickShowLoginModal?: () => void }) {
  const [isShowInput, setIsShowInput] = useState<Boolean>(false);
  let navigate = useNavigate()

  const token = hasToken()

  const hideSearch = () => {
    if (!token && clickShowLoginModal) {
      clickShowLoginModal()
      return
    }
    setIsShowInput(true);
  };
  const onClickGoHome = () => {
    navigate('/home')
  }
  return (
    <HeaderMorphStyle>
      <div className="wrap">
        <div className="header">
          <div className="header-left" onClick={onClickGoHome}>
            <Logo color="black"></Logo>
          </div>

          <div className="header-center">
            {!isShowInput && (
              <>
                <NavMenuFilter></NavMenuFilter>
              </>
            )}
            {isShowInput && <Search setIsShowInput={setIsShowInput}></Search>}
          </div>

          <div className="header-right">
            {!isShowInput && (
              <div className="search-btn" onClick={() => hideSearch()}>
                <RiSearchLine />
              </div>
            )}
            <User />
          </div>
        </div>
      </div>
    </HeaderMorphStyle>
  );
}

export default MorphHeader;
