import { Form,  Modal, Select } from "antd"
import { useEffect, useState } from "react"
import UserSelect from "./userSelect"
import { getAuthorItem } from "types/user"

type Props ={
    isModalOpen: boolean
    handleOk: (user:collabrationUserObj) => void
    handleCancel: () => void
}

export type collabrationUserObj = {
    userId: number
    role: number
    roleText: string
    username: string
    avatarUrl: string
    certifiedRoleId: number
}


function AddModal({isModalOpen, handleOk, handleCancel}: Props) {
    const [form] = Form.useForm();
    const [userObject,setUserObjct] = useState<getAuthorItem>()
useEffect(() => {
  form.resetFields()
},[isModalOpen])
    const onFinish = () => {
        form
          .validateFields()
          .then((values) => {
            //console.log(values)
            userObject &&  handleOk(makeUserList(values,userObject));
          })
    }

    const options = [
        { value: 1, label: '创作者' },
        { value: 2, label: '参与人' },
        { value: 3, label: '导师' },
        { value: 4, label: '原作者' }
      ]

    const makeUserList = (values: any,userObject:getAuthorItem)=>{
      
        return {
            userId:userObject?.userId,
            role:values.userType,
            roleText:values.userType === 1? '创作者': values.userType === 2? '参与人':values.userType === 3? '导师' : "原作者",
            username:userObject?.userInfo.username,
            avatarUrl:userObject?.userInfo.avatarUrl,
            certifiedRoleId:userObject?.userInfo.certifiedRoleId
        }
    }
    
  return (
    <Modal title="添加共同创作人" open={isModalOpen} onOk={onFinish} onCancel={handleCancel}>
          <Form
      form={form}
      name="just-a-name"
      style={{ maxWidth: 600 ,marginTop:20}}
    >
      <Form.Item label="用户昵称" name="userId" rules={[{ required: true }]}>
        <UserSelect onUserObjectChange={(value) => setUserObjct(value)}></UserSelect>
      </Form.Item>
      <Form.Item label="共创身份" name="userType" rules={[{ required: true }]}>
      <Select
      options={options}
    />
      </Form.Item>
      </Form>
  </Modal>
  )
}

export default AddModal