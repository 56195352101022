import { publicUserDetailType } from "types/user/detail";
import {
  determineUserLocation,
  determineUserProfession,
} from "utils/functions/user";

export function renderTagline(userDetail: publicUserDetailType) {
  const profession = determineUserProfession(userDetail);
  const location = determineUserLocation(userDetail);
  return (
    <div className="user-tagline">
      <span>{profession}</span>
      {profession && location && <span style={{ color: "#c4c4c7",padding:"0 4px" }}> | </span>}
      <span>{location}</span>
    </div>
  );
}
