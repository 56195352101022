import { useCallback, useEffect, useState } from "react";
import { CategoryFilterStyle } from "./style";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MallProductCategoryListItem, MallWorktypeListItem } from "types/product";
import { apiGetMallProductListCategory, apiGetMallThemeStyleList, apiGetMallWorkTypeList } from "api/shop";
import { RiArrowLeftSLine, RiArrowRightSLine, RiCloseCircleFill, RiCloseCircleLine } from "react-icons/ri";
import { queryUrl } from "utils/functions/common";
import { useKeenSlider } from "keen-slider/react";
import Swiper from "swiper";
import { hasToken } from "utils/functions/common";

import 'swiper/css';
import { loginPathWithRedirect } from "utils/functions/user";

function CategoryFilter({ clearValue, listOne }: { clearValue: () => void, listOne: MallProductCategoryListItem[] }) {
  const [firstPartOfListOne, setFirstPartOfListOne] = useState<MallProductCategoryListItem[]>([]);
  const [secondPartOfListOne, setSecondPartOfListOne] = useState<MallProductCategoryListItem[]>([]);
  const [listTwo, setListTwo] = useState<MallProductCategoryListItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  const [isCanSwiper, setIsCanSwiper] = useState(false)
  const catalog = new URLSearchParams(window.location.search).get("catalog") || ""
  const workType = new URLSearchParams(window.location.search).get("workType") || ""
  const categoryPid = new URLSearchParams(window.location.search).get("categoryPid") || ""
  const [workTypeList, setWorkTyleList] = useState<MallWorktypeListItem[]>([])
  const [isYueGao, setIsYueGao] = useState(false)
  const [themeStyleList, setThemeStyleList] = useState<{ name: string, id: string }[]>([])
  const themeStyle = new URLSearchParams(window.location.search).get("themeStyle")
  const isFreeFee = new URLSearchParams(window.location.search).get("isFreeFee")
  const isRecommend = new URLSearchParams(window.location.search).get("isRecommend")
  const [pictureAlbumId, setPictureAlbumId] = useState(0)
  const isPictureAlbum = new URLSearchParams(window.location.search).get("isPictureAlbum")
  let shopId = JSON.parse(localStorage.getItem('user_info')!)?.shopId
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    mode: "free",
    initial: 0,
    slides: {
      perView: 9,
      spacing: 10
    },
  });

  useEffect(() => {
    instanceRef && instanceRef.current?.update();
  }, [listOne])

  const [swiper, setSwiper] = useState<any>()

  useEffect(() => {
    if (listOne.length === 0) {
      return
    }
    const res2 = JSON.parse(JSON.stringify(listOne))
    setFirstPartOfListOne(res2.splice(0, 2))
    setSecondPartOfListOne(res2.splice(0, 6))
  }, [listOne])

  useEffect(() => {
    apiGetMallWorkTypeList().then(res => {
      setWorkTyleList(res);
    })
  }, []);

  useEffect(() => {
    apiGetMallThemeStyleList().then(res => {
      const result: { name: string, id: string }[] = JSON.parse(JSON.stringify(res))
      result.forEach((item) => {
        item.id = String(item.id)
      })
      setThemeStyleList(result);
    })
  }, [])

  useEffect(() => {
    const shiwu = listOne.find(item => item.code === 'shiwu')
    if (!!shiwu) {
      const params = {
        type: 3,
        level: 2,
        pid: shiwu.id
      }
      apiGetMallProductListCategory(params).then((res) => {
        const album = res.find(sub => sub.code === 'pictureAlbum')
        if (!!album) {
          setPictureAlbumId(album.id)
        }
      });
    }
  }, [listOne])



  const onClickPid = (detail: MallProductCategoryListItem) => {
    if (detail.code === 'yuegao') {
      navigate("/mall/commission")
      return
    }
    navigate(`?categoryPid=${detail.id}`)
  };

  useEffect(() => {
    initSwiper()
  }, [listTwo, catalog, isFreeFee, isRecommend])


  const initSwiper = () => {
    const swiper = new Swiper(".tags_container", {
      slidesPerView: "auto",
      spaceBetween: 54
    });
    setSwiper(swiper)
  }


  useEffect(() => {
    const categoryPid = searchParams.get("categoryPid")
    const params: any = {
      type: 3,
      level: 2
    }
    if (!!categoryPid) {
      params.pid = categoryPid
      delete params.isRecommand
    } else {
      delete params.pid
      params.isRecommand = 1
    }
    apiGetMallProductListCategory(params).then((res) => setListTwo(res));
  }, [searchParams.get("categoryPid")]);


  const onSecondCategoryClick = (id: number) => {
    searchParams.set("categoryId", id.toString());
    searchParams.set("pageNumber", "1");
    searchParams.delete("catalog");
    setSearchParams(searchParams);
  };

  const getNameAndDesc = () => {
    const pid = searchParams.get("categoryPid");
    const cid = searchParams.get("categoryId");
    if (!pid && !cid) {
      return {
        name: "全部商品",
        desc: "",
      };
    }
    if (pid && !cid) {
      const pItem = listOne.find((item) => item.id === Number(pid));
      return { name: pItem?.name, desc: pItem?.description };
    }
    if (pid && cid) {
      const cItem = listTwo.find((item) => item.id === Number(cid));
      return { name: cItem?.name, desc: cItem?.description };
    }
  };

  const clear = () => {
    clearValue()
    const query = queryUrl({ keyword: "" })
    navigate(`?${query}`)
  }

  const move2 = (type: string) => {
    if (type === "prev") {
      instanceRef.current?.prev();
    }
    if (type === "next") {
      instanceRef.current?.next();
    }
  }

  const move = (flag: string) => {
    if (flag == "prev") {
      swiper.slidePrev();
    } else {
      swiper.slideNext()
    }
  }

  useEffect(() => {
    if (!swiper) {
      return
    }
    setIsCanSwiper(swiper.isBeginning && !swiper.isEnd)
  }, [swiper])

  const checkWorkType = (value: string) => {
    const query = queryUrl({ workType: value, pageNumber: "" })
    navigate(`?${query}`)
  }

  const checkThemeStyle = (value: string) => {
    const query = queryUrl({ themeStyle: value, pageNumber: "" })
    navigate(`?${query}`)
  }

  useEffect(() => {
    const result = listOne.find((item) => {
      return item.code === 'yuegao'
    })
    setIsYueGao(Number(categoryPid) === result?.id)
  }, [listOne, categoryPid])

  const toStore = () => {
    if (!hasToken()) {
      navigate(loginPathWithRedirect())
      return
    }
    if (!!shopId) {
      navigate("/user-center/shop/product/create")
    } else {
      navigate("/user-center/shop/product-list")
    }
  }

  const toYuegao = () => {
    if (!hasToken()) {
      navigate(loginPathWithRedirect())
      return
    }
    if (!!shopId) {
      navigate("/user-center/shop/commission/product/create")
    } else {
      navigate("/user-center/shop/commission/product-list")
    }
  }

  return (
    <CategoryFilterStyle className="filter">
      <div className="category-wrap">
        <div className="category-left">
          <div className="cat-one">
            <div className={
              `cat-one-item ${!searchParams.get("categoryPid") && !searchParams.get("categoryId") && !isFreeFee && !isRecommend && !searchParams.get("catalog") ? "active" : ""}`
            }
              onClick={() => {
                searchParams.delete("categoryPid");
                searchParams.delete("categoryId");
                searchParams.delete("catalog");
                searchParams.delete("isFreeFee");
                searchParams.delete("pageNumber");
                searchParams.delete("isRecommend");
                setSearchParams(searchParams);
              }}
            >
              <img src="https://cdn-prd.ggac.com/ggac/common/shop-category-all.png" className="icon" />
              <div>全部商品</div>
            </div>
            {
              firstPartOfListOne.map(item => {
                return <div key={item.id} onClick={() => onClickPid(item)} className={
                  `cat-one-item ${searchParams.get("categoryPid") === item.id.toString() ? "active" : ""}`
                }>
                  <img src={item.coverUrl} className="icon" />
                  <div>{item.name}</div>
                </div>
              })
            }
          </div>
          <div className="cat-two">
            {
              secondPartOfListOne.map(item => {
                return <div key={item.id} onClick={() => onClickPid(item)} className={
                  `cat-two-item ${searchParams.get("categoryPid") === item.id.toString() ? "active" : ""}`
                }>
                  <div>{item.name}</div>
                </div>
              })
            }

            <div className={
              `cat-two-item ${searchParams.get("categoryId") === String(pictureAlbumId) && !categoryPid ? "active" : ""}`
            } onClick={() => {
              navigate(`?categoryId=${pictureAlbumId}&isPictureAlbum=1`)
            }}>
              <div>画册</div>
            </div>

            <div onClick={() => {
              navigate(`?catalog=1`)
            }} className={
              searchParams.get("catalog") ? "cat-two-item active" : "cat-two-item"
            }>
              <div>数字图鉴</div>
            </div>

            <div onClick={() => {
              navigate(`?isRecommend=1`)
            }} className={
              !!isRecommend && !categoryPid ? "cat-two-item active" : "cat-two-item"
            }>
              <div>热门推荐</div>
            </div>
            <div onClick={() => {
              navigate(`?isFreeFee=1`)
            }} className={
              !!isFreeFee && !categoryPid ? "cat-two-item active" : "cat-two-item"
            }>
              <div>免费</div>
            </div>
          </div>
        </div>
        <div className="category-right">
          <div className="list">
            <div className="list-item" onClick={() => window.open("/mall/guide")}>
              <img src="https://cdn-prd.ggac.com/ggac/common/category-right-icon1.png" className="icon" />
              <div>开店指南</div>
            </div>
            <div className="list-item" onClick={toStore}>
              <img src="https://cdn-prd.ggac.com/ggac/common/category-right-icon2.png" className="icon" />
              <div>发布商品</div>
            </div>
            <div className="list-item" onClick={toYuegao}>
              <div className="circle">
                <img src="https://cdn-prd.ggac.com/ggac/common/market-yuegao-icon.png" className="icon" />
              </div>
              <div>发布约稿</div>
            </div>
          </div>
        </div>
      </div>
      {
        !catalog && !((!!isFreeFee || !!isRecommend || !!isPictureAlbum) && !categoryPid) && <>
          {
            !isYueGao && listTwo.length > 1 && <div className="row-2">
              {
                isCanSwiper && <div className="control prev" onClick={() => move("prev")}>
                  <RiArrowLeftSLine></RiArrowLeftSLine>
                </div>
              }

              <div className="tags_container swiper-container">
                <div className="swiper-wrapper">
                  {listTwo.length > 0 && !isPictureAlbum && (
                    <div
                      className={
                        `${!searchParams.get("categoryId") ? 'active' : ''} tag swiper-slide`
                      }

                      onClick={() => {
                        searchParams.delete("categoryId");
                        searchParams.delete("catalog");
                        setSearchParams(searchParams);
                      }}
                    >
                      全部
                    </div>
                  )}
                  {
                    listTwo.length > 1 && !isPictureAlbum && listTwo.map((item, index) => {
                      return (
                        <div
                          className={
                            `tag swiper-slide ${searchParams.get("categoryId") === item.id.toString() ? 'active' : ''}`
                          }
                          onClick={() => onSecondCategoryClick(item.id)}
                          key={index}
                        >
                          {item.name}
                        </div>
                      );
                    })
                  }
                </div>
              </div>

              {
                isCanSwiper && <div className="control next" onClick={() => move("next")}>
                  <RiArrowRightSLine></RiArrowRightSLine>
                </div>
              }

            </div>
          }
          {
            isYueGao && <>
              <div className="select first-select">
                <div className="row-2">
                  作品类型:
                  <div className={`tag ${!workType ? 'active' : ''}`} onClick={() => checkWorkType("")}>全部</div>
                  {
                    workTypeList.map(item => {
                      return <div onClick={() => checkWorkType(String(item.id))} className={`tag ${Number(workType) === item.id ? 'active' : ''}`} key={item.id}>{item.name}</div>
                    })
                  }
                </div>
              </div>
              <div className="select">
                <div className="row-2">
                  题材风格:
                  <div className={`tag ${!themeStyle ? 'active' : ''}`} onClick={() => checkThemeStyle("")}>全部</div>
                  {
                    themeStyleList.map(item => {
                      return <div onClick={() => checkThemeStyle(String(item.id))} className={`tag ${themeStyle === item.id ? 'active' : ''}`} key={item.id}>{item.name}</div>
                    })
                  }
                </div>
              </div>
            </>
          }

          {
            (!isFreeFee && !categoryPid) && <>
              {!searchParams.get("keyword") && getNameAndDesc()?.desc && (
                <div className="meta">
                  {/* <div className="name">{getNameAndDesc()?.name}</div> */}
                  <div className="desc">{getNameAndDesc()?.desc}</div>
                </div>
              )}
              {searchParams.get("keyword") && <div className="meta">
                <div className="desc">搜索：{searchParams.get("keyword")}  <RiCloseCircleFill size={15} onClick={clear} /></div>

              </div>}
            </>
          }
        </>
      }
    </CategoryFilterStyle>
  );
}

export default CategoryFilter;
