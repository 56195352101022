import { Button, Checkbox, Form, Input, InputNumber, message } from "antd"
import { ToBeAnswererStyle } from "./style"
import { useEffect, useState } from "react";
import { getAnswererDetail, postToBeAnswerer } from "api/forum";



const ToBeAnswerer = ({ saveSuccess }: { saveSuccess: () => void }) => {

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false)

    const [isAgree, setIsAgree] = useState(false)

    const { TextArea } = Input

    const NumberProps = {
        min: '0',//最小值
        max: '9999.99',//最大值
        stringMode: true,//字符值模式，开启后支持高精度小数
        step: '0.01',//小数位数
        precision: 2,
    };


    useEffect(() => {
        getAnswererDetail().then(res => {
            if (!!res) {
                const { briefIntroduction, wage } = res
                form.setFieldsValue({
                    briefIntroduction,
                    wage
                })
            }
        })

    }, [])

    const save = () => {
        form.validateFields().then(async (formRes) => {
            const { briefIntroduction, wage } = formRes
            if (!isAgree) {
                message.warning("请阅读并同意用户协议")
                return
            }
            setIsLoading(true)
            postToBeAnswerer({
                briefIntroduction,
                wage
            }).then(() => {
                saveSuccess()
                message.success("提交成功")
            }).finally(() => {
                setIsLoading(false)
            })
        })
    }

    return <ToBeAnswererStyle>
        <Form
            form={form}
            style={{ marginTop: 20 }}

        >
            <div style={{ display: "flex" }}>
                <Form.Item
                    name="wage"
                    label='咨询价格'
                    rules={[{ required: true, message: "请输入咨询价格" }]}
                >
                    <InputNumber {...NumberProps} placeholder="请输入咨询价格" />
                </Form.Item>
                <div className="price-tip">元/次</div>
            </div>
            <Form.Item
                name="briefIntroduction"
                label='个人简介'
                className="briefIntroduction"
            >
                <TextArea placeholder="请输入个人简介" maxLength={100} />
            </Form.Item>
        </Form>
        <div className="agreement">
            <Checkbox checked={isAgree} onChange={() => setIsAgree(!isAgree)}>阅读并同意用户协议</Checkbox>
        </div>
        <div className="btn">
            <Button type="primary" onClick={save} loading={isLoading}>保存</Button>
        </div>
    </ToBeAnswererStyle >
}

export default ToBeAnswerer