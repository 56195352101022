import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'types/enums/config-language-type'
import {useGRoutes} from 'utils/hooks/useGRoute'
import { store } from 'utils/store'
import {AboutUsStyle} from './style'
import {ConfigContext} from '../../../App'
import { ThemeType } from 'types/enums/config-theme-type'
import {apiGetProductList} from 'api/product/index'
import {DEF_ENVVAR_REACT_APP_COS_BUCKET} from 'utils/vars/const-envvar'


export const AboutUs = () =>{
console.log(DEF_ENVVAR_REACT_APP_COS_BUCKET);
        const { t } = useTranslation()
        const  gRouter = useGRoutes()
        const  onClickLogin = () =>{
                gRouter('/v2/login')
              }
        const onClickMatch =  () =>{
                gRouter('/commodity/create')
        }

         const ConfigObj = useContext(ConfigContext)

        const onChangeLanguageEN = () =>{
                ConfigObj.changeLanguage(LanguageType.en_GB)
        }
        const onChangeLanguageZH = () =>{
                ConfigObj.changeLanguage(LanguageType.zh_CN)
        }

        const onChangeThemeDark = () =>{
                ConfigObj.changeTheme(ThemeType.dark)
        }

        const onChangeThemeLight = () =>{
                ConfigObj.changeTheme(ThemeType.light)

        }

        const onClickGetGoodList = () =>{
                apiGetProductList({
                        pageNumber: 1,
                        pageSize:10
                })
        }
        
    
        return <AboutUsStyle>
                     <div className='container'>
                        <h1>{t('header.register')}</h1>
                        <div onClick={onClickLogin}>login</div>
                        <div  onClick={onClickMatch}>match</div>
                        <div>当前语言:{store.getState().config.language}</div>
                        <div onClick={onChangeLanguageEN}>切换英语</div>
                        <div onClick={onChangeLanguageZH}>切换中文</div>

                        <div onClick={onChangeThemeDark}>切换暗黑主题</div>
                        <div onClick={onChangeThemeLight}>切换明亮主题</div>
                        <div onClick={onClickGetGoodList}>获取列表</div>
                        <div></div>
                     </div>
                </AboutUsStyle>
}