import { Button, Checkbox, Col,  Input, Modal, Row, Space, message } from "antd";
import { ApiCreateRefund } from "api/shop";
import { useState } from "react";
import { OrderListResponse } from "types/product";

type Props = {
  visible: boolean;
  onSubmit: () => void;
  onClose: () => void;
  detail: OrderListResponse
};

function RedundApplyModal({ visible, onSubmit, onClose ,detail}: Props) {
  const [loading, setLoading] = useState(false);
  const [refundReason,setRefundReason] = useState('')
  const [other,setOther] = useState('')
  const handleCheckChange = (values:any) => {
   let res= values.map((item: string) =>{
      if(item ==='其他'){
        return '其他原因:'+ other
      }
      return item
    })
    setRefundReason(res.join(','))
  };

  const handleSubmit = () => {
    if(refundReason ===''){
      message.error('请填写至少一个退款理由！')
      return
    }
   
    setLoading(true);
    ApiCreateRefund({
        orderNumber:detail.orderNumber,refundReason:refundReason
    }).then(() => {
      message.success("申请成功");
      onSubmit();
      onClose();
      setLoading(false);
    }).finally(() => {
        setLoading(false);
    })
  }

  return (
    <div>
      <Modal
        className="modal"
        title="请选择退款原因"
        onCancel={onClose}
        centered={true}
        footer={null}
        getContainer={false}
        open={visible}
        destroyOnClose
      >
        <div className="container">
          <Checkbox.Group onChange={handleCheckChange}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Checkbox value="不喜欢">不喜欢</Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="看到更好的商品">看到更好的商品</Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="比别的平台卖得贵">比别的平台卖得贵</Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="发货慢">发货慢</Checkbox>
              </Col>
              <Col span={24}>
                <Space>
                <Checkbox value="其他">其他</Checkbox><Input placeholder="请输入理由" value={other} onChange={(e)=>setOther(e.target.value)}/>
                </Space>
              </Col>
            </Row>
          </Checkbox.Group>
          <div className="btn" style={{marginTop: '24px'}}>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              提交
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RedundApplyModal;
