import { theme } from 'antd';
const { useToken } = theme;

export const UseBorder = () => {

    const { token } = useToken();

    // return {
    //     borderRadius: token.borderRadius+4, //6+4
    //     borderRadiusSM: token.borderRadiusSM+4, //4+
    //     borderRadiusLG: token.borderRadiusLG+4,//8+4
    //     borderRadiusXS: token.borderRadiusXS+4,//2+4
    // }
 
    return {
        borderRadius: token.borderRadius, //6+4
        borderRadiusSM: token.borderRadius, //4+
        borderRadiusLG: token.borderRadiusLG,//8+4
        borderRadiusXS: token.borderRadiusXS,//2+4
    }
}