import { useCallback, useEffect, useState } from "react"
import { RewardReceiveOrderStyle } from "./style"
import { getAnswererDetail, postGetReceiveOrderList } from "api/forum"
import CommonTabs from "ui-lib/tab/common-tab"
import { forumReceiveOrderStatusList } from "views/forum/config"
import { useLocation, useNavigate } from "react-router-dom"
import { ForumSellerOrderListItem } from "api/forum/type"
import { NoData } from "components/no-data"
import { Modal, Pagination, Spin } from "antd"
import { queryUrl } from "utils/functions/common"
import DeliverReward from "../../components/deliverReward"
import ToBeAnswerer from "../../components/toBeAnswerer"
import UISegmentedControl from "ui-lib/segmented-control"

const RewardReceiveOrder = () => {

    const state = new URLSearchParams(useLocation().search).get("state") || '1,2,3,4'
    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const pageSize = 10
    const [list, setList] = useState<ForumSellerOrderListItem[]>([])
    const [totalSize, setTotalSize] = useState(0)
    const [loading, setLoading] = useState(false)
    const [orderNumber, setOrderNumber] = useState("")

    const [isShowDeliver, setIsShowDeliver] = useState(false)

    const navigate = useNavigate()

    const [isShowAnswerer, setIsShowAnswerer] = useState(false)

    const getList = useCallback(() => {
        postGetReceiveOrderList({ state, pageNumber: Number(pageNumber), pageSize }).then(res => {
            setLoading(true)
            setList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [state, pageNumber])

    useEffect(() => {
        getList()
    }, [getList])

    const checkTab = (detail: { title: string, key: string | number }) => {
        navigate(`?${queryUrl({ state: detail.key, pageNumber: 0 })}`)
    }

    const changePage = (e: number) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const toOrderDetail = (orderNumber: string) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(`/forum/reward/receive-order/${orderNumber}`)
    }

    const toDeliver = (orderNumber: string) => {
        getAnswererDetail().then(res => {
            setOrderNumber(orderNumber)
            if (!res) {
                setIsShowAnswerer(true)
            } else {
                setIsShowAnswerer(false)
                setIsShowDeliver(true)
            }
        })
    }

    const saveSuccess = () => {
        setIsShowAnswerer(false)
        // setIsShowDeliver(true)
        window.location.href = window.location.href
    }

    const link = () => {
        navigate(`/forum/reward/index`, { replace: true })
    }

    const tabOption = [
        {
            label: "付款订单",
            value: "pay",
        },
        {
            label: "收款订单",
            value: "collection",
        },
    ];

    const changeWorkType = (value: any) => {
        if (value === 'pay') {
            navigate(`/forum/reward/order/list`)
        }
    }

    return <RewardReceiveOrderStyle>
        <div className="list">
            <div className="list-title">
                <div className="link" onClick={() => link()}>
                    返回 悬赏主页
                </div>
                <span>{`>`}</span>我的订单
            </div>
        </div>

        <div className="tab-wrap">
            <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={'collection'}></UISegmentedControl>
        </div>

        <CommonTabs
            tabs={forumReceiveOrderStatusList}
            onClick={(item) => checkTab(item)}
            defaultKey={state}
        ></CommonTabs>
        {
            list.length > 0 && <>
                <Spin spinning={loading}>
                    <div className="list">
                        {
                            list.map(item => {
                                return <div className="list-item" key={item.id}>
                                    <div className="order">
                                        <div className="orderNumber">
                                            订单编号：{item.orderNumber}
                                        </div>
                                        <div className="word" onClick={() => toOrderDetail(item.orderNumber)}>订单详情 {`>`}</div>
                                    </div>
                                    <div className="topic">
                                        <div className="topic-title" onClick={() => window.open(`/forum/reward/detail/${item.topicId}`)}>
                                            {item.topicTitle}
                                        </div>
                                        <div className="status">
                                            {
                                                item.state === 1
                                                    ? "待回答"
                                                    : item.state === 2
                                                        ? "待确认"
                                                        : item.state === 3
                                                            ? "已完成"
                                                            : item.state === 4
                                                                ? "已取消"
                                                                : ""
                                            }
                                        </div>
                                    </div>

                                    <div className="price-wrap">
                                        <div className="price">
                                            <div className="price-icon"></div>
                                            赏金￥{item.amount}
                                        </div>
                                        {
                                            [1, 2].includes(item.state) && <div className="btn" onClick={() => toDeliver(item.orderNumber)}>
                                                {item.state === 1 ? '立即回答' : "编辑回答"}
                                            </div>
                                        }

                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Spin>
                <Pagination
                    style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                    hideOnSinglePage={true}
                    current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                    onChange={changePage}
                    pageSize={pageSize}
                    total={totalSize}
                    showSizeChanger={false}
                    size="default"
                />
                <Modal className='modal' title="提交答案" centered={true} footer={null} destroyOnClose={true} getContainer={false} onCancel={() => setIsShowDeliver(false)} open={isShowDeliver}>
                    <DeliverReward orderNumber={orderNumber} close={() => setIsShowDeliver(false)} listOnFlush={() => navigate(`/forum/reward/receive-order?state=2`)}></DeliverReward>
                </Modal>

                <Modal getContainer={false} onCancel={() => setIsShowAnswerer(false)} title='成为答主' footer={<></>} width={650} destroyOnClose={true} centered={true} open={isShowAnswerer}>
                    <ToBeAnswerer saveSuccess={saveSuccess} />
                </Modal>

            </>
        }

        {
            list.length === 0 && <NoData />
        }
    </RewardReceiveOrderStyle >
}

export default RewardReceiveOrder