import { Button, message } from "antd";
import { SuperInteractionStyle } from "./style";
import { UserBaseInfo } from "types/user";
import { useState } from "react";
import { postToRecommendWork } from "api/work";
import { ArticleDetailResponse } from "types/article";

type Props = {
  detail: ArticleDetailResponse;
  updateDetail: (detail: ArticleDetailResponse) => void;
};
function SuperInteraction({ detail, updateDetail }: Props) {
  const [recommendLoading, setRecommedLoading] = useState(false);
  let userBaseInfo: UserBaseInfo = JSON.parse(
    localStorage.getItem("user_info")!
  );

  const toRecommend = () => {
    setRecommedLoading(true);
    postToRecommendWork({
      dataId: detail.id,
      dataTable: "article",
      userId: userBaseInfo.id,
    })
      .then(() => {
        detail.isRecommend = 1;
        updateDetail(detail);
        message.success("推荐成功");
      })
      .finally(() => {
        setRecommedLoading(false);
      });
  };
  return (
    <SuperInteractionStyle>
      {userBaseInfo && userBaseInfo.permissions.includes("workRecommend") && (
        <Button
          disabled={detail.isRecommend === 1}
          loading={recommendLoading}
          type="primary"
          onClick={toRecommend}
        >
          {detail && detail.isRecommend === 1 ? "已推荐" : "推荐"}
        </Button>
      )}
    </SuperInteractionStyle>
  );
}

export default SuperInteraction;
