import styled from "styled-components";

export const ProductReviewListStyle = styled.div`

  .more {
    color: ${props => props.theme.colors.colorPrimary};
    text-align: center;
    cursor: pointer;
  }
  
  .row {
    display: flex;
    gap:${(props) => props.theme.space.padding}px;
    margin-bottom: ${(props) => props.theme.space.paddingLG}px;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-size: cover;
    }
    .right-part {
        flex: 1;
        display: flex;
        flex-direction:column;
        gap: ${(props) => props.theme.space.paddingXS}px;
      .up {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .rating{
            color: ${(props) => props.theme.colors.colorTextTertiary};
            font-size:  ${(props) => props.theme.fontSizes.fontSizeSM}px;
            display: flex;
            align-items: center;
            transform: translateY(-2px);
            .label{
                padding-right: ${(props) => props.theme.space.padding}px;
                padding-top: 4px;
            }
        }
      }
      .middle {
        font-size:  ${(props) => props.theme.fontSizes.fontSizeSM}px;
      }
      .pictures {
        display: flex;
        gap: 15px;
        .item {
          width: 50px;
          aspect-ratio: 1;
          background-size: cover;
          border-radius: ${(props) => props.theme.border.borderRadius}px;
        }
        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }
      }
      .bottom {
        font-size:  ${(props) => props.theme.fontSizes.fontSizeSM}px;
        display: flex;
        gap:  ${(props) => props.theme.space.padding}px;
        color: ${(props) => props.theme.colors.colorTextTertiary};
      }
      .reply {
        cursor: pointer;
      }
      .active {
        color: ${(props) => props.theme.colors.colorPrimary};
      }
    }
  }
`;
