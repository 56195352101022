import styled from "styled-components";

export const PopoverContentStyle = styled.div`
      padding: ${props => props.theme.space.paddingXS}px;
      .tag {
        margin-top: ${props => props.theme.space.padding}px;
        height: 400px;
        overflow: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        ::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
        &-item {
            width: 100%;
            height: 40px;
            border-radius: 3px;
            transition:all 1s;
            cursor: pointer;
            position: relative;
            margin-bottom: ${props => props.theme.space.marginXS}px;
            border-radius:  ${props => props.theme.border.borderRadius}px;
            overflow: hidden;
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                .title {
                    background:rgba(68,68,68,.66);
                }
            }
            .title {
                background: rgba(0,0,0,.66);
                color: #fff;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
                top: 0;
            }
            .no-check {
                &:hover {
                    svg:first-child {
                        display: none;
                    }
                    svg:last-child {
                        display: block;
                    }
                }
                svg:first-child {
                    display: block;
                }
                svg:last-child {
                    display: none;
                }
            }
            .check {
                &:hover {
                    svg:first-child {
                        display: none;
                    }
                    svg:last-child {
                        display: block;
                    }
                }
                svg:first-child {
                    display: block;
                }
                svg:last-child {
                    display: none;
                }
            }
            .tag-icon{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                display: flex;
                align-items: center;
                z-index: 10;
                font-size: 20px;
            }
            .check {
                color: #5BB75B !important;
            }
        }
      }
`