import styled from "styled-components";

export const ContestLayoutStyle = styled.div`
    display: flex;
    flex-direction: column;
    .float {
      position: fixed;
      top: 100px;
      right: 0;
      img {
        width: 239px;
        cursor: pointer;
      }
    }
    .modal {
        .ant-modal-content {
          width: 1297px;
          height: 795px;
          padding-top: 300px;
          background: url("https://cdn-prd.ggac.com/ggac/submatch/sims/sims-popup2.png") center center no-repeat;
          background-size: 100% 100%; 
          box-shadow: none;
      }
    }
`