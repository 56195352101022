import styled from "styled-components";

export const UiTabStyle = styled.div`
border-bottom: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
margin-bottom:  ${(props) => props.theme.space.paddingLG}px;
.tabs{
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0 20px;
    .tab{
        color: #8F8F8F;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        padding:  ${(props) => props.theme.space.paddingXXS}px ${(props) => props.theme.space.padding}px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding-bottom: 17px;
        &:hover {
        color: ${(props) => props.theme.colors.colorText} !important;
      }
        &.active{
            border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
            color:${(props) => props.theme.colors.colorText};
        }
    }
}

`