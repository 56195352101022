import React, { useEffect, useState } from 'react'
import { SearchInputStyle } from './style'
import { Input, message } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

function RewardSearchInput() {
  const keyword = new URLSearchParams(useLocation().search).get("keyword") || ""
  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue(keyword || '')
  }, [keyword])

  const search = () => {
    if (!searchValue) {
      message.warning("请输入关键字")
      return
    }
    navigate('/forum/reward/search?keyword=' + searchValue)
  }



  return (
    <SearchInputStyle className='SearchInputStyle'>
      <Input placeholder="请输入搜索关键词" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} allowClear
        onPressEnter={() => search()}
      />
    </SearchInputStyle>
  )
}

export default RewardSearchInput