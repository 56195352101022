import { useState } from "react";
import { UserBillboardStyle } from "./style";
import { RankingAuthorItem, RankingPeriodListItem } from "types/billboard";
import SideBar from "../sidebar";
import RankingHeader from "../header";
import ExtraInfo from "../extra-info-user";
import { getRankingAuthorList } from "api/billboard";
import { Button, message } from "antd";
import UiAvatar from "ui-lib/avatar";
import { postFollowUser } from "api/user";
import { prettifyNumber } from "utils/functions/common";
import { ImageObjectCloudResize } from "utils/functions/file";
import { NoData } from "components/no-data";

export const UserBillboard = () => {
  const [dataList, setDataList] = useState<RankingAuthorItem[]>([]);
  const [openedItemId, setOpenedItemId] = useState<number>(0);
  const [focusLoading, setFocusLoading] = useState<boolean>(false)

  const fetchDataList = (rankingId: number) => {
    let params = {
      rankingId: rankingId,
      pageSize: 20,
    };
    getRankingAuthorList(params).then((res) => {
      setDataList(res);
    });
  };

  const onClickGoDetail = (item: RankingAuthorItem) => {
    window.open(`/user/${item.userId}/works`);
  };

  const handlePeriodChange = (item: RankingPeriodListItem | undefined) => {

    if (item) {
      fetchDataList(item.id);
    } else {
      setDataList([])
    }
  };

  // const hanldeShowExtraInfo =(e:any, item:RankingAuthorItem)=>{
  //   e.stopPropagation()
  //   if(item.id === openedItemId){
  //     setOpenedItemId(0)
  //   }
  //   if(openedItemId === 0){
  //     setOpenedItemId(item.id)
  //   }

  //   if(openedItemId > 0 && item.id !== openedItemId){
  //     setOpenedItemId(item.id)
  //   }
  // }  

  const focusUser = (detail: RankingAuthorItem, index: number, e: any) => {
    e.stopPropagation()
    setFocusLoading(true)
    const flag = detail?.isFollow === 1 ? 0 : 1
    postFollowUser(
      {
        followee: detail?.userId!,
        flag
      }
    ).then(() => {
      message.success("操作成功")
      if (detail) {
        detail.isFollow = flag
      }
      dataList[index].isFollow = flag
      setDataList([...dataList])
    }).finally(() => {
      setFocusLoading(false)
    })
  }

  return (
    <UserBillboardStyle>
      <div className="billboard-container">
        <div style={{ width: "280px" }}>
          <SideBar></SideBar>
        </div>
        <div className="main">
          <RankingHeader
            onCurrentPeriodChange={handlePeriodChange}
          ></RankingHeader>
          {
            dataList.length === 0 && <div className="no-data"><NoData /></div>
          }
          {dataList.length > 0 &&
            dataList.map((item, index) => {
              return (
                <div className="list-item" key={item.id}>
                  <div className="rank-num">
                    {item.top === 1 && <img src='https://cdn-prd.ggac.com/ggac/assets/image/ranking/top1.png' width={90} alt=""></img>}
                    {item.top === 2 && <img src='https://cdn-prd.ggac.com/ggac/assets/image/ranking/top2.png' width={90} alt=""></img>}
                    {item.top === 3 && <img src='https://cdn-prd.ggac.com/ggac/assets/image/ranking/top3.png' width={90} alt=""></img>}
                    {item.top > 3 && <h1>{item.top}</h1>}

                  </div>
                  <div className="right-part">

                    <div
                      className="upper-info"
                      onClick={() => onClickGoDetail(item)}
                    >
                      <div
                        className="avatar"
                      >
                        <UiAvatar url={item?.userInfo?.avatarUrl} username={item?.userInfo?.username}></UiAvatar>
                      </div>
                      <div className="main-info">
                        <div className="wrapper">
                          <div className="user-info">
                            <div className="upper">
                              <div className="title" >{item?.userInfo?.username}</div>
                              {/* <div className="meta-info">
                            <div className="category">{item.userInfo.id}</div>
                          </div> */}
                            </div>

                            <div className="down">
                              {item.isFollow === 1 && <Button onClick={(e) => focusUser(item, index, e)} className="btn defaultBtn" >已关注</Button>}
                              {item.isFollow === 0 && <Button onClick={(e) => focusUser(item, index, e)} className="btn" type="primary" >关注</Button>}
                            </div>
                          </div>
                          <div className="work-list">
                            {item.workList.slice(0, 3).map((work, index) => {
                              return <div className="list-item_" key={index} style={{ backgroundImage: `url(${ImageObjectCloudResize(work.coverUrl, 500)})` }} onClick={(e) => {
                                e.stopPropagation();
                                window.open(`/work/detail/${work.id}`)
                              }}></div>
                            })}
                          </div>
                        </div>
                        <div className="right">
                          <div>{prettifyNumber(item.incHot)}</div>
                          {/* <div className="show-extra" onClick={(e)=>hanldeShowExtraInfo(e,item)}>
                          <span> 数据信息</span> 
                          {openedItemId ===item.id &&  <RiArrowUpSLine className="icon"></RiArrowUpSLine>}
                          {openedItemId !=item.id &&  <RiArrowDownSLine className="icon"></RiArrowDownSLine>}
                         
                        </div> */}
                        </div>
                      </div>
                    </div>
                    {openedItemId === item.id && <ExtraInfo item={item}></ExtraInfo>}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </UserBillboardStyle>
  );
};
