import { TopicListItemStyle } from "./style";

import { ForumTopicListItem } from "api/forum/type";
import { useState } from "react";
import UiTag from "ui-lib/tag";
import ReplyItem from "../reply/reply-item";
import { extractPathnameAndSearchParams } from "utils/functions/common";
import { Button, Tag } from "antd";
import { useSearchParams } from "react-router-dom";

type Props = {
  listItem: ForumTopicListItem;
  onListItemChange: (item: ForumTopicListItem) => void;
  onCommentCountChange: () => void;
  onLaunchReply: (topicId: number) => void;
  needRefresh: () => void;
};

function TopicListItem({ listItem, onCommentCountChange, onLaunchReply, needRefresh }: Props) {
  const [showComment, setShowComment] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type")
  return (
    <TopicListItemStyle>
      <div className="top-row" onClick={() => window.location.href = `/forum/question/topic/${listItem.id}?back=${extractPathnameAndSearchParams(window.location.href)}`}>
        <div className="fjs">

          <div className="fjs-content">
            {
              listItem.isTop === 1 && type === 'new' && <Tag className="tag" color="#EA4332">置顶</Tag>
            }
            {
              listItem.isRecommend === 1 && <Tag className="tag" color="#F2731D">精</Tag>
            }
            <a href={`/forum/question/topic/${listItem.id}?back=${extractPathnameAndSearchParams(window.location.href)}`} className="title overflow-hidden" title={listItem.title} >
              {listItem.title}
            </a>
          </div>
          <Button onClick={(e) => {
            e.stopPropagation();
            onLaunchReply(listItem.id)
          }


          }>写回复</Button>
        </div>


        <div className="reply-count">{listItem.replyCount > 1 ? '查看全部回复（' + listItem.replyCount + '条）' : ''}</div>
      </div>

      {listItem.reply && (
        < div onClick={() => window.location.href = `/forum/question/topic/${listItem.id}?back=${extractPathnameAndSearchParams(window.location.href)}`}>
          <ReplyItem
            listItem={listItem.reply}
            onEdit={() => {
              needRefresh();
            }}
            onListItemChange={() => {
              needRefresh();
            }}
            onDeleteSuccess={() => {
              needRefresh();
            }}
            onCommentCountChange={() => {
              onCommentCountChange();
            }}
            usedInOtherList={true}
            onShowComment={(show) => {
              setShowComment(show);
            }}
            interactionMarginBottom={listItem?.tagList.length > 0 ? 14 : 0}
            showHotIcon
          ></ReplyItem>

        </div>
      )}
      {!showComment && listItem?.tagList.length > 0 && (
        <div className="bottom-row" style={{ marginTop: listItem.reply ? 0 : 12 }}>
          <div className="tag-list">
            {listItem?.tagList.map((tag, index) => <UiTag clickable theme="yellow-3" key={index} onClick={() => { window.location.href = `/forum/question/search?tagName=${tag.name}` }}>{tag.name}</UiTag>)}
          </div>
          {/* <Button type="primary" className="btn" onClick={()=>onLaunchReply(listItem.id)}>我来回答</Button> */}
        </div>
      )}
    </TopicListItemStyle>
  );
}

export default TopicListItem;
