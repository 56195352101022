import styled from "styled-components"


export const HistoryListStyle = styled.div`

    .title_ {
        font-size: 16px;
        padding-bottom: ${props => props.theme.space.margin}px;
    }
    .no-data {
        margin-top: 200px;
    }
    .list {
       
        display: grid;
        gap: ${(props) => props.theme.space.padding}px;
    }
`