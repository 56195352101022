import { CardTitleStyle } from "./style"
import { ArticleListItem } from "types/article"

function CardTitleArticleRender({item}:{item: (ArticleListItem )}) {

  return (
    <CardTitleStyle>
        <>
        {
          (item.status === 2 && item.onSale === 1) &&<a className="overflow-hidden" href={`/article/detail/${item.id}`} target="_blank" rel="noreferrer">{item.title}</a>
        }
        {
          (item.status !== 2 || item.onSale === 0) &&<span className="overflow-hidden">{item.title}</span>
        }
        </>
    </CardTitleStyle>
  )
}

export default CardTitleArticleRender