import { get, post } from "api"
import { FavourWorkRequest, GetFavourListRequest, GetFavourListResponse, workUserLikeItem, ReasonItem, ReportRequest } from "types/common/favour"
import { PaginationResponse } from "types/common/axios"

// 点赞
export const favourWork = (params: FavourWorkRequest) => {
    return post<FavourWorkRequest, never>('/api/zan/flag', params)
}

// 喜欢
export const postLikeWork = (params: FavourWorkRequest) =>
    post<FavourWorkRequest, never>('/api/like/flag', params)


// 点赞列表
export const getFavourList = (params: GetFavourListRequest) => {
    return get<GetFavourListRequest, GetFavourListResponse>('/api/favor/list', params)
}

// 作品喜欢列表返回用户
export const getUserLikeList = (params: GetFavourListRequest) => {
    return get<GetFavourListRequest, PaginationResponse<workUserLikeItem>>('/api/like/user_list', params)
}

// 举报评论
export const getReportResonList = () => {
    return get<{}, ReasonItem[]>(`/api/cfg/report`)
}

// 举报
export const postReport = (params: ReportRequest) =>{
    return post<ReportRequest, never>(`/api/report/create`,params)
}

