import { useNavigate } from "react-router-dom"
import { XnfwWorkRankStyle } from "./style"
import { workJosn } from './work'

const XnfwWorkRank = () => {
    const navigate = useNavigate()
    const toDetail = (value: string) => {
        navigate(value)
    }

    const toWorkDetail = (id: number) => {
        window.open(`/work/detail/${id}`)
    }

    const toLink = (value: string) => {
        window.open(value)
    }

    return <XnfwWorkRankStyle>
        <div className="cover">
            <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-work-kv.png" alt="" />
        </div>
        <div className="content">
            <div className="tab">
                <div onClick={() => toDetail("/events/custom/xnwf/work")} className={`${window.location.pathname === '/events/custom/xnwf/work' ? 'active' : ''} tab-item`}>年度作品榜单</div>
                <div onClick={() => toDetail("/events/custom/xnwf/user")} className={`${window.location.pathname === '/events/custom/xnwf/user' ? 'active' : ''} tab-item`}>年度用户榜单</div>
            </div>
            <div className="inner">
                <img className="dw dw1" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw1.png" />
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-title1.png" />
                </div>
                <div className="work work1">
                    {
                        workJosn[0].map((item) => {
                            return <div onClick={() => toWorkDetail(item.id)} className="work-item" key={item.id} style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                        })
                    }
                </div>
                <div className="tip" >因<span onClick={() => toLink("https://www.ggac.com/contest/czwj/workprize")}>《剑网3》</span>、<span onClick={() => toLink("https://www.ggac.com/v2/sub/yjwj/workprize")}>《永劫无间》</span>主题赛事过于火热，其榜单作品不再一一呈现，大家可前往对应赛事页面欣赏作品。</div>
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-title2.png" />
                </div>

                <div className="work work2">
                    <img className="dw dw2" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw2.png" />
                    {
                        workJosn[1].map((item) => {
                            return <div onClick={() => toWorkDetail(item.id)} className="work-item" key={item.id} style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                        })
                    }
                </div>
                <div className="work-wrap">
                    <img className="dw dw3" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw3.png" />
                    <div className="title title1">
                        <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-title3.png" />
                    </div>
                    <div className="work work3">
                        {
                            workJosn[2].map((item) => {
                                return <div onClick={() => toWorkDetail(item.id)} className="work-item" key={item.id} style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                            })
                        }
                    </div>
                </div>
                <div className="work-wrap">

                    <div className="title title1">
                        <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-title4.png" />
                    </div>
                    <div className="work work4">
                        <img className="dw dw4" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw4.png" />
                        {
                            workJosn[3].map((item) => {
                                return <div onClick={() => toWorkDetail(item.id)} className="work-item" key={item.id} style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                            })
                        }
                    </div>
                </div>
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-title5.png" />
                </div>
                <div className="work work5">
                    <img className="dw dw5" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw5.png" />
                    {
                        workJosn[4].map((item) => {
                            return <div onClick={() => toWorkDetail(item.id)} className="work-item" key={item.id} style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                        })
                    }
                </div>
                {/* <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-title6.png" />
                </div>
                <div className="work work1">
                    <img className="dw dw6" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw6.png" />
                    {
                        Array.from(Array(30), (v, k) => k).map((item) => {
                            return <div className="work-item"></div>
                        })
                    }
                </div> */}
                <div className="bottom">
                    <div className="code">
                        <div className="code-item">
                            <div className="code-tip">GGAC 2023个人年度报告</div>
                            <img
                                src="https://cdn-prd.ggac.com/settings/bg/year_report/qr_code.png"
                            />
                            <div className="code-word">扫一扫，查看我的年度报告！</div>
                        </div>
                    </div>

                    <img className="dw dw7" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw7.png" />
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-bottom.png" alt="" />
                </div>
            </div>
        </div>
    </XnfwWorkRankStyle >
}

export default XnfwWorkRank