import { DataItem } from "components/work-modal/type"
import { WorkCardStyle } from "./style"



export const  WorkCard = (props: {value: DataItem}) =>{

    return <WorkCardStyle>
            <div className="container">
                    <div className="cover-wrapper" style={{backgroundImage: `url(${props.value.coverUrl})`}}>
                    </div>
                    <div className="title">{props.value.title}</div>
                </div>
        </WorkCardStyle>
}
