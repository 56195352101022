import UISegmentedControl from "ui-lib/segmented-control"
import { CouponListStyle } from "./style"
import { useEffect, useState } from "react";
import { getCouponList } from "api/coupon";
import { CouponListResponse } from "types/coupon";
import { NoData } from "components/no-data";
import { Pagination, Spin } from "antd";
import { queryUrl } from "utils/functions/common";
import { useNavigate } from "react-router-dom";

const CouponList = () => {

    const state = Number(new URLSearchParams(window.location.search).get("state")) || 1;
    const pageNumber = Number(new URLSearchParams(window.location.search).get("pageNumber")) || 1;

    const [couponList, setCouponList] = useState<CouponListResponse[]>([])

    const [loading, setLoading] = useState(false)

    const pageSize = 10

    const navigate = useNavigate()

    const [totalSize, setTotalSize] = useState(0)

    const tabOption = [
        {
            label: "未使用",
            value: 1,
        },
        {
            label: "已使用",
            value: 2,
        },
        {
            label: "已过期",
            value: 3,
        },
    ];


    useEffect(() => {
        setLoading(true)
        getCouponList({ state, pageNumber }).then(res => {
            setCouponList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [state, pageNumber])

    const changeType = (value: number | string) => {
        const url = queryUrl({ state: value, pageNumber: 1 })
        navigate(`?${url}`)
    }

    const changePage = (value: any) => {
        const url = queryUrl({ pageNumber: value })
        navigate(`?${url}`)
    }

    return <CouponListStyle>
        <UISegmentedControl onValueChange={changeType} options={tabOption} value={state}></UISegmentedControl>
        <div className="data">
            <Spin spinning={loading}>
                {
                    couponList.length === 0 && <NoData />
                }
                {
                    couponList.length > 0 && <div className="list">
                        <div className="list-item">
                            <div>优惠详情</div>
                            <div>使用范围</div>
                            {
                                state === 2 && <>
                                    <div>使用时间</div>
                                    <div>订单编号</div>
                                </>
                            }
                        </div>
                        {
                            couponList.map(item => {
                                return <div className="list-item" key={item.id}>
                                    <div>{`${(item.amount / 100).toFixed(2)}元`}{item.limitAmount > 0 ? `（满${(item.limitAmount / 100).toFixed(2)}元可用）` : "（无限制）"}</div>
                                    <div>{item.description ? item.description : '/'}</div>
                                    {
                                        state === 2 && <>
                                            <div>{item.usedTime ? item.usedTime : '/'}</div>
                                            <div>{item.usedNo ? item.usedNo : '/'}</div>
                                        </>
                                    }
                                </div>
                            })
                        }
                    </div>
                }
                <Pagination
                    pageSize={pageSize}
                    style={{ textAlign: "center" }}
                    hideOnSinglePage={true}
                    onChange={changePage}
                    showSizeChanger={false}
                    total={totalSize}
                    current={pageNumber}
                />
            </Spin>

        </div>
    </CouponListStyle>
}

export default CouponList