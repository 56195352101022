import styled from "styled-components";

export const AppDownloadStyle = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(https://cdn.ggac.com/img/app_download_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .full-container {
    background-color: rgb(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    height: 100%;
    width: 100%;
    /* background-color: #000; */

    display: flex;
    justify-content: center;
    align-items: center;
    .center {
      display: flex;
      align-items: center;
      flex-direction: column;

      .logo {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;
      }
      .tag-line {
        font-size: 18px;
        margin-bottom: 101px;
        letter-spacing: 6px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-weight: 200;
      }

      .icons-and-qrcode {
        display: flex;
        gap: 20px;
        .left {
           
          .item {
            background-color: #fff;
            border-radius: 66px;
            display: flex;
            gap: 18px;
            padding: 8px 32px;
            margin-bottom: 24px;
            cursor: pointer;
            .icon{
                font-size: 32px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 4px;
            }
            .text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                .up{
                    font-weight: 600;
                }
                .down{
                  
                }
            }
          }
        }
        .right {
            margin-left: 40px;
            .qrcode{
                border-radius: 8px;
            }
            .tips{
                color: #fff;
                text-align: center;
                padding-top: 8px;
            }
        }
      }
    }
  }
`;
