import { eventCheckinListResponse } from "types/events"
import { ContributionDetailStyle } from "./style"
import UiCalender from "ui-lib/calender"
import { useEffect, useState } from "react"
import GlobalShare from "components/share"

const ContributionDetail = ({ detail, dateList }: { detail: eventCheckinListResponse, dateList: string[] }) => {
    const [year, setYear] = useState(1)
    const [month, setMonth] = useState(1)

    const [showShare, setShowShare] = useState(false)

    // const toShare = () => {
    //     setShowShare(true)
    // }

    useEffect(() => {
        if (!detail?.startTime) {
            return
        }
        const arr = detail?.startTime.split("-")
        setYear(Number(arr[0]))
        setMonth(Number(arr[1]))
    }, [detail?.startTime])

    return <ContributionDetailStyle>
        <div className="detail">
            <img src={detail?.bigImage} className="cover" alt="" />
            <div className="part padding-lg">
                <div className="section-title">作品打卡</div>
                <div className="calendar-wrap">
                    <UiCalender year={year} month={month} hightlightItems={dateList}></UiCalender>
                </div>
            </div>
            <div className="btn">
                {/* <Button type="primary" onClick={toShare}>分享我的日历</Button> */}
            </div>
            {
                <div className="content" dangerouslySetInnerHTML={{ __html: detail?.content }}></div>
            }
            <GlobalShare info={{ title: detail?.name, cover_image: detail?.smallImage, desc: detail?.description }} shareType="work" isShow={showShare} onClose={() => { setShowShare(false) }} />
        </div>
    </ContributionDetailStyle>
}

export default ContributionDetail