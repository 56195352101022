import { Pagination, Spin } from "antd";
import { getRewardApplicantList } from "api/forum";
import { ForumMineApplyListItem } from "api/forum/type";
import { NoData } from "components/no-data";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonTabs from "ui-lib/tab/common-tab"
import { queryUrl } from "utils/functions/common";
import { RewardApplicantListStyle } from "./style";

const RewardApplicantList = () => {
    const state = new URLSearchParams(useLocation().search).get("state") || "4"
    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const navigate = useNavigate()
    const pageSize = 10
    const [list, setList] = useState<ForumMineApplyListItem[]>([])
    const [totalSize, setTotalSize] = useState(0)
    const [loading, setLoading] = useState(false)
    const forumOrderStatusList = [
        {
            key: '4',
            title: "抢答成功",
        },
        {
            key: "0",
            title: "全部记录",
        }
    ];

    useEffect(() => {
        const params: any = { pageNumber: Number(pageNumber) }
        if (state !== '0') {
            params.status = state
        } else {
            delete params.status
        }
        setLoading(true)
        getRewardApplicantList(params).then(res => {
            setList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [state, pageNumber])


    const checkTab = (detail: { title: string, key: string | number }) => {
        navigate(`?${queryUrl({ state: detail.key, pageNumber: 0 })}`)
    }

    const changePage = (e: number) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const link = () => {
        navigate(`/forum/reward/index`, { replace: true })
    }

    const toAnswerer = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        navigate(`/forum/reward/receive-order?state=1`)
    }

    return <RewardApplicantListStyle>
        <div className="list">
            <div className="list-title">
                <div className="link" onClick={() => link()}>
                    返回 悬赏主页
                </div>
                <span>{`>`}</span>我的抢答
            </div>
        </div>


        <CommonTabs
            tabs={forumOrderStatusList}
            onClick={(item) => checkTab(item)}
            defaultKey={state || '2,4'}
        ></CommonTabs>

        {
            list.length > 0 && <>
                <Spin spinning={loading}>
                    <div className="list">
                        {
                            list.map(item => {
                                return <div className="list-item" key={item.id}>
                                    <div className="topic">
                                        <div className="topic-title" onClick={() => window.open(`/forum/reward/detail/${item.id}`)}>
                                            {item.title}
                                        </div>
                                        <div className="status">
                                            {
                                                item.status === 3 && <>已关闭</>
                                            }
                                            {
                                                [1, 2].includes(item.status) && <>
                                                    {
                                                        item.orderStatus === 0 && item.status === 1 && <>悬赏中</>
                                                    }
                                                    {
                                                        ((item.orderStatus === 0 && item.status === 2) || ([1, 2].includes(item.orderStatus))) && <>回答中</>
                                                    }
                                                    {
                                                        item.orderStatus === 3
                                                            ? "已回答"
                                                            : item.orderStatus === 4
                                                                ? "已完成"
                                                                : ""
                                                    }
                                                </>
                                            }
                                            {
                                                item.status === 4 && [0, 4].includes(item.orderStatus) && <>已完成</>
                                            }
                                        </div>
                                    </div>
                                    <div className="price-wrap">
                                        <div className="price">
                                            <div className="price-icon"></div>
                                            赏金￥{item.amount}
                                        </div>
                                        <div className="word">
                                            {
                                                item.orderStatus == 2 && <div className="btn" onClick={() => toAnswerer()}>去回答</div>
                                            }
                                            <div className="word-text" onClick={() => window.open(`/forum/reward/detail/${item.id}`)}>问题详情 {`>`}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <Pagination
                        style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                        hideOnSinglePage={true}
                        current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                        onChange={changePage}
                        pageSize={pageSize}
                        total={totalSize}
                        showSizeChanger={false}
                        size="default"
                    />
                </Spin>
            </>
        }

        {
            list.length === 0 && <NoData />
        }

    </RewardApplicantListStyle>
}

export default RewardApplicantList