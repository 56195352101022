export enum OrderType {
    ORDER_TYPE_NFT = 1,
    ORDER_TYPE_COPYRIGHT_REGISTRATION = 2,
    ORDER_TYPE_COPYRIGHT_PRESERVATION = 3,
    ORDER_TYPE_COPYRIGHT_TRANSFER = 4,
    ORDER_TYPE_COMMODITY = 100
}

export const OrderTypeArr = [
    { name: "NFT", value: OrderType.ORDER_TYPE_NFT },
    { name: "版权登记", value: OrderType.ORDER_TYPE_COPYRIGHT_REGISTRATION },
    { name: "版权存证", value: OrderType.ORDER_TYPE_COPYRIGHT_PRESERVATION },
    { name: "版权转让", value: OrderType.ORDER_TYPE_COPYRIGHT_TRANSFER },
    { name: "商城", value: OrderType.ORDER_TYPE_COMMODITY }
]

export enum OrderSearchType {
    ORDER_SEARCH_COMMODITY_NAME = 1,
    ORDER_SEARCH_USERNAME = 2,
    ORDER_SEARCH_PRODUCT_NAME = 3
}

export const orderSearchArr = [
    { name: "商品名称", value: OrderSearchType.ORDER_SEARCH_COMMODITY_NAME },
    { name: "买家昵称", value: OrderSearchType.ORDER_SEARCH_USERNAME },
    { name: "订单编号", value: OrderSearchType.ORDER_SEARCH_PRODUCT_NAME }
]