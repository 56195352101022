import { useOutletContext } from "react-router"
import { Regultion2024RuleStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import { Tooltip } from "antd"

const Regultion2024Rule = () => {

    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const ruleTypeList: { title: string, tip: string, tip1: string, id: number, list: { name: string, id: number }[] }[] = contestJSON.ruleTypeList || []
    const ruleTypeList2: { name: string, desc: string[], id: number }[] = contestJSON.ruleTypeList2 || []
    const ruleTypeList3: { name: string, desc: string[], id: number }[] = contestJSON.ruleTypeList3 || []
    const ruleTypeList4: string[] = contestJSON.ruleTypeList4 || []
    const ruleTypeList5: string[] = contestJSON.ruleTypeList5 || []

    const lang = localStorage.getItem('lang')


    const toDetail = (id: number) => {
        window.open(`/official/${id}`)
    }

    return <Regultion2024RuleStyle>
        <div className={`rule ${lang === 'en_GB' ? 'en-rule' : ''}`}>
            <div className="part1">
                <div className="title-wrap">
                    <div className="num-wrap">
                        <div className="num color">01</div>
                    </div>
                    <div className="title" lang="t_lang">赛事组别</div>
                </div>
                {
                    ruleTypeList.map(item => {
                        return <div key={item.id}>
                            <div className="sub-title">
                                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-sub-title-left.png" alt="" />
                                <div className="sub-content">
                                    <span lang="t_lang">{item.title}</span>
                                    <Tooltip placement="topLeft" title={item.tip1}>
                                        <img className="wenhao" src="https://cdn-prd.ggac.com/ggac/reg/2024/2024-reg-wenhao1.png" alt="" />
                                    </Tooltip>
                                </div>
                                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-sub-title-right.png" alt="" />
                            </div>
                            <div className="tip" lang="t_lang">{item.tip}</div>
                            <div className="list">
                                {
                                    item.list.map(sub => {
                                        return <div key={sub.id} onClick={() => toDetail(sub.id)} className="list-item" lang="t_lang">{sub.name}</div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }

                <div className="rule2">
                    <div className="title-wrap">
                        <div className="num-wrap">
                            <div className="num color">02</div>
                        </div>
                        <div className="title" lang="t_lang">评审维度</div>
                    </div>
                </div>

                <div className="list2">
                    {
                        ruleTypeList2.map(item => {
                            return <div className="detail" key={item.id}>
                                <div className="name" lang="t_lang">{item.name}</div>
                                {
                                    item.desc.map(desc => {
                                        return <div className="desc" key={desc} lang="t_lang">{desc}</div>
                                    })
                                }
                            </div>
                        })
                    }
                </div>

            </div>
            <div className="part2">
                <div className="title-wrap">
                    <div className="num-wrap">
                        <div className="num">03</div>
                    </div>
                    <div className="title" lang="t_lang">评审环节</div>
                </div>
                <div className="list-wrap">
                    <div className="list3">
                        {
                            ruleTypeList3.map(item => {
                                return <div className="detail" key={item.id}>
                                    <div className="name" lang="t_lang">{item.name}</div>
                                    <div className="desc-wrap scroll-style">
                                        {
                                            item.desc.map(desc => {
                                                return <div className="desc" key={desc} lang="t_lang">{desc}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="word" lang="t_lang">
                        ※注：最终提交时间截止前，无论作品是否晋级，均可对参赛作品进行修改、调整。如未对作品主体内容进行大幅度修改、调整、替换，则不影响其晋级状态。<br />
                        晋级、入围、提名阶段，往届获奖选手、GGAC大赛评审委员、第六届GGAC大赛评委，均拥有让作品直接进入下一轮评选的权利。<br />（该权利不设置具体比例，以各位老师对参赛作品的评审为标准）
                    </div>
                </div>
                <div id="four" style={{ height: "20px" }}></div>
                <div className="title-wrap">
                    <div className="num-wrap">
                        <div className="num">04</div>
                    </div>
                    <div className="title" lang="t_lang">参赛须知</div>
                </div>
                <div className="list4">
                    <div className="list4-wrap">
                        {
                            ruleTypeList4.map((item, index) => {
                                return <div className="detail" key={item}>
                                    <div>{index + 1}、</div>
                                    <div lang="t_lang">{item}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="part3">
                <div id="five" style={{ height: "20px" }}></div>
                <div className="title-wrap">
                    <div className="num-wrap">
                        <div className="num color">05</div>
                    </div>
                    <div className="title" lang="t_lang">获奖须知</div>
                </div>

                <div className="list5">
                    <div className="list5-wrap">
                        {
                            ruleTypeList5.map((item, index) => {
                                return <div className="detail" key={index}>
                                    <div>{index + 1}、</div>
                                    <div lang="t_lang">{item}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="part4">
                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-logo.png" alt="" />
            </div>
        </div>
    </Regultion2024RuleStyle>
}

export default Regultion2024Rule