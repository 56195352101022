

import { translateCardCategoryText } from 'utils/functions/common';
import { ArticleListItem } from 'types/article';
import UniCardLayout from 'components/uni-card/layout';
import HotIcons from 'components/uni-card/common/hot-icons';
import CardTitleArticleRender from 'components/uni-card/common/card-title/article';
import HomePageArticleCardStatus from './HomePageArticleCardStatus';


function HomePageArticleCard({ item }: { item: ArticleListItem }) {
    const UiObject ={
        coverUrl:item.coverUrl,
        title:<CardTitleArticleRender item={item}/>,
        subtitle:<span> {translateCardCategoryText(item.categoryList)}</span>,
        view:item.viewCount,
        hot:item.hot,
        // userName:item.userInfo?.username,
        // avatarUrl:item.userInfo.avatarUrl,
        date: item.lastSubmitTime,
        workLink: `/article/detail/${item.id}`,
        userLink:`/user/${item.userId}`,
        icons:HotIcons({item}),
        isPublish: true,
        leftBottomNode: <HomePageArticleCardStatus item={item}></HomePageArticleCardStatus>,
      }
      
      return UniCardLayout({item:UiObject});
}

export default HomePageArticleCard