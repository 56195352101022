import { Button, Input, Modal, Pagination, Popover, message } from "antd";
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  MutableRefObject,
} from "react";
import { ManageStyle } from "./manageStyle";
import { useGRoutes } from "utils/hooks/useGRoute";
import { MoveTypeArr } from "types/enums/commodity-move-type";
import { RiAddBoxLine, RiAddLine, RiCheckboxIndeterminateLine, RiErrorWarningLine } from "react-icons/ri";
import {
  MallAdminProductListRequest,
  MallAdminProductListResponse,
  MallShopDetailResponse,
} from "types/product";
import {
  apiGetProductList,
  apiGetProductShareImage,
  apiPosMoverProduct,
  apiPostDeleteProduct,
  apiPostProductSale,
  getShopDetail,
} from "api/shop";
import { useNavigate } from "react-router-dom";
import CommonTabs from "ui-lib/tab/common-tab";
import {
  getSearchParam,
  queryUrl
} from "utils/functions/common";
import { NoData } from "components/no-data";
import ShopTopLine from "../componnet/shop-top-line";
import ShopApplicant from "views/user-center/shop/applicant";
import GlobalShare from "components/share";
import { UserBaseInfo } from "types/user";
const { confirm } = Modal;

type MoveItem = {
  name: string;
  value: string;
};

const { Search } = Input;
const ManageList = () => {
  const [list, updateList] = useState<MallAdminProductListResponse[]>([]);
  const [currentExpandItem, setCurrentExpandItem] =
    useState<MallAdminProductListResponse>();
  const gRouter = useGRoutes();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0); // 列表总数
  const searchInput: MutableRefObject<any> = useRef(null);
  const [key, setKey] = useState(0);
  let onSale = new URLSearchParams(window.location.search).get("onSale");
  let pageNumber =
    new URLSearchParams(window.location.search).get("pageNumber") || 1;
  let name = new URLSearchParams(window.location.search).get("name");
  let status = new URLSearchParams(window.location.search).get("status");
  let code = new URLSearchParams(window.location.search).get("code");
  const [loading, setLoading] = useState(false);

  const [isHasShop, setIsHasShop] = useState(false)

  const [shopDetail, setShopDetail] = useState<MallShopDetailResponse>()

  const [showShare, setShowShare] = useState(false)

  const [shareUrl, setShareUrl] = useState('')

  const [shareImageUrl, setShareImageUrl] = useState('')

  const [isShareLoading, setIsShareLoading] = useState(false)

  const [shareQrCode, setShareQrCose] = useState("")

  const breadcrumbs = [
    {
      title: "商品管理",
    },
  ];

  let userAllInfo: UserBaseInfo = JSON.parse(
    localStorage.getItem("user_info")!
  );

  const toCreate = () => {
    if (code) {
      gRouter(`/user-center/shop/product/create?code=${code}`);
      return
    }
    gRouter("/user-center/shop/product/create");
  };
  let userId: number
  if (!!JSON.parse(localStorage.getItem('user_info')!)) {
    userId = JSON.parse(localStorage.getItem('user_info')!).id
  }


  const pageSize = 10;

  const defaultParams = useMemo(() => {
    return {
      pageNumber,
      pageSize: pageSize,
      onSale,
      status,
      name,
      classify: 1
    };
  }, [onSale, status, pageNumber, name]);

  const getList = useCallback(async () => {
    let paramsReq: MallAdminProductListRequest = Object.assign(defaultParams);
    const result = await apiGetProductList(paramsReq);
    updateList(result.pageData);
    setTotal(result.totalSize);
  }, [defaultParams]);

  useEffect(() => {
    getList()
  }, [getList])

  // 编辑
  const editCommodity = (detail: MallAdminProductListResponse) => {
    gRouter(`/user-center/shop/product/update/${detail.id}`);
  };
  // 上下架
  const shelves = (detail: MallAdminProductListResponse) => {
    apiPostProductSale({
      id: detail.id,
      onSale: detail.onSale === 1 ? 0 : 1,
    }).then(() => {
      message.success("操作成功！");
      getList();
    });
  };

  // 分享
  const shareProduct = (detail: MallAdminProductListResponse) => {
    setIsShareLoading(true)
    apiGetProductShareImage(Number(detail?.id)).then(res => {
      if (userAllInfo) {
        setShareUrl(window.location.origin + `/product/detail/${detail.id}?shareUserId=${userAllInfo.id}`);
      } else {
        setShareUrl(window.location.origin + `/product/detail/${detail.id}`)
      }
      setShareImageUrl(res.minaImage)
      setShareQrCose(res.minaQrCode)
      setShowShare(true)
    }).finally(() => {
      setIsShareLoading(false)
    })
  }

  // 移动
  const move = (
    detail: MoveItem,
    commodityDetail: MallAdminProductListResponse
  ) => {
    apiPosMoverProduct({
      dataId: commodityDetail.id,
      dataTable: "mall_product",
      optType: detail.value,
      userId
    }).then(() => {
      getList();
    });
  };

  // 移动弹窗

  const PopoverContent = (commodityDetail: MallAdminProductListResponse) => {
    return (
      <div className="popover-btn">
        {MoveTypeArr.map((item) => {
          return (
            <Button key={item.name} onClick={() => move(item, commodityDetail)}>
              {item.name}
            </Button>
          );
        })}
      </div>
    );
  };

  // 筛选列表 修改分页
  const changePage = (detail: any) => {
    const query = queryUrl({ pageNumber: detail });
    navigate(`?${query}`);
  };

  // 搜索关键字
  const searchList = (e: string) => {
    const query = queryUrl({ name: e, pageNumber: 1 });
    navigate(`?${query}`);
  };

  // 删除商品
  const deleteCommodity = (detail: MallAdminProductListResponse) => {
    confirm({
      title: "删除商品?",
      content: `确定删除商品${detail.name}吗`,
      onOk() {
        setLoading(true);
        apiPostDeleteProduct(detail.id)
          .then(() => {
            getList();
          })
          .finally(() => {
            setLoading(false);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // 展示驳回原因
  const refuse = (title: string, value: string) => {
    Modal.confirm({
      title: title,
      content: value,
      okText: "关闭",
      cancelButtonProps: { style: { display: "none" } },
      cancelText: "",
      centered: true,
    });
  };

  useEffect(() => {
    getShopDetailFun()
  }, [])

  const getShopDetailFun = () => {    
    getShopDetail().then(res => {
      if (res.id) {
        setIsHasShop(true)
        getList();
      } else {
        setIsHasShop(false)
      }
      setShopDetail(res)
    })
  }

  const checkTab = (tab: { key: number | string; title: string }) => {
    const key = Number(tab.key);
    setKey(key);
    setCurrentExpandItem(undefined)
    if (key === 0) {
      navigate(`/user-center/shop/product-list?pageNumber=1&tabKey=0&name=${name || ''}`);
    } else if (key === 1) {
      navigate(
        `/user-center/shop/product-list?onSale=1&status=2&pageNumber=1&tabKey=1&name=${name || ''}`
      );
    } else if (key === 2) {
      navigate(
        `/user-center/shop/product-list?onSale=0&status=1,3&pageNumber=1&tabKey=2&name=${name || ''}`
      );
    } else if (key === 3) {
      navigate(
        `/user-center/shop/product-list?onSale=0&status=2&pageNumber=1&tabKey=3&name=${name || ''}`
      );
    } else if (key === 4) {
      navigate(
        `/user-center/shop/product-list?onSale=0&status=0&pageNumber=1&tabKey=4&name=${name || ''}`
      );
    }
  };


  useEffect(() => {
    let params
    if (onSale === "0" && status === "2") {
      params = { onSale: 0, status: "2", classify: 1 }
    } else {
      params = { classify: 1 }
    }
  }, [onSale, status])


  useEffect(() => {
    if (!onSale && !status) {
      setKey(0);
    } else if (onSale === "1" && status === "2") {
      setKey(1);
    } else if (onSale === "0" && status === "1,3") {
      setKey(2);
    } else if (onSale === "0" && status === "2") {
      setKey(3);
    } else if (onSale === "0" && status === "0") {
      setKey(4);
    }
  }, [onSale, status]);

  const [statusTabs] = useState(
    [
      {
        key: 0,
        title: "全部"
      },
      {
        key: 1,
        title: "架上商品"
      },
      {
        key: 2,
        title: "审核中"
      },
      {
        key: 3,
        title: "未上架"
      },
      {
        key: 4,
        title: "草稿"
      },
    ]
  )



  const expandRow = (item: MallAdminProductListResponse) => {
    if (currentExpandItem && currentExpandItem.id === item.id) {
      setCurrentExpandItem(undefined);
    } else {
      setCurrentExpandItem(item);
    }
  };
  return (
    <>
      {
        isHasShop && <ManageStyle flag={key}>
          <div className="table-wrap">
            <div className="title">
              <ShopTopLine defaultValue="product" breadcrumbs={breadcrumbs} />
            </div>
            <div className="main">
              <div className="main-top">
                <CommonTabs
                  tabs={statusTabs}
                  defaultKey={Number(getSearchParam("tabKey"))}
                  onClick={(tab) => checkTab(tab)}
                ></CommonTabs>
                <div className="actions">
                  <Search
                    ref={searchInput}
                    allowClear={true}
                    onSearch={(e) => {
                      searchList(e);
                    }}
                    type="text"
                    placeholder="请输入商品名称关键字"
                  />
                  <Button
                    type="primary"
                    className="btn"
                    onClick={() => toCreate()}
                    icon={<RiAddLine size={18} />}
                  >
                    创建商品
                  </Button>
                </div>
              </div>

              <div className="main-list">
                {/* {
                  onSale === '0' && status === '2' && <div className="tip">温馨提示：未上架订单显示已通过审核、未上架的商品，审核通过后需手动上架</div>
                } */}
                <div className="heading">
                  <span>&nbsp;</span>
                  {key === 1 && <span>序号</span>}
                  <span>商品类目</span>
                  <span>商品信息</span>
                  <span>商品价格</span>
                  <span>库存</span>
                  <span>销量</span>
                  <span>创建时间</span>
                  <span>操作</span>
                </div>
                <div className="list">
                  <div className="list-item">
                    {list.length === 0 && <NoData />}
                    {list.map((item, index) => {
                      return (
                        <div key={item.id}>
                          <div className="row" key={item.id}>
                            <div className="expand" onClick={() => expandRow(item)}>
                              {item.id === currentExpandItem?.id && <RiCheckboxIndeterminateLine></RiCheckboxIndeterminateLine>}
                              {item.id !== currentExpandItem?.id && <RiAddBoxLine></RiAddBoxLine>}
                            </div>


                            {key === 1 && (
                              <div className="row-cell">
                                <span className="no_">
                                  {(Number(pageNumber) - 1) * pageSize +
                                    (index + 1)}
                                </span>
                              </div>
                            )}
                            <div className="row-cell" style={{ textAlign: 'left' }}>
                              {item.categoryList[0].name + '-' + item.categoryList[1].name}
                            </div>
                            <div className="row-cell">
                              {item.onSale === 1 && (
                                <div className="cell-card">
                                  <a
                                    href={`/product/detail/${item.id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="thumbnail"
                                      src={item.coverUrl}
                                      alt=""
                                    />
                                  </a>
                                  <div className="title_" title={item.name}>
                                    <a
                                      href={`/product/detail/${item.id}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {" "}
                                      {item.name}
                                    </a>
                                  </div>
                                </div>
                              )}

                              {item.onSale !== 1 && (
                                <div className="cell-card">
                                  <span>
                                    <img
                                      className="thumbnail"
                                      src={item.coverUrl}
                                      alt=""
                                    />
                                  </span>
                                  <div className="title_" title={item.name}>{item.name}</div>
                                </div>
                              )}
                            </div>
                            <div className="row-cell">
                              ￥{(item.rmbPrice / 100).toFixed(2)}
                            </div>

                            <div className="row-cell">{item.categoryList[0].code === 'yuegao' ? '---' : item.quantity}</div>
                            <div className="row-cell">{item.sold}</div>
                            <div className="row-cell">{item.createTime}</div>
                            <div className="row-cell btns">
                              {item.canEdit === 1 && (
                                <Button
                                  type="primary"
                                  onClick={() => deleteCommodity(item)}
                                  loading={loading}
                                >
                                  删除
                                </Button>
                              )}
                              {item.canEdit === 1 && (
                                <Button
                                  type="primary"
                                  onClick={() => editCommodity(item)}
                                >
                                  编辑
                                </Button>
                              )}
                              {item.onSale === 1 && (
                                <Button
                                  type="primary"
                                  onClick={() => shelves(item)}
                                >
                                  下架
                                </Button>
                              )}
                              {item.onSale === 1 && (
                                <Button
                                  type="primary"
                                  onClick={() => shareProduct(item)}
                                  loading={isShareLoading}
                                >
                                  分享
                                </Button>
                              )}
                              {item.status === 3 && (
                                <Button type="dashed" onClick={() => refuse("驳回原因", item.refuseReason)} className="refuse">
                                  <RiErrorWarningLine className="icon"></RiErrorWarningLine> <span>驳回原因</span>
                                </Button>
                              )}

                              {item.onSale === 0 && item.status === 2 && (
                                <>
                                  <Button
                                    type="primary"
                                    onClick={() => shelves(item)}
                                  >
                                    上架
                                  </Button>
                                  {
                                    !!item.offSaleReason && <Button type="dashed" onClick={() => refuse('下架原因', item.offSaleReason)}>
                                      下架原因
                                    </Button>
                                  }
                                </>
                              )}
                              {item.onSale === 1 && key === 1 && total > 1 && (
                                <Popover
                                  getPopupContainer={(trigger) => trigger}
                                  placement="bottom"
                                  content={PopoverContent(item)}
                                >
                                  <Button type="primary">移动</Button>
                                </Popover>
                              )}
                            </div>
                          </div>
                          {currentExpandItem && item.id === currentExpandItem.id && (
                            <div className="expand-content">


                              {currentExpandItem.skuList.map((sku, index) => {
                                return <div className="sku" key={index}>
                                  <span>&nbsp;</span>
                                  <span>&nbsp;</span>
                                  {key === 1 && <span>&nbsp;</span>}
                                  <div className="info">
                                    <div className="cover" style={{ backgroundImage: `url(${sku.coverUrl})` }}></div>
                                    <span className="name_">{sku.name}</span>
                                  </div>


                                  <span>￥{(sku.rmbPrice / 100).toFixed(2)}</span>
                                  <span>{sku.quantity}</span>
                                  <span>{sku.sold}</span>
                                  <span>&nbsp;</span>
                                  <span>&nbsp;</span>
                                </div>
                              })}
                            </div>


                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <Pagination
                style={{ textAlign: "center", margin: "24px 0 0 0" }}
                hideOnSinglePage={true}
                current={Number(pageNumber)}
                onChange={changePage}
                total={total}
                pageSize={pageSize}
                showSizeChanger={false}
              />
            </div>
          </div>
          <GlobalShare
            info={{
              title: "",
              cover_image: "",
              desc: "",
            }}
            shareImageInfo={{ qrCode: shareQrCode, shareImageUrl: shareImageUrl }}
            shareType="shop"
            isShow={showShare}
            onClose={() => {
              setShowShare(false);
            }}
            shareUrl={shareUrl}
          ></GlobalShare>
        </ManageStyle>
      }
      {
        !isHasShop && !!shopDetail && <ShopApplicant updateDetail={getShopDetailFun} detail={shopDetail} />
      }
    </>
  );
};

export default ManageList;
