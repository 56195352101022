import { get, post } from "api"
import { PaginationResponse } from "types/common/axios";
import { CertificateListRequest, certificateItem } from "types/user/certificate";
//获取用户任务列表
export const apiGetCertificateList = async (props: CertificateListRequest) =>
    await get<CertificateListRequest, PaginationResponse<certificateItem>>(`/api/certificate/list`, props);

export const apiGetMyCertificateList = async (props: CertificateListRequest) =>
    await get<CertificateListRequest, certificateItem[]>(`/api/certificate/mine_list`, props);

// 是否公开证书
export const postCertificateFlag = (params: { flag: number, id: number }) =>
    post<{ flag: number, id: number }, {}>("/api/certificate/flag_show", params)