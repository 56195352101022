import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import CardView from "./card-view";
import { SlideBannerStyle } from "./style";
import Carousel from "nuka-carousel"
import { getCarouselList, sendCarouselLog } from "api/common/carousel";
import { useEffect, useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { ImageObjectCloudResize } from "utils/functions/file";


function SlideBanner() {
  const [slideList, setSlideList]=useState<CarouselListItem[]>([]);
  const defaultControlsConfig={
    // pagingDotsStyle:{display: 'none'},
    pagingDotsContainerClassName: 'slide-dots',
    pagingDotsClassName: 'dot-item',
     nextButtonStyle:{  backgroundColor: 'rgba(225, 225, 255, 0.6)'},
     prevButtonStyle:{ backgroundColor: 'rgba(225, 225, 255, 0.6)'},
     prevButtonText:<RiArrowLeftSLine></RiArrowLeftSLine>,
     nextButtonText:<RiArrowRightSLine></RiArrowRightSLine>
  }

  const getSlideList=()=>{
      const params:CarouselListRequest ={
        lang: 1,
        type: 1,
        status:1,
        pageSize:100
      }

      getCarouselList(params).then(res=>{
        setSlideList(res.pageData)
      })
  }

  useEffect(()=>{
    getSlideList();
  },[])

  const open = (item:CarouselListItem)=>{
    if(!item.webUrl) {
      return
    }
    sendCarouselLog({carouselId:item.id})
    window.open(item.webUrl,'_blank')
  }
  return (
    <SlideBannerStyle>
         <Carousel style={{width:'100%'}} wrapAround={true} autoplay={true} autoplayInterval={3000} defaultControlsConfig={defaultControlsConfig}>
          {
            slideList && slideList.map(item =>{
              return (
                <div  className="slide" key={item.id}  style={{ backgroundImage: `url(${ImageObjectCloudResize(item.displayImage,1920)})`, cursor: `${item.webUrl ? 'pointer' : ''}` }} onClick={()=>{
                  open(item)
                  }}>
                  <div className="card-view-container">
                     {item.templateParams?.avatarUrl && item.templateParams?.workTitle &&  <CardView item={item}></CardView>}  
                  </div>
                </div>
              )
            })
          }
       
        </Carousel>
    </SlideBannerStyle>
  );
}

export default SlideBanner;
