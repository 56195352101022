import { useNavigate, useParams } from "react-router"
import { OfficialDetailStyle } from "./style"
import { useEffect, useRef, useState } from "react"
import { apiGetOfficialDetail } from "api/notification"
import { OfficialArticleDetail } from "types/notification"
import { Button, Input, message } from "antd"
import EmojiPicker from "components/emoji-picker"
import { CommentListItem } from "types/comment"
import Comments from "components/comments"
import { postCreateComment } from "api/work/comment"
import { emojiParse, isMobile } from "utils/functions/common"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"
import GoBackTitle from "ui-lib/go-back"

const { TextArea } = Input

const OfficialDetail = () => {
    const { id } = useParams()
    const [detail, setDetail] = useState<OfficialArticleDetail>()
    const [commentCount, setCommentCount] = useState<number>(0);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const commentRef = useRef() as React.MutableRefObject<any>;

    // 调用commet创建方法
    const createComment = (val: CommentListItem) => {
        commentRef.current?.addComment(val);
    };
    useEffect(() => {
        apiGetOfficialDetail(Number(id)).then(res => {
            setDetail(res)
            setCommentCount(res.actionCount.commentCount)
        })
    }, [id])

    const onClickComment = () => {
        if (!comment.trim()) {
            message.warning("请输入内容");
            return;
        }
        setLoading(true);
        postCreateComment({
            content: emojiParse(comment),
            dataId: Number(id),
            dataTable: "official_article",
        })
            .then((res) => {
                message.success("创建成功");
                createComment(res);
            })
            .finally(() => {
                setComment("");
                setLoading(false);
            });
    };

    const updateCommentCount = () => {
        apiGetOfficialDetail(Number(id)).then(res => {
            setCommentCount(res.actionCount.commentCount)
        })
    };

    const appendEmoji = (item: { url: string, title: string }) => {
        setComment((res) => res + item.title)
    }

    const onChangeComment = (event: any) => {
        setComment(event.target.value);
    };

    const toLink = (id: number) => {
        document.getElementById('article-title')!.scrollTop = 0
        commentRef.current?.resetComment()
        navigate(`/official/${id}`)
    }

    useEffect(() => {
        const checkIsMobile = isMobile()
        if (checkIsMobile) {
            window.location.href = `https://m.ggac.com` + window.location.pathname
            return
        }
    }, [])

    return <OfficialDetailStyle>
        <div className="nav">
            {
                detail?.type === 1 && <>
                    {/* <GoBackTitle onClick={() => navigate('/user-center/notification')}>
                        返回消息中心
                    </GoBackTitle> */}
                    <div>官方公告</div>
                </>
            }
        </div>
        <div className="article-title" id="article-title">{detail?.title}</div>
        <div className="date">
            {
                new Date(detail?.createTime!).getFullYear()
            }
            -
            {
                new Date(detail?.createTime!).getMonth() + 1
            }
            -
            {
                new Date(detail?.createTime!).getDate()
            }
        </div>
        <div className="content" dangerouslySetInnerHTML={{ __html: detail?.content! }}></div>
        <div className="bottom">
            {
                detail?.beforeArticle && <div className="bottom-item first" onClick={() => toLink(detail.beforeArticle.id)}>
                    <div className="top"><RiArrowLeftSLine />上一页</div>
                    <div className="name">{detail.beforeArticle.title}</div>
                </div>
            }
            {
                detail?.afterArticle && <div className="bottom-item second" onClick={() => toLink(detail.afterArticle.id)}>
                    <div className="top">下一页<RiArrowRightSLine /></div>
                    <div className="name">{detail.afterArticle.title}</div>
                </div>
            }
        </div>
        <div className="comment-wrapper" id="input-wrap">
            <div className="input-wrapper">
                <TextArea
                    allowClear
                    value={comment}
                    onChange={onChangeComment}
                    placeholder="请输入你的评论"
                    onPressEnter={onClickComment}
                />
                <div className="input">
                    <EmojiPicker appendEmoji={appendEmoji} />
                    <Button
                        className="confirm-btn"
                        type="primary"
                        loading={loading}
                        onClick={onClickComment}
                    >
                        确认
                    </Button>
                </div>
            </div>
            <Comments
                ref={commentRef}
                params={{ commentCount, updateCommentCount, type: "official_article" }}
            ></Comments>
        </div>
    </OfficialDetailStyle>
}

export default OfficialDetail