import { useNavigate } from "react-router-dom"
import { XnfwUserRankStyle } from "./style"
import { userJosn } from "./user"

const XnfwUserRank = () => {
    const navigate = useNavigate()
    const toDetail = (value: string) => {
        navigate(value)
    }

    const toUserDetail = (id: number) => {
        window.open(`/user/${id}`)
    }

    return <XnfwUserRankStyle>
        <div className="cover">
            <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-work-kv.png" alt="" />
        </div>
        <div className="content">
            <div className="tab">
                <div onClick={() => toDetail("/events/custom/xnwf/work")} className={`${window.location.pathname === '/events/custom/xnwf/work' ? 'active' : ''} tab-item`}>年度作品榜单</div>
                <div onClick={() => toDetail("/events/custom/xnwf/user")} className={`${window.location.pathname === '/events/custom/xnwf/user' ? 'active' : ''} tab-item`}>年度用户榜单</div>
            </div>
            <div className="inner">
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-title1.png" />
                </div>
                <div className="work work1">
                    <img className="dw dw1" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-dw1.png" />
                    {
                        userJosn[0].map((item) => {
                            return <div className="work-item" key={item.id} onClick={() => toUserDetail(item.id)}>
                                <div className="cover-wrap">
                                    <div className="work-cover" style={{ backgroundImage: `url(${item.avatarUrl})` }}></div>
                                </div>
                                <div className="user-name">{item.username}</div>
                            </div>
                        })
                    }
                </div>
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-title2.png" />
                </div>
                <div className="work work2">
                    <img className="dw dw2" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-user-dw2.png" />
                    {
                        userJosn[1].map((item) => {
                            return <div className="work-item" key={item.id} onClick={() => toUserDetail(item.id)}>
                                <div className="cover-wrap">
                                    <div className="work-cover" style={{ backgroundImage: `url(${item.avatarUrl})` }}></div>
                                </div>
                                <div className="user-name">{item.username}</div>
                            </div>
                        })
                    }
                </div>
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-title3.png" />
                </div>
                <div className="work work3">
                    <img className="dw dw3" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-user-dw3.png" />
                    {
                        userJosn[2].map((item) => {
                            return <div className="work-item" key={item.id} onClick={() => toUserDetail(item.id)}>
                                <div className="cover-wrap">
                                    <div className="work-cover" style={{ backgroundImage: `url(${item.avatarUrl})` }}></div>
                                </div>
                                <div className="user-name">{item.username}</div>
                            </div>
                        })
                    }
                </div>
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-title4.png" />
                </div>
                <div className="work work4">
                    <img className="dw dw4" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-user-dw4.png" />
                    {
                        userJosn[3].map((item) => {
                            return <div className="work-item" key={item.id} onClick={() => toUserDetail(item.id)}>
                                <div className="cover-wrap">
                                    <div className="work-cover" style={{ backgroundImage: `url(${item.avatarUrl})` }}></div>
                                </div>
                                <div className="user-name">{item.username}</div>
                            </div>
                        })
                    }
                </div>
                <div className="title title1">
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-title5.png" />
                </div>
                <div className="work work5">
                    <img className="dw dw5" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-user-dw5.png" />
                    {
                        userJosn[4].map((item) => {
                            return <div className="work-item" key={item.id} onClick={() => toUserDetail(item.id)}>
                                <div className="cover-wrap">
                                    <div className="work-cover" style={{ backgroundImage: `url(${item.avatarUrl})` }}></div>
                                </div>
                                <div className="user-name">{item.username}</div>
                            </div>
                        })
                    }
                </div>
                <div className="bottom">
                    <div className="code">
                        <div className="code-item">
                            <div className="code-tip">GGAC 2023个人年度报告</div>
                            <img
                                src="https://cdn-prd.ggac.com/settings/bg/year_report/qr_code.png"
                            />
                            <div className="code-word">扫一扫，查看我的年度报告！</div>
                        </div>
                    </div>
                    <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-bottom.png" alt="" />
                </div>
            </div>
        </div>
    </XnfwUserRankStyle>
}

export default XnfwUserRank