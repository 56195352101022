import styled from "styled-components";

export const WallectOrderStyle = styled.div`
    padding: ${props => props.theme.space.paddingLG}px;

    margin: 0 auto;
    .tabs{
        width: 100%;
        display: flex;
        justify-content: center;
    }
`