import { ShopAgreementStyle } from "./style"

const ShopAgreement = () => {
    return <ShopAgreementStyle>
        <div className="title">提示条款</div>
        <div className="content">
            <div>《GGAC用户授权许可协议》（以下简称“本协议”）为授权人与被授权人之间的协议，GGAC数字艺术平台（以下简称“本平台”）将依据《GGAC用户协议》及本协议的规定提供服务。</div>
            <div>使用授权功能时，请您认真阅读本协议，审阅并接受或不接受本协议。</div>
            <div>若您勾选本协议或购买、下载授权商品，即表示您已充分阅读、理解并同意签署本协议，且您自愿受本协议的条款约束。GGAC有权随时变更本协议，经修订的条款一经GGAC公布，立即自动生效。如您不同意相关变更，必须停止使用授权服务。一旦您继续使用授权服务，则表示您已接受并自愿遵守经修订后的条款。</div>
        </div>
        <div className="title">第一部分 定义</div>
        <div className="content">
            <div>“本网站”、“本平台”：无特别说明的情况下均指GGAC数字艺术平台。</div>
            <div>“用户”：指具有民事行为能力的GGAC各项服务的使用者。</div>
            <div>“被授权人”、“买家”：指购买并使用授权商品的主体（包括自然人、法人、非法人的各类组织等）。</div>
            <div>“授权人”、“卖家”：指创建并出售授权商品的实名认证用户。</div>
            <div>“店铺”、“集市”：指GGAC提供的交易服务的总称，包含开通店铺、买卖商品、授权服务等功能。</div>
            <div>“授权商品”：指店铺分类为“授权”的所有商品。</div>
            <div>“授权”：指授权人赋予被授权人授权商品全球范围内的永久使用权利（除非按照本协议规定终止）。</div>
        </div>
        <div className="title">第二部分 知识产权申明</div>
        <div className="content">
            <div>1、本平台上展示的授权商品的知识产权归属于授权人，并受中华人民共和国法律法规及相应国际条例保护。</div>
            <div>2、依据本协议对被授权人的授权许可，仅为使用权利许可，不视为授权商品的知识产权、所有权等其他任何权利的转让，除本协议明确陈述的授权外，被授权人不享有授权商品的其他任何权利。</div>
            <div>3、当授权人与被授权人自行签署授权商品的知识产权转让协议或其他协议时，双方签署的有关协议不论是否早于或晚于本协议签署，都将优先于本协议生效。</div>
        </div>
        <div className="title">第三部分 授权许可</div>
        <div className="content">
            <div>1、授权人可依据销售目的选择以下的授权类型对创建的授权商品进行一项或多项授权许可设置。</div>
            <div className="indent">
                （a）个人使用授权许可<br />在个人使用授权许可下，被授权人可获得授权商品全球性的、永久的、不可转售的、非排他的授权使用许可，将授权商品用于个人用途，具体包括：
                <div className="indent">（1）个人欣赏、制作贺卡、邀请函、定制海报等非盈利性制品</div>
                <div className="indent">（2）个人学习、交流经验</div>
                <div className="indent">（3）非商业性数字用途：个人社交媒体等的非盈利使用</div>
                <div className="indent">（4）以个人用途为前提，在原授权商品的基础上进行二次创作、修改、加工</div>
            </div>
            <div className="indent">
                商业使用授权许可<br />
                在商业使用授权许可下，被授权人可获得授权商品全球性的、永久的、不可转售的、非排他的授权使用许可，将授权商品用于个人用途及商业用途，具体包括：
                <div className="indent">（1）个人欣赏、制作贺卡、邀请函、定制海报等非盈利性制品</div>
                <div className="indent">（2）个人学习、交流经验</div>
                <div className="indent">（3）非商业性数字用途：个人社交媒体等的非盈利使用</div>
                <div className="indent">（4）商业性数字用途：自媒体、视频、电商、网站、线上广告等的盈利性使用</div>
                <div className="indent">（5）线下印刷：纺织品、手机壳、贺卡、明信片、日历、杯子、T恤、抱枕等盈利性用途</div>
                <div className="indent">（6）影视、游戏等商业项目</div>
                <div className="indent">（7）在原授权商品的基础上进行二次创作、修改、加工</div>
            </div>
            <div className="indent">
                商业独家授权许可<br />
                在商业独家授权许可下，被授权人可获得授权商品全球性的、永久的、可转售的、排他的独家授权使用许可，授权商品的使用权利将独属于被授权人，被授权人可将授权商品用于个人用途及商业用途，具体包括：
                <div className="indent">（1）个人欣赏、制作贺卡、邀请函、定制海报等非盈利性制品</div>
                <div className="indent">（2）个人学习、交流经验</div>
                <div className="indent">（3）非商业性数字用途：个人社交媒体等的非盈利使用</div>
                <div className="indent">（4）商业性数字用途：自媒体、视频、电商、网站、线上广告等的盈利性使用</div>
                <div className="indent">（5）线下印刷：纺织品、手机壳、贺卡、明信片、日历、杯子、T恤、抱枕等盈利性用途</div>
                <div className="indent">（6）影视、游戏等商业项目</div>
                <div className="indent">（7）在原授权商品的基础上进行二次创作、修改、加工</div>
                <div className="indent">（8）对授权商品原件的免费公开</div>
                授权人将商业独家授权许可出售给被授权人后，授权人不可再进行公开、转售授权商品或将授权商品用于商业项目等的行为。
            </div>
            <div>2、除非本协议明确允许，被授权人在使用授权协议时还有如下附加限制：</div>
            <div className="indent">（a）被授权人在使用授权商品时有署名义务；</div>
            <div className="indent">（b）不得将授权商品原件用出版、转售、公开或其他方式提供给任何第三方；</div>
            <div className="indent">（c）不得将授权商品或本协议的任何权利转让或分享给任何第三方；</div>
            <div className="indent">（d）不得将授权商品的使用权利用于违法违规的任何目的；</div>
            <div className="indent">（e）不得自称自己是授权商品的知识产权持有者；</div>
            <div className="indent">（f）不得直接或间接协助、鼓动任何第三方进行本协议中禁止的规定。</div>
        </div>
        <div className="title">第四部分 买卖双方责任</div>
        <div className="content">
            <div className="indent">
                1、买卖双方有权根据本协议及GGAC发布的相关规则，利用GGAC发布或购买授权商品、查询用户公开信息、使用站内沟通渠道、参加本平台有关活动及有权享受本平台提供的其他有关服务。
            </div>
            <div className="indent">
                2、买卖双方有义务为自身使用户账户下发生的所有活动（包括但不仅限于发布或购买授权商品、点击同意各类协议及规则、参与各项活动等）承担责任。因买卖双方自身过失导致的任何损失均由买卖双方自行承担，相关行为包括但不仅限于：不按照交易提示操作，未及时进行交易操作，泄露交易信息。
            </div>
            <div className="indent">
                3、授权人须保证拥有在GGAC售卖的授权商品完整的知识产权，不得侵犯他人知识产权或其他合法权益的信息、不得违反国家法律、法规、行政规章的规定、不得违背社会公共利益或公共道德、不得违反GGAC的相关规定。
            </div>
            <div className="indent">
                4、被授权人有权在授权许可范围内按本协议约定合理合法地使用授权商品，不得以任何超出授权许可使用范围的方式使用授权商品。
            </div>
            <div className="indent">
                5、买卖双方在GGAC交易中遵守应当遵守诚实守信原则，不应在交易中扰乱市场秩序，妨碍正常交易，其行包括但不仅限于：发布虚假授权商品、授权商品信息不完整不明确、商品定价异常、完成虚假的交易订单、买卖双方互刷好评、恶意差评降低其他用户信用评价。
            </div>
            <div className="indent">
                6、买卖双方在授权交易过程中发生交易纠纷，应通过官方沟通渠道协商处理，达成一致后继续或终止交易。
            </div>
        </div>
        <div className="title">第五部分 GGAC责任</div>
        <div className="content">
            <div>1、GGAC将根据本协议为买卖双方提供交易相关服务。服务内容包括网站正常运行、注册用户账号、查询用户公开信息、售卖商品、购买商品、交易支付、信息交流、客服应答。</div>
            <div>2、GGAC的集市仅为用户提供交易相关服务，对用户所发布信息的合法性、真实性及其品质，以及用户履行交易的能力等，一律不承担任何担保责任。</div>
            <div>3、GGAC对双方的交易行为不加以监控，除非用户允许否则不会介入交易过程。</div>
            <div>4、GGAC有权对授权商品资料进行审查，当对授权商品内容存在疑问时，本平台有权发出通知询问用户并要求用户做出解释、改正。</div>
            <div>5、GGAC不承担对所有用户的交易行为及交易有关的其他事项进行事先审查的义务，但如果发生以下情形，本平台有权无需征得用户的同意即可限制用户的账号活动、向用户核实有关资料、发出警告通知、暂时中止服务、无限期终止本协议以及中止其余平台规则规定的用户权利：
                <div>（a）用户存在违反本协议的行为；</div>
                <div>（b）存在用户或其他第三方通知本平台，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据，而本平台无法联系到该用户核证或验证的情况；</div>
                <div>（c）根据国家法律、法规、行政规章规定、本协议的内容和本平台所掌握的事实依据，可以认定该用户存在违法或违反本协议行为的，或存在其他的不当行为的情况。</div>
            </div>
            <div>6、GGAC将对授权商品的每个成交订单收取5%~15%出售金额的服务费，其中包含网络带宽、IDC、客服运营成本、支付手续费、渠道推广费等提供授权服务所需成本及平台收益。</div>
            <div>7、GGAC依据本协议及相关规则，可以冻结、使用、先行赔付、退款、处置用户缴存并冻结在本平台账户内的有关交易资金。</div>
            <div>8、交易纠纷中，当用户通过司法机关依照法定程序要求本平台提供相关资料的，本平台将积极配合并提供有关资料。</div>
        </div>
        <div className="title">第六部分 服务的中断和终止</div>
        <div className="content">
            <div>本协议在终止前将永久有效。</div>
            <div>若发生以下情况，用户在本协议下的权利将自动终止，恕不另行通知：
                <div>（a）用户违反本协议的任何条款；</div>
                <div>（b）用户因未完成交易付款，或因任何原因发生了退款、取消订单。本协议终止后，被授权人须停止对有关授权商品所有形式的使用，并销毁授权商品的所有文件或资料。</div>
            </div>
        </div>
        <div className="title">第七部分 纠纷调解</div>
        <div className="content">
            <div>1、若买卖双方在交易中发生争议，请尽量通过官方渠道沟通协商解决，继续完成或取消交易。</div>
            <div>2、买卖双方的交易争议协商不成时，可使用本平台的调解服务。双方须向本平台提供纠纷相关材料，本平台将依据双方在本平台渠道内的沟通内容、本协议及《GGAC用户协议》的规定进行调解。使用调解服务时须注意，本平台无法对非经官方渠道的交易、约定提供包括调解服务在内的任何服务。</div>
            <div>3、如对调解决定不满意，用户仍有权中止调解服务，采取其他争议处理途径解决争议。</div>
        </div>
        <div className="title">第八部分 保密</div>
        <div className="content">
            用户保证在使用本平台过程中所获悉的属于GGAC及他方的且无法自公开渠道获得的文件及资料（包括但不限于商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）予以保密。未经该资料和文件的原提供方同意，用户不得向第三方泄露该商业秘密的全部或者部分内容。但法律、法规、行政规章另有规定或者双方另有约定的除外。
        </div>
        <div className="title">第九部分 完整协议</div>
        <div className="content">
            <div>本协议是《GGAC用户协议》为完整的、不可分割的一部分。</div>
            <div>您在使用授权服务时应当遵守《GGAC用户协议》、本协议以及其他所有的GGAC条款和规定。</div>
        </div>
    </ShopAgreementStyle>
}

export default ShopAgreement