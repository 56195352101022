import { Checkbox, Col, Input, Modal, Row, Spin } from "antd"
import { getWorkList } from "api/work";
import { useCallback, useEffect, useState } from "react"
import { DataItem } from "./type";
import { WorkCard } from "./components/work-card";
import { WorkModalStyle } from "./style";
import {NoData} from 'components/no-data'
import { CheckboxChangeEvent } from "antd/es/checkbox";
const { Search } = Input;

export const  WorkModal = (props:{
    show:boolean,
    close:() => void,
    onChange?: (value: DataItem[]) => void,
    value?: DataItem[]
}) =>{

    const  [confirmLoading, setConfirmLoading ] = useState(false)

    const  [searchValue, setSearchValue] = useState('')

    const [workList, setWorkList] = useState<DataItem[]>([])

    const [selectedList, setSelectedList] = useState<DataItem[]>(props.value!)


    const  onCancel = () =>{
        props.close()
    }

    const  onSearch = (value: string) =>{
        setSearchValue(value)
    }

    const onClickSubmit = () =>{
        
        props.onChange&&props.onChange(selectedList)
        props.close()
    }

    


    const  getList = useCallback(() =>{
        let params = {
            isPublic: 1,
            pageNumber: 1,
            pageSize: 100,
            userId: JSON.parse(localStorage.getItem('user_info')!).id,
            status: 2,
            onSale: 1,
            title: searchValue
        }
        setConfirmLoading(true)
         getWorkList(params).then(res=>{
            let arr = res.pageData.map(item=>{
                return {
                    id: item.id,
                    title: item.title,
                    coverUrl: item.coverUrl,
                    checked: selectedList.findIndex(propsItem => propsItem.id === item.id) !== -1
                }
            })
            setWorkList(arr)
         }).finally(()=>{
            setConfirmLoading(false)
         })
    },[searchValue,props.value])

    const  onChangeCheckBox = (e:CheckboxChangeEvent) =>{
        let {checked,value} = e.target
        let index = workList.findIndex(item=> item.id === value)
        if(checked) {
            let obj = selectedList.find(item=>item.id === value)
                if(obj) {
                // 已经存在不需要做什么
                } else {
                    let arr = workList
                    arr[index].checked = true
                    setWorkList(arr)
                    setSelectedList(pre=> [...pre,workList[index]])
                }
        } else {
            let obj = selectedList.find(item=>item.id === value)
                if(obj) {
                    let arr = workList
                    arr[index].checked = false
                    setWorkList(arr)
                    let arrSelect = [...selectedList]
                    setSelectedList([...arrSelect.filter(item=> item.id !==value)])
                // 已经存在不需要做什么
                } else {
                    let arr = workList
                    arr[index].checked = true
                    setWorkList(arr)
                    setSelectedList(pre=> [...pre,workList[index]])
                }

        }
    }

    


    useEffect(()=>{
        setSearchValue('')
        setSelectedList([...props.value!])
    },[props.show, props.value])

    useEffect(()=>{
        getList()
    }, [searchValue, getList])

    return <WorkModalStyle>
            <div className="select-modal-container" id="select-modal-container">
                <Modal
                    getContainer={document.getElementById('select-modal-container')!}
                    title="我的作品"
                    open={props.show}
                    cancelText="取消"
                    onCancel={onCancel}
                    okText="保存"
                    onOk={ onClickSubmit } 
                    confirmLoading={confirmLoading}
                    width={800}
                    maskClosable={false}
                    wrapClassName="select-work-modal-wrapper"
                    
                >
                    <Spin spinning={confirmLoading}>
                    <div className="content-wrapper">
                        <div className="search-wrapper">
                            <Search placeholder="请输入关键字搜索" defaultValue={searchValue}  onSearch={ onSearch} style={{ width: '100%', borderRadius: '8px' }} />
                        </div>
                        <div className="list-wrapper">
                        {workList.length===0&& 
                        <NoData></NoData>}
                           {workList.length>0&&  
                                <Row justify="start" style={{width: '100%'}}>
                                    {workList.map((item)=>{
                                        return <Col span={6} key={item.id} style={{padding: '10px'}}  >
                                                <Checkbox checked={item.checked} value={item.id} onChange={onChangeCheckBox}  className="checkbox" style={{position: 'absolute', top: '12px', right: '18px',zIndex:'1' }}></Checkbox>
                                                    <WorkCard  value={item} key={item.id} ></WorkCard>
                                                </Col>
                                     })}
                                </Row>
                            }
                        </div>
                    </div>
                    </Spin>
                </Modal>
            </div>
    </WorkModalStyle>
}