import styled from "styled-components";

export const ArticleInteractionStyle = styled.div`
    .interaction-wrapper {
      margin: ${(props) => props.theme.space.paddingLG}px 0;
      padding: ${(props) => 1.5 * props.theme.space.paddingLG}px 0;
      border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      border-bottom: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .operation-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .active {
          background-color: ${(props) =>
            props.theme.colors.colorPrimary} !important;
          .icon {
            color: ${(props) => props.theme.colors.colorBgContainer} !important;
          }
        }
        .actionIcon {
          margin: 0 ${(props) => props.theme.space.margin}px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${(props) => props.theme.colors.colorBgContainer};
          border-radius: 50% 50%;
          border: 1px solid ${(props) => props.theme.colors.colorBorder};
          cursor: pointer;
          .icon {
            width: ${(props) => props.theme.fontSizes.fontSizeXL}px;
            height: ${(props) => props.theme.fontSizes.fontSizeXL}px;
            color: ${(props) => props.theme.colors.colorPrimaryText};
          }
        }
        .interaction-item {
          margin: 0 ${(props) => props.theme.space.margin}px;
          display: flex;
          flex-direction: column;
          width: 60px;
          height: 60px;
          justify-content: center;
          cursor: pointer;
          align-items: center;
          border: 1px solid ${(props) => props.theme.colors.colorPrimary};
          border-radius: 50%;
          color: ${(props) => props.theme.colors.colorPrimary};
          .icon {
            width: ${(props) => props.theme.fontSizes.fontSizeXL}px;
            height: ${(props) => props.theme.fontSizes.fontSizeXL}px;
            color: ${(props) => props.theme.colors.colorPrimaryText};
          }
        }
        .alarm-item {
          position: absolute;
          align-self: flex-end;
          right: ${(props) =>  props.theme.space.paddingLG}px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
        }
      }
      .favour-list-wrapper {
        margin: ${(props) => props.theme.space.margin}px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .avatar-list {
          display: flex;
          margin: ${(props) => props.theme.space.marginXXS}px 0;
        }
      }
    }
`