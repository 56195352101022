import styled from "styled-components";

export const CouponModalStyle = styled.div`
  width: 464px;
  height: 452px;
  background-image: url("https://cdn-prd.ggac.com/ggac/assets/image/shop/packet-bg.png");
  position: relative;
  .close{
    position: absolute;
    right: 24px;
    cursor: pointer;
  }
  .headline {
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("https://cdn-prd.ggac.com/ggac/assets/image/shop/packet-text.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 26px;
    width: 100%;
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("https://cdn-prd.ggac.com/ggac/assets/image/shop/packet-bottom.png");
    height: 136px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .click-area{
      
        height: 50px;
        width: 80%;
        margin-top: 60px;
        cursor: pointer;
    }
    a{
        margin-bottom: 12px;
        color:#fff;
        margin-top: 10px;
    }
  }
  .list {
   position: absolute;
   top:110px;
   left: 50%;
    transform: translateX(-50%);
    overflow-y: auto;
    max-height: 250px;

    .list-item {
        margin: 0 auto;
        background-color: #fff;
        width: 280px;
       
        margin-bottom: 4px;
    border-radius: 12px;
    /* border: ${(props) =>"1px solid " + props.theme.colors.colorBorder}; */
    overflow: hidden;
        display: flex;
      .card-left {
        width: 120px;
        border-right: 2px dashed ${(props) => props.theme.colors.colorBorder};
        position: relative;
        padding: 4px 12px;
        padding-bottom: 16px;
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 1.2em;
          height: 0.6em;
          background: #FFEBC6;
          border-radius: 0 0 0.6em 0.6em;
          left: calc(100% - 0.6em);
      
          border-top-color: #fff;
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 1.2em;
          height: 0.6em;
          background: #FFEBC6;
          border-radius: 0.6em 0.6em 0 0;
          left: calc(100% - 0.6em);
  
          border-bottom: 1px solid #FFEBC6;
        }
        &:before {
          top: -1px;
        }
        &:after {
          bottom: 0px;
        }
        .money {
          color: #ff5132;
          font-weight: 600;
          display: flex;
          gap: 4px;
          align-items: flex-end;
          .sign {
            font-size: 18px;
          
          }
          .amount {
            transform: translateY(6px);
            font-size: 30px;
          }
        }
        .tip {
          color: ${(props) => props.theme.colors.colorTextTertiary};
          text-align: center;
        }
      }

      .card-right {
        width: 220px;
        padding-left: 18px;
        display: flex;
        .a {
          flex: 1;
          display: flex;
          flex-direction: column;

          justify-content: center;
          .title {
            font-size: 16px;
            text-align: left;
            font-weight: 600;
          }
          .desc {
            color: ${(props) => props.theme.colors.colorTextTertiary};
            font-size: 12px;
          }
        }
        .b {
            cursor: pointer;
            display: flex;
            align-items: center;
            background: linear-gradient(to bottom right, #FF9135 35%, #FF3B3C 100%);
            border-radius:24px;
            color: #fff;
            padding: 0 12px !important;
            height: 28px;
            margin: 20px 12px 0 0;
        }
      }
    }
  }
`;

export const CouponModalWrapStyle = styled.div`
  .ant-modal-content {
    background-color: transparent !important;
  }
`;
