import styled from "styled-components";

export const CommentWrapStyle = styled.div`

    .comment-wrapper{
        .input{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 8px 0;
        }
        .left-wrap {
            display: flex;
            align-items: center;
            gap: 15px;
        }
        .confirm-btn{
            height: 30px;
            padding: 0 16px;
        }
    }
`