import { FavoriteProductListItem } from 'types/favorite';
import { Button, Space } from 'antd';
import { RiDeleteBin2Line, RiEyeLine } from 'react-icons/ri';
import { FavoriteCardActionStyle } from 'components/uni-card/self-cards/userCenterFavoriteCard/style';


const FavoriteCardAction = ({ item, workLink, shouldRefresh }: { item: FavoriteProductListItem, workLink: string, shouldRefresh: (item: FavoriteProductListItem) => void }) => {

    const viewFavorites = () => {
        window.open(workLink)
    }

    return <FavoriteCardActionStyle>
        <Space>
            <Button
                size="large"
                shape="circle"
                onClick={(e) => {
                    e.stopPropagation()
                    shouldRefresh && shouldRefresh(item)
                }
                }
            >
                <RiDeleteBin2Line></RiDeleteBin2Line>
            </Button>
            {
                (item.expired === -0) && <Button
                    onClick={(e) => { e.stopPropagation(); viewFavorites() }}
                    size="large"
                    shape="circle"
                >
                    <RiEyeLine></RiEyeLine>
                </Button>
            }
        </Space>
    </FavoriteCardActionStyle>
}


export default FavoriteCardAction