import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel"
import { useEffect, useState } from "react"
import { apiGetAlbumList } from "api/nft-album"
import { NftSalesPlanListResponse } from "types/nft-album"
import { Button, Pagination, Spin } from "antd"
import { NoData } from "components/no-data"
import AlbumCard from "views/mall/components/album-card"
import { ShopAlbumStyle } from "./style"
import { hasToken } from "utils/functions/common"
import { loginPathWithRedirect } from "utils/functions/user"
import { useNavigate } from "react-router-dom"
import { RiAddLine } from "react-icons/ri"

const ShopAlbum = () => {

    const [pageNumber, setPageNumber] = useState(1)

    const [list, setList] = useState<NftSalesPlanListResponse[]>([])

    const [loading, setLoading] = useState(false)

    const [total, setTotal] = useState(0)

    const pageSize = 24

    const changePage = (e: any) => {
        setPageNumber(e)
    }

    const navigate = useNavigate()

    const token = hasToken()

    const toPublish = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        window.open(`/user-center/tj-publish`)
    }

    useEffect(() => {
        setLoading(true)
        apiGetAlbumList({ pageNumber, pageSize }).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [pageNumber])

    return <ShopAlbumStyle>
        <div className="btn">
            <Button
                type="primary"
                onClick={toPublish}
                icon={<RiAddLine size={18} />}
            >
                发布图鉴
            </Button>
        </div>
        <Spin spinning={loading}>
            <div className="data-list">
                {list &&
                    list.map((item, index) => {
                        return <AlbumCard item={item} key={index} />

                    })}
            </div>
            {list?.length === 0 && (
                <div style={{ padding: "100px 0" }}>
                    <NoData />
                </div>
            )}
        </Spin>
        <Pagination
            style={{ textAlign: "center", marginTop: "28px" }}
            hideOnSinglePage={true}
            current={pageNumber}
            onChange={changePage}
            showSizeChanger={false}
            pageSize={pageSize}
            total={total}
        />
    </ShopAlbumStyle>
}

export default ShopAlbum