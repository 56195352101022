import styled from "styled-components";

export const PhotoDisplayAntStyle = styled.div`
.image-wrap {
  position: relative;
  color: #fff;
  /* padding: 0 40px; */
  width: fit-content;
  margin: 0 auto;
  &:hover {
    .icon-wrap {
      visibility:visible;
    }
  }
  .operation-wrapper{
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 10px;
    z-index: 999;
    /* top: 10px; */
  }
  .icon-wrap {
    /* position: absolute; */
    margin-left: 4px;
    display: flex;
    visibility:hidden;
    align-items: center;
    gap: 2px;
    left: 20px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 8px;
    padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
    cursor: pointer;
  }
}

.ant-image .ant-image-mask{
  display: none;
}

`


