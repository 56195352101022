import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import { GuanggaoBannerStyle } from "./style";
import { getCarouselList, sendCarouselLog } from "api/common/carousel";
import { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { shuffleArray } from "utils/functions/common";

type Props = {
  positionCode: number;
  isAutoplay?: boolean
};
export function GuanggaoBannerCarousel({ positionCode, isAutoplay = false }: Props) {
  const [banners, setBanners] = useState<CarouselListItem[]>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 1,

    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    if (!isAutoplay) {
      return
    }
    if (banners?.length === 1) {
      return
    }
    const startSlide = () => {
      if (instanceRef && instanceRef.current) {
        instanceRef.current.next();
      }
    };

    const intervalId = setInterval(startSlide, 3000); // 每3秒自动播放下一张
    return () => clearInterval(intervalId);
  }, [isAutoplay, banners]);

  const getSlideList = () => {
    const params: CarouselListRequest = {
      lang: 1,
      type: positionCode,
      status: 1,
    };
    getCarouselList(params).then((res) => {
      setBanners(shuffleArray(res.pageData));
    });
  };

  useEffect(() => {
    getSlideList();
  }, []);

  const move = (type: string) => {
    if (type === "prev") {
      instanceRef.current?.prev();
    }
    if (type === "next") {
      instanceRef.current?.next();
    }
  };

  return (
    <GuanggaoBannerStyle>
      {banners && (
        <>
          <div ref={sliderRef} className="keen-slider">
            {banners.map((item, index) => {
              return (
                <div
                  className="keen-slider__slide"
                  key={index}

                  onClick={() => {
                    sendCarouselLog({ carouselId: item.id })
                    window.open(item.webUrl, "_blank");
                  }}
                >
                  <img src={item.displayImage} alt="" />
                </div>
              );
            })}
          </div>
          {loaded && instanceRef.current && banners.length > 1 && (
            <>
              <div className="control prev" onClick={() => move("prev")}>
                <RiArrowLeftSLine></RiArrowLeftSLine>
              </div>
              <div className="control next" onClick={() => move("next")}>
                <RiArrowRightSLine></RiArrowRightSLine>
              </div>
            </>
          )}
        </>
      )}
    </GuanggaoBannerStyle>
  );
}


