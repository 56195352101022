import styled from "styled-components";

export const WorkDetailNavStyle = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 ${(props) => props.theme.space.padding}px;
    top:26vh;
    .arrow{
        width: 38px;
        height: 38px;
        background: #000000;
        opacity: 0.5;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .prev{
        cursor: pointer;
        font-size: 24px;
        color:  ${(props) => props.theme.colors.colorTextSecondary};
     
    }
    .next{
        cursor: pointer;
        font-size: 24px;
        color:  ${(props) => props.theme.colors.colorTextSecondary};
    }
`