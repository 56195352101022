import { ComboUploadStyle } from "./style";
import { RiBox3Line, RiImageAddFill, RiMovieFill } from "react-icons/ri";
import { ComboImageUpdate } from "./image/update";
import { useEffect, useState } from "react";
import UploadList from "./uploadList";
import { ComboVideoUpload } from "./video/index";
import { MediaType } from "types/enums/media-type";
import { ComboThreedUpload } from "./threed";
import { ImageEditForm, ListItem, MarmosetEditForm, ThreeDEditForm, VideoEditForm } from "./type";
import { createPortal } from "react-dom";
import { ComboImageUpload } from "./image/upload";
import { message } from "antd";
import { ComboMarmosetUpload } from "./marmoset";

function ComboUpload(props: {
  value?: ListItem[];
  onChange?: (mediaList: ListItem[]) => void;
  mark?: string
}) {
  const [imageUpdateShow, setImageUpdateShow] = useState(false);
  const [imageUploadShow, setImageUploadShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [threedShow, setThreedShowShow] = useState(false);
  const [marmosetShow, setMarmosetShow] = useState(false);
  const [mediaList, setMediaList] = useState<ListItem[]>([]);

  const [imgUpdateForm, setImgUpdateForm] = useState<ImageEditForm>({
    img: {
      file: undefined,
      imgUrl: "",
    },
    effect: undefined,
    caption: "",
  });

  const [imgUploadForm, setImgUploadForm] = useState<ImageEditForm[]>([]);

  const [videoForm, setVideoForm] = useState<VideoEditForm>({
    video: {
      file: undefined,
      videoUrl: "",
    },
    coverUrl: {
      file: undefined,
      imgUrl: "",
    },
    caption: "",
  });

  const [threeDForm, setThreeDForm] = useState<ThreeDEditForm>({
    model: {
      file: undefined,
      fileUrl: "",
    },
    hdr: {
      file: undefined,
      fileUrl: "",
    },
    bg: {
      file: undefined,
      imgUrl: "",
    },
    caption: "",
  });

  const [marMosetForm, setMarMosetForm] = useState<MarmosetEditForm>({
    model: {
      file: undefined,
      fileUrl: "",
    },
    caption: "",
  });

  useEffect(() => {
    props.value &&  setMediaList(props.value!);
  }, [props.value]);

  const getMediaListFinished = () =>{
    let flag = (mediaList ?? []).every((item)=>{
      return item.percent >=100
    })
    return flag;
  }

  const onClickUploadImage = () => {
    if(!getMediaListFinished()) {
      message.warning('还有媒体正在上传中，请稍后再试')
      return
    }
    setImgUploadForm([]);
    setImageUploadShow(true);
  };

  const onClickCloseUpdateImage = () => {
    setImageUpdateShow(false);
  };

  const onClickUploadVideo = () => {
    if(!getMediaListFinished()) {
      message.warning('还有媒体正在上传中，请稍后再试')
      return
    }
    setVideoForm({
      video: {
        file: undefined,
        videoUrl: "",
      },
      coverUrl: {
        file: undefined,
        imgUrl: "",
      },
      caption: "",
    })
    setVideoShow(true);
  };

  const onClickCloseUploadVideo = () => {
    setVideoShow(false);
  };

  const onClickUploadThreed = () => {
    if(!getMediaListFinished()) {
      message.warning('还有媒体正在上传中，请稍后再试')
      return
    }
    setThreedShowShow(true);
  };

  const onClickUploadMarmoset = () => {
    if(!getMediaListFinished()) {
      message.warning('还有媒体正在上传中，请稍后再试')
      return
    }
    setMarmosetShow(true);
  };
  const onClickCloseUploadThreed = () => {
    setThreedShowShow(false);
    setMarmosetShow(false);
  };

  const handleListItemRemove = async (item: ListItem) => {
    let arr = mediaList.filter((listItem) => {
      return item.id !== listItem.id;
    });
    setMediaList([...arr]);
    props.onChange && props.onChange([...arr]);
  };
  let tempMediaList: ListItem[] = mediaList;

  const refreshMediaList = (newItem: ListItem, type: "add" | "update") => {
    // console.log(`Refreshing media list1`,newItem,mediaList)
    if (type === "add") {
      tempMediaList = [...tempMediaList, newItem];
      props.onChange && props.onChange(tempMediaList);
      setMediaList((pre) => [...pre, newItem]);
    } else {
      // console.log(mediaList)
      //修改列表
     
      let arr = tempMediaList.map((item) => {
        if (item.key === newItem.key) {
          item = Object.assign(item, newItem);
          return item;
        }
        return item;
      });
      setMediaList([...arr]);
      props.onChange && props.onChange([...arr]);

      // setMediaList((pre) =>{
      //    let arr = pre.map((item) => {
      //     if (item.key === newItem.key) {
      //     item =  Object.assign(item,newItem)
      //       return item;
      //     }
      //     return item;
      //   })
      //   props.onChange&&props.onChange([...arr])
      //   return arr
      // }
      // );
    }
  };

  const dragChangeList = (list: ListItem[]) => {
    setMediaList([...list]);
    props.onChange && props.onChange([...list]);
  };

  const  onClickCloseUploadImage = () =>{
    setImageUploadShow(false)
  }

  const handleMediaEdit = (media: ListItem) => {
    if (media.type === MediaType.image) {
      setImgUpdateForm({
        id: media.id,
        img: {
          file: undefined,
          imgUrl: media.url,
        },
        caption: media.caption!,
        effect: media.params?.specialEffectTemplateId,
      });
      setImageUpdateShow(true);
    } else if (media.type === MediaType.video) {
      setVideoForm({
        id: media.id,
        video: {
          file: undefined,
          videoUrl: media.url,
        },
        coverUrl: {
          file: undefined,
          imgUrl: media.coverUrl,
        },
        caption: media.caption!,
      });
      setVideoShow(true);
    } else if (media.type === MediaType.threed) {
      setThreeDForm({
        id: media.id,
        model: {
          file: undefined,
          fileUrl: media.url,
        },
        hdr: {
          file: undefined,
          fileUrl: media.params!.hdrImageUrl!,
        },
        bg: {
          file: undefined,
          imgUrl: media.params!.threeDBgUrl!,
        },
        caption: media.caption!,
      });
      setThreedShowShow(true);
    }else if (media.type === MediaType.marmoset) {
      setMarMosetForm({
        id: media.id,
        model: {
          file: undefined,
          fileUrl: media.url,
        },
        caption: media.caption!,
      });
      setMarmosetShow(true);
    }
  };

  return (
    <ComboUploadStyle>
      <ul className="tabs">
        <li className="tab-item active" onClick={onClickUploadImage}>
          <div className="icon">
            <RiImageAddFill />
          </div>
          <span className="text">图片</span>
        </li>
        <li className="tab-item" onClick={onClickUploadVideo}>
          <div className="icon">
            <RiMovieFill />
          </div>
          <span className="text">视频</span>
        </li>
        <li className="tab-item" onClick={onClickUploadThreed}>
          <div className="icon">
            <RiBox3Line />
          </div>
          <span className="text">3D</span>
        </li>
        <li className="tab-item" onClick={onClickUploadMarmoset}>
          <div className="icon">
           <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/logo_simple_dark64.png" alt="" width={19}/>
          </div>
          <span className="text">八猴</span>
        </li>
        {/* <li className="tab-item">
          <div className="icon">
            <RiLandscapeFill />
          </div>
          <span className="text">360全景</span>
        </li>
        <li className="tab-item">
          <div className="icon">
            <RiCameraLensFill />
          </div>
          <span className="text">媒体库</span>
        </li> */}
      </ul>
      <div className="drag-n-drop"></div>
      {/* //把modal搞到外面去，否则form中套form会报warning */}
      {createPortal(
        <>
          <ComboImageUpdate
            show={imageUpdateShow}
            params={imgUpdateForm}
            close={onClickCloseUpdateImage}
            refreshMediaList={refreshMediaList}
           
          ></ComboImageUpdate>

          <ComboImageUpload
            show={imageUploadShow}
            params={imgUploadForm}
            close={onClickCloseUploadImage}
            refreshMediaList={refreshMediaList}
            mark={props.mark}
          ></ComboImageUpload>

          <ComboVideoUpload
            show={videoShow}
            params={videoForm}
            close={onClickCloseUploadVideo}
            refreshMediaList={refreshMediaList}
            mark={props.mark}
          ></ComboVideoUpload>
          <ComboThreedUpload
            show={threedShow}
            params={threeDForm}
            close={onClickCloseUploadThreed}
            refreshMediaList={refreshMediaList}
            mark={props.mark}
          ></ComboThreedUpload>
          <ComboMarmosetUpload
           show={marmosetShow}
           params={marMosetForm}
           close={onClickCloseUploadThreed}
           refreshMediaList={refreshMediaList}
           mark={props.mark}
          ></ComboMarmosetUpload>
        </>,
        document.body
      )}

      <UploadList
        edit={handleMediaEdit}
        mediaList={mediaList}
        onRemove={handleListItemRemove}
        dragChangeList={dragChangeList}
      ></UploadList>
    </ComboUploadStyle>
  );
}

export default ComboUpload;
