import styled from 'styled-components'

export const DetailsStyle = styled.div`
    .wrap{
        position: relative;
        width: 1260px;
        margin: 0 auto;
        display: flex;
        .left{
            width: 1000px;
            padding-top: 50px;
            .pre-image{
                width: 500px;
                height: 500px;
                background-size: cover;
            }
            .image-bottom{
                display: flex;
                margin-top: ${props => props.theme.space.margin}px;
                width: 500px;
                overflow-x: scroll;
            }
            .image-item{
                width: 150px;
                height: 150px;
                margin-right:${props => props.theme.space.marginXS}px;
                background-size: cover;
            }
            .description{
                width: 100%;
                background-color: ${props => props.theme.colors.colorBlack1Ggac};
                height: 50px;
                line-height: 50px;
                margin-top: ${props => props.theme.space.margin}px;
                padding: 0 ${props => props.theme.space.padding}px;
            }
            
        }
        .title{
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 15px;
        }
        .right{
            /* position: fixed;
            right: -5px;
            top: 50px; */
            padding-top: 50px;
            width: 260px;
            height: calc(100vh - 50px);
            background-color: #f8f8f8;
            /* overflow-y: scroll; */
            overflow-x: hidden;
            font-size: 14px;
            padding-top: 50px;
            padding: 50px 25px 0;
            .item{
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                .value{
                    font-weight: bold;
                }
            }
            .ant-btn{
                margin-bottom: ${props => props.theme.space.marginXS}px;
            }
            .buy {
                width: 100%;
            }
            .btn {
                width: 100%;
                height: 36px;
                letter-spacing: 2px;
                line-height: 36px;
                text-align: center;
                padding: 0;
            }
            .is_focused{
                background-color: #94969f !important;
            }
            .sub-title{
                margin-top: ${props => props.theme.space.margin}px;
            }
            .user{
                display: flex;
                align-items: center;
                
                &-image{
                    border-radius: 50%;
                    width: 80px;
                    height: 80px;
                    background-size: cover;
                    margin-right: ${props => props.theme.space.marginXS}px;
                }

                &-detail{
                    
                }

            }
        }
        .modal{
            .title{
                font-size: 25px;
                text-align: center;
            }
            .code-wrap{
                text-align: center;
            }
        }
    }
`