import { UserArticleStyle } from "./style";
import { useCallback, useEffect, useState } from "react";
import { getArticleList } from "api/article";
import { Pagination, Spin } from "antd";
import { useMemo } from "react";
import { ArticleListItem, ArticleListRequest } from "types/article";
import { PaginationResponse } from "types/common/axios";
import { NoData } from "components/no-data";
import { useParams } from "react-router";
import { dynamicColumns } from "utils/functions/common";
import HomePageArticleCard from "components/uni-card/self-cards/homePageArticleCard";

function WorkList() {
    const [articleList, setArticleList] = useState<ArticleListItem[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [articleData, setArticleData] = useState<PaginationResponse<ArticleListItem>>();
    const { id } = useParams()
    const [loading, setLoading] = useState(false)

    const params: ArticleListRequest = useMemo(() => {
        const data: ArticleListRequest = {
            pageSize: dynamicColumns()*3,
            pageNumber,
            userId: Number(id),
            onSale: 1
        };
        return data;
    }, [pageNumber, id]);

    const getList = useCallback(async () => {
        setLoading(true)
        getArticleList(params).then(res => {
            setArticleList(res.pageData);
            setArticleData(res);
        }).finally(() => {
            setLoading(false)
        })

    }, [params]);

    useEffect(() => {
        getList();
    }, [getList]);

    const changePage = (e: number) => {
        setPageNumber(e);
    };

    return (
        <UserArticleStyle>
            <Spin spinning={loading}>
                <div className={`list ${articleList.length === 0 ? 'no-list' : ''}`}>
                    {articleList.length === 0 && (
                        <div
                            style={{ width: "100%", padding: "100px 0", position: "relative" }}
                        >
                            <NoData></NoData>
                        </div>
                    )}
                    {articleList.length > 0 && articleList.map((item, index) => {
                        return <HomePageArticleCard item={item} key={index}></HomePageArticleCard>
                    })}
                </div>
            </Spin>
            <Pagination
                pageSize={params.pageSize}
                style={{ textAlign: "center", marginTop: "28px" }}
                hideOnSinglePage={true}
                onChange={changePage}
                total={articleData?.totalSize}
                showSizeChanger={false}
                current={pageNumber}
            />
        </UserArticleStyle>
    );
}

export default WorkList;
