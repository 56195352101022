import styled from "styled-components";

export const RelateWorkStyle = styled.div`
    .ant-pagination {
        margin-top: ${props => props.theme.space.marginXS}px !important;
    }
    .wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: center;
        gap: ${props => props.theme.space.marginXS}px;

        .active {
            border: 3px solid ${props => props.theme.colors.colorPrimary} !important;
            border-radius: ${props => props.theme.border.borderRadius}px;
            overflow: hidden;
        }
        
        .work {
            width: 100%;
            border: 3px solid transparent;
            border-radius: ${props => props.theme.border.borderRadiusSM}px;
                overflow: hidden;
            .cover {
                width: 100%;
                height: 100px;
                background-size: cover;
                cursor: pointer;

            }
            .name {
                text-align: center;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                padding: 4px 2px;
                width: 106px;
            }
            
        }
        .import {
                text-align: center;
                cursor: pointer;
                font-size: 12px;
                &:hover{
                    color: ${props => props.theme.colors.colorPrimary};
                }
            }
    }

    .btns {
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-top: ${props => props.theme.space.marginXS}px;
    }
`