import { configureStore } from '@reduxjs/toolkit'
import configReducer from './reducer/config'
import userReducer from './reducer/user'
import utilsReducer from './reducer/utils'
import imReducer from './reducer/im'
import userRedDotsReducer from './reducer/user-red-dots'
export const store = configureStore({
    reducer: {
      config: configReducer,
      user: userReducer,
      headerType:utilsReducer,
      im:imReducer,
      userRedDots:userRedDotsReducer
    }
})