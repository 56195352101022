export const emojiJson = [
    //评论表情图片json
    {
        title: "表情、人物",
        logo: `https://cdn.ggac.com/img/开心.png`,
        id: 1,
        em_list: [
            {
                url: `https://cdn.ggac.com/img/开心.png`,
                title: "[开心]",
            },
            {
                url: `https://cdn.ggac.com/img/大笑.png`,
                title: "[大笑]",
            },
            {
                url: `https://cdn.ggac.com/img/笑容可掬.png`,
                title: "[笑容可掬]",
            },
            {
                url: `https://cdn.ggac.com/img/咧嘴眯眼.png`,
                title: "[咧嘴眯眼]",
            },
            {
                url: `https://cdn.ggac.com/img/高兴.png`,
                title: "[高兴]",
            },
            {
                url: `https://cdn.ggac.com/img/颠倒的脸.png`,
                title: "[颠倒的脸]",
            },
            {
                url: `https://cdn.ggac.com/img/愉快.png`,
                title: "[愉快]",
            },
            {
                url: `https://cdn.ggac.com/img/满意.png`,
                title: "[满意]",
            },
            {
                url: `https://cdn.ggac.com/img/托脸.png`,
                title: "[托脸]",
            },
            {
                url: `https://cdn.ggac.com/img/思考.png`,
                title: "[思考]",
            },
            {
                url: `https://cdn.ggac.com/img/呲牙.png`,
                title: "[呲牙]",
            },
            {
                url: `https://cdn.ggac.com/img/眨眼.png`,
                title: "[眨眼]",
            },
            {
                url: `https://cdn.ggac.com/img/调皮.png`,
                title: "[调皮]",
            },
            {
                url: `https://cdn.ggac.com/img/馋.png`,
                title: "[馋]",
            },
            {
                url: `https://cdn.ggac.com/img/吐舌.png`,
                title: "[吐舌]",
            },
            {
                url: `https://cdn.ggac.com/img/财迷.png`,
                title: "[财迷]",
            },
            {
                url: `https://cdn.ggac.com/img/喜欢.png`,
                title: "[喜欢]",
            },
            {
                url: `https://cdn.ggac.com/img/流口水.png`,
                title: "[流口水]",
            },
            {
                url: `https://cdn.ggac.com/img/亲吻.png`,
                title: "[亲吻]",
            },
            {
                url: `https://cdn.ggac.com/img/接吻.png`,
                title: "[接吻]",
            },

            {
                url: `https://cdn.ggac.com/img/长鼻子.png`,
                title: "[长鼻子]",
            },
            {
                url: `https://cdn.ggac.com/img/闭嘴.png`,
                title: "[闭嘴]",
            },

            {
                url: `https://cdn.ggac.com/img/汗水.png`,
                title: "[汗水]",
            },
            {
                url: `https://cdn.ggac.com/img/破涕为笑.png`,
                title: "[破涕为笑]",
            },

            {
                url: `https://cdn.ggac.com/img/光环.png`,
                title: "[光环]",
            },
            {
                url: `https://cdn.ggac.com/img/得意.png`,
                title: "[得意]",
            },
            {
                url: `https://cdn.ggac.com/img/酷.png`,
                title: "[酷]",
            },
            {
                url: `https://cdn.ggac.com/img/郁闷.png`,
                title: "[郁闷]",
            },
            {
                url: `https://cdn.ggac.com/img/脸红.png`,
                title: "[脸红]",
            },
            {
                url: `https://cdn.ggac.com/img/打喷嚏.png`,
                title: "[打喷嚏]",
            },
            {
                url: `https://cdn.ggac.com/img/困.png`,
                title: "[困]",
            },
            {
                url: `https://cdn.ggac.com/img/睡觉.png`,
                title: "[睡觉]",
            },
            {
                url: `https://cdn.ggac.com/img/晕.png`,
                title: "[晕]",
            },
            {
                url: `https://cdn.ggac.com/img/张开嘴.png`,
                title: "[张开嘴]",
            },
            {
                url: `https://cdn.ggac.com/img/惊讶.png`,
                title: "[惊讶]",
            },
            {
                url: `https://cdn.ggac.com/img/吃惊.png`,
                title: "[吃惊]",
            },
            {
                url: `https://cdn.ggac.com/img/汗.png`,
                title: "[汗]",
            },
            {
                url: `https://cdn.ggac.com/img/苦笑.png`,
                title: "[苦笑]",
            },
            {
                url: `https://cdn.ggac.com/img/失望.png`,
                title: "[失望]",
            },
            {
                url: `https://cdn.ggac.com/img/低落.png`,
                title: "[低落]",
            },
            {
                url: `https://cdn.ggac.com/img/困惑.png`,
                title: "[困惑]",
            },
            {
                url: `https://cdn.ggac.com/img/苦恼.png`,
                title: "[苦恼]",
            },
            {
                url: `https://cdn.ggac.com/img/疲惫厌烦.png`,
                title: "[疲惫厌烦]",
            },
            {
                url: `https://cdn.ggac.com/img/愁眉苦脸.png`,
                title: "[愁眉苦脸]",
            },
            {
                url: `https://cdn.ggac.com/img/面无表情.png`,
                title: "[面无表情]",
            },
            {
                url: `https://cdn.ggac.com/img/伤心.png`,
                title: "[伤心]",
            },
            {
                url: `https://cdn.ggac.com/img/哭泣.png`,
                title: "[哭泣]",
            },
            {
                url: `https://cdn.ggac.com/img/哭.png`,
                title: "[哭]",
            },

            {
                url: `https://cdn.ggac.com/img/挣扎.png`,
                title: "[挣扎]",
            },
            {
                url: `https://cdn.ggac.com/img/受伤.png`,
                title: "[受伤]",
            },
            {
                url: `https://cdn.ggac.com/img/白眼.png`,
                title: "[白眼]",
            },
            {
                url: `https://cdn.ggac.com/img/恐惧.png`,
                title: "[恐惧]",
            },
            {
                url: `https://cdn.ggac.com/img/焦虑.png`,
                title: "[焦虑]",
            },
            {
                url: `https://cdn.ggac.com/img/恐惧加尖叫.png`,
                title: "[恐惧加尖叫]",
            },
            {
                url: `https://cdn.ggac.com/img/吐了.png`,
                title: "[吐了]",
            },
            {
                url: `https://cdn.ggac.com/img/瞪眼.png`,
                title: "[瞪眼]",
            },
            {
                url: `https://cdn.ggac.com/img/愤怒.png`,
                title: "[愤怒]",
            },
            {
                url: `https://cdn.ggac.com/img/鼻子冒白气.png`,
                title: "[鼻子冒白气]",
            },
            {
                url: `https://cdn.ggac.com/img/生病.png`,
                title: "[生病]",
            },
            {
                url: `https://cdn.ggac.com/img/发烧.png`,
                title: "[发烧]",
            },

            {
                url: `https://cdn.ggac.com/img/骷髅.png`,
                title: "[骷髅]",
            },
            {
                url: `https://cdn.ggac.com/img/交叉骨.png`,
                title: "[骷髅/交叉骨]",
            },
            {
                url: `https://cdn.ggac.com/img/幽灵.png`,
                title: "[幽灵]",
            },
            {
                url: `https://cdn.ggac.com/img/邪恶.png`,
                title: "[邪恶]",
            },
            {
                url: `https://cdn.ggac.com/img/恶魔.png`,
                title: "[恶魔]",
            },
            {
                url: `https://cdn.ggac.com/img/食人魔.png`,
                title: "[食人魔]",
            },
            {
                url: `https://cdn.ggac.com/img/外星人.png`,
                title: "[外星人]",
            },
            {
                url: `https://cdn.ggac.com/img/小丑.png`,
                title: "[小丑]",
            },
            {
                url: `https://cdn.ggac.com/img/爷爷.png`,
                title: "[爷爷]",
            },
            {
                url: `https://cdn.ggac.com/img/女人.png`,
                title: "[女人]",
            },

            {
                url: `https://cdn.ggac.com/img/宝宝.png`,
                title: "[宝宝]",
            },

            {
                url: `https://cdn.ggac.com/img/笑_xiao.png`,
                title: "[笑/xiao]",
            },

            {
                url: `https://cdn.ggac.com/img/亲吻_qw.png`,
                title: "[亲吻/qw]",
            },

            {
                url: `https://cdn.ggac.com/img/喜欢_xh.png`,
                title: "[喜欢/xh]",
            },
            {
                url: `https://cdn.ggac.com/img/破涕为笑_ptwx.png`,
                title: "[破涕为笑/ptwx]",
            },
            {
                url: `https://cdn.ggac.com/img/得意_dy.png`,
                title: "[得意/dy]",
            },
            {
                url: `https://cdn.ggac.com/img/汗_han.png`,
                title: "[汗/han]",
            },
            {
                url: `https://cdn.ggac.com/img/恐惧_kj.png`,
                title: "[恐惧/kj]",
            },
            {
                url: `https://cdn.ggac.com/img/捂嘴.png`,
                title: "[捂嘴]",
            },
            {
                url: `https://cdn.ggac.com/img/捂眼.png`,
                title: "[捂眼睛]",
            },
            {
                url: `https://cdn.ggac.com/img/不听.png`,
                title: "[不听不听]",
            },
            {
                url: `https://cdn.ggac.com/img/新月脸.png`,
                title: "[新月脸]",
            },
            {
                url: `https://cdn.ggac.com/img/满月脸.png`,
                title: "[满月脸]",
            },
            {
                url: `https://cdn.ggac.com/img/舌头.png`,
                title: "[舌头]",
            },
            {
                url: `https://cdn.ggac.com/img/强.png`,
                title: "[强]",
            },
            {
                url: `https://cdn.ggac.com/img/耶.png`,
                title: "[耶]",
            },
            {
                url: `https://cdn.ggac.com/img/强壮.png`,
                title: "[强壮]",
            },
            {
                url: `https://cdn.ggac.com/img/鼓掌.png`,
                title: "[鼓掌]",
            },
            {
                url: `https://cdn.ggac.com/img/拳头.png`,
                title: "[拳头]",
            },
            {
                url: `https://cdn.ggac.com/img/上.png`,
                title: "[上]",
            },
            {
                url: `https://cdn.ggac.com/img/下.png`,
                title: "[下]",
            },
            {
                url: `https://cdn.ggac.com/img/左.png`,
                title: "[左]",
            },
            {
                url: `https://cdn.ggac.com/img/右.png`,
                title: "[右]",
            },
            {
                url: `https://cdn.ggac.com/img/好的.png`,
                title: "[好的]",
            },
            {
                url: `https://cdn.ggac.com/img/双手合十.png`,
                title: "[双手合十]",
            },
            {
                url: `https://cdn.ggac.com/img/zd.png`,
                title: "[炸弹]",
            },
            // {
            //     url: `https://cdn.ggac.com/img/便便.png`,
            //     title: "[便便]",
            // },
            {
                url: `https://cdn.ggac.com/img/心碎.png`,
                title: "[心碎]",
            },
            {
                url: `https://cdn.ggac.com/img/心.png`,
                title: "[心]",
            },

            {
                url: `https://cdn.ggac.com/img/闪闪发光.png`,
                title: "[闪闪发光]",
            },
            {
                url: `https://cdn.ggac.com/img/跳动的心.png`,
                title: "[跳动的心]",
            },
            {
                url: `https://cdn.ggac.com/img/旋转心.png`,
                title: "[旋转心]",
            },
            {
                url: `https://cdn.ggac.com/img/一见钟情.png`,
                title: "[一见钟情]",
            },
            {
                url: `https://cdn.ggac.com/img/可.png`,
                title: "[可]",
            },
            {
                url: `https://cdn.ggac.com/img/祝.png`,
                title: "[祝]",
            },
            {
                url: `https://cdn.ggac.com/img/秘.png`,
                title: "[秘]",
            },
            {
                url: `https://cdn.ggac.com/img/皇冠.png`,
                title: "[皇冠]",
            },

            {
                url: `https://cdn.ggac.com/img/玫瑰花.png`,
                title: "[玫瑰花]",
            },

            {
                url: `https://cdn.ggac.com/img/喝彩.png`,
                title: "[喝彩]",
            },
            {
                url: `https://cdn.ggac.com/img/喝彩_彩带.png`,
                title: "[喝彩/彩带]",
            },
            {
                url: `https://cdn.ggac.com/img/烟花.png`,
                title: "[烟花]",
            },

            {
                url: `https://cdn.ggac.com/img/烟火.png`,
                title: "[烟火]",
            },

            {
                url: `https://cdn.ggac.com/img/星星.png`,
                title: "[星星]",
            },
        ],
    },
    {
        title: "物体",
        logo: `https://cdn.ggac.com/img/16.png`,
        id: 2,
        em_list: [
            {
                url: `https://cdn.ggac.com/img/29.png`,
                title: "[咖啡]",
            },
            {
                url: `https://cdn.ggac.com/img/7.png`,
                title: "[茶]",
            },
            {
                url: `https://cdn.ggac.com/img/34.png`,
                title: "[画板]",
            },
            {
                url: `https://cdn.ggac.com/img/1.png`,
                title: "[药丸]",
            },
            {
                url: `https://cdn.ggac.com/img/2.png`,
                title: "[钱袋]",
            },
            {
                url: `https://cdn.ggac.com/img/3.png`,
                title: "[南瓜灯]",
            },
            {
                url: `https://cdn.ggac.com/img/4.png`,
                title: "[放大镜]",
            },
            {
                url: `https://cdn.ggac.com/img/5.png`,
                title: "[奖杯]",
            },
            {
                url: `https://cdn.ggac.com/img/6.png`,
                title: "[蝴蝶结]",
            },
            {
                url: `https://cdn.ggac.com/img/8.png`,
                title: "[牛奶]",
            },
            {
                url: `https://cdn.ggac.com/img/9.png`,
                title: "[牛角包]",
            },
            {
                url: `https://cdn.ggac.com/img/10.png`,
                title: "[甜甜圈]",
            },
            {
                url: `https://cdn.ggac.com/img/11.png`,
                title: "[啤酒]",
            },
            {
                url: `https://cdn.ggac.com/img/12.png`,
                title: "[饭团]",
            },
            {
                url: `https://cdn.ggac.com/img/13.png`,
                title: "[鸡腿]",
            },
            {
                url: `https://cdn.ggac.com/img/14.png`,
                title: "[爆米花]",
            },
            {
                url: `https://cdn.ggac.com/img/15.png`,
                title: "[米饭]",
            },
            {
                url: `https://cdn.ggac.com/img/16.png`,
                title: "[棒棒糖]",
            },
            {
                url: `https://cdn.ggac.com/img/17.png`,
                title: "[蛋糕]",
            },
            {
                url: `https://cdn.ggac.com/img/18.png`,
                title: "[巧克力]",
            },
            {
                url: `https://cdn.ggac.com/img/19.png`,
                title: "[糖果]",
            },
            {
                url: `https://cdn.ggac.com/img/20.png`,
                title: "[热狗]",
            },
            {
                url: `https://cdn.ggac.com/img/21.png`,
                title: "[苹果]",
            },

            {
                url: `https://cdn.ggac.com/img/22.png`,
                title: "[草莓]",
            },
            {
                url: `https://cdn.ggac.com/img/23.png`,
                title: "[柠檬]",
            },
            {
                url: `https://cdn.ggac.com/img/24.png`,
                title: "[猕猴桃]",
            },

            {
                url: `https://cdn.ggac.com/img/25.png`,
                title: "[葡萄]",
            },
            {
                url: `https://cdn.ggac.com/img/26.png`,
                title: "[桃子]",
            },
            {
                url: `https://cdn.ggac.com/img/27.png`,
                title: "[玉米]",
            },
            {
                url: `https://cdn.ggac.com/img/28.png`,
                title: "[冰沙]",
            },
            {
                url: `https://cdn.ggac.com/img/30.png`,
                title: "[薯条]",
            },

            {
                url: `https://cdn.ggac.com/img/31.png`,
                title: "[煎蛋]",
            },
            {
                url: `https://cdn.ggac.com/img/32.png`,
                title: "[红薯]",
            },
            {
                url: `https://cdn.ggac.com/img/33.png`,
                title: "[圣诞树]",
            },
        ],
    },
    {
        title: "自然、动物",
        logo: `https://cdn.ggac.com/img/猪头.png`,
        id: 3,
        em_list: [
            {
                url: `https://cdn.ggac.com/img/猪头.png`,
                title: "[猪头]",
            },
            {
                url: `https://cdn.ggac.com/img/猪鼻子.png`,
                title: "[猪鼻子]",
            },
            {
                url: `https://cdn.ggac.com/img/小猪.png`,
                title: "[小猪]",
            },
            {
                url: `https://cdn.ggac.com/img/独角兽.png`,
                title: "[独角兽]",
            },
            {
                url: `https://cdn.ggac.com/img/猴子.png`,
                title: "[猴子]",
            },
            {
                url: `https://cdn.ggac.com/img/狐狸.png`,
                title: "[狐狸]",
            },
            {
                url: `https://cdn.ggac.com/img/老虎.png`,
                title: "[老虎]",
            },
            {
                url: `https://cdn.ggac.com/img/马.png`,
                title: "[马]",
            },
            {
                url: `https://cdn.ggac.com/img/猫头鹰.png`,
                title: "[猫头鹰]",
            },
            {
                url: `https://cdn.ggac.com/img/奶牛.png`,
                title: "[奶牛]",
            },
            {
                url: `https://cdn.ggac.com/img/青蛙.png`,
                title: "[青蛙]",
            },
            {
                url: `https://cdn.ggac.com/img/狮子.png`,
                title: "[狮子]",
            },
            {
                url: `https://cdn.ggac.com/img/兔子.png`,
                title: "[兔子]",
            },
            {
                url: `https://cdn.ggac.com/img/熊.png`,
                title: "[熊]",
            },
            {
                url: `https://cdn.ggac.com/img/熊猫.png`,
                title: "[熊猫]",
            },
            {
                url: `https://cdn.ggac.com/img/太阳.png`,
                title: "[太阳]",
            },
            {
                url: `https://cdn.ggac.com/img/月亮.png`,
                title: "[月亮]",
            },
            {
                url: `https://cdn.ggac.com/img/彩虹.png`,
                title: "[彩虹]",
            },
            {
                url: `https://cdn.ggac.com/img/龙卷风.png`,
                title: "[龙卷风]",
            },
            {
                url: `https://cdn.ggac.com/img/雪花.png`,
                title: "[雪花]",
            },
            {
                url: `https://cdn.ggac.com/img/闪电.png`,
                title: "[闪电]",
            },
            {
                url: `https://cdn.ggac.com/img/枫叶.png`,
                title: "[枫叶]",
            },
            {
                url: `https://cdn.ggac.com/img/花束.png`,
                title: "[花束]",
            },
            {
                url: `https://cdn.ggac.com/img/四叶草.png`,
                title: "[四叶草]",
            },
            {
                url: `https://cdn.ggac.com/img/仙人掌.png`,
                title: "[仙人掌]",
            },
        ],
    },
]