import styled from "styled-components";

export const EvaluateOrderStyle = styled.div`
    .btn {
        text-align: center;
    }
    .tip{
        color: #aaa;
        margin-left: 75px;
    }
    .imageList {
        .ant-form-item {
            margin-bottom: 0 !important;
        }
    }
`