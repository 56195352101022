import { useEffect, useState } from "react";
import { AdStyle } from "./style";
import { getCarouselList, sendCarouselLog } from "api/common/carousel";
import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import { shuffleArray } from "utils/functions/common";

function Ad() {
  const [banner, setBanner] = useState<CarouselListItem>();

  useEffect(() => {
    const params: CarouselListRequest = {
      lang: 1,
      type: 19,
      status: 1,
    };
    getCarouselList(params).then((res) => {
      setBanner(shuffleArray(res.pageData).pop())
    });
  }, []);

  return (
    <AdStyle>
    {banner && <div
        className="holder"
        onClick={()=>{
          sendCarouselLog({carouselId:banner.id})
          window.open(banner.webUrl)
        }}
        style={{
          backgroundImage:`url(${banner.displayImage})`,
        }}
      >
      </div>}  
    </AdStyle>
  );
}

export default Ad;
