import { WorkListItem } from 'types/work'
import UniCardLayout from '../../layout';
import PrizeIcons from '../../common/prize-icons';
import WorkIcons from '../../common/hot-icons';
import { translateCardCategoryText } from 'utils/functions/common';
import UserCenterAlbumAction from './userCenterAlbumAction';
import CardTitleRender from '../../common/card-title/work';
import MediaIconsWork from '../../common/media-icons/work';
import UserCenterAlbumStatus from './userCenterAlbumStatus';


function UserCenterAlbumCard({ item, deleteAlbum }: { item: WorkListItem, deleteAlbum: (item: WorkListItem) => void }) {

  const UiObject = {
    coverUrl: item.coverUrl,
    title: <CardTitleRender item={item} />,
    subtitle: <span> {item.dictMap.mediaCategory} |  {translateCardCategoryText(item.categoryList)}</span>,
    view: item.viewCount,
    hot: item.hot,
    date: item.lastSubmitTime,
    centerNode: <UserCenterAlbumAction item={item} deleteAlbum={deleteAlbum}></UserCenterAlbumAction>,
    icons: WorkIcons({ item }),
    workLink: `/work/detail/${item.id}`,
    leftBottomNode: <UserCenterAlbumStatus item={item}></UserCenterAlbumStatus>,
    rightTopNode: PrizeIcons({ item }),
    rightBottomNode: MediaIconsWork({ item }),
    isPublish: true
  }

  return UniCardLayout({ item: UiObject });
}

export default UserCenterAlbumCard