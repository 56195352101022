import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const ContestWorkStyle = styled.div`
    .list {
        display: grid;
        --auto-grid-min-size: 19rem;
        /* grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        ); */
        grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
        gap: 1rem;
        padding: ${props => props.theme.space.marginLG}px;
        &>div {
          display: grid;
        }
    }
    .commpetiton {
        display: flex;
        justify-content: center;
        gap: ${props => props.theme.space.paddingLG}px;
        align-items: center;
        background-color: rgb(230, 230, 230);
        padding: ${props => props.theme.space.padding}px;
        margin-top: ${props => props.theme.space.marginLG}px;
        font-size: 16px;
        .stage-active {
            color: ${props => props.theme.colors.colorPrimary};
        }
        &-item {
            cursor: pointer;
        }
        &>div:hover {
            color: ${props => props.theme.colors.colorPrimary};
        }
    }
    #regulation-type {
        position: relative;
        .text {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 25px;
        }
    }
    .right-wrap {
        display: flex;
        justify-content: flex-end;
        gap: 30px;
        align-items: center;
        padding: ${props => props.theme.space.paddingLG}px;
        padding-bottom: 0;
        .rule {
            cursor: pointer;
            font-weight: bold;
        }
    }
`