import styled from "styled-components";

export const ReplyItemStyle = styled.div`
  padding: 0 ${(props) => props.theme.space.padding}px;
  border-bottom: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
  .topic {
    margin-top: 14px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #000;
      display: flex;
      .tag {
          padding: 0 3px !important;
          height: 17px !important;
          line-height: 15px;
          border-radius: 3px !important;
          margin-top: 3px;
          position: relative;
          top: 3px;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
  }
  .user {
    .avatar {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      position: relative;
      .role {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        left: 26px;
        bottom: 0px;
        cursor: pointer;
        font-size: 12px;
        font-weight: bolder;
        &.yellow {
          background-color: ${(props) => props.theme.colors.colorPrimary};
        }
        &.green {
          background-color: #52c41a;
        }
        &.purple {
          background-color: #722ed1;
        }
        &.blue {
          background-color: #1677ff;
        }
        &.hide {
          display: none;
        }
      }
    }
    .user-name {
      font-weight: 600;
    }
  }
  .actions {
  }
  .brief-reply {
    display: flex;
    align-items: flex-start;
    gap: 14px;
    padding: ${(props) => props.theme.space.paddingXS}px 0;
    position: relative;
    min-height: 90px;
    .thumbnail {
      width: 190px;
      height: 105px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .video {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
    }
    .brief-content {
      flex: 1;
      padding: ${(props) => props.theme.space.paddingXS}px 0;
      height: 80px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* Number of lines to show */
      -webkit-box-orient: vertical;
      .text {
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0;
      }
    }

    
  }
  .more-text {
      /* position: absolute;
      right: 0;
      bottom: 10px; */
      float: right;
      color: ${(props) => props.theme.colors.colorPrimaryActive};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
    .more-text-0 {
      /* position: absolute;
      right: 0;
      bottom: 10px; */
      float: right;
      color: ${(props) => props.theme.colors.colorPrimaryActive};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
  .content-body {
    position: relative;
    display: flex;
    /* align-items: center; */
    margin-top: 10px;
    .ant-tag {
      height: max-content;
    }
    .content {
      p {
        margin: 0 !important;
      }
      img {
        max-width: 100%;
      }
    }

    .more-text {
      display: block;
      text-align: right;
      padding: 5px;
      color: ${(props) => props.theme.colors.colorPrimaryActive};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
    .more-text-0 {
      display: block;
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 5px;
      width: 60px;
      color: ${(props) => props.theme.colors.colorPrimaryActive};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
  }

  .edit-time {
    cursor: pointer;
    display: block;
    margin-top: 10px;
    color: ${(props) => props.theme.colors.colorTextTertiary};
    &:hover {
      color: ${(props) => props.theme.colors.colorText};
    }
  }

  .interaction {
    display: flex;
    align-items: center;
    margin: 14px 0;
    .icon-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${(props) => props.theme.colors.colorTextSecondary};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
  }
`;
