import styled from "styled-components";

export const SearchListStyle = styled.div`
    .loading {
        display: flex;
        justify-content: center;
        span {
            margin-left: ${props => props.theme.space.marginXS}px;
        }
    }
    .search-popup{
        position: absolute;
        width: 98%;
        top: 50px;
        left: 5px;
        z-index: 200;
        background-color: #fff;
        box-shadow: ${(props) => props.theme.shadow.boxShadow};
        padding: ${(props) => props.theme.space.padding}px;
        user-select: none;
        border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
        .divider{
            font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        }
        .divider::after{
            color: #000;
        }
        .ant-divider {
            margin: ${props=>props.theme.space.marginXS}px 0;
            margin-bottom: 0;
        }
        .popup-list {
            .list-item {
                padding: ${(props) => props.theme.space.paddingSM}px ${(props) => props.theme.space.padding}px;
                &:hover {
                    background-color: ${(props => props.theme.colors.colorPrimaryBg)};
                  
                    cursor: pointer;
                    border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
                }
            }
        }
    }
`