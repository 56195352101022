import styled from "styled-components";

export const WorkDetailNavStyle = styled.div`
    /* position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 ${(props) => props.theme.space.padding}px;
    top:26vh;
    z-index: 1; */
    position: relative;
    .arrow{
        position: fixed;
        top:55vh;
        width: 38px;
        height: 38px;
        background: #000000;
        opacity: 0.5;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;  
        cursor: pointer;      
    }
    .prev{
        font-size: 24px;
        color:  ${(props) => props.theme.colors.colorTextSecondary};
        left: 50px;
    }
    .next{
        font-size: 24px;
        color:  ${(props) => props.theme.colors.colorTextSecondary};
        right: 440px;
    }
`