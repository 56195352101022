import { useState, useEffect, useCallback } from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<string>('none'); 

  const handleScroll =  useCallback((e: any)=> {
    if(scrollPosition >e.target.scrollTop) {
      setScrollDirection('up')
    } else{
      setScrollDirection('down');
    }
    setScrollPosition(e.target.scrollTop);
  },[scrollPosition])

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScroll(e), {
      capture: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);


  return [scrollPosition,scrollDirection];
};

export default useScrollPosition;
