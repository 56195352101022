import {  Tooltip,  } from "antd"
import { ArticleHeaderStyle } from "./style"
import { RiArrowDropRightLine, RiEyeLine,  RiHeartLine,  RiMessageLine } from "react-icons/ri"
import { ArticleDetailResponse } from "types/article"


import { UserBaseInfo } from "types/user"
// import { postFollowUser } from "api/user";
// import { postToRecommendWork } from "api/work";

type Props = {
  detail: ArticleDetailResponse,
  commentCount: number,
  onDetailChange: (detail: ArticleDetailResponse) => void,
}

function ArticleHeader({ detail,  commentCount }: Props) {

  return (
    <ArticleHeaderStyle>

      <div className="header-wrapper">
        <div className="header-left">
          <div className="header-item title-item">{detail?.title}</div>
          <div className="header-item time-item">
            更新于 : {detail?.updateTime}
            <Tooltip title={<><div>{"创建于 : " + detail?.submitTime}</div></>}>
              <RiArrowDropRightLine
                style={{ marginLeft: "10px" }}
                size={22}
              ></RiArrowDropRightLine>
            </Tooltip>
          </div>
          <div className="bottom">

            <div className="header-item category-item">
              {
                detail?.categoryList.length > 1 && detail?.categoryList[1] && <> {detail?.categoryList[1].name}</>
              }
            </div>

            <div className="header-item interaction-item">
              <div className="icon">
                <RiEyeLine size={"18px"} />{" "}
                <span className="data">{detail?.viewCount}</span>
              </div>
              <div className="icon">
                <RiHeartLine size={"18px"} />{" "}
                <span className="data">{detail?.likeCount}</span>
              </div>
              <div className="icon">
                <RiMessageLine size={"18px"} />{" "}
                <span className="data">{commentCount}</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </ArticleHeaderStyle>
  )
}

export default ArticleHeader