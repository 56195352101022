import styled from "styled-components";

export const RedeemCardStyle = styled.div`
    margin-top: ${props => props.theme.space.margin}px;
    .list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${props => props.theme.space.margin}px;
        background-color: ${props => props.theme.colors.colorBlack1Ggac};
        padding: ${props => props.theme.space.paddingLG}px;
        border-radius: ${props => props.theme.border.borderRadiusLG}px;
        &-item {
            .cover {
                width: 100%;
                height: 250px;
                background-size: cover;
                border-radius: ${props => props.theme.border.borderRadius}px;
            }
            .bottom {
                padding: ${props => props.theme.space.padding}px;
                color: #000;
                text-align: center;
                button {
                    width: 100%;
                }
                .name {
                    word-break: break-all;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
                .count {
                    font-weight: bold;
                    margin: ${props => props.theme.space.marginXXS}px;
                }
            }
        }
    } 
    .address {
        min-height: 220px;
        max-height: 550px;
        overflow: auto;
    }
    .address-item {
        margin-bottom: ${props => props.theme.space.margin}px;
        &:last-child {
            margin-bottom: 0;
        }
        .receiver {
            margin-bottom: ${props => props.theme.space.marginXXS}px;
        }
        .phone {
            margin-left: ${props => props.theme.space.marginXS}px;
        }
        .tag {
            background-color: ${props => props.theme.colors.colorPrimary};
            padding: 0 ${props => props.theme.space.paddingXXS}px;
            margin-left: ${props => props.theme.space.marginXXS}px;
            color: #fff;
            border-radius: 2px;
            width: 64px;
            text-align: center;
        }
        
    }
    .desc {
        color: ${props => props.theme.colors.colorTextTertiary};
    }
`