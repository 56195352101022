import { createSlice } from '@reduxjs/toolkit'

const imSlice = createSlice({
    name: 'im',
    initialState:{
        unreadUpdate: 0
    },
    reducers: {
        fireUnreadUpdateEvent(state, action) {         
            return {
                ...state,
                unreadUpdate: action.payload
            }
        
        }
    }
})

export const { fireUnreadUpdateEvent } = imSlice.actions

export default imSlice.reducer