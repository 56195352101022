import styled from "styled-components";

export const WorkModalStyle = styled.div`
    .select-modal-container{
        position: relative;
       width: 100%;
       height: 100%;
    .select-work-modal-wrapper{
                .list-wrapper{
                    display: flex;
                    width: 100%;
                    margin-top: 20px;
                    justify-content: center;
            }
            }
    }
`