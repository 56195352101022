import styled from "styled-components";

export const TagsStyle = styled.div`
 display: flex;
 flex-direction: column;
 .inlineTags{
   padding: ${props => props.theme.space.paddingXS}px;
   padding-right: 0;
    display: flex;
    align-items: flex-start;
 
   
    .tag-list{
      display: inline-flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-wrap: wrap;
      & > div {
        white-space:normal !important;
      }
      img{
      width: 20px;
      height: 20px;
    }

       .no-image-tag{
      background-color: #F1F1F3;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    }
    .title {
      min-width: 45px;
      transform: translateY(-1px);
    }
    .ant-tag {
      margin-bottom: ${props => props.theme.space.marginXXS}px;
    }
 }
`