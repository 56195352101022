import { Avatar, Select, Spin } from "antd";
import { DefaultOptionType, LabeledValue } from "antd/lib/select";
import { useDebouncedCallback } from 'use-debounce';
import { useCallback, useState } from "react";
import { getAuthorList } from "api/user";
import { getAuthorItem } from "types/user";

type Props = {
  value?: number; //入口定义 是整数数组
  onChange?:  (value: number) => void;
  onUserObjectChange?: (value: getAuthorItem | undefined) => void;
};


function UserSelect({ value, onChange ,onUserObjectChange}: Props) {
  const [options, setOptions] = useState<DefaultOptionType[] | undefined>([]);
  const [fetching, setFetching] = useState(false);
  const [list,setList] = useState<getAuthorItem[]>([])

  interface OptionReqeustType {name?:string,pageSize?:number}
 

  const getOptionsList= useCallback(({name,pageSize=20}:OptionReqeustType) => {
    if (!name) {
      return
    }
    setOptions([]);
    setFetching(true);
    //get接口需要把数组转成逗号分隔的字符串
   return getAuthorList({ words:name,pageSize}).then((res) => {
    //console.log(res.pageData,'--------------88999------')
      setList(res.pageData)
      const options_from_api: DefaultOptionType[] = res.pageData.map(
        (user: getAuthorItem) => formatOptions(user)
      );
      setFetching(false);
      setOptions([...options_from_api]);
    });
  },[getAuthorList]);

  const debouncedGetOptionsList = useDebouncedCallback(getOptionsList, 500);
 
  
  const handleChange = (
    value: any,
  ) => {
    //console.log(value,'====0=====',list)
    const target = list.find(item => item.userInfo.id === value)
    onChange && onChange(value);
    onUserObjectChange && onUserObjectChange(target)
    //console.log(target,'====1=====')
  };

//  useEffect(() => {
//   console.log(typeof value ,'-------');
//   let id = undefined
//   if(typeof value === 'number'){
//     id = value
//   }else if(typeof value === 'object'){
//     id = (value as any).id
//   }
//   //console.log(id)
//   value && apiPublicUserDetail(id).then((res)=>{
//     //console.log(res,'hahhahahaha')
//     setLabelValue({label:res.username, value: value})
//   })
 

//  },[value])

  const matchSearchKeywordsAndGetOptionsList = (inputValue: string) => {
    let params:OptionReqeustType = {
      pageSize:20
    }
    params.name = inputValue
    debouncedGetOptionsList(params)
  }

  const formatOptions = (item: getAuthorItem): (DefaultOptionType | LabeledValue) => {
    return {
      label: (
        <div>
          <Avatar size="small" src={item.userInfo.avatarUrl} alt="" />
          <span>
            {item.userInfo.username}-  {item.userId}
          </span>
        </div>
      ),
      value: item.userId,
    };
  };
  return (
    <div>
      <Select
        value={value}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        style={{ width: "100%" }}
        allowClear
        placeholder="请选择"
        onChange={handleChange}
        options={options}
        filterOption={false} //这是个大坑，远程获取数据一定要关掉
        onFocus={()=>getOptionsList({pageSize:20})}
        onSearch={(value) => matchSearchKeywordsAndGetOptionsList(value)}
        showSearch={true}
      />
    </div>
  );
}

export default UserSelect;
