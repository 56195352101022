import styled from "styled-components";


export const DraftWorkStyle = styled.div`
    /* max-width: 1400px; */
    margin: 0 auto;
    /* padding: ${(props) => props.theme.space.padding}px 0; */
    .top-bar{
        display: flex;
        justify-content: space-between;
        .segmented {
            .ant-segmented-item-label,.ant-segmented-item-icon {
                display: flex;
                align-items: center;
            }
            .ant-segmented-item-selected {
                color: #fff;
                background-color: ${props=>props.theme.colors.colorPrimary};
            }
        }
        
        .btn{
            display: flex;
            align-items: center;
            background-color: ${(props) => props.theme.colors.colorPrimary};
            color: ${(props) => props.theme.colors.colorTextLightSolid};
            span{
                margin-right: ${(props) => props.theme.space.marginXXS}px;
            }
        }
        .select{
            
        }
    }
    .list{
        padding:${(props) => props.theme.space.paddingLG}px 0;
        display: grid;
        gap:${(props) => props.theme.space.padding}px;
    }
`