import { Button, InputNumber, Modal } from "antd"
import { VoteTicketStyle } from "./style"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"

const VoteTicket = forwardRef((props: { isShow: boolean, ticket: number, close: () => void, vote: (value: number) => void }, ref) => {
    const { isShow, ticket, close, vote } = props
    useImperativeHandle(ref, () => {
        return {
            resetLoading: resetLoading,
        };
    });
    const [value, setValue] = useState(1)
    const [loading, setLoading] = useState(false)

    const changeValue = (value: number) => {
        setValue(value)
    }

    const resetLoading = () => {
        setLoading(false)
    }

    useEffect(() => {
        if (isShow) {
            setValue(1)
        }
    }, [isShow])

    return <VoteTicketStyle>
        <Modal footer={<></>} destroyOnClose={true} title='投票' onCancel={close} centered={true} open={isShow}>
            <div className="wrap" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                <div className="top" style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    <div>请输入票数：</div>
                    <InputNumber min={1} max={ticket} value={value} onChange={(e) => changeValue(Number(e))} />
                </div>
                <div className="tip">您当前可用票数：{ticket}</div>
                <Button type="primary" loading={loading} onClick={() => { setLoading(true); vote(value) }} style={{ width: "200px" }}>投票</Button>
            </div>
        </Modal>
    </VoteTicketStyle>
})

export default VoteTicket