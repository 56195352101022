import { Button, Form,  Modal, Rate, UploadFile } from "antd"
import { EvaluateOrderStyle } from "./style"
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { PicturesWall } from "components/uploadImageList";
import { mallReviewCreate } from "api/shop";
import { getFileUrlByCosUpload } from "config/cos";
import { getRandomKey } from "config/utils/randomKey";
import { MallReviewListResponse } from "types/product";

const EvaluateOrder = ({ isEvaluateShow, reviewDetail, onCancel, orderNumber, update, disabled = false }:
    { isEvaluateShow: boolean, disabled: boolean, reviewDetail?: MallReviewListResponse, update: () => void, onCancel: () => void, orderNumber: string }) => {
    const [form] = Form.useForm<submitParams>();
    const [loading, setLoading] = useState(false)

    type submitParams = {
        ratings: number,
        imageList: UploadFile[],
        orderNumber?: string,
        content: string,
    }

    useEffect(() => {
        const imageList = reviewDetail?.imageList
        const arr: UploadFile[] = []
        if (imageList && imageList.length > 0) {
            imageList?.forEach((item, index) => {
                arr.push({
                    uid: String(index),
                    url: item,
                    name: item
                })
            })
        }
        form.setFieldsValue({
            ratings: reviewDetail?.ratings || 0,
            imageList: arr,
            content: reviewDetail?.content || ""
        })
    }, [orderNumber, reviewDetail])

    const submit = () => {
        form.validateFields().then(async res => {
            setLoading(true)
            const { imageList, ratings, content } = res
            let currentImageList
            let descUrlsArr: Promise<{ url: string }>[] = []
            if (imageList) {
                imageList.forEach((item) => {
                    if (item.originFileObj) {
                        descUrlsArr.push(getFileUrlByCosUpload(item.originFileObj!, `ggac/shop/product/mallReview/${getRandomKey(item.originFileObj!.name)}`))
                    }
                })

                let res2 = await Promise.all(descUrlsArr)
                let uploadIndex = -1;
                let updatedescImageListMyNftDetail = []
                updatedescImageListMyNftDetail = imageList && imageList?.map(item => {
                    if (item.originFileObj) {
                        uploadIndex = uploadIndex + 1
                        return res2[uploadIndex].url
                    } else {
                        return item.url!
                    }
                })
                currentImageList = updatedescImageListMyNftDetail
            }
            mallReviewCreate({ imageList: currentImageList, orderNumber, ratings, content }).then(res => {
                onCancel()
                update()
            }).finally(() => {
                setLoading(false)
            })
        })

    }

    return <EvaluateOrderStyle>
        <Modal className='modal' title='商品评价' onCancel={onCancel} centered={true} footer={null} getContainer={false} open={isEvaluateShow}>
            <Form form={form} disabled={disabled}>
                <Form.Item label="满意度" rules={[{ required: true, message: '请选择满意度' }, ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || value === 0) {
                            return Promise.reject(new Error('请选择满意度'));
                        }
                        return Promise.resolve();
                    },
                }),]} name="ratings">
                    <Rate disabled={disabled} />
                </Form.Item>
                <Form.Item label="商品评价" name="content">
                    <TextArea placeholder="请输入商品评价" />
                </Form.Item>
                <Form.Item
                    name="imageList"
                    label="上传图片"
                    className="imageList"
                >
                    <PicturesWall maxCount={5} listType="picture-card" />
                </Form.Item>
                <div className="tip">最多可上传5张图片</div>
                <div className="btn">
                    <Button type="primary" loading={loading} onClick={submit}>提交评价</Button>
                </div>
            </Form>
        </Modal>
    </EvaluateOrderStyle>
}

export default EvaluateOrder