import styled from "styled-components";

export const WorkVoteStyle = styled.div`
.tip {
    text-align: center;
    margin-top: ${props => props.theme.space.marginXS}px;
    color: rgba(0, 0, 0, 0.45);
}
.onVoteBtn {
    margin-top: ${props => props.theme.space.marginXS}px;
}
`