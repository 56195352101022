import { CarouselListItem } from "types/common/carousel";
import { CardViewStyle } from "./style";
import { Avatar, Space } from "antd";

function CardView({ item }: { item: CarouselListItem }) {
  const open = (e: any, url: string) => {
    e.stopPropagation();
    window.open(url, "_blank");
  };
  return (
    <CardViewStyle>
      <Space>
        <div
          className="avatar"
          onClick={(e) => open(e, `/user/${item.templateParams.userId}/works`)}
        >
          <Avatar src={item.templateParams.avatarUrl} size={40}></Avatar>
        </div>
        <div
          onClick={(e) => open(e, "/work/detail/" + item.templateParams.workId)}
          dangerouslySetInnerHTML={{ __html:item.templateParams.workTitle}}
        >
          
        </div>
      </Space>
    </CardViewStyle>
  );
}

export default CardView;
