import React, {
  CSSProperties,
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from "react";
import { Input, Modal, Popconfirm, Progress, Upload, message } from "antd";
import { ItemStyle, SubUploadImageListStyle } from "./style";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { UploadChangeParam } from "antd/lib/upload";
import { Image } from "antd";
import { ListItem } from "views/work/components/comboUpload/type";
import { CosDirectoryType, MediaType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";
import { getFileUrlByCosUpload } from "config/cos";
import { apiCreateMedia, apiUpdateMedia } from "api/media";
import { ProgressInfo } from "cos-js-sdk-v5";
import { useParams } from "react-router-dom";
import { RiAddLine, RiDeleteBinLine, RiEyeLine } from "react-icons/ri";
import { RcFile } from "antd/es/upload";
import {
  isFileOversized,
  isImage,
  isImageResolutionTooLarge,
} from "utils/functions/file";
import { ListManager } from "react-beautiful-dnd-grid";

type Props = {
  disabled?: boolean;
  value?: ListItem[];
  onChange?: (params: ListItem[]) => void;
  maxLength?: number;
  mark?: string;
  noDesc?: boolean;
};

export const SubPicturesWall: React.FC<Props> = memo(
  ({ onChange: onFileChange, mark, ...props }) => {
    const [list, setList] = useState<ListItem[]>([]);
    const { id } = useParams();

    const [caption, setCaption] = useState("");

    const [visible, setVisible] = useState(false);

    const [current, setCurrent] = useState<ListItem>();

    const [loading, setLoading] = useState(false);

    const [isShowDescModel, setIsShowDescModal] = useState(false)

    const [editDescDetail, setEditDescDetail] = useState<ListItem>()

    useEffect(() => {
      setList(props.value! ?? []);
    }, [props.value]);

    const validateFile = async (imgFile: RcFile) => {
      if (!isImage(imgFile)) {
        message.warning("上传文件中包含不支持的图片格式,请检查后重新上传", 3);
        return false;
      }
      if (isFileOversized(imgFile, 20)) {
        message.warning("上传文件大小不能超过20M,请检查后重新上传", 3);
        return false;
      }
      if (await isImageResolutionTooLarge(imgFile, 7680, 7680)) {
        message.warning("包含超大分辨率图片,请检查后重新上传，最大支持7680x7680", 3);
        return false;
      }

      return true;
    };
    const onChange = async ({ fileList }: UploadChangeParam) => {
      setLoading(true);

      let imgFile = fileList[fileList.length - 1].originFileObj;
      if (!imgFile) {
        setLoading(false);
        return;
      }
      validateFile(imgFile).then(async (res) => {
        if (!res) {
          setLoading(false);
          return
        };

        const uploadUUid = getFileName(
          CosDirectoryType.workImage,
          imgFile!.name
        );

        let newItem: ListItem = {
          id: 0,
          url: "",
          coverUrl: "",
          type: MediaType.image,
          key: uploadUUid,
          percent: 0,
          caption: "",
          mark: mark!,
        };

        list.push(newItem);
        setList(list);

        const resultImg = await getFileUrlByCosUpload(
          imgFile as File,
          uploadUUid,
          onProgress
        );

        //绑定媒体，写入数据库
        const dataItem = await apiCreateMedia({
          coverUrl: "",
          dataId: Number(id),
          dataTable: "work",
          md5: resultImg.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
          name: imgFile!.name.split(".").shift() + "",
          rank: 0,
          size: imgFile!.size,
          type: MediaType.image,
          url: resultImg.url,
          mimeType: imgFile!.type,
          uuid: uploadUUid,
          caption: "",
          mark: mark!,
        });

        //转码失败就停止
        if (!(typeof dataItem === 'object' && dataItem !== null)) {
          setLoading(false);
          return
        }

        newItem.url = dataItem.url;
        newItem.id = dataItem.id;
        newItem.percent = 100;
        list.forEach((item) => {
          if (item.key === newItem.key) {
            item.percent = 100;
          }
        });
        setList(list.concat([]));

        onFileChange!(list.concat([]));
        setLoading(false);
      });
    };

    //上传进度回调
    const onProgress = (progressData: ProgressInfo, key: string) => {
      list.forEach((item) => {
        if (item.key === key) {
          item.percent =
            progressData.percent * 100 > 10
              ? +(progressData.percent * 100 - 10).toFixed(2)
              : +progressData.percent.toFixed(2);
        }
      });
      setList(list.concat([]));
      onFileChange!(list.concat([]));
    };

    const onClickDelete = (media: ListItem) => {
      let arr = list.filter((item) => item.id !== media.id);
      setList(arr);
      onFileChange!(arr);
    };

    const onClickEditCaption = () => {
      let params = {
        id: Number(editDescDetail?.id),
        caption: caption,
      };
      apiUpdateMedia(params).then((res) => {
        list.forEach((item) => {
          if (item.id === params.id) {
            item.caption = params.caption;
          }
        });
        setList(list.concat([]));
        onFileChange!(list.concat([]));
      });
    };

    const beforeUpload = () => {
      return false;
    };
    const uploadButton = (
      <div>
        <RiAddLine />
        <div>上传</div>
      </div>
    );

    const ListElement = ({ item }: any) => {
      return <ItemStyle>
        <div className="card-wrapper">
          {item.percent < 100 && (
            <Progress
              type="circle"
              percent={item.percent}
              size={80}
            />
          )}
          {(item.percent >= 100 || item.url) && (
            <div
              className="image"
              style={{ backgroundImage: `url(${item.url})` }}
            >
              <div className="operation-container">
                <div
                  className="operation-item"
                  onClick={() => {
                    setCurrent(item);
                    setVisible(true);
                  }}
                >
                  <RiEyeLine size={16}></RiEyeLine>
                </div>
                <div
                  className="operation-item"
                  onClick={() => onClickDelete(item)}
                >
                  {" "}
                  <RiDeleteBinLine
                    size={16}
                  ></RiDeleteBinLine>
                </div>
              </div>
            </div>
          )}
          {!props.noDesc && (
            <div>
              {(item.percent >= 100 || item.url) && (
                <div
                  className="desc"
                  onClick={() => { setCaption(item.caption); setEditDescDetail(item); setIsShowDescModal(true) }}
                >
                  {item.caption ? item.caption : "添加描述"}
                </div>
              )}
            </div>
          )}
        </div>
      </ItemStyle>

    }

    const reorderList = (sourceIndex: number, destinationIndex: number) => {
      console.log("reorderList", sourceIndex, destinationIndex);
      if (!list) return;
      if (destinationIndex === sourceIndex) {
        return;
      }
      const targetItem = list[sourceIndex];
      if (destinationIndex < sourceIndex) {
        const a = list.slice(0, destinationIndex)
        const b = list.slice(destinationIndex)
        const aRemoveTarget = a.filter(item => item.id !== targetItem.id);
        const bRemoveTarget = b.filter(item => item.id !== targetItem.id);
        const sortedList = [...aRemoveTarget, targetItem, ...bRemoveTarget]
        setList(sortedList);
        onFileChange!(sortedList);
      }

      if (destinationIndex > sourceIndex) {
        const a = list.slice(0, destinationIndex + 1)
        const b = list.slice(destinationIndex + 1)
        const aRemoveTarget = a.filter(item => item.id !== targetItem.id);
        const sortedList = [...aRemoveTarget, targetItem, ...b]
        setList(sortedList);
        onFileChange!(sortedList);
      }
    };

    return (
      <SubUploadImageListStyle>
        <div>
          <Image
            width={200}
            style={{ display: "none" }}
            src={current?.url}
            preview={{
              visible,
              src: current?.url,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />

          {list && (
            <div className="list-wrap">
              <ListManager
                items={list}
                direction="horizontal"
                maxItems={4}
                render={(item) => <ListElement item={item} />}
                onDragEnd={reorderList}
              />
              <Upload
                {...props}
                listType="picture-card"
                disabled={props.disabled}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={onChange}
                multiple={false}
                accept=".png,.jpg,.gif"
              >
                {!loading && uploadButton}
              </Upload>
            </div>
          )}
        </div>
        <Modal onOk={onClickEditCaption} title={!!editDescDetail?.caption ? '编辑描述' : "添加描述"} onCancel={() => setIsShowDescModal(false)} destroyOnClose={true} closable={false} centered={true} open={isShowDescModel}>
          <Input value={caption} onChange={(e) => setCaption(e.target.value)} placeholder="请输入描述" />
        </Modal>
      </SubUploadImageListStyle>
    );
  }
);
