import { get, post } from "api";
import { PaginationResponse } from "types/common/axios";
import {
  NftAlbumListRequest,
  NftAlbumListResponse,
  NftDetailResponse,
  NftListRequest,
  NftListResponse,
  NftSalesPlanListRequest,
  NftSalesPlanListResponse,
  SellerNftAlbumCreateRequest,
  SellerNftAlbumDetail,
  SellerNftAlbumListItem,
  SellerNftAlbumListRequest,
  SellerNftAlbumUpdateRequest,
} from "types/nft-album";

// 获取图鉴列表
export const apiGetAlbumList = (params: NftSalesPlanListRequest) =>
  get<NftSalesPlanListRequest, PaginationResponse<NftSalesPlanListResponse>>(
    "/api/nft_sales_plan/list",
    params
  );

export const apiGetAlbumCode = (id: number) =>
  get<{}, {}>(`/api/nft_sales_plan/mina_qr_code/${id}`);

// 获取我的图鉴列表
export const apiGetMyAlbumList = (params: NftAlbumListRequest) =>
  get<NftAlbumListRequest, PaginationResponse<NftAlbumListResponse>>(
    "/api/nft_album/list",
    params
  );

// 获取我的图鉴卡列表
export const apiGetMyNftList = (params: NftListRequest) =>
  get<NftListRequest, PaginationResponse<NftListResponse>>(
    "/api/nft/list",
    params
  );

// 获取我的图鉴卡列表
export const apiGetNftDetail = (id: number) =>
  get<{ id: number }, NftDetailResponse>(`/api/nft/detail/${id}`);

// 获取证书分享图
export const apiGetCertificateShareImage = (id: number) =>
  post<{ id: number }, string>(`/api/nft/share_image/${id}`);

// 获取证书分享图
export const apiGetNftShareImage = (id: number) =>
  get<{ id: number }, string>(`/api/nft_product/share_image/${id}`);

//创建图鉴
export const apiCreateSellerNftAlbum = (params: SellerNftAlbumCreateRequest) =>
  post<SellerNftAlbumCreateRequest, any>(
    `/api/seller_nft_album/create`,
    params
  );

  //更新图鉴
export const apiUpdateSellerNftAlbum = (params: SellerNftAlbumUpdateRequest) =>
  post<SellerNftAlbumUpdateRequest, any>(
    `/api/seller_nft_album/update`,
    params
  );

//图鉴详情
export const apiSellerNftAlbumDetail = (params: { id: number }) =>
  get<{ id: number }, SellerNftAlbumDetail>(`/api/seller_nft_album/detail/${params.id}`);


// 卖家图鉴列表
export const apiGetSallerNftAlbumList = (params: SellerNftAlbumListRequest) =>
    get<SellerNftAlbumListRequest, PaginationResponse<SellerNftAlbumListItem>>("/api/seller_nft_album/list", params)

// 卖家图鉴上下架
export const apiPostshelvesSallerNftAlbum = (id: number) =>
    post<{ id: number }, {}>(`/api/seller_nft_album/on_sale/${id}`)
