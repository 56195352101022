import styled from "styled-components";

export const CollabrationStyle = styled.div`
  padding: 0 ${(props) => props.theme.space.padding}px;
  margin-bottom: ${(props) => props.theme.space.margin}px;
  .btn {
    margin-bottom: ${(props) => props.theme.space.margin}px;
  }
  .list {
    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${(props) => props.theme.space.marginXS}px;
      .avatar {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        position: relative;
        .role {
          position: absolute;
          width: 12px;
          height:12px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          left: 26px;
          bottom: 0px;
          cursor: pointer;
          font-size: 12px;
          font-weight: bolder;
          &.yellow {
            background-color: ${(props) => props.theme.colors.colorPrimary};
          }
          &.green {
            background-color: #52c41a;
          }
          &.purple {
            background-color: #722ed1;
          }
          &.blue {
            background-color: #1677ff;
          }
          &.hide {
            display: none;
          }
        }
      }
    }
  }
`;
