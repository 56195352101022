import { Modal } from "antd"
import { BadgeModalStyle } from "./styles"
import { PublicUserStatistics, publicUserDetailType } from "types/user/detail"
import BadgeLeft from "./left"
import { useEffect, useState } from "react"
import { apiGetCertificateList, apiGetMyCertificateList } from "api/user/certificate"
import { certificateItem } from "types/user/certificate"
import { apiGetUserBadges } from "api/user/badge"
import { BadgeItem } from "types/user/badge"
import BadgeRight from "./right"
import { RiCloseCircleLine } from "react-icons/ri"

const BadgeModal = ({ isShow, userDetail, closeModal, isShowRodio = false, badgeCount }: { isShow: boolean, userDetail: publicUserDetailType, closeModal: () => void, isShowRodio?: boolean, badgeCount: number }) => {

    const userId = userDetail.id

    const [certificateList, setCertificateList] = useState<certificateItem[]>([])

    const [badgeList, setBadgeList] = useState<BadgeItem[]>([])

    const updateList = (value: certificateItem[]) => {
        setCertificateList([...value])
    }

    useEffect(() => {

        if (isShowRodio) {
            apiGetMyCertificateList({
                userId,
                pageSize: 100
            }).then(res => {
                setCertificateList(res)
            })
        } else {
            apiGetCertificateList({
                userId,
                pageSize: 100
            }).then(res => {
                setCertificateList(res.pageData)
            })
        }

        apiGetUserBadges({ userId }).then(res => {
            setBadgeList(res)
        })
    }, [userId])

    return <BadgeModalStyle>
        <Modal footer={<></>} width={800} maskClosable={false} style={{ padding: 0 }} className="badge-modal" destroyOnClose={true} centered={true} open={isShow}>
            <RiCloseCircleLine onClick={closeModal} style={{ position: "absolute", cursor: "pointer", right: "35px", top: "35px", color: "#fff", fontSize: "30px" }} />
            <div className="header" style={{ height: '100px', color: "#fff", gap: "80px", backgroundColor: "#000", display: "flex", padding: "0 80px", alignItems: "center" }}>
                <div className="user" style={{ display: "flex", marginRight: "20px", alignItems: "center", gap: "10px" }}>
                    <div className="cover" style={{ backgroundImage: (`url(${userDetail.avatarUrl})`), width: "60px", height: "60px", borderRadius: "50%", backgroundPosition: "center", backgroundSize: "cover" }} />
                    <div style={{ maxWidth: "200px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{userDetail.username}</div>
                </div>
                <div className="badge-count" style={{ display: "flex", alignItems: "center" }}>徽章：<span style={{ fontSize: '30px' }}>{badgeCount}</span></div>
                <div className="badge-count" style={{ display: "flex", alignItems: "center" }}>证书：<span style={{ fontSize: '30px' }}>{certificateList.length}</span></div>
            </div>
            <div className="content" style={{ display: "flex", padding: "20px 0", overflow: "hidden" }}>
                <BadgeLeft isShowRodio={isShowRodio} updateList={updateList} userId={userId} certificateList={certificateList} />
                <BadgeRight badgeList={badgeList} />
            </div>
        </Modal>
    </BadgeModalStyle>
}

export default BadgeModal