import React, {
  CSSProperties,
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from "react";
import { Input, Popconfirm, Progress, Upload, message } from "antd";
import { SubUploadImageListStyle } from "./style";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { UploadChangeParam } from "antd/lib/upload";
import { Image } from "antd";
import { ListItem } from "views/work/components/comboUpload/type";
import { CosDirectoryType, MediaType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";
import { getFileUrlByCosUpload } from "config/cos";
import { apiCreateMedia, apiUpdateMedia } from "api/media";
import { ProgressInfo } from "cos-js-sdk-v5";
import { useParams } from "react-router-dom";
import { RiAddLine, RiDeleteBinLine, RiEyeLine } from "react-icons/ri";
import { RcFile } from "antd/es/upload";
import {
  isFileOversized,
  isImage,
  isImageResolutionTooLarge,
} from "utils/functions/file";

const grid = 8;
const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
  background: isDraggingOver ? "lightblue" : "#f9f9f9",
  display: "flex",
  flexWrap: "wrap",
  padding: grid * 2,
  overflow: "auto",
});
const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): CSSProperties => ({
  userSelect: "none",
  padding: 0,
  margin: 4,
  height: 154,
  width: 154,
  background: isDragging ? "lightgreen" : "#f9f9f9",
  ...draggableStyle,
});
type Props = {
  disabled?: boolean;
  value?: ListItem[];
  onChange?: (params: ListItem[]) => void;
  maxLength?: number;
  mark?: string;
  noDesc?: boolean;
};

export const SubPicturesWall: React.FC<Props> = memo(
  ({ onChange: onFileChange, mark, ...props }) => {
    const [list, setList] = useState<ListItem[]>([]);
    const { id } = useParams();

    const [caption, setCaption] = useState("");

    const [visible, setVisible] = useState(false);

    const [current, setCurrent] = useState<ListItem>();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setList(props.value! ?? []);
    }, [props.value]);

    const validateFile = async (imgFile: RcFile) => {
      if (!isImage(imgFile)) {
        message.warning("上传文件中包含不支持的图片格式,请检查后重新上传",3);
        return false;
      }
      if (isFileOversized(imgFile, 20)) {
        message.warning("上传文件大小不能超过20M,请检查后重新上传",3);
        return false;
      }
      if (await isImageResolutionTooLarge(imgFile, 7680, 7680)) {
        message.warning("包含超大分辨率图片,请检查后重新上传，最大支持7680x7680",3);
        return false;
      }
     
      return true;
    };
    const onChange = async ({ fileList }: UploadChangeParam) => {
      setLoading(true);

      let imgFile = fileList[fileList.length - 1].originFileObj;
      if (!imgFile) {
        setLoading(false);
        return;
      }
      validateFile(imgFile).then(async (res) => {
        if (!res){
          setLoading(false);
          return
        };

        const uploadUUid = getFileName(
          CosDirectoryType.workImage,
          imgFile!.name
        );

        let newItem: ListItem = {
          id: 0,
          url: "",
          coverUrl: "",
          type: MediaType.image,
          key: uploadUUid,
          percent: 0,
          caption: "",
          mark: mark!,
        };

        list.push(newItem);
        setList(list);

        const resultImg = await getFileUrlByCosUpload(
          imgFile as File,
          uploadUUid,
          onProgress
        );

        //绑定媒体，写入数据库
        const dataItem = await apiCreateMedia({
          coverUrl: "",
          dataId: Number(id),
          dataTable: "work",
          md5: resultImg.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
          name: imgFile!.name.split(".").shift() + "",
          rank: 0,
          size: imgFile!.size,
          type: MediaType.image,
          url: resultImg.url,
          mimeType: imgFile!.type,
          uuid: uploadUUid,
          caption: "",
          mark: mark!,
        });

        //转码失败就停止
        if(!(typeof dataItem === 'object' && dataItem !== null)){
          setLoading(false);
          return
        }

        newItem.url = dataItem.url;
        newItem.id = dataItem.id;
        newItem.percent = 100;
        list.forEach((item) => {
          if (item.key === newItem.key) {
            item.percent = 100;
          }
        });
        setList(list.concat([]));

        onFileChange!(list.concat([]));
        setLoading(false);
      });
    };

    //上传进度回调
    const onProgress = (progressData: ProgressInfo, key: string) => {
      list.forEach((item) => {
        if (item.key === key) {
          item.percent =
            progressData.percent * 100 > 10
              ? +(progressData.percent * 100 - 10).toFixed(2)
              : +progressData.percent.toFixed(2);
        }
      });
      setList(list.concat([]));
      onFileChange!(list.concat([]));
    };

    const reorder = (
      list: ListItem[],
      startIndex: number,
      endIndex: number
    ) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const onDragEnd = ({ source, destination }: DropResult) => {
      if (!destination) {
        return;
      }

      const newFileList = reorder(list, source.index, destination.index);
      setList(newFileList);
      //console.log(newFileList);
      onFileChange!(newFileList);
    };

    const onClickDelete = (media: ListItem) => {
      let arr = list.filter((item) => item.id !== media.id);
      setList(arr);
      onFileChange!(arr);
    };

    const onClickEditCaption = (item: ListItem) => {
      let params = {
        id: item.id,
        caption: caption,
      };
      apiUpdateMedia(params).then((res) => {
        list.forEach((item) => {
          if (item.id === params.id) {
            item.caption = params.caption;
          }
        });
        setList(list.concat([]));
        onFileChange!(list.concat([]));
      });
    };

    const onChangeCaption = (e: ChangeEvent<HTMLInputElement>) => {
      setCaption(e.target.value);
    };

    const beforeUpload = () => {
      return false;
    };
    const uploadButton = (
      <div>
        <RiAddLine />
        <div>上传</div>
      </div>
    );

    return (
      <SubUploadImageListStyle>
        <div>
          <Image
            width={200}
            style={{ display: "none" }}
            src={current?.url}
            preview={{
              visible,
              src: current?.url,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className="drop-wrap"
                >
                  {list?.map((item, index) => (
                    <Draggable
                      key={item.key}
                      draggableId={item.key + ""}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="drop"
                        >
                          <div className="card-wrapper">
                            {item.percent < 100 && (
                              <Progress
                                type="circle"
                                percent={item.percent}
                                size={80}
                              />
                            )}
                            {(item.percent >= 100 || item.url) && (
                              <div
                                className="image"
                                style={{ backgroundImage: `url(${item.url})` }}
                              >
                                <div className="operation-container">
                                  <div
                                    className="operation-item"
                                    onClick={() => {
                                      setCurrent(item);
                                      setVisible(true);
                                    }}
                                  >
                                    <RiEyeLine size={16}></RiEyeLine>
                                  </div>
                                  <div
                                    className="operation-item"
                                    onClick={() => onClickDelete(item)}
                                  >
                                    {" "}
                                    <RiDeleteBinLine
                                      size={16}
                                    ></RiDeleteBinLine>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!props.noDesc && (
                              <Popconfirm
                                overlayStyle={{ width: "400px" }}
                                title="修改描述"
                                description={
                                  <div>
                                    <Input
                                      placeholder="请输入描述"
                                      value={caption}
                                      onChange={onChangeCaption}
                                    ></Input>
                                  </div>
                                }
                                showCancel={false}
                                onConfirm={() => onClickEditCaption(item)}
                              >
                                {(item.percent >= 100 || item.url) && (
                                  <div
                                    className="desc"
                                    onClick={() => setCaption(item.caption)}
                                  >
                                    {item.caption ? item.caption : "添加描述"}
                                  </div>
                                )}
                              </Popconfirm>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <Upload
                    {...props}
                    listType="picture-card"
                    disabled={props.disabled}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={onChange}
                    multiple={false}
                    accept=".png,.jpg,.gif"
                  >
                    {!loading && uploadButton}
                  </Upload>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </SubUploadImageListStyle>
    );
  }
);
