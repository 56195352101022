import { LoadingIconStyle } from './style'

function LoadingIcon() {
  return (
    <LoadingIconStyle>
       <div className="loading"></div>
    </LoadingIconStyle>
  )
}

export default LoadingIcon