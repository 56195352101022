import { RecommendStyle } from "./style";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { getRecommendWorkLikeList } from "api/work";
import { RecommendWorkListItem } from "types/user/work";
import { apiUserWorkList } from "api/user/work";
import { NoData } from "components/no-data";
import { getWorkTidUrl, hasToken, setWorkListParamsForNav } from "utils/functions/common";
import { useLocation, useNavigate } from "react-router";
import { WorkListRequest } from "types/work";
import { ImageObjectCloudResize } from "utils/functions/file";
import { loginPathWithRedirect } from "utils/functions/user";

const RecommendList = (props: { name: string }) => {
    const [name] = useState(props.name)
    const navigate = useNavigate()
    const [list, setList] = useState<RecommendWorkListItem[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [latestCurrentSize, setLatestCurrentSize] = useState(0)

    const [loading, setLoading] = useState(false)

    const pageSize = 49
    const id = new URLSearchParams(useLocation().search).get("id");
    const type = Number(new URLSearchParams(useLocation().search).get("type") || 1)

    const [isInitLoading, setIsInitLoading] = useState(false)
    const [listParams, setListParams] = useState<any>()

      const t_id = Date.now();

      const saveWorkListParamsForNav = (index:number) => {
        const params =  {number:index,dropdown:1,...listParams}
        setWorkListParamsForNav(params,t_id);
      };
      

    const getRecommentWorkList = useCallback((page?: number) => {
        setLoading(true)
        let params: {
            pageNumber: number,
            pageSize: number,
            categoryId?: number
            needTotal?: number
            tagId?: number
        } = {
            pageNumber: page ? page : currentPage,
            pageSize: pageSize,
            needTotal: 0
        }
        if (type === 1 && id) {
            params.categoryId = Number(id)
        } else if (type === 2 && id) {
            params.tagId = Number(id)
        }
        setListParams({sortField:'recommendUpdateTime',isRecomend:1, ...params})
        getRecommendWorkLikeList(params).then(res => {
            setList((pre) => pre.concat(res.pageData))
            setLatestCurrentSize(res.currentSize)
        }).finally(() => {
            setLoading(false)
            setIsInitLoading(false)
        })
    }, [currentPage, type, id])

    const getHotWorkList = useCallback((page?: number) => {
        console.log(currentPage)
        setLoading(true)
        let params: WorkListRequest = {
            pageNumber: page ? page : currentPage,
            pageSize: pageSize,
            isPublic: 1,
            sortField: 'threeDaysHot',
            needTotal: 0
        }
        if (type === 1 && id) {
            params.categoryId = Number(id)
        } else if (type === 2 && id) {
            params.tagId = id
        }
        setListParams({sortField:'threeDaysHot',...params})
        apiUserWorkList(params).then(res => {
            const result = res.pageData.map(item => {
                return {
                    coverUrl: item.coverUrl, //封面
                    userInfo: { //用户信息
                        avatarUrl: item.userInfo.avatarUrl, //头像
                        userId: item.userId, //用户ID
                        username: item.userInfo?.username //用户昵称
                    },
                    id: item.id, //ID
                    title: item.title, //标题
                    userId: item.userId //用户ID
                }
            })
            setList((pre) => pre.concat(result))
            setLatestCurrentSize(res.currentSize)
        }).finally(() => {
            setLoading(false)
            setIsInitLoading(false)
        })
    }, [currentPage, type, id])

    const getFocusWorkList = useCallback((page?: number) => {
        if (!hasToken()) {
            setIsInitLoading(false)
            return
        }
        setLoading(true)
        let params: WorkListRequest = {
            pageNumber: page ? page : currentPage,
            pageSize: pageSize,
            isFollowed: 1,
            needTotal: 0
        }
        if (type === 1 && id) {
            params.categoryId = Number(id)
        } else if (type === 2 && id) {
            params.tagId = id
        }
        setListParams({isFollowed:1,...params})
        apiUserWorkList(params).then(res => {
            const result = res.pageData.map(item => {
                return {
                    coverUrl: item.coverUrl, //封面
                    userInfo: { //用户信息
                        avatarUrl: item.userInfo?.avatarUrl, //头像
                        userId: item.userId, //用户ID
                        username: item.userInfo?.username //用户昵称
                    },
                    id: item.id, //ID
                    title: item.title, //标题
                    userId: item.userId //用户ID
                }
            })
            setList((pre) => pre.concat(result))
            setLatestCurrentSize(res.currentSize)
        }).finally(() => {
            setIsInitLoading(false)
            setLoading(false)
        })
    }, [currentPage, type, id])

    const toUserHome = (e: any, id: number) => {
        e.stopPropagation()
        window.open(`/user/${id}/works`)
    }

    useEffect(() => {
        setCurrentPage(1)
        setLatestCurrentSize(0)
        setList([])
        setIsInitLoading(true)
        if (props.name === 'recommend') {
            getRecommentWorkList(1)
        } else if (props.name === 'hot') {
            getHotWorkList(1)
        } else if (props.name === 'follow') {
            getFocusWorkList(1)
        }

    }, [props.name, id, type])


    useEffect(() => {
        if (currentPage === 1) {
            return
        }
        if (props.name === 'recommend') {
            getRecommentWorkList()
        } else if (props.name === 'hot') {
            getHotWorkList()
        } else if (props.name === 'follow') {
            getFocusWorkList()
        }
    }, [currentPage])

    const loadMoreData = () => {
        setCurrentPage(currentPage + 1)
    }

    const onClickGoLogin = () => {
        navigate(loginPathWithRedirect())
    }
    return <RecommendStyle>
        <Spin spinning={isInitLoading}>
            <div className="recommend-container">
                <div className="list-wrapper">
                    {list.length === 0 && !loading &&
                        <div className="no-data-wrapper">
                            {(name !== 'follow' || hasToken()) && <NoData></NoData>}
                            {(!hasToken() && name === 'follow') && <div className="no-login-wrapper">
                                <NoData tips="登录之后可以查看关注G友发布的内容"></NoData>
                                <Button type="primary" onClick={onClickGoLogin} style={{ marginTop: '8px' }}>去登录</Button>
                            </div>
                            }
                        </div>}
                    {list.length > 0 &&
                        <InfiniteScroll
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                            dataLength={list.length}
                            next={loadMoreData}
                            // hasMore={list.length < total}
                            hasMore={latestCurrentSize > 0}
                            loader={<div style={{ display: 'flex', width: '100%', justifyContent: 'center', height: '60px', alignItems: 'center' }}>
                                <Spin tip="加载中..." />
                            </div>}
                            scrollableTarget="kindsWorks"
                        >
                            {/* <div className="recommend"> */}
                            {
                                list.map((item, index) => {
                                    return <div className="recommend-item" key={item.id} onClick={() => saveWorkListParamsForNav(index)}>
                                      
                                        <a href={getWorkTidUrl(item.id,t_id)} target="_blank" rel="noreferrer">
                                            <div className="image" style={{ backgroundImage: `url(${ImageObjectCloudResize(item.coverUrl, 500)})` }} />
                                        </a>
                                        <div className="bottom">
                                            <div className="name">{item.title}</div>
                                            <div className="user-info" onClick={(e) => toUserHome(e, item.userId)}>
                                                <div className="cover" style={{ backgroundImage: `url(${item.userInfo?.avatarUrl})` }} />
                                                <div className="username">{item.userInfo?.username}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {/* </div> */}
                        </InfiniteScroll>
                    }
                </div>

            </div>
        </Spin>

    </RecommendStyle>
}

export default RecommendList