import styled from "styled-components";

export const WithdrawalSubmitStyle = styled.div`
    .submit-form {
        .ant-form-item {
            margin-bottom: ${props => props.theme.space.margin}px;
        }
        .btns {
            display: flex;
            gap: ${props => props.theme.space.margin}px;
            .btn {
                flex: 1;
                border-radius: 100px !important;
            }
        }
        .flex{
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                height: 40px;
                margin-bottom: 16px;
                cursor: pointer;
            }
        }
    }
`