import styled from "styled-components";

export const NftEditStyle = styled.div`
  background-color: ${(props) => props.theme.colors.colorBgContainer};
  margin: ${(props) => props.theme.space.marginXS}px 0;
  .tips{
  color: ${props => props.theme.colors.colorTextTertiary};
  display: block;
}
  .content {
    .gutter-row {
      position: relative;
    }
    .gutter-row1 {
      display: flex;
      gap: 10px;
      .input-wrap {
        display: flex;
        flex-direction: column;
        position: relative;
        .error {
          left: 0;
        }
      }
    }
    .error {
      color: red;
      position: absolute;
      bottom: -20px;
      left: 10px;
      font-size: 12px;
    }
    .create {
      padding: ${(props) => props.theme.space.paddingLG * 2}px 0;
      padding-top: 0;
      margin: 0 60px;
      .ant-input-number {
        width: 200px;
      }
      .title {
        font-weight: bold;
        font-size: ${(props) => props.theme.fontSizes.fontSizeHeading5}px;
        margin-bottom: ${(props) => props.theme.space.margin}px;
        cursor: pointer;
      }
      .btn {
        width: 100px;
        height: 30px;
        line-height: 30px;
        background-color: ${(props) => props.theme.colors.colorPrimary};
        color: #fff;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          padding-left: ${(props) => props.theme.space.paddingXXS}px;
        }
      }
      .btn2 {
        margin: 15px auto 0;
      }
      .button-wrap {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        button {
          padding: 0 ${(props) => props.theme.space.paddingLG}px;
          width: 280px;
        }
      }
    }
    .description .ant-row {
    display: block !important;
  }
  }
`;


export const PrivilegesStyle = styled.div``