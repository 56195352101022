import styled from "styled-components";

export const UpdateMobileStyle = styled.div`
.form{
  padding-top:  ${(props) => props.theme.space.padding}px ;
}
    .code {
        display: flex;
        .verifyCode {
          flex: 1;
        }
        
    }
    .btn {
      text-align: center;
     
      button{
        width: 100%;
      }
    }
`