import { Button, Checkbox, Modal, Space, message } from "antd";
import { ProvinceModalStyle } from "./style";
import { useCallback, useEffect, useState } from "react";
import { apiGetChineseAddressList } from "api/common";

type ProvinceListItem = {
  id: number;
  name: string;
};

type Props = {
  isModalOpen: boolean;
  handleOk: (val: ProvinceListItem[]) => void;
  handleUpdateOk: (val: ProvinceListItem[],index:number) => void;
  handleCancel: () => void;
  updateObj?:{
    index: number;
    ids:  number[];
  } | undefined;
};

function ProvinceModal({ isModalOpen, handleOk, handleCancel, updateObj,handleUpdateOk }: Props) {
  const [optionList, setOptionList] = useState<any>();
  const [selected, setSelected] = useState<number[]>([]);
  const [selectedObj, setSelectedObj] = useState<ProvinceListItem[]>([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const getAddress = useCallback(async () => {
    const result = await apiGetChineseAddressList({ types: "2" });
    setOptionList(
      result.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  }, []);

  useEffect(() => {
    if (updateObj && optionList) {
      const ids = [...updateObj.ids];
      setSelected(ids);
      if (ids.length === optionList.length) {
        setCheckedAll(true);
      }
      const obj = updateObj.ids.map((item: any) => {
        return {
          id: item,
          name: optionList.find((item1: any) => item1.value === item).label,
        };
      });
      setSelectedObj(obj);
    }
  }, [updateObj, optionList]);


  useEffect(() => {
    getAddress();
  }, []);

  useEffect(() => {
    if (checkedAll) {
      const ids = optionList.map((item: any) => item.value)
      setSelected(ids);
      const obj = ids.map((item: any) => {
        return {
          id: item,
          name: optionList.find((item1: any) => item1.value === item).label,
        };
      });
      setSelectedObj(obj);
    } 
  }, [checkedAll]);
  
  const onChange = (value: any) => {
    setSelected(value);
    if (value.length === optionList.length) {
      setCheckedAll(true);
    }else{
      setCheckedAll(false);
    }
    const obj = value.map((item: any) => {
      return {
        id: item,
        name: optionList.find((item1: any) => item1.value === item).label,
      };
    });
    setSelectedObj(obj);
  };

  const footer = () => {
    return (
      <div
        className="footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Space>
          <Checkbox
            onChange={(val) => {
              if(val.target.checked){
                setCheckedAll(true)
              }else{
                setCheckedAll(false)
                setSelected([])
              }
            }}
            checked={checkedAll}
          >
            全选
          </Checkbox>
          <div>已选择{selected.length}个区域</div>
        </Space>
        <Space>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={() => 
           
            {
              if(!selectedObj || selectedObj.length === 0 || selected.length === 0){
                message.error("请选择至少一个区域！")
                return
              }
             
              if(updateObj){
                
                handleUpdateOk(selectedObj,updateObj.index);
              }else{
                handleOk(selectedObj);
              }
            }
          }>
            保存
          </Button>
        </Space>
      </div>
    );
  };
  return (
    <ProvinceModalStyle className="ProvinceModalStyle">
      <Modal title="选择区域" open={isModalOpen} footer={footer} width={640} onCancel={handleCancel}>
        <Checkbox.Group
          options={optionList}
          onChange={onChange}
          value={selected}
        />
      </Modal>
    </ProvinceModalStyle>
  );
}

export default ProvinceModal;
