import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import { GuanggaoBannerStyle } from "./style";
import { getCarouselList, sendCarouselLog } from "api/common/carousel";
import { useEffect, useState } from "react";
import { getRandomItemsFromArray } from "utils/functions/common";
import { CoverImageObjectCloudResize } from "utils/functions/file";

type Props = {
  positionCode: number;
  aspectRatio?:number;
};
function GuanggaoBannerTwo({ positionCode,aspectRatio=10/2.5 }: Props) {
  const [banners, setBanners] = useState<CarouselListItem[]>([]);
  const getSlideList = () => {
    const params: CarouselListRequest = {
      lang: 1,
      type: positionCode,
      status: 1,
    };
    getCarouselList(params).then((res) => {
      let two = getRandomItemsFromArray(res.pageData, 2);
      two && setBanners(two);
    });
  };

  useEffect(() => {
    getSlideList();
  }, []);

  return (
    <GuanggaoBannerStyle>
      {banners.map((item, index) => {
        return (
          <div
            className="banner_"
            key={index}
            style={{ backgroundImage: `url(${item.displayImage})` , aspectRatio}}
            onClick={() => {
              sendCarouselLog({carouselId:item.id})
              window.open(item.webUrl, "_blank");
            }}
          ></div>
        );
      })}
    </GuanggaoBannerStyle>
  );
}

export default GuanggaoBannerTwo;
