
import { createSlice } from '@reduxjs/toolkit'

type tabType = {
    name: string,
    key: string,
    path: string,
    dot: number
  }

  
const list: tabType[] = [
    {
      name: "推荐",
      key: "recommend",
      path:"recommend",
      dot:0
    },
    {
      name: "热门",
      key: "hot",
      path:"hot",
      dot:0
    },
    {
      name: "关注",
      key: "follow",
      path:"follow",
      dot:0
    },
    {
      name: "榜单",
      key: "billboard",
      path:"billboard/work/week",
      dot:0
    }
]
const userRedDotsSlice = createSlice({
    name: 'userRedDots',
    initialState: {
        tabList: list
    },
    reducers: {
        updateTabList(state, action) {
            return {
                ...state,
                tabList: action.payload
            }
        },
    },
})

export const { updateTabList } = userRedDotsSlice.actions

export default userRedDotsSlice.reducer
