import { useEffect, useState } from "react";

function MarmosetDisplay({url,width,height,needDecode=true}:{url:string,width:number,height:number,needDecode?:boolean}) {

  const [marmoset,setMarmoset] = useState<any>()
  const [comId,setComId]= useState<string>()
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  function getRandomCharacter() {
    const randomIndex1 = Math.floor(Math.random() * characters.length);
    const randomIndex2 = Math.floor(Math.random() * characters.length);
    const randomIndex3 = Math.floor(Math.random() * characters.length);
    const randomIndex4 = Math.floor(Math.random() * characters.length);
    return characters[randomIndex1]+characters[randomIndex2]+characters[randomIndex3]+characters[randomIndex4];
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn-prd.ggac.com/ggac/assets/js/marmoset.js';
    script.async = true;
    document.body.appendChild(script);
    setTimeout(() => {
      setMarmoset((window as any).marmoset); 
      setComId("marmoset-")
    }, 500)
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  async function decryptAES(ciphertext:string, decryptionKey:string) {
    try {
      // Convert the decryption key to an ArrayBuffer
      const keyBuffer = new TextEncoder().encode(decryptionKey);
      const keyCrypto = await crypto.subtle.importKey(
        'raw',
        keyBuffer,
        { name: 'AES-CBC' },
        false,
        ['decrypt']
      );
  
      // Convert the ciphertext to an ArrayBuffer
    const ciphertextBuffer = Uint8Array.from(atob(ciphertext), c => c.charCodeAt(0));
  
    const encoder = new TextEncoder();
    const ivBuffer = encoder.encode(decryptionKey);
      // Decrypt the ciphertext using the key
      const decryptedBuffer = await crypto.subtle.decrypt(
        { name: 'AES-CBC', iv: ivBuffer},
        keyCrypto,
        ciphertextBuffer
      );
  
      // Convert the decrypted ArrayBuffer to a UTF-8 string
      const decryptedText = new TextDecoder().decode(decryptedBuffer);
  
      return decryptedText;
    } catch (error) {
      console.error('AES decryption error:', error);
      throw error;
    }
  }

  useEffect(() => {
      if(needDecode){
        url &&  decryptAES(url,'1234567890abcdef').then(res=>{
          if(marmoset){
            var myviewer = new marmoset.WebViewer( width, height, res);
            var element = comId && document.getElementById(comId);
            element &&  element.appendChild( myviewer.domRoot);
            myviewer.loadScene();
            getRandomCharacter();
          }
          return () => {
            myviewer && myviewer.unload(); //bye bye
          };
    
        });
      }else{
        if(marmoset){
          var myviewer = new marmoset.WebViewer( width, height, url);
          var element = comId && document.getElementById(comId);
          element &&  element.appendChild( myviewer.domRoot);
          myviewer.loadScene();
          getRandomCharacter();
        }
      }
     
  },[marmoset,url])
  return (
    
      <div id={comId} style={{display:'flex',justifyContent:'center'}}></div>
   
  )
}

export default MarmosetDisplay