import { Col, Form, Input, Modal, Row,  Spin, message } from "antd";
import { apiCreateMedia, apiUpdateMedia } from "api/media";
import ImageUpload from "components/uploadImage";
import { getFileUrlByCosUpload } from "config/cos";
import { ProgressInfo } from "cos-js-sdk-v5";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CosDirectoryType, MediaType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";
import { ImageEditForm, ImageEditProps, ListItem } from "../type";
import { ImageUploadStyle } from "./style";

const { TextArea } = Input;
type EffectItem = {
  id: number, 
  name: string, 
  url: string, 
  checked: boolean
}

export const ComboImageUpdate = (props: ImageEditProps) => {
  const [form] = Form.useForm<ImageEditForm>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { id } = useParams();
  const [selectEffect, setSelectEffect] = useState(-1)
  const [effectList, setEffectList] = useState<EffectItem[]>([
    {id: 1, name: '泡泡', url: 'https://cdn.aicg.art/ai/56fce022-31e2-11ee-a833-5254007ef5a7.mp4', checked: false },
    {id: 2, name: '飘雪', url: 'https://cdn.aicg.art/ai/94291326-31e2-11ee-a833-5254007ef5a7.mp4', checked: false },
    {id: 4, name: '落雨', url: 'https://cdn.aicg.art/ai/626d78be-31e1-11ee-a833-5254007ef5a7.mp4', checked: false },
    {id: 5, name: '爱心烟花', url: 'https://cdn.aicg.art/ai/ffb3e042-31df-11ee-a833-5254007ef5a7.mp4', checked: false },
    {id: 7, name: '流星烟花', url: 'https://cdn.aicg.art/ai/89f79860-31e1-11ee-a833-5254007ef5a7.mp4', checked: false },
  ])

  useEffect(() => {
  
    setConfirmLoading(false);
    form.resetFields();
    form.setFieldsValue({
      ...props.params
    });
    let obj = JSON.parse(JSON.stringify(effectList))
    obj.forEach((element: EffectItem) => {
      if(element.id === props.params.effect) {
        element.checked = true
      } else {
        element.checked = false
      }
    });
    setEffectList(obj)
    setSelectEffect(props.params.effect!)
  }, [props.show]);

  const onCancel = () => {
    props.close();
  };

  const onClickSubmit = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        let { caption, img } = values;

        let imgFile = img.file;
        const uploadUUid = getFileName(CosDirectoryType.workImage, imgFile!.name);
        let newItem:ListItem  = {
          id: 0,
          url: "",
          coverUrl: "",
          type: MediaType.image,
          key: uploadUUid,
          percent: 0,
          caption: caption,
          params:{
            specialEffectTemplateId: undefined
          }
        };
        props.refreshMediaList(newItem, "add");

        props.close();
        props.refreshMediaList(newItem, "update");
        const resultImg = await getFileUrlByCosUpload(
          imgFile as File,
          uploadUUid,
          onProgress
        );

        //绑定媒体，写入数据库
        const dataItem = await apiCreateMedia({
          coverUrl: "",
          dataId: Number(id),
          dataTable: "work",
          md5: resultImg.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
          name: imgFile!.name.split(".").shift() + "",
          rank: 0,
          size: imgFile!.size,
          type: MediaType.image,
          url: resultImg.url,
          mimeType: imgFile!.type,
          uuid: uploadUUid,
          caption: caption,
          params:{
            specialEffectTemplateId: selectEffect === -1? undefined: selectEffect,
            specialEffectVideo: ''
          }
        });
        newItem.caption = dataItem.caption
        newItem.url = dataItem.url;
        newItem.id = dataItem.id;
        newItem.params!.specialEffectTemplateId = dataItem.params.specialEffectTemplateId
        newItem.params!.specialEffectVideo = dataItem.params.specialEffectVideo
        newItem.percent = 100;
        props.refreshMediaList(newItem, "update");
      })
      .catch((err) => {
        setConfirmLoading(false);
      });
  };


  //上传进度回调
  const onProgress = (progressData: ProgressInfo, key: string) => {
    console.log(progressData)
    props.refreshMediaList(
      {
        id: 0,
        url: "",
        coverUrl: "",
        key: key,
        type: MediaType.image,
        percent: progressData.percent * 100 >10? progressData.percent * 100 -10: progressData.percent,
        caption: '',
      },
      "update"
    );
  };

  const onClickSave = () => {
    form
      .validateFields()
      .then(async (values) => {
       let params = {
        id: props.params.id!,
        caption: values.caption,
        params:{
          specialEffectTemplateId: selectEffect
        }
       }
       apiUpdateMedia(params).then(res=>{
        message.success('修改成功!')
        let newItem = {
          id: res.id,
          url: res.url,
          coverUrl: res.url,
          type: MediaType.image,
          key: res.uuid,
          percent: 100,
          caption: res.caption,
          params:{
            specialEffectTemplateId: selectEffect === -1? undefined: selectEffect
          }
        };
        
        props.refreshMediaList(newItem, "update");
        props.close();
       })

      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  // const  onClickVideo = (index: number) =>{
   
  //   const checked= effectList[index].checked
  //     let obj = JSON.parse(JSON.stringify(effectList))
  //     obj.forEach((element: EffectItem) => {
  //       element.checked = false
  //     });
  //     obj[index].checked = !checked
  //     if(obj[index].checked) {
  //       setSelectEffect(obj[index].id)
  //     } else {
  //       setSelectEffect(-1)
  //     }
      
  //     setEffectList(obj)
  // }

  return (
    <ImageUploadStyle>

      <Modal
      getContainer={false}
        className="upload-image-modal"
        title="上传图片"
        open={props.show}
        cancelText="取消"
        onCancel={onCancel}
        okText="保存"
        onOk={props.params.id ? onClickSave : onClickSubmit}
        confirmLoading={confirmLoading}
        width={800}
        maskClosable={false}
      >
        <Spin spinning={confirmLoading}>
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="img"
                  label="图片"
                  rules={[
                    {
                      required: true,
                      message: "图片必填",
                    },
                    {
                      validator: (rule, val, callback) => {
                        if (val.imgUrl) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("图片必填");
                        }
                      },
                    },
                  ]}
                >
                  <ImageUpload disabled={!!props.params.id} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="caption"
                  label="描述"
                >
                  <TextArea
                    placeholder="请输入描述"
                    maxLength={500}
                    rows={2}
                    showCount={true}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={24}>
                <Form.Item
                  name="effect"
                  label="特效"
                  className="effect-item-container"
                >
                  <div className="effect-wrapper" >
                    {effectList.map((item, index)=>{
                      return <div key={item.id} className={item.checked? 'video-wrapper actived': 'video-wrapper'} onClick={(e) =>{
                        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
                        onClickVideo(index)}}>
                          
                        <Player
                        playbackRates={[]}
                        disablePictureInPicture	={true}
                        hiddenFullScreenButton={true}
                        hiddenVolume={true}
                        hiddenQualityMenu={true}
                        hiddenPlayButton={true}
                        hiddenTime={true}
                        hiddenTimeline={true}
                        loop={true}
                        
                        autoplay={true}
                        sources={{
                        sd: {
                          play_url:
                            item.url,
                        },}}
                        id={"player"}
                        initialObjectFit="contain"
                      ></Player>
                      <div>{item.name}</div>
                      </div>
                    })}
                  </div>
                 
                </Form.Item>
              </Col> */}

            </Row>
          </Form>
        </Spin>
      </Modal>

    </ImageUploadStyle>
  );
};
