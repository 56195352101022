import { WorkListStyle } from "../style";
import { useCallback, useEffect, useState } from "react";
import {
  apiMyWorkList,
} from "api/user/work";

import { Pagination, Spin } from "antd";
import TagList from "../tag-list";
import { filterValueType } from "../workType";
import { useMemo } from "react";
import { WorkListItem, WorkListRequest } from "types/work";
import { PaginationResponse } from "types/common/axios";
import { useNavigate } from "react-router";
import { UserBaseInfo } from "types/user";
import { NoData } from "components/no-data";
import UISegmentedControl from "ui-lib/segmented-control";
import PublishedWorkCard from "components/uni-card/self-cards/publishedWorkCard";
import useWindowSize from "hooks/useWindowSize";

function WorkList() {
  const [workList, setWorkList] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<filterValueType>();
  const [pageNumber, setPageNumber] = useState(1);
  const [workData, setWorkData] = useState<PaginationResponse<WorkListItem>>();
  const navigate = useNavigate();
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!);
  const [loading, setLoading] = useState(false)
  const [screenSize] = useWindowSize();
  
  const params: WorkListRequest = useMemo(() => {
    const data: WorkListRequest = {
      pageSize: 30,
      pageNumber,
      isPublic: filterValue?.isPublic,
      onSale: 0,
    };

    if (userInfo?.id) {
      data.userId = userInfo.id;
    }
    return data;
  }, [pageNumber, filterValue?.isPublic, filterValue?.onSale, userInfo?.id]);

  const getList = useCallback(async () => {
    setLoading(true)
    apiMyWorkList(params).then(res => {
      setWorkList(res.pageData);
      setWorkData(res);
    }).finally(() => {
      setLoading(false)
    })
  }, [params]);

  useEffect(() => {
    getList();
  }, [getList]);

  const updateFilterValue = (value: filterValueType) => {
    setFilterValue(value);
  };

  // const deleteWork = (id: number, state?: number) => {
  //   Modal.confirm({
  //     title: "确定删除？",
  //     content: "删除该作品后，将无法找回",
  //     okText: "确定",
  //     cancelText: "取消",
  //     onCancel() { },
  //     centered: true,
  //     onOk() {
  //       const func =
  //         state && state === 2 ? postDeleteWork : postDeleteDraftWork;
  //       func(id).then(() => {
  //         const index = workList.findIndex((item) => item.id === id);
  //         workList.splice(index, 1);
  //         setWorkList([...workList]);
  //         message.success("删除成功");
  //       });
  //     },
  //   });
  // };



  const tabOption = [
    {
      label: "已通过",
      value: "work",
    },
    {
      label: "审核和草稿",
      value: "draft",
    },
    {
      label: "下架",
      value: "offShelf",
    },
  ];

  const changePage = (e: number) => {
    setPageNumber(e);
  };

  const changeWorkType = (e: any) => {
    if (e === "draft") {
      navigate("/user-center/home/work/draft/list");
    } else if (e === "work") {
      navigate("/user-center/home/work/list");
    }
  };

  return (
    <WorkListStyle>
      <div className="top-bar">
        <div className="left">
          <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={'offShelf'}></UISegmentedControl>
        </div>
        <div className="right">
          <TagList change={updateFilterValue} />
        </div>
      </div>

      <Spin spinning={loading}>
        {workList.length === 0 && (
          <div
            style={{ width: "100%", marginTop: "200px", position: "relative" }}
          >
            <NoData></NoData>
          </div>
        )}
        <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
          {workList.length > 0 && (
            workList.map((item, index) => {
              return <PublishedWorkCard item={item} key={index} shouldRefresh={() => getList()}></PublishedWorkCard>
            })
            // <WorkCard workList={workList} deleteWork={deleteWork}  isSelf={true} />
          )}
        </div>
      </Spin>
      <Pagination
        pageSize={params.pageSize}
        style={{ textAlign: "center" }}
        hideOnSinglePage={true}
        onChange={changePage}
        showSizeChanger={false}
        total={workData?.totalSize}
        current={pageNumber}
      />
    </WorkListStyle>
  );
}

export default WorkList;
