import { FoldDetailStyle } from "./style";
import { getFavoriteDetail, getFavoriteProductList } from "api/favorite";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { FavoriteDataListItem, FavoriteProductListItem } from "types/favorite";
import { Pagination, Spin } from "antd";
import { NoData } from "components/no-data";
import GoBackTitle from "ui-lib/go-back";
import { queryUrl, setWorkListParamsForNav } from "utils/functions/common";
import PublicFavoriteCard from "components/uni-card/public-cards/publicFavoriteCard";
import { useSearchParams } from "react-router-dom";
import FavoriteProductCard from "components/product-card/favorite-product-card";
import useWindowSize from "hooks/useWindowSize";

const FavoriteDetail = () => {
  const { favoritesId } = useParams<any>();
  const [list, setList] = useState<FavoriteDataListItem[]>([]);
  const [productList, setProductList] = useState<FavoriteProductListItem[]>([]);
  const [screenSize] = useWindowSize();
  const [pageNumber, setPageNumber] = useState(
    Number(new URLSearchParams(useLocation().search).get("pageNumber") || 1)
  );

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [total,setTotal] = useState(0);

  const navigate = useNavigate();

  const params = useMemo(() => {
    return {
      pageNumber,
      pageSize: 30,
      id: Number(favoritesId),
    };
  }, [pageNumber, favoritesId]);

  const getList = useCallback(() => {
    setLoading(true);
    if(Number(type) === 3){
        getFavoriteProductList(params).then(res => {
            setProductList(res.pageData)
            setTotal(res.totalSize)
            setLoading(false)
        })
    }else{
        getFavoriteDetail(params).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }
   
  }, [params]);

  useEffect(() => {
    getList();
  }, [getList]);

  const changePage = (e: number) => {
    setPageNumber(e);
    const query = queryUrl({ pageNumber: e });
    navigate(`?${query}`);
  };

  const toBack = () => {
    navigate(`/user/${id}/favorites`, { replace: true });
  };


  // const t_id = Date.now();

  // const saveWorkListParamsForNav = (index:number) => {
  //   const params_ =  {number:index,favoriteId:params.id,pageNumber:params.pageNumber,pageSize:params.pageSize}
  //   setWorkListParamsForNav(params_,t_id);
  // };
  

  return (
    <FoldDetailStyle>
      <div onClick={toBack}>
        {" "}
        <GoBackTitle>返回收藏夹列表</GoBackTitle>
      </div>

      <Spin spinning={loading}>
     

        <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
          {(Number(type) === 1 || Number(type) === 2) &&
            list.map((item, index) => {
              return  <PublicFavoriteCard item={item} key={index}/> ;
            })}
          {Number(type) === 3 &&
            productList.map((item, index) => {
              return (
              
                <FavoriteProductCard
                  item={item}
                  key={index}
                ></FavoriteProductCard>
               
              );
            })}
        </div>
        {(Number(type) === 1 || Number(type) === 2) && list.length === 0 && (
          <div style={{ padding: "49px 0" }}>
            <NoData />
          </div>
        )}
        {Number(type) === 3 && productList.length === 0 && (
          <div style={{ padding: "49px 0" }}>
            <NoData />
          </div>
        )}
      </Spin>
      <Pagination
        style={{ textAlign: "center", marginTop: "15px" }}
        hideOnSinglePage={true}
        current={pageNumber}
        onChange={changePage}
        showSizeChanger={false}
        pageSize={params.pageSize}
        total={total}
      />
    </FoldDetailStyle>
  );
};

export default FavoriteDetail;
