import { ScoreWorkListItem } from "types/contest"
import { ScoreActionStyle } from "./style"
import { Button, Space, Tooltip } from "antd";
import { RiEdit2Line, RiSkipUpLine } from "react-icons/ri";

const ScoreAction = ({ item, isTop = false, clickToTop }: { item: ScoreWorkListItem, isTop: boolean, clickToTop?: (detail: ScoreWorkListItem) => void }) => {
    return <ScoreActionStyle>
        <Space>
            {
                isTop &&
                <Button
                    size="middle"
                    onClick={(e) => { e.stopPropagation(); clickToTop && clickToTop(item) }}
                    type="primary"
                    className="btn"
                >
                    <RiSkipUpLine></RiSkipUpLine>置顶
                </Button>
            }

        </Space>
    </ScoreActionStyle >
}

export default ScoreAction