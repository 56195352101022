import { ArticleListItem } from "types/article";
import { HomePageArticleCardStatusStyle } from "./style";


function HomePageArticleCardStatus({ item }: { item: ArticleListItem }) {
    return (
        <HomePageArticleCardStatusStyle>
              {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
           
          
        </HomePageArticleCardStatusStyle>
    );
}

export default HomePageArticleCardStatus;
