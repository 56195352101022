import { useEffect, useState } from "react"
import { Ceremony2024Style } from "./style"
import { hasToken } from "utils/functions/common"
import { useNavigate } from "react-router-dom"
import { loginPathWithRedirect } from "utils/functions/user"
import CeremonySwiper from "./swiper-image"
import { getEventExhibitionList } from "api/events"

const CereMony2024 = () => {

    const navigate = useNavigate()
    const [list3, setLis3] = useState<{ cover: string, id: number, url: string, name: string }[]>([])
    const [currentValue, setCurrentValue] = useState("2024")
    const [isFixed, setFixed] = useState(false)

    const tabList = [
        { name: "2024嘉年华", value: "2024", id: 1 },
        { name: "热门活动", value: "activity", id: 2 },
        { name: "2024回顾", value: "history", id: 3 },
        { name: "2024感谢", value: "thank", id: 4 },
        { name: "2025继续", value: "next", id: 5 },
        { name: "联系我们", value: "call", id: 6 }
    ]

    const judgeList = [
        { name: ["光叔", "许喆隆"], id: 3, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge3.png", desc: ["NVIDIA首席艺术家", "著名3D艺术家 魔型志发起人"] },
        { name: ["GUO", "DONG"], id: 9, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge9.png", desc: ["自由CG艺术家", "潮流玩具设计师"] },
        { name: ["姬鹏"], id: 5, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge5.png", desc: ["中国美术学院电影学院美术系主任、教授", "戏剧影视美术专业负责人，学科带头人"] },
        { name: ["矩阵"], id: 12, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge12.png", desc: ["著名漫画家、插画艺术家"] },
        { name: ["可乐狗"], id: 13, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge13.png", desc: ["乐元素创意美术总监"] },
        { name: ["LU", "ZHAN"], id: 10, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge10.png", desc: ["CG 艺术家", "独立创作者"] },
        { name: ["陆烨"], id: 8, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge8.png", desc: ["NVIDIA RTX AI 专家", "中国电影电视技术学会电影", "高新技术专业委员会委员"] },
        { name: ["罗其胜"], id: 2, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge2.png", desc: ["开天工作室创始人", "著名CG艺术家"] },
        { name: ["石头", "Crab"], id: 4, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge4.png", desc: ["米哈游《原神》视效监督", "著名游戏动画专家"] },
        { name: ["苏健"], id: 15, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge15.png", desc: ["著名影视概念设计师、CG插画艺术家"] },
        { name: ["Tooth", "Wu"], id: 11, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge11.png", desc: ["TOART创办人，暴雪签约艺术家", "第27届光谱Spectrum金奖"] },
        { name: ["熊军"], id: 6, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge6.png", desc: ["懿百动画创始人", "著名游戏动画专家"] },
        { name: ["杨雪果"], id: 1, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge1.png", desc: ["云南艺术学院设计学院副教授", "著名CG艺术家、策展人"] },
        { name: ["余碗粥"], id: 16, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge16.png", desc: ["《幽明志》制作人"] },
        { name: ["张为"], id: 7, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge7.png", desc: ["网易游戏雷火仓颉动画中心负责人", "网易游戏雷火二次元无限大项目动画总监"] },
        { name: ["神秘", "嘉宾"], id: 14, url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge14.png", desc: ["敬请期待"] },
    ]

    const prizeList1 = [
        { name: "盛会邀请", tip: ["第六届GGAC大赛", "颁奖典礼嘉宾邀请函"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-image1.png", id: 1 },
        { name: "限量奖章", tip: ["GGAC 2024年度作者", "纪念奖章"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-image2.png", id: 2 },
        { name: "智酷臻品", tip: ["明基screenBarPlus", "智能护眼屏幕挂灯"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-image3.png", id: 3 },
        { name: "精品盲盒", tip: ["艺术家盲盒、英雄联盟、", "小桑系列盲盒"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-image4.png", id: 4 }
    ]


    const prizeList2 = [
        { name: ["约稿商品", "一键发布"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-icon1.png", id: 1 },
        { name: ["随时沟通", "省时高效"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-icon2.png", id: 2 },
        { name: ["操作简单", "交易便捷"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-icon3.png", id: 3 },
        { name: ["平台保障", "交易无忧"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-icon4.png", id: 4 },
        { name: ["限免服务费", "零门槛搞钱"], image: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-icon5.png", id: 5 }
    ]

    const cooperationList = [
        { name: "指导单位", tip: "中国电影美术学会CG艺术专业委员会、上海动漫行业协会、上海游戏行业协会、北京国际设计周组委会", id: 1 },
        { name: "主办单位", tip: "数字艺术平台GGAC.COM", id: 2 },
        { name: "联合主办", tip: "英伟达 NVIDIA Studio", id: 3 },
        { name: "特别支持", tip: "COLORFUL iGame、TRYX创氪星系、WACOM、明基、Style3D CG、懿百动画", id: 4 },
        { name: "友情支持", tip: "腾讯游戏、网易游戏、叠纸游戏、散爆游戏、掌趣科技&天马时空、巨人网络、三七游戏、西山居、游戏科学、蔚领、田也、上海美术电影制片厂、万维猫、念动文化、野团子动画工作室、跳跃网络、人民邮电出版社·数艺社、摩点、印物所、CCG EXPO、GAF插画节、WePlay文化展、新西兰展览、CIGA独立游戏联盟、上海浦东新区科幻协会、九点水美术馆、非常博物馆、VERSES、Alienware、Substance by Adobe、Marvelous Designer、Zbrush、微元素、曼奇立德、火星时代教育、汇众教育、晶核教育、百艺动画、爱课狮、鹿柏棠CG、第九联盟、鱿物、边界智能、文昌链、唯一艺术、易界科技", id: 5 },
        { name: "合作媒体", tip: "中国CG艺术媒体联盟（CG模型网、CG世界、wuhu动画人空间、七点GAME、乐艺ArtPage、原画人、Drock、之绘ART、CGView、电影设计师、艺数绘、XRCG学院、光翼学园、北斗北）、腾讯游戏频道、腾讯视频、腾讯新闻、网易新闻、微博动漫、Artlailai、艺类、站酷、机核网、东西文娱、东西游戏、游戏葡萄、游戏新知、游研社、游民星空、游戏陀螺、GameLook、GameRes游资网、DoNews、178.com、精英玩家俱乐部、TOPACGN、魔型志、星球日报、Thepoly、动画师、CG枢纽网、设计癖、TOP UI、COTA五号 ", id: 6 },
        { name: "合作院校", tip: "中央美术学院、北京电影学院、清华大学美术学院、中国美术学院、中国传媒大学、鲁迅美术学院、四川美术学院、广州美术学院、西安美术学院、天津美术学院、湖北美术学院、云南艺术学院", id: 7 },
        { name: "特别鸣谢", tip: "鹰角开拓芯、诺惟投资、界石投资、丰厚资本、平安创投", id: 8 },
    ]

    const aboutList = [
        "GGAC数字艺术平台(WWW.GGAC.COM)   国际知名数字艺术专业平台之一，国内最大CG创作垂类社区，汇集全球百万数字艺术创作者，海量数字艺术佳作。致力于为创作者链接发展机会，提升创作价值，推动数字艺术与CG创作的行业发展，打造数字艺术生态圈。 ",
        "GGAC数字艺术大赛创办于2018年，迄今已举办至第六届，是全球数字艺术界含金量与影响力最大的专业赛事之一，被誉为“CG艺术界的奥斯卡” 。GGAC大赛累计邀请国内外专业与高校评委500余位，赛事覆盖全球65个国家与地区，参赛选手超过11万名，参赛作品超过3万件，获奖作品1000余件，全网曝光量超过20亿。",
        "GGAC大赛的举办，以发掘和培养优秀CG艺术人才、促进全球CG艺术文化交流、提升中国CG艺术的国际影响力为愿景，多年来致力于传统美学与现代CG的融合与创作，推动新时代中国文化艺术在世界舞台的展现与链接！",
        "决胜方寸，执笔封神！GGAC，一战成名！"
    ]

    const contestList = [
        { id: 1, src: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest1.png", url: "https://www.ggac.com/2024/regulation/detail" },
        { id: 2, src: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest2.png", url: "https://www.ggac.com/v2/regulation" },
        { id: 3, src: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest3.png", url: "https://www.ggac.com/v2/2021/regulation" },
        { id: 4, src: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest4.png", url: "https://achive.ggac.com/2020/regulation" },
        { id: 5, src: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest6.png", url: "https://achive.ggac.com/2019/regulation" },
        { id: 6, src: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest5.png", url: "https://achive.ggac.com/2018/regulation" }
    ]

    const codeList = [
        { name: "商务合作", person: "Vina", url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-code1.png", id: 1 },
        { name: "版权合作", person: "小G", url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-code2.png", id: 2 },
        { name: "约稿合作", person: "小美", url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-code3.png", id: 3 },
        { name: "媒体合作", person: "小美", url: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-code4.png", id: 4 }
    ]

    const list1 = [
        { name: `《SIM's的艺术》畅游"模拟经营"创意美术大赛`, url: "https://www.ggac.com/contest/sims/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover1.png", id: 1 },
        { name: `GGAC x iGame渲染动画创意大赛`, url: "https://www.ggac.com/contest/xrtz/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover2.png", id: 2 },
        { name: `《剑网3》外装武器设计大赛`, url: "https://www.ggac.com/contest/czwj2/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover3.png", id: 3 },
        { name: `TRYX裸眼3D立体视觉创意大赛`, url: "https://www.ggac.com/contest/ly3d/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover4.png", id: 4 },
        { name: `《原创未来》2023元宇宙设计创意大赛`, url: "https://www.ggac.com/contest/ycwl2/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover5.png", id: 5 },
        { name: `《定义我的修仙》入木三千劫.永劫无间外观设计大赛`, url: "https://www.ggac.com/v2/sub/yjwj", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover6.png", id: 6 },
        { name: `《妖灵志异》国风x冒险-游戏角色设计大赛`, url: "https://www.ggac.com/contest/ylzy/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover7.png", id: 7 },
        { name: `《寻找悟空》悟空传xGGAC共创计划`, url: "https://www.ggac.com/contest/wukong/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover8.png", id: 8 },
        { name: `《霓光.华夏》田也xGGAC模型征稿赛`, url: "https://www.ggac.com/contest/ty/detail", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-cover9.png", id: 9 }
    ]

    const list2 = [
        { name: `wdcc世界设计之都大会`, url: "https://mp.weixin.qq.com/s/ZuClNxu0qgEU_EfB_P316A", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art1.png", id: 1 },
        { name: `Wonder Festival 2024`, url: "https://mp.weixin.qq.com/s/h5ZC2L3n9ipyo4RHfhi7VA", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art2.png", id: 2 },
        { name: `北京国际插画动画艺术节`, url: "https://mp.weixin.qq.com/s/vh1rNODVIxYzT-UpSSxTQA", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art3.png", id: 3 },
        { name: `第十四届全国美展`, url: "https://mp.weixin.qq.com/s/9vbT6ioJgzdjSau1yxNN3Q", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art4.png", id: 4 },
        { name: `非遗新生`, url: "https://mp.weixin.qq.com/s/FyqUdMbAN4HbekTkxulsqQ", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art5.png", id: 5 },
        { name: `超级拼图艺术节`, url: "https://mp.weixin.qq.com/s/nHFKMHGGM0gY5g4RuMzMWg", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art6.png", id: 6 },
        { name: `风华长安`, url: "https://mp.weixin.qq.com/s/vI-p1TKMIRIJ_KCy2AaFow", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art7.png", id: 7 },
        { name: `璀璨新未来 徐家汇数字艺术大展`, url: "https://mp.weixin.qq.com/s/PpwbwKIsq3UzrxzPZfm7GA", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art8.png", id: 8 },
        { name: `BFC漫粉艺术节`, url: "https://mp.weixin.qq.com/s/C_3Yyt_RidBDB1e5dL9lAQ", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art9.png", id: 9 },
        { name: `不忙的周六 搞点艺术`, url: "https://mp.weixin.qq.com/s/-sx_5Fo8X3T32qyGoq2bzw", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-art10.png", id: 10 }
    ]

    const list4 = [
        { name: ``, url: "https://www.huilahuila.com/#/courses/detail?id=89", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-course1.png", id: 1 },
        { name: ``, url: "https://www.huilahuila.com/#/courses/detail?id=113", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-course2.png", id: 2 },
        { name: ``, url: "https://www.huilahuila.com/#/courses/detail?id=109", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-course3.png", id: 3 },
        { name: ``, url: "https://www.huilahuila.com/#/courses/detail?id=117", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-course4.png", id: 4 },
        { name: ``, url: "https://www.huilahuila.com/#/courses/detail?id=101", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-course5.png", id: 5 },
        { name: ``, url: "https://www.huilahuila.com/#/courses/detail?id=120", cover: "https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-contest-course6.png", id: 6 },
    ]

    const list5 = [
        { name: `A站销冠 SUKE BRUSH SET 2023`, url: "https://www.ggac.com/product/detail/9", cover: "https://cdn-prd.ggac.com/ggac/shop/product/coverUrl/FhJFy26FQh63TMtiRS5hn7FzSKneHtQw1701314314134-900x.webp", id: 1 },
        { name: `光叔同款 ZBrush 大理石材质球`, url: "https://www.ggac.com/product/detail/12", cover: "https://cdn-prd.ggac.com/ggac/product/image/2023/12/25/6d9a278c-59d2-4b86-b7c5-7d41582b9179-900x.webp", id: 2 },
        { name: `Blur's good brush 7.0 Pro`, url: "https://www.ggac.com/product/detail/11", cover: "https://cdn-prd.ggac.com/ggac/shop/product/coverUrl/QrrCbMZDBwtNQX3sZWP3mpyRA7ejb5721701322097365-900x.webp", id: 3 },
        { name: `神不月同款笔刷 BOCHEN brush tool set`, url: "https://www.ggac.com/product/detail/10", cover: "https://cdn-prd.ggac.com/ggac/shop/product/coverUrl/aiByrdDhZmGn4rSH4TAm5xtGrAPKcmf81701315821519-900x.webp", id: 4 },
        { name: `游园惊梦-GGAC大赛金奖`, url: "https://www.ggac.com/product/detail/67", cover: "https://cdn-prd.ggac.com/ggac/product/image/2024/1/17/1b5628f2-0a40-4283-b992-6a0a7981ac1a-900x.webp", id: 5 },
        { name: `HouChina个人IP《姬和子》系列插图PSD源文件`, url: "https://www.ggac.com/product/detail/21", cover: "https://cdn-prd.ggac.com/ggac/shop/product/coverUrl/PaCAWartBZ8iG86MGPXHb7FA3sdy3Q6c1701334248609-900x.webp", id: 6 },
        { name: `林中奇遇`, url: "https://www.ggac.com/product/detail/74", cover: "https://cdn-prd.ggac.com/ggac/product/image/2024/1/18/d7b5bb40-47d1-440e-a5a0-2cc08e8d99bc-900x.webp", id: 7 }
    ]

    useEffect(() => {
        window.addEventListener('scroll', pageScoll);
        return function () {
            window.removeEventListener('scroll', pageScoll);
        };
    }, [])

    useEffect(() => {
        getEventExhibitionList({}).then(res => {
            const result = res.pageData.map(item => {
                return {
                    name: item.name,
                    cover: item.coverUrl,
                    id: item.id,
                    url: `https://www.ggac.com/events/exhibition/${item.id}`
                }
            })
            setLis3(result.slice(0, 6))
        })
    }, [])

    const onScroll = (scrollTopAll: any) => {
        let scrollItems = document.querySelectorAll('.title');
        const scrollTopInner = document.querySelector(`.content`) as HTMLElement;
        for (let i = scrollItems.length - 1; i >= 0; i--) {
            // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
            let judge = scrollTopAll >= scrollTopInner?.offsetTop + (scrollItems[i] as HTMLElement).offsetTop - (scrollItems[0] as HTMLElement).offsetTop - 20;
            if (judge) {
                const className = scrollItems[i].className
                const arr = className.split(" ")
                const arr2 = arr[1].split("-")
                setCurrentValue(arr2[1])
                break;
            }
        }
    }

    const pageScoll = () => {
        let scrollTop =
            document.documentElement.scrollTop ||
            document.body.scrollTop;
        const banner = document.querySelector(`.banner`) as HTMLElement
        if (scrollTop >= banner.clientHeight) {
            setFixed(true)
        } else {
            setFixed(false)
        }
        onScroll(scrollTop)
    }

    const toPoint = (item: any) => {
        const scrollTop = document.querySelector(`.content`) as HTMLElement
        let scrollItem = document.querySelector(`.title-${item.value}`) as HTMLElement
        window.scrollTo({
            top: scrollItem.offsetTop - 100,
            behavior: "smooth",
        });
    }
    const toPublish = () => {
        if (!hasToken()) {
            navigate(loginPathWithRedirect())
            return
        }
        window.open("/mall/commission")
    }



    return <Ceremony2024Style>
        <div className="banner">
            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-banner.png" alt="" />
        </div>
        <div className="tab tab-wrap">
            {
                tabList.map(item => {
                    return <div className={`tab-item ${currentValue === item.value ? 'active' : ''}`} onClick={() => toPoint(item)} key={item.value}>{item.name}</div>
                })
            }
        </div>
        <div className="bg content">
            <div className="title title-2024">
                <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-title1.png" alt="" />
            </div>
            <div className="part1">
                <div className="sub-title">精彩看点！</div>
                <div className="part1-border">
                    <div className="part1-border-top">
                        <div>
                            <div className="part-title">年度大奖公布</div>
                            <div className="tip">第六届GGAC大赛数百个奖项</div>
                        </div>
                        <div>
                            <div className="part-title">8场年度分享</div>
                            <div className="tip">CG圈作者2024团建</div>
                        </div>
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-icon.png" className="person" />
                    </div>
                    <div className="part1-border-bottom">
                        <div>
                            <div className="part-title">顶级专业论坛</div>
                            <div className="tip">年度大热门<br />视听盛宴</div>
                        </div>
                        <div>
                            <div className="part-title">百位嘉宾大聚</div>
                            <div className="tip">明星云集<br />“CG春晚”</div>
                        </div>
                        <div>
                            <div className="part-title">百万级全网曝光</div>
                            <div className="tip">数十家媒体<br />数百万专业粉丝</div>
                        </div>
                    </div>
                </div>
                <div className="date">
                    <div className="sub-title">12/21会程</div>
                    <div className="date-line">
                        <div className="date-top">
                            <div className="time">上午 10:00-12:00</div>
                            <div className="line" />
                            <div className="icon" />
                            <div className="date-tip">
                                2024 GGAC AWARDS
                            </div>
                        </div>
                        <div className="date-content dashed">
                            <div className="left">
                                <div className="word">2024数字艺术嘉年华</div>
                            </div>
                            <div className="right">
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        10:00
                                    </div>
                                    <div className="value">
                                        <div className="date-title">开幕致辞</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge1.png" />
                                            <div className="info">
                                                <div className="info-name">林永民</div>
                                                <div className="info-tip">
                                                    <div>GGAC大赛创办人</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        10:15
                                    </div>
                                    <div className="value">
                                        <div className="date-title">颁奖仪式  年度作者</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="date-content">
                            <div className="left">
                                <div className="word">GGAC公开课·年度分享</div>
                            </div>
                            <div className="right">
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        10:30
                                    </div>
                                    <div className="value">
                                        <div className="date-title">数字艺术家的自由生长：从《楚歌》到《狼藉》的故事</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge2.png" />
                                            <div className="info">
                                                <div className="info-name">徐子晖</div>
                                                <div className="info-tip">
                                                    <div>自由插画师、</div>
                                                    <div>《楚歌》《狼藉》作者</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        10:50
                                    </div>
                                    <div className="value">
                                        <div className="date-title">《班达若之光》的创作解析与世界观搭建分享</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge3.png" />
                                            <div className="info">
                                                <div className="info-name">格子</div>
                                                <div className="info-tip">
                                                    <div>自由插画师、原画师</div>
                                                    <div>概念设计师</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        11:10
                                    </div>
                                    <div className="value">
                                        <div className="date-title">原创概念设计《蜡烛镇》游戏世界观搭建</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge4.png" />
                                            <div className="info">
                                                <div className="info-name">蟹同学的书包</div>
                                                <div className="info-tip">
                                                    <div>概念设计师 </div>
                                                    <div>自由艺术家</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        11:30
                                    </div>
                                    <div className="value">
                                        <div className="date-title">创作者圆桌</div>
                                        <div className="prize-judge mb0">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-judge.png" />
                                            <div className="info">
                                                <div className="info-name">蟹同学的书包</div>
                                                <div className="info-tip">
                                                    <div>概念设计师 </div>
                                                    <div>自由艺术家</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="date-top">
                            <div className="time">下午 13:00-18:00</div>
                            <div className="line" />
                            <div className="icon" />
                            <div className="date-tip">
                                2024 GGAC AWARDS
                            </div>
                        </div>
                        <div className="date-content dashed">
                            <div className="left">
                                <div className="word">GGAC公开课·年度分享</div>
                            </div>
                            <div className="right">
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        13:00
                                    </div>
                                    <div className="value">
                                        <div className="date-title">如何搭建概念设计框架？—动画游戏双栖艺术家对娱乐化设计概念的理解浅谈</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge5.png" />
                                            <div className="info">
                                                <div className="info-name">JASON 王毅</div>
                                                <div className="info-tip">
                                                    <div>CG概念艺术家</div>
                                                    <div>编剧、动画导演</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        13:20
                                    </div>
                                    <div className="value">
                                        <div className="date-title">浅析CG创作中的动态人体Pose</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge6.png" />
                                            <div className="info">
                                                <div className="info-name">孙文俊</div>
                                                <div className="info-tip">
                                                    <div>GGAC大赛评委、3D角色设计师</div>
                                                    <div>人体艺术爱好者</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        13:40
                                    </div>
                                    <div className="value">
                                        <div className="date-title">《XXX》</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge7.png" />
                                            <div className="info">
                                                <div className="info-name">黄成希</div>
                                                <div className="info-tip">
                                                    <div>GGAC大赛评委、3D角色设计师</div>
                                                    <div>人体艺术爱好者</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        14:00
                                    </div>
                                    <div className="value">
                                        <div className="date-title">天何言十年-从影游视觉开发到泛娱乐内容孵化</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge7.png" />
                                            <div className="info">
                                                <div className="info-name">天何言工作室</div>
                                                <div className="info-tip">
                                                    <div>制片人 唐山立</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        14:20
                                    </div>
                                    <div className="value">
                                        <div className="date-title">关于个人IP《布米》创作的十年</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge8.png" />
                                            <div className="info">
                                                <div className="info-name">李超雄</div>
                                                <div className="info-tip">
                                                    <div>中国电影美术学会会员</div>
                                                    <div>广州市动漫艺术家协会会员</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="date-content dashed">
                            <div className="left">
                                <div className="word">第六届GGAC大赛</div>
                                <div className="word">颁奖典礼</div>
                            </div>
                            <div className="right">
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        14:40
                                    </div>
                                    <div className="value">
                                        <div className="date-title">颁奖致辞</div>
                                        <div className="prize-judge">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-date-judge9.png" />
                                            <div className="info">
                                                <div className="info-name">黄非</div>
                                                <div className="info-tip">
                                                    <div>中国电影美术学会秘书长</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        15:00
                                    </div>
                                    <div className="value">
                                        <div className="date-title">颁奖仪式 第六届GGAC大赛</div>
                                        <div className="date-prize">奖项：<span>特别赛道/优秀奖/人气奖/校园奖/毕设奖/创作奖</span></div>
                                        {/* <div className="prize-judge mb0">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-judge.png" />
                                            <div className="info">
                                                <div className="info-name">黄非</div>
                                                <div className="info-tip">
                                                    <div>中国电影美术学会秘书长</div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="date-content dashed">
                            <div className="left">
                                <div className="word">GGAC公开课</div>
                                <div className="word">年度论坛</div>
                            </div>
                            <div className="right">
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        16:40
                                    </div>
                                    <div className="value">
                                        <div className="date-title">2024年度圆桌</div>
                                        <div className="prize-judge mb0">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-judge.png" />
                                            <div className="info">
                                                <div className="info-name">黄非</div>
                                                <div className="info-tip">
                                                    <div>中国电影美术学会秘书长</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="date-content">
                            <div className="left">
                                <div className="word">第六届GGAC大赛</div>
                                <div className="word">大奖揭晓！</div>
                            </div>
                            <div className="right">
                                <div className="item">
                                    <div className="label">
                                        <div className="label-icon" />
                                        17:30
                                    </div>
                                    <div className="value">
                                        <div className="date-title">颁奖仪式  第六届GGAC大赛 · 特等奖</div>
                                        {/* <div className="prize-judge mb0">
                                            <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-judge.png" />
                                            <div className="info">
                                                <div className="info-name">黄非</div>
                                                <div className="info-tip">
                                                    <div>中国电影美术学会秘书长</div>
                                                </div>
                                            </div>
                                        </div> */}  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub-title">重磅嘉宾</div>
                <div className="judge">
                    {
                        judgeList.map(item => {
                            return <div className="judge-item" key={item.id}>
                                <div className="judge-top">
                                    <img src={item.url} />
                                    <div className="name">
                                        {
                                            item.name.map(name => {
                                                return <div key={name}>{name}</div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="judge-desc" style={{ bottom: item.desc.length === 3 ? "-20px" : item.desc.length === 1 ? "18px" : "0" }}>
                                    {
                                        item.desc.map(desc => {
                                            return <div key={desc}>{desc}</div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="judge-tip">*排名不分先后</div>
            </div>
            <div className="title title-activity">
                <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-title2.png" alt="" />
            </div>
            <div className="part2">
                <div className="activity-title">
                    <div>2024 GGAC年榜</div>
                    <div>“CG艺术百大作者”</div>
                </div>
                <div className="border">
                    <div className="prize">
                        {
                            prizeList1.map(item => {
                                return <div className="prize-item" key={item.id}>
                                    <img src={item.image} alt="" />
                                    <div className="name">{item.name}</div>
                                    <div className="tip">
                                        {
                                            item.tip.map(tip => {
                                                return <div key={tip}>{tip}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="btn" onClick={() => window.open("https://www.ggac.com/official/2604")}>查看活动详情</div>
                </div>
                <div className="activity-title">
                    <div>中国邮政 X 上海美术家协会 X GGAC</div>
                    <div>蛇年贺图征集</div>
                    <div className="activity-title-tip">带上#蛇年春节#或#2025新年#标签，分享自己对明年的展望，就有机会获得以下奖励</div>
                </div>
                <div className="border2-top">
                    <div className="border2-top-item">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-border-icon1.png" alt="" />
                        <div className="name">顶级曝光</div>
                        <div className="tip">登上GGAC<span>第六届颁奖典礼</span><br />数百万全网曝光，数十家媒体宣发</div>
                    </div>
                    <div className="border2-top-item">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-border-icon2.png" alt="" />
                        <div className="name">官方发行</div>
                        <div className="tip">入选<span>中国邮政</span>官方2025<br />蛇年生肖邮票集并发行</div>
                    </div>
                    <div className="border2-top-item">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-border-icon3.png" alt="" />
                        <div className="name">美协评选</div>
                        <div className="tip"><span>上海美术家协会</span>举办的<br />【蛇 生肖大展】评选获得美协积分</div>
                    </div>
                </div>
                <div className="border2-bottom">
                    <div className="border2-bottom-item">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-border-icon4.png" alt="" />
                        <div className="name">第一桶金</div>
                        <div className="tip">获得<span>授权合作机会</span>，赚取2025<span>第一桶金</span></div>
                    </div>
                    <div className="border2-bottom-item">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-border-icon5.png" alt="" />
                        <div className="name">更多奖励</div>
                        <div className="tip">赢取GGAC新年小礼包及最新<span>实体徽章</span></div>
                    </div>
                </div>
                <div className="btn btn2" onClick={() => window.open("https://www.ggac.com/official/2606")}>查看活动详情</div>
                <div className="sub-title">创作变现，努力搞钱</div>
                <div className="part-tip">站内约稿功能升级啦！限时免服务费，快来体验吧！</div>
                <div className="border3">
                    {
                        prizeList2.map(item => {
                            return <div className="border3-item" key={item.id}>
                                <img src={item.image} alt="" />
                                <div className="name">
                                    {
                                        item.name.map(name => {
                                            return <div key={name}>{name}</div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="btn btn2" onClick={toPublish}>立即发布约稿</div>
            </div>
            <div className="title title-history">
                <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-title3.png" alt="" />
            </div>
            <div className="part3">
                {/* <div className="sub-title">关键数据</div> */}
                {/* <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part3-image1.png" alt="" /> */}
                <div className="third-title">
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-third-title-left.png" alt="" />
                    <div>我们一起</div>
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-third-title-right.png" alt="" />
                </div>
                <div className="sub-title">踊跃参赛</div>
                <CeremonySwiper list={list1} moreUrl="https://www.ggac.com/events" />
                <div className="sub-title">携手参展</div>
                <CeremonySwiper list={list2} />
                {
                    list3.length > 0 && <>
                        <div className="sub-title">积极共创</div>
                        <CeremonySwiper list={list3} moreUrl="https://www.ggac.com/events/exhibition/list" aspect={450 / 300} />
                    </>
                }
                <div className="sub-title">努力搞钱</div>
                <CeremonySwiper list={list5} moreUrl="https://www.ggac.com/mall/market" aspect={450 / 300} />
                <div className="sub-title">高效学习</div>
                <CeremonySwiper list={list4} moreUrl="https://www.huilahuila.com/#/free" />
            </div>
            <div className="title title-thank">
                <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-title4.png" alt="" />
            </div>
            <div className="part4">
                <div className="third-title">
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-third-title-left.png" alt="" />
                    <div>亲爱的创作者与伙伴们</div>
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-third-title-right.png" alt="" />
                </div>
                <div className="cooperation">
                    {
                        cooperationList.map(item => {
                            return <div className="cooperation-item" key={item.id}>
                                <div className="cooperation-item-title">{item.name}</div>
                                <div className="cooperation-item-tip">{item.tip}</div>
                            </div>
                        })
                    }
                    <img className="cooperation-icon" src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part4-person.png" />
                </div>
            </div>
            <div className="title title-next">
                <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-title5.png" alt="" />
            </div>
            <div className="part5">
                <div className="third-title">
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-third-title-left.png" alt="" />
                    <div>梦想不停，逐光而行</div>
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-third-title-right.png" alt="" />
                </div>
                <div className="about">
                    {
                        aboutList.map(item => {
                            return <div className="about-item" key={item}>
                                <div>{item}</div>
                            </div>
                        })
                    }
                </div>
                <div className="sub-title">GGAC历届大赛</div>
                <div className="contest">
                    {
                        contestList.map(item => {
                            return <div className="contest-item" key={item.id}>
                                <img src={item.src} alt="" />
                                <div className="btn" onClick={() => window.open(item.url)}>查看详情</div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="title title-call">
                <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-title6.png" alt="" />
            </div>
            <div className="code">
                {
                    codeList.map(item => {
                        return <div className="code-item" key={item.id}>
                            <div className="code-top">
                                {item.name}
                                <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-code-arrow.png" />
                                {item.person}
                            </div>
                            <div className="code-bottom">
                                <img src={item.url} alt="" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="code-tip">
                感谢G站创作者：<a href="https://www.ggac.com/user/263767/works">小岳</a>、<a href="https://www.ggac.com/user/40052/works">MORNCOLOUR</a>、<a href="https://www.ggac.com/user/111913/works">冷可汀</a>、<a href="https://www.ggac.com/user/89133/works">PPPPPPPP</a>，对本届艺术嘉年华及颁奖典礼页面的视觉支持。
            </div>
        </div>
        {
            isFixed && <div className="absolute">
                <div className="absolute-item">
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side1.png" />
                    <div className="popup">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side6.png" alt="" />
                    </div>
                </div>
                <div className="absolute-item">
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side2.png" />
                    <div className="popup">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side7.png" alt="" />
                    </div>
                </div>
                <div className="absolute-item">
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side3.png" />
                    <div className="popup">
                        <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side8.png" alt="" />
                    </div>
                </div>
                <div className="absolute-item" onClick={() => window.open("https://live.bilibili.com/13624793?broadcast_type=0&is_room_feed=1&spm_id_from=333.999.to_liveroom.0.click&live_from=86002")}>
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side4.png" />
                </div>
                <div className="absolute-item" onClick={() => window.open("https://m.weibo.cn/search?containerid=231522type%3D1%26q%3D%23%E7%AC%AC%E5%85%AD%E5%B1%8AGGAC%E5%A4%A7%E8%B5%9B%23&luicode=10000011&lfid=231522type%3D1%26q%3D%23%E7%AC%AC%E5%85%AD%E5%B1%8AGGAC%E5%A4%A7%E8%B5%9B%23&v_p=42")}>
                    <img src="https://cdn-prd.ggac.com/ggac/ceremony/2024/ceremony-side5.png" />
                </div>
            </div>
        }
    </Ceremony2024Style>
}

export default CereMony2024