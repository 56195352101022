import { useEffect, useState } from "react"
import { MyRewardStyle } from "./style"
import { getMyReward, postEditQuestionState } from "api/forum"
import { ForumMineQuestionListItem } from "api/forum/type"
import { Modal, Pagination, message } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { queryUrl } from "utils/functions/common"
import SelectAnswerer from "./select-answerer"
import { NoData } from "components/no-data"
import CommonTabs from "ui-lib/tab/common-tab"


const MyReward = () => {
    const [list, setList] = useState<ForumMineQuestionListItem[]>([])
    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const state = new URLSearchParams(useLocation().search).get("state") || "1"
    const [totalSize, setTotalSize] = useState(0)
    const [isShowModal, setIsShowModal] = useState(false)
    const [topicId, setTopicId] = useState(0)

    const pageSize = 10

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        getList()
    }, [pageNumber, state])

    const getList = () => {
        getMyReward({ pageSize, pageNumber: Number(pageNumber), status: Number(state) }).then(res => {
            setList(res.pageData)
            setTotalSize(res.totalSize)
        })
    }

    const changePage = (e: number) => {
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const openModal = (detail: ForumMineQuestionListItem) => {
        setIsShowModal(true)
        setTopicId(detail.id)
    }

    const link = () => {
        navigate(`/forum/reward/index`, { replace: true })
    }

    const editQuestionState = (detail: ForumMineQuestionListItem) => {
        postEditQuestionState({ topicId: detail.id, flag: detail.status === 3 ? 1 : 0 }).then(() => {
            message.success(detail.status === 3 ? "重启成功" : "关闭成功")
            getList()
        })
    }

    const toPay = () => {
        navigate(`/forum/reward/order/list?state=0`)
    }

    const readAnswer = (state: number) => {
        navigate(`/forum/reward/order/list?state=${state}`)
    }

    const tabList = [
        {
            key: '1',
            title: "悬赏中",
        },
        {
            key: "2",
            title: "回答中",
        },
        {
            key: '3',
            title: "已回答",
        },
        {
            key: "4",
            title: "已完成",
        },
        {
            key: "5",
            title: "已关闭",
        }
    ]

    const checkTab = (detail: any) => {
        const query = queryUrl({ state: detail.key, pageNumber: "" })
        navigate(`?${query}`)
    }


    return <MyRewardStyle>
        <div className="list">

            <div className="list-title">
                <div className="link" onClick={() => link()}>
                    返回 悬赏主页
                </div>
                <span>{`>`}</span>我的悬赏
            </div>

            <CommonTabs
                tabs={tabList}
                onClick={(item) => checkTab(item)}
                defaultKey={state || '1'}
            ></CommonTabs>

            {
                list.length > 0 && <>
                    {
                        list.map(item => {
                            return <div key={item.id} className="list-item">
                                <div className="title">
                                    <div className="title-word" onClick={() => window.open(`/forum/reward/detail/${item.id}`)}>{item.title}</div>
                                    <div className="status">
                                        {
                                            item.status === 3 && <>已关闭</>
                                        }
                                        {
                                            [1, 2].includes(item.status) && <>
                                                {
                                                    item.orderStatus === 0 && item.status === 1 && <>悬赏中</>
                                                }
                                                {
                                                    ((item.orderStatus === 0 && item.status === 2) || ([1, 2].includes(item.orderStatus))) && <>回答中</>
                                                }
                                                {
                                                    item.orderStatus === 3
                                                        ? "已回答"
                                                        : item.orderStatus === 4
                                                            ? "已完成"
                                                            : ""
                                                }
                                            </>
                                        }
                                        {
                                            item.status === 4 && [0, 4].includes(item.orderStatus) && <>已完成</>
                                        }
                                    </div>
                                </div>
                                <div className="desc">
                                    <div className="desc-left">
                                        {
                                            item.status !== 3 &&
                                            <>
                                                {item.applyCount}人抢答
                                                {
                                                    item.status === 1 && <> 待选人 </>
                                                }
                                                {
                                                    item.status === 2 && item?.invitedUserInfo && <> 已选：{item?.invitedUserInfo.username}</>
                                                }

                                            </>

                                        }
                                    </div>
                                    <div className="desc-right" onClick={() => window.open(`/forum/reward/detail/${item.id}`)}>问题详情 {`>`}</div>
                                </div>
                                <div className="bottom">
                                    <div className="price">
                                        <div className="price-icon" />
                                        赏金￥{item.amount}
                                    </div>
                                    <div className="btns">
                                        {
                                            item.applyCount > 0 && item.canChoosed === 1 && item.status === 1 && <div onClick={() => openModal(item)}>立即选人</div>
                                        }
                                        {
                                            item.status === 2 && item.orderStatus == 1 && <div onClick={() => toPay()}>去支付</div>
                                        }
                                        {
                                            [3, 4].includes(item.orderStatus) && <div onClick={() => readAnswer(item.orderStatus - 1)}>查看答案</div>
                                        }
                                        {
                                            [1].includes(item.status) && <div onClick={() => editQuestionState(item)}>关闭问题</div>
                                        }

                                        {
                                            item.status === 3 && <div onClick={() => editQuestionState(item)}>重新上架</div>
                                        }

                                    </div>
                                </div>
                            </div>
                        })
                    }
                    <Pagination
                        style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                        hideOnSinglePage={true}
                        current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                        onChange={changePage}
                        pageSize={pageSize}
                        total={totalSize}
                        showSizeChanger={false}
                        size="default"
                    />
                </>
            }

            {
                list.length === 0 && <NoData />
            }

        </div>

        <Modal getContainer={false} title='选择答主' footer={<></>} width={650} destroyOnClose={true} closable={false} centered={true} open={isShowModal}>
            <SelectAnswerer close={() => setIsShowModal(false)} topicId={topicId} />
        </Modal>
    </MyRewardStyle>
}

export default MyReward