import { ArticleListItem } from "types/article"
import { DraftArticleActionStyle } from "./styled"
import { Button, Space, Tooltip } from "antd";
import { RiDeleteBin2Line, RiEdit2Line, RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const DraftArticleAction = ({ item, deleteWork }: { item: ArticleListItem, deleteWork: (id: number) => void }) => {
    const navigate = useNavigate()
    const viewWork = (item: ArticleListItem) => {

    }
    const editWork = (item: ArticleListItem) => {
        navigate(`/article/update/${item.id}`);
    }
    return <DraftArticleActionStyle>

        <Space>
            {
                item.status !== 1 && <Tooltip title="编辑" className="tooltip">
                    <Button
                        size="large"
                        shape="circle"
                        onClick={(e) => { e.stopPropagation(); editWork(item) }}
                    >
                        <RiEdit2Line></RiEdit2Line>
                    </Button>
                </Tooltip>
            }
            {
                item.status !== 1 && <Tooltip title="删除" className="tooltip">
                    <Button
                        size="large"
                        shape="circle"
                        onClick={(e) => { e.stopPropagation(); deleteWork && deleteWork(item.id) }
                        }
                    >
                        <RiDeleteBin2Line></RiDeleteBin2Line>
                    </Button>
                </Tooltip>
            }
            {item.status === 2 && item?.onSale !== 0 && (
                <Tooltip title="查看" className="tooltip">
                    <Button
                        onClick={() => viewWork(item)}
                        size="large"
                        shape="circle"
                    >
                        <RiEyeLine></RiEyeLine>
                    </Button>
                </Tooltip>
            )}
        </Space>
    </DraftArticleActionStyle>
}

export default DraftArticleAction