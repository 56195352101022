import { GlobalSearchPostResponse } from "types/common"
import { PrizeIconsStyle } from "./style"
import { WorkListItem } from "types/work"
import { FavoriteDataListItem } from "types/favorite"
import { WorkContestListItem } from "types/contest"
import { Tooltip } from "antd"
import { WorkLikeListsItem } from "types/user/work"

function PrizeIcons({ item }: { item: GlobalSearchPostResponse | WorkListItem | FavoriteDataListItem | WorkContestListItem | WorkLikeListsItem }) {

  let prize = item.prizeList && item.prizeList[0]

  const jumpUser = (e: any) => {
    console.log(e)
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    window.open('/user/' + prize.userInfo.id);
  }

  const renderTip = (judgeInfos: any) => {
    return (
      <>
        <div>本作品被以下评委推荐{prize?.name}</div>
        <div className="judge-wrap" style={{ display: "flex", gap: "10px", margin: "5px 0" }}>
          {
            judgeInfos.map((item: any) => {
              return <div className="judge-item" title={item.userInfo.username} onClick={() => window.open(
                `/user/${item?.userId}/works`
              )} style={{ background: `url(${item.userInfo.avatarUrl})`, width: "25px", aspectRatio: 1, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer" }}></div>
            })
          }
        </div >
      </>
    )
  };

  const renderTip2 = (judgeInfos: any) => {
    return (
      <>
        <div>本作品被以下评委推荐</div>
        <div className="judge-wrap" style={{ display: "flex", gap: "10px", margin: "5px 0" }}>
          {
            judgeInfos.map((item: any) => {
              return <Tooltip title={`本作品被${item.userInfo.username}推荐`}>
                <div className="judge-item" onClick={() => window.open(`/user/${item?.userId}/works`)} style={{ background: `url(${item.userInfo.avatarUrl})`, width: "25px", aspectRatio: 1, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer" }} />
              </Tooltip>
            })
          }
        </div >
      </>
    )
  };

  return (
    <PrizeIconsStyle>
      {/* 获奖图标 */}
      {item.prizeList && item.prizeList.length > 0 && prize && prize.type === 2 && (
        <Tooltip title={prize.name}>
          {
            !prize?.listIcon && <div className="item stage-prize" >
              <span className="title">{prize.name}</span>

              <img className="default-icon" src="https://cdn-prd.ggac.com/ggac/assets/image/icon/trophy.svg" alt="" />
            </div>
          }
          {
            prize?.listIcon && <div className="item stage-prize" style={{ backgroundColor: "transparent", marginTop: 0 }}><img src={prize?.listIcon} alt="" width={38} /></div>
          }
        </Tooltip>
      )}

      {(item?.recommendJudgeInfos?.length === 0 || !item?.recommendJudgeInfos) && item.prizeList && item.prizeList.length > 0 && prize && prize.type === 1 && (prize.userId > 0 || prize.userId === null) && (
        <div className="item stage-prize" >
          <span className="title">{prize.name}</span>
          {prize.userInfo && <Tooltip title={prize.userInfo.username}>
            <img src={prize.userInfo.avatarUrl} alt={prize.userInfo.username} onClick={jumpUser} className="avatar" />
          </Tooltip>
          }
        </div>
      )}

      {item.prizeList && item.prizeList.length > 0 && prize && prize.type === 1 && prize.userId < 0 && (
        <div className="item stage-prize" >
          <span className="title">{prize.name}</span>
          {prize.judgeInfos && prize.judgeInfos.length === 1 && <Tooltip title={`本作品被${prize.judgeInfos[0].userInfo.username}推荐${prize.name}`}>
            <img src={prize.judgeInfos[0].userInfo.avatarUrl} alt={prize.judgeInfos[0].userInfo.username} onClick={() => window.open(`/user/${prize.judgeInfos[0].userId}/works`)} className="avatar" />
          </Tooltip>
          }
          {prize.judgeInfos && prize.judgeInfos.length > 1 && <div className="judge-wrap" style={{ display: "flex" }}>
            {
              prize.judgeInfos.slice(0, 2).map((item, index) => {
                return <Tooltip title={`本作品被${item.userInfo.username}推荐${prize.name}`}>
                  <div className="judge-item" onClick={() => window.open(
                    `/user/${item?.userId}/works`
                  )} key={item.id} style={{
                    marginRight: prize.judgeInfos.length > 2 ? "-5px" : prize.judgeInfos.length === 2 && index === 1 ? '0' : '-5px',
                    background: `url(${item.userInfo.avatarUrl})`, width: "24px", aspectRatio: 1, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer"
                  }} />
                </Tooltip>
              })
            }
            {
              prize.judgeInfos && prize.judgeInfos.length > 2 && <Tooltip title={renderTip(prize.judgeInfos)}>
                <div className="circle" style={{ width: "24px", aspectRatio: 1, backgroundColor: "#e6e6e6", borderRadius: "50%", display: "flex", gap: "2px", justifyContent: "center", alignItems: "center" }}>
                  {
                    [0, 1, 2].map(item => {
                      return <div className="circle-item" key={item} style={{ width: "3px", aspectRatio: 1, borderRadius: "50%", backgroundColor: "#90929e" }} />
                    })
                  }
                </div>
              </Tooltip>
            }
          </div>
          }
        </div>
      )}

      {!!item?.recommendJudgeInfos && item.recommendJudgeInfos.length > 0 && prize.type === 1 && (
        <div className="item stage-prize stage-prize2" >
          <span className="title">推荐</span>
          {item.recommendJudgeInfos.length === 1 && <Tooltip title={`本作品被${item.recommendJudgeInfos[0].userInfo.username}推荐`}>
            <img src={item.recommendJudgeInfos[0].userInfo.avatarUrl} alt={item.recommendJudgeInfos[0].userInfo.username} onClick={() => window.open(`/user/${item.recommendJudgeInfos && item.recommendJudgeInfos[0].userId}/works`)} className="avatar" />
          </Tooltip>
          }
          {item.recommendJudgeInfos.length > 1 && <div className="judge-wrap" style={{ display: "flex" }}>
            {
              item.recommendJudgeInfos.slice(0, 3).map((sub, index) => {
                return <Tooltip title={`本作品被${sub.userInfo.username}推荐`}>
                  <div className="judge-item" onClick={() => window.open(
                    `/user/${sub?.userId}/works`
                  )} key={sub.id} style={{
                    marginRight: item.recommendJudgeInfos && item.recommendJudgeInfos.length > 2 ? "-5px" : item.recommendJudgeInfos && item.recommendJudgeInfos.length === 2 && index === 1 ? '0' : '-5px',
                    background: `url(${sub.userInfo.avatarUrl})`, width: "24px", aspectRatio: 1, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer"
                  }} />
                </Tooltip>
              })
            }
            {
              item.recommendJudgeInfos.length > 3 && <Tooltip title={renderTip2(item.recommendJudgeInfos)}>
                <div className="circle" style={{ width: "24px", aspectRatio: 1, backgroundColor: "#fff", borderRadius: "50%", display: "flex", gap: "2px", justifyContent: "center", alignItems: "center" }}>
                  {
                    [0, 1, 2].map(sub => {
                      return <div className="circle-item" key={sub} style={{ width: "3px", aspectRatio: 1, borderRadius: "50%", backgroundColor: "#90929e" }} />
                    })
                  }
                </div>
              </Tooltip>
            }
          </div>
          }
        </div>
      )}

    </PrizeIconsStyle>
  )
}

export default PrizeIcons