import styled from "styled-components";

export const WorkFilterStyle = styled.div`
    padding: ${props => props.theme.space.paddingLG}px ${props => props.theme.space.paddingLG + 6}px;
    display: flex;
    gap: ${props => props.theme.space.paddingLG}px;
    background-color:  ${props => props.theme.colors.colorBlack1Ggac};
    .ant-select {
        width: 150px !important;
    }

    .wrap {
        display: flex;
        align-items: center;
        gap: ${props => props.theme.space.paddingLG}px;
    }

    .type-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-item {
            display: flex;
            gap:  ${props => props.theme.space.margin}px;
            margin-bottom: ${props => props.theme.space.marginXS}px;
            &:last-child{
                margin-bottom: 0;
            }
            .title {
                font-weight: bold;
                min-width: 115px;
            }
            .type-wrap {
                display: flex;
                width: 100%;
                justify-content: space-between;
                .right {
                    color: ${props => props.theme.colors.colorPrimary};
                    cursor: pointer;
                }
            }
            .type {
                display: flex;
                gap: ${props => props.theme.space.margin}px;
                
                &-item {
                    cursor: pointer;
                    display: flex;
                    gap: ${props => props.theme.space.margin}px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.active {
                        span { 
                            color: ${props => props.theme.colors.colorPrimary};
                        }
                    }
                    .second-type {
                        display: flex;
                        gap: ${props => props.theme.space.margin}px;
                    }
                    .second-active {
                        color: ${props => props.theme.colors.colorPrimary};
                    }
                }
                
            }
        }
    }
`