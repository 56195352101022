import styled from "styled-components";

export const DetailStyle = styled.div`
  margin-bottom: 40px;
  .wrap {
    position: relative;
    max-width: 1420px;
    margin: 0 auto;
    display: flex;
    gap: ${(props) => props.theme.space.paddingLG}px;
    flex-direction: column;
    .border-box {
      border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
    }
    .product-up-part {
      display: flex;
      padding: ${(props) => props.theme.space.paddingLG}px;
      margin-top: ${(props) => props.theme.space.paddingLG}px;
      .left {
        width: 800px;
        .pre-image {
          height: 450px;
          width: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: ${(props) => props.theme.border.borderRadius}px;
          background-color: #000000;
          position: relative;
          cursor: pointer;
          .nav_ {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 24px;
            color: #999999;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
              color: #e9e9e9;
            }
            &.left_ {
              left: 20px;
            }
            &.right_ {
              right: 20px;
            }
          }
        }
        .image-bottom {
          /* display: grid; */
          margin-top: ${(props) => props.theme.space.margin}px;
          overflow-x: auto;
          display: flex;
          gap: 5px;
          /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
          /* overflow-x: auto; */
          .image-wrap {
            display: flex;
            margin-bottom: 5px;
            gap: 5px;
          }
          .arrow {
            background: rgba(0, 0, 0, 0.3);
            width: 29px;
            height: 70px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            cursor: pointer;
          }
        }
        .image-item {
          width: 110px;
          height: 70px;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: ${(props) => props.theme.border.borderRadius}px;
          /* border: 3px solid transparent; */
          cursor: pointer;
          background-position: center;
          &.active {
            border: 3px solid ${(props) => props.theme.colors.colorPrimary};
          }
        }

        .description {
          width: 100%;
          background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
          height: 50px;
          line-height: 50px;
          margin-top: ${(props) => props.theme.space.margin}px;
          padding: 0 ${(props) => props.theme.space.padding}px;
        }
      }

      .right {
        flex: 1;
        .title {
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 15px;
          color: #202020;
        }

        .first-row {
          display: flex;
          align-items: center;
          gap:12px;
          .coupon {
            width: auto !important;
            cursor: pointer;
            display: flex;
            align-items: center;
            background: linear-gradient(to bottom right, #FF9135 35%, #FF3B3C 100%);
            border-radius:24px;
            color: #fff;
            padding: 0 12px !important;
            height: 28px;
            margin-bottom: 12px;
          }
        }
        font-size: 14px;

        padding: 0 25px;

        .specs {
          display: flex;
          align-items: center;
          &-right {
            display: flex;
            margin-left: ${(props) => props.theme.space.marginXS}px;
            .cover {
              width: 40px;
              height: 40px;
              background-size: cover;
              border-radius: 50%;
            }
          }
        }
        .price {
          align-items: flex-end !important;
          .label {
            padding-bottom: 3px;
          }
        }
        .item {
          display: flex;
          margin-bottom: 15px;
          gap: 10px;
          align-items: center;
          .label {
            color: #8a8a8b;
            font-size: 14px;
            width: max-content;
          }
          .value {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            gap: ${(props) => props.theme.space.margin}px;
            flex: 1;
            .rmbPrice {
              color: #ff9a16;
              font-size: 28px;
              align-items: center;

              display: flex;
              align-items: flex-end;
              span:first-child {
                font-size: 16px;
              }
              span:nth-child(2) {
                margin-bottom: -4px;
              }
              .qi {
                font-size: 14px !important;
                color: rgba(0, 0, 0, 0.4) !important;
                margin-bottom: 2px;
              }
            }
            .originalPrice {
              font-size: 14px;
              color: #8a8a8b;
              text-decoration: line-through;
              margin-top: 10px;
            }
            .active {
              border: 1px solid #ff9a16;
              .name {
                color: #ff9a16 !important;
              }
            }
            .sku-item {
              background: #f1f1f3;
              display: flex;
              align-items: center;
              padding: 2px;
              cursor: pointer;
              border-radius: 6px;
              .cover {
                width: 28px;
                height: 28px;
                border-radius: 4px;
                background-size: cover;
              }
              .name {
                padding: 0 10px;
                font-size: 14px;
                color: #202020;
                max-width: 400px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .btn {
          width: 300px;
          height: 40px;
          letter-spacing: 2px;
          font-size: 16px;
          margin-bottom: ${(props) => props.theme.space.margin}px;
          &.active {
            border-color: ${(props) => props.theme.colors.colorPrimary};
            color: ${(props) => props.theme.colors.colorPrimary};
          }
        }
        .specs-detail {
          display: flex;
          color: #8a8a8b;
          div {
            padding: 0 30px;
            position: relative;
          }
          div:first-child {
            padding-left: 0;
          }
          div:last-child {
            padding-right: 0;
            &::after {
              height: 0;
            }
          }
          div::after {
            content: "";
            position: absolute;
            right: 0;
            height: 60%;
            width: 2px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #e9e9e9;
          }
        }
        button {
          margin-bottom: ${(props) => props.theme.space.marginXS}px;
        }
        .tip {
          font-size: 12px;
          font-weight: 400;
          color: #8a8a8b;
          line-height: 18px;
          margin-top: ${(props) => props.theme.space.marginLG}px;
        }
        .user {
          display: flex;
          align-items: center;
          &-image {
            border-radius: 50%;
            width: 80px;
            height: 80px;
            background-size: cover;
            margin-right: ${(props) => props.theme.space.marginXS}px;
          }
        }
      }
    }

    .product-down-part {
      display: flex;
      gap: ${(props) => props.theme.space.paddingLG}px;

      .left-a {
        flex: 1;
        padding-bottom: 50px;
        .top {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          .share {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            a {
              width: 100px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
        .bottom-content {
          padding: ${(props) => props.theme.space.padding}px
            ${(props) => props.theme.space.paddingLG}px;
          .tags {
            position: absolute;
            bottom: 0;
            left: 20px;
          }
        }
      }
      .right-b {
        width: 332px;
        display: flex;
        flex-direction: column;
        gap: ${(props) => props.theme.space.paddingLG}px;
        .visit-shop {
          border-color: ${(props) => props.theme.colors.colorPrimary};
          color: ${(props) => props.theme.colors.colorText};
          min-width: 68px;
          height: 28px;
          padding: 2px 8px;
        }
      }
    }

    .modal {
      .title {
        font-size: 25px;
        text-align: center;
      }
      .sub-title {
        font-size: 20px;
        color: red;
        text-align: center;
        margin-bottom: 10px;
      }
      .second-title {
        margin-top: 10px;
        text-align: center;
      }
      .third-title {
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: ${(props) => props.theme.colors.colorLinkDefault};
        display: flex;
        align-items: center;
        width: max-content;
        margin: 0 auto ${props => props.theme.space.marginXS}px;
        div {
          max-width: 140px;
          padding-bottom: 2px;
        }
        .username:hover {
          color: ${(props) => props.theme.colors.colorLinkHover};
        }
        .icon {
          font-size: 18px;
          color: ${(props) => props.theme.colors.colorPrimary};
          padding-right: ${(props) => props.theme.space.paddingXXS}px;
        }
      }
      .code-wrap {
        text-align: center;
        .ant-qrcode {
          margin: 0 auto;
        }
        .code-time {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          
          .ant-statistic-content {
            display: flex;
            align-items: center;
          }
        
          .ant-statistic-content-value {
            font-size: 16px;
          }
        }
      }
    }
  }
`;

export const RichTextContentStyle = styled.div`
  .list {
    line-height: 35px;
    border-bottom: 1px solid ${props => props.theme.colors.colorBorder};
    padding-bottom:  ${props => props.theme.space.padding}px;
    div {
      display: flex;
      gap: 10px;
    }
  }
  .html-content {
    img,video {
      max-width: 100%;
    }
  }
  .image-p {
    margin: 0 !important;
  }
`;
