import { Button, Modal, Pagination } from "antd"
import { RelateWorkStyle } from "./style"
import { useEffect, useState } from "react"
import { apiMyWorkList } from "api/user/work"
import { WorkListItem } from "types/work"
import { apiGetWorkDraftDetail, importContentFromWork } from "api/work"
import { useSearchParams } from "react-router-dom"

type workType = {
    coverUrl: string;
    id: number;
    title: string;
};

const RelateWork = ({ isShowRelate, onCancel, saveWork, relatdWorkList, onImportContent }: { relatdWorkList: workType[], isShowRelate: boolean, onCancel: () => void, saveWork: (value: workType[]) => void, onImportContent: (value: string) => void }) => {
    const [workList, setWorkList] = useState<WorkListItem[]>([])
    const [selectWork, setSelectWork] = useState<workType[]>([])
    const [totalSize, setTotalSize] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const pageSize = 12

    const onClose = () => {
        setSelectWork([])
        onCancel()
    }

    useEffect(() => {
        if (!isShowRelate) {
            return
        }
        setPageNumber(1)
        setSelectWork([...relatdWorkList])
    }, [isShowRelate, relatdWorkList])

    useEffect(() => {
        apiMyWorkList({ onSale: 1, isPublic: 1, pageSize, pageNumber }).then(res => {
            setWorkList(res.pageData)
            setTotalSize(res.totalSize)
        })
    }, [pageNumber])

    const select = (detail: WorkListItem) => {
        const index = selectWork.findIndex(item => item.id === detail.id)
        if (index === -1) {
            setSelectWork((result) => [...[detail], ...result])
        } else {
            selectWork.splice(index, 1)
            setSelectWork([...selectWork])
        }
    }

    const submit = () => {
        saveWork(selectWork)
        onCancel()
    }

    const changePage = (e: number) => {
        setPageNumber(e)
    }

    const importContent = (id: number) => {
        importContentFromWork({ id }).then(res => onImportContent(res))
    }


    return <RelateWorkStyle>
        <Modal className='modal' title='请选择关联作品' onCancel={onCancel} centered={true} footer={null} getContainer={false} open={isShowRelate} afterClose={() => setSelectWork([])}>
            <div className="wrap">
                {
                    workList?.map(item => {
                        return <div className="work-item" key={item.id} >
                            <div onClick={() => select(item)} className={`work ${selectWork.find(sub => sub.id === item.id) ? 'active' : ''}`}>
                                <div className="cover" style={{ backgroundImage: `url(${item.coverUrl})` }} />
                                <div className="name" title={item.title}>{item.title}</div>

                            </div>
                            <div className="import" onClick={() => importContent(item.id)}>复制到商品描述</div>
                        </div>
                    })
                }
            </div>
            <Pagination
                style={{ textAlign: "center", margin: "12px 0 42px 0" }}
                hideOnSinglePage={true}
                current={Number(pageNumber)}
                pageSize={pageSize}
                onChange={changePage}
                total={totalSize}
                showSizeChanger={false}
            />
            <div className="btns">
                <Button type="primary" onClick={submit}>确定</Button>
                <Button type="primary" ghost onClick={onClose}>取消</Button>
            </div>
        </Modal>
    </RelateWorkStyle>
}

export default RelateWork