import { post } from "api"
import { QQWebLoginResponse, QQWebRegisterRequest } from "types/qq"


// 登录
export const apiQQLogin = (params: {code: string, appType: number}) =>{
    return  post<{code: string}, QQWebLoginResponse>('/api/wx_user/login', params)
}

// 注册
export const apiQQRegister = (params: QQWebRegisterRequest) =>{
    return post<QQWebRegisterRequest, {
        token: string
        isNew: number
    }>('/api/wx_user/register', params)
}
export const apiBindQQ = (code: string) => {
    return post('/api/wx_user/bind',{appType: 3,code: code})
}


export const apiUnbindQQ = (appType: number) => {
    return post(`/api/wx_user/unbind/${appType}`)
}