import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { plugins, toolbarString } from "./fn";
import { Modal,Progress,Spin,UploadFile, message } from "antd";
import { getFileName } from "utils/functions/common";
import {  MediaType } from "types/enums/media-type";
import { getFileUrlByCosUpload } from "config/cos";
import { apiCreateMedia } from "api/media";
import { useParams } from "react-router-dom";
import { PicturesWall } from "components/uploadImageList";
import { ProgressInfo } from "cos-js-sdk-v5";
import { VideoUpload } from "components/videoUpload";
import { TinyMceStyle } from "./style";

type ContentObject = { html?: string; media?: number[] };

type Props = {
  value?: ContentObject;
  onChange?: (content: ContentObject) => void;
  width?: number | string;
  cosImagePath:string;
  cosVideoPath:string;
};

export default function TinyEditor({ value, onChange,width='100%',cosImagePath,cosVideoPath }: Props) {
  const { id } = useParams();
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [loading, setLoading] = useState(false)
  const [showImgModal, setShowImgModal] = useState(false)
  const [showVidModal, setShowVidModal] = useState(false)
  const [picList, setPicList] = useState<UploadFile[]>([])
  const [innerHtml, setInnerHtml] = useState<string>("");
  const [mediaIds, setMediaIds] = useState<number[]>([])
  const [video, setVideo] = useState<{file?: File, videoUrl?: string}>({
    file: undefined,
    videoUrl: ''
  })
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    value?.html && setInnerHtml(value?.html);
    
  }, [value?.html]);

  const insertImage = (url:string) => {
   
    if (editorRef.current) {
      // url = 'https://cdn-dev.ggac.com/image/2023/8/22/0de1c03c-0f08-4d46-8ee5-1bf0b0945d1c.png'
        editorRef.current.execCommand('InsertHtml', false, `<p class="image-p"><img style="max-width:100%;display: block; margin-left: auto; margin-right: auto;" src="${url}" width="auto"></p>`)
    }
  };

  const insertVideo = async (url:string)=>{
    //url = 'https://cdn-dev.ggac.com/video/2023/8/31/0db9e8dd-8cfd-4601-b643-a45d937719f8.mp4'
    if (editorRef.current) {
        editorRef.current.execCommand('InsertHtml', false, `<p>
        <video controls="controls" style="display: block; margin-left: auto; margin-right: auto;" width="100%" height="auto">
        <source src="${url}" type="video/mp4"></video>
        </p>`)
        // console.log(editorRef.current.getContent());
    }
  }
  const handleChange = (a: string, editor: TinyMCEEditor) => {
    let contentObject = { html: a, media: [] };
    setInnerHtml(a);
    onChange && onChange(contentObject);
  };

  const onClickConfirmImgUpload = async () =>{
    setLoading(true)
    try {
        for (let index = 0; index < picList.length; index++) {
          const item = picList[index];
          const uploadUUid = getFileName(cosImagePath, item!.name);
          const resultImg = await getFileUrlByCosUpload(
              item.originFileObj as unknown as File,
              uploadUUid,
          );
          const dataItem = await apiCreateMedia({
            coverUrl: "",
            dataId: Number(id),
            dataTable: "article",
            md5: resultImg.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
            name: item!.name.split(".").shift() + "",
            rank: 0,
            size: item!.size!,
            type: MediaType.image,
            url: resultImg.url,
            mimeType: item!.type!,
            uuid: uploadUUid,
            caption: '',
            params:{
            specialEffectTemplateId: undefined
            }
        });
         mediaIds.push(dataItem.id)
            setMediaIds(mediaIds)
         item.url =   dataItem.url
         if(picList.length === index+1) {
             
          //插入图片 ，保持图片顺序
          picList.forEach(element => {
           
            element.url && insertImage(element.url)
          });
        
           setLoading(false)
           message.success('上传成功！')
           setShowImgModal(false)
       }
        }
    } catch (error) {
        setLoading(false)
    }
}

const onProgress = (progressData: ProgressInfo) =>{
    setProgress(Math.round(progressData.percent*100))
}

const onClickConfirmVideoUpload = async () =>{
    setLoading(true)
    try {
        let file = video.file
        const uploadUUid = getFileName(cosVideoPath, file!.name);
        const resultFile = await getFileUrlByCosUpload(
            file as unknown as File,
            uploadUUid,
            onProgress
        );

    const dataItem = await apiCreateMedia({
        coverUrl: "",
        dataId: Number(id),
        dataTable: "article",
        md5: resultFile.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
        name: file!.name.split(".").shift() + "",
        rank: 0,
        size: file!.size!,
        type: MediaType.video,
        url: resultFile.url,
        mimeType: file!.type!,
        uuid: uploadUUid,
        caption: '',
        params:{
        specialEffectTemplateId: undefined
        }
    });
    mediaIds.push(dataItem.id)
        setMediaIds(mediaIds)
    message.success('上传成功！')
    
    //插入视频
    insertVideo(dataItem.url)
        setLoading(false)
        setShowVidModal(false)


} catch (error) {
        setLoading(false)
}
}

const  onChangeVideoFile = (val: {coverUrl?: string
    videoUrl?: string,
    file?: File,
    videoRation?: number}) =>{
    setVideo({
        file: val.file!,
        videoUrl: val.videoUrl!
    })
}


const  onFileChange = (val: any) =>{
    setPicList(val)
 }

  return (
    <TinyMceStyle>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={innerHtml}
        onEditorChange={handleChange}
        init={{
          height: 400,
          width: width,
          menubar: false,
          language: "zh-Hans",
          placeholder:"请输入内容...",
          font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
          paste_data_images:false,
          paste_block_drop: false,
          paste_enable_default_filters: false,
          paste_retain_style_properties: false,
          paste_remove_styles_if_webkit: false,
          paste_strip_class_attributes: false,
          setup: (editor) => {
            editor.ui.registry.addButton("ggacImg", {
              text: "上传图片",
              onAction: () => {
             
               setPicList([])    
                setShowImgModal(true)
            },
            });
            editor.ui.registry.addButton("ggacVideo", {
              text: "上传视频",
              onAction: () => {    
              
                setVideo({})
                setShowVidModal(true)
            },
            
            });
            //用于调试
            // editor.ui.registry.addButton("ggacLog", {
            //     text: "LOG",
            //     onAction: () => {    
            //       log()       
            //       //setShowVidModal(true)
            //   },
            // });
          },
          plugins: plugins,
          toolbar: toolbarString,
          toolbar_mode: "wrap",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <Modal
        width={620}
        confirmLoading={loading}
        title="上传图片"
        open={showImgModal}
        onOk={onClickConfirmImgUpload}
        onCancel={() => {
            setShowImgModal(false);
        }}
      >
        
        <Spin spinning={loading}>
          <div><span style={{color: 'red', fontSize: '18px', margin: '2px'}}>*</span>单次最多上传8张图片，单张图片大小不超过20M，单张图片分辨率不超过7680x7680"</div>
                 
          <PicturesWall
            listType="picture-card"
            maxLength={8}
            value={picList}
            onChange={onFileChange}
          ></PicturesWall>
        </Spin>
      </Modal>

      <Modal
        confirmLoading={loading}
        title="上传视频"
        open={showVidModal}
        onOk={onClickConfirmVideoUpload}
        onCancel={() => {
          setProgress(0);
          setShowVidModal(false);
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
         
          <div className="left" style={{ alignSelf: "start" }}>
            <VideoUpload value={video} onChange={onChangeVideoFile} />
          </div>
          {loading && (
            <div
              className="right"
              style={{
                width: "100%",
                marginLeft: "20px",
              }}
            >
              <Progress strokeColor="#ffcd00" percent={progress} />
            </div>
          )}
        </div>
      </Modal>
    </TinyMceStyle>
  );
}
