import {
    Form,
    Select,
    Button,
    Input,
    message,
    FormInstance,
    Modal,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { LoginFormStyle } from "./style";
import { apiGetCaptcha, apiLoginSendEmail, apiLoginSendVerifyCode } from "api/common";
import { apiGetUserBaseInfo, postLoginByEmail } from "api/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sleep } from "utils/functions/common";
import { AgreementContext } from "./login";
import { getCaptchaType } from "types/common";

function LoginFormEmail({ isModal = false }: { isModal?: boolean }) {
    let [searchParams] = useSearchParams();
    const [countDown, setCountDown] = useState<number>(60);
    const [sendMsgBtnLoading, setSendMsgBtnLoading] = useState(false);
    const [captchaDetail, setDaptchaDetail] = useState<getCaptchaType>()
    const [messageApi, contextHolder] = message.useMessage();
    const formRef = useRef<FormInstance>(null);
    let navigate = useNavigate();
    const isAgree = useContext(AgreementContext);

    useEffect(() => {
        updateCaptchaCode()
    }, [])

    const updateCaptchaCode = () => {
        apiGetCaptcha().then(res => {
            setDaptchaDetail(res)
        })
    }


    const onFinish = async (values: any) => {
        if (!isAgree) {
            message.warning("请先同意用户协议！")
            return
        }
        const { token } = await postLoginByEmail({
            email: values.email.trim(),
            verCode: values.verifyCode,
        })
        localStorage.setItem('auth_token', token);
        const userInfo = await apiGetUserBaseInfo()
        localStorage.setItem("user_info", JSON.stringify(userInfo));
        message.success("登录成功！")
        sleep(500)
        if (isModal) {
            window.location.href = window.location.href
        } else {
            const redirectUrl = searchParams.get("redirect")
            if (redirectUrl) {
                window.location.href = redirectUrl
            } else {
                navigate('/home')
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const onSendCode = async () => {
        const email = formRef.current?.getFieldValue('email');
        const captchaCode = formRef.current?.getFieldValue('captchaCode')
        if (!captchaCode) {
            messageApi.error("请输入图形验证码！");
            return
        }
        if (!email) {
            messageApi.error("请输入邮箱地址！");
            return
        }

        //让按钮开始loading
        setSendMsgBtnLoading(true);
        // 让发送邮箱验证码
        apiLoginSendEmail({
            email: formRef.current?.getFieldValue("email"),
            captchaUid: String(captchaDetail?.uid),
            captchaCode: formRef.current?.getFieldValue('captchaCode')
        }).then(() => {
            messageApi.info("邮箱验证码已发送");
            //刷新倒计时开始
            let interval = setInterval(() => {
                setCountDown((pre) => {
                    if (pre === 0) {
                        setCountDown(60);
                        clearInterval(interval);
                    }
                    return pre - 1;
                });
            }, 1000);
        })
            .finally(() => {
                setSendMsgBtnLoading(false)
            });
    };


    return (
        <LoginFormStyle>
            {contextHolder}
            <Form
                name="basic"
                style={{ width: "100%" }}
                initialValues={{ remember: true, mobile: "", countryCode: '86' }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                requiredMark={false}
                ref={formRef}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: "请输入邮箱地址" },
                    {
                        pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                        message: '邮箱格式不正确',
                    },
                    {
                        max: 50,
                        message: '邮箱不得超过50字符',
                    },
                    ]}
                    normalize={(value) => value.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, '')}
                >
                    <Input placeholder="请输入邮箱地址" />
                </Form.Item>

                <div style={{ display: "flex", alignItems: "center" }}>

                    <Form.Item
                        name="captchaCode"
                        style={{ margin: 0, width: "184px" }}
                        rules={[{ required: true, message: "请输入图形验证码" }
                        ]}
                    >
                        <Input placeholder="请输入图形验证码" width={184} />
                    </Form.Item>
                    <img src={captchaDetail?.base64} onClick={updateCaptchaCode} style={{ marginLeft: "16px" }} width={132} alt="" />
                </div>
                <Form.Item
                    name="verifyCode"
                    style={{ marginTop: "16px" }}
                    rules={[
                        {
                            required: true,
                            message: "请填写验证码",
                        },
                        {
                            pattern: /^\d{4}$/,
                            message: "验证码为4位数字",
                        },
                    ]}
                >
                    <div className="code">
                        <Input className="verifyCode" placeholder="请输入邮箱验证码" />
                        <Button
                            type="primary"
                            onClick={() => onSendCode()}
                            disabled={countDown < 60}
                            loading={sendMsgBtnLoading}
                            className="sendMsg"
                        >
                            {countDown < 60 && countDown + "秒后重新获取"}
                            {countDown === 60 && !sendMsgBtnLoading && "获取验证码"}
                        </Button>
                    </div>
                </Form.Item>



                <Form.Item className="submit">
                    <Button type="primary" htmlType="submit" block>
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </LoginFormStyle>
    );
}

export default LoginFormEmail;
