import styled from "styled-components";

export const WithdrawlStyle = styled.div`
    .withdrawal-modal {
        .top {
            padding: ${props => props.theme.space.padding}px;
            background-color: rgb(241, 241, 241);
            margin-bottom: ${props => props.theme.space.margin}px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
                width: 22px;
                margin-right: ${props => props.theme.space.marginXS}px;
            }
        }
        .ant-form-item  {
            margin-bottom: 0;
        }
        .desc {
            text-align: right;
            color: ${props => props.theme.colors.colorTextSecondary};
            margin-top: ${props => props.theme.space.marginXXS}px;
            span {
                color: ${props => props.theme.colors.colorPrimary};
                cursor: pointer;
            }
        }
        .btns {
            display: flex;
            margin-top: ${props => props.theme.space.margin}px;
            justify-content: center;
            gap: ${props => props.theme.space.margin}px;
        }
    }
`