import { useCallback, useEffect, useState } from "react"
import { RewardOrderListStyle } from "./style"
import { getRewardOrderList, postCancelOrder, postRewardConfirmReceipt } from "api/forum"
import CommonTabs from "ui-lib/tab/common-tab"
import { forumOrderAllStatusList, forumOrderStatusList } from "views/forum/config"
import { useLocation, useNavigate } from "react-router-dom"
import { ForumOrderListItem } from "api/forum/type"
import { NoData } from "components/no-data"
import { Modal, Pagination, Spin, message } from "antd"
import { queryUrl } from "utils/functions/common"
import ReadDiliver from "./readDiliver"
import UISegmentedControl from "ui-lib/segmented-control"

const RewardOrderList = () => {

    const state = new URLSearchParams(useLocation().search).get("state") || ""
    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const topicId = new URLSearchParams(useLocation().search).get("topicId") || ""
    const pageSize = 10
    const [list, setList] = useState<ForumOrderListItem[]>([])
    const [totalSize, setTotalSize] = useState(0)
    const [loading, setLoading] = useState(false)
    const [isShowDiliver, setIsShowDiliver] = useState(false)
    const [orderNumber, setOrderNumber] = useState<string>("")

    const navigate = useNavigate()

    const getList = useCallback(() => {
        const params: any = { state, pageNumber: Number(pageNumber), pageSize }
        if (topicId) {
            params.topicId = Number(topicId)
        }
        getRewardOrderList(params).then(res => {
            setLoading(true)
            setList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [state, pageNumber, topicId])

    useEffect(() => {
        getList()
    }, [getList])



    const checkTab = (detail: { title: string, key: string | number }) => {
        if (state === detail.key) {
            return
        }
        navigate(`?${queryUrl({ state: detail.key, pageNumber: 0, topicId: "" })}`)
    }

    const changePage = (e: number) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const toOrderDetail = (orderNumber: string) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(`/forum/reward/order/${orderNumber}`)
    }

    const link = () => {
        navigate(`/forum/reward/index`, { replace: true })
    }

    const submit = (detail: ForumOrderListItem) => {
        Modal.confirm({
            title: "确认收货？",
            content: "确定确认收货吗",
            okText: "确认",
            cancelText: "取消",
            onCancel() { },
            centered: true,
            onOk() {
                postRewardConfirmReceipt({ flag: 1, orderNumber: detail.orderNumber }).then(() => {
                    message.success("确认收货成功");
                    navigate(`/forum/reward/order/list?state=3`)
                });
            },
        });
    }

    const cancelOrder = (detail: ForumOrderListItem) => {
        Modal.confirm({
            title: "取消订单？",
            content: "确定取消订单吗",
            okText: "确定",
            cancelText: "取消",
            onCancel() { },
            centered: true,
            onOk() {
                postCancelOrder(detail.orderNumber).then(() => {
                    message.success("取消订单成功");
                    getList()
                });
            },
        });
    }

    const toRead = (orderNumber: string) => {
        setIsShowDiliver(true)
        setOrderNumber(orderNumber)
    }

    const tabOption = [
        {
            label: "付款订单",
            value: "pay",
        },
        {
            label: "收款订单",
            value: "collection",
        },
    ];

    const changeWorkType = (value: any) => {
        if (value === 'collection') {
            navigate(`/forum/reward/receive-order`)
        }
    }

    return <RewardOrderListStyle>
        <div className="list">
            <div className="list-title">
                <div className="link" onClick={() => link()}>
                    返回 悬赏主页
                </div>
                <span>{`>`}</span>我的订单
            </div>
        </div>
        <div className="tab-wrap">
            <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={'pay'}></UISegmentedControl>
        </div>
        <CommonTabs
            tabs={forumOrderStatusList}
            onClick={(item) => checkTab(item)}
            defaultKey={state || ''}
        ></CommonTabs>
        {
            list.length > 0 && <>
                <Spin spinning={loading}>
                    <div className="list">
                        {
                            list.map(item => {
                                return <div className="list-item" key={item.id}>
                                    <div className="order">
                                        <div className="orderNumber">
                                            订单编号：{item.orderNumber}
                                        </div>
                                        <div className="word" onClick={() => toOrderDetail(item.orderNumber)}>订单详情 {`>`}</div>
                                    </div>
                                    <div className="topic">
                                        <div className="topic-title" onClick={() => window.open(`/forum/reward/detail/${item.topicId}`)}>
                                            {item.topicTitle}
                                        </div>
                                        <div className="status">
                                            {
                                                forumOrderAllStatusList.find(order => String(item.state) === order.key)?.title
                                            }
                                        </div>
                                    </div>

                                    <div className="price-wrap">
                                        <div className="price">
                                            <div className="price-icon"></div>
                                            赏金￥{item.amount}
                                        </div>
                                        <div className="btn-wrap">
                                            {
                                                item.state === 0 && <div onClick={() => cancelOrder(item)}>取消订单</div>
                                            }
                                            {
                                                item.state === 0 && <div onClick={() => toOrderDetail(item.orderNumber)}>立即支付</div>
                                            }
                                            {
                                                [2, 3].includes(item.state) && <>
                                                    <div onClick={() => toRead(item.orderNumber)}>查看回答</div>
                                                    {
                                                        item.state === 2 && <div onClick={() => submit(item)}>确认收货</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Spin>
                <Pagination
                    style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                    hideOnSinglePage={true}
                    current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                    onChange={changePage}
                    pageSize={pageSize}
                    total={totalSize}
                    showSizeChanger={false}
                    size="default"
                />
                <Modal getContainer={false} title='回答详情' footer={<></>} width={650} destroyOnClose={true} onCancel={() => setIsShowDiliver(false)} centered={true} open={isShowDiliver}>
                    <ReadDiliver orderNumber={orderNumber} />
                </Modal>
            </>
        }

        {
            list.length === 0 && <NoData />
        }
    </RewardOrderListStyle>
}




export default RewardOrderList