import styled from "styled-components";
import yellowGBright from "assets/images/big-g-yellow-bright.svg";
import silverGBright from "assets/images/big-g-silver-bright.svg";
import bronzeGBright from "assets/images/big-g-bronze-bright.svg";

export const WorkBillboardStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .billboard-container {
    padding: ${(props) => props.theme.space.paddingLG}px 0;
    max-width: ${(props) => props.theme.space.maxContainer}px;
    width: 93vw;
    display: flex;
    /* min-width: 1350px; */
    .main {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      min-height: calc(100vh - 100px);
      width: calc(100% - 280px);
      /* min-width: 800px; */
      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .list-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom:${(props) => props.theme.space.paddingLG}px;
        position: relative;
        min-width: 950px;
        &:nth-child(2) {
          .rank-num {
            h1{
              color: ${(props) => props.theme.colors.colorPrimary};
              font-size: 48px;
              transform: translateY(-16px);
            }
          }
          .upper-info{
            position: relative;
            background-image: url('https://cdn-prd.ggac.com/ggac/assets/image/ranking/gold.png'), url(${yellowGBright});
            background-repeat: no-repeat;
            background-position: right 0px top 0px, left -100px top -380px;
            background-color: #f4c869;
            background-size: 420px,300px;
            border: 1px solid #F9BB46 !important;
          }
           
          }
          &:nth-child(3) {
            .rank-num {
            h1{
              color: #d5e1f4;
              font-size: 48px;
              transform: translateY(-16px);
            }
          }
            .upper-info{
            position: relative;
            background-image:  url('https://cdn-prd.ggac.com/ggac/assets/image/ranking/silver.png'), url(${silverGBright});
            background-repeat: no-repeat;
            background-position: right 0px top 0px, left -100px top -380px;
            background-color: #eceff4;
            background-size: 420px,300px;
            border: 1px solid #D6E1F4 !important;

          }
          }
          &:nth-child(4) {
            .rank-num {
            h1{
              color: #f6cab0;
              font-size: 48px;
              transform: translateY(-16px);
            }
          }
            .upper-info{
            position: relative;
            background-image: url('https://cdn-prd.ggac.com/ggac/assets/image/ranking/bronze.png'),  url(${bronzeGBright});
            background-repeat: no-repeat;
            background-position: right 0px top 0px, left -100px top -380px;
            background-color: #ffe0cd;
            background-size: 420px,300px;
            border: 1px solid #f6cab0 !important;
          }
          }
        .rank-num {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          h1{
            font-size: 32px;
            font-weight: 800;
            color: ${(props) => props.theme.colors.colorBlack3Ggac};
            text-align: center;
          }
        }

        .right-part{
          margin-left:180px;
          width: calc(100% - 180px);
          min-width: 700px;
          .upper-info{
          width: 100%;
          overflow: hidden;
          position: relative;
          border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
          border: 1px solid
            ${(props) => props.theme.colors.colorBorder};
          padding: ${(props) => props.theme.space.paddingLG}px ;
          cursor: pointer;
          display: flex;
          
          &:hover {
            box-shadow: ${(props) => props.theme.shadow.boxShadowThin};
          }
          .image {
            width: 320px;
            aspect-ratio: 223/176;
            background-size: cover;
            background-position: center center;
            cursor: pointer;
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
          }

          .main-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-right: ${(props) => props.theme.space.padding}px;
            padding-left: ${(props) => props.theme.space.padding}px;
            color: ${(props) => props.theme.colors.colorTextSecondary};
            max-width: calc(100% - 260px);
            .left {
              padding: ${(props) => props.theme.space.padding}px;
              display: flex;
              flex-direction: column;
              align-self: space-around;
              justify-content: space-between;
              width: 80%;
              height: 100%;
              .upper {
                .title {
                  font-size: large;
                  font-weight: bold;
                  color: ${(props) => props.theme.colors.colorText};
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100%;
                }
                .meta-info {
                  margin: ${(props) => props.theme.space.marginXS}px 0;
                  display: flex;
                  align-items: center;
                  position: relative;
                  width: 100%;
                  .category {
                    margin-right: ${(props) => props.theme.space.margin}px;
                  }
                  .data {
                    display: flex;
                    align-items: center;
                    margin-right: ${(props) => props.theme.space.marginXXS}px;
                  }
                }
              }
              .down {
                display: flex;
                align-items: center;
                .username {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: calc(100 - "60px");
                  margin-right: ${(props) => props.theme.space.margin}px;
                }
                .time {
                  font-size: small;
                }
              }
            }
            .right {
              font-size: ${(props) => props.theme.fontSizes.fontSizeLG*2}px;
              font-weight: bold;
              color: ${(props) => props.theme.colors.colorText};
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding-bottom: ${(props) => props.theme.space.paddingLG+4}px;
              text-align: center;
              .show-extra{
                font-size: ${(props) => props.theme.fontSizes.fontSize}px;
                color: ${(props) => props.theme.colors.colorTextSecondary};
                font-weight: 400;
                display: flex;
                  align-items: center;
                .icon{
                    font-size: 18px;
                  }
                &:hover{
                  color: ${(props) => props.theme.colors.colorText};
                }
              }
            }
          }
     
          }
        }

      }
    }
    .side-bar {
      width: 280px;
      margin-right: ${(props) => props.theme.space.padding}px;
      border: 1px solid ${(props) => props.theme.colors.colorBorder};
      box-sizing: border-box;
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      padding: ${(props) => props.theme.space.paddingLG}px;
      .ant-menu-inline {
        border-inline-end: none !important;
      }
      .ant-menu-item-selected {
        background-color: ${(props) => props.theme.colors.colorPrimary};
        &:hover {
          background-color: ${(props) =>
            props.theme.colors.colorPrimary} !important;
        }

        .ant-menu-title-content {
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-item {
        color: ${(props) => props.theme.colors.colorTextLightCustom};
        &:hover {
          background-color: transparent;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-submenu-title {
        color: ${(props) => props.theme.colors.colorTextTertiary};
        &:hover {
          background: none !important;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
    }
  }
`;

