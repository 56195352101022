import { Button, Input, Modal,message } from "antd";
import {  apiAgreeOrRefuseRefund } from "api/shop";
import { useState } from "react";
import {  SellerOrderListResponse } from "types/product";
const { TextArea } = Input;
type Props = {
  visible: boolean;
  onSubmit: () => void;
  onClose: () => void;
  detail: SellerOrderListResponse
};

function RefuseRefundModal({ visible, onSubmit, onClose ,detail}: Props) {
  const [loading, setLoading] = useState(false);
  const [ value, setValue ] = useState('');

  const handleSubmit = () => {
    setLoading(true);
    apiAgreeOrRefuseRefund({
        orderNumber:detail.orderNumber,sellerRefuseReason:value,type:2
    }).then(() => {
      message.success("操作成功");
      onSubmit();
      onClose();
      setLoading(false);
    }).finally(() => {
        setLoading(false);
    })
  }

  return (
    <div>
      <Modal
        className="modal"
        title="填写拒绝退款理由"
        onCancel={onClose}
        centered={true}
        footer={null}
        getContainer={false}
        open={visible}
        destroyOnClose
      >
        <div className="container">
        <TextArea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="请填写拒绝退款理由"
      />
          <div className="btn" style={{marginTop: '24px'}}>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              提交
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RefuseRefundModal;
