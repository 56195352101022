import styled from 'styled-components'

export const SearchStyle = styled.div`
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
.header-search-wrap{
    display: flex;
    align-items: center; 
    position: absolute;
    margin-right: 14px;
    .input-wrap{
        height: 100%;
        border: 1px solid #fff;
        border-radius: 50px;
        width: 30vw;
        min-width: 300px;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2%;
        input {
            border-color: #fff;
            border-radius: 50px;
            border: none !important;
            background-color: transparent !important;
            color: #fff;
            border: none;
            width: 100%;
            outline: none !important;
            line-height: 42px;
            &::placeholder{
                color: #fff;
            }
            &:focus {
                border-color: transparent;
                outline: none;
            }
        }
        button:hover {
            background-color: transparent !important;
        }
        .ant-input-affix-wrapper {
            border-color: transparent;
            background-color: transparent;
            box-shadow: none;
            padding: 4px 0;
            line-height: 42px;

        }
        .ant-input-group-addon {
            background-color: transparent;
            padding-left: ${props => props.theme.space.paddingSM}px;
            button {
                padding-left: 5px;
                line-height: 46px;
                .ant-btn-icon {
                    .anticon {
                        font-size: 18px !important;
                        color: #fff;
                    }
                }
            }
        }
        .search{
            color: #fff;
            cursor: pointer;
            min-width: 30px;
        }
    }
    .ant-btn-default {
        background-color: transparent;
        border: none;
        border-left: 1px solid rgba(255,255,255,.7);
        height: 42px;
    }
    .ant-input-wrapper {
        /* display: flex;
        align-items: center; */
    }
    .search-icon{
        color: #fff;
        margin-left: ${(props) => props.theme.space.marginXS}px;
        cursor: pointer;
    }
}
`