import styled from "styled-components";

export const AlbumListStyle = styled.div`
    .top {
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 42px;
        }
        margin-bottom: ${props => props.theme.space.paddingLG}px;
    }
`