import React from 'react'
import { SectionTitleStyle } from './style'

type Props = {
    text: string
}
function SectionTitle({text}:Props) {
  return (
    <SectionTitleStyle>{text}</SectionTitleStyle>
  )
}

export default SectionTitle