import styled from "styled-components";

export const EventContributionStyle = styled.div`
    .wrap {
        padding: ${props => props.theme.space.paddingLG}px;
        width: 1430px;
        margin: 0 auto;
    }
    .top {
        height: 162px;
        position: relative;
        .banner-list {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            overflow: hidden;
            list-style: none;
            padding: 0;
            z-index: 1;
            /* display: flex;
            transition-property: transform;
            box-sizing: content-box; */
            .banner-item {
                text-align: center;
                font-size: 18px;
                position: relative;
                transform: scale(0.9);
                transition: transform 1s;
                background-size: 100% !important;
                cursor: pointer;
                width: 239.5px !important;
                height: 100%;
                overflow: hidden;
                margin-right: 0 !important;
                user-select: none;
                border-radius: ${props => props.theme.border.borderRadius}px;
                .swiperModel {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(255, 255, 255, 0.2);
                  display: block;
                  z-index: 99999;
                }
            }
            .active:hover .swiperModel {
                display: none;
            }
            .active {
              transform: scale(1);
            }
            .active .swiperModel {
              display: none;
            }
            .banner {
                background-size: cover;
                width: 100%;
                height: 100%;
                cursor: pointer;
                position: relative;
                transition: transform 1s;
                .info {
                    position: absolute;
                    width: 100%;
                    height: 100px;
                    background-color: rgba(0,0,0,.5);
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: 20px;
                    .number {
                        font-size: 23px;
                        text-align: center;
                    }
                    .date{
                        font-size: 16px;
                        margin:${props => props.theme.space.marginXXS}px 0;
                    }
                }
            }
            .active {
                transform: scale(1);
            }
        }
        .next,.prev {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: ${props => props.theme.colors.colorBlack3Ggac};
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;
        }
        .next {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         right:-45px;
        }
        
        .prev {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -45px;
        }
    }
    .contribution-info {
        display: flex;
        gap: 15px;
        margin-top: ${props => props.theme.space.marginLG}px;
        .card-image {
            width: 596px;
            height: 280px;
            background-size: cover;
            border-radius: ${props => props.theme.border.borderRadius}px;
        }
        .right {
            flex:1;
            padding-left: ${props => props.theme.space.paddingLG}px;
            .title {
                font-size: 22px;
                margin-bottom: ${props => props.theme.space.margin}px;
                font-weight: bold;
            }
            .content{
                font-size: 14px;
            }
            .btn {
                margin-top: ${props => props.theme.space.marginLG}px;
            }
            .date {
                margin-bottom: ${props => props.theme.space.margin}px;
            }
        }
    }
    .tab-wrap {
        padding: 0 ${props => props.theme.space.paddingLG}px;
        height: 50px;
        margin: ${props => props.theme.space.marginLG}px 0 ${props => props.theme.space.marginLG}px 0 ;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #F0F0F0;
            padding-bottom: 15px;
            width: 100%;
            &-item {
                cursor: pointer;
                font-size: 16px;
                position: relative;
                font-weight: bold;
                color: #8f8f8f;
                padding: 0 10px;
                margin: 0 ${props => props.theme.space.margin}px;
                &:hover {
                  color: ${(props) => props.theme.colors.colorText} !important;
                }
            }
        }
        .tab-item:hover,.active {
            color: ${(props) => props.theme.colors.colorText} !important;
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 40px;
                width: 100%;
                height: 2px;
                background-color: ${props => props.theme.colors.colorPrimary};
            }
        }
    }
    
`