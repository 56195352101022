import { Button, Checkbox, Form, Modal, Space, message } from "antd";
import { apiCreateForumReply, apiUpdateForumReply } from "api/forum";
import { useEffect, useState } from "react";
import { CosDirectoryType } from "types/enums/media-type";
import TinyEditor from "ui-lib/tinyMCE";
import { ReplyEditFooterStyle } from "./style";
type Props = {
  isModalOpen: boolean;
  handleOk: (topic?: any) => void;
  handleCancel: () => void;
  topicId?: number;
  replyId?: number;
  html?: string;
  isAnonymity_?:number
};

function ReplyCreateAndUdate({
  isModalOpen,
  handleOk,
  handleCancel,
  topicId,
  replyId,
  html,
  isAnonymity_
}: Props) {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isAnonymity, setIsAnonymity] = useState(isAnonymity_??0);
  const onFinish = () => {
    form.validateFields().then((values) => {
      const { content } = values as any;
      setConfirmLoading(true);
      if (replyId) {
        apiUpdateForumReply({ id: replyId, content: content.html,isAnonymity:Number(isAnonymity) }).then(
          (res) => {
            setIsAnonymity(0)
            message.success("编辑成功");
            form.resetFields();
            handleOk();
            setConfirmLoading(false);
          }
        );
      } else {
        topicId &&   apiCreateForumReply({ topicId: topicId, content: content.html,isAnonymity:Number(isAnonymity) }).then(
          (res) => {
            setIsAnonymity(0)
            message.success("发布成功");
            form.resetFields();
            handleOk(res.id);
            setConfirmLoading(false);
          }
        );
      }
    });
  };
  useEffect(() => {

    if (html) {
      form.setFieldsValue({
        content: {
          html: html,
        },
      });
    }
    setIsAnonymity(isAnonymity_??0)
    
  }, [html,isAnonymity_,isModalOpen]);

  const footer = () => {
    return (
      <ReplyEditFooterStyle className="ReplyEditFooterStyle">
        <div className="left"></div>
        <div className="right">
          <Space>
            <Checkbox
              checked={Boolean(isAnonymity)}
              onChange={(e) => {
                setIsAnonymity(e.target.checked ? 1 : 0);
              }}
            >
              匿名
            </Checkbox>
            <Button
              className="btn"
              onClick={() => {
                form.resetFields();
                setIsAnonymity(0)
                handleCancel();
              }}
            >
              取消
            </Button>
            <Button type="primary" className="btn" onClick={onFinish} loading={confirmLoading}>
              发布
            </Button>
          </Space>
        </div>
      </ReplyEditFooterStyle>
    );
  };
  return (
    <Modal
      title={replyId ? "编辑回复" : "回复话题"}
      open={isModalOpen}
      onOk={onFinish}
      onCancel={handleCancel}
      width={1036}
      footer={footer()}
      style={{marginTop: 140}}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        name="just-a-name"
        style={{ marginTop: 20 }}
        requiredMark={false}
      >
        <Form.Item
          name="content"
          rules={[{ required: true, message: "请输入回复内容" }]}
        >
          <TinyEditor
            cosImagePath={CosDirectoryType.forumImage}
            cosVideoPath={CosDirectoryType.forumVideo}
          ></TinyEditor>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ReplyCreateAndUdate;
