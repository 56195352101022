import { AnonymousUserStyle } from "./style";

function AnonymousUser({size=48}: {size?:number}) {
  return (
    <AnonymousUserStyle className="AnonymousUserStyle">
      <div className="avatar" style={{width:size,height:size,overflow:"hidden"}}>
        {/* <RiBearSmileLine ></RiBearSmileLine> */}
        <img src="https://cdn.ggac.com/media/images/user_avatar.jpg" alt=""  width={size?size:40} height={size?size:40}/>
      </div>
      <span>匿名</span>
    </AnonymousUserStyle>
  );
}

export default AnonymousUser;
