import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import UserWorks from "views/user-frontpage/works";
import UserFavorites from "views/user-frontpage/favorites";
import UserFavoritesDetail from "views/user-frontpage/favorites/favoritesDetail"
import UserArticle from "views/user-frontpage/article"
import UserFansList from "views/user-frontpage/fans";
import UserFolloweeList from "views/user-frontpage/followee";
import AlbumList from "views/user-frontpage/album-list";
import AlbumDetail from "views/user-frontpage/album-list/detail";
import HonorList from "views/user-creator-center/honor";
import UserFrontPageLayout from "views/user-frontpage/layout/index";
import UserContestWorks from "views/user-frontpage/works/contest-works";
import ProductShowCase from "views/user-frontpage/productShowcase";
import NftAlbum from "views/user-frontpage/nft-album";


export const UserFrontPageRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Layout></Layout>,
    children: [
      {
        path: "/",
        element: <UserFrontPageLayout></UserFrontPageLayout>,
        children: [
          {
            path: "user/:id",
            element: <UserWorks></UserWorks>,
          },
        ]
      }
    ]
  },
  {
    path: "/",
    element: <Layout></Layout>,
    children: [
      {
        path: "user/:id",
        element: <UserFrontPageLayout></UserFrontPageLayout>,
        children: [
          {
            path: "",
            element: <UserWorks></UserWorks>,
          },
          {
            path: "works",
            element: <UserWorks></UserWorks>,
          },
          {
            path: "contest-works",
            element: <UserContestWorks></UserContestWorks>,
          },
          {
            path: "favorites",
            element: <UserFavorites></UserFavorites>,
          },
          {
            path: "work-album",
            element: <AlbumList></AlbumList>,
          },
          {
            path: "work-album/detail/:albumId",
            element: <AlbumDetail></AlbumDetail>,
          },
          {
            path: "honor",
            element: <HonorList />
          },
          {
            path: "album",
            element: <NftAlbum />
          },
          {
            path: "articles",
            element: <UserArticle></UserArticle>,
          },
          {
            path: "shop",
            element: <ProductShowCase></ProductShowCase>,
          },
          {
            path: "favorites/detail/:favoritesId",
            element: <UserFavoritesDetail></UserFavoritesDetail>,
          }, {
            path: "fans",
            element: <UserFansList></UserFansList>,
          },
          {
            path: "followee",
            element: <UserFolloweeList></UserFolloweeList>,
          }
        ],
      },
    ],
  },
];
