import { Button, Input, Modal, Spin } from "antd"
import { AddAlbumStyle } from "./style"
import { useEffect, useState, useCallback, useMemo } from "react"
import { apiGetAlbumList, postAddToAlbum } from "api/album"
import { UserBaseInfo } from "types/user"
import { albumListItem } from "types/album"
import { NoData } from "components/no-data"
import {  RiAddFill, RiCloseLine } from "react-icons/ri"
import CreateAlbum from "../create-album"
import InfiniteScroll from "react-infinite-scroll-component"

const AddAlbum = ({ id, closeAlbum }: { id?: number, closeAlbum: () => void }) => {
    const [albumList, setAlbumList] = useState<albumListItem[]>([])
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
    const [isShowAlbumModel, setIsShowAlbumModel] = useState(false)
    const [value, setValue] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [totalSize, setTotalSize] = useState(0)

    const params = useMemo(() => {
        return {
            userId: userInfo.id,
            currentDataId: id,
            name: value,
            pageNumber,
            pageSize: 100
        }
    }, [pageNumber, value, userInfo.id, id])

    useEffect(() => {
        setPageNumber(1)
        setAlbumList([])
    }, [params.userId, params.name])

    const getList = useCallback(() => {
        apiGetAlbumList(params).then(res => {
            setAlbumList((result) => [...result, ...res.pageData])
            setTotalSize(res.totalSize)
        })
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const addToAlbum = (detail: albumListItem, index: number) => {
        const flag = detail.isPortfolioCurrentData === 0 ? 1 : 0
        postAddToAlbum({
            flag,
            portfolioId: detail.id,
            workId: Number(id)
        }).then(res => {
            albumList[index].isPortfolioCurrentData = flag
            setAlbumList([...albumList])
        })
    }

    const openModel = () => {
        setIsShowAlbumModel(true)
    }

    const close = () => {
        setIsShowAlbumModel(false)
    }

    const success = () => {
        setAlbumList([])
        setIsShowAlbumModel(false)
        getList()
    }

    const onkeydown = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(1)
            setValue(e.target.value)
        }
    }

    const loadMoreData = () => {
        setPageNumber(pageNumber + 1)
    }

    return <AddAlbumStyle>
        <div className="title">添加到作品集 <RiCloseLine className="icon" onClick={closeAlbum} /></div>
        <Input placeholder="请输入关键字后回车" onKeyDown={onkeydown}></Input>
        {
            albumList.length === 0 && <NoData />
        }
        <div className="list" id="scrollableDiv2">
            <InfiniteScroll
                dataLength={albumList.length}
                next={loadMoreData}
                hasMore={albumList.length < totalSize}
                // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                loader={<div style={{ display: 'flex', width: '100%', justifyContent: 'center', height: '60px', alignItems: 'center' }}>
                    <Spin />
                </div>}
                scrollableTarget="scrollableDiv2"
            >
                {
                    albumList && albumList.length > 0 && albumList.map((item, index) => {
                        return <div className="album-item" key={item.id}>
                            <div className="left">
                                <div className="cover" style={{ backgroundImage: `url(${item.coverList && item.coverList[0]})` }}></div>
                                <div className="name">{item.name}</div>
                            </div>

                            <Button type={item.isPortfolioCurrentData === 1 ? 'default' : 'primary'} onClick={() => addToAlbum(item, index)} style={{ width: 80 }}>
                                {
                                    item.isPortfolioCurrentData === 1 ? '已添加' : '添加 '
                                }
                            </Button>
                        </div>
                    })
                }
            </InfiniteScroll>
        </div>

        <div className="bottom">
            {/* <div className="btn" onClick={openModel}><RiAddCircleFill className="icon"></RiAddCircleFill>创建作品集</div> */}
            <Button onClick={openModel} className="button" type="primary">
                <RiAddFill size={18}></RiAddFill>创建作品集
            </Button>
        </div>
        <Modal footer={<></>} destroyOnClose={true} closable={false} centered={true} open={isShowAlbumModel}>
            <CreateAlbum close={close} success={success} />
        </Modal>
    </AddAlbumStyle >
}

export default AddAlbum