import styled from "styled-components";


export const MylikesStyle = styled.div`
    .no-data {
        margin-top: 200px;
    }
    .data-list {
        display: grid;
        gap: ${(props) => props.theme.space.padding}px;
    }

`