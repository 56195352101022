import { ReactNode } from "react";
import { calcStyle } from "./fn";
import { UiTagStyle } from "./style";

interface Props extends React.ComponentProps<"div"> { theme: string;clickable?:boolean, children: ReactNode,}

function UiTag({ theme,clickable=false, children,...rest }: Props) {
  const style = calcStyle(theme);
  return (
    <div {...rest}>
    <UiTagStyle bgColor={style.bgColor} textColor={style.textColor} border={style.border} clickable={clickable}>
      {children}
    </UiTagStyle>
    </div>
  );
}

export default UiTag;
