import styled from "styled-components";


export const  GuanggaoBannerStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props=>props.theme.space.padding}px;
    margin-top: ${props=>props.theme.space.padding}px;
    .banner_{
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: ${props=>props.theme.border.borderRadius}px;
        cursor: pointer;
    }
`