export const translateRefundStatus = (code: any) => {
  switch (code) {
    case 1:
      return "买家申请";
    case 2:
      return "退款中";
    case 3:
      return "退款成功";
    case 4:
      return "卖家拒绝";
    case 5:
      return "平台介入";
    case 6:
      return "平台拒绝";
    case 7:
      return "买家关闭";
    default:
      break;
  }
};


