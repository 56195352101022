import { Button, Menu, MenuProps, Popover } from "antd";
import { CreatorCenterStyle } from "./style";
import { RiArrowLeftLine, RiUpload2Line } from "react-icons/ri";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { mapCurrentPath } from "./fn";

type MenuItem = Required<MenuProps>["items"][number];

function CreatorCenterLayout() {
  let location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname)
  const navigate = useNavigate();
  const items: MenuItem[] = [
    {
      key: "content",
      icon: <></>,
      children: [
        {
          key: "/user-creator-center/work/list",
          icon: <></>,
          label: "作品管理",
        },
        { key: "/user-creator-center/work-album", icon: <></>, label: "作品集" },
        {
          key: "/user-creator-center/article/list",
          icon: <></>,
          label: "文章管理",
        },
      ],
      label: "内容管理",
    },
    { key: "/user-creator-center/certified-author", icon: <></>, label: "认证作者" },
    // { key: "/user-creator-center/signed-author", icon: <></>, label: "申请签约" },
    // { key: "/user-creator-center/statistic", icon: <></>, label: "数据看板" },
    // { key: "/user-creator-center/fans", icon: <></>, label: "粉丝管理" },
    // { key: "/user-creator-center/comments", icon: <></>, label: "评论管理" },
  ];

useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  const btnContent = () => {
    return (
      <div style={{ width: "200px" }}>
        <div className="btn-content">
          <Button type="primary" onClick={()=>window.open('/work/create','_blank')}>上传作品</Button>
        </div>
        <div className="btn-content">
          <Button type="primary"  onClick={()=>window.open('/article/create','_blank')}>上传文章</Button>
        </div>
      </div>
    );
  };

  return (
    <CreatorCenterStyle>
      <div className="main-container">
        <div className="sideBar">
          <div className="wrap">
            <div className="page-title">创作中心</div>
            <div className="contribute">
              <Popover
                placement="bottom"
                content={btnContent}
                getPopupContainer={(trigger) => trigger}
                trigger="click"
                className="content-wrap"
              >
                <Button block className="btn" type="text">
                  <RiUpload2Line></RiUpload2Line>{" "}
                  <span className="text">上传 </span>
                </Button>
              </Popover>
            </div>

            <Menu
              onClick={onClick}
              defaultOpenKeys={["content"]}
              mode="inline"
              items={items}
              className="menu"
              selectedKeys={[mapCurrentPath(currentPath)]}
            />
          </div>

          <div className='bottom' onClick={() => navigate("/user-center/home")}>
            <div className="icon">
              <RiArrowLeftLine></RiArrowLeftLine>
            </div>
            个人中心
          </div>
        </div>

        <div className="pannel">
          <Outlet></Outlet>
        </div>
      </div>
    </CreatorCenterStyle>
  );
}

export default CreatorCenterLayout;
