import { WorkListItem } from "types/work";
import { UserCenterAlbumStatusStyle } from "./style";


function UserCenterAlbumStatus({ item }: { item: WorkListItem }) {
    return (
        <UserCenterAlbumStatusStyle>
                 {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
            {
                !item.coverUrl && <div className="status">已失效</div>
            }
        
        </UserCenterAlbumStatusStyle>
    );
}

export default UserCenterAlbumStatus;
