import { useState } from "react";
import { WorkBillboardStyle } from "./style";
import { getRankingWorkList } from "api/billboard";
import { RankingPeriodListItem, RankingWorkItem } from "types/billboard";
import { Avatar } from "antd";
import { RiEyeLine, RiMessageLine, RiThumbUpLine } from "react-icons/ri";
import SideBar from "../sidebar";
import RankingHeader from "../header";
import ExtraInfo from "../extra-info-work";
import { prettifyNumber } from "utils/functions/common";
import { ImageObjectCloudResize } from "utils/functions/file";
import { NoData } from "components/no-data";

export const WorkBillboard = () => {
  const [workList, setWorkList] = useState<RankingWorkItem[]>([]);
  const [openedItemId, setOpenedItemId] = useState<number>(0);

  const fetchWorkRankingList = (rankingId: number) => {
    let params = {
      rankingId: rankingId,
      pageSize: 20,
    };
    getRankingWorkList(params).then((res) => {
      setWorkList(res);
    });
  };

  const onClickGoDetail = (item: RankingWorkItem) => {
    window.open(`/work/detail/${item.workId}`);
  };

  const handlePeriodChange = (item: RankingPeriodListItem | undefined) => {
    if(item){
      fetchWorkRankingList(item.id);
    }else{
      setWorkList([])
    }
   
  };

  // const hanldeShowExtraInfo =(e:any, item:RankingWorkItem)=>{
  //   e.stopPropagation()
  //   if(item.id === openedItemId){
  //     setOpenedItemId(0)
  //   }
  //   if(openedItemId === 0){
  //     setOpenedItemId(item.id)
  //   }

  //   if(openedItemId > 0 && item.id !== openedItemId){
  //     setOpenedItemId(item.id)
  //   }
  // }  

  return (
    <WorkBillboardStyle>
      <div className="billboard-container">
        <div className="div">
          <SideBar></SideBar>
        </div>
        <div className="main">
          <RankingHeader
            onCurrentPeriodChange={handlePeriodChange}
          ></RankingHeader>
          {
            workList.length === 0 && <div className="no-data"><NoData /></div>
          }
          {workList.length > 0 &&
            workList.map((item, index) => {
              return (
                <div className="list-item" key={item.id}>
                  <div className="rank-num">

                    <div className="rank-num">
                      {item.top === 1 && <img src='https://cdn-prd.ggac.com/ggac/assets/image/ranking/top1.png' width={90} alt=""></img>}
                      {item.top === 2 && <img src='https://cdn-prd.ggac.com/ggac/assets/image/ranking/top2.png' width={90} alt=""></img>}
                      {item.top === 3 && <img src='https://cdn-prd.ggac.com/ggac/assets/image/ranking/top3.png' width={90} alt=""></img>}
                      {item.top > 3 && <h1>{item.top}</h1>}

                    </div>
                  </div>
                  <div className="right-part">

                    <div
                      className="upper-info"
                      onClick={() => onClickGoDetail(item)}
                    >
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${ImageObjectCloudResize(item.coverUrl, 500)})` }}
                      />
                      <div className="main-info">
                        <div className="left">
                          <div className="upper">
                            <div className="title">{item.title}</div>
                            <div className="meta-info ">
                              <div className="category">{item.secondCategoryName}</div>
                              <div className="data">
                                <RiEyeLine
                                  size={16}
                                  style={{ margin: "0 2px" }}
                                ></RiEyeLine>
                                {item.viewCount}
                              </div>
                              <div className="data">
                                <RiMessageLine
                                  size={16}
                                  style={{ margin: "0 2px" }}
                                ></RiMessageLine>
                                {item.commentCount}
                              </div>
                              <div className="data">
                                <RiThumbUpLine
                                  size={16}
                                  style={{ margin: "0 2px" }}
                                ></RiThumbUpLine>
                                {item.likeCount}
                              </div>
                            </div>
                          </div>

                          <div className="down" onClick={(e) => {
                            e.stopPropagation();
                            window.open(`/user/${item.userId}/works`)
                          }}>
                            <Avatar
                              src={item?.userInfo?.avatarUrl}
                              style={{ marginRight: "6px" }}
                            ></Avatar>
                            <div className="username">
                              {item?.userInfo?.username}
                            </div>
                            {/* <div className="time">{item.createTime}</div> */}
                          </div>
                        </div>
                        <div className="right">
                          <div>{prettifyNumber(item.incHot)}</div>
                          {/* <div className="show-extra" onClick={(e)=>hanldeShowExtraInfo(e,item)}>
                        <span> 数据信息</span> 
                          {openedItemId ===item.id &&  <RiArrowUpSLine className="icon"></RiArrowUpSLine>}
                          {openedItemId !=item.id &&   <RiArrowDownSLine className="icon"></RiArrowDownSLine>}
                        </div> */}
                        </div>
                      </div>
                    </div>
                    {openedItemId === item.id && <ExtraInfo item={item}></ExtraInfo>}
                  </div>

                </div>
              );
            })}
        </div>
      </div>
    </WorkBillboardStyle>
  );
};
