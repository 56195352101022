import styled from "styled-components";

export const SpecificationsFormStyle = styled.div`
    .btns {
        display: flex;
        gap: ${props => props.theme.space.margin}px;
        margin-bottom: ${props => props.theme.space.margin}px;
    }
    .form-specification {
        border: 1px solid #f0f0f0;
        border-radius: ${props => props.theme.border.borderRadiusLG}px;
        padding: ${props => props.theme.space.padding}px;
        margin-bottom: ${props => props.theme.space.margin}px;
        background: rgb(249, 249, 249);
        width: 556px !important;
        .ant-input-number {
            width: 100% !important;
            input {
                padding: 7px 11px !important;
            }
        }
    }
    .attachmentList {
        margin: 0;
        margin-bottom: 20px;
    }
    .tip {
        color: #8A8A8B;
        font-size: 12px;
        margin-bottom: ${props => props.theme.space.margin}px;
    }
`

export const DiscountStyle = styled.div`

    .show-part{
        display: flex;
        align-items: center;
        gap: ${props => props.theme.space.margin}px;

    }
    .discounted{
        line-height: 38px;
        border-bottom: 1px solid #000;
        padding: 0 20px 0 10px;
        margin:  0 10px;
    }
`