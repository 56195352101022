import { useState } from "react";
import { WallectOrderStyle } from "./style"
import UserOrderList from "./order";
import WallectInfo from "./wallect";
import UISegmentedControl from "ui-lib/segmented-control";

const WalletOrder = () => {
    const [tabId, setTabId] = useState('wallect')
    const tabOption = [
        {
            label: "钱包",
            value: 'wallect',
        },
        {
            label: "订单",
            value: 'order',
        }
    ];
    const changeTab = (e: any) => {
        setTabId(e)
    }
    return <WallectOrderStyle>
        <div className="tabs">

        <UISegmentedControl options={tabOption} onValueChange={changeTab} value={tabId}></UISegmentedControl>
        </div>
    
        {
            tabId === 'wallect' && <WallectInfo />
        }
        {
            tabId === 'order' && <UserOrderList />
        }
    </WallectOrderStyle>
}

export default WalletOrder