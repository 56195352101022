import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import { AuthWriterHome } from "views/user-center/auth-writer";
import { AuthWriterCreate } from "views/user-center/auth-writer/update/index";


export const authWriterRoutes:RouteObject[] = [
    {
        path: '/',
        element: <Layout></Layout>,
        children:[
            {
                path: '/auth-writer',
                element: <AuthWriterHome></AuthWriterHome>
            },
            {
                path: '/auth-writer/create',
                element: <AuthWriterCreate></AuthWriterCreate>
            },
        ]
    }
]