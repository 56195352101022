import styled from "styled-components";
export const NavMenuStyle = styled.div`
  .container_ {
    background-color: ${(props) => props.theme.colors.colorBgLayout};
    display: flex;
    justify-content: center;
    /* background-color: ${(props) => props.theme.colors.colorPrimary}; */
    position: relative;
    .badge {
      .ant-scroll-number {
        width: 7px;
        height: 7px;
      }
      
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #f0f0f0;
      bottom: 0;
      left: 0;
    }
    .wrap {
      position: relative;
      /* border-bottom: 1px solid #f0f0f0; */
      margin: 0 ${(props) => props.theme.space.margin}px;
      width: 100%;
      .side-gg-banner{
        position: absolute;
        right: 0;
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        top: 50%;
        transform: translateY(-70%);
        cursor: pointer;
        img {
          width: 100px;
        }
        .banner-item{
          object-fit: contain;
        }
      }
    }
  }
`;

export const NavMenuFilterStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .menus {
    display: flex;
    margin: 0 auto;
    .menu-item {
      /* background-color: bisque; */
      span {
        font-size: ${(props) => props.theme.fontSizes.fontSizeHeading4_5}px;
        color: ${(props) => props.theme.colors.colorText};
      }
      font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
      color: #8F8F8F;
      padding: ${(props) => props.theme.space.paddingXS + 4}px 10px;
      margin: 0 ${(props) => props.theme.space.paddingMD}px;
      box-sizing: border-box;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      &:hover {
        color: ${(props) => props.theme.colors.colorText} !important;
      }
      cursor: pointer;
      &.active {
        border-bottom: 3px solid ${(props) => props.theme.colors.colorPrimary};
        color:${(props) => props.theme.colors.colorText};
      }
      &:hover {
        border-bottom: 3px solid ${(props) => props.theme.colors.colorPrimary};
      }
    }
  }
`;
