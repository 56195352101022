
import { RedeemUserInfoStyle } from "./style"
import { RiQuestionFill } from "react-icons/ri"
import { Button, Popover } from "antd"
import { useState } from "react"
import RedeemHistory from "../redeem-history"
import { getWalletDetailType } from "types/wallet"

const RedeemUserInfo = ({ walletDetail }: { walletDetail?: getWalletDetailType }) => {
    const [showPopup, setShowPopup] = useState(false)


    const getHistory = () => {
        setShowPopup(true)
    }
    const updatePopup = (value: boolean) => {
        setShowPopup(value)
    }



    return <RedeemUserInfoStyle>
        <div className="user-info">
            <div className="user">
              
                <div className="number"><span className="count">{walletDetail?.gpBalance}</span>G分</div>
                <div className="info">
                    <div className="desc">
                        <span>( 可用G分{walletDetail?.gpBalance})</span>
                        <Popover
                            placement='right'
                            content={<div><div>可用G分：当前可使用的G分，可用于兑换商品</div><div>锁定G分：上传作品所得G分锁定期24小时，锁定期结束后自动变为可用G分</div></div>}
                        >
                            <RiQuestionFill />
                        </Popover>
                    </div>
                   
                </div>
            </div>
            <Button className="history" type="primary" ghost={true} onClick={() => getHistory()}>兑换历史</Button>
        </div>
        {
            showPopup && <RedeemHistory isShow={showPopup} updatePopup={updatePopup} />
        }

    </RedeemUserInfoStyle>
}

export default RedeemUserInfo