import { useCallback, useEffect, useState } from "react";
import { AddressPopupStyle } from "./style";
import { Button, Cascader, Checkbox, Form, Input, Modal, message } from "antd";
import { chineseAddressItem } from "types/common";
import { apiGetChineseAddressList } from "api/common";
import {
  apiGetUserAddressDetail,
  apiPostCreateUserAddress,
  apiPostEditUserAddress,
} from "api/user/address";

type Props = {
  isShow: boolean;
  isCreate: boolean;
  updateList: () => void;
  addressId?: number;
  onCancal: () => void;
  onSave?: (id: number) => void;
};
const AddressPopup = ({
  isShow,
  addressId,
  isCreate,
  updateList,
  onCancal,
  onSave
}: Props) => {
  const [countryList, setCountryList] = useState<chineseAddressItem[]>([]);
  const [form] = Form.useForm<any>();
  const [provinceId, setProvinceId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [loading, setLoading] = useState(false);
  const getAddress = useCallback(async () => {
    const result = await apiGetChineseAddressList();
    setCountryList(result);
  }, [isShow]);

  const save = () => {
    form.validateFields().then(async (res) => {
      setLoading(true);
      let func =
        addressId && !isCreate
          ? apiPostEditUserAddress
          : apiPostCreateUserAddress;
      const form = Object.assign(res, {
        provinceId,
        cityId,
        districtId,
        isDefault: res.isDefault ? 1 : 0,
      });
      if (addressId && !isCreate) {
        form.id = addressId;
      }
      func(form)
        .then(() => {
          message.success(addressId ? "修改成功" : "创建成功");
          onCancal();
          onSave && onSave(addressId ? addressId : 0);
          updateList();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  // 初始化地址信息
  const initAddressDetail = useCallback(async () => {
    let result;
    if (addressId && !isCreate) {
      result = await apiGetUserAddressDetail(Number(addressId));
      setProvinceId(result?.provinceId || 0);
      setCityId(result?.cityId || 0);
      setDistrictId(result?.districtId || 0);
      form.setFieldsValue({
        receiver: result?.receiver || "",
        phone: result?.phone || "",
        country: addressId
          ? [
            result?.provinceId,
            result?.cityId,
            result?.districtId ? result?.districtId : "",
          ]
          : [],
        address: result?.address || "",
        postalCode: result?.postalCode,
        isDefault: result?.isDefault === 1 ? true : false,
      });
    } else {
      setProvinceId(0);
      setCityId(0);
      setDistrictId(0);
      form.setFieldsValue({
        receiver: "",
        phone: "",
        country: [],
        address: "",
        postalCode: "",
        isDefault: false,
      });
    }

    getAddress();
  }, [form, getAddress, addressId, isCreate]);
  const changeCountry = (selectedOptions: any[]) => {
    if (selectedOptions && selectedOptions.length > 0 && selectedOptions[0]) {
      setProvinceId(selectedOptions[0]);
    }
    if (selectedOptions && selectedOptions.length > 0 && selectedOptions[1]) {
      setCityId(selectedOptions[1]);
    }
    if (selectedOptions && selectedOptions.length > 0 && selectedOptions[2]) {
      setDistrictId(selectedOptions[2]);
    } else {
      setDistrictId(0)
    }
  };
  // 校验省市区
  const limitCountry = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback();
    } else if (
      (value && value.length > 2) ||
      (value && value.length === 2 && [264, 265, 266].includes(value[0])) ||
      (value &&
        value.length === 2 &&
        [379, 436, 439, 476, 478].includes(value[1]))
    ) {
      callback();
    } else {
      callback(new Error("请选择完整的地址"));
    }
  };
  useEffect(() => {
    initAddressDetail();
  }, [initAddressDetail, addressId]);

  return (
    <AddressPopupStyle>
      <Modal
        className="modal"
        destroyOnClose={true}
        centered={true}
        footer={null}
        onCancel={onCancal}
        getContainer={false}
        open={isShow}
        width={600}
      >
        <Form
          labelCol={{ span: 6 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
          form={form}
          className="form"
        >
          <Form.Item
            label="收件人"
            name="receiver"
            rules={[{ required: true, message: "请输入收件人名称" }]}
          >
            <Input maxLength={50} placeholder="请输入收件人名称" />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="phone"
            rules={[
              { required: true, message: "请输入手机号码" },
              {
                pattern:
                  /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                message: "请输入正确手机号",
              },
            ]}
          >
            <Input placeholder="请输入收件人手机号" maxLength={11} />
          </Form.Item>
          <Form.Item
            label="收货地址省市区"
            name="country"
            rules={[
              { required: true, message: "请选择省市区" },
              {
                validator: (rules, value, callback) =>
                  limitCountry(rules, value, callback),
              },
            ]}
          >
            <Cascader
              options={countryList}
              onChange={changeCountry}
              changeOnSelect
              fieldNames={{ label: "name", value: "id" }}
              placeholder="请选择收货地址省市区"
            />
          </Form.Item>
          <Form.Item
            label="详细地址"
            name="address"
            rules={[{ required: true, message: "请输入详细地址" }]}
          >
            <Input maxLength={80} placeholder="请输入详细地址" />
          </Form.Item>
          <Form.Item
            label="邮政编码"
            name="postalCode"
            rules={[
              {
                pattern: new RegExp(/^[1-9]\d*$/, "g"),
                message: "邮政编码错误",
              },
            ]}
          >
            <Input placeholder="请输入邮政编码" maxLength={6} />
          </Form.Item>
          <Form.Item
            label="设为默认地址"
            name="isDefault"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <div className="btn-wrap">
            <Button
              className="btn"
              loading={loading}
              type="primary"
              block
              onClick={() => save()}
            >
              保存
            </Button>
          </div>
        </Form>
      </Modal>
    </AddressPopupStyle>
  );
};

export default AddressPopup;
