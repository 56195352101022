import { Button, Modal, Pagination, Spin } from "antd"
import { AlbumListStyle } from "./style"
import { RiAddFill } from "react-icons/ri"
import { useState, useEffect, useCallback, useMemo } from "react"
import { albumListItem } from "types/album"
import { apiGetAlbumList } from "api/album"
import { NoData } from "components/no-data"
import AlbumCard from "components/album/album-card"
import CreateAlbum from "components/album/create-album"
import { UserBaseInfo } from "types/user"
import { dynamicColumns } from "utils/functions/common"
const AlbumList = () => {
    const [albumList, setAlbumList] = useState<albumListItem[]>([])
    const [isShowAlbumModel, setIsShowAlbumModel] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalSize, setTotalSize] = useState(1)
    const [albumDetail, setAlbumDetail] = useState<albumListItem>()
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
    const [loading, setLoading] = useState(false)

    const params = useMemo(() => {
        return {
            userId: userInfo.id,
            pageNumber,
            pageSize: dynamicColumns()*3
        }
    }, [pageNumber])

    const getList = useCallback(() => {
        setLoading(true)
        apiGetAlbumList(params).then(res => {
            setAlbumList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const openAlbumPopup = () => {
        setAlbumDetail(undefined)
        setIsShowAlbumModel(true)
    }

    const close = () => {
        setIsShowAlbumModel(false)
    }
    const success = () => {
        setPageNumber(1)
        getList()
    }

    const editAlbum = (detail: albumListItem) => {
        setIsShowAlbumModel(true)
        setAlbumDetail(detail)
    }

    const changePage = (e: number) => {
        setPageNumber(e);
    };
    

    return <AlbumListStyle>
        <div className="top">
            <div className="top-left">
                <Button onClick={() => openAlbumPopup()} type="primary"><RiAddFill size={18}></RiAddFill>创建作品集</Button>
            </div>
        </div>
        <Spin spinning={loading}>
            {
                albumList.length === 0 &&
                <div style={{ padding: '200px 0' }}>
                    <NoData />
                </div>
            }
            {
                albumList && albumList.length > 0 && <AlbumCard success={success} editAlbum={editAlbum} list={albumList} />
            }
        </Spin>

        <Pagination
            pageSize={params.pageSize}
            style={{ textAlign: "center", marginTop: "28px" }}
            hideOnSinglePage={true}
            showSizeChanger={false}
            onChange={changePage}
            total={totalSize}
            current={pageNumber}
        />

        <Modal footer={<></>} closable={false} destroyOnClose={true} centered={true} open={isShowAlbumModel}>
            <CreateAlbum close={close} detail={albumDetail} success={success} />
        </Modal>
    </AlbumListStyle>
}


export default AlbumList