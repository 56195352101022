import styled from "styled-components";


export const TagSelectStyle = styled.div`
    .container {
        .ant-select-selector{
            padding: 4px !important;
        }
        .tag-list-wrapper{
           
            .title{
                margin-top: ${props=> props.theme.space.marginXS}px;
                font-size: ${props=> props.theme.fontSizes.fontSizeSM}px;
                color: ${props=> props.theme.colors.colorTextTertiary};
                display: flex;
                justify-content: space-between;
                align-items: center;
                .alternative{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover{
                        color: ${props=> props.theme.colors.colorPrimaryText}
                    }
                    span{
                        margin-left:${props=> props.theme.space.marginXS}px;
                    }
                }
            }
            .list-wrapper{
                margin-top: ${props=> props.theme.space.marginXS}px;
                display: flex;
                flex-wrap: wrap;
                .tag{
                    
                    cursor: pointer;
                    margin: 0 ${props=> props.theme.space.marginXXS}px ${props=> props.theme.space.marginXXS}px 0;
                }
            }
        }
    }
`