import styled from "styled-components";

export const CzwjModalStyle = styled.div`
    .modal {
        .ant-modal-footer {
            text-align: center;
        }
        .ant-modal-body {
            .wrap {
                div:last-child {
                    margin: 5px 0;
                }
            }
            div {
                line-height: 28px;
            }
        }
    }
`