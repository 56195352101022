import { ChangeEvent, useEffect, useState } from "react";
import { PrivilegesStyle } from "./style";
import { Button, Checkbox, Col, Input, Row, Spin } from "antd";
import { NftPrivileges } from "types/nft-album";
import { getFileUrlByCosUpload } from "config/cos";
import { getCosPathByFileName } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { FileUpload } from "components/fileUpload";

type Props = {
  onChange?: (value: NftPrivileges[]) => void;
  value?: NftPrivileges[];

}
function Privileges({ onChange, value }: Props) {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState<NftPrivileges[]>(value ?? []);


  useEffect(() => {
    console.log("Loading", value);
    value && setList(value)
  }, [value])



  const placeHolders = [
    '可下载本款图鉴卡的对应高清图', '可下载本款图鉴卡的对应精美壁纸', '可下载本款图鉴卡的对应头像', '自定义', '自定义'
  ]

  const handleChange = (e: ChangeEvent<HTMLInputElement>, item: NftPrivileges, key_: string) => {

    let clone = [...list]
    const index = clone.findIndex((i) => i.code === item.code);
    if (key_ === 'name') {
      clone[index].name = e.target.value;
    }
    if (key_ === 'description') {
      clone[index].description = e.target.value;
    }

    setList(clone)
    onChange && onChange(clone)
  };



  type UpFile = { fileUrl: string; file: File | undefined }
  const handleFileChange = async (params: UpFile, item: NftPrivileges) => {
    // console.log(params,item)
    if (!params.file) {
      const index = list.findIndex(i => i.code === item.code)
      list[index].url = ""
      setList([...list])
      onChange && onChange(list)
      // onChange && onChange(list.filter(item => item.url !== undefined && item.url !== ''))
      return
    }
    setLoading(true)
    upload(params.file!).then((res) => {
      let clone = [...list]
      const index = clone.findIndex((i) => i.code === item.code);
      clone[index].url = res.url;
      clone[index].checked = 1
      setList([...clone])
      onChange && onChange(clone)
      setLoading(false)
    })
  }

  const upload = async (file: File) => {
    const fileUUid = getCosPathByFileName(CosDirectoryType.nftImage, file.name);
    //上传腾讯云
    const result = await getFileUrlByCosUpload(
      file,
      fileUUid,
    )
    return result
  }

  const constructUploadFile = (item: NftPrivileges | undefined) => {
    if (item?.url) {
      return {
        fileUrl: item.url,
        file: undefined
      }
    }
    return {
      fileUrl: undefined,
      file: undefined
    }
  }

  const addPrivileges = () => {
    list.push({
      code: `CUSTOM-${list.length - 2}`,
      name: '',
      description: '',
      url: "",
      checked: 0
    })
    setList([...list])
  }

  const changeCheckBox = (e: any, detail: NftPrivileges, index: number) => {
    const isChecked = e.target.checked
    list[index].checked = !!isChecked ? 1 : 0
    setList([...list])
  }

  return (
    <PrivilegesStyle>
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col className="gutter-row" span={6}>
            <span>权益名称</span>
          </Col>
          <Col className="gutter-row" span={12}>
            <span>权益描述</span>
          </Col>
          <Col className="gutter-row" span={6}>
            <span>添加附件</span>
          </Col>
        </Row>
        {list.map((item, index) => {
          return (
            <Row gutter={24} key={index} align={'middle'} style={{ marginTop: 20 }}>
              <Col className="gutter-row gutter-row1" span={6}>
                <Checkbox onChange={(e) => changeCheckBox(e, item, index)} checked={item.checked === 1} />
                {index > 2 && <div className="input-wrap">
                  <Input
                    placeholder={placeHolders[index]}
                    value={item.name}
                    onChange={(e) => handleChange(e, item, 'name')}
                    maxLength={200}
                  />
                  {
                    !item.name && item.checked === 1 && <div className="error">请输入权益名称</div>
                  }
                </div>
                }
                {index <= 2 && <span>{item.name}</span>}

              </Col>
              <Col className="gutter-row" span={12}>
                <span>
                  <Input
                    placeholder={placeHolders[index]}
                    value={item.description}
                    onChange={(e) => handleChange(e, item, 'description')}
                    maxLength={200}
                  />
                  {
                    !item.description && item.checked === 1 && <div className="error">请输入权益描述</div>
                  }
                </span>
              </Col>
              <Col className="gutter-row" span={6}>

                <FileUpload limit={30} onChange={(params: any) => handleFileChange(params, item)} value={constructUploadFile(item)}></FileUpload>
                {
                  !item.url && item.checked === 1 && <div className="error">请添加附件</div>
                }
              </Col>
            </Row>
          );
        })}
        {
          list.length < 5 && <Button onClick={addPrivileges}>增加权益</Button>
        }
      </Spin>
    </PrivilegesStyle>
  );
}

export default Privileges;
