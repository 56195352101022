import styled from "styled-components";

export  const  SingleSelectStyle = styled.div`
    .theme-select-container{
        display: flex;
        flex-wrap: wrap;
        .theme-item-wrapper{
            margin: ${props => props.theme.space.marginXS}px;
            padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
            background-color: ${props => props.theme.colors.colorBgContainer};
            border-radius: ${props => props.theme.border.borderRadius}px;
            border: 1px solid ${props => props.theme.colors.colorBorder};
            position: relative;
            cursor: pointer;
        }
        .active{
            background-color: ${props => props.theme.colors.colorPrimary};
        }
    }
`