import MaterialContent from "views/user-creator-center/components/meterial-content"
import { AuthWriterCreateStyle } from "./style"

export const SignedAuthor = () => {
    return <AuthWriterCreateStyle>
        <MaterialContent type={2} />
    </AuthWriterCreateStyle>
}

export default SignedAuthor

