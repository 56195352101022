import { Button, Form, FormInstance, Input,  message } from "antd";
import { useNavigate } from "react-router-dom";
import { RiQuestionLine, RiRefreshLine } from "react-icons/ri";
import { CompleteUserInfoStyle } from "./style";
import { useEffect, useRef } from "react";
import { apiCompleteUserInfo, apiGetRandomUserName, apiGetUserBaseInfo } from "api/user";
import { sleep } from "utils/functions/common";
import { PwdRegex } from "config/common";

function CompleteUserInfo() {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);

  let navigate = useNavigate();
  const redirect = new URLSearchParams(window.location.search).get(
    "redirect"
  );
  useEffect( ()=>{
    apiGetUserBaseInfo().then (userInfo=>{
      if(userInfo.hasPassword ===1){
        navigate('/user-center/home')
      }else{
        generateRandomName()
      }
      
    })
   
  },[navigate])

  const generateRandomName = async ()=>{
   const username = await apiGetRandomUserName()
   formRef.current?.setFieldValue('username', username)
  }

  const onFinish = async (values: any)=>{
    if(values.password !== values.passwordConfirmation){
      messageApi.error("两次密码输入不一致，请检查！")
      return
    }
   await apiCompleteUserInfo({password:values.password,username:values.username})
   message.success("欢迎加入GGAC！")
   sleep(500)
   if(redirect) {
    window.location.href = redirect
   }else {
    navigate('/user-center/home')
   }
   

  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const nextTime = () => {
    message.success("欢迎加入GGAC！")
    sleep(500)
    if(redirect) {
      window.location.href = redirect
     }else {
      navigate('/user-center/home')
     }
  }


  return (
    <div className="formContainer full">
    {contextHolder}
      <div className="left wide">
        <div className="groupTitle">
          <div className="title">还差一点点</div>
          <div className="subTitle">为了更好的体验，建议您补全信息</div>
        </div>
        <CompleteUserInfoStyle>
          <Form form={form} layout="vertical" ref={formRef} onFinish={onFinish} onFinishFailed={onFinishFailed} requiredMark={false}>
            <div className="parted">
            <Form.Item
              label="用户名"
              name='username'
              tooltip={{
                title: "支持中文，英文，数字，2-18个字符",
                icon: <RiQuestionLine />,
              }}
              className="leftItem"
              rules={[
                {
                  required: true,
                  message: "请填写用户名",
                },
                {
                  pattern:/^[\u4e00-\u9fa5a-zA-Z0-9\s_\\(\\)\\（\\）]{2,18}$/,
                  message:"用户名支持中文，英文，数字，下划线和中英文括号，2-18个字符"
                }
              ]}
            > 
            <Input placeholder="请输入用户名,支持中文，英文和数字" allowClear/>
            </Form.Item>
            <Button icon={<RiRefreshLine />} className="rightItem" onClick={()=>generateRandomName()}/>
            </div>
    
            <Form.Item
              label="密码"
              name="password"
              tooltip={{
                title: "密码6-18位英文+数字",
                icon: <RiQuestionLine />
              }}
              rules={[
                {
                  required: true,
                  message: "请填写密码",
                },
                {
                  pattern:PwdRegex,
                  message:"密码6-18位英文+数字"
                }
              ]}
            >
              <Input.Password placeholder="请输入密码"  autoComplete="off" allowClear/>
            </Form.Item>
            <Form.Item label="密码确认" name="passwordConfirmation"
            
            rules={[
              {
                required: true,
                message: "请再次输入一致的密码",
              }
            ]}
            >
              <Input.Password placeholder="请再次输入密码" allowClear autoComplete="off" />
            </Form.Item>
            <Form.Item>
              <div className="parted actions">
                <span className="text" onClick={() => nextTime()}>
                  下次再说
                </span>
                <Button type="primary" className="rightItem" htmlType="submit">
                  提交
                </Button>
              </div>
            </Form.Item>
          </Form>
        </CompleteUserInfoStyle>
      </div>
    </div>
  );
}

export default CompleteUserInfo;
