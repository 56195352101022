import { MenuProps, Menu } from 'antd';
import { SideBarStyle } from './style'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { store } from 'utils/store';
import useScrollPosition from 'hooks/useScrollPosition';

type MenuItem = Required<MenuProps>["items"][number];
const items: MenuItem[] = [
  {
    key: "1",
    icon: <></>,
    label: <span className='top-item'>作品榜</span>,
    children: [
      { key: "/billboard/work/week", icon: <></>, label: "周榜" },
      { key: "/billboard/work/month", icon: <></>, label: "月榜" },
    ],
  },
  {
    key: "2",
    icon: <></>,
    label: <span className='top-item'>作者榜</span>,
    children: [
      { key: "/billboard/user/week", icon: <></>, label: "周榜" },
      { key: "/billboard/user/month", icon: <></>, label: "月榜" },
    ],
  },
];


function SideBar() {
  let location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false)

  const [positionY] = useScrollPosition();
  const navMenuElem = document.getElementById("work-list");

  useEffect(() => {
    const divTop = navMenuElem?.getBoundingClientRect().top;
    if (divTop) {
      setIsFixed(divTop <= 170)
    }
  }, [positionY])

  useEffect(() => {
    console.log('headerType', store.getState().headerType)
  }, [store.getState().headerType])

  const onClick: MenuProps["onClick"] = (item) => {
    setCurrentPath(item.key);
    navigate(item.key);
  };

  return (
    // <Affix offsetTop={top}>
    <SideBarStyle>
      <div className="side-bar" style={{ position: isFixed ? 'fixed' : 'relative', top: isFixed ? '100px' : 0 }}>
        <Menu
          onClick={onClick}
          inlineCollapsed={false}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          items={items}
          expandIcon={<div></div>}
          openKeys={["1", "2"]}
          className="menu"
          selectedKeys={[currentPath]}
        />
      </div>
    </SideBarStyle>
    // </Affix>
  )
}

export default SideBar