import styled from "styled-components";

export const DeliverRewardStyle = styled.div`
    /* padding-right: 30px; */
    .content {
        padding: 0 15px 0 0;
        max-height: 500px;
        overflow: auto;
        position: relative;
        ::-webkit-scrollbar {
            width: 10px; /* 设置滚动条的宽度 */
            background-color: transparent; /* 滚动条的背景色 */
            left: 0;
        }
        ::-webkit-scrollbar-track {
          background: #e1e1e1; /* 轨道的背景色 */
          border-radius: 10px; /* 轨道的圆角 */
        }
        /* 自定义滚动条的滑块（thumb） */
        ::-webkit-scrollbar-thumb {
          background-color: #c1c1c1; /* 滑块的背景色 */
          border-radius: 10px; /* 滑块的圆角 */
        }

        /* 滑块在悬停或者点击时的样式 */
        ::-webkit-scrollbar-thumb:hover {
          background: #a8a8a8; /* 悬停时滑块的背景色 */
        }

        ::-webkit-scrollbar-thumb:active {
          background-color: #919191; /* 点击时滑块的背景色 */
        }
    }

    .btns {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }

    .drop-wrap {
        background-color: #fff !important;
        padding: 0px 14px !important;
    }
    .ant-input-number {
        width: 200px;
    }
`