import { eventCheckinListResponse } from "types/events"
import { ContributionWorkStyle } from "./style"
import { useEffect, useState } from "react";
import { getWorkSelectedList } from "api/work";
import { WorkListItem } from "types/work";
import { NoData } from "components/no-data";
import { Pagination, Spin } from "antd";
import { useLocation, useNavigate } from "react-router";
import { queryUrl } from "utils/functions/common";
import PublicWorkCard from "components/uni-card/public-cards/publicWorkCard";

const ContributionWork = ({ detail }: { detail: eventCheckinListResponse }) => {
    const [list, setList] = useState<WorkListItem[]>([])
    const [total, setTotal] = useState(0)
    let pageNumber = Number(new URLSearchParams(useLocation().search).get("pageNumber") || 1)
    let isCheckinSelected = Number(new URLSearchParams(useLocation().search).get("isCheckinSelected") || 0)
    const navigate = useNavigate()
    const pageSize = 20
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!detail) return
        setLoading(true)
        getWorkSelectedList({ submitTimeStart: detail.startTime, isCheckinSelected, submitTimeEnd: detail.endTime, pageNumber, pageSize }).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [detail, pageNumber, isCheckinSelected])

    const changePage = (e: number) => {
        const url = queryUrl({ pageNumber: e })
        navigate(`?${url}`)
    }

    const saveWorkIdsForNav = () => {
        list && localStorage.setItem(
            "workIdsForNav",
            JSON.stringify(
                list.map((item) => {
                    return item.id;
                })
            )
        );
    }


    return <ContributionWorkStyle onClick={()=>saveWorkIdsForNav()}>

        <Spin spinning={loading}>
            {
                list.length === 0 && <NoData />
            }
            <div className="list">
                {list?.map(item => {
                    return <div key={item.id}>
                        {/* <WorkCardPublic key={item.id} item={item}></WorkCardPublic> */}
                        <PublicWorkCard  key={item.id} item={item}></PublicWorkCard>
                    </div>
                })}
            </div>
        </Spin>
        <Pagination
            style={{ textAlign: "center", marginTop: "24px" }}
            hideOnSinglePage={true}
            current={pageNumber}
            showSizeChanger={false}
            onChange={changePage}
            pageSize={pageSize}
            total={total}
        />
    </ContributionWorkStyle>
}

export default ContributionWork