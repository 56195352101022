import styled from "styled-components";

export const PhotoContentDisplayStyle = styled.div`
.image-wrap {
  position: relative;
  color: #fff;
  /* padding: 0 40px; */
  width: fit-content;
  margin: 0 auto;
  &:hover {
    .icon-wrap {
      visibility:visible;
    }
  }
  .operation-wrapper{
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 10px;
    /* top: 10px; */
  }
  .icon-wrap {
    /* position: absolute; */
    margin-left: 4px;
    display: flex;
    visibility:hidden;
    align-items: center;
    gap: 2px;
    left: 20px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 8px;
    padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
    cursor: pointer;
  }
}

`


export const ToolBarControls = styled.div`
  margin-right: 5px;
  display: flex;
  gap: 18px;
  .control-icon{
    font-size:  ${(props) => props.theme.fontSizes.fontSizeLG * 1.2}px;
    cursor: pointer;
    color:#cccccc;
    &:hover{
      color:${(props) => props.theme.colors.colorTextLightSolid};
    }
  }
`
export const BottomBarControls = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  color:#fff;
  z-index: 999;
  .btn{
    padding:${(props) => props.theme.space.paddingXS}px ${(props) => props.theme.space.paddingSM}px;
    background-color: rgba(0,0,0,.4);
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    margin-bottom: 10px;
    cursor: pointer;
  }
`