import styled from "styled-components";

export const Ly3dWorkprizeStyle = styled.div`
    @font-face {
        font-family: 'SourceHanSerifCN';
        src: url('https://cdn-prd.ggac.com/ggac/font/SourceHanSerifCN-Regular.otf');
    }

    @font-face {
      font-family: "kuaikanshijieti";
      src: url("https://cdn.ggac.com/fonts/kuaikanshijieti.ttf");
    }
    .workprize {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ly3d/ly3d-rule-bg.png") center center no-repeat;
        background-size: cover;
        margin-top: -10px;
        padding: 100px 0;
        font-family: 'alibabaPuHuiTi';
        .title {
            margin-bottom: 60px;
            text-align: center;
        }
        .en-title {
          background: url("https://cdn-prd.ggac.com/ggac/submatch/ly3d/ly3d-en-title.png") center center no-repeat;
          background-size: 100% 100%; 
          width: 661px;
          height: 93px;
          margin: 0 auto 60px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 45px;
          color: #000000;
          font-family: 'kuaikanshijieti';
        }
        .tip {
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .work {
          display: flex;
          justify-content: center;
          gap: 50px;
          width: 1200px;
          margin: 0 auto 50px;
          flex-wrap: wrap;
          .work-item {
            border: solid 1px rgba(103,88,227,.5);
            border-radius: 8px;
            box-sizing: border-box;
          }
          .cover {
            width: 1200px;
	          height: 400px;
            background-size: 100%;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            cursor: pointer;
            background-size: cover;
          }
          video {
            width: 1200px;
	          height: 450px;
            object-fit: cover;
          }
          .info-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .link {
              color: #6758e3;
              padding-right: 26px;
              cursor: pointer;
              font-weight: bold;
            }
          }
          .info {
            height: 72px;
            display: flex;
            padding: 0 26px;
            align-items: center;
            gap: 20px;
            color: #6758e3;
            .user_avatar_image {
              width: 40px;
	            height: 40px;
              border-radius: 50%;
              background-size: cover;
              cursor: pointer;
              background-position: center center;
            }
            .user-name {
              font-size: 16px;
              cursor: pointer;
            }
          }
          .comment {
            padding: 0 30px;
            border-top: solid 1px #6758e3;
            &-item {
              border-bottom: solid 1px #6758e3;
              color: #6758e3;
              padding: 15px 0;
            }
            &-item:last-child {
              border: none;
            }
          }
        }

        .work-5{
          
            .work-item {
                width: 573px !important;
            }
            video {
              width: 571px !important;
              height: 340px !important; 
              object-fit: cover;
            }
            .cover {
                width: 571px !important;
                height: 340px !important; 
            }
        }
    }
    .part3 {
      img {
          width: 1001px;
      }
      text-align: center;
    }
`