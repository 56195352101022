
import { useNavigate } from "react-router-dom";
import { NotificationStyle } from "./style";
import { apiGetNotificationList } from "api/notification";
import { NotificationListItem } from "types/notification";
import { useEffect, useState } from "react";
import { RiInboxLine } from "react-icons/ri";
import { Spin } from "antd";
import { t } from "i18next";
import { renderTitle } from "./fn";
import { descRender } from "views/user-center/notification/fn";

function NotificationDropdown({ isOpen }: { isOpen: boolean }) {
  const navigate = useNavigate()
  const [noticeList, setNoticeList] = useState<NotificationListItem[]>([]);
  const [loading, setLoading] = useState(false)

  const getNoticeData = () => {
    setLoading(true)
    apiGetNotificationList({}).then((res) => {
      setNoticeList(res.pageData);
    }).finally(()=>{
      setLoading(false)
    });
  };

  useEffect(() => {
    getNoticeData()
  }, [])

  const onClickGoNoticeCenter = () => {
    navigate('/user-center/notification')
  }

  return (
    <NotificationStyle>
      <div className="inner-container">
      <div className="header_">
        <div className="left">{t('notification.messageBox')}</div>
      </div>
   
      {noticeList.length > 0 && noticeList.map((item, index) => (
        <div className='dropdown-item' key={index}  onClick={()=>navigate('/user-center/notification')} >
          <div className="title">{`[${renderTitle(item.type)}]`}</div>
          <div className="description">{descRender(item)}</div>
        </div>
      ))}
   
      {noticeList.length === 0 &&
      <Spin spinning={loading}>
        <div className="empty-message">
          <RiInboxLine></RiInboxLine>
        </div>
        </Spin>
      }
      <div className="footer_" onClick={onClickGoNoticeCenter} >
        <span>查看全部</span>
      </div>
      </div>
    </NotificationStyle>
  )
}

export default NotificationDropdown