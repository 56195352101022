import { Dropdown, Space, Spin } from "antd";
import { DropdownListStyle, RankingHeaderStyle } from "./style";
import { useEffect, useState } from "react";
import { RankingPeriodListItem } from "types/billboard";
import { getRankingPeriodList } from "api/billboard";
import { useLocation } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";

type Props = {
  onCurrentPeriodChange: (item: RankingPeriodListItem | undefined) => void;
};
function RankingHeader({ onCurrentPeriodChange }: Props) {
  const [periodList, setPeriodList] = useState<RankingPeriodListItem[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<RankingPeriodListItem>();
  const [typeObj, setTypeObj] = useState({ dataType: 0, timeType: 0 })
  const [loading, setLoading] = useState(false)

  let location = useLocation();

  useEffect(() => {
    getPeriodList();
  }, [location.pathname]);

  const determinteDataTypeAndTime = () => {
    let obj = { dataType: 0, timeType: 0 }
    if (location.pathname === "/billboard/work/week") {
      obj = { dataType: 1, timeType: 1 }
      setTypeObj({ ...obj })

    }
    if (location.pathname === "/billboard/work/month") {
      obj = { dataType: 1, timeType: 2 }

    }
    if (location.pathname === "/billboard/user/week") {
      obj = { dataType: 2, timeType: 1 }

    }
    if (location.pathname === "/billboard/user/month") {
      obj = { dataType: 2, timeType: 2 }

    }
    setTypeObj({ ...obj })
    return obj
  };

  const getPeriodList = () => {
    const obj = determinteDataTypeAndTime()
    let params = { dataType: obj.dataType, pageSize: 100, type: obj.timeType };
    setLoading(true)
    getRankingPeriodList(params).then((res) => {
      let listData = res.pageData;
      if (listData.length > 0) {
        setCurrentPeriod(listData[0]);
        onCurrentPeriodChange(listData[0]);
        setPeriodList([...listData]);
      }
      if (listData.length === 0) {
        setCurrentPeriod(undefined);
        onCurrentPeriodChange(undefined);
        setPeriodList([]);
      }
      setLoading(false)
    }).finally(() => { setLoading(false) });
  };

  const handleDropdownClick = (item: RankingPeriodListItem) => {
    setCurrentPeriod(item);
    onCurrentPeriodChange(item);
  };

  const listItem = (item: RankingPeriodListItem) => {
    return (
      <DropdownListStyle onClick={() => handleDropdownClick(item)}>
        <div className="title">第{item.no}期</div>
        <div className="timespan">
          {item.startTime.slice(0, 10).replace(/-/g, '.')} - {item.endTime.slice(0, 10).replace(/-/g, '.')}
        </div>
      </DropdownListStyle>
    );
  };

  const renderDropdownTarget = () => {
    if (currentPeriod) {
      return <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <span className="small-text">
          {currentPeriod.startTime.slice(0, 10).replace(/-/g, '.')} - {currentPeriod.endTime.slice(0, 10).replace(/-/g, '.')}
        </span>
        <RiArrowDownSLine className="icon"></RiArrowDownSLine>
      </div>
    }

    return <></>;
  };

  const translate = (key: string) => {
    if (key === 'dataType') {
      if (typeObj.dataType === 1) {
        return '作品'
      }
      if (typeObj.dataType === 2) {
        return '作者'
      }
    }
    if (key === 'timeType') {
      if (typeObj.timeType === 1) {
        return '周'
      }
      if (typeObj.timeType === 2) {
        return '月'
      }
    }
    return ''
  }
  return (
    <RankingHeaderStyle>
      <div className="header">
        <div className="left">
          <Space>
            <span className="big-text">{translate('dataType')} - {translate('timeType')}榜</span>
            {
              translate('timeType') === '周' && <span className="small-text">每周二11:00更新</span>
            }
            {/* <span className="small-text">规则介绍</span> */}
          </Space>
        </div>
        <div className="right">
          <Space>
            {loading && <Spin size="small" />}
            {currentPeriod && !loading && (
              <span className="big-text">第 {currentPeriod?.no} 期</span>
            )}
            {periodList && periodList?.length > 0 && !loading && (
              <Dropdown
                arrow={true}
                menu={{
                  items: periodList.map((item) => {
                    return { key: item.id, label: listItem(item) };
                  }),
                }}
                trigger={["click"]}
                getPopupContainer={(trigger) => trigger}
              >
                {renderDropdownTarget()}
              </Dropdown>
            )}
          </Space>
        </div>
      </div>
    </RankingHeaderStyle>
  );
}

export default RankingHeader;
