import styled from "styled-components";

export const SearchPopupStyle = styled.div`
    .search-popup{
        position: absolute;
        width: 100%;
        top: 50px;
        left: 0;
        background-color: #fff;
        box-shadow: ${(props) => props.theme.shadow.boxShadow};
        padding: ${(props) => props.theme.space.padding}px;
        z-index: 200;
        border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
        .popup-list {
            &-item {
                padding: ${(props) => props.theme.space.padding}px;
            }
        }

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        
        .sub {
            display: flex;
            margin: ${props => props.theme.space.margin}px 0;
            flex-wrap: wrap;
            gap: 5px;
            &-item {
                color: rgb(85, 85, 85);
                background-color: #fff;
                background-color: rgb(242, 242, 242);
                cursor: pointer;
                padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
                border-radius: 30px;
                &:hover {
                    background-color: ${props => props.theme.colors.colorPrimary};
                    color: #fff;
                }
            }
        }
    }
    
` 