import styled from "styled-components";

export const ProductShowcaseStyle = styled.div`
width: 100%;
.data-list{
    display: grid;
    gap:${(props) => props.theme.space.padding}px;
    padding-top: 20px;
    position: relative;
}
.categories{
  position: relative;
  /* .ant-tabs-nav{
    margin-bottom: 0;
    transform: translateY(-10px);
    &::before{
      border-bottom:none;
    }
  } */
}
.share {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  position: absolute;
  right: 4px;
  top: 10px;
  a {
    width: 100px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.theme.fontSizes.fontSize}px;
    justify-content: flex-end;
  }
}

`