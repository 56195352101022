export enum ThemeType {
    light =  'light',
    dark = 'dark'
}

export const ThemeTypeArr  = [
    {name: '日间模式',  value:ThemeType.light },
    {name: '夜间模式',  value:ThemeType.dark }
]



