import { Button, Pagination } from "antd"
import { useEffect, useState } from "react"
import { getRmbFlowList, getWalletDetail } from "api/wallet"
import { WalletRmbFlowListItem, getWalletDetailType } from "types/wallet"
import Withdrawal from "../withdrawal"
import { RiWalletLine } from "react-icons/ri"
import { WalletDetailStyle } from "./style"

const WallectDetail = () => {
    const [list, setList] = useState<WalletRmbFlowListItem[]>([])
    const [isShow, setIsShow] = useState(false)
    const [wallectDetail, setWallectDetail] = useState<getWalletDetailType>()
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 16;
    const [total, setTotal] = useState(0);

    const getList = () => {
        getRmbFlowList({ pageSize: pageSize, pageNumber: pageNumber }).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        })
    }

    const getDetail = () => {
        getWalletDetail().then(res => {
            setWallectDetail(res)
        })
    }

    useEffect(() => {
        getList()
        getDetail()
    }, [pageNumber])

    const toWithdrawal = () => {
        setIsShow(true)
    }

    const updateIsShow = (value: boolean) => {
        setIsShow(value)
    }

    const withdrawalSuccess = () => {
        getList()
        getDetail()
    }

    const changePage = (e: number) => {
        setPageNumber(e);
    };

    return <WalletDetailStyle>
        <div className="top">
            <div className="name">
                <RiWalletLine className="icon"></RiWalletLine>
                <span>钱包余额（CNY）：</span><span className="amount">{wallectDetail?.rmbBalance! / 100 || 0}</span></div>
            <Button type="primary" onClick={toWithdrawal}>提现</Button>
        </div>
        <div className="list">
            <div className="title">余额明细</div>

            {
                list.length > 0 && list.map(item => {
                    return <div className="list-item" key={item.id}>
                        <div className="desc">{item.remark}</div>
                        <div className="number">{!!item.amount ? item.amount / 100 : 0}</div>
                        <div className="status">
                            {
                                item.status === 1 ? '执行中' : item.status === 2 ? '成功' : item.status ? '失败' : ''
                            }
                        </div>
                        <div className="date">{item.createTime}</div>
                    </div>
                })
            }

            <Pagination
                style={{ textAlign: "center", marginTop: "28px" }}
                hideOnSinglePage={true}
                current={pageNumber}
                onChange={changePage}
                showSizeChanger={false}
                pageSize={pageSize}
                total={total}
            />

        </div>
        <Withdrawal isShow={isShow} detail={wallectDetail} withdrawalSuccess={withdrawalSuccess} update={updateIsShow} />
    </WalletDetailStyle>
}

export default WallectDetail
