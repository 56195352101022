import { ForumTopicPayIssuessListItem } from "api/forum/type"
import { RewardItemStyle } from "./style"
import { Button, Image, message } from "antd"
import { hasToken } from "utils/functions/common"
import { useNavigate } from "react-router-dom"
import { loginPathWithRedirect } from "utils/functions/user"
import { postCreateApplicant } from "api/forum"

const RewardItem = ({ detail, updateList }: { detail: ForumTopicPayIssuessListItem, updateList: (id: number) => void }) => {

    const navigate = useNavigate()

    const token = hasToken()

    const answering = () => {
        message.warning("问题回答中，不可抢答")
    }

    const applicant = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        if (detail.isApplied === 1) {
            message.warning("已抢答，待提问人选择。")
            return;
        }

        postCreateApplicant({ topicId: detail.id, type: 1 }).then(res => {
            updateList(detail.id)
            message.warning("已抢答，待提问人选择。")
        })
    }

    const toDetail = () => {
        navigate(`/forum/reward/detail/${detail.id}`)
    }

    return <RewardItemStyle>
        <div className="detail" onClick={toDetail}>
            <div className="title">
                <div className="title-word">
                    {detail.title}
                </div>
                <div className="price">
                    <div className="price-icon"></div>
                    赏金￥{detail.amount}
                </div>
            </div>
            <div className="desc">
                <div className="desc-word">
                    {
                        !!detail.contentWords && <>需求描述：{detail.contentWords}</>
                    }
                </div>
                {
                    detail.status === 2 && <Button onClick={(e) => { e.stopPropagation(); answering() }}>回答中</Button>
                }
                {
                    detail.status === 1 && <Button type={detail.isApplied === 0 ? 'primary' : 'default'} onClick={(e) => { e.stopPropagation(); applicant() }}>{detail.isApplied === 0 ? "抢答" : "已抢答"}</Button>
                }
                {
                    detail.status === 4 && <Button>已完成</Button>
                }

            </div>
            <div className="image">
                {
                    detail.imageList && detail.imageList.length > 0 && detail.imageList.map(item => {
                        return <Image src={item} key={item} />
                    })
                }
            </div>
        </div >
    </RewardItemStyle >
}

export default RewardItem