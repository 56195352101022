import styled from "styled-components";

export const CreatorCenterStyle = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.colorBgLayout};
  .main-container {
    display: flex;
    margin: 0 auto;
    min-height: calc(100vh - 50px);
    overflow: auto;
    
    .sideBar{
      min-width: 256px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: ${(props) => props.theme.colors.colorBgContainer};
      border-right:1px solid ${(props) => props.theme.colors.colorBorder};
      .wrap{
        width: 100%;
        .page-title{
          padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
          background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
          text-align: center;
          font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        }
        .contribute{
          padding: ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.padding}px;
          border-bottom: 1px solid ${(props) => props.theme.colors.colorBorder};
          .btn{
            display: flex;
            align-items: center;
            justify-content: center;
            .text{
              padding:0 ${(props) => props.theme.space.paddingXS}px;
            }
          }
          .content-wrap {
            width: 100%;
            background-color:  ${(props) => props.theme.colors.colorBorder};
          }
          .btn-content {
            width: 100%;
            margin: ${props => props.theme.space.marginXS}px 0;
            button {
              width: 100%;
            }
          }
        }
        .menu {
           width: 100%;
           padding: ${props => props.theme.space.paddingLG}px;
           padding-top: 0;
           border: none;
           .ant-menu-item-selected{
        background-color: ${(props) => props.theme.colors.colorPrimary};
        &:hover {
          background-color: ${(props) => props.theme.colors.colorPrimary} !important;
      }
       
        .ant-menu-title-content{
          color: ${(props) => props.theme.colors.colorText};
        }
 
      }
      .ant-menu-item{
        color: ${(props) => props.theme.colors.colorTextLightCustom};
        &:hover{
          background-color: transparent;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-submenu-title{
        color: ${(props) => props.theme.colors.colorTextTertiary};
        &:hover{
          background: none !important;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-sub{
          border-radius: ${(props) => props.theme.border.borderRadius}px;
          overflow: hidden;
        }
        }
      }

      .bottom0 {
        bottom: 0 !important;
      }
     
      .bottom{
        text-align: center;
        position: relative;
        border-top: 1px solid ${(props) => props.theme.colors.colorBorder};
        padding: ${(props) => props.theme.space.padding}px 0;
        cursor: pointer;
        position: fixed;
        width: 256px;
        bottom: 0;
        .icon{
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size:  ${(props) => props.theme.fontSizes.fontSizeXL}px;
          padding:0 ${(props) => props.theme.space.padding}px;
        }
      }
    }
 
    .pannel {
      margin-left: ${(props) => props.theme.space.marginLG}px;
      background-color: ${(props) => props.theme.colors.colorBgContainer};
      flex: 1;
      padding: ${(props) => props.theme.space.paddingLG}px;
    }
  }
`;
