import { get, post } from "api"
import { PaginationResponse } from "types/common/axios"
import { favoriteListRequest, favoriteListItem, postAddToFavoriteRequest, createFavoriteRequest, FavoriteDataListRequest, FavoriteDataListItem, FavoriteProductListRequest, FavoriteProductListItem } from "types/favorite"


// 获取作品收藏夹列表
export const apiFavoriteList = (params: favoriteListRequest) =>
    get<favoriteListRequest, PaginationResponse<favoriteListItem>>("/api/favorite/list", params)

// 添加/删除作品/文章到收藏夹

export const postAddToFavorite = (params: postAddToFavoriteRequest) =>
    post<postAddToFavoriteRequest, {}>(`/api/favorite/able_flag`, params)

// 创建收藏夹
export const postCreateFavorite = (params: createFavoriteRequest) =>
    post<createFavoriteRequest, favoriteListItem>(`/api/favorite/create`, params)

// 编辑收藏夹信息
export const postEditFavorite = (params: createFavoriteRequest) =>
    post<createFavoriteRequest, favoriteListItem>(`/api/favorite/update`, params)

// 删除收藏夹
export const postDelteFavorite = (id: number) =>
    post<{ id: number }, {}>(`/api//favorite/delete/${id}`)

// 获取收藏夹中的列表
export const getFavoriteDetail = (params: FavoriteDataListRequest) =>
    get<FavoriteDataListRequest, PaginationResponse<FavoriteDataListItem>>(`/api/favorite/data_list`, params)

// 收藏夹-商品列表
export const getFavoriteProductList = (params: FavoriteProductListRequest) =>
    get<FavoriteProductListRequest, PaginationResponse<FavoriteProductListItem>>(`/api/favorite/product_list`, params)
