import UISegmentedControl from "ui-lib/segmented-control"
import { UserAuthenticationStyle } from "./style"
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const UserAuthentication = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState("idCard")
    const tabOption = [
        {
            label: "填写身份信息",
            value: "idCard",
        },
        {
            label: "补充学生证信息",
            value: "student",
        },
    ];

    useEffect(() => {
        const pathname = window.location.pathname.split("/")
        const defaultValue = pathname[pathname.length - 1]
        setValue(defaultValue)
    }, [window.location.pathname])



    const changeTab = (e: any) => {
        navigate(`/user-center/authentication/${e}`)
    }

    return <UserAuthenticationStyle>
        <UISegmentedControl
            onValueChange={changeTab}
            options={tabOption}
            value={value}
        ></UISegmentedControl>
        <Outlet></Outlet>
    </UserAuthenticationStyle>
}

export default UserAuthentication