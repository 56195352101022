import { Modal } from "antd"
import { AgreementModalStyle } from "./style"

const AgreementModal = ({ isShow, close }: { isShow: boolean, close: () => void }) => {
    return <AgreementModalStyle>
        <Modal width={800} footer={<></>} onCancel={() => close()} destroyOnClose={true} closable={true} centered={true} open={isShow}>
            <div style={{ textAlign: "center", fontSize: "23px", padding: "0 0 15px", fontWeight: "bold" }}>G站开店协议</div>
            <div style={{ maxHeight: "600px", overflow: "auto",lineHeight:"25px" }}>
                <div>欢迎您使用我们的产品和服务！请在使用/继续使用我们的产品与/或服务前，仔细阅读并充分理解本政策，并在需要时，按照本政策的指引，作出适当的选择。如当您按照注册页面提示填写信息，阅读并同意本协议或其他方式确认即表示您已充分阅读、理解并接受本协议的全部内容，并与GGAC数字艺术平台（GGAC店铺）达成本协议。本协议内容包括协议正文及所有GGAC数字艺术平台已经发布的各类规则，所有规则与本协议正文具有同等法律效力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容主张本协议无效，或要求撤销本协议。如您不同意本协议将导致我们无法为您提供完整的服务，您也可以选择停止使用。</div>
                <div style={{fontWeight: "bold"}}>（一）出售流程	</div>
                <div>1、用户在提交开店申请并通过后，可开启店铺管理功能，成为店铺卖家。</div>
                <div>2、卖家自愿发布商品到GGAC店铺，自行完成商品的信息编辑与管理。</div>
                <div>3、用户发布的商品须经过GGAC审核才能被发布。若用户发布的商品违反《中华人民共和国著作权法》、《中华人民共和国民法典》等相关法律、法规之规定或GGAC的规定，GGAC有权立即将发布用户的发布的商品从GGAC上删除、并终止对发布用户进行服务。若因此给GGAC造成其他经济损失，卖家应全额承担此损失（包括但不限于各种赔偿费用和律师费）。</div>
                <div>4、若商品购买者发现下载后的商品与卖家描述的有严重出入并且不能正常使用，GGAC店铺在接到商品购买者售后投诉并确认事实成立后，将从卖家账户扣除该笔订单金额，并向购买者全额退还款项。</div>
                <div style={{fontWeight: "bold"}}>（二）收益结算</div>
                <div>1、店铺卖家同意销售商品所得款项统一支付至GGAC账户中， GGAC在实际收到用户购买商品的款项后，将按照约定及流程按比例将款项结算给店铺卖家。</div>
                <div>2、店铺卖家每次出售的商品可获得出售金额的85%-95%作为收益，GGAC平台将收取出售金额的5%-15%，作为服务费，包含网络带宽、IDC、客服运营成本、支付手续费、渠道推广费等。</div>
                <div>3、店铺卖家每个自然月可以提现一次，每次可提现的最低标准为人民币壹佰元整（¥100），最高提现标准为人民币伍万元整（¥50000）。</div>
                <div>4、提现用户应当如实向GGAC提供真实、有效的账户信息，否则可能无法正常提现。</div>
                <div style={{fontWeight: "bold"}}>（三）售后须知	</div>
                <div>若GGAC用户购买店铺卖家商品后，因交易未成功或交易违反相关规定，但交易款项已经先行划付至卖方账户时，卖方有义务将对应款项退回。</div>
                <div style={{fontWeight: "bold"}}>（四）违约责任</div>
                <div>1、商品卖家与商品买家在交易过程中出现任何一方违反法律或相关协议的情形，由双方自行解决，GGAC不承担任何因此造成的法律责任和损失。若致使GGAC遭受经济损失，违约方应全额承担此损失。GGAC有义务秉着客观公正的原则提供双方交易中产生的各种证据，以保障交易双方权利不受侵害。</div>
                <div>2、商品若触犯法律或侵犯他人或其他组织的利益，一切法律后果、经济赔偿由商品卖家承担，GGAC不承担任何由卖家上传的商品导致的直接、间接或者偶然产生的任何损害。如因发布用户或其上传的作品违反法律法规的规定或侵害了第三方的合法权利/权益（包括但不限于知识产权、名誉权、肖像权及其他合法权利/权益等），给GGAC或GGAC其他用户造成了损失或招致任何处罚的，GGAC有权要求发布用户承担全部责任并赔偿GGAC及GGAC的其他用户全部损失(包括但不限于所遭受的损失、第三方要求的赔偿、律师费用、为解决纠纷产生的其他费用等)。</div>
                <div>3、商品由商品购买者或下载者按照授权约定的范围使用，GGAC对商品买家的合法使用不作任何保证，不承担任何法律责任。	</div>
            </div>
        </Modal>
    </AgreementModalStyle>
}

export default AgreementModal