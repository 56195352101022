import { Button, message } from "antd"
import { SpecificationStyle } from "./style"
import SpecificationsForm from "./specifications-form"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { skuListItem } from "types/commodity"
import { useSearchParams } from "react-router-dom"
import { MallProductCategoryListItem } from "types/product"

const Specification = forwardRef(({ list, updateSkuList,categoryList, isRequired = false, isAuthorized = false }: { list: skuListItem[],categoryList:MallProductCategoryListItem[], isRequired: boolean, isAuthorized: boolean, updateSkuList: (list: skuListItem[]) => void }, ref) => {
    const [isShowForm, setIsShowForm] = useState(false)
    const [skuId, setSkuId] = useState(-1)
    let [searchParams] = useSearchParams();
    const draftId = searchParams.get("draftId")
    const type = new URLSearchParams(window.location.search).get("type") || ""
    const addSpecification = (detail: skuListItem) => {
        if (skuId !== -1) {
            list[skuId] = detail
            message.success("修改成功")
        } else {
            list.push(detail)
            message.success("添加成功")
        }
        setIsShowForm(false)
        setSkuId(-1)
        updateSkuList(list)
    }

    useEffect(() => {
        console.log(isAuthorized)
        if(!!type){
            updateSkuList([])
        }
    }, [isAuthorized])

    useImperativeHandle(ref, () => ({
        updateSkuList
    }));

    const deleteSpecification = (detail: skuListItem) => {
        list.splice(skuId, 1)
        message.success("删除成功")
        updateSkuList([...list])
        setSkuId(-1)
        updateSkuList(list)
    }

    useEffect(() => {
        if (draftId) {
            setIsShowForm(true); setSkuId(-1)
        }
    }, [draftId])

    return <SpecificationStyle>
        <div className="wrap">
            {
                list.map((item, index) => {
                    return <div key={item.name}>
                        {
                            (skuId === -1 || !(skuId === index)) && <div className="card" key={item.id}>
                                <div className="left">
                                    <div className="cover" style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                                    <div className="detail">
                                        <div className="name">{item.name}</div>
                                        <div className="price">￥{Number.isNaN(item.rmbPrice) ? item.originalPrice : item.rmbPrice}</div>
                                    </div>
                                </div>
                                <div className="right" onClick={() => { setSkuId(index); setIsShowForm(false) }}>编辑</div>
                            </div>
                        }

                        {
                            skuId !== -1 && skuId === index && <SpecificationsForm resetEdit={() => setSkuId(-1)} isRequired={isRequired} isAuthorized={isAuthorized} detail={item} deleteSpecification={deleteSpecification} onClose={() => setIsShowForm(false)} addSpecification={addSpecification} />
                        }
                    </div>

                })
            }
            {
                !isShowForm && <Button type="primary" onClick={() => { setIsShowForm(true); setSkuId(-1) }} >添加商品属性</Button>
            }
            {
                isShowForm && <SpecificationsForm isRequired={isRequired} isAuthorized={isAuthorized} onClose={() => setIsShowForm(false)} addSpecification={addSpecification} />
            }
        </div>
    </SpecificationStyle>
})

export default Specification