import styled from "styled-components"

export const AuthenticationIdCardStyle = styled.div`
.form {
    padding: 30px 0;
    .btn-wrap{
        text-align: center;
        padding-left: 240px;
    }
    .refuseReason {
        text-align: center;
        margin-bottom: 15px;
        color: red;
    }
}
`