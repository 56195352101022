import { DraftWorkStyle } from "./style";
import { useCallback, useEffect, useState } from "react";
import { ArticleListItem, ArticleListRequest } from "types/article";
import { Pagination, Modal, message, Spin } from "antd";
import { useMemo } from "react";
import { PaginationResponse } from "types/common/axios";
import { getDraftArticleList, postDeleteDraftArticle } from "api/article";
import { useNavigate } from "react-router";
import { NoData } from "components/no-data";
import Filter from "./filter";
import { filterValueType } from "../workType";
import UISegmentedControl from "ui-lib/segmented-control";
import DraftArticleCard from "components/uni-card/self-cards/draftArticleCard";
import useWindowSize from "hooks/useWindowSize";


function DraftWork() {
  const [articleList, setArticleList] = useState<ArticleListItem[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [articleData, setArticleData] = useState<PaginationResponse<ArticleListItem>>();
  const [filterValue, setFilterValue] = useState<filterValueType>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [screenSize] = useWindowSize();

  const draftParams: ArticleListRequest = useMemo(() => {
    const params: ArticleListRequest = {
      pageNumber,
      pageSize: 30,
    }
    if (filterValue?.status !== 4) {
      params.status = filterValue?.status
    }
    return params
  }, [pageNumber, filterValue?.status]);

  const getDraftList = useCallback(() => {
    setLoading(true)
    getDraftArticleList(draftParams).then((res) => {
      setArticleList(res.pageData);
      setArticleData(res);
    }).finally(() => {
      setLoading(false)
    })
  }, [draftParams]);

  useEffect(() => {
    getDraftList();
  }, [getDraftList]);

  const tabOption = [
    {
      label: "已通过",
      value: "work"
    },
    {
      label: "审核和草稿",
      value: "draft"
    },
    {
      label: "下架",
      value: "offShelf"
    },
  ];

  const changePage = (e: number) => {
    setPageNumber(e);
  };

  const changeWorkType = (e: any) => {
    if (e === "work") {
      navigate("/user-center/home/article/list");
    } else if (e === "offShelf") {
      navigate("/user-center/home/article/offShelf/list");
    }
  };

  const updateFilterValue = (value: filterValueType) => {
    setFilterValue(value);
  };

  const deleteWork = (id: number) => {
    Modal.confirm({
      title: "确定删除？",
      content: "删除该草稿文章后，将无法找回",
      okText: "确定",
      cancelText: "取消",
      onCancel() { },
      centered: true,
      onOk() {
        postDeleteDraftArticle(id).then(() => {
          const index = articleList.findIndex((item) => item.id === id);
          articleList.splice(index, 1);
          setArticleList([...articleList]);
          message.success("删除成功");
        });
      },
    });
  };

  return (
    <DraftWorkStyle>
      <div className="top-bar">
        <div className="left">
          <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={'draft'}></UISegmentedControl>
        </div>
        <div className="right">
          <Filter change={updateFilterValue} />
        </div>
      </div>

      <Spin spinning={loading}>
        {articleList.length === 0 && (
          <div
            style={{ width: "100%", marginTop: "200px", position: "relative" }}
          >
            <NoData></NoData>
          </div>
        )}
        <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
          {articleList.length > 0 && articleList.map((item) => {
            return <DraftArticleCard deleteWork={deleteWork} item={item} key={item.id} />
          })}
        </div>
      </Spin>
      {articleData &&  <Pagination
       pageSize={articleData?.pageSize}
        style={{ textAlign: "center" }}
        hideOnSinglePage={true}
        onChange={changePage}
        showSizeChanger={false}
        total={articleData?.totalSize}
        current={pageNumber}

      />}
     
    </DraftWorkStyle>
  );
}

export default DraftWork;
