import { TagsStyle } from "./style";
import SdTag from "ui-lib/tag/sd-tag";

function ProductTags(props: {
  tagList:  { //标签
    name: string //标签名称
    id: number //标签ID
}[]
}) {

  if(props.tagList?.length ===0)
  {return <></>}else{
    return (
      <div >
      <TagsStyle>
        {props.tagList && props.tagList.length > 0 && (
          <div className="inlineTags">
            <div className="title">商品标签：</div>
            <div className="tag-list">
       
              {props.tagList.map((item,index) => {
                return (
                  <SdTag key={index}>
                    {item.name}
                  </SdTag>
                );
              })}
            </div>
          </div>
        )}
      </TagsStyle>
      </div>
    );
  }
  
}

export default ProductTags;
