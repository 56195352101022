import { Button, Modal, Radio, RadioChangeEvent, message } from "antd"
import { RedeemCardStyle } from "./style"
import { useState } from "react"
import { apiGetUserAddressList } from "api/user/address"
import { UserAddressItem } from "types/user/address"
import { redeemItem } from "types/redeem"
import { postCreateOrder } from "api/redeem"


const RedeemCard = ({ list, updateWalletDetail }: { list: redeemItem[], updateWalletDetail?: () => void }) => {
    const [addressList, setAddressList] = useState<UserAddressItem[]>([])
    const [modalShow, setModalShow] = useState(false)
    const [value, setValue] = useState(0)
    const [productId, setProductId] = useState(0)
    const [loading, setLoading] = useState(false)

    const submit = (detail: redeemItem) => {
        apiGetUserAddressList().then(res => {

            if (res.length === 0) {
                Modal.confirm({
                    title: '提示',
                    content: '为了保障您的权益，请完善您的收货信息',
                    okText: "确定",
                    cancelText: "取消",
                    centered: true,
                    className: "modal",
                    onOk() {
                        window.open("/user-center/address")
                    },
                });
            } else {
                const result = res.find(item => {
                    return item.isDefault === 1
                })
                setValue(result?.id!)
                setProductId(detail.id)
                setAddressList(res)
                setModalShow(true)
            }
        })

    }

    const submitAddress = () => {
        if (!value) {
            message.warning("请选择地址")
            return
        }
        setLoading(true)
        postCreateOrder({ addressId: value, productId }).then(res => {
            setModalShow(false)
            message.success("兑换成功")
            updateWalletDetail && updateWalletDetail()
        })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    }

    return <RedeemCardStyle>
        <div className="list">
            {
                list.map(item => {
                    return <div className="list-item" key={item.id}>
                        <div className="cover" style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                        <div className="bottom">
                            <div className="name" title={item.name}>{item.name}</div>
                            <div className="count">{item.price} G分</div>
                            <Button loading={loading} type="primary" onClick={() => submit(item)} disabled={item.status !== 1}>兑换</Button>
                        </div>
                    </div>
                })
            }
        </div>
        {/* <Portal.Root asChild={modalShow}>Content</Portal.Root> */}
        <Modal
            title="请选择地址"
            centered
            open={modalShow}
            onOk={() => submitAddress()}
            onCancel={() => setModalShow(false)}
            getContainer={false}
            destroyOnClose={true}
        >
            <div className="address">
                <Radio.Group onChange={onChange} value={value}>
                    {
                        addressList.map(item => {
                            return <div className="address-item" key={item.id}>
                                <Radio value={item.id} className="radio">
                                    <div className="receiver">
                                        {item.receiver}
                                        <span style={{ marginLeft: "5px" }} className="phone">{item.phone}</span>
                                    </div>
                                    <div className="desc">
                                        {item.fullAddress}
                                        {
                                            !!item.isDefault && <div className="tag">默认地址</div>
                                        }
                                    </div>
                                </Radio>
                            </div>
                        })
                    }
                </Radio.Group>
            </div>
        </Modal>
    </RedeemCardStyle>
}

export default RedeemCard