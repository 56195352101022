import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import Login from "views/user-auth/login";
import Register from "views/user-auth/register";
import Security from "views/user-center/security";
import AuthLayout from "views/user-auth/layout";
import CompleteUserInfo from "views/user-auth/completeUserInfo";
import UserCenterLayout from "views/user-center/layout";
import WorkList from "views/user-creator-center/work";
import CreatorCenterLayout from "views/user-creator-center/layout";

export const userRoutes: RouteObject[] = [
  {
    path: "/user",
    element: <Layout></Layout>,
    children: [
      {
        path: "security",
        element: <Security></Security>,
      },
      {
        path: "home",
        element: <UserCenterLayout></UserCenterLayout>,
      },

    ],
  },
  {
    path: "/auth",
    element: <AuthLayout></AuthLayout>,
    children: [
      {
        path: "login",
        element: <Login></Login>,
      },
      {
        path: "register",
        element: <Register></Register>,
      },
      {
        path: "completeUserInfo",
        element: <CompleteUserInfo></CompleteUserInfo>,
      },
    ],
  },
  {
    path: "/creator",
    element: <CreatorCenterLayout></CreatorCenterLayout>,
    children: [
      {
        path: "work/list",
        element: <WorkList></WorkList>,
      },
    ],
  },
];
