import styled from "styled-components";

export const AnonymousUserStyle = styled.div`
display: flex;
align-items: center;
gap:12px;
    .avatar{
            font-size: 24px;
            height: 48px;
            width: 48px;
            background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${(props) => props.theme.colors.colorBlack5Ggac};
     }

`