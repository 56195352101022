import styled from "styled-components";

export const UserCenterFavoriteCardStyle = styled.div`
    .center {
        display: none;
    }
`
export const FavoriteCardActionStyle = styled.div`
      display: flex;
  justify-content: center;
  .action-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.2rem;
  }
`

export const FavoriteCardStatusStyle = styled.div`
    display: flex;
    align-items: center;
    gap:  ${(props) => props.theme.space.paddingXXS}px;
    margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
    margin-left: ${(props) => props.theme.space.paddingXXS}px;
    .status {
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: #595959;
      color: ${(props) => props.theme.colors.colorTextLightSolid};
      padding: ${(props) => props.theme.space.paddingXXS}px
        ${(props) => props.theme.space.paddingXS}px;
       border-radius: 4px;
      font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
      .question {
        font-size: 16px;
        color: red;
        border-radius: 50%;
      }
    }
    .subtitle {
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4px 12px;
    color: rgb(255, 255, 255);
    border-radius: 6px;
    white-space: nowrap;
   }
`