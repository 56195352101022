import styled from "styled-components"
export const RegulationWorkSwiperStyle = styled.div`
    width: 1361px;
    margin: 50px auto 0;
    overflow-x: auto;
    position: relative;
    ::-webkit-scrollbar {
        display: none; 
    }
    img {
        width: 1072px;
	    height: 443px;;
        object-fit: cover;
        cursor: pointer;
    }
    .name {
        margin-top: 20px;
    }
    .swiper-button-next,.swiper-button-prev{
        display: block !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 47px;
        height: 47px;
        z-index: 100;
        &::after{
            display: none;
        }
    }
    .swiper-button-prev::before {
        content: "";
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-2024-mobile-price-work-left.png") center center no-repeat;
        background-size: 100% 100%; 
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .swiper-button-next::before {
        content: "";
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-2024-mobile-price-work-right.png") center center no-repeat;
        background-size: 100% 100%; 
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    .swiper-wrapper{
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        height: 86%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 300ms;
        transform: scale(0.8);
        flex-direction: column;
    }

    .name {
        font-size: 16px;
    }
            
    .swiper-slide-active,
    .swiper-slide-duplicate-active {
        transform: scale(1);
    }
    .swiper-button-next,
    .swiper-button-prev{
        display: none;
    }
    .swiper-slide-active{
        z-index: 999 !important;
    }
    .swiper-slide-prev .s-right{
        display: none;
    }
    .swiper-slide-prev .s-left{
        margin-left: 35%;
    }
`