export const countryCodes = [
  {
    lable: "中国",
    code: "86",
  },
  {
    lable: "中国香港",
    code: "852",
  },
  {
    lable: "中国澳门",
    code: "853",
  },
  {
    lable: "中国台湾",
    code: "886",
  },
  {
    lable: "美国/加拿大",
    code: "1",
  },
  {
    lable: "日本",
    code: "81",
  },
  {
    lable: "韩国",
    code: "82",
  },
  {
    lable: "新加坡",
    code: "65",
  },
  {
    lable: "马来西亚",
    code: "60",
  },
  {
    lable: "越南",
    code: "84",
  },
  {
    lable: "澳大利亚",
    code: "61",
  },
  {
    lable: "英国",
    code: "44",
  },
  {
    lable: "法国",
    code: "33",
  },
  {
    lable: "俄罗斯",
    code: "7",
  },
  {
    lable: "德国",
    code: "49",
  },
  {
    lable: "泰国",
    code: "66",
  },
  {
    lable: "印度",
    code: "91",
  },
  {
    lable: "菲律宾",
    code: "63",
  },
  {
    lable: "巴西",
    code: "55",
  },
  {
    lable: "印度尼西亚",
    code: "62",
  },
  {
    lable: "意大利",
    code: "39",
  },
  {
    lable: "土耳其",
    code: "90",
  },
  {
    lable: "新西兰",
    code: "64",
  },
  {
    lable: "埃及",
    code: "20",
  },
];
