import styled from "styled-components"

export const FolderStyle = styled.div`
   
    .top {
        display: flex;
        margin-bottom: ${props => props.theme.space.paddingLG}px;
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 42px;
        }
    }
`