import styled from "styled-components";

export const  NoDataStyle = styled.div`
    .no-data-container{
        position: relative;
       width: 100%;
       height: 100%;
       .img {
        display: flex;
        justify-content: center;
       }
       .tips{
        display: flex;
        justify-content: center;
        color: ${props=>props.theme.colors.colorTextTertiary};
        margin-top: ${props=>props.theme.space.margin*1.2}px;
       }
        
    }
`