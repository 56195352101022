import { theme } from "antd";
const { useToken } = theme;

export const UseLightColors = () => {
  const { token } = useToken();
  return {
    colorPrimary: token.colorPrimary,
    colorPrimaryText: token.colorPrimaryText,
    colorText: token.colorText, //rgba(0, 0, 0, 0.88)
    colorBlack6Ggac: "#000000",
    colorBlack5Ggac: "#363636",
    colorBlack4Ggac: "#6c6c6c",
    colorBlack3Ggac: "#a2a2a2",
    colorBlack2Ggac: "#d8d8d8",
    colorBlack1Ggac: "#f3f3f3",
    colorTextLightSolid: token.colorTextLightSolid, //#ffffff
    colorTextSecondary: token.colorTextSecondary, //rgba(0, 0, 0, 0.65)
    colorTextTertiary: token.colorTextTertiary, //rgba(0, 0, 0, 0.45)
    colorTextLightCustom: 'rgba(0, 0, 0, 0.55)', //rgba(0, 0, 0, 0.55)
    colorError: token.colorError, //#ff4d4f
    colorBgContainer: token.colorBgContainer, 
    colorBorder: "#e4e4e4", //#e4e4e4
    colorBorderSecondary: token.colorBorderSecondary, //#f0f0f0
    colorBgLayout: "#fbfbfd", //#f5f5f5
    colorPrimaryActive: token.colorPrimaryActive, //#cf851d
    colorPrimaryBg: token.colorPrimaryBg, //#fffcf0
    colorPrimaryBorder: token.colorPrimaryBorder, //#f0f0f0
    colorFillQuaternary: token.colorFillQuaternary, //#f5f5f5
    colorHomeBgContainer:"#f1f1f3",
    colorLinkDefault:"#8A8A8B",
    colorLinkHover:"#FF9A16"
  };
};
