import { useCallback, useEffect, useMemo, useState } from "react"
import { HistoryListStyle } from "./style"
import { apiGetHistory } from "api/work"
import { FavoriteDataListItem } from "types/favorite"
import { Pagination, Spin } from "antd"
import { NoData } from "components/no-data"
import PublicCard from "components/uni-card/public-cards/publicESCard";
import useWindowSize from "hooks/useWindowSize"

const HistoryList = () => {
    const [historyList, setHistoryList] = useState<FavoriteDataListItem[]>([])
    const [pageNumber, setPageNumber] = useState(1)
    const [totalSize, setTotalSize] = useState(0)
    const [loading, setLoading] = useState(false)
    const [screenSize] = useWindowSize()
    const params = useMemo(() => {
        return {
            pageSize: 30,
            pageNumber
        }
    }, [pageNumber])

    const getList = useCallback(() => {
        setLoading(true)
        apiGetHistory(params).then(res => {
            setHistoryList([])
            setHistoryList([...res.pageData])
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const changePage = (e: number) => {
        setPageNumber(e);
    };
    return <HistoryListStyle>
        <Spin spinning={loading}>
            <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
                {
                    historyList.map((item, index) => {
                        return <PublicCard key={index} item={item}></PublicCard>
                    })
                }
            </div>
        </Spin>
        <Pagination
            pageSize={params.pageSize}
            style={{ textAlign: "center",paddingTop:24}}
            hideOnSinglePage={true}
            onChange={changePage}
            total={totalSize}
            showSizeChanger={false}
            current={pageNumber}
        />
        {
            historyList.length === 0 && <div className="no-data">
                <NoData />
            </div>
        }
    </HistoryListStyle>
}

export default HistoryList