import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const ScoreListStyle = styled.div`
padding-top:  ${props=>props.theme.space.paddingLG*2}px;
    .header{
        text-align: center;
        font-size:  ${props=>props.theme.fontSizes.fontSizeLG*1.5}px;
    }
    .task-info{
        width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: ${props=>props.theme.space.paddingSM}px;
        padding: ${props=>props.theme.space.paddingLG*2}px;
        .info-item{
            .label{
                color: ${props=>props.theme.colors.colorTextTertiary};
                padding-bottom: 4px;
            }
        }
    }
    .work-list{
        .list{
            display: grid;
        grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
        gap: 1rem;
        padding: ${props=>props.theme.space.marginLG}px;
        }

    }
    .submit{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .filter{
        display: flex;
        justify-content: space-between;
        padding: 0 ${props=>props.theme.space.marginLG}px;
        .type{
            display: flex;
                gap: ${props => props.theme.space.margin}px;
                &-item {
                    cursor: pointer;
                    display: flex;
                    gap: ${props => props.theme.space.margin}px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.active {
                        span { 
                            color: ${props => props.theme.colors.colorPrimary};
                        }
                    }
                    .second-type {
                        display: flex;
                        gap: ${props => props.theme.space.margin}px;
                    }
                    .second-active {
                        color: ${props => props.theme.colors.colorPrimary};
                    }
                }
        }
        .right{

        }
    }
`