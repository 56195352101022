import { RiArrowLeftSLine } from 'react-icons/ri'
import { GoBackTitleStyle } from './style'
import { ReactNode } from 'react';


interface Props extends React.ComponentProps<"div"> {  children?: ReactNode,}

function GoBackTitle({children, ...rest}:Props) {
  return (
    <div  {...rest}>
    <GoBackTitleStyle >
        <RiArrowLeftSLine className='icon'></RiArrowLeftSLine> <span> {children}</span>
    </GoBackTitleStyle>
    </div>
  )
}

export default GoBackTitle