import { get, post } from "api"
import { PaginationResponse } from 'types/common/axios'
import { CategoryMallMineListItem, CategoryMallMineListRequest, CfgMallShopApplyRemarkResponse, CfgProductTypeResponse, ExpressInfoResponse, MallAdminProductListRequest, MallAdminProductListResponse, MallProductCategoryListItem, MallProductCategoryListRequest, MallProductListRequest, MallProductOtherRecommendListResponse, MallProductRecommendListItem, MallProductRecommendListRequest, MallReviewCreateRequest, MallReviewListRequest, MallReviewListResponse, MallShopDetailResponse, MallShopShareImage, MallWorktypeListItem, MoveProductParams, OrderCreateMallRequest, OrderCreateMallResponse, OrderListRequest, OrderListResponse, OrderPreCreateMallRequest, OrderPreCreateMallResponse, OrderUpdateMallRequest, PublicProductDetail, PublicProductListItem, RefundApplyAdminRequest, RefundCreateRequest, SellerMallProductRedDotResponse, SellerOrderDeliveryRequest, SellerOrderListRequest, SellerOrderListResponse, SellerRefundConfirmRequest, ShippingFeeTemplateCreateRequest, ShippingFeeTemplateDetail, ShippingFeeTemplateListItem, ShippingFeeTemplateUpdateRequest, orderDetailResponse } from "types/product";


// 获取商品列表
export const apiGetPublicProductList = async (params: MallProductListRequest) =>
    await get<MallProductListRequest, PaginationResponse<PublicProductListItem>>("/api/mall_product/list", params);

// 获取商品列表
export const apiGetPublicRecommandProductList = async (params: MallProductRecommendListRequest) =>
    await get<MallProductRecommendListRequest, MallProductRecommendListItem[]>(`/api/mall_product/recommend_list/${params.count}`);

// 修改商品商城列表
export const apiGetProductType = async (code?: string) =>
    await get<{ code: string }, CfgProductTypeResponse>(`/api/cfg/product_type/${code}`);

// 获取卖家商品列表
export const apiGetProductList = async (params: MallAdminProductListRequest) =>
    get<MallAdminProductListRequest, PaginationResponse<MallAdminProductListResponse>>(`/api/seller_mall_product/list`, params);

// 获取卖家订单列表
export const apiGetSellerOrderList = async (params: SellerOrderListRequest) =>
    get<SellerOrderListRequest, PaginationResponse<SellerOrderListResponse>>(`/api/seller_order/list`, params);

// 修改订单
export const updateOrderAmount = (params: { amount: number, orderNumber: string, serviceChargePayer?: number }) =>
    post<{ amount: number, orderNumber: string, serviceChargePayer?: number }, {}>("/api/seller_order/update_amount", params)

// 获取卖家订单列表
export const apiGetOrderList = async (params: OrderListRequest) =>
    get<OrderListRequest, PaginationResponse<OrderListResponse>>(`/api/order/list`, params);

// 商品-商品审核通过的红点
export const apiPostProductRedDot = async (params?: { status?: string, onSale?: number, classify?: number }) =>
    post<{ status?: string, onSale?: number, classify?: number }, SellerMallProductRedDotResponse>(`/api/seller_mall_product/red_dot`, params);

// 商品-商品上下架
export const apiPostProductSale = async (params: { id: number, onSale: number }) =>
    post<{ id: number, onSale: number }, {}>(`/api/seller_mall_product/on_sale`, params);

// 商品移动
export const apiPosMoverProduct = async (params: MoveProductParams) =>
    await post<MoveProductParams, {}>("/api/system/data_move", params);

// 商品-商品删除
export const apiPostDeleteProduct = async (id: number) =>
    post<{ id: number }, {}>(`/api/seller_mall_product/delete/${id}`);

// 对外展示商品详情列表
export const apiGetPublicProductDetail = (id: number) =>
    get<{ id: number }, PublicProductDetail>(`/api/mall_product/detail/${id}`);

// 商品创建预创建订单
export const postPreCreateMall = (params: OrderPreCreateMallRequest) =>
    post<OrderPreCreateMallRequest, OrderPreCreateMallResponse>(`/api/order/pre_create_mall`, params);

// 商品创建订单
export const postCreateMall = (params: OrderCreateMallRequest) =>
    post<OrderCreateMallRequest, OrderCreateMallResponse>(`/api/order/create_mall`, params);

// 商品修改订单
export const postEditMall = (params: OrderUpdateMallRequest) =>
    post<OrderUpdateMallRequest, {}>(`/api/order/update_address`, params);

// 查询订单状态是否已支付
export const postSearchOrderSuccess = (orderNumber: string) =>
    post<{ orderNumber: string }, OrderCreateMallResponse>(`/api/payment/state/${orderNumber}`);

// 查询卖家订单详情
export const apiGetSellerOrderDetail = (orderNumber: string) =>
    get<{ orderNumber: string }, orderDetailResponse>(`/api/seller_order/detail/${orderNumber}`);

// 卖家取消订单
export const postSellerResetOrder = (orderNumber: string) =>
    post<{ orderNumber: string }, {}>(`/api/seller_order/cancel/${orderNumber}`);

// 查询买家订单详情
export const apiGetOrderDetail = (orderNumber: string) =>
    get<{ orderNumber: string }, orderDetailResponse>(`/api/order/detail/${orderNumber}`);

// 买家取消订单
export const postResetOrder = (orderNumber: string) =>
    post<{ orderNumber: string }, {}>(`/api/order/cancel/${orderNumber}`);

// 买家确定收货
export const postConfirmOrder = (orderNumber: string) =>
    post<{ orderNumber: string }, {}>(`/api/order/confirm/${orderNumber}`);

// 【卖家】交易订单-发货
export const postDelivery = (params: SellerOrderDeliveryRequest) =>
    post<SellerOrderDeliveryRequest, {}>(`/api/seller_order/delivery`, params);

// 发布商品评价
export const mallReviewCreate = (params: MallReviewCreateRequest) =>
    post<MallReviewCreateRequest, {}>(`/api/mall_review/create`, params);

// 获取商品评价列表
export const getMallReview = (params: MallReviewListRequest) =>
    get<MallReviewListRequest, PaginationResponse<MallReviewListResponse>>(`/api/mall_review/list`, params);

// 获取商品评价详情
export const getMallReviewDetail = (orderNumber: string) =>
    get<{ orderNumber: string }, MallReviewListResponse>(`/api/mall_review/detail/${orderNumber}`);

// 获取其他商品推荐列表
export const getOtherProductList = (id: number) =>
    get<{ id: number }, MallProductOtherRecommendListResponse[]>(`/api/mall_product/other_recommend_list/${id}`);


// 根据订单号获取物流信息
export const ApiGetExpressInfo = ({ orderNumber }: { orderNumber: string }) =>
    get<{ orderNumber: string }, ExpressInfoResponse>(`/api/order/express_delivery/${orderNumber}`);

//买家申请退款
export const ApiCreateRefund = (params: RefundCreateRequest) =>
    post<RefundCreateRequest, any>(`/api/refund/create`, params);


//卖家同意退款
export const apiAgreeOrRefuseRefund = (params: SellerRefundConfirmRequest) =>
    post<SellerRefundConfirmRequest, any>(`/api/seller_refund/confirm`, params);

//退款-申请平台介入
export const apiRefundApplyAdmin = (params: RefundApplyAdminRequest) => {
    return post<RefundApplyAdminRequest, any>(`/api/refund/apply_admin`, params);

}

// 获取店铺信息
export const getShopDetail = () =>
    get<{}, MallShopDetailResponse>(`/api/mall_shop/detail`);

// 获取店铺信息
export const postApplicantShop = (remark: string) =>
    post<{ remark: string }, MallShopDetailResponse>(`/api/mall_shop/apply`, { remark });

// 获取申请店铺备注
export const getShopNotesList = () =>
    get<{}, CfgMallShopApplyRemarkResponse[]>(`/api/cfg/mall_shop_apply_remark`);

//运费模板-列表
export const apiGetShippingFeeTemplateList = () =>
    get<any, ShippingFeeTemplateListItem[]>(`/api/shipping_fee_template/list`);

//运费模板-创建
export const apiCreateShippingFeeTemplate = (params: ShippingFeeTemplateCreateRequest) =>
    post<ShippingFeeTemplateCreateRequest, any>(`/api/shipping_fee_template/create`, params);

//运费模板-修改
export const apiUpdateShippingFeeTemplate = (params: ShippingFeeTemplateUpdateRequest) =>
    post<ShippingFeeTemplateUpdateRequest, any>(`/api/shipping_fee_template/update`, params);

//运费模板-详情
export const apiGetShippingFeeTemplateDetail = (params: { id: number }) =>
    get<{ id: number }, ShippingFeeTemplateDetail>(`/api/shipping_fee_template/detail/${params.id}`);

//运费模板-删除
export const apiDeleteShippingFeeTemplate = (params: { id: number }) =>
    post<{ id: number }, any>(`/api/shipping_fee_template/delete/${params.id}`);

// 商品分享图
export const apiGetProductShareImage = (id: number) =>
    get<{ id: number }, MallShopShareImage>(`/api/mall_product/share_image/${id}`);

// 店铺分享图
export const apiGetMallShareImage = (id: number) =>
    get<{ id: number }, MallShopShareImage>(`/api/mall_shop/share_image/${id}`);

// 修改店铺分享图
export const apiPostUpdateMallCoverUrl = (coverUrl: string) =>
    post<{ coverUrl: string }, MallShopShareImage>(`/api/mall_shop/update`, { coverUrl });

//类目-商城个人类目列表
export const apiGetUserProductListCategory = (params: CategoryMallMineListRequest) =>
    get<CategoryMallMineListRequest, CategoryMallMineListItem[]>(`/api/category/mall_mine_list/${params.userId}`);

//类目-商城类目列表
export const apiGetMallProductListCategory = (params: MallProductCategoryListRequest) =>
    get<MallProductCategoryListRequest, MallProductCategoryListItem[]>(`/api/mall_product_category/list`, params);

export const apiGetMallWorkTypeList = () =>
    get<{}, MallWorktypeListItem[]>(`/api/mall_worktype/list`);

export const apiGetMallThemeStyleList = () =>
    get<{}, MallWorktypeListItem[]>(`/api/mall_themestyle/list`);