import {
  Form,
  Select,
  Button,
  Input,
  message,
  FormInstance,
  Modal,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { LoginFormStyle } from "./style";
import { apiGetCaptcha, apiLoginSendVerifyCode } from "api/common";
import { countryCodes } from "config/countryCode";
import { apiGetUserBaseInfo, apiLoginUserBySms } from "api/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkPhone, sleep } from "utils/functions/common";
import { AgreementContext } from "./login";
import { getCaptchaType } from "types/common";

const { Option } = Select;
function LoginFormSms({ isModal = false }: { isModal?: boolean }) {
  let [searchParams] = useSearchParams();
  const [countDown, setCountDown] = useState<number>(60);
  const [sendMsgBtnLoading, setSendMsgBtnLoading] = useState(false);
  const [captchaDetail, setDaptchaDetail] = useState<getCaptchaType>()
  const [messageApi, contextHolder] = message.useMessage();
  const [seachParams] = useSearchParams();
  const channelCode = seachParams.get('channelCode') ?? undefined;
  const formRef = useRef<FormInstance>(null);
  let navigate = useNavigate();
  const isAgree = useContext(AgreementContext);
  const [selectCountryCode, setSelectCountryCode] = useState(86)
  const selectBefore = (
    <Form.Item name="countryCode" noStyle>
      <Select optionLabelProp="label" onChange={(e) => setSelectCountryCode(e)} popupMatchSelectWidth={false}  >
        {countryCodes.map((item, index) =>
          <Option value={item.code} label={'+' + item.code} className="customOptions" style={{ width: '150px' }} key={index}>
            <span>
              {item.lable}
            </span>
            <span>
              {'+' + item.code}
            </span>
          </Option>)}
      </Select>
    </Form.Item>
  );

  useEffect(() => {
    updateCaptchaCode()
  }, [])

  const updateCaptchaCode = () => {
    apiGetCaptcha().then(res => {
      setDaptchaDetail(res)
    })
  }


  const onFinish = async (values: any) => {
    if (!isAgree) {
      message.warning("请先同意用户协议！")
      return
    }
    const { token } = await apiLoginUserBySms({
      countryCode: values.countryCode,
      mobile: values.mobile.trim(),
      verCode: values.verifyCode,
      channelCode
    })
    localStorage.setItem('auth_token', token);
    const userInfo = await apiGetUserBaseInfo()
    localStorage.setItem("user_info", JSON.stringify(userInfo));
    message.success("登录成功！")
    sleep(500)
    if (isModal) {
      window.location.href = window.location.href
    } else {
      const redirectUrl = searchParams.get("redirect")
      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        navigate('/home')
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onSendCode = async () => {
    const countryCode = formRef.current?.getFieldValue('countryCode');
    const mobile = formRef.current?.getFieldValue('mobile');
    const captchaCode = formRef.current?.getFieldValue('captchaCode')
    if (!captchaCode) {
      messageApi.error("请输入图形验证码！");
      return
    }
    if (!mobile) {
      messageApi.error("请填写手机号码！");
      return
    }
    if (Number(countryCode) === 86 && !checkPhone(mobile)) {
      messageApi.error("手机号码格式不正确！");
      return
    }
    //让按钮开始loading
    setSendMsgBtnLoading(true);
    // 让发送短信验证码
    apiLoginSendVerifyCode({
      mobile: formRef.current?.getFieldValue("mobile"),
      countryCode: countryCode,
      captchaUid: captchaDetail?.uid,
      captchaCode: formRef.current?.getFieldValue('captchaCode')
    }).then((res) => {
      messageApi.info("短信已发送");
      //刷新倒计时开始
      let interval = setInterval(() => {
        setCountDown((pre) => {
          if (pre === 0) {
            setCountDown(60);
            clearInterval(interval);
          }
          return pre - 1;
        });
      }, 1000);
    })
      .finally(() => {
        setSendMsgBtnLoading(false)
      });

  };

  const showTip = () => {
    Modal.confirm({
      title: '提示',
      content: <div>
        <div>如果您无法接收海外短信，请通过邮箱注册或登录。</div>
        <div>If you are unable to receive overseas SMS. Please register or log in through email.</div>
      </div>,
      okText: "确定",
      centered: true,
      cancelButtonProps: { style: { display: 'none' } },
    });
  }

  return (
    <LoginFormStyle>
      {contextHolder}
      <Form
        name="basic"
        style={{ width: "100%" }}
        initialValues={{ remember: true, mobile: "", countryCode: '86' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        ref={formRef}
      >
        <Form.Item
          name="mobile"
          rules={[{ required: true, message: "请输入手机号码" },
          {
            validator: (rule, val) => {
              const countryCode = formRef.current?.getFieldValue('countryCode');
              if (val.length === 0) {
                return Promise.resolve()
              }
              if (Number(countryCode) === 86 && !checkPhone(val)) {
                return Promise.reject('手机号格式不正确')
              }
              if (!!val.trim()) {
                return Promise.resolve()
              } else {
                return Promise.reject('请输入手机号码')
              }
            }
          }
          ]}
          normalize={(value) => value.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, '')}
        >
          <Input addonBefore={selectBefore} placeholder="请输入手机号码" />
        </Form.Item>
        {
          ![86, 852, 853, 886].find(item => item === Number(selectCountryCode)) && <div className="tip" onClick={showTip}>无法收到短信？</div>
        }

        <div style={{ display: "flex", alignItems: "center" }}>

          <Form.Item
            name="captchaCode"
            style={{ margin: 0, width: "184px" }}
            rules={[{ required: true, message: "请输入图形验证码" }]}
          >
            <Input placeholder="请输入图形验证码" width={184} />
          </Form.Item>
          <img src={captchaDetail?.base64} onClick={updateCaptchaCode} style={{ marginLeft: "16px" }} width={132} alt="" />
        </div>
        <Form.Item
          name="verifyCode"
          style={{ marginTop: "16px" }}
          rules={[
            {
              required: true,
              message: "请填写验证码",
            },
            {
              pattern: /^\d{4}$/,
              message: "验证码为4位数字",
            },
          ]}
        >
          <div className="code">
            <Input className="verifyCode" placeholder="请输入短信验证码" />
            <Button
              type="primary"
              onClick={() => onSendCode()}
              disabled={countDown < 60}
              loading={sendMsgBtnLoading}
              className="sendMsg"
            >
              {countDown < 60 && countDown + "秒后重新获取"}
              {countDown === 60 && !sendMsgBtnLoading && "获取验证码"}
            </Button>
          </div>
        </Form.Item>



        <Form.Item className="submit">
          <Button type="primary" htmlType="submit" block>
            登录
          </Button>
        </Form.Item>
      </Form>
    </LoginFormStyle>
  );
}

export default LoginFormSms;
