import { get, post } from "api"
import { PaginationResponse } from "types/common/axios";
import { ContestCodeListRequest, ContestDetailResponse, ContestFieldListResponse, ContestStageListResponse, ContestVoteTicketType, ContestWorkTotalType, ScoreCreateRequest, ScoreFlowDetail, ScoreFlowDetailRequest, ScoreNewCreateRequest, ScoreSubmitRequest, ScoreTaskDetail, ScoreTaskDetailRequest, ScoreUpdateTopRequest, ScoreWorkListItem, ScoreWorkListRequest, VoteUserTotal, WorkContestListItem, WorkContestListRequest } from "types/contest";

// 子比赛作品列表
export const getContestList = (params: WorkContestListRequest) =>
    get<WorkContestListRequest, PaginationResponse<WorkContestListItem>>(`/api/work/contest_list`, params)

export const getContestDetail = (params: { code?: string, id?: number }) =>
    get<{ code?: string, id?: number }, ContestDetailResponse>(`/api/contest/detail`, params)

export const getWorkTypeList = (contestId: number) =>
    get<{ contestId: number }, ContestFieldListResponse[]>(`/api/contest/field_list/${contestId}`)

export const getcontestStageList = (id: number) =>
    get<{ id: number }, ContestStageListResponse[]>(`/api/contest/stage_list`, { id })

// 获取邀请码
export const getcontestCodeList = (contestCode: string) =>
    get<{ contestCode: string }, ContestCodeListRequest>(`/api/contest_signup/invitation_code/${contestCode}`)

// 比赛投票
export const postContestUserTicket = (params: ContestVoteTicketType) =>
    post<ContestVoteTicketType, {}>(`/api/vote/use_ticket`, params)

// 获取用户比赛可使用票数
export const getContestUserVoteTotal = (params: { id: number, workId?: number }) =>
    get<{ id: number, workId?: number }, VoteUserTotal>(`/api/vote/user_total`, params)

// 获取作品票数
export const getContestWorkTotal = (workId: number) =>
    get<{ workId: number }, ContestWorkTotalType>(`/api/vote/work_total`, { workId })

// 一票晋级
export const postOneVote = (workId: number) =>
    post<{ workId: number }, {}>(`/api/contest/one_vote_upgrade/${workId}`)

// 赛事-获奖候选
export const postAwardCandidateVote = (workId: number) =>
    post<{ workId: number }, {}>(`/api/contest/award_candidate/${workId}`)

// 获取打分列表 
export const apiGetScoreWorkList = (params: ScoreWorkListRequest) => {
    return get<ScoreWorkListRequest, PaginationResponse<ScoreWorkListItem>>(`/api/score/work_list`, params)
}

// 获取新版打分列表 
export const apiGetNewScoreWorkList = (params: ScoreWorkListRequest) => {
    return get<ScoreWorkListRequest, ScoreWorkListItem[]>(`/api/score/new_work_list`, params)
}

// 更新打分列表顺序
export const apiPostUpdateScoreList = (params: ScoreUpdateTopRequest) =>
    post<ScoreUpdateTopRequest, {}>(`/api/score/update_top`, params)



//获取打分任务详情
export const apiGetScoreWorkTaskDetail = (params: ScoreTaskDetailRequest) => {
    return get<ScoreTaskDetailRequest, ScoreTaskDetail>(`/api/score/task_detail`, params)
}

//创建打分
export const apiCreateWorkScore = (params: ScoreCreateRequest) =>
    post<ScoreCreateRequest, any>(`/api/score/create`, params)

//提交打分    
export const apiSubmitWorksScore = (params: ScoreSubmitRequest) =>
    post<ScoreSubmitRequest, any>(`/api/score/submit`, params)

//打分详情
export const apiGetWorkScoreDetail = (params: ScoreFlowDetailRequest) =>
    get<ScoreFlowDetailRequest, ScoreFlowDetail>(`/api/score/flow_detail`, params)

// 获取比赛标签
export const apiGetContestTagList = (id: number) =>
    get<{ id: number }, { id: number, name: string }[]>(`/api/contest/tag_list/${id}`)

// 新打分
export const apiPostNewScore = (params: ScoreNewCreateRequest) =>
    post<ScoreNewCreateRequest, {}>(`/api/score/new_create`, params)

export interface ContestSignupListItem {
    contestId: number //比赛ID
    subContestSignupList: string[] //参赛类型
    id: number //报名
    contestName: string //比赛名称
    contestCode: string //比赛编号
    updateStatus: number

}

//获取个人参赛列表
export const apiGetSelfContestLis = () => {
    return get<any, ContestSignupListItem[]>('/api/contest_signup/list')
}


export interface ContestSignupDetailRequest {
    id: number //id
}

export interface ContestSignupDetail {
    mediaCategory: number[] //媒体分类
    otherContact: string //其它联系方式
    themeA: number[] //主题A
    mobile: string //手机号
    otherChannelAccount: string //其余渠道账号
    updateTime: string //修改时间
    team: number[] //组别
    socialMedia: string //社交媒体
    userId: number //用户ID
    organizationType: number //职业状态
    contestId: number //比赛ID
    qqWx: string //QQ/微信
    createTime: string //创建时间
    organization: string //公司院校
    nickname: string //昵称
    theme: number[] //主题
    id: number //null
    category: number[] //类目
    invitationCode: string //邀请码
}

//获取个人报名详情
export const apiGetSignUpDetail = (params: ContestSignupDetailRequest) => {
    return get<ContestSignupDetailRequest, ContestSignupDetail>(`/api/contest_signup/detail/${params.id}`)
}

// 赛事报名分享图
export const postRegultionShareImage = (contestId: number) =>
    post<{}, { shareImageUrl: string }>(`/api/contest_signup/share_image/${contestId}`)

// 获取邀请报名人数量
export const getApplicantCount = (code: string) =>
    get<{}, {}>(`/api/contest_signup/invite_signup_num/${code}`)

export interface ContestSignupUpdateRequest {
    mediaCategory?: number[] //媒体分类
    otherContact?: string //其它联系方式
    themeA?: number[] //主题A
    mobile?: string //手机号
    otherChannelAccount?: string //其余渠道账号
    team?: number[] //组别
    socialMedia?: string //社交媒体
    organizationType?: number //职业状态
    contestId: number //赛事ID
    theme2?: number[] //二级主题
    qqWx?: string //QQ/微信
    organization?: string //公司院校
    nickname?: string //昵称
    theme?: number[] //主题
    id: number //报名ID
    category?: number[] //内容分类
    invitationCode?: string //邀请码
    style3DSpecialAward?: number
}

// 更新个人报名
export const apiUpdateSignUp = (params: ContestSignupUpdateRequest) => {
    return post<ContestSignupUpdateRequest, any>('/api/contest_signup/update', params)
}