export enum SaleStatus {
    COMMODITY_SALE_ALL = 100,
    COMMODITY_SALING = 1,
    COMMODITY_SALED = 0
}


export const saleStatusArr = [
    { name: "全部", value: SaleStatus.COMMODITY_SALE_ALL },
    { name: "上架", value: SaleStatus.COMMODITY_SALING },
    { name: "下架", value: SaleStatus.COMMODITY_SALED }
]

export enum SoldOutStatus {
    COMMODITY_SOLDOUT_ALL = 100,
    COMMODITY_SOLDOUTED = 1,
    COMMODITY_SOLDOUT = 0
}


export const SoldOutStatusArr = [
    { name: "全部", value: SoldOutStatus.COMMODITY_SOLDOUT_ALL },
    { name: "是", value: SoldOutStatus.COMMODITY_SOLDOUTED },
    { name: "否", value: SoldOutStatus.COMMODITY_SOLDOUT }
]


export enum OrderStatusType {
    COMMODITY_ORDER_STATUS_ALL = 1,
    COMMODITY_ORDER_STATUS_SALED = 2,
    COMMODITY_ORDER_STATUS_PAYING = 3,
    COMMODITY_ORDER_STATUS_CLOSED = 4
}

export const OrderStatusTypeArr = [
    { name: '全部', value: OrderStatusType.COMMODITY_ORDER_STATUS_ALL },
    { name: '已售出', value: OrderStatusType.COMMODITY_ORDER_STATUS_SALED },
    { name: '待支付', value: OrderStatusType.COMMODITY_ORDER_STATUS_PAYING },
    { name: '已关闭', value: OrderStatusType.COMMODITY_ORDER_STATUS_CLOSED }
]


export enum CommodityStatus {
    COMMODITY_ORDER_STATUS_PAYING = 0,
    COMMODITY_ORDER_STATUS_WAIT_SEND = 1,
    COMMODITY_ORDER_STATUS_DELIVERY = 2,
    COMMODITY_ORDER_STATUS_FINALISH = 3,
    COMMODITY_ORDER_STATUS_RESERT = 4
}

export const CommodityStatusTypeArr = [
    { name: '待付款', value: CommodityStatus.COMMODITY_ORDER_STATUS_PAYING },
    { name: '待发货', value: CommodityStatus.COMMODITY_ORDER_STATUS_WAIT_SEND },
    { name: '待确定收货', value: CommodityStatus.COMMODITY_ORDER_STATUS_DELIVERY },
    { name: '已完成', value: CommodityStatus.COMMODITY_ORDER_STATUS_FINALISH },
    { name: '已取消', value: CommodityStatus.COMMODITY_ORDER_STATUS_RESERT }
]


export enum UserOrderStatusType {
    COMMODITY_ORDER_STATUS_ALL = 1,
    COMMODITY_ORDER_STATUS_PAYING = 0,
    COMMODITY_ORDER_STATUS_SALED = 3,
    COMMODITY_ORDER_STATUS_CLOSED = 4
}

export const UserOrderStatusTypeArr = [
    { label: '全部', value: UserOrderStatusType.COMMODITY_ORDER_STATUS_ALL },
    { label: '未支付', value: UserOrderStatusType.COMMODITY_ORDER_STATUS_PAYING },
    { label: '已支付', value: UserOrderStatusType.COMMODITY_ORDER_STATUS_SALED },
    { label: '已关闭', value: UserOrderStatusType.COMMODITY_ORDER_STATUS_CLOSED }
]

export enum DeliveryStatus {
    DELIVERYSTATUS_48_TIME = 0,
    DELIVERYSTATUS_SEVEN_DATE_TIME = 1,
    DELIVERYSTATUS_FIFTEEN_DATE_TIME = 2,
    DELIVERYSTATUS_PER_SALE = 3
}

export const DeliveryStatusArr = [
    { label: '48小时内', value: DeliveryStatus.DELIVERYSTATUS_48_TIME },
    { label: '7天内', value: DeliveryStatus.DELIVERYSTATUS_SEVEN_DATE_TIME },
    { label: '15天内', value: DeliveryStatus.DELIVERYSTATUS_FIFTEEN_DATE_TIME },
    { label: '预售', value: DeliveryStatus.DELIVERYSTATUS_PER_SALE }
]


