import { useEffect, useMemo, useState } from "react"
import { UserFansListStyle } from "./style"
import { getAuthorList } from "api/user"
import { UserBaseInfo, getAuthorItem } from "types/user"
import UserCard from "components/userCard"
import { Pagination } from "antd"
import GoBackTitle from "ui-lib/go-back"
import { NoData } from "components/no-data"
import { useNavigate } from "react-router-dom"

const UserFansList = () => {

    let userInfo: UserBaseInfo = JSON.parse(
        localStorage.getItem("user_info")!
    );

    const [pageNumber, setPageNumber] = useState(1)
    const [list, setList] = useState<getAuthorItem[]>([])
    const [total, setTotal] = useState(0)
    const params = useMemo(() => {
        return {
            // userId: Number(userInfo.id),
            followee: Number(userInfo.id),
            pageNumber,
            pageSize: 60
        }
    }, [userInfo.id, pageNumber])

    useEffect(() => {
        getAuthorList(params).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        })
    }, [params])

    const changePage = (e: number) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Use 'smooth' for smooth scrolling, or 'auto' for instant scrolling
        });
        setPageNumber(e);
    };

    const navigate = useNavigate()

    const back = () => {
        navigate("/user-center/home", { replace: true })
    }

    return <UserFansListStyle>
        <GoBackTitle onClick={() => back()}>返回</GoBackTitle>
        <UserCard list={list} />
        {
            list.length === 0 && <NoData />
        }
        <Pagination
            pageSize={params.pageSize}
            style={{ textAlign: "center" ,marginTop: 24}}
            hideOnSinglePage={true}
            showSizeChanger={false}
            onChange={changePage}
            total={total}
            current={pageNumber}
        />

    </UserFansListStyle>
}

export default UserFansList