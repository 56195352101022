import { Button, Checkbox, Input, message } from 'antd'
import { SecondReplyStyle } from './style'
import { useState, useCallback, } from 'react'
import { postCreateComment } from 'api/work/comment';
import { CommentListItem, CreateCommentRequest } from 'types/comment';
import { useParams } from 'react-router-dom';
import EmojiPicker from 'components/emoji-picker';
import { emojiParse } from "utils/functions/common";


type SecondReplyType = {
    params: {
        visible: boolean,
        detail: CommentListItem
        sendSubComment: (val: CommentListItem) => void,
        close: () => void,
        type: string,
        pid: number,
        word?: string,
        dataId?: number,
        anonymity?: boolean
    }
}

const SecondReply = (props: SecondReplyType) => {
    const { params } = props
    const [subValue, setSubValue] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const { id, productId } = useParams<any>();
    const [isAnonymity, setIsAnonymity] = useState(false)
    const changeValue = useCallback((e: any) => {
        setSubValue(e.target.value)
    }, [])

    const appendEmoji = (item: { url: string, title: string }) => {
        setSubValue((res) => res + item.title)
    }

    const sendSub = () => {
        if (!subValue.trim()) {
            message.error(`${params.word ? params.word : '评论'}内容不能为空`)
            return
        }
        setLoading(true)
        const req: CreateCommentRequest = {
            content: emojiParse(subValue),
            dataId: params.dataId || Number(id) || Number(productId),
            dataTable: params.type,
            pid: params.pid
        }
        if (params.detail.level === 2) {
            req.replyId = params.detail.id
        }
        if (isAnonymity) {
            req.isAnonymity = isAnonymity ? 1 : 0
        }
        postCreateComment(req).then((res) => {
            message.success('创建成功')
            params.sendSubComment(res)
            setIsAnonymity(false)
            params.close()
        }).finally(() => {
            setSubValue('')
            setLoading(false)
        })
    }

    const onChangeCheck = (e: any) => {
        setIsAnonymity(e.target.checked)
    }

    return <SecondReplyStyle>
        {params.visible && <div className='input-wrap'>
            <Input.TextArea value={subValue} onChange={changeValue} placeholder={`回复${params?.detail.isAnonymity === 1 ? '匿名' : params?.detail.replyUserInfo?.username || params?.detail.userInfo.username}：`}></Input.TextArea>
            <div className="btn-wrap">
                <div className="left-wrap">
                    <EmojiPicker appendEmoji={appendEmoji} />
                    {
                        !!params.anonymity && <Checkbox checked={isAnonymity} onChange={onChangeCheck}>是否匿名</Checkbox>
                    }
                </div>
                <Button type='primary' onClick={() => sendSub()} className='btn' loading={loading}>{params.word ? params.word : "评论"}</Button>
            </div>
        </div>}
    </SecondReplyStyle>
}

export default SecondReply