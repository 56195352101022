import styled from "styled-components";

export const FoldDetailStyle = styled.div`
    .button-wrap {
        padding: ${props => props.theme.space.padding}px;
        padding-bottom: 0;
    }
    .list{
        display: grid;
        gap: 24px;
    }
`