import { Message } from "@tencentcloud/chat"
import { MyMessageItem } from "../my-msg"
import { OtherMessageItem } from "../other-msg"



export const MessageItem = (props: {msg: Message}) =>{
    const msg = props.msg
    let userId = JSON.parse(localStorage.getItem('user_info')!).id

    return <div className="message-item-wrapp{er">
                {(userId === Number(msg.from))&& <MyMessageItem msg={props.msg}></MyMessageItem>}
                {(userId !== Number(msg.from))&& <OtherMessageItem msg={props.msg}></OtherMessageItem>}
           </div>
}