import { NotificationListItem } from "../../../types/notification";

function open(e: any, url: string) {
  // e.stopPropagation();
  window.open(url, "_blank");
}

const transLabel = (text: string) => {
  switch (text) {
    case "work":
      return "作品";
    case "article":
      return "文章";
    case "mall_product":
      return "商品";
  }
};

export function descRender(
  msg: NotificationListItem,
  clearMsg?: (msg: NotificationListItem) => void
) {
  switch (msg.type) {
    case 1:
      let node = <div></div>;
      if (
        msg.params &&
        msg.params.dataId &&
        msg.params.dataTable !== "mall_product"
      ) {
        if (msg.params.isPass) {
          node = (
            <a
              onClick={(e) => {
                e.stopPropagation();
                clearMsg && clearMsg(msg);
              }}
              href={
                msg.params.dataTable === "work"
                  ? `/work/detail/${msg.params.dataId}`
                  : `/article/detail/${msg.params.dataId}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {msg.params.template === 18 && <> 恭喜您！您的共创{transLabel(msg.params.dataTable)}
                <span>{msg.params.title}</span>已通过。</>

              }
              {msg.params.template !== 18 && <> 恭喜您！您提交的{transLabel(msg.params.dataTable)}
                <span>{msg.params.title}</span>已通过。</>
              }

              {msg.params.dataTable === "work"
                ? "在作品页面生成分享海报，让更多人看到您的作品！"
                : ""}
            </a>
          );
        } else {
          node = (
            <a
              onClick={(e) => {
                e.stopPropagation();
                clearMsg && clearMsg(msg);
              }}
              href={
                msg.params.dataTable === "work"
                  ? `/user-center/home/work/draft/list`
                  : "/user-center/home/article/draft/list"
              }
              target="_blank"
              rel="noreferrer"
            >
              很遗憾！您提交的{transLabel(msg.params.dataTable)}
              <span>{msg.params.title}</span>未通过。点击查看详情！
            </a>
          );
        }
      } else {
        node = <>{msg.content}</>;
      }

      if (
        msg.params &&
        msg.params.dataId &&
        msg.params.dataTable === "mall_product"
      ) {
        if (msg.params.isPass) {
          node = (
            <a
              onClick={(e) => {
                e.stopPropagation();
                clearMsg && clearMsg(msg);
              }}
              href={`/user-center/shop/product-list?onSale=0&status=2&pageNumber=1&tabKey=3`}
              target="_blank"
              rel="noreferrer"
            >
              恭喜您！您提交的{transLabel(msg.params.dataTable)}
              <span>{msg.params.title}</span>已通过审核。
              {msg.params.dataTable === "mall_product"
                ? "请前往店铺管理上架商品。"
                : ""}
            </a>
          );
        } else {
          node = node = (
            <a
              onClick={(e) => {
                e.stopPropagation();
                clearMsg && clearMsg(msg);
              }}
              href={`/user-center/shop/product-list?onSale=0&status=1,3&pageNumber=1&tabKey=2`}
              target="_blank"
              rel="noreferrer"
            >
              很遗憾！您提交的{transLabel(msg.params.dataTable)}
              <span>{msg.params.title}</span>未通过审核。点击查看详情！
            </a>
          );
        }
      }
      return node;
    case 2:
      return (
        <>
          <span
            onClick={(e) => open(e, `/user/${msg.params.fromUserId}/works`)}
          >
            {msg.params.fromUsername}
          </span>
          点赞了你的{transLabel(msg.params.dataTable)}
          {msg.params.dataTable === "work" && (
            <span onClick={(e) => open(e, `/work/detail/${msg.params.dataId}`)}>
              {msg.params.title}
            </span>
          )}
          {msg.params.dataTable === "article" && (
            <span
              onClick={(e) => open(e, `/article/detail/${msg.params.dataId}`)}
            >
              {msg.params.title}
            </span>
          )}
        </>
      );

    case 3:
      if (msg.params.dataTable === "work") {
        return (
          <>
            <span onClick={(e) => open(e, `/user/${msg.params.fromUserId}`)}>
              {msg.params.fromUsername}
            </span>
            在作品
            <span onClick={(e) => open(e, `/work/detail/${msg.params.dataId}`)}>
              {msg.params.title}
            </span>
            下评论道：
            <span
              onClick={(e) => open(e, `/work/detail/${msg.params.dataId}`)}
              dangerouslySetInnerHTML={{ __html: msg.params.content }}
            ></span>
          </>
        );
      }
      if (msg.params.dataTable === "article") {
        return (
          <>
            <span onClick={(e) => open(e, `/user/${msg.params.fromUserId}`)}>
              {msg.params.fromUsername}
            </span>
            在文章
            <span
              onClick={(e) => open(e, `/article/detail/${msg.params.dataId}`)}
            >
              {msg.params.title}
            </span>
            下评论道：
            <span
              onClick={(e) => open(e, `/article/detail/${msg.params.dataId}`)}
              dangerouslySetInnerHTML={{ __html: msg.params.content }}
            ></span>
          </>
        );
      }

      break;

    case 4:
      return (
        <>
          <span
            onClick={(e) => open(e, `/user/${msg.params?.fromUserId}/works`)}
          >
            {msg.params?.fromUsername}
          </span>
          关注了你
        </>
      );

    case 5:
      if (msg.params.dataTable === "user") {
        return (
          <>
            <span onClick={(e) => open(e, `/user/${msg.params.fromUserId}`)}>
              {msg.params.fromUsername}
            </span>
            在你的主页留言：
            <span
              onClick={(e) => open(e, `/user/${msg.params.dataId}`)}
              dangerouslySetInnerHTML={{ __html: msg.params.content }}
            ></span>
          </>
        );
      }
      break;
    case 6:
      return renderShopMsg(msg, clearMsg);
    case 7:
      return renderForumMsg(msg, clearMsg);
    default:
      break;
  }
}

function renderShopMsg(
  msg: NotificationListItem,
  clearMsg?: (msg: NotificationListItem) => void
) {
  switch (msg.params.template) {
    case 1:
      return (
        <span className="shop-msg">
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user/${msg.params.fromUserId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.fromUsername}
          </a>
          &nbsp; 购买了你的商品&nbsp;
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/product/detail/${msg.params.dataId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.title}
          </a>。{
            !!msg.params.orderNumber && <span>点击查看订单详情</span>
          }
        </span>
      );

    case 2:
      if (msg.params.isPass) {
        return (
          <span className="shop-msg">
            恭喜您！您提交的{transLabel(msg.params.dataTable)}&nbsp;
            <a
              onClick={(e) => {
                e.stopPropagation();
                clearMsg && clearMsg(msg);
              }}
              href={`/user-center/shop/product-list?onSale=0&status=2&pageNumber=1&tabKey=3`}
              target="_blank"
              rel="noreferrer"
            >
              {msg.params.title}
            </a>
            &nbsp; 已通过审核。
            {msg.params.dataTable === "mall_product"
              ? "请前往店铺管理上架商品。"
              : ""}
          </span>
        );
      } else {
        return (
          <span className="shop-msg">
            很遗憾！您提交的{transLabel(msg.params.dataTable)} &nbsp;
            <a
              onClick={(e) => {
                e.stopPropagation();
                clearMsg && clearMsg(msg);
              }}
              href={`/user-center/shop/product-list?onSale=0&status=1,3&pageNumber=1&tabKey=2`}
              target="_blank"
              rel="noreferrer"
            >
              {msg.params.title}
            </a>
            &nbsp; 未通过审核。点击查看详情！
          </span>
        );
      }
    case 3:
      return (
        <span className="shop-msg">
          您购买的
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/product/detail/${msg.params.dataId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.title}
          </a>
          卖家已发货。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 4:
      return (
        <span className="shop-msg">
          您的商品
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/product/detail/${msg.params.dataId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.title}
          </a>
          订单已被买方
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user/${msg.params.fromUserId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.fromUsername}
          </a>
          取消。 订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/seller-order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 5:
      return (
        <span className="shop-msg">
          您购买的订单已被卖家取消。 订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 6:
      return (
        <span className="shop-msg">
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user/${msg.params.fromUserId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.fromUsername}
          </a>
          在您的商品
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/product/detail/${msg.params.dataId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.title}
          </a>
          中评论
        </span>
      );

    case 7:
      return (
        <span className="shop-msg">
          买方
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user/${msg.params.fromUserId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.fromUsername}
          </a>
          已对订单
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/product/detail/${msg.params.dataId}`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.title}
          </a>
          评价。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/seller-order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );

    case 8:
      return (
        <span className="shop-msg">
          您售出的商品已结算，请前往个人中心-<a
            href={`/user-center/wallet`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            我的钱包
          </a> &nbsp;中提现！
        </span>
      );

    case 9:
      return (
        <span className="shop-msg">
          您售出的商品
          <a
            href={`/product/detail/${msg.params.dataId}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          买家申请退款。 订单编号{" "}
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/seller-order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );

    case 10:
      return (
        <span className="shop-msg">
          您购买的商品
          <a
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          退款成功。订单编号：{" "}
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 11:
      return (
        <span className="shop-msg">
          您购买的商品
          <a
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          卖家已拒绝退款，您可以申请平台介入 。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 12:
      return (
        <span className="shop-msg">
          您购买的商品
          <a
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          平台已同意退款。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 13:
      return (
        <span className="shop-msg">
          您购买的商品
          <a
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          平台已拒绝退款。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 14:
      return (
        <span className="shop-msg">
          您售出的商品
          <a
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          平台已同意退款。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/seller-order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 15:
      return (
        <span className="shop-msg">
          您售出的商品
          <a
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          平台拒绝了买家的退款申请。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/seller-order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 16:
      return (
        <span className="shop-msg">
          您购买的商品
          <a
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          卖家24小时内未响应，您可以申请平台介入。订单编号：
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            {msg.params.orderNumber}
          </a>
        </span>
      );
    case 17:
      if (msg.params.isPass) {
        return (
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={
              `/user-center/shop/product-list`
            }
            target="_blank"
            rel="noreferrer"
          >

            您的开店申请已通过审核，快去上架商品吧！

          </a>
        );
      } else {
        return (
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={
              `/user-center/shop/product-list`
            }
            target="_blank"
            rel="noreferrer"
          >

            您的开店申请未通过，驳回原因： &nbsp; {msg.params.rejectReason}

          </a>
        );
      }
    case 18:
      return (
        <span className="shop-msg">
          您的购买的约稿商品
          <a
            href={`/product/detail/${msg.params.dataId}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
          >
            &nbsp; {msg.params.title} &nbsp;
          </a>
          对方已完成交稿，请您确认。
          <a
            onClick={(e) => {
              e.stopPropagation();
              clearMsg && clearMsg(msg);
            }}
            href={`/user-center/shop/order/detail/${msg.params.orderNumber}?from=all`}
            target="_blank"
            rel="noreferrer"
          >
            点击查看订单详情
          </a>
        </span>
      );
    default:
      break;
  }
}

function renderForumMsg(msg: NotificationListItem,
  clearMsg?: (msg: NotificationListItem) => void) {
  switch (msg.params.template) {
    case 19:
      return <span className="shop-msg">
        <a
          onClick={(e) => {
            if (msg.params.fromUserId === 0) {
              e.preventDefault();
            }
            e.stopPropagation();
            clearMsg && clearMsg(msg);
          }}
          href={msg.params.fromUserId === 0 ? '#' : `/user/${msg.params.fromUserId}`}
          target="_blank"
          rel="noreferrer"
        >
          {msg.params.fromUsername}
        </a> &nbsp;回复了你的问题 &nbsp;<a
          href={`/forum/question/topic/${msg.params.dataId}?back=/forum/index?type=hot&currentPage=1&isFollow=0&isRecommend=0&sortField=hot`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            clearMsg && clearMsg(msg);
          }}
        >
          {msg.params.title}，点击查看

        </a>
      </span>

    case 21:
      return <span className="shop-msg">
        <a
          onClick={(e) => {
            if (msg.params.fromUserId === 0) {
              e.preventDefault();
            }
            e.stopPropagation();
            clearMsg && clearMsg(msg);
          }}
          href={msg.params.fromUserId === 0 ? '#' : `/user/${msg.params.fromUserId}`}
          target="_blank"
          rel="noreferrer"
        >
          {msg.params.fromUsername}
        </a> &nbsp;对你的回复  <a
          href={`/forum/question/topic/${msg.params.dataId}?back=/forum/index?type=hot&currentPage=1&isFollow=0&isRecommend=0&sortField=hot`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            clearMsg && clearMsg(msg);
          }}
        >
          {msg.params.title}
        </a>评论道: <span dangerouslySetInnerHTML={{ __html: msg.params.content }} />

      </span>

    case 22:
      return <span className="shop-msg">
        <a
          onClick={(e) => {
            e.stopPropagation();
            clearMsg && clearMsg(msg);
          }}
          href={`/user/${msg.params.fromUserId}`}
          target="_blank"
          rel="noreferrer"
        >
          {msg.params.fromUsername}
        </a> &nbsp;点赞了你的回答 <a
          href={`/forum/question/topic/${msg.params.dataId}?back=/forum/index?type=hot&currentPage=1&isFollow=0&isRecommend=0&sortField=hot`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            clearMsg && clearMsg(msg);
          }}
        >
          {msg.params.title}
        </a>

      </span>

  }
}