import { DetailHeaderStyle } from "./style";
import { Tooltip } from "antd";
import { WorkDetailType } from "types/work";
import { findAchiveContestPath } from "./fn";

type Props = {
  detail: WorkDetailType;
};
function DetailHeader({ detail }: Props) {

  const makeTime = (time: string) => {
    if (time === '1990-01-01') {
      return ''
    }
    return time
  }
  const renderTip = (prize: any) => {
    if (prize.type === 1) {
      if (prize.userId < 0 && prize.judgeInfos && prize.judgeInfos.length === 1) {
        return (
          <div>
            本作品被{prize.judgeInfos[0]?.userInfo?.username}
            {prize?.oneVoteName}
          </div>
        )
      } else if (prize.userId < 0 && prize.judgeInfos && prize.judgeInfos.length > 1) {
        return (
          <div>
            本作品被以下评委推荐{prize?.oneVoteName}
            <div className="judge" style={{ display: "flex", gap: "10px", margin: "5px 0" }}>
              {
                prize.judgeInfos.map((item: any) => {
                  return <div onClick={() => window.open(
                    `/user/${item?.userId}/works`
                  )} title={item.userInfo.username} style={{ background: `url(${item?.userInfo.avatarUrl})`, width: "25px", aspectRatio: 1, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer" }} />
                })
              }

            </div>
          </div>
        )
      }
      return (
        <div>
          {prize?.oneVoteUserInfo && prize.userId > 0 && <div>
            本作品被{prize?.oneVoteUserInfo?.username}
            {prize?.oneVoteName}
          </div>}
          {!prize?.oneVoteUserInfo && prize.userId > 0 &&
            <div style={{ textAlign: "center" }}>   {prize.name} </div>
          }

          <div style={{ textAlign: "center" }}>
            {makeTime(prize.createTime?.substring(0, 10))}
          </div>
        </div>
      );
    }
    if (prize.type === 2) {
      return (
        <div style={{ textAlign: "center" }}>
          <div>本作品荣获</div>
          <div>{`⌜${detail.contestWork?.contestName}⌟`}</div>
          <div> {prize.name}</div>
          <div style={{ textAlign: "center" }}>
            {prize.createTime === '1990-01-01 00:00:00' ? '' : prize.createTime?.substring(0, 10)}
          </div>
        </div>
      );
    }
  };

  const renderTip2 = (data: any) => {
    return <div className="judge" style={{ width: "149px", display: "flex", flexWrap: "wrap", gap: "10px", margin: "5px 0" }}>
      {
        data.slice(5, data.lenth).map((item: any) => {
          return <Tooltip title={`本作品被${item.userInfo.username}推荐`}>
            <div onClick={() => window.open(
              `/user/${item?.userId}/works`
            )} style={{ background: `url(${item?.userInfo.avatarUrl})`, width: "25px", aspectRatio: 1, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer" }} />
          </Tooltip>
        })
      }
    </div>
  };

  const openContest = () => {
    if (["blackshark", "fight", "supercell"].includes(detail.contestWork?.contestCode)) {
      window.open(`https://achive.ggac.com/sub/${detail.contestWork?.contestCode}`)
    } else if (["creativeLab", "ly3d", "xrtz", "ggac6"].includes(detail.contestWork.contestCode)) {
      window.open("/2024/regulation/detail")
    } else if ([19, 22, 23, 24].includes(detail.contestWork?.contestId) || (detail.contestWork?.contestId > 35)) {
      window.open("/contest/" + detail.contestWork?.contestCode + '/detail')
    } else {
      findAchiveContestPath(detail)
    }

  }
  return (
    <DetailHeaderStyle>
      <div className="contest-header-container">
        <div className="contest">

          <div className="stage">
            {detail.contestWork?.prizes
              ?.sort((a, b) => b.type - a.type)
              .map((prize, index) => {
                //最终奖
                if (prize.type === 2) {
                  return (
                    <Tooltip title={renderTip(prize)} key={index}>
                      <div className="prize-item final-prize-item">
                        <span className="name">{prize.name}</span>
                        {prize.type === 2 && (
                          <div
                            className="avatar"
                            style={{
                              backgroundImage: `url(${prize.coverUrl ||
                                "https://cdn-prd.ggac.com/ggac/assets/image/icon/trophy.svg"
                                })`,
                              backgroundSize: "contain",
                            }}
                          ></div>
                        )}
                      </div>
                    </Tooltip>
                  );
                }
              })}
            {
              detail.recommendJudgeInfos && detail.recommendJudgeInfos.length > 0 &&
              <div className="prize-item new-prize-item">
                <div className="judge-wrap">
                  {
                    detail.recommendJudgeInfos.slice(0, 5).map(item => {
                      return <Tooltip title={`本作品被${item.userInfo.username}推荐`}>
                        <div className="judge-item" onClick={() =>
                          window.open(
                            `/user/${item?.userId}/works`
                          )
                        } style={{
                          backgroundImage: `url(${item?.userInfo.avatarUrl})`,
                        }} />
                      </Tooltip>
                    })
                  }
                  {
                    detail.recommendJudgeInfos.length > 5 && <Tooltip color="#fff" title={renderTip2(detail.recommendJudgeInfos)}>
                      <div className="circle">
                        {
                          [1, 2, 3].map(item => {
                            return <div className="circle-item" key={item} />
                          })
                        }
                      </div>
                    </Tooltip>
                  }
                </div>
              </div>
            }
            {detail.contestWork?.prizes
              ?.sort((a, b) => b.type - a.type)
              .map((prize, index) => {
                //阶段奖
                if (prize.type === 1) {
                  if (prize.userId < 0) {
                    if (prize.judgeInfos && prize.judgeInfos.length === 1) {
                      return <Tooltip title={renderTip(prize)} key={index}>
                        {prize?.judgeInfos && (
                          <div className="prize-item new-prize-item" style={{ borderRadius: prize.oneVoteUserInfo ? '32px 0 0 32px' : '32px' }}>
                            <div
                              className="prize-avatar"
                              style={{
                                backgroundImage: `url(${prize.judgeInfos[0]?.userInfo.avatarUrl})`,
                              }}
                              onClick={() =>
                                window.open(
                                  `/user/${prize.judgeInfos[0]?.userId}/works`
                                )
                              }
                            ></div>
                          </div>
                        )}
                      </Tooltip>
                    } else if (prize.judgeInfos && prize.judgeInfos.length === 2) {
                      return <Tooltip title={renderTip(prize)} key={index}>
                        <div className="prize-item new-prize-item" style={{ borderRadius: prize.oneVoteUserInfo ? '32px 0 0 32px' : '32px' }}>
                          <div className="judge-wrap judge-two-wrap">
                            {
                              prize?.judgeInfos.slice(0, 2).map(item => {
                                return <div className="judge-item" style={{
                                  backgroundImage: `url(${item?.userInfo.avatarUrl})`,
                                }}></div>
                              })
                            }
                          </div>
                        </div>
                      </Tooltip>
                    } else if (prize.judgeInfos && prize.judgeInfos.length > 2) {
                      return <Tooltip title={renderTip(prize)} key={index}>
                        <div className="prize-item new-prize-item" style={{ borderRadius: prize.oneVoteUserInfo ? '32px 0 0 32px' : '32px' }}>
                          <div className="judge-wrap">
                            {
                              prize?.judgeInfos.slice(0, 4).map(item => {
                                return <div className="judge-item" style={{
                                  backgroundImage: `url(${item?.userInfo.avatarUrl})`,
                                }}></div>
                              })
                            }
                          </div>
                        </div>
                      </Tooltip>
                    }
                  }
                  if (index === 0) {
                    return (
                      <Tooltip title={renderTip(prize)} key={index}>
                        <div className={`prize-item ${!!prize.oneVoteUserInfo ? 'final-prize-item' : ''}`} style={{ borderRadius: prize.oneVoteUserInfo ? '32px 0 0 32px' : '32px' }}>
                          <span className="name" style={{ paddingRight: prize.oneVoteUserInfo ? '28.8px' : '12px' }}>{prize.name}</span>
                          {prize.type === 1 && prize.oneVoteUserInfo && (
                            <div
                              className="avatar"
                              style={{
                                backgroundImage: `url(${prize.oneVoteUserInfo?.avatarUrl})`,
                              }}
                              onClick={() =>
                                window.open(
                                  `/user/${prize.oneVoteUserInfo?.id}/works`
                                )
                              }
                            ></div>
                          )}
                        </div>
                      </Tooltip>
                    );
                  }
                  if (!!prize?.oneVoteUserInfo) {
                    return (
                      <Tooltip title={renderTip(prize)} key={index}>
                        {prize?.oneVoteUserInfo && (
                          <div
                            className="prize-avatar"
                            style={{
                              backgroundImage: `url(${prize.oneVoteUserInfo?.avatarUrl})`,
                            }}
                            onClick={() =>
                              window.open(
                                `/user/${prize.oneVoteUserInfo?.id}/works`
                              )
                            }
                          ></div>
                        )}
                      </Tooltip>
                    );
                  }
                }
              })}
          </div>
          <div className="contest-info">
            <div
              className="title"
            >
              <div onClick={() => openContest()} style={{ display: "flex" }}>
                {
                  detail.contestWork?.contestType === 2 ?
                    <Tooltip title={!!["creativeLab", "ly3d", "xrtz"].includes(detail.contestWork.contestCode) ? "第六届GGAC大赛" : detail.contestWork.contestName}>
                      <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/contest-gold.svg" width={16} alt="" />
                    </Tooltip>
                    :
                    <Tooltip title={detail.contestWork.contestName}>
                      <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/contest-silver.svg" width={16} alt="" />
                    </Tooltip>
                }
              </div>
              {
                !!["creativeLab", "ly3d", "xrtz"].includes(detail.contestWork.contestCode) && <>
                  <div className="content-title">
                    <div onClick={() => openContest()}>第六届GGAC大赛</div><span>{`${">"}`}</span>
                    <div onClick={() => window.open(`/contest/${detail.contestWork.contestCode}`)}>{detail.contestWork.contestName}</div>
                  </div>
                </>
              }

              {
                !["creativeLab", "ly3d", "xrtz"].includes(detail.contestWork.contestCode) && <div onClick={() => openContest()}>{detail.contestWork.contestName}</div>
              }

            </div>
            <div className="info-block">
              {/* <Space split={<Divider type="vertical" />}> */}
              {detail.dictMap?.team && (
                <div className="info-item"><div>{`>`}</div>{detail.dictMap?.team}</div>
              )}
              {
                detail.contestWork.contestCode === 'ggac6' && <>
                  {detail.dictMap?.theme && (
                    <div className="info-item"><span>{`>`}</span><div>GGAC创作奖</div></div>
                  )}
                  {detail.dictMap?.themeA && (
                    <div className="info-item"><span>{`>`}</span><div>GGAC学院奖</div></div>
                  )}
                </>
              }
              {
                detail.contestWork.contestCode !== 'ggac6' && <div>
                  {detail.dictMap?.theme && (
                    <div className="info-item"><span>{`>`}</span><div>{detail.dictMap?.theme}</div></div>
                  )}
                </div>
              }
              {
                detail.contestWork.contestCode === 'ggac6' && (detail.dictMap.theme || detail.dictMap.themeA) && <>
                  <span>{`${">"}`}</span><div>{detail.dictMap.theme || detail.dictMap.themeA}</div>
                </>
              }
              <div className="info-item">
                <span>{`>`}</span><div>{detail.publishType === 1 ? "阶段作品" : "完成作品"}</div>
              </div>
              {/* </Space> */}
            </div>
          </div>
        </div>
        <div className="metadata"></div>
      </div>
    </DetailHeaderStyle >
  );
}

export default DetailHeader;
