import { createSlice } from '@reduxjs/toolkit'


const headerTransitionSlice = createSlice({
    name: 'headerTrans',
    initialState: "default",
    reducers: {
        changeHeaderType(state, action) {
            return action.payload
        }
    }
})

export const { changeHeaderType} = headerTransitionSlice.actions

export default headerTransitionSlice.reducer