import { get, post } from "api"
import { getRedeemListRequest, redeemItem, redeemListOrderItem, getRedeemListOrderRequest, createRedeemRequest } from "types/redeem"
import { PaginationResponse } from "types/common/axios"

// 获取积分列表
export const apiGetRedeemList = (params: getRedeemListRequest) =>
    get<getRedeemListRequest, PaginationResponse<redeemItem>>("/api/gp_product/list", params)

// 积分订单列表
export const apiGetRedeemOrderList = (params: getRedeemListOrderRequest) =>
    get<getRedeemListOrderRequest, PaginationResponse<redeemListOrderItem>>("/api/gp_order/list", params)

// 创建订单
export const postCreateOrder = (params: createRedeemRequest) =>
    post<createRedeemRequest, {}>("/api/gp_order/flag", params)