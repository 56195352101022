
import {HelpStyle} from './style'
import { Divider } from 'antd';
import React  from "react";

import type { MenuProps } from 'antd';
import { Layout, Menu, theme } from 'antd';


const { Header, Content,  Sider } = Layout;

const accountRegistration =[
        {title:"1、手机收不到验证码？",content:"根据电信安全要求，同一个手机号每分钟最多发送一条短信，每小时最多5条，每天最多10条，超出部分将会被阻止发送。"},
        {title:"2、忘记密码了怎么办？",content:"进入“账号登录”页面点击“忘记密码？”，输入手机号/邮箱，通过验证后即可重置密码。"},
        {title:"3、注册的手机号是否可以更改？",content:"请前往“安全中心”修改并重新验证即可更改。"},
        {title:"4、实名认证通过后还可以更改吗？",content:"实名认证作为用户账户的重要关联信息，一经认证，不得取消或随意修改。若确有修改需求的话，您可以联系客服并提供相关资料修改。"},
        {title:"5、可以注销账号吗？",content:"很遗憾的告诉您，目前通过手机号码注册的帐号暂时无法修改和解除手机号码绑定。"},  
        
]   

const personal =[
        {title:"1、是否可以改名字？",content:"可以，您可以在“个人中心”-“资料”-“基本信息”处进行修改。"},
        {title:"2、是否可以设置个人主页背景？",content:"可以，您可在“用户中心”左上方点击“装饰主页”设置个性化主页背景。"},
        {title:"3、是否可以设置个人主页地址？",content:"可以，您可在“用户中心”内点击“头像”，编辑资料内“主页”设置个性化主页地址。"},
        {title:"4、自由职业，如何填写公司/院校？",content:"根据您的实际情况，填写“自由画师”、“自由模型师”等即可。"},
        {title:"5、实名认证在哪里？",content:"您可以在“头像”-“安全中心”-“实名认证”处进行认证。"},
]

const signUp =[
        {title:"1、如何报名参赛GGAC？",content:"注册GGAC账号后点击“立即报名”，填写完整内容即可参赛。"},
        {title:"2、校园组和专业组有何区别？",content:
        ["可以，您可在“用户中心”左上方点击“装饰主页”设置个性化主页背景。\n",
        "1）校园组为特别为在校学生设置的组别，包括全日制专本科学生、研究生等；\n",
        "2）校园组参赛者可选择2D美术、3D模型和ANI动画进行创作，无细分类别之分；\n",
        "3）专业组参赛者需选择细分类别进行参赛创作，如2D角色设计；"
        ]},
        {title:"3、我目前还是学生可以选择专业组参赛吗？",content:"可以，学生可以选择专业组报名参赛，竞争专业组奖项、奖金。"},
        {title:"4、我是今年毕业的应届生是否可以报名参赛校园组？",content:"可以，今年毕业的应届生可以选择校园组报名参赛。（友情提示：请提前对学生证拍照留档，避免毕业后学生证上缴学校后造成无法审核）"},
        {title:"5、官方如何确认作品作者的组别是否正确？",content:"为保证比赛的公平公正，作品入围后，官方将对参赛者个人信息及作品源文件进行审核，以确保参赛者组别正确及作品为参赛者本人创作。"},
        {title:"6、是否可以团队身份参加，并且以团队制作作品吗？",content:"不可以，本届赛事仅针对个人参赛者。我们如计划在未来进行面对团队的比赛会另行发布，但目前仅针对个人参赛者。"},
        {title:"7、报名通过后，发现信息填错了，报名信息是否可以更改？",content:"可在，“个人中心”—“资料”进行更改，但报名信息一旦进行修改，您将重新进行账号审核，同时您之前上传的本次比赛作品将会清空热度并下架，请谨慎操作。"},
        {title:"8、是否可以多组别一起报名参赛？",content:"可以，如您有余力，可选择一同报名参赛多个组别，但每种细分类别下只可以投递一份作品。对于参加多类别比赛的参赛者，我们希望看到您以某一类别的作品为基础，创作其他更多更丰富的作品。"},
        {title:"9、本届赛事共分为哪几个阶段？",content:"本届赛事共分为1.“海选晋级”、“评选入围”、“奖项提名”、“奖项评审”四个阶段。"},
        {title:"10、“海选晋级”是什么意思？作品晋级由什么决定？",content:[
        "1）“海选晋级”阶段为7/8/9月1日12:00-7日12:00，10月8日12:00-14日12:00限时开启对当前所有海选作品的晋级投票，晋级将由参赛作品的票数；\n",
        "2）“海选晋级”阶段，每次将晋级该晋级期内各类别票数排名前40%的作品；\n",
        "3）“海选晋级”阶段，当前晋级期未晋级的作品，仍将保留当前部分票数于下一晋级期继续海选；\n",
        "4）“海选晋级”阶段，GGAC评审团拥有“一票晋级”权利，除在作品全部提交完成后，将会有为期一周的专家、评委晋级外，专家、评委将不定期对优秀作品进行晋级。"
        ]},
        {title:"11、“评选入围”是什么意思？作品入围由什么决定？",content:[
        "1）“评选入围”阶段，将由评审团从已晋级的作品中评审；\n",
        "2）“评选入围”阶段，不设入围比例，以参赛作品品质为入围标准。"
        ]},
        {title:"12、“奖项提名”是什么意思？",content:"“奖项提名”阶段，将由初评评委从已入围作品中评选最终角逐奖项的候选作品并进行公示。"},
        {title:"13、“奖项评审”是什么意思？",content:"“奖项评审”阶段，将由终评评委对公示后无异议的作品进行最终的奖项评审。"},
        {title:"14、如何查看及修改报名信息",content:"在官网个人信息-资料页面中有关于报名的信息，并可进行修改。"}
]

const works =[
        {title:"1、创作的作品是否需要原创？",content:"您的参赛作品必须为本人原创，严禁包含政治、低俗、色情、宗教等内容，不得违反相关法律法规，严禁抄袭、盗用他人作品，且参赛期间版权未移交他人，未被商用或授权他人使用。"},
        {title:"2、作品的尺寸是否有限定？",content:"创作的作品画布尺寸的长度或宽度不低于2480px；分辨率300dpi，尺寸上限和作品横竖构图由画师、模型师自己决定并不限制。（例如，场景作品的宽度应不低于2480px，角色作品的高度应不低于2480px）"},
        {title:"3、什么是阶段性作品？",content:"创作过程中的阶段性作品包括但不限于：概念草图、轮廓背景、线稿色稿、高模展示图、低模展示图、贴图展示图等。"},
        {title:"4、是否必须在创作过程中上传阶段性作品？",content:"不必须，GGAC不强制，但鼓励参赛者在创作过程中上传阶段性作品。"},
        {title:"5、已发布的参赛作品是否可以进行修改？",content:["1）创作中已发布的阶段性作品，可以进行修改；\n","2）已发布的完整参赛作品如对上传且通过审核的上架竞选作品进行修改，该作品将重新进行审核，且所获得的得票数及晋级状态将进行清空，请谨慎操作。"]},
        {title:"6、参赛作品必须要包含GGAC的LOGO吗？",content:"参赛作品必须包含GGAC的LOGO，GGAC官方希望参赛者能将GGAC的LOGO作为设计元素呈现于作品中。"},
        {title:"7、一个人是否可以同时参加两个或多个类型？",content:[
        "可以，如果你是校园组，你可以同时参加2D美术、3D模型和ANI动画三个类型；如果你是专业组，你可以同时参加所有详细分组任意一个或其中几个类型。 特别声明：\n",
        "1）每个类型只能上传一幅作品进行参赛\n",
        "2）一幅作品仅能上传至一个类型进行参赛\n",
        "3）允许和鼓励大家用一幅参赛作品衍生其他类型的作品进行创作并参赛\n"
        ]},
        {title:"8、角色设计的的4张（或以上）指的是什么？",content:"角色设计的参赛者需要在赛事主题及个人的创作世界观中创作4张（或以上）单体角色设计图，以及包含这些角色一张阵容图。"},
        {title:"9、作品提交时间？",content:"GGAC赛事作品的最终时间为2020年10月7日23:59;"},
        {title:"10、是否限制渲染器？",content:"不限制，但建议使用Unity、Unreal、Substance和Marmoset或Arnold、VRay、Blender等常规渲染软件。"},
        {title:"11、关于作品署名",content:"作品不要求作者署名，如果想要著名则建议不要影响整体的画面效果。"},
        {title:"12、选手间是否可以联合共用一个设定和故事背景",content:"可以，如果是一个组别的话建议风格区分开。"},
        {title:"13、参加插画美宣的比赛也要求角色和其他元素的设定说明图，是否和角色设计组别冲突？",content:"插画美宣里面的角色设定说明是为了辅助美宣作品介绍世界观的，如果单独拿出里面的元素，比如角色另外做套角色设计也可以参加角色原画的比赛。角色设计考察的是设计能力，而美宣更多关注的是画面的表现力。"},
        {title:"14、视频作品无法播放？",content:[
        "视频暂时只支持编码：h264，可以通过Pr进行转码。步骤\n",
        "https://jingyan.baidu.com/article/597035522a493bcfc00740b3.html"
        ]},
        {title:"15、在G站上传作品之后，是否在别的平台发布",content:"可以，但是需要注意版权问题。"}
]

const items: MenuProps['items'] = [

].map((icon, index) => ({
  key: String(index + 1),
  label: `${index +1}`,


}));

export const Help: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();                       
  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />
      </Sider>
      
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
      <HelpStyle>
        <Header style={{ padding: 0, background: colorBgContainer }}  > <h2 className='headerTitle' style={{fontSize: '2rem'}} >帮助中心</h2> </Header>
      </HelpStyle>
        <Content className='cont' style={{ margin: '24px 16px 0', overflow: 'initial'}}>
        <HelpStyle>
        <div style={{ padding: 24, textAlign: 'center', background: colorBgContainer }}>
                <div>
                <h1 className='Title'>账号注册</h1>
                {accountRegistration.map((item)=>
                        <div style={{ marginTop: '15px'}}>
                          <div className='title'>
                                {item.title}
                          </div>
                          <div className='content'>
                                {item.content}
                          </div>
                        </div>)}
                        <Divider  />
                </div>
                <div>
                <h1 className='Title'>个人中心</h1>
                {personal.map((item)=>
                        <div style={{ marginTop: '15px'}}>
                          <div className='title'>
                                {item.title}
                          </div>
                          <div className='content'>
                                {item.content}
                          </div>
                        </div>)}
                        <Divider  />
                </div>
                <div>
                <h1 className='Title'>报名参赛</h1>
                {signUp.map((item)=>
                        <div style={{ marginTop: '15px'}}>
                          <div className='title'>
                                {item.title}
                          </div>
                          <div className='content' >
                                {item.content}
                          </div>
                        </div>)}
                        <Divider  />
                </div>
                <div>
                <h1 className='Title'>作品</h1>
                {works.map((item)=>
                        <div style={{ marginTop: '15px'}}>
                          <div className='title'>
                                {item.title}
                          </div >
                          
                          <div className='content' >
                                {item.content}
                          </div>
                        </div>)}
                        <Divider  />
                </div>
        </div>
        </HelpStyle>
        </Content>
      </Layout>
    </Layout>
    
  );
};

export default Help;