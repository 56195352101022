import styled from "styled-components";

export const ArticleHeaderStyle = styled.div`
  .header-wrapper {
    padding-top: ${(props) => props.theme.space.paddingLG}px;
    display: flex;
    justify-content: space-between;
    .header-left {
      flex: 1;
      .header-item {
        padding: ${(props) => props.theme.space.paddingXXS}px 0;
        color: ${(props) => props.theme.colors.colorTextSecondary};
        font-size: ${(props) => props.theme.fontSizes.fontSize}px;
      }

      .title-item {
        font-size: ${(props) => props.theme.fontSizes.fontSizeHeading4}px;
        color: ${(props) => props.theme.colors.colorText};
        font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        margin-bottom: ${(props) => props.theme.space.paddingLG}px;
      }

      .time-item {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .interaction-item {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .icon {
            display: flex;
            align-items: center;
            margin-right: ${(props) => props.theme.space.margin}px;
            .data {
              margin-left: ${(props) => props.theme.space.marginXXS}px;
            }
          }
        }
      }
    }
    .header-right {
      flex-basis: 300px;
      display: flex;
      align-items: center;
      margin-left: ${(props) => props.theme.space.marginXS}px;
      .avatar-item {
        position: relative;
        .crown {
          position: absolute;
          top: -15px;
          color: ${(props) => props.theme.colors.colorPrimary};
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }
        .role {
          position: absolute;
          bottom: 5px;
          width: 20px;
          height: 20px;
          background-color: ${(props) => props.theme.colors.colorPrimary};
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 50%;
          right: 0;
          cursor: pointer;
        }
      }
      .meta {
        color: ${(props) => props.theme.colors.colorTextTertiary};
        font-size: ${(props) => props.theme.fontSizes.fontSize}px;
        margin: ${(props) => props.theme.space.marginXS}px 0;
      }
      .userinfo-right {
        display: flex;
        flex-direction: column;
        margin-left: ${(props) => props.theme.space.margin}px;
        .username-item {
          font-size: ${(props) => props.theme.fontSizes.fontSize}px;
          font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        }
        .userinfo-item {
          padding-bottom: ${(props) => props.theme.space.paddingXXS}px;
        }
        .btn-item {
          display: flex;
          .disabled {
            background-color: rgba(0, 0, 0, 0.04) !important;
            color: rgba(0, 0, 0, 0.25) !important;
            border: 1px solid #d9d9d9;
            cursor: not-allowed;
          }
          .focus-btn {
            /* color: ${(props) => props.theme.colors.colorTextLightSolid}; */
            background-color: transparent;
            border: 1px solid ${(props) => props.theme.colors.colorPrimary};
            color: ${(props) => props.theme.colors.colorPrimary};
          }
          .msg-btn {
            margin-left: ${(props) => props.theme.space.marginXS}px;
          }
        }
      }
    }
  }
`;
