import styled from "styled-components";
import { dynamicColumns } from "utils/functions/common";

export const UserArticleStyle = styled.div`

    .list{
        display: grid;
        grid-template-columns: repeat(${dynamicColumns()}, 1fr);
        gap:${(props) => props.theme.space.padding}px;
    }
    .no-list {
      display: block;
    }
`