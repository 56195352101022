import styled from "styled-components";

export const Regtulation2024AboutStyle = styled.div`
    p {
        margin: 10px 0;
    }
    .ggac_container {
        width: 1430px;
        margin: 0 auto;
    }

    .about_wrapper_en {

      .title {
        letter-spacing: 0 !important;
      }

      .judge_card {
        .member {
          height: 1499px !important;
        }
      }

      .match_judge_card {
        .card_right {
          div:last-child {
            padding: 30px 30px 375px !important;
          }
        }
      }
    }

    .about_wrapper {
    padding: 90px 0;
    
  .title {
    position: relative;
    width: 542px;
    height: 90px;
    margin: 0 auto;
    margin-bottom: 80px;
    background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-mobile-rule-title-line.png")
      no-repeat center bottom;
    font-size: 40px;
    font-family: zyjx-song;
    letter-spacing: 10px;
    text-align: center;
  }
  .text {
    div {
      text-align: center;
    }
    .cut {
      margin-bottom: 20px;
    }
  }
  p {
    line-height: 25px;
  }

  // 关于大赛
  .about_match {
    position: relative;
    font-size: 16px;
    padding-bottom: 80px;
    .about_match_bg {
      left: 50%;
      top: 46%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
    .about_match_leader {
      margin: 60px 0 20px 0;
      text-align: center;
      color: ${props => props.theme.colors.colorPrimary};
      img {
        margin-bottom: 20px;
        width: 150px;
      }
    }
  }
  .card_title {
    font-size: 18px;
    color: $theme-color;
    border-bottom: 1px solid ${props => props.theme.colors.colorPrimary};
    width: max-content;
    text-align: center;
    padding: 10px 0;
    margin: 0 auto;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.colorPrimary};
  }
  .judge_card {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    padding: 30px;
    background: #fff;
    margin-top: 40px;

    .top-cnt {
      display: flex;
      flex-direction: column;
      height: 856px;
      flex-wrap: wrap;

      p {
        width: 33%;
      }
      span {
        display: inline-block;
        min-width: 35px;
        margin-right: 10px;
        line-height: 25px;
        flex-shrink: 0;
      }
    }
  }

    .member {
        p {
            margin: 0;
        }
    }

  // 大赛评委
  .match_Judge {
    padding: 60px 0 100px 0;
    .match_judge_card {
      display: flex;
      align-items: flex-start;

      .blod {
        font-weight: 600;
        text-align: center;
      }
      p {
        line-height: 24px;
        span {
          display: inline-block;
          min-width: 60px;
        }
      }
      .card_left {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        border: 1px solid #e0e0e0;
        padding: 30px 30px 20px;
        background: #fff;
        width: 50%;
        margin-right: 40px;
        .top_cnt {
          div {
            margin-bottom: 37px;
          }
          .blod {
            margin-bottom: 10px;
          }
        }
      }
      .card_right {
        width: 50%;
        height: 916px;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
          border: 1px solid #e0e0e0;
          padding: 30px;
          background: #fff;
          flex: 1;
          width: 100%;

          &:first-child {
            margin-bottom: 26px;
          }

          &:last-child {
            padding: 30px 30px 256px 30px ;
          }
        
        }
      }
    }
  }
  // 合作单位
  .work_together {
    padding: 60px 0 0 0;
    .work_list {
        .sub-title {
            color: ${props => props.theme.colors.colorPrimary};
        }
      .work_list_item {
        margin-bottom: 40px;
        text-align: center;
        a {
          color: rgba(0, 0, 0, 0.88);
        }
        p {
          color: $theme-color;
          margin-bottom: 20px;
        }
        div {
          line-height: 24px;
        }
      }
    }
  }
}
`