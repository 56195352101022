import styled from "styled-components";

export const UserCertificatesStyle = styled.div`
.certificates {
    padding: ${props => props.theme.space.paddingLG}px;
    .title {
        font-size: 16px;
        padding-bottom: ${props => props.theme.space.margin}px;
    }
    .list {
        display: grid;
        /* --auto-grid-min-size: 13rem; */
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
    }
    .no-data {
        margin-top: 200px;
    }
}
`