import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { VideoContentDisplayStyle } from "./style";
import Player from "griffith";

type Props = {
  url: string;
  cover?: string;
  from?: string,
  originalUrl?: string
};

const VideoContentDisplay = forwardRef(({ url, cover, from, originalUrl }: Props, ref: any) => {
  const [videoRatio, setVideoRatio] = useState(1);
  const video = document.createElement('video');
  const [currentVideo, setCurrentVideo] = useState(url)
  const [isLoadOriginalUrl, setIsLoadOriginalUrl] = useState(false)
  const getVideoDimensions = (url: string) => {

    video.addEventListener('loadedmetadata', function () {
      const width = this.videoWidth;
      const height = this.videoHeight;

      setVideoRatio(height / width);
    });

    video.src = url;

    // Load the video to trigger the 'loadedmetadata' event
    video.load();
  }

  useImperativeHandle(ref, () => ({
    pauseVideo: () => {
      const videoList: HTMLCollection = document.getElementsByTagName('video');
      if (videoList.length > 1) {
        [...videoList].forEach(item => {
          // item.currentTime = 0
          // item && item.pause()
          console.log(video)
        })
      }
    }

  }));



  useEffect(() => {

    if (!url) {
      originalUrl && setCurrentVideo(originalUrl)
      originalUrl && getVideoDimensions(originalUrl)
      setIsLoadOriginalUrl(true)
    } else {
      setCurrentVideo(url)
      getVideoDimensions(url)
      setIsLoadOriginalUrl(false)
    }

  }, [url, originalUrl])

  const checkoutVideo = () => {
    if (!originalUrl) {
      return
    }
    setCurrentVideo(originalUrl)
    getVideoDimensions(originalUrl)
    setIsLoadOriginalUrl(true)
  }

  function isImageUrl(url: string) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];

    const fileExtension = url.slice(url.lastIndexOf('.')).toLowerCase();
    return imageExtensions.includes(fileExtension);
  }

  return (
    <VideoContentDisplayStyle>
      {<div className="video-wrap">
        {
          !!originalUrl && !!url && <>
            {
              !isLoadOriginalUrl && <div className="tag" onClick={checkoutVideo}>加载高清视频</div>
            }
            {
              isLoadOriginalUrl && <div className="tag">高清视频已加载</div>
            }
          </>
        }
        <Player
          shouldObserveResize={true}
          cover={isImageUrl(cover!) ? cover : undefined}
          locale="zh-Hans"
          sources={{
            sd: {
              play_url: currentVideo || url,
            },
          }}
          loop={!!(from === 'ly3d')}
          autoplay={!!(from === 'ly3d')}
          id={"player"}
          initialObjectFit="contain"
        />
      </div>}

    </VideoContentDisplayStyle>
  );
})


export default VideoContentDisplay;
