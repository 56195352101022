import styled from "styled-components";

export const RegulationPopupStyle = styled.div`
    
    .content {
        padding-top: 160px;
        padding-right: 50px;
        position: relative;
        .close {
            width: 30px;
            cursor: pointer;
        }

        .close-wrap {
            margin-top: 60px;
            text-align: center;
        }
    }
    .popup-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        &-item {
            width: calc(208px / 1.3);
            height: calc(58px / 1.3);
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-btn.png") center center no-repeat;
            background-size: 100% 100%; 
            font-family: 'REEJI';
            font-size: 22px;
	        letter-spacing: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
	        color: #ffffff;
            cursor: pointer;
        }
        margin-bottom: 20px;
        &-item:hover,.popup-tab-active {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-btn-active.png") center center no-repeat;
            background-size: 100% 100%;  
        }
    }

    .popup-title {
        text-align: center;
        font-size: 18px;
        font-family: kuaikanshijieti;
        color: #9fe0ff;
        position: relative;
        width: 229px;
        text-align: center;
        margin: 10px auto;
        &::after {
            content: "";
            position: absolute;
            width: 229px;
            height: 12px;
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-title.png") center center no-repeat;
            background-size: 100% 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .scroll-content {
        height: 300px;
        overflow-y: auto;
        padding: 0 20px;
        &>div:first-child {
            .popup-desc {
                text-align: center;
            }
        }
        ::-webkit-scrollbar {
            width: 10px; /* 设置滚动条的宽度 */
            background-color: transparent; /* 滚动条的背景色 */
        }
        ::-webkit-scrollbar-track {
          background: #e1e1e1; /* 轨道的背景色 */
          border-radius: 10px; /* 轨道的圆角 */
        }
        /* 自定义滚动条的滑块（thumb） */
        ::-webkit-scrollbar-thumb {
          background-color: #c1c1c1; /* 滑块的背景色 */
          border-radius: 10px; /* 滑块的圆角 */
          border: 2px solid #ffffff; /* 滑块边框 */
        }

        /* 滑块在悬停或者点击时的样式 */
        ::-webkit-scrollbar-thumb:hover {
          background: #a8a8a8; /* 悬停时滑块的背景色 */
        }

        ::-webkit-scrollbar-thumb:active {
          background-color: #919191; /* 点击时滑块的背景色 */
        }
        .top {
            display: flex;
            color: #fff;
            justify-content: space-between;
            align-items: center;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 25px;
            span {
                margin-left: 10px;
                color: ${props => props.theme.colors.colorPrimary};
                cursor: pointer;
            }
        }
    }

    .popup-desc-1 {
        text-align: center;
    }

    .popup-desc,.popup-tip {
        color: #ffffff;
        line-height: 30px;
    }
`