import { WorkDetailType } from "types/work";

export const findAchiveContestPath =(detail:WorkDetailType)=>{
    switch (detail.contestWork?.contestId){
        case 35:
            window.open("/v2/regulation/work");
            break;
        case 34:
            window.open("/v2/2021/regulation");
            break;
        case 33:
            window.open("https://achive.ggac.com/2020/regulation"); 
            break;
        case 32:
            window.open("https://achive.ggac.com/2019/regulation");  
            break;
        case 31:
            window.open("https://achive.ggac.com/2018/regulation");  
            break;  
            
        default:
            window.open(`/v2/sub/${detail.contestWork?.contestCode}/work` )   
    }
}