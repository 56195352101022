import styled from "styled-components";

export const PreSubmitModalStyle = styled.div`
.modal {
    .under_line{
      padding-bottom: ${props => props.theme.space.marginLG}px;
      border-bottom: 1px solid ${props => props.theme.colors.colorBorderSecondary};
    }
    .item{
        display: flex;
        margin-bottom: 15px;
        gap: 10px;
        align-items: center;
        .label {
            color: #8A8A8B;
            font-size: 14px;
            min-width: 112px;
        }
        .value{
            display: flex;
            align-items: center;
            gap: ${props => props.theme.space.margin}px;
            .active {
                border: 1px solid #FF9A16;
                .name {
                    color: #FF9A16 !important;
                }
            }
            .sku-item {
                display: flex;
                background: #F1F1F3;
                display: flex;
                align-items: center;
                padding: 2px;
                cursor: pointer;
                border-radius: 6px;
                .cover {
                    width: 28px;
                    height: 28px;
                    border-radius: 4px;
                    background-size: cover;
                }
                .name {
                    padding: 0 10px;
                    font-size: 14px;
                    color: #202020;
                }
            }
            .pay {
                display: flex;
                &-item {
                  width: 150px;
                  height: 55px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: solid 1px #e0e0e0;
                  margin-right: 40px;
                  cursor: pointer;
                  border-radius: ${props => props.theme.border.borderRadius}px;
                  gap: 10px;
                  &:last-child {
                    margin-right: 0;
                  }
                  .type {
                    width: 36px;
                    height: 36px;
                    border-radius: 6px;
                    margin-right: 11px;
                  }
                  div {
                    font-size: 16px;
                    color: #2b2e36;
                  }
                }
                .active {
                  border: solid 1px #f4aa2f;
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    background: url("https://cdn.ggac.com/img/copyright-pay-active.png")
                      center center no-repeat;
                    background-size: 100% 100%;
                  }
                }
            }
            .no-address {
                span {
                    cursor: pointer;
                    color: ${props => props.theme.colors.colorPrimary};
                }
            }
            .address{
              .address-item{
                margin-top: 10px;
                display: flex;
                max-width: 400px;
                .desc {
                  color: #8A8A8B !important;
                  word-break: break-all;
                }
                .receiver{
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  .right {
                    display: flex;
                    gap: 15px;
                  }
                  .tag{
                    color: ${props => props.theme.colors.colorTextSecondary};
                    font-size: ${props => props.theme.fontSizes.fontSizeSM}px;
                    cursor: pointer;
                  }
                  .yellow {
                    color: ${props => props.theme.colors.colorPrimary};
                  }
                  .color {
                    font-weight: bold;
                  }
                }
              }
            }
            .add-address {
              border: 1px solid ${props => props.theme.colors.colorPrimary};
              padding: 4px 0;
              width: 100px;
              text-align: center;
              border-radius: 50px;
              margin-top: 10px;
              color: ${props => props.theme.colors.colorPrimary};
              cursor: pointer;
            }
        }
    }
}
    
`