import { MediaType } from "types/enums/media-type";
import { WorkDetailMedieListItem } from "types/work";

export function isImgEfx(mediaItem: WorkDetailMedieListItem) {
  if (
    mediaItem.type === MediaType.image &&
    mediaItem.params?.specialEffectTemplateId &&
    mediaItem.params?.specialEffectVideo
  ) {
    return true;
  }
  return false;
}

export function isImg(mediaItem: WorkDetailMedieListItem) {
  if (
    mediaItem.type === MediaType.image &&
    (!mediaItem.params?.specialEffectTemplateId ||
      !mediaItem.params?.specialEffectVideo)
  ) {
    return true;
  }
  return false;
}

export function isVideo(mediaItem: WorkDetailMedieListItem) {
  if (mediaItem.type === MediaType.video) {
    return true;
  }
  return false;
}

export function isThreedGlb(mediaItem: WorkDetailMedieListItem) {
 
  if (mediaItem.type === MediaType.threed &&
    mediaItem.mimeType === "") {
    return true;
  }
  return false;
}

export function isThreedMview(mediaItem: WorkDetailMedieListItem) {
  if (
    mediaItem.mimeType === "model/mview"
  ) {
    return true;
  }
  return false;
}


export function isWorkAudio(mediaItem: WorkDetailMedieListItem) {
  console.log(mediaItem.mark === "musicFile")
  if (
    mediaItem.mark === "musicFile"
  ) {
    return true;
  }
  return false;
}


export function reorderMediaList(list:WorkDetailMedieListItem[]){    
    let keys = ['effectVideo','effectImage','draftImage','textureImage','threeDImage','processImage','detailSupplementImage','regular']
   
    let videos = list.filter(item=>item.type ===2)
    let others = list.filter(item=>item.type !==2)
    let newArray:any = [];

     for (let i = 0; i < keys.length; i++) {
      let a = others.filter(item => {
        return keys[i] === item.mark
      });
      newArray = [...newArray,...a]
     }

     const untaged = others.filter((element) => !newArray.includes(element));

    return [...videos,...newArray,...untaged]
}



export function translateContestMediaMark(mediaItem: WorkDetailMedieListItem) {
  switch (mediaItem.mark) {
    case "draftImage":
      return "概念草图";
    case "detailSupplementImage":
      return "细节补充图";
    case "processImage":
      return "过程图";
    case "textureImage":
      return "材质展示图";
    case "threeDImage":
      return "3D展示图";
    case "effectImage":
      return "最终效果图";
    case "effectVideo":
      return "最终效果视频";
      case "moreDetail":
    return "细节补充";  
    default:
      break;
  }
}
