import { Button, Form, Input, message, Radio, Select } from "antd"
import { BizFormStyle } from "./style"
import { apiGetDictList, postBusinessContact } from "api/common";
import { DictItem } from "types/common";
import { useEffect, useState } from "react";

const BizForm = () => {
    const [form] = Form.useForm<any>();
    const [professionList, setProfessionList] = useState<DictItem[]>()
    const [cooperationType, setCooperationType] = useState<DictItem[]>()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const budgetList = [
        { name: "20万以内", value: "20万以内", id: 1 },
        { name: "20万~50万", value: "20万~50万", id: 2 },
        { name: "50万~80万", value: "50万~80万", id: 3 },
        { name: "80万以上", value: "80万以上", id: 4 }
    ]

    const submit = () => {
        form.validateFields().then(res => {
            setSubmitLoading(true)
            postBusinessContact({ ...res }).then(() => {
                message.success("提交成功")
                setIsSubmit(true)
            }).finally(() => {
                setSubmitLoading(false)
            })
        })
    }

    useEffect(() => {
        apiGetDictList({ type: "bizContactProfession" }).then(res => {
            setProfessionList(res)
        })
        apiGetDictList({ type: "bizContactType" }).then(res => {
            setCooperationType(res)
        })
    }, [])
    return <BizFormStyle>
        {/* <img src="https://cdn-prd.ggac.com/ggac/common/biz-word1.png" className="word1" /> */}
        <div className="word1">
            <div>和GGAC</div>
            <div>一起共创</div>
            <div className="sub">
                <div>办比赛</div>
                <div>办活动</div>
                <div>定制创作</div>
                <div>找艺术家</div>
            </div>
        </div>
        <img src="https://cdn-prd.ggac.com/ggac/common/biz-word2.png" className="word2" />
        <Form form={form} className="form">
            <div className="title">商务合作</div>
            <div className="title2">办比赛 / 办活动 / 定制创作 / 找艺术家</div>
            <div className="flex">
                <Form.Item rules={[
                    {
                        required: true,
                        message: "请输入姓名",
                    }
                ]} name="name" label="姓名">
                    <Input placeholder="请输入姓名"></Input>
                </Form.Item>
                <Form.Item className="right" rules={[
                    {
                        required: true,
                        message: "请输入电话",
                    },
                    {
                        pattern: /^[1][3,4,5,6.7,8,9][0-9]{9}$/,
                        message: "手机号格式有误"
                    }
                ]} name="phone" label="电话">
                    <Input placeholder="请输入电话"></Input>
                </Form.Item>
            </div>
            <div className="flex">
                <Form.Item name="wx" label="微信" rules={[
                    {
                        required: true,
                        message: "请输入微信联系账号",
                    },
                ]}>
                    <Input placeholder="请输入微信"></Input>
                </Form.Item>
                <Form.Item name="email" className="right" label="邮箱" rules={[

                    {
                        pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z]{2,5}$/,
                        message: "邮箱格式有误"
                    }
                ]}>
                    <Input placeholder="请输入邮箱"></Input>
                </Form.Item>
            </div>

            <Form.Item rules={[
                {
                    required: true,
                    message: "公司全称",
                }
            ]} name="companyName" label="公司全称">
                <Input placeholder="请输入公司全称"></Input>
            </Form.Item>
            <Form.Item name="companyAddress" label="公司地址">
                <Input placeholder="请输入公司地址"></Input>
            </Form.Item>

            <Form.Item name="profession" rules={[
                {
                    required: true,
                    message: "请选择所属行业",
                }
            ]} label="所属行业">
                <Select
                    options={professionList}
                    fieldNames={{ label: 'name', value: 'code' }}
                    placeholder="请输入所属行业"
                />
            </Form.Item>
            <Form.Item name="type" rules={[
                {
                    required: true,
                    message: "请选择合作类型",
                }
            ]} label="合作类型">
                <Select
                    options={cooperationType}
                    fieldNames={{ label: 'name', value: 'name' }}
                    placeholder="请输入合作类型"
                />
            </Form.Item>
            <Form.Item name="budget" rules={[
                {
                    required: true,
                    message: "请输入共创预算",
                }
            ]} label="共创预算">
                <Radio.Group name="radiogroup" className="radio">
                    {
                        budgetList.map(item => {
                            return <div className="radio-item" key={item.id}>
                                <Radio value={item.name}>{item.name}</Radio>
                            </div>
                        })
                    }
                </Radio.Group>
            </Form.Item>
            <Form.Item name="projectNeed" rules={[
                {
                    required: true,
                    message: "请输入项目需求",
                }
            ]} label="项目需求">
                <Input.TextArea showCount placeholder="请输入项目需求"></Input.TextArea>
            </Form.Item>

            <div className="btn" style={{ textAlign: 'center' }}>
                {
                    !isSubmit && <Button onClick={submit} type="primary" loading={submitLoading} block>提交</Button>
                }
                {
                    !!isSubmit && <Button type="primary" block>已提交</Button>
                }
            </div>
            <div className="tip">请填写表单，我们会尽快与您联系沟通合作细节</div>
        </Form>
    </BizFormStyle>
}

export default BizForm