import { useEffect, useState } from 'react'
import { HotTagsStyle } from './style'
import { TagHotListItem } from 'types/common'
import UiTag from 'ui-lib/tag'
import { apiGetGlobalHotTagRank } from 'api/common'

function HotTags() {
  const [tags, setTags] = useState<TagHotListItem[]>([])

  useEffect(() => {
    apiGetGlobalHotTagRank({pageNumber:1,pageSize:10,needTotal:1,type:1}).then((res:any) => {
        setTags(res)
    })
  },[])
  return (
    <HotTagsStyle>
        <div className='title'>热门标签</div>
        <div className='tags'>
            {
                tags.map((item,index)=>{
                    return <UiTag clickable theme="yellow-3" key={index} onClick={()=>{window.location.href =`/forum/question/search?tagName=${item.name}`}}>{item.name}</UiTag>
                })
            }
        </div>
    </HotTagsStyle>
  )
}

export default HotTags

