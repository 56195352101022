import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
import { BottomGgStyle } from "./style";
import BusinessContact from "components/business-contact";
import { useState } from "react";

function BottomGg() {
  const [isShowBusiness, setIsShowBusiness] = useState(false)
  const onClose = () => {
    setIsShowBusiness(false)
  }
  return (
    <BottomGgStyle>
      <div className="container_">
        <div className="wrap">
            <GuanggaoBannerCarousel positionCode={2}></GuanggaoBannerCarousel>
        </div>  
        
      </div>
      <BusinessContact onClose={onClose} isShow={isShowBusiness} />
    </BottomGgStyle>
  );
}

export default BottomGg;
