import {
  RiWechatFill,
  RiMessage2Fill,
  RiLockFill,
  RiQqFill,
  RiArrowLeftSLine,
  RiMailFill
} from "react-icons/ri";
import {
  Modal,
  Space,
  Button,
  Checkbox,
} from "antd";
import { useEffect, useState, createContext } from "react";
import LoginFormPwd from "./LoginFormPwd";
import LoginFormSms from "./LoginFormSms";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WexinLogin } from "./weixin-login";
import { QQLogin } from "./qq-login";
import { queryUrl, randomNum } from "utils/functions/common";
import LoginFormEmail from "./emailForm";

export const AgreementContext = createContext(false);

function Login() {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams();
  const stage = searchParams.get("stage");
  const [modalTitle, setModalTitle] = useState('隐私政策')
  const [wordUrl, setWordUrl] = useState('https://pre-cdn-hz.ggac.com/docx/policy.html')
  const bg = `https://cdn-prd.ggac.com/ggac/assets/image/login/${randomNum(1, 20)}.jpg`
  const [wordModalShow, setWordModalShow] = useState(false)
  const [isAgreed, setIsAgreed] = useState(false)
  const onClickOpenWordModal = (url: string, title: string) => {
    setWordUrl(url)
    setModalTitle(title)
    setWordModalShow(true)
  }

  const redirect = new URLSearchParams(window.location.search).get(
    "redirect"
  );

  const loginType = new URLSearchParams(window.location.search).get(
    "login-type"
  ) || "1"

  useEffect(() => {

    if (Number(loginType) === 4) {
      searchParams.delete('login-type')
      onClickQQLogin()
    } else {
      setCurrentLoginMethod(Number(loginType))
    }
  }, [loginType])



  //1 密码 2 微信 3 短信 4 QQ
  const [currentLoginMethod, setCurrentLoginMethod] = useState(1);

  useEffect(() => {
    if (stage === 'weixin') {
      setCurrentLoginMethod(2)
    } else {
      if (stage === 'qq') {
        setCurrentLoginMethod(4)
      }
    }
  }, [stage])

  const getMethodText = () => {
    switch (currentLoginMethod) {
      case 1:
        return "密码";

      case 2:
        return "微信";

      case 3:
        return "短信";

      case 4:
        return "QQ";
      case 5:
        return "邮箱";
      default:
        break;
    }
  };

  const onClickQQLogin = () => {
    // setSearchParams('');
    searchParams.delete('stage')
    // var location = document.location
    let url
    if (redirect) {
      url = encodeURIComponent(`https://www.ggac.com/auth/login?stage=qq&redirect=${redirect}`)
    } else {
      url = encodeURIComponent(`https://www.ggac.com/auth/login?stage=qq`)
    }
    window.location.href = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=101955030&redirect_uri=${url}&state=989735`
  }

  useEffect(() => {
    if (localStorage.getItem('auth_token')) {
      navigate('/user-center/home')
    }
  }, [navigate])

  const toLogin = () => {
    navigate(`/auth/register?redirect=${redirect}`)
  }


  const resetSearchParams = () => {
    const channelCode = searchParams.get('channelCode')
    if (channelCode) {
      localStorage.setItem('channelCode', channelCode)
    }
    searchParams.delete('stage')
  }
  return (
    <div className="formContainer" id="login-form-container">
      <div className="meta">
        <span className="back" onClick={() => navigate('/')}><RiArrowLeftSLine></RiArrowLeftSLine> 返回首页</span>  还没有账号？立即<span className="em" onClick={toLogin}>注册</span>
      </div>
      <div className="left">
        <div className="title">{getMethodText()}登录 GGAC</div>
        <AgreementContext.Provider value={isAgreed}>
          {currentLoginMethod === 1 && <LoginFormPwd></LoginFormPwd>}
          {currentLoginMethod === 2 && (<WexinLogin></WexinLogin>)}
          {currentLoginMethod === 3 && <LoginFormSms></LoginFormSms>}
          {currentLoginMethod === 4 && <QQLogin></QQLogin>}
          {currentLoginMethod === 5 && <LoginFormEmail />}
        </AgreementContext.Provider>
        <Checkbox checked={isAgreed} onChange={(value) => setIsAgreed(value.target.checked)}>
          <div className="userAgreement">
            <Space  >
              登录或注册即代表同意<span className="Agreement" onClick={() => onClickOpenWordModal('https://cdn-prd.ggac.com/ggac/assets/html/ggac-user-agreement.html', 'GGAC用户协议及隐私权政策')}>用户协议</span>和<span onClick={() => onClickOpenWordModal('https://cdn-prd.ggac.com/ggac/assets/html/ggac-user-agreement.html', 'GGAC用户协议及隐私权政策')}>隐私协议</span>
            </Space>
          </div>
          <Modal
            centered
            onCancel={() => { setWordModalShow(false) }}
            width="820px"
            open={wordModalShow}
            title={modalTitle}
            footer={<Button type="primary" onClick={() => {
              setWordModalShow(false)
            }}>确认</Button>}
          >
            <div>
              <iframe style={{ width: '750px', height: '700px', border: 'none', marginTop: '10px', marginLeft: '10px' }} src={wordUrl} title="用户协议"></iframe>
            </div>

          </Modal>
        </Checkbox>

        <div className="divider div-transparent">
          <span className="text">更多登录方式</span>
        </div>

        {currentLoginMethod !== 4 && (
          <div className="bar" onClick={onClickQQLogin}>
            <RiQqFill className="icon" size={20} /> <span >QQ登录</span>{" "}
          </div>
        )}
        {currentLoginMethod !== 2 && (
          <div className="bar" onClick={() => { resetSearchParams(); setCurrentLoginMethod(2) }}>
            <RiWechatFill className="icon" size={20} /> <span>微信登录</span>{" "}
          </div>
        )}
        {currentLoginMethod !== 5 && (
          <div className="bar" onClick={() => { resetSearchParams(); setCurrentLoginMethod(5) }}>
            <RiMailFill className="icon" size={18} />
            <span>邮箱登录</span>
          </div>
        )}
        {currentLoginMethod !== 3 && (
          <div className="bar" onClick={() => { resetSearchParams(); setCurrentLoginMethod(3) }}>
            <RiMessage2Fill className="icon" size={18} />
            <span>短信登录</span>
          </div>
        )}
        {currentLoginMethod !== 1 && (
          <div className="bar" onClick={() => { resetSearchParams(); setCurrentLoginMethod(1) }}>
            <RiLockFill className="icon" size={18} />
            <span>密码登录</span>
          </div>
        )}
      </div>
      <div className="right">
        <img src={bg} alt="" className="skew" />
        {/* <div className="skew" style={{backgroundImage:`url(${bg})`}}></div> */}
      </div>
    </div>
  );
}

export default Login;
