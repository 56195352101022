import { Modal, Input, message } from "antd";
import { ScoreFlowDetail } from "types/contest";
import { ModalStyle } from "./style";
import { useEffect, useState } from "react";
import ScoreWidget from "./score-widget";
import { apiCreateWorkScore, apiGetWorkScoreDetail } from "api/contest";
const { TextArea } = Input;

type Props = {
  openModal: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  workId: number;
  taskId: number;
  workCoverUrl: string;
  workTitle: string;
  mediaCategory:number
};
function ScoreModal({ openModal, handleCancel, workId,taskId,workCoverUrl,workTitle,mediaCategory}: Props) {
  const [scoreItem, setScoreItem] = useState<ScoreFlowDetail>();
  const [scoreParams, setScoreParams] = useState<any>({
    skillScore: scoreItem?.skillScore || 0.1, //技法表现分
    qualityScore: scoreItem?.qualityScore || 0.1, //完成品质分
    styleScore: scoreItem?.styleScore || 0.1, //风格亮点分
    ideaScore: scoreItem?.ideaScore || 0.1, //概念想法分
    remark: scoreItem?.remark || "", //评语
    taskId: taskId, //任务ID
    workId: workId, //作品ID
  });

  useEffect(() => {
    apiGetWorkScoreDetail({taskId: taskId, workId: workId,mediaCategory:mediaCategory}).then((res)=>{
      let item = res
      if(!item) return 
      setScoreItem(res)
      if (item.averageScore) {
        setScoreParams({
          skillScore: item.skillScore,
          qualityScore: item.qualityScore,
          styleScore: item.styleScore,
          ideaScore: item.ideaScore,
          remark: item.remark,
          taskId: taskId,
          workId: workId,
        });
      }
    })
  
  }, [openModal]);

  const onCancel = () => {
    Modal.destroyAll();
    handleCancel();
  };

  const instruction = () => {
    return (
      <div className="instruction">
        <p>说明：</p>
        <p>1.评委老师请从四个维度分别评分,作品得分为四维度平均分。</p>
        <p>2.每维度总分为10分,可选择的最低分数单位为0.1分。</p>
        <p>3.可直接拖动评分条进行评分,也可在输入框内手动输入评分，</p>
        <p>
          4.作品评分后,均可再次修改,评验老师可根据作品排名情况再次修改评分。
        </p>
      </div>
    );
  };

  const handleScoreUpdate = (key_: string, value: number | string) => {
    if (!key_) return;
    // console.log(key_, value);
    scoreParams[key_] = value;
    // console.log(scoreParams)
    setScoreParams({ ...scoreParams });
  };

  const createScore = () => {
    apiCreateWorkScore(scoreParams).then((res) => {
      message.success("评分保存成功！");
      handleCancel();
    });
  };

  return (
    <Modal
      title="作品打分"
      open={openModal}
      onOk={createScore}
      onCancel={onCancel}
      width={800}
      okText="保存"
      destroyOnClose //关闭时销毁
    >
      <ModalStyle className="modal-style">
        {instruction()}
        <div className="container">
          <div className="left">
            <a
              href={"/work/detail/" + workId}
              target={"_blank"}
              rel="noreferrer"
            >
              <div
                className="cover"
                style={{ backgroundImage: `url(${workCoverUrl})` }}
              ></div>
              <div className="name overflow-hidden">{workTitle}</div>
            </a>
          </div>

          <div className="right">
            <div className="tip">
              拖动下方进度条进行打分 <span>(最低分数0.1分)</span>
            </div>
            <div className="score-widget">
              <ScoreWidget
                name="造型材质"
                key_="ideaScore"
                inputValue={scoreParams.ideaScore}
                onUpdate={handleScoreUpdate}
              ></ScoreWidget>
            </div>
            <div className="score-widget">
              <ScoreWidget
                name="风格亮点"
                key_="skillScore"
                inputValue={scoreParams.skillScore}
                onUpdate={handleScoreUpdate}
              ></ScoreWidget>
            </div>
            <div className="score-widget">
              <ScoreWidget
                name="应用拓展"
                key_="styleScore"
                inputValue={scoreParams.styleScore}
                onUpdate={handleScoreUpdate}
              ></ScoreWidget>
            </div>
            <div className="score-widget">
              <ScoreWidget
                name="完成品质"
                key_="qualityScore"
                inputValue={scoreParams.qualityScore}
                onUpdate={handleScoreUpdate}
              ></ScoreWidget>
            </div>
            <div className="review">
              <div className="label">评委寄语</div>
              <div className="content">
                <TextArea
                  rows={3}
                  style={{ width: 410 }}
                  placeholder="请输入评委寄语"
                  maxLength={500}
                  showCount
                  onChange={(e) => handleScoreUpdate("remark", e.target.value)}
                  value={scoreParams.remark}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalStyle>
    </Modal>
  );
}

export default ScoreModal;
