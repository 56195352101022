import styled from "styled-components";

export const ToBeAnswererStyle = styled.div`
    .ant-input-number {
        width: 200px;
    }
    .price-tip {
        padding-top: 5px;
        margin-left: 5px;
    }
    .agreement {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn {
        margin-top: 30px;
        text-align: center;
        button {
            padding:  0 50px;
        }
    }

    .briefIntroduction {
        label {
            padding-left: 10px;
        }
    }

    textarea  {
        overflow: hidden; /* 禁止下拉 */
        resize: none; /* 禁止调整大小 */
        height: 100px;
    }
`