import { useEffect, useState } from "react";
import { MyBadgesStyle } from "./style";
import { BadgeItem } from "types/user/badge";
import { apiGetUserBadges } from "api/user/badge";
import { getLocalUserInfo } from "utils/functions/common";
import BadgeDialog from "./badge-modal";
import BadgeListItems from "./badge-list-item";

function MyBadges() {
  const [growthBadges, setGrowthBadges] = useState<BadgeItem[]>([]);
  const [achievementBadges, setAchievementBadges] = useState<BadgeItem[]>([]);
  const [specialBadges, setSpecialBadges] = useState<BadgeItem[]>([]);
  const [contestBadges, setContestBadges] = useState<BadgeItem[]>([]);
  const [eventBadges, setEventBadges] = useState<BadgeItem[]>([]);

  let userInfo = getLocalUserInfo();

  useEffect(() => {
    userInfo &&
      apiGetUserBadges({ userId: userInfo.id }).then((res) => {
        setGrowthBadges(res.filter((item) => item.type === 1));
        setAchievementBadges(res.filter((item) => item.type === 2));
        setSpecialBadges(
          res.filter((item) => item.type === 3 && item.isActivated > 0)
        );
        setContestBadges(
          res.filter((item) => item.type === 4 && item.isActivated > 0)
        );
        setEventBadges(
          res.filter((item) => item.type === 5 && item.isActivated > 0)
        );
      });
  }, []);

  return (
    <MyBadgesStyle>
      <BadgeDialog></BadgeDialog>
      <div className="section-block yellow">
        <div className="section-title">个人成长徽章</div>
        <div className="section-content">{BadgeListItems(growthBadges)}</div>
      </div>
      <div className="section-block yellow">
        <div className="section-title">个人成就徽章</div>
        <div className="section-content">
          {BadgeListItems(achievementBadges)}
        </div>
      </div>
      {specialBadges.length > 0 && (
        <div className="section-block yellow">
          <div className="section-title">特别徽章</div>
          <div className="section-content">{BadgeListItems(specialBadges)}</div>
        </div>
      )}
      {contestBadges.length > 0 && (
        <div className="section-block yellow">
          <div className="section-title">比赛徽章</div>
          <div className="section-content">{BadgeListItems(contestBadges)}</div>
        </div>
      )}
      {eventBadges.length > 0 && (
        <div className="section-block yellow">
          <div className="section-title">活动徽章</div>
          <div className="section-content">{BadgeListItems(eventBadges)}</div>
        </div>
      )}

    </MyBadgesStyle>
  );
}

export default MyBadges;
