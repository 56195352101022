import styled from "styled-components";

export const CropImgStyle = styled.div`
    .container{
        
    }
    .upload-icon-text{
        color:${props=> props.theme.colors.colorTextTertiary};
        .icon{
            color:${props=> props.theme.colors.colorBlack3Ggac};
            font-size: ${props=> props.theme.fontSizes.fontSizeLG*1.5}px;
        }
    }
`