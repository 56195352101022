import { useEffect, useState } from "react"
import { CollaborativeCreatorsStyle } from "./style"
import { AllUserCreatorListItem } from "types/user/work"
import { useParams } from "react-router-dom"
import { apiGetWorkCreatorList } from "api/work"
import { Avatar, Button, Tooltip, message } from "antd"
import { findUserRole } from "utils/functions/common"
import { postFollowUser } from "api/user"


function CollaborativeCreators({type=1}:{type?:number}) {
  const [list, setList] = useState<AllUserCreatorListItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentUserId,setCurrentUserId]=useState<number>()
  const {id}= useParams()

  useEffect(() => {
    getList()
  },[])

  const getList = ()=>{
    apiGetWorkCreatorList({postId:Number(id),type}).then((res)=>{
      setList(res)
    })
  }

  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
        return "yellow";
      case 3:
        return "blue";
      default:
        return "hide";
    }
  };

    // 关注用户
    const followUser = (user: AllUserCreatorListItem) => {
        if (user) {
          setIsLoading(true)
          const flag = user.isFollow === 1 ? 0 : 1
          postFollowUser(
            {
              followee: user.id,
              flag
            }
          ).then(() => {
            user.isFollow = flag
            message.success("操作成功")
          }).finally(() => {
            setIsLoading(false)
          })
        }
    
      }
    
  return (
    <CollaborativeCreatorsStyle className="CollaborativeCreatorsStyle">
        <div className="header">创作团队</div>
        <div className="list">
        {list && list.map((item,index)=>{
            return <div className="list-item" key={index} onClick={() => window.open(`/user/${item.userId}`)}>
                <div className="avatar">
                <Avatar src={item.avatarUrl} size={48}></Avatar>
                {
              item?.certifiedRoleId && <Tooltip title={findUserRole(item.certifiedRoleId)?.name} placement='bottom'>
                <div className={`role ${renderColor(
                  item.certifiedRoleId
                )}`}>
                  V
                </div>
              </Tooltip>
            }
                </div>
                <div className="username overflow-hidden" title={item.username}>{item.username}</div>
                <div className="usertype">{item.role === 1? '创作者': item.role === 2? '参与人': item.role === 3?'导师':"原作者"}</div>
                <Button size="small" type="primary" className={`btn ${item?.isFollow === 1 ? 'active' : ''}`} loading={currentUserId===item.id && isLoading} onClick={(e) => 
                 {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentUserId(item.id);
                    followUser(item)
                 }
                   }>
                {
                  item?.isFollow === 1 ? '已关注' : '关注'
                }
              </Button>
            </div>
        })}
        </div>
    </CollaborativeCreatorsStyle>
  )
}

export default CollaborativeCreators