import styled from "styled-components";

export const ChatRoomStyle = styled.div`
.chat-room-container{
    .ant-modal-wrap{
        overflow: unset !important;
    }
    .chat-room-modal{
        .ant-modal-header{
            background-color: ${props => props.theme.colors.colorBgLayout} !important;
        }
        .ant-modal-content{
            background-color: ${props => props.theme.colors.colorBgLayout} !important;
            min-height: 300px;
        }
    }
    
    .chat-container {
        height: 600px;
        display: flex;
        background-color: ${props => props.theme.colors.colorBgLayout};
        .left-wrapper{
            width: 250px;
           
            border: 1px solid ${props=> props.theme.colors.colorBorder};
            overflow-y: auto;
            border-radius: ${props=> props.theme.border.borderRadiusSM}px;
            ::-webkit-scrollbar{
                display:none;/*隐藏滚动条*/
                } 
            .user-list-wrapper{
               
                .user-list-item{
                    padding:${props=> props.theme.space.paddingXS}px ${props=> props.theme.space.paddingSM}px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    
                    border-bottom: 1px solid ${props=> props.theme.colors.colorBorder};
                    .con-right{
                        .con-top{
                            margin-left: ${props => props.theme.space.marginXS}px;
                        }
                    }
                }
                .user-list-item-active{
                    background-color: ${props => props.theme.colors.colorHomeBgContainer};
                }
            }
        }
        .right-wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: ${props => props.theme.space.marginXS}px;
            .message-wrapper{
               flex: 1;
               width: 100%;
               border: 1px solid ${props => props.theme.colors.colorBorder};
               border-radius: ${props=> props.theme.border.borderRadiusSM}px;
               margin-bottom: ${props => props.theme.space.marginXS}px;
               overflow-y: auto;
               display: flex;
               flex-direction: column-reverse;
               position: relative;
               padding-top: 10px;
              .infinite-scroll-component__outerdiv{
                /* height: 100%; */
              }
            }
            .input-wrapper{
                position: relative;
                border: 1px solid ${props => props.theme.colors.colorBorder};
               border-radius: ${props=> props.theme.border.borderRadiusSM}px;
               padding: ${props=> props.theme.space.paddingSM}px;
               background-color:${props=> props.theme.colors.colorTextLightSolid} ;
               .rce-container-input{
                border-radius: ${props=> props.theme.border.borderRadiusSM}px;
               }
            }
        }
    }
    .empty{
        height: 400px;

        .ant-empty{
            margin-top: 200px;
        }
    }
}
`