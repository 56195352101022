import { ShippingFeeTemplateListItem } from "types/product";


export const formatMoney = (value: number | undefined) => {
    if (value === undefined) return undefined;
    return Number((value / 100).toFixed(2));
};

export const formatShippingFeeTemplate = (items:ShippingFeeTemplateListItem[]) => {
    return items.map(item => {
       return {
        label:item.name,
        value:item.id
       }
    })
}

