import { WorkDetailMedieListItem } from 'types/work';
import { v4 as uuidv4 } from 'uuid';

export function makeFilePath(prefix:string, name: string){
  const now = new Date();
  let str = `${prefix}/${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}/` + uuidv4() + "." + name.split(".").pop();
  return str
}

export function ImageObjectCloudResize(path: string, maxWidth:number){
 
  if(!isValidURL(path)){
    return ''
  }
  const url = new URL(path);
  const hostname = url.hostname;
  const fileExtension = path.slice(path.lastIndexOf('.')).toLowerCase();
  //腾讯
  if((hostname ==='cdn-prd.ggac.com') || (hostname ==='cdn-dev.ggac.com')){
    return path + `?imageMogr2/thumbnail/${maxWidth}x>/format/webp/auto-orient`
  }
  //阿里
  if(hostname ==='cdn.ggac.com'){
    if(fileExtension ==='.jpg'||fileExtension ==='.jpeg'){
      return path
    }
    return path + `?x-oss-process=image/resize,w_${maxWidth},m_lfit/format,webp`
  }
}

export function useResizedUrl(mediaItem:WorkDetailMedieListItem){
    let path = mediaItem.url
    if(!isValidURL(path)){
      return ''
    }
    const url = new URL(path);
    const hostname = url.hostname;
    const fileExtension = path.slice(path.lastIndexOf('.')).toLowerCase();

    //阿里
    if(hostname ==='cdn.ggac.com' && (fileExtension ==='.jpg'||fileExtension ==='.jpeg')){
      return mediaItem.url
    }
    return mediaItem.resizedUrl??mediaItem.url
}

export function CoverImageObjectCloudResize(path: string, maxWidth:number){
 
  if(!isValidURL(path)){
    return ''
  }
  const url = new URL(path);
  const hostname = url.hostname;
  //腾讯
  if((hostname ==='cdn-prd.ggac.com') || (hostname ==='cdn-dev.ggac.com')){
    return path + `?imageMogr2/thumbnail/${maxWidth}x>/format/webp/auto-orient`
  }
  //阿里
  if(hostname ==='cdn.ggac.com'){
    return path + `?x-oss-process=image/resize,w_${maxWidth},m_lfit/format,webp`
  }
}


export function isValidURL(url:string) {
  const regex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
  return regex.test(url);
}


export function beautifyFileSize(fileSize:number) {
  // 定义单位
  var units = ['B', 'KB', 'MB', 'GB', 'TB'];

  // 迭代处理文件尺寸
  for (var i = 0; i < units.length; i++) {
    if (fileSize < 1024) {
      return fileSize.toFixed(2) + ' ' + units[i];
    }
    fileSize /= 1024;
  }

  return fileSize.toFixed(2) + ' ' + units[units.length - 1];
}


export function isImageResolutionTooLarge(file: File, maxWidth: number, maxHeight: number): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = function (event) {
      if (event.target && typeof event.target.result === 'string') {
        img.onload = function () {
          const width = img.width;
          const height = img.height;

          if (width > maxWidth || height > maxHeight) {
            resolve(true); // Image resolution is too large
          } else {
            resolve(false); // Image resolution is within limits
          }
        };
        img.onerror = function () {
          reject(new Error('Failed to load image'));
        };
        img.src = event.target.result;
      }
    };

    reader.onerror = function () {
      reject(new Error('Failed to read file'));
    };

    reader.readAsDataURL(file);
  });
}

export const isFileOversized = (file: File,limit:number) => {
  return file.size / 1024 / 1024 > limit
}

export function isImage(file: File) {
  if(!file){
    return false
  }
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif','webp'];
  const extension = file?.name?.split('.')?.pop()?.toLowerCase();
  if(!extension){
    return false
  }
  return allowedExtensions.includes(extension);
}

export function isAudio(file: File) {
  if(!file){
    return false
  }
  const allowedExtensions = ["mp3", "ogg", "wav", "flac", "m4a", "aac"];
  const extension = file?.name?.split('.')?.pop()?.toLowerCase();
  console.log(extension)
  if(!extension){
    return false
  }
  return allowedExtensions.includes(extension);
}