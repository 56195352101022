import { get ,post} from "api"
import { PaginationResponse } from "types/common/axios"
import { CarouselListItem, CarouselListRequest, CarouselLogRequest, CarouselSmallBannerListRequest } from "types/common/carousel"



//轮播图
export const getCarouselList = (params: CarouselListRequest) => {
    return get<CarouselListRequest, PaginationResponse<CarouselListItem>>('/api/carousel/list', params)
}

// 轮播图日志采集
export const sendCarouselLog = (params: CarouselLogRequest) => {
    return post<CarouselLogRequest, any>('/api/carousel/log', params)
}

//小广告位查询接口
export const getSmallBannerList = (params: CarouselSmallBannerListRequest) =>{
    return post<CarouselSmallBannerListRequest, CarouselListItem[]>('/api/carousel/small_banner_list', params)
}

