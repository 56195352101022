import { useEffect, useState } from "react";
import { AdStyle } from "./style";
import { getCarouselList, sendCarouselLog } from "api/common/carousel";
import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import { shuffleArray } from "utils/functions/common";

function Ad() {
  const [banner, setBanner] = useState<CarouselListItem>();

  useEffect(() => {
    const params: CarouselListRequest = {
      lang: 1,
      type: 19,
      status: 1,
    };
    getCarouselList(params).then((res) => {
      setBanner(shuffleArray(res.pageData).pop())
    });
  }, []);

  return (
    <AdStyle>
      {banner && <a href={banner.webUrl } target='_blank' onClick={()=>sendCarouselLog({carouselId:banner.id})}>
        <div
          className="holder"
          style={{
            backgroundImage: `url(${banner.displayImage})`,
          }}
        >
        </div></a>}
    </AdStyle>
  );
}

export default Ad;
