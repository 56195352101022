export function generateDateList(month:number,year:number) {

    var firstDate = new Date(year, month-1, 1); 
    var dateList = []; // Array to store the generated dates
  
    // Set the currentDate to the first day of the week
    firstDate.setDate(firstDate.getDate() - firstDate.getDay());
  
    // Generate the list of 42 days
    for (var i = 0; i < 42; i++) {
      const item = {
        dateStr: firstDate.getFullYear()+'-'+getMonthNumber(firstDate)+'-'+ getDateNumber(firstDate),
        dateObj:new Date(firstDate),
        isThisMonth:firstDate.getMonth() === (month-1),
      }  
      dateList.push(item); // Add the current date to the list
      firstDate.setDate(firstDate.getDate() + 1); // Increment the date by one day
    }
    return dateList;
}


function getDateNumber(date: Date){
  if (date.getDate() <10){
    return '0' +(date.getDate())
  }else{
    return (date.getDate())
  }
}

function getMonthNumber(date:Date){
  if(date.getMonth()<10){
    return '0' +(date.getMonth() +1)
  }else{
    return (date.getMonth() +1)
  }
}



  