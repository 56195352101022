import styled from "styled-components";

export const AuthenticationStudentStyle = styled.div`
    .ant-form-item-label {
        display: flex;
        align-items: center;
    }
    .img-tip {
        margin: -20px 0 20px 140px;
        color: #8A8A8B;
    }
    .drop-wrap {
        padding: 0 !important;
        height: 110px !important;
    }
    .status {
        margin: 24px 0 0;
    }
    .btn-wrap {
        .refuseReason {
            text-align: center;
            margin-bottom: 15px;
            color: red;
        }
        text-align: center;
    }
    .tip {
        margin: ${props=>props.theme.space.marginXS}px 0 0 130px;
        color: red;
    }
`