import { get } from "api"
import { PaginationResponse } from 'types/common/axios'
import { CfgMallManuscriptParams, MallAdminProductListResponse, ProductListRequest } from "types/product";


export const apiGetProductList = async (params: ProductListRequest) =>
    await get<ProductListRequest, PaginationResponse<MallAdminProductListResponse>>("/api/v6/nft/biz/product/list", params);

//配置-商城稿件参数
export const apiGetProductConfig = async () =>
    await get<{}, CfgMallManuscriptParams>("/api/cfg/mall_manuscript_params");