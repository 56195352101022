import { useEffect, useState } from "react"
import { RewardIndexStyle } from "./style"
import { getAnswererDetail, getForumDataList } from "api/forum"
import { ForumMineStatistics } from "api/forum/type"
import { Button, Modal, Tooltip } from "antd"
import { Outlet, useNavigate } from "react-router-dom"
import { hasToken } from "utils/functions/common"
import CreateAndUpdateReward from "../components/createAndUpdateReward"
import RewardSearchInput from "../search-input"
import { loginPathWithRedirect } from "utils/functions/user"
import ToBeAnswerer from "../components/toBeAnswerer"

const RewardIndex = () => {

    const navigate = useNavigate()

    const [isShowReward, setIsShowReward] = useState(false)

    const token = hasToken()

    const [dataList, setDataList] = useState<ForumMineStatistics>()

    const [rewardPriceDetail, setRewardPriceDetail] = useState<{ briefIntroduction: string, wage: number }>()

    const [isShowAnswerer, setIsShowAnswerer] = useState(false)

    const toLink = (url: string) => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        navigate(url)
    }

    const showReward = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        setIsShowReward(true)
    }

    useEffect(() => {
        if (!token) {
            return
        }
        getForumDataList().then(res => {
            setDataList(res)
        })
    }, [])

    useEffect(() => {
        if (!token) {
            return
        }
        getRewardPrice()
    }, [token])

    const getRewardPrice = () => {
        getAnswererDetail().then(res => {
            setRewardPriceDetail(res)
        })
    }

    const updatePrice = () => {
        setIsShowAnswerer(true)
    }
    const saveSuccess = () => {
        setIsShowAnswerer(false)
        getRewardPrice()
    }

    const toBeAnswererClick = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        setIsShowAnswerer(true)
    }

    return <RewardIndexStyle>
        <div className="container">
            <div className="left">
                <Outlet></Outlet>
            </div>
            <div className="right">
                <RewardSearchInput />
                <div className="btn-wrap">
                    <Button type="primary" className="reward-btn" onClick={showReward}>悬赏提问</Button>
                </div>
                <div className="border">
                    <div className="border-item qustion" onClick={() => { toLink("/forum/reward/my-reward") }}>
                        <div className="border-top">
                            <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/reward/reward-user-question.png)` }} />
                            {
                                !!dataList?.questionCount && <div className="count">{dataList?.questionCount}</div>
                            }
                        </div>
                        <div className="name">我的悬赏</div>

                    </div>
                    <div className="border-item applicant" onClick={() => { toLink("/forum/reward/my-applicant") }}>
                        <div className="border-top">
                            <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/reward/reward-user-applicant.png)` }} />
                            {
                                !!dataList?.applyCount && <div className="count">{dataList?.applyCount}</div>
                            }
                        </div>
                        <div className="name">我的抢答</div>
                    </div>

                    <div className="border-item invitation" onClick={() => { toLink("/forum/reward/my-invitation") }}>
                        <div className="border-top">
                            <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/reward/reward-user-invitation.png)` }} />
                            {
                                !!dataList?.inviteCount && <div className="count">{dataList?.inviteCount}</div>
                            }
                        </div>
                        <div className="name">收到邀约</div>
                    </div>

                </div>

                <div className="border">

                    <div className="border-item received-order" onClick={() => { toLink("/user-center/wallet/index") }}>
                        <div className="border-top">
                            <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/reward/reward-pc-wallet.png)` }} />
                        </div>
                        <div className="name">我的钱包</div>
                    </div>


                    <div className="border-item payed-order" onClick={() => { toLink("/forum/reward/order/list") }}>
                        <div className="border-top">
                            <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/reward/reward-pc-order.png)` }} />
                        </div>
                        <div className="name">我的订单</div>
                    </div>

                    {
                        !rewardPriceDetail && <div className="border-item payed-order" onClick={() => toBeAnswererClick()}>
                            <div className="border-top">
                                <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/reward/reward-price.png)` }} />
                            </div>
                            <div className="name">成为答主</div>
                        </div>
                    }
                    {
                        !!rewardPriceDetail && <div className="border-item payed-order" onClick={() => toBeAnswererClick()}>
                        <div className="border-top">
                            <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/reward/reward-price3.png)` }} />
                        </div>
                        <div className="name">答主设置</div>
                    </div>
                    }

                    {/* <div className="border-item collect" onClick={() => { toLink("/forum/reward/order/list") }}>
                        <div className="border-top" style={{ backgroundColor: "rgb(255,243,229,0.7)" }}>
                            <div className="img" style={{ 'backgroundImage': `url(https://cdn-prd.ggac.com/ggac/icon/forum-icon3.png)` }} />
                        </div>
                        <div className="name">我的收藏</div>
                    </div> */}

                </div>

                {/* {
                    !rewardPriceDetail && <div className="answerer-btn">
                        <Button type="primary" ghost onClick={() => toBeAnswererClick()}>成为答主</Button>
                    </div>
                } */}

                {
                    token && <div className="bottom-tip">
                        <div className="income">
                            答题累计收益：{dataList?.revenue}元
                            <Tooltip placement="topLeft" title="总收益展示已完成订单金额，包含结算中的订单，可前往个人中心我的钱包去提现">
                                <img className="wenhao" src="https://cdn-prd.ggac.com/ggac/reg/2024/2024-reg-wenhao.png" alt="" />
                            </Tooltip>
                        </div>
                    </div>
                }
                {/* {
                    rewardPriceDetail && <div className="bottom-tip bottom-tip-price">
                        <div className="income">我的指导价格：{rewardPriceDetail && rewardPriceDetail.wage}元</div>
                        <div className="wallet" onClick={updatePrice}>编辑</div>
                    </div>
                } */}






            </div>
        </div>
        <Modal getContainer={false} title='悬赏提问' footer={<></>} width={650} destroyOnClose={true} closable={false} centered={true} open={isShowReward}>
            <CreateAndUpdateReward close={() => setIsShowReward(false)} />
        </Modal>


        <Modal getContainer={false} onCancel={() => setIsShowAnswerer(false)} title='成为答主' footer={<></>} width={650} destroyOnClose={true} centered={true} open={isShowAnswerer}>
            <ToBeAnswerer saveSuccess={saveSuccess} />
        </Modal>
    </RewardIndexStyle >
}

export default RewardIndex