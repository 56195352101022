import GoBackTitle from "ui-lib/go-back";
import PublishTopic from "../common/publish";
import SearchInput from "../common/search-input";
import { ForumSearchStyle } from "./style";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { ForumTopicListItem } from "api/forum/type";
import { apiForumTopicGlobalSearch, apiForumTopicList } from "api/forum";
import TopicListItem from "../topic-list-item";
import { NoData } from "components/no-data";
import ReplyCreateAndUdate from "../reply/reply-edit/reply-create-update";
import HotTags from "../common/hot-tags";

function ForumSearch() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get('keyword');
  const tagName = searchParams.get('tagName');
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [dataList, setDataList] = useState<ForumTopicListItem[]>([]);
  const [showReplyEdit, setReplyShowEdit] = useState(false);
  const [currentTopicId, setCurrentTopicId] = useState<number | undefined>();
  const pageSize = 20;

  useEffect(() => {
    if (!searchParams.get('sortField')) {
      searchParams.set('sortField', 'lastSubmitTime')
      setSearchParams(searchParams)
    }
    if (!searchParams.get("currentPage")) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("currentPage", String(1));
      setSearchParams(newSearchParams);
    } else {
      getList()
    }

  }, [
    searchParams
  ]);
  const getList = () => {
    setLoading(true);
    const queryParams = Object.fromEntries(searchParams.entries());
    queryParams.keyword && apiForumTopicGlobalSearch({
      pageNumber: Number(searchParams.get('currentPage')) > 0 ? Number(searchParams.get('currentPage')) : 1,
      pageSize: pageSize,
      needTotal: 1,
      collapseTextCount: 400,
      ...queryParams,
    }).then((res: any) => {
      setDataList(res.pageData);
      setTotalSize(res.totalSize);
      setLoading(false);
    });
    queryParams.tagName && apiForumTopicList({
      pageNumber: Number(searchParams.get('currentPage')) > 0 ? Number(searchParams.get('currentPage')) : 1,
      pageSize: pageSize,
      needTotal: 1,
      collapseTextCount: 400,
      ...queryParams,
    }).then((res: any) => {
      setDataList(res.pageData);
      setTotalSize(res.totalSize);
      setLoading(false);
    });
  };

  const setLocalList = (item: ForumTopicListItem) => {
    setDataList((prevData) => {
      return prevData.map((obj) => {
        if (obj.id === item.id) {
          return item;
        }
        return obj;
      });
    });
  };

  const changePage = (e: any) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('currentPage', e)
    setSearchParams(newSearchParams);
  };

  const link = () => {
    navigate(`/forum/question/index`, { replace: true })
  }

  return (
    <ForumSearchStyle className="ForumSearchStyle">
      <div className="container">
        <div className="left">
          <div className="list-title">
            <div className="link" onClick={() => link()}>
              返回 话题主页
            </div>
            <span>{`>`}</span>
            搜索
          </div>
          <div className="meta">
            {keyword && <div className="keyword" title={keyword}>
              搜索：{keyword}
            </div>}
            {tagName && <div className="keyword" title={tagName}>
              标签：{tagName}
            </div>}
            <div className="filter">
              <Space size={"large"}>
                <span onClick={() => {
                  searchParams.set('sortField', 'lastSubmitTime')
                  searchParams.set('currentPage', '1')
                  setSearchParams(searchParams)
                }} className={`filter-item ${searchParams.get('sortField') === 'lastSubmitTime' ? 'active' : ''}`}>最新</span>
                <span onClick={() => {
                  searchParams.set('sortField', 'hot')
                  searchParams.set('currentPage', '1')
                  setSearchParams(searchParams)
                }} className={`filter-item ${searchParams.get('sortField') === 'hot' ? 'active' : ''}`}>最热</span>
              </Space>
            </div>
          </div>

          <Spin spinning={loading}>
            <div className="topic-list">
              {dataList.map((item, index) => {
                return (
                  <TopicListItem
                    key={index}
                    onLaunchReply={(topicId) => {
                      setCurrentTopicId(topicId)
                      setReplyShowEdit(true)
                    }}
                    listItem={item}
                    onListItemChange={(item_) => {
                      setLocalList(item_);
                    }}
                    onCommentCountChange={() => {
                      getList();
                    }}
                    needRefresh={() => {
                      getList();
                    }}
                  ></TopicListItem>
                );
              })}
            </div>
            {dataList.length === 0 && !loading && (
              <div style={{ marginTop: "200px" }}>
                <NoData></NoData>
              </div>
            )}
          </Spin>
          {dataList.length > 0 && (
            <Pagination
              style={{ textAlign: "center", margin: "24px 0 12px 0" }}
              hideOnSinglePage={true}
              current={Number(searchParams.get('currentPage')) > 0 ? Number(searchParams.get('currentPage')) : 1}
              onChange={changePage}
              pageSize={pageSize}
              total={totalSize}
              showSizeChanger={false}
              size="default"
            />
          )}
        </div>
        <div className="right">
          <SearchInput></SearchInput>
          <PublishTopic></PublishTopic>
          <HotTags></HotTags>
        </div>
      </div>
      <ReplyCreateAndUdate
        isModalOpen={showReplyEdit}
        handleOk={() => {
          navigate(`/forum/question/topic/${currentTopicId}`);
          setReplyShowEdit(false);
        }}
        handleCancel={() => {
          setCurrentTopicId(undefined);
          setReplyShowEdit(false);
        }}
        topicId={currentTopicId}
      ></ReplyCreateAndUdate>
    </ForumSearchStyle>
  );
}

export default ForumSearch;
