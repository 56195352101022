import { CommandItemStyle } from "./style"
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useEffect, useState } from "react";
import { WorkAbleAndTaItem } from "types/user/work";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

const RecommandItem = ({ list }: { list: WorkAbleAndTaItem[] }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        slides: {
            perView: 3,
            spacing: 8
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    useEffect(() => {
        instanceRef && instanceRef.current?.update();
    }, [list])

    const move = (type: string) => {
        if (type === "prev") {
            instanceRef.current?.prev();
        }
        if (type === "next") {
            instanceRef.current?.next();
        }
    };

    const toDetail = (detail: WorkAbleAndTaItem) => {
        window.open(`/work/detail/${detail.id}`)
    }
    return <CommandItemStyle>
        <div ref={sliderRef} className="keen-slider">
            {
                list.length > 0 && list.map((item => {
                    return <div key={item.id} onClick={() => toDetail(item)} className="item keen-slider__slide" style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                }))
            }
        </div>
        {
            list.length > 3 && <>
                <div className="left-arrow" onClick={() => move("prev")}>
                    <RiArrowLeftSLine size={20} />
                </div>
                <div className="right-arrow" onClick={() => move("next")}>
                    <RiArrowRightSLine size={20} />
                </div>
            </>
        }
    </CommandItemStyle>
}

export default RecommandItem