import { useOutletContext } from "react-router"
import { RegulatioinDetailStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import { Modal, Tooltip } from "antd"
import RegulationWorkSwiper from "./swiper"
import { useState } from "react"
import Regulation3DTextPopup from "./popup"

const Regulatioin2024Detail = () => {

    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()

    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const judgeList: { name: string, desc: string[], id: number, src: string }[] = contestJSON.judgeList || []
    const dateList: { name: string, tip: string, id: number }[] = contestJSON.dateList || []
    const priceList: { title: string, id: number, tip: string, tip1: string, tabList: { name: string, id: number }[], list: { name: string, count: number, id: number, item: number, money: number, desc: string[][] }[] }[] = contestJSON.priceList || []
    const code: { name: string[], id: number, url: string }[] = contestJSON.code || []
    const style3dPriceList: { title: string, list: { name: string, count: number, id: number, item: number, money: number, desc: string[][] }[] } = contestJSON.style3dPriceList || {}
    const detailAboutList: string[] = contestJSON.detailAboutList || []
    const honorList: string[][] = contestJSON.honorList || []
    const regulationWork: { url: string, desc: string, id: string }[] = contestJSON.regulationWork || []
    const [isShow3DStylePopup, setIsShow3DStylePopup] = useState(false)
    const style3dWordList1: { name: string, desc: string[], id: number, tip: string, href: string, url: string } = contestJSON.style3dWordList1 || {}
    const style3dWordList2: { name: string, desc: string[], id: number, tip: string, href: string, url: string } = contestJSON.style3dWordList2 || {}
    const [current3dWordList, setCurrent3dWordList] = useState<{ name: string, desc: string[], id: number, tip: string, href: string, url: string }>()
    const toSchool = () => {
        window.open("https://www.ggac.com/contest/ydbz/detail")
    }

    const lang = localStorage.getItem('lang')

    const onClose = () => {
        setIsShow3DStylePopup(false)
    }


    const toJudge = (id: number) => {
        if (!!id) {
            window.open(`/user/${id}`)
        }
    }

    const toDetail = (id: number) => {
        window.open(`/official/${id}`)
    }

    return <RegulatioinDetailStyle>
        {
            detail && <div className={`detail ${lang === 'en_GB' ? 'en-detail' : ''}`}>
                <div className="part1">
                    <div className="date">
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-date-line-left.png" className="left" />
                        {
                            dateList.map(item => {
                                return <div className="date-item" lang="t_lang" key={item.id} dangerouslySetInnerHTML={{ __html: item.name }}></div>
                            })
                        }
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-date-line-right.png" className="right" />
                    </div>
                    <div className="date-tip">
                        {
                            dateList.map(item => {
                                return <div className="tip" key={item.id} lang="t_lang">{item.tip}</div>
                            })
                        }
                    </div>
                    <div className="part-content">
                        {
                            lang === 'zh_CN' && <img className="theme" src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-one-word.png"></img>
                        }
                        {
                            lang === 'en_GB' && <img className="theme" src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-one-word-en.png"></img>
                        }
                        <div className="word">
                            <div lang="t_lang">万物皆有裂缝，那是光照进的地方。</div>
                            <div lang="t_lang">光不仅仅是物质的存在，更是发现内心深处的希望和热爱。</div>
                            <div lang="t_lang">每一点星光都是一个故事，每一束光芒都是一段奇迹。</div>
                            <div lang="t_lang">也许，在我们创作中也曾艰辛曲折，迷失在追寻光的路途上。</div>
                            <div lang="t_lang">但请始终相信，无论何时何境，</div>
                            <div lang="t_lang">心之所向，必将熠熠生光，照亮世界。</div>
                        </div>
                        <video controls loop poster="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-video-image2.png" src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-video.mp4" />
                    </div>
                </div>
                <div className="price">
                    <div className="title-wrap">
                        <div className="num-wrap">
                            <div className="num">01</div>
                        </div>
                        <div className="title" lang="t_lang">大赛奖项</div>
                    </div>
                    {
                        priceList.map(item => {
                            return <div key={item.id}>
                                <div className="sub-title">
                                    <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-left.png" alt="" />
                                    <div className="sub-content">
                                        <span lang="t_lang">{item.title}</span>
                                        <Tooltip placement="topLeft" title={item.tip1}>
                                            <img className="wenhao" src="https://cdn-prd.ggac.com/ggac/reg/2024/2024-reg-wenhao.png" alt="" />
                                        </Tooltip>
                                    </div>
                                    <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-right.png" alt="" />
                                </div>
                                <div className="tab-wrap">
                                    <div className="word" lang="t_lang">{item.tip}</div>
                                    <div className="tab-list">
                                        {
                                            item.tabList.map(item => {
                                                return <div className="tab-item" key={item.id} onClick={() => toDetail(item.id)}>
                                                    <span lang="t_lang">{item.name}</span>
                                                    {
                                                        (![2571, 2567].includes(item.id)) && <>，</>
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    item.id === 1 && <div className="third-title">
                                        <div className="price-num" lang="t_lang">特等奖 · 1名</div>
                                        <div className="money-wrap">
                                            <span>¥</span>
                                            <span>100</span>
                                            <span>,</span>
                                            <span>000</span>
                                        </div>
                                    </div>
                                }
                                {
                                    item.id === 1 && <div className="tab-wrap tab-wrap2" lang="t_lang">Wacom Movink OLED数位屏 价值¥6499</div>
                                }
                                <div className="list">
                                    {
                                        item.list.map(detail => {
                                            return <div key={detail.id} className="list-item">
                                                <div className="name" lang="t_lang">{detail.name}</div>
                                                {/* <div className="number">{detail.count}名</div> */}
                                                {
                                                    !!detail.item && <div className="item" lang="t_lang">每细分组别各{detail.item}名</div>
                                                }
                                                <div className="money white">
                                                    {
                                                        !!detail.money && <div className="money-wrap">
                                                            <span>¥</span>
                                                            <span>{detail.money}</span>
                                                            <span>,</span>
                                                            <span>000</span>
                                                        </div>
                                                    }
                                                    {
                                                        !detail.money && <div className="gift" lang="t_lang">精美礼品</div>
                                                    }
                                                </div>
                                                <div className="desc-list">
                                                    {
                                                        detail.desc && detail.desc.map((desc, index) => {
                                                            return <div key={index} className="desc-wrap">
                                                                {
                                                                    desc.map(subDesc => {
                                                                        return <div className="desc" lang="t_lang" key={subDesc}>{subDesc}</div>
                                                                    })
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    item.id == 1 && <>
                                        <div className="tip sub-tip">
                                            <div lang="t_lang">※注：所有GGAC创作奖提名作品均可获得由Style3D 提供的Style3D Atelier 个人Pro版、Style3D Simulator 个人版许可证*3个月</div>
                                            <div lang="t_lang">及Style3D 4000+ 服装款式模型3个月使用权，不限提名作品创作类型。</div>
                                        </div>
                                        <div className="three-tip" lang="t_lang">- 示例作品 -</div>
                                        {
                                            regulationWork && regulationWork.length > 0 && <RegulationWorkSwiper list={regulationWork} />
                                        }
                                        <div className="work-btn" onClick={() => window.open(`/official/2575`)} lang="t_lang">查看更多示例</div>
                                    </>
                                }
                                {
                                    item.id == 2 && <>
                                        <div className="tip sub-tip">
                                            <div lang="t_lang">※注：所有GGAC学院奖提名作品均可获得由Style3D 提供的Style3D Atelier 个人Pro版、Style3D Simulator 个人版许可证*3个月</div>
                                            <div lang="t_lang">及Style3D 4000+ 服装款式模型3个月使用权，不限提名作品创作类型。</div>
                                        </div>
                                    </>
                                }
                            </div>
                        })
                    }
                    <div className="three-tip" lang="t_lang">- 学院奖专题活动 -</div>
                    <div className="img-list img-list1">
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-image3.png" alt="" onClick={toSchool} />
                    </div>
                    <div className="sub-title">
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-left.png" alt="" />
                        <div className="sub-content">
                            <span lang="t_lang">创意实验室</span>
                            <Tooltip placement="topLeft" title="创意实验室：GGAC与跨界合作伙伴为推动数字艺术与新技术、新产品的融合，探索更多新的创意表达与可能，联合举办的特别赛道，「创意实验室」将由不同形式的创意赛事组成，具体参赛规则请参加各自赛事规则。">
                                <img className="wenhao" src="https://cdn-prd.ggac.com/ggac/reg/2024/2024-reg-wenhao.png" alt="" />
                            </Tooltip>
                        </div>
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-right.png" alt="" />
                    </div>
                    <div className="img-list">
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-image1.png" onClick={() => window.open("/contest/ly3d")} />
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-image2.png" onClick={() => window.open("/contest/xrtz")} />
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-image5.png" onClick={() => window.open("https://www.ggac.com/official/2576")} />
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-image4.png" onClick={() => window.open("/contest/sims")} />
                    </div>
                    {/* <div style={{ height: "30px" }} id="style3D" />
                    <div className="sub-title">
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-left.png" alt="" />
                        <div className="sub-content" lang="t_lang">
                            Style3D 特别奖
                        </div>
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-right.png" alt="" />
                    </div>
                    <div className="tab-wrap tab-wrap2">
                        <span lang="t_lang">使用</span>
                        <span onClick={() => { setCurrent3dWordList(style3dWordList1); setIsShow3DStylePopup(true) }}>
                            Style3D Atelier</span>
                        <span lang="t_lang">或</span>
                        <span lang="t_lang" onClick={() => { setCurrent3dWordList(style3dWordList2); setIsShow3DStylePopup(true) }}>Style3D Simulator</span>
                        <span lang="t_lang">创作的参赛作品均可参与评选</span>
                    </div>
                    <div className="btn btn2" lang="t_lang" onClick={() => window.open("/official/2576")}>前往了解参与规则</div>
                    <div className="list style3d">
                        {
                            style3dPriceList.list.map(detail => {
                                return <div key={detail.id} className="list-item">
                                    <div className="name" lang="t_lang">{detail.name}</div>
                                    <div className="number" lang="t_lang">{detail.count}名</div>
                                    <div className="money white">
                                        {
                                            !!detail.money && <div className="money-wrap">
                                                <span>¥</span>
                                                <span>{detail.money}</span>
                                                <span>,</span>
                                                <span>000</span>
                                            </div>
                                        }
                                        {
                                            !detail.money && <div className="gift">
                                                <div>Style3D</div>
                                                <div lang="t_lang">全套限定周边</div>
                                            </div>
                                        }
                                    </div>
                                    <div className="desc-list">
                                        {
                                            detail.desc && detail.desc.map((desc, index) => {
                                                return <div key={index} className="desc-wrap">
                                                    {
                                                        desc.map(subDesc => {
                                                            return <div className="desc" lang="t_lang" key={subDesc}>{subDesc}</div>
                                                        })
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>

                    <div className="three-tip" lang="t_lang">
                        - Style3D 创作Tips -
                    </div>

                    <div className="tip">
                        <div lang="t_lang">参赛者电脑端点击链接，分别进入Style3D Atelier官网和Style3D Simulator官网，注册账号后，即可下载使用。</div>
                        <div lang="t_lang">大赛期间Style3D 将为第六GGAC大赛所有参赛者提供全程软件权限及技术支持。</div>
                    </div>

                    <div className="btn btn2" onClick={() => window.open("https://jinshuju.net/f/FXfgqX")} lang="t_lang">前往开通相关资源权限</div> */}


                    <div className="sub-title">
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-left.png" alt="" />
                        <span lang="t_lang">赛事荣誉</span>
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-sub-title-right.png" alt="" />
                    </div>
                    <div className="last-border">
                        {
                            honorList.map((desc, index) => {
                                return <div className="border-item" key={index}>
                                    {
                                        desc.map(sub => {
                                            return <div key={sub} lang="t_lang">{sub}</div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="judge">
                    <div className="title-wrap">
                        <div className="num-wrap">
                            <div className="num color-num">02</div>
                        </div>
                        <div className="title" lang="t_lang">大赛评委</div>
                    </div>
                    <div className="judge-list">
                        {
                            judgeList.map(item => {
                                return <div className="judge-item" key={item.src}>
                                    <div className="image" onClick={() => toJudge(item.id)} style={{ backgroundImage: `url(${item.src})`, cursor: !!item.id ? "pointer" : "auto" }}></div>
                                    <div className="judge-name">{item.name}</div>
                                    {
                                        item.desc.map(desc => {
                                            return <div className="desc" key={desc} lang="t_lang">{desc}</div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="about">
                    <div className="title-wrap">
                        <div className="num-wrap">
                            <div className="num">03</div>
                        </div>
                        <div className="title" lang="t_lang">关于大赛</div>
                    </div>
                    <div className="border">
                        <img className="logo" src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-logo.png"></img>
                        <div className="content">
                            {
                                detailAboutList.map(item => {
                                    return <div key={item} lang="t_lang">{item}</div>
                                })
                            }
                        </div>
                    </div>
                    <div className="title-wrap">
                        <div className="num-wrap">
                            <div className="num">04</div>
                        </div>
                        <div className="title" lang="t_lang">关注我们</div>
                    </div>
                    <div className="code">
                        {
                            code.map(item => {
                                return <div className="code-item" key={item.id}>
                                    <img className="code-image" src={item.url} />
                                    {
                                        item.name.map(desc => {
                                            return <div className="desc" key={desc} lang="t_lang">{desc}</div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className="bottom-logo">
                        <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-detail-logo.png" alt="" />
                    </div>
                </div>
                {
                    current3dWordList && <Modal footer={<></>} className="modal" getContainer={false} destroyOnClose={true} closable={false} centered={true} open={isShow3DStylePopup}>
                        <Regulation3DTextPopup close={onClose} textDetail={current3dWordList} />
                    </Modal>
                }
            </div>
        }
    </RegulatioinDetailStyle>
}

export default Regulatioin2024Detail