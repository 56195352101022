import styled from "styled-components";

export const RecommandationStyle = styled.div`
    .listWrap{
      margin-top: ${(props)=> props.theme.space.marginLG}px;
        .title{
          font-size: ${props => props.theme.fontSizes.fontSizeHeading4};
          font-weight:${props => props.theme.fontSizes.fontWeightHeading4};
          margin-bottom: ${props => props.theme.space.marginXS}px;
        }
    }
`