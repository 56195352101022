import styled from "styled-components";

export const BusinessContactStyle = styled.div`

    
`

export const FormStyle = styled.div`
    padding-top: ${(props) => props.theme.space.paddingLG}px;
    padding-right: ${(props) => props.theme.space.paddingLG}px;
    .tip {
        font-weight: bold;
    }
    
    .btn {
        text-align: center;
        padding-top: ${(props) => props.theme.space.paddingXXS}px;
        padding-left: 38px;
     }
`