import { useEffect, useState } from "react"
import { Regtulation2024Style } from "./style"
import { Outlet, useNavigate } from "react-router"
import { getContestDetail } from "api/contest"
import { ContestDetailResponse } from "types/contest"
import { hasToken, isMobile } from "utils/functions/common"
import { loginPathWithRedirect } from "utils/functions/user"
import { useLocation, useSearchParams } from "react-router-dom"
import { FloatButton, Modal } from "antd"
import RegulationPopup from "./popup"

const Regtulation2024 = () => {

    const [detail, setDetail] = useState<ContestDetailResponse>()
    const [searchParams] = useSearchParams();
    const invitationCode = searchParams.get('invitationCode');
    const [isShowPopup, setIsShowPopup] = useState(false)
    let link = new URLSearchParams(useLocation().search).get("link") || ''

    const navigate = useNavigate()

    const [videoFinish, setVideoFinish] = useState(false)

    const [screenHeight, setScreenHeight] = useState(0)

    const lang = localStorage.getItem('lang')

    const tabList = [
        { name: "赛事首页", value: "/2024/regulation/detail", id: 1 },
        { name: "赛事规则", value: "/2024/regulation/rule", id: 2 },
        { name: "大赛作品", value: "/2024/regulation/work", id: 3 },
        { name: "创意实验室", value: "", id: 4 },
        { name: "关于大赛", value: "/2024/regulation/about", id: 6 },
        { name: "往届赛事", value: "/2024/regulation/previous", id: 7 },
        { name: "常见问题", value: "question", id: 8 }
    ]

    const enTabList = [
        { name: "HOMEPAGE", value: "/2024/regulation/detail", id: 1 },
        { name: "RULES", value: "/2024/regulation/rule", id: 2 },
        { name: "ARTWORKS", value: "/2024/regulation/work", id: 3 },
        { name: "IDEAS LABS", value: "", id: 4 },
        { name: "ABOUT GGAC", value: "/2024/regulation/about", id: 6 },
        { name: "HISTORY", value: "/2024/regulation/previous", id: 7 },
        { name: "Q&A", value: "question", id: 8 }
    ]

    const [currentTabList, setCurrentTabList] = useState<any[]>([])

    useEffect(() => {
        if (lang === 'zh_CN') {
            setCurrentTabList(tabList)
        } else {
            setCurrentTabList(enTabList)
        }
    }, [lang])

    useEffect(() => {
        getContestDetail({ code: "ggac6" }).then(res => {
            setDetail(res)
            if (res.onSale === 0) {
                navigate("/")
            }

            if (!link) {
                return
            }
            setTimeout(() => {
                const top = document && document?.getElementById(link)
                console.log(top)
                if (top) {
                    top.scrollIntoView();
                }
            }, 200)
        })
    }, [link])

    useEffect(() => {
        var screenHeight = window.innerHeight || document.documentElement.clientHeight;
        setScreenHeight(screenHeight)
    }, [])

    const openPopup = () => {
        setIsShowPopup(true)
    }


    useEffect(() => {
        const checkIsMobile = isMobile()
        const { pathname, search } = window.location
        if (checkIsMobile) {
            window.location.href = `https://m.ggac.com` + pathname + search
            return
        }
    }, [])

    useEffect(() => {
        // banner初始化展示图片,视频加载完成后替换为视频
        var videoElem = document.getElementById("videoElem");
        videoElem && videoElem.addEventListener("canplaythrough", () => {
            setVideoFinish(true)
        });
    }, [])

    const token = hasToken()

    const toApply = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        if (invitationCode) {
            navigate(`/g-contest/apply/${detail?.id}?invitationCode=${invitationCode}&code=ggac6`)
        } else {
            navigate(`/g-contest/apply/${detail?.id}?code=ggac6`)
        }
    }

    const toUpload = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        navigate(`/g-contest/upload/${detail?.id}`)
    }

    const changeTab = (value: string) => {
        if (!value) {
            return
        }
        if (value === 'question') {
            // window.open(contestJSON.questionHref)
            window.open("/official/2565")
            return
        }
        navigate(value)
    }

    const subMatchList = [
        { name: "TRYX裸眼3D大赛", value: "ly3d", id: 1 },
        { name: "渲染动画创意大赛", value: "xrtz", id: 2 },
        { name: "Style3D 特别奖", value: "Style3D", id: 3 },
        { name: "畅游创意大赛", value: "sims", id: 4 }
    ]

    const subMatchEnList = [
        { name: "3D Vision Contest", value: "ly3d", id: 1 },
        { name: "3D Render Contest", value: "xrtz", id: 2 },
        { name: "Style3D Special Award", value: "Style3D", id: 3 },
        { name: "SIM's Art Contest", value: "sims", id: 4 }
    ]

    const toDetail = (detail: { name: string, value: string, id: number }) => {
        if (detail.value === 'Style3D') {
            window.open("https://www.ggac.com/official/2576")
            return
        }
        window.open(`/contest/${detail.value}`)
    }

    const changeLang = () => {
        if (lang === 'en_GB') {
            localStorage.setItem('lang', "zh_CN")
        } else {
            localStorage.setItem('lang', "en_GB")
        }

        window.location.href = window.location.href

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })


    }

    return <Regtulation2024Style>
        <div className={`banner ${lang === 'en_GB' ? 'en-banner' : ''}`}>
            <div className="banner_content" style={{ height: `${screenHeight - 50}px` }}>
                {
                    <video
                        style={{ display: !videoFinish ? "none" : "block" }}
                        id="videoElem"
                        muted={true}
                        v-show="videoFinish"
                        src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-kv.mp4"
                        loop={true}
                        controls={false}
                        autoPlay
                    >
                        您的浏览器不支持 video 标签。
                    </video>
                }
                {
                    !videoFinish && <img className="banner_image" src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-banner.png" alt="" />
                }
                {
                    lang === 'zh_CN' && <div className="kv-word" style={{ backgroundImage: `url(${"https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-kv-word.png"})` }}></div>
                }

                {
                    lang === 'en_GB' && <div className="kv-word" style={{ backgroundImage: `url(${"https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-kv-word-en.png"})` }}></div>
                }

                <div className="btn-wrap">
                    {
                        detail && detail.canSignup === 1 && <div className="btn" onClick={toApply} lang="t_lang">立即报名</div>
                    }
                    {
                        detail && detail.canUpload === 1 && <div className="btn" onClick={toUpload} lang="t_lang">上传作品</div>
                    }
                    <div className="word">2024.5.16—2024.10.15</div>
                </div>

            </div>
            <div className="tab" id="regulation-tab">
                {
                    currentTabList.map(item => {
                        return <div onClick={() => changeTab(item.value)} className={`${window.location.pathname === item.value ? 'active' : ''} tab-item`} key={item.id}>
                            <span>{item.name}</span>
                            {
                                item.id === 4 && <div className="popup">
                                    {
                                        lang === 'zh_CN' && subMatchList.map(item => {
                                            return <div key={item.id}>
                                                <div onClick={() => toDetail(item)} className="popup-item">{item.name}</div>
                                            </div>
                                        })
                                    }

                                    {
                                        lang === 'en_GB' && subMatchEnList.map(item => {
                                            return <div key={item.id}>
                                                <div onClick={() => toDetail(item)} className="popup-item">{item.name}</div>
                                            </div>
                                        })
                                    }

                                </div>
                            }
                        </div>
                    })
                }
            </div>
        </div>
        <Modal footer={<></>} className="modal" getContainer={false} destroyOnClose={true} closable={false} centered={true} open={isShowPopup}>
            {
                detail && <RegulationPopup detail={detail} isShowPopup={isShowPopup} close={() => setIsShowPopup(false)} />
            }
        </Modal>
        {/* {
            window.location.pathname !== '/2024/regulation/work' && isShow && <div className="float">
                <div onClick={openPopup} className="float-btn"><div>立即查看</div></div>
            </div>
        } */}

        <FloatButton.Group shape="circle">
            <FloatButton.BackTop tooltip="回到顶部" />
            {
                lang === 'en_GB' && <FloatButton tooltip="Switch Language" onClick={() => changeLang()} className="lang" description="中" icon={<></>} />
            }
            {
                lang === 'zh_CN' && <FloatButton tooltip="切换语言" onClick={() => changeLang()} className="lang" description="En" icon={<></>} />
            }
        </FloatButton.Group>

        <Outlet context={{ detail: detail }}></Outlet>
    </Regtulation2024Style>
}

export default Regtulation2024
