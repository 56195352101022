import styled from "styled-components";

export const MasonaryImagesStyle = styled.div`
    .my-masonry-grid {
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

`