import { Table, Button, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UserAddressItem } from "types/user/address";
import { useState, useEffect, useCallback } from "react";
import {
  apiGetUserAddressList,
  apiPostDeleteAddress,
} from "api/user/address";
import { AddressListStyle } from "./style";
import { useGRoutes } from "utils/hooks/useGRoute";
import { RiAddFill, RiCheckboxCircleFill } from "react-icons/ri";

const UserAddress = () => {
  const [list, setList] = useState<UserAddressItem[]>([]);

  const getList = useCallback(async () => {
    const result = await apiGetUserAddressList();
    setList(result);
  }, []);

  // const setDefalt = (option: UserAddressItem) => {
  //   if (!!option.isDefault) {
  //     return;
  //   }
  //   setLoading(true);
  //   apiUpdateAddressIsDefalt(option.id)
  //     .then((res) => {
  //       getList();
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const columns: ColumnsType<UserAddressItem> = [

    {
      title: "收件人/手机号",
      dataIndex: "receiver",
      key: "receiver",
      width: 220,
      align: "left",
      render: (_, option) => (
        <div className="receiver_phone">
        <span>{option.receiver}</span>
        <span>{option.phone}</span>
        {option.isDefault ? <RiCheckboxCircleFill size={20} style={{color:'#79C5A9',paddingTop:5}} className="icon"/>: ""}
        </div>
  
      )
    },
    {
      title: "收货地址",
      dataIndex: "address",
      key: "address",
      width: 'auto',
      align: "left",
      render: (_, option) => (
   
          <div style={{textAlign:'left',maxHeight:"630px",wordBreak:"break-word"}}>{option.fullAddress}</div>

      ),
    },
    {
      title: "邮政编码",
      dataIndex: "postalCode",
      key: "postalCode",
      width: 90,
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "createTime",
      key: "createTime",
      width: 140,
      align: "center",
      render: (_, option) => (
        <div>
          <div className="btns">
            <Button
              className="btn"
              type="text"
              size="small"
              onClick={() => editAddress(option)}
            >
              编辑
            </Button>
            <Button
              className="btn"
              size="small"
              type="text"
              onClick={() => deleteAddress(option)}
            >
              删除
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const gRouter = useGRoutes();
  const toCreate = () => {
    gRouter("/user-center/address/create");
  };
  // 编辑地址
  const editAddress = (option: UserAddressItem) => {
    gRouter(`/user-center/address/edit/${option.id}`);
  };
  // 删除地址
  const deleteAddress = (option: UserAddressItem) => {
    Modal.confirm({
      title: "确定删除该地址？",
      content: '删除后无法找回',
      okText: "确定",
      cancelText: false,
      onOk: async () => {
        await apiPostDeleteAddress([Number(option.id)]);
        getList();
      },
    });
  };

  useEffect(() => {
    getList();
  }, [getList]);
  return (
    <AddressListStyle>
      <div className="table-wrap">
        <Button onClick={() => toCreate()} className="button" type="primary">
          <RiAddFill size={18}></RiAddFill>添加地址
        </Button>

        <Table
          pagination={false}
          className="table"
          rowKey="id"
          rowClassName="table"
          columns={columns}
          dataSource={list}
        ></Table>
      </div>
    </AddressListStyle>
  );
};

export default UserAddress;
