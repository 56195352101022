import styled from "styled-components";

export const  WorkCardStyle = styled.div`
    .container{
        width: 100%;
        position: relative;
       
        .radio-wrapper{
            position: absolute;
            top: ${(props)=> props.theme.space.marginXXS}px;
            right: ${(props)=> props.theme.space.marginXXS}px;
            z-index: 1000;
        }
        .cover-wrapper{
            position: relative;
            width: 100%;
            height: 100%;
            padding-top: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: ${(props)=> props.theme.border.borderRadius}px;
        }
        .title{
            margin-left: ${(props)=> props.theme.space.marginXXS}px;
            margin-top: 10px;
            overflow: hidden;
            white-space: nowrap; 
            text-overflow: ellipsis;
            color: ${(props)=> props.theme.colors.colorText};
        }
    }
`