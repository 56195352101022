import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {EnTrans} from './lang/en/index';
import {ZhTrans} from './lang/zh/index';

import {JaTrans} from './lang/ja/index';
import {KoTrans} from './lang/ko/index';
import { store } from 'utils/store';
import { changeStoreLanguage } from 'utils/store/reducer/config';
import { LanguageType } from 'types/enums/config-language-type';

const resources = {
    en_GB: {
        translation: EnTrans
    },
    zh_CN: {
        translation: ZhTrans
    },
    ja_JP: {
        translation: JaTrans
    },
    ko_KR: {
        translation: KoTrans
    },
};

i18n.use(initReactI18next).init({
    compatibilityJSON:'v3',
    resources,
    lng: 'zh_CN',
    interpolation: {
        escapeValue: false,
    },
});

export const i18nChangelanguage = (language: LanguageType) => {
    store.dispatch(changeStoreLanguage(language))
    i18n.changeLanguage(language)
    localStorage.setItem('lang',language)
}

export default i18n;
