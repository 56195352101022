import { AddressStyle } from "./style"
import { Form, Input, Cascader, Button, message, Checkbox } from "antd"
import { useState, useEffect, useCallback } from 'react'
import { apiGetChineseAddressList } from "api/common"
import { chineseAddressItem } from "types/common"
import { apiPostCreateUserAddress, apiGetUserAddressDetail, apiPostEditUserAddress } from "api/user/address"
import { useParams } from 'react-router-dom';
import GoBackTitle from "ui-lib/go-back"

const UserAddress = () => {
    const [countryList, setCountryList] = useState<chineseAddressItem[]>([])
    const [form] = Form.useForm<any>();
    const [provinceId, setProvinceId] = useState(0)
    const [cityId, setCityId] = useState(0)
    const [districtId, setDistrictId] = useState(0)
    const [loading, setLoading] = useState(false)
    const { id } = useParams<any>();
    const getAddress = useCallback(async () => {
        const result = await apiGetChineseAddressList()
        setCountryList(result)
    }, [])
    const save = () => {
        form.validateFields().then(async res => {
            setLoading(true)
            let func = id ? apiPostEditUserAddress : apiPostCreateUserAddress
            const form = Object.assign(res, { provinceId, cityId, districtId, isDefault: res.isDefault ? 1 : 0 })
            if (id) {
                form.id = id
            }
            func(form).then(() => {
                message.success('提交成功')
                back()
            }).finally(() => { setLoading(false) })

        })
    }
    const back = () => {
        window.history.back();
    }
    // 初始化地址信息
    const initAddressDetail = useCallback(async () => {
        let result
        if (id) {
            result = await apiGetUserAddressDetail(Number(id))
        }
        setProvinceId(result?.provinceId || 0)
        setCityId(result?.cityId || 0)
        setDistrictId(result?.districtId || 0)
        form.setFieldsValue({
            receiver: result?.receiver || '',
            phone: result?.phone || '',
            country: id ? [result?.provinceId, result?.cityId, result?.districtId ? result?.districtId : ''] : [],
            address: result?.address || '',
            postalCode: result?.postalCode,
            isDefault: result?.isDefault === 1 ? true : false
        })
        getAddress()
    }, [form, getAddress, id])
    const changeCountry = (selectedOptions: any[]) => {
        if (selectedOptions && selectedOptions.length > 0 && selectedOptions[0]) {
            setProvinceId(selectedOptions[0])
        }
        if (selectedOptions && selectedOptions.length > 0 && selectedOptions[1]) {
            setCityId(selectedOptions[1])
        }
        if (selectedOptions && selectedOptions.length > 0 && selectedOptions[2]) {
            setDistrictId(selectedOptions[2])
        }else {
            setDistrictId(0)
        }

    }
    // 校验省市区
    const limitCountry = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback()
        } else if ((value && value.length > 2) || (value && value.length === 2 && [264, 265, 266].includes(value[0])) || (value && value.length === 2 && [379, 436, 439, 476, 478].includes(value[1]))) {
            callback()
        } else {
            callback(new Error('请选择完整的地址'))
        }
    }
    useEffect(() => {
        initAddressDetail()
    }, [initAddressDetail])
    return <AddressStyle>
        <div className="form-wrap">
            <GoBackTitle onClick={() => back()}>返回</GoBackTitle>
            <Form
                labelCol={{ span: 6 }}
                style={{ maxWidth: 600 }}
                autoComplete="off"
                form={form}
                className="form"
            >
                <Form.Item
                    label="收件人"
                    name="receiver"
                    rules={[{ required: true, message: "请输入收件人名称" }]}
                >
                    <Input maxLength={50} placeholder="请输入收件人名称" />
                </Form.Item>
                <Form.Item
                    label="手机号"
                    name="phone"
                    rules={[{ required: true, message: "请输入手机号码" },
                    {
                        pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                        message: '请输入正确手机号'
                    }]}
                >
                    <Input placeholder="请输入收件人手机号" maxLength={11} />
                </Form.Item>
                <Form.Item
                    label="收货地址省市区"
                    name="country"
                    rules={[{ required: true, message: "请选择省市区" },
                    { validator: (rules, value, callback) => limitCountry(rules, value, callback) }
                    ]}
                >
                    <Cascader options={countryList} onChange={changeCountry} changeOnSelect fieldNames={{ 'label': "name", value: 'id' }} placeholder="请选择收货地址省市区" />
                </Form.Item>
                <Form.Item
                    label="详细地址"
                    name="address"
                    rules={[{ required: true, message: "请输入详细地址" }]}
                >
                    <Input maxLength={80} placeholder="请输入详细地址" />
                </Form.Item>
                <Form.Item
                    label="邮政编码"
                    name="postalCode"
                    rules={[{ pattern: new RegExp(/^[1-9]\d*$/, "g"), message: "邮政编码错误" }]}
                >
                    <Input placeholder="请输入邮政编码" maxLength={6} />
                </Form.Item>
                <Form.Item
                    label="设为默认地址"
                    name="isDefault"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <div className="btn-wrap">
                    <Button className="btn" loading={loading} type="primary" block onClick={() => save()}>保存</Button>
                </div>
            </Form>
        </div>
    </AddressStyle >
}

export default UserAddress