import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ScoreListStyle } from "./style";
import { Button, Pagination, Radio, RadioChangeEvent, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { queryUrl } from "utils/functions/common";
import { apiGetScoreWorkList, apiGetScoreWorkTaskDetail, apiSubmitWorksScore } from "api/contest";
import { ScoreTaskDetail, ScoreWorkListItem } from "types/contest";
import ScoreWorkCard from "components/uni-card/self-cards/scoreWorkCard";


function ScoreList() {
  const navigate = useNavigate();
  const localUser = JSON.parse(localStorage.getItem("user_info") || "{}");
  const { id } = useParams();
  const [list, setList] = useState<ScoreWorkListItem[]>([]);
  const [taskDetail, setTaskDetail] = useState<ScoreTaskDetail>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(1);
  const [messageApi, contextHolder] = message.useMessage();

  const pageSize = 48;
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber = Number(searchParams.get("pageNumber")) || undefined;
  let cat = Number(searchParams.get("cat"));
  let sortField = searchParams.get("sortField") || undefined;
  let scoreFilter = Number(searchParams.get("scoreFilter")) || undefined;
  let filterList = [
    {
      label: "最新",
      value: "lastSubmitTime",
    },
    {
      label: "最热",
      value: "threeDaysHot",
    },
    {
      label: "得分",
      value: "averageScore",
    },
  ];

  const changePage = (e: number) => {
    const url = queryUrl({ pageNumber: e });
    navigate(`?${url}`);
  };

  useEffect(() => {
    if(!localUser?.permissions?.includes('workScore')){
      messageApi.open({
        type: 'error',
        content: '当前登录用户没有权限！',
      });
      return
    }
    getList();
    getTaskDetail();
  }, [id, searchParams]);

  const getList = async () => {
    setLoading(true);
    const res = await apiGetScoreWorkList({
      mediaCategory: cat, //媒体类型
      needTotal: 1, //是否需要总数；默认需要，不需要总时，效率高
      pageNumber: pageNumber, //页码，默认1
      pageSize: pageSize, //页条数，最大100，默认10
      sortField: sortField, //排序字段：threeDaysHot最近3天热度值，averageScore平均分
      taskId: Number(id), //任务ID
      scoreFilter:scoreFilter
    });
    setList(res.pageData);
    setTotal(res.totalSize);
    setLoading(false);
  };

  const getTaskDetail = () => {
    apiGetScoreWorkTaskDetail({ taskId: Number(id),mediaCategory:cat }).then((res) => {
      setTaskDetail(res);
    });
  };

  const handleChangeSort = (value: string) => {
    searchParams.set('sortField',value);
    searchParams.set('pageNumber','1');
    setSearchParams(searchParams)
  };

  const handleChangeScope = (e: RadioChangeEvent)=>{
    searchParams.set('scoreFilter',e.target.value);
    setSearchParams(searchParams)
  }

  const finalSubmit = ()=>{
    apiSubmitWorksScore({  "mediaCategory": cat,
    "taskId": Number(id)}).then(() => {
      message.success('最终提交成功!')
      window.location.reload();
    })
  }

  return (
    <ScoreListStyle className="score-list">
      {contextHolder}
      <div className="header">
        {taskDetail?.mediaCategories.find((item) => item.id === cat)?.name}
        作品打分列表
      </div>
      <div className="task-info">
        <div className="info-item">
          <div className="label">创作分类</div>
          <div className="content">
            {taskDetail?.mediaCategories.find((item) => item.id === cat)?.name}
          </div>
        </div>
       
        <div className="info-item">
          <div className="label">开始时间</div>
          <div className="content">
            {taskDetail?.startTime.substring(0, 10)}
          </div>
        </div>
        <div className="info-item">
          <div className="label">结束时间</div>
          <div className="content">{taskDetail?.endTime.substring(0, 10)}</div>
        </div>
        <div className="info-item">
          <div className="label">提交状态</div>
          <div className="content">{taskDetail?.isSubmit?'已提交':'未提交'}</div>
        </div>
        <div className="info-item">
          <div className="label">打分评委</div>
          <div className="content">{localUser.username}</div>
        </div>
        <div className="info-item">
          <div className="label">比赛</div>
          <div className="content overflow-hidden" >
           {taskDetail?.dataName}</div>
        </div>
      </div>
      <div className="submit">
          <Button disabled={(taskDetail && taskDetail.isSubmit) ? true : false} className="btn" type="primary" style={{width:200}} onClick={finalSubmit}>最终提交</Button>
      </div>
      <div className="filter">
        <div className="type">
          {filterList.map((item) => {
            return (
              <div
                key={item.value}
                className={`type-item ${
                  item.value === sortField ? "active" : ""
                }`}
                onClick={() => handleChangeSort(item.value)}
              >
                <span>{item.label}</span>
              </div>
            );
          })}
        </div>
        <div className="right">
        <Radio.Group onChange={handleChangeScope} value={Number(searchParams.get("scoreFilter") )|| 1}>
      <Radio value={1}>全部</Radio>
      <Radio value={2}>只看已打分</Radio>
      <Radio value={3}>只看未打分</Radio>

    </Radio.Group>
        </div>
      </div>
      <div className="work-list">
        <Spin spinning={loading}>
          {taskDetail &&  <div className="list">
            {list.map((item, index) => {
              return <ScoreWorkCard taskMediaCategory={cat} key={index} item={item} needRefresh={getList} scoreTask={taskDetail}></ScoreWorkCard>;
            })}
          </div>}
         
        </Spin>
        <Pagination
          style={{ textAlign: "center", margin: "12px 0 42px 0" }}
          hideOnSinglePage={true}
          current={pageNumber}
          onChange={changePage}
          pageSize={pageSize}
          total={total}
          showSizeChanger={false}
        />
      </div>
    </ScoreListStyle>
  );
}

export default ScoreList;
