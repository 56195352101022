import { WalletInfoStyle } from "./style"
import UiTabs from "ui-lib/tab"
import { Outlet } from "react-router-dom"

const WallectInfo = () => {

    const userHomeTabs = [
        { key: 1, title: "钱包余额", url: '/user-center/wallet', urlGroup: ['/user-center/wallet', '/user-center/wallet/index'] },
        { key: 2, title: "优惠劵", url: '/user-center/wallet/coupon', urlGroup: ['/user-center/wallet/coupon'] },
    ];



    return <WalletInfoStyle>
        <UiTabs tabs={userHomeTabs}></UiTabs>
        <Outlet></Outlet>
    </WalletInfoStyle>
}

export default WallectInfo
