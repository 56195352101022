
import { RouteObject } from "react-router-dom";
import { Help } from 'views/static_page/help'
import { AboutUs } from 'views/static_page/about_us'
import AppDownload from "views/static_page/app_download";
import Layout from "views/layout";


export const staticRoutes:RouteObject[] = [
    {
        path: '/static/help',
        element:  <Help></Help>
    },
    {
        path: '/static/about/us',
        element:  <AboutUs></AboutUs>
    },

    {
        path: '/',
        element: <Layout></Layout>,
        children:[
            {
                path: 'static/app-download',
                element:  <AppDownload></AppDownload>
            },
        ]

    }    

]

