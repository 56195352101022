import { useMenuAnimation } from 'utils/hooks/useMenuAnimation';
import { UploadDorpdownStyle } from './style'
import { hasToken } from 'utils/functions/common';
import { loginPathWithRedirect } from 'utils/functions/user';
import { useNavigate } from 'react-router-dom';



function UploadDorpdown({ isOpen }: { isOpen: boolean }) {
  const scope = useMenuAnimation(isOpen, '.dropdown-item');
  let shopId = JSON.parse(localStorage.getItem('user_info')!)?.shopId
  const navigate = useNavigate()


  const createWorkDraft = async () => {
    window.open('/work/create', '_blank')
  }

  const onClickGoArticleCreate = () => {
    window.open('/article/create', '_blank')
  }

  const toStore = () => {
    if (!hasToken()) {
      navigate(loginPathWithRedirect())
      return
    }
    if (!!shopId) {
      navigate("/user-center/shop/product/create")
    } else {
      navigate("/user-center/shop/product-list")
    }
  }

  const toYuegao = () => {
    if (!hasToken()) {
      navigate(loginPathWithRedirect())
      return
    }
    if (!!shopId) {
      navigate("/user-center/shop/commission/product/create")
    } else {
      navigate("/user-center/shop/commission/product-list")
    }
  }

  return (
    <UploadDorpdownStyle ref={scope}>
      <div className='inner-container'>
        <div className='item-wrap'>
          <div className='dropdown-item' onClick={() => createWorkDraft()}>上传作品
          </div>
        </div>
        <div className='item-wrap'>
          <div className='dropdown-item' onClick={onClickGoArticleCreate}>上传文章</div>
        </div>
        <div className="item-wrap">
          <div className='dropdown-item'
            onClick={toStore}
          >
            发布商品
          </div>
        </div>
        <div className="item-wrap">
          <div className='dropdown-item'
            onClick={toYuegao}
          >
            发布约稿
          </div>
        </div>
        {/* <div className='item-wrap'>
        <div className='dropdown-item' onClick={()=>navigate('/user-center/home/work/list')}>创作中心</div>
        </div> */}
      </div>
    </UploadDorpdownStyle>
  )
}

export default UploadDorpdown