import styled from "styled-components";

export const ShippingTemplateStyle = styled.div`
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${(props) => props.theme.space.marginLG}px;
  }
  .title {
    font-size: 16px;
  }
  .list {
    .list-item {
      border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      margin: ${(props) => props.theme.space.margin}px auto;
      .header {
        background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
        display: flex;
        justify-content: space-between;
        padding: ${(props) => props.theme.space.padding}px
          ${(props) => props.theme.space.paddingMD}px;
      }
      .content {
        padding: ${(props) => props.theme.space.padding}px
          ${(props) => props.theme.space.paddingMD}px;
      }
    }
  }
`;

export const ShippingTemplateEditStyle = styled.div`
  .default-fee {
    display: flex;
    .form-label {
      padding-right: 8px;
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      &:before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
    span {
      display: block;
      padding: 5px 2px;
    }
  }
  .special-fee{
    display: flex;
    .link{
        margin-left: ${(props) => props.theme.space.margin}px;
        color: ${props => props.theme.colors.colorPrimary};
        &:hover{
            color: ${props => props.theme.colors.colorPrimaryActive};
            cursor: pointer;
        }
    }
  }
  .special-fee-list{
    width: 960px;
    .special-fee-list-item{
        border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      margin: ${(props) => props.theme.space.margin}px auto;
      .header {
        background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
        display: flex;
        justify-content: space-between;
        padding: ${(props) => props.theme.space.padding}px
          ${(props) => props.theme.space.paddingMD}px;
        .provinces{
          max-width: 80%;
        }
      }
      .content {
        padding: ${(props) => props.theme.space.padding}px
          ${(props) => props.theme.space.paddingMD}px;
      }
    }
  }
  .submit{
    display: flex;
    justify-content: center;
    width: 900px;
    margin-top:  ${(props) => props.theme.space.paddingLG}px;
    .btn{
        width: 160px;
    }
  }
`;

export const ProvinceModalStyle = styled.div`
    .check-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .check-item{

        }
    }
    .footer{
        display: flex;
        justify-content: space-between;
    }
`
