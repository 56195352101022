import { useCallback, useEffect, useState } from "react"
import { RecommandationStyle } from "./style"
import { getAbleAndTaList } from "api/work"
import { WorkAbleAndTaItem } from "types/user/work"
import RecommandItem from "./recommandItem"
import { WorkDetailType } from "types/work"

function Recommandation({ detail }: { detail: WorkDetailType }) {
 
  const [ableList, setAbleList] = useState<WorkAbleAndTaItem[]>([])
  const [taList, setTaList] = useState<WorkAbleAndTaItem[]>([])
  const getList = useCallback(() => {
    if (!detail) {
      return
    }
    getAbleAndTaList({ id: detail?.id, categoryId: detail?.categoryList?.[0]?.id }).then(res => {
      setAbleList(res.ableWorks)
      setTaList(res.taWorks)
    })
  }, [detail])

  useEffect(() => {
    getList()
  }, [getList])
  return (
    <RecommandationStyle>
       <div className="listWrap">
        {
          taList.length > 0 && <>
            <div className="title">TA的作品</div>
            <RecommandItem list={taList} />
          </>
        }
      </div>

      <div className="listWrap">
        {
          ableList.length > 0 && <>
            <div className="title">相关作品</div>
            <RecommandItem list={ableList} />
          </>
        }
      </div>
    </RecommandationStyle>
  )
}

export default Recommandation