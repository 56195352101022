import React from 'react'
import { AuthStyle } from './style'
import { Outlet } from "react-router-dom";

function AuthLayout() {
  return (
    <AuthStyle>
         <Outlet></Outlet>
    </AuthStyle>
  )
}

export default AuthLayout