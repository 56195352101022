import styled from "styled-components";

export const RegulationPopupStyle = styled.div`
    
    .content {
        padding-top: 160px;
        padding-right: 60px;
        position: relative;
        .close {
            width: 30px;
            cursor: pointer;
        }

        .close-wrap {
            margin-top: 90px;
            text-align: center;
        }
    }

    .btn {
        width: calc(208px / 1.1);
        height: calc(58px / 1.1);
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-btn.png") center center no-repeat;
        background-size: 100% 100%; 
        font-family: 'REEJI';
        font-size: 20px;
	    letter-spacing: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
	    color: #ffffff;
        cursor: pointer;
        margin-top: 25px !important;
    }

    .popup-title {
        text-align: center;
        font-size: 16px;
        font-family: kuaikanshijieti;
        color: #9fe0ff;
        position: relative;
        width: 300px;
        text-align: center;
        margin: 10px auto;
        img {
            width: 150px;
        }
        &::after {
            content: "";
            position: absolute;
            width: 300px;
            height: 12px;
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-title.png") center center no-repeat;
            background-size: 100% 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .popup-tip {
        text-align: center;
    }


    .desc-wrap {
        display: flex;
        color: #ffffff;
        gap: 3px;
        div:first-child {
            padding-top: 3px;
        }
    }
    .popup-desc,.popup-tip {
        color: #ffffff;
        line-height: 30px;
    }
`