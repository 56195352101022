import { MediaIconsStyle } from "./style"
import {  RiLiveLine } from "react-icons/ri";
import { WorkContestListItem } from "types/contest";
import { MediaType } from "types/enums/media-type";
import { WorkDraftItem, WorkListItem } from "types/work";

function MediaIconsWork({item}:{item: WorkListItem | WorkDraftItem | WorkContestListItem}) {
  return (
    <MediaIconsStyle>
          {/* 视频图标 */}
          {item.mediaTypeList?.includes(MediaType.video) && (
            <div className="item" title="视频作品">
              <RiLiveLine></RiLiveLine>
            </div>
          )}
             {/* 多图图标 */}
             {item.mediaTypeList?.includes(MediaType.multiImage) && (
            <div className="item" title="多图作品">
             <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/multi-img.svg" alt="" width={17}/>
            </div>
          )}
    </MediaIconsStyle>
  )
}

export default MediaIconsWork