import { Affix, Button, Form, Input, Select, Space, Spin, Tooltip, message } from "antd";
import { ArticleUpdateStyle } from "./style";
import { useEffect, useState } from "react";
import { TagSelect } from "components/tagSelect";
import { ImageCrop } from "components/crop-img";
import { getFileUrlByCosUpload } from "config/cos";
import { getFileName } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { useNavigate, useParams } from "react-router-dom";
import { WorkModal } from "components/work-modal";
import { DataItem } from "components/work-modal/type";
import { WorkCard } from "components/work-modal/components/work-card";
import {
  createArticleDraft,
  apiGetArticleDraftDetail,
  updateArticleDraft,
} from "api/article";
import TinyEditor from "ui-lib/tinyMCE";
import { getWorkCategory } from "api/work";
import { CategoryItem } from "types/user/work";
import Collabration, { collabrationUserSimple } from "views/work/update/collabration";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ArticleDraftDetailResponse } from "types/article";

type ArticleUpdateForm = {
  title: string;
  tagList: string[];
  type: number[] | number;
  categoryId: number;
  coverUrl: {
    file?: File;
    imgUrl?: string;
  };
  content: { html: string; media: number[] };
};

function ArticleUpdate() {
  const [top] = useState(64);
  const [form] = Form.useForm<ArticleUpdateForm>();
  const [coverUrlFile, setCoverUrlFile] = useState<{
    file?: File;
    imgUrl?: string;
  }>();
  const [coverUrl, setCoverUrl] = useState("");
  const [selectWorkShow, setSelectWorkShow] = useState(false);
  const [selectWorkList, setSelectWorkList] = useState<DataItem[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CategoryItem[]>([]);
  const [creatorList, setCreatorList] = useState<collabrationUserSimple[]>([]);
  const [detail,setDetail] = useState<ArticleDraftDetailResponse>()

  let navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    getCategoryOptions();
    if (!id) {
      form.setFieldsValue({
        title: "",
        tagList: [],
        categoryId: undefined,
        coverUrl: {
          file: undefined,
          imgUrl: "",
        },
        content: {
          html: "",
          media: [],
        },
      });
    } else {
      apiGetArticleDraftDetail(Number(id)).then((res) => {
        setDetail(res)
        setCoverUrlFile({
          file: undefined,
          imgUrl: res.coverUrl,
        });
        setSelectWorkList(
          res.workList?.map((item) => {
            return {
              ...item,
              checked: true,
            };
          })
        );
        setCoverUrl(res.coverUrl);
        form.setFieldsValue({
          title: res.title,
          tagList: res.tagList?.map((item) => item.name),
          type: res.type,
          categoryId: res.categoryList[1].id,
          coverUrl: {
            file: undefined,
            imgUrl: res.coverUrl,
          },
          content: {
            html: res.content,
            media: res.mediaList?.map((item) => item.id),
          },
        });
      });
    }
  }, [id, form]);

  const getCategoryOptions = () => {
    getWorkCategory({ type: 1, useArticleUpload: 1, level: 2 }).then((res) =>
      setCategoryOptions(res)
    );
  };

  const onClickSave = (submit: number, status?: string) => {
    if (!coverUrlFile?.imgUrl) {
      message.warning("请上传封面！");
      return;
    }
    if (loading) {
      message.error("有文件正在上传中,请稍后重试！");
      return;
    }

    if (id) {
      form.validateFields().then(async (values) => {
        let { title, tagList, categoryId, type, content } = values;
        let params = {
          id: Number(id),
          coverUrl: coverUrl,
          tagList: tagList,
          title: title, //标题
          categoryId: categoryId, // 类目ID
          submit: submit,
          content: content.html,
          mediaList: content.media,
          workIdList: selectWorkList?.map((item) => {
            return item.id;
          }),
          type: type,
          creators:creatorList
        };
        await updateArticleDraft(params);
        if (!status) {
          submit === 0 && message.success("保存草稿成功！");
          if (submit === 1) {
            message.success("提交成功！");
            navigate("/user-center/home/article/draft/list");
          }
        } else {
          window.open(`/article/preview/${Number(id)}`);
        }
      });
    } else {
      form
        .validateFields()
        .then(async (values) => {
         
          let { title, tagList, categoryId, content, type } = values;
          let params = {
            coverUrl: coverUrlFile?.imgUrl!,
            tagList: tagList,
            title: title, //标题
            categoryId: categoryId, // 类目ID
            submit: submit,
            type: type,
            content: content.html,
            mediaList: content.media,
            workIdList: selectWorkList?.map((item) => {
              return item.id;
            }),
            creators:creatorList
          };
          let res = await createArticleDraft(params);

          if (!status) {
            if (submit === 0) {
              message.success("创建草稿成功！");
              navigate(`/article/update/${res.id}`);
            }
            if (submit === 1) {
              message.success("提交成功！");
              navigate("/user-center/home/article/draft/list");
            }
          } else {
            navigate(`/article/update/${res.id}`);
            window.open(`/article/preview/${res.id}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [loading, setLoading] = useState(false);

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    setLoading(true);
    if (params.file) {
      let urlKey = getFileName(CosDirectoryType.articleCover, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey).finally(
        () => {
          setLoading(false);
        }
      );

      setCoverUrl(res.url);
      setCoverUrlFile({
        file: undefined,
        imgUrl: res.url,
      });
    }
  };

  const onChangeSelectWork = (val: DataItem[]) => {
    setSelectWorkList([...val]);
  };
  return (
    <ArticleUpdateStyle>
      <div className="main-container">
        <div className="left">
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            requiredMark={true}
            size="large"
            className="form-area"
            form={form}
          >
            <div className="form-section">
              <Form.Item
                label="标题"
                name="title"
                rules={[{ required: true, message: "标题为必填项" }]}
              >
                <Input placeholder="为您的文章起一个好听的名字" />
              </Form.Item>
              <Form.Item
                label="分类"
                name="categoryId"
                rules={[{ required: true, message: "类目为必填项" }]}
              >
                <Select
                  style={{ width: "50%" }}
                  options={categoryOptions?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
            </div>

            <div className="form-section">
              <Form.Item
                label="正文"
                name="content"
                rules={[{ required: true, message: "内容为必填项" }]}
              >
                {<TinyEditor cosImagePath={CosDirectoryType.articleImage} cosVideoPath={CosDirectoryType.articleVideo}></TinyEditor>}
              </Form.Item>
            </div>

            <div className="form-section">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.categoryId !== currentValues.categoryId
                }
              >
                {({ getFieldValue }) =>
                 <Form.Item label="标签" name="tagList">
                 <TagSelect
                   dataTable="article"
                   categoryId={getFieldValue("categoryId")}
                   maxCount={5}
                 ></TagSelect>
               </Form.Item>
                }
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="right">
          <div className="card">
            <div className="header">
              <div>
                <span style={{ color: "red", marginRight: "5px" }}>*</span>
                文章封面
              </div>
            </div>
            <div className="image-crop-wrapper">
              <Spin spinning={loading}>
                <ImageCrop
                  value={coverUrlFile}
                  onChange={(params: {
                    imgUrl: string;
                    file: File | undefined;
                  }) => {
                    setCoverUrlFile({ ...params });
                  }}
                  uploadImage={upload}
                />
              </Spin>
            </div>
            <div className="add-work-wrapper">
              <Button
                className="add-btn"
                type="primary"
                onClick={() => {
                  setSelectWorkShow(true);
                }}
              >
                关联作品
              </Button>

              {selectWorkShow && (
                <WorkModal
                  value={selectWorkList}
                  show={selectWorkShow}
                  close={() => {
                    setSelectWorkShow(false);
                  }}
                  onChange={onChangeSelectWork}
                ></WorkModal>
              )}
            </div>
            {selectWorkList && selectWorkList.length > 0 && (
              <div className="select-list-wrapper">
                {selectWorkList.map((item) => {
                  return (
                    <div className="list-item-wrapper" key={item.id}>
                      <WorkCard value={item}></WorkCard>
                    </div>
                  );
                })}
              </div>
            )}
             <div className="header">
              <span>共同创作</span>{" "}
              <Tooltip title="添加除本人外的共同创作人或导师，最多可添加5人">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
         <Collabration onListChange={(list)=>setCreatorList(list)} creators={detail?.creators}></Collabration>
          </div>

          <Affix offsetTop={top}>
            <div className="card">
              <div className="header">发布选项</div>

              <div className="footer">
                <Space direction="vertical" className="block-btn">
                  <Button onClick={() => onClickSave(0)} block>
                    保存草稿
                  </Button>
                  {/* <Button block onClick={() => onClickSave(0, 'pre')}>预览</Button> */}
                  <Button type="primary" onClick={() => onClickSave(1)} block>
                    立即发布
                  </Button>
                </Space>
              </div>
            </div>
          </Affix>
        </div>
      </div>
    </ArticleUpdateStyle>
  );
}

export default ArticleUpdate;
