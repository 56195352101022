import styled from "styled-components";

export const PublishedCardActionsStyle = styled.div`
  display: flex;
  justify-content: center;
  .action-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.2rem;
  }

  .ant-dropdown-menu {
    li:first-child {
      cursor: auto;
      &:hover {
          background-color: transparent !important;
      }
    }
  }

  .ant-dropdown-menu-item {
    &:hover {
        background-color: ${props => props.theme.colors.colorPrimary} !important;
    }
  }

  .ant-dropdown-menu-title-content {
    div {
      width: 100%;
      text-align: center;
      
    }
  }

`;

export const DrafWorkCardActionsStyle = styled.div`
  display: flex;
  justify-content: center;
  .action-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.2rem;
  }

  .ant-dropdown-menu {
    li:first-child {
      cursor: auto;
      &:hover {
          background-color: transparent !important;
      }
    }
  }

  .ant-dropdown-menu-item {
    &:hover {
        background-color: ${props => props.theme.colors.colorPrimary} !important;
    }
  }

  .ant-dropdown-menu-title-content {
    
    div {
      width: 100%;
      text-align: center;
      
    }
  }

`;

export const DrafWorkCardStatusStyle = styled.div`
  display: flex;
  align-items: center;
  gap:  ${(props) => props.theme.space.paddingXXS}px;
  margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
  margin-left: ${(props) => props.theme.space.paddingXXS}px;
  .refuse {
    /* background-color: transparent !important; */
  }
  .status {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #595959;
    color: ${(props) => props.theme.colors.colorTextLightSolid};
    padding: ${(props) => props.theme.space.paddingXXS}px
      ${(props) => props.theme.space.paddingXS}px;
     border-radius: 4px;
    font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
    position: relative;
    z-index: 2;
    .question {
      font-size: 16px;
      color: red;
      border-radius: 50%;
    }
  
  }
  .subtitle {
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4px 12px;
    color: rgb(255, 255, 255);
    border-radius: 6px;
    white-space: nowrap;
   }
`;

export const PublishWorkCardStatusStyle = styled.div`
  display: flex;
  align-items: center;
  gap:  ${(props) => props.theme.space.paddingXXS}px;
  margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
  margin-left: ${(props) => props.theme.space.paddingXXS}px;
  .refuse {
    background-color: transparent !important;
  }
  .status {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #595959;
    color: ${(props) => props.theme.colors.colorTextLightSolid};
    padding: ${(props) => props.theme.space.paddingXXS}px
      ${(props) => props.theme.space.paddingXS}px;
     border-radius: 4px;
    font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
    position: relative;
    z-index: 2;
    .question {
      font-size: 16px;
      color: red;
      border-radius: 50%;
    }
  }
  .subtitle {
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4px 12px;
    color: rgb(255, 255, 255);
    border-radius: 6px;
    white-space: nowrap;
   }
`
