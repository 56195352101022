export enum WorkCategory {
    art=  1,
    article= 2,
    shop= 3,
}

export const WorkCategoryArr  = [
   {name: '作品',  value: WorkCategory.art },
   {name: '文章',  value: WorkCategory.article },
   {name: '商品',  value: WorkCategory.shop },

]



