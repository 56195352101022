import { EventStyle } from "./style_0";
import data from "./data.json";
import { useEffect, useState } from "react";
import { isMobile } from "utils/functions/common";
import { Modal } from "antd";
function Xnwf0() {
  const [showQrcode ,setShowQrcode] = useState(false)
  const LogoTitle = (title: string) => {
    return (
      <div className="logo-title">
        <img
          src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/logo-title.png"
          alt=""
        />
        <span className="text_">{title}</span>
      </div>
    );
  };
  const openLink = (url: string) => {
    window.open(url, "_blank");
  }
  useEffect(() => {
    const checkIsMobile = isMobile()
    if (checkIsMobile) {
      window.location.href = `https://m.ggac.com` + window.location.pathname
      return
    }
  }, [])
  return (
    <EventStyle>
      <section className="top_">
        <img
          src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/banner.png"
          alt=""
          className="top-bg"
        />
        <div className="top-btn">
          <img
            src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/top-btn.png"
            alt=""
          />
        </div>
      </section>
      <section className="content">
        <div className="inner-container">
        <div className="welcome">
         <img className="left-deco" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/welcome-left-deco.png" alt="" />
         <img className="right-deco" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/welcome-right-deco.png" alt="" />
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: data.welcome }}
          ></div>
        </div>
        <div className="quanjiatong">
          <div className="section-title">
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/section-title-one.png"
              alt=""
            />
          </div>
          <div className="item-list">
            {data.quanjiatongCardList.slice(0, 4).map((item, index) => {
              return (
                <div className="item" key={index} >
                  <img
                    src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/image-frame.png"
                    alt=""
                  />
                  <div className="overlay">
                    <div
                      className="cover"
                      style={{ backgroundImage: `url(${item.cover})` }}
                    ></div>
                    <div className="name">{item.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="item-list sp">
            {data.quanjiatongCardList.slice(4, 10).map((item, index) => {
              return (
                <div className="item" key={index} >
                  <img
                    src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/image-frame.png"
                    alt=""
                  />
                  <div className="overlay">
                    <div
                      className="cover"
                      style={{ backgroundImage: `url(${item.cover})` }}
                    ></div>
                    <div className="name">{item.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="more">
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/quanjiatong-more.png"
              alt=""
            />
          </div>
        </div>
        <div className="huodongshijian">
          <div className="section-title">
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/section-title-2.png"
              alt=""
            />
          </div>
          <div className="time-content">
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/timeline.png"
              alt=""
            />
            <div className="time-grid">
              {data.huodongshijian1_4.map((item, index) => {
                return <span key={index}>{item}</span>;
              })}
            </div>
          </div>
        </div>
        <div className="red-part">
        <div className="section-title" style={{ marginTop: 100 }}>
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/red-part-weilaifu-title.png"
              alt=""
            />
          </div>
          <div className="container_ jingyefu weilaifu">
          <img  className="right-deco" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-jingyefu-right-deco.png" alt="" />
            <div className="overlay">
              <div className="slogan">
                <img
                  src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/red-part-weilaifu-slogan.png"
                  alt=""
                />
              </div>
              <div className="time">{data.weilaifu.time}</div>
              {LogoTitle("活动详情")}
              <div
                className="text"
                style={{ paddingBottom: 20 }}
                dangerouslySetInnerHTML={{ __html: data.weilaifu.text1 }}
              ></div>
              {LogoTitle("活动规则")}
              <div
                className="text sp"
                style={{ paddingTop: 0 }}
                dangerouslySetInnerHTML={{ __html: data.weilaifu.text2 }}
              ></div>
              <div className="btn" onClick={()=>window.open('/events/custom/xnwf/work','_blank')} style={{ marginTop: 130 }}>
                <img
                  src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/red-part-weilaifu-btn.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        <div className="section-title"  style={{ marginTop: 130 }}>
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/red-part-zaoniao-title.png"
              alt=""
            />
          </div>
          <div className="container_ zaoniaofu">
           <img  className="left-deco" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-zaoniaofu-left-deco.png" alt="" />
            <div className="overlay">
              <div className="slogan">
                <img
                  src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-zaoniaofu-slogan.png"
                  alt=""
                />
              </div>
              <div className="time">{data.zaoniaofu.time}</div>
              {LogoTitle("活动详情")}
              <div
                className="text"
                style={{ paddingTop: 0 }}
                dangerouslySetInnerHTML={{ __html: data.zaoniaofu.text1 }}
              ></div>
              {LogoTitle("活动规则")}
              <div
                className="text sp"
                style={{ paddingTop: 0 }}
                dangerouslySetInnerHTML={{ __html: data.zaoniaofu.text2 }}
              ></div>
              <div className="cards-1">
                <div className="item">
                <a className="a" href="https://www.ggac.com/product/detail/34" target="_blank" rel="noreferrer">
                  </a>
                  <a className="b" href="https://www.ggac.com/user/25/shop" target="_blank" rel="noreferrer"></a>
                </div>
                <div className="item">
                <a className="a" href="https://www.ggac.com/product/detail/35" target="_blank" rel="noreferrer">
                  </a>
                  <a className="b" href="https://www.ggac.com/user/25/shop" target="_blank" rel="noreferrer"></a>
                </div>
              </div>
              <div className="cards-2">
                <a className="top" href="https://www.ggac.com/user/3157/shop" target="_blank" rel="noreferrer"></a>
                <div className="row">
                    <a  href="https://www.ggac.com/product/detail/22" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/26" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/27" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/23" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/25" target="_blank" rel="noreferrer"></a>
                </div>
              </div>
              <div className="cards-3">
              <a className="top" href="https://www.ggac.com/user/697972/shop" target="_blank" rel="noreferrer"></a>
                <div className="row">
                    <a  href="https://www.ggac.com/product/detail/31" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/32" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/29" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/30" target="_blank" rel="noreferrer"></a>
                    <a  href="https://www.ggac.com/product/detail/28" target="_blank" rel="noreferrer"></a>
                </div>
              </div>
              <div className="tip">
              小G持续挖矿中，宝藏堆将陆续更新，敬请期待~
              </div>
         
            </div>          
          </div>
          <div className="section-title" style={{ marginTop: 100 }}>
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/red-part-jingyefu-title.png"
              alt=""
            />
          </div>
          <div className="container_ jingyefu">
          <img  className="right-deco" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-jingyefu-right-deco.png" alt="" />
            <div className="overlay">
              <div className="slogan">
                <img
                  src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/slogan1.png"
                  alt=""
                />
              </div>
              <div className="time">{data.jingyefu.time}</div>
              {LogoTitle("活动详情")}
              <div
                className="text"
                style={{ paddingBottom: 20 }}
                dangerouslySetInnerHTML={{ __html: data.jingyefu.text1 }}
              ></div>
              {LogoTitle("活动规则")}
              <div
                className="text sp"
                style={{ paddingTop: 0 }}
                dangerouslySetInnerHTML={{ __html: data.jingyefu.text2 }}
              ></div>
              <div className="btn" onClick={()=>openLink('/work/create')}>
                <img
                  src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/jingyefu-btn.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        
        </div>   
        <div className="red-part sp" >
        <div className="section-title" >
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/red-part-hulifu-title.png"
              alt=""
            />
          </div>
          <div className="container_ hualifu">
          <img  className="left-deco" src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-hualifu-left-deco.png" alt="" />
            <div className="overlay">
              <div className="slogan">
                <img
                  src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/slogan2.png"
                  alt=""
                />
              </div>
              <div className="time">{data.hualifu.time}</div>
              {LogoTitle('活动详情')}
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: data.hualifu.text1 }}
              ></div>
               {LogoTitle('活动规则')}
              <div
                className="text sp"
                dangerouslySetInnerHTML={{ __html: data.hualifu.text2 }}
              ></div>
              <div className="list one">
                <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/hualifu-main-list-1.png" alt="" />
                <div className="over-lay">
                    <div className="click-item" onClick={()=>openLink('/product/detail/12')}></div>
                    <div className="click-item" onClick={()=>openLink('/product/detail/11')}></div>
                    <div className="click-item" onClick={()=>openLink('/product/detail/9')}></div>
                </div>
              </div>
              <div className="list two">
                <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/hualifu-main-list-2.png" alt="" />
                <div className="over-lay">
                    <div className="click-item" onClick={()=>openLink('/product/detail/10')}></div>
                    <div className="click-item" onClick={()=>openLink('/product/detail/21')}></div>
                    <div className="click-item" onClick={()=>openLink('/product/detail/7')}></div>
                </div>
              </div>
              <div className="list three"  onClick={()=>openLink('/user/25/shop')}>
                <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/hualifu-main-list-3.png" alt="" />
              </div>
              <div>
                <p style={{color:'#a93c1c'}}>小G持续挖矿中，宝藏堆将陆续更新，敬请期待~</p>
              </div>
            </div>
          </div>
          </div>
        <div className="kaidian">
          <img
            src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/kaidian.png"
            alt=""
          />
          <div className="btn"  onClick={()=>openLink('https://wj.qq.com/s2/13760537/2d03/')}>
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/kaidian-btn.png"
              alt=""
            />
          </div>
        </div>
        <div className="fuli">
          <img
            src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/fuli.png"
            alt=""
          />
        </div>
        <div className="jingqingqidai-1">
          <img
            src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/jingqingqidai.png"
            alt=""
          />
        </div>
        <div className="gengduohuodong">
          <div className="section-title">
            <img
              src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/more-huodong-title.png"
              alt=""
            />

          </div>
          <div className="more-event">
            <img src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/more-event.png" alt="" />
            <div className="overlay">
                <div className="click-item" onClick={()=>openLink('/contest/wukong/detail')}></div>
                <div className="click-item" onClick={()=>openLink('/contest/ty/detail')}></div>
                <div className="click-item" onClick={()=>openLink('/contest/ycwl2/detail')}></div>
            </div>
          </div>
        </div>
        <div className="statement">
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: data.statement }}
          ></div>
        </div>
        <div className="footer">
          <img
            src="https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/footer.png"
            alt=""
          />
        </div>
        </div>
      </section>
      <Modal title="用微信扫描二维码参加活动" open={showQrcode} onOk={()=>setShowQrcode(false)} onCancel={()=>setShowQrcode(false)} cancelButtonProps={{style:{display:'none'}}} okButtonProps={{style:{display:'none'}}}>
       <div className="content" style={{display:'flex',justifyContent:'center',padding:'20px 0'}}>
        <img src="https://cdn-prd.ggac.com/settings/bg/year_report/qr_code.png" alt="" width={300}/>
       </div>
      </Modal>
    </EventStyle>
  );
}

export default Xnwf0;
