import { useNavigate, useParams } from "react-router-dom"
import { ReceiveOrderDetailStyle } from "./style"
import { useCallback, useEffect, useState } from "react"
import { getReceiveOrderDetail, getRewardFileList } from "api/forum"
import { ForumOrderDeliveryDocuments, ForumSellerOrderDetail } from "api/forum/type"
import { Image, Spin } from "antd"
import { RiDownload2Line, RiFileCopy2Line } from "react-icons/ri"

const ReceiveOrderDetail = () => {

    const orderNumber = useParams()?.orderNumber

    const navigate = useNavigate()

    const [orderDetail, setOrderDetail] = useState<ForumSellerOrderDetail>()

    const [deliverOrderDetail, setDeliverOrderDetail] = useState<ForumOrderDeliveryDocuments>()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!orderNumber) {
            return
        }
        setLoading(true)
        getReceiveOrderDetail(orderNumber).then(res => {
            setOrderDetail(res)
        }).finally(() => {
            setLoading(false)
        })

    }, [orderNumber])

    const getDetail = useCallback(() => {
        if (!orderDetail) {
            return
        }
        if (![2, 3].includes(orderDetail?.state)) {
            return
        }
        getRewardFileList(orderDetail.orderNumber).then(res => {
            setDeliverOrderDetail(res)
        })
    }, [orderDetail])

    useEffect(() => {
        getDetail()
    }, [getDetail])

    const link = () => {
        navigate(`/forum/reward/receive-order`, { replace: true })
    }

    return <ReceiveOrderDetailStyle>
        {
            !!orderDetail && <Spin spinning={loading}>
                <div className="detail">
                    <div className="list-title">
                        <div className="link" onClick={() => link()}>
                            返回 收款订单
                        </div>
                        <span>{`>`}</span>订单详情
                    </div>
                    <div className="detail-item quesion">
                        <div className="label">问题标题：</div>
                        <div className="value">{orderDetail?.topicTitle}</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">提问用户：</div>
                        <div className="value">{orderDetail?.username}</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">订单编号：</div>
                        <div className="value">{orderDetail?.orderNumber}</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">订单状态：</div>
                        <div className="value">{orderDetail?.state === 0 ? '待支付' : orderDetail?.state === 1 ? '已支付' : orderDetail?.state === 2 ? '已回答，待确认' : orderDetail?.state === 3 ? '已完成' : "已取消"}</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">支付金额：</div>
                        <div className="value">{orderDetail?.amount}元</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">订单创建时间：</div>
                        <div className="value">{orderDetail?.createTime}</div>
                    </div>
                    {
                        orderDetail?.paidTime && <div className="detail-item">
                            <div className="label">订单支付时间：</div>
                            <div className="value">{orderDetail?.paidTime}</div>
                        </div>
                    }
                    {
                        orderDetail?.imageList && orderDetail?.imageList.length > 0 && <div className="detail-item  image-list">
                            <div className="label">图片列表：</div>
                            <div className="value image-wrap">
                                <div className="image" style={{
                                    justifyContent: orderDetail.imageList.length < 4 ? 'flex-end' : 'flex-start',
                                }}>
                                    {
                                        orderDetail?.imageList.map(item => {
                                            return <Image src={item} className="image-item" key={item} />
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        orderDetail?.fileList && orderDetail?.fileList.length > 0 && <div className="detail-item">
                            <div className="label">源文件列表：</div>
                            <div className="value image-wrap">
                                <div className="image" style={{
                                    justifyContent: orderDetail.fileList.length < 4 ? 'flex-end' : 'flex-start',
                                }}>
                                    {
                                        orderDetail?.fileList.map(item => {
                                            return <>
                                                {
                                                    ['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <Image src={item} className="image-item" key={item} />
                                                }
                                                {
                                                    !['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <div className="file-item">
                                                        <RiFileCopy2Line size={30} />
                                                        <div className="block">
                                                            <RiDownload2Line onClick={() => window.open(item)} color="#fff" size={16} />
                                                            <div className="word" onClick={() => window.open(item)}>下载文件</div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        orderDetail?.fileSupplement && <div className="detail-item fileSupplement">
                            <div className="label">源文件链接：</div>
                            <div className="value">{orderDetail?.fileSupplement}</div>
                        </div>
                    }

                    {
                        [2, 3].includes(orderDetail?.state) && <div className="deliver-content">
                            <div className="deliver-title">回答详情</div>
                            {
                                deliverOrderDetail?.content && <div className="detail-item">
                                    <div className="label">答案描述：</div>
                                    <div className="value">{deliverOrderDetail?.content}</div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.imageList && deliverOrderDetail?.imageList.length > 0 && <div className="detail-item second-image-list">
                                    <div className="label">图片列表：</div>
                                    <div className="value image-wrap">
                                        <div className="image" style={{
                                            justifyContent: orderDetail.imageList.length < 4 ? 'flex-end' : 'flex-start',
                                        }}>
                                            {
                                                deliverOrderDetail?.imageList.map(item => {
                                                    return <Image src={item} className="image-item" key={item} />
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.fileList && deliverOrderDetail?.fileList.length > 0 && <div className="detail-item">
                                    <div className="label">源文件列表：</div>
                                    <div className="value image-wrap">
                                        <div className="image" style={{
                                            justifyContent: orderDetail.imageList.length < 4 ? 'flex-end' : 'flex-start',
                                        }}>
                                            {
                                                deliverOrderDetail?.fileList.map(item => {
                                                    return <>
                                                        {
                                                            ['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <Image src={item} className="image-item" key={item} />
                                                        }
                                                        {
                                                            !['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <div className="file-item">
                                                                <RiFileCopy2Line size={30} />
                                                                <div className="block">
                                                                    <RiDownload2Line onClick={() => window.open(item)} color="#fff" size={16} />
                                                                    <div className="word" onClick={() => window.open(item)}>下载文件</div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.fileSupplement && <div className="detail-item fileSupplement">
                                    <div className="label">源文件链接：</div>
                                    <div className="value">{deliverOrderDetail?.fileSupplement}</div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.createTime && <div className="detail-item">
                                    <div className="label">提交时间：</div>
                                    <div className="value">{deliverOrderDetail?.createTime}</div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </Spin>
        }
    </ReceiveOrderDetailStyle>
}

export default ReceiveOrderDetail