import { useNavigate, useParams } from "react-router-dom"
import { RewardOrderDetailStyle } from "./style"
import { useCallback, useEffect, useState } from "react"
import { getRewardFileList, getRewardOrderDetail } from "api/forum"
import { ForumOrderDeliveryDocuments, ForumOrderDetail } from "api/forum/type"
import { Button, Divider, Image, Modal, QRCode, Spin, message } from "antd"
import { apiPostAliPayCommodity, apiPostWeixinPayCommodity } from "api/commodity"
import { PayType } from "types/enums/commodity-pay-type"
import { postSearchOrderSuccess } from "api/shop"
import { RiDownload2Line, RiFileCopy2Line } from "react-icons/ri"

const RewardOrderDetail = () => {

    const orderNumber = useParams()?.orderNumber

    const [orderDetail, setOrderDetail] = useState<ForumOrderDetail>()

    const [payType, setPayType] = useState(1);

    const [loading, setLoading] = useState(false)

    const [weixinCode, setWeixinCode] = useState("")

    const [weixinVisible, setWeixinVisible] = useState(false)

    let weixinTimer: any = null // 微信定时器

    const [codeStatus, setCodeStatus] = useState<"active" | "expired" | "loading">("loading")

    const navigate = useNavigate()

    const [deliverOrderDetail, setDeliverOrderDetail] = useState<ForumOrderDeliveryDocuments>()

    const [initLoading, setinitLoading] = useState(false)

    const payList = [
        {
            name: "微信支付",
            image: "https://cdn.ggac.com/img/copyright-pay-wx.png",
            id: 1,
        },
        {
            name: "支付宝",
            image: "https://cdn.ggac.com/img/copyright-pay-zfb.png",
            id: 2,
        },
    ];

    // 查询订单支付状态，最多查询100次
    const loopCheckOrderStatus = useCallback((value: string) => {
        let num = 0;
        weixinTimer = setInterval(() => {
            // 开启定时器
            if (num >= 100) {
                clearInterval(weixinTimer);
                return;
            }
            searchOrderSuccess(String(orderNumber));
            num++;
        }, 1500);
    }, [weixinTimer])



    const getDetail = useCallback(() => {
        if (!orderNumber) {
            return
        }
        setinitLoading(true)
        getRewardOrderDetail(orderNumber).then(res => {
            setOrderDetail(res)
        }).finally(() => {
            setinitLoading(false)
        })
    }, [orderNumber])

    useEffect(() => {
        getDetail()
    }, [getDetail])

    // 查询订单是否支付成功
    const searchOrderSuccess = async (value: string) => {
        const result = await postSearchOrderSuccess(value)
        if (result.state === 1) {
            message.success("支付成功")
            setWeixinVisible(false)
            clearInterval(weixinTimer);
            getDetail()
        }
    }

    const getDeliverDetail = useCallback(() => {
        if (!orderDetail) {
            return
        }
        if (![2, 3].includes(orderDetail?.state)) {
            return
        }
        getRewardFileList(orderDetail.orderNumber).then(res => {
            setDeliverOrderDetail(res)
        })
    }, [orderDetail])

    useEffect(() => {
        getDeliverDetail()
    }, [getDeliverDetail])



    const getWeixinCode = (value: string) => {
        setWeixinCode(value)
        setWeixinVisible(true)
        loopCheckOrderStatus(value)
        setCodeStatus("active")
    }

    const onRefreshCode = () => {
    }

    const pay = async () => {
        if (PayType.COMMODITY_PAY_WEIXIN === payType) {
            setLoading(true);
            const payDetail = await apiPostWeixinPayCommodity(String(orderNumber));
            getWeixinCode(payDetail.code_url);
        } else if (PayType.COMMODITY_PAY_ALI === payType) {
            const payDetail = await apiPostAliPayCommodity({
                orderNumber: String(orderNumber),
                returnUrl: `forum/reward/order/${orderNumber}`,
            });
            var form = payDetail.body;
            const div = document.createElement("div");
            div.innerHTML = form; //此处form就是后台返回接收到的数据
            document.body.appendChild(div);
            document.forms[0].submit();
        }
        setLoading(false);
    }

    const link = () => {
        navigate(`/forum/reward/order/list`, { replace: true })
    }

    

    return <RewardOrderDetailStyle>
        <div className="list">

            <div className="list-title">
                <div className="link" onClick={() => link()}>
                    返回 付款订单
                </div>
                <span>{`>`}</span>订单详情
            </div>
        </div>


        {
            !!orderDetail && <Spin spinning={initLoading}>
                <div className="detail">
                    <div className="word">支付金额由平台监管，整个咨询结束后，由您确认收货后才会打入到对方账户</div>
                    <div className="detail-item quesion">
                        <div className="label">问题标题：</div>
                        <div className="value">{orderDetail?.topicTitle}</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">回答用户：</div>
                        <div className="value">{orderDetail?.sellerUsername}</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">订单编号：</div>
                        <div className="value">{orderDetail?.orderNumber}</div>
                    </div>
                    <div className="detail-item">
                        <div className="label">订单状态：</div>
                        <div className="value">{orderDetail?.state === 0 ? '待支付' : orderDetail?.state === 1 ? '已支付' : orderDetail?.state === 2 ? '已回答，待确认' : orderDetail?.state === 3 ? '已完成' : "已取消"}</div>
                    </div>

                    <div className="detail-item">
                        <div className="label">支付金额：</div>
                        <div className="value">{orderDetail?.amount}元</div>
                    </div>

                    <div className="detail-item">
                        <div className="label">订单创建时间：</div>
                        <div className="value">{orderDetail?.createTime}</div>
                    </div>
                    {
                        orderDetail?.paidTime && <div className="detail-item">
                            <div className="label">订单支付时间：</div>
                            <div className="value">{orderDetail?.paidTime}</div>
                        </div>
                    }


                    {
                        orderDetail?.imageList && orderDetail?.imageList.length > 0 && <div className="detail-item image-list">
                            <div className="label">图片列表：</div>
                            <div className="value image-wrap">
                                <div className="image" style={{
                                    justifyContent: orderDetail.imageList.length < 4 ? 'flex-end' : 'flex-start',
                                }}>
                                    {
                                        orderDetail?.imageList.map(item => {
                                            return <Image src={item} className="image-item" key={item} />
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        orderDetail?.fileList && orderDetail?.fileList.length > 0 && <div className="detail-item">
                            <div className="label">源文件列表：</div>
                            <div className="value image-wrap">
                                <div className="image" style={{
                                    justifyContent: orderDetail.fileList.length < 4 ? 'flex-end' : 'flex-start',
                                }}>
                                    {
                                        orderDetail?.fileList.map(item => {
                                            return <>
                                                {
                                                    ['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <Image src={item} className="image-item" key={item} />
                                                }
                                                {
                                                    !['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <div className="file-item">
                                                        <RiFileCopy2Line size={30} />
                                                        <div className="block">
                                                            <RiDownload2Line onClick={() => window.open(item)} color="#fff" size={16} />
                                                            <div className="word" onClick={() => window.open(item)}>下载文件</div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        orderDetail?.fileSupplement && <div className="detail-item fileSupplement">
                            <div className="label">源文件链接：</div>
                            <div className="value">{orderDetail?.fileSupplement}</div>
                        </div>
                    }


                    {
                        [2, 3].includes(orderDetail?.state) && <div className="deliver-content">
                            <div className="deliver-title">回答详情</div>
                            {
                                deliverOrderDetail?.content && <div className="detail-item">
                                    <div className="label">答案描述：</div>
                                    <div className="value">{deliverOrderDetail?.content}</div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.imageList && deliverOrderDetail?.imageList.length > 0 && <div className="detail-item second-image-list">
                                    <div className="label">图片列表：</div>
                                    <div className="value image-wrap">
                                        <div className="image" style={{
                                            justifyContent: orderDetail.imageList.length < 4 ? 'flex-end' : 'flex-start',
                                        }}>
                                            {
                                                deliverOrderDetail?.imageList.map(item => {
                                                    return <Image src={item} className="image-item" key={item} />
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.fileList && deliverOrderDetail?.fileList.length > 0 && <div className="detail-item">
                                    <div className="label">源文件列表：</div>
                                    <div className="value image-wrap">
                                        <div className="image" style={{
                                            justifyContent: orderDetail.imageList.length < 4 ? 'flex-end' : 'flex-start',
                                        }}>
                                            {
                                                deliverOrderDetail?.fileList.map(item => {
                                                    return <>
                                                        {
                                                            ['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <Image src={item} className="image-item" key={item} />
                                                        }
                                                        {
                                                            !['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <div className="file-item">
                                                                <RiFileCopy2Line size={30} />
                                                                <div className="block">
                                                                    <RiDownload2Line onClick={() => window.open(item)} color="#fff" size={16} />
                                                                    <div className="word" onClick={() => window.open(item)}>下载文件</div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.fileSupplement && <div className="detail-item fileSupplement">
                                    <div className="label">源文件链接：</div>
                                    <div className="value">{deliverOrderDetail?.fileSupplement}</div>
                                </div>
                            }
                            {
                                deliverOrderDetail?.createTime && <div className="detail-item">
                                    <div className="label">提交时间：</div>
                                    <div className="value">{deliverOrderDetail?.createTime}</div>
                                </div>
                            }
                        </div>
                    }

                    {
                        orderDetail?.state === 0 && <>
                            <div className="detail-item">
                                <div className="label">支付方式：</div>
                                <div className="value">
                                    <div className="pay">
                                        {payList.map((item) => {
                                            return (
                                                <div
                                                    onClick={() => setPayType(item.id)}
                                                    className={`pay-item ${payType === item.id ? "active" : ""
                                                        }`}
                                                    key={item.id}
                                                >
                                                    <img src={item.image} alt="" />
                                                    <div className="name">{item.name}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="btn">
                                <Button type="primary" onClick={pay} loading={loading}>立即支付</Button>
                            </div>
                        </>
                    }

                    <Modal className='modal' centered={true} footer={null} getContainer={false} onCancel={() => { setWeixinVisible(false); clearInterval(weixinTimer); }} open={weixinVisible}>
                        <div className="title">请扫码进行微信支付</div>
                        <div className="code-wrap">
                            {
                                weixinCode && <QRCode
                                    errorLevel="H"
                                    value={weixinCode}
                                    size={200} // 二维码的大小
                                    onRefresh={onRefreshCode}
                                    status={codeStatus}
                                />
                            }
                        </div>
                    </Modal>
                </div>
            </Spin>
        }
    </RewardOrderDetailStyle>
}

export default RewardOrderDetail