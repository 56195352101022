import { getEventList } from "api/events";
import { CardStyle } from "./style";
import { useEffect, useState } from "react";
import { eventListResponse } from "types/events";
import { ImageObjectCloudResize } from "utils/functions/file";

function EventCard() {
  const [eventData, setEventData] = useState<eventListResponse[]>([])
  useEffect(() => {
    getEventList({ pageSize: 6 }).then(res => {
      setEventData(res.pageData)
    })
  }, [])

  return (
    <CardStyle>
      {eventData.slice(0, 6).map((item) => {
        return (
          <div className="card" key={item.id}>
            <a href={`${item.webUrl}`} target="_blank" rel="noreferrer">
              <div
                className="cover"
                style={{ backgroundImage: `url(${ImageObjectCloudResize(item.coverUrl, 500)})` }}
              >
                <div className="date" >{item.customText ? item.customText : item.day > 0 ? `距离活动结束还有${item.day}天` : item.customText}</div>
              </div>
            </a>
            <a href={`${item.webUrl}`} target="_blank" rel="noreferrer">
              <div className="content">
                <div className="flex">

                  <div className="upper">
                    <div className="title" title={item.name}>{item.name}</div>
                  </div>

                </div>
              </div>
            </a>
          </div>
        );
      })}
    </CardStyle>
  );
}

export default EventCard;
