import styled from "styled-components";

export const CreateAlbumStyle = styled.div`
    .title {
        text-align: center;
        font-weight: bold;
        margin-bottom: ${props => props.theme.space.paddingLG}px;
        font-size: 18px;
    }
    .btns {
        margin-top: ${props => props.theme.space.paddingLG}px;
        text-align: center;
        button:last-child {
            border-color: ${props => props.theme.colors.colorPrimary};
            color: ${props => props.theme.colors.colorPrimary};
            margin-left: ${props => props.theme.space.margin}px;
        }
    }
`