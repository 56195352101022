import styled from "styled-components";

export const CategoryFilterStyle = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.space.margin}px;
  .row-1 {
    display: inline-flex;
    justify-content: center;
    gap: 18px;
    width: 100%;
    align-items: center;
    .block {
      min-width: calc((100%  - 80px) / 9) !important;
      max-width: calc((100%  - 80px) / 9) !important;
      height: 51px;
      background-color: ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      font-weight: bold;
      &:hover {
        .text {
          color: ${(props) => props.theme.colors.colorPrimaryText};
        }
      }
      .block-overlay {
        content: "";
        display: block;
        position: absolute;
        border-radius: 6px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        filter: brightness(50%) saturate(220%);
      }
      .text {
        position: absolute;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.active {
          color: ${(props) => props.theme.colors.colorPrimaryText};
        }
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: 100%;
        text-align: center;
      }
    }
  }

  .category-wrap {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    min-width: 1300px;
    .category-left {
      display: flex;
      width: calc(100% - 170px);
      gap: 10px;
      .cat-one {
        display: flex;
        gap: 10px;
        .active {
          background-color: ${props => props.theme.colors.colorPrimary};
        }
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 150px;
          aspect-ratio: 150 / 110;
	        background-color: #f1f1f3;
	        border-radius: 8px;
	        color: #2b2e36;
          cursor: pointer;
          .icon {
            width: 23px;
          }
        }
      }
      .cat-two {
        display: flex;
        gap: 10px;
        flex-direction: column;
        flex-wrap: wrap;
        height: 110px;
        width: calc((100% - (150px * 3 + 10px * 3) - (4 * 10px) ));
        align-content: baseline;
        .active {
          background-color: ${props => props.theme.colors.colorPrimary};
        }
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: calc(100%  / 5);
	        height: 50px;
          background-color: #f1f1f3;
	        border-radius: 4px;
          cursor: pointer;
          min-width: 100px;
        }
      }
    }

    .category-right {
      .list {
        display: flex;
        gap: 20px;
        .circle {
          width: 65px;
          height: 65px;
          border-radius: 50%;
          background-color: ${props=>props.theme.colors.colorPrimary};
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 23px;
            aspect-ratio: 40 / 41;
          }
        }
        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 10px;
          cursor: pointer;
          .icon {
            width: 65px;
          }
        }
      }
    }
    
  }

  .control {
      font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
      border-radius: 50% 50%;
      width: 28px;
      height: 28px;
      background-color: #F1F1F3;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .tags_container  {
      width: 100%;
      overflow: hidden;
    }
    .first-select {
      .row-2 {
        border-bottom: none !important;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .select {
      width: 100%;
      .row-2 {
        justify-content: flex-start;
        gap: 54px;
        .tag {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  .row-2 {
    margin: ${(props) => props.theme.space.margin}px auto;
    display: flex;
    justify-content: center;
    gap: 12px;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: ${props => props.theme.space.padding}px;
    position: relative;
    .tag {
      padding: 6px 0;
      cursor: pointer;
      text-align: center;
      color: #8f8f8f;
      width: max-content;
      &.active {
        color: #2b2e36;
        font-weight: bold;
      }
      &:hover {
        color: #2b2e36;
        font-weight: bold;
      }
      border-radius: 26px;
      text-align: center;
      width: auto;
    }
    
  }
  .meta{
    margin: 0 auto;
    text-align: center;
    height:22px;
    .name{
        font-size: 20px;
        font-weight: 500;
    }
    .desc{
        color: #8C8C8C;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        svg {
          color: rgba(0, 0, 0, 0.25);
          cursor: pointer;
          &:hover {
            color: rgba(0, 0, 0, 0.45);
          }
        }
    }
  }
`;
