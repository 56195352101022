import styled from "styled-components";


export const NftAlbumDetailStyle = styled.div`
    .album {
        padding: 100px 0;
        .album-inner {
            width: 1200px;
            margin: 0 auto;
        }
        .top {
            .name {
                    font-weight: bold;
                    font-size: 30px;
                }
                .tag {
                    display: flex;
                    gap: 15px;
                    margin-top: 5px;
                    padding-bottom: ${props => props.theme.space.paddingLG}px;
                    border-bottom: 1px solid #e6e0e0;
                }
                .nft-count {
                    background: url("https://cdn-prd.ggac.com/ggac/nft/nft-ablum/nft-list-item-bg.png") center center no-repeat;
                    background-size: 100%;
                    width: 120px;
                    aspect-ratio: 237 / 40;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 10px;
                }

                .chain-wrap {
                    background: linear-gradient(90deg, #fce3cf 0%, #fadfc8 31%, #f2cba2 100%);
                    padding: 2px;
                    width: max-content;
                }

                .chain {
                    width: max-content;
                    background-color: #fff;
                    padding: 0 5px;
                    font-size: 12px;
                }

        }
        .btn-content {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
        &-content {
            display: flex;
            gap: 50px;
            margin-top: ${props => props.theme.space.marginLG}px;
            .left {
                width: 800px;

                .nft-title {
                    font-size: 18px;
                    margin-bottom: ${props => props.theme.space.margin}px;
                }
                
                .image {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    &-item {
                        width: 100%;
                    }
                }
            }
            .right {
                width: 350px;
                .ant-row {
                    border: 1px solid #eee;
                    padding: 25px;
                    border-radius: 10px;
                    margin-bottom: ${props => props.theme.space.marginLG}px;
                }
                .ant-col-6 {
                    flex: none;
                }
                .privileges {
                    display: flex;
                    flex-wrap: wrap;
                    margin: ${props => props.theme.space.marginLG}px 0;
                    justify-content: center;
                    &-item {
                        display: flex;
                        width: calc(100% / 5);
                        flex-direction: column;
                        align-items: center;
                        img {
                            width: 45px;
                            border-radius: 10px;
                        }
                        .privileges-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 100%;
                            text-align: center;
                            padding: 3px 5px;
                        }
                    }
                }

                .info {
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        background: url("https://cdn-prd.ggac.com/ggac/nft/nft-ablum/nft-badge.png") center center no-repeat;
                        background-size: 100%;
                        right: 100px;
                        top: 0;
                    }
                }

                .item {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 10px;
                    .value {
                        display: flex;
                        align-items: center;
                        svg {
                            margin-left: 3px;
                            color: ${props => props.theme.colors.colorPrimary};
                            cursor: pointer;
                        }

                        div {
                            max-width: 200px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .btn-wrap {
                    .btn {
                       width: 100%;
                       height: 40px;
                       /* background-color: ${props => props.theme.colors.colorPrimary}; */
                       border-radius: 40px;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       color: #fff;
                       font-size: 16px;
                       cursor: pointer;
                       color: #000;
                    }
                    .btns {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 15px;
                        justify-content: center;
                        margin-top: ${props => props.theme.space.marginLG}px;
                        .btn {
                            width: calc((100% - 30px) / 3);
                        }
                    }
                }
            }
        }
    }
`