import styled from "styled-components";
interface Props {
  isWideScreen?: boolean;
}
export const SmallBannerStyle = styled.div<Props>`
  .container_ {
    background-color: ${(props) => props.theme.colors.colorBgLayout};
    padding: ${(props) => props.theme.space.padding}px 0;
    max-width: ${(props) => props.theme.space.maxContainer}px;
    margin: 0 auto;
    .wrap {
      
      padding:0 ${(props) => props.theme.space.padding}px;
      width: 100%;
     
      display: flex;
      align-items: center;
    }
  }
  .card-list {
    display: grid;
    grid-template-columns: repeat( ${(props) => props.isWideScreen?8:6}, 1fr);
    column-gap: ${(props) => props.theme.space.padding}px;
    width: 100%;
    .banner {
      width: 100%;
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      overflow: hidden;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      aspect-ratio: 265/180;
    }
  }
`;
