import { RelatedWorkStyle } from "./style"
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useEffect, useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { WorkListType } from "types/product";

const RelatedWork = ({ list }: { list: WorkListType[] }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        slides: {
            perView: 1
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
    });

    useEffect(() => {
        instanceRef && instanceRef.current?.update();
    }, [list])

    const move = (type: string) => {
        if (type === "prev") {
            instanceRef.current?.prev();
        }
        if (type === "next") {
            instanceRef.current?.next();
        }
    };

    const toDetail = (detail: WorkListType) => {
        window.open(`/work/detail/${detail.id}`)
    }
    return <RelatedWorkStyle>
        <div ref={sliderRef} className="keen-slider">
            {
                list.length > 0 && list.map((item => {
                    return <div key={item.id}
                        onClick={() => toDetail(item)}
                        className="item keen-slider__slide"
                    >
                        <div className="cover" style={{ backgroundImage: `url(${item.coverUrl})` }} />
                        <div className="title" title={item.title}>{item.title}</div>
                    </div>
                }))
            }
        </div>
        {
            list.length > 1 && <>
                <div className="left-arrow" onClick={() => move("prev")}>
                    <RiArrowLeftSLine size={25} />
                </div>
                <div className="right-arrow" onClick={() => move("next")}>
                    <RiArrowRightSLine size={25} />
                </div>
            </>
        }
    </RelatedWorkStyle>
}

export default RelatedWork