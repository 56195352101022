import React from 'react'


function Logo({ color }: { color?: string }) {

  return (
    <img style={{ cursor: 'pointer' }} src={color === 'black' ? "https://cdn-prd.ggac.com/ggac/assets/image/official-logo.svg" : "https://cdn-prd.ggac.com/ggac/assets/image/official-logo1.svg"} className='logo' alt='' height={40} />
  )
}

export default Logo