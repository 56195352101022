import { RiAndroidFill, RiAppleFill } from 'react-icons/ri'
import { AppDownloadStyle } from './style'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { DownloadDetail } from 'types/download'
import { isMobile } from 'utils/functions/common'

function AppDownload() {
    const [detail,setDetail]=useState<DownloadDetail>();

    useEffect(()=>{
        const checkIsMobile = isMobile()
    if (checkIsMobile) {
      window.location.href = 'https://m.ggac.com/download'
      return
    }
      getVersion();
    },[])

    const getVersion = async()=>{
        try {
            const response = await axios.post('/api/v0/version');
            setDetail(response.data.data)
          } catch (err) {
           
          }
    }
  return (
    <AppDownloadStyle>
        <div className='full-container'>
            <div className='center'>

            <div className='logo'>
                <img src="https://cdn.ggac.com/img/app_logo.png" alt="" width={100} height={100}/>
                <img src="https://cdn.ggac.com/img/app-download-img2.png" alt="" height={121} style={{marginBottom: 4}}/>
            </div>
            <div className='tag-line'>
                <div>开启你的虚拟画廊 链接#CG艺术元宇宙#</div>
                <div>GALAXY GALLERY for ARTISTs and CREATORs</div>
            </div>
            <div className='icons-and-qrcode'>
                <div className='left'>
                    <div className='item' onClick={()=>window.open('https://apps.apple.com/us/app/ggac%E9%93%B6%E6%B2%B3%E7%94%BB%E5%BB%8A/id1598859910')}>
                        <div className='icon'>
                            <RiAppleFill></RiAppleFill>
                        </div>
                        <div className='text'>
                            <div className='up'>iPhone 版</div>
                            <div className='down'>{detail?.ios_data.version_number} </div>
                        </div>
                    </div>
                    <div className='item' onClick={()=>window.open('https://cdn-prd.ggac.com/ggac/apk/ggac_latest.apk')}>
                        <div className='icon'>
                            <RiAndroidFill></RiAndroidFill>
                        </div>
                        <div className='text'>
                            <div className='up'>Android 版</div>
                            <div className='down'> {detail?.android_data.version_number} </div>
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <img className='qrcode' src="https://cdn.ggac.com/img/app_download_ewm.png" alt="" width={140}/>
                    <div className='tips'>扫描二维码下载</div>
                </div>
            </div>
            </div>
           
        </div>
    </AppDownloadStyle>
  )
}

export default AppDownload



