import styled from "styled-components";

export const RewardReceiveOrderStyle = styled.div`
    .tab-wrap {
        padding: ${(props) => props.theme.space.paddingXS}px ${(props) => props.theme.space.paddingLG}px  ${(props) => props.theme.space.padding}px;
    }
    .list {
        &-title {
          padding: ${props => props.theme.space.padding}px 0 ${props => props.theme.space.padding}px 20px;
          display: flex;
          span {
              padding: 0 5px;
          }
          .link {
              color: #808080;
              cursor: pointer;
              &:hover {
                color: #FF9A16;
              }
          }
      }
        &-item {
            border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
            padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
            min-height: 44px;
            
            .order {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                .word {
                    cursor: pointer;
                }
            }
            .topic {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                gap: 30px;
                &-title {
                    flex: 1;
                    min-width: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                }
                .status {
                    color: #FF9A16;
                    flex: 0 0 auto;
                }
            }
            .price-wrap {
                display: flex;
                justify-content: space-between;
                .btn {
                    font-size: 12px;
                    color: #000;
                    background: #ffc712;
                    border-radius: 50px;
                    line-height: 25px;
                    width: 65px;
                    text-align: center;
                    cursor: pointer;
                }
            }
            .price {
                display: flex;
                color: #FF9A16;
                gap: 5px;
                align-items: center;
                font-size: 15px;
                &-icon {
                    background: url(https://cdn-prd.ggac.com/ggac/reward/reward-price.png) center center no-repeat;
                    background-size: 100% 100%;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
`