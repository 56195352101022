export const notification= 
{ 
    type: {
        all: '全部八嘎',
        system: '系统八嘎',
        favour: '喜欢八嘎',
        comment: '评论八嘎',
        subscribe: '关注八嘎'
    },
    messageBox: '消息盒八嘎', 
}
