import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const ScoreListStyle = styled.div`
padding-top:  ${props=>props.theme.space.paddingLG*2}px;
    .header{
        text-align: center;
        font-size:  ${props=>props.theme.fontSizes.fontSizeLG*1.5}px;
    }
    .task-info{
        width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: ${props=>props.theme.space.paddingSM}px;
        padding: ${props=>props.theme.space.paddingLG*2}px;
        .info-item{
            .label{
                color: ${props=>props.theme.colors.colorTextTertiary};
                padding-bottom: 4px;
            }
        }
    }
    .list{
        display: grid;
        grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
        gap: 1rem;
        padding: ${props=>props.theme.space.marginLG}px;
        width: 100%;
        .drop {
            display: grid;
        }
    }
    .submit{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .wrap {
        position: sticky;
        top: 50px;
        z-index: 9;
        background-color: #fff;
        padding-bottom: 24px;
    }
    .list {
        padding-top: 0 !important;
    }
    .tip-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 ${props=>props.theme.space.marginLG}px;
    }
    .tip {
        div {
            line-height: 22px;
            span{
                color: red;
            }
        }
    }

    .list-title {
        margin: ${props=>props.theme.space.marginLG}px;
        margin-top: 0;
        border-left: 4px solid ${props=>props.theme.colors.colorPrimary};
        padding-left: 4px;
    }

    .word {
        text-align: center;
        color: red;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .score-tab {
        display: flex;
    }
`
