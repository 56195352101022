import { useEffect, useState } from "react"
import { RegulationPopupStyle } from "./style"
import { getApplicantCount } from "api/contest"
import { UserBaseInfo } from "types/user"
import { message } from "antd"
import copy from "copy-to-clipboard"
import { ContestDetailResponse } from "types/contest"

const RegulationPopup = ({ close, isShowPopup, detail }: { close: () => void, isShowPopup: boolean, detail: ContestDetailResponse }) => {
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const popupTabList: { name: string, id: number, tip: string, list: { name: string, desc: string[] }[] }[] = contestJSON.popupTabList

    const [currentId, setCurrentId] = useState(1)
    const [currentContent, setCurrentContent] = useState<{ name: string, id: number, tip: string, list: { name: string, desc: string[] }[] }>()
    const [applicantCount, setApplicantCount] = useState(0)
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

    useEffect(() => {
        const result = popupTabList.find(item => currentId === item.id)
        setCurrentContent(result)
    }, [currentId])

    const changeTab = (id: number) => {
        const element = document.getElementById('scroll-content');
        if(element) {
            element.scrollTop = 0;
        }
        setCurrentId(id)
    }

    const closePopup = () => {
        close()
    }

    const copyText = () => {
        copy(`你获大奖，我得红包!体验双倍快乐~ 立即访问 https://www.ggac.com/2024/regulation/detail?invitationCode=${userInfo.id}，填写邀请码：${userInfo.id}报名`)
        message.success("复制成功")
    }



    useEffect(() => {
        if (isShowPopup && userInfo) {
            getApplicantCount("ggac6").then(res => {
                setApplicantCount(Number(res))
            })
        }
    }, [userInfo, isShowPopup])

    return <RegulationPopupStyle>
        <div className="content">
            <div className="popup-tab">
                {
                    popupTabList.map(item => {
                        return <div onClick={() => changeTab(item.id)} className={`${item.id === currentId ? 'popup-tab-active' : ''} popup-tab-item`} key={item.id}>{item.name}</div>
                    })
                }
            </div>
            <div className={`scroll-content scroll-content${currentContent && currentContent.id}`} id="scroll-content">
                {
                    currentContent && currentContent.id === 1 && userInfo && userInfo.id && <div className="top">
                        <div>
                            我的邀请码：{userInfo.id}
                            <span onClick={copyText}>一键复制</span>
                        </div>
                        <div>
                            我邀请的人数：{applicantCount}
                        </div>
                    </div>
                }
                {
                    currentContent?.list.map((item, index) => {
                        return <div key={item.name}>
                            <div className="popup-title">{item.name}</div>
                            {
                                item.desc.map(desc => {
                                    return <div className={`popup-desc popup-desc-${index + 1}`} key={desc}>{desc}</div>
                                })
                            }
                        </div>
                    })
                }

                <div className="popup-tip">{currentContent?.tip}</div>
            </div>
            <div className="close-wrap">
                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation2024-popup-close.png" onClick={closePopup} className="close" />
            </div>
        </div>
    </RegulationPopupStyle>
}

export default RegulationPopup