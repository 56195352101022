import { get } from "api"
import { RankingAuthorItem, RankingAuthorRequest, RankingPeriodListItem, RankingPeriodListRequest, RankingWorkItem, RankingWorkRequest } from "types/billboard"
import { PaginationResponse } from "types/common/axios"

// 获取作品榜单
export const getRankingWorkList = (params: RankingWorkRequest) => {
    return get<RankingWorkRequest, RankingWorkItem[]>("/api/ranking/work", params)
}


// 获取作者榜单
export const getRankingAuthorList = (params: RankingAuthorRequest) => {
    return get<RankingAuthorRequest, RankingAuthorItem[]>("/api/ranking/author", params)
}


// 获取排行榜期数 榜单
export const getRankingPeriodList = (params: RankingPeriodListRequest) =>{
    return get<RankingPeriodListRequest, PaginationResponse<RankingPeriodListItem>>("/api/ranking/list", params)
}
