import styled from "styled-components";

export const CreateFavoriteStyle = styled.div`
    padding: 0 ${props => props.theme.space.paddingSM}px;
    .title{
        text-align: center;
        padding-top:  ${props => props.theme.space.paddingSM}px;
       padding-bottom: ${props => props.theme.space.paddingLG}px ;
       font-weight: ${props => props.theme.fontSizes.fontWeightHeading4};
    }
    .btns {
        display: flex;
        justify-content: center;
        gap: 15px;
    }
`