import { TagSelectStyle } from './style'
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { WareItem, apiGetWareList } from 'api/ware';
import { debounce } from 'lodash';


export const CreationWare = (props: {
    type: number,
    value?: string[];
    disabled?: boolean
    selectMode?: 'multiple' | 'tags'
    onChange?: (value: string[]) => void;
}) => {
    let [options, setOptions] = useState<WareItem[]>([])
    const getOptions = useCallback(async (type: number) => {
        let res = await apiGetWareList({ type: type })
        setOptions(res)
    }, [])

    useEffect(() => {
        getOptions(props.type)
    }, [props.type, getOptions])

    const handleChange = (value: string[]) => {
        console.log(typeof value)
        props.onChange && props.onChange(value)
    }

    const  onSearchTagsList= useMemo(() => {
        const loadOptions = (value: string) => {
            getOptions(props.type)
        }
        return debounce(loadOptions, 500)
      },[])


    return <TagSelectStyle>
        <div className='container'>
        {/* <Select
                fieldNames={{ label: 'name', value: 'id' }}
                mode={props.selectMode}
                value={props.value!}
                style={{ width: '100%' }}
                placeholder="请选择"
                onChange={handleChange}
                options={options}
                allowClear
                size="middle"
            /> */}
            <Select
                filterOption={true}
                placeholder="请选择"
                mode="tags"
                onSearch={onSearchTagsList}
                value={props.value!}
                onChange={handleChange}
                tagRender={tagRender}
                size='middle'
                fieldNames={{ label: 'name', value: 'name' }}
                options={options}
            />
        </div>
    </TagSelectStyle>
}

const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3, fontSize: 14, padding: '4px 8px' }}
        >
            {label}
        </Tag>
    );
};
