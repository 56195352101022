import { hasToken } from "utils/functions/common"
import { MallGuideStyle } from "./style"
import { useNavigate } from "react-router-dom"
import { loginPathWithRedirect } from "utils/functions/user"
import { useState } from "react"
import { Button, Modal } from "antd"
import ShopYuegaoAgreement from "views/user-shop/productManage/create/yuegaoAgreement"

const MallGuide = () => {

    const [isShow, setIsShow] = useState(false)

    const list1 = [
        { name: "约稿服务", desc: ["提高知名度、", "影响力以及收益"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part1-icon1.png", id: 1 },
        { name: "作品源文件", desc: ["和大家分享交流技巧"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part1-icon2.png", id: 2 },
        { name: "专属笔刷素材", desc: ["一起创作更好的作品"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part1-icon3.png", id: 3 },
        { name: "手办文创周边", desc: ["手办、明信片、海报…", "让你的创作变成实体吧"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part1-icon4.png", id: 4 },
        { name: "课程课件", desc: ["做一回老师，", "带学生享受创作的乐趣"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part1-icon5.png", id: 5 },
        { name: "二手闲置", desc: ["用闲置好物回血，", "就来CG圈“海鲜市场”"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part1-icon6.png", id: 6 }
    ]

    const list2 = [
        { name: "宣传", desc: "官方扶持、站内外宣传推广", image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part2-icon1.png", id: 1 },
        { name: "报酬", desc: "作品创收、持续获得收益", image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part2-icon2.png", id: 2 },
        { name: "服务", desc: "专业服务、保障交易安全", image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part2-icon3.png", id: 3 },
    ]

    const list3 = [
        { name: "增强吸引力", desc: "丰富商品介绍", image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part4-image1.png", id: 1 },
        { name: "多渠道宣传", desc: "善用一键分享", image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part4-image2.png", id: 2 },
        { name: "争取曝光", desc: "成为认证作者", image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part4-image3.png", id: 3 },
        { name: "提升实力", desc: "参与G站赛事", image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part4-image4.png", id: 4 },
    ]

    const list4 = [
        { name: "订单结算", desc: ["订单完成后平台将", "进行结算服务"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part5-icon1.png", id: 1 },
        { name: "余额体现", desc: ["个人中心-我的钱包-", "选择提现"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part5-icon2.png", id: 2 },
        { name: "等待到账", desc: ["提现后通常", "24小时内到账"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part5-icon3.png", id: 3 },
    ]

    const list5 = [
        { name: "注册GGAC", desc: ["打开注册页面创建自己的G站账号"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part6-icon1.png", id: 1 },
        { name: "实名认证", desc: ["在店铺页面提交实名认证申请，", "审核通过后即可开店"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part6-icon2.png", id: 2 },
        { name: "上架商品", desc: ["在个人店铺页面可以创建商品，", "商品通过审核后就请尽快上架你的商品吧。"], image: "https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part6-icon3.png", id: 3 },
    ]

    const navigate = useNavigate()

    const toStore = () => {
        if (!hasToken()) {
            navigate(loginPathWithRedirect())
            return
        }
        window.open("/user-center/shop/product-list")
    }

    return <MallGuideStyle>
        <div className="banner">
            <img src="https://cdn-prd.ggac.com/ggac/store-guide/store-guide-banner.png" alt="" />
            <div className="banner-content">
                <div className="first-title">创作变现！原地开店</div>
                <div className="second-title">免费开设你的小店</div>
                <div className="banner-btn" onClick={toStore}>立即开启 {`${`>`}`}</div>
            </div>
        </div>
        <div className="part1">
            <div className="title">
                <div>您可以在这里出售</div>
            </div>
            <div className="list">
                {
                    list1.map(item => {
                        return <div className="list-item" key={item.id}>
                            <img src={item.image} alt="" />
                            <div className="name">{item.name}</div>
                            <div className="desc">
                                {
                                    item.desc.map(desc => {
                                        return <div key={desc}>{desc}</div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        <div className="part2">
            <div className="title">
                <div>您可以在这里获得</div>
            </div>
            <div className="list">
                {
                    list2.map(item => {
                        return <div className="list-item" key={item.id}>
                            <img src={item.image} alt="" />
                            <div className="name">{item.name}</div>
                            <div className="desc">
                                {item.desc}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        {/* <div className="part3 second-part-style">
            <div className="title">
                <div>如何给商品定价</div>
            </div>
            <div className="part3-inner">
                <div className="left">
                    <img src="https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part3-image.png" alt="" />
                </div>
                <div className="right">
                    <div className="tip">
                        <div className="tip-one">您的期望收益</div>
                        <div className="tip-two">服务费</div>
                        <div className="tip-three">=</div>
                        <div className="tip-four">商品定价</div>
                    </div>
                    <div className="content">
                        <div>商品价格=您期望的收益（由您输入）+服务费（系统自动计算）</div>
                        <div>服务费为您期望收益的一定比例，不同分类比例不同。服务费包含网络带宽、IDC、运营成本、支付手续费、渠道推广费等。</div>
                        <div>实物商品的运费发布者可自行设置运费模版，服务费计算时不包含您所设置的运费。</div>
                    </div>
                </div>

            </div>
        </div> */}
        <div className="part4">
            <div className="title">
                <div>如何让商品更受欢迎</div>
            </div>
            <div className="list">
                {
                    list3.map(item => {
                        return <div className="list-item" key={item.id}>
                            <div className="name">{item.name}</div>
                            <img src={item.image} />
                            <div className="desc">{item.desc}</div>
                        </div>
                    })
                }
            </div>
        </div>
        <div className="part5">
            <div className="title">
                <div>如何进行结算</div>
            </div>
            <div className="list">
                {
                    list4.map(item => {
                        return <div className="list-item" key={item.id}>
                            <div className="circle">
                                <img src={item.image} />
                            </div>
                            <div className="name">{item.name}</div>
                            <div className="desc">
                                {
                                    item.desc.map(desc => {
                                        return <div key={desc}>{desc}</div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        <div className="part6 second-part-style">
            <div className="title">
                <div>仅需3步即刻开店</div>
            </div>
            <div className="list">
                {
                    list5.map(item => {
                        return <div className="list-item" key={item.id}>
                            <div className="circle">
                                <img src={item.image} />
                            </div>
                            <div className="name">{item.name}</div>
                            <div className="desc">
                                {
                                    item.desc.map(desc => {
                                        return <div key={desc}>{desc}</div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="title">
                <div>开店需知</div>
            </div>
            <div className="content">
                <div>店铺仅为GGAC用户交易平台，GGAC不对交易双方交易内容承担任何责任。</div>
                <div>开店时您需要确认商品涉及作品、素材的原创性、真实性、合法性，避免发生交易纠纷。</div>
                <div>当您的商品不符合GGAC用户协议时，GGAC有权下架、删除相关商品。</div>
            </div>
            <div className="tip">详细店铺协议请参见<span onClick={() => setIsShow(true)}>店铺协议文档</span>。</div>
        </div>
        <Modal width={700} footer={[<Button type="primary" onClick={() => setIsShow(false)}>
            确定
        </Button>]} title="约稿商品购买协议" destroyOnClose={true} closable={false} centered={true} open={isShow}>
            <ShopYuegaoAgreement />
        </Modal>
    </MallGuideStyle>
}

export default MallGuide