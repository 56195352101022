import { FavoriteCardStatusStyle } from "./style";
import { FavoriteDataListItem } from 'types/favorite';


function FavoriteCardStatus({ item }: { item: FavoriteDataListItem }) {
    return (
        <FavoriteCardStatusStyle>
              {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
            {
                (item.expired === 1) && <div className="status">已失效</div>
            }
            {item.isPublic === 0 && (item.expired === 0) && <div className="status">私密</div>}
          
        </FavoriteCardStatusStyle>
    );
}

export default FavoriteCardStatus;
