import Comments from "components/comments";
import WorkContent from "./left/workContent";
import Ad from "./right/ad";
import FloatChatInput from "./right/floatChatInput";
import Interaction from "./right/interaction";
import Recommandation from "./right/recommandation";
import Tags from "./right/tags";
import WorkInfo from "./right/workInfo";
import { WorkDetailStyle } from "./style";
import { RiAlarmWarningLine, RiMessageFill } from "react-icons/ri";
import { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { CommentListItem } from "types/comment"
import { getWorkDetail } from "api/work";
import { useNavigate, useParams } from 'react-router-dom';
import { WorkDetailType } from "types/work";
import { InteractionType } from "./detailType";
import { ReportModal } from "components/report";
import { FloatButton, Modal, Spin } from "antd";
import UserCardGlobal from "components/userBlock";
import SuperInteraction from "./right/super-interaction";
import { determineSuperaction } from "utils/functions/user";
import WorkDetailNav from "./left/nav";
import { hasToken, isMobile } from "utils/functions/common";
import PerfectScrollbar from 'perfect-scrollbar';
import WorkVote from "./right/work-vote";
import { ChatRoom } from "components/chat";
import RelatedProducts from "./right/related-products";
import CollaborativeCreators from "./right/collaborativeCreators";
import AuthorizedProducts from "./right/authorized-products";
import LoginModal from "components/login-modal";

function WorkDetail() {
  const commentRef = useRef() as React.MutableRefObject<any>;
  const navigate = useNavigate()
  // 调用commet创建方法
  const createComment = (val: CommentListItem) => {
    commentRef.current?.addComment(val)
  }

  const { id } = useParams<any>();
  const [showReport, setShowReport] = useState(false) // 举报弹窗
  const [commentCount, setCommentCount] = useState<number>(0)

  const [detail, setDetail] = useState<WorkDetailType>()
  const [loading, setLoading] = useState(false)

  const [chatModalOpen, setChatModalOpen] = useState(false)

  const selectRef = useRef(null)

  const [isShowLoginModal, setIsShowLoginModal] = useState(false)

  const updateLoading = (value: boolean) => {
    setLoading(value)
  }

  useEffect(() => {

    new
      PerfectScrollbar('#scroll-right', {
        wheelPropagation: true,
        minScrollbarLength: 20,
        suppressScrollX: true,
        wheelSpeed: 1,
      });

    const checkIsMobile = isMobile()
    if (checkIsMobile) {
      window.location.href = `https://m.ggac.com` + window.location.pathname
      return
    }
  }, [])

  const getDetail = useCallback(() => {
    getWorkDetail(Number(id)).then(res => {
      document.title = res.title;
      setCommentCount(res.actionCount.commentCount)
      setDetail(res)
    }).catch(err => {
      if (err.code === '604') {
        navigate('/error', { replace: true })
      }
    })
  }, [id])

  const updateCommentCount = () => {
    getWorkDetail(Number(id)).then(res => {
      setCommentCount(res.actionCount.commentCount)
    })
  }

  const defaultInteraction = useMemo(() => {
    return {
      isLike: detail?.isLike!,
      isFavorite: detail?.isFavorite!,
      actionCount: {
        likeCount: detail?.actionCount?.likeCount!,
        favoriteCount: detail?.actionCount?.favoriteCount!,
      }
    }
  }, [detail])

  const updateInteraction = (value: InteractionType) => {
    getDetail()
  }

  // 收藏成功后修改收藏状态
  const updateFavoriteStatus = () => {
    getDetail()
  }

  // 举报作品
  const reportWork = () => {
    setShowReport(true)
  }

  const onCloseReport = () => {
    setShowReport(false)
  }

  const toCommentPostion = () => {
    const offsetTop = document.getElementById('input-wrap')?.offsetTop!
    const container = document.querySelector('#scroll-right');
    container!.scrollTop = offsetTop - 50;
  }

  const onClickToTop = () => {

    const container = document.querySelector('#scroll-right');
    container!.scrollTop = 0;
    // container!.scrollTop = offsetTop - 50;
  }

  const onClickOpenChat = () => {
    setChatModalOpen(true)
  }
  const handleCloseModal = () => {
    setChatModalOpen(false)
  }

  const readWordList: number[] = JSON.parse(localStorage.getItem("Read_work_list") || "[]")

  const token = hasToken()

  useEffect(() => {
    if (!id) {
      return
    }
    const index = readWordList.findIndex(item => item === Number(id))
    if (!token && readWordList.length < 10) {
      if (index >= 0) {
      } else {
        readWordList.push(Number(id))
        localStorage.setItem("Read_work_list", JSON.stringify(readWordList))
      }
    } else if (!token && readWordList.length >= 10) {
      setIsShowLoginModal(true)
      return
    }

    getDetail()
  }, [getDetail, id])

  return (
    <WorkDetailStyle id="detail-work" >
      {/* {detail?.contestWork && <DetailHeader detail={detail}></DetailHeader>} */}
      <Spin spinning={loading}>
        <div className="main--container">
          <div className="left">
            {detail && <WorkDetailNav id={Number(id)} userId={detail?.userId}></WorkDetailNav>}
            {detail && <WorkContent detail={detail}></WorkContent>}
          </div>
          <div className="right" ref={selectRef} id="scroll-right" >
            <div className="section-block">
              {detail && detail.creatorCount === 0 && <UserCardGlobal isShowBusinessBtn={true} userId={detail?.userId}></UserCardGlobal>}
              {detail && detail.creatorCount > 0 && <CollaborativeCreators></CollaborativeCreators>}
              {detail && <Interaction updateLoading={updateLoading} detail={detail} defaultInteraction={defaultInteraction} update={updateInteraction} updateFavoriteStatus={updateFavoriteStatus}></Interaction>}
              {determineSuperaction() && detail && <SuperInteraction detail={detail} updateDetail={(detail) => { setDetail(detail) }}></SuperInteraction>}
              {detail && <WorkInfo {...detail}></WorkInfo>}
              {
                detail && <WorkVote detail={detail} updateDetail={() => { getDetail() }} />
              }
              {
                detail && <>
                  <Ad></Ad>
                  <AuthorizedProducts></AuthorizedProducts>
                  <RelatedProducts></RelatedProducts>
                  <Recommandation detail={detail!}></Recommandation>
                  <Tags tagList={detail?.tagList!} hardwareTagList={detail?.hardwareList!} softwareTagList={detail?.softwareList!}></Tags>
                </>
              }
            </div>

            {
              !!detail && <>
                <div className="section-block">
                  <div className="container">
                    <Comments ref={commentRef} params={{ commentCount, updateCommentCount, type: 'work' }}></Comments>
                    <div id="input-wrap">
                      <FloatChatInput params={{ createComment }}></FloatChatInput>
                    </div>
                  </div>

                </div>
                <div className="report" onClick={() => reportWork()}>
                  <RiAlarmWarningLine size={18}></RiAlarmWarningLine>
                  <span>举报</span>
                </div>
              </>
            }
          </div>
        </div>
      </Spin>
      {showReport && (
        <ReportModal
          show={showReport}
          close={onCloseReport}
          params={{ dataId: Number(detail?.id), dataTable: "work" }}
        ></ReportModal>
      )}

      {chatModalOpen && !!detail && <ChatRoom show={chatModalOpen} close={handleCloseModal} toUserId={detail?.userId!} orderNumber={'50202311141636400354'}></ChatRoom>}

      {
        !!detail && <FloatButton.Group shape="circle" className="float-button">
          <FloatButton.BackTop tooltip="回到顶部" onClick={onClickToTop} />
          <FloatButton tooltip="前往评论" onClick={toCommentPostion} icon={<RiMessageFill />} />
          {/* <FloatButton tooltip="聊天" onClick={onClickOpenChat} icon={<RiMessageFill />} /> */}
        </FloatButton.Group>
      }

      <Modal width={450} footer={<></>} title="" destroyOnClose={true} closable={true} onCancel={() => setIsShowLoginModal(false)} centered={true} open={isShowLoginModal}>
        <LoginModal />
      </Modal>
    </WorkDetailStyle >
  );
}

export default WorkDetail;
