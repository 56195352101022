import styled from "styled-components";

export const SignedAuthorMaterialStyle = styled.div`
    /* background-image: url('https://cdn-prd.ggac.com/ggac/assets/image/user-center/signed_author_bg.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    height: 100%;
    .list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 150px;
        height: 100%;
        background: url(https://cdn.ggac.com/img/pc_indent_kv.jpg) center center no-repeat;
        background-size: cover;
        padding-bottom: 80px;
        &-item {
            width: 470px;
            border-radius: 8px;
            padding: ${props => props.theme.space.paddingLG}px;
            box-shadow: 0px 2px 14px 2px rgba(43, 46, 54, 0.1);
            border-radius: 8px;
            background-color: #fff;
            .title {
                width: 46px;
                height: 46px;
                background: #f5e0c2;
                border-radius: 50%;
                line-height: 46px;
                text-align: center;
                margin: 0 auto;
                margin-bottom: 35px;
            }
            .desc {
                height: 110px;
                text-align: center;
                font-size: 18px;
                div {
                    line-height: 30px;
                }
            }
            .btn {
                width: 167px;
                height: 50px;
                background: #f5e0c2;
                border-radius: 25px;
                text-align: center;
                line-height: 50px;
                font-size: 20px;
                margin: 0 auto;
                margin-bottom: ${props => props.theme.space.margin}px;
                cursor: pointer;
            }
            .finish {
                font-size: 16px;
                line-height: 36px;
                text-align: center;
            }
        }
    }
`