import styled from "styled-components";


export const ExpressInfoStyle = styled.div`
.wrap_{
    .status{
        padding: ${props=> props.theme.space.padding}px 0;
        .label{
            font-weight:${props=> props.theme.fontSizes.lineHeightHeading4} ;
        }
    }
    .context{
    color: ${props=> props.theme.colors.colorTextSecondary};
}
.more{
    display: flex;
    justify-content: center;
    text-align: center;
    color: ${props=> props.theme.colors.colorLinkDefault};
    &:hover{
        cursor: pointer;
        color: ${props=> props.theme.colors.colorLinkHover};

    }
}
}

`