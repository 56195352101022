import { UiCalenderStyle } from './style'
import { generateDateList } from './fn'
import { inArray } from 'utils/functions/common'

type Props = {
    hightlightItems?:string[],
    month?:number,
    year?:number
}
function UiCalender({hightlightItems=[],month=new Date().getMonth()+1,year=new Date().getFullYear()}:Props) {
   const days = generateDateList(month,year)
  
   const renderCell = (item: any)=>{
    if(inArray(item.dateStr,hightlightItems)){
        return <span className='highlight' title='当日有发布过作品'>{item.dateObj.getDate()}</span>
    }else{
        return <span>{item.dateObj.getDate()}</span>
    }
   }

  return (
    <UiCalenderStyle>
        <div className='title'>{year}年{month}月</div>
        <div className='header'>
             <div className='header-item'>周日</div>
            <div className='header-item'>周一</div>
            <div className='header-item'>周二</div>
            <div className='header-item'>周三</div>
            <div className='header-item'>周四</div>
            <div className='header-item'>周五</div>
            <div className='header-item'>周六</div>
           
        </div>
        <div className='cells'>
            {days.map((item, index) => {
                return <div className={item.isThisMonth?'cell isCurrent':'cell'} key={index}>
                    {renderCell(item)}
                </div>
            })}
        </div>
    </UiCalenderStyle>
  )
}

export default UiCalender