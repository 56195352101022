import { myWorkListItem } from 'types/work'
import UniCardLayout from '../layout';
import PrizeIcons from '../common/prize-icons';
import WorkIcons from '../common/hot-icons';
import { translateCardCategoryText } from 'utils/functions/common';
import PublishWorkCardActions from './publishWorkCardActions';
import CardTitleRender from '../common/card-title/work';
import MediaIconsWork from '../common/media-icons/work';
import PublishWorkCardStatus from './publishWorkCardStatus';


function PublishedWorkCard({item,shouldRefresh}:{item:myWorkListItem,shouldRefresh:()=>void}) {

    const UiObject ={
        coverUrl:item.coverUrl,
        title:<CardTitleRender dataType={item.onSale === 1 ? 'work' : 'noLink'} item={item}/>,
        subtitle:<span> {item.dictMap.mediaCategory} |  {translateCardCategoryText(item.categoryList)}</span>,
        view:item.viewCount,
        hot:item.hot,
        date:item.lastSubmitTime,
        centerNode:<PublishWorkCardActions item={item} shouldRefresh={shouldRefresh}></PublishWorkCardActions>,
        icons:WorkIcons({item}),
        workLink: item.onSale === 1 ? `/work/detail/${item.id}` : '',
        leftBottomNode:<PublishWorkCardStatus item={item}></PublishWorkCardStatus>,
        rightTopNode:PrizeIcons({item}),
        rightBottomNode:MediaIconsWork({item}),
        isPublish: true
      }
      
      
    return UniCardLayout({item:UiObject});
}

export default PublishedWorkCard