import styled from "styled-components";

export const RankingHeaderStyle = styled.div`
  width: 100%;
  .ant-dropdown-menu{
    max-height: 350px;
    overflow: auto;
  }
  .ant-dropdown {
    padding: 10px 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: ${(props) => props.theme.space.paddingLG}px
    ${(props) => props.theme.space.paddingSM}px;
    padding-top: 0px;
    padding-left: 180px;
    .left {
      .big-text {
        font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
      }
      .small-text {
        color: ${(props) => props.theme.colors.colorTextTertiary};
      }
    }
    .right {
      
      .big-text {
        font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
      }
      .small-text {
        color: ${(props) => props.theme.colors.colorTextTertiary};
        cursor: pointer;
      }
      .icon{
        font-size: 18px;
        padding-top: 2px;
        cursor: pointer;
        color: ${(props) => props.theme.colors.colorTextTertiary};
        margin-left: 2px;
      }
    }
  }
`;

export const DropdownListStyle = styled.div`

    display: flex;
    flex-direction: column;
    .title {
      font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
    }
    .timespan {
      color: ${(props) => props.theme.colors.colorTextTertiary};
    }
  
`;
