import styled from "styled-components";

export const ImageUploadStyle = styled.div`
  
        .upload-image-modal {
            .effect-item-container{
                .effect-wrapper{
                    display: flex;
                    justify-content: flex-start;
                .video-wrapper{
                    margin-left: ${props => props.theme.space.margin}px;
                    width: 150px;
                   display: flex;
                   flex-direction: column;
                   align-items: center;
                }
                .video-wrapper > div{
                    pointer-events: none;
                }
                .actived{
                    border: 3px solid ${props => props.theme.colors.colorPrimary};
                }
            }
        }
        
        
        
    }
`