import { CouponDrawItem } from "types/user"
import { CouponCardStyle } from "./style"
import { formatMoney } from "views/user-shop/productManage/create/fn"
import { Radio } from "antd"

type Props={
    item:CouponDrawItem
    isActive?:boolean
}
function CouponCard({item,isActive}:Props) {
  return (
    <CouponCardStyle isActive={isActive}>
    
      <div className="card-left">
        <div className="money">
          <span className="sign">¥</span>
          <span className="amount">{formatMoney(item.amount)}</span>
        </div>
        <div className="tip">满{formatMoney(item.limitAmount)}元可用</div>
      </div>
      <div className="card-right">
          <div className="a">
              <div className="title">{item.name}</div>
              <div className="desc">
              {item.endTime &&  <div>{item.createTime.substring(0,16)}至{item.endTime.substring(0,16)}</div>}
              {!item.endTime &&  <div>{item.createTime.substring(0,16)}至 2099-{item.createTime.substring(5,16)}</div>}
              </div>
          </div>
          <div className="b">
              <Radio checked={isActive}></Radio>
          </div>
      </div>

    </CouponCardStyle>
  )
}

export default CouponCard