import { useNavigate, useParams } from "react-router-dom"
import { RewardDetailStyle } from "./style"
import { useEffect, useState } from "react"
import { apiForumFollow, getAnswererOfQeustion, getRewardDetail, postCreateApplicant } from "api/forum"
import { ForumOrderAttachmentListItem, ForumTopicPayIssuessDetail, ForumUserModal } from "api/forum/type"
import { Button, Image, Modal, Space, message } from "antd"
import CreateAndUpdateReward from "../components/createAndUpdateReward"
import { hasToken } from "utils/functions/common"
import { loginPathWithRedirect } from "utils/functions/user"
import RewardDetailReadDiliver from "./download-file"
import RewardAnswererItem from "./answerer-item"
import { RiArrowDropDownLine, RiArrowDropUpLine, RiShareForwardLine } from "react-icons/ri"
import copy from "copy-to-clipboard"
import SelectAnswerer from "../my-reward/select-answerer"

const RewardDetail = () => {

    const id = useParams()?.id

    const [detail, setDetail] = useState<ForumTopicPayIssuessDetail>()

    const [isShowReward, setIsShowReward] = useState(false)

    const token = hasToken()

    const navigate = useNavigate()

    const [isShowFileList, setIsShowFileList] = useState(false)

    const [selectDetail, setSelectUserInfo] = useState<ForumUserModal>()

    const [answererList, setAnswererList] = useState<ForumOrderAttachmentListItem[]>([])

    const [isExpandPart1, setIsExpandPart1] = useState(false)
    const [isHeightReached, setIsHeightReached] = useState(false)
    const [isExpandPart2, setIsExpandPart2] = useState(false)
    const [isHeight2Reached, setIsHeight2Reached] = useState(false)
    const [isFollowLoading, setIsFollowLoading] = useState(false)
    const [isShowSelect, setShowSelect] = useState(false)

    useEffect(() => {
        if (!id) {
            return
        }
        getDetail()
    }, [id])

    useEffect(() => {
        if (!token) {
            return
        }
        if (!detail) {
            return
        }
        getAnswererOfQeustion({ topicId: detail.id }).then(res => {
            setAnswererList(res.pageData)
            var element1 = document.getElementById("part1");
            var height = element1?.offsetHeight;
            if (Number(height || 0) > 500) {
                setIsHeightReached(true)
                setIsExpandPart1(false)
            }

            var element2 = document.getElementById("part2");
            var height2 = element2?.offsetHeight;
            if (Number(height2 || 0) > 500) {
                setIsHeight2Reached(true)
                setIsExpandPart2(false)
            }

        })
    }, [token, detail])

    const getDetail = () => {
        getRewardDetail(Number(id)).then(res => {
            setDetail(res)
            if (res.type === 3) {
                setSelectUserInfo({
                    wage: res.amount,
                    userInfo: res.inviteUserInfo
                })
            }
        })
    }

    const updateEdit = () => {
        setIsShowReward(true)
    }

    const applicant = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        if (!detail) {
            return
        }
        if (detail.isApplied === 1) {
            message.warning("已抢答，待提问人选择。")
            return;
        }

        postCreateApplicant({ topicId: detail.id, type: 1 }).then(res => {
            detail.isApplied = 1
            setDetail({ ...detail })
            message.warning("已抢答，待提问人选择。")
        })
    }

    const link = () => {
        navigate(`/forum/reward/index`, { replace: true })
    }

    const toDeliver = () => {
        navigate(`/forum/reward/receive-order?state=1`)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const download = () => {
        setIsShowFileList(true)
    }

    const toPay = () => {
        navigate(`/forum/reward/order/list?state=0&topicId=${detail?.id}`)
    }

    const copyText = () => {
        message.success("链接已复制")
        copy(window.location.href)
    }

    const handleFollow = () => {
        if (!detail) {
            return
        }
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        setIsFollowLoading(true);
        apiForumFollow({ topicId: Number(id) })
            .then(() => {
                detail.isFollow = detail.isFollow === 1 ? 0 : 1
                detail && setDetail({ ...detail })
                setIsFollowLoading(false);
            })
            .catch(() => {
                setIsFollowLoading(false);
            });
    };

    const toSelectAnserer = () => {
        setShowSelect(true)
    }


    return <RewardDetailStyle>

        {
            detail &&
            <>
                <div className="top">
                    <div className="list-title">
                        <div className="link" onClick={() => link()}>
                            返回 悬赏主页
                        </div>
                        <span>{`>`}</span>问题详情
                    </div>
                    <div className="title">
                        <div className="title-word">{detail?.title}</div>
                        <div className="price">
                            <div className="price-icon"></div>
                            赏金￥{detail?.amount}
                        </div>
                    </div>
                    <div className="user-info-wrap">
                        <Space size={"middle"}>
                            <Button
                                type="primary"
                                onClick={() => handleFollow()}
                                loading={isFollowLoading}
                            >
                                {detail?.isFollow ? "取消关注" : "关注问题"}
                            </Button>
                            <div
                                className="icon-btn"
                                onClick={copyText}
                            >
                                <RiShareForwardLine
                                    style={{ fontSize: 16 }}
                                ></RiShareForwardLine>
                                <span>分享</span>
                            </div>
                        </Space>
                        <div className="user-info">
                            <div className="user-avatar" onClick={() => window.open(`/user/${detail?.userId}`)} style={{ backgroundImage: `url(${detail?.userInfo.avatarUrl})` }} />
                            <div className="user-name" onClick={() => window.open(`/user/${detail?.userId}`)}>{detail?.userInfo.username}</div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="left">
                        <div className={`part ${isHeightReached && !isExpandPart1 ? 'overflow' : ''}`} id="part1">

                            <div className="desc" dangerouslySetInnerHTML={{ __html: detail?.description }}></div>
                            <div className="image">
                                {
                                    detail.imageList.map(item => {
                                        return <Image className="image-item" key={item} src={item} alt="" />
                                    })
                                }
                            </div>

                            {
                                detail.fileList.length > 0 && <>
                                    <div className="file">
                                        <div className="file-title">提问源文件列表：</div>
                                        {
                                            detail.fileList.map((item, index) => {
                                                return <span className="file-item" onClick={() => window.open(item)} key={item}>
                                                    附件{index + 1}
                                                </span>
                                            })
                                        }
                                    </div>
                                </>
                            }

                            {
                                !!detail.fileSupplement && <div className="fileSupplement">
                                    <div className="label">提问源文件链接：</div>
                                    <div className="value">{detail.fileSupplement}</div>
                                </div>
                            }
                        </div>

                        {
                            isHeightReached && !isExpandPart1 && <div className="expand">
                                <div onClick={() => setIsExpandPart1(true)}>
                                    <RiArrowDropDownLine size={25} />展开全部
                                </div>
                            </div>
                        }

                        {
                            isHeightReached && isExpandPart1 && <div className="expand">
                                <div onClick={() => setIsExpandPart1(false)}>
                                    <RiArrowDropUpLine size={25} />隐藏
                                </div>
                            </div>
                        }

                        {
                            answererList.length > 0 && <>
                                <div className="deliver-title">回答详情</div>
                                <div className={`part list ${isHeight2Reached && !isExpandPart2 ? 'overflow' : ''}`} id="part2">
                                    {
                                        answererList.map(item => {
                                            return <RewardAnswererItem detail={item} key={item.id}></RewardAnswererItem>
                                        })
                                    }
                                </div>

                                {
                                    isHeight2Reached && !isExpandPart2 && <div className="expand">
                                        <div onClick={() => setIsExpandPart2(true)}>
                                            <RiArrowDropDownLine size={25} />展开全部
                                        </div>
                                    </div>
                                }

                                {
                                    isHeight2Reached && isExpandPart2 && <div className="expand">
                                        <div onClick={() => setIsExpandPart2(false)}>
                                            <RiArrowDropUpLine size={25} />隐藏
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className="right">
                        <div className="tip">
                            {
                                detail.status === 1 && <>{detail.applyCount}人抢答，待发起人选择</>
                            }
                            {
                                detail.status === 2 && <>回答中</>
                            }
                            {
                                detail.status === 3 && <>问题已关闭</>
                            }
                            {
                                detail.status === 4 && <>问题已完成</>
                            }
                        </div>
                        <div className="btn-wrap">
                            {
                                [1, 2].includes(detail.fileDeliveried) && <>
                                    {
                                        detail.fileList && detail.fileList.length > 0 && <div className="btn" onClick={() => download()}>下载源文件</div>
                                    }
                                    {
                                        detail.fileDeliveried === 2 && <div className="btn" onClick={() => toDeliver()}>去回答</div>
                                    }
                                </>
                            }
                            {
                                detail.isCreator === 1 && ((detail.applyCount > 0 && detail.status === 1) || (detail.status === 2 && detail.payStatus === 0)) && <div className="btn" onClick={toSelectAnserer}>立即选人</div>
                            }
                            {
                                detail.isCreator === 1 && detail.status === 1 && <div className="btn" onClick={updateEdit}>编辑</div>
                            }
                            {
                                detail.payStatus === 1 && <div className="btn" onClick={toPay}>去支付</div>
                            }
                            {
                                detail.status === 1 && detail.isCreator !== 1 && <div className="btn" onClick={applicant}>
                                    {detail.isApplied === 1 ? "已抢答" : "抢答"}
                                </div>
                            }
                        </div>
                    </div >
                </div>
            </>

        }

        {
            detail && <>
                <Modal getContainer={false} title='悬赏提问' footer={<></>} width={650} destroyOnClose={true} closable={false} centered={true} open={isShowReward}>
                    <CreateAndUpdateReward answererDetail={selectDetail} detail={detail} close={() => setIsShowReward(false)} listOnFlush={getDetail} />
                </Modal>

                <Modal getContainer={false} title='选择答主' footer={<></>} width={650} destroyOnClose={true} closable={false} centered={true} open={isShowSelect}>
                    <SelectAnswerer close={() => setShowSelect(false)} topicId={detail.id} />
                </Modal>

                <Modal getContainer={false} title='下载源文件' footer={<></>} width={650} destroyOnClose={true} onCancel={() => setIsShowFileList(false)} centered={true} open={isShowFileList}>
                    <RewardDetailReadDiliver detail={detail} />
                </Modal>
            </>
        }
    </RewardDetailStyle >
}

export default RewardDetail