import { Button, Checkbox, CheckboxProps, Form, Input, Radio, UploadFile, message } from "antd";
import { SpecificationsFormStyle } from "./style";
import { skuListItem } from "types/commodity";
import { FilesWall } from "../../../uploadFileList";
import { useEffect, useState } from "react";
import { getFileUrlByCosUpload } from "config/cos";
import { InputNumber } from "antd";
import { ImageCrop } from "components/crop-img";
import { getFileName, getLocalUserInfo } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { useSearchParams } from "react-router-dom";
import { apiGetWorkDraftDetail } from "api/work";

const SpecificationsForm = ({
  detail,
  onClose,
  isRequired,
  isAuthorized,
  resetEdit,
  addSpecification,
  deleteSpecification,
}: {
  isRequired: boolean;
  isAuthorized: boolean;
  resetEdit?: () => void;
  detail?: skuListItem;
  onClose: () => void;
  addSpecification: (detail: skuListItem) => void;
  deleteSpecification?: (detail: skuListItem) => void;
}) => {
  const [form] = Form.useForm<submitParams>();
  const [loading, setLoading] = useState(false);
  const showDiscount = Form.useWatch("showDiscount", form);
  const isOutUrlShow = Form.useWatch("isOutUrlShow", form);
  const originalPrice = Form.useWatch("originalPrice", form);
  const rmbPrice = Form.useWatch("rmbPrice", form);
  const localUser = getLocalUserInfo();
  let [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");
  const [isPrivateInquiry, setIsPrivateInquiry] = useState(0);
  type submitParams = {
    coverUrl: {
      file?: File;
      imgUrl?: string;
    };
    attachmentList: UploadFile[];
    categoryId: number;
    name: string;
    originalPrice: number | string;
    quantity: number | string;
    fileFormat: string;
    rmbPrice: number | string;
    outerUrl?: string;
    showDiscount?: boolean;
    isOutUrlShow?: boolean;
    authorizationMark: number;
    isPrivateInquiry?: boolean;
  };

  const add = () => {
    form.validateFields().then(async (res) => {
      const {
        coverUrl,
        attachmentList,
        name,
        originalPrice,
        quantity,
        fileFormat,
        rmbPrice,
        outerUrl,
        authorizationMark,
      } = res;
      if (isAuthorized && isNaN(authorizationMark)) {
        message.error("请选择商品授权类型");
        return;
      }
      if (!isAuthorized && !coverUrl.imgUrl) {
        message.error("请上传商品属性封面图");
        return;
      }

      setLoading(true);
      let params: any = {
        name: !isAuthorized ? name : Number(authorizationMark) == 1 ? "个人使用授权" : Number(authorizationMark) == 2 ? "商业使用授权" : Number(authorizationMark) == 3 ? "商业买断授权" : "",
        originalPrice: !isPrivateInquiry ? Number(originalPrice) : 0,
        quantity: Number(quantity),
        fileFormat,
        rmbPrice: showDiscount ? Number(rmbPrice) : !isPrivateInquiry ? Number(originalPrice) : 0,
        id: detail?.id ? detail?.id : 0,
        attachmentList: attachmentList.map((item) => item.url),
        coverUrl: coverUrl.imgUrl ? coverUrl.imgUrl : "https://cdn-prd.ggac.com/ggac/icon/default-product-image.png",
        authorizationMark: isAuthorized ? Number(authorizationMark) : 0,
        isPrivateInquiry: isPrivateInquiry ? 1 : 0
      };
      setLoading(false);
      addSpecification(params);
    });
  };

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      setLoading(true);
      let urlKey = getFileName(CosDirectoryType.productImage, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      form.setFieldValue("coverUrl", {
        file: undefined,
        imgUrl: res.url,
      });
      setLoading(false);
    }
  };

  const reset = () => {
    resetEdit && resetEdit();
    onClose && onClose();
  };

  useEffect(() => {
    setFormWithWorkInfo()
  }, [draftId])

  const setFormWithWorkInfo = () => {
    if (draftId) {
      apiGetWorkDraftDetail(Number(draftId)).then((res) => {
        form.setFieldsValue({
          coverUrl: {
            file: undefined,
            imgUrl: res?.coverUrl,
          },
          name: res?.title
        });
      })

    }
  }

  useEffect(() => {

    const arr: UploadFile[] = [];
    detail?.attachmentList?.forEach((item, index) => {
      arr.push({
        uid: String(index),
        url: item,
        name: item,
      });
    });
    form.resetFields();
    form.setFieldsValue({
      coverUrl: {
        file: undefined,
        imgUrl: detail?.coverUrl
        // imgUrl: detail?.coverUrl ? detail?.coverUrl: isAuthorized ? "https://cdn-prd.ggac.com/ggac/icon/default-product-image.png" : "",
      },
      attachmentList: arr,
      name: detail?.name,
      rmbPrice:
        Number(detail?.rmbPrice) >= 0 ? Number(detail?.rmbPrice) : undefined,
      showDiscount: detail?.rmbPrice === detail?.originalPrice ? false : true,
      originalPrice:
        Number(detail?.originalPrice) >= 0
          ? Number(detail?.originalPrice)
          : undefined,
      fileFormat: detail?.fileFormat || undefined,
      quantity:
        Number(detail?.quantity) >= 0 ? Number(detail?.quantity) : undefined,
      outerUrl: detail?.outerUrl,
      isOutUrlShow: Boolean(detail?.outerUrl),
      authorizationMark: isAuthorized ? Number(detail?.authorizationMark) : 0,
      isPrivateInquiry: Boolean(detail?.isPrivateInquiry),
    });
    setIsPrivateInquiry(!!detail?.isPrivateInquiry ? 1 : 0);
  }, [detail, isAuthorized]);

  const renderTip = () => {
    const rmbPrice_num = Number(rmbPrice);
    const roriginalPrice_num = Number(originalPrice);
    if (
      rmbPrice_num &&
      roriginalPrice_num &&
      rmbPrice_num <= roriginalPrice_num
    ) {
      return `相当于原价${Math.floor(
        (rmbPrice_num / roriginalPrice_num) * 100
      )}%`;
    }
    return "";
  };
  const isChoosePrivateInquiry: CheckboxProps['onChange'] = (e) => {
    setIsPrivateInquiry(e.target.checked == true ? 1 : 0);
  };

  const clearDiscoutStatus = (e: any) => {
    if (!e) {
      form.setFieldValue("rmbPrice", '')
    }
  }

  return (
    <SpecificationsFormStyle>
      <Form
        className="form-specification"
        style={{ width: 534 }}
        form={form}
        labelAlign="left"
        initialValues={{ showDiscount: false, isOutUrlShow: false }}
      >
        {!isAuthorized && (<Form.Item
          name="name"
          label="商品属性名称"
          rules={[{ required: !isAuthorized ? true : false, message: "请输入商品属性名称" }]}
        >
          <Input maxLength={50} type="text" placeholder="请输入商品属性名称" />
        </Form.Item>)}
        {isAuthorized && (<Form.Item name="authorizationMark"
        >
          <Radio.Group>
            <Radio value={1}>个人使用授权</Radio>
            <Radio value={2}>商业使用授权</Radio>
            <Radio value={3}>商业买断授权</Radio>
          </Radio.Group>
        </Form.Item>)}
        <Form.Item
          name="coverUrl"
          label="商品属性封面图"
          rules={[{ required: !!isAuthorized ? false : true, message: "请上传商品属性封面图" }]}
          extra="建议尺寸300*300"
        >
          {/* <ImageUpload /> */}
          <ImageCrop aspect={1 / 1} uploadImage={upload} />
        </Form.Item>
        <div style={{ display: "flex", "alignItems": "center", gap: "10px" }}>
          <Form.Item
            name="originalPrice"
            label="价格"
            rules={[{ required: isPrivateInquiry == 1 ? false :true, message: "请输入商品价格" }, {
              validator: (rule, val, callback) => {
                if (val <= 100000 || !val) {
                  return Promise.resolve()
                } else {
                  return Promise.reject('最大价格不能超过100000')
                }
              }
            }]}
          >
            <InputNumber
              min={0}
              size="middle"
              placeholder="请输入商品价格"
              disabled={isPrivateInquiry == 1}
            />
          </Form.Item>
          {isAuthorized && (
            <Form.Item name="isPrivateInquiry">
              <Checkbox checked={isPrivateInquiry == 1 ? true : false} onChange={isChoosePrivateInquiry}>私聊询价</Checkbox>
            </Form.Item>
          )}
        </div>
        <Form.Item name="showDiscount" label={"是否设置折扣"}>
          <Radio.Group disabled={isPrivateInquiry == 1} onChange={(e) => clearDiscoutStatus(e.target.value)}>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>
        {showDiscount && (
          <Form.Item
            name="rmbPrice"
            label={"请输入折扣价格"}
            rules={[{
              validator: (rule, val, callback) => {
                const newOriginalPrice = form.getFieldValue("originalPrice")
                if (val < newOriginalPrice) {
                  return Promise.resolve()
                } else {
                  return Promise.reject('折扣价格最大不能超出或者等于原价')
                }
              }
            }]}
            extra={renderTip()}
          >
            <InputNumber
              min={0}
              size="middle"
              disabled={isPrivateInquiry == 1}
              placeholder="请输入折扣价格"
            />
          </Form.Item>
        )}
        <Form.Item
          name="quantity"
          label="商品属性数量"
          rules={[{ required: true, message: "请输入商品属性数量" }]}
          tooltip="如无商品数量限制，填写大额数字即可。如999,9999等。"
        >
          <InputNumber
            width={"100%"}
            min={1}
            size="middle"
            max={100000}
            placeholder="请输入商品属性数量"
          />
        </Form.Item>

        {isRequired && (
          <Form.Item
            name="attachmentList"
            label="上传附件"
            className="attachmentList"
            rules={[{ required: true, message: "请上传附件" }]}
          >
            <FilesWall
              listType="picture-card"
              maxLength={5}
              onUploadStart={() => setLoading(true)}
              onUploadEnd={() => setLoading(false)}
            />
          </Form.Item>
        )}

        {!isRequired && (
          <Form.Item
            name="attachmentList"
            className="attachmentList"
            label="上传附件"
            extra="更新商品源文件，建议新建商品属性"
          >
            <FilesWall
              listType="picture-card"
              maxLength={5}
              onUploadStart={() => setLoading(true)}
              onUploadEnd={() => setLoading(false)}
            />
          </Form.Item>
        )}
        {isRequired && (
          <Form.Item
            name="fileFormat"
            label="附件格式"
            rules={[{ required: true, message: "请输入附件格式" }]}
          >
            <Input placeholder="请输入商品文件格式，可多输入多个格式，以「、」或「，」分隔" />
          </Form.Item>
        )}
        {!isRequired && (
          <Form.Item name="fileFormat" label="附件格式">
            <Input placeholder="请输入商品文件格式，可多输入多个格式，以「、」或「，」分隔" />
          </Form.Item>
        )}

        {localUser?.mallShopPermission?.productOuterUrlFlag === 1 && (
          <>
            <Form.Item name="isOutUrlShow" label="非站内交易">
              <Radio.Group>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Form.Item>

            {isOutUrlShow && (
              <Form.Item name="outerUrl" label="站外链接">
                <Input
                  maxLength={256}
                  placeholder="请输入该属性商品站外购买链接,例：https://..."
                ></Input>
              </Form.Item>
            )}
          </>
        )}

        <div className="btns">
          <Button type="primary" onClick={add} loading={loading}>
            保存商品属性
          </Button>
          <Button type="primary" onClick={reset}>
            取消
          </Button>
          {!!(detail && detail?.name) && (
            <Button
              type="primary"
              ghost
              onClick={() => deleteSpecification && deleteSpecification(detail)}
            >
              删除商品属性
            </Button>
          )}
        </div>
      </Form>
    </SpecificationsFormStyle>
  );
};

export default SpecificationsForm;
