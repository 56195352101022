import styled from "styled-components";

export const UserStyle = styled.div`
  .unAuthed {
    height: 50px;
    display: flex;
    cursor: pointer;
    min-width: 130px;
    .sign-in {
      color: ${(props) => props.theme.colors.colorPrimary};
      height: 100%;
      line-height: 50px;
      padding: 0 ${(props) => props.theme.space.padding}px;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
    .sign-up {
      color: ${(props) => props.theme.colors.colorText};
      background-color: ${(props) => props.theme.colors.colorPrimary};
      height: 100%;
      line-height: 50px;
      padding: 0 ${(props) => props.theme.space.padding}px;
      &:hover {
        background-color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
  }
  .authed {
    display: flex;
    align-items: center;
    cursor: pointer;
    .avatar {
      aspect-ratio: 1/1;
      /* background-color:${(props) => props.theme.colors.colorPrimary}; */
      line-height: 50px;
      padding: 0 ${(props) => props.theme.space.padding}px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .notification{
      cursor: pointer;
      position: relative;
      .icon{
        font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px;
        line-height: 50px;
        color:#fff;
        &:hover {
          color: ${props => props.theme.colors.colorPrimary};
        }
      }
      .ant-badge-count {
        top: 10px;
      }
      padding: 0 ${(props) => props.theme.space.padding}px;
    }
    .upload {
      color: ${(props) => props.theme.colors.colorTextLightSolid};
      line-height: 50px;
      padding: 0 ${(props) => props.theme.space.padding}px;
      padding-top:2px;
      font-size: ${(props) => props.theme.fontSizes.fontSizeXL + 1}px;
      position: relative;
    }
  }
`;
export const UploadDorpdownStyle = styled.div`
  position: absolute;
  top: 50px;
  right: 50%;
  transform: translateX(50%);
  padding: ${(props) => props.theme.space.paddingXS}px;
  .inner-container{
    box-shadow: ${(props) => props.theme.shadow.boxShadow};
    border-radius:  ${(props) => props.theme.border.borderRadius}px;
    overflow: hidden;
    width: max-content;
    background-color: #fff;
  }
  .item-wrap{
    margin: ${(props) => props.theme.space.marginXS}px;
    .dropdown-item {
    padding: 0 ${(props) => props.theme.space.paddingLG}px;
    color: ${(props) => props.theme.colors.colorText};
    font-size: ${(props) => props.theme.fontSizes.fontSize}px;
    border-bottom: ${(props) => props.theme.colors.colorBorder} 1px
      solid;
    background-color: #fff;
    height: 38px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.theme.border.borderRadiusXS}px;

    &:hover {
      background-color: ${(props) => props.theme.colors.colorPrimary};
      border-radius: ${(props) => props.theme.border.borderRadius}px;
    }
    &:last-child {
      border: none;
    }
  }
  }

`;

export const UserDropdownStyle = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  z-index: 199;
  padding-top: ${(props) => props.theme.space.paddingXS}px;
  .inner-container{
    width: max-content;
    overflow: hidden;
    box-shadow: ${(props) => props.theme.shadow.boxShadow};
    border-radius:  ${(props) => props.theme.border.borderRadius}px;
    padding:0 ${(props) => props.theme.space.paddingXS}px ;
    padding-top:${(props) => props.theme.space.paddingSM}px;
    background-color: #fff;

  }

  .dropdown-item {
    padding: 0 ${(props) => props.theme.space.paddingLG}px;
    color: ${(props) => props.theme.colors.colorTextLightCustom};
    font-size: ${(props) => props.theme.fontSizes.fontSize}px;
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      margin-bottom: ${(props) => props.theme.space.paddingXS}px;
    height: 38px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: ${(props) => props.theme.colors.colorPrimary};
      color: ${(props) => props.theme.colors.colorText};
    }
    &:last-child {
      border: none;
    }
  }

  .infoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom:  ${(props) => props.theme.space.paddingXS}px;
    .grid-item {
      cursor: default;
      margin: 0 ${(props) => props.theme.space.paddingXS}px;
      padding:  ${(props) => props.theme.space.paddingXS}px ${(props) => props.theme.space.paddingXS}px 0 ${(props) => props.theme.space.paddingXS}px;
      text-align: center;
      line-height: normal;
      position: relative;
      /* &:hover {
        background-color: ${(props) => props.theme.colors.colorPrimary};
        border-radius: ${(props) => props.theme.border.borderRadius}px;
      } */
      .number {
        font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px;
      }
      .title {
        line-height: 40px;
      }
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 24px;
        position: absolute;
        top: 50%;
        right: -8px;
        transform: translateY(-50%);
        background-color: ${(props) => props.theme.colors.colorBorder};
      }
    }
  }
`;
