import { get, post } from "api"
import { addToAlbumReq, albumDetailType, albumListItem, albumWorkListReq, getAlbumListReq, updateAlbumReq } from "types/album"
import { PaginationResponse } from "types/common/axios"
import { WorkListItem } from "types/work"

// 获取作品集列表
export const apiGetAlbumList = (params: getAlbumListReq) => {
    return get<getAlbumListReq, PaginationResponse<albumListItem>>('/api/portfolio/list', params)
}

// 创建作品集
export const postCreateAlbum = (name: string) => {
    return post<{ name: string }, albumDetailType>('/api/portfolio/create', { name })
}

// 删除作品集
export const postDeleteAlbum = (id: number) => {
    return post<{ id: number }, albumDetailType>(`/api/portfolio/delete/${id}`)
}

// 添加到作品集
export const postAddToAlbum = (params: addToAlbumReq) => {
    return post<addToAlbumReq, {}>(`/api/portfolio/able_flag`, params)
}

// 修改作品集
export const postUpdateAlbum = (params: updateAlbumReq) => {
    return post<updateAlbumReq, {}>(`/api/portfolio/update`, params)
}

export const getAlbumWorkList = (params: albumWorkListReq) => {
    return get<albumWorkListReq, PaginationResponse<WorkListItem>>('/api/portfolio/work_list', params)
}