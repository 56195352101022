import { apiGetUserBaseInfo, masklogin } from "api/user";
import { useEffect } from "react"

export const AdminLogin = () =>{


    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        const accessCode = params.get('accessCode');
        const userId = params.get('userId');
        const redirectUrl = params.get('redirectUrl')
        if(accessCode && userId) {
            masklogin({accessCode: accessCode, userId: +userId!}).then(async res=>{
                localStorage.setItem('auth_token', res )
                const userInfo = await apiGetUserBaseInfo()
                localStorage.setItem("user_info", JSON.stringify(userInfo));
                window.location.href = redirectUrl!
            })
        }
    }, [])

    return <div>
                管理员登陆
          </div>
}