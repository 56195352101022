import { Modal, Progress, Image } from "antd";
import { UploadListStyle } from "./style";
import { ListManager } from "react-beautiful-dnd-grid";

import {
  RiImageFill,
  RiDeleteBin6Line,
  RiVideoFill,
  RiPlayCircleLine,
  RiEditBoxLine,
  RiMagicLine,
  RiEyeLine,
  RiBox3Line,
} from "react-icons/ri";


import { Suspense, useState } from "react";
import { MediaType } from "types/enums/media-type";
import { ListItem } from "./type";
import React from "react";
const ThreeDContentDisplayComponent = React.lazy(() => import("./3dRender"));
const MarmosetDisplayComponent = React.lazy(() => import("./marmosetPreview"));
type Props = {
  mediaList: ListItem[];
  onRemove: (item: ListItem) => void;
  dragChangeList: (list: ListItem[]) => void;
  edit: (item: ListItem) => void;
};

function UploadList({ mediaList, onRemove, dragChangeList, edit }: Props) {
  const remove = (item: ListItem) => {
    onRemove(item);
  };
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewVideoUrl, setPreviewVideoUrl] = useState("");
  const [preview3DVisible, set3DPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);

  const [currentItem, setCurrentItem] = useState<ListItem>();


  const onClickPreviewVideo = (item: ListItem) => {
    setCurrentItem(item);
    setPreviewVideoUrl("");
    if (item.type === MediaType.image) {
      if (
        item.params?.specialEffectTemplateId &&
        item.params?.specialEffectTemplateId! >= 0
      ) {
        setPreviewVideoUrl(item.params?.specialEffectVideo!);
        setPreviewVisible(true);
      } else {
        setPreviewImage(true);
      }
    } else if (item.type === MediaType.video) {
      setPreviewVideoUrl(item.url);
      setPreviewVisible(true);
    }
  };

  const onClickPreview3D = (item: ListItem) => {
    setCurrentItem(undefined);
    setCurrentItem(item);
    set3DPreviewVisible(true);
  };

  const ListElement = ({ item }: any) => {
    return (
      <div className="card">
        {item.percent < 100 && (
          <Progress
            percent={item.percent}
            className="progress"
            showInfo={false}
            strokeColor={"#FCC55A"}
          />
        )}

        {item.type === MediaType.image && (
          <div
            className="content"
            style={{ backgroundImage: `url(${item.url})` }}
          >
            {item.id > 0 && (
              <div className="image-icon-wrapper">
                <div className="icon ">
                  <RiImageFill />
                </div>
                {item.params && item.params?.specialEffectTemplateId && (
                  <div className="icon ">
                    <RiMagicLine />
                  </div>
                )}
              </div>
            )}

            <Image
              width={200}
              style={{ display: "none" }}
              src={currentItem?.url}
              preview={{
                visible: previewImage,
                src: currentItem?.url,
                onVisibleChange: (value) => {
                  setPreviewImage(value);
                },
              }}
            />

            {item.id > 0 && (
              <div className="play" onClick={() => onClickPreviewVideo(item)}>
                <RiEyeLine size={30} color="#fff" />
              </div>
            )}

           
          </div>
        )}

        {item.type === MediaType.video && (
          <div
            className="content"
            style={{
              backgroundImage: `url(${item.coverUrl ? item.coverUrl : ""} )`,
            }}
          >
            {item.id > 0 && (
              <div className="icon">
                <RiVideoFill />
              </div>
            )}
            {item.id > 0 && (
              <div className="play" onClick={() => onClickPreviewVideo(item)}>
                <RiPlayCircleLine size={40} color="#fff" />
              </div>
            )}
          </div>
        )}

        {item.type === MediaType.threed && (
          <div
            className="content"
            style={{
              backgroundImage: `url(${
                item.params?.threeDBgUrl!
              })`
            }}
          >
            {item.id > 0 && (
              <div className="icon">
              <RiBox3Line />
              </div>
            )}
            {item.id > 0 && (
              <div className="play" onClick={() => onClickPreview3D(item)}>
                <RiPlayCircleLine
                  style={{ opacity: 1 }}
                  size={40}
                  color="#fff"
                />
              </div>
            )}
          </div>
        )}

{item.type === MediaType.marmoset && (
          <div
            className="content"
            style={{
              backgroundImage: require("assets/images/components/3d-cover.png"),
            }}
          >
            {item.id > 0 && (
              <div className="icon">
                <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/logo_simple_dark64.png" alt="" width={19}/>
              </div>
            )}
            {item.id > 0 && (
              <div className="play" onClick={() => onClickPreview3D(item)}>
                <RiPlayCircleLine
                  style={{ opacity: 1 }}
                  size={40}
                  color="#fff"
                />
              </div>
            )}
          </div>
        )}

        {item.id > 0 && (
          <div className="delete operate" onClick={() => remove(item)}>
            <RiDeleteBin6Line size={20} />
          </div>
        )}

        {item.id > 0 && (
          <div className="edit operate" onClick={() => edit(item)}>
            <RiEditBoxLine size={20} />
          </div>
        )}

        <div className="foot"></div>
      </div>
    );
  };


  const reorderList = (sourceIndex: number, destinationIndex: number) => {
    console.log("reorderList", sourceIndex, destinationIndex);
    if (!mediaList) return;
    if (destinationIndex === sourceIndex) {
      return;
    }
    const targetItem = mediaList[sourceIndex];
    if(destinationIndex<sourceIndex){
      const a = mediaList.slice(0,destinationIndex)
      const b = mediaList.slice(destinationIndex)
      const aRemoveTarget = a.filter(item=> item.id !== targetItem.id);
      const bRemoveTarget = b.filter(item=> item.id !== targetItem.id);
      const sortedList = [...aRemoveTarget,targetItem,...bRemoveTarget]
      dragChangeList(sortedList);
    }

    if(destinationIndex>sourceIndex){
      const a = mediaList.slice(0,destinationIndex+1)
      const b = mediaList.slice(destinationIndex+1)
      const aRemoveTarget = a.filter(item=> item.id !== targetItem.id);
      const sortedList = [...aRemoveTarget,targetItem,...b]
      dragChangeList(sortedList);

    }
  
  };



  return (
    <UploadListStyle>
      {mediaList && (
        <ListManager
          items={mediaList}
          direction="horizontal"
          maxItems={4}
          render={(item) => <ListElement item={item} />}
          onDragEnd={reorderList}
        />
      )}

<Modal
              title="视频预览"
              open={previewVisible}
              onOk={() => setPreviewVisible(false)}
              onCancel={() => setPreviewVisible(false)}
              width={800}
              footer={null}
              style={{ maxHeight: "90vh" }}
            >
              <video
                style={{ width: "100%" ,maxHeight:'80vh'}}
                src={previewVideoUrl}
                controls
              ></video>
            </Modal>

   {currentItem && <Modal
        title={"3d预览"}
        open={preview3DVisible}
        onOk={() => set3DPreviewVisible(false)}
        onCancel={() => set3DPreviewVisible(false)}
        width={1000}
        getContainer={false}
        footer={null}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Suspense>
          {currentItem && currentItem.type ===3 && (
            <ThreeDContentDisplayComponent
              currentItem={currentItem}
            ></ThreeDContentDisplayComponent>
          )}
          {currentItem && currentItem.type ===5 && (
            <MarmosetDisplayComponent
              currentItem={currentItem}
            ></MarmosetDisplayComponent>
          )}
        </Suspense>
      </Modal>}   
    </UploadListStyle>
  );
}

export default UploadList;
