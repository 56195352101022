import { get } from "api"
import { TagCategoryAbleListRequest, TagItem } from "types/category"


// 获取推荐标签列表
export const getRecommendCategoryTagList = (params: TagCategoryAbleListRequest) => {
    return get<TagCategoryAbleListRequest, TagItem[]>("/api/recommend_tag/list", params)
}



