import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  message,
} from "antd";
import ImageUpload from "components/uploadImage";
import { FileUpload } from "components/fileUpload";
import { getFileUrlByCosUpload } from "config/cos";
import { ProgressInfo } from "cos-js-sdk-v5";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CosDirectoryType, MediaType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";
import { apiCreateMedia, apiUpdateMedia } from "api/media";
import { ThreeDEditForm, ThreeDEditProps } from "../type";


const { TextArea } = Input;



export const ComboThreedUpload = (props: ThreeDEditProps) => {
  const [form] = Form.useForm<ThreeDEditForm>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { id } = useParams();
 
  useEffect(() => {
    setConfirmLoading(false);
    form.resetFields();
    form.setFieldsValue({
     ...props.params
    });
  }, [props.show]);

  const onCancel = () => {
    props.close();
  };

  const onClickSubmit = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        let { model, hdr, bg, caption} = values
        
          let modelFile = model.file
          const uploadUUid = getFileName(CosDirectoryType.workModel,modelFile!.name)
          let newItem = { id: 0, url: "", coverUrl: '', type: MediaType.threed, key: uploadUUid, percent: 0,caption: '', params: {
            hdrImageUrl: '',
            threeDBgUrl: '',
          }, };

          props.refreshMediaList(newItem, 'add')

          props.close()

        let resultHdr = {
          url: props.params.hdr.fileUrl,
          md5: ''
        }
        
          if(hdr&&hdr.file) {
            resultHdr = await getFileUrlByCosUpload(
              hdr.file!,
              getFileName(CosDirectoryType.workModel, hdr.file!.name),
            )
          }
            let resultBg = {
              url: props.params.bg.imgUrl,
              md5: ''
            }
            if(bg&&bg.file) {
                resultBg = await getFileUrlByCosUpload(
                bg.file!,
                getFileName(CosDirectoryType.workModel, bg.file!.name),
              )
              }

          newItem.coverUrl = resultBg.url

          props.refreshMediaList(newItem, 'update')
        
          const resultThreeD = await getFileUrlByCosUpload(
            model.file as File,
            uploadUUid,
            onProgress
          );
       

          //绑定媒体，写入数据库
          const dataItem = await apiCreateMedia({
            coverUrl:  '',
            dataId: Number(id),
            dataTable: "work",
            md5: resultThreeD.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
            name: model.file!.name.split(".").shift()+'',
            rank: 0,
            size: model.file!.size,
            type: MediaType.threed,
            url: resultThreeD.url,
            mimeType: model.file!.type,
            uuid: uploadUUid,
            caption: caption,
            params: {
              hdrImageUrl: resultHdr.url,
              threeDBgUrl: resultBg.url
            },
            mark:props.mark,
          });
          newItem.url = dataItem.url
          newItem.id = dataItem.id;
          newItem.coverUrl = dataItem.coverUrl;
          newItem.percent=100;
          newItem.caption =dataItem.caption
          newItem.params = dataItem.params
          props.refreshMediaList(newItem, 'update')
      })
      .catch((err) => {
        setConfirmLoading(false);
      });
  };

    //上传进度回调
    const onProgress = (progressData: ProgressInfo, key: string) => {
      props.refreshMediaList(
          { id: 0, url: "",coverUrl: '',  key: key, type: MediaType.threed, percent:  progressData.percent * 100, caption: '' }, 'update'
      ) 
    };

  const onClickSave = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        let {  hdr, bg, caption} = values
        
        let newItem = { id: 0, url: "", coverUrl: '', type: MediaType.threed, key: '', percent: 0,caption: caption, params: {
            hdrImageUrl: '',
            threeDBgUrl: '',
           
        } };
        let resultHdr = {
          url: '',
          md5: ''
        }
        
          if(hdr&&hdr.file) {
            resultHdr = await getFileUrlByCosUpload(
              hdr.file!,
              getFileName(CosDirectoryType.workModel, hdr.file!.name),
            )
          }
            let resultBg = {
              url: '',
              md5: ''
            }
            if(bg&&bg.file) {
                resultBg = await getFileUrlByCosUpload(
                bg.file!,
                getFileName(CosDirectoryType.workModel, bg.file!.name),
              )
              }


          //绑定媒体，写入数据库
          const dataItem = await apiUpdateMedia({
            id: props.params.id!,
            caption: caption,
            params: {
              hdrImageUrl: resultHdr.url,
              threeDBgUrl: resultBg.url,
            },
          });
          newItem.id = dataItem.id;
          newItem.caption=dataItem.caption
          newItem.coverUrl = dataItem.coverUrl
          newItem.key = dataItem.uuid
          newItem.percent = 100
          newItem.url = dataItem.url
          newItem.params = dataItem.params
          props.refreshMediaList(newItem, 'update')
          message.success('修改成功!')
          props.close()
      })
      .catch((err) => {
        setConfirmLoading(false);
      });
    
  };


  return (
    <div>
      <Modal
        title="3D文件"
        open={props.show}
        cancelText="取消"
        onCancel={onCancel}
        okText="保存"
        onOk={ props.params.id ? onClickSave : onClickSubmit } 
        confirmLoading={confirmLoading}
        width={500}
        maskClosable={false}
        forceRender
      >
        <Spin spinning={confirmLoading}>
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              
              <Col span={8} > 
                <Form.Item
                  name="model"
                  tooltip="支持.glb格式,可通过各种3D软件导出"
                  label="3D 模型"
                  rules={[{ required: true, message: '模型文件必填' }, 
                  
                  { validator:  (rule, val, callback) => {
                    if(val.fileUrl){
                      return Promise.resolve()
                    } else {
                      return Promise.reject('模型文件必填')
                    }
                  }}
                ]}
                  >
                  <FileUpload  disabled={!!props.params.id} fileType="glb"  />
                </Form.Item>
              </Col>

              <Col span={8} > 
                <Form.Item
                  name="hdr"
                  label="3D HDR图"
                  tooltip="支持.hdr格式的HDRI环境贴图"
                  rules={[
                  
                ]}
                  >
                  <FileUpload fileType="hdr"  />
                </Form.Item>
              </Col>

              <Col span={8} > 
                <Form.Item
                  name="bg"
                  label="3D背景图"
                  tooltip="支持jpg,png格式的背景贴图"
                  rules={[
                  
                ]}>
                  <ImageUpload  />
                </Form.Item>
              </Col>
              
              <Col span={24}> 
                <Form.Item
                  name="caption"
                  label="描述"
                 
                      >
                    <TextArea placeholder='请输入描述' maxLength={500} rows={2} showCount={true}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};
