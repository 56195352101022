import { useCallback, useEffect, useRef, useState } from "react"
import { ContestWorkStyle } from "./style"
import { ContestDetailResponse, ContestStageListResponse, VoteUserTotal, WorkContestListItem, WorkContestListRequest } from "types/contest";
import { NoData } from "components/no-data";
import { getContestList, getContestUserVoteTotal, getcontestStageList, postContestUserTicket } from "api/contest";
import ContestWorkFilter from "./work-filter";
import { Modal, Pagination, Spin, message } from "antd";
import { hasToken, queryUrl, setWorkListParamsForNav } from "utils/functions/common";

import { useLocation, useNavigate, useOutletContext } from "react-router";
import ContestWorkCard from "components/uni-card/self-cards/contestWorkCard";
import { UserBaseInfo } from "types/user";
import VoteTicket from "components/vote-ticket";
import { loginPathWithRedirect } from "utils/functions/user";

const Regulation2024Work = ({ name }: { name: string }) => {
    const [list, setList] = useState<WorkContestListItem[]>([])
    const [total, setTotal] = useState<number>(1)
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const themeId = new URLSearchParams(useLocation().search).get("themeId")
    let categoryId = new URLSearchParams(useLocation().search).get("categoryId")
    let pageNumber = Number(new URLSearchParams(useLocation().search).get("pageNumber"))
    const sortField = new URLSearchParams(useLocation().search).get("sortField") || ''
    let publishType = new URLSearchParams(useLocation().search).get("publishType") || ''
    let themeA = new URLSearchParams(useLocation().search).get("themeA") || ""
    let tagName = new URLSearchParams(useLocation().search).get("tagName") || ""

    const pageSize = 48;
    const [stageList, setStageList] = useState<ContestStageListResponse[]>([])
    let stageNumber = Number(new URLSearchParams(useLocation().search).get("stageNumber") || 0)
    const [stage, setStage] = useState(1)
    const [loading, setLoading] = useState(false)
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
    const [canVoteTotal, setCanVoteTotal] = useState(0)
    const voteRef = useRef() as React.MutableRefObject<any>;
    const [isVoteShow, setIsVoteShow] = useState(false)
    const [currentVoteItem, setCurrentVoteItem] = useState<WorkContestListItem>()
    const [listParams, setListParams] = useState<WorkContestListRequest>()
    const [voteDetail, setVoteDetail] = useState<VoteUserTotal>()
    const [isShowVoteTip, setIshowVoteTip] = useState(false)

    const navigate = useNavigate()

    const token = hasToken()

    const [isShowLoginTip, setIsShowLoginTip] = useState(false)

    const getList = async (value?: WorkContestListRequest) => {
        setLoading(true)
        const result = stageList.find((item) => item.number === stage)
        let params = {
            contestCode: name,
            pageNumber: Number(pageNumber) || 1,
            pageSize,
            stageNumber: stage,
            ...value
        }
        if (themeId) {
            if (themeId === 'all') {
                params.theme = 0
            } else {
                params.theme = Number(themeId)
            }
        }
        if (categoryId) {
            params.categoryId = Number(categoryId)
        }
        if (sortField) {
            params.sortField = sortField
        }
        if (publishType) {
            params.publishType = Number(publishType)
        }
        if (result?.voteId && !!(result?.isClosedVoteButton === 0 && result?.voteId > 0)) {
            params.voteId = result.voteId
        }
        if (themeA) {
            if (themeA === 'all') {
                params.themeA = 0
            } else {
                params.themeA = Number(themeA)
            }
        }
        if (tagName) {
            params.tagName = tagName
        }
        setListParams(params)
        const res = await getContestList(params).then().finally(() => {
            setLoading(false)
        })
        setList(res.pageData)
        setTotal(res.totalSize)
    }



    useEffect(() => {
        if (!pageNumber) {
            return
        }
        const offsetTop = document.getElementById("regulation-tab")?.offsetTop!;
        window.scrollTo({
            top: offsetTop - 200,
            behavior: "smooth",
        });
    }, [pageNumber])

    useEffect(() => {
        pageNumber = 1
    }, [themeId, categoryId, sortField, publishType, stageNumber, themeA])

    useEffect(() => {
        if (!detail) return
        getcontestStageList(detail?.id).then(res => {
            setStageList(res)
        })
    }, [detail])

    useEffect(() => {
        if (!detail) return
        setStage(Number(stageNumber) || detail.displayStageNumber || 1)
    }, [detail, stageNumber])

    useEffect(() => {
        if (stageList.length === 0) return
        const result = stageList.find((item) => item.number === stage)
        setIshowVoteTip(result?.isClosedVoteButton === 0 && result?.voteId > 0)
    }, [stageList, stage])

    useEffect(() => {
        if (!detail) return
        if (stageList.length === 0) return
        if (!token && pageNumber > 3) {
            setIsShowLoginTip(true)
            return
        }
        getList()
    }, [detail, themeId, categoryId, pageNumber, sortField, publishType, stage, themeA, tagName, stageList])



    const updateValue = (value: WorkContestListRequest) => {
        getList(value)
    }

    const changePage = (e: number) => {
        if (!token && e > 3) {
            setIsShowLoginTip(true)
            return
        }
        const url = queryUrl({ pageNumber: e })
        navigate(`?${url}`)
    };

    const checkoutStage = (e: number) => {
        const url = queryUrl({ stageNumber: e, sortField: "" })
        navigate(`?${url}`)
    }

    const saveWorkIdsForNav = () => {

        list && localStorage.setItem(
            "workIdsForNav",
            JSON.stringify(
                list.map((item) => {
                    return item.id;
                })
            )
        );
    }
    // 获取用户可使用票数
    const getUserContestVote = () => {
        const findResult = stageList.find((item) => item.number === stage)
        if (!!findResult && !!Number(findResult.voteId) && findResult.isClosedVoteButton === 0) {
            getContestUserVoteTotal({ id: Number(findResult.voteId) }).then(res => {
                setCanVoteTotal(res.validTicketCount)
                setVoteDetail(res)
            })
        }
    }

    const updateVote = (detail: WorkContestListItem, count: number) => {
        const index = list.findIndex(item => {
            return item.id === detail.id
        })
        list[index].vote.ticketCount = list[index].vote.ticketCount + count
        setList([...list])
    }

    useEffect(() => {
        if (userInfo?.id) {
            getUserContestVote()
        }
    }, [stage, stageList])

    const close = () => {
        setIsVoteShow(false)
    }

    const vote = (value: number, detail?: WorkContestListItem) => {
        const voteId = Number(detail && detail.vote.voteId) || Number(currentVoteItem?.vote.voteId)
        const workId = Number(detail && detail.id) || Number(currentVoteItem?.id)
        postContestUserTicket({ count: value, id: voteId, workId }).then(res => {
            message.success("投票成功")
            updateVote(detail || currentVoteItem!, value)
            getUserContestVote()
            close()
            if (!!detail) {
                const index = list.findIndex(item => item.id === workId)
                list[index].vote.status = 1
                setList([...list])
            }
        }).finally(() => {
            voteRef.current.resetLoading()
        })
    }

    const selectVoteItem = (detail: WorkContestListItem) => {
        setCurrentVoteItem(detail)
        if (voteDetail?.useRule === 1 && voteDetail.validTicketCount > 0) {
            vote(1, detail)
            return
        }
        setIsVoteShow(true)
    }

    const t_id = Date.now();

    const saveWorkListParamsForNav = (index: number) => {
        const params = { number: index, ...listParams }
        setWorkListParamsForNav(params, t_id);
    };

    return <ContestWorkStyle onClick={() => saveWorkIdsForNav()}>
        <Spin spinning={loading}>
            {
                stageList && stageList.length > 1 && <div className="commpetiton">
                    {
                        stageList.map((item, index) => {
                            return <div key={index} onClick={() => checkoutStage(item.number)} className={`${item.number === stage ? 'stage-active' : ''} commpetiton-item`}>{item.name}</div>
                        })
                    }
                </div>
            }

            <div id="type">
                <ContestWorkFilter subDetail={detail} updateValue={updateValue} showVoteFilter={isShowVoteTip} />
            </div>

            {
                isShowVoteTip && <div className="right-wrap">
                    <div className="vote-ticket">
                        当前可用票数：{canVoteTotal}
                    </div>
                </div>
            }
            {
                list.length === 0 && <NoData></NoData>
            }

            <div className="list" >
                {
                    list.map((item, index) => {
                        return <div onClick={() => saveWorkListParamsForNav(index)} key={index} >

                            <ContestWorkCard selectVoteItem={selectVoteItem} canVoteTotal={canVoteTotal} item={item} t_id={t_id}></ContestWorkCard></div>
                    })
                }
            </div>

            <Pagination
                style={{ textAlign: "center", margin: "12px 0 42px 0" }}
                hideOnSinglePage={true}
                current={pageNumber}
                onChange={changePage}
                pageSize={pageSize}
                total={total}
                showSizeChanger={false}
                showQuickJumper
            />
        </Spin>
        <VoteTicket ref={voteRef} isShow={isVoteShow} vote={vote} close={close} ticket={canVoteTotal} />
        <Modal
            title="提示"
            open={isShowLoginTip}
            onOk={() => { setIsShowLoginTip(false); navigate(loginPathWithRedirect()) }}
            onCancel={() => setIsShowLoginTip(false)}
            centered
            okText="前往登录"
            destroyOnClose //关闭时销毁
        >
            登录查看更多优秀作品
        </Modal>
    </ContestWorkStyle>
}

export default Regulation2024Work