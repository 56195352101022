import { useEffect, useMemo, useState } from 'react'
import { MylikesStyle } from './style'
import { Pagination, Spin } from 'antd'
import { NoData } from 'components/no-data'
import {  getLocalUserInfo } from 'utils/functions/common'
import { getWorkLikeList } from 'api/work'
import { WorkLikeListsItem } from 'types/user/work'
import PublicESCard from 'components/uni-card/public-cards/publicESCard'
import useWindowSize from 'hooks/useWindowSize'

function MyLikes() {
    const [loading, setLoading] = useState(false)
    const [workList, setWorkList] = useState<WorkLikeListsItem[]>()
    const [pageNumber, setPageNumber] = useState(1)
    const [total, setTotal] = useState(0)
    const [screenSize] = useWindowSize()

    useEffect(() => {
        getList()
    },[pageNumber])
    const getList = ()=>{
        setLoading(true)
        getWorkLikeList({userId:getLocalUserInfo()?.id,pageNumber:params.pageNumber,pageSize:params.pageSize}).then(res=>{
            setWorkList(res.pageData)
            setTotal(res.totalSize)
            setLoading(false)
        })
    }
    const params = useMemo(() => {
        return {
          pageNumber,
          pageSize: 30,
        }
      }, [pageNumber])
      
    const changePage = (e: number) => {
        setPageNumber(e);
    };

  return (
    <MylikesStyle>
       
        <Spin spinning={loading}>
        <div className='data-list' style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
          {workList?.map((workListItem, index) => {
            
             return <PublicESCard item={workListItem} key={index}></PublicESCard>
     
          })}
        </div>
        {
          workList?.length === 0 && <div style={{ padding: '100px 0' }}><NoData /></div>
        }
      </Spin>

      <Pagination
        style={{ textAlign: "center", marginTop: "28px" }}
        hideOnSinglePage={true}
        current={pageNumber}
        onChange={changePage}
        showSizeChanger={false}
        pageSize={params.pageSize}
        total={total}
      />
    </MylikesStyle>
  )
}

export default MyLikes