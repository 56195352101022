import styled from "styled-components";

export const CommandItemStyle = styled.div`
    position: relative;
    margin-bottom: ${props => props.theme.space.marginXS}px;
    .left-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -29px;
        cursor: pointer;
        padding: ${props => props.theme.space.paddingXS}px;
    }
    .right-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -29px;
        cursor: pointer;
        padding: ${props => props.theme.space.paddingXS}px;
    }
    .item {
        padding: 1px;
        min-width: 98px !important;
        max-width: 98px !important;
        height: 98px;
        background: black;
        border-radius:  ${props => props.theme.border.borderRadiusSM}px;
        box-sizing: border-box;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        &:hover{
          box-shadow: ${props => props.theme.shadow.boxShadow};
        }
    } 
`