import { v4 as uuidv4 } from 'uuid';
import qs from 'query-string';
import { UserRoleArr } from 'types/enums/user-role-type';
import { emojiJson } from 'mock/emoJson';
import { apiGetUserBaseInfo } from 'api/user';
//延迟执行
export const sleep = async (milliseconds: number) => {
  await new Promise((resolve) => {
    return setTimeout(resolve, milliseconds);
  });
};

//检查手机号码格式
export const checkPhone = (mobile: string): boolean => {
  if (/^[1][3,4,5,6.7,8,9][0-9]{9}$/.test(mobile)) {
    return true;
  }
  return false;
};

//检查邮箱格式
export const checkEmail = (mobile: string): boolean => {
  if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(mobile)) {
    return true;
  }
  return false;
};

// 获取cos随机文件名
export const getFileName = (dir: string, name: string) => {
  const now = new Date();
  let str = `/ggac/${dir}/${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}/` + uuidv4() + "." + name.split(".").pop();
  return str
}

export const getCosPathByFileName = (dir: string, name: string) => {
  const now = new Date();
  let str = `/ggac/${dir}/${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}/` +name.split(".")[0]+'_' +Math.floor(Date.now() / 1000)+  "." + name.split(".").pop() ;
  return str
}

//防抖
export function antishake<T>(fn: T, wait: number = 800): () => void { //第一个参数是函数 第二个参数是毫秒值
  let timer: NodeJS.Timeout //声明一个变量来接收延时器 初始值为null
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      typeof fn === 'function' && fn(...args) //调用这函数
    }, wait);
  }
}

// 节流
export function throttle<T>(fn: T, wait: number = 800): () => void {
  let timer: NodeJS.Timeout | null //节点闸
  return function (...args) {
    if (timer) return //null false 不是null结果减少true 如果上传没有我就直接跳过 没有人我就上去
    timer = setTimeout(() => {
      typeof fn === 'function' && fn(...args) //调用这函数
      timer = null //做完之后重新关闭节点闸
    }, wait);
  }
}



//随机整数函数
export function randomNum(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getLocalUserInfo = () => {
  const userString = localStorage.getItem("user_info")
  let userInfo
  if (userString) {
    userInfo = JSON.parse(userString);
  } else {
    userInfo = undefined
  }
  return userInfo
}

export const activeGetLocalUserInfo = async () => {

  const userInfo = await apiGetUserBaseInfo()
  localStorage.setItem("user_info", JSON.stringify(userInfo));
  return userInfo
}


// 判断是否有Token
export const hasToken = () => {
  const token = localStorage.getItem('auth_token')
  if (token) {
    return true
  } else {
    return false
  }
}

// 判断是否有Token
export const getUserId = () => {
  const user_info = localStorage.getItem('user_info')
  if (!!user_info) {
    return JSON.parse(user_info)?.id
  } else {
    return false
  }
}

// 判断是否为手机端
export const isMobile = () => {
  let flag = false,
    userAgentInfo = navigator.userAgent,
    Agents = [
      'phone',
      'pad',
      'MiuiBrowser',
      'pod',
      'iPhone',
      'iPod',
      'ios',
      'iPad',
      'Android',
      'Mobile',
      'BlackBerry',
      'IEMobile',
      'MQQBrowser',
      'JUC',
      'Fennec',
      'wOSBrowser',
      'BrowserNG',
      'WebOS',
      'Symbian',
      'Windows Phone',
    ]
  console.log('userAgentInfo', userAgentInfo)
  for (let a = 0; a < Agents.length; a++) {
    if (userAgentInfo.indexOf(Agents[a]) >= 0) {
      flag = true
      break
    }
  }
  return flag
}



// 获取当前路由query 并添加内容跳转
export const queryUrl = (params: object) => {
  const query = qs.parse(window.location.search)
  const obj = Object.assign(query, params)
  Object.keys(obj).forEach(item => {
    if (!obj[item]) {
      delete obj[item]
    }
  })
  return qs.stringify(obj)
}


//翻译卡片类目格式
type CategoryList = {
  dataId?: number;
  id: number;
  level: number;
  name: string;
}[]

export const translateCardCategoryText = (list: CategoryList) => {
  if ((list && list.length === 0) || !list) {
    return ''
  }
  let lev1 = ''
  let lev2 = ''
  list.forEach(item => {
    if (item.level === 1) {
      lev1 = item.name
    }
    if (item.level === 2) {
      lev2 = item.name
    }
  })

  if (lev1 && lev2) {
    // return lev1 + '-' + lev2
    return lev2
  } else if (lev1) {
    return lev1
  } else {
    return lev2
  }
}

// 匹配用户对应的身份标识
export const findUserRole = (id: number) => {
  return UserRoleArr.find(item => {
    return item.value === id
  })
}

//判断元素是否在数组内
export function inArray(element: (string | number), array: Array<string | number>): boolean {
  return array.indexOf(element) !== -1;
}

//从数组中获取任意数量 随机元素
export function getRandomItemsFromArray(arr: Array<any>, count: number) {
  //结果数量超过数组长度，则结果数量 = 数组长度
  if (count > arr.length) {
    count = arr.length
  }
  var randomIndices: any[] = [];
  var randomItems = [];

  // Generate two unique random indices
  while (randomIndices.length < count) {
    var randomIndex = Math.floor(Math.random() * arr.length);

    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
    }
  }

  // Retrieve the random items using the generated indices
  randomItems = randomIndices.map(index => arr[index]);

  return randomItems;
}

//数组洗牌
export function shuffleArray<T>(array: T[]): T[] {
  const newArray = [...array]; // Create a shallow copy of the original array

  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // Swap elements
  }

  return newArray;
}

// emoji转码
export const emojiParse = (str: string) => {
  emojiJson.forEach((e) => {
    e.em_list.forEach((ele, i) => {
      if (str.indexOf(ele.title) > -1) {
        while (str.indexOf(ele.title) > -1) {
          str = str.replace(ele.title, `<img src="${ele.url}" style="vertical-align: middle;width:24px;height:24px"/>`);
        }
      }
    });
  });
  return str;
}

// 分享至微信qq微博 type： qzone sina qq wechat分享至哪里 ,flag来自哪个页面做区分，info：当前页面作品详情/资讯详情
export interface shareInfoType {
  title?: string, cover_image?: string, desc?: string, author?: string
}
export const share = (type: string, flag: string, info: shareInfoType,shareUrl?:string) => {
  let title = '',
    pic = '',
    summary = '',
    author = '',
    lk = shareUrl || document.location.href
  if (flag === 'workDetial') {
    //来自作品详情页
    title = info?.title! || ''
    pic = shareUrl || info?.cover_image! || ''
    summary = info?.desc! || ''
    author = info?.author! || ''
  } else if (flag === 'article') {
    // 来自资讯详情
    title = info?.title!
  }

  //qq空间分享接口
  if (type === 'qzone') {
    window.open(
      `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(lk)}?sharesource=qzone&title=${title}&pics=${pic}&summary=${summary}`
    )
  }
  //新浪微博接口的传参
  if (type === 'sina') {
    let sina_url, st, su

    st = 'G站ID： ' + title + ' | ' + author

    if (flag === 'article') {
      su = `${lk}?sharesource=weibo`
      sina_url =
        'http://service.weibo.com/share/share.php?url=' +
        su +
        '&title=' +
        title +
        '&pic=' +
        '' +
        '&appkey=2706825840'
    } else {
      su = `${lk}?sharesource=weibo${encodeURIComponent(
        '@GGAC数字艺术平台'
      )}`
      sina_url =
        'http://service.weibo.com/share/share.php?url=' +
        su +
        '&title=' +
        st +
        '&pic=' +
        pic +
        '&appkey=2706825840'
    }
    window.open(sina_url)
  }
  //qq好友接口的传参
  if (type === 'qq') {
    window.open(
      `http://connect.qq.com/widget/shareqq/index.html?url=${lk}?sharesource=qzone&title=${title}&pic=${pic}&summary=${summary}`
    )
  }
  //微信分享
  else {
    window.open(`/work/share?link=${encodeURIComponent(lk)}`)
  }
}

//美化数字
export function prettifyNumber(number: number) {
  if (number >= 1000) {
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const suffixIndex = Math.floor(Math.log10(number) / 3);
    const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
    return shortNumber + suffixes[suffixIndex];
  }
  return number.toString();
}


export function uniqueArrayByDataId<T extends { dataId: number }>(array: T[]): T[] {
  const uniqueValues: { [key: string]: boolean } = {};
  return array.reduce((result: T[], item: T) => {
    const dataId = item.dataId;
    if (!uniqueValues[dataId]) {
      uniqueValues[dataId] = true;
      result.push(item);
    }
    return result;
  }, []);
}

//在tab打开页面，但是不跳转
export function openNewBackgroundTab(url:string){
  var a = document.createElement("a");
  a.href = url;
  var evt = document.createEvent("MouseEvents");
  //the tenth parameter of initMouseEvent sets ctrl key
  evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0,
                              true, false, false, false, 0, null);
  a.dispatchEvent(evt);
}

export function OpenArticle(id:number,type:number){
  let  url = `/article/detail/${id}`
    if (type ===1) {
      window.open(url, '_blank');
    }
    if (type ===2) {
      openNewBackgroundTab(url)
    }
}

export function OpenWork(id:number,type:number){
  let  url = `/work/detail/${id}`
    if (type ===1) {
      window.open(url, '_blank');
    }
    if (type ===2) {
      openNewBackgroundTab(url)
    }
}

export function detectWindowWidth(){
  const width = window.innerWidth;
  //console.log(width);
  if(width<=1920){
    return 'small'
  }
  if(width>1920){
    return 'standard'
  }

  return 'unkown'
}

export function detectWindowWidthFullWidth(){
  const width = window.innerWidth;

  if(width<=1920){
    return 'small'
  }
  if(width>1920){
    return 'standard'
  }

  return 'unkown'
}

export function isWideScreen(){
  const width = window.innerWidth;
  const height = window.innerHeight;
  if((width/height) > 16/9 && width> 1920){
    return true
  }
  return false
}

export function dynamicColumnsFullWidth(){
 
  if(detectWindowWidthFullWidth()==='small'){
    return 5
  }
  if(detectWindowWidthFullWidth()==='standard'){
    return 6
  }
  return 6
}

export function dynamicColumnsFullWidth2(){
 
  if(detectWindowWidthFullWidth()==='small'){
    return 6
  }
  if(detectWindowWidthFullWidth()==='standard'){
    return 8
  }
  return 6
}


export function dynamicColumns(){
 
  if(detectWindowWidth()==='small'){
    return 5
  }
  if(detectWindowWidth()==='standard'){
    return 6
  }
  return 6
}

//复制文字到剪贴板
export function copyToClipboard(text:string){
  // Create a temporary input element
  let tempInput = document.createElement("input");
  
  // Set the input element's value to text
  tempInput.value = text;
  
  // Append the input element to the DOM
  document.body.appendChild(tempInput);
  
  // Select the input element's content
  tempInput.select();
  
  // Copy the selected content to the clipboard
  document.execCommand("copy");
  
  // Remove the temporary input element from the DOM
  document.body.removeChild(tempInput);
}

export const getDateString = (time: number) =>{
  let date = new Date(time*1000);
  let y =  date.getFullYear();
  let m = "0" + (date.getMonth() + 1);
  let d = "0" + date.getDate();
          let h =  "0" + date.getHours();
          let mm =  "0" + date.getMinutes();
          let s =  "0" + date.getSeconds();

  return y + "-" + m.substring(m.length - 2, m.length) + "-" + d.substring(d.length - 2, d.length) +' ' 
          +h.substring(h.length - 2, h.length) + ':' +mm.substring(mm.length - 2, mm.length) + ':' +s.substring(s.length - 2, s.length); 
}

export function setSearchParam(key:string, value:string) {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
}

export function getSearchParam(key:string) {
  const params = new URLSearchParams(window.location.search);
  return params.get(key)
}


export function getFileNameFromURL(url:string) {
  if(!url){
    return
  }
  // Split the URL by slashes to extract the file name
  var segments = url.split('/');
  var fileName = segments[segments.length - 1];

  // Return the file name
  return unicodeEncode(fileName);
}

function unicodeEncode(fileName:string) {
  // Encode the file name to Unicode
  var unicodeFileName = decodeURIComponent(fileName);

  // Return the Unicode encoded file name
  return unicodeFileName;
}


export function truncateText(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}


export function getImageDimensions(url:string, callback:(width:number | null,height:number | null,error:any)=>void) {
  var img = new Image();
  img.src = url;
  img.onload = function() {
    var width = img.width;
    var height = img.height;
    callback( width, height, null);
  };
  img.onerror = function() {
    callback(null, null, new Error("加载图片发现错误！") );
  };
}

export function dayDifference(date:string,){
  let now = new Date();

// Given date string
let givenDateString = date;

// Convert the given date string to a Date object
let givenDate = new Date(givenDateString);

// Calculate the time difference in milliseconds
let timeDifference = givenDate.getTime() - now.getTime();

// Convert the time difference to days
let daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

return daysDifference
}


export function getBeautityTime(date:string) {
  const now = Date.now();
  const endTime = new Date(date);
  const len = (endTime.getTime() - now) / 1000;
  if (len > 365 * 86400) {
    return `${Math.floor(len / (365 * 86400))}年`;
  } else if (len > 86400) {
    return `${Math.floor(len / 86400)}天`;
  } else if (len > 3600) {
    return `${Math.floor(len / 3600)}小时`;
  } else if (len > 60) {
    return `${Math.floor(len / 60)}分钟`;
  } else if (len > 1) {
    return `${Math.floor(len)}秒`;
  }
  return "1秒";
}

function areTimestampsInSameDay(timestamp1:number, timestamp2:number) {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Compare the year, month, and day of the two dates
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function setWorkListParamsForNav (parameters: any,timestamp:number){

  const  params_map_string = localStorage.getItem('workListParamsForNav');

  if(params_map_string){
      let params_map = new Map(JSON.parse(params_map_string));
      const  old_timestamp =params_map.get('timestamp')
      //清除旧的过期数据
      if(old_timestamp && !areTimestampsInSameDay(old_timestamp as number,Date.now())){
        localStorage.removeItem('workListParamsForNav')
        makeANewMap(parameters,timestamp)
      }else{
        params_map.set(timestamp,parameters)
        const serializedMap = JSON.stringify(Array.from(params_map.entries()));
        localStorage.setItem(
          "workListParamsForNav",serializedMap
        )
      }

  }else{
    makeANewMap(parameters,timestamp)
  }
  
}

function makeANewMap(parameters: any,timestamp:number){
  const newMap = new Map();
  newMap.set('timestamp',timestamp);
  newMap.set(timestamp,parameters);
  const serializedMap = JSON.stringify(Array.from(newMap.entries()));
  localStorage.setItem(
    "workListParamsForNav",
    serializedMap
  );
}

export function getWorkTidUrl(id:number,t_id?:number):string{
  let work_url =`/work/detail/${id}`
  if(t_id){
    return work_url+`?t_id=${t_id}`
  }
  return work_url
}

export function extractPathnameAndSearchParams(urlString:string) {
  var url = new URL(urlString);
  var pathnameAndSearchParams = url.pathname + url.search;
  var encodedPathnameAndSearchParams = encodeURIComponent(pathnameAndSearchParams);
  
  return encodedPathnameAndSearchParams;
}


// 判断设备语言
export const isNavLang = () => {
  let navLang = 'zh-hans'
  if (navigator.language.includes('zh')) {
    navLang = 'zh-hans'
  } else {
    navLang = 'en'
  }
  return navLang
}

// 取本地存储
export const getStorage = (key:string) => {
  return localStorage.getItem(key) || ''
}
// 存本地存储
export const setStorage = (key:string, value:any) => {
  localStorage.setItem(key, value)
}
// 移除本地存储
export const removeStorage = (key:string, value:any) => {
  localStorage.removeItem(key)
}