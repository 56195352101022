import styled from "styled-components";

export const MonthSelectionStyle = styled.div`
    max-width: ${(props) => props.theme.space.maxContainer}px;
    padding: 0 ${props => props.theme.space.padding}px;
    background-color: ${(props) => props.theme.colors.colorBgLayout};
    margin: 0 auto;
    .title-wrap {
      position: relative;
      width: 100%;
      margin-top: ${(props) => props.theme.space.padding}px;
      .tip {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        .icon{
          font-size: 16px;
          padding-left: 4px;
        }
        color: ${props => props.theme.colors.colorLinkDefault};
        &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
      }
    }
  .container_ {
    max-width: ${(props) => props.theme.space.maxContainer}px;
    .wrap {
      /* display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      column-gap: ${(props) => props.theme.space.padding}px;
      margin: 0 auto; */
    }
  }
`