

//判断浏览器内核版本
export const isBrowserVersionTooOld=()=>{
    var userAgent = navigator.userAgent;
  var match = userAgent.match(/Chrome\/(\d+)\./);
  var version = match ? parseInt(match[1]) : -1;
  var isChrome = /Chrome/.test(navigator.userAgent) 

  console.log(version,isChrome)

  if(version <= 0){
    return false
  }
  if(version < 88 && isChrome){
    return true;
  }

  return false
}