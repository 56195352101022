import styled from 'styled-components'

export const ImageDescUploadListStyle = styled.div`
.drop-wrap{
    margin-bottom: ${props=> props.theme.space.marginXS};
    /* align-items: center; */
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    justify-items: center;
    /* flex-direction: column; */
    .ant-upload-wrapper{
        width: 154px;
        margin-top: 4px;
        .ant-upload{
            width: 154px;
            height: 154px;
            margin-bottom: 0 !important;
        }
    }

    .anticon-delete{
        svg{
            color: ${props=> props.theme.colors.colorTextLightSolid};
        }
    }
}
.drop{
    margin: ${props=> props.theme.space.marginXXS}px;
    display: flex;
    flex-direction:column;
    align-items: center;
    .ant-upload-list-item {
        position: relative;
        border: 1px solid #d9d9d9;
        padding: ${props=> props.theme.space.paddingXS};
        width: 154px;
        height: 154px;
        border-radius: 8px;
        box-sizing: border-box;
    }
    img{
        width: 100%;
        height: 100%;
    }
    
    .ant-upload-wrapper{
        width: 154px;
    }
    .ant-upload-list-item:hover{
        
        .ant-upload-list-item-actions{
            display: block;
        }
    }
    .ant-upload-list-item-name{
        display: none;
    }
    .ant-upload-list-item-actions{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.4);
        display: none;
        text-align: center;
        line-height: 154px;
    }
    a{
        color: ${props=> props.theme.colors.colorTextLightSolid};
    }
    button{
        color: ${props=> props.theme.colors.colorTextLightSolid};
        &:hover{
            color: ${props=> props.theme.colors.colorTextLightSolid};
        }
    }
}
`


export const ImgPreviewItemStyle = styled.div`
    cursor: pointer;
    background-color: ${props=> props.theme.colors.colorHomeBgContainer};
    border-radius: ${props=> props.theme.border.borderRadius}px;
    overflow: hidden;
    margin-bottom: 4px;
    .image{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
        height: 154px;
        width: 154px;
       
        &:hover{
        .actions{
            display: block;
        }
    }
        .actions{
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color:#fff;
            font-size: 18px;
            .item{
                padding: 4px;
                background-color: rgba(0, 0, 0,.5);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }


`