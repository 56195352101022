import { get, post } from "api"
import { ForumApplicantCreateRequest, ForumApplicantListItem, ForumApplicantListRequest, ForumConsultListItem, ForumConsultListRequest, ForumFavoriteFlagRequest, ForumFavoriteReplyListItem, ForumFavoriteReplyListRequest, ForumFollowFlagRequest, ForumMineApplyListItem, ForumMineApplyListRequest, ForumMineInviteListItem, ForumMineInviteListRequest, ForumMineQuestionListItem, ForumMineQuestionListRequest, ForumMineReplyListItem, ForumMineReplyListRequest, ForumMineStatistics, ForumMineTopicListItem, ForumOrderAttachmentListItem, ForumOrderAttachmentListRequest, ForumOrderCreate, ForumOrderCreateRequest, ForumOrderDeliveryDocuments, ForumOrderDetail, ForumOrderListItem, ForumOrderListRequest, ForumRecommendFlagRequest, ForumReplyCreateRequest, ForumReplyDetail, ForumReplyListItem, ForumReplyListRequest, ForumReplyUpdateRequest, ForumSellerOrderDetail, ForumSellerOrderListItem, ForumSellerOrderListRequest, ForumSellerOrderSubmitRequest, ForumTopic2CreateRequest, ForumTopicCreateRequest, ForumTopicDetail, ForumTopicListItem, ForumTopicListRequest, ForumTopicOperateInviteRequest, ForumTopicPayIssuessDetail, ForumTopicPayIssuessListItem, ForumTopicPayIssuessListRequest, ForumTopicUpdateRequest, GlobalSearchForumTopicItem, GlobalSearchForumTopicRequest } from "./type"
import { PaginationResponse } from "types/common/axios"

export const apiCreateForumTopic = (params: ForumTopicCreateRequest) => {
    return post<ForumTopicCreateRequest, any>("/api/forum_topic/create", params)
}

export const apiUpdateForumTopic = (params: ForumTopicUpdateRequest) => {
    return post<ForumTopicUpdateRequest, any>("/api/forum_topic/update", params)
}


export const apiForumTopicDetail = (params: { id: number, collapseTextCount?: number }) => {
    return get<{ id: number, collapseTextCount?: number }, ForumTopicDetail>(`/api/forum_topic/detail/${params.id}?collapseTextCount=${params.collapseTextCount}`)
}


export const apiDeleteForumTopic = (params: { id: number }) => {
    return post<{ id: number }, any>(`/api/forum_topic/delete/${params.id}`)
}


export const apiFollowForumTopic = (params: { id: number }) => {
    return post<{ id: number }, any>(`/api/forum_topic/delete/${params.id}`)
}


export const apiForumTopicList = (params: ForumTopicListRequest) => {
    return get<ForumTopicListRequest, PaginationResponse<ForumTopicListItem>>(`/api/forum_topic/list`, params)
}


export const apiCreateForumReply = (params: ForumReplyCreateRequest) => {
    return post<ForumReplyCreateRequest, { id: number }>("/api/forum_reply/create", params)
}

export const apiUpdateForumReply = (params: ForumReplyUpdateRequest) => {
    return post<ForumReplyUpdateRequest, { id: number }>("/api/forum_reply/update", params)
}

export const apiDeleteForumReply = (params: { id: number }) => {
    return post<{ id: number }, any>(`/api/forum_reply/delete/${params.id}`)
}

export const apiForumReplyList = (params: ForumReplyListRequest) => {
    return get<ForumReplyListRequest, PaginationResponse<ForumReplyListItem>>(`/api/forum_reply/list`, params)
}

export const apiForumReplyDetail = (params: { id: number, collapseTextCount: number }) => {
    return get<{ id: number, collapseTextCount: number }, ForumReplyDetail>(`/api/forum_reply/detail/${params.id}`, params)
}

export const apiForumFollow = (params: ForumFollowFlagRequest) => {
    return post<ForumFollowFlagRequest, any>(`/api/forum_follow/flag`, params)
}


export const apiMyForumTopicList = (params: ForumReplyListRequest) => {
    return get<ForumReplyListRequest, PaginationResponse<ForumMineTopicListItem>>(`/api/forum_mine/topic_list`, params)
}

export const apiMyForumReplyList = (params: ForumMineReplyListRequest) => {
    return get<ForumMineReplyListRequest, PaginationResponse<ForumMineReplyListItem>>(`/api/forum_mine/reply_list`, params)
}

export const apiForumTopicRecommend = (params: ForumRecommendFlagRequest) => {
    return post<ForumRecommendFlagRequest, any>(`/api/forum_recommend/flag`, params)
}

export const apiForumTop = (params: ForumRecommendFlagRequest) => {
    return post<ForumRecommendFlagRequest, any>(`/api/forum_topic/top_flag`, params)
}

export const apiMyForumFavoriteReplyList = (params: ForumFavoriteReplyListRequest) => {
    return get<ForumFavoriteReplyListRequest, PaginationResponse<ForumFavoriteReplyListItem>>(`/api/forum_favorite/reply_list`, params)
}

export const apiForumFavorite = (params: ForumFavoriteFlagRequest) => {
    return post<ForumFavoriteFlagRequest, any>(`/api/forum_favorite/flag`, params)
}


//全文检索-论坛话题
export const apiForumTopicGlobalSearch = (params: GlobalSearchForumTopicRequest) =>
    get<GlobalSearchForumTopicRequest, PaginationResponse<GlobalSearchForumTopicItem>>("/api/global_search/forum_topic", params)


// 付费列表
export const apiGetForumTopicPayIssuessList = (params: ForumTopicPayIssuessListRequest) =>
    get<ForumTopicPayIssuessListRequest, PaginationResponse<ForumTopicPayIssuessListItem>>("/api/forum_topic/pay_issuess_list", params)

// 应征悬赏
export const postCreateApplicant = (params: ForumApplicantCreateRequest) =>
    post<ForumApplicantCreateRequest, {}>("/api/forum_applicant/create", params)

//应征悬赏详情
export const getApplicantDetail = (id: number) =>
    post<{ id: number }, ForumTopicPayIssuessDetail>(`/api/forum_topic/pay_issuess_detail/${id}`)

// 创建应征悬赏
export const postCreateReward = (params: ForumTopic2CreateRequest) =>
    post<ForumTopic2CreateRequest, {}>(`/api/forum_topic/create`, params)

// 修改应征悬赏
export const postUpdateReward = (params: ForumTopicUpdateRequest) =>
    post<ForumTopicUpdateRequest, {}>(`/api/forum_topic/update`, params)

// 获取我创建的应征悬赏
export const getMyReward = (params: ForumMineQuestionListRequest) =>
    get<ForumMineQuestionListRequest, PaginationResponse<ForumMineQuestionListItem>>(`/api/forum_mine/question_list`, params)

// 获取我应征悬赏可选择的答主
export const getMyRewardAnsererList = (params: ForumApplicantListRequest) =>
    get<ForumApplicantListRequest, PaginationResponse<ForumApplicantListItem>>(`/api/forum_applicant/list/${params.topicId}`, params)

// 获取我应征悬赏可选择的答主
export const postCreateRewardOrder = (params: ForumOrderCreateRequest) =>
    post<ForumOrderCreateRequest, ForumOrderCreate>(`/api/forum_order/create`, params)

// 获取我应征悬赏可选择的答主
export const getRewardOrderDetail = (orderNumber: string) =>
    get<{ orderNumber: string }, ForumOrderDetail>(`/api/forum_order/detail/${orderNumber}`)

// 获取我应征悬赏可选择的答主
export const getRewardFileList = (orderNumber: string) =>
    get<{ orderNumber: string }, ForumOrderDeliveryDocuments>(`/api/forum_order/delivery_documents/${orderNumber}`)

// 获取我收款订单详情
export const getReceiveOrderDetail = (orderNumber: string) =>
    get<{ orderNumber: string }, ForumSellerOrderDetail>(`/api/forum_seller_order/detail/${orderNumber}`)

// 获取我应征悬赏可选择的答主
export const getRewardOrderList = (params: ForumOrderListRequest) =>
    get<ForumOrderListRequest, PaginationResponse<ForumOrderListItem>>(`/api/forum_order/list`, params)

// 获取我的应征列表
export const getRewardApplicantList = (params: ForumMineApplyListRequest) =>
    get<ForumMineApplyListRequest, PaginationResponse<ForumMineApplyListItem>>(`/api/forum_mine/apply_list`, params)
// 获取我的应征列表
export const getRewardInvitationList = (params: ForumMineInviteListRequest) =>
    get<ForumMineInviteListRequest, PaginationResponse<ForumMineInviteListItem>>(`/api/forum_mine/invite_list`, params)

// 接受或者拒绝邀请
export const postEditInvitationState = (params: ForumTopicOperateInviteRequest) =>
    post<ForumTopicOperateInviteRequest, {}>(`/api/forum_topic_operate/invite`, params)

// 获取我的收款订单
export const postGetReceiveOrderList = (params: ForumSellerOrderListRequest) =>
    get<ForumSellerOrderListRequest, PaginationResponse<ForumSellerOrderListItem>>(`/api/forum_seller_order/list`, params)

// 获取答主列表
export const getAnsererList = (params: ForumConsultListRequest) =>
    get<ForumConsultListRequest, PaginationResponse<ForumConsultListItem>>(`/api/forum_consult/list`, params)

export const getRewardDetail = (id: number) =>
    get<{ id: number }, ForumTopicPayIssuessDetail>(`/api/forum_topic/pay_issuess_detail/${id}`)

export const postEditQuestionState = (params: { topicId: number, flag: number }) =>
    post<{ topicId: number, flag: number }, ForumTopicPayIssuessDetail>(`/api/forum_topic_operate/topic_flag`, params)

// 确认收货
export const postRewardConfirmReceipt = (params: { orderNumber: string, flag: number }) =>
    post<{ orderNumber: string, flag: number }, ForumTopicPayIssuessDetail>(`/api/forum_order/confirm`, params)

// 取消订单
export const postCancelOrder = (orderNumber: string) =>
    post<{ orderNumber: string }, ForumTopicPayIssuessDetail>(`/api/forum_order/cancel/${orderNumber}`)

// 发布交付文件
export const postDeliverDocuments = (params: ForumSellerOrderSubmitRequest) =>
    post<ForumSellerOrderSubmitRequest, {}>(`/api/forum_seller_order/submit`, params)

// 选择答主
export const postChooseAnserer = (params: { applyUserId: number, topicId: number }) =>
    post<{ applyUserId: number, topicId: number }, {}>(`/api/forum_topic_operate/choose`, params)

// 数据统计
export const getForumDataList = () =>
    get<{ applyUserId: number, topicId: number }, ForumMineStatistics>(`/api/forum_mine/statistics`)

// 成为答主
export const postToBeAnswerer = (params: { briefIntroduction: string, wage: number }) =>
    post<{ briefIntroduction: string, wage: number }, {}>(`/api/forum_consult/save`, params)

// 查询答主详情
export const getAnswererDetail = () =>
    get<{}, { briefIntroduction: string, wage: number }>(`/api/forum_consult/detail`)

// 查询问题下的回复
export const getAnswererOfQeustion = (params: ForumOrderAttachmentListRequest) =>
    get<ForumOrderAttachmentListRequest, PaginationResponse<ForumOrderAttachmentListItem>>(`/api/forum_order_attachment/list`, params)
