import styled from "styled-components";

export const MyTopicListStyle = styled.div`
  .list {
    .list-item {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.colorBorderSecondary};
      padding: 0 ${(props) => props.theme.space.paddingLG}px;
      min-height: 44px;
      margin-top: 14px;
      display: flex;
      flex-direction:column;
      gap: 8px;
      .title {
        font-size: 18px;
        font-weight: 600;
        color: #000;
      }
      .edit-time {
      cursor: pointer;
      color: ${(props) => props.theme.colors.colorTextTertiary};
      &:hover {
        color: ${(props) => props.theme.colors.colorText};
      }
    }
    .interaction {
    display: flex;
    align-items: center;
    margin: 14px 0;
    .icon-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${(props) => props.theme.colors.colorTextSecondary};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
    }
  }
    }
  }
`;
