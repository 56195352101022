import { Button, Form, InputNumber, Modal, } from "antd"
import { WithdrawlStyle } from "./style"
import { getWalletDetailType } from "types/wallet"
import WithdrawalSubmit from "./submit"
import { useState } from "react"
import { apiGetCaptcha } from "api/common"
import { getCaptchaType } from "types/common"

const Withdrawal = ({ isShow, detail, update, withdrawalSuccess }: { withdrawalSuccess: () => void, isShow: boolean, detail?: getWalletDetailType, update: (value: boolean) => void }) => {

    const [form] = Form.useForm();
    const [isShowSubmit, setIsShowSubmit] = useState(false)
    const [loading, setIsLoading] = useState(false)
    const [captchaDetail, setCaptchaDetail] = useState<getCaptchaType>()

    const allWithdrawal = () => {
        const amount = !!detail?.rmbBalance ? (detail?.rmbBalance!) / 100 : 0
        form.setFieldsValue({
            amount
        })
    }

    //定义InputNumber的参数              
    const NumberProps = {
        min: '0',//最小值
        max: '999999999999999.99',//最大值
        stringMode: true,//字符值模式，开启后支持高精度小数
        step: '0.01',//小数位数
        formatter: (value: any) => {//指定输入框展示值的格式
            const reg1 = `${value}`.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
            return reg1.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            //如果不需要添加千位分隔符可以直接返回return reg1 
        },
    };

    const getDetail = () => {
        apiGetCaptcha().then(res => {
            setCaptchaDetail(res)
            setIsShowSubmit(true)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const updateIsShow = (value: boolean) => {
        setIsShowSubmit(value)
    }

    const submit = () => {
        form.validateFields().then(res => {
            setIsLoading(true)
            getDetail()
            update(false)
        })
    }

    return <WithdrawlStyle>
        <Modal className="withdrawal-modal" onCancel={() => update(false)} getContainer={false} footer={<></>} title="钱包提现" destroyOnClose={true} centered={true} open={isShow}>
            <div className="top">
                <img src="https://cdn.ggac.com/img/wallet-alipay.png" alt="" />
                提现到支付宝</div> 
            <Form form={form}>
                <Form.Item label="提现金额"
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: '请输入提现金额',
                        },
                        () => ({
                            validator(_, value) {
                                if (value > ((detail && detail.rmbBalance)!) / 100)
                                    return Promise.reject(new Error('超出最大提现金额'));
                                else if (value < 0.1)
                                    return Promise.reject(new Error('最低提现金额位0.1'));
                                else
                                    return Promise.resolve();
                            }
                        }),
                    ]}
                >
                    <InputNumber type="number" {...NumberProps} style={{ width: '100%' }} />
                </Form.Item>
            </Form>
            <div className="desc">全部余额￥{(detail?.rmbBalance!) / 100 || 0} 最低提现0.1元 <span onClick={allWithdrawal}>全部提现</span></div>
            <div className="btns">
                <Button type="primary" className="btn" loading={loading} onClick={submit}>确定</Button>
                <Button type="primary" className="btn" ghost onClick={() => update(false)}>取消</Button>
            </div>
        </Modal>
        <WithdrawalSubmit withdrawalSuccess={withdrawalSuccess} updateIsShow={updateIsShow} isShow={isShowSubmit} updateImage={getDetail} detail={captchaDetail} amount={form.getFieldValue('amount')} />
    </WithdrawlStyle>
}

export default Withdrawal