import styled from "styled-components";

export const CertifiedAuthorStyle = styled.div`
  /* background-image: url('https://cdn-prd.ggac.com/ggac/assets/image/user-center/certified_author_bg.svg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  .authent-author {
    padding-bottom: 0.8rem;
    &-top {
      width: 100%;
      height: 600px;
      background: url("https://cdn.ggac.com/img/pc_indent_kv.jpg") center
        center no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 38px;
      img {
        width: 161px;
        height: auto;
      }
      .welcome {
        margin-top: 40px;
        color: #fff;
        font-size: 20px;
        text-align: center;
      }
    }
    &-bottom {
      background: url("~@/assets/images/authenticate/pc_indent_bg.jpg") center
        center no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      // 权益
      .interest {
        /* width: 1200px; */
        /* height: 481px; */
        max-width: 1200px;
        background: #ffffff;
        box-shadow: 0px 2px 14px 2px rgba(43, 46, 54, 0.1);
        border-radius: 20px;
        padding: 60px 130px;
        margin-top: -150px;
        margin-bottom: 140px;
        &-cnt {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 60px;
          gap: 50px;
          justify-content: center;
          max-width: 800px;
          &-item {
            width: 126px;
            text-align: center;
            /* margin: 0 53px; */
            display: flex;
            flex-direction: column;
            align-items: center;
            /* margin-bottom: 50px; */
            .cover {
              width: 74px;
              height: 74px;
              background: linear-gradient(60deg, #f5e0c2, #e8cda5);
              border-radius: 50%;
              margin-bottom: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              .iconfont {
                width:30px;    
              }
            }
            .text {
            
              font-size: 16px;
            }
          }
        }
      }
      // 步骤
      .step {
        margin-bottom: 139px;
        &-cnt {
          /* width: 1200px; */
          min-width: 1000px;
          display: flex;
          justify-content: space-between;
          align-items: stretch;

          .list-item {
            width: 470px;
            height: 340px;
            border-radius: 8px;
            padding: ${(props) => props.theme.space.paddingLG}px;
            box-shadow: 0px 2px 14px 2px rgba(43, 46, 54, 0.1);
            border-radius: 8px;
            background-color: #fff;
            .title {
              width: 46px;
              height: 46px;
              background: #f5e0c2;
              border-radius: 50%;
              line-height: 46px;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 35px;
            }
            .desc {
              height: 110px;
              text-align: center;
              font-size: 18px;
              div {
                line-height: 30px;
              }
            }
            .btn {
              width: 167px;
              height: 50px;
              background: #f5e0c2;
              border-radius: 25px;
              text-align: center;
              line-height: 50px;
              font-size: 20px;
              margin: 0 auto;
              margin-bottom: ${(props) => props.theme.space.margin}px;
              cursor: pointer;
            }
            .finish {
              font-size: 16px;
              line-height: 36px;
              text-align: center;
            }
          }
        }
      }
      // 优势
      .advantage {
        width: 1200px;
        &-table {
          width: 100%;
          p {
            width: 128px;
            text-align: center;
            margin: 0 137px;
          }
          .table-head {
            height: 50px;
            background: #f5e0c2;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .table-cnt {
            box-shadow: 0px 2px 14px 2px rgba(43, 46, 54, 0.1);
            border-radius: 0px 0px 8px 8px;
            .row {
              height: 59px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 1px solid #f4f4f4;
              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .authent-entitle {
      text-align: center;
      margin-bottom: 59px;
      margin-top: 19px;
    }
    .authent-title {
      font-size: 30px;
      position: relative;
      text-align: center;
      width: 220px;
      margin: 0 auto;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transition: transparentY(-50%);
        width: 42px;
        height: 2px;
        right: -30px;
        background-color: #000000;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transition: transparentY(-50%);
        left: -30px;
        width: 42px;
        height: 2px;
        background-color: #000000;
      }
    }
  }
`;
