import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Input, Modal, Pagination, Select, Spin, Tabs } from "antd";
import { KeywordSearchStyle } from "./style";
import { NoData } from "components/no-data";
import InfiniteScroll from "react-infinite-scroll-component";
import { getGlobelImageList } from "api/media";
import { GlobalSearchImageRequest, GlobalSearchImageResponse } from "types/media";
import { SearchOption } from "components/search-option";
import { useLocation } from "react-router";
import SearchPopup from "views/layout/header/search/search-popup";
import SearchList from "views/layout/header/search/search-list";
import { useNavigate } from "react-router";
import { getAuthorItem } from "types/user";
import UserCard from "components/userCard";
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
import { apiGetDictList, postGlobalSearch } from "api/common";
import { GlobalSearchPostResponse, globalSearchRequest } from "types/common";
import { hasToken, queryUrl } from "utils/functions/common";
import { getAuthorList } from "api/user";
import MasonaryImages from "../masonaryImages";
import { debounce } from 'lodash';
import PublicCard from "components/uni-card/public-cards/publicESCard";
import { apiGetPublicProductList } from "api/shop";
import { PublicProductListItem } from "types/product";
import MarketProductCard from "components/product-card/market-product-card";
import LoginModal from "components/login-modal";



const { Search } = Input;

type MenuItem = {
  id: number;
  name: string;
  code: string;
};

export const KeyWordSearchList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filterTagsValue, setFilterTagsValue] = useState("");
  const [workList, setWorkList] = useState<GlobalSearchPostResponse[]>([]);
  const [imgList, setImgList] = useState<GlobalSearchImageResponse[]>([]);
  const [authorList, setAuthorList] = useState<getAuthorItem[]>([]);
  const [loading, setLoading] = useState(false);
  const pageSize = 48;
  const [currentPage, setCurrentPage] = useState(1);
  const [latestCurrentSize, setLatestCurrentSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [currentType, setCurrentType] = useState<string>();
  const [updateValue, setUpdateValue] = useState("")
  const [visible, setVisible] = useState(false);
  const [productList, setProductList] = useState<PublicProductListItem[]>([])
  type AntSelectOption = {
    label: string,
    value: string | number,
  }
  const [mediaCategoryOptions, setMediaCategoryOptions] = useState<AntSelectOption[]>()

  const [isShowLoginModal, setIsShowLoginModal] = useState(false)

  const token = hasToken()

  const navigate = useNavigate();

  const inputRef = useRef() as React.MutableRefObject<any>;

  const name = new URLSearchParams(useLocation().search).get("name");
  const pageNumber = Number(new URLSearchParams(useLocation().search).get("pageNumber") || 1);
  const type = new URLSearchParams(useLocation().search).get("type");
  const categoryId = new URLSearchParams(useLocation().search).get(
    "categoryId"
  );
  const sortField = new URLSearchParams(useLocation().search).get("sortField");
  const timeField = new URLSearchParams(useLocation().search).get("timeField");
  const mediaType = new URLSearchParams(useLocation().search).get("mediaType");
  const [mediaCategory, setMediaCategory] = useState(new URLSearchParams(useLocation().search).get(
    "mediaCategory"
  ))

  useEffect(() => {
    if (type === "work") {
      setCurrentType("work");
    } else if (type === "article") {
      setCurrentType("article");
    } else if (type === "author") {
      setCurrentType("author");
    } else if (type === "img") {
      setCurrentType("img");
    } else if (type === "shop") {
      setCurrentType("shop");
    } else {
      setCurrentType("");
    }
  }, [type]);

  useEffect(() => {
    // 获取媒体分类列表
    apiGetDictList({ type: 'mediaCategory' }).then((res) => {
      const options = res.map(item => { return { value: String(item.code), label: item.name } }
      )
      const options_ = [{ value: "全部创作类型", label: "全部创作类型" }, ...options];
      setMediaCategory("全部创作类型")
      setMediaCategoryOptions(options_)
    });
  }, [])

  const deleteHistory = () => {
    localStorage.removeItem("search-history");
  };

  const reqParams = useMemo(() => {
    return {
      categoryId: categoryId,
      title: name || "",
      pageNumber: type === 'img' ? currentPage : pageNumber,
      pageSize,
      isPublic: 1,
      tagIds: filterTagsValue,
      currentType: type || "work",
      sortField,
      timeField: Number(timeField),
      mediaType: Number(mediaType),
      mediaCategory: Number(mediaCategory)
    };
  }, [
    categoryId,
    currentPage,
    currentType,
    filterTagsValue,
    type,
    name,
    sortField,
    timeField,
    mediaType,
    mediaCategory
  ]);

  // 切换分页
  const changePage = (e: number) => {
    setCurrentPage(e)
    const query = queryUrl({ pageNumber: e })
    navigate(`?${query}`)
  }

  useEffect(() => {
    const scrollableDiv = document.querySelector("#scrollableDiv")
    if (!scrollableDiv) {
      return
    }
    scrollableDiv.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pageNumber])

  useEffect(() => {
    clearList();
  }, [name, categoryId, sortField, timeField, mediaType, mediaCategory]);

  const getListData = useCallback(() => {
    if (loading) return;
    setLoading(true);
    if (reqParams.currentType === "work" || reqParams.currentType === "article") {
      let params: globalSearchRequest = {
        words: reqParams.title,
        pageNumber: reqParams.pageNumber,
        pageSize: reqParams.pageSize,
        dataTable: reqParams.currentType
      };
      if (reqParams.categoryId) {
        if (reqParams.categoryId === '全部分类') {
          params.categoryId = undefined
        } else {
          params.categoryId = Number(reqParams.categoryId.split(',').pop());
        }

      }
      if (reqParams.sortField) {
        params.sortField = reqParams.sortField;
      }
      if (reqParams.timeField) {
        params.timeField = reqParams.timeField;
      }
      if (reqParams.mediaType) {
        params.mediaType = reqParams.mediaType;
      }
      // if (reqParams.mediaCategory) {
      //   params.mediaCategory = reqParams.mediaCategory;
      // }

      if (reqParams.mediaCategory) {
        if (mediaCategory === "全部创作类型") {
          delete params.mediaCategory
        } else {
          params.mediaCategory = Number(mediaCategory);
        }

      }
      setLoading(true)
      postGlobalSearch(params).then(res => {
        // setWorkList((arr) => uniqueArrayByDataId([...arr, ...res.pageData]))
        setWorkList([...res.pageData])
        setTotalSize(res.totalSize)
      }).finally(() => { setLoading(false) })
    } else if (reqParams.currentType === "img") {
      setLoading(true);
      let params: GlobalSearchImageRequest = {
        pageNumber: reqParams.pageNumber,
        pageSize: reqParams.pageSize,
        words: reqParams.title

      };
      if (reqParams.sortField) {
        params.sortField = reqParams.sortField;
      }
      if (reqParams.timeField) {
        params.timeField = reqParams.timeField;
      }
      getGlobelImageList({ ...params })
        .then((res) => {
          setImgList((pre) => [...pre, ...res.pageData]);
          setTotalSize(res.totalSize);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (reqParams.currentType === "author") {
      setLoading(true);
      let params = {
        words: reqParams.title,
        pageNumber: reqParams.pageNumber,
        needTotal: 1,
        pageSize: reqParams.pageSize,
      };
      getAuthorList(params)
        .then((res) => {
          setLoading(false);
          setLatestCurrentSize(res.currentSize)
          setTotalSize(res.totalSize);
          // setAuthorList((pre) => [...pre, ...res.pageData]);
          setAuthorList([...res.pageData])
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (reqParams.currentType === 'shop') {
      apiGetPublicProductList({ pageNumber: reqParams.pageNumber, pageSize, sortField: 'globalRank', name: reqParams.title }).then((res) => {
        setProductList(res.pageData);
        setLoading(false)
        setTotalSize(res.totalSize)
      });
    }
  }, [reqParams]);

  useEffect(() => {
    if (!token) {
      setIsShowLoginModal(true)
      return
    }
    getListData();
  }, [getListData]);

  const menuList = [
    { id: 1, name: "作品", code: "work" },
    { id: 4, name: "作者", code: "author" },
    { id: 2, name: "文章", code: "article" },
    { id: 3, name: "图片", code: "img" },
    { id: 4, name: "商品", code: "shop" },
  ];

  useEffect(() => {
    setSearchValue(reqParams.title || '')
  }, [reqParams.title, reqParams.currentType])

  const onSearch = (value: string) => {
    if (!value) {
      return
    }
    if (value === reqParams.title) {
      return
    }
    saveHistory(value)
    setSearchValue(value);
    closePopup()
    clearList()
    inputRef.current.blur();
    navigate(`/search/keyword?type=${currentType}&name=${value}`);
  };

  const clearList = () => {
    setCurrentPage(1);
    setWorkList([]);
    setImgList([]);
    setAuthorList([]);
    setLatestCurrentSize(0)
  };

  const onClickChooseType = (item: MenuItem) => {
    if (loading || item.code === currentType) return;
    clearList();
    setCurrentType(item.code);
    navigate(`/search/keyword?type=${item.code}&name=${reqParams.title}`);
  };

  const loadMoreData = () => {
    if (workList.length >= totalSize) return;
    setCurrentPage(currentPage + 1);
  };

  //当不需要totalSize时,加载更多数据的判断条件
  // const loadMoreDataWhenNoNeedTotal = () => {
  //   if (latestCurrentSize === 0) return;
  //   setCurrentPage(currentPage + 1);
  // };

  const handleChangeMediaType = (value: string) => {
    const query = queryUrl({ mediaType: value, pageNumber: 1 })
    navigate(`?${query}`)
  }

  const handleChangeMediaCategory = (value: string) => {
    const query = queryUrl({ mediaCategory: value, pageNumber: 1 })
    setMediaCategory(value)
    navigate(`?${query}`)
  }

  const onFocus = () => {
    setVisible(true);
  };
  const onBlur = () => {
    setTimeout(() => {
      setVisible(false);
    })
  };

  const changeValue = (value: string) => {
    setSearchValue(value);
  }

  const debounceSetText = useMemo(() => {
    const update = (value: string) => {
      setUpdateValue(value);
    }
    return debounce(update, 500)

  }, [])

  const onChange = (e: any) => {
    const value = e.target.value;
    if (!!value) {
      debounceSetText(value);
    }

    setSearchValue(value);
  };

  const saveHistory = (value: string) => {
    const history: string = localStorage.getItem("search-history") || "[]";
    let arr: string[] = JSON.parse(history) || [];
    if (value) {
      if (arr.includes(value)) {
        const index = arr.findIndex((e: string) => e === value);
        arr.splice(index, 1);
      }
      arr.unshift(value);
      arr = arr.slice(0, 5);
      localStorage.removeItem("search-history");
      localStorage.setItem("search-history", JSON.stringify(arr));
      navigate(`/search/keyword?name=${value}&type=${currentType}`);
    }
  }


  const toSearch = (value: string) => {
    saveHistory(value)
    clearList();
    navigate(`/search/keyword?name=${value}&type=${currentType}`);
    setVisible(false);
    setSearchValue("");
    inputRef.current.blur();
  };

  const closePopup = () => {
    setVisible(false);
  };

  const saveWorkIdsForNav = () => {
    localStorage.setItem(
      "workIdsForNav",
      JSON.stringify(
        workList.filter((item) => {
          return item.dataTable === "work"
        }).map((item) => {
          return item.dataId;
        })
      )
    );
  }

  return (
    <KeywordSearchStyle onClick={() => saveWorkIdsForNav()}>
      <div className="search-keyword-container" id="scrollableDiv">
        <div className="guanggao-banner-wrapper">
          <GuanggaoBannerCarousel positionCode={11}></GuanggaoBannerCarousel>
        </div>
        <div className="search-wrapper">
          <Search
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            // addonBefore={
            //   <Select style={{ width: '100px' }} value={searchType} onChange={onChangeSearchType}> {searchTypeList.map((item) => <Option key={item.key} value={item.key}>{item.label}</Option>)}</Select>}
            size="large"
            placeholder="请输入关键字搜索"
            allowClear
            onSearch={onSearch}
            style={{ borderRadius: "8px", height: "42px" }}
            value={searchValue}
            disabled={loading}
          />

          {!searchValue && !loading && (
            <SearchPopup
              params={{ visible, close: closePopup, toSearch, deleteHistory }}
            />
          )}
          {searchValue && (
            <SearchList
              changeValue={changeValue}
              visible={visible}
              close={closePopup}
              value={updateValue || name || ''}
            />
          )}
        </div>

        <div className="tabs-wrapper">
          <Tabs
            style={{ width: "100%" }}
            size="large"
            centered
            activeKey={reqParams.currentType}
            defaultActiveKey="work"
            items={menuList.map((item) => {
              return {
                label: (
                  <div
                    onClick={() => onClickChooseType(item)}
                    className="tab-name"
                  >
                    {item.name}
                  </div>
                ),
                key: item.code,
              };
            })}
          ></Tabs>
        </div>

        <div className="filter-wrapper">
          {["work", 'article'].includes(currentType!) && (
            <div className="search-wrap">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchOption type={reqParams.currentType}></SearchOption>
                {
                  currentType === 'work' && <Select
                    style={{ width: 200, marginLeft: '15px' }}
                    onChange={handleChangeMediaCategory}
                    options={mediaCategoryOptions}
                    allowClear={false}
                    placeholder="创作类型"
                    value={mediaCategory}
                  >
                  </Select>
                }
              </div>
              {
                (type === 'work' || !type) && <div>
                  <Select
                    style={{ width: 200, margin: 0 }}
                    onChange={handleChangeMediaType}
                    options={[{ label: "图片", value: "1" }, { label: "视频", value: "2" }, { label: "3D", value: "3" }]}
                    allowClear
                    placeholder="媒体类型"
                    value={mediaType}
                  >
                  </Select>
                </div>
              }
            </div>
          )}
          {/* <div style={{ marginLeft: '30px' }}>
            <TagFilter onChange={onTagsFilterChange}></TagFilter>
          </div> */}
        </div>

        <Spin spinning={loading}>
          {(reqParams.currentType === "work" || reqParams.currentType === "article") && (
            <div className="list-wrapper">
              {workList.length === 0 && !loading && <NoData></NoData>}
              {workList.length > 0 &&
                <div className="list">
                  {
                    workList.map((item, index) => {
                      return <PublicCard key={index} item={item}></PublicCard>
                    })
                  }
                </div>
                // <InfiniteScroll
                //   dataLength={workList.length}
                //   next={loadMoreData}
                //   hasMore={workList.length < totalSize}
                //   loader={
                //     <div
                //       style={{
                //         display: "flex",
                //         width: "100%",
                //         bottom: "0",
                //         justifyContent: "center",
                //         height: "60px",
                //         alignItems: "center",
                //       }}
                //     >
                //       <Spin />
                //     </div>
                //   }
                //   scrollableTarget="scrollableDiv"
                // >
                //   })}
                // </InfiniteScroll>
              }
              {
                workList.length > 0 && < Pagination
                  style={{ textAlign: "center", margin: "12px 0 42px 0" }}
                  hideOnSinglePage={true}
                  current={reqParams.pageNumber}
                  onChange={changePage}
                  pageSize={pageSize}
                  total={totalSize > 9600 ? 9600 : totalSize}
                  showSizeChanger={false}
                  showQuickJumper
                />
              }
            </div>
          )}
          {reqParams.currentType === "author" && (
            <div className="list-wrapper">
              {authorList.length === 0 && !loading && <NoData></NoData>}
              {authorList.length > 0 && (
                // <InfiniteScroll
                //   style={{ display: "flex", flexWrap: "wrap" }}
                //   dataLength={authorList.length}
                //   next={loadMoreDataWhenNoNeedTotal}
                //   hasMore={latestCurrentSize <= pageSize}
                //   loader={
                //     <div
                //       style={{
                //         display: "flex",
                //         width: "100%",
                //         // position: "absolute",
                //         bottom: "0",
                //         justifyContent: "center",
                //         height: "60px",
                //         alignItems: "center",
                //       }}
                //     >
                //       <Spin />
                //     </div>
                //   }
                //   scrollableTarget="scrollableDiv"
                // >

                // </InfiniteScroll>
                <div>
                  <UserCard list={authorList} />
                  {
                    authorList.length > 0 && < Pagination
                      style={{ textAlign: "center", margin: "12px 0 42px 0" }}
                      hideOnSinglePage={true}
                      current={reqParams.pageNumber}
                      onChange={changePage}
                      pageSize={pageSize}
                      total={totalSize > 9600 ? 9600 : totalSize}
                      showSizeChanger={false}
                      showQuickJumper
                    />
                  }
                </div>
              )}
            </div>
          )}
          {reqParams.currentType === "shop" && (
            <div className="list-wrapper ">
              {productList.length === 0 && !loading && <NoData></NoData>}
              {productList.length > 0 && (
                <>
                  <div className="list">
                    {productList &&
                      productList.map((item, index) => {
                        return <MarketProductCard item={item} key={index} />;
                      })}
                  </div>
                  {
                    productList.length > 0 && < Pagination
                      style={{ textAlign: "center", margin: "12px 0 42px 0" }}
                      hideOnSinglePage={true}
                      current={reqParams.pageNumber}
                      onChange={changePage}
                      pageSize={pageSize}
                      total={totalSize > 9600 ? 9600 : totalSize}
                      showSizeChanger={false}
                      showQuickJumper
                    />
                  }
                </>
              )}
            </div>
          )}

        </Spin>

        {reqParams.currentType === "img" && (
          <div className="list-wrapper ">
            {imgList.length === 0 && !loading && <NoData></NoData>}

            {imgList.length > 0 && (
              <InfiniteScroll
                dataLength={imgList.length}
                next={loadMoreData}
                hasMore={imgList.length < totalSize}
                className="infinite-scroll-image"
                loader={
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      // position: "absolute",
                      bottom: "0",
                      justifyContent: "center",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    <Spin />
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <MasonaryImages imgList={imgList}></MasonaryImages>
              </InfiniteScroll>
            )}
          </div>
        )}

        <Modal width={450} footer={<></>} title="" destroyOnClose={true} closable={true} onCancel={() => setIsShowLoginModal(false)} centered={true} open={isShowLoginModal}>
          <LoginModal />
        </Modal>
      </div>
    </KeywordSearchStyle >
  );
};
