import UISegmentedControl from "ui-lib/segmented-control";
import { HonorListStyle } from "./style"
import Badges from "./badge";
import { useEffect, useState } from "react";
import CertificateCard from "components/certificate-card";
import { useParams } from "react-router";
import { apiGetCertificateList } from "api/user/certificate";
import { certificateItem } from "types/user/certificate";
import { NoData } from "components/no-data";
import { Spin } from "antd";

const HonorList = () => {
    const [value, setVuale] = useState('badge')
    const tabOption = [
        {
            label: "徽章",
            value: "badge",
        },
        {
            label: "证书",
            value: "certificate",
        },
    ];
    const [loading, setLoading] = useState(false)

    const [list, setList] = useState<certificateItem[]>([])

    const userId = useParams()?.id

    const changeWorkType = (e: any) => {
        setVuale(e)
    };

    useEffect(() => {
        setLoading(true)
        apiGetCertificateList({ userId: Number(userId) }).then(res => {
            setList(res.pageData)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return <HonorListStyle>
        <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={value}></UISegmentedControl>
        {
            value === 'badge' && <Badges />
        }
        <Spin spinning={loading}>
            {
                value === 'certificate' && list.length === 0 && <NoData />
            }
            {
                value === 'certificate' && <div className="list">
                    {
                        list.map(item => {
                            return <CertificateCard key={item.id} detail={item} />
                        })
                    }
                </div>
            }

        </Spin>
    </HonorListStyle>
}

export default HonorList
