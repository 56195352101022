import styled from "styled-components";

export const NotificationStyle = styled.div`
  position: absolute;
  padding-top: ${(props) => props.theme.space.paddingXS}px;
  top: 50px;
  right: 50%;
  transform: translateX(40%);
  .inner-container {
    min-width: 360px;
    width: max-content;
    overflow: hidden;
    box-shadow: ${(props) => props.theme.shadow.boxShadow};
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    padding: ${(props) => props.theme.space.paddingXS}px
      ${(props) => props.theme.space.paddingSM}px;
    color: ${(props) => props.theme.colors.colorText};
    z-index: 999;
    background-color: ${(props) => props.theme.colors.colorTextLightSolid};
  }

  .header_ {
    color: ${(props) => props.theme.colors.colorText};
    border-bottom: 1px solid ${(props) => props.theme.colors.colorBorder};
    margin: 0 ${(props) => props.theme.space.paddingSM}px;
    font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
    .left {
      color: ${(props) => props.theme.colors.colorTextSecondary};
      padding: ${(props) => props.theme.space.paddingXS}px 0;
      text-align: left;
      &:hover {
        color: ${(props) => props.theme.colors.colorText};
      }
    }
  }
  .empty-message {
    color: ${(props) => props.theme.colors.colorPrimary};
    font-size: ${(props) => props.theme.fontSizes.fontSizeLG * 1.3}px;
    display: flex;
    justify-content: center;
    padding: ${(props) => props.theme.space.paddingLG}px 0;
  }

  .footer_ {
    color: ${(props) => props.theme.colors.colorTextSecondary};
    border-top: 1px solid ${(props) => props.theme.colors.colorBorder};
    padding: ${(props) => props.theme.space.paddingXS}px 0;
    margin: 0 ${(props) => props.theme.space.paddingSM}px;
    text-align: center;
    &:hover {
      color: ${(props) => props.theme.colors.colorText};
    }
  }
  .dropdown-item {
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    margin: ${(props) => props.theme.space.paddingXS}px 0;
    line-height: 20px;
    padding: ${(props) => props.theme.space.paddingXS}px
      ${(props) => props.theme.space.paddingSM}px;
    color: ${(props) => props.theme.colors.colorText};
    font-size: ${(props) => props.theme.fontSizes.fontSize}px;
    display: flex;
    align-items: center;

    .title {
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      padding-right: 8px;
    }
    .description {
      color: ${(props) => props.theme.colors.colorTextSecondary};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 30ch;
      span {
        color: ${(props) => props.theme.colors.colorPrimary};
        padding: 0 ${(props) => props.theme.space.paddingXXS}px;
        cursor: pointer;
        &:first-child {
          padding-left: 0 !important;
          margin-left: 0 !important;
        }
        &:hover {
          color: ${(props) => props.theme.colors.colorPrimaryActive};
        }
      }
      .shop-msg{
            color: ${props => props.theme.colors.colorText} !important;
            a{
                color: ${props => props.theme.colors.colorPrimary};
               &:hover{
                color: ${props => props.theme.colors.colorPrimaryActive};
               }
            }
        }
    }
    .hover-link {
      color: ${(props) => props.theme.colors.colorPrimary};
    }

    .icon {
      color: ${(props) => props.theme.colors.colorTextTertiary};
      font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
    }
    .linkstyle {
      color: #7676e4;
      cursor: pointer;
      font-weight: 480;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
    }
    &:last-child {
      border: none;
    }
  }
`;
