import styled from "styled-components";

export const AddFavoriteStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: rgba(0,0,0,0.5); */
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-popup {
        background-color: rgba(0,0,0,0.5);
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 999;
    }
    .modal{
        background-color:${props=> props.theme.colors.colorBgContainer};
        width: 420px;
        border-radius:${props=> props.theme.border.borderRadiusSM}px ;
        position: relative;
        z-index: 1000;
        .header{
            padding: ${props=> props.theme.space.paddingXS}px;
            display: flex;
            justify-content: center;
            position: relative;
            .close{
                cursor: pointer;
                position: absolute;
                right: ${props=> props.theme.space.paddingXS}px;
                top: ${props=> props.theme.space.paddingSM}px;
            }
           
        }
        .content{
            padding: ${props=> props.theme.space.padding}px;
           
            .list{
                margin: ${props=> props.theme.space.margin*1.5}px 0;
                width: 100%;
                max-height: 300px;
                overflow: auto;
                overscroll-behavior: contain;
                .infinite-scroll-component {
                    padding-bottom: 0;
                }
                .listItem{
                    width: 100%;
                    padding: ${props=> props.theme.space.paddingSM}px;
                    display: flex;
                    justify-content: space-between;
                    box-sizing: border-box;
                    &:hover{
                        background-color: ${props=> props.theme.colors.colorPrimaryBg};
                    }
                    .left_{
                        display: flex;
                        align-items: center;
                        background-color:transparent;
                        .title{
                            padding:0 ${props=> props.theme.space.paddingSM}px ;
                        }
                        .tag{
                            color: ${props=> props.theme.colors.colorPrimary};
                            font-size: ${props=> props.theme.fontSizes.fontSizeSM}px;
                        }
                    }
                    .right_ {
                            display: flex;
                            justify-content: flex-end;
                    }
                    .active {
                        background-color: transparent !important;
                        color: ${props=> props.theme.colors.colorPrimary} !important;
                        border-color: ${props=> props.theme.colors.colorPrimary};
                    }
                }
            }
        }
    }
    .footer{
        display: flex;
        justify-content: center;
        .action{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .icon{
                color:${props=> props.theme.colors.colorPrimary};
                font-size: ${props=> props.theme.fontSizes.fontSizeLG}px;
            }
            .text{
                padding:0 ${props=> props.theme.space.paddingXS}px;
            }
        }
    }
`