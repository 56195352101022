import { RegulationPopupStyle } from "./style"

const Regulation3DTextPopup = ({ close, textDetail }: { close: () => void, textDetail: { name: string, desc: string[], id: number, tip: string, href: string, url: string, } }) => {

    const closePopup = () => {
        close()
    }

    return <RegulationPopupStyle>
        <div className="content">
            <div>
                <div className="popup-title">
                    <img src={textDetail.url} alt="" />
                </div>
                <div className="popup-tip" lang="t_lang">{textDetail.tip}</div>
                {
                    textDetail.desc.map((item, index) => {
                        return <div className="desc-wrap" key={item}>
                            <div>{index + 1}、</div>
                            <div className="popup-desc" lang="t_lang">{item}</div>
                        </div>
                    })
                }
                <div className="btn" onClick={() => window.open(textDetail.href)} lang="t_lang">前往下载使用</div>
            </div>
            <div className="close-wrap">
                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation2024-popup-close.png" onClick={closePopup} className="close" />
            </div>
        </div>
    </RegulationPopupStyle>
}

export default Regulation3DTextPopup