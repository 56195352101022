export const plugins =  [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "preview",
    "help",
    "wordcount",
    "paste"
  ]

  export const toolbarString = "undo redo | blocks fontsize| " +
  "bold italic forecolor |lineheight alignleft aligncenter outdent indent " +
  "alignright alignjustify | bullist numlist | " +
  "removeformat" +
  " | fullscreen | ggacImg | ggacVideo | ggacLog"

