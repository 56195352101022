import { useEffect, useState } from "react"
import { RewardIndexStyle } from "./style"
import { apiGetForumTopicPayIssuessList } from "api/forum"
import { ForumTopicPayIssuessListItem } from "api/forum/type"
import { Checkbox, Empty, Pagination, Spin } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { hasToken, queryUrl } from "utils/functions/common"
import RewardItem from "./reward-item"
import CommonTabs from "ui-lib/tab/common-tab"

const RewardIndex = () => {

    const [list, setList] = useState<ForumTopicPayIssuessListItem[]>([])
    const isPay = new URLSearchParams(useLocation().search).get("isPay") || 0
    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const statusList = new URLSearchParams(useLocation().search).get("statusList") || ""
    const sortField = new URLSearchParams(useLocation().search).get("sortField") || "lastSubmitTime"
    const navigate = useNavigate()
    const [totalSize, setTotalSize] = useState(0)
    const token = hasToken()
    const [loading, setLoading] = useState(false)

    const pageSize = 10

    useEffect(() => {
        if (!pageNumber) {
            return
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [pageNumber])

    useEffect(() => {
        getList()
    }, [pageNumber, isPay, statusList, sortField])

    const getList = () => {
        setLoading(true)
        const params: any = { pageSize, pageNumber: Number(pageNumber), isPay: Number(isPay) }
        if (sortField === 'focus') {
            params.isFollow = 1
        } else {
            params.sortField = sortField
        }

        if (!!statusList) {
            params.statusList = statusList
        }
        apiGetForumTopicPayIssuessList(params).then(res => {
            setList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }

    const changePage = (e: number) => {
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const updateList = (id: number) => {
        const index = list.findIndex(item => item.id === id)
        list[index].isApplied = 1
        setList([...list])
    }

    const [sortList, setSortList] = useState<{ key: string, title: string }[]>([
        {
            key: 'lastSubmitTime',
            title: "最新",
        },
    ])

    useEffect(() => {
        if (!token) {
            return
        }
        const index = sortList.findIndex(item => item.key === 'focus')
        if (index >= 0) {
            return
        } else {
            sortList.unshift({
                key: 'focus',
                title: "关注",
            })
            setSortList([...sortList])
        }

    }, [sortList, token])

    const changeCanReply = (e: any) => {
        navigate(`?${queryUrl({ statusList: !!e ? '1' : '', pageNumber: "" })}`)
    }

    const selectTab = (e: any) => {
        const query = queryUrl({ sortField: e.key })
        navigate(`?${query}`)
    }

    return <RewardIndexStyle>
        <div className="tabs-wrap">
            <CommonTabs
                onClick={(e) => selectTab(e)}
                tabs={sortList}
                defaultKey={sortField}
            ></CommonTabs>

            <div className="tabs-right">
                <Checkbox onChange={(e) => changeCanReply(e.target.checked)} checked={statusList === "1"}>可抢答</Checkbox>
            </div>
        </div>
        <Spin spinning={loading}>
            {
                list.length === 0 && <div style={{ marginTop: "30px" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无悬赏"></Empty>
                </div>
            }
            <div className="list">
                {
                    list.map(item => {
                        return <RewardItem updateList={updateList} key={item.id} detail={item} />
                    })
                }
            </div>
            <Pagination
                style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                hideOnSinglePage={true}
                current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                onChange={changePage}
                pageSize={pageSize}
                total={totalSize}
                showSizeChanger={false}
                size="default"
            />
        </Spin>
    </RewardIndexStyle >
}

export default RewardIndex