import { theme } from 'antd';
const { useToken } = theme;

export const UseSpace = () => {
    const { token } = useToken();
    return {
        margin: token.margin,       //16
        padding: token.padding,    //16
        marginXS: token.marginXS,  //8
        marginLG: token.marginLG,
        paddingXS: token.paddingXS, //8
        paddingSM: token.paddingSM,  //12
        paddingLG:token.paddingLG, //24
        marginXXS:token.marginXXS, //4
        paddingXXS: token.marginXXS, // 4
        mainContainer:1400, //1400
        maxContainer:3280, //1880
        paddingMD:20
    }
}