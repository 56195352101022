import { Popover } from "antd"
import { EmojiPickerStyle } from "./style"
import { RiEmotionLine } from "react-icons/ri"
import { emojiJson } from "mock/emoJson"
import { useState } from "react"

const EmojiPicker = ({ appendEmoji }: { appendEmoji: (item: { url: string, title: string }, index?: number) => void }) => {
    const [activeEm, setActiveEm] = useState(1)
    const [pickerShow, setPickShow] = useState(false)

    const nameSplice = (item: { url: string, title: string }) => {
        let str = item.title.replace("[", "").replace("]", "");
        return str;
    }
    const append = (item: { url: string, title: string }, index: number) => {
        setPickShow(false)
        appendEmoji(item, index);
    }

    const onOpenChange = (value: boolean) => {
        setPickShow(value)
    }

    const EmojiPickerContent = (
        <div className="emoji_warpper">
            {
                emojiJson.map((item, index) => {
                    return <div key={index} style={{ display: item.id === activeEm ? 'block' : 'none' }}>
                        <p className="emoji_title">{item.title}</p>
                        <div className="emoji_warpper_cnt new_scorll_style">
                            <div className="emoji_warpper_item image__lazy">
                                {
                                    item.em_list.map((ele, index) => {
                                        return <div key={index} className="item" onClick={() => append(ele, index)} title={nameSplice(ele)}>
                                            <img src={ele.url} v-lazy="ele.url" alt=""/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                })
            }
            <div className="emoji_warpper_tab">
                {
                    emojiJson.map(item => {
                        return <div key={item.id} title={item.title} onClick={() => setActiveEm(item.id)} className={`${activeEm === item.id ? 'active' : ''}`}>
                            <img src={item.logo} alt="" />
                        </div>
                    })
                }
            </div>
        </div>
    )

    return <EmojiPickerStyle>
        <Popover trigger="click" open={pickerShow} onOpenChange={onOpenChange} overlayClassName="popover" placement="bottom" getPopupContainer={(trigger) => trigger} content={EmojiPickerContent}>
            <div className="emoji">
                <RiEmotionLine size={20} />
            </div>
        </Popover>
    </EmojiPickerStyle>
}

export default EmojiPicker