import styled from "styled-components";

export const UiCalenderStyle = styled.div`
.title{
    text-align: center;
    padding: ${(props) => props.theme.space.padding}px 0 ${(props) => props.theme.space.paddingLG}px 0;
    font-weight:  ${(props) => props.theme.fontSizes.fontWeightHeading4};
}
    .header{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        &-item{
            padding:  ${(props) => props.theme.space.padding}px 0;
            text-align: center;
        }
    }
    
    .cells{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 4px;
        .cell{
            padding:  ${(props) => props.theme.space.padding}px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: ${(props) => props.theme.border.borderRadius}px;
            color:  ${(props) => props.theme.colors.colorTextTertiary};
            &.isCurrent{
                /* background-color: blueviolet; */
                color:  ${(props) => props.theme.colors.colorText};
                font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
            }
            .highlight{
                background-color: ${(props) => props.theme.colors.colorPrimary};
                width: 42px;
                height: 42px;
                text-align: center;
                line-height: 42px;
                border-radius: 50%;
            }
        }
    }
`