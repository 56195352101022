import styled from "styled-components";

export const MenuStyle = styled.div`
  color: ${(props) => props.theme.colors.colorTextLightSolid};
  margin-left: 200px;

  .menuList {
    display: flex;
    padding-left: ${(props) => props.theme.space.padding}px;
    min-width: 900px;
  
  }

  .menuItem {
    cursor: pointer;
    /* letter-spacing: ${(props) => props.theme.space.paddingXXS}px; */
    text-indent:${(props) => props.theme.space.paddingXXS}px;
    padding: 0 ${(props) => props.theme.space.padding}px;
    position: relative;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
    .ant-popover-inner {
        border-radius:  ${props => props.theme.border.borderRadius}px !important;
    }
    &>div{
      display: flex;
      align-items: center;
    }
    &:hover {  
      &:after {
        content: "";
        position: absolute;
        height: 3px;
        width: 80%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
       
        background-color: ${(props) => props.theme.colors.colorPrimary};
      }
    }
    &.active{
      &:after {
        content: "";
        position: absolute;
        height: 3px;
        width: 80%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${(props) => props.theme.colors.colorPrimary};
      }
    }
    line-height: 50px;
    display: flex;
    align-items: center;
  }
  .suffix {
    margin-left: ${(props) => props.theme.space.marginXXS}px;
    display: flex;
    align-items: center;
    animation: regulation-bounce-up 1.5s linear infinite;
  }
  .prefix {
    margin-right: ${(props) => props.theme.space.marginXXS}px;
    display: flex;
    align-items: center;
  }
  .actionIcon{
    display: flex;
    align-items: center;
    padding: 0 ${(props) => props.theme.space.padding}px;
    cursor: pointer;
  }
  @keyframes regulation-bounce-up {
    25% {
      transform: translateY(1px);
    }

    50%,
    100% {
      transform: translateY(0);
    }

    75% {
      transform: translateY(-1px);
    }
  }
`;

export const SecondMenuStyle = styled.div`
  position: absolute;
  top: 50px;
  left: 6px;
  color: ${(props) => props.theme.colors.colorBlack5Ggac};
  box-shadow: ${(props) => props.theme.shadow.boxShadow};
  /* overflow: hidden; */
  z-index: 1999;
  padding-top:${(props) => props.theme.space.paddingXS}px;
  border-radius: ${(props) => props.theme.border.borderRadius}px;
  background-color: transparent !important;
  &:hover {
    overflow: visible;
  }
  .second-menu{
    width: max-content;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    background-color: #fff;
    box-sizing: border-box;
    padding:  ${(props) => props.theme.space.paddingXS}px;
  }
  .item-wrap{
    margin-bottom: ${(props) => props.theme.space.paddingXS}px;

    &:last-child{
      margin-bottom:0;
    }
    .second-item {
    display: flex;
    position: relative;
    background-color: #fff;
    height: 42px !important;
    align-items: center;
    &:first-child {
      border-radius: ${(props) => props.theme.border.borderRadius}px  ${(props) => props.theme.border.borderRadius}px 0 0;
      /* overflow: hidden; */
    }
    &:last-child {
      border: none;
      border-radius:0 0  ${(props) => props.theme.border.borderRadius}px  ${(props) => props.theme.border.borderRadius}px ;
      /* overflow: hidden; */
    
    }
    &:only-child {
      border-radius:${(props) => props.theme.border.borderRadius}px  ${(props) => props.theme.border.borderRadius}px ;
      /* overflow: hidden; */
    }
    &:hover {
      background-color:${(props) => props.theme.colors.colorPrimary};
    }
    &.no-cover {
      width: 100%;
      text-align: center;
    }
    .a {
      height: 42px;
      width: 60px;
      background-size: cover;
      border-radius: ${(props) => props.theme.border.borderRadius}px 0 0  ${(props) => props.theme.border.borderRadius}px;
    }
    .b {
      padding: 0 0 0 ${(props) => props.theme.space.padding}px;
      flex: 1;
      text-align: left;
    }
    .c {
      font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px;
      line-height:  42px;
      padding: 0 0 0 ${(props) => props.theme.space.paddingXS}px;
      transform: translateY(3px);
    }
  }
  }
 
`;

export const ThirdMenuStyle = styled.div<{ offWidth: number }>`
  position: absolute;
  top: -10px;
  right: -18px;
  z-index: 1999;
  transform: translateX(${(props: any) => props.offWidth - 20}px);
  padding-left: 15px;

  .third-menu {
    box-shadow: ${(props) => props.theme.shadow.boxShadow};
    color: ${(props) => props.theme.colors.colorBlack5Ggac};
    padding: ${(props) => props.theme.space.paddingXXS}px  ${(props) => props.theme.space.paddingXS}px;
    background-color: #fff;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    width: max-content;
    max-height: 300px;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 6px; /* 设置滚动条的宽度 */
        background-color: transparent; /* 滚动条的背景色 */
    }
    ::-webkit-scrollbar-track {
      background: #e1e1e1; /* 轨道的背景色 */
      border-radius: 10px; /* 轨道的圆角 */
    }
    /* 自定义滚动条的滑块（thumb） */
    ::-webkit-scrollbar-thumb {
      background-color: #c1c1c1; /* 滑块的背景色 */
      border-radius: 10px; /* 滑块的圆角 */
    }
    &:hover {
        &::before {
          background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
        }
      }
  }

  .third-item {
    display: flex;
    margin: 5px auto;
    position: relative;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    height: 42px !important;
    line-height: 42px;
    width: initial;
    padding:  0 ${(props) => props.theme.space.paddingXS}px;
    text-align: center;
    display: flex;
    justify-content: center;
    
    &:first-child {
    }
    &:last-child {
    }
    &:only-child {
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.colorPrimary};
    }
    &.no-cover {
      /* width: 100%; */
      text-align: center;
    }

  }
`;
