import styled from 'styled-components'

export const ShopTopLineStyle = styled.div`


display: flex;
justify-content: space-between;
position: relative;
.node {
  text-align: center;
  margin-bottom: 20px;
  span {
      color: rgba(0, 0, 0, 0.45);
  }
  .tip {
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
  }
}

.share {
  position:absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  a {
    width: 100px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.theme.fontSizes.fontSize}px;
  }
}
`

export const ShopRigthNodeStyle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  .tip {
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
  }
  .ant-upload-list {
    justify-content: center;
  }

  .ant-upload-list-item-container{
    margin: 0 !important;
  }
`