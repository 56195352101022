import { useEffect, useState } from "react"
import { SingleSelectStyle } from "./style"
import {  SignupFieldOptions } from "types/sub/application"

export const SingleSelect = (props: {value?: number[], onChange?: (val: number[]) => void, multiple?: boolean, data?: SignupFieldOptions[]}) =>{
    const [choosedList, setChoosedList] = useState<number[]>([])

    useEffect(() =>{
        setChoosedList(props.value! ?? [])
    }, [props.value])


    const  onClickItem = (item: SignupFieldOptions) => {
        if(props.multiple) {
            let index = choosedList.indexOf(item.id)
            if(index>=0) {
                choosedList.splice(index,1)
                props.onChange!(choosedList)
                let arr = choosedList.concat([])
                setChoosedList(arr)
            } else {
                let arr = choosedList.concat([item.id])
                props.onChange!(arr)
                setChoosedList(arr)
            }
        } else {
            if(choosedList.includes(item.id)) {
                setChoosedList([])
                props.onChange!([])
            } else {
                props.onChange!([item.id])
            }
           
        }
    }

    return  <SingleSelectStyle>
                <div className="theme-select-container">
                    {props.data?.map((item,index) => 
                        <div  className={ choosedList?.indexOf(item.id)>=0 ? 'theme-item-wrapper active' : 'theme-item-wrapper'} 
                            onClick={() => onClickItem(item)} key={item.id}>
                                {item.name}
                        </div>)}
                </div>
            </SingleSelectStyle>
}