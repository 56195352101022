import styled from "styled-components";

export const ShopAgreementStyle = styled.div`
    max-height: 400px;
    overflow-y: auto;
    padding: 0 20px;
    .title {
        font-weight: bold;
    }
    .content {
        div {
            line-height: 30px;
        }
        &>div {
            &>div {
                padding-left: 20px;
            }
        }
    }
    
`