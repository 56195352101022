import { PageHeaderStyle } from "./style";
import { Typography } from "antd";
import { Breadcrumb } from "antd";
import { RiArrowLeftLine
} from "react-icons/ri";

const { Title } = Typography;
type Props ={
  breadcrumbs: {title:string}[],
  link?:()=>void
}
function PageHeader({breadcrumbs,link}:Props) {
  // 返回上一页
  const back = () => {
    
    link && link();
    (link && link()) || window.history.back();
  };
  return (
    <PageHeaderStyle>
      <Breadcrumb
        items={breadcrumbs}
      />

      <Title level={5} onClick={() => back()} className="back">
      <RiArrowLeftLine ></RiArrowLeftLine>  <span>返回</span> 
      </Title>
     
      
    </PageHeaderStyle>
  );
}

export default PageHeader;
