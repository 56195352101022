import { Navigate, RouteObject } from 'react-router-dom'
import Layout from 'views/layout'
import UserCenterLayout from 'views/user-center/layout'
import UserDetail from 'views/user-center/setting/user-detail'
import UserOrderList from 'views/user-center/user-order'
import UserAddress from 'views/user-center/setting/address'
import UserAddressContent from 'views/user-center/setting/address/content'
import UserAccount from 'views/user-center/setting/account'
import { FavoriteList } from 'views/user-center/favorit'
import UserCenterHome from 'views/user-center/home'
import FavoriteDetail from 'views/user-center/favorit/detail'
import NotificationList from 'views/user-center/notification'
import HistoryList from 'views/user-center/history'
import Redeem from 'views/user-center/redeem'
import MyBadges from 'views/user-center/badges'
import UserFansList from 'views/user-center/myFans'
import UserFolloweeList from 'views/user-center/myFollowee'
import UserCertificates from 'views/user-center/certificates'
import OfficialDetail from 'views/user-center/notification/detail'
import WorkList from 'views/user-creator-center/work'
import DraftWorkList from 'views/user-creator-center/work/draftWork'
import ArticleList from 'views/user-creator-center/article'
import DraftArticleList from 'views/user-creator-center/article/draftWork'
import AlbumList from 'views/user-creator-center/album'
import AlbumDetail from 'views/user-creator-center/album/detail'
import OffShelf from 'views/user-creator-center/work/offShelf'
import OffShelfArticle from 'views/user-creator-center/article/offShelf'
import UserContestWorks from 'views/user-frontpage/works/contest-works'
import MyLikes from 'views/user-center/likes'
import CertifiedAuthor from 'views/user-creator-center/certified-author'
import SignedAuthor from 'views/user-creator-center/signed-author'
import SignedAuthorMaterial from 'views/user-creator-center/signed-author'
import CertifiedAuthorMaterial from 'views/user-creator-center/certified-author/material'
import WallectInfo from 'views/user-center/wallet'
import MyOrderList from 'views/user-center/order'
import UserAuthentication from 'views/user-center/authentication'
import AuthenticationIdCard from 'views/user-center/authentication/idCard'
import AuthenticationStudent from 'views/user-center/authentication/student'
import NftAlbumList from 'views/user-center/nft-album/list'
import Quests from 'views/user-center/home/quests'
import WallectDetail from 'views/user-center/wallet/walletDetail'
import CouponList from 'views/user-center/wallet/couponList'
import NftPublish from 'views/user-center/nft-seller/publish'
import EditSellerNftAlbum from 'views/user-center/nft-seller/modify/edit'
import NftSold from 'views/user-center/nft-seller/sold/sold'
import TjDetail from 'views/user-center/nft-seller/order'

export const UserCenterRoutes: RouteObject[] = [
  {
    path: '/user-center',
    element: <Navigate to="/user-center/home/work/list"></Navigate>,
  },
  {
    path: '/user-center/home',
    element: <Navigate to="/user-center/home/work/list"></Navigate>,
  },
  {
    path: '/user-center/authentication',
    element: <Navigate to="/user-center/authentication/idCard"></Navigate>,
  },
  {
    path: '/user-center/wallet',
    element: <Navigate to="/user-center/wallet/index"></Navigate>,
  },

  {
    path: '/',
    element: <Layout></Layout>,
    children: [
      {
        path: 'user-center',
        element: <UserCenterLayout></UserCenterLayout>,
        children: [
          {
            path: 'home',
            element: <UserCenterHome></UserCenterHome>,
            children: [
              {
                path: 'work/list',
                element: <WorkList></WorkList>,
              },
              {
                path: 'contest-work/list',
                element: <UserContestWorks></UserContestWorks>,
              },
              {
                path: 'work/offShelf/list',
                element: <OffShelf></OffShelf>,
              },
              {
                path: 'work/draft/list',
                element: <DraftWorkList></DraftWorkList>,
              },
              {
                path: 'article/list',
                element: <ArticleList></ArticleList>,
              },
              {
                path: 'article/draft/list',
                element: <DraftArticleList></DraftArticleList>,
              },
              {
                path: 'article/offShelf/list',
                element: <OffShelfArticle></OffShelfArticle>,
              },
              {
                path: 'work-album',
                element: <AlbumList></AlbumList>,
              },
              {
                path: 'work-album/detail/:id',
                element: <AlbumDetail></AlbumDetail>,
              },
              {
                path: 'favorites',
                element: <FavoriteList></FavoriteList>,
              },
              {
                path: 'likes',
                element: <MyLikes></MyLikes>,
              },
              {
                path: 'favorites/detail/:id',
                element: <FavoriteDetail></FavoriteDetail>,
              },
              {
                path: 'history',
                element: <HistoryList></HistoryList>,
              },
            ],
          },
          {
            path: 'notification',
            element: <NotificationList></NotificationList>,
          },

          {
            path: 'user-detail',
            element: <UserDetail></UserDetail>,
          },
          {
            path: 'quests',
            element: <Quests></Quests>,
          },
          {
            path: 'address',
            element: <UserAddress></UserAddress>,
          },
          {
            path: 'authentication',
            element: <UserAuthentication />,
            children: [
              {
                path: 'idCard',
                element: <AuthenticationIdCard />,
              },
              {
                path: 'student',
                element: <AuthenticationStudent />,
              },
            ],
          },
          {
            path: 'address/create',
            element: <UserAddressContent></UserAddressContent>,
          },
          {
            path: 'address/edit/:id',
            element: <UserAddressContent></UserAddressContent>,
          },
          {
            path: 'account',
            element: <UserAccount></UserAccount>,
          },
          {
            path: 'user-order',
            element: <UserOrderList></UserOrderList>,
          },
          {
            path: 'order',
            element: <MyOrderList></MyOrderList>,
          },
          {
            path: 'wallet',
            element: <WallectInfo></WallectInfo>,
            children: [
              {
                path: 'index',
                element: <WallectDetail />,
              },
              {
                path: 'coupon',
                element: <CouponList />,
              },
            ],
          },
          {
            path: 'favorites',
            element: <FavoriteList></FavoriteList>,
          },
          // {
          //   path: "favorites/detail/:id",
          //   element: <FavoriteDetail></FavoriteDetail>,
          // },
          {
            path: 'history',
            element: <HistoryList />,
          },
          {
            path: 'redeem',
            element: <Redeem />,
          },
          {
            path: 'badges',
            element: <MyBadges />,
          },
          {
            path: 'fans',
            element: <UserFansList />,
          },
          {
            path: 'followee',
            element: <UserFolloweeList />,
          },
          {
            path: 'certificates',
            element: <UserCertificates />,
          },
          {
            path: 'certified-author',
            element: <CertifiedAuthor />,
          },
          {
            path: 'signed-author',
            element: <SignedAuthor />,
          },
          {
            path: 'signed-author/material',
            element: <SignedAuthorMaterial />,
          },
          {
            path: 'certified-author/material',
            element: <CertifiedAuthorMaterial />,
          },
          {
            path: 'nft-album',
            element: <NftAlbumList />,
          },
          {
            path: 'tj-publish',
            element: <NftPublish />,
          },
          {
            path: 'tj-edit',
            element: <EditSellerNftAlbum />,
          },
          {
            path: 'tj-sold',
            element: <NftSold />,
          },
          {
            path: 'tj-detail/:orderNumber',
            element: <TjDetail />,
          },
        ],
      },
      {
        path: 'official/:id',
        element: <OfficialDetail></OfficialDetail>,
      },
    ],
  },
]
