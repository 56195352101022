import { Avatar,  Divider, FloatButton,  Spin, Tooltip} from "antd"
import { getArticleDraftDetail } from "api/article"
import {  useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { ArticleDraftDetailResponse } from "types/article";
import { ArticleDetailStyle } from './style'
import { RiArrowDropRightLine,  RiFireLine } from "react-icons/ri";

import { workUserLikeItem } from "types/common/favour";
import { ArticleWorkCard } from "../components/article-card";
import { CommentListItem } from "types/comment";

import { ReportModal } from "components/report";
import { ArticleDetailCardInfo } from "../type";


export const ArticlePreview = () =>{
    const { id } = useParams();

    const [detail, setDetail] = useState<ArticleDraftDetailResponse>()

    const [favourList, setFavourList] = useState<workUserLikeItem[]>()

    const [loading, setLoading] = useState(false)


    const [showReport, setShowReport] = useState(false)

    const commentRef = useRef() as React.MutableRefObject<any>;

    const navigate = useNavigate()
    // 调用commet创建方法
    const createComment = (val: CommentListItem) => {
      commentRef.current?.addComment(val)
    }

    const  getDetail = () =>{
        setLoading(true)
        getArticleDraftDetail(+id!).then(res=>{
            setDetail(res!)
        }).finally(()=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        getDetail()
    },[])

    

 




    // const onClickFavour = (flag: number) =>{
    //     setLoading(true)
    //     let params = {
    //         dataId: +id!,
    //         dataTable:"article",
    //         flag:flag
    //     }

    //     postLikeWork(params).then(res=>{
    //         let obj = JSON.parse(JSON.stringify(detail))
    //         flag ==1 ?obj.likeCount++:obj.likeCount--
    //         obj!.isLike = flag
    //         setDetail(obj)
    //         message.success('操作成功!')
    //     }).finally(()=>{
    //         setLoading(false)
    //     })
    // }

    // const  onClickCollect = () =>{
    //     setShowFavorite(true)
    // }

    // const  onClickCloseCollect = () =>{
    //     setShowFavorite(false)
    //     getDetail()
    // }

    // const updateCommentCount = () => {
    //     getArticleDetail(Number(id)).then(res => {
    //       setCommentCount(res.commentCount)
    //     })
    //   }

    // const onClickComment = () =>{
    //     if(!comment) {
    //         message.warning('请输入内容')
    //         return 
    //     }
    //     setLoading(true)
    //     postCreateComment({
    //     content: comment,
    //     dataId: Number(id),
    //     dataTable: 'article'
    //     }).then((res) => {
    //     message.success('创建成功')
    //    createComment(res)
    //     }).finally(() => {
    //         setComment('')
    //         setLoading(false)
    //     })
    // }

    // const  onChangeComment = (event: any ) =>{
    //    setComment(event.target.value)
    // }

    // const onClickReport = () => {
    //     setShowReport(true)
    // }

    const onCloseReport = () =>{
        setShowReport(false)
    }

    const onClickGoUserCenter = () =>{
        navigate('')
    }

    const onClickGoWorkDetail = (item: ArticleDetailCardInfo) =>{
        window.open(`/work/detail/${item.id}`)
    }

    return <ArticleDetailStyle><div className="container">
        <Spin spinning={loading}>
            <div className="header-wrapper">
                <div className="header-left">
                    <div className="header-item title-item">{detail?.title}</div>
                    <div className="header-item time-item">更新于 : {detail?.updateTime}
                        <Tooltip title={'创建于 : '+detail?.createTime}>
                            <RiArrowDropRightLine style={{marginLeft: '10px'}} size={22}></RiArrowDropRightLine>
                        </Tooltip>
                    </div>
                    <div className="header-item category-item">  {detail?.categoryList[0].name}</div>
                    <div className="header-item interaction-item">
                      
                        <div className="icon">
                            <RiFireLine size={"18px"}  /> <span  className="data">232321</span>
                        </div>
                    </div>
                </div>
                <Divider type="vertical" style={{height: 'auto'}} />
                <div className="header-right">
                    <div className="userinfo-left">
                        <div className="avatar-item">
                        <Avatar size={56} src={detail?.userInfo.avatarUrl} onClick={onClickGoUserCenter} />
                        </div>
                    </div>

                    <div className="userinfo-right">
                        <div className="username-item userinfo-item">{detail?.userInfo.username}</div>
                        <div className="intro-item userinfo-item"></div>
                        <div className="btn-item userinfo-item">

                            {/* <div className="msg-btn btn">私信</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Divider></Divider>
            <div className="tags-wrapper">
                <div className="tags-title">标签: </div>
                    {detail?.tagList.map(item=> {return <div key={item.id} className="tags-item">
                        {item.name}
                    </div>})}
            </div>

            <div className="content-wrapper">
                <div dangerouslySetInnerHTML={{__html: detail?.content!}}></div>
            </div>
        
            <div className="interaction-wrapper">
                {/* <div className="operation-wrapper">
                    {showFavorite && <AddFavorite type='article' FavoriteTypeDisabled={true} onClose={onClickCloseCollect}></AddFavorite>}
                    <div className="interaction-item">
                        <RiShareLine  className="icon" ></RiShareLine>
                        <div>分享</div>
                    </div>

                    <div className="alarm-item" onClick={onClickReport}>
                        <RiAlarmWarningLine size={18}></RiAlarmWarningLine>
                        <div>举报</div>
                    </div>
                </div> */}
                {favourList&&favourList.length>0&&<div className="favour-list-wrapper">
                    <div className="title">- {favourList?.length}位G友点赞 -</div>
                    <div className="avatar-list">
                        {favourList?.map(item=> {
                            return <div key={item.id}>
                                <Avatar src={item.avatarUrl}></Avatar>
                            </div>
                        })}
                    </div>
                </div>
                }
            </div>
            <Divider></Divider>
            <div className="works-wrapper">
                    {detail?.workList.map((item)=><div onClick={() =>onClickGoWorkDetail(item)} key={item.id} className="card-item">
                        <ArticleWorkCard  info={item}></ArticleWorkCard>
                        </div>
                        )}
            </div>

            {/* <div className="comment-wrapper">
                 <div className="input-wrapper">
                    <TextArea
                        allowClear
                        value={comment}
                        onChange={onChangeComment}
                        
                        placeholder="请输入你的评论"
                        onPressEnter={onClickComment}
                        />
                    <Button className="confirm-btn" type="primary" onClick={onClickComment}>确认</Button>
                </div>
                <Comments ref={commentRef} params={{ commentCount, updateCommentCount, type: 'article' }}></Comments>
               
                
            </div> */}
            <div style={{height: '200px'}}></div>

            <ReportModal
                show={showReport}
                close={onCloseReport}
                params={{ dataId: Number(detail?.id), dataTable: "article" }}
            ></ReportModal>
            <div className="float-wrapper">
                    <FloatButton.Group shape="circle" style={{ right: 24 }}>
                        <FloatButton />
                        <FloatButton.BackTop visibilityHeight={100} />
                        <FloatButton.BackTop visibilityHeight={100} />
                </FloatButton.Group>
            </div>
            </Spin>
        </div>
    </ArticleDetailStyle>
}