import styled from "styled-components";

export const MaterialContentStyle = styled.div`
    .auth-writer-container{
        .back {
            cursor: pointer;
        }
        .form-wrapper{
            padding:  ${props => props.theme.space.paddingLG}px  ${props => props.theme.space.padding}px;;
            .parter{
                display: flex;

                .left{
           
                    width: 500px;
                }
                .right{
                    padding-left: ${props => props.theme.space.paddingLG*2}px;
                    width: 540px;
                }
            }
            .btn{
                margin-top: ${props => props.theme.space.paddingLG}px;
                width: 100%;
            }
        }
    }
`
