import { useEffect, useState } from "react"
import { EventContributionStyle } from "./style"
import { eventCheckinListResponse } from "types/events"
import { getEventCheckinDetail, getEventCheckinList, getEventCheckinWorkList } from "api/events"
import { useLocation, useNavigate } from "react-router";
import ContributionDetail from "./detail";
import ContributionWork from "./work";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { Button } from "antd";
import { isMobile, queryUrl } from "utils/functions/common";

import Swiper from 'swiper';

// Import Swiper styles
import 'swiper/css';

const EventContribution = () => {
    const [list, setList] = useState<eventCheckinListResponse[]>([])
    const showDetail = Number(new URLSearchParams(useLocation().search).get("show-detail") || 0)
    const isCheck = Number(new URLSearchParams(useLocation().search).get("isCheckinSelected") || 0)
    const [isCheckinSelected, setIsCheckinSelected] = useState(0)
    const [detail, setDetail] = useState<eventCheckinListResponse>()
    const navigate = useNavigate()
    const [dateList, setDateList] = useState<string[]>([])
    const [swiper, setSwiper] = useState<any>()
    const [id, setId] = useState(Number(new URLSearchParams(useLocation().search).get("id")))

    const initSwiper = (lists: eventCheckinListResponse[]) => {
        // let swiper_num = Math.floor(document.getElementById("swiper_container")!.clientWidth / 230)
        const activeIndex = lists.findIndex(item => item.id === Number(id))
        const swiper = new Swiper("#swiper_container", {
            slidesPerView: 6,
            spaceBetween: 20,
            centeredSlides: true,
            loop: true,
            initialSlide: activeIndex,
            slideToClickedSlide: true,
            on: {
                click: () => {
                    setId(lists[swiper.realIndex].id)
                    const query = queryUrl({ id: lists[swiper.realIndex].id, isCheckinSelected: lists[swiper.realIndex].isSelected === 1 ? 1 : 0 })
                    navigate(`?${query}`)
                },
            },
        });
        setSwiper(swiper)
    }

    useEffect(() => {
        setIsCheckinSelected(isCheck)
    }, [new URLSearchParams(useLocation().search).get("isCheckinSelected")])

    const getDetail = (id: number) => {
        getEventCheckinDetail(id).then(res => {
            setDetail(res)
            getDateList(res)
            if (res.isSelected === 1) {
                // window.location.href = `/events/contribution?isCheckinSelected=1&id=${id}`
                setIsCheckinSelected(1)
                window.history.pushState({}, "", `/events/contribution?isCheckinSelected=1&id=${id}`);
            }
        })
    }

    const getDateList = (detail: eventCheckinListResponse) => {
        if (showDetail !== 1) {
            return
        }
        getEventCheckinWorkList({ startTime: detail?.startTime, endTime: detail?.endTime }).then(res => {
            setDateList(res.checkinDates)
        })
    }

    useEffect(() => {
        getEventCheckinList({ pageSize: 100 }).then(res => {
            setList(res.pageData)
            if (!id) {
                setId(res.pageData[0]?.id)
            }
            setTimeout(() => {
                initSwiper(res.pageData)
            }, 500)
        })
        const checkIsMobile = isMobile()
        if (checkIsMobile) {
            window.location.href = `https://m.ggac.com` + window.location.pathname + window.location.search
            return
        }
    }, [])


    const selectTab = (value: string) => {
        if (value === 'show-detail') {
            navigate(`/events/contribution?show-detail=1&id=${id}`)
        } else {
            navigate(`/events/contribution?isCheckinSelected=${value}&id=${id}`)
        }
    }

    const move = (type: string) => {
        if (type === "prev") {
            swiper.slidePrev();
        }
        if (type === "next") {
            swiper.slideNext()
        }
    };

    useEffect(() => {
        if (!id) return
        getDetail(Number(id))
    }, [id])

    const upload = () => {
        window.open(`/work/create?isShowPrivate=0`)
    }

    return <EventContributionStyle>
        <div className="wrap">
            <div className="top">
                {
                    list.length > 0 && <div
                        className="swiper-container swiper-container-horizontal banner-list"
                        id="swiper_container"
                    >
                        <div className="swiper-wrapper">
                            {list.map((item) => {
                                return <div className={`swiper-slide banner-item ${Number(id) === item.id ? 'active' : ''}`} key={item.id}>
                                    <div className="banner" style={{ backgroundImage: `url(${item.smallImage})` }}>
                                    </div>
                                    <div className="swiperModel"></div>
                                </div>
                            })}
                        </div>
                    </div>
                }
                <div className="control prev" onClick={() => move("prev")}>
                    <RiArrowLeftSLine></RiArrowLeftSLine>
                </div>
                <div className="control next" onClick={() => move("next")}>
                    <RiArrowRightSLine></RiArrowRightSLine>
                </div>
            </div>
            {detail && <div className="contribution-info">
                <div className="card-image" style={{ backgroundImage: `url(${detail?.middleImage})` }} />
                <div className="right">
                    <div className="title">{detail?.name}</div>
                    <div className="date">征稿时间：
                        {detail?.startTime.substring(0, 10)}
                        -
                        {detail?.endTime.substring(0, 10)}
                    </div>
                    <div className="content">{detail?.description}</div>
                    <Button type='primary' className="btn" onClick={upload}>上传作品</Button>
                </div>
            </div>}

            <div className="tab-wrap">
                <div className="tab">
                    <div className={`tab-item ${showDetail === 1 ? 'active' : ''}`} onClick={() => selectTab('show-detail')}>详细信息</div>
                    <div className={`tab-item ${isCheckinSelected === 1 && showDetail !== 1 ? 'active' : ''}`} onClick={() => selectTab('1')}>本月精选</div>
                    <div className={`tab-item ${isCheckinSelected === 0 && showDetail !== 1 ? 'active' : ''}`} onClick={() => selectTab('0')}>征稿作品</div>
                </div>
            </div>
            {
                Number(showDetail) === 1 && <ContributionDetail dateList={dateList} detail={detail!} />
            }
            {
                Number(showDetail) !== 1 && <ContributionWork detail={detail!} />
            }
        </div>
    </EventContributionStyle>
}

export default EventContribution