import styled from "styled-components";


export const SectionTitleStyle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size:  ${props=> props.theme.fontSizes.fontSizeLG}px;
    background-color: ${props=> props.theme.colors.colorBgLayout};
    margin-top: 50px;
    margin-bottom: 30px;
`