import styled from "styled-components";
import { dynamicColumns } from "utils/functions/common";

export const ContributionWorkStyle = styled.div`
    .list{
        display: grid;
        --auto-grid-min-size: 18rem;
        margin-bottom: ${props => props.theme.space.margin}px;
        grid-template-columns: repeat(${dynamicColumns()},  minmax(var(--auto-grid-min-size), 1fr));
        gap: 1rem; 
    }
`