import { Button, DatePicker, Form, UploadFile, message } from "antd"
import { AuthenticationStudentStyle } from "./style"
import { useEffect, useState } from "react"
import { studentIdentificationDetailResponse } from "types/user"
import { getStudentIdentification, postStudentIdentification } from "api/user"
import { ProductPicturesWall } from "views/user-shop/productManage/uploadImageList"
import { CosDirectoryType } from "types/enums/media-type"
import dayjs from 'dayjs'

type submitParams = {
    cardUrls: imageType[],
    endYear: number
    startYear: number,
    dateArr: any[]
}

type imageType = {
    url: string
    key: string
    percent: number,
}

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};


const AuthenticationStudent = () => {
    const [form] = Form.useForm<submitParams>();
    let userId = JSON.parse(localStorage.getItem('user_info')!).id
    const [detail, setDetail] = useState<studentIdentificationDetailResponse>()
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false)
    const [canEdit, setCanEdit] = useState(false)

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        getStudentIdentification(Number(userId)).then(res => {
            setDetail(res)

            if (res) {
                const arr: UploadFile[] = []
                res.cardUrls?.forEach((item, index) => {
                    arr.push({
                        uid: String(index),
                        url: item,
                        name: item
                    })
                })
                form.setFieldsValue({
                    cardUrls: arr,
                    endYear: res.endYear,
                    startYear: res.startYear,
                    dateArr: [dayjs(`${res.startYear}-01-01`, "YYYY"), dayjs(`${res.endYear}-01-01`, "YYYY")]
                })
            }

        })
    }

    const save = () => {
        if ((detail && detail?.status !== 0) && !canEdit) {
            setCanEdit(true)
            return
        }
        form.validateFields().then(async res => {
            const { cardUrls, dateArr } = res
            setLoading(true)
            postStudentIdentification({ cardUrls: cardUrls.map(item => item.url), startYear: dateArr[0].$y, endYear: dateArr[1].$y }).then(res => {
                message.success("提交成功")
                getDetail()
                setCanEdit(false)
            }).finally(() => {
                setLoading(false)
            })

        })

    }

    return <AuthenticationStudentStyle>
        <Form
            {...formItemLayout}
            className='form'
            style={{ maxWidth: 800 }}
            labelAlign='left'
            form={form}
            disabled={(detail && detail?.status !== 0) && !canEdit}
        >
            <div className="tip">学生证信息用于平台赛事学生身份认证。</div>
            {
                detail && detail.status !== 0 && <Form.Item
                    className="status"
                    label="审核状态"
                >
                    {detail.status === 1 ? '审核中' : detail.status === 2 ? '已认证' : detail.status === 3 ? '已驳回' : ''}
                </Form.Item>
            }
            <Form.Item
                name="cardUrls"
                label="学生证照片"
                rules={[{ required: true, message: '请上传学生证照片' }]}
                className="cardUrls"
            >
                <ProductPicturesWall disabled={(detail && detail?.status !== 0) && !canEdit} imgSrc={CosDirectoryType.authenticationStudent} maxLength={3} />
            </Form.Item>
            <div className="img-tip">最多上传3张图片</div>
            <Form.Item
                name="dateArr"
                label="在读年份"
                rules={[{ required: true, message: '请选择在读年份' }]}
                className="dateArr"
            >
                <RangePicker picker="year" format="YYYY" />
            </Form.Item>
            <Form.Item>
                <div className="btn-wrap">
                    {
                        detail && detail.refuseReason && detail.status === 3 && <div className="refuseReason">驳回原因：{detail?.refuseReason}</div>
                    }
                    <Button loading={loading} disabled={detail?.status === 1} type="primary" onClick={save}>{detail?.status === 1 ? '审核中' : detail?.status === 2 || detail?.status === 3 ? '修改信息' : '提交'}</Button>
                </div>
            </Form.Item>
        </Form>
    </AuthenticationStudentStyle>
}

export default AuthenticationStudent