import { FoldDetailStyle } from "./style"
import { getFavoriteDetail, getFavoriteProductList, postAddToFavorite } from "api/favorite"
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from "react-router";
import { FavoriteDataListItem, FavoriteProductListItem } from "types/favorite";
import { Modal, Pagination, Spin, message } from "antd";
import { RiArrowLeftSLine } from "react-icons/ri";
import { NoData } from "components/no-data";
import { queryUrl } from "utils/functions/common";

import UserCenterFavoriteCard from "components/uni-card/self-cards/userCenterFavoriteCard";
import { useSearchParams } from "react-router-dom";
import FavoriteProductCard from "components/product-card/favorite-product-card";
import useWindowSize from "hooks/useWindowSize";


const FavoriteDetail = () => {
    const { id } = useParams<any>();
    let [searchParams] = useSearchParams();
    const type = searchParams.get("type");
    const [list, setList] = useState<FavoriteDataListItem[]>([])
    const [productList, setProductList] = useState<FavoriteProductListItem[]>([])
    const [total,setTotal] = useState(0)
    const [screenSize] = useWindowSize();
    const [pageNumber, setPageNumber] = useState(Number(new URLSearchParams(useLocation().search).get("pageNumber") || 1))
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const params = useMemo(() => {
        return {
            pageNumber,
            pageSize:30,
            id: Number(id)
        }
    }, [pageNumber, id])

    const getList = useCallback(() => {
        setLoading(true)
        if(Number(type) === 3){
            getFavoriteProductList(params).then(res => {
                setProductList(res.pageData)
                setTotal(res.totalSize)
                setLoading(false)
            })
        }else{
            getFavoriteDetail(params).then(res => {
                setList(res.pageData)
                setTotal(res.totalSize)
            }).finally(() => {
                setLoading(false)
            })
        }
       
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const changePage = (e: number) => {
        setPageNumber(e)
        const query = queryUrl({ pageNumber: e })
        navigate(`?${query}`)
    }

    const toBack = () => {
        navigate(`/user-center/home/favorites`, { replace: true })
    }

    const shouldRefresh = (item: FavoriteDataListItem | FavoriteProductListItem) => {
        Modal.confirm({
            title: "取消收藏？",
            content: "取消收藏后该收藏夹内无法找回",
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                const params = {
                    dataId: item.dataTable === 'media' ? item.dataId : item.id,
                    dataTable: item.dataTable,
                    favoriteId: Number(id),
                    flag: 0,
                };
                postAddToFavorite(params).then(() => {
                    message.success("取消成功");
                    getList()
                });
            },
        });
    };

    return <FoldDetailStyle>
        <div className="button-wrap">
            <div onClick={toBack} className="back-btn">  <RiArrowLeftSLine className="back-icon"></RiArrowLeftSLine> <span>返回收藏夹列表</span></div>
        </div>
        <Spin spinning={loading}>
            <div className="list"  style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
                {(Number(type) ===1 || Number(type) ===2) && list.map((item, index) => {
                    return <UserCenterFavoriteCard shouldRefresh={shouldRefresh} item={item} key={index} />
                })}
                {Number(type) === 3 && productList.map((item, index) => {
                    return <FavoriteProductCard  shouldRefresh={shouldRefresh} item={item} key={index}></FavoriteProductCard>
                })}
            </div>
        </Spin>
        <Pagination
            style={{ textAlign: "center", marginTop: "15px" }}
            hideOnSinglePage={true}
            current={pageNumber}
            onChange={changePage}
            pageSize={params.pageSize}
            total={total}
        />
        {
          (Number(type) ===1 || Number(type) ===2) &&  list.length === 0 && <div style={{ padding: "200px 0" }}><NoData /></div>
        }
         {
          Number(type) === 3 &&  productList.length === 0 && <div style={{ padding: "200px 0" }}><NoData /></div>
        }
    </FoldDetailStyle>
}

export default FavoriteDetail