import styled from "styled-components";

export const PublicCardStatusStyle = styled.div`
 display: flex;
    align-items: center;
    gap:  ${(props) => props.theme.space.paddingXXS}px;
    margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
    margin-left: ${(props) => props.theme.space.paddingXXS}px;
    .subtitle {
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4px 12px;
    color: rgb(255, 255, 255);
    border-radius: 6px;
    white-space: nowrap;
   }

`