import { useEffect, useState } from "react";
import { ForumMineTopicListItem, ForumMineTopicListRequest } from "api/forum/type";
import { apiMyForumTopicList } from "api/forum";
import { MyTopicListStyle } from "./style";
import { Pagination, Space, Tag, Tooltip } from "antd";
import { RiMessage3Line } from "react-icons/ri";
import { NoData } from "components/no-data";

function MyTopicList() {
  const [list, setList] = useState<ForumMineTopicListItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [listParams, setListParams] = useState<ForumMineTopicListRequest>({
    pageSize: 20,
  })

  useEffect(() => {
    apiMyForumTopicList({ pageNumber: currentPage, ...listParams }).then((res) => {
      setTotalSize(res.totalSize);
      setList(res.pageData);
    });
  }, [listParams, currentPage])

  const changePage = (e: any) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(e)
  };

  return (
    <MyTopicListStyle>
      <div className="list">
        {list.map((item, index) => {
          return (
            <div className="list-item" key={index}>
              <a
                href={`/forum/question/topic/${item.id}`}
                className="title overflow-hidden"
                title={item.title}
              >
                {
                  item.isRecommend === 1 && <Tag className="tag" color="#F2731D">精</Tag>
                }
                {item.title}
              </a>
              <div className="interaction">
                <Space size="large">
                  <Tooltip title={`创建于 ${item.submitTime}`}>
                    <span className="edit-time">
                      编辑于 {item.lastSubmitTime}
                    </span>
                  </Tooltip>
                  <div className="icon-btn">
                    <RiMessage3Line style={{ fontSize: 16 }}></RiMessage3Line>{" "}
                    <span>{item.replyCount}人回复</span>
                  </div>
                </Space>
              </div>
            </div>
          );
        })}
        {list.length === 0 && (
          <div style={{ marginTop: "200px" }}>
            <NoData></NoData>
          </div>
        )}
      </div>
      <Pagination
        style={{ textAlign: "center", margin: "24px 0 12px 0" }}
        hideOnSinglePage={true}
        current={currentPage}
        onChange={changePage}
        pageSize={listParams.pageSize}
        total={totalSize}
        showSizeChanger={false}
        size="default"
      />
    </MyTopicListStyle>
  );
}

export default MyTopicList;
