import SectionTitle from "components/section-title"
import { WorkCollectionStyle } from "./style"
import ArticleCard from "./card"
import { getRecommendArticleList } from "api/article"
import { useEffect, useState } from "react"
import { RecommendArticleListItem } from "types/article"
import {  RiArrowRightSLine } from "react-icons/ri"

function Articles() {
  const [list, setList] = useState<RecommendArticleListItem[]>([])
  const toMore = () => {
    window.open("/explore?pageNumber=1&sortField=recommendUpdateTime&type=article")
  }
  const getList = () => {
    getRecommendArticleList({ pageSize: 12 }).then(res => {
      setList(res.pageData)
    })
  }

  useEffect(() => {
    getList()
  }, [])


  return (
    <WorkCollectionStyle>
      <div className="title-wrap">
       {list &&  <SectionTitle text={'文章精选'}></SectionTitle>}
        <div className="tip" onClick={toMore}>查看更多 <RiArrowRightSLine className="icon"></RiArrowRightSLine></div>
      </div>
      <div className="container_">
        <div className="wrap">
          <ArticleCard list={list}></ArticleCard>
        </div>
      </div>
    </WorkCollectionStyle>
  )
}

export default Articles