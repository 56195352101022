import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  message,
} from "antd";
import { apiCreateMedia, apiUpdateMedia } from "api/media";
import ImageUpload from "components/uploadImage";
import { VideoUpload } from "components/videoUpload";
import { getFileUrlByCosUpload } from "config/cos";
import { ProgressInfo } from "cos-js-sdk-v5";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CosDirectoryType, MediaType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";
import { VideoEditForm, VideoEditProps } from "../type";
import { VideoUploadStyle } from "./style";

const { TextArea } = Input;

export const ComboVideoUpload = (props: VideoEditProps) => {
  const [form] = Form.useForm<VideoEditForm>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { id } = useParams();
 
  useEffect(() => {
    setConfirmLoading(false);
    form.resetFields();
    form.setFieldsValue({
      ...props.params
    });
  }, [props.show]);

  const onCancel = () => {
    props.close();
  };

  const onClickSubmit = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        let { video, caption, coverUrl} = values
        
          let videoFile = video.file
          const uploadUUid = getFileName(CosDirectoryType.workVideo,videoFile!.name)
          let newItem = { id: 0, url: "", coverUrl: '', type: MediaType.video, key: uploadUUid, percent: 0,caption: caption };
          props.refreshMediaList(newItem, 'add')

        props.close()
        let resultCoverUrl = {url: '',
          md5: ''}
      if( coverUrl&&coverUrl.file) {
          resultCoverUrl = await getFileUrlByCosUpload(
          coverUrl.file!,
          getFileName(CosDirectoryType.workVideo, coverUrl.file!.name),
        )
      }
          

          newItem.coverUrl = resultCoverUrl.url

          props.refreshMediaList(newItem, 'update')
        
          const resultVideo = await getFileUrlByCosUpload(
            videoFile as File,
            uploadUUid,
            onProgress
          );
       

          //绑定媒体，写入数据库
          const dataItem = await apiCreateMedia({
            coverUrl:  resultCoverUrl.url,
            dataId: Number(id),
            dataTable: "work",
            md5: resultVideo.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
            name: videoFile!.name.split(".").shift()+'',
            rank: 0,
            size: videoFile!.size,
            type: MediaType.video,
            url: resultVideo.url,
            mimeType: videoFile!.type,
            uuid: uploadUUid,
            caption: caption,
            mark:props.mark
          });
          newItem.url = dataItem.url
          newItem.id = dataItem.id;
          newItem.coverUrl = dataItem.coverUrl;
          newItem.percent=100
          newItem.caption = dataItem.caption
          console.log(newItem)
          props.refreshMediaList(newItem, 'update')
      })
      .catch((err) => {
        setConfirmLoading(false);
      });
  };


    //上传进度回调
    const onProgress = (progressData: ProgressInfo, key: string) => {
      props.refreshMediaList(
          { id: 0, url: "",coverUrl: '',  key: key, type: MediaType.video, percent:  progressData.percent * 100, caption: '' }, 'update'
      ) 
    };

    const  onClickUpdate = () =>{
      setConfirmLoading(true);
      form
        .validateFields()
        .then(async (values) => {
          let {  caption, coverUrl} = values
          
            
  
          let resultCoverUrl = {url: props.params.coverUrl.imgUrl,
            md5: ''}
        if( coverUrl&&coverUrl.file) {
            resultCoverUrl = await getFileUrlByCosUpload(
            coverUrl.file!,
            getFileName(CosDirectoryType.workVideo, coverUrl.file!.name),
          )
        }
            
            //绑定媒体，写入数据库
            const dataItem = await apiUpdateMedia({
              id: props.params.id!,
              coverUrl:  resultCoverUrl.url,
              caption: caption
            });
            
            let newItem = { id: props.params.id!, url: dataItem.url, coverUrl: dataItem.coverUrl, type: MediaType.video, key: dataItem.uuid, percent: 100,caption: dataItem.caption };
            props.refreshMediaList(newItem, 'update')
            message.success('修改成功!')
            props.close()
        })
        .catch((err) => {
          setConfirmLoading(false);
        });
    }


  // const onClickSave = () => {
  //   form
  //     .validateFields()
  //     .then(async (values) => {
  //       console.log(values)
       
  //     })
  //     .finally(() => {
  //       setConfirmLoading(false);
  //     });
  // };


  return (
    <VideoUploadStyle>
      <Modal
        title="上传视频"
        open={props.show}
        cancelText="取消"
        onCancel={onCancel}
        okText="保存"
        onOk={ props.params.id ? onClickUpdate :onClickSubmit } 
        confirmLoading={confirmLoading}
        width={500}
        maskClosable={false}
      >
        <Spin spinning={confirmLoading}>
          <Form form={form} layout="vertical" >
            <Row gutter={24}>
              

              <Col span={9} > 
                  <Form.Item
                    name="video"
                    label="视频"
                    extra="单个视频不能超过200M"

                    rules={[{ required: true, message: '视频必填' }, 
                      { validator:  (rule, val, callback) => {
                        if(val.videoUrl){
                          return Promise.resolve()
                        } else {
                          return Promise.reject('视频必填')
                        }
                      }}
                    ]}
                    >
					          <VideoUpload  disabled={!!props.params.id} />
					        </Form.Item>
				        </Col>

                <Col span={8}>
                          <Form.Item
                            name="coverUrl"
                            label="视频封面"
                          >
                            <ImageUpload />
                          </Form.Item>
                        </Col>

                <Col span={24}> 
                        <Form.Item
                        name="caption"
                        label="描述"
                            >
                            <TextArea placeholder='请输入描述' maxLength={500} rows={2} showCount={true}/>
                        </Form.Item>
                  </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </VideoUploadStyle>
  );
};
