import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { SubMatchStyle } from "./style";
import { SingleSelect } from "../components/single-select";
import { SignupField } from "types/sub/application";
import { useParams, useSearchParams } from "react-router-dom";
import { apiGetSignUpField, apiPostSub } from "api/sub";
import { SubSecondSelect } from "../components/second-select";
import GoBackTitle from "ui-lib/go-back";
import { SubChildrenSelect } from "../components/children-select";
import { apiUserDetail } from "api/user/detail";
import { apiGetSignUpDetail, apiUpdateSignUp, getContestDetail } from "api/contest";
import { ContestDetailResponse } from "types/contest";

export const ContestApplication = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [list, setList] = useState<SignupField[]>();
  const [searchParams] = useSearchParams();
  const signUpId = searchParams.get("signUpId");
  const contestCode = searchParams.get("contestCode");
  const validateMessages = {
    required: "${label} 不能为空",
    pattern: {
      mismatch: "${label} 格式不正确",
    },
  };

  const [contestDetail, setContestDetail] = useState<ContestDetailResponse>()

  useEffect(() => {
    getContestDetail({ id: Number(id) }).then(res => {
      setContestDetail(res)
    })
  }, [id])

  const isLongarts = Number(id) === 36;

  const [userInfo, sertUserInfo] = useState({ username: "", mobile: "" });

  useEffect(() => {
    apiUserDetail().then((res) => {
      // setUserDetail(userDetail);
      sertUserInfo({
        username: res.username,
        mobile: res.mobile,
      });
      if (signUpId) {
        apiGetSignUpDetail({ id: Number(signUpId) }).then((res: any) => {
          form.setFieldsValue(res);
        });
      }
    });
  }, []);

  useEffect(() => {
    apiGetSignUpField(+id!).then((resField) => {
      setList(resField);
    });
  }, [id]);

  const onClickSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj: any = { contestId: +id!! };
        if (isLongarts) {
          obj.mediaCategory = [6];
        }
        Object.assign(obj, values);

        const newArr = JSON.parse(JSON.stringify(obj));
        if (newArr?.team) {
          if (newArr?.team.constructor === Object) {
            obj.team = newArr.team.label;
            if (newArr.team.category) {
              obj.category = newArr.team.category;
            }
          } else {
            obj.team = newArr.team;
          }
        }
        if (newArr?.mediaCategory) {
          if (newArr?.mediaCategory.constructor === Object) {
            obj.mediaCategory = newArr.mediaCategory.label;
            if (newArr.mediaCategory.category) {
              obj.category = newArr.mediaCategory.category;
            }
          } else {
            obj.mediaCategory = newArr.mediaCategory;
          }
        }

        if (newArr?.theme) {
          if (newArr?.theme.constructor === Object) {
            obj.theme = newArr.theme.label;
            if (newArr.theme.category) {
              obj.category = newArr.theme.category;
            }
            if (newArr.theme?.children) {
              obj.theme2 = newArr.theme?.children;
            }
          } else {
            obj.theme = newArr.theme;
          }
        }

        if (newArr.category) {
          if (newArr.category.length > 0) {
            obj.category = newArr.category;
          }
        }

        if (signUpId) {
          apiUpdateSignUp({ id: Number(signUpId), ...Object.assign(obj) }).then(
            () => {
              message.success("修改成功！");
              //window.history.back();
              window.location.href = `/contest/${contestCode}/detail`;
            }
          );
        } else {
          apiPostSub(Object.assign(obj)).then(() => {
            message.success("报名成功！");
            window.history.back();
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getComponent = (item: SignupField) => {
    if (
      item.code === "theme" ||
      item.code === "team" ||
      (item.code === "category") ||
      (item.code === "mediaCategory" && !isLongarts)
    ) {
      if(contestDetail?.code === 'czwj2' && item.code === "category") {
        return <></>
      }
      if (item.hasCategory ) {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SubSecondSelect
              data={item.options}
              multiple={!!item.isMulCheck}
            ></SubSecondSelect>
          </Form.Item>
        );
      } else if (item.hasSecondOption) {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SubChildrenSelect
              data={item.options}
              multiple={!!item.isMulCheck}
            ></SubChildrenSelect>
          </Form.Item>
        );
      } else {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SingleSelect
              data={item.options}
              multiple={!!item.isMulCheck}
            ></SingleSelect>
          </Form.Item>
        );
      }
    } else if (item.code === "mediaCategory" && isLongarts) {
    } else {
      // const userInfo = JSON.parse(localStorage.getItem('user_info')!)
      if (item.code === "mobile") {
        form.setFieldValue(item.code, userInfo.mobile);
      }
      if (item.code === "nickname") {
        form.setFieldValue(item.code, userInfo.username);
      }
      return (
        <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <Input
            disabled={item.code === "mobile"}
            placeholder={`请填写您的${item.name}`}
          ></Input>
        </Form.Item>
      );
    }
  };

  return (
    <SubMatchStyle>
      <div className="sub-match-container">
        <div className="form-wrapper">
          <GoBackTitle
            onClick={() => {
              if (contestCode) {
                window.location.href = `/contest/${contestCode}/detail`;
              } else {
                window.history.back();
              }
            }}
          >
            返回赛事首页
          </GoBackTitle>
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            requiredMark={true}
            className="form-area"
            form={form}
            style={{ width: "100%" }}
            validateMessages={validateMessages}
          >
            <div className="form-section">
              {list &&
                list
                  .filter((item) => item.fieldFlag === 2)
                  .map((item) => getComponent(item))}
            </div>
            <div className="form-section">
              {list &&
                list
                  .filter((item) => item.fieldFlag === 1)
                  .map((item) => getComponent(item))}
            </div>

            {/* <Form.Item
                            label="描述"
                            name="desc"
                            rules={[{ required: true },{pattern: new RegExp('')}]}
                            >
                            <SubTextArea></SubTextArea>
                        </Form.Item> */}
          </Form>

          <Button type="primary" onClick={onClickSubmit} block>
            提交报名
          </Button>
        </div>
      </div>
    </SubMatchStyle>
  );
};
