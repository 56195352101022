import  {  useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom';
import { initGlobalState, MicroAppStateActions,registerMicroApps, start } from 'qiankun';

import { ConfigProvider } from 'antd';

import {store} from 'utils/store/index'
import reportWebVitals from './reportWebVitals';

import { LanguageType } from 'types/enums/config-language-type';
import './styles/index';
import  i18n, { i18nChangelanguage } from 'utils/i18n';
import {DEF_ENVVAR_REACT_APP_ACTION} from 'utils/vars/const-envvar'
import {App} from './App'


let configStore = store.getState().config
let qiankunState = {
    language: localStorage.getItem('lang') ?? LanguageType.zh_CN,
    theme: configStore.theme
}


const actions: MicroAppStateActions = initGlobalState(qiankunState);

actions.onGlobalStateChange((state, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  console.log('base');
  console.log(state, prev);
  console.log(state === prev);
});

const Boot = () => {
  let location = useLocation()
  //console.log(store.getState().config.language);
  
  if(localStorage.getItem('lang') !== i18n.language && localStorage.getItem('lang') !== 'null') {
    i18nChangelanguage(localStorage.getItem('lang') as LanguageType)
  }
  
  useEffect(()=>{
    let lang = localStorage.getItem('lang')
    if(lang === 'null') {
      lang = LanguageType.zh_CN
    }
    i18nChangelanguage(lang as LanguageType)
  }, [])

  //记住上一页面路由
  useEffect(()=>{
    const notRemList = ['login', 'register', 'forgetpasswd', 'error_404' ,'userSuggestion']
    let path = window.location.pathname
    let notRem = notRemList.some(word=>{
      return path.includes(word)
  })

  if(!notRem) {
      localStorage.setItem('redirectPath', path)
  }
  document.title = 'GGAC数字艺术平台'
  },[location])

  return <ConfigProvider  theme={{
                token: {
                  colorPrimary: "#ffc712",
                },
                components:{
                  Button: {
                    colorTextLightSolid: "#000",
                    controlHeight:38,
                    colorPrimaryHover:"rgba(255, 216, 60, 1)",
                    colorBgContainerDisabled: "#EEEEEE",
                    borderColorDisabled:'#EEEEEE',
                    colorTextDisabled:'#B0B0B0',
                  },
                  Select:{
                    controlHeight:38
                  },
                  Input:{
                    controlHeight:38
                  },
                  Radio:{
                    buttonSolidCheckedActiveBg	:"#ffc712",
                    buttonSolidCheckedBg:"#ffc712",
                    buttonSolidCheckedHoverBg:"#ffc712",
                  }
                }
               }}>
                <App></App>
            </ConfigProvider>
       
      }

registerMicroApps([
  {
    name: 'ggac-base',
    container: '#ggac-base',
    entry: DEF_ENVVAR_REACT_APP_ACTION === 'run'? 'http://localhost:9100': '/child/past/',
    activeRule: '/v3',
    props: { qiankunState }
  }
]);

//启动 qiankun
start({
  sandbox: {
    // 样式隔离特性
    experimentalStyleIsolation: false,
  },
  excludeAssetFilter: (assetUrl: string) => {
    const whiteList: string[] = [];
    const whiteWords = ['hm.baidu.com/hm', 'google-analytics.com/analytics', 'v2plugins'];
    if (whiteList.includes(assetUrl)) { return true }
    return whiteWords.some(w => { return assetUrl.includes(w)})
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);




root.render(
    <BrowserRouter>
   
      <Provider store={store}>
        <Boot></Boot>
      </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();






