import styled from "styled-components";

interface MyStyledComponentProps {
    workLength?: number;
}

export const WorkShareStyle = styled.div<MyStyledComponentProps>`

@font-face {
    font-family: 'AlibabaPuHuiTiR';
    src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
}

@font-face {
    font-family: 'PangMenZhengDao';
    src: url('https://cdn-prd.ggac.com/ggac/font/PangMenZhengDao.ttf');
}

    .container_{
        display: flex;
        align-items: center;

        .left-wrap {
            overflow-y: auto;
            max-height: 515px;
            overflow-x: hidden;
            ::-webkit-scrollbar {
              width: 10px; /* 设置滚动条的宽度 */
              background-color: transparent; /* 滚动条的背景色 */
            }
            ::-webkit-scrollbar-track {
              background: #e1e1e1; /* 轨道的背景色 */
              border-radius: 10px; /* 轨道的圆751 / 1000角 */
            }
            /* 自定义滚动条的滑块（thumb） */
            ::-webkit-scrollbar-thumb {
              background-color: #c1c1c1; /* 滑块的背景色 */
              border-radius: 10px; /* 滑块的圆角 */
              border: 2px solid #ffffff; /* 滑块边框 */
            }
            
            /* 滑块在悬停或者点击时的样式 */
            ::-webkit-scrollbar-thumb:hover {
              background: #a8a8a8; /* 悬停时滑块的背景色 */
            }
            
            ::-webkit-scrollbar-thumb:active {
              background-color: #919191; /* 点击时滑块的背景色 */
            }
        }
        
        .left{
            width: 320px;
            display: flex;
            flex-direction: column;
            border-radius:  ${props => props.theme.border.borderRadius}px;
            box-shadow: ${props => props.theme.shadow.boxShadow};
            font-family: 'AlibabaPuHuiTiR';
            position: relative;
            border-radius: 6px;
            overflow: hidden;
            cursor: pointer;

            .jigsaw {
                display: flex;
                flex-wrap: wrap;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                overflow: hidden;
                background: transparent;
                .jigsw-wrap {
                    display: flex;
                    gap: 3px;
                    flex-wrap: wrap; 
                    width: 100%;
                    background-color: #fff;
                    padding-top: 3px;
                }
                div {
                    background-position: center;
                    background-size: cover;
                }
                &-item-1 {
                    width: 100%;
                    aspect-ratio: 750 / 726;
                }
                &-item-2 {
                    width: ${props => props.workLength === 2 ? "100%" : (!!props.workLength && props.workLength > 2) ? "calc((100% - 3px) / 2)" : '0'};
                    aspect-ratio: ${props => props.workLength === 2 ? 750 / 726 : (!!props.workLength && props.workLength > 2) ? 371 / 429 : ''};
                }
                &-item-3 {
                    width: ${props => (!!props.workLength && props.workLength >= 3) ? "calc((100% - 3px) / 2)" : '0'};
                    aspect-ratio: 371 / 429;
                }
                &-item-4 {
                    width: ${props => (!!props.workLength && props.workLength >= 4) ? "100%" : '0'};
                    aspect-ratio: 750 / 726;
                }
                &-item-5 {
                    width: ${props => props.workLength === 5 ? "100%" : (!!props.workLength && props.workLength > 5) ? "calc((100% - 3px) / 2)" : '0'};
                    aspect-ratio: ${props => props.workLength === 5 ? 750 / 726 : (!!props.workLength && props.workLength > 5) ? 371 / 429 : ''};
                }
                &-item-6 {
                    width: ${props => (!!props.workLength && props.workLength >= 6) ? "calc((100% - 3px) / 2)" : '0'};
                    aspect-ratio: 371 / 429;
                }
            }

            .share-img{
                object-fit: contain;
                width: 100%;
                background-size: cover;
                background-position: center;
            }
            .user2 {
                background-color: #000 !important;
                color: #fff;
            }
            .user3 {
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: transparent !important;
                color: #fff;
            }
            .user {
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                background-color: #fff;
                .title {
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 230px;
                }
                .prize-word {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 10px;
                    max-width: 220px;
                }
                .user-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    .code {
                        width: 60px;
                    }
                    div {
                        font-size: 10px;
                        margin-top: 5px;
                        color: #979797;
                    }

                    .day {
                        font-family: 'PangMenZhengDao';
                        font-size: 35px;
                        color: #fff;
                        margin-top: 0;
                        margin-right: 5px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                    }
                    .year {
                        transform: translateY(-50%) rotate(-90deg);
                        color: #fff;
                        position: absolute;
                        top: 50%;
                        right: -22px;
                        margin-top: 0;
                    }
                }
                .user-detail {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-top: 5px;
                    .user-cover {
                        width: 20px;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                    }
                    .user-name {
                        font-size: 12px;
                    }
                }
            }
        }

        .right{
           flex: 1;
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           .up{
            text-align: center;
            padding:${props => props.theme.space.paddingLG}px;
           }
           .qr-code {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                margin-bottom: 20px;
                img {
                    width: 150px;
                }
           }
           .down{
            display: flex;
            justify-content: center;
            gap:  ${props => props.theme.space.paddingLG}px;
            padding: 0 ${props => props.theme.space.padding}px;
            width: 100%;
           
            .item{
                font-size: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                flex: 1;
                .icon{
                    width: 100%;
                    aspect-ratio: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color:#fff;
                    background-color:${props => props.theme.colors.colorPrimary};
                    border-radius: 50%;
                }
                span{
                    font-size: ${props => props.theme.fontSizes.fontSize}px;
                    padding:${props => props.theme.space.padding}px 0;
                }
            }
           }
        }

        .wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .tab {
        display: flex;
        gap: 20px;
        margin: ${props => props.theme.space.margin}px 0;
        cursor: pointer;
        .tab-active {
            color: ${props => props.theme.colors.colorPrimary};
        }
    }

    .work-wrap {
        display: flex;
        align-items: center;
        width: 100%; 
        position: relative;
        padding: 0 15px;

        .control {
            font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
            border-radius: 50% 50%;
            width: 28px;
            height: 28px;
            background-color: #F1F1F3;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            &.prev {
              left: -18px;
            }
            &.next {
              right: -25px;
            }
            &.next {
              margin-right: ${props => props.theme.space.marginXS}px;
            }
        }
    }

    .active {
        border: 1px solid ${props => props.theme.colors.colorPrimary};
    }

    .work {
        &-item {
            aspect-ratio: 1;
            background-size: cover;
            background-position: center;
            cursor: pointer;
        }
    }

    .template {
        height: 140px;
        &-item {
            background-color: #f4f5fb;
            border-radius: 5px;
            background-size: 100% 100%;
            position: relative;
            cursor: pointer;
            .icon {
                margin: 0 auto;
            }
        }
        .name {
            position: absolute;
            bottom: 10px;
            text-align: center;
            width: 100%;
            color: #3b3b3c;
        }
        .icon-1 {
            padding-top: 16px;
            .icon {
                width: 44px;
                height: 76px;
                
            }
        }
        .icon-2 {
            padding-top: 32px;
            .icon {
                width: 76px;
                height: 44px;
            }
        }
        .icon-3 {
            padding-top: 16px;
            .icon {
                width: 44px;
                height: 76px;
            }
        }
        .icon-4 {
            padding-top: 26px;
            .icon {
                width: 44px;
                height: 58px;
            }
        }

        .icon-5 {
            padding-top: 26px;
            .icon {
                width: 44px;
                height: 58px;
            }
        }

        .icon-6 {
            padding-top: 26px;
            .icon {
                width: 44px;
                aspect-ratio: 1 / 1;
            }
        }
        .icon-7 {
            padding-top: 26px;
            .icon {
                width: 46px;
                aspect-ratio: 1 / 1;
            }
        }
        .icon-8 {
            padding-top: 26px;
            .icon {
                width: 33px;
                aspect-ratio: 33 / 54;
            }
        }
    }

`