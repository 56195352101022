import { isLogin } from "./fn";

const forumTopicTabs_ = [
  {
    key: 'following',
    title: "关注",
  },
  {
    key: 'recommend',
    title: "推荐",
  },
  {
    key: 'hot',
    title: "最热",
  },
  {
    key: 'new',
    title: "最新",
  }
];

export const forumTopicTabs = ()=>{
  if(isLogin()){
    return forumTopicTabs_
  }else{
    return [
      {
        key: 'recommend',
        title: "推荐",
      },
      {
        key: 'hot',
        title: "最热",
      },
      {
        key: 'new',
        title: "最新",
      }
    ]
  }
}

export const myDataTabs = [
  {
    key: 'topic',
    title: "我的发布",
  },
  {
    key: 'reply',
    title: "我的回复",
  },
  {
    key: 'favorite',
    title: "我的收藏"
  },
];

export const forumOrderStatusList = [
  {
    key: '',
    title: "全部",
  },
  {
    key: '0',
    title: "待支付",
  },
  {
    key: '1',
    title: "待回答",
  },
  {
    key: '2',
    title: "待确认",
  },
  {
    key: '3',
    title: "已完成",
  }
];

export const forumReceiveOrderStatusList = [
  {
    key: '1,2,3,4',
    title: "全部",
  },
  {
    key: '1',
    title: "待回答",
  },
  {
    key: '2',
    title: "待确认",
  },
  {
    key: '3',
    title: "已完成",
  }
];

export const forumOrderAllStatusList = [
  {
    key: '',
    title: "全部",
  },
  {
    key: '0',
    title: "待支付",
  },
  {
    key: '1',
    title: "待回答",
  },
  {
    key: '2',
    title: "待确认",
  },
  {
    key: '3',
    title: "已完成",
  },
  {
    key: '4',
    title: "已取消",
  }
];