import styled from "styled-components";

export const ReadDiliverStyle = styled.div`

    .detail {
        margin-top: 30px;
        overflow: auto;
        max-height: 350px;
    }

    .detail-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        font-size: 15px;
        align-items: center;
    }

    .image-wrap {
        width: calc(100% - 100px);
    }

    .label {
        width: 100px;
    }

    .value {
        max-width: calc(100% - 100px);
    }

    .image {
        width: 100%;
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        .ant-image {
            width: calc((100% - 30px * 3) / 4 );
        }
        &-item {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }
        .file-item {
            width: calc((100% - 30px * 3) / 4 );
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eee;
            position: relative;
            &:hover {
                .block {
                    z-index: 2;
                }
            }
            .block {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.7);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fff;
                cursor: pointer;
                z-index: -1;
                .word {
                    font-size: 12px;
                }
            }
        }
    }
`