import ProductCardLayout from "../layout";
import { FavoriteProductListItem } from "types/favorite";
import FavoriteCardAction from "./favoriteCardAction";

function FavoriteProductCard({ item ,shouldRefresh}: { item: FavoriteProductListItem ,shouldRefresh?:(item:FavoriteProductListItem)=>void}) {

  const UiObject = {
    coverUrl: item.coverUrl,
    title: item.name,
    subtitle: item.categoryName,
    productLink: "",
    realPrice: item.rmbPrice,
    id: item.id,
    isSoldOut: item.isSoldOut,
    hasOuterUrl: item.hasOuterUrl,
    ourterUrl: item.outerUrl,
    expired: item.expired,
    leftBottomNode:  (item.expired === 1) && <div className="status">已失效</div>,
    centerNode: shouldRefresh && <FavoriteCardAction item={item} workLink={`/product/detail/${item.id}`} shouldRefresh={shouldRefresh}></FavoriteCardAction>,
    bottomLeftNode:
    <a className="user" onClick={(e)=>e.stopPropagation()} href={`/user/${item.userInfo.id}/shop`} target="_blank">
      <div className="avatar" style={{backgroundImage:`url(${item.userInfo.avatarUrl})`}}>
      </div>
      <span className="overflow-hidden">{item.userInfo.username}</span>
    </a>
    ,
    bottomRightNode:<div className="real">
    <span className="sign">￥</span>
    <span>{item.rmbPrice && (item.rmbPrice / 100).toFixed(2)}</span>
  </div>
  };

  return ProductCardLayout({ item: UiObject });
}

export default FavoriteProductCard;
