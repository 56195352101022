import { ForumTopicPayIssuessDetail } from "api/forum/type"
import { RewardDetailReadDiliverStyle } from "./style"
import { RiDownload2Line, RiFileCopy2Line } from "react-icons/ri"
import { Image } from "antd"

const RewardDetailReadDiliver = ({ detail }: { detail: ForumTopicPayIssuessDetail }) => {
    return <RewardDetailReadDiliverStyle>
        <div className="detail">
            {
                detail?.imageList && detail?.imageList.length > 0 && <div className="detail-item">
                    <div className="label">图片列表：</div>
                    <div className="value image-wrap">
                        <div className="image">
                            {
                                detail?.imageList.map(item => {
                                    return <Image src={item} className="image-item" key={item} />
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                detail?.fileList && detail?.fileList.length > 0 && <div className="detail-item">
                    <div className="label">源文件列表：</div>
                    <div className="value image-wrap">
                        <div className="image">
                            {
                                detail?.fileList.map(item => {
                                    return <>
                                        {
                                            ['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <Image src={item} className="image-item" key={item} />
                                        }
                                        {
                                            !['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <div className="file-item">
                                                <RiFileCopy2Line size={30} />
                                                <div className="block">
                                                    <RiDownload2Line onClick={() => window.open(item)} color="#fff" size={16} />
                                                    <div className="word" onClick={() => window.open(item)}>下载文件</div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                detail?.fileSupplement && <div className="detail-item">
                    <div className="label">源文件链接：</div>
                    <div className="value">{detail?.fileSupplement}</div>
                </div>
            }
        </div>
    </RewardDetailReadDiliverStyle>
}

export default RewardDetailReadDiliver