import styled from "styled-components";

export const LatestEventStyle = styled.div`
      min-width: 1200px;
      max-width: ${(props) => props.theme.space.maxContainer}px;
      padding: 0 ${props => props.theme.space.padding}px;
      /* padding-top: ${(props) => props.theme.space.padding}px; */
      background-color: ${(props) => props.theme.colors.colorBgLayout};
      margin: 0 auto;
    .title-wrap {
      position: relative;
      width: 100%;
      .tip {
        position: absolute;
        right:0;
        top: 50%;
        transform: translateY(-50%);
        color: ${props => props.theme.colors.colorLinkDefault};
        cursor: pointer;
        display: flex;
        align-items: center;
        .icon{
          font-size: 16px;
          padding-left: 4px;
        }
        &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
      }
    }
  .container_ {
    margin: 0 auto;
    .wrap {
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
  }
`;


export const CardStyle = styled.div`
  /* z-index: 100; */
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: ${(props) => props.theme.space.padding}px;
  margin: 0 auto;
  .card {
    background-color: #fff;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colors.colorBorder};
    &:hover{
      box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px 0px,
      rgba(0, 0, 0, 0.16) 0px 0px 1px 0px;
    }
    .cover {
      width: 100%;
      aspect-ratio: 223 / 176;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${(props) => props.theme.colors.colorBlack6Ggac};
      position: relative;
      .date {
          position: absolute;
          top: 10px;
          right: 0;
          height: 32px;
          color: #fff;
          line-height: 32px;
          text-align: center;
          padding: 0 ${props => props.theme.space.paddingXS}px;
          font-size: 12px;
          background: rgba(0,0,0,.6);
          z-index: 2;
          border-radius: ${(props) => props.theme.border.borderRadiusSM}px 0 0  ${(props) => props.theme.border.borderRadiusSM}px;
        }
      }
    .content {
      padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingMD}px;
      .flex {
        display: flex;
        flex-direction: column;
        .upper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
            color: ${(props) => props.theme.colors.colorBlack6Ggac};
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
          }
          .view {
            display: flex;
            align-items: center;
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            span {
              padding-left: ${(props) => props.theme.space.paddingXXS}px;
              color: ${(props) => props.theme.colors.colorTextSecondary};
            }
          }
        }
        .down{
          color: ${(props) => props.theme.colors.colorTextSecondary};
          font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
        }
      }
    }
  }
`;