import { HomePageRankStyle } from "./style";
import SectionTitle from "components/section-title";
import RankCard from "./card";
import { useEffect, useState } from "react";
import { getRankingWorkList } from "api/billboard";
import { RankingWorkItem } from "types/billboard";
import { useNavigate } from "react-router";
import { RiArrowRightSLine } from "react-icons/ri";

function HomePageRank() {

  const [workList, setWorkList] = useState<RankingWorkItem[]>([])
  const navigate = useNavigate()

  const getWorkList = () => {
    let params = {
      pageSize: 6
    }
    getRankingWorkList(params).then(res => {
      setWorkList(res.slice(0,6) )
    })
  }

  const toMore = () => {
    document.getElementById('kindsWorks')!.scrollTop = document.body.clientHeight - 145
    navigate(`/billboard/work/week`)
  }

  useEffect(() => {
    getWorkList()
  }, [])
  return (
    <HomePageRankStyle>
      <div className="title-wrap">
        <SectionTitle text={'本周热榜'}></SectionTitle>
        <div className="tip" onClick={toMore}>查看更多 <RiArrowRightSLine className="icon"></RiArrowRightSLine></div>
      </div>
      <div className="container_">
        <div className="wrap">
          <RankCard list={workList}></RankCard>
        </div>
      </div>
    </HomePageRankStyle>
  );
}

export default HomePageRank;
