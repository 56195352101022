import styled from "styled-components";

export const WorkCollectionStyle = styled.div`
  background-color: ${(props) => props.theme.colors.colorBgLayout};
  max-width: ${(props) => props.theme.space.maxContainer}px;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0 ${props => props.theme.space.padding}px;
  .title-wrap {
      position: relative;
     width: 100%;
     margin-top: ${(props) => props.theme.space.padding}px;
      .tip {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: ${props => props.theme.colors.colorLinkDefault};
        cursor: pointer;
        display: flex;
        align-items: center;
        .icon{
          font-size: 16px;
          padding-left: 4px;
        }
        &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
      }
    }
  .container_ {
    /* background-color: ${(props) => props.theme.colors.colorHomeBgContainer}; */
    .outer{
      margin:0 auto;
      min-width: 1200px;
    }
    .wrap {
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
  }

`

export const CollectionCarouselStyle = styled.div`
    width: 100%;
    position: relative;
    .control{
      font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
      border-radius: 50% 50%;
      width: 28px;
      height: 28px;
      background-color: ${(props) => props.theme.colors.colorTextLightSolid};
      box-shadow:  ${(props) => props.theme.shadow.boxShadowSecondary};
      &:hover{
        box-shadow:  ${(props) => props.theme.shadow.boxShadow};
      }
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .prev{
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      left: -38px;
    }
    .next{
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      right: -38px;
    }

`

export const CardStyle = styled.div`
    .card {
    margin: 0 5px;
    cursor: pointer;
    &:hover{
      
    }

    .cover {
      width: 100%;
      aspect-ratio: 223/176;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      background-color: ${(props) => props.theme.colors.colorBlack6Ggac};
    }
    .content {
      padding: ${(props) => props.theme.space.paddingXS}px 0;
      .flex {
        display: flex;
        flex-direction: column;
        .upper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          a {
            display: inline-grid;
          }
          .title {
            margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-clamp: 1;
            color: ${(props) => props.theme.colors.colorBlack6Ggac};
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
          }
          .view {
            display: flex;
            align-items: center;
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            span {
              padding-left: ${(props) => props.theme.space.paddingXXS}px;
              color: ${(props) => props.theme.colors.colorTextSecondary};
            }
          }
        }
        .user-name {
          font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
          color: ${(props) => props.theme.colors.colorTextSecondary};
        }
      }
    }
  }

`