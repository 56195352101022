import { orderDetailResponse } from "types/product";
import { PreSubmitModalStyle } from "./style";
import {
  Button,
  Checkbox,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { UserAddressItem } from "types/user/address";
import { apiGetUserAddressList, apiPostDeleteAddress } from "api/user/address";
import { postEditMall } from "api/shop";
import AddressPopup from "views/user-center/product/components/address-popup";
import { formatMoney } from "views/user-shop/productManage/create/fn";
import { initChatService } from "utils/functions/chat";
import ShopYuegaoAgreement from "views/user-shop/productManage/create/yuegaoAgreement";

type Props = {
  onCancal: () => void;
  detail: orderDetailResponse;
  isShow: boolean;
  submitPay: (type: number) => void;
  onNeedDetailRefresh: () => void;
};
const PayModal = ({
  onCancal,
  isShow,
  detail,
  submitPay,
  onNeedDetailRefresh,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [payType, setPayType] = useState(1);
  const [addressList, setAddressList] = useState<UserAddressItem[]>([]);
  const [addressValue, setAddressValue] = useState(0);
  const [isShowAddressPopup, setIsShowAddressPopup] = useState(false);
  const [updateAddressId, setUpdateAddressId] = useState(0);
  const [isCreate, setIsCreate] = useState(true);
  const [isAgreeYuegao, setIsAgreeYuegao] = useState(false)
  const [isShowShopYuegaoAgreement, setIsShowShopYuegaoAgreement] = useState(false)
  const [isYuegao, setIsYuegao] = useState(false)
  const payList = [
    {
      name: "微信支付",
      image: "https://cdn.ggac.com/img/copyright-pay-wx.png",
      id: 1,
    },
    {
      name: "支付宝",
      image: "https://cdn.ggac.com/img/copyright-pay-zfb.png",
      id: 2,
    },
  ];

  const getAddress = () => {
    apiGetUserAddressList().then((res) => {
      setAddressList(res);
      // const value = res.find((item) => item.isDefault === 1);
      // if (value && value.id && detail.mallProduct.productType === 2) {
      //   setAddressValue(value?.id);
      // }
    });
  };

  useEffect(() => {
    getAddress();
    isShow && setAddressValue(detail.shippingAddressId);
  }, [isShow]);

  useEffect(() => {
    if (detail?.mallProduct.categoryList[0].code === 'yuegao' && isShow) {
      setIsYuegao(true)
      initChatService(detail.sellerUserId)
    } else {
      setIsYuegao(false)
    }
  }, [detail, isShow])

  const onChange = (e: RadioChangeEvent) => {
    setAddressValue(e.target.value);
    updateOrder(e.target.value);
  };

  const updateOrder = (shippingAddressId: number) => {
    postEditMall({
      orderNumber: detail.orderNumber,
      shippingAddressId: shippingAddressId,
    }).then(() => {
      onNeedDetailRefresh();
    });
  };

  const updateAddress = (e: any, id: number) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowAddressPopup(true);
    setUpdateAddressId(id);
    setIsCreate(false);
  };

  const onAddressCancal = () => {
    setIsShowAddressPopup(false);
  };

  const addAddress = () => {
    setIsShowAddressPopup(true);
    setIsCreate(true);
  };

  const deleteAddress = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    // 删除地址
    Modal.confirm({
      title: "确定删除该地址？",
      content: "删除后无法找回",
      okText: "确定",
      cancelText: false,
      onOk: async () => {
        await apiPostDeleteAddress([Number(id)]);
        message.success("删除成功");
        getAddress();
      },
    });
  };

  const toPay = () => {
    if (isYuegao && !isAgreeYuegao) {
      message.warning("请勾选且阅读《约稿商品购买协议》")
      return
    }
    // 修改
    const params: any = { orderNumber: detail.orderNumber };
    if (addressValue) {
      params.shippingAddressId = addressValue;
      postEditMall(params)
        .then((res) => {
          submitPay(payType);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      submitPay(payType);
    }
  };

  const openAgreeYuegao = (e: any) => {
    e.stopPropagation()
    setIsShowShopYuegaoAgreement(true)
  }

  return (
    <PreSubmitModalStyle>
      <Modal
        className="modal"
        centered={true}
        footer={null}
        onCancel={onCancal}
        getContainer={false}
        open={isShow}
        width={600}
      >
        <div className="item">
          <div className="label">商品属性：</div>
          <div className="value">
            <div className="sku-item active">
              <div
                className="cover"
                style={{
                  backgroundImage: `url(${detail.mallProduct.skuImage})`,
                }}
              />
              <div className="name">{detail.mallProduct.skuName}</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="label">购买数量：</div>
          <div className="value">{detail.mallProduct.num}</div>
        </div>
        {detail && detail.mallProduct.isPrivateInquiry === 0 && (
          <Space size="large">
            <div className="item">
              <div className="label">商品金额：</div>
              <div className="value">{formatMoney(detail.productAmount)}元</div>
            </div>
            {detail?.deliveryFee > 0 && (
              <div className="item">
                <div className="label">运费：</div>
                <div className="value">{formatMoney(detail.deliveryFee)}元</div>
              </div>
            )}
            {detail?.discountAmount > 0 && (
              <div className="item coupon">
                <div className="label">优惠券：</div>
                <div className="value" style={{ color: 'red' }}>- {formatMoney(detail?.discountAmount)}元</div>
              </div>
            )}
          </Space>
        )}

        <div className="item">
          <div className="label">实付金额：</div>
          <div className="value">￥{((detail.amount + detail.serviceCharge) / 100).toFixed(2) || 0}</div>
        </div>
        <div className="item">
          <div className="label">支付方式：</div>
          <div className="value">
            <div className="pay">
              {payList.map((item) => {
                return (
                  <div
                    onClick={() => setPayType(item.id)}
                    className={`pay-item ${payType === item.id ? "active" : ""
                      }`}
                    key={item.id}
                  >
                    <img src={item.image} alt="" />
                    <div className="name">{item.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="item">
          {
            isYuegao && <div className="agreement-tip">
              <Checkbox checked={isAgreeYuegao} onChange={e => { setIsAgreeYuegao(e.target.checked) }}>已阅读并同意《<h6 onClick={(e) => openAgreeYuegao(e)}>约稿商品购买协议</h6>》</Checkbox>
            </div>
          }
        </div>
        {detail.mallProduct.productType === 2 && (
          <div className="item">
            <div className="label">收货地址：</div>
            <div className="value">
              <div
                className="address"
                style={{
                  display: addressList.length === 0 ? "flex" : "block",
                  alignItems: "center",
                }}
              >
                <Radio.Group onChange={onChange} value={addressValue}>
                  {addressList.map((item) => {
                    return (
                      <div className="address-item" key={item.id}>
                        <Radio value={item.id} className="radio">
                          <div className="receiver">
                            <div className="left">
                              {" "}
                              {item.receiver}
                              <span
                                style={{ marginLeft: "5px" }}
                                className="phone"
                              >
                                {item.phone}{" "}
                              </span>
                            </div>
                            <div className="right">
                              {!!item.isDefault && (
                                <div className="tag yellow">默认地址</div>
                              )}
                              <div
                                className="tag color"
                                onClick={(e) => updateAddress(e, item.id)}
                              >
                                修改
                              </div>
                              <div
                                className="tag color"
                                onClick={(e) => deleteAddress(e, item.id)}
                              >
                                删除
                              </div>
                            </div>
                          </div>
                          <div className="desc">{item.fullAddress}</div>
                        </Radio>
                      </div>
                    );
                  })}
                </Radio.Group>
                {addressList.length < 5 && (
                  <div className="add-address" onClick={addAddress}>
                    添加地址
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <Button
          className="btn"
          type="primary"
          onClick={toPay}
          disabled={!addressValue && detail.mallProduct.productType === 2}
        >
          立即支付
        </Button>
      </Modal>
      <Modal width={700} footer={[<Button type="primary" onClick={() => setIsShowShopYuegaoAgreement(false)}>
        确定
      </Button>]} title="约稿商品购买协议" destroyOnClose={true} closable={false} centered={true} open={isShowShopYuegaoAgreement}>
        <ShopYuegaoAgreement />
      </Modal>
      <AddressPopup
        updateList={getAddress}
        isCreate={isCreate}
        onCancal={onAddressCancal}
        addressId={updateAddressId}
        isShow={isShowAddressPopup}
        onSave={(id) => {
          if (id === addressValue) {
            updateOrder(id);
          }
          setIsShowAddressPopup(false);
        }}
      />
    </PreSubmitModalStyle>
  );
};

export default PayModal;
