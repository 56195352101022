import useWindowSize from 'hooks/useWindowSize';
import GirdWall from './grid-wall';
import { PhotoWallStyle } from './style'
import { useCallback, useEffect, useState } from 'react';
import { apiGetWorkWall } from 'api/work';
import { WorkWallItem } from 'types/user/work';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { queryUrl } from 'utils/functions/common';

function PhotoWall() {
  const [screenSize] = useWindowSize();
  const [listData, setListData] = useState<WorkWallItem[]>([]);
  const [totalData, setTotalData] = useState<WorkWallItem[]>([]);
  const refreshNumber = new URLSearchParams(useLocation().search).get("refresh") || 0;
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()


  const getWorkWallList = useCallback(() => {
    // clearQueryParams(1)
    let query = queryUrl({ loadingWall: 1 })
    navigate(`?${query}`, { replace: true })
    setLoading(true)
    apiGetWorkWall({
      bigCount: 1,
      middleCount: 3,
      smallCount: 67
    }).then(res => {
      setLoading(false)
      const query = queryUrl({ loadingWall: "" })
      navigate(`?${query}`, { replace: true })
      res.bigWorkWallList?.forEach(item => {
        item.size = 'big'
      })
      res.middleWorkWallList?.forEach(item => {
        item.size = 'middle'
      })
      res.smallWorkWallList?.forEach(item => {
        item.size = 'normal'
      })
      const result = [...res.bigWorkWallList, ...res.middleWorkWallList, ...res.smallWorkWallList]
      if (screenSize.dynamicWidth >= 1600) {
        setListData([...result.slice(0, 67)]);
      } else if (window.innerWidth >= 1300 && screenSize.dynamicWidth < 1600) {
        setListData([...result.slice(0, 63)]);
      } else if (screenSize.dynamicWidth < 1300) {
        setListData([...result.slice(0, 55)]);
      }
      // setListData(result)
      setTotalData([...result])
    }).finally(() => {
      const query = queryUrl({ loadingWall: "" })
      navigate(`?${query}`, { replace: true })
    })
  }, [refreshNumber])

  useEffect(() => {
    getWorkWallList()
  }, [getWorkWallList])


  useEffect(() => {
    if (screenSize.dynamicWidth >= 1600) {
      setListData(totalData.slice(0, 67));
    } else if (window.innerWidth >= 1300 && screenSize.dynamicWidth < 1600) {
      setListData(totalData.slice(0, 63));
    } else if (screenSize.dynamicWidth < 1300) {
      setListData(totalData.slice(0, 55));
    }
  }, [screenSize]);

  // useEffect(() => {
  //   localStorage.setItem(
  //     "workIdsForNav",
  //     JSON.stringify(
  //       listData.map((item) => {
  //           return item.id;
  //       })
  //     )
  //   );
  // }, [listData]);

  const saveWorkIdsForNav = () => {

    listData && localStorage.setItem(
      "workIdsForNav",
      JSON.stringify(
        listData.map((item) => {
          return item.id;
        })
      )
    );
  }

  return (
    <PhotoWallStyle id="photo-wall" onClick={() => saveWorkIdsForNav()}>
      <div className="container_">
        <Spin spinning={loading}>
          <div className="wrap">

            {listData && <GirdWall data={listData}></GirdWall>}

          </div>
        </Spin>
      </div>
    </PhotoWallStyle>
  )
}

export default PhotoWall