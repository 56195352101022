import styled from "styled-components";

export const RecommendStyle = styled.div`
.ant-spin-container {
    &::after {
        background-color: rgba(0,0,0,.3);
    }
}
.recommend-container{
    /* padding: 1vw 0; */
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100vh - 160px);
    max-width: ${(props) => props.theme.space.maxContainer}px;
    margin: 0 auto;
}

    .list-wrapper {
        /* height: calc(100vh - 160px); */
        /* overflow: auto; 
        height: 300px; */
        width: 98%;
        position: relative;
        display: flex;
        /* justify-content: center; */
        .no-data-wrapper{
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .no-login-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        
    }
    .infinite-scroll-component__outerdiv {
        width: 100%;
    }
    .infinite-scroll-component {
        max-width: ${(props) => props.theme.space.maxContainer}px;
        width: 100%;
    }
    /* .recommend {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        width: 100%; */
        
        .recommend-item {
            /* width: 14vw;
            height: 14vw; */
            /* flex: 1 0 auto; */
            width: calc(100% / 7);
            overflow: hidden;
            position: relative;
            /* min-width: 150px; */
            &:hover {
                .bottom {
                    display: block;
                }
            }
            .image {
                width: 100%;
                aspect-ratio: 1;
                background-size: cover;
                cursor: pointer;
                transition: all 1.5s ease-in-out;
                background-position: center;
                background-repeat: no-repeat;
                &:hover {
                    transform: scale(1.3);
                }
            }
            .bottom {
                display: none;
                background: linear-gradient(to top, #474747 0%,transparent);
                width: 100%;
                /* height: 48px; */
                position: absolute;
                left: 0;
                bottom: 0;
                text-align: center;
                padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.paddingXS}px ;
                .user-info{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    cursor: pointer;
                    .cover {
                        width: 25px;
                        height: 25px;
                        background-size: cover;
                        border-radius: 50%;
                        margin-right: ${props => props.theme.space.marginXXS}px;
                        
                    }
                    .username {
                        max-width: calc(100% - 50px);
                        word-break: break-all;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }
                }
                .name {
                    /* display: flex;
                    align-items: center;
                    justify-content: center; */
                    /* line-height: 48px; */
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    /* width: 100px ; */
                }
            }
        }
    /* } */
`