import { createSlice } from '@reduxjs/toolkit'
import {LanguageType} from 'types/enums/config-language-type'
import { ThemeType } from 'types/enums/config-theme-type'

const configSlice = createSlice({
  name: 'config',
  initialState: {
    language: localStorage.getItem('lang') ?? LanguageType.zh_CN,
    theme: ThemeType.light,
  },
  reducers: {
    changeStoreLanguage(state, action) {
     return {
        ...state,
        language: action.payload
     }
    },
    changeStoreTheme(state, action) {
      return {
        ...state,
        language: action.payload
     }
    },
    changeStoreConfig(state, action) {
      return {
        ...state,
        ...action.payload
     }
    }
  }
})

export const { changeStoreLanguage, changeStoreTheme, changeStoreConfig } = configSlice.actions
export default configSlice.reducer
