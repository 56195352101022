import { Pagination, Spin } from "antd"
import { AlbumDetailStyle } from "./style"
import { useCallback, useEffect, useState, useMemo } from "react"
import { useParams } from "react-router"

import { WorkListItem } from "types/work"
import { getAlbumWorkList } from "api/album"
import { NoData } from "components/no-data"
import { PaginationResponse } from "types/common/axios"
import GoBackTitle from "ui-lib/go-back"
import HomePageWorkCard from "components/uni-card/self-cards/homePageWorkCard"
import useWindowSize from "hooks/useWindowSize"
import { setWorkListParamsForNav } from "utils/functions/common"

const AlbumDetail = () => {
    const [albumList, setAlbumList] = useState<WorkListItem[]>([])
    const [pageNumber, setPageNumber] = useState(1)
    const [albumData, setAlbumData] = useState<PaginationResponse<WorkListItem>>()
    const id = useParams().albumId
    const [screenSize] = useWindowSize();
    const params = useMemo(() => {
        return { id: Number(id), pageNumber, pageSize: 30 }
    }, [pageNumber, id])
    const [loading, setLoading] = useState(false)
    const getList = useCallback(() => {
        setLoading(true)
        getAlbumWorkList(params).then(res => {
            setAlbumList(res.pageData)
            setAlbumData(res)
        }).finally(()=>{
            setLoading(false)
        })
    }, [id,pageNumber])

    useEffect(() => {
        getList()
    }, [getList,pageNumber])



    const changePage = (e: number) => {
        setPageNumber(e);
    };

    const toBack = () => {
        window.history.back()
    }

      const t_id = Date.now();

      const saveWorkListParamsForNav = (index:number) => {
        const params_ =  {number:index,portfolioId:params.id,pageNumber:params.pageNumber,pageSize:params.pageSize}
        setWorkListParamsForNav(params_,t_id);
      };
      

    return <AlbumDetailStyle>
        <div className="top">
            <div onClick={toBack}> <GoBackTitle >返回作品集列表</GoBackTitle></div>

            <Spin spinning={loading}>
                {
                    albumList.length === 0 &&
                    <div style={{ padding: '49px 0' }}>
                        <NoData />
                    </div>
                }
               <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
               {albumList.map((item,index)=>{
               
                return <div onClick={() => saveWorkListParamsForNav(index)} key={index} ><HomePageWorkCard item={item} t_id={t_id}></HomePageWorkCard></div>
               }) }
               </div>
            </Spin>
            <Pagination
                style={{ textAlign: "center", marginTop: 28 }}
                hideOnSinglePage={true}
                current={pageNumber}
                showSizeChanger={false}
                onChange={changePage}
                pageSize={params.pageSize}
                total={albumData?.totalSize}
            />
        </div>
    </AlbumDetailStyle>
}

export default AlbumDetail