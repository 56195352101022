import styled from "styled-components";

export const UserOrderStyle = styled.div`
  .top {
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingXS}px;
    justify-content: space-between;
    .header {
      font-size: ${(props) => props.theme.fontSizes.fontSizeHeading5}px;
      padding: ${(props) => props.theme.space.padding}px 0;
    }
    .tag {
      display: flex;
      align-items: center;
      .active {
        color: ${(props) => props.theme.colors.colorPrimary};
      }
      &-item {
        padding: 0 ${(props) => props.theme.space.padding}px;
        cursor: pointer;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  .stats {
    text-align: center;
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
      margin-right: ${(props) => props.theme.space.marginXS}px;
    }
  }

  .btns {
    button {
      margin: 0 ${(props) => props.theme.space.marginXXS}px
        ${(props) => props.theme.space.marginXXS}px;
    }
  }
`;
