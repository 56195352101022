import { useEffect, useState } from "react"
import { SearchListStyle } from "./style"
import { Divider } from "antd"
import { postSearch } from "api/search"
import { useNavigate } from "react-router"
import { RiLoaderLine } from "react-icons/ri"

type searchItemType = {
    name?: string, id: number, title?: string
}

const SearchList = ({ visible, close, value, changeValue }: { visible: boolean, close: () => void, value?: string, changeValue?: (value: string) => void }) => {
    const [loading, setLoading] = useState(false)
    const [workList, setWorkList] = useState<searchItemType[]>([])
    const [articleList, setArticleList] = useState<searchItemType[]>([])
    const [tagList, setTagList] = useState<searchItemType[]>([])
    const [authorList, setAuthorList] = useState<searchItemType[]>([])
    const [mallProducts, setMallProducts] = useState<searchItemType[]>([])
    const navigate = useNavigate()


    const toSearch = (name: string, status: string = 'work') => {
        changeValue && changeValue(name)
        const history: string = localStorage.getItem('search-history') || '[]'
        let arr: string[] = JSON.parse(history) || []
        let type: string = ''
        if (status === 'work' || !status) {
            type = 'work'
        } else if (status === 'article') {
            type = 'article'
        } else if (status === 'tag') {
            type = 'tag'
        } else if (status === 'author') {
            type = 'author'
        } else if (status === 'shop') {
            type = 'shop'
        }
        if (name) {
            if (arr.includes(name)) {
                const index = arr.findIndex((e: string) => e === name)
                arr.splice(index, 1)
            }
            arr.unshift(name)
            arr = arr.slice(0, 5)
            console.log(arr)
            localStorage.removeItem('search-history')
            localStorage.setItem('search-history', JSON.stringify(arr))
            navigate(`/search/keyword?name=${name}&type=${type}`)
        }
        navigate(`/search/keyword?name=${name}&type=${type}`)
        close()
    }

    useEffect(() => {
        if (!visible) {
            return
        }
        setLoading(true)
        postSearch(value || '').then(res => {
            setWorkList(res.works)
            setArticleList(res.articles)
            setTagList(res.tags)
            setAuthorList(res.users)
            setMallProducts(res.mallProducts)
        }).finally(() => {
            setLoading(false)
        })
    }, [value, visible])

    return <SearchListStyle>
        <div className="search-popup" onMouseDown={(e) => e.preventDefault()} style={{ display: visible ? 'block' : 'none' }}>
            {
                loading && <div className="loading">
                    <RiLoaderLine />
                    <span>搜索中</span>
                </div>
            }
            {
                !loading && <div className="search-inner">
                    {
                        workList.length === 0 && tagList.length === 0 && articleList.length === 0 && mallProducts.length === 0 && <div className="none">暂无内容</div>
                    }
                    {
                        workList.length > 0 && <>
                            <Divider orientation='left' className='divider' plain>作品</Divider>
                            {
                                workList && workList.length > 0 && <div className="popup-list">
                                    {
                                        workList.map((item) => {
                                            return <div onClick={() => toSearch(item.name! || item.title!, 'work')} className="list-item" key={item.id}>{item.name}</div>
                                        })
                                    }
                                </div>
                            }

                        </>
                    }
                    {
                        authorList.length > 0 && <>
                            <Divider orientation='left' className='divider' plain>作者</Divider>
                            {
                                authorList && authorList.length > 0 && <div className="popup-list">
                                    {
                                        authorList.map((item) => {
                                            return <div onClick={() => toSearch(item.name! || item.title!, 'author')} className="list-item" key={item.id}>{item.name}</div>
                                        })
                                    }
                                </div>
                            }

                        </>
                    }
                    {
                        articleList.length > 0 && <>
                            <Divider orientation='left' className='divider' plain>文章</Divider>
                            {
                                articleList && articleList.length > 0 && <div className="popup-list">
                                    {
                                        articleList.map((item) => {
                                            return <div onClick={(e) => toSearch(item.name! || item.title!, 'article')} className="list-item" key={item.id}>{item.name}</div>
                                        })
                                    }
                                </div>
                            }

                        </>
                    }
                    {
                        mallProducts.length > 0 && <>
                            <Divider orientation='left' className='divider' plain>商品</Divider>
                            {
                                mallProducts && mallProducts.length > 0 && <div className="popup-list">
                                    {
                                        mallProducts.map((item) => {
                                            return <div onClick={(e) => toSearch(item.name! || item.title!, 'shop')} className="list-item" key={item.id}>{item.name}</div>
                                        })
                                    }
                                </div>
                            }

                        </>
                    }
                    {/* {
                        tagList.length > 0 && <>
                            <Divider orientation='left' className='divider' plain>标签</Divider>
                            {
                                tagList && tagList.length > 0 && <div className="list">
                                    {
                                        tagList.map((item) => {
                                            return <div onClick={(e) => toSearch(item.name! || item.title!, '')} className="list-item" key={item.id}>{item.name}</div>
                                        })
                                    }
                                </div>
                            }
                        </>
                    } */}
                </div>
            }
        </div>
    </SearchListStyle>
}

export default SearchList
