const env = process.env;

export const DEF_ENVVAR_NODE_ENV: string = env.NODE_ENV;

export const DEF_ENVVAR_REACT_APP_COS_BUCKET: string = env.REACT_APP_COS_BUCKET!;

export const DEF_ENVVAR_REACT_APP_COS_REGION: string = env.REACT_APP_COS_REGION!;

export const DEF_ENVVAR_REACT_APP_CDN_LINK: string = env.REACT_APP_CDN_LINK!;

export const DEF_ENVVAR_REACT_APP_ACTION: string = env.REACT_APP_ACTION!;
