import styled from "styled-components";

export const UiAvatarStyle = styled.div`

.ant-segmented{
    border: 1px solid ${(props) => props.theme.colors.colorBorder};
}
    .ant-segmented-item{
        margin: 0 1px;
        &:hover{
            background-color: ${(props) => props.theme.colors.colorTextLightSolid} !important;
           
        }
    }
    .ant-segmented-item-selected{
        background-color: ${(props) => props.theme.colors.colorPrimary};
        &:hover{
            background-color: ${(props) => props.theme.colors.colorPrimary} !important;
           
        }
    }

`