import { useLocation } from 'react-router-dom';
import ContentCategoryCarousel from './carousel'
import { ContentCategoryStyle } from './style'
import { useEffect, useState } from 'react';
import { store } from "utils/store";

function ContentCategory() {
  const [headerType, setHeaderType] = useState('default');
  const location = useLocation();
  const handleHeaderTypeChange = () => {
    setHeaderType(store.getState().headerType)
  }
  useEffect(() => {
    const unsubscribe = store.subscribe(handleHeaderTypeChange)
    return () => {
      unsubscribe()
    }
  }, [])
  return (
  
      <ContentCategoryStyle headerType={headerType} id="content-category" isHome={location.pathname==='/home'}>
        <div className="container_">
          <div className="wrap">
            <ContentCategoryCarousel></ContentCategoryCarousel>
          </div>
        </div>
      </ContentCategoryStyle>
  
  )
}

export default ContentCategory