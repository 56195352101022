import { Avatar, Button, Popconfirm, Space, Tag, Tooltip, message } from "antd";
import { ReplyItemStyle } from "./reply-item-style";
import { copyToClipboard, extractPathnameAndSearchParams, findUserRole, getLocalUserInfo } from "utils/functions/common";
import { PlusOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  RiDeleteBinLine,
  RiEditBoxLine,
  RiHeartFill,
  RiHeartLine,
  RiMessage3Line,
  RiShareForwardLine,
  RiStarLine,
  RiStarFill,
  RiVideoLine,
} from "react-icons/ri";
import { ForumMineReplyListItem, ForumReplyListItem } from "api/forum/type";
import { useEffect, useState } from "react";
import { postFollowUser } from "api/user";
import { postLikeWork } from "api/common/favour";
import { apiDeleteForumReply, apiForumFavorite } from "api/forum";
import { canSuperManage, isMe } from "../fn";
import CommentWrap from "../common/comment";
import ReplyCreateAndUdate from "./reply-edit/reply-create-update";
import AnonymousUser from "../anonymous-user";

type Props = {
  listItem: ForumReplyListItem | ForumMineReplyListItem;
  onEdit: () => void;
  onListItemChange: (item: ForumReplyListItem | ForumMineReplyListItem) => void;
  onDeleteSuccess: (id: number) => void;
  onCommentCountChange: () => void;
  usedInOtherList?: boolean;
  onShowComment?: (show: boolean) => void;
  interactionMarginBottom?: number;
  showHotIcon?: boolean
};
function ReplyItem({
  listItem,
  onEdit,
  onListItemChange,
  onCommentCountChange,
  onDeleteSuccess,
  usedInOtherList = false,
  onShowComment,
  interactionMarginBottom = 14,
  showHotIcon
}: Props) {
  const [loading, setLoading] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showMore, setShowMore] = useState(Boolean(listItem.collapse));
  const [showEdit, setShowEdit] = useState(false);
  const localUser = getLocalUserInfo();
  useEffect(() => {
    setShowMore(Boolean(listItem.collapse));
  }, [listItem]);
  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
        return "yellow";

      case 3:
        return "blue";

      default:
        return "hide";
    }
  };

  const followUser = () => {
    if (listItem.userInfo) {
      setLoading(true);
      const flag = listItem.userInfo.isFollow === 1 ? 0 : 1;
      postFollowUser({
        followee: listItem.userId,
        flag,
      })
        .then(() => {
          listItem.userInfo.isFollow = flag;
          onListItemChange(listItem);
          message.success("操作成功");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleLike = () => {
    let flag = listItem?.isLike === 1 ? 0 : 1;
    postLikeWork({
      dataId: listItem.id,
      dataTable: "forum_reply",
      flag: flag,
    }).then(() => {
      listItem.isLike = flag;
      onListItemChange(listItem);
    });
  };

  const handleFavorites = () => {
    let flag = listItem?.isFavorite === 1 ? 0 : 1;
    apiForumFavorite({
      replyId: listItem.id,
    }).then(() => {
      listItem.isFavorite = flag;
      onListItemChange(listItem);
    });
  };

  const onDelete = (id: number) => {
    apiDeleteForumReply({ id }).then(() => {
      message.success("删除成功");
      onDeleteSuccess(id);
    });
  };

  const checkUserAvatar = (listItem: ForumReplyListItem) => {
    if (!usedInOtherList) {
      if (listItem.isAnonymity) {
        return <AnonymousUser size={38}></AnonymousUser>;
      } else {
        return (
          <div className="user">
            <div
              className="avatar"
              onClick={() => window.open(`/user/${listItem.userId}`)}
            >
              <Avatar src={listItem.userInfo.avatarUrl} size={38}></Avatar>
              {listItem.userInfo.certifiedRoleId && (
                <Tooltip
                  title={findUserRole(listItem.userInfo.certifiedRoleId)?.name}
                  placement="bottom"
                >
                  <div
                    className={`role ${renderColor(
                      findUserRole(listItem.userInfo.certifiedRoleId)?.value
                    )}`}
                  >
                    V
                  </div>
                </Tooltip>
              )}
              <div
                className="overflow-hidden user-name"
                title={listItem.userInfo.username}
                style={{ width: 300 }}
              >
                {listItem.userInfo.username}
              </div>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <ReplyItemStyle
      className="ReplyItemStyle"
      style={{
        borderBottom: usedInOtherList ? "none" : "1px solid #f0f0f0",
        padding: usedInOtherList ? "0" : "0 20px",
      }}
    >
      {listItem.topic && (
        <div className="topic">
          {listItem.topic.deleted === 1 && (
            <a
              href="#"
              className="title"
              style={{
                color: "rgb(185, 184, 184)",
                textDecoration: "line-through",
              }}
              onClick={(e) => e.preventDefault()}
            >
              {
                listItem.topic.isRecommend === 1 && <Tag className="tag" color="#F2731D">精</Tag>
              }
              {listItem.topic.title}-已删除
            </a>
          )}
          {listItem.topic.deleted === 0 && listItem.userId !== localUser.id && (
            <a href={`/forum/question/topic/${listItem?.topic?.id}?back=${extractPathnameAndSearchParams(window.location.href)}`} className="title">
              {
                listItem.topic.isRecommend === 1 && <Tag className="tag" color="#F2731D">精</Tag>
              }
              {listItem.topic.title}
            </a>
          )}
          {listItem.topic.deleted === 0 && listItem.userId === localUser.id && (
            <a href={`/forum/question/topic/${listItem?.topic?.id}?topMineId=${listItem?.id}&back=${extractPathnameAndSearchParams(window.location.href)}`} className="title">
              {
                listItem.topic.isRecommend === 1 && <Tag className="tag" color="#F2731D">精</Tag>
              }
              {listItem.topic.title}
            </a>
          )}
        </div>
      )}
      <div className="top" style={{ marginTop: usedInOtherList ? "0" : 14 }}>
        {checkUserAvatar(listItem)}
        <div className="action">
          {isMe() && (
            <Button
              icon={listItem?.userInfo?.isFollow === 1 ? "" : <PlusOutlined />}
              style={{ padding: "2px 10px", height: 32 }}
              onClick={() => followUser()}
              loading={loading}
            >
              {listItem?.userInfo?.isFollow === 1 ? "已关注" : "关注"}
            </Button>
          )}
        </div>
      </div>
      {showMore && (
        <>
        <div className="brief-reply" >
          {showHotIcon && showMore && <Tag color="orange">热评</Tag>}

          {listItem?.coverUrl && listItem.coverUrlType === 1 && (
            <div
              className="thumbnail"
              style={{ backgroundImage: `url(${listItem?.coverUrl})`, transform: showHotIcon ? 'translate(-10px)' : 'none' }}

              onClick={() => {
                setShowMore(false)
              }}
            ></div>
          )}
          {listItem?.coverUrl && listItem.coverUrlType === 2 && (

            <div
              className="thumbnail video"
              style={{ backgroundColor: '#000', transform: showHotIcon ? 'translate(-10px)' : 'none' }}
              onClick={() => {
                setShowMore(false)
              }}
            >
              <RiVideoLine style={{ color: '#fff' }}></RiVideoLine>
            </div>
          )}

          <div className={"brief-content"} style={{ paddingTop: showHotIcon ? 0 : 8 }}>

            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: listItem.contentWords }}
            ></div>
          </div>
          

        </div>
        <div className="more-text" onClick={(e) => {
          e.stopPropagation()
          setShowMore(false)
        }}>
          阅读全文<DownOutlined />
        </div>
        </>

      )}

      {!showMore && (
        <>
        <div className="content-body">
          {showHotIcon && !Boolean(listItem.collapse) && <Tag color="orange">热评</Tag>}
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: listItem.content }}
          ></div>

         
        </div>
         {Boolean(listItem.collapse) && (
          <span className="more-text-0" onClick={(e) => {
            e.stopPropagation();
            setShowMore(true)
          }}>
            收起 <UpOutlined />
          </span>
        )}
        </>
      )}
      {!usedInOtherList && (
        <Tooltip title={`创建于 ${listItem.submitTime}`}>
          <span className="edit-time">编辑于 {listItem.lastSubmitTime}</span>
        </Tooltip>
      )}

      <div className="interaction" style={{ marginBottom: interactionMarginBottom }} onClick={(e) => { e.stopPropagation() }}>
        <Space size="large">
          <div
            className="icon-btn"
            onClick={(e) => {
              e.stopPropagation()
              setShowComment(!showComment);
              onShowComment && onShowComment(!showComment);
            }}
          >
            <RiMessage3Line style={{ fontSize: 16 }}></RiMessage3Line>{" "}
            <span>{listItem.commentCount}条评论</span>
          </div>
          <div
            className="icon-btn"
            onClick={(e) => {
              e.stopPropagation()
              copyToClipboard(window.location.protocol + '//' + window.location.hostname + `/forum/question/topic/${listItem.topicId}?topMineId=${listItem.id}`);
              message.success("分享链接已复制！");
            }}
          >
            <RiShareForwardLine style={{ fontSize: 16 }}></RiShareForwardLine>{" "}
            <span>分享</span>
          </div>
          {/* <div className="icon-btn" onClick={(e) => { e.stopPropagation(); handleFavorites() }}>
            {listItem?.isFavorite ? (
              <RiStarFill style={{ fontSize: 16 }}></RiStarFill>
            ) : (
              <RiStarLine style={{ fontSize: 16 }}></RiStarLine>
            )}
            <span>{listItem?.isFavorite ? "取消收藏" : "收藏"}</span>
          </div> */}
          <div className="icon-btn" onClick={(e) => { e.stopPropagation(); handleLike() }}>
            {listItem?.isLike ? (
              <RiHeartFill style={{ fontSize: 16 }}></RiHeartFill>
            ) : (
              <RiHeartLine style={{ fontSize: 16 }}></RiHeartLine>
            )}
            <span>{listItem?.isLike ? "取消点赞" : "点赞"}</span>
          </div>
          {isMe(listItem.userId) && (
            <div className="icon-btn" onClick={(e) => { e.stopPropagation(); setShowEdit(true) }}>
              <RiEditBoxLine style={{ fontSize: 16 }}></RiEditBoxLine>{" "}
              <span>编辑</span>
            </div>
          )}
          {(isMe(listItem.userId) || canSuperManage()) && (
            <Popconfirm
              title="确认删除吗？"
              onConfirm={() => onDelete(listItem.id)}
              okText="确认"
              cancelText="取消"
            >
              <div className="icon-btn">
                <RiDeleteBinLine style={{ fontSize: 16 }}></RiDeleteBinLine>
                <span>删除</span>
              </div>
            </Popconfirm>
          )}
        </Space>
      </div>
      {showComment && (
        <CommentWrap
          dataId={listItem.id}
          anonymity={true}
          onCountChange={() => {
            onCommentCountChange();
          }}
        ></CommentWrap>
      )}

      <ReplyCreateAndUdate
        isModalOpen={showEdit}
        handleOk={() => {
          onEdit();
          setShowEdit(false);
        }}
        handleCancel={() => {
          setShowEdit(false);
        }}
        topicId={listItem.topicId}
        replyId={listItem.id}
        html={listItem.content}
        isAnonymity_={listItem.isAnonymity}
      ></ReplyCreateAndUdate>
    </ReplyItemStyle>
  );
}

export default ReplyItem;
