import styled from "styled-components";

export const CeremonySwiperStyle = styled.div`
    .wrap {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        &:hover {
            .arrow-wrap {
                display: block;
            }
        }
        .arrow-wrap {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
            background-color: rgba(0,0,0,.5);
            border-radius: 50%;
            cursor: pointer;
            text-align: center;
            display: none;
        }
        .arrow {
            width: 13px;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
        }
        .arrow-left {
            left: 30px;
            .arrow {
              left: 46%;
            }
        }
        .arrow-right {
            right: 30px;
            .arrow {
              left: 56%;
            }
        }
    }
    .keen-slider {
        margin-bottom: 30px;
        width: 100%;
        .keen-slider__slide {
            cursor: pointer;
        }
        .more {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .cover {
            aspect-ratio: 450 / 250;
            background-size: cover;
            background-position: center;
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .btn {
        width: 140px;
	    height: 34px;
	    border-radius: 17px;
	    border: solid 1px #000;
        font-size: 16px;
        margin: 0 auto 30px;
	    color: #000;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
    }
`