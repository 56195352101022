import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import ArticleUpdate from "views/article/update/index";
import {ArticleDetail} from "views/article/detail";
import { ArticlePreview } from "views/article/preview";
import ArticleList from "views/article/list";

export const articleRoutes:RouteObject[] = [
    {
        path: '/article',
        element: <Layout></Layout>,
        children:[
            {
                path: 'detail/:id',
                element: <ArticleDetail></ArticleDetail>
            },
            {
                path: "update/:id",
                element: <ArticleUpdate></ArticleUpdate>,
            },
            {
                path: "create",
                element: <ArticleUpdate></ArticleUpdate>,
            },
            {
                path: "preview/:id",
                element: <ArticlePreview></ArticlePreview>,
            },
            {
                path: "list",
                element: <ArticleList></ArticleList>,
            },
            
        ]
    }
]