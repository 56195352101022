import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const KeywordSearchStyle = styled.div`
    .guanggao-banner-wrapper{
      padding: ${(props) => props.theme.space.paddingSM}px 0;
    }
    .search-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: 1100px;
    }
    .tab-name {
        text-align: center;
        color: #8f8f8f;
        font-size: 16px;
        padding: 0 10px;
    }
    .ant-tabs-tab-active {
        .tab-name {
          color: ${(props) => props.theme.colors.colorText} !important;
        }
    }
    .ant-tabs-tab {
        position: relative;
        margin: 0 ${props => props.theme.space.margin}px !important;
    }
    .ant-tabs-tab::after {
        content: "";
        width: 100%;
        position: absolute;
        background-color: ${props => props.theme.colors.colorPrimary};
        height: 0;
        bottom: 0;
        left: 0;
    }
    .ant-tabs-tab:hover {
        &::after {
            height: 2px !important;
        }
        .tab-name {
            color: ${(props) => props.theme.colors.colorText} !important;
        }
    }
    .search-keyword-container{
        padding: 0 ${props => props.theme.space.paddingLG}px;
        height: 100vh;
        overflow: auto;
        position: relative;
        overflow-x:hidden ;
        &::before {
            content: "";
            display: block;
            width: 100vw;
            height: 50px;
            background-color: #232424 !important;
            transform: translateX(-24px);
         }
        .search-wrapper{
            margin: ${props => 3 * props.theme.space.margin}px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            max-width: 548px;
            /* height: 40px; */
            .ant-input-affix-wrapper {
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;
            }
            .ant-input-group-addon {
                button {
                    border-top-right-radius: 100px !important;
                    border-bottom-right-radius: 100px !important;
                }
                
            }
        }
        .tabs-wrapper{
            .ant-tabs-nav{
                margin-bottom: 0;
            }
        }
        .filter-wrapper{
            width: 100%;
            display: flex;
            margin: ${(props) => props.theme.space.paddingLG}px 0;
            .ant-select{
                margin-left: ${(props) => props.theme.space.margin}px;
            }
        }
        /* .list {
            display: grid;
            --auto-grid-min-size: 19rem;
            grid-template-columns: repeat(
              auto-fill,
              minmax(var(--auto-grid-min-size), 1fr)
            );
            gap: 1rem;
        } */
        .list {
            display: grid;
            --auto-grid-min-size: 19rem;
            /* grid-template-columns: repeat(
              auto-fill,
              minmax(var(--auto-grid-min-size), 1fr)
            ); */
            grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
            gap: 1rem;
            padding-bottom: ${props => props.theme.space.paddingLG}px;
        }
        .list-wrapper{
            /* .infinite-scroll-component  {
                display: grid;
                --auto-grid-min-size: 19rem;
                grid-template-columns: repeat(
                  auto-fill,
                  minmax(var(--auto-grid-min-size), 1fr)
                );
                gap: 1rem;
                .list-item{
                    min-width: 200px;
                }
            } */
            .infinite-scroll-image{
                display: block;
            }
        }
      }
`;
