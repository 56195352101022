import styled from "styled-components";


export const BadgeLeftStyle = styled.div`
    .left {
        width: 300px;
        /* height: 100px; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        .tab {
            display: flex;
            justify-content: center;
            gap: 15px;
            margin-bottom: 15px;
            &-item {
                color: #524f4f;
            }
            .active {
                color: #000 !important;
                font-weight: bold;
            }
        }

        .carousel-wrap {
            position: relative;
        }

        .carousel {
            width: 200px;
            position: relative;
        }

        .left-arrow {
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            left: -40px;
            cursor: pointer;
            padding: ${props => props.theme.space.paddingXS}px;
        }

        .right-arrow {
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            right: -40px;
            cursor: pointer;
            padding: ${props => props.theme.space.paddingXS}px;
        }
        

        .carousel-content {
            width: 200px;
            text-align: center;
        }
        .switch-wrap {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
        }

        .no-data-wrap {
            display: flex;
            align-items: center;
            height: calc(100% - 30px);
        }
    }
`