import styled from "styled-components";

export const ArticleWorkStyle = styled.div`
  .container {
    background: ${(props) => props.theme.colors.colorBgContainer};
    background-color: #f8f8f8;
    width: 100%;
    aspect-ratio: 3/4;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    cursor: pointer;
    .top-wrapper {
      width: 100%;
     aspect-ratio: 1;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: ${(props) => props.theme.border.borderRadius}px ${(props) => props.theme.border.borderRadius}px 0 0;
    }
    .middle-wrapper {
      padding: ${(props) => props.theme.space.paddingXS}px ${(props) => props.theme.space.paddingSM}px;

      
      .middle-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: ${(props) => props.theme.space.marginXS}px;

        .title {
          flex: 1;
          word-break: break-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          width: calc(100% - 50px);
          color: ${(props) => props.theme.colors.colorText};
         
        }
        .type {
          color: #b3b4ba;
          text-align: right;
          flex-basis: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .middle-bottom {
        margin-top: ${(props) => props.theme.space.margin}px;
        border-top: 1px solid #e0e0e0;
        padding-top:${(props) => props.theme.space.paddingXS}px;

        .userinfo {
          padding-bottom: ${(props) => props.theme.space.padding}px;
          display: flex;
          align-items: center;
          .username {
            /* width: 90px; */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: ${(props) => props.theme.space.marginXXS}px;
          }
        }
      }
      
    }
  }
`;
