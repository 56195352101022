import styled from "styled-components";

export const CardTitleStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    a{
        display: inline-block;
        max-width: 15rem;
    }
`