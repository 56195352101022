import { RouteObject } from "react-router-dom";
import { AdminLogin } from "views/admin/login";





export const adminRoutes: RouteObject[] = [
    {
        path: '/admin-login',
        element: <AdminLogin></AdminLogin>,
        
    }
]