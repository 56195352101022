import { Button, Modal, Pagination, message } from 'antd'
import { RiAddLine, RiErrorWarningLine } from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'
import UISegmentedControl from 'ui-lib/segmented-control'
import { NftPublishStyle } from './style'
import CommonTabs from 'ui-lib/tab/common-tab'
import { useCallback, useEffect, useState } from 'react'
import {
  apiGetSallerNftAlbumList,
  apiPostshelvesSallerNftAlbum,
} from 'api/nft-album'
import {
  SellerNftAlbumListItem,
  SellerNftAlbumListRequest,
} from 'types/nft-album'
import { queryUrl } from 'utils/functions/common'
import { NoData } from 'components/no-data'

function NftPublish() {
  const navigate = useNavigate()
  const location = useLocation()
  let state = Number(
    new URLSearchParams(window.location.search).get('state') || ''
  )
  let pageNumber = Number(
    new URLSearchParams(window.location.search).get('pageNumber') || 1
  )
  let keyword = new URLSearchParams(window.location.search).get('keyword') || ''

  const [searchValue, setSearchValue] = useState('')

  const [list, setList] = useState<SellerNftAlbumListItem[]>([])

  const pageSize = 10

  const [total, setTotal] = useState(0)

  const tabOption = [
    {
      label: '图鉴收藏',
      value: 'nft-album',
    },
    {
      label: '我发布的',
      value: 'tj-publish',
    },
    {
      label: '我卖出的',
      value: 'tj-sold',
    },
  ]
  const changeTab = (e: any) => {
    navigate('/user-center/' + e)
  }

  const getList = useCallback(() => {
    const params: SellerNftAlbumListRequest = { pageNumber, keyword, pageSize }

    if (state) {
      params.uiStatus = state
    } else {
      delete params.uiStatus
    }

    apiGetSallerNftAlbumList(params).then((res) => {
      setSearchValue(keyword)
      setList(res.pageData)
      setTotal(res.totalSize)
    })
  }, [state, pageNumber, keyword])

  useEffect(() => {
    getList()
  }, [getList])

  const statusTabs: { key: number; title: string }[] = [
    {
      key: 0,
      title: '全部',
    },
    {
      key: 1,
      title: '发售中',
    },
    {
      key: 2,
      title: '审核中',
    },
    {
      key: 3,
      title: '未上架',
    },
  ]

  const checkTab = (detail: { key: number | string; title: string }) => {
    const query = queryUrl({
      pageNumber: 1,
      state: Number(detail.key) === 0 ? '' : detail.key,
    })
    navigate(`?${query}`)
  }

  const getSecondPathSeg = () => {
    const regex = /\/user-center\/([^?/]+)/
    const matches = location.pathname.match(regex)
    if (matches && matches.length > 1) {
      return matches[1]
    } else {
      return ''
    }
  }

  const onSearch = (e: string) => {
    const query = queryUrl({ pageNumber: 1, keyword: e })
    navigate(`?${query}`)
  }

  // 展示驳回原因
  const refuse = (title: string, value: string) => {
    Modal.confirm({
      title: title,
      content: value,
      okText: '关闭',
      cancelButtonProps: { style: { display: 'none' } },
      cancelText: '',
      centered: true,
    })
  }

  const changePage = (detail: any) => {
    const query = queryUrl({ pageNumber: detail })
    window.scrollTo({
      top: 0,
    })
    navigate(`?${query}`)
  }

  const editAlbum = (detail: SellerNftAlbumListItem) => {
    navigate(`/user-center/tj-edit?id=${detail.id}`)
    window.scrollTo({
      top: 0,
    })
  }

  const shelves = (detail: SellerNftAlbumListItem) => {
    apiPostshelvesSallerNftAlbum(detail.id).then(() => {
      getList()
      message.success('操作成功')
    })
  }

  return (
    <NftPublishStyle>
      <UISegmentedControl
        onValueChange={changeTab}
        options={tabOption}
        value={getSecondPathSeg()}
      ></UISegmentedControl>

      <div className="main">
        <div className="main-top">
          <CommonTabs
            tabs={statusTabs}
            defaultKey={Number(state)}
            onClick={(item) => checkTab(item)}
          ></CommonTabs>

          <div className="right">
            {/* <Search
            placeholder="请输入关键字"
            allowClear
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(e) => onSearch(e)}
          /> */}
            <Button
              type="primary"
              className="btn"
              onClick={() => {
                navigate('/user-center/tj-edit')
              }}
              icon={<RiAddLine size={18} />}
            >
              发布图鉴
            </Button>
          </div>
        </div>
        <div className="main-list">
          <div className="heading">
            <span>图鉴信息</span>
            <span>图鉴价格</span>
            <span>发行数量</span>
            <span>销量</span>
            <span>发售起止时间</span>
            <span>操作</span>
          </div>
          <div className="list">
            <div className="list-item">
              {list.length === 0 && <NoData />}
              {list.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className="row" key={item.id}>
                      <div className="row-cell">
                        {item.onSale === 1 && (
                          <div
                            className="cell-card on-sale"
                            onClick={() =>
                              message.warning('发布后请到移动端查看')
                            }
                          >
                            <img
                              className="thumbnail"
                              src={item.coverUrl}
                              alt=""
                            />
                            <div className="title_">{item.name}</div>
                          </div>
                        )}

                        {item.onSale !== 1 && (
                          <div className="cell-card">
                            <span>
                              <img
                                className="thumbnail"
                                src={item.coverUrl}
                                alt=""
                              />
                            </span>
                            <div className="title_">{item.name}</div>
                          </div>
                        )}
                      </div>
                      <div className="row-cell">
                        ￥{(item.rmbPrice / 100).toFixed(2)}
                      </div>

                      <div className="row-cell">{item.limitedQuantity}</div>
                      <div className="row-cell">{item.sold}</div>
                      <div className="row-cell">
                        {item.startTime} - {item.endTime}
                      </div>
                      <div className="row-cell btns">
                        {((item.auditStatus === 3 &&
                          item.onSale === 0 &&
                          item.state === 1) ||
                          item.auditStatus === 4) && (
                          <Button
                            type="primary"
                            onClick={() => editAlbum(item)}
                          >
                            编辑
                          </Button>
                        )}
                        {item.onSale === 1 && (
                          <Button type="primary" onClick={() => shelves(item)}>
                            下架
                          </Button>
                        )}
                        {item.auditStatus === 4 && (
                          <Button
                            type="dashed"
                            onClick={() =>
                              refuse('驳回原因', item.refuseReason)
                            }
                            className="refuse"
                          >
                            <RiErrorWarningLine className="icon"></RiErrorWarningLine>{' '}
                            <span>驳回原因</span>
                          </Button>
                        )}

                        {item.onSale === 0 && item.auditStatus === 3 && (
                          <>
                            <Button
                              type="primary"
                              onClick={() => shelves(item)}
                            >
                              上架
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <Pagination
        style={{ textAlign: 'center', margin: '24px 0 0 0' }}
        hideOnSinglePage={true}
        current={Number(pageNumber)}
        onChange={changePage}
        total={total}
        pageSize={pageSize}
        showSizeChanger={false}
      />
    </NftPublishStyle>
  )
}

export default NftPublish
