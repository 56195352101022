
import { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce';

function useWindowSize() {
    const [screenSize, setScreenSize] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
        dynamicColumns: getColumns()
      });
      const setDimension = useDebouncedCallback(() => {
        setScreenSize({
          dynamicColumns: getColumns(),
          dynamicWidth: window.innerWidth,
          dynamicHeight: window.innerHeight,
        })
      },200)

      function getColumns(){
        const width = window.innerWidth;
       
        if(width<=1920){
          return 5
        }
        if(width>1920){
          return 6
        }
      
        return 5
      }
      
      
      useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
      }, [screenSize])
  return [screenSize]
}

export default useWindowSize