import { Segmented, ConfigProvider } from "antd";
import { UiAvatarStyle } from "./style";
import { SegmentedLabeledOption, SegmentedValue } from "antd/es/segmented";

type Props ={
  onValueChange:(value:SegmentedValue)=>void,
  options:((string | number) | SegmentedLabeledOption)[],
  value: SegmentedValue,
}

function UISegmentedControl({onValueChange,options,value}:Props) {
  const OnChange = (val:SegmentedValue)=>{
    onValueChange(val)
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            colorBgLayout: "#fff",
            borderRadius:10,
            borderRadiusSM:8,
            controlHeight:40,
            controlPaddingHorizontal:22,
          },
        },
      }}
    >
      <UiAvatarStyle>
        <Segmented
          options={options}
          value={value}
          onChange={(val)=>OnChange(val)}
        />
      </UiAvatarStyle>
    </ConfigProvider>
  );
}

export default UISegmentedControl;
