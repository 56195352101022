import { useCallback, useEffect, useState } from "react"
import { ReadDiliverStyle } from "./style"
import { ForumOrderDeliveryDocuments, ForumOrderDetail } from "api/forum/type"
import { getRewardFileList } from "api/forum"
import { RiDownload2Line, RiFileCopy2Line } from "react-icons/ri"
import { Image } from "antd"

const ReadDiliver = ({ orderNumber }: { orderNumber: string }) => {

    const [orderDetail, setOrderDetail] = useState<ForumOrderDeliveryDocuments>()

    const getDetail = useCallback(() => {
        if (!orderNumber) {
            return
        }
        getRewardFileList(orderNumber).then(res => {
            setOrderDetail(res)
        })
    }, [orderNumber])

    useEffect(() => {
        getDetail()
    }, [getDetail])

    return <ReadDiliverStyle>

        <div className="detail">
            {
                orderDetail?.content && <div className="detail-item">
                    <div className="label">回答描述：</div>
                    <div className="value">
                        {orderDetail.content}
                    </div>
                </div>
            }
            {
                orderDetail?.imageList && orderDetail?.imageList.length > 0 && <div className="detail-item">
                    <div className="label">图片列表：</div>
                    <div className="value image-wrap">
                        <div className="image">
                            {
                                orderDetail?.imageList.map(item => {
                                    return <Image src={item} className="image-item" key={item} />
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                orderDetail?.fileList && orderDetail?.fileList.length > 0 && <div className="detail-item">
                    <div className="label">文件列表：</div>
                    <div className="value image-wrap">
                        <div className="image">
                            {
                                orderDetail?.fileList.map(item => {
                                    return <>
                                        {
                                            ['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <Image src={item} className="image-item" key={item} />
                                        }
                                        {
                                            !['jpeg', 'jpg', 'gif', 'png', 'bmp'].includes(item.substring(item.lastIndexOf(".") + 1)) && <div className="file-item">
                                                <RiFileCopy2Line size={30} />
                                                <div className="block">
                                                    <RiDownload2Line onClick={() => window.open(item)} color="#fff" size={16} />
                                                    <div className="word" onClick={() => window.open(item)}>下载文件</div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                orderDetail?.fileSupplement && <div className="detail-item">
                    <div className="label">源文件链接：</div>
                    <div className="value">{orderDetail.fileSupplement}</div>
                </div>
            }
        </div>
    </ReadDiliverStyle>
}

export default ReadDiliver