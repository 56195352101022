import i18next from "i18next"



export enum NotificationType {
    all = 0,
    system = 1,
    like = 2,
    comment = 3,
    follow = 4,
    officialNotification = 11,
    shop = 6,
    forum= 7
}

export const NotificationTypeArr = () =>{
    return  [
    { label: i18next.t('notification.type.all'), value: NotificationType.all },
    { label: i18next.t('notification.type.system'), value: NotificationType.system },
    { label: i18next.t('notification.type.favour'), value: NotificationType.like },
    { label: i18next.t('notification.type.comment'), value: NotificationType.comment },
    { label: i18next.t('notification.type.subscribe'), value: NotificationType.follow },
    { label: i18next.t('notification.type.shop'), value: NotificationType.shop },
    { label: i18next.t('notification.type.forum'), value: NotificationType.forum },
    { label: i18next.t('notification.type.officialNotification'), value: NotificationType.officialNotification },
   
]
}



