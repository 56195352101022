import styled from "styled-components";

export const AddAlbumStyle = styled.div`
    padding: ${props => props.theme.space.paddingSM}px;
    .title {
        text-align: center;
        font-weight: bold;
        margin-bottom: ${props => props.theme.space.margin}px;
        font-size: ${props => props.theme.fontSizes.fontSizeLG}px;
        position: relative;
        .icon {
            position: absolute;
            right: -24px;
            top: -5px;
            transform: translateY(-50%);
            cursor: pointer;
            font-size: 20px;
        }
    }
    input {
        width: 432px;
        display: block;
        margin:0 auto ${props => props.theme.space.margin}px;
    }

    .list {
        overflow-y: auto;
        max-height: 300px;
    }
    
    .album-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${props => props.theme.space.margin}px;
        padding: 0 ${props=>props.theme.space.paddingXS}px;
        .left {
            display: flex;
            align-items: center;
            .cover {
                width: 40px;
                height: 40px;
                background-color: rgb(204, 204, 204);
                border-radius: 3px;
                margin-right: ${props => props.theme.space.marginXS}px;
                background-size: cover;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`