import styled from "styled-components";

export const GuanggaoBannerStyle = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  &:hover {
    .control {
      display: flex;
    }
  }
  .keen-slider{
    border-radius: ${(props) => props.theme.border.borderRadius}px !important;
    overflow: hidden;
    width: 100%;
  }
  .keen-slider__slide {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    width: 100%;
    aspect-ratio: 10/0.8;
    max-height: 180px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${(props) => props.theme.border.borderRadius}px !important;
    overflow: hidden;
    cursor: pointer;
    img{
      object-fit: cover;
    width: 100%;
    border-radius: ${(props) => props.theme.border.borderRadius}px !important;
    }
  }
  .control {
    font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
    border-radius: 50% 50%;
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.colorTextLightSolid};
    box-shadow: ${(props) => props.theme.shadow.boxShadowSecondary};
    display: none;
    &:hover {
      box-shadow: ${(props) => props.theme.shadow.boxShadow};
    }

    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }
    &.prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }
  }
`;
