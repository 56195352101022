import styled from "styled-components";

export const SelectAnswererStyle = styled.div`
    .content {
        max-height: 300px;
        overflow: auto;

        .content-title {
            margin: 20px 0 20px 0;
            font-size: 13px;
            display: flex;
            justify-content: space-between;
            span {
                color: #94969f;
            }
        }

        .ant-radio-group {
            width: 100%;
            font-size: 13px;
        }

        .ant-radio {
            width: 16px;
            height: 16px;
            .ant-radio-inner,input {
                width: 100% !important;
                height: 100%;
            }
        }

        .ant-radio-wrapper-disabled {
            color: rgba(0, 0, 0, 0.88);
        }

        .ant-radio-wrapper {
            width: 100%;
            margin-right: 0 ;
            span:last-child {
                display: block;
                width: 96%;
                padding-right: 0 !important;
            }
        }

        .word {
            margin: 20px 0;
            span {
                color: #94969f;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }

            .user {
                background-color: #f6f8fb;
                padding: 10px 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                &-info {
                    display: flex;
                    align-items: center;
                    min-width: 0;
                    flex: 1;
                    gap: 15px;
                    
                    .user-avatar {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-size: cover;
                    }
                    .user-name {
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .user-introduce {
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #94969f;
                    }
                }
                .btn {
                    border: 1px solid ${props => props.theme.colors.colorPrimary};
                    padding: 5px 12px;
                    border-radius: 50px;
                    color: ${props => props.theme.colors.colorPrimary};
                    font-size: 12px;
                    flex: 0 0 auto;
                }
                .fail {
                  background-color: #f4f4f4 !important;
                  color: #94969f !important;
                }
            }
        }
    }

    .btns {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }
`