import Comments from "components/comments";
import WorkContent from "./left/workContent";
import Ad from "./right/ad";
import Interaction from "./right/interaction";
import Recommandation from "./right/recommandation";
import Tags from "./right/tags";
import WorkInfo from "./right/workInfo";
import { WorkDetailStyle } from "./style";
import { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { apiDraftWorkDetail, getWorkDetail } from "api/work";
import { useNavigate, useParams } from 'react-router-dom';
import { InteractionType } from "./detailType";
import { FloatButton, Spin } from "antd";
import UserCardGlobal from "components/userBlock";
import WorkDetailNav from "./left/nav";
import { isMobile } from "utils/functions/common";
import PerfectScrollbar from 'perfect-scrollbar';

// window.addEventListener('scroll', (e)=>{
//   console.log(e)
// });

function WorkPreDetail() {
  const commentRef = useRef() as React.MutableRefObject<any>;
  const navigate = useNavigate()
  // 调用commet创建方法
  // const createComment = (val: CommentListItem) => {
  //   commentRef.current?.addComment(val)
  // }

  const { id } = useParams<any>();
  // const [showReport, setShowReport] = useState(false) // 举报弹窗
  const [commentCount, setCommentCount] = useState<number>(0)

  const [detail, setDetail] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [hasPublished, setHasPublished] = useState(false)

  const selectRef = useRef(null)

  const updateLoading = (value: boolean) => {
    setLoading(value)
  }



  useEffect(() => {

    const ps = new
      PerfectScrollbar('#scroll-right', {
        wheelPropagation: true,
        minScrollbarLength: 20,
        suppressScrollX: true,
        wheelSpeed: 1,
      });


    const checkIsMobile = isMobile()
    if (checkIsMobile) {
      window.location.href = `https://m.ggac.com` + window.location.pathname
      return
    }
  }, [])

  const getDetail = useCallback(() => {

    apiDraftWorkDetail(Number(id)).then(draftRes => {
      setHasPublished(false)
      setDetail(Object.assign(draftRes, {
        orderType: 1, isRecommend: 0, isLike: 0, qrCode: '', actionCount: {
          threeDaysHot: 0,
          hotLevel: 0,
          recommendLevel: 0,
          likeCount: 0,
          viewCount: 0,
          hot: 0,
          commentCount: 0,
          favoriteCount: 0,
        },
        publishTime: '',
        publishType: 0,
        isFavorite: 0,
        shareImageUrl: '',
        lastSubmitTime: '',
        lastPublishTime: ''
      }))
    })
  }, [id])

  const updateCommentCount = () => {
    getWorkDetail(Number(id)).then(res => {
      setCommentCount(res.actionCount.commentCount)
    })
  }

  const defaultInteraction = useMemo(() => {
    return {
      isLike: detail?.isLike!,
      isFavorite: detail?.isFavorite!,
      actionCount: {
        likeCount: detail?.actionCount?.likeCount!,
        favoriteCount: detail?.actionCount?.favoriteCount!,
      }
    }
  }, [detail])

  const updateInteraction = (value: InteractionType) => {
    getDetail()
  }

  // 收藏成功后修改收藏状态
  const updateFavoriteStatus = () => {
    getDetail()
  }

  // // 举报作品
  // const reportWork = () => {
  //   setShowReport(true)
  // }

  // const onCloseReport = () => {
  //   setShowReport(false)
  // }

  // const toCommentPostion = () => {
  //   const offsetTop = document.getElementById('input-wrap')?.offsetTop!
  //   const container = document.querySelector('#scroll-right');
  //   container!.scrollTop = offsetTop - 50;

  // }



  const onClickToTop = () => {

    const container = document.querySelector('#scroll-right');
    container!.scrollTop = 0;

    // container!.scrollTop = offsetTop - 50;
  }





  useEffect(() => {
    getDetail()
  }, [getDetail])

  return (
    <WorkDetailStyle id="detail-work" >
      <Spin spinning={loading}>
        <div className="main--container">
          <div className="left">
            <WorkDetailNav id={Number(id)}></WorkDetailNav>
            {detail && <WorkContent detail={detail}></WorkContent>}
          </div>
          <div className="right" ref={selectRef} id="scroll-right" >
            <div className="section-block">
              {detail && <UserCardGlobal userId={detail?.userId}></UserCardGlobal>}


              {detail && hasPublished && <Interaction updateLoading={updateLoading} detail={detail} defaultInteraction={defaultInteraction} update={updateInteraction} updateFavoriteStatus={updateFavoriteStatus}></Interaction>}

              {/* {determineSuperaction() && detail && hasPublished && <SuperInteraction detail={detail} updateDetail={(detail) => { setDetail(detail) }}></SuperInteraction>} */}
              {detail && hasPublished && <WorkInfo  {...detail}></WorkInfo>}
              <Tags tagList={detail?.tagList!} hardwareTagList={detail?.hardwareList!} softwareTagList={detail?.softwareList!}></Tags>
              <Ad></Ad>
              {!detail?.isContest && <Recommandation detail={detail!}></Recommandation>}
            </div>

            {/* {hasPublished && detail && <div className="section-block">
              <div className="container" style={{ pointerEvents: 'none' }}>
                <Comments ref={commentRef} params={{ commentCount, updateCommentCount, type: 'work', id: detail.twinId }}></Comments>
              </div>
            </div>} */}
            {/* {hasPublished&&<div className="report" onClick={() => reportWork()}>
              <RiAlarmWarningLine size={18}></RiAlarmWarningLine>
              <span>举报</span>
            </div>} */}
          </div>
        </div>
      </Spin>
      {/* {showReport && (
        <ReportModal
          show={showReport}
          close={onCloseReport}
          params={{ dataId: Number(detail?.id), dataTable: "work" }}
        ></ReportModal>
      )} */}
      <FloatButton.Group shape="circle" className="float-button">
        <FloatButton.BackTop tooltip="回到顶部" onClick={onClickToTop} />
        {/* <FloatButton tooltip="前往评论" onClick={toCommentPostion} icon={<RiMessageFill />} /> */}
      </FloatButton.Group>
    </WorkDetailStyle >
  );
}

export default WorkPreDetail;
