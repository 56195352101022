import { UpdatePasswordStyle } from "./style"
import { Modal, Form, Input, Button, message } from 'antd'
import { useState } from 'react'
import SendCode from '../sendCode'
import { apiUpdatePassword } from "api/user"
import { PwdRegex } from "config/common"
type appendixModalType = {
    params: {
        visible: boolean,
        visibleChange: (visible: boolean, needRefesh?: boolean) => void,
        hasPassword?: boolean,
        mobile: string,
        UpdateSuccess: () => void,
    }
}

const UpdatePassword = (props: appendixModalType) => {
    const [form] = Form.useForm<any>();
    const { params } = props
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const onCancel = () => {
        params.visibleChange(false)
        form.setFieldsValue({
            password: "",
            secondPassword: "",
            verCode: ""
        })
    }
    const submit = () => {
        form.validateFields().then(async res => {
            setIsLoading(true)
            apiUpdatePassword({
                password: res.password,
                verCode: res.verCode
            }).then(res => {
                message.success(`${params.hasPassword ? '修改成功' : "设置成功"}`)
                onCancel()
                params.UpdateSuccess()
            }).finally(() => {
                setIsLoading(false)
            })
        })
    }
    return <UpdatePasswordStyle>
        <div className="password">
            <Modal className='modal' destroyOnClose={true} title={`${params.hasPassword ? '修改密码' : '设置密码'}`} centered={true} footer={null} getContainer={false} onCancel={onCancel} open={params.visible} width={420}>
                <Form form={form} className="form">
                    <Form.Item name='password' label="新的密码" rules={[
                        {
                            required: true,
                            message: "密码不能为空"
                        },
                        {
                            pattern: PwdRegex,
                            message: "密码必须包含字母、数字，长度为6-18位字符"
                        }
                    ]}>
                        <Input.Password max={18} placeholder="请输入您要设置的密码" />
                    </Form.Item>
                    <Form.Item name='secondPassword' label="确认密码" rules={[
                        {
                            required: true,
                            message: "请和新密码保持一致"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('您输入的两个密码不匹配'));
                            },
                        }),
                    ]}>
                        <Input.Password max={18} placeholder="请再次输入您要设置的密码" />
                    </Form.Item>
                    <Form.Item
                        name="verCode"
                        label="验证码"
                        rules={[
                            {
                                required: true,
                                message: "请填写验证码",
                            },
                            {
                                pattern: /^\d{4}$/,
                                message: "验证码为4位数字",
                            },
                        ]}
                    >
                        <div className="code">
                            <Input maxLength={4} placeholder="请输入短信验证码" />
                            <SendCode value={params.mobile} />
                        </div>
                    </Form.Item>
                    <div className="btn"><Button block type="primary" loading={isLoading} onClick={() => submit()}>提交</Button></div>
                </Form>
            </Modal>
        </div>
    </UpdatePasswordStyle>
}

export default UpdatePassword