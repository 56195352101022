import { Badge } from "antd";
import { RiChat1Line } from "react-icons/ri";
import { ChatIconStyle } from "./style";
import { ChatRoom } from "components/chat";
import { useEffect, useState } from "react";
import { apiGetImUnreadCount } from "api/im";
import { useSelector } from "react-redux";
import { logoutChat } from "utils/functions/chat";


function ChatIcon() {
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const unReadCountUpdated = useSelector((state: any) => {
    return state.im.unreadUpdate;
  });

  const handleCloseModal = () => {
    setChatModalOpen(false);
    apiGetImUnreadCount().then((res) => {
      setUnreadCount(res.count);
    });
  };

  const onClickOpenChat = () => {
    logoutChat(() => setChatModalOpen(true))
  };

  const getUnreadCount = () => {
    apiGetImUnreadCount().then((res) => {
      setUnreadCount(res.count);
    });
  };
  // ready后查询聊天信息初始化会话列表
  // const onSdkReady = useCallback((event: any) => {
  //   //监听会话列表更新，需要SDK Ready后才能调用，ready的时候会自动触发一次
  //   //比较奇怪的是，这个事件会在收到 对方消息后，会触发2次
  //   chat.on(TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, () => {
  //     console.log("会话列表更新");
  //   });
  // }, []);
  // const initChatService = useCallback(async () => {
  //   await getImAppid().then((appIdRes) => {
  //     let options = {
  //       SDKAppID: appIdRes.appId, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
  //     };

  //     // if(!chat) {
  //     // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
  //     chat = TencentCloudChat.create(options); // SDK 实例通常用 tim 表示
  //     // }

  //     // 设置 SDK 日志输出级别，详细分级请参见 <a href="https://web.sdk.qcloud.com/im/doc/en/SDK.html#setLogLevel">setLogLevel 接口的说明</a>
  //     chat.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用
  //     // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
  //     chat.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
  //     //登陆

  //     //获取自己IM信息
  //     getIMLoginInfo().then((res) => {
  //       let promise = chat.login({
  //         userID: res.userId + "",
  //         userSig: res.userSign,
  //       });
  //       promise
  //         .then(function (imResponse) {
  //           console.log("自己登录成功", imResponse.data); // 登录成功
  //           if (imResponse.data.repeatLogin === true) {
  //             // 标识账号已登录，本次登录操作为重复登录。
  //             console.log(imResponse.data.errorInfo);
  //           }
  //         })
  //         .catch(function (imError) {
  //           console.warn("login error:", imError); // 登录失败的相关信息
  //         });
  //     });

  //     chat.on(TencentCloudChat.EVENT.SDK_READY, onSdkReady);
  //   });
  // }, [onSdkReady]);

  useEffect(() => {
    // initChatService();
  }, []);

  useEffect(() => {
    getUnreadCount();
  }, [unReadCountUpdated]);

  return (
    <ChatIconStyle>
      {chatModalOpen && (
        <ChatRoom show={chatModalOpen} close={handleCloseModal}></ChatRoom>
      )}
      <Badge count={unreadCount} size="small" offset={[-10, 10]}>
        <div className="search-btn" onClick={onClickOpenChat}>
          <RiChat1Line></RiChat1Line>
        </div>
      </Badge>
    </ChatIconStyle>
  );
}

export default ChatIcon;
