export function mapCurrentPath(path: string) {
  const lastSlashIndex = path.lastIndexOf("/");
  if (
    path.substring(0, lastSlashIndex) ===
    "/user-creator-center/work-album/detail"
  ) {
    path = "/user-creator-center/work-album/detail";
  }
  
  switch (path) {
    case "/user-creator-center/work/draft/list":
      return "/user-creator-center/work/list";
      case "/user-creator-center/work/offShelf/list":
        return "/user-creator-center/work/list";
    case "/user-creator-center/work-album/detail":
      return "/user-creator-center/work-album";
    case "/user-creator-center/article/draft/list":
      return "/user-creator-center/article/list";
      case "/user-creator-center/article/offShelf/list":
      return "/user-creator-center/article/list";
    case "/user-creator-center/certified-author/material":
      return "/user-creator-center/certified-author";
    case "/user-creator-center/signed-author/material":
      return "/user-creator-center/signed-author";
    default:
      return path;
  }
}
