import styled from "styled-components";

export const  AuthWriterCreateStyle = styled.div`
    .auth-writer-container{
        .form-wrapper{
            margin-top: ${props => 2*props.theme.space.margin}px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn{
                background: ${props => props.theme.colors.colorPrimary};
                padding: 14px 100px;
                border-radius: ${props => props.theme.border.borderRadius}px;
                cursor: pointer;
            }
        }
    }
`
