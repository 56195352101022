import { NftSalesPlanListResponse } from "types/nft-album"
import { AlbumCardStyle } from "./style"
import { Modal } from "antd"
import { useState } from "react"
import { apiGetAlbumCode } from "api/nft-album"
import { RiFunctionLine } from "react-icons/ri"

const AlbumCard = ({ item, isShowUser = true }: { item: NftSalesPlanListResponse, isShowUser?: boolean }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    const [code, setCode] = useState<any>('')

    const open = () => {
        apiGetAlbumCode(item.id).then((res) => {
            setCode(res)
            setIsShowModal(true)
        })
    }

    return <AlbumCardStyle>
        <view onClick={open}>
            <div className="cover" style={{ backgroundImage: `url(${item.listImage})` }}>
            </div>
            <div className="bottom">
                <div className="name">
                    <div className="albun-name" title={item.name}>
                        {item.name}
                    </div>
                    {
                        item.mode === 2 && <RiFunctionLine className="icon" />
                    }
                </div>
                {
                    item.publisherUserInfo && isShowUser && <a href={`/user/${item.publisherUserInfo?.id}`} onClick={(e) => e.stopPropagation()} target="_blank" className="user">
                        <div className="avatar" style={{ backgroundImage: `url(${item.publisherUserInfo?.avatarUrl})` }}></div>
                        <div className="username" title={item.publisherUserInfo?.username}>{item.publisherUserInfo?.username}</div>
                    </a>
                }
            </div>
        </view>
        {
            code && <Modal className="modal" onCancel={() => setIsShowModal(false)} footer={null} destroyOnClose={true} centered={true} open={isShowModal}>
                <div className="wrap" style={{ textAlign: "center" }}>
                    <h3 style={{ marginTop: 0 }}>请打开微信扫描下面二维码查看</h3>
                    <img src={code} style={{ width: '200px', marginTop: "15px" }} />
                </div>
            </Modal>
        }
    </AlbumCardStyle>
}

export default AlbumCard