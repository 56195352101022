import { NotFoundStyle } from './style'
function NotFound() {

  return (
    <NotFoundStyle>
        <div className='content'>

        <img src="https://cdn-prd.ggac.com/ggac/assets/image/home/404_.png" alt="" width={540}/>
        <h5>404, 没找到页面呀, <span className="link" onClick={()=>{
          window.location.href ='/'
        }}> 返回GGAC首页?</span></h5>
        </div>
        
        
    </NotFoundStyle>
  )
}

export default NotFound