import { SearchPopupStyle } from "./style"
import { useState, useEffect } from 'react'
import { getSearchHot } from "api/search"
import { hotResponse } from "types/search"
import { RiDeleteBin3Line } from "react-icons/ri";

type searchModalType = {
    params: {
        visible: boolean,
        close: () => void,
        toSearch?: (value: string) => void,
        deleteHistory: () => void
    }
}

const SearchPopup = (props: searchModalType) => {
    const { params } = props
    const [historyList, setHistory] = useState<string[]>()
    const [hotList, setHotList] = useState<hotResponse>([])
    const localhostHistory = localStorage.getItem('search-history')

    useEffect(() => {
        getSearchHot().then(res => {
            setHotList(res)
        })
    }, [])

    useEffect(() => {
        if (localhostHistory) {
            setHistory(JSON.parse(localhostHistory))
        }
    }, [localhostHistory])

    const deleteHistory = () => {
        params.deleteHistory()
        setHistory([])
    }


    // const toSearch = (name: string) => {
    //     navigete(`/search/keyword?name=${name}`)
    //     params.close()
    // }


    return <SearchPopupStyle>
        <>
            {
                ((historyList && historyList.length > 0) || (hotList && hotList.length > 0)) && <div className="search-popup" onMouseDown={(e) => e.preventDefault()} style={{ display: params.visible ? 'block' : 'none' }}>
                    <div className="popup-list">
                        <div className="list-item">
                            {
                                historyList && historyList.length > 0 && <>
                                    <div className="title">最近搜索 <RiDeleteBin3Line onClick={deleteHistory} style={{ cursor: "pointer" }} size={18}></RiDeleteBin3Line></div>
                                    <div className="sub">
                                        {
                                            historyList.map((item) => {
                                                return <div className="sub-item" onClick={() => params.toSearch && params.toSearch(item)} key={item}>
                                                    {item}
                                                </div>
                                            })
                                        }
                                    </div>
                                </>
                            }
                            {
                                hotList && hotList.length > 0 && <>
                                    <div className="title">热门搜索</div>
                                    <div className="sub">
                                        {
                                            hotList.map((item) => {
                                                return <div className="sub-item" onClick={() => params.toSearch && params.toSearch(item.word)} key={item?.word!}>
                                                    {item.word}
                                                </div>
                                            })
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }

        </>

    </SearchPopupStyle>
}

export default SearchPopup