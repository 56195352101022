import { get } from "api"


export interface GetWareRequest  {
    name?: string
    type?: number
}

export interface WareItem   {
    id: number
    name: string
    rank: number
    type: number
}
// 获取职业列表
export const apiGetWareList = async (params: GetWareRequest) =>
    get<GetWareRequest, WareItem[]>('/api/dict/creation_env_list', params)


    