import styled from "styled-components";

export const CertificateCardStyle = styled.div`
.list-item {
    background-color: #f8f8f8;
    text-align: center;
    .cover {
        width: 100%;
        height: 206px;
        background-size: cover;
    }
    .info {
        padding: 0 ${props => props.theme.space.padding}px ${props => props.theme.space.padding}px;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .user-header {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
        margin-top: -24px;
    }
    .user-name {
        color: #7f7f86;
        padding: 0 0 ${props => props.theme.space.padding}px;
    }
    .year {
        padding: ${props => props.theme.space.paddingXS}px 0;
    }
    .year,.award {
        padding-bottom: ${props => props.theme.space.paddingXS}px;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
`