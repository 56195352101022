import BadgeListItems from 'views/user-center/badges/badge-list-item';
import { BadgesStyle } from './style'
import { useEffect, useState } from 'react';
import { apiGetUserBadges } from 'api/user/badge';
import { BadgeItem } from 'types/user/badge';
import { useParams } from 'react-router-dom';
import { NoData } from 'components/no-data';

function Badges() {
  const [growthBadges, setGrowthBadges] = useState<BadgeItem[]>([]);
  const [achievementBadges, setAchievementBadges] = useState<BadgeItem[]>([]);
  const [specialBadges, setSpecialBadges] = useState<BadgeItem[]>([]);
  const [contestBadges, setContestBadges] = useState<BadgeItem[]>([]);
  const [eventBadges, setEventBadges] = useState<BadgeItem[]>([]);
  const id = useParams()?.id

  useEffect(() => {
    id && apiGetUserBadges({ userId: Number(id), orderDesc: 'updateTime' }).then((res) => {
      setGrowthBadges(
        res.filter((item) => item.type === 1 && item.isActivated > 0)
      );
      setAchievementBadges(
        res.filter((item) => item.type === 2 && item.isActivated > 0)
      );
      setSpecialBadges(
        res.filter((item) => item.type === 3 && item.isActivated > 0)
      );
      setContestBadges(
        res.filter((item) => item.type === 4 && item.isActivated > 0)
      );
      setEventBadges(
        res.filter((item) => item.type === 5 && item.isActivated > 0)
      );
    });
  }, []);
  return (
    <BadgesStyle>
      <div className='badge-list'>
  
        {!!(specialBadges.length > 0) &&
          <>
            <div className="title">特别徽章</div>
            <div>{BadgeListItems(specialBadges)}</div>
          </>
        }
        {!!(contestBadges.length > 0) &&
          <>
            <div className="title">比赛徽章</div>
            <div>{BadgeListItems(contestBadges)}</div>
          </>
        }
        {!!(eventBadges.length > 0) &&
          <>
            <div className="title">活动徽章</div>
            <div>{BadgeListItems(eventBadges)}</div>
          </>
        }
            {!!(growthBadges.length > 0) &&
          <>
            <div className="title">个人成长徽章</div>
            <div>{BadgeListItems(growthBadges)}</div>
          </>
        }
            {!!(achievementBadges.length > 0) &&
          <>
            <div className="title">个人成就徽章</div>
            <div>{BadgeListItems(achievementBadges)}</div>
          </>
        }
        {
          specialBadges?.length === 0 && contestBadges?.length === 0 && eventBadges?.length === 0 && growthBadges.length ===0 && achievementBadges.length===-0 && <div style={{ padding: "34px 0" }}><NoData /></div>
        }
      </div>

    </BadgesStyle>
  )
}

export default Badges