import { useEffect, useState } from "react";
import { EventListStyle } from "./style";
import { eventListResponse } from "types/events";
import { getEventList } from "api/events";
import { Divider, Input, Pagination } from "antd";
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
import { NoData } from "components/no-data";
import { useLocation, useNavigate } from "react-router";
import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import { getCarouselList } from "api/common/carousel";
import { shuffleArray } from "utils/functions/common";



const EventList = () => {
  const [eventingData, setEventingData] = useState<eventListResponse[]>([]);
  const [eventedData, setEventedData] = useState<eventListResponse[]>([]);
  const [eventingDataTotal, setEventingDataTotal] = useState(0);
  const [eventingPageNumber, setEventingPageNumber] = useState(1);
  const [eventedDataTotal, setEventedDataTotal] = useState(0);
  const [eventedPageNumber, setEventedPageNumber] = useState(1);
  const [type, setType] = useState(0)
  const name = new URLSearchParams(useLocation().search).get("value") || ''
  const [searchValue, setSearchValue] = useState(new URLSearchParams(useLocation().search).get("value") || '')
  const { Search } = Input;
  const [banner, setBanner] = useState<CarouselListItem>();

  const getSlideList = () => {
    const params: CarouselListRequest = {
      lang: 1,
      type: 22,
      status: 1,
    };
    getCarouselList(params).then((res) => {
      setBanner(shuffleArray(res.pageData)?.[0]);
    });
  };

  useEffect(() => {
    getSlideList();
  }, []);

  useEffect(() => {
    setEventedPageNumber(1)
    setEventingPageNumber(1)
  }, [type])

  useEffect(() => {
    const params: any = {
      status: 1, pageNumber: eventingPageNumber, name: searchValue
    }
    if (type) {
      params.type = type
    } else {
      delete params.type
    }
    getEventList(params).then((res) => {
      setEventingData(res.pageData);
      setEventingDataTotal(res.totalSize);
    });

  }, [eventingPageNumber, type, name]);

  useEffect(() => {
    const params: any = {
      status: 2, pageNumber: eventedPageNumber, name: searchValue
    }
    if (type) {
      params.type = type
    } else {
      delete params.type
    }
    getEventList(params).then((res) => {
      setEventedData(res.pageData);
      setEventedDataTotal(res.totalSize);
    });
  }, [eventedPageNumber, type, name])

  const changeEventingPageNumber = (e: number) => {
    setEventingPageNumber(e);
  };

  const changeEventedPageNumber = (e: number) => {
    setEventedPageNumber(e);
  };

  const tabOption = [
    {
      label: "最新活动",
      value: 0,
    },
    {
      label: "主题赛事",
      value: 1,
    },
    {
      label: "赛事福利",
      value: 2,
    },
    {
      label: "站内活动",
      value: 3,
    },
    {
      label: "专题策划",
      value: 4,
    },
    // {
    //   label: "官方公告",
    //   value: 5,
    // },
  ];

  const navigate = useNavigate()

  const onSearch = (e: string) => {
    setSearchValue(e);
    navigate(`/events?value=${e}`);
  };

  const changeWorkType = (e: any) => {
    setType(Number(e))
  }

  const onChange = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const jump = (banner: CarouselListItem) => {
    window.open(banner.webUrl);
  }

  return (
    <EventListStyle>
      <div className="guanggao-banner-wrapper">
        <GuanggaoBannerCarousel positionCode={9}></GuanggaoBannerCarousel>
      </div>
      <div className="event-wrap">
        <div className="type">
          {
            tabOption.map(item => {
              return <div key={item.value} onClick={() => changeWorkType(item.value)} className={`${type === item.value ? 'active' : ''} type-item`}>{item.label}</div>
            })
          }

          {banner && (
            <div
              className="side-gg-banner"
              onClick={() => jump(banner)}
            >
              <img src={banner?.displayImage} alt="" className="banner-item" />
            </div>
          )}

        </div>
        <div className="search-wrap">
          <Search onSearch={onSearch} onChange={onChange} allowClear value={searchValue} width={320} placeholder="请输入关键字搜索" />
        </div>

        <div className="event">
          {eventingData.map((item) => {
            return (
              <a
                className="event-item"
                key={item.id}
                href={item.webUrl}
              >
                <div
                  className="cover"
                  style={{ backgroundImage: `url(${item.listImage})` }}
                >
                  <div className="date">
                    {item.customText
                      ? item.customText
                      : `距离活动结束还有${item.day}天`}
                  </div>
                </div>
                <div className="info">
                  <div className="title">{item.name}</div>
                  <div className="desc" title={item.description}>
                    {item.description}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
        <Pagination
          pageSize={10}
          style={{ textAlign: "center" }}
          hideOnSinglePage={true}
          onChange={changeEventingPageNumber}
          total={eventingDataTotal}
          current={eventingPageNumber}
          showSizeChanger={false}
        />
        {eventedData.length > 0 && <Divider style={{ margin: '40px 0' }} plain>
          已结束
        </Divider>}
        <div className="event">
          {eventedData.map((item) => {
            return (
              <a
                className="event-item"
                key={item.id}
                href={item.webUrl}
              >
                <div
                  className="cover"
                  style={{ backgroundImage: `url(${item.listImage})` }}
                >
                  <div className="date">
                    {item.customText ? item.customText : `已结束`}
                  </div>
                </div>
                <div className="info">
                  <div className="title">{item.name}</div>
                  <div className="desc" title={item.description}>
                    {item.description}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
        {
          eventedData.length === 0 && eventingData.length === 0 && <NoData />
        }
        <Pagination
          pageSize={10}
          style={{ textAlign: "center" }}
          hideOnSinglePage={true}
          onChange={changeEventedPageNumber}
          total={eventedDataTotal}
          showSizeChanger={false}
          current={eventedPageNumber}
        />
      </div>
    </EventListStyle>
  );
};

export default EventList;
