import { UserCenterHomeStyle } from "./style";
import React from "react";
import UiTabs from "ui-lib/tab";
import { Outlet } from "react-router-dom";
import { userHomeTabs } from "../layout/fn";


export const TopContext = React.createContext({
  updateDetail: () => { }
})

function UserCenterHome() {



  return (
    <UserCenterHomeStyle>
     
        <UiTabs tabs={userHomeTabs}></UiTabs>
        <div className="sub-container">

        <Outlet />
        </div>
       
     
    </UserCenterHomeStyle>
  );
}

export default UserCenterHome;
