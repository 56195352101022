import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UserWorksStyle } from "./style";
import { apiUserWorkList } from "api/user/work";
import { WorkListItem } from "types/work";
import { useLocation, useParams } from "react-router-dom";
import { Button, Modal, Pagination, Spin } from "antd";
import { NoData } from "components/no-data";
import HomePageWorkCard from "components/uni-card/self-cards/homePageWorkCard";
import {
  getLocalUserInfo,
  setWorkListParamsForNav,
} from "utils/functions/common";
import useWindowSize from "hooks/useWindowSize";
import ApplicantPopup from "./applicant-popup";

function UserContestWorks() {
  const { id } = useParams();
  const [workList, setWorkList] = useState<WorkListItem[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [screenSize] = useWindowSize();
  const location = useLocation();

  const [isShow, setIsShow] = useState(false)


  const params = useMemo(() => {
    return {
      pageNumber,
      pageSize: 30,
      isContest: 1,
    };
  }, [pageNumber]);

  const getList = useCallback(() => {
    setLoading(true);

    apiUserWorkList({
      userId: Number(id ?? getLocalUserInfo().id),
      ...params,
      onSale: 1,
    })
      .then((res) => {
        setWorkList(res.pageData);
        setTotal(res.totalSize);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, id]);

  const changePage = (e: number) => {
    setPageNumber(e);
  };

  useEffect(() => {
    getList();
  }, [getList]);

  const t_id = Date.now();

  const saveWorkListParamsForNav = (index: number) => {
    const params_ = { number: index, ...params, userId: Number(id), onSale: 1 };
    setWorkListParamsForNav(params_, t_id);
  };

  const openRegulationApplicant = () => {
    setIsShow(true)
  }

  return (
    <UserWorksStyle>
      {
        window.location.pathname === "/user-center/home/contest-work/list" && <Button onClick={() => openRegulationApplicant()} type="primary" style={{marginBottom:'16px'}}>赛事报名信息</Button>
      }

      <Spin spinning={loading}>
        <div
          className="data-list"
          style={{
            gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`,
          }}
        >
          {workList?.map((workListItem, index) => {
            return (
              <div onClick={() => saveWorkListParamsForNav(index)} key={index}>
                <HomePageWorkCard
                  item={workListItem}
                  t_id={t_id}
                ></HomePageWorkCard>{" "}
              </div>
            );
          })}
        </div>
        {workList?.length === 0 && (
          <div style={{ padding: "100px 0" }}>
            <NoData />
          </div>
        )}
      </Spin>
      <Pagination
        style={{ textAlign: "center", marginTop: "28px" }}
        hideOnSinglePage={true}
        current={pageNumber}
        onChange={changePage}
        showSizeChanger={false}
        pageSize={params.pageSize}
        total={total}
      />
      <Modal width={750} onCancel={() => setIsShow(false)} footer={<></>} maskClosable={true} destroyOnClose={true} centered={true} open={isShow}>
        <ApplicantPopup />
      </Modal>
    </UserWorksStyle>
  );
}

export default UserContestWorks;
