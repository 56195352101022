
import { WorkListItem } from 'types/work'
import { translateCardCategoryText } from 'utils/functions/common';
import HotIcons from '../common/hot-icons';
import PrizeIcons from '../common/prize-icons';
import UniCardLayout from '../layout';
import MediaIconsWork from '../common/media-icons/work';
import CardTitleRender from '../common/card-title/work';
import PublicCardStatus from './PublicCardStatus';

function PublicWorkCard({ item,t_id }: { item: WorkListItem, t_id?:number }) {
  let work_url =`/work/detail/${item.id}`
  if(t_id){
    work_url = work_url+`?t_id=${t_id}`
  }

    const UiObject ={
        coverUrl:item.coverUrl,
        title:<CardTitleRender item={item}/>,
        subtitle:<span> {item.dictMap?.mediaCategory} |  {translateCardCategoryText(item.categoryList)}</span>,
        view:item.viewCount,
        hot:item.hot,
        userName:item.userInfo?.username,
        avatarUrl:item.userInfo.avatarUrl,
        icons:HotIcons({item}),
        workLink: work_url,
        userLink:`/user/${item.userId}`,
        rightTopNode:PrizeIcons({item}),
        rightBottomNode:MediaIconsWork({item}),
        leftBottomNode:<PublicCardStatus item={item}></PublicCardStatus>,
        isPublish: true,
      }
      
      
      return UniCardLayout({item:UiObject});
}

export default PublicWorkCard