import styled from "styled-components";

export const ArticleDetailStyle = styled.div`
    
    .container{
        max-width: 1280px;
        position: relative;
        margin: 0 auto;
        .header-wrapper{
            padding-top: ${(props)=> props.theme.space.paddingLG}px;
            display: flex;
            justify-content: space-between;
            .header-left{
                flex: 1;
                .header-item{
                    padding: ${(props) => props.theme.space.paddingXXS}px 0;
                    color: ${(props) => props.theme.colors.colorTextSecondary};
                    font-size: ${(props)=> props.theme.fontSizes.fontSize}px;
                }
                
                .title-item{
                    font-size: ${(props)=> props.theme.fontSizes.fontSizeHeading4}px;
                    color: ${(props) => props.theme.colors.colorText};
                    font-weight: ${(props)=> props.theme.fontSizes.fontWeightHeading4};
                    
                }
                .time-item{
                    display: flex;
                    align-items: center;
                }
                .interaction-item{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .icon{
                        display: flex;
                        align-items: center;
                        margin-right: ${(props)=> props.theme.space.margin}px;
                        .data{
                                margin-left:  ${(props)=> props.theme.space.marginXXS}px;
                        }
                    }

                }
            }
            .header-right{
                flex-basis: 300px;
                display: flex;
                align-items: center;
                margin-left: ${(props)=> props.theme.space.marginXS}px;
                
                    .userinfo-right{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-left: ${(props) => props.theme.space.margin}px;
                        .username-item{
                            font-size: ${(props) => props.theme.fontSizes.fontSize}px;
                            font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
                        }
                        .userinfo-item{
                            padding-bottom: ${(props) => props.theme.space.paddingXXS}px;
                        }
                        .btn-item{
                            display: flex;
                            .btn{
                                padding: ${(props)=> props.theme.space.paddingXS}px ${(props)=> props.theme.space.padding}px;
                                color: ${(props) => props.theme.colors.colorTextLightSolid};
                                background:${(props) => props.theme.colors.colorPrimary};
                                border-radius: ${(props)=> props.theme.border.borderRadius}px;
                                font-size: ${(props) => props.theme.fontSizes.fontSize}px;
                                cursor: pointer;
                            }
                            .focus-btn{
                                /* color: ${(props) => props.theme.colors.colorTextLightSolid}; */
                                background-color: transparent;
                                border: 1px solid ${(props) => props.theme.colors.colorPrimary};
                                color: ${(props) => props.theme.colors.colorPrimary};;
                            }
                            .msg-btn{
                                margin-left: ${(props) => props.theme.space.marginXS}px;
                            }
                        }
                    }
            }
        }
        .tags-wrapper{
            display: flex;
            align-items: center;
            .tags-title{
                margin-right: ${(props) => props.theme.space.margin}px;
                font-weight: bold;
            }
            .tags-item{
                cursor: pointer;
                border: 1px solid ${(props)=> props.theme.colors.colorBorder};
                border-radius: ${(props)=> props.theme.border.borderRadius}px;
                font-size: ${(props) => props.theme.fontSizes.fontSize}px;
                padding: ${(props)=> 1.5*props.theme.space.paddingXXS}px ${(props)=> props.theme.space.padding}px;
                margin-right: ${(props) => props.theme.space.marginXS}px;
            }
        }
        .content-wrapper{
            /* position: relative;
            margin: 0 auto; */
            display: flex;
            justify-content: center;
        }
        .interaction-wrapper{
            margin-top: ${(props) => 2*props.theme.space.margin}px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .operation-wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                .interaction-item{
                margin: 0 ${(props)=> props.theme.space.margin}px;
                display: flex;
                flex-direction: column;
                width: 60px;
                height: 60px;
                justify-content: center;
                cursor: pointer;
                align-items: center;
                border: 1px solid ${(props) => props.theme.colors.colorPrimary};
                border-radius: 50%;
                color: ${(props)=> props.theme.colors.colorPrimary};
                    .icon{
                        width: ${props=> props.theme.fontSizes.fontSizeXL}px;
                        height: ${props=> props.theme.fontSizes.fontSizeXL}px;
                        color:${props=> props.theme.colors.colorPrimaryText};
                    }
                }
                .alarm-item{
                    position: absolute;
                    align-self: flex-end;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-end;
                    cursor: pointer;
                }
            }
            .favour-list-wrapper{
                margin: ${(props)=> props.theme.space.margin}px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                .avatar-list{
                    display: flex;
                    margin: ${(props)=> props.theme.space.marginXXS}px 0;
                }
            }
        }
        .works-wrapper{
            display: flex;
            flex-wrap: wrap;
            .card-item{
                margin: ${(props)=> props.theme.space.marginXXS}px;
            }
        }
        .comment-wrapper{
            padding: ${(props) => 2*props.theme.space.paddingLG}px 0;
            .input-wrapper{
                margin-top: ${(props) => props.theme.space.marginXS}px;
                position: relative;
                display: flex;
                flex-direction: column;
                .confirm-btn{
                    width: 80px;
                    margin-top: ${(props) => props.theme.space.marginXS}px;
                    align-self: flex-end;
                }
            }
        }

    }
`