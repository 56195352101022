import { Modal, Pagination, Spin, message } from "antd";
import { getRewardInvitationList, postEditInvitationState } from "api/forum";
import { ForumMineInviteListItem } from "api/forum/type";
import { NoData } from "components/no-data";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonTabs from "ui-lib/tab/common-tab"
import { queryUrl } from "utils/functions/common";
import { RewardInvitationListStyle } from "./style";

const RewardInvitationList = () => {
    const state = new URLSearchParams(useLocation().search).get("state") || ""
    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const navigate = useNavigate()
    const pageSize = 10
    const [list, setList] = useState<ForumMineInviteListItem[]>([])
    const [totalSize, setTotalSize] = useState(0)
    const [loading, setLoading] = useState(false)

    const forumOrderStatusList = [
        {
            key: '',
            title: "全部",
        },
        {
            key: '2',
            title: "已接受",
        },
        {
            key: '3',
            title: "已拒绝",
        }
    ];

    useEffect(() => {
        getRewardInvitationList({ pageNumber: Number(pageNumber), status: state }).then(res => {
            setLoading(true)
            setList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [state, pageNumber])


    const checkTab = (detail: { title: string, key: string | number }) => {
        navigate(`?${queryUrl({ state: detail.key, pageNumber: 0 })}`)
    }

    const changePage = (e: number) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const eidtApplicant = (topicId: number, flag: number) => {
        postEditInvitationState({
            flag,
            topicId,
        }).then(() => {
            const index = list.findIndex(item => item.id === topicId)
            if (!!flag) {
                list[index].applyStatus = 2
                message.success("接受成功")
            } else {
                list[index].applyStatus = 3
                message.success("拒绝成功")
            }
            setList([...list])
        })
    }

    const link = () => {
        navigate(`/forum/reward/index`, { replace: true })
    }

    const DeliverRewardOrder = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        navigate(`/forum/reward/receive-order`)
    }

    return <RewardInvitationListStyle>
        <div className="list">
            <div className="list-title">
                <div className="link" onClick={() => link()}>
                    返回 悬赏主页
                </div>
                <span>{`>`}</span>收到邀约
            </div>
        </div>

        <CommonTabs
            tabs={forumOrderStatusList}
            onClick={(item) => checkTab(item)}
            defaultKey={state || ''}
        ></CommonTabs>

        {
            list.length > 0 && <>
                <Spin spinning={loading}>
                    <div className="list">
                        {
                            list.map(item => {
                                return <div className="list-item" key={item.id}>
                                    <div className="topic" onClick={() => window.open(`/forum/reward/detail/${item.id}`)}>
                                        <div className="topic-title">
                                            {item.title}
                                        </div>
                                        <div className="status word">
                                            问题详情 {`>`}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="price">
                                            <div className="price-icon"></div>
                                            赏金￥{item.amount}
                                        </div>
                                        <div>
                                            {
                                                item.status === 1 && item.applyStatus === 1 && <div className="invitation-wrap">
                                                    <div onClick={() => eidtApplicant(item.id, 1)}>接受邀约</div>
                                                    <div onClick={() => eidtApplicant(item.id, 0)}>拒绝邀约</div>
                                                </div>
                                            }
                                            {
                                                (item.applyStatus === 4 && (item.status === 2)) && <div className="invitation-wrap">
                                                    <div onClick={() => DeliverRewardOrder()}>立即回答</div>
                                                </div>
                                            }

                                            {
                                                (item.applyStatus === 2 && (item.orderStatus === 1 || !item.orderStatus)) && <div className="state">
                                                    已接受，待对方支付
                                                </div>
                                            }
                                            {
                                                item.applyStatus === 3 && item.status === 1 && <div className="state">已拒绝</div>
                                            }
                                            {
                                                item.applyStatus === 5 && <div className="state">抱歉，您来晚了，已选定其他答主</div>
                                            }

                                            {
                                                item.status === 2 && <>
                                                    {
                                                        !!item.orderStatus && <div className="word">订单详情 {`>`}</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <Pagination
                        style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                        hideOnSinglePage={true}
                        current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                        onChange={changePage}
                        pageSize={pageSize}
                        total={totalSize}
                        showSizeChanger={false}
                        size="default"
                    />
                </Spin>
            </>
        }

        {
            list.length === 0 && <NoData />
        }

    </RewardInvitationListStyle >
}

export default RewardInvitationList