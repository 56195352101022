import { useEffect, useMemo, useState } from "react"
import { EventExhibitionStyle } from "./style"
import { useLocation, useParams } from "react-router"
import { getEventExhibitionDetail } from "api/events"
import { EventExhibitionDetailResponse } from "types/events"
import { Button, Pagination, Spin } from "antd"
import { NoData } from "components/no-data"
import { useNavigate } from "react-router"
import { isMobile, queryUrl } from "utils/functions/common"
import { SearchOption } from "components/search-option"
import { getWorkList } from "api/work"
import { WorkListItem } from "types/work"
import PublicWorkCard from "components/uni-card/public-cards/publicWorkCard"

const EventExhibition = () => {
    const id = Number(useParams()?.id)
    const [detail, setDetail] = useState<EventExhibitionDetailResponse>()
    const [list, setList] = useState<WorkListItem[]>([])
    const [total, setTotal] = useState(0)
    let sortField = new URLSearchParams(useLocation().search).get("sortField")
    const timeField = Number(new URLSearchParams(useLocation().search).get("timeField") || 0)
    const navigate = useNavigate()
    let pageNumber = Number(new URLSearchParams(useLocation().search).get("pageNumber") || 1)
    const pageSize = 48
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getEventExhibitionDetail(id).then(res => {
            setDetail(res)
        })
    }, [id])


    const tagNames = useMemo(() => {
        return detail?.tagList.map(item => {
            return item.name
        }) || []
    }, [detail?.tagList])

    const upload = () => {
        window.open(`/work/create?tag=${JSON.stringify(tagNames)}&isShowPrivate=0`)
    }

    const paramsReq = useMemo(() => {
        return {
            id, sortField: sortField || detail?.defaultSort, pageNumber, timeField
        }
    }, [id, sortField, pageNumber, timeField, detail?.defaultSort])

    const getList = (e: number = 1) => {
        const params: any = {
            pageNumber: paramsReq.pageNumber, pageSize, tagIds: detail?.tagList.map(tag => tag.id).join(','), sortField: paramsReq.sortField
        }
        if (timeField) {
            params.timeField = timeField
        }
        if(!!detail?.timeFlag) {
            params.publishTimeEnd = detail.startTime
            params.publishTimeStart = detail.endTime
        }
        setLoading(true)
        getWorkList(params).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!detail) return
        getList()
    }, [paramsReq, detail])

    useEffect(() => {
        const checkIsMobile = isMobile()
        if (checkIsMobile) {
            window.location.href = `https://m.ggac.com` + window.location.pathname
            return
        }
    }, [])

    const changePage = (e: number) => {
        const url = queryUrl({ pageNumber: e })
        navigate(`?${url}`)
    }

    //点分页滚到到最上面
    useEffect(() => {

        const scrollableDiv = document.querySelector("#scrollableDiv");
        console.log(scrollableDiv)
        scrollableDiv && scrollableDiv.scrollIntoView({
            behavior: "smooth",
        });
    }, [pageNumber]);

    const saveWorkIdsForNav = () => {
        list && localStorage.setItem(
            "workIdsForNav",
            JSON.stringify(
                list.map((item) => {
                    return item.id;
                })
            )
        );
    }


    return <EventExhibitionStyle onClick={() => saveWorkIdsForNav()} >
        <div className="banner">
            <div className="banner-image">
                <img src={detail?.topBanner} alt="" />
            </div>

            <div className="content">
                <div className="top">
                    <div className="name">{detail?.name}</div>
                    <div className="date">{detail?.startTime?.substring(0, 10)} - {detail?.endTime?.substring(0, 10)}</div>
                </div>
                <div className="desc" dangerouslySetInnerHTML={{ __html: detail?.content! }} ></div>
                {
                    !!detail?.canUpload && <div className="btn-wrap">
                        <Button type='primary' className="upload" onClick={upload}>上传作品</Button>
                    </div>
                }

                {
                    !!(detail?.status === 3) && <div className="btn-wrap">
                        <Button type='primary' className="upload" disabled>已结束</Button>
                    </div>
                }
            </div>
        </div>
        <div className="work" id="scrollableDiv">
            <div className="work-search">
                <SearchOption defaultSortField={paramsReq.sortField} isShowTypeList={false}></SearchOption>
            </div>
        </div>
        <Spin spinning={loading}>
            {
                list.length === 0 && <NoData></NoData>
            }
            <div className="list">
                {
                    list.map((item, index) => {
                        return <PublicWorkCard item={item} key={index}></PublicWorkCard>
                    })
                }
            </div>
        </Spin>
        <Pagination
            style={{ textAlign: "center", marginTop: "15px" }}
            hideOnSinglePage={true}
            current={pageNumber}
            onChange={changePage}
            pageSize={pageSize}
            showSizeChanger={false}
            total={total}
        />
    </EventExhibitionStyle >
}

export default EventExhibition