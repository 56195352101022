import { MutableRefObject, useRef } from "react";
import { RegulationWorkSwiperStyle } from "./style"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { Autoplay, EffectFade, Navigation } from 'swiper/modules';

const RegulationWorkSwiper = ({ list }: { list: { url: string, desc: string, id: string }[] }) => {

    const swiperRef: MutableRefObject<any> = useRef(null);

    return <RegulationWorkSwiperStyle>
        {/* <img className="arrow left swiper-button-next" src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-2024-mobile-price-work-left.png" />
        <img className="arrow right" src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-2024-mobile-price-work-right.png" /> */}
        <Swiper
            spaceBetween={-180}
            effect={'carousel'}
            slidesPerView={1.5}
            centeredSlides={true}
            ref={swiperRef}
            navigation
            modules={[
                EffectFade,
                Autoplay,
                Navigation
            ]}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            loop
        >
            {
                list.map(item => {
                    return <SwiperSlide className="swiper-slide" key={item.id}>
                        <img src={item.url} alt="" onClick={() => window.open(`/work/detail/${item.id}`)} />
                        <div className="name" lang="t_lang">{item.desc}</div>
                    </SwiperSlide>
                })
            }

        </Swiper>
    </RegulationWorkSwiperStyle>
}

export default RegulationWorkSwiper