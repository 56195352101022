import styled from "styled-components";

export const TopicListItemStyle = styled.div`
    border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
    padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
    min-height: 44px;
    .top-row{
        cursor: pointer;
        /* display: flex;
        align-items: center;
        justify-content: space-between; */
        .reply-count{
            color: ${(props) => props.theme.colors.colorTextTertiary}
        }
        .fjs{
            display: flex;
            justify-content: space-between;
            align-content: center;
            &-content {
                display: flex;
                align-items: center;
                max-width: 590px;
                .tag {
                    margin-top: 2px;
                    padding: 0 3px !important;
                    height: 17px !important;
                    line-height: 15px;
                    border-radius: 3px !important;
                }
            }
        }
    }
     .title{
        font-size: 18px;
        font-weight: 600;
        color: #000;
        display: inline-block;
        max-width: 580px;
     }
    .brief-reply{
        display: flex;
        align-items: flex-start;
        gap:14px;
        padding:  ${(props) => props.theme.space.paddingXS}px 0;
        .thumbnail{
           width: 190px;
           height: 105px;
           background-repeat: no-repeat;
           background-size: cover;
           background-position: center;
        }
        .brief-content{
            flex:1;
            padding:  ${(props) => props.theme.space.paddingXS}px 0;
        }
    }
    .interaction{
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .icon-btn{
            display: flex;
            align-items: center;
            gap:8px;
            color: ${(props) => props.theme.colors.colorTextSecondary};
            cursor: pointer;
            &:hover{
                color: ${(props) => props.theme.colors.colorPrimaryActive};
            }
        }
       
    }
    .bottom-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
       
        /* padding-top: 14px; */
        .tag-list{
            display: flex;
            align-items: center;
            gap: 8px;
            max-width: 670px;
            overflow-x: auto;
            &::-webkit-scrollbar {
            display: none;
            }
        }
        .btn{
            height: 30px;
            padding-top:3px;

        }
    }
`