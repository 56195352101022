import React, { memo, useEffect, useState } from 'react';

import { UploadFile } from 'antd/es/upload/interface';
import { Modal, Upload, message } from 'antd';
import { UploadStyle } from "./style"

import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { RiAddLine } from 'react-icons/ri';
import { getCosPathByFileName } from 'utils/functions/common';
import { CosDirectoryType } from 'types/enums/media-type';
import { getFileUrlByCosUpload } from 'config/cos';
import { isFileOversized } from 'utils/functions/file';

type Props = {
  disabled?: boolean
  value?: UploadFile[]
  onChange?: (params: UploadFile[]) => void
  maxLength?: number
  fileType?: string
  remark?: string
  imgSrc?: string
  onUploadStart?: () => void,
  onUploadEnd?: () => void
  limitSize?: number
} & UploadProps

const FilesWall: React.FC<Props> = memo(({ onChange: onFileChange, ...props }) => {
  const [previewImage, setPreviewImage] = useState('');
  const fileList = props.value || [];

  const onChange = async ({ fileList }: UploadChangeParam) => {
    if (props.limitSize && fileList && fileList[0] && (fileList[fileList.length - 1]?.size || 0) / 1024 / 1024 > props.limitSize) {
      return false;
    }

    let innerError = 0

    // for (let i = 0; i < fileList.length; i++) {
    //   const file = fileList[i].originFileObj;
    //   let index = fileList[i].name.lastIndexOf(".");
    //   let ext = fileList[i].name.substring(index + 1);
    //   let fileType = props.fileType?.split(',')
    //   if (file && props.fileType && !fileType?.includes(ext)) {
    //     message.error(`上传文件格式须为${props.fileType}`)
    //     return
    //   }

    // }

    // switch (innerError) {
    //   case 1:
    //     message.warning('上传文件中包含不支持的图片格式,请检查后重新上传', 2);
    //     break;
    //   case 2:
    //     message.warning('上传文件中包含尺寸超出20M文件,请检查后重新上传', 2);
    //     break;
    //   case 3:
    //     message.warning('包含超大分辨率图片,请检查后重新上传', 2);
    //     break;
    // }



    if (innerError === 0) {
      onFileChange!(fileList);
    }

  };

  useEffect(() => {
    // fileList.forEach(item => {
    //   item.name = '文件'
    // })
  }, [fileList])

  const getBase64 = (file: File | Blob | undefined): Promise<string> => {
    if (!file) return Promise.reject(new Error('no file'));
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file!);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  // const imagePreview = async (file: UploadFile, callback: (params: { image: string }) => void) => {
  //   const newFile = file;
  //   if (!newFile.url && !newFile.preview) {
  //     newFile.preview = await getBase64(file.originFileObj);
  //   }

  //   const newPreviewImage: string = newFile.url || newFile.preview || '';
  //   callback({
  //     image: newPreviewImage,
  //   });
  // };

  const beforeUpload = async (file: any) => {
    return file

  }
  const uploadButton = (
    <div>
      <RiAddLine />
      <div style={{ marginTop: 8, fontSize: '12px' }}>上传文件</div>
    </div>
  );

  const handleCustomFileChange = async (options: any) => {
    const { file } = options;
    if (props.limitSize && isFileOversized(file, props.limitSize)) {
      message.warning(`上传文件大小不能超过${props.limitSize}M,请检查后重新上传`, 3);
      return false;
    }
    if (file) {
      const fileUUid = getCosPathByFileName(props.imgSrc ? props.imgSrc : CosDirectoryType.productAttatchment, file.name);

      props.onUploadStart && props.onUploadStart()
      //上传腾讯云
      const resultImg = await getFileUrlByCosUpload(
        file,
        fileUUid,
        ((progressData, key) => {
          console.log(progressData, key)
          options.onProgress({ percent: progressData.percent * 100 })
        })
      )

      props.onUploadEnd && props.onUploadEnd()
      options.file.url = resultImg.url
      options.onSuccess(resultImg.url, options.file);
      options.file.status = 'done';
    }
  }

  return (
    <UploadStyle>

      {!props.disabled && <Upload
        {...props}
        disabled={props.disabled}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onChange={onChange}
        multiple={true}
        listType="picture-card"
        maxCount={props.maxLength || 5}
        customRequest={handleCustomFileChange}
      >
        {fileList.length === (props.maxLength || 5) ? null : uploadButton}
      </Upload>
      }
      <span className='remark'>{props.remark}</span>
      <Modal
        open={!!previewImage}
        footer={null}
        onCancel={() => setPreviewImage('')}
        bodyStyle={{ padding: 0 }}
      >
        <img style={{ width: '100%' }} alt="" src={previewImage} />
      </Modal>
    </UploadStyle>
  );
});

export { FilesWall };
