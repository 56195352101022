import { useState, useEffect, useCallback } from 'react'
import { postFollowUser } from 'api/user'
import { useParams } from "react-router-dom";
import { DetailsStyle } from './style'
import { apiGetPublicProductDetail } from 'api/shop'
import { PublicProductDetail } from 'types/product';
import { apiPublicUserDetail } from 'api/user/detail';
import { publicUserDetailType } from 'types/user/detail';
import { Button } from 'antd';

const Detail = () => {
    const [detail, setDetail] = useState<PublicProductDetail>()
    const [userDetil, setUserDetail] = useState<publicUserDetailType>()
    const id = useParams()?.productId;
    const [preImageUrl, setPreImageUrl] = useState('')

    const getDetail = useCallback(async () => {
        const result = await apiGetPublicProductDetail(+id!)
        setDetail(result)
        setPreImageUrl(result.imageList[0])
        const userResult = await apiPublicUserDetail(result.sellerUserId)
        setUserDetail(userResult)
    }, [id])

    useEffect(() => {
        getDetail()
    }, [getDetail])

    // 下载附件
    // const download = () => {
    //     window.open(detail?.download?.url)
    // }

    const selectImage = (detail: string) => {
        setPreImageUrl(detail)
    }
    

    // 关注/取消用户
    const focusUser = async () => {
        const flag = userDetil?.isFollow === 1 ? 0 : 1
        postFollowUser({ followee: Number(userDetil?.id), flag }).then(() => {
            if (userDetil) {
                userDetil.isFollow = flag
            }
            setUserDetail(userDetil)
        })
    }

    const ImagePreview = () => {
        return <div>
            <div className='pre-image' style={{ backgroundImage: `url(${preImageUrl})` }} />
            <div className="image-bottom">
                {
                    detail?.imageList.map((item) => {
                        return <div className='image-item' key={item} style={{ backgroundImage: `url(${item})` }} onClick={() => selectImage(item)}> </div>
                    })
                }
            </div>
        </div>
    }

    return <DetailsStyle>
        <div className='wrap'>
            <div className="left">
                <div className="title">{detail?.name}</div>
                <div className="image-wrap">
                    {ImagePreview()}
                </div>
                <div className='description'>
                    商品详情
                </div>
            </div>
            <div className="right">
                <div className="title">商品信息</div>
                <div className='item'>
                    <div className="label">商品价格</div>
                    <div className="value">￥{detail?.rmbPrice}</div>
                </div>
                <div className='item'>
                    <div className="label">库存</div>
                    <div className="value">{detail?.quantity}</div>
                </div>
                <div className='title sub-title'>注意事项</div>
                <div>
                    <div>请仔细阅读描述格式、版本、渲染器和插件。oj.bx等通用格式不会兼容所有软件，购买前先咨询格式转换出错、软件不兼容问题不在售后范围内。使用非描述中的格式、版本以及软件环境打开文件可能会有不兼容的问题。此情况不在售后范围内，请按需购买。</div>
                    <div>模型为三维数字模型，虚拟物品，非实体模型，与现实环境中的真实物体有差异版权说明:</div>
                    <div>在本站下载的素材，著作权归原作者所有。如需获得商业授权，购买前请咨询作者或者联系客服。未经书面授权或签订书面合同，不得以任何形式发行、发布、传播、复制、出租、转售、汇编该素材。您所购买并下载的素材，系由发布人进行上传并出售，您认为该内容侵犯您合法权益的，请点击查看权利通知规则。</div>
                </div>

                <div className='title sub-title'>作者信息</div>
                <div className="user">
                    <div className="user-image" style={{ backgroundImage: `url(${userDetil?.avatarUrl})` }} />
                    <div className="user-detail">
                        <div className="user-name">{userDetil?.username}</div>
                        <div className="user-career">{userDetil?.userInfo.profession}</div>
                        <Button type="primary" onClick={() => focusUser()} className={`btn ${userDetil?.isFollow === 1 ? 'is_focused' : ''}`}>{userDetil?.isFollow === 1 ? '已关注' : '关注'}</Button>
                    </div>
                </div>

            </div>
        </div>
    </DetailsStyle>
}

export default Detail