import styled from "styled-components";

export const RewardAnswererItemStyle = styled.div`
    .user-info {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        .user-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            cursor: pointer;
        }
        .user-name {
            max-width: calc(100% - 70px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
        }
    }

    .content {
        padding: 0 0 20px;
    }

    .tip {
        margin-top: ${props => props.theme.space.marginLG}px;
    }

    .file {
        padding: 20px 0;
        display: flex;
        gap: 20px;
        
        &-item {
            display: flex;
            justify-content: space-between;
            color: ${props => props.theme.colors.colorPrimary};
            cursor: pointer;
            text-decoration: underline;
            text-underline-offset: 5px;
        }
    }

    .fileSupplement {
        padding: 20px 0;
        display: flex;
    }
`