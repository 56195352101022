import { useState, useEffect } from 'react';
import { Select } from 'antd';
import { SignupFieldOptions } from 'types/sub/application';

export const CategorySecondSelect = (props: { value?: number[], data: SignupFieldOptions[], onChange?: (value: number[]) => void }) => {
  const [firstData, setFirstData] = useState<SignupFieldOptions[]>([])
  const [secondData, setSecondData] = useState<{ //类目列表
    optionId: number //选项ID
    categoryName: string //类目名称
    categoryId: number //类目ID
  }[]>([])

  const [first, setFirst] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);

  useEffect(() => {
    setFirstData(props.data)
    if (props.value) {
      setFirst(props.value[0])
      setSecond(props.value[1])
      props?.data && props?.data.length > 0 && props.data.forEach(item => {
        // item.categories.forEach((childrenItem) => {
        //   if (+props.value![1] === childrenItem.categoryId) {
        //     setSecondData(item.categories)
        //     setSecond(childrenItem.categoryId)
        //   }
        // })
        if (+props.value![0] === item.id) {
          setSecondData(item.categories)
        }
      })
    } else {
      setSecondData(props.data?.[0].categories)
      setFirst(props.data?.[0].id)
      setSecond(props.data?.[0].categories?.[0].categoryId)
      props?.data && props?.data.length > 0 && props.onChange && props.onChange([props.data?.[0].id, props.data?.[0].categories?.[0].categoryId])
    }

  }, [props.value])



  const handleFirstChange = (value: number) => {
    setSecondData([])
    let index = firstData.findIndex((item) => item.id === value)
    let item = firstData[index].categories
    setSecondData(item)
    setFirst(value)
    setSecond(item[0].categoryId)
    props.onChange && props.onChange([value, item[0].categoryId])
  };

  const handleSecondChange = (value: number) => {
    setSecond(value)
    props.onChange && props.onChange([first, value])
  };


  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Select
        style={{ width: '48%' }}
        value={first}
        onChange={handleFirstChange}
        options={firstData && firstData.map((item) => ({ label: item.name, value: item.id }))}
      />

      <Select
        style={{ width: '48%' }}
        value={second}
        onChange={handleSecondChange}
        options={secondData?.map((item) => ({ label: item.categoryName, value: item.categoryId }))}
      />
    </div>
  );
};
