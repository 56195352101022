import Comments from 'components/comments'
import { CommentWrapStyle } from './style'
import { useRef, useState } from 'react';
import { CommentListItem, CreateCommentRequest } from 'types/comment';
import { Button, Checkbox, message } from 'antd';
import { postCreateComment } from 'api/work/comment';
import { emojiParse } from 'utils/functions/common';
import TextArea from 'antd/es/input/TextArea';
import EmojiPicker from 'components/emoji-picker';

type Props = {
  dataId: number
  onCountChange?: () => void
  anonymity?: boolean
}

function CommentWrap({ dataId, onCountChange, anonymity }: Props) {
  const commentRef = useRef() as React.MutableRefObject<any>;
  const [commentCount, setCommentCount] = useState<number>(0);
  const [comment, setComment] = useState("");
  const [isAnonymity, setIsAnonymity] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // 调用commet创建方法
  const createComment = (val: CommentListItem) => {
    commentRef.current?.addComment(val);
  };
  const onChangeComment = (event: any) => {
    setComment(event.target.value);
  };
  const appendEmoji = (item: { url: string; title: string }) => {
    setComment((res) => res + item.title);
  };

  const onChangeCheck = (e: any) => {
    setIsAnonymity(e.target.checked)
  }

  const onClickComment = (replyId: number) => {
    if (!comment.trim()) {
      message.warning("请输入内容");
      return;
    }
    setIsLoading(true)
    if (isLoading) {
      return
    }
    const params: CreateCommentRequest = {
      content: emojiParse(comment),
      dataId: replyId,
      dataTable: "forum_reply",
    }
    if (isAnonymity) {
      params.isAnonymity = isAnonymity ? 1 : 0
    }
    postCreateComment(params)
      .then((res) => {
        message.success("评论成功");
        onCountChange && onCountChange()
        createComment(res);
        setIsAnonymity(false)
      })
      .finally(() => {
        setComment("");
        setIsLoading(false)
      });
  };

  const updateCommentCount = () => {
    onCountChange && onCountChange()
    setCommentCount(0);
  };

  return (
    <CommentWrapStyle className='CommentWrapStyle' onClick={(e) => { e.stopPropagation() }}>
      <div className="comment-wrapper">
        <div className="input-wrapper">
          <TextArea
            allowClear
            value={comment}
            onChange={onChangeComment}
            placeholder="请输入你的评论"
          // onPressEnter={onClickComment}
          />
          <div className="input">
            <div className="left-wrap">
              <EmojiPicker appendEmoji={appendEmoji} />
              {
                anonymity && <Checkbox checked={isAnonymity} onChange={onChangeCheck}>是否匿名</Checkbox>
              }
            </div>
            <Button
              className="confirm-btn"
              type="primary"
              size="small"
              onClick={() => onClickComment(dataId)}
              loading={isLoading}
            >
              确认
            </Button>
          </div>
        </div>
        <Comments
          ref={commentRef}
          params={{
            id: dataId,
            commentCount,
            updateCommentCount,
            type: "forum_reply",
            anonymity
          }}
        ></Comments>
      </div>
    </CommentWrapStyle>
  )
}

export default CommentWrap