import { Button, Form, Input, UploadFile, message } from "antd";
import { DeliverRewardStyle } from "./style"
import { CosDirectoryType } from "types/enums/media-type";
import { ProductPicturesWall } from "views/user-shop/productManage/uploadImageList"
import { FilesWall } from "views/user-shop/productManage/uploadFileList"
import { useEffect, useState } from "react";
import { getRewardFileList, postDeliverDocuments } from "api/forum";

const { TextArea } = Input

const DeliverReward = ({ orderNumber, close, listOnFlush }: { orderNumber: string, close: () => void, listOnFlush: () => void }) => {

    const [loading, setLoading] = useState(false)


    type imageType = {
        url: string
        key: string
        percent: number,
    }

    type submitParams = {
        imageList: imageType[],
        fileList: UploadFile[];
        content: string,
        fileSupplement: string
    }

    const [form] = Form.useForm<submitParams>();

    useEffect(() => {
        getRewardFileList(orderNumber).then(res => {
            const newImage: imageType[] = []
            if (!!res.imageList) {
                res.imageList.forEach((item) => {
                    newImage.push({
                        url: item,
                        key: item,
                        percent: 100
                    })
                })
            }

            const newFileList: UploadFile[] = [];
            if (!!res?.fileList) {
                res?.fileList?.forEach((item, index) => {
                    newFileList.push({
                        uid: String(index),
                        url: item,
                        name: item,
                    });
                });
            }
            if (!!res.orderNumber) {
                form.setFieldsValue({
                    imageList: newImage,
                    fileList: newFileList,
                    content: res.content,
                    fileSupplement: res.fileSupplement
                })
            }
        })
    }, [orderNumber])

    const submitForm = () => {
        form.validateFields().then(async (res) => {
            const {
                imageList,
                fileList,
                fileSupplement,
                content
            } = res;
            const params: any = { content, orderNumber, fileSupplement }
            if (imageList && imageList.length > 0) {
                const newImageList = imageList.map(item => item.url)
                params.imageList = newImageList
            } else {
                params.imageList = []
            }


            if (fileList && fileList.length > 0) {
                const newFileList = fileList.map(item => item.url)
                params.fileList = newFileList
            } else {
                params.fileList = []
            }

            if (params.fileList.lenght === 0 && params.imageList.length === 0 && !content && !fileSupplement) {
                message.warning("描述，图片，源文件，源文件链接必须填写一种")
                return
            }

            setLoading(true)
            postDeliverDocuments(params).then(() => {
                close()
                listOnFlush()
                message.success("提交成功")
            }).finally(() => {
                setLoading(false)
            })
        })
    }



    return <DeliverRewardStyle>
        <div className="content">
            <Form
                form={form}
                name="just-a-name"
                style={{ marginTop: 20 }}
                requiredMark={false}
            >
                <Form.Item
                    name="content"
                    label='答案描述'
                    rules={[{ required: true, message: "请输入答案描述" }]}
                >
                    <TextArea placeholder="请输入答案描述" />
                </Form.Item>
                <Form.Item
                    name="imageList"
                    label='上传图片'
                >
                    <ProductPicturesWall limitSize={30} imgSrc={CosDirectoryType.forumImage} maxLength={9} />
                </Form.Item>
                <Form.Item
                    name="fileList"
                    label='上传源文件'
                >
                    <FilesWall maxLength={9} limitSize={30} imgSrc={CosDirectoryType.forumFile} listType="picture-card" />
                </Form.Item>
                <Form.Item
                    name="fileSupplement"
                    label='源文件链接'
                    rules={[{ required: true, message: "请输入源文件链接" }]}
                >
                    <TextArea placeholder="请输入描述" />
                </Form.Item>
            </Form>
        </div>
        <div className="btns">
            <Button type="primary" ghost onClick={() => close()}>取消</Button>
            <Button type="primary" onClick={submitForm} loading={loading}>提交</Button>
        </div>
    </DeliverRewardStyle>
}

export default DeliverReward