import { useEffect, useState } from "react";
import { VideoContentDisplayStyle } from "./style";
import Player  from "griffith";

type Props = {
  url: string;
  cover?: string;
};

function VideoContentDisplay({ url,cover }: Props) {
  const [videoRatio,setVideoRatio] = useState(1);
  const getVideoDimensions = (url:string) =>{
    const video = document.createElement('video');
    
    
    video.addEventListener('loadedmetadata', function() {
      const width = this.videoWidth;
      const height = this.videoHeight;
      
      setVideoRatio(height / width);
    });
    
    video.src = url;
    
    // Load the video to trigger the 'loadedmetadata' event
    video.load();
  }

  useEffect(()=>{
    getVideoDimensions(url)
  },[])

  function isImageUrl(url:string) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
  
    const fileExtension = url.slice(url.lastIndexOf('.')).toLowerCase();
    return imageExtensions.includes(fileExtension);
  }
  
  return (
    <VideoContentDisplayStyle>
      {<div style={{ margin:'0 auto'}}>
      <Player
      shouldObserveResize={true}
      cover={isImageUrl(cover!)?cover:undefined}
      locale="zh-Hans"
        sources={{
          sd: {
            play_url: url,
          },
        }}
        id={"player"}
        initialObjectFit="contain"
      ></Player>
      </div>}
      
    </VideoContentDisplayStyle>
  );
}

export default VideoContentDisplay;
