import { useState, useEffect } from "react";
import useWindowSize from "./useWindowSize";

function useCoordinates(totalCount:number): any {
  const [screenSize] = useWindowSize();
  const [bigMatrix, setBigMatrix] = useState<number[]>([1, 4, 1, 4]);
  const [midMatrixA, setMidMatrixA] = useState<number[]>([1, 4, 1, 4]);
  const [midMatrixB, setMidMatrixB] = useState<number[]>([1, 4, 1, 4]);
  const [midMatrixC, setMidMatrixC] = useState<number[]>([1, 4, 1, 4]);
  const [colCount, setColCount] = useState(10);
  let colC = 10;

  useEffect(() => {
    let ids: string[] = []; //建立格子id池
    if (window.innerWidth >= 1600) {
      colC = 12;
    } else if (window.innerWidth < 1600 && window.innerWidth >= 1500) {
      colC = 10;
    } else if (window.innerWidth < 1300) {
      colC = 8;
    }
    setColCount(colC); //根据窗口宽度判断 栏数

    fillBig((result) => (ids = [...ids, ...result])); //获取大格子 坐标，并将子格子id 放进id池
  
    fillSmall(ids, (result, midMatrix) => {
      ids = [...ids, ...result];
      setMidMatrixA(midMatrix);
    }); //获取第一个中格子 坐标，并将子格子id 放进id池
   
    fillSmall(ids, (result, midMatrix) => {
      ids = [...ids, ...result];
      setMidMatrixB(midMatrix);
    }); //获取第二个中格子 坐标，并将子格子id 放进id池

    fillSmall(ids, (result, midMatrix) => {
      ids = [...ids, ...result];
      setMidMatrixC(midMatrix);
    }); //获取第三个中格子 坐标，并将子格子id 放进id池
   
  }, [screenSize]); //屏幕尺寸变更事件，触发重新计算

  //计算大格子的坐标，并上报 子格子的id数据
  const fillBig = (callBack: (ids: string[]) => void) => {
    const bigStartPoint = getRandomBigGridStartPoint(colCount); //随机起点 
    let result = breakGridIntoIds(bigStartPoint[0], bigStartPoint[1], 3); //把格子拆分成 子格子id 集合
    callBack(result);
    const bigMatrix = [
      bigStartPoint[0],
      bigStartPoint[0] + 3,
      bigStartPoint[1],
      bigStartPoint[1] + 3,
    ];
    setBigMatrix(bigMatrix);
  };

  //计算中格子的坐标，判断是否有子格子冲突，如无冲突，上报 中格子坐标和 其子格子的id数据
  const fillSmall = (
    ids: string[],
    callBack: (ids: string[], midMatrix: number[]) => void
  ) => {
    const midStartPoint = getRandomMidGridStartPoint(colCount); //随机起点
    let result = breakGridIntoIds(midStartPoint[0], midStartPoint[1], 2); //把格子拆分成 子格子id 集合

    const intersection = result.filter((item) => ids.includes(item)); //判断 子格子id数组和 id池是否有交集
    if (intersection.length>0) {
      fillSmall(ids, callBack);
    } else {
      const midMatrix = [
        midStartPoint[0],
        midStartPoint[0] + 2,
        midStartPoint[1],
        midStartPoint[1] + 2,
      ];
      callBack(result, midMatrix);
    }
  };

  //把格子拆分成 子格子id 集合
  const breakGridIntoIds = (
    colStart: number,
    rowStart: number,
    gridUitEvenCount: number
  ) => {
    const ids: string[] = [];
    for (let i = 0; i < gridUitEvenCount; i++) {
      const id = String([
        colStart,
        rowStart + i,
        colStart + 1,
        rowStart + i + 1,
      ]);

      ids.push(id);
      for (let x = 1; x < gridUitEvenCount; x++) {
        const id = String([
          colStart + x,
          rowStart + i,
          colStart + x + 1,
          rowStart + i + 1,
        ]);

        ids.push(id);
      }
    }

    return ids;
  };

  //获取大格子的随机起点
  // const getRandomBigGridStartPoint = (colC: number) => {   
  //   const bigColStart = randomNum(1, colC - 4);
  //   const bigRowStart = randomNum(1, totalCount / colC - 4);  
  //   return [bigColStart, bigRowStart];
  // };

  //固定在第一格
  const getRandomBigGridStartPoint = (colC: number) => {   
    const bigColStart = 1;
    const bigRowStart = 1;  
    return [bigColStart, bigRowStart];
  };

  //获取中格子的随机起点
  const getRandomMidGridStartPoint = (colC: number) => {
    const midColStart = randomNum(1, colC - 3);
   // const midRowStart = randomNum(1, totalCount / colC - 3);
    const midRowStart = randomNum(1, 5);
    return [midColStart, midRowStart];
  };

  //随机整数函数
  function randomNum(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return [screenSize, colCount, bigMatrix, [midMatrixA,midMatrixB,midMatrixC]];
}

export default useCoordinates;
