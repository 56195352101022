import { WorkDraftItem } from "types/work";
import { DrafWorkCardStatusStyle } from "./style";
import { Popover } from "antd";
import { RiQuestionFill } from "react-icons/ri";

function DrafWorkCardStatus({ item }: { item: WorkDraftItem }) {
  const popoverRefuseReason = (item: WorkDraftItem) => {
    return (
      <div>
        <span style={{ color: "rgb(244, 170, 47)" }}>驳回原因</span>
        <p>{item.refuseReason}</p>
      </div>
    );
  };
  
  return (
    <DrafWorkCardStatusStyle>
         {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
      {item.status === 0 && <div className="status">草稿</div>}
      {item.status === 1 && <div className="status">审核中</div>}
      {item.isContest === 1 && item.publishType === 1 && (
        <div className="status">阶段性作品</div>
      )}
      {item.status === 3 && (
        <div className="status refuse">
          <span>审核驳回</span>
          <Popover placement="bottom" content={() => popoverRefuseReason(item)}>
            <RiQuestionFill className="question"></RiQuestionFill>
          </Popover>
        </div>
      )}
      {item.isPublic === 0 && <div className="status">私密</div>}
   
    </DrafWorkCardStatusStyle>
  );
}

export default DrafWorkCardStatus;
