import { ShippingFeeTemplateListItem } from "types/product";
import { formatMoney } from "views/user-shop/productManage/create/fn";

export function findNextItem(array:Array<any>, currentItem:string) {
    const currentIndex = array.findIndex(item => item === currentItem);
    const nextIndex = currentIndex + 1;
    if (nextIndex >= array.length) {
      return array[0]; // Return the first item if the next item is not found
    }
    return array[nextIndex];
  }
  
  export function findPreviousItem(array:Array<any>, currentItem:string) {
    const currentIndex = array.findIndex(item => item === currentItem);
    const previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      return array[array.length - 1]; // Return the last item if the previous item is not found
    }
    return array[previousIndex];
}


export const renderTip =(item:ShippingFeeTemplateListItem)=>{

  return  <div className="content">
  <p>
    默认运费（除指定地区外）：{item.defaultRule.baseNum}件内
    {formatMoney( item.defaultRule.baseFee)}元，每增加
    {item.defaultRule.incNum}件，增加运费
    {formatMoney( item.defaultRule.incFee)}元。
    {item.defaultRule.freeNum > 0 &&
      `满${item.defaultRule.freeNum}件包邮`}
  </p>
  {item.specialRules.map((rule, index) => {
    return (
      <p key={index}>
        {rule.provinceList
          .map((item) => item.name)
          .join(", ")}
        <br />
        {`${rule.baseNum}件内${formatMoney( rule.baseFee)}元，每增加${rule.incNum}件，增加运费${formatMoney( rule.incFee)}元。满${rule.freeNum}件包邮`}
      </p>
    );
  })}
</div>
}
