import styled from "styled-components";

export const ForumSearchStyle = styled.div`

    .container{
        width: 1032px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 5fr 2fr;
        gap:14px;
        min-height: 100vh;
        .list-title {
            display: flex;
            margin-bottom: 20px;
            padding: 0 ${(props) => props.theme.space.paddingLG}px;
            span {
                padding: 0 5px;
            }
            .link {
                color: #808080;
                cursor: pointer;
                &:hover {
                    color: #FF9A16;
                }
            }
        }
        .left{
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            padding: ${(props) => props.theme.space.paddingLG}px 0;
            .meta{
                display: flex;
                justify-content: space-between;
                padding: ${(props) => props.theme.space.paddingSM}px ${(props) => props.theme.space.paddingLG}px;
                border-bottom: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
                .keyword{
                    max-width: 500px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .filter{

                    .filter-item{
                        cursor: pointer;
                        &:hover{
                            color: ${(props) => props.theme.colors.colorPrimaryActive};
                        }
                        &.active{
                            color: ${(props) => props.theme.colors.colorPrimaryActive};
                        }
                    }
                }
            }
        }
        .right{
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            padding: ${(props) => props.theme.space.paddingLG}px;
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};

        }
    }
`