import styled from "styled-components";

export const LoginModalStyle = styled.div`
.meta {
  color: ${(props) => props.theme.colors.colorTextSecondary};
  display: flex;
  .back{
    display: flex;
    align-items: center;
    margin-right: 20px;
    &:hover{
      color: ${(props) => props.theme.colors.colorPrimary};
      cursor: pointer;
    }
  }
  .em {
    color: ${(props) => props.theme.colors.colorPrimary};
    padding: 0 ${(props) => props.theme.space.paddingXS}px;
    font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
    cursor: pointer;
  }
}
.formContainer {
    position: relative;
    display: flex;
    /* box-shadow: ${(props) => props.theme.shadow.boxShadowSecondary}; */
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    /* height: 560px; */
    background-color: ${(props) => props.theme.colors.colorTextLightSolid};
    margin-bottom: 28px;
    
    .title {
      padding: ${(props) => props.theme.space.padding }px 0;
      text-align: center;
      font-size: ${(props) => props.theme.fontSizes.fontSizeHeading4}px;
      color: ${(props) => props.theme.colors.colorTextSecondary};
    }
    .groupTitle {
      padding: ${(props) => props.theme.space.padding * 1.5}px 0;
      text-align: center;
      .title {
        padding: 0;
        font-size: ${(props) => props.theme.fontSizes.fontSizeHeading4}px;
        color: ${(props) => props.theme.colors.colorTextSecondary};
      }
      .subTitle {
        padding: ${(props) => props.theme.space.paddingXS}px 0;
        color: ${(props) => props.theme.colors.colorTextSecondary};
      }
    }

    .bar {
      margin-top: ${(props) => props.theme.space.marginXS}px;
      width: 100%;
      background-color: ${(props) => props.theme.colors.colorBgLayout};
      padding: ${(props) => props.theme.space.paddingXS}px;
      color: ${(props) => props.theme.colors.colorTextSecondary};
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        padding: 0 ${(props) => props.theme.space.paddingXS}px;
        width: 114px;
        text-align: center;
      }
    }
    .divider {
      position: relative;
      height: 1px;
      margin: ${(props) => props.theme.space.margin * 2}px 0
        ${(props) => props.theme.space.margin * 2}px 0;
      .text {
        position: absolute;
        left: 50%;
        right: 50%;
        margin: 0 auto;
        top: -10px;
        background-color: #fff;
        display: block;
        padding: 0 ${(props) => props.theme.space.paddingSM}px;
        display: flex;
        justify-content: center;
        color: ${(props) => props.theme.colors.colorTextTertiary};
        transform: translateX(-47px);
        width: 90px;
        cursor: pointer;
      }
    }
    .div-transparent:before {
      content: "";
      position: absolute;
      top: 0;
      left: 5%;
      right: 5%;
      width: 90%;
      height: 1px;
      background-image: linear-gradient(
        to right,
        transparent,
        ${(props) => props.theme.colors.colorTextTertiary},
        transparent
      );
    }
    .left {
      width: 100%;
      padding: 0 ${(props) => props.theme.space.padding * 1.5}px;
      display: flex;
      flex-direction: column;

      justify-content: center;
      height: 100%;
      box-sizing: border-box;
      .icon {
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        color: ${(props) => props.theme.colors.colorTextTertiary};
      }
      .checkItem {
        margin: 0;
      }
      .submit {
        margin-top: ${(props) => props.theme.space.margin * 1.5}px;
      }
      .scan {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .userAgreement {
        color: ${(props) => props.theme.colors.colorTextTertiary};
        span {
          color: ${(props) => props.theme.colors.colorPrimary};
          font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
          cursor: pointer;
        }
      }
    }
    .left.wide {
      width: 380px;
      margin: 0 auto;
    }

  }
  .formContainer.full {
    width: 760px;
  }
`