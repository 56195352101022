import { useCallback, useEffect, useMemo, useState } from 'react'
import { UserWorksStyle } from './style'
import { apiUserWorkList } from 'api/user/work'
import { WorkListItem } from 'types/work'
import { useParams } from 'react-router-dom'
import { Pagination, Spin } from 'antd'
import { NoData } from 'components/no-data'
import HomePageWorkCard from 'components/uni-card/self-cards/homePageWorkCard'
import { isMobile, setWorkListParamsForNav } from 'utils/functions/common'
import useWindowSize from 'hooks/useWindowSize'

function UserWorks() {
  const { id } = useParams()
  const [workList, setWorkList] = useState<WorkListItem[]>()
  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [screenSize] = useWindowSize();

  const params = useMemo(() => {
    return {
      pageNumber,
      pageSize: 30,
    }
  }, [pageNumber])

  const getList = useCallback(() => {
    setLoading(true)
    apiUserWorkList({ userId: Number(id), ...params, onSale: 1 }).then((res) => {
      setWorkList(res.pageData)
      setTotal(res.totalSize)
    }).finally(() => {
      setLoading(false)
    })
  }, [params, id])

  const changePage = (e: number) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Use 'smooth' for smooth scrolling, or 'auto' for instant scrolling
    });
    setPageNumber(e);
  };

  useEffect(() => {
    const checkIsMobile = isMobile()
    if (checkIsMobile) {
      window.location.href = `https://m.ggac.com` + window.location.pathname
      return
    }
  }, [])

  useEffect(() => {
    getList()
  }, [getList])




const t_id = Date.now();

const saveWorkListParamsForNav = (index:number) => {
  const params_ = {number:index,...params,userId: Number(id),onSale: 1}
  setWorkListParamsForNav(params_,t_id);
};

  return (
    <UserWorksStyle>
      <Spin spinning={loading}>
        <div className='data-list' style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
          {workList?.map((workListItem, index) => {
            return <div onClick={() => saveWorkListParamsForNav(index)} key={index} >
            <HomePageWorkCard item={workListItem} t_id={t_id}></HomePageWorkCard>
            </div>
          })}
        </div>
        {
          workList?.length === 0 && <div style={{ padding: '100px 0' }}><NoData /></div>
        }
      </Spin>
      <Pagination
        style={{ textAlign: "center", marginTop: "28px" }}
        hideOnSinglePage={true}
        current={pageNumber}
        onChange={changePage}
        showSizeChanger={false}
        pageSize={params.pageSize}
        total={total}
      />
    </UserWorksStyle>
  )
}

export default UserWorks