import { getWorkCategory } from "api/work";
import { useEffect, useState } from "react";
import { CategoryItem } from "types/user/work";
import { DiscoverListStyle } from "./style";
import { Checkbox, Pagination, Select, Spin, Tabs } from "antd";
import { NoData } from "components/no-data";
import { WorkCategory } from "types/enums/work-category";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchOption } from "components/search-option";
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
import { GlobalSearchPostResponse } from "types/common";
import { apiGetDictList, postGlobalSearch } from "api/common";
import { queryUrl } from "utils/functions/common";
import PublicCard from "components/uni-card/public-cards/publicESCard";
import { CoverImageObjectCloudResize } from "utils/functions/file";

export const DiscoverList = () => {
  const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
  const [categoryId, setCategoryId] = useState(
    new URLSearchParams(window.location.search).get("categoryId") || -1
  );
  const [categoryPid, setCategoryPid] = useState(
    new URLSearchParams(window.location.search).get("categoryPid") || 10000
  );
  const [workList, setWorkList] = useState<GlobalSearchPostResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const navigate = useNavigate();
  const dataTable = new URLSearchParams(window.location.search).get(
    "dataTable"
  );
  const sortField =
    new URLSearchParams(window.location.search).get("sortField") || "";
  const timeField =
    new URLSearchParams(window.location.search).get("timeField") || "";
  const mediaType = new URLSearchParams(window.location.search).get(
    "mediaType"
  );
  const mediaCategory = new URLSearchParams(useLocation().search).get(
    "mediaCategory"
  );
  const pageNumber = Number(new URLSearchParams(useLocation().search).get("pageNumber") || 1)
  const pageSize = 48;
  const typeId = new URLSearchParams(useLocation().search).get("typeId");

  type AntSelectOption = {
    label: string;
    value: string | number;
  };
  const [mediaCategoryOptions, setMediaCategoryOptions] =
    useState<AntSelectOption[]>();

  // useEffect(() => {
  //   localStorage.setItem(
  //     "workIdsForNav",
  //     JSON.stringify(
  //       workList.filter((item) => {
  //         return item.dataTable === "work"
  //       }).map((item) => {
  //         return item.dataId;
  //       })
  //     )
  //   );
  // }, [workList]);

  const saveWorkIdsForNav = () => {
    localStorage.setItem(
      "workIdsForNav",
      JSON.stringify(
        workList.filter((item) => {
          return item.dataTable === "work"
        }).map((item) => {
          return item.dataId;
        })
      )
    );
  }

  useEffect(() => {
    setLoading(true);
    setWorkList([]);
  }, [
    categoryPid,
    categoryId,
    dataTable,
    sortField,
    timeField,
    mediaType,
    mediaCategory,
  ]);

  useEffect(() => {
    // 获取媒体分类列表
    apiGetDictList({ type: "mediaCategory" }).then((res) => {
      const options = res.map((item) => {
        return { value: String(item.code), label: item.name };
      });
      setMediaCategoryOptions(options);
    });
  }, []);

  useEffect(() => {
    const scrollableDiv = document.querySelector("#scrollableDiv")
    if (!scrollableDiv) {
      return
    }
    scrollableDiv.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pageNumber])

  // 切换分页
  const changePage = (e: any) => {
    // setCurrentPage(e)
    const query = queryUrl({
      pageNumber: e,
    });
    navigate(`?${query}`);
  }

  useEffect(() => {
    const params: any = {
      type: 1, hasSelected: 1
    }
    if (dataTable === 'article') {
      params.subType = 2
    }
    getWorkCategory(params)
      .then((res) => {
        res.forEach((item, index) => {
          let articleObj = {
            code: "article",
            coverUrl: "",
            id: -1,
            level: 2,
            name: "文章",
            pid: 1,
            rank: 1,
            type: WorkCategory.article,
            children: [],
          };
          articleObj.id = item.id;
          articleObj.pid = item.id;
          // res[index]?.children?.push(articleObj)
        });

        if (res.length === 0) {
          setCategoryList([])
          return
        }

        res.unshift(
          {
            code: "精选",
            coverUrl: "https://cdn-dev.ggac.com/site/menu/edit/coverImage/AbmcSwiCWRiHnk8sYCBkePjpyyxiCfS81694005646951.jpeg",
            id: 10000,
            level: 1,
            name: "精选",
            pid: 0,
            rank: 366,
            type: 1,
            children: []
          },
          {
            code: "全部",
            coverUrl: "https://cdn-dev.ggac.com/site/menu/edit/coverImage/AbmcSwiCWRiHnk8sYCBkePjpyyxiCfS81694005646951.jpeg",
            id: 1000,
            level: 1,
            name: "全部",
            pid: 0,
            rank: 366,
            type: 1,
            children: []
          },
        )

        setCategoryList(res);
        if (typeId) {
          setCategoryId(Number(typeId));
          const result = res.find((item) => {
            return item.children.find((sub) => sub.id === Number(typeId));
          });
          if (result) {
            setCategoryPid(result.id);
          }
          return;
        }
        // console.log(categoryPid)
        // console.log(res[1].id)
        if (dataTable === 'article') {
          setCategoryPid(res[0].id);
          return
        }
        // setCategoryPid(categoryPid || res[1].id);
        // setCategoryId(res[0].children[0].id)
      })
      .finally(() => {
        setLoading(false);
      });
  }, [typeId, dataTable]);

  useEffect(() => {
    // if (categoryId === -1) {
    //     return
    // }
    let params: any = {
      pageNumber,
      pageSize: pageSize,
      isPublic: 1,
    };

    if (categoryPid === 10000) {
      params.sortField = "recommendUpdateTime"
      params.isRecommend = 1
      delete params.categoryId
    } else if (categoryPid === 1000) {
      params.sortField = "lastSubmitTime"
      delete params.categoryId
    } else if (categoryId === -1 || !categoryId) {
      params.categoryId = Number(categoryPid);
    } else if (categoryId) {
      params.categoryId = Number(categoryId);
    } else if (!categoryId) {
      delete params.categoryId
      setCategoryPid(10000)
    }
    if (params.categoryId === 1000) {
      delete params.categoryId
    }

    if (params.categoryId === 10000) {
      params.isRecommend = 1
      delete params.categoryId
    }

    if (sortField) {
      params.sortField = sortField;
    }
    if (timeField) {
      params.timeField = Number(timeField);
    }

    if (dataTable) {
      params.dataTable = dataTable;
    }

    if (mediaType) {
      params.mediaType = Number(mediaType);
    }

    if (mediaCategory) {
      params.mediaCategory = Number(mediaCategory);
    }

    postGlobalSearch(params)
      .then((res) => {
        // setWorkList((arr) => uniqueArrayByDataId([...arr, ...res.pageData]));
        setWorkList([...res.pageData])
        setTotalSize(res.totalSize);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    categoryPid,
    categoryId,
    currentPage,
    dataTable,
    sortField,
    timeField,
    mediaType,
    mediaCategory,
    pageNumber
  ]);

  const onClickChooseFirst = (item: CategoryItem) => {
    if (item.id === 10000) {
      setCategoryId(10000);
      const query = queryUrl({
        categoryPid: 10000,
        categoryId: "",
        sortField: "recommendUpdateTime",
        pageNumber: 1
      });
      navigate(`?${query}`);
    } else if (item.id === 1000) {
      setCategoryId(1000);
      const query = queryUrl({
        categoryPid: 1000,
        categoryId: "",
        sortField: "lastSubmitTime",
        pageNumber: 1
      });
      navigate(`?${query}`);
    } else {
      setCategoryId(-1);
      const query = queryUrl({ categoryPid: item.id, categoryId: "", sortField: "recommendUpdateTime", timeField: "", mediaCategory: "", pageNumber: 1 });
      navigate(`?${query}`);
    }
    setCategoryPid(item.id);
    setCurrentPage(1);
  };

  const onClickChooseSecond = (item: CategoryItem) => {
    setCategoryPid(item.pid);
    let query: string;
    if (categoryId === item.id) {
      query = queryUrl({ categoryId: "", pageNumber: 1 });
      setCategoryId(-1);
    } else {
      query = queryUrl({ categoryPid: item.pid, categoryId: item.id, pageNumber: 1 });
      setCategoryId(item.id);
    }
    setCurrentPage(1);
    navigate(`?${query}`);
  };

  const handleChangeMediaType = (value: boolean) => {
    const query = queryUrl({ mediaType: value ? '2' : '', pageNumber: 1 });
    navigate(`?${query}`);
  };

  const loadMoreData = () => {
    if (workList.length >= totalSize) return;
    setCurrentPage(currentPage + 1);
  };

  const handleChangeMediaCategory = (value: string) => {
    const query = queryUrl({ mediaCategory: value, pageNumber: 1 });
    navigate(`?${query}`);
  };

  const changeIsArticle = (value: boolean) => {
    const query = queryUrl({ dataTable: value ? 'article' : '', pageNumber: 1 });
    navigate(`?${query}`);
  }

  return (
    <DiscoverListStyle onClick={() => saveWorkIdsForNav()}>
      <div className="category-search-container" id="scrollableDiv">
        <div className="guanggao-banner-wrapper">
          <GuanggaoBannerCarousel positionCode={9}></GuanggaoBannerCarousel>
        </div>
        <Spin spinning={loading}>
          <div className="search-wrapper">
            <Tabs
              size="large"
              activeKey={`${categoryPid}`}
              items={categoryList?.map((item) => {
                return {
                  label: (
                    <div
                      className="tab-name"
                      onClick={() => onClickChooseFirst(item)}
                    >
                      {item.name}
                    </div>
                  ),
                  key: String(item.id),
                  children: (
                    <div className="second-wrapper">
                      {item.children?.map((child) => {
                        return (
                          <div
                            onClick={() => onClickChooseSecond(child)}
                            className={`second-item ${categoryId === child.id ? "second-item-active" : ""
                              }`}
                            key={child.id}
                          >
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(${CoverImageObjectCloudResize(child.coverUrl, 500)})`,
                              }}
                            ></div>
                            <div className="name">{child.name}</div>
                          </div>
                        );
                      })}
                    </div>
                  ),
                };
              })}
            ></Tabs>
          </div>
          <div className="select">
            <div style={{ display: "flex" }}>
              <SearchOption isShowTypeList={false} />
              <Select
                style={{ width: 200, marginLeft: "15px" }}
                onChange={handleChangeMediaCategory}
                options={mediaCategoryOptions}
                allowClear
                placeholder="创作类型"
                value={mediaCategory}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox style={{ marginRight: "10px" }} checked={dataTable === "article" ? true : false} onChange={(e) => changeIsArticle(e.target.checked)}>文章</Checkbox>
              <Checkbox checked={mediaType === "2" ? true : false} onChange={(e) => handleChangeMediaType(e.target.checked)}>视频</Checkbox>
            </div>
          </div>
          <div className="list-wrapper">
            {workList.length === 0 && !loading && (
              <div style={{ marginTop: "200px" }}>
                <NoData></NoData>
              </div>
            )}
            {workList.length > 0 && (
              // <InfiniteScroll
              //   dataLength={workList.length}
              //   next={loadMoreData}
              //   hasMore={workList.length < totalSize}
              //   loader={<div style={{ display: 'flex', width: '100vw', justifyContent: 'center', height: '60px', alignItems: 'center' }}>
              //     <Spin />
              //   </div>}
              //   scrollableTarget="scrollableDiv"
              // >
              //   {workList?.map((item, index) => {
              //     return (
              <div className="list">
                {
                  workList.map((item, index) => {
                    return <PublicCard key={index} item={item}></PublicCard>
                  })
                }
              </div>

              //     );
              //   })}
              // </InfiniteScroll>
            )}
          </div>
        </Spin>
        {
          workList.length > 0 && < Pagination
            style={{ textAlign: "center", margin: "12px 0 42px 0" }}
            hideOnSinglePage={true}
            current={pageNumber}
            onChange={changePage}
            pageSize={pageSize}
            total={totalSize > 9600 ? 9600 : totalSize}
            showSizeChanger={false}
            showQuickJumper
          />
        }
      </div>
    </DiscoverListStyle>
  );
};
