import styled from "styled-components";

export const SlideBannerStyle = styled.div`
  position: relative;
  /* max-width: ${(props) => props.theme.space.maxContainer+200}px; */
  width: 100%;
  aspect-ratio: 1920/530;
  .slide-dots{
    .dot-item{
      &:hover{
        /* fill:  ${(props) => props.theme.colors.colorPrimary} !important; */
      }
      svg{
        height: 8%;
        width: 8px;
       
      }
    }
  }
  .slide {
    width: 100%;
    aspect-ratio: 1920/530;
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .card-view-container{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform:translateX(-50%);
      max-width: ${(props) => props.theme.space.maxContainer}px;
      width: 100%;
      padding-bottom: ${(props) => props.theme.space.padding}px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .slider-container {
    .slider-control-centerleft {
      display: none;
      button{
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.1) !important;
        backdrop-filter: blur(10px) brightness(100%) !important;
        color: ${(props) => props.theme.colors.colorTextLightSolid} !important;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG*1.5}px;
        margin-left: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          color: ${(props) => props.theme.colors.colorPrimary} !important;
          
        }
      }
    }
    .slider-control-centerright {
      display: none;
      button{
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.1) !important;
        backdrop-filter: blur(10px) brightness(100%) !important;
        color: ${(props) => props.theme.colors.colorTextLightSolid} !important;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG*1.5}px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          color: ${(props) => props.theme.colors.colorPrimary} !important;
        }
      }
    }
    &:hover {
      .slider-control-centerleft {
        display: block;

      }
      .slider-control-centerright {
        display: block;
      }
    }
  }

 
`;

export const CardNavStyle = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.space.margin}px;
  .nav-item {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    margin: 0 ${(props) => props.theme.space.marginXS}px;
    border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
    cursor: pointer;
    &.active {
      width: 32px;
      height: 10px;
    }
  }
`;

export const CardViewStyle = styled.div`
  margin-right:  ${(props) => props.theme.space.paddingSM}px;
  width: 220px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px) brightness(100%);
  border-radius: 50px 50px;
  padding: ${(props) => props.theme.space.paddingXS}px
    ${(props) => props.theme.space.padding}px;
    padding-left: 15px;
  color: ${(props) => props.theme.colors.colorTextLightSolid};
  display: flex;
  .avatar {

  }
  span {
  }
`;
