import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const ArticleListStyle = styled.div`
    .artilce-container {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        .guanggao-banner-wrapper{
            padding: 0 ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.paddingSM}px;
            
        }
        .line {
          width: 100vw;
          height: 50px;
          background-color: #232424 !important;
          margin-bottom: ${props=>props.theme.space.margin}px;
        }
        .article-type{
          display: flex;
          justify-content: center;
          margin-bottom: ${(props) => props.theme.space.marginLG}px;
          border-bottom: 1px solid #f0f0f0;
          min-width: 1250px;
          .list{
            padding-top:${(props) => props.theme.space.paddingLG}px;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 14px;
            .active{
              color: ${(props) => props.theme.colors.colorText} !important;
            }
            .active::after {
              content: "";
              position: absolute;
              bottom: -1px;
              min-width: 80%;
              height: 2px;
              background-color: ${props => props.theme.colors.colorPrimary};
             
            }
            .list-item:hover {
              color: ${(props) => props.theme.colors.colorText} !important;
              &::after {
                content: "";
                position: absolute;
                min-width: 80%;
                height: 2px;
                bottom: -1px;
                background-color: ${props => props.theme.colors.colorPrimary};
              }
            }
            .list-item{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: ${props=>props.theme.space.padding}px 10px;
                margin: 0;
                cursor: pointer;
                position: relative;
                text-align: center;
                font-size: 16px;
                color: #8f8f8f;
            }
          }
        }
        .select {
          padding: 0 ${(props) => props.theme.space.paddingLG + 4}px;
          display: flex;
          justify-content: space-between;
        }
        .list-wrapper{
            padding: ${(props) => props.theme.space.paddingLG}px;
            /* .infinite-scroll-component {
            display: grid;
            --auto-grid-min-size: 18rem;
            grid-template-columns: repeat(
              auto-fill,
              minmax(var(--auto-grid-min-size), 1fr)
            );
            gap: ${(props) => props.theme.space.paddingLG}px;
            .list-item {
              width: 100%;
            }
          } */
          .list {
            display: grid;
            --auto-grid-min-size: 19rem;
            /* grid-template-columns: repeat(
              auto-fill,
              minmax(var(--auto-grid-min-size), 1fr)
            ); */
            grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
            gap: 1rem;
            min-width: 1250px;
          }
        }
    }
`