import { useEffect, useState } from "react"
import { UserCertificatesStyle } from "./style"
import { apiGetMyCertificateList } from "api/user/certificate"
import { certificateItem } from "types/user/certificate"
import { UserBaseInfo } from "types/user"
import CertificateCard from "components/certificate-card"
import { NoData } from "components/no-data"

const UserCertificates = () => {

    const [list, setList] = useState<certificateItem[]>([])
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

    useEffect(() => {
        apiGetMyCertificateList({ userId: Number(userInfo.id) }).then(res => {
            setList(res)
        })
    }, [])

    return <UserCertificatesStyle>
        <div className="certificates">
            <div className="title">荣誉证书</div>
            <div className="list">
                {
                    list.map(item => {
                        return <CertificateCard detail={item} />
                    })
                }
            </div>
            {
                list.length === 0 && <div className="no-data">
                    <NoData />
                </div>
            }
        </div>
    </UserCertificatesStyle>
}

export default UserCertificates