import React, { CSSProperties, memo, useEffect, useState } from 'react';
import { Progress, Upload, message } from 'antd';
import { ItemStyle, SubUploadVideoListStyle } from "./style"
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle
} from 'react-beautiful-dnd';
import { UploadChangeParam } from 'antd/lib/upload';
import { ListItem } from 'views/work/components/comboUpload/type';
import { CosDirectoryType, MediaType } from 'types/enums/media-type';
import { getFileName } from 'utils/functions/common';
import { getFileUrlByCosUpload } from 'config/cos';
import { apiCreateMedia } from 'api/media';
import { ProgressInfo } from 'cos-js-sdk-v5';
import { useParams } from 'react-router-dom';
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri';
import Player from 'griffith';
import { ListManager } from 'react-beautiful-dnd-grid';

type Props = {
  disabled?: boolean
  value?: ListItem[]
  onChange?: (params: ListItem[]) => void
  maxLength?: number
  mark?: string
}


export const SubVideoWall: React.FC<Props> = memo(({ onChange: onFileChange, mark, ...props }) => {
  const [list, setList] = useState<ListItem[]>([])
  const { id } = useParams()

  useEffect(() => {
    setList(props.value! ?? [])
  }, [props.value])


  const onChange = async ({ fileList }: UploadChangeParam) => {
    let imgFile = fileList[fileList.length - 1].originFileObj;
    if (imgFile && imgFile.type !== 'video/mp4') {
      message.error('不支持该文件格式，请上传MP4格式的视频文件')
      return
    }
    const uploadUUid = getFileName(CosDirectoryType.workVideo, imgFile!.name);

    let newItem: ListItem = {
      id: 0,
      url: "",
      coverUrl: "",
      type: MediaType.video,
      key: uploadUUid,
      percent: 0,
      caption: '',
      mark: mark!
    };

    list.push(newItem)
    setList(list)

    const resultImg = await getFileUrlByCosUpload(
      imgFile as File,
      uploadUUid,
      onProgress
    );

    //绑定媒体，写入数据库
    const dataItem = await apiCreateMedia({
      coverUrl: "",
      dataId: Number(id),
      dataTable: "work",
      md5: resultImg.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
      name: imgFile!.name.split(".").shift() + "",
      rank: 0,
      size: imgFile!.size,
      type: MediaType.video,
      url: resultImg.url,
      mimeType: imgFile!.type,
      uuid: uploadUUid,
      caption: '',
      mark: mark!
    });
    newItem.url = dataItem.url;
    newItem.id = dataItem.id;
    newItem.percent = 100;
    list.forEach(item => {
      if (item.key === newItem.key) {
        item.percent = 100
      }
    })
    setList(list.concat([]))

    onFileChange!(list.concat([]));
  };

  //上传进度回调
  const onProgress = (progressData: ProgressInfo, key: string) => {
    list.forEach(item => {
      if (item.key === key) {
        item.percent = progressData.percent * 100 > 10 ? +(progressData.percent * 100 - 10).toFixed(2) : +progressData.percent.toFixed(2)
      }
    })
    setList(list.concat([]))
    onFileChange!(list.concat([]));
  };



  const reorder = (list: ListItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const onClickDelete = (media: ListItem) => {
    let arr = list.filter(item => item.id !== media.id)
    setList(arr)
    onFileChange!(arr);
  }



  const beforeUpload = () => {
    return false
  }
  const uploadButton = (
    <div>
      <RiAddLine />
      <div>上传</div>
    </div>
  );

  const reorderList = (sourceIndex: number, destinationIndex: number) => {
    console.log("reorderList", sourceIndex, destinationIndex);
    if (!list) return;
    if (destinationIndex === sourceIndex) {
      return;
    }
    const targetItem = list[sourceIndex];
    if (destinationIndex < sourceIndex) {
      const a = list.slice(0, destinationIndex)
      const b = list.slice(destinationIndex)
      const aRemoveTarget = a.filter(item => item.id !== targetItem.id);
      const bRemoveTarget = b.filter(item => item.id !== targetItem.id);
      const sortedList = [...aRemoveTarget, targetItem, ...bRemoveTarget]
      setList(sortedList);
      onFileChange!(sortedList);
    }

    if (destinationIndex > sourceIndex) {
      const a = list.slice(0, destinationIndex + 1)
      const b = list.slice(destinationIndex + 1)
      const aRemoveTarget = a.filter(item => item.id !== targetItem.id);
      const sortedList = [...aRemoveTarget, targetItem, ...b]
      setList(sortedList);
      onFileChange!(sortedList);
    }
  };

  const ListElement = ({ item }: { item: ListItem }) => {
    return <ItemStyle>
      <div className='card-wrapper'>
        {item.percent < 100 && <Progress type="circle" percent={item.percent} size={80} />}
        {(item.percent >= 100 || item.url) && <div className='image'  >
          <Player
            playbackRates={[]}
            disablePictureInPicture={true}
            hiddenVolume={true}
            hiddenQualityMenu={true}
            sources={{
              sd: {
                play_url:
                  item.url,
              },
            }}
            id={"player"}
            initialObjectFit="contain"
          ></Player>
          <div className='operation-container'>
            <div className='operation-item' onClick={() => onClickDelete(item)}> <RiDeleteBinLine size={16}></RiDeleteBinLine></div>
          </div>
        </div>}
      </div>
    </ItemStyle>

  }

  return (
    <SubUploadVideoListStyle>
      <div className='list-wrap'>
        {
          list && <ListManager
            items={list}
            direction="horizontal"
            maxItems={3}
            render={(item) => <ListElement item={item} />}
            onDragEnd={reorderList}
          />
        }

        <Upload
          {...props}
          listType="picture-card"
          disabled={props.disabled}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={onChange}
          multiple={false}
          accept='.mp4'
        >
          {uploadButton}
        </Upload>
      </div>
    </SubUploadVideoListStyle>
  );
});

