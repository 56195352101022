import { Cascader } from 'antd'
import { AddressSelectStyle } from './style'
import { useCallback, useEffect, useState } from 'react';
import { chineseAddressItem } from 'types/common';
import { apiGetChineseAddressList } from 'api/common';
type Props = {
    onChange?:(value: number[])=>void
    value?: number[],
    types?:string
}
function AddressSelect({onChange,value,types}:Props) {
  
    const [countryList, setCountryList] = useState<chineseAddressItem[]>([])
    const getAddress = useCallback(async () => {
        const result = await apiGetChineseAddressList(types?{types}:undefined)
        setCountryList(result)
    }, [])

    useEffect(() => {
        getAddress()
    },[])

   const onSelectChange=(value:any)=>{
    onChange &&  onChange(value)
   }
  return (
    <AddressSelectStyle className='AddressSelectStyle'>
        
        <Cascader options={countryList} onChange={onSelectChange} changeOnSelect fieldNames={{ 'label': "name", value: 'id' }} placeholder="请选择省市地区" value={value}/>

    </AddressSelectStyle>
  )
}

export default AddressSelect