import { Suspense, lazy, useEffect, useState } from "react";
import { ContestLayoutStyle } from "./style"
import { Outlet } from "react-router";
import { getContestDetail } from "api/contest";
import { ContestDetailResponse } from "types/contest";
import { isMobile } from "utils/functions/common";
import { FloatButton, Modal } from "antd";
import Ly3dPopup from "./sims";
import { useLocation } from "react-router-dom";
import SimsPopup from "./sims"

const ContestLayout = () => {
    let path = window.location.pathname
    const contestName = path.split("/")[2]
    const Comp = lazy(() => import('views/contest/' + contestName + '/banner'));
    const [detail, setDetail] = useState<ContestDetailResponse>()
    const [isShowSims, setIsShowSims] = useState(false)
    const [isShowSims2, setIsShowSims2] = useState(false)
    const location = useLocation()
    useEffect(() => {
        getContestDetail({ code: contestName }).then(res => {
            setDetail(res)
        })
    }, [])
    useEffect(() => {
        const checkIsMobile = isMobile()
        if (checkIsMobile) {
            window.location.href = `https://m.ggac.com/contest/` + contestName
            return
        }
    }, [])


    // useEffect(() => {
    //     setIsShowLy3dPopup2(false)
    // }, [location.pathname])



    useEffect(() => {
        if (contestName === 'sims') {
            window.addEventListener('scroll', (e: any) => {
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
                if (!isShowSims2) {
                    if (scrollTop > 1120) {
                        setIsShowSims(true)
                    } else {
                        setIsShowSims(false)
                    }
                }
            });
        }
    }, [contestName])

    const openPopup = () => {
        setIsShowSims(false)
        setIsShowSims2(true)
    }

    const close = () => {
        setIsShowSims(true)
        setIsShowSims2(false)
    }

    return <ContestLayoutStyle>
        <Suspense fallback={<></>}>
            <Comp detail={detail} />
        </Suspense>
        <Outlet context={{ detail: detail }}></Outlet>
        <FloatButton.Group shape="circle">
            <FloatButton.BackTop tooltip="回到顶部" />
        </FloatButton.Group>
        {
            !['/contest/sims/work', '/contest/sims/workprize'].includes(window.location.pathname) && isShowSims && !isShowSims2 && <div className="float">
                <img src="https://cdn-prd.ggac.com/ggac/submatch/sims/sims-popup-float.png" alt="" onClick={openPopup} />
            </div>
        }
        <Modal width={1297} footer={<></>} className="modal" getContainer={false} destroyOnClose={true} closable={false} centered={true} open={isShowSims2}>
            {
                detail && <SimsPopup close={() => close()} />
            }
        </Modal>
    </ContestLayoutStyle>
}

export default ContestLayout