import { get, post } from "api"
import { GetSubSettingRequest, SchoolIsApplyResponse, SignUpSubParams, SignUpSubSchoolParams, SignupField, SubDetail, SubSettingResponse, SubWorkCreateRequest, SubWorkDetail, SubWorkDetailRequest, UpdateSubWorkRequest, UploadMediaField } from "types/sub/application";


export const apiGetSignUpField = async (id: number) =>
    await get<never, SignupField[]>(`/api/contest_signup/field_list/${id}`);

export const apiPostSub = (params: SignUpSubParams) => {
    return post<SignUpSubParams, never>('/api/contest_signup/create', params)
}

export const apiSchoolIsApply = (contestId: number) => {
    return get<{ contestId: number }, SchoolIsApplyResponse>(`/api/contest_signup/query_apply_academy/${contestId}`)
}

export const apiPostSubSchool = (params: SignUpSubSchoolParams) => {
    return post<SignUpSubSchoolParams, never>('/api/contest_signup/add_academy', params)
}

export const apiGetSubDetail = (id: number) => {
    return get<never, SubDetail>(`/api/contest_signup/detail/${id}`)
}

export const apiGetWorkField = (id: number) => {
    return get<never, SignupField[]>(`/api/contest_work/field_list/${id}`);
}

export const apiGetMediaField = (params?: { mediaCategory?: number }) => {
    return get<{ mediaCategory?: number }, UploadMediaField[]>(`/api/contest_work/upload_media_type`, params)
}

export const apiCreateSubWork = (params: SubWorkCreateRequest) => {
    return post<SubWorkCreateRequest, { id: Number }>('/api/contest_work/create', params)
}

export const apiGetSubWorkDetail = (params: SubWorkDetailRequest) => {
    return get<SubWorkDetailRequest, SubWorkDetail>(`/api/contest_work/detail/${params.contestId}/${params.workId}`)
}

export const apiUpdateSubWork = (params: UpdateSubWorkRequest) => {
    return post('/api/contest_work/update', params)
}

export const apiGetSubSetting = (params: GetSubSettingRequest) => {
    return get<GetSubSettingRequest, SubSettingResponse>(`/api/contest/detail`, params)
}


