import styled from "styled-components";

export const HeaderStyle = styled.div`
  .wrap {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
  }
  .black {
    /* background-color: #232424 !important; */
    
  }

  .header {
    height: 50px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px) brightness(100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${(props) => props.theme.space.paddingLG}px;
    position: relative;
    &-left {
      /* background-color: ${(props) => props.theme.colors.colorPrimary}; */
      position: absolute;
      top: 0;
      left: 0;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      z-index: 10;
      cursor: pointer;
      /* border-radius: 0 12px 12px 0; */
      .logo {
        height: 30px;
        img{
          cursor: pointer;
        }
      }
    }
    &-center {
      display: flex;
      width: 100%;
      height: 100%;
    }
    &-right {
      .search-btn {
        padding: 0 ${(props) => props.theme.space.padding}px;
        font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px;
        line-height: 50px;
        color: ${(props) => props.theme.colors.colorTextLightSolid};
        cursor: pointer;
        transform: translateY(1px);
        &:hover {
          color: ${(props) => props.theme.colors.colorPrimary};
        }
      }
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;

export const HeaderMorphStyle = styled.div`
  box-shadow:${(props) => props.theme.shadow.boxShadow} ;
  .wrap {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
  }
  .header {
    height: 50px;
    width: 100%;
    background-color:rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px) brightness(100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${(props) => props.theme.space.paddingLG}px;
    position: relative;
    &-left {
      position: absolute;
      top: 0;
      left: 0;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      cursor: pointer;
      .logo {
        height: 30px;
        cursor: pointer;
      }
    }
    &-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .input-wrap {
        border:1px solid #ccc !important;
      }
      input::placeholder {
		    color: #ccc !important;
	    }
      input {
        color: #000 !important;
      }
      .ant-input-group-addon {
        .anticon {
          color: #ccc !important;
        }
      }
      .ant-btn-default {
        border-left-color: #ccc !important;
      }
      .search-icon {
        color: #ccc !important;
      }
      .container_ {
        width: max-content !important;
        .wrap {
          width: max-content !important;
        }
      }
    }
    &-right {
      .search-btn {
        padding: 0 ${(props) => props.theme.space.padding}px;
        font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px;
        line-height: 50px;
        color: ${(props) => props.theme.colors.colorBlack6Ggac};
        cursor: pointer;
        transform: translateY(1px);
        &:hover {
          color: ${(props) => props.theme.colors.colorPrimary};
        }
      }
      .notification{
        .icon{
          color: ${(props) => props.theme.colors.colorBlack6Ggac} !important;
          padding-top: 2px;
        }
        
      }
      .authed{
        .upload{
          color: ${(props) => props.theme.colors.colorBlack6Ggac} !important;
          padding-top: 3px;
        }
      }
      position: absolute;
      right: 24px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;
