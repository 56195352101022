import styled from "styled-components"

export const AccountStyle = styled.div`
padding: ${(props) => props.theme.space.paddingLG}px;
    .account {
        max-width: 600px;
        border: 1px solid ${(props) => props.theme.colors.colorBorder};
        border-radius:  ${(props) => props.theme.border.borderRadius}px;
        .title {
            background-color: ${(props) => props.theme.colors.colorFillQuaternary};
            color: #2b2e36;
            padding: ${(props) => props.theme.space.padding}px;
            font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        }
        .content {
            &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 50px;
                padding: ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
                cursor: pointer;
                &:hover{
                    background-color: ${(props) => props.theme.colors.colorFillQuaternary};
                }   
                .label {
                    max-width: 200px;
                }
                .value{
                    color:  ${(props) => props.theme.colors.colorTextSecondary};
                }
                .btn {
                  
                }
            }
        }
    }
`