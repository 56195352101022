import styled from "styled-components";

export const ExhibitionListStyle = styled.div`
padding-top: 50px;
.guanggao-banner-wrapper{
  padding: ${(props) => props.theme.space.paddingSM}px 0;
  width: ${(props) => props.theme.space.mainContainer}px;
  margin: 0 auto;
}
@media screen and (max-width: 1440px) {
    .event-item {
        width: 585px !important;
        .cover {
            height: 280px !important;
        }
    }
}
.page-title{
    width: ${(props) => props.theme.space.mainContainer}px;
    margin: 0 auto;
    text-align: center;
    font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px;
    padding: ${props => props.theme.space.paddingLG}px 0;
}
.event-wrap {
    width: ${(props) => props.theme.space.mainContainer}px;
    margin: 0 auto;
    padding: ${props => props.theme.space.paddingLG}px 0;
}
.title_ {
    font-size: 16px;
    font-weight: bold;
    margin-top: ${(props) => props.theme.space.marginLG * 2}px;
    margin-bottom: ${(props) => props.theme.space.marginLG}px;
  }
  .event {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${(props) => props.theme.space.paddingLG}px;
    margin-bottom:${(props) => props.theme.space.marginLG}px;
    &-item {
      width: 100%;
      border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      box-sizing: border-box;
      &:hover{
        cursor: pointer;
            box-shadow: ${(props) => props.theme.shadow.boxShadowThin};
           
        }
      .cover {
        /* aspect-ratio: 10/4; */
        height: 327px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
       
        border-radius: ${(props) => props.theme.border.borderRadius}px
          ${(props) => props.theme.border.borderRadius}px 0 0;
        overflow: hidden;
       
        .date {
          position: absolute;
          top: 20px;
          right: 0;
          height: 32px;
          color: #fff;
          line-height: 32px;
          text-align: center;
          padding: 0 ${(props) => props.theme.space.padding}px;
          background: rgba(0, 0, 0, 0.6);
          z-index: 2;
          border-radius: ${(props) => props.theme.border.borderRadiusSM}px 0 0 ${(props) => props.theme.border.borderRadiusSM}px; 
        }
      }
      .info {
        padding: ${(props) => props.theme.space.paddingLG}px;
        background: rgb(244, 244, 244);
        border-radius: 0 0 ${(props) => props.theme.border.borderRadius}px
          ${(props) => props.theme.border.borderRadius}px;
        overflow: hidden;
        height: 107px;
        .title {
          color: ${(props) => props.theme.colors.colorBlack6Ggac};
          font-size: 16px;
        }
        .desc {
          color: ${(props) => props.theme.colors.colorBlack3Ggac};
          overflow: hidden;
          box-sizing: border-box;
          margin-top: ${(props) => props.theme.space.marginXS}px;
          white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70ch;

        }
      }
    }
  }
`