import { Button, Modal, Spin, message } from "antd";
import { WorkShareStyle } from "./style";
import { RiArrowLeftSLine, RiArrowRightSLine, RiLinkM, RiQqFill, RiStarSmileFill, RiWeiboFill } from "react-icons/ri";
import { getFileName, share, shareInfoType } from "utils/functions/common";
import copy from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";
import { getWorkShareList } from "api/work";
import { WorkShareInfo } from "types/user/work";
import 'cropperjs/dist/cropper.css';
import ImgCrop from 'react-cropper';
import { CosDirectoryType } from "types/enums/media-type";
import { getFileUrlByCosUpload } from "config/cos";
import { useKeenSlider } from "keen-slider/react";
import domtoimage from 'dom-to-image-more';

type Props = { isShow?: boolean; onClose: () => void, shareType: string, info: shareInfoType, shareUrl?: string, workId: number, shareImageInfo?: { qrCode: string, shareImageUrl: string } };
function GlobalWorkShare({ isShow = false, onClose, info, shareImageInfo, shareUrl, workId }: Props) {
    const [shareWorkDetail, setShareWorkDetail] = useState<WorkShareInfo>()
    const [tabId, setTabId] = useState(1)
    const [cropImageShow, setCropImageShow] = useState(false)
    const [selectCropImage, setSelectCropImage] = useState("") // 选择裁剪图片
    const [selectImage, setSelectImage] = useState("") // 已选图片
    const [cropedImage, setCropedImage] = useState("")
    const [initAspect, setInitAspect] = useState(750 / 960)
    const [aspect, setAspect] = useState(750 / 960)
    const [saveImgLoading, setSaveImgLoading] = useState(false)
    const cropperRef = useRef() as React.MutableRefObject<any>;
    const [prizeWord, setPrizeWord] = useState("")
    const [nowDateArr, setNowDateArr] = useState<string[]>([])
    const toShare = async (type: string) => {
        if (type === 'copy') {
            copy(shareUrl || document.location.href)
            message.success("复制成功")
            return
        }
        setSaveImgLoading(true)
        const node = document.getElementById('my-image');
        let croppedFile: any
        try {
            const img = await domtoimage.toBlob(node!, { quality: 1, scale: 2.5 });
            croppedFile = new File([img], 'crop-image' + Date.now(), {
                type: "png",
                lastModified: Date.now(),
            });
        } catch {
            setSaveImgLoading(false)
        }
        let urlKey = getFileName(CosDirectoryType.workShareCrop, croppedFile.name)
        getFileUrlByCosUpload(croppedFile!, urlKey).then(res => {
            share(type, "workDetial", { ...info }, res.url)
        }).finally(() => {
            setSaveImgLoading(false)
        })
    }

    useEffect(() => {
        getWorkShareList(workId).then(res => {
            setShareWorkDetail(res)
            setSelectImage(res.imageList[0].originalCoverUrl || res.imageList[0].url)
            setSelectCropImage(res.imageList[0].originalCoverUrl || res.imageList[0].url)
            setCropedImage(res.imageList[0].originalCoverUrl || res.imageList[0].url)
        })
    }, [workId])

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        let month: string = (date.getMonth() + 1).toString();
        month = Number(month) < 10 ? '0' + month : month;
        var day: string = date.getDate().toString();
        day = Number(day) < 10 ? '0' + day : day;
        setNowDateArr([year.toString(), month.toString(), day.toString()])
    }, [cropedImage])

    useEffect(() => {
        if (!shareWorkDetail) {
            return
        }
        const filterPrizeList = shareWorkDetail.prizeList.filter((item) => item.type !== 2);
        if (shareWorkDetail.prizeList && shareWorkDetail.prizeList.length === 0) {
            setPrizeWord((shareWorkDetail.contestName || '') + `【海选作品】`);
        } else {
            const result = shareWorkDetail.prizeList.find((item) => item.type === 2);
            if (!!result) {
                let word: string = ''
                shareWorkDetail.prizeList.forEach((item) => {
                    if (item.type === 2) {
                        word = word + `【${item.name}】`
                    }
                });
                setPrizeWord('荣获' + shareWorkDetail.contestName + word)
            } else {
                if (filterPrizeList.length > 0) {
                    setPrizeWord(shareWorkDetail.contestName + `【${filterPrizeList[0].name}】`);
                }
            }
        }
    }, [shareWorkDetail])

    const templateList = [
        { name: "默认", id: 1, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon9-16.png", aspect: 750 / 960, value: "defaut" },
        { name: "16:9", id: 2, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon16-9.png", aspect: 16 / 9, value: "16 / 9" },
        { name: "9:16", id: 3, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon9-16.png", aspect: 9 / 16, value: "9 / 16" },
        { name: "3:4", id: 4, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon3-4.png", aspect: 3 / 4, value: "3 / 4" },
        { name: "4:3", id: 5, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon3-4.png", aspect: 4 / 3, value: "3 / 4" },
        { name: "1:1", id: 6, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon1-1.png", aspect: 1 / 1, value: "1 / 1" },
        { name: "画历", id: 7, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon-date.png", aspect: 751 / 1000, value: "calendar" },
        { name: "拼图", id: 8, cover: "https://cdn-prd.ggac.com/ggac/icon/work-share/work-share-icon-jigsaw.png", aspect: 0, value: "jigsaw" },
    ]

    const clickTemplate = (value: number) => {
        if (value === 0) {
            setAspect(0)
            setInitAspect(0)
            return
        }
        setAspect(value)
        setCropImageShow(true)
    }

    const handleSaveImg = () => {
        cropperRef.current.cropper.getCroppedCanvas().toBlob((blob: any) => {
            const splitArr = cropedImage.split("/")
            const splitArr2 = cropedImage.split(".")
            const croppedFile = new File([blob], splitArr[splitArr.length - 1], {
                type: splitArr2[splitArr2.length - 1],
                lastModified: Date.now(),
            });
            setSaveImgLoading(true)
            let urlKey = getFileName(CosDirectoryType.workShareCrop, croppedFile.name)
            getFileUrlByCosUpload(croppedFile!, urlKey).then(res => {
                setCropedImage(res.url)
                setCropImageShow(false)
                message.success("上传成功")
                setInitAspect(aspect)
                setSelectImage(selectCropImage)
            }).finally(() => {
                setSaveImgLoading(false)
            })
        })
    }

    const checkTab = (id: number) => {
        if (id === tabId) return
        setTabId(id)
    }

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        mode: "free-snap",
        initial: 0,
        slides: {
            perView: 6,
            spacing: 10,
        }
    });

    const [sliderRef2, instanceRef2] = useKeenSlider<HTMLDivElement>({
        mode: "free-snap",
        initial: 0,
        slides: {
            perView: 6,
            spacing: 10,
        }
    });

    const move = (type: string) => {
        if (type === "prev") {
            instanceRef.current?.prev();
        }
        if (type === "next") {
            instanceRef.current?.next();
        }
    };

    const move2 = (type: string) => {
        if (type === "prev") {
            instanceRef2.current?.prev();
        }
        if (type === "next") {
            instanceRef2.current?.next();
        }
    };

    useEffect(() => {
        if (!isShow) {
            return
        }
        setTimeout(() => {
            instanceRef2 && instanceRef2.current?.update();
        }, 20)
        instanceRef && instanceRef.current?.update();
    }, [shareWorkDetail, isShow])

    const checkCropImage = (detail: { originalCoverUrl: string, url: string }) => {
        setSelectCropImage(detail.originalCoverUrl || detail.url)
        setCropImageShow(true)
    }

    const downloadImage = async () => {
        setSaveImgLoading(true)
        const node = document.getElementById('my-image');
        try {
            const img = await domtoimage.toPng(node!, { quality: 1, scale: 2.5 });
            let image = new Image()
            image.src = img
            image.setAttribute("crossOrigin", "anonymous")
            image.onload = () => {
                let c = document.createElement("canvas")
                c.width = image.width
                c.height = image.height
                c.getContext("2d")?.drawImage(image, 0, 0, image.width, image.height)
                let a = document.createElement("a")
                a.download = 'crop-image' + Date.now()
                a.href = c.toDataURL("image/png")
                a.click()
                setSaveImgLoading(false)
            }
        } catch {
            setSaveImgLoading(false)
        }
    }

    return (
        <Modal
            className="modal"
            onCancel={onClose}
            footer={<></>}
            title="分享"
            destroyOnClose={true}
            centered={true}
            open={isShow}
            width={720}
        >
            <WorkShareStyle workLength={(shareWorkDetail && shareWorkDetail?.imageList.length) || 0}>
                <Spin spinning={saveImgLoading} tip="图片合成中，请稍等">
                    <div className="container_">
                        <div className="left-wrap">
                            <div className="left" id="my-image" onClick={() => downloadImage()}>
                                {
                                    initAspect === 0 && <div className="jigsaw">
                                        <div className={`jigsaw-item jigsaw-item-1`} style={{ backgroundImage: `url(${shareWorkDetail?.imageList[0].originalCoverUrl || shareWorkDetail?.imageList[0].url})` }} />
                                        <div className="jigsw-wrap">
                                            {
                                                shareWorkDetail?.imageList.slice(1, 6).map((item, index) => {
                                                    return <div className={`jigsaw-item jigsaw-item-${index + 2}`} key={item.url} style={{ backgroundImage: `url(${item.originalCoverUrl || item.url})` }} />
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    initAspect !== 0 && <div style={{ aspectRatio: initAspect, backgroundImage: `url(${cropedImage})` }} className="share-img" />
                                }

                                {
                                    [751 / 1000].includes(initAspect) && <div className="user user2">
                                        <div className="user-left">
                                            <div className="title">{shareWorkDetail?.title}</div>
                                            <div className="user-detail">
                                                <div className="user-cover" style={{ backgroundImage: `url(https://cdn-prd.ggac.com/ggac/icon/work-share/share-logo1.png)` }}></div>
                                                <div className="user-name">{shareWorkDetail?.username}</div>
                                            </div>
                                        </div>
                                        <div className="user-right">
                                            <div className="day">{nowDateArr[2]}</div>
                                            <div className="year">{nowDateArr[0]}.{nowDateArr[1]}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    ![751 / 1000, 16 / 9, 9 / 16, 1 / 1, 3 / 4, 4 / 3].includes(initAspect) && <div className="user">
                                        <div className="user-left">
                                            <div className="title">{shareWorkDetail?.title}</div>
                                            <div className="prize-word">{prizeWord}</div>
                                            <div className="user-detail">
                                                <div className="user-cover" style={{ backgroundImage: `url(${shareWorkDetail?.avaterUrl})` }}></div>
                                                <div className="user-name">{shareWorkDetail?.username}</div>
                                            </div>
                                        </div>
                                        <div className="user-right">
                                            <img src={shareWorkDetail?.qrCode} alt="" className="code" />
                                            <div>长按浏览作品</div>
                                        </div>
                                    </div>
                                }
                                {
                                    [16 / 9, 9 / 16, 1 / 1, 3 / 4, 4 / 3].includes(initAspect) && <div className="user user3">
                                        <div className="user-detail">
                                            <div className="user-cover" style={{ backgroundImage: `url(https://cdn-prd.ggac.com/ggac/icon/work-share/share-logo.png)` }}></div>
                                            <div className="user-name">{shareWorkDetail?.username}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="right">
                            <div className="up">
                                <span>点击图片下载到本地即可分享</span>
                            </div>
                            <div className="wrap">
                                {
                                    shareImageInfo?.qrCode && <div className="qr-code">
                                        <img src={shareImageInfo?.qrCode} alt="" />
                                        <span>分享给微信好友</span>
                                    </div>
                                }
                                <div className="down">
                                    <div className="item" onClick={() => toShare('copy')}>
                                        <div className="icon">
                                            <RiLinkM></RiLinkM>
                                        </div>
                                        <span>复制链接</span>
                                    </div>
                                    <div className="item" onClick={() => toShare('sina')}>
                                        <div className="icon" style={{ background: 'rgb(194, 53, 47)' }}>
                                            <RiWeiboFill></RiWeiboFill>
                                        </div>
                                        <span>新浪微博</span>
                                    </div>
                                    <div className="item" onClick={() => toShare('qq')}>
                                        <div className="icon" style={{ background: 'rgb(110, 179, 234)' }}>
                                            <RiQqFill></RiQqFill>
                                        </div>
                                        <span>QQ</span>
                                    </div>
                                    <div className="item">
                                        <div className="icon" onClick={() => toShare('qzone')} style={{ background: 'rgb(241, 208, 96)' }}>
                                            <RiStarSmileFill></RiStarSmileFill>
                                        </div>
                                        <span>QQ空间</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab">
                        <div className={`tab-item ${1 === tabId ? 'tab-active' : ''}`} onClick={() => checkTab(1)}>更换模板</div>
                        {
                            initAspect !== 0 && <div className={`tab-item ${2 === tabId ? 'tab-active' : ''}`} onClick={() => checkTab(2)}>更换图片</div>
                        }
                    </div>
                    {
                        tabId === 1 && <div className="work-wrap">
                            <div className="control prev" onClick={() => move2("prev")}>
                                <RiArrowLeftSLine></RiArrowLeftSLine>
                            </div>
                            <div className="template keen-slider" ref={sliderRef2}>
                                {
                                    templateList.map(item => {
                                        return <div onClick={() => clickTemplate(item.aspect)} className={`template-item keen-slider__slide icon-${item.id} ${item.aspect === initAspect ? 'active' : ''}`} key={item.id}>
                                            <div className="icon" style={{ backgroundImage: `url(${item.cover})`, transform: `${item.aspect === 4 / 3 ? 'rotate(90deg)' : 'none'}` }} key={item.id}></div>
                                            <div className="name">{item.name}</div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="control next" onClick={() => move2("next")}>
                                <RiArrowRightSLine></RiArrowRightSLine>
                            </div>
                        </div>
                    }
                    {
                        tabId === 2 && !!shareWorkDetail && <div className="work-wrap">
                            {
                                shareWorkDetail.imageList.length > 6 && <div className="control prev" onClick={() => move("prev")}>
                                    <RiArrowLeftSLine></RiArrowLeftSLine>
                                </div>
                            }
                            <div className="work keen-slider" ref={sliderRef}>
                                {
                                    shareWorkDetail.imageList.map(item => {
                                        return <div key={item.url} onClick={() => checkCropImage(item)} className={`work-item keen-slider__slide ${selectImage === (item.originalCoverUrl || item.url) ? 'active' : ''}`} style={{ backgroundImage: `url(${item.originalCoverUrl || item.url})` }}></div>
                                    })
                                }
                            </div>
                            {
                                shareWorkDetail.imageList.length > 6 && <div className="control next" onClick={() => move("next")}>
                                    <RiArrowRightSLine></RiArrowRightSLine>
                                </div>
                            }
                        </div>
                    }
                </Spin>

                <Modal
                    title="图片裁剪"
                    open={cropImageShow}
                    destroyOnClose={true}
                    onCancel={() => setCropImageShow(false)}
                    maskClosable={false}
                    footer={[
                        <Button type="primary" loading={saveImgLoading} onClick={handleSaveImg}>
                            确定
                        </Button>,
                        <Button onClick={() => setCropImageShow(false)}>
                            取消
                        </Button>,
                    ]}
                >
                    <ImgCrop
                        ref={cropperRef}
                        guides={false}
                        background={false}
                        zoomable={false}
                        rotatable={false}
                        dragMode="none"
                        aspectRatio={aspect}
                        src={selectCropImage} />
                </Modal>

            </WorkShareStyle>
        </Modal >
    );
}

export default GlobalWorkShare;
