import { Modal, Form, Input, Button, message } from "antd"
import { BindEmailStyle } from "./style"
import SendCode from "../sendCode"
import { apiBindEmail } from "api/user"
import { useState } from 'react'

type appendixModalType = {
    params: {
        visible: boolean,
        visibleChange: (visible: boolean, needRefesh?: boolean) => void,
        BindEmailSuccess: () => void,
    }
}

const BindEmail = (props: appendixModalType) => {
    const [form] = Form.useForm<any>();
    const { params } = props
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const onCancel = () => {
        params.visibleChange(false)
        form.setFieldsValue({
            email: "",
            verCode: ""
        })
    }

    const changeEmail = () => {
        setEmail(form.getFieldValue('email'))
    }

    // 提交form
    const submit = () => {
        form.validateFields().then(async res => {
            setIsLoading(true)
            apiBindEmail({
                email: form.getFieldValue('email'),
                verCode: form.getFieldValue('verCode')
            }).then(() => {
                message.success('绑定成功')
                params.BindEmailSuccess()
                onCancel()
            }).finally(() => {
                setIsLoading(false)
            })
        })
    }
    return <BindEmailStyle>
        <Modal className='modal' destroyOnClose={true} title='绑定邮箱' centered={true} footer={null} getContainer={false} onCancel={onCancel} open={params.visible} width={420}>
            <Form form={form} className="form">
                <Form.Item name='email' label="邮箱"
                    rules={[{ required: true, message: "请输入邮箱地址" },
                    {
                        pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                        message: '邮箱格式不正确',
                    },
                    {
                        max: 50,
                        message: '邮箱不得超过50字符',
                    },
                    ]}>
                    <Input placeholder="请输入邮箱地址" onChange={() => changeEmail()} />
                </Form.Item>
                <Form.Item
                    name="verCode"
                    label="验证码"
                    rules={[
                        {
                            required: true,
                            message: "请填写验证码",
                        },
                        {
                            pattern: /^\d{4}$/,
                            message: "验证码为4位数字",
                        },
                    ]}
                >
                    <div className="code">
                        <Input maxLength={4} className="verCode" placeholder="请输入短信验证码" />
                        <SendCode value={email} type="email" />
                    </div>
                </Form.Item>
                <div className="btn"><Button loading={isLoading} type="primary" onClick={() => submit()} block>提交</Button></div>
            </Form>
        </Modal>
    </BindEmailStyle>
}

export default BindEmail