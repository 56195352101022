import styled from "styled-components";

export const RewardItemStyle = styled.div`
    .detail {
        border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
        padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
        min-height: 44px;
        cursor: pointer;
        .title {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            &-word {
                min-width: 0;
                flex: 1;
                font-size: 18px;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .price {
                display: flex;
                color: #FF9A16;
                gap: 10rpx;
                align-items: center;
                flex: 0 0 auto;
                font-size: 15px;
                &-icon {
                    background: url(https://cdn-prd.ggac.com/ggac/reward/reward-price.png) center center no-repeat;
                    background-size: 100% 100%;
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                }
            }
        }

        .desc {
            color: #808080;
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            align-items: center;
            gap: 35px;

            button {
                width: 74px;
                padding: 7px 0;
            }
            
            &-word {
                min-width: 0;
                flex: 1;
                font-size: 18px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 13px;
                word-wrap: break-word;
                overflow-wrap: break-word; /* 标准属性 */
                word-break: break-all; /* 确保单词内部也可以断开 */
            }
            .btn {
                font-size: 12px;
                color: #000;
                background: #ffc712;
                border-radius: 50px;
                height: 25px;
                line-height: 25px;
                width: 68px;
                text-align: center;
                cursor: pointer;
            }
        }

        .image {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;
            .ant-image {
                width: calc((100% - 4 * 10px) / 5);
            }
            img {
                object-fit: cover;
                border-radius: 10px;
                width: 100%;
                aspect-ratio: 1;
            }
        }
    }
`