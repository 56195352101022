import { UserBaseInfo } from "types/user"
import { ApplySuccessStyle } from "./style"
import { Button, message } from "antd"
import copy from "copy-to-clipboard"

const ApplySuccess = ({ shareImage }: { shareImage: string}) => {
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

    const copyText = () => {
        copy(`你获大奖，我得红包!体验双倍快乐~ 立即访问 https://www.ggac.com/2024/regulation/detail?invitationCode=${userInfo.id}，填写邀请码：${userInfo.id}报名`)
        message.success("复制成功")
    }

    const toLink = (value: string) => {
        window.open(value)
    }

    

    return <ApplySuccessStyle>
        <div className="applicant-sucess">
            <div className="left">
                <div className="left-tip">点击鼠标右键-图片另存为 保存图片即可分享</div>
                <img src={shareImage} className="share-image" />
            </div>
            <div className="right">
                <div className="title">
                    <div>开始探索G站!</div>
                </div>
                <div className="sub-title">
                    与百万CG人一起<span onClick={() => toLink("/explore?categoryPid=10000&sortField=recommendUpdateTime")}>刷作品</span>，
                    <span onClick={() => toLink("/forum/index")}>聊八卦</span>。
                    挖掘<span onClick={() => toLink("/mall/market")}>宝藏好物</span>，<br />
                    快乐<span onClick={() => toLink("https://www.huilahuila.com/#/")}>学习进步</span>。快来分享你的个人创作吧!
                </div>
                <div className="title">
                    <div>邀请好友一起参赛！</div>
                </div>
                <div className="sub-title">
                    <div>你获大奖，我得红包!体验双倍快乐~</div>
                    <div>立即访问 https://www.ggac.com/2024/regulation/detail</div>
                    <div>填写邀请码：{userInfo.id} 报名</div>
                </div>
                <Button type="primary" onClick={copyText}>一键复制</Button>
                <div className="tip">加入GGAC官方交流群了解更多赛事信息</div>
                <div className="img-list">
                    <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-detail-code1.png" alt="" />
                    <img src="https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-detail-code5.png" alt="" />
                </div>
            </div>
        </div>

    </ApplySuccessStyle>
}

export default ApplySuccess