import { Upload, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { RiAddLine } from 'react-icons/ri';
import LoadingIcon from 'ui-lib/loading';

type VideoUploadProps = {
    limit?: number,
    width?: number,
    height?: number,
	value?: {
      coverUrl?: string
      videoUrl?: string,
      file?: Blob,
      videoRation?: number
	}
	isSquare?: boolean,
	onChange?: (params: {videoUrl: string; file: File | undefined}) => void
  disabled?: boolean
}

export const VideoUpload = (props: VideoUploadProps)=> {
  const [videoUrl, setVideoUrl] = useState<string>(props.value!.videoUrl ?? '')
  const [loading, setLoading] = useState<boolean>(false)

  const triggerChange = (changedValue: { videoUrl: string; file: File| undefined, coverUrl: string, videoRatio: number }) => {
    props.onChange?.({
      ...props!.value,
      ...changedValue,
    });
  };

  useEffect(()=>{
	  setVideoUrl(props.value!.videoUrl ?? "")
	  if(props.value!.videoUrl) {
      if(!props.value?.coverUrl) {
        let videoCover = 'https://cdn-prd.ggac.com/ggac/assets/image/icon/video-bg-icon0.svg'
        setFileList([{
                      uid: '1',
                      url: videoCover,
                      name: 'image.png'
                    }])
      } else {
        setFileList([{
          uid: '1',
          url: props.value.coverUrl,
          name: 'image.png'
        }])
      }
      
	  } else {
		  setFileList([])
	  }
  }, [props.value])

  const beforeUpload = (file: File) => {
	  setLoading(true)
  	  const isVideo = file.type === 'video/mp4' 
      if (!isVideo) {
        message.error('只允许上传 mp4 格式的文件!');
        setLoading(false)
		return 
  	}
      let isLimit = true
      isLimit =  file.size / 1024 / 1024 <= (props.limit! ?? 200);
      if (!isLimit) {
        	message.error('视频大小超出限制');
          setLoading(false)
          return 
      }
    
    if(isVideo && isLimit) {
        // 获取视频本地播放链接
        var url = URL.createObjectURL(file)
        var video = document.createElement('video')
        video.currentTime = 1;
        video.src = url
        // video 监听事件 获取视频第一秒的画面作为封面
        video.oncanplay  = evt => {
          const { videoWidth, videoHeight } = video;  // 获取video的宽高
            setTimeout(()=>{
                // 截取封面图展示
                const canvas = document.createElement('canvas');
                canvas.width = videoWidth;
                canvas.height = videoHeight;
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
                const dataBase64 = canvas.toDataURL('image/png'); // 完成base64图片的创建
                setFileList([{
                    uid: '1',
                    url: dataBase64,
                    name: file.name
                }])

                let videoRatio = Math.round(((videoHeight/videoWidth) * 100))
                triggerChange({
                    coverUrl: dataBase64,
                    file: file,
                    videoRatio: videoRatio,
                    videoUrl: url
                  });
                setVideoUrl(url);
                setLoading(false)
            }, 1000)
        }
    }
    return false
}

const uploadButton = (
  <div>
    {loading ? <LoadingIcon /> : <RiAddLine />}
    <div style={{ marginTop: 8, fontSize: '12px' }}>上传视频</div>
  </div>
);
const [fileList,setFileList] = useState<UploadFile<{url:string}>[]>([])

const [previewVisible, setPreviewVisible] = useState(false)

const onPreview = ()=>{
	setPreviewVisible(true)
}

const onRemove = ()=>{
	setVideoUrl('')
	setFileList([])
	triggerChange({
		videoUrl: '',
		file: undefined,
    coverUrl: '',
    videoRatio: 1
	  });
}
    return (
		<div style={{marginBottom:"10px"}}>
			<Upload
					listType="picture-card"
					beforeUpload={beforeUpload}
					onRemove={onRemove}
					fileList={fileList}
					onPreview = {onPreview}
          disabled = {props.disabled} 
				>
					{videoUrl ? null : uploadButton}
			</Upload>

			<Modal
        title="视频预览"
        open={ previewVisible }
        onOk={() => setPreviewVisible(false)}
        onCancel={() => setPreviewVisible(false)}
      >
        <video style={{width: '100%',maxHeight:'80vh'}} src={videoUrl} controls></video>
      </Modal>
		</div>
    );
  }

  

