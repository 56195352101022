import { workListVoList } from "types/work"
import { MonthlyCardStyle } from "./style"
import { ImageObjectCloudResize } from "utils/functions/file";
import { RiFireLine } from "react-icons/ri";
import { Avatar } from "antd";
import { prettifyNumber } from "utils/functions/common";


const MonthlyCard = ({ list }: { list: workListVoList[] }) => {
    return <MonthlyCardStyle>
        {
            list.map(item => {
                return <div className="card" key={item.id}>
                    <a href={`/work/detail/${item.id}`} target="_blank" rel="noreferrer">
                        <div
                            className="cover"
                            style={{ backgroundImage: `url(${ImageObjectCloudResize(item.coverUrl, 500)})` }}
                        ></div>
                        <div className="content">
                            <div className="flex">
                                <div className="upper" >
                                    <div className="title" title={item.title}>{item.title}</div>
                                    <div className="view">
                                        <RiFireLine className="icon"></RiFireLine>
                                        <span>{prettifyNumber(item.hot)}</span>
                                    </div>
                                </div>
                                <div className="bottom">
                                    <a href={`/user/${item?.userId}`} target="_blank" rel="noreferrer">
                                        <Avatar className="avatar" size="small" src={item?.userInfo?.avatarUrl} />
                                        <div className="user-name">{item?.userInfo?.username}</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            })
        }
    </MonthlyCardStyle>
}

export default MonthlyCard