import { BadgeItem } from "types/user/badge"
import { BadgeRightStyle } from "./style"
import BadgeListItems from "views/user-center/badges/badge-list-item"
import { useEffect, useState } from "react";
import { NoData } from "components/no-data";


const BadgeRight = ({ badgeList }: { badgeList: BadgeItem[] }) => {

    const [growthBadges, setGrowthBadges] = useState<BadgeItem[]>([]);
    const [achievementBadges, setAchievementBadges] = useState<BadgeItem[]>([]);
    const [specialBadges, setSpecialBadges] = useState<BadgeItem[]>([]);
    const [contestBadges, setContestBadges] = useState<BadgeItem[]>([]);
    const [eventBadges, setEventBadges] = useState<BadgeItem[]>([]);

    const [newBadgeList, setNewBadgeList] = useState<BadgeItem[]>([])

    useEffect(() => {
        const result = badgeList.filter((item) => {
            return item.isActivated === 1
        })
        setNewBadgeList(result)
        filterBadges(result)
    }, [badgeList]);

    const filterBadges = (value: BadgeItem[]) => {
        setGrowthBadges(value.filter((item) => item.type === 1));
        setAchievementBadges(value.filter((item) => item.type === 2));
        setSpecialBadges(
            value.filter((item) => item.type === 3 && item.isActivated > 0)
        );
        setContestBadges(
            value.filter((item) => item.type === 4 && item.isActivated > 0)
        );
        setEventBadges(
            value.filter((item) => item.type === 5 && item.isActivated > 0)
        );
    }


    return <BadgeRightStyle>
        {
            growthBadges.length > 0 && <div className="section-block yellow">
                <div className="section-title">个人成长徽章</div>
                <div className="section-content">{BadgeListItems(growthBadges, false)}</div>
            </div>
        }
        {
            achievementBadges.length > 0 && <div className="section-block yellow">
                <div className="section-title">个人成就徽章</div>
                <div className="section-content">
                    {BadgeListItems(achievementBadges, false)}
                </div>
            </div>
        }
        {specialBadges.length > 0 && (
            <div className="section-block yellow">
                <div className="section-title">特别徽章</div>
                <div className="section-content">{BadgeListItems(specialBadges, false)}</div>
            </div>
        )}
        {contestBadges.length > 0 && (
            <div className="section-block yellow">
                <div className="section-title">比赛徽章</div>
                <div className="section-content">{BadgeListItems(contestBadges, false)}</div>
            </div>
        )}
        {eventBadges.length > 0 && (
            <div className="section-block yellow">
                <div className="section-title">活动徽章</div>
                <div className="section-content">{BadgeListItems(eventBadges, false)}</div>
            </div>
        )}

        {
            newBadgeList.length === 0 && <div className="no-wrap">
                <NoData />
            </div>
        }
    </BadgeRightStyle>
}

export default BadgeRight