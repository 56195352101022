export enum LanguageType {
    zh_CN =  'zh_CN',
    en_GB =  'en_GB',
    ko_KR = 'ko_KR',
    ja_JP = 'ja_JP'
}

export const LanguageTypeArr  = [
    {name: '中文',  value:LanguageType.zh_CN },
    {name: '英文',  value:LanguageType.en_GB },
    {name: '韩文',  value:LanguageType.zh_CN },
    {name: '日文',  value:LanguageType.en_GB },
]



