import styled from "styled-components";

export const HotTagsStyle = styled.div`
    margin: ${(props) => props.theme.space.paddingLG}px 0;
    margin-top: ${(props) => props.theme.space.paddingLG*2}px;
    .title{
        font-weight: 600;
    }
    .tags{
        margin-top:${(props) => props.theme.space.padding}px;
        display: flex;
        flex-direction: column;
        gap:${(props) => props.theme.space.paddingSM}px;
    }
`