import styled from "styled-components"

export const AlbumListStyle = styled.div`
padding: 12px 24px 24px;
max-width: 2680px;
margin: 0 auto;
.fliter{
    padding:  ${(props) => props.theme.space.paddingLG}px 0 0 0;
    margin-bottom: ${(props) => props.theme.space.paddingLG}px;
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
}
.tabs{
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0 20px;

    .tab{
        color: #8F8F8F;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        padding:  ${(props) => props.theme.space.paddingXXS}px ${(props) => props.theme.space.padding}px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding-bottom: 17px;
        &:hover {
        color: ${(props) => props.theme.colors.colorText} !important;
        border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
      }
        &.active{
            border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
            color:${(props) => props.theme.colors.colorText};
        }
    }
}

.data-list {
    display: flex;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: ${props=>props.theme.space.padding}px;
}
`