import MaterialContent from 'views/user-creator-center/components/meterial-content';
import { AuthWriterCreateStyle } from './style';



export const CertifiedAuthorMaterial = () => {

    return <AuthWriterCreateStyle>
        <MaterialContent />
    </AuthWriterCreateStyle >
}

export default CertifiedAuthorMaterial

