import { Button, Form, Input, InputNumber, UploadFile, message } from "antd";
import { CreateAndUpdateRewardStyle } from "./style"
import TinyEditor from "ui-lib/tinyMCE";
import { CosDirectoryType } from "types/enums/media-type";
import { ProductPicturesWall } from "views/user-shop/productManage/uploadImageList"
import { FilesWall } from "views/user-shop/productManage/uploadFileList"
import { useEffect, useState } from "react";
import { ForumTopicPayIssuessDetail, ForumUserModal } from "api/forum/type";
import { postCreateReward, postUpdateReward } from "api/forum";
import { useNavigate } from "react-router-dom";

const CreateAndUpdateReward = ({ detail, close, listOnFlush, answererDetail }: { detail?: ForumTopicPayIssuessDetail, close: () => void, listOnFlush?: () => void, answererDetail?: ForumUserModal }) => {

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()


    type imageType = {
        url: string
        key: string
        percent: number,
    }

    type submitParams = {
        imageList: imageType[],
        title: string
        amount: number,
        fileList: UploadFile[];
        type: number
        id?: number
        description: {
            html: string, media: number[]
        }
        inviteUserId?: number
        fileSupplement: string
    }

    useEffect(() => {
        if (!detail) {
            return
        }
        const newImage: imageType[] = []
        detail.imageList.forEach((item) => {
            newImage.push({
                url: item,
                key: item,
                percent: 100
            })
        })

        const newFileList: UploadFile[] = [];
        detail?.fileList?.forEach((item, index) => {
            newFileList.push({
                uid: String(index),
                url: item,
                name: item,
            });
        });

        form.setFieldsValue({
            imageList: newImage,
            title: detail.title,
            amount: detail.amount,
            fileList: newFileList,
            description: {
                html: detail.description,
                media: []
            },
            fileSupplement: detail.fileSupplement
        })
    }, [detail])

    const submitForm = () => {
        form.validateFields().then(async (res) => {
            const {
                imageList,
                title,
                amount,
                fileList,
                description,
                fileSupplement,
            } = res;
            const params: any = { title, amount, description: description && description.html, fileSupplement, type: !!answererDetail ? 3 : 2 }
            if (imageList && imageList.length > 0) {
                const newImageList = imageList.map(item => item.url)
                params.imageList = newImageList
            } else {
                params.imageList = []
            }


            if (fileList && fileList.length > 0) {
                const newFileList = fileList.map(item => item.url)
                params.fileList = newFileList
            } else {
                params.fileList = []
            }

            if (!!answererDetail) {
                params.inviteUserId = answererDetail.userInfo.id
                params.amount = answererDetail.wage
            }

            if (!!detail) {
                setLoading(true)
                params.amount = detail.amount
                postUpdateReward(Object.assign(params, { id: detail.id })).then(() => {
                    message.success("修改成功")
                    close()
                    listOnFlush && listOnFlush()
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                postCreateReward(params).then(() => {
                    close()

                    if (!!answererDetail) {
                        navigate(`/forum/reward/index`)
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        })
                    } else {
                        if (listOnFlush) {
                            listOnFlush()
                        } else {
                            window.location.replace(window.location.href);
                        }
                    }
                    message.success("创建成功")
                }).finally(() => {
                    setLoading(false)
                })
            }
        })
    }

    const NumberProps = {
        min: '0',//最小值
        max: '9999.99',//最大值
        stringMode: true,//字符值模式，开启后支持高精度小数
        step: '0.01',//小数位数
        precision: 2,
    };

    const [form] = Form.useForm<submitParams>();
    return <CreateAndUpdateRewardStyle>
        <div className="content">
            <Form
                form={form}
                style={{ marginTop: 20 }}
            >
                <Form.Item
                    name="title"
                    label='标题'
                    rules={[{ required: true, message: "请输入标题" }]}
                >
                    <Input placeholder="请输入标题" maxLength={40} />
                </Form.Item>
                <Form.Item
                    name="imageList"
                    label='上传图片'
                >
                    <ProductPicturesWall maxLength={9} limitSize={30} imgSrc={CosDirectoryType.forumImage} />
                </Form.Item>
                <Form.Item
                    name="fileList"
                    label='上传源文件'
                >
                    <FilesWall limitSize={30} maxLength={9} imgSrc={CosDirectoryType.forumFile} listType="picture-card" />
                </Form.Item>
                <Form.Item
                    name="fileSupplement"
                    label='源文件链接'
                >
                    <Input placeholder="请输入源文件链接" />
                </Form.Item>

                {
                    !!answererDetail && <>
                        <Form.Item
                            label='邀约对象'
                        >
                            <div className="user-info">
                                <div className="user-avatar" style={{ backgroundImage: `url(${answererDetail.userInfo.avatarUrl})` }} />
                                <div className="user-name">{answererDetail.userInfo.username}</div>
                            </div>
                        </Form.Item>

                        <Form.Item
                            label='悬赏价格'
                        >
                            {answererDetail.wage}元
                        </Form.Item>

                    </>
                }

                {
                    !answererDetail && <>
                        {
                            !detail && <div style={{ display: "flex", gap: "5px" }}>
                                <Form.Item
                                    name="amount"
                                    label='悬赏金额'
                                    rules={[{ required: true, message: "请输入悬赏金额" }]}
                                >
                                    <InputNumber {...NumberProps} placeholder="请输入悬赏金额" />
                                </Form.Item>
                                <div style={{ paddingTop: "5px" }}>元</div>
                            </div>
                        }
                        {
                            !!detail && <Form.Item
                                label='悬赏金额'
                            >
                                {detail.amount}元
                            </Form.Item>
                        }
                    </>
                }
                <Form.Item
                    name="description"
                    label='描述'
                >
                    <TinyEditor
                        cosImagePath={CosDirectoryType.forumImage}
                        cosVideoPath={CosDirectoryType.forumVideo}
                    ></TinyEditor>
                </Form.Item>
            </Form>
        </div>
        <div className="btns">
            <Button type="primary" ghost onClick={() => close()}>取消</Button>
            <Button type="primary" onClick={submitForm} loading={loading}>{!!detail ? '编辑' : '发布'}</Button>
        </div>
    </CreateAndUpdateRewardStyle>
}

export default CreateAndUpdateReward