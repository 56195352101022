import { Part1Style } from "./style"
import { Form, Input, Cascader, Select, FormInstance, message, Tag, Button, Divider, Dropdown } from 'antd';
import { useState, useCallback, useRef, useEffect } from 'react'
import { apiGetCountryList, apiGetDictList, } from 'api/common'
import { countryItem, countryRequest, DictItem } from 'types/common'
import { postUserDetail } from "api/user/detail"
import { apiUserDetail, postUpdateUserAvatar } from "api/user/detail";
import { ImageCrop } from "components/crop-img"
import { getRandomKey } from "config/utils/randomKey"
import { getFileUrlByCosUpload } from "config/cos";
import { getLocalUserInfo } from "utils/functions/common";
import type { MenuProps } from 'antd';


const Part1 = () => {
    const [tag, setTag] = useState<string[]>([])
    const [countryId, setCountryId] = useState(0)
    const [provinceId, setProvinceId] = useState<number | null>(0)
    const [cityId, setCityId] = useState<number | null>(0)
    const [defaultCountry, setdefaultCountry] = useState<number[]>([])
    const [countryList, setCountryList] = useState<countryItem[]>([])
    const formRef = useRef<FormInstance>(null);
    const [form] = Form.useForm<any>();
    const [professionList, setProfessionList] = useState<DictItem[]>([])
    const [address, setAddress] = useState('')
    const onkeyup = (e: any) => {
        const keyCode = e?.keyCode
        if (keyCode === 13) {
            const tagNew = form.getFieldValue('tag');
            const arr = JSON.parse(JSON.stringify(tag))
            if (!tagNew) {
                message.error('请输入标签')
                return
            }
            console.log(arr.includes(tagNew))
            if (arr.includes(tagNew)) {
                message.error('标签不能重复')
                return
            }
            if (arr.length === 5) {
                message.error('标签最多输入5个')
                return
            }
            arr.push(tagNew)
            setTag(arr)
        }
    }
    const onChange = (index: any) => {
        const arr = JSON.parse(JSON.stringify(tag))
        arr.splice(index, 1)
        setTag(arr)
    }

    const getCountryList = useCallback(async (params: countryRequest) => {
        const result = await apiGetCountryList(params)
        result.map((item => {
            item.isLeaf = false
            return item
        }))
        setCountryList(result)
    }, [])
    const onChangeCountry = (selectedOptions: countryItem[] | any[]) => {
        console.log(selectedOptions[1])
        if (selectedOptions[0]) {
            setCountryId(selectedOptions[0])
            setProvinceId(null)
            setCityId(null)
        }
        if (selectedOptions[1]) {
            setProvinceId(selectedOptions[1])
            setCityId(null)
        }
        if (selectedOptions[2]) {
            setCityId(selectedOptions[2])
        }
    }
    // 提交
    const save = () => {
        if (!form.getFieldValue('avatarUrl').imgUrl) {
            message.warning('头像不能为空')
            return
        }
        form.validateFields().then(async res => {
            const obj = Object.assign(res, { countryId, provinceId, cityId, tagList: tag })
            postUserDetail({ ...obj }).then(res => {
                message.success("保存成功")
            })
        })
    }
    const loadData = async (selectedOptions: countryItem[] | any[]) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const result = await apiGetCountryList({ pid: String(targetOption?.id), type: String(targetOption?.type + 1) })
        result.map(item => {
            if (targetOption?.id === 1 && item.type === 2) {
                item.isLeaf = false
            }
            return item
        })
        targetOption.loading = false;
        targetOption.children = result
        setCountryList([...countryList]);
    }
    // 获取职业列表
    const getProfessionList = useCallback(async () => {
        const result = await apiGetDictList({ type: "userInfoProfession" })
        setProfessionList(result)
    }, [])
    // 获取用户信息
    const getUserDetail = useCallback(async () => {
        await getProfessionList()
        const result = await apiUserDetail()
        const tagList = result.tagList && result.tagList.length > 0 ? result.tagList.map(item => item.name) : []
        const { userInfo } = result
        setTag(tagList)
        setdefaultCountry([userInfo.countryId, userInfo.provinceId, userInfo.cityId])
        setCountryId(userInfo.countryId)
        setProvinceId(userInfo.provinceId)
        setCityId(userInfo.cityId)
        const { regionDictMap } = userInfo
        if (!regionDictMap.countryName && !regionDictMap.provinceName && !regionDictMap.cityName) {
            setAddress('')
        } else {
            setAddress(`${regionDictMap.countryName} ${regionDictMap.provinceName ? "/" + regionDictMap.provinceName : ''} ${regionDictMap.cityName ? "/" + regionDictMap.cityName : ''}`)
        }
        console.log(userInfo)
        form.setFieldsValue({
            avatarUrl: {
                imgUrl: result.avatarUrl
            },
            username: result.username,
            email: userInfo.email,
            hometown: userInfo.hometown,
            company: userInfo.company,
            profession: userInfo.profession,
            university: userInfo.university,
            major: userInfo.major,
            signature: userInfo.signature,
            qq: userInfo.qq,
            wx: userInfo.wx,
            weibo: userInfo.weibo,
            artstation: userInfo.artstation,
            bilibili: userInfo.bilibili,
            xiaohongshu: userInfo.xiaohongshu,
            tag: ""
        })
    }, [form, getProfessionList])
    useEffect(() => {
        form.resetFields()
        getCountryList({
            pid: '0',
            type: '1'
        })
        getUserDetail()
    }, [getCountryList, getUserDetail, form])
    const upload = async (params: { imgUrl: string; file: File | undefined }) => {
        if (params.file) {
            let urlKey = `/ggac/user/detail/url/${getRandomKey(params.file!.name)}`
            let res = await getFileUrlByCosUpload(
                params.file!,
                urlKey
            );
            await postUpdateUserAvatar({ avatarUrl: res.url })
            message.success("上传成功")
            let userInfo = getLocalUserInfo()
            userInfo.avatarUrl = res.url
            localStorage.removeItem('user_info')
            localStorage.setItem("user_info", JSON.stringify(userInfo))
        }
    }

    const SelectValue = (props: { value?: string, onChange?: (value: string) => void }) => {
        const onClick: MenuProps['onClick'] = ({ key }) => {
            props.onChange && props.onChange(key)
        };
        return <Dropdown overlayStyle={{ maxHeight: "300px", overflow: "auto" }} trigger={["click"]}
            menu={{ items: professionList.map((item) => ({ label: item.name, key: item.name })), onClick }} placement="bottom">
            <Input value={props.value} placeholder="请输入您的职业" onChange={(value) => { props.onChange && props.onChange(value.target.value) }} />
        </Dropdown>
    }

    return <Part1Style>
        <div>
            <Form
                labelCol={{ span: 4 }}
                style={{ maxWidth: 600 }}
                autoComplete="off"
                ref={formRef}
                form={form}
            >
                <Form.Item
                    label="头像"
                    name="avatarUrl"
                >
                    <ImageCrop aspect={1 / 1} uploadImage={upload} />
                </Form.Item>
                {/* <Form.Item
                    label="用户名"
                    name="username"
                >
                    <Input placeholder="请输入您的用户名" disabled />
                </Form.Item> */}
                <Form.Item
                    label="邮箱"
                    name="email"
                >
                    <Input placeholder="请输入您的邮箱" />
                </Form.Item>
                <Form.Item
                    label="家乡"
                    name="hometown"
                >
                    <Input placeholder="请选择您的家乡" />
                </Form.Item>
                <Form.Item
                    label="现居"
                >
                    <Cascader
                        placeholder={address ? <span style={{ color: '#000000e0' }}>{address} </span> : "请选择您的现居地"}
                        options={countryList}
                        loadData={loadData}
                        onChange={onChangeCountry}
                        defaultValue={defaultCountry}
                        fieldNames={{
                            label: 'name',
                            value: 'id',
                        }}
                        changeOnSelect
                    />
                </Form.Item>
                <div className="flex">
                    <div className="flex-label">公司信息：</div>
                    <div className="flex flex-value">
                        <Form.Item
                            name="company"
                            style={{ marginRight: "20px", marginBottom: 0 }}
                        >
                            <Input placeholder="请输入您的公司名" style={{ width: 240 }} />
                        </Form.Item>
                        <Form.Item
                            label=""
                            name="profession"
                            style={{ marginBottom: 0 }}
                        >
                            <SelectValue />
                        </Form.Item>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-label">院校信息：</div>
                    <div className="flex">
                        <Form.Item
                            label=""
                            name="university"
                            style={{ marginRight: "20px", marginBottom: '30px', width: 240 }}
                        >
                            <Input placeholder="请输入您的毕业院校" />
                        </Form.Item>
                        <Form.Item
                            label=""
                            name="major"
                            style={{ marginBottom: '30px', width: 240 }}
                        >
                            <Input placeholder="请输入您的院校专业" />
                        </Form.Item>
                    </div>
                </div>
                <Form.Item
                    label="个人签名"
                    name="signature"
                >
                    <Input placeholder="请输入个人签名" />
                </Form.Item>
                <Form.Item
                    label="标签"
                    name="tag"
                    style={{ marginBottom: 0 }}
                >
                    <div>
                        <Input onKeyUp={onkeyup} placeholder="按回车添加标签" />
                        {
                            tag.length > 0 && <div className="tag">
                                {
                                    tag.map((item: any, index: number) => {
                                        return <Tag onClose={() => onChange(index)} closable={true} key={index}>{item}</Tag>
                                    })
                                }
                            </div>
                        }
                    </div>
                </Form.Item>
                <Divider plain style={{ padding: "20px 0" }}> 社交媒体</Divider>
                <Form.Item
                    label="QQ"
                    name="qq"
                >
                    <Input placeholder="请输入您的QQ" />
                </Form.Item>
                <Form.Item
                    label="微信"
                    name="wx"
                >
                    <Input placeholder="请输入您的微信" />
                </Form.Item>
                <Form.Item
                    label="新浪微博"
                    name="weibo"
                    rules={[
                        {
                            pattern: /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
                            message: "请输入正确的新浪微博个人主页链接"
                        }
                    ]}
                >
                    <Input placeholder="请输入您的新浪微博个人主页链接" />
                </Form.Item>
                <Form.Item
                    label="Artstation"
                    name="artstation"
                    rules={[
                        {
                            pattern: /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
                            message: "请输入正确的Artstation个人主页链接"
                        }
                    ]}
                >
                    <Input placeholder="请输入您的Artstation个人主页链接" />
                </Form.Item>
                <Form.Item
                    label="Bilibili"
                    name="bilibili"
                    rules={[
                        {
                            pattern: /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
                            message: "请输入正确的Bilibili个人主页链接"
                        }
                    ]}
                >
                    <Input placeholder="请输入您的Bilibili个人主页链接" />
                </Form.Item>
                <Form.Item
                    label="小红书"
                    name="xiaohongshu"
                >
                    <Input placeholder="请输入您的小红书个人主页链接" />
                </Form.Item>
                <div className="btn">

                    <Button onClick={() => save()} type="primary" block>保存</Button>
                </div>

            </Form>
        </div>

    </Part1Style >
}

export default Part1