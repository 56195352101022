import { useCallback, useEffect, useMemo, useState } from "react"
import { PopoverContentStyle } from "./style"
import { Input, Spin, message } from "antd"
import { RiCheckFill, RiAddFill, RiCloseFill } from "react-icons/ri"
import { HomeTypeListItem } from "types/work"
import { PostUpdateTypeStatus, getHomeTypeList } from "api/work"
import { UserBaseInfo } from "types/user"
import { NoData } from "components/no-data"

const PopoverContent = ({ updateList }: { updateList: () => void }) => {
    const [value, setValue] = useState('')

    const [allTypeList, setAllTypeList] = useState<HomeTypeListItem[]>([])

    const params = useMemo(() => {
        return {
            name: value
        }
    }, [value])

    const [loading, setLoading] = useState(false)

    const listCount = useMemo(() => {
        return allTypeList.filter((item) => {
            return item.status === 1
        }).length || 0
    }, [allTypeList])

    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!);
    const updateStatus = (detail: HomeTypeListItem, index: number) => {
        if (listCount <= 5 && detail.status === 1) {
            message.error("数量不能小于5")
            return
        }
        const flag = detail.status === 1 ? 0 : 1
        PostUpdateTypeStatus({
            mixTagId: detail.id,
            flag,
            userId: Number(userInfo.id)
        }).then(() => {
            updateList()
            getTypeList()
        })
    }

    const getTypeList = useCallback(() => {
        setLoading(true)
        getHomeTypeList({ name: params.name }).then(res => {
            setAllTypeList(res)
        }).finally(() => {
            setLoading(false)
        })
    }, [params.name])

    useEffect(() => {
        getTypeList()
    }, [getTypeList])

    const onkeydown = (e: any) => {
        if (e.keyCode === 13) {
            setValue(e.target.value)
        }
    }

    return <PopoverContentStyle>
        <Input placeholder="请输入关键字 回车后搜索" onKeyDown={onkeydown}></Input>
        <Spin spinning={loading}>
            <div className="tag" >
                {
                    allTypeList.map((item, index) => {
                        return <div className="tag-item" key={item.id}>
                            <img src={item.coverUrl} alt="" />
                            <div className="title">{item.name}</div>
                            {
                                item.status === 1 && <div className="tag-icon check">
                                    <RiCheckFill color="#5BB75B"></RiCheckFill>
                                    <RiCloseFill color="#bebec2" onClick={() => updateStatus(item, index)}></RiCloseFill>
                                </div>
                            }
                            {
                                item.status === 0 && <div className="tag-icon no-check">
                                    <RiAddFill color="#bebec2"></RiAddFill>
                                    <RiCheckFill color="#5BB75B" onClick={() => updateStatus(item, index)}></RiCheckFill>
                                </div>
                            }
                        </div>
                    })
                }
                {
                    allTypeList.length === 0 && <NoData />
                }

            </div>
        </Spin>
    </PopoverContentStyle>
}

export default PopoverContent