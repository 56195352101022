import { Button, message } from 'antd'
import { checkEmail, checkPhone } from "utils/functions/common";
import { useState } from 'react'
import { apiSendEmail, apiSendVerifyCode } from "api/common";
import { SendCodeStyle } from './style';


const SendCode = ({ value, type = "mobile" }: { value: string, type?: string }) => {
    const [countDown, setCountDown] = useState<number>(60);
    const [sendMsgBtnLoading, setSendMsgBtnLoading] = useState(false);
    let interval: NodeJS.Timer
    const timerStart = () => {
        //刷新倒计时开始
        clearInterval(interval);
        interval = setInterval(() => {
            setSendMsgBtnLoading(false);
            setCountDown((pre) => {
                if (pre === 0) {
                    setCountDown(60);
                    clearInterval(interval);
                }
                return pre - 1;
            });
        }, 1000);
    }
    const onSendCode = async () => {
        const mobile = value
        if (!mobile) {
            message.error("请填写手机号码！");
            return
        }
        if (!checkPhone(mobile)) {
            message.error("手机号码格式不正确！");
            return
        }
        //让按钮开始loading
        setSendMsgBtnLoading(true);
        // 让发送短信验证码
        await apiSendVerifyCode({
            mobile: mobile,
            countryCode: "86",
        });
        message.success("短信已发送");
        timerStart()
    };

    const onSendEmailCode = async () => {
        console.log(value)
        const email = value
        if (!email) {
            message.error("请填写邮箱地址！");
            return
        }
        if (!checkEmail(email)) {
            message.error("邮箱地址格式不正确！");
            return
        }
        //让按钮开始loading
        setSendMsgBtnLoading(true);
        // 让发送短信验证码
        await apiSendEmail(email);
        message.success("验证码已发送");
        timerStart()
    }
    return <SendCodeStyle>
        <Button
            type="primary"
            onClick={() => type === 'mobile' ? onSendCode() : onSendEmailCode()}
            disabled={countDown < 60}
            loading={sendMsgBtnLoading}
            className="sendMsg"
        >
            {countDown < 60 && countDown + "秒后重新获取"}
            {countDown === 60 && !sendMsgBtnLoading && "获取验证码"}
        </Button>
    </SendCodeStyle>
}

export default SendCode