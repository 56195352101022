import { useEffect, useRef, useState } from "react";
import {
  Form,
  Select,
  Modal,
  Space,
  Button,
  Checkbox,
  Input,
  FormInstance,
  message
} from "antd";
import { RegisteFormStyle } from "./style";
import { apiGetCaptcha, apiLoginSendEmail, apiLoginSendVerifyCode } from "api/common";
import { apiGetUserBaseInfo, postRegisterByEmail } from "api/user";
import { countryCodes } from "config/countryCode"
import { useLocation, useNavigate } from "react-router-dom";
import { sleep } from "utils/functions/common";
import { getCaptchaType } from "types/common";

const { Option } = Select;
function RegisteEmailForm() {
  const [messageApi, contextHolder] = message.useMessage();
  const [countDown, setCountDown] = useState<number>(60);
  const [sendMsgBtnLoading, setSendMsgBtnLoading] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const [modalTitle, setModalTitle] = useState('隐私政策')
  const [captchaDetail, setDaptchaDetail] = useState<getCaptchaType>()
  const [wordUrl, setWordUrl] = useState('https://pre-cdn-hz.ggac.com/docx/policy.html')
  const [wordModalShow, setWordModalShow] = useState(false)
  const channelCode = new URLSearchParams(useLocation().search).get("channelCode") || ''
  const onClickOpenWordModal = (url: string, title: string) => {
    setWordUrl(url)
    setModalTitle(title)
    setWordModalShow(true)
  }

  useEffect(() => {
    updateCaptchaCode()
  }, [])

  const updateCaptchaCode = () => {
    apiGetCaptcha().then(res => {
      setDaptchaDetail(res)
    })
  }
  let navigate = useNavigate();

  const redirect = new URLSearchParams(window.location.search).get(
    "redirect"
  );

  const selectBefore = (
    <Form.Item name="countryCode" noStyle>
      <Select optionLabelProp="label" popupMatchSelectWidth={false}>
        {countryCodes.map((item, index) =>
          <Option value={item.code} label={'+' + item.code} className="customOptions" style={{ width: '150px' }} key={index}>
            <span>
              {item.lable}
            </span>
            <span>
              {'+' + item.code}
            </span>

          </Option>)}
      </Select>
    </Form.Item>
  );

  const onFinish = async (values: any) => {

    if (!values.agreement) {
      messageApi.error("请同意并勾选用户协议和隐私条款");
      return
    }

    const { countryCode, email, mobile, verifyCode } = values

    const { token, isNew } = await postRegisterByEmail({ channelCode, countryCode, email, mobile, verCode: verifyCode })
    localStorage.setItem('auth_token', token);
    const userBaseInfo = await apiGetUserBaseInfo()
    localStorage.setItem("user_info", JSON.stringify(userBaseInfo));
    if (isNew) {
      navigate(redirect ? `/auth/completeUserInfo?redirect=${redirect}` : `/auth/completeUserInfo`)
    } else {
      message.info("欢迎回来！")
      sleep(500)
      if (redirect) {
        window.location.href = redirect
      } else {
        navigate('/user-center/home')
      }

    }

  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };


  const onSendCode = async () => {
    const email = formRef.current?.getFieldValue('email');
    const captchaCode = formRef.current?.getFieldValue('captchaCode')
    if (!captchaCode) {
      messageApi.error("请输入图形验证码！");
      return
    }
    if (!email) {
      messageApi.error("请填写邮箱地址！");
      return
    }
    //让按钮开始loading
    setSendMsgBtnLoading(true);
    // 让发送短信验证码
    apiLoginSendEmail({
      email: formRef.current?.getFieldValue("email"),
      captchaUid: String(captchaDetail?.uid),
      captchaCode: formRef.current?.getFieldValue('captchaCode')
    }).then((res) => {
      messageApi.info("邮箱验证码已发送");
      //刷新倒计时开始
      let interval = setInterval(() => {
        setCountDown((pre) => {
          if (pre === 0) {
            setCountDown(60);
            clearInterval(interval);
          }
          return pre - 1;
        });
      }, 1000);
    })
      .finally(() => {
        setSendMsgBtnLoading(false)
      });

  };

  const limitMobile = (rule: any, value: any, callback: any) => {
    const countryCode = formRef.current?.getFieldValue('countryCode');
    if (countryCode === "86") {
      const regTest = /^[1][3,4,5,6.7,8,9][0-9]{9}$/
      if (value && !regTest.test(value)) {
        callback("手机号格式有误")
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  return (
    <RegisteFormStyle>
      {contextHolder}
      <Form
        name="basic"
        style={{ width: "100%" }}
        initialValues={{ agreement: false, mobile: '', countryCode: '86' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        ref={formRef}
      >

        <Form.Item
          name="email"
          rules={[{ required: true, message: "请输入邮箱地址" },
          {
            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: '邮箱格式不正确',
          },
          {
            max: 50,
            message: '邮箱不得超过50字符',
          },
          ]}
          normalize={(value) => value.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, '')}
        >
          <Input placeholder="请输入邮箱地址" />
        </Form.Item>

        <div style={{ display: "flex", alignItems: "center" }}>

          <Form.Item
            name="captchaCode"
            style={{ marginBottom: '16px', width: "184px" }}
            rules={[{ required: true, message: "请输入图形验证码" }]}
          >
            <Input placeholder="请输入图形验证码" width={184} />
          </Form.Item>
          <img src={captchaDetail?.base64} onClick={updateCaptchaCode} style={{ marginLeft: "16px" }} width={132} alt="" />
        </div>
        <Form.Item
          name="verifyCode"
          rules={[
            {
              required: true,
              message: "请填写验证码",
            },
            {
              pattern: /^\d{4}$/,
              message: "验证码为4位数字"
            }
          ]}
        >
          <div className="code">
            <Input className="verifyCode" placeholder="请输入邮箱验证码" />
            <Button
              type="primary"
              onClick={() => onSendCode()}
              disabled={countDown < 60}
              loading={sendMsgBtnLoading}
              className="sendMsg"
            >
              {countDown < 60 && !sendMsgBtnLoading && countDown + "秒后重新获取"}
              {countDown === 60 && !sendMsgBtnLoading && "获取验证码"}
            </Button>
          </div>
        </Form.Item>

        <Form.Item
          name="mobile"
          rules={[{ required: true, message: "请输入绑定手机号码" },
          { validator: (rules, value, callback) => limitMobile(rules, value, callback) }
          ]}
          normalize={(value) => value.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, '').trim()}
        >
          <Input addonBefore={selectBefore} placeholder="请输入绑定手机号码" />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          className="checkItem"
        >
          <Checkbox>
            <div className="userAgreement">
              <Space onClick={() => onClickOpenWordModal('https://cdn-prd.ggac.com/ggac/assets/html/ggac-user-agreement.html', 'GGAC用户协议及隐私权政策')}>
                注册即代表同意<span className="Agreement" style={{}}>用户协议</span>和<span>隐私协议</span>
              </Space>
            </div>
            <Modal
              centered
              onCancel={() => { setWordModalShow(false) }}
              width="820px"
              open={wordModalShow}
              title={modalTitle}
              footer={<Button type="primary" onClick={() => {
                setWordModalShow(false)
              }}>确认</Button>}
            >
              <div>
                <iframe style={{ width: '750px', height: '700px', border: 'none', marginTop: '10px', marginLeft: '10px' }} src={wordUrl} title="用户协议"></iframe>
              </div>

            </Modal>
          </Checkbox>
        </Form.Item>

        <Form.Item className="submit">
          <Button type="primary" htmlType="submit" block>
            注册
          </Button>
        </Form.Item>
      </Form>
    </RegisteFormStyle>
  );

}

export default RegisteEmailForm;
