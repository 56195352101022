import { apiGetMenus } from "api/common";
import { useEffect, useState, useCallback } from "react";
import { MenuItem } from "types/common";
import { MenuStyle } from "./style";
import SecondMeus from "./secondMeus";
import { RiMoreFill } from "react-icons/ri";
import { Popover } from "antd";
import SubTypeList from "./subTypeList";
import { useLocation } from "react-router-dom";

function MainMenu() {
  const [menus, setMenus] = useState<MenuItem[]>([]);
  const [currentHoverMenu, setCurrentHoverMenu] = useState<
    MenuItem | undefined
  >();
  const location = useLocation();
  const [residualMenus, setResidualMenus] = useState<MenuItem[]>([]);
  const [isShowResidual, setIsShowResidual] = useState<Boolean>(false);
  const getMenus = useCallback(async () => {
    const menus = await apiGetMenus({ type: 2 });
    const limitMenus = menus.slice(0, 10);
    const residualMenus = menus.slice(limitMenus.length, menus.length);
    setResidualMenus(residualMenus);
    setMenus(limitMenus);
  }, []);

  useEffect(() => {
    getMenus();
  }, [getMenus]);

  const handleMenuClick = (menu: MenuItem) => {
   
    if (menu.isNewWeb === 1) {
      window.open(menu.url, "_blank");
    } else {
      if (menu.isHttp === 1) {
        console.log("====", menu.url, menu);
        window.open(menu.url, "_self");
      } else {
        window.location.href = menu.url
      }
    }
  };

  return (
    <MenuStyle>
      <ul className="menuList">
        {menus.map((menu, index) => (
          <li
            className={
              `menuItem ${location.pathname.includes(menu.customCode) ? 'active' : ''}`
            }
            key={menu.id}
            onMouseEnter={() => setCurrentHoverMenu(menu)}
            onMouseLeave={() => setCurrentHoverMenu(undefined)}
          >
            {menu.name !== "作品" && (
              <>
                <div onClick={() => handleMenuClick(menu)}>
                  {menu.prefix && (
                    <div className="prefix">
                      <img src={menu.prefix} alt="" />
                    </div>
                  )}
                  {menu.name}
                  {menu.suffix && (
                    <div className="suffix">
                      <img src={menu.suffix} alt="" style={{ maxHeight: 14 }} />
                    </div>
                  )}
                </div>
                {menu.children.length > 0 &&
                  currentHoverMenu?.id === menu.id && (
                    <SecondMeus
                      menus={menu.children}
                      isOpen={
                        menu.children.length > 0 &&
                        currentHoverMenu?.id === menu.id
                      }
                    />
                  )}
              </>
            )}
            {menu.name === "作品" && (
              <Popover
                placement="topLeft"
                destroyTooltipOnHide={true}
                overlayStyle={{ top: "58px", left: "0px" }}
                arrow={false}
                key={menu.id}
                // trigger='click'
                getPopupContainer={(trigger) => trigger}
                content={<SubTypeList isOpen={currentHoverMenu?.id === menu.id}/>}
                
              >
                <span onClick={() => handleMenuClick(menu)}>
                  <span className="prefix">
                    <img src={menu.prefix} alt="" />
                  </span>
                  {menu.name}
                  <span className="suffix">
                    <img src={menu.suffix} alt="" style={{ maxHeight: 20 }} />{" "}
                  </span>
                </span>
              </Popover>
            )}
          </li>
        ))}
        {residualMenus.length > 0 && (
          <li
            className="actionIcon menuItem"
            onMouseEnter={() => setIsShowResidual(true)}
            onMouseLeave={() => setIsShowResidual(false)}
          >
            <RiMoreFill className="icon" size={20} />
            {isShowResidual && (
              <SecondMeus menus={residualMenus} isOpen={true} />
            )}
          </li>
        )}
      </ul>
    </MenuStyle>
  );
}

export default MainMenu;
