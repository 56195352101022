import styled from "styled-components";

export const WallectInfoStyle = styled.div`
    margin-top: ${props => props.theme.space.paddingLG}px;
    .top {
        padding: ${props => props.theme.space.padding}px;
        border-radius: ${props => props.theme.border.borderRadius}px;
        background-color:${props => props.theme.colors.colorBlack1Ggac};
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            gap: ${props => props.theme.space.paddingXS}px;
            .icon{
                font-size: 22px;
            }
            .amount{
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
    
    .list {
        padding: ${props => props.theme.space.padding}px;
        margin-top: ${props => props.theme.space.margin}px;
        .title {
            font-size: ${(props) => props.theme.fontSizes.fontSizeHeading5}px;
            padding: ${(props) => props.theme.space.padding}px 0;
        }
        &-item {
            display: flex;
            justify-content: space-between;
            padding:  ${(props) => props.theme.space.paddingSM}px 0;
            border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorder};
            .desc {
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                max-width: 230px;
                
            }
            div {
                flex: 1;
                padding-right: ${props => props.theme.space.paddingSM}px;
                &>:last-child{
                    padding-right: 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
 `