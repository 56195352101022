import { Avatar, Button, Input, Modal, Spin, Tag } from "antd";
import { AddFavoriteStyle } from "./style";
import { RiCloseLine, RiSearchLine, RiAddCircleFill } from "react-icons/ri";
import { useParams } from "react-router";
import { useCallback, useEffect, useState, useMemo } from "react";
import { apiFavoriteList, postAddToFavorite } from "api/favorite";
import { UserBaseInfo } from "types/user";
import { favoriteListItem } from "types/favorite";
import FavoriteCreate from "components/favorite/create-favorite";
import { NoData } from "components/no-data";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  onClose: () => void,
  type?: string,
  FavoriteTypeDisabled?: boolean
  dataId?: number
}

function AddFavorite({ onClose, type = 'work', dataId }: Props) {
  const { id } = useParams<any>();
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState<favoriteListItem[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [isShowFavoriteModal, setIsShowFavoriteModal] = useState(false)
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
  const [totalSize, setTotalSize] = useState(0)
  const [curretnPage, setCurretnPage] = useState(1)
  const [currentId, setCurrentId] = useState(1)
  let typeCode = 0;
  if(['work', 'article'].includes(type)){
    typeCode = 1 
  }else if(type ==='mall_product'){
    typeCode = 3
  }else{
    typeCode = 2
  }
  const params = useMemo(() => {
   
    return {
      userId: userInfo.id,
      currentDataId: dataId || Number(id),
      dataTable: type,
      type: typeCode,
      name: searchValue,
      pageNumber: curretnPage
    }
  }, [userInfo.id, id, type, searchValue, curretnPage])

  useEffect(() => {
    setList([])
  }, [params.name])

  const getList = useCallback(() => {
    apiFavoriteList({ ...params, pageSize: 10 })
      .then(res => {
        setList((result) => [...result, ...res.pageData])
        setTotalSize(res.totalSize)
      })
  }, [params])

  const createFavoriteSuccess = (detail: favoriteListItem) => {
    detail.isFavoriteCurrentData = 0
    list.unshift(detail)
    setList([...list])
    setIsShowFavoriteModal(false)
  }

  const addToFavorite = (detail: favoriteListItem, index: number) => {
    setIsLoading(true)
    setCurrentId(detail.id)
    postAddToFavorite({
      dataId: dataId || Number(id),
      dataTable: type,
      favoriteId: detail.id,
      flag: detail.isFavoriteCurrentData === 0 ? 1 : 0,
    }).then(res => {
      list[index].isFavoriteCurrentData = detail.isFavoriteCurrentData === 1 ? 0 : 1
      setList([...list])
    }).finally(() => {
      setIsLoading(false)
    })
  }

  // const changeSearchValue = (e: string) => {
  //   setSearchValue(e)
  // }

  const onkeydown = (e: any) => {
    if (e.keyCode === 13) {
      setSearchValue(e.target.value)
    }
  }

  const loadMoreData = () => {
    if (list.length >= totalSize) return;
    setCurretnPage(curretnPage + 1);
  }

  useEffect(() => {
    getList()
  }, [getList])
  return (
    <AddFavoriteStyle onClick={(e) => e.stopPropagation()}>
      <div className="modal-popup" onTouchMove={(e) => {e.preventDefault();e.stopPropagation()}}></div>
      <div className="modal" >
        <div className="header">
          <div className="title">
            收藏
          </div>
          <div className="close" onClick={() => onClose()}>
            <RiCloseLine></RiCloseLine>
          </div>
        </div>
        <div className="content" >
          <Input placeholder="输入关键字并回车搜索" onKeyDown={onkeydown} prefix={<RiSearchLine />} />
          {/* <ul className="list"> */}
          <div className="list" id="scrollableDiv1">
            <InfiniteScroll
              dataLength={list.length}
              next={loadMoreData}
              hasMore={list.length < totalSize}
              // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
              loader={<div style={{ display: 'flex', width: '100%', justifyContent: 'center', height: '60px', alignItems: 'center' }}>
                <Spin />
              </div>}
              scrollableTarget="scrollableDiv1"
            >
              {
                list.length > 0 && list.map((item, index) => {
                  return <div className="listItem" key={item.id}>
                    <div className="left_">
                      <Avatar shape="square" size="large" src={item.coverList && item.coverList.length > 0 && item.coverList[0]} />
                      <span className="title">{item.name}</span>
                      {
                        item.isPrivate === 1 && <Tag color="orange">私密</Tag>
                      }
                    </div>
                    <div className="right_">
                      <Button type="primary" className={`${item.isFavoriteCurrentData === 1 ? 'active' : ''}`} loading={isLoading && item.id === currentId} onClick={() => { addToFavorite(item, index) }}>{item.isFavoriteCurrentData ? '取消收藏' : '收藏'}</Button>
                    </div>
                  </div>
                })
              }
            </InfiniteScroll>
          </div>
          {
            list.length === 0 && list.length === totalSize && <NoData />
          }
          <div className="footer">
            <div className="action" onClick={() => setIsShowFavoriteModal(true)}>
              <RiAddCircleFill className="icon"></RiAddCircleFill>
              <span className="text">新建收藏夹</span>
            </div>
          </div>
        </div>
        <Modal footer={null} closable={false} destroyOnClose={true} centered={true} open={isShowFavoriteModal}>
          <FavoriteCreate type={typeCode} disabled={true} onClose={() => setIsShowFavoriteModal(false)} success={createFavoriteSuccess} />
        </Modal>
      </div>
    </AddFavoriteStyle>
  );
}

export default AddFavorite;
