import { Header } from "./header";
import { LayoutStyle } from "./layoutStyle";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { isBrowserVersionTooOld } from "fn";
import { RiCloseLine } from "react-icons/ri";
import { getUserCoupon } from "api/user";
import CouponModal from "./CouponModal/CouponModal";
import { CouponDrawItem } from "types/user";
import { getLocalUserInfo, getSearchParam } from "utils/functions/common";

type Props = {
  bgColor?: string
}
function Layout({bgColor='#fff'}:Props) {
  const location = useLocation()
  const [isHome, setIsHome] = useState(false)
  const [isHasBanner, setIsHasBanner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [couponList,setCouponList] = useState<CouponDrawItem[]>([]);
  const [showCouponModal,setShowCouponModal] = useState(false)
  const localUser = getLocalUserInfo();


  useEffect(() => {
    if (["/home", "/hot", "/recommend", "/follow"].includes(location.pathname) || location.pathname.search('/billboard') !== -1) {
      setIsHome(true)
    } else {
      setIsHome(false)
    }
    const result = ["/explore", "/keyword", "/hot", "/recommend", "/follow", "/contest/", "/exhibition"].find((item) => {
      return location.pathname.search(item) !== -1
    })
    if ((result && location.pathname !== '/events/exhibition/list') || location.pathname === "/article/list" || location.pathname.search('/billboard') !== -1 || location.pathname === '/home') {
      setIsHasBanner(true)
    } else {
      setIsHasBanner(false)
    }

  }, [location.pathname])

  useEffect(() => {
    if (isBrowserVersionTooOld()) {
      console.log("Browser version too old")
      setIsModalOpen(true);
    }
  }, [])


  useEffect(() => {
    localUser &&  getUserCoupon({
      strategyId:1,
      shareUserId:getSearchParam('shareUserId')?Number(getSearchParam('shareUserId')):undefined,
      productId:getSearchParam('productId')?Number(getSearchParam('productId')):undefined
    }).then(res=>{
      
      if ( (["/home", "/mall/market"].includes(location.pathname) || location.pathname.search('product/detail/')  ===1 || location.pathname.search('/user-center')===0 || location.pathname.search('/user/')===0) && res.showDialog===1) {
        setShowCouponModal(true)
        setCouponList(res.couponList)
      } 
    })
  },[])

  return (
    <LayoutStyle style={{backgroundColor:bgColor}} className="LayoutStyle">
      <Header></Header>
      <div className={`layout-content ${!isHasBanner ? 'add-block' : ''} ${isHome ? 'is-home' : ''}`} id="kindsWorks">
        <Outlet></Outlet>
      </div>
      {/* <Footer></Footer> */}
      {isModalOpen && <div className='version-tip' >
        <RiCloseLine className="close" onClick={() => setIsModalOpen(false)}></RiCloseLine>
        <h5>浏览器版本过低</h5>
        <p>系统君查询到您的浏览器内核版本似乎有些年份了，为了更好的浏览体验，建议将您正在使用的浏览器升级到最新版，推荐以下官方正版浏览器：</p>
        <p><a href="https://www.google.cn/chrome/index.html">谷歌浏览器官网</a> </p>
        <p><a href="https://www.microsoft.com/zh-cn/edge">Edge浏览器官网</a> </p>
        <p><a href="http://www.firefox.com.cn/">火狐浏览器官网</a> </p>
        <p><a href="https://browser.qq.com/">QQ浏览器官网</a> </p>
        <p><a href="https://ie.sogou.com/">搜狗浏览器官网</a> </p>
        <p><a href="https://browser.360.cn/">360浏览器官网</a> </p>
      </div>}
      <CouponModal isModalOpen={showCouponModal}  handleCancel={() => setShowCouponModal(false)} list={couponList}></CouponModal>
    </LayoutStyle>
  );
}

export default Layout;
