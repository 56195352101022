import { Button } from "antd"
import { CertificateCardStyle } from "./style"
import { certificateItem } from "types/user/certificate"

const CertificateCard = ({ detail }: { detail: certificateItem }) => {

    const toLink = () => {
        window.open(detail.cnUrl)
    }

    return <CertificateCardStyle>
        <div className="list-item">
            <div className="cover" style={{ backgroundImage: `url(${detail.cnUrl})` }}></div>
            <div className="info">
                {
                    !!detail.userInfo && <>
                        <div className="user-header" style={{ backgroundImage: `url(${detail.userInfo.avatarUrl})` }}></div>
                        <div className="user-name">{detail.userInfo.username}</div>
                    </>
                }
                <div className="year">{detail.name}</div>
                <div className="award">{detail.description}</div>
                <Button type="primary" onClick={() => { toLink() }}>查看证书</Button>
            </div>
        </div>
    </CertificateCardStyle>
}

export default CertificateCard