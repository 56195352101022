import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import ScoreList from "views/score";
import NewScoreList from "views/new-score";
import TrackScoreList from "views/track-score";


export const scoreRoutes: RouteObject[] = [

    {
        path: "/score",
        element: <Layout></Layout>,
        children: [
            {
                path: ":id/list",
                element: <ScoreList></ScoreList>,
            }
        ]
    },
    {
        path: "/new-score",
        element: <Layout></Layout>,
        children: [
            {
                path: ":id/list",
                element: <NewScoreList />,
            }
        ]
    },
    {
        path: "/track-score",
        element: <Layout></Layout>,
        children: [
            {
                path: ":id/list",
                element: <TrackScoreList />,
            }
        ]
    }
]