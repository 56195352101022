import { useEffect, useState } from "react";
import { ForumMineReplyListItem, ForumMineTopicListRequest } from "api/forum/type";
import { apiMyForumFavoriteReplyList, apiMyForumReplyList} from "api/forum";
import { MyTopicListStyle } from "./style";

import ReplyItem from "views/forum/reply/reply-item";
import ReplyCreateAndUdate from "views/forum/reply/reply-edit/reply-create-update";
import { Pagination } from "antd";
import { NoData } from "components/no-data";
import { useParams } from "react-router-dom";


function MyReplyList() {
  const [replyList,setReplyList] = useState<ForumMineReplyListItem[]>([])
  const [showEdit, setShowEdit] = useState(false);
  const [currentEditReply,setCurrentEditReply] = useState<{id:number,html:string}>()
  // const [listParams,setListParams] = useState<ForumMineTopicListRequest>({
  //   pageSize:20,
  // })
  const pageSize = 20
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const {tab} = useParams();
  
  useEffect(() => {
    getList()

  }, [currentPage,tab]);

  const getList = ()=>{

    if(tab ==='reply'){
      apiMyForumReplyList({pageNumber:currentPage,pageSize:pageSize,collapseTextCount:400}).then((res) => {
        setTotalSize(res.totalSize);
        setReplyList(res.pageData);
      });
    }
   
    if(tab ==='favorite'){
      apiMyForumFavoriteReplyList({pageNumber:currentPage,pageSize:pageSize,collapseTextCount:400}).then((res) => {
        setTotalSize(res.totalSize);
        setReplyList(res.pageData);
      });
    }
  }

  const setLocalList = (item:ForumMineReplyListItem)=>{
    setReplyList(prevData => {
      return prevData.map(obj => {
        if (obj.id === item.id) {
          return item;
        }
        return obj;
      });
    });
  }

  const removeFromLocalist =(id:number)=>{
    setReplyList(prevData => {
      return prevData.filter(obj => {
        return obj.id !==id;
      });
    });
  }
  const changePage = (e: any) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(e)
  };

  return (
    <MyTopicListStyle>
      <div className="list">
      {replyList.map((item,index)=>{
            return  <ReplyItem key={index} listItem={item} onEdit={()=>{
              getList()
            }}
            onListItemChange={(item_)=>{setLocalList(item_)}}
            onDeleteSuccess={(id)=>{removeFromLocalist(id)}}
            onCommentCountChange={()=>{ getList()}}
            
            ></ReplyItem>
          })}
           {replyList.length === 0  && (
              <div style={{ marginTop: "200px" }}>
                <NoData></NoData>
              </div>
            )}
      </div>
      <Pagination
              style={{ textAlign: "center", margin: "24px 0 12px 0" }}
              hideOnSinglePage={true}
              current={currentPage}
              onChange={changePage}
              pageSize={pageSize}
              total={totalSize}
              showSizeChanger={false}
              size="default"
            />
      <ReplyCreateAndUdate isModalOpen={showEdit} handleOk={() => {
        getList()
        setShowEdit(false)}} handleCancel={() => {
          setCurrentEditReply(undefined)
          setShowEdit(false)}} replyId={currentEditReply?.id} html={currentEditReply?.html}></ReplyCreateAndUdate>
    </MyTopicListStyle>
  );
}

export default MyReplyList;
