import { Form, InputNumber } from "antd"
import { useEffect } from "react";
import { ShippingFeeSpecialItem } from "types/product";

type Props ={
    value?:ShippingFeeSpecialItem
    onChange(val:ShippingFeeSpecialItem):void,
    shouldValiate:number,
}
function SpecialInputItem({value,onChange,shouldValiate}:Props) {
  const [form] = Form.useForm();  

  useEffect(()=>{
      if(shouldValiate>0){
        form.validateFields()
      }
  },[shouldValiate])
  useEffect(()=>{
    form.setFieldsValue({...value})
  },[value])

  
  return (
    <div>
       <Form
          className="special-item"
          style={{ marginTop: 20 }}
          labelAlign="left"
          form={form}
          onValuesChange={(value,allValues)=> onChange(allValues as ShippingFeeSpecialItem)}
        >
             <div className="default-fee">
          <Form.Item
            name="baseNum"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber min={1}  placeholder="请输入"/>
          </Form.Item>
          <span> &nbsp;件内运费&nbsp; </span>
          <Form.Item
            name="baseFee"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber min={0} placeholder="请输入"/>
          </Form.Item>
          <span>&nbsp;元，每增加&nbsp;</span>
          <Form.Item
            name="incNum"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber  min={1} placeholder="请输入"/>
          </Form.Item>
          <span>&nbsp;件，运费增加&nbsp;</span>
          <Form.Item
            name="incFee"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber  min={0} placeholder="请输入"/>
          </Form.Item>
          <span>&nbsp;元。</span>
         
          <span>多件包邮：满&nbsp;</span>
          <Form.Item
            name="freeNum"
            rules={[{ required: true, message: "请输入" }]}
          >
            <InputNumber  min={0} placeholder="请输入"/>
          </Form.Item>
          <span>&nbsp;件包邮</span>
          </div>
        </Form>
    </div>
  )
}

export default SpecialInputItem