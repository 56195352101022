import styled from "styled-components";


export const FoldDetailStyle = styled.div`

    .button-wrap {
        padding-bottom: ${props => props.theme.space.padding}px;
  
        display: flex;
        align-items: center;
        .back-btn{
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover{
                color: ${props => props.theme.colors.colorPrimaryText};
            }
            .back-icon{
                font-size: ${props => props.theme.fontSizes.fontSizeLG*1.2}px;
            }
          
        }
    }

    .list {
        display: grid;
    

        gap: ${(props) => props.theme.space.padding}px;
        column-gap: ${(props) => props.theme.space.paddingLG}px;
    }
`