import { useLocation, useNavigate } from "react-router-dom"
import { RewardSearchStyle } from "./style"
import { useEffect, useState } from "react"
import { ForumTopicPayIssuessListItem } from "api/forum/type"
import { apiGetForumTopicPayIssuessList } from "api/forum"
import { queryUrl } from "utils/functions/common"
import RewardItem from "../reward-item"
import { Pagination, Spin } from "antd"

const RewardSearch = () => {

    const navigate = useNavigate()

    const keyword = new URLSearchParams(useLocation().search).get("keyword") || ""
    const [list, setList] = useState<ForumTopicPayIssuessListItem[]>([])
    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const [totalSize, setTotalSize] = useState(0)

    const [loading, setLoading] = useState(false)

    const link = () => {
        navigate(`/forum/reward/index`, { replace: true })
    }

    const pageSize = 10

    useEffect(() => {
        getList()
    }, [pageNumber, keyword])

    const getList = () => {
        setLoading(true)
        const params: any = { sortField: "lastSubmitTime", pageSize, pageNumber: Number(pageNumber), keyword }
        apiGetForumTopicPayIssuessList(params).then(res => {
            setList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }

    const changePage = (e: number) => {
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const updateList = (id: number) => {
        const index = list.findIndex(item => item.id === id)
        list[index].isApplied = 1
        setList([...list])
    }

    return <RewardSearchStyle>
        <div className="list-title">
            <div className="link" onClick={() => link()}>
                返回 悬赏主页
            </div>
            <span>{`>`}</span>搜索
        </div>

        <div className="keyword-wrap">
            {
                <div className="keyword">
                    {keyword && <>搜索：{keyword}</>}
                </div>
            }

            <div className="search-tab">
                <div className="search-tab-item active">最新</div>
            </div>
        </div>

        <Spin spinning={loading}>
            <div className="list">
                {
                    list.map(item => {
                        return <RewardItem updateList={updateList} key={item.id} detail={item} />
                    })
                }
            </div>
            <Pagination
                style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                hideOnSinglePage={true}
                current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                onChange={changePage}
                pageSize={pageSize}
                total={totalSize}
                showSizeChanger={false}
                size="default"
            />
        </Spin>


    </RewardSearchStyle>
}

export default RewardSearch