import styled from "styled-components";

export const WorkDetailStyle = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 0 !important;
  .placeholder-50-px {
    height: 50px;
    width: 100vw;
    background-color: rgb(54, 54, 54, 0.35);
  }

  .float-button {
    bottom: 85px;
    
  }

  .main--container {
    display: flex;
    max-width: ${(props) => props.theme.space.maxContainer}px;
    width: 100%;
    margin: 0 auto;
    min-width: 1400px;
    background: #fbfbfd;
    
    
    .left {
      padding-left:28px;
     width: calc(100% -  408px);
     position: relative;
     
     &::-webkit-scrollbar {
      display: none;
      }
      /* overflow-y: auto;
     height: 100vh; */
     scrollbar-width: none; 
     -ms-overflow-style:none;
    }
    /* .right::-webkit-scrollbar {
      display: none; 
    } */
    
    .right {
      position: sticky;
      top: 50px;
      right: 0;
      color: #2b2e36;
      font-size: 14px;
      height: calc(100vh - 50px);
      padding: ${(props) => props.theme.space.paddingLG}px;
      width: 408px;
      .section-block {
        padding:${(props) => props.theme.space.paddingLG}px;
        border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
        border: 1px solid ${(props) => props.theme.colors.colorBorder};
        background-color: #fff;
        margin-bottom: ${(props) => props.theme.space.paddingLG}px;
      }

      .report {
        color: ${(props) => props.theme.colors.colorTextSecondary};
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: ${(props) => props.theme.space.paddingXXS}px;
        span {
          padding: 0 ${(props) => props.theme.space.paddingXXS}px;
          padding-top: 2px;
        }
        &:hover {
          color: ${(props) => props.theme.colors.colorPrimary};
        }
      }
    }
  }
`;
