import { get } from "api";
import { PaginationResponse } from "types/common/axios";
import { eventListRequest, eventListResponse, eventCheckinListRequest, eventCheckinListResponse, WorkCheckinDateRequest, WorkCheckinDateResponse, EventExhibitionDetailResponse } from "types/events";

// 获取活动墙
export const getEventList = (params: eventListRequest) =>
    get<eventListRequest, PaginationResponse<eventListResponse>>("/api/event_wall/list", params)

// 获取活动签到列表
export const getEventCheckinList = (params?: eventCheckinListRequest) =>
    get<eventListRequest, PaginationResponse<eventCheckinListResponse>>("/api/event_checkin/list", params)

// 获取活动签到详情
export const getEventCheckinDetail = (id: number) =>
    get<{ id: number }, eventCheckinListResponse>(`/api/event_checkin/detail/${id}`)

export const getEventCheckinWorkList = (params: WorkCheckinDateRequest) =>
    get<WorkCheckinDateRequest, WorkCheckinDateResponse>(`/api/work/checkin_date`, params)

// 获取作品展详情
export const getEventExhibitionDetail = (id: number) =>
    get<{ id: number }, EventExhibitionDetailResponse>(`/api/event_exhibition/detail/${id}`)

export const getEventExhibitionList = (params: { pageNumber?: number, pageSize?: number }) =>
    get<{ pageNumber?: number, pageSize?: number }, PaginationResponse<EventExhibitionDetailResponse>>(`/api/event_exhibition/list`, params)

