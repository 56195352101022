import { Modal, Form, Input, Button, message } from "antd"
import { UpdateMobileStyle } from "./style"
import SendCode from "../sendCode"
import { apiUpdateMobile } from "api/user"
import { useState } from 'react'

type appendixModalType = {
    params: {
        visible: boolean,
        visibleChange: (visible: boolean, needRefesh?: boolean) => void,
        UpdateSuccess: () => void,
    }
}

const UpdateMobile = (props: appendixModalType) => {
    const [form] = Form.useForm<any>();
    const { params } = props
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [mobile, setMobile] = useState<string>('')
    const onCancel = () => {
        params.visibleChange(false)
        form.setFieldsValue({
            mobile: "",
            verCode: ""
        })
    }

    const changeMobile = () => {
        setMobile(form.getFieldValue('mobile'))
    }

    // 提交form
    const submit = () => {
        form.validateFields().then(async res => {
            setIsLoading(true)
            apiUpdateMobile({
                mobile: form.getFieldValue('mobile'),
                verCode: form.getFieldValue('verCode')
            }).then(() => {
                message.success('修改成功')
                params.UpdateSuccess()
                onCancel()
            }).finally(() => {
                setIsLoading(false)
            })
        })
    }
    return <UpdateMobileStyle>
        <Modal className='modal' destroyOnClose={true} title='修改手机号' centered={true} footer={null} getContainer={false} onCancel={onCancel} open={params.visible} width={420}>
            <Form form={form} className="form">
                <Form.Item name='mobile' label="手机号"
                    rules={[{ required: true, message: "请输入手机号码" },
                    {
                        pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                        message: '请输入正确手机号'
                    }]}>
                    <Input placeholder="请输入新的手机号" onChange={() => changeMobile()} />
                </Form.Item>
                <Form.Item
                    name="verCode"
                    label="验证码"
                    rules={[
                        {
                            required: true,
                            message: "请填写验证码",
                        },
                        {
                            pattern: /^\d{4}$/,
                            message: "验证码为4位数字",
                        },
                    ]}
                >
                    <div className="code">
                        <Input maxLength={4} className="verCode" placeholder="请输入短信验证码" />
                        <SendCode value={mobile} />
                    </div>
                </Form.Item>
                <div className="btn"><Button loading={isLoading} type="primary" onClick={() => submit()} block>提交</Button></div>
            </Form>
        </Modal>
    </UpdateMobileStyle>
}

export default UpdateMobile