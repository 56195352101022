import TencentCloudChat, { ChatSDK } from "@tencentcloud/chat";
import { apiRegisterImUser, getIMLoginInfo, getImAppid } from "api/im";
import TIMUploadPlugin from "tim-upload-plugin";
import { OrderCreateMallResponse, orderDetailResponse, OrderListResponse, SellerOrderListResponse } from "types/product";

var chat: ChatSDK;
var toUserId: string
var onSdkReady: () => void

export const initChatService = (value: number) => {
  onSdkReady = () => {
    // chat.on(
    //   TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED,
    //   onConversationListUpdated
    // );
    // chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
    toUserId = String(value)
    if (value) {
      //注册用户（没有会新建）  
      apiRegisterImUser(Number(value)).then((res) => {
        //如果没有会话，这个方法可以创建对话
        chat.getConversationProfile('C2C' + value);
      });
    }
  }
  console.log(chat)
  getImAppid().then((appIdRes) => {
    let options = {
      SDKAppID: appIdRes.appId, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
    };

    // if(!chat) {
    // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
    chat = TencentCloudChat.create(options); // SDK 实例通常用 tim 表示
    // }

    // 设置 SDK 日志输出级别，详细分级请参见 <a href="https://web.sdk.qcloud.com/im/doc/en/SDK.html#setLogLevel">setLogLevel 接口的说明</a>
    chat.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用
    // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
    chat.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
    //登陆

    //获取自己IM信息
    getIMLoginInfo().then((res) => {
      let promise = chat.login({
        userID: res.userId + "",
        userSig: res.userSign,
      });
      promise
        .then(function (imResponse) {
          console.log("自己登录成功", imResponse.data); // 登录成功
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        })
        .catch(function (imError) {
          console.warn("login error:", imError); // 登录失败的相关信息
        });
    });
    chat.on(TencentCloudChat.EVENT.SDK_READY, onSdkReady);
  });
}

export const logoutChat = (func?: () => void) => {
  if (!chat) {
    func && func()
    return
  }
  chat.off(TencentCloudChat.EVENT.SDK_READY, onSdkReady);
  chat.logout();
  chat.destroy().finally(() => {
    func && func()
  });
};

export const orderCreateSendMessage = (detail: OrderCreateMallResponse) => {

  if (!chat) {
    return
  }

  let message = chat.createCustomMessage({
    to: toUserId,
    conversationType: TencentCloudChat.TYPES.CONV_C2C,
    // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
    // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
    payload: {
      data: "orderCreateSendMessage",
      extension: JSON.stringify({ coverUrl: detail.productImage, name: detail.productName, orderNumber: detail.orderNumber, amount: detail.amount, sellerUserId: detail.sellerUserId, userId: detail.userId }),
    },
  })
  // 3. 发送消息
  let promise = chat.sendMessage(message);
  promise.then(function (imResponse) {
    // 发送成功
    console.log(imResponse);
  }).catch(function (imError) {
    // 发送失败
    console.warn('sendMessage error:', imError);
  });
}

export const orderPayedSendMessage = (detail: orderDetailResponse) => {
  if (!toUserId) {
    return
  }
  let message = chat.createCustomMessage({
    to: toUserId,
    conversationType: TencentCloudChat.TYPES.CONV_C2C,
    // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
    // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
    payload: {
      data: "orderPayedSendMessage",
      extension: JSON.stringify({ coverUrl: detail.productImage, name: detail.productName, orderNumber: detail.orderNumber, amount: detail.amount, sellerUserId: detail.sellerUserId, userId: detail.userId, serviceCharge: detail.serviceCharge }),
    },
  })
  // 3. 发送消息
  let promise = chat.sendMessage(message);
  promise.then(function (imResponse) {
    // 发送成功
    console.log(imResponse);
  }).catch(function (imError) {
    // 发送失败
    console.warn('sendMessage error:', imError);
  });
}

export const orderReceiptOfGoodsSendMessage = (detail: orderDetailResponse | OrderListResponse, success?: () => void) => {
  if (!toUserId) {
    return
  }
  let message = chat.createCustomMessage({
    to: toUserId,
    conversationType: TencentCloudChat.TYPES.CONV_C2C,
    // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
    // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
    payload: {
      data: "orderReceiptOfGoodsSendMessage",
      extension: JSON.stringify({ coverUrl: detail.productImage, name: detail.productName, orderNumber: detail.orderNumber, amount: detail.amount, sellerUserId: detail.sellerUserId, userId: detail.userId, serviceCharge: detail.serviceCharge }),
    },
  })
  // 3. 发送消息
  let promise = chat.sendMessage(message);
  promise.then(function (imResponse) {
    // 发送成功
    console.log(imResponse);
    success && success()
  }).catch(function (imError) {
    // 发送失败
    console.warn('sendMessage error:', imError);
  });
}

export const orderUpdatePriceSendMessage = (detail: orderDetailResponse | SellerOrderListResponse, price?: number) => {
  if (!toUserId) {
    return
  }
  let message = chat.createCustomMessage({
    to: toUserId,
    conversationType: TencentCloudChat.TYPES.CONV_C2C,
    // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
    // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
    payload: {
      data: "orderUpdatePriceSendMessage",
      extension: JSON.stringify({ coverUrl: detail.productImage, name: detail.productName, orderNumber: detail.orderNumber, amount: price || detail.amount, sellerUserId: detail.sellerUserId, userId: detail.userId, serviceCharge: detail.serviceCharge }),
    },
  })
  // 3. 发送消息
  let promise = chat.sendMessage(message);
  promise.then(function (imResponse) {
    // 发送成功
    console.log(imResponse);
  }).catch(function (imError) {
    // 发送失败
    console.warn('sendMessage error:', imError);
  });
}

export const orderConfirmSubmissionSendMessage = (detail: orderDetailResponse | SellerOrderListResponse) => {
  if (!toUserId) {
    return
  }
  let message = chat.createCustomMessage({
    to: toUserId,
    conversationType: TencentCloudChat.TYPES.CONV_C2C,
    // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
    // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
    payload: {
      data: "orderConfirmSubmissionSendMessage",
      extension: JSON.stringify({ coverUrl: detail.productImage, name: detail.productName, orderNumber: detail.orderNumber, amount: detail.amount, sellerUserId: detail.sellerUserId, userId: detail.userId, serviceCharge: detail.serviceCharge }),
    },
  })
  // 3. 发送消息
  let promise = chat.sendMessage(message);
  promise.then(function (imResponse) {
    // 发送成功
    console.log(imResponse);
  }).catch(function (imError) {
    // 发送失败
    console.warn('sendMessage error:', imError);
  });
}
