import styled from "styled-components"

export const BadgeModalStyle = styled.div`
.modal {
        height: 100px;
        .header {
            height: 50px;
            background-color: #000;
        }
        .content {
            padding: 20px 0;
        }
        .badge-count {
            font-size: 30px;
        }
    } 

::v-deep .ant-modal-content {
    padding: 0;
}
`