import { Button, Checkbox, Modal, message } from "antd"
import { useEffect, useState } from "react"
import { getShopNotesList, postApplicantShop } from "api/shop"
import { CfgMallShopApplyRemarkItem, CfgMallShopApplyRemarkResponse } from "types/product"
import AgreementModal from "../agreement-modal"

const ApplicantModal = ({ isShow, close, success }: { isShow: boolean, close: () => void, success: () => void }) => {

    const [data, setData] = useState<CfgMallShopApplyRemarkResponse[]>([])

    const [isAgreement, setIsAgreement] = useState(false)

    const [customValue, setCustomValue] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const [isShowAgreement, setIsShowAgreement] = useState(false)

    useEffect(() => {
        getShopNotesList().then(res => {
            setData(res)
        })
    }, [])

    const [selectData, setSelectData] = useState<string[]>([])

    const onChangeItem = (detail: CfgMallShopApplyRemarkItem, fIndex: number) => {
        const index = selectData.findIndex(item => item === detail.name)
        if (index >= 0) {
            selectData.splice(index, 1)
            setSelectData(selectData)
        } else {
            selectData.push(detail.name)
            setSelectData(selectData)
        }
    }

    const onChangeAgreement = (e: boolean) => {
        setIsAgreement(e)
    }

    const submit = () => {
        if (!isAgreement) {
            message.warning("请勾选G站开店协议")
            return
        }
        const finalData: string[] = JSON.parse(JSON.stringify(selectData))
        const otherIndex = finalData.findIndex(item => item === '其他')
        if (otherIndex >= 0) {
            finalData.splice(otherIndex, 1)
            finalData.push(`其他：${customValue}`)
        }
        const afterOtherIndex = finalData.findIndex(item => item.includes("其他："))
        if (afterOtherIndex >= 0) {
            finalData.splice(afterOtherIndex, 1)
            finalData.push(`其他：${customValue}`)
        }
        if (finalData.length === 0) {
            message.warning("请选择计划出售的商品")
            return
        }
        postApplicantShop(JSON.parse(JSON.stringify(finalData)).join(",")).then(() => {
            message.success("提交成功")
            success()
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return <div>
        <>
            <Modal footer={<></>} onCancel={() => close()} destroyOnClose={true} closable={true} centered={true} open={isShow}>
                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>计划出售的商品（可多选）</div>
                <div style={{ margin: "20px 0 10px" }}>
                    {
                        data.map((item, fIndex) => {
                            return <div style={{ display: "flex" }} key={item.name}>
                                <div className="name" style={{ marginRight: "30px" }}>{item.name}</div>
                                <div className="value" style={{ marginBottom: "15px" }}>
                                    {
                                        item.items.map((sub, index) => {
                                            return <div key={index} style={{ display: "inline-block" }}>
                                                {
                                                    sub.canEdit === 1 && <div style={{ display: "inline-block" }}>
                                                        <Checkbox style={{ marginRight: "5px" }} onChange={() => onChangeItem(sub, fIndex)}>{sub.name}</Checkbox>
                                                        <input onChange={(e) => (setCustomValue(e.target.value))} type="text" style={{ border: "none", borderBottom: "1px solid #eee", outline: "none", width: "80px", padding: "2px 4px" }} />
                                                    </div>
                                                }
                                                {
                                                    sub.canEdit === 0 && <Checkbox style={{ marginRight: "15px" }} onChange={() => onChangeItem(sub, fIndex)}>{sub.name}</Checkbox>
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                <div style={{ textAlign: "center" }}>
                    <Checkbox style={{ marginRight: "5px" }} checked={isAgreement} onChange={(e) => onChangeAgreement(e.target.checked)}>我已阅读并同意<span style={{ color: "#ffc712" }} onClick={(sub) => { sub.preventDefault(); setIsShowAgreement(true); console.log(1111) }}>《 G站开店协议 》</span></Checkbox>
                </div>
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                    <Button loading={isLoading} onClick={submit} style={{ width: "150px" }} type="primary">提交申请</Button>
                </div>
            </Modal >
            <AgreementModal isShow={isShowAgreement} close={() => setIsShowAgreement(false)} />
        </>
    </div >
}

export default ApplicantModal