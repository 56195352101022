import { DraftWorkStyle } from "./style";
import { useCallback, useEffect, useState } from "react";
import { Pagination, Spin } from "antd";
import { useMemo } from "react";
import { WorkDraftItemRequest, WorkDraftItem } from "types/work";
import { PaginationResponse } from "types/common/axios";
import { apiWorkDraftList } from "api/user/work";
import { useNavigate } from "react-router";
import { NoData } from "components/no-data";
import Filter from "./filter";
import { filterValueType } from "../workType";
import UISegmentedControl from "ui-lib/segmented-control";
import DraftWorkCard from "components/uni-card/self-cards/draftWorkCard";
import useWindowSize from "hooks/useWindowSize";

function DraftWork() {
  const [workList, setWorkList] = useState<WorkDraftItem[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [workData, setWorkData] = useState<PaginationResponse<WorkDraftItem>>();
  const [filterValue, setFilterValue] = useState<filterValueType>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [screenSize] = useWindowSize();
  const draftParams: WorkDraftItemRequest = useMemo(() => {
    const params: WorkDraftItemRequest = {
      pageNumber,
      pageSize: 30,
    }
    if (params.status !== 4) {
      params.status = filterValue?.status
    }
    return params
  }, [pageNumber, filterValue?.status]);

  const getDraftList = useCallback(() => {
    setLoading(true)
    apiWorkDraftList(draftParams).then((res) => {
      setWorkList(res.pageData);
      setWorkData(res);
    }).finally(() => {
      setLoading(false)
    })
  }, [draftParams]);

  useEffect(() => {
    getDraftList();
  }, [getDraftList]);

  const tabOption = [
    {
      label: "已通过",
      value: "work",
    },
    {
      label: "审核和草稿",
      value: "draft",
    },
    {
      label: "下架",
      value: "offShelf",
    },
  ];

  const changePage = (e: number) => {
    setPageNumber(e);
  };

  const changeWorkType = (e: any) => {
    if (e === "work") {
      navigate("/user-center/home/work/list");
    } else if (e === "offShelf") {
      navigate("/user-center/home/work/offShelf/list");
    }
  };

  const updateFilterValue = (value: filterValueType) => {
    setFilterValue(value);
  };

  // const revertWork = (id: number) => {
  //   apiRevertWork(id).then(res => {
  //     const index = workList.findIndex((item) => item.id === id);
  //     workList[index].status = 0;
  //     workList[index].canEdit = 1;
  //     setWorkList([...workList]);
  //     message.success("撤销审核成功");
  //   })
  // }

  // const deleteWork = (id: number) => {
  //   console.log(id)
  //   Modal.confirm({
  //     title: "确定删除？",
  //     content: "删除该草稿作品后，将无法找回",
  //     okText: "确定",
  //     cancelText: "取消",
  //     onCancel() { },
  //     centered: true,
  //     onOk() {
  //       // console.log(id)
  //       // return
  //       postDeleteDraftWork(id).then(() => {
  //         const index = workList.findIndex((item) => item.id === id);
  //         workList.splice(index, 1);
  //         setWorkList([...workList]);
  //         message.success("删除成功");
  //       });
  //     },
  //   });
  // };

  return (
    <DraftWorkStyle>
      <div className="top-bar">
        <div className="left">

          <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={'draft'}></UISegmentedControl>

        </div>
        <div className="right">
          <Filter change={updateFilterValue} />
        </div>
      </div>

      <Spin spinning={loading}>
        {workList.length === 0 && (
          <div
            style={{ width: "100%", marginTop: "200px", position: "relative" }}
          >
            <NoData></NoData>
          </div>
        )}
        <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
          {workList.map((item, index) => (
            <DraftWorkCard key={index} item={item} shouldRefresh={() => getDraftList()}></DraftWorkCard>
          ))}
        </div>
      </Spin>
      <Pagination
        style={{ textAlign: "center" }}
        hideOnSinglePage={true}
        onChange={changePage}
        pageSize={30}
        showSizeChanger={false}
        total={workData?.totalSize}
        current={pageNumber}
      />
    </DraftWorkStyle>
  );
}

export default DraftWork;
