import { useNavigate } from "react-router-dom"
import { SignedAuthorMaterialStyle } from "./style"
import { CertifiedAuthorDetailResponse } from "types/user/auth-writer"
import { useEffect, useState } from "react"
import { apiGetAuthWriterDetail } from "api/user/auth-writer"

const SignedAuthorMaterial = () => {
    const navigate = useNavigate()
    const [detail, setDetail] = useState<CertifiedAuthorDetailResponse>()

    const toMaterial = () => {
        if ([2, 3].includes(detail?.status!)) {
            navigate(`/user-creator-center/signed-author/material`)
        }
    }

    const getDetail = () => {
        apiGetAuthWriterDetail(2).then(res => {
            setDetail(res)
        })
    }


    useEffect(() => {
        getDetail()
    }, [])

    return <SignedAuthorMaterialStyle>
        <div className="list">
            <div className="list-item">
                <div className="title">01</div>
                <div className="desc">
                    <div>提交签约信息</div>
                </div>
                <div className="btn" onClick={toMaterial}>{detail?.status === 0 ? '待审核' : detail?.status === 1 ? '已认证' : detail?.status === 2 ? '驳回' : '立即提交'}</div>
                {
                    detail?.status === 2 && <div className="finish">驳回原因：{detail?.refuseReason}</div>
                }
            </div>
        </div>
    </SignedAuthorMaterialStyle>
}

export default SignedAuthorMaterial