import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const DiscoverListStyle = styled.div`
  .guanggao-banner-wrapper {
    padding: ${(props) => props.theme.space.paddingSM}px
      ${(props) => props.theme.space.paddingLG}px;
  }
  .category-search-container {
    overflow: auto;
    overflow-x: hidden;
    height: 100vh;
    &::before {
      content: "";
      display: block;
      width: 100vw;
      height: 50px;
      background-color: #232424 !important;
    }
    .search-wrapper {
      display: flex;
      justify-content: center;
      margin-top: ${(props) => 2 * props.theme.space.margin}px;
      margin-bottom: ${(props) => 1.6 * props.theme.space.margin}px;
      .ant-tabs-nav {
        margin-bottom: 0;
      }
      .ant-tabs-nav-wrap {
        justify-content: center;
      }

      .tab-name {
        text-align: center;
        font-size: 16;
      }

      .ant-tabs-tab {
        color: ${(props) => props.theme.colors.colorText} !important;
        padding: ${props => props.theme.space.padding}px 10px;
        margin: 0 ${props => props.theme.space.margin}px;
        &::after{
          margin-right: 0;
        }
        font-size: 20px;
      }
      .ant-tabs-tab-btn {
        /* color: ${(props) => props.theme.colors.colorText} !important; */
        color: #8f8f8f;
        font-weight: 200;
        text-shadow: 0 0 0.25px currentcolor;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        &:hover {
          color: ${(props) => props.theme.colors.colorText} !important;
        }
      }
        .ant-tabs-tab:hover {
          &::after {
            height: 2px !important;
          }
        }
        .ant-tabs-tab::after {
          content: "";
          width: 100%;
          position: absolute;
          background-color: ${props => props.theme.colors.colorPrimary};
          height: 0;
          bottom: 0;
          left: 0;
          
        }
      .ant-tabs-tab-active {
        .tab-name {
          color: ${(props) => props.theme.colors.colorText} !important;
        }
      }
      .second-wrapper {
        display: flex;
        justify-content: center;
        /* background-color: ${(props) =>
    props.theme.colors.colorBlack1Ggac}; */
        padding: ${(props) => props.theme.space.paddingSM}px
          ${(props) => props.theme.space.paddingLG}px;
        width: 100vw;
        .second-item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 ${(props) => props.theme.space.paddingLG}px;
          height: 42px;
          border: 1px solid ${(props) => props.theme.colors.colorBorder};
          font-size: ${(props) => props.theme.fontSizes.fontSize}px;
          margin: 0 10px;
          border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          /* display: inline-block; */
          white-space: nowrap;
          .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
            z-index: 1;
            filter: brightness(65%) saturate(120%);
          }
          .name {
            color: #fff;
            z-index: 2;
          }
        }
        .second-item-active {
          .name {
            color: ${(props) => props.theme.colors.colorPrimary};
          }
        }
      }
    }
    .select {
      padding: 0 ${(props) => props.theme.space.paddingLG}px;
      display: flex;
      justify-content: space-between;
      min-width: 1100px;
    }
    .list-wrapper {
      padding: ${(props) => props.theme.space.paddingLG}px;
      .list {
        /* display: grid;
        --auto-grid-min-size: 19rem;
        grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
        gap: 1rem;
        min-width: 1250px; */

        &>div {
          display: grid;
        }

        display: grid;
        --auto-grid-min-size: 19rem;
        grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
        gap: 1rem;
      }
      .infinite-scroll-component {
        display: grid;
        --auto-grid-min-size: 19rem;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        gap: 1rem;
        .list-item {
          width: 100%;
        }
      }
    }
  }

  .article-type{
    display: flex;
    justify-content: center;
    margin: 12px 0;
    /* border-bottom: 1px solid #f0f0f0; */
  }

  .article {
      padding-top: ${(props) => props.theme.space.paddingLG}px;
  }

  .article-wrapper {
    margin-bottom: 0 !important;
    .second-wrapper {
      padding: 0 !important;
    }
  }

  .article-list {
      display: flex;
      gap: 0px;
      .active{
        color: ${(props) => props.theme.colors.colorPrimary} !important;
      }
      &-item{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 24px;
        height: 42px;
        font-size: 14px;
        margin: 0px 10px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: rgb(166,166,166);
        color: #fff;
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
            z-index: 1;
            filter: brightness(65%) saturate(120%);
          }
          span{
            z-index: 100;
          }
      }
    }

`;
