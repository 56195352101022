import { get, post } from "api"
import { PaginationResponse } from 'types/common/axios'
import { payCommodityParams, appendixWebDownloadItem, appendixBuyItem, commodityOrderParams, userCommodityOrderParams, weixinPayReturn, aliPayReturn, commodityOrderItem, bizCommodityType, moveCommodityParams, bizCommodityParams, appendixUploadParams, shelvesCommodityParams, bizCommodityCreateParams, appendixUploadType, appdedixUploadCreate } from "types/commodity";

// 获取商品列表
export const apiGetCommodityList = async (params: bizCommodityParams) =>
    await get<bizCommodityParams, PaginationResponse<bizCommodityType>>("/api/v6/nft/biz/product/list", params);

// 创建商品
export const apiPostCreateCommdity = async (params: bizCommodityCreateParams) =>
    await post<bizCommodityCreateParams, {}>("/api/seller_mall_product/save", params);

// 删除商品
export const apiPostDeleteCommodity = async (id: number) =>
    await post<{ id: number }, number>(`/api/v6/nft/biz/product/delete/${id}`);

// 商品详情
export const apiGetCommodityDetail = async (id: number) =>
    await get<{ id: number }, bizCommodityType>(`/api/seller_mall_product/detail/${id}`);

// 买家商品详情
export const apiGetUserCommodityDetail = async (id: number) =>
    await get<{ id: number }, bizCommodityType>(`/api/v6/nft/web/product/detail/${id}`);

// 修改商品
export const apiPostUpdateCommodity = async (params: bizCommodityCreateParams) =>
    await post<bizCommodityCreateParams, PaginationResponse<number>>("/api/v6/nft/biz/product/update", params);

// 商品上下架
export const apiPostShelvesCommodity = async (params: shelvesCommodityParams) =>
    await post<shelvesCommodityParams, PaginationResponse<number>>("/api/v6/nft/biz/product/on_sale", params);

// 商品移动
export const apiPosMoverCommodity = async (params: moveCommodityParams) =>
    await post<moveCommodityParams, PaginationResponse<number>>("/api/v6/nft/biz/product/move", params);

// 获取附件列表

export const apiGetAppendixUploadList = async (params: appendixUploadParams) =>
    await get<appendixUploadParams, PaginationResponse<appendixUploadType>>("/api/v6/nft/biz/download/list", params);

// 获取Web附件列表
export const apiGetAppendixDownloadList = async (params: appendixUploadParams) =>
    await get<appendixUploadParams, PaginationResponse<appendixWebDownloadItem>>("/api/v6/nft/web/download/list", params);

// 创建附件

export const apiPostCreateAppendix = async (params: appdedixUploadCreate) =>
    await post<appdedixUploadCreate, PaginationResponse<number>>("/api/v6/nft/biz/download/create", params);

// 前台附件列表
export const apiGetBuyAppendixList = async (params: appendixUploadParams) =>
    await get<appendixUploadParams, PaginationResponse<appendixBuyItem>>("/api/v6/nft/web/download/list", params);

// 修改附件
export const apiPostUpdateAppendix = async (params: appdedixUploadCreate) =>
    await post<appdedixUploadCreate, PaginationResponse<number>>("/api/v6/nft/biz/download/update", params);

// 商品创建订单
export const apiPostCreateCommodityOrder = async (params: payCommodityParams) =>
    await post<payCommodityParams, commodityOrderItem>("/api/v6/nft/web/order/create", params);

// 查询订单详情
export const apiGetOrderDetail = async (orderNumber: string) =>
    await get<payCommodityParams, commodityOrderItem>(`/api/v6/nft/web/order/detail/${orderNumber}`);

// 商品微信支付
export const apiPostWeixinPayCommodity = async (orderNumber: string) =>
    await post<string, weixinPayReturn>(`/api/payment/wx_native_pay/${orderNumber}`);
//商品支付宝支付
export const apiPostAliPayCommodity = async (params: { orderNumber: string, returnUrl?: string }) =>
    await post<{ orderNumber: string, returnUrl?: string }, aliPayReturn>(`/api/payment/ali_page_pay`, params);

// 商品订单列表后台
export const apiGetCommodityOrderList = async (params: commodityOrderParams) =>
    await get<commodityOrderParams, PaginationResponse<commodityOrderItem>>(`/api/v6/nft/biz/order/list`, params);

// 前台订单列表
export const apiGetUserCommodityOrderList = async (params: userCommodityOrderParams) =>
    await get<userCommodityOrderParams, PaginationResponse<commodityOrderItem>>(`/api//order/list`, params);

// 订单取消支付
export const apiPostCancelCommodityOrder = async (orderNumber: string) =>
    await post<string, aliPayReturn>(`/api/v6/nft/web/payment/cancel/${orderNumber}`);


