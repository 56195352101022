import styled from "styled-components";

export const UiAvatarStyle = styled.div<{size?: number}>`

.border {
  border:5px solid ${(props) => props.theme.colors.colorBlack1Ggac};
}

.AvatarRoot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  /* overflow: hidden; */
  user-select: none;
  width:${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
  position: relative;
  .role {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    font-weight: bolder;
    &.yellow{
      background-color:  ${(props) => props.theme.colors.colorPrimary};
    }
    &.orange {
      background-color: #f37425;
      font-size: 11px;
    }
    &.green{
      background-color:#52c41a;
    }
    &.purple{
      background-color:#722ed1;
    }
    &.blue{
      background-color:#1677ff;
    }
    &.hide{
      display: none;
    }
  }
}

.AvatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;

}

.AvatarFallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.colorPrimaryBg};
  color:  ${(props) => props.theme.colors.colorPrimaryText};
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
}
`