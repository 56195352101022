import { useKeenSlider } from "keen-slider/react";
import { CeremonySwiperStyle } from "./style"

const CeremonySwiper = ({ list, moreUrl, aspect }: { list: { cover: string, id: number, url: string, name: string }[], moreUrl?: string, aspect?: number }) => {
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slides: {
            perView: 4,
            spacing: 15
        },
    });
    const move = (type: string) => {
        if (type === "prev") {
            instanceRef.current?.prev();
        }
        if (type === "next") {
            instanceRef.current?.next();
        }
    };
    return <CeremonySwiperStyle>
        <div className="wrap">
            <div ref={sliderRef} className="keen-slider">
                {
                    list.map(item => {
                        return <div key={item.id} className="keen-slider__slide" onClick={() => window.open(item.url)}>
                            <div className="cover" style={{ backgroundImage: `url(${item.cover})`, aspectRatio: !!aspect ? aspect : 450 / 250 }} />
                            {
                                !!item.name && <div className="name">{item.name}</div>
                            }
                        </div>
                    })
                }

            </div>
            {
                list.length > 0 && <>
                    <div className="arrow-wrap arrow-left" onClick={() => move("prev")} style={{ top: !!moreUrl ? '33%' : "40%" }}>
                        <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/xrtz/xrtz-part3-arrow-left.png" />
                    </div>
                    <div className="arrow-wrap arrow-right" onClick={() => move("next")} style={{ top: !!moreUrl ? '33%' : "40%" }}>
                        <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/xrtz/xrtz-part3-arrow-right.png" />
                    </div>
                </>
            }
            {
                moreUrl && <div className="btn" onClick={() => window.open(moreUrl)}>查看更多</div>
            }
        </div>
    </CeremonySwiperStyle>
}

export default CeremonySwiper