import { ContestSignupListItem, apiGetSelfContestLis } from "api/contest";
import { ApplicantPopupStyle } from "./style"
import { useEffect, useState } from "react";
import ContestCard from "../contest-card";
import { NoData } from "components/no-data";

const ApplicantPopup = () => {

    const [myContests, setMyContests] = useState<ContestSignupListItem[]>();

    const getMyContests = () => {
        window.location.pathname === "/user-center/home/contest-work/list" &&
            apiGetSelfContestLis().then((res: any) => {
                setMyContests(res);
            });
    };

    useEffect(() => {
        getMyContests();
    }, [])

    return <ApplicantPopupStyle>
        {window.location.pathname === "/user-center/home/contest-work/list" && (
            <div className="outer">
                {
                    myContests && myContests.length > 0 && <div className="flex" style={{ marginBottom: 20 }}>
                        {myContests?.map((item, index) => {
                            return <ContestCard key={index} item={item}></ContestCard>;
                        })}
                    </div>
                }
                {
                   !myContests && <>
                        <NoData />
                   </> 
                }
            </div>
        )}
    </ApplicantPopupStyle>
}

export default ApplicantPopup




