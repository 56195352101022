import styled from "styled-components"

export const ReceiveOrderDetailStyle = styled.div`
    
    .detail {
        .list-title {
          padding: ${props => props.theme.space.padding}px 30px ${props => props.theme.space.padding * 2}px;
          display: flex;
          span {
              padding: 0 5px;
          }
          .link {
              color: #808080;
              cursor: pointer;
              &:hover {
                color: #FF9A16;
              }
          }
        }
        .value {
            max-width: calc(100% - 100px);
        }

        .fileSupplement {
            overflow-wrap: break-word;
        }

        .second-image-list {
            margin-bottom: 100px;
        }

        .ant-divider-inner-text {
            font-weight: bold;
        }
    
        .ant-divider {
            margin: 30px 0;
        }

        .deliver-title {
            background-color: #fbfbfd;
            text-align: center;
            line-height: 40px;
            margin: 50px 0;
            font-weight: bold;
            font-size: 15px;
        }

        &-item {
            display: flex;
            margin-bottom: 20px;
            font-size: 15px;
            justify-content: space-between;
            padding: 0px 30px;
        }

        .image-wrap {
            width: calc(100% - 100px);
        }

        .image {
            width: 100%;
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            .ant-image {
                width: calc((100% - 20px * 3) / 4 );
            }
            &-item {
                width: 100%;
                aspect-ratio: 1 / 1;
                object-fit: cover;
            }
            .file-item {
                width: calc((100% - 20px * 3) / 4 );
                aspect-ratio: 1 / 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #eee;
                position: relative;
                &:hover {
                    .block {
                        z-index: 2;
                    }
                }
                .block {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.7);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    cursor: pointer;
                    z-index: -1;
                    .word {
                        font-size: 12px;
                    }
                }
            }
        }
    }
`