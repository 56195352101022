import {  useEffect } from "react";
import { useAnimate, stagger } from "framer-motion";

export function useMenuAnimation(isOpen: boolean,classId: string) {
    const [scope, animate] = useAnimate();
    useEffect(() => {
        animate(classId, { y:[50,0]  }, {
            ease: "easeInOut",
            duration: 0.1,
            delay: stagger(0.05),
            x: { duration: 1 }
          })
    }, [isOpen,classId,animate]);
  
    return scope;
}