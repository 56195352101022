import { get, post } from "api"
import { PaginationResponse } from "types/common/axios"
import { GlobalSearchImageRequest, GlobalSearchImageResponse, MediaCreateRequest, MediaCreateResponse, MediaListItem, MediaPublicListRequest, MediaUpdateRequest, MediaUpdateResponse } from "types/media"


//媒体公开列表
export const getMediaList = async (params: MediaPublicListRequest) => {
    return get<MediaPublicListRequest, PaginationResponse<MediaListItem>>("/api/media/public_list", params)
}

export const getGlobelImageList = (params: GlobalSearchImageRequest) =>
    get<GlobalSearchImageRequest, PaginationResponse<GlobalSearchImageResponse>>("/api/global_search/image", params)

//创建媒体
export const apiCreateMedia = async (params: MediaCreateRequest) => {
    return post<MediaCreateRequest, MediaCreateResponse>("/api/media/create", params)
}


//编辑媒体

export const apiUpdateMedia = async (params: MediaUpdateRequest) => {
    return post<MediaUpdateRequest, MediaUpdateResponse>("/api/media/update", params)
}