import { Button, Modal, Space, Tooltip, message } from "antd"
import { AlbumCardStyle } from "./style"
import { albumListItem } from "types/album"
import { RiDeleteBin2Line, RiEdit2Line, RiEyeLine } from "react-icons/ri"
import { postDeleteAlbum } from "api/album"
import { useNavigate, useParams } from "react-router"
import { CoverImageObjectCloudResize } from "utils/functions/file"

const AlbumCard = ({ list, editAlbum, success, isShowPopup = true }: { list: albumListItem[], editAlbum?: (value: albumListItem) => void, success?: () => void, isShowPopup?: boolean }) => {
    // const editAlbum = (detail: albumListItem) => {

    // }
    const navigate = useNavigate()
    const userId = useParams()?.id
    const deleteAlbum = (detail: albumListItem) => {
        Modal.confirm({
            title: '确定删除？',
            content: '删除该作品集后，作品集的内容将会随之删除，并且无法找回！',
            okText: "确定",
            cancelText: "取消",
            onCancel() { },
            centered: true,
            onOk() {
                postDeleteAlbum(detail.id).then(() => {
                    message.success('删除成功')
                    success && success()
                })
            },
        });
    }

    const toDetail = (id: number) => {
        if (!isShowPopup) {
            navigate(`/user/${Number(userId)}/work-album/detail/${id}`)
        } else {
            navigate(`/user-center/home/work-album/detail/${id}`)
        }
    }


    const viewAlbum = (id: number) => {
        navigate(`/user-center/home/work-album/detail/${id}`)
    }

    return <AlbumCardStyle>
        <div className="ablum">
            {
                list.length > 0 && list.map(item => {
                    return <div className="ablum-card" key={item.id}>
                        <div className="cover" onClick={() => toDetail(item.id)}>
                            {
                                item.coverList && item.coverList.length > 0 && item.coverList.map((cover) => {
                                 
                                    return <div className="cover-item" style={{ backgroundImage: `url(${CoverImageObjectCloudResize(cover,500)})` }} key={cover} />
                                })
                            }
                            {
                                isShowPopup && <div className="center">
                                    <Space>
                                        {
                                            <Tooltip title="编辑" className="tooltip">
                                                <Button
                                                    size="large"
                                                    shape="circle"
                                                    onClick={(e) => { e.stopPropagation(); editAlbum && editAlbum(item) }}
                                                >
                                                    <RiEdit2Line></RiEdit2Line>
                                                </Button>
                                            </Tooltip>
                                        }
                                        <Tooltip title="删除" className="tooltip">
                                            <Button
                                                size="large"
                                                shape="circle"
                                                onClick={(e) =>{ e.stopPropagation(); deleteAlbum && deleteAlbum(item)}}
                                            >
                                                <RiDeleteBin2Line></RiDeleteBin2Line>
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="查看" className="tooltip">
                                            <Button
                                                onClick={() => viewAlbum(item.id)}
                                                size="large"
                                                shape="circle"
                                            >
                                                <RiEyeLine></RiEyeLine>
                                            </Button>
                                        </Tooltip>
                                    </Space>
                                </div>
                            }
                        </div>
                        <div className="info">
                            <div className="name" title={item.name}>{item.name}</div>
                        </div>
                    </div>
                })
            }
        </div>
    </AlbumCardStyle>
}

export default AlbumCard