import PageHeader from 'views/user-shop/layout/pageHeader'
import { NftEditStyle } from './style'
import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  message,
  Popconfirm,
  PopconfirmProps,
} from 'antd'
import { ImageCrop } from 'components/crop-img'
import { getFileName } from 'utils/functions/common'
import { getFileUrlByCosUpload } from 'config/cos'
import { CosDirectoryType } from 'types/enums/media-type'
import TinyEditor from 'ui-lib/tinyMCE'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Privileges from './privileges'
import {
  apiCreateSellerNftAlbum,
  apiSellerNftAlbumDetail,
  apiUpdateSellerNftAlbum,
} from 'api/nft-album'
import dayjs from 'dayjs'
import { NftPrivileges } from 'types/nft-album'
import 'dayjs/locale/zh-cn'
const { RangePicker } = DatePicker
const breadcrumbs = [
  {
    title: '图鉴列表',
  },
  {
    title: '创建图鉴',
  },
]
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
}

const initPrivileges = [
  {
    code: 'PSD',
    name: '下载源文件',
    description: '可下载本款图鉴卡的对应PSD或高清JPG文件',
    url: undefined,
    checked: 0
  },
  {
    code: 'BZ',
    name: '赠送壁纸',
    description: '可下载本款图鉴卡的对应精美壁纸',
    url: undefined,
    checked: 0
  },
  {
    code: 'TX',
    name: '赠送头像',
    description: '可下载本款图鉴卡的对应头像',
    url: undefined,
    checked: 0
  },
  // {
  //   code: 'CUSTOM-1',
  //   name: '',
  //   description: '',
  //   url: undefined,
  // },
  // {
  //   code: 'CUSTOM-2',
  //   name: '',
  //   description: '',
  //   url: undefined,
  // },
]

function EditSellerNftAlbum() {
  const [form] = Form.useForm()
  const [searchParmas] = useSearchParams()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const id = searchParmas.get('id')
  const navigate = useNavigate()
  const upload = async (params: {
    imgUrl: string
    file: File | undefined
    fieldName?: string
  }) => {
    if (params.file) {
      let urlKey = getFileName(CosDirectoryType.nftImage, params.file.name)
      let res = await getFileUrlByCosUpload(params.file!, urlKey)
      form.setFieldValue(params.fieldName, {
        file: undefined,
        imgUrl: res.url,
      })
    }
  }

  const submit = () => {
    form.validateFields().then((values: any) => {
      const {
        coverUrl,
        customDescription,
        detailUrl,
        url,
        rangeTime,
        rmbPrice,
        privileges,
      } = values
      let params = { ...values }
      params.customDescription = customDescription.html
      params.coverUrl = coverUrl.imgUrl
      params.detailUrl = detailUrl.imgUrl
      params.url = url.imgUrl
      params.startTime = rangeTime[0].format('YYYY-MM-DD HH:mm:ss')
      params.endTime = rangeTime[1].format('YYYY-MM-DD HH:mm:ss')
      params.rmbPrice = rmbPrice * 100
      delete params.rangeTime

      const hasMore = privileges.find((item: NftPrivileges) => {
        return item.checked === 1 && !(item.url && item.description && item.name)
      })

      if (!!hasMore) {
        return
      }

      const valid_privileges = privileges.filter(
        (item: NftPrivileges) => item.checked === 1
      )
      console.log(valid_privileges, 'valid_privileges')

      if (!valid_privileges || valid_privileges.length === 0) {
        message.error('请至少设置一个收藏权益，并上传附件！')
        return
      } else {
        params.privileges = valid_privileges
      }

      if (id) {
        apiUpdateSellerNftAlbum({ id: Number(id), ...params }).then((res) => {
          message.success('创建成功！')
          navigate('/user-center/tj-publish')
        })
      } else {
        apiCreateSellerNftAlbum(params).then((res) => {
          message.success('创建成功！')
          navigate('/user-center/tj-publish')
        })
      }
    })
  }

  useEffect(() => {
    if (id) {
      apiSellerNftAlbumDetail({ id: Number(id) }).then((res: any) => {
        const detail = res

        form.setFieldsValue({
          name: detail.name,
          rmbPrice: detail.rmbPrice / 100,
          limitedQuantity: detail.limitedQuantity,
          coverUrl: {
            file: undefined,
            imgUrl: detail?.coverUrl,
          },
          detailUrl: {
            file: undefined,
            imgUrl: detail?.detailUrl,
          },
          url: {
            file: undefined,
            imgUrl: detail?.url,
          },
          customDescription: {
            html: detail.customDescription,
            media: [],
          },
          rangeTime: [dayjs(detail.startTime), dayjs(detail.endTime)],
          privileges: updateArray(initPrivileges, detail.privileges),
        })
      })
    }
  }, [id])

  const updateArray = (
    originalArray: NftPrivileges[],
    updateArray: NftPrivileges[]
  ): NftPrivileges[] => {
    const originalArray2: NftPrivileges[] = JSON.parse(JSON.stringify(originalArray))// 创建原始数组的副本以避免直接修改

    updateArray.forEach(item=>{
        const index = originalArray2.findIndex(detail=>detail.code === item.code)
        if(index >= 0) {
          originalArray2[index]= item
        }else {
          originalArray2.push(item)
        }
    })
    

    originalArray2.forEach(item=>{
      if(item.url) {
        item.checked = 1
      }
    })


    return originalArray2
  }

  const confirm: PopconfirmProps['onConfirm'] = (e) => {
    submit()
  }

  return (
    <NftEditStyle>
      <PageHeader breadcrumbs={breadcrumbs}></PageHeader>
      <div className="content">
        <Form
          {...formItemLayout}
          className="create"
          style={{ maxWidth: 800 }}
          labelAlign="left"
          form={form}
          initialValues={{
            privileges: initPrivileges,
          }}
        >
          <h4>图鉴卡信息录入</h4>
          <Form.Item
            name="name"
            label="图鉴名称"
            rules={[{ required: true, message: '请选择图鉴名称' }]}
          >
            <Input type="text" maxLength={20} placeholder="请输入图鉴名称" disabled={Boolean(id)} />
          </Form.Item>
          <Form.Item
            name="coverUrl"
            label="封面上传"
            rules={[{ required: true, message: '请上传封面图' }]}
            extra="建议尺寸700*500，图片大小最大不能超出10M"
          >
            <ImageCrop
              uploadImage={(params) =>
                upload({ fieldName: 'coverUrl', ...params })
              }
              aspect={700 / 500}
            />
          </Form.Item>
          <Form.Item
            name="detailUrl"
            label="图鉴预览图"
            rules={[{ required: true, message: '请上传图鉴预览图' }]}
            extra="用于购买前的展示,建议尺寸700*700，图片大小最大不能超出10M"
          >
            <ImageCrop
              uploadImage={(params) =>
                upload({ fieldName: 'detailUrl', ...params })
              }
              aspect={700 / 700}
            />
          </Form.Item>
          <Form.Item
            name="url"
            label="上链高清图"
            rules={[{ required: true, message: '请上传上链高清图' }]}
            extra="购买后的用户可以查看,请上传高清原图，图片大小最大不能超出30M"
          >
            <ImageCrop
              uploadImage={(params) => upload({ fieldName: 'url', ...params })}
              noNeedCrop={true}
              limitSize={30}
              disabled={Boolean(id)}
            />
          </Form.Item>
          <Form.Item label="收藏权益" name="privileges" required>
            <Privileges></Privileges>
          </Form.Item>
          <Form.Item
            name="customDescription"
            label={
              <>
                图鉴卡描述填写{' '}
                <span className="tips" style={{ paddingLeft: 8 }}>
                  可以增加图鉴介绍,图鉴预览图,作者介绍,创作经历,获奖荣誉,粉丝福利,收藏说明等内容,促进用户购买
                </span>
              </>
            }
            rules={[{ required: true, message: '请输入图鉴卡描述' }]}
            className="description"
            labelCol={{ span: 24, offset: 24 }}
          >
            <TinyEditor
              width={800}
              cosImagePath={CosDirectoryType.productImage}
              cosVideoPath={CosDirectoryType.productVideo}
            ></TinyEditor>
          </Form.Item>
          <h4>图鉴发行设置</h4>
          <Form.Item
            name="rangeTime"
            label="发售起止时间"
            rules={[{ required: true, message: '开始-结束时间必填' }]}
          >
            <RangePicker
              minDate={dayjs(dayjs().subtract(0, 'day'))}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              placeholder={['发售开始时间', '发售结束时间']}
            />
          </Form.Item>
          <Form.Item
            name="limitedQuantity"
            label="发售数量"
            rules={[{ required: true, message: '请输入发售数量' }]}
          >
            <InputNumber
              style={{ width: 395 }}
              min={1}
              size="middle"
              max={1000}
              placeholder="填写图鉴卡计划发售数量，发售数量限制1-1000个"
              disabled={Boolean(id)}
            />
          </Form.Item>
          <Form.Item
            name="rmbPrice"
            label={'发售价格'}
            rules={[{ required: true, message: '请输入发售价格' }]}
          >
            <InputNumber
              min={1}
              style={{ width: 395 }}
              size="middle"
              max={1000}
              placeholder="填写图鉴卡计划发售价格，1-1000元"
            />
          </Form.Item>
          <div className="button-wrap">
            <Popconfirm
              title="温馨提示"
              description={
                <>
                  <p>1、图鉴上架即为开售，上架后不可修改，暂停售卖可下架图鉴</p>
                  <p>2、图鉴售出后的费用，将在发行结束后统一结算</p>
                  <p>
                    3、图鉴免费发行，售出后上链费用自付，限时补贴1元1个，发行结束后结算时扣除
                  </p>
                </>
              }
              onConfirm={confirm}
              okText="确定并提交"
              cancelText="取消"
            >
              <Button
                type="primary"
                loading={confirmLoading}
              // onClick={() => submit()}
              >
                提交审核
              </Button>
            </Popconfirm>
          </div>
        </Form>
      </div>
    </NftEditStyle>
  )
}

export default EditSellerNftAlbum
