import { RankCardStyle } from "./style";
import { Avatar, } from "antd";
import { RiFireLine } from "react-icons/ri";
import { RankingWorkItem } from "types/billboard";
import { prettifyNumber } from "utils/functions/common";
import { ImageObjectCloudResize } from "utils/functions/file";

function RankCard(props: { list: RankingWorkItem[] }) {


  return (
    <RankCardStyle>
      {props.list && props.list.map((item) => {
        return (
          <div className="card" key={item.id}>
            <a href={`/work/detail/${item.workId}`} target="_blank" rel="noreferrer">
              <div
                className="cover"
                style={{ backgroundImage: `url(${ImageObjectCloudResize(item.coverUrl, 500)})` }}
              ></div>
              <div className="content">
                <div className="flex">
                  <div className="upper" >
                    <div className="title" title={item.title}>
                     {item.title} </div>
                    <div className="view">
                      <RiFireLine className="icon"></RiFireLine>
                      <span>{prettifyNumber(item.incHot)}</span>
                    </div>
                  </div>
                  <div className="bottom">
                    <a href={`/user/${item?.userId}`} target="_blank" rel="noreferrer">
                      <Avatar className="avatar" size="small" src={item?.userInfo?.avatarUrl} />
                      <a className="user-name" >{item?.userInfo?.username}</a>
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      })}
    </RankCardStyle >
  );
}

export default RankCard;
