import { useState } from "react"
import { ShopApplicantStyle } from "./style"
import { Button, message } from "antd"
import { MallShopDetailResponse } from "types/product"
import ApplicantModal from "./applicant-modal"

const ShopApplicant = ({ detail, updateDetail }: { detail: MallShopDetailResponse, updateDetail: () => void }) => {

    const [isShow, setIsShow] = useState(false)

    const toApplicant = () => {
        setIsShow(true)
    }

    return <ShopApplicantStyle>
        <div className="applicant-content">
            <div className="applicant-title">申请开店</div>
            <div className="applicant-tip">店铺功能测试中，申请后请等待官方审核</div>
            <div className="applicant-step">
                <div className="applicant-step-item">
                    <img className="icon icon-three" src="https://cdn-prd.ggac.com/ggac/shop/product/applicant/applicant-icon2.png" />
                    <Button type="primary" onClick={toApplicant} disabled={[1, 2].includes(detail.status)}>{[0, 3].includes(detail.status) ? '申请开店' : detail.status === 1 ? '审核中' : detail.status === 2 ? '审核通过' : '禁止申请'}</Button>
                </div>
            </div>
            <ApplicantModal isShow={isShow} close={() => setIsShow(false)} success={() => { updateDetail(); setIsShow(false) }} />
        </div>
    </ShopApplicantStyle >
}

export default ShopApplicant