import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const EventExhibitionStyle = styled.div`
  padding-bottom: ${(props) => props.theme.space.paddingLG}px;
  .banner {
    /* margin-top: -50px; */
    .banner-image {
      img {
        width: 100%;
        aspect-ratio: 10/3;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .content {
    padding: 50px ${(props) => props.theme.space.paddingLG}px;
    .top {
      font-size: 22px;
      color: #f4aa2f;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
      margin: 0 auto;
      .name {
        margin-bottom: ${(props) => props.theme.space.marginLG}px;
      }
      .date {
        font-size: 18px;
      }
    }

    .desc {
      width: 100%;
      margin: ${(props) => props.theme.space.marginLG}px 0;
      word-break: break-all;
      box-sizing: content-box;
      font-size: 15px;
      text-align: center;

      img {
        max-width: 100%;
        object-fit: cover;
      }

      table {
        max-width: 100% !important;
      }

      video {
        display: block;
        width: 100% !important;
      }

      ol {
        margin-bottom: 10px;
        padding: 0 40px;
        text-align: left;
      }

      a {
        color: #0782c1;
      }

      span {
        line-height: 35px;
      }

      p {
        line-height: 35px;
        text-align: left;
      }
    }

    .address {
      margin-bottom: 30px;
      text-align: center;
      font-size: 16px;
      line-height: 35px;
    }
    .btn-wrap {
      text-align: center;
    }
  }
  .work {
    &-search {
      padding: 0 ${(props) => props.theme.space.paddingLG}px;
    }
  }
  .list {
    padding: ${(props) => props.theme.space.paddingLG}px;
    display: grid;
    grid-template-columns: repeat(
      ${dynamicColumnsFullWidth2()},
      1fr
    );
    gap: 1rem;
  }
`;
