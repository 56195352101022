import { Button, Form, Input, Modal, Select, message } from "antd"
import { BusinessContactStyle, FormStyle } from "./style"
import { useEffect, useState } from "react";
import { apiGetDictList } from "api/common";
import { DictItem } from "types/common";
import { postBusinessContact } from "api/common";

const BusinessContact = ({ isShow = false, onClose, partnerUser }: { isShow?: boolean, onClose: () => void, partnerUser?: { name?: string, id?: number } }) => {
    const [form] = Form.useForm<any>();
    const [professionList, setProfessionList] = useState<DictItem[]>()
 
    const submit = () => {
        form.validateFields().then(res => {
            postBusinessContact({ ...res, partnerUserId: partnerUser?.id }).then(() => {
                message.success("提交成功")
                onClose()
            })
        })
    }

    useEffect(() => {
        apiGetDictList({ type: "bizContactProfession" }).then(res => {
            setProfessionList(res)
        })
    }, [])
    useEffect(() => {
        form.setFieldValue("partnerUserName", partnerUser?.name)
    }, [partnerUser])

    useEffect(() => {
        if (!isShow) {
            form.resetFields()
        }
    }, [isShow])
    return <BusinessContactStyle>
        <Modal className='modal' onCancel={onClose} footer={<></>} title="共创合作" destroyOnClose={true} centered={true} open={isShow} width={640}>
            <FormStyle>
                <Form labelCol={{ span: 5 }} form={form} className="form">
                    {
                        partnerUser?.name && <Form.Item name="partnerUserName" label="合作人名称">
                            <Input disabled placeholder="合作人名称"></Input>
                        </Form.Item>
                    }
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "请输入联系人姓名",
                        }
                    ]} name="name" label="联系人姓名">
                        <Input placeholder="请输入联系人姓名"></Input>
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "请输入联系人电话",
                        },
                        {
                            pattern: /^[1][3,4,5,6.7,8,9][0-9]{9}$/,
                            message: "手机号格式有误"
                        }
                    ]} name="phone" label="联系人电话">
                        <Input placeholder="请输入联系人电话"></Input>
                    </Form.Item>
                    <Form.Item name="wx" label="联系人微信" rules={[
                        {
                            required: true,
                            message: "请输入微信联系账号",
                        },
                    ]}>
                        <Input placeholder="请输入联系人微信"></Input>
                    </Form.Item>
                    <Form.Item name="email" label="联系人邮箱" rules={[

                        {
                            pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z]{2,5}$/,
                            message: "邮箱格式有误"
                        }
                    ]}>
                        <Input placeholder="请输入联系人邮箱"></Input>
                    </Form.Item>

                    <Form.Item rules={[
                        {
                            required: true,
                            message: "公司全称",
                        }
                    ]} name="companyName" label="公司全称">
                        <Input placeholder="请输入公司全称"></Input>
                    </Form.Item>
                    <Form.Item name="companyAddress" label="公司地址">
                        <Input placeholder="请输入公司地址"></Input>
                    </Form.Item>

                    <Form.Item name="profession" rules={[
                        {
                            required: true,
                            message: "请选择所属行业",
                        }
                    ]} label="所属行业">
                        <Select
                            style={{ width: 160 }}
                            options={professionList}
                            fieldNames={{ label: 'name', value: 'code' }}
                            placeholder="请输入所属行业"
                        />
                    </Form.Item>
                    <Form.Item name="budget" rules={[
                        {
                            required: true,
                            message: "请输入共创预算",
                        }
                    ]} label="共创预算">
                        <Input placeholder="请输入共创预算"></Input>
                    </Form.Item>
                    <Form.Item name="projectNeed" rules={[
                        {
                            required: true,
                            message: "请输入项目需求",
                        }
                    ]} label="项目需求">
                        <Input.TextArea placeholder="请输入项目需求"></Input.TextArea>
                    </Form.Item>

                    <div className="btn" style={{ textAlign: 'center' }}>
                        <Button onClick={submit} type="primary" block>提交</Button>
                    </div>
                </Form>
            </FormStyle>
        </Modal>
    </BusinessContactStyle>
}

export default BusinessContact