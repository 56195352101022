import styled from "styled-components";

export const AlbumCardStyle = styled.div`
    border: 1px solid ${(props) => props.theme.colors.colorBorder};
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    min-width: 200px;
    .cover{
        width: 100%;
        aspect-ratio: 266 / 226;
        background-size: cover;
        background-position: center;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        position: relative;
    }
    .bottom {
        padding: ${props => props.theme.space.padding}px;
        .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .albun-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100% - 20px);
            }
            svg {
                color: #000;
                font-size: 20px;
            }
        }
        .user {
            display: flex;
            gap: 10px;
            align-items: center;
            margin: ${props => props.theme.space.marginXS}px 0 0;
            .avatar {
                aspect-ratio: 25 / 25;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                width: 25px;
            }
            .username {
                width: calc(100% - 40px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: ${(props) => props.theme.colors.colorText} !important;
                width: calc(100% - 35px);
                &:hover{
                    color: ${(props) => props.theme.colors.colorLinkHover} !important;
                }
            }
        }
    }

    .modal {
        text-align: center;
    }
`