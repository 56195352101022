import styled from "styled-components";

export const RewardApplicantListStyle = styled.div`
   
    .list {
        &-title {
          padding: ${props => props.theme.space.padding}px 0 ${props => props.theme.space.padding}px 20px;
          display: flex;
          span {
              padding: 0 5px;
          }
          .link {
              color: #808080;
              cursor: pointer;
              &:hover {
                color: #FF9A16;
              }
          }
      }

        &-item {
            border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
            padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
            min-height: 44px;
            .topic {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                align-items: center;
                gap: 35px;
                &-title {
                    min-width: 0px;
                    flex: 1;
                    font-size: 18px;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }
                .status {
                    color: #FF9A16;
                    min-width: 0px;
                    flex: 0 0 auto;
                    font-size: 15px;
                }
            }

            .price-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price {
                    display: flex;
                    color: #FF9A16;
                    gap: 5px;
                    align-items: center;
                    font-size: 15px;
                    flex: 1;
                    min-width: 0;
                    &-icon {
                        background: url(https://cdn-prd.ggac.com/ggac/reward/reward-price.png) center center no-repeat;
                        background-size: 100% 100%;
                        width: 15px;
                        height: 15px;
                    }
                }
                .word {
                    flex: 0 0 auto;
                    color: #808080;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    .btn {
                        font-size: 12px;
                        color: #000;
                        background: #ffc712;
                        border-radius: 50px;
                        line-height: 25px;
                        width: 68px;
                        text-align: center;
                        cursor: pointer;
                    }
                    .word-text {
                        cursor: pointer;
                    }
                }
            }
        }
    }
`