import styled from "styled-components";

export const EventListStyle = styled.div`
padding-bottom: ${props => props.theme.space.paddingLG}px;

.guanggao-banner-wrapper{
  padding: ${(props) => props.theme.space.paddingSM}px 0;
  width: ${(props) => props.theme.space.mainContainer}px;
  margin: 0 auto;
}

  @media screen and (max-width: 1440px) {
    .event-item {
      width: 585px !important;
      .cover {
        height: 280px !important;
      }
    }
  }
  .page-title{
    width: ${(props) => props.theme.space.mainContainer}px;
    margin: 0 auto;
    text-align: center;
    font-size: ${(props) => props.theme.fontSizes.fontSizeXL}px;
    padding: ${props => props.theme.space.paddingLG}px 0;
}
  .event-wrap {
    width: ${(props) => props.theme.space.mainContainer}px;
    margin: 0 auto;
    
  }
  .title_ {
    font-size: 16px;
    font-weight: bold;
    margin: ${(props) => props.theme.space.margin}px 0;
  }
  .type-list {
    display: flex;
    justify-content: flex-end;
    min-width: 1400px;
    margin: 0 auto;
  }
  .type {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 50px 0 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #F0F0F0;
    position: relative;
    .side-gg-banner{
      position: absolute;
      right: 0;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      top: 50%;
      transform: translateY(-70%);
      cursor: pointer;
      img {
        width: 100px;
      }
      .banner-item{
        object-fit: contain;
      }
    }
    &-item {
      font-size: 16px;
      font-weight: bold;
      position: relative;
      cursor: pointer;
      color: #8f8f8f;
      padding: 0 10px;
      margin: 0 ${props => props.theme.space.margin}px;
      &:hover {
        color: ${(props) => props.theme.colors.colorText} !important;
      }
    }
    &-item:hover,.active {
      color: ${(props) => props.theme.colors.colorText} !important;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: #FFC930;
        left: 50%;
        transform: translateX(-50%);
        top: 39px;
      }
    }
  }
  .search-wrap {
    display: flex;;
    justify-content: flex-end;
    margin-bottom: 20px;
    .ant-input-affix-wrapper {
      border-top-left-radius: 20px !important;
      border-bottom-left-radius: 20px !important;
    }
    .ant-input-search {
      width: 320px;
      button  {
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
    }
  }
  .event {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* display: flex;
    flex-wrap: wrap; */
    gap: ${(props) => props.theme.space.paddingLG}px;
    margin-bottom:${(props) => props.theme.space.marginLG}px;
    &-item {
      width: 100%;
      /* aspect-ratio: 10/4; */
      border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: ${(props) => props.theme.border.borderRadius}px;
      box-sizing: border-box;
      /* width: calc((100% - ${(props) => props.theme.space.marginLG}px) / 2); */
      &:hover{
        cursor: pointer;
            box-shadow: ${(props) => props.theme.shadow.boxShadowThin};
           
        }
      .cover {
        height: 327px;
        width: 100%; 
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
       
        border-radius: ${(props) => props.theme.border.borderRadius}px
          ${(props) => props.theme.border.borderRadius}px 0 0;
        overflow: hidden;
       
        .date {
          position: absolute;
          top: 20px;
          right: 0;
          height: 32px;
          color: #fff;
          line-height: 32px;
          text-align: center;
          padding: 0 ${(props) => props.theme.space.padding}px;
          background: rgba(0, 0, 0, 0.6);
          z-index: 2;
          border-radius: ${(props) => props.theme.border.borderRadiusSM}px 0 0 ${(props) => props.theme.border.borderRadiusSM}px; 
        }
      }
      .info {
        padding: ${(props) => props.theme.space.paddingLG}px;
        background: rgb(244, 244, 244);
        border-radius: 0 0 ${(props) => props.theme.border.borderRadius}px
          ${(props) => props.theme.border.borderRadius}px;
        overflow: hidden;
        /* height: 107px; */
        .title {
          color: ${(props) => props.theme.colors.colorBlack6Ggac};
          font-size: 16px;
        }
        .desc {
          color: ${(props) => props.theme.colors.colorBlack3Ggac};
          overflow: hidden;
          box-sizing: border-box;
          margin-top: ${(props) => props.theme.space.marginXS}px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 70ch;
          height: 25px;
        }
      }
    }
  }
`;
