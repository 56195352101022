import { Avatar, Badge } from "antd";
import { UserStyle } from "./style";
import { RiNotification2Line, RiUploadCloudLine } from "react-icons/ri";
import UploadDorpdown from "./uploadDorpdown";
import { useEffect, useState } from "react";
import UserDropdown from "./userDropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import NotificationDropdown from "./notificationDropDown";
import { getLocalUserInfo, hasToken } from "utils/functions/common";
import { useSelector } from 'react-redux';

function User() {
  const [currentHover, setCurrentHover] = useState(0)
  const [noticeCount, setNoticeCount] = useState(0);
  const navigate = useNavigate()
  let userInfo = getLocalUserInfo()
  const userNoticeCount = useSelector((state: any) => {
    return state.user.userNoticeCount
  });
  let [searchParams] = useSearchParams();
  const channelCode = searchParams.get('channelCode')

  const toLogin = (type: string) => {
    document.title = 'GGAC数字艺术平台';
    const redirectPath = window.location.href
    let base
    if (type === 'login') {
      base = '/auth/login'
    } else {
      base = '/auth/register'
    }

    base = base + `?redirect=${redirectPath}`
    if (channelCode) {
      base = base + `&channelCode=${channelCode}`
    }
    navigate(base, { replace: true })
  }

  useEffect(() => {
    setNoticeCount(userNoticeCount);
  }, [userNoticeCount])

  
  return (
    <UserStyle >
      {!userInfo && (
        <div className="unAuthed">
          <div className="sign-in" onClick={() => toLogin('login')}>登录</div>
          <div className="sign-up" onClick={() => toLogin("register")}>注册</div>
        </div>
      )}
      {hasToken() && (
        <div className="authed">
          <div
            className="notification"
            onClick={() => {
              document.title = 'GGAC数字艺术平台';
              navigate('/user-center/notification')
            }}
            onMouseEnter={() => setCurrentHover(4)}
            onMouseLeave={() => setCurrentHover(3)}
          >
            {/* noticeCount */}
            <Badge count={noticeCount} size="small">
              <div className="icon">
                <RiNotification2Line></RiNotification2Line>
              </div>
            </Badge>
            {currentHover === 4 && (
              <NotificationDropdown
                isOpen={currentHover === 4}
              ></NotificationDropdown>
            )}
          </div>
          <div className="upload" onMouseEnter={() => setCurrentHover(1)} onMouseLeave={() => setCurrentHover(0)}>
            <RiUploadCloudLine />
            {currentHover === 1 && <UploadDorpdown isOpen={currentHover === 1}></UploadDorpdown>}
          </div>

          {/* user-center/home */}
          <div className="avatar" onMouseEnter={() => setCurrentHover(2)} onMouseLeave={() => setCurrentHover(0)}>
            <Avatar
              onClick={() => {
                document.title = 'GGAC数字艺术平台'
                navigate(`/user-center/home/work/list`)
              }}
              src={userInfo.avatarUrl}
            >
            </Avatar>
            {currentHover === 2 && <UserDropdown isOpen={currentHover === 2} userInfo={userInfo}></UserDropdown>}
          </div>
        </div>
      )}
    </UserStyle>
  );
}

export default User;
