import styled from "styled-components";

export const SecurityStyle = styled.div`
  .content {
    margin: 100px auto 0;
    width: 650px;

  }
  .header {
    background-color:${props => props.theme.colors.colorBlack2Ggac};
    color:${props=>props.theme.colors.colorBlack6Ggac};
    padding: ${props => props.theme.space.padding}px ${props => props.theme.space.paddingLG}px;
    font-size: ${props => props.theme.fontSizes.fontSizeXL};
  }
  .detail{
   background-color:${props => props.theme.colors.colorBlack1Ggac};
   padding: ${props => props.theme.space.padding *2}px ;
  }
  .c-line{
   position:relative
  }
  .mb-20{
   margin-bottom: ${props => props.theme.space.margin}px!important;
  }
  .l-name{
   color: ${props=>props.theme.colors.colorBlack3Ggac};
   display: inline-block;
    width: 120px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  .l-content{
   color: #2b2e36;
   display: inline-block;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  .info-btn {
    position: absolute;
    right: 0;
    top: 0;
   
}
`;
