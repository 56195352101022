import React, {  memo, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { Input, Modal, Upload, message } from 'antd';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { RiAddLine } from 'react-icons/ri';
import { ImageDescUploadListStyle } from './style';
import ImgPreviewItem from './imgPreviewItem';
import { isFileOversized, isImage, isImageResolutionTooLarge } from 'utils/functions/file';

const { TextArea } = Input;

type Props = {
  disabled?: boolean
  value?: UploadFile[]
  onChange?: (params: UploadFile[]) => void
  maxLength?: number
} & UploadProps
const ImageDescUploadList: React.FC<Props> = memo(({ onChange: onFileChange, ...props }) => {
  const [previewImage, setPreviewImage] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const fileList = props.value || [];

 
  const onChange = async ({ fileList }: UploadChangeParam) => {
    let innerError = 0
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i].originFileObj;
      if(file && !isImage(file)){
        innerError =1
        break
      }
      if(file && isFileOversized(file,20)){
        innerError =2
        break
      }
      if(file &&  await isImageResolutionTooLarge(file, 7680 , 7680)) {
        innerError =3
        break
      }
    }

    switch (innerError) {
      case 1:
        messageApi.warning('上传文件中包含不支持的图片格式,请检查后重新上传',2);
        break;
        case 2:
          messageApi.warning('上传文件中包含尺寸超出20M文件,请检查后重新上传',2);
          break;
          case 3:
            messageApi.warning('包含超大分辨率图片,请检查后重新上传',2);
            break;
    }

  if(innerError===0){
    onFileChange!(fileList);
  }
    
  };

  const onRemove = (file: UploadFile) => {
    if (props.disabled) {
      return false
    }
    const newFileList = fileList.filter(
      (item) => item.uid !== file.uid
    );
   
    onFileChange!(newFileList);

  };

  const getBase64 = (file: File | Blob | undefined): Promise<string> => {
    if (!file) return Promise.reject(new Error('no file'));
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file!);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  // const imagePreview = async (file: UploadFile, callback: (params: { image: string }) => void) => {
  //   const newFile = file;
  //   if (!newFile.url && !newFile.preview) {
  //     newFile.preview = await getBase64(file.originFileObj);
  //   }

  //   const newPreviewImage: string = newFile.url || newFile.preview || '';
  //   callback({ image: newPreviewImage });
  // };

  // const onPreview = async (file: UploadFile) => {
  //   await imagePreview(file, ({ image }) => {
  //     setPreviewImage(image);
  //   });
  // };
  const beforeUpload = () => {

    return false
  }
  const uploadButton = (
    <div>
      <RiAddLine />
      <div>上传</div>
    </div>
  );

  const onChangeCaption = (file: UploadFile,e: any) =>{

    let index = fileList.findIndex(item => item.uid === file.uid)
    fileList[index].response = e.target.value;
  }

  return (
    <ImageDescUploadListStyle>
      {contextHolder}
      {fileList && (
          <div className='drop-wrap'>
            {fileList.map((item, index) => (
                  <div className='drop' key={index}>
                    {/* <UploadList
                      locale={{ previewFile: '预览图片', removeFile: '删除图片' }}
                      showDownloadIcon={false}
                      listType={props.listType}
                      onPreview={onPreview}
                      onRemove={onRemove}
                      showRemoveIcon={!props.disabled}
                      items={[item]}
                    /> */}
                    <ImgPreviewItem item={item} onDelete={onRemove}></ImgPreviewItem>
                      <TextArea style={{width: '154px',marginTop:4,fontSize:14}} value={item.response} onChange={(e) => onChangeCaption(item, e)}  rows={2} placeholder="请输入描述"  />
                  </div>
            ))}
      
                {!props.disabled && fileList.length < 8 && <Upload
                  {...props}
                  disabled={props.disabled}
                  fileList={fileList}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={onChange}
                  multiple={true}
                  className="upload-add"
                >
                  {uploadButton}
                </Upload>
                }
              </div>
           
      )}
      <Modal
        open={!!previewImage}
        footer={null}
        onCancel={() => setPreviewImage('')}
        bodyStyle={{ padding: 0 }}
      >
        <img style={{ width: '100%' }} alt="" src={previewImage} />
      </Modal>
    </ImageDescUploadListStyle>
  );
});

export { ImageDescUploadList };
