import styled from "styled-components";

export const UniCardLayoutStyle = styled.div`

  width: 100%;
  position: relative;
  border-radius: ${(props) => props.theme.border.borderRadius}px;
  border: 1px solid ${(props) => props.theme.colors.colorBorder};
  cursor: pointer;
  overflow: hidden;
  height: max-content;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.boxShadowSecondary};
  }
  min-width: 200px;
  .card-container {
    width: 100%;

    position: relative;

      .up-part {
        width: 100%;
        aspect-ratio: 266/191;
        position: relative;
        &.cover {
          background-repeat: no-repeat;
          background-size: cover;
          /* background-position: top; */
          position: relative;
          &:hover {
            .center {
              display: block;
            }
          }
        }
        
        .center {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
        }
        .left-bottom{
          position: absolute;
          left: 0;
          bottom: 0;
        }
        .right-top {
          position: absolute;
          right: 0;
          top: 0;
        }
        .right-bottom {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      .click-a-tag{
          background:transparent;
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          height: 100%;
          z-index: 1;
        }
      .subtitle{
        color: ${(props) => props.theme.colors.colorTextTertiary};
        font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        background-color: rgba(0,0,0,0.4);
        padding: ${(props) => props.theme.space.paddingXXS}px ${(props) => props.theme.space.paddingSM}px;
        color: #fff;
        border-radius: 6px;
      }
      .subtitle-re{
       
        position: absolute;
        width: 100%;
        height: 46px;
        bottom: 0;
        background: linear-gradient(to top, black, transparent);
        padding: ${(props) => props.theme.space.paddingXXS}px ${(props) => props.theme.space.paddingSM}px;
        color: #fff;
       line-height:46px;
        text-align: center;
      }
      .status{
        color: ${(props) => props.theme.colors.colorTextTertiary};
        font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
        background-color: rgba(0,0,0,0.4);
        padding: ${(props) => props.theme.space.paddingXXS}px ${(props) => props.theme.space.paddingSM}px;
        color: #fff;
        border-radius: 6px;
        margin-left: 10px;
        margin-bottom: 10px;
      }
    
    .bottom-part {
      padding: 12px
        ${(props) => props.theme.space.paddingMD}px;
      .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .qi {
        font-size: 13px;
        margin-left: 3px;
        color: rgba(0,0,0,0.4);
      }
      .title {
        cursor: pointer;
        width: 100%;
        a{
          color: ${(props) => props.theme.colors.colorText} !important;
          &:hover{
            color: ${(props) => props.theme.colors.colorLinkHover} !important;
          }
        }
        
      }

      .isPrivateInquiry {
        font-size: 15px;
        font-weight: bold;
        line-height: 31.5px;
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top:  ${(props) => props.theme.space.paddingSM - 4}px;
        .left{
          flex: 0 0 auto;
          .real{
              font-size: 20px;
              font-weight: 500;
              text-align: center;
              .sign{
                font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
              }
              .discount{
                color: ${(props) => props.theme.colors.colorTextTertiary};
                padding-left: ${(props) => props.theme.space.paddingXS}px;
                font-size: ${(props) => props.theme.fontSizes.fontSize}px;
                text-decoration: line-through;
              }
          }
          .orginal{

          }
          .user{
            display: flex;
            gap: 4px;
            align-items: center;
            position: relative;
            z-index: 2;
            .avatar{
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .overflow-hidden {
              max-width: 6vw;
            }

          }
        }
        .right{
          flex: 1;
          min-width: 0;
          display: flex;
          justify-content: flex-end;
          .action{

          }
          .real{
              font-size: 20px;
              font-weight: 500;
              text-align: center;
              .sign{
                font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
              }
              .discount{
                color: ${(props) => props.theme.colors.colorTextTertiary};
                padding-left: ${(props) => props.theme.space.paddingXS}px;
                font-size: ${(props) => props.theme.fontSizes.fontSize}px;
                text-decoration: line-through;
              }
          }
        }

      }
    }
  }
`;
