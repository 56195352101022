import { ImgPreviewItemStyle } from './style'
import { RiDeleteBin3Line } from 'react-icons/ri'
import { UploadFile } from 'antd/es/upload/interface';

function ImgPreviewItem({item,onDelete}:{item:UploadFile,onDelete:(item:UploadFile)=>void}) {
  const file = item.originFileObj
  if(!file) return <></>
  const blobURL = URL.createObjectURL(file);

  return (
    <ImgPreviewItemStyle>
        <div className='image' style={{backgroundImage:`url(${blobURL})`}}>

            <div className='actions'>
                <div className='item' onClick={()=>onDelete(item)}> <RiDeleteBin3Line></RiDeleteBin3Line></div>
               
            </div>
        </div>
    </ImgPreviewItemStyle>
  )
}

export default ImgPreviewItem