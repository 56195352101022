import styled from "styled-components";

export const RewardDetailStyle = styled.div`
    width: 100%;
    min-height: calc(100vh - 50px);
    background-color: #f6f8fb;
    padding-top: 50px;
    .top {
        width: 1032px;
        margin: 0 auto;
        background-color: #fff;
        padding: ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.padding}px;
        border-radius: ${props => props.theme.border.borderRadius}px;
        .list-title {
            display: flex;
            margin-bottom: 20px;
            span {
                padding: 0 5px;
            }
            .link {
                color: #808080;
                cursor: pointer;
                &:hover {
                    color: #FF9A16;
                }
            }
        }
        .title {
            display: flex;
            justify-content: space-around;
            font-size: 16px;
            gap: 10px;
            margin-bottom: ${props => props.theme.space.margin}px;
            .title-word {
                flex: 1;
                min-width: 0;
                font-weight: bold;
            }
            .price {
                display: flex;
                color: #FF9A16;
                gap: 10rpx;
                align-items: center;
                flex: 0 0 auto;
                font-size: 15px;
                &-icon {
                    background: url(https://cdn-prd.ggac.com/ggac/reward/reward-price.png) center center no-repeat;
                    background-size: 100% 100%;
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                }
            }
        }

        .user-info-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 20px;
            .share {
                color: #FF9A16;
                cursor: pointer;
                flex: 0 0 auto;
            }
        }

        .user-info {
            display: flex;
            gap: 15px;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
            .user-name {
                font-size: 15px;
                max-width: calc(100% - 50px);
                cursor: pointer;
            }
            .user-avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                cursor: pointer;
            }
        }
        .icon-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            color: ${(props) => props.theme.colors.colorTextSecondary};
            cursor: pointer;
            width: 55px;

            &:hover {
              color: ${(props) => props.theme.colors.colorPrimaryActive};
              border: none;
            }
            /* border: 1px solid ${props => props.theme.colors.colorTextSecondary}; */
        }
    }
    .container{
        width: 1032px;
        margin: ${(props) => props.theme.space.margin}px auto 0;
        display: grid;
        grid-template-columns:  minmax(737px, 5fr)  minmax(281px, 2fr);
        gap:14px;
        
        .left{
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            padding: ${(props) => props.theme.space.paddingLG}px 0;
            border-radius: ${props => props.theme.border.borderRadius}px;
            
            .part {
                padding: 0 ${(props) => props.theme.space.paddingLG}px;
            }

            .overflow {
                height: 300px;
                overflow-y: hidden;
            }

            .expand {
                display: flex;
                justify-content: flex-end;
                padding: 0 ${(props) => props.theme.space.paddingLG}px;
                margin-top: ${(props) => props.theme.space.margin}px;
                color: ${props => props.theme.colors.colorPrimary};
                div {
                    width: max-content;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
            

            .image {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            .desc {
                word-wrap: break-word;
                overflow-wrap: break-word;
            }

            .ant-divider-inner-text {
                font-weight: bold;
            }
        
            .ant-divider {
                margin: 50px 0;
            }

            .deliver-title {
                background-color: #fbfbfd;
                text-align: center;
                line-height: 40px;
                margin: 50px 0;
                font-weight: bold;
                font-size: 15px;
            }

            .file {
                padding: 20px 0;
                display: flex;
                gap: 20px;
                
                &-item {
                    display: flex;
                    justify-content: space-between;
                    color: ${props => props.theme.colors.colorPrimary};
                    cursor: pointer;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                }
            }
        
            .fileSupplement {
                margin: 20px 0;
                display: flex;
                .value {
                    max-width: calc(100% - 150px);
                    overflow-wrap: break-word;
                }
            }

    }
    .right{
        width: 281px;
        background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
        padding: ${(props) => props.theme.space.paddingLG}px;
        border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
        border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
        border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
        border-radius: ${props => props.theme.border.borderRadius}px;
        height: max-content;
        .btn-wrap {
            display: flex;
            gap: 10px;
            width: 100%;
            div {
                flex: 1;
                background-color: ${props => props.theme.colors.colorPrimary};
                line-height: 40px;
                text-align: center;
                margin-top: ${props => props.theme.space.margin}px;
                border-radius: 50px;
                color: #000;
                background: #ffc712;
                cursor: pointer;
            }
        }
    }
}
`