import SectionTitle from "components/section-title"
import { MonthSelectionStyle } from "./MonthSelectionStyle"
import { useEffect, useState } from "react"
import { getMothlyPicks } from "api/work"
import { workListVoList } from "types/work"
import MonthlyCard from "./card"
import { RiArrowRightSLine } from "react-icons/ri"

const MonthSelection = () => {
    const [list, setList] = useState<workListVoList[]>([])
    const [monthId, setMonthId] = useState(0)
    useEffect(() => {
        getMothlyPicks().then(res => {
            setList(res.workListVoList)
            setMonthId(res.eventCheckId)
        })
    }, [])

    const toMore = () => {
        window.open(`events/contribution?isCheckinSelected=1&id=${monthId}`)
    }
    return <MonthSelectionStyle>
        {
            list.length >= 6 && <>
                <div className="title-wrap">
                    <SectionTitle text={'本月精选'}></SectionTitle>
                    <div className="tip" onClick={toMore}>查看更多 <RiArrowRightSLine className="icon"></RiArrowRightSLine></div>
                </div>
                <div className="container_">
                    <div className="wrap">
                        {
                            list.length > 0 && <MonthlyCard list={list}></MonthlyCard>
                        }
                    </div>
                </div>
            </>
        }
    </MonthSelectionStyle>
}

export default MonthSelection