import styled from "styled-components";


export const UserCenterLayoutStyle = styled.div`
  background-color: ${(props) => props.theme.colors.colorBgLayout};
  
  .layout-padding {
    height: 50px;
    width: 100%;
    background-color: gray;
  }

  .banner {
    width: 100%;
    aspect-ratio: 1920/300;
    background-color: ${(props) => props.theme.colors.colorBlack6Ggac};
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .actions {
        display: block;
        display: flex;
        gap: 12px;

        .item {
          color: #fff;
          cursor: pointer;
          padding: ${(props) => props.theme.space.paddingSM}px
            ${(props) => props.theme.space.padding}px;
          background-color: rgba(7, 7, 7, 0.2);
          border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
          &:hover {
            color: ${(props) => props.theme.colors.colorPrimary};
          }
          .icon-btn {
            font-size: 18px;
          }
        }
      }
    }
    .actions {
      display: none;
    }
    .to-user-frontpage {
      position: absolute;
      bottom: 20px;
      z-index: 101;
      max-width: ${(props) => props.theme.space.maxContainer}px;
      width: 100%;
      padding: 0 ${(props) => props.theme.space.paddingLG}px;
      display: flex;
      justify-content: flex-end;

      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      color: ${(props) => props.theme.colors.colorBlack1Ggac};
      font-size: ${(props) => props.theme.fontSizes.fontSize}px;
    }
  }
  .main-container {
    /* transform: translateY(-20px); */
    display: flex;
    // width: 1280px;
    /* max-width: ${(props) => props.theme.space.maxContainer}px; */
    max-width: 3280px;
    width: 100%;
    padding: 0 ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.padding}px;
    /* margin: -20px auto 0; */
    position: relative;
    margin: 0 auto;

    .left-pannel{
      transform: translateY(-80px);
      /* max-width: 350px;
      min-width: 280px; */
      width: 270px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${(props) => props.theme.space.marginLG}px;
    }
    .right-pannel {
      position: relative;
      margin: ${(props) => props.theme.space.marginLG* 1.4}px;
      margin-right: ${(props) => props.theme.space.marginXS}px;
      flex: 1;
      min-height: 100vh;
      .zizhi{
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }
  }
  .comment-wrap {
    margin-top: 50px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    padding: 30px;
    background-color: #fff;
    .comment-top {
      margin-bottom: 30px;
    }
    .bottom {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      align-items: center;
    }
  }
`;