import React, { CSSProperties, memo, useEffect, useState } from 'react';
import { Progress, Upload, message } from 'antd';
import { SubUploadImageListStyle } from "./style"
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle
} from 'react-beautiful-dnd';
import { Image } from 'antd';
import { CosDirectoryType } from 'types/enums/media-type';
import { getFileName } from 'utils/functions/common';
import { getFileUrlByCosUpload } from 'config/cos';
import { ProgressInfo } from 'cos-js-sdk-v5';
import { RiAddLine, RiDeleteBinLine, RiEyeLine } from 'react-icons/ri';
import { isFileOversized, isImage } from 'utils/functions/file';

const grid = 8;
const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
  background: isDraggingOver ? 'lightblue' : '#f9f9f9',
  display: 'flex',
  flexWrap: 'wrap',
  padding: grid * 2,
  overflow: 'auto',
});
const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): CSSProperties => ({
  userSelect: 'none',
  padding: 0,
  margin: 4,
  height: 154,
  width: 154,
  background: isDragging ? 'lightgreen' : '#f9f9f9',
  ...draggableStyle,
});

type imageType = {
  url: string
  key: string
  percent: number,
}
type Props = {
  disabled?: boolean
  value?: imageType[]
  onChange?: (params: imageType[]) => void
  maxLength?: number,
  mark?: string,
  noDesc?: boolean,
  imgSrc?: string
  limitSize?: number
}

export const ProductPicturesWall: React.FC<Props> = memo(({ onChange: onFileChange, mark, ...props }) => {
  const [list, setList] = useState<imageType[]>([])
  const [visible, setVisible] = useState(false)

  const [current, setCurrent] = useState<imageType>()

  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setList(props.value! ?? [])
  }, [props.value])



  const onChange = async () => {
    return false
  };

  //上传进度回调
  const onProgress = (progressData: ProgressInfo, key: string) => {
    list.forEach(item => {
      if (item.key === key) {
        item.percent = progressData.percent * 100 > 10 ? +(progressData.percent * 100 - 10).toFixed(2) : +progressData.percent.toFixed(2)
      }
    })
    setList(list.concat([]))
    onFileChange!(list.concat([]));
  };



  const reorder = (list: imageType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }

    const newFileList = reorder(
      list,
      source.index,
      destination.index
    );
    setList(newFileList)
    onFileChange!(newFileList);
  };

  const onClickDelete = (value: imageType) => {
    let arr = list.filter(item => item.url !== value.url)
    setList(arr)
    onFileChange!(arr);
  }

  // const validateFile = async (imgFile: RcFile) => {
  //   if (!isImage(imgFile)) {
  //     message.warning("上传文件中包含不支持的图片格式,请检查后重新上传",3);
  //     return false;
  //   }
  //   if (isFileOversized(imgFile, 20)) {
  //     message.warning("上传文件大小不能超过20M,请检查后重新上传",3);
  //     return false;
  //   }
  //   if (await isImageResolutionTooLarge(imgFile, 7680, 7680)) {
  //     message.warning("包含超大分辨率图片,请检查后重新上传，最大支持7680x7680",3);
  //     return false;
  //   }

  //   return true;
  // };



  const beforeUpload = async (file: any) => {
    if (!['image/jpg', 'image/jpeg', 'image/png', 'image/gif','image/webp'].includes(file.type)) {
      message.warning("上传文件中包含不支持的图片格式,请检查后重新上传",3);
      return false;
    }
    if (props.limitSize && isFileOversized(file, props.limitSize)) {
      message.warning(`上传图片大小不能超过${props.limitSize}M,请检查后重新上传`, 3);
      return false;
    }
    setLoading(true)
    // let imgFile = fileList[fileList.length - 1].originFileObj;
    const uploadUUid = getFileName(props.imgSrc ? props.imgSrc : CosDirectoryType.productImage, file!.name);

    let newItem: imageType = {
      url: "",
      key: uploadUUid,
      percent: 0,
    };

    list.push(newItem)
    onFileChange!(list.concat([]));

    const resultImg = await getFileUrlByCosUpload(
      file as File,
      uploadUUid,
      onProgress
    );

    const index = list.findIndex(item => item.key === uploadUUid)

    newItem.url = resultImg.url;
    newItem.percent = 100;
    list[index] = newItem
    setList(list.concat([]))

    onFileChange!(list.concat([]));
    setLoading(false)
    return false
  }
  const uploadButton = (
    <div>
      <RiAddLine />
      <div>上传图片</div>
    </div>
  );

  return (
    <SubUploadImageListStyle>
      <div>
        <Image
          width={200}
          style={{ display: 'none' }}
          src={current?.url}
          preview={{
            visible,
            src: current?.url,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />

        <DragDropContext onDragEnd={onDragEnd}>

          <Droppable droppableId="droppable" direction='horizontal'>

            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
                className='drop-wrap'
              >
                {list?.map((item, index) => (
                  <Draggable
                    key={item.key}
                    draggableId={item.key + ''}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className='drop'
                      >
                        <div className='card-wrapper'>
                          {item.percent < 100 && <Progress type="circle" percent={item.percent} size={80} />}
                          {(item.percent >= 100 || item.url) && <div className='image' style={{ backgroundImage: `url(${item.url})` }}>

                            <div className='operation-container'>
                              <div className='operation-item' onClick={() => { setCurrent(item); setVisible(true) }}>
                                <RiEyeLine size={16}></RiEyeLine>
                              </div>
                              {
                                !props.disabled && <div className='operation-item' onClick={() => onClickDelete(item)}> <RiDeleteBinLine size={16}></RiDeleteBinLine></div>
                              }
                            </div>
                          </div>}
                        </div>

                      </div>
                    )}
                  </Draggable>
                ))}
                <Upload
                  {...props}
                  listType="picture-card"
                  disabled={props.disabled}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={onChange}
                  multiple={false}
                  accept=".png,.jpg,.gif"
                >
                  {!loading && list.length < (props.maxLength || 15) && uploadButton}
                </Upload>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </SubUploadImageListStyle>
  );
});

