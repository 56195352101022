// import { Image } from "antd";
import Image from '../../../../../lib/ant-image/index'
import { PhotoDisplayAntStyle } from "./style";
import { WorkDetailMedieListItem } from "types/work";
import { useState } from "react";
import AddFavorite from "components/favorite/add-favorite";
import { RiStarLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { hasToken } from "utils/functions/common";
import { loginPathWithRedirect } from "utils/functions/user";

type Props = {
  mediaItem: WorkDetailMedieListItem;
  onClickPreviewIcon: (mediaItem: WorkDetailMedieListItem) => void,
};
function PhotoDisplayAnt({ mediaItem, onClickPreviewIcon }: Props) {
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const closePopup = () => {
    setIsShow(false);
  };

  const favourImage = (e: any) => {
    e.stopPropagation();
    if (!hasToken()) {
      navigate(loginPathWithRedirect());
      return;
    }
    setIsShow(true);
  };

  const handleShowPreview = () => {
    onClickPreviewIcon(mediaItem)
  };
  return (
    <PhotoDisplayAntStyle>
      <div className="image-wrap">
        <div className="operation-wrapper">
          <div className="icon-wrap" onClick={(e) => favourImage(e)}>
            <RiStarLine className="icon" />
            图片收藏
          </div>
        </div>
        <Image src={mediaItem.resizedUrl ?? mediaItem.url} preview={
          { src: mediaItem.isLoadingOrigin ? mediaItem.url : mediaItem.resizedUrl ?? mediaItem.url }
        } width={"100%"} onClick={() => handleShowPreview()} />

      </div>

      {isShow && (
        <AddFavorite
          type="media"
          dataId={mediaItem.id}
          FavoriteTypeDisabled={true}
          onClose={() => closePopup()}
        ></AddFavorite>
      )}
    </PhotoDisplayAntStyle>
  );
}

export default PhotoDisplayAnt;
