export function renderTitle(type: number) {
  switch (type) {
    case 1:
      return "系统";
    case 2:
      return "点赞";
    case 3:
      return "评论";
    case 4:
      return "关注";
     case 5:
     return "留言";
     case 6:
      return "店铺";
      case 7:
        return "TALK";  
    default:
      break;
  }
}
