import { ReactNode } from "react";
import styled from "styled-components";
export const ProductDetailCardComponentStyle = styled.div`
 border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
 border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
    .header{
        height: 50px;
        border-bottom: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
        display: flex;
        align-items: center;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        padding:0  ${(props) => props.theme.space.paddingMD}px;
        justify-content: space-between;
    }
    .content{
        padding: ${(props) => props.theme.space.paddingMD * 2}px;;
    }
`
interface Props extends React.ComponentProps<"div"> { title: string; subTitle?: ReactNode, children: ReactNode }



function ProductDetailCardComponent({ title, subTitle = <></>, children, ...rest }: Props) {

    return (
        <ProductDetailCardComponentStyle>
            <div className='header'>
                <span>{title}</span> {subTitle}</div>
            <div className='content'>
                {children}
            </div>
        </ProductDetailCardComponentStyle>
    )
}

export default ProductDetailCardComponent