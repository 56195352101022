import { SecurityStyle } from "./style";
import { useEffect, useState } from "react";
import { apiGetSecurityInformation } from "api/user";
import { SecurityInformation } from "types/user/security";
import { Button } from "antd";

function Security() {
  const [information, setInformation] = useState<SecurityInformation>();

  useEffect(() => {
    getInformation();
  },[]);


  const getInformation =  () => {

    apiGetSecurityInformation().then((res)=>{
      setInformation(res)
    });

  };
  return (
    <SecurityStyle>
      <div className="content">
     
        <div className="header">{`您的账号安全性评分：${information?.accountSecurityGrade}`}</div>
        <div className="detail">
          <div className="c-line mb-20">
            <span className="l-name">手机号</span>
            <span className="l-content">{information?.mobile.replace(/(\d{3})\d*(\d{4})/, "$1****$2")}</span>
            <Button className="info-btn">修改</Button>
          </div>
          <div className="c-line mb-20">
            <span className="l-name">邮箱</span>
            <span className="l-content">{information?.email.length === 0 ? '未绑定邮箱':information?.email}</span>
            <Button className="info-btn">{information?.email.length === 0 ? '绑定':'修改'}</Button>
          </div>
          <div className="c-line mb-20">
            <span className="l-name">登陆密码</span>
            <span className="l-content">建议您经常修改密码，以保证账号更加安全</span>
            <Button className="info-btn">修改</Button>
          </div>
          <div className="c-line mb-20">
            <span className="l-name">QQ</span>
            <span className="l-content">{information?.qq === null? '未绑定':information?.qq}</span>
            <Button className="info-btn">{information?.qq === null ? '绑定':'修改'}</Button>
          </div>
          <div className="c-line mb-20">
            <span className="l-name">微信</span>
            <span className="l-content">{information?.weixin === null? '未绑定':information?.weixin}</span>
            <Button className="info-btn">{information?.weixin === null ? '绑定':'修改'}</Button>
          </div>
          <div className="c-line mb-20">
            <span className="l-name">微博</span>
            <span className="l-content">{information?.weibo === null? '未绑定':information?.weibo}</span>
            <Button className="info-btn">{information?.weibo === null ? '绑定':'修改'}</Button>
          </div>
        </div>
      </div>
    </SecurityStyle>
  );
}

export default Security;
