import styled from "styled-components";

export const ContactInfoStyle = styled.div`
  border: 1px solid ${(props) => props.theme.colors.colorBorder};
  border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
  padding: ${(props) => props.theme.space.paddingLG}px ;
  background-color: ${(props) => props.theme.colors.colorTextLightSolid};
  /* width: 100%; */
  width: 270px;
    .block{
        .title{
            text-align: center;
        }
        .content{
            padding-top: ${(props) => props.theme.space.paddingLG}px ;
            color: ${(props) => props.theme.colors.colorTextTertiary};
            .visitor-list{
                width: 100%;
               
                 display: grid;
               grid-template-columns: 1fr 1fr 1fr; 
               /* display: inline-flex; */
               gap:1px;
               /* flex-wrap: wrap;
               justify-content: flex-start; */
                .list-item{
                    /* flex: 1 1 20%;  */
                    /* width: 100%; */
                    aspect-ratio: 1/1;
                    text-align: center;
                    cursor: pointer;
                    gap:  ${(props) => props.theme.space.paddingXXS}px;
                    margin-bottom:  ${(props) => props.theme.space.paddingSM}px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .avatar{
                        width: 33px;
                        aspect-ratio: 1;
                        border-radius: 50%;
                    }
                    .user-name{
                        color: ${(props) => props.theme.colors.colorText};
                        width: 100%;
                        word-break: break-all;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-clamp: 1;
                        max-width: 69px;
                        font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
                    }
                    .time{
                        font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
                        color: ${(props) => props.theme.colors.colorTextTertiary};
                    }
                }
            }
        }
    }
    .divider{
        height: 1px;
        background-color: ${(props) => props.theme.colors.colorBorder};
        width: 100%;
        margin: ${(props) => props.theme.space.padding}px 0 ${(props) => props.theme.space.paddingLG * 1.5}px 0;
    }
`