import styled from "styled-components";

export const BadgesStyle = styled.div`
 padding: ${(props) => props.theme.space.paddingLG}px;
 padding-top: 0;
  .section-title{
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        display: flex;
        justify-content: space-between;
        .more{
            font-size: ${(props) => props.theme.fontSizes.fontSize}px;
            color:  ${(props) => props.theme.colors.colorTextSecondary};
            &:hover{
                color:  ${(props) => props.theme.colors.colorPrimaryText};
                cursor: pointer;
            }
        }
    }
  .badge-list{
    padding: ${(props) => props.theme.space.paddingLG}px 0;
  }  
  
  .title {
    margin: ${(props) => props.theme.space.margin}px 0;
  }
`