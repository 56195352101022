import styled from 'styled-components'

let a = "1fr ";
type GridWallStyleProps = {
  columnsCount?: number;
  rowsCount?: number;
};


export const GridWallStyle = styled.div<GridWallStyleProps>`
  display: grid;
  grid-template-columns: ${(props) => a.repeat(props.columnsCount!)};
  gap: 0px 0px;
  width: 100%;

  a {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    z-index: 10;
  }

  .gridBlock {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    transition: 0.5s;
    transition: background-size 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      background-size: cover;
      transform-origin: center;
      transition: transform 1s ease-in-out;
    }
    &:hover {
      &::after {
        transform: scale(1.3);
      }
    }
  }
`;


export const PhotoWallStyle = styled.div`
   .container_ {
    background-color: ${(props) => props.theme.colors.colorBgLayout};
    padding: 0  ${(props) => props.theme.space.padding}px;
    max-width: ${(props) => props.theme.space.maxContainer}px;
    padding-bottom:0;
    margin: 0 auto;
    .wrap {
      display: flex;
      align-items: center;
      background-color: black;
      border-radius:0 0 ${(props) => props.theme.border.borderRadius}px ${(props) => props.theme.border.borderRadius}px;
      overflow: hidden;
    }
  }
`