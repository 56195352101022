import { ShopYuegaoAgreementStyle } from "./style"

const ShopYuegaoAgreement = () => {
    return <ShopYuegaoAgreementStyle>
        <div className="title">一、用户的权利和义务</div>
        <div className="content">
            <div>1.账号使用</div>
            <div>用户需通过合法途径注册账号，并对账号的安全性负责，不得将账号转让、出租或出借给他人。</div>
            <div>遵守平台规则，不得使用虚假信息注册，按规定进行实名认证（如平台有要求）。</div>
            <div>2.约稿行为</div>
            <div>尊重创作者的知识产权，不得在未获得授权的情况下擅自使用、修改或传播创作者的作品。</div>
            <div>按照约定的流程和要求进行约稿，包括提交需求、支付稿酬、提供反馈等。</div>
            <div>对自己在平台上的言行负责，不得发布违法、违规、有害、侵犯他人权益或违背公序良俗的内容。</div>
            <div>3.交易规范</div>
            <div>诚信交易，不得进行欺诈、虚假交易等行为。</div>
            <div>遵守平台的定价规则和交易手续费规定。</div>
            <div>按照协议约定的方式和时间支付稿酬，若有逾期需承担相应责任。</div>
        </div>
        <div className="title">二、创作者的权利和义务</div>
        <div className="content">
            <div>1.资质与认证</div>
            <div>创作者需向平台提交真实、准确的个人信息和资质证明，以通过平台的审核认证，获得接稿资格</div>
            <div>保证所创作的作品为原创，不得抄袭、剽窃他人作品或侵犯他人知识产权。</div>
            <div>保证自己具备相应的绘画技能和创作能力，能够按照企划方的需求和约定的标准完成稿件。</div>
            <div>2.稿件创作与交付</div>
            <div>按照企划方的要求和约定时间进行创作，确保稿件质量符合约定标准。</div>
            <div>尊重企划方的意见和建议，在合理范围内积极配合修改稿件，但对于不合理或超出约定范围的要求，有权拒绝修改。</div>
            <div>按时交付稿件，若因特殊原因无法按时交付，应提前与企划方沟通并协商解决方案</div>
            <div>3.权利与义务</div>
            <div>创作者与企划方进行交易并获得稿酬后，默认企划方拥有该稿件著作权中的全部财产权（包括但不限于复制权、发行权、改编权、展示权、摄制权、表演权、广播权、信息网络传播权以及其他使用权和获得报酬权等），但双方另有约定的情况除外 。</div>
            <div>创作者有权在平台规定范围内展示自己的作品和创作成果，以提升个人知名度和获取更多约稿机会，但双方另有约定的情况除外。</div>
            <div>不得利用平台进行欺诈、虚假宣传等不正当行为，维护平台的良好秩序和声誉。</div>
            <div>对在创作过程中知悉的企划方商业秘密、需求信息等负有保密义务，未经授权不得向第三方披露。</div>
        </div>
        <div className="title">三、企划方的权利和义务</div>
        <div className="content">
            <div>1.使用规则</div>
            <div>有权在平台上选择心仪的创作者，并根据双方协商确定稿费、交稿时间等具体合作事宜。</div>
            <div>需尊重创作者的创作成果和知识产权，不得擅自修改、歪曲或未经授权使用创作者的作品。</div>
            <div>2.交易与交付</div>
            <div>按照与创作者约定的价格和付款方式及时支付稿酬。在平台规定的交易流程下，可能涉及预付款、阶段性付款等形式，以保障双方权益。</div>
            <div>若企划方对稿件不满意，应按照平台规则和与创作者的约定，在合理范围内提出修改意见，不得恶意刁难或故意拖延付款。</div>
            <div>企划方进行交易并获得稿酬后，即默认企划方拥有该稿件著作权中的全部财产权（包括但不限于复制权、发行权、改编权、展示权、摄制权、表演权、广播权、信息网络传播权以及其他使用权和获得报酬权等），但创作者与企划方另有约定的情况除外。</div>
            <div>3.权利与义务</div>
            <div>企划方有义务遵守平台的各项规章制度，包括但不限于交易规则、知识产权保护规定等。</div>
            <div>不得利用平台从事任何违法、违规或损害平台及其他用户利益的活动。</div>
            <div>对在约稿过程中知悉的创作者个人信息、创作内容等负有保密义务，未经授权不得向第三方披露。</div>
        </div>
        <div className="title">四、平台的权利和义务</div>
        <div className="content">
            <div>1.服务提供</div>
            <div>为用户提供约稿平台及相关服务，包括但不限于信息展示、交易撮合、沟通工具等。</div>
            <div>维护平台的正常运行，确保系统的稳定性和安全性，及时处理技术故障和问题。</div>
            <div>2.管理与监督</div>
            <div>制定并执行平台的规则和政策，对用户的行为进行管理和监督，对违规行为进行处罚，包括但不限于警告、限制功能使用、封禁账号等。</div>
            <div>处理用户之间的纠纷和投诉，提供相应的协调和解决方案。</div>
            <div>对平台上的内容进行审核，如发现违法、违规或不良信息，有权采取删除、屏蔽等措施。</div>
            <div>3.保障安全</div>
            <div>采取合理的技术和管理措施，保障平台的安全稳定，保护用户的信息和交易安全，防止数据泄露、黑客攻击等安全问题。</div>
        </div>
        <div className="title">五、平台手续费</div>
        <div className="content">
            <div>手续费收取比例：平台固有服务的手续费会在双方成交之后收取。</div>
            <div>手续费承担方：一般情况下，手续费可能由创作者承担、也可能由企划方承担，具体承担方式可根据交易双方的协商确定 。</div>
               <div>收费规则适用范围：适用于平台上各种约稿交易，无论是企业与创作者之间的合作，还是个人客户与创作者的交易等</div>
        </div>
        <div className="title">六、交易流程与规范</div>
        <div className="content">
            <div>1.约稿流程</div>
            <div>创作者发布约稿、双方沟通、确定合作及需求、支付费用、制作内容，确认提交，确认收货等各个环节的操作步骤和要求。</div>
            <div>2.稿酬支付</div>
            <div>确定需求和费用，在平台上下单，并由约稿发布者改价，由企划方支付</div>
            <div>支付后平台监管资金，交稿后由企划方确认收货。</div>
            <div>平台结算后转入创作者账户。</div>
            <div>3.稿件验收</div>
            <div>稿件验收可通过站内私信方式</div>
            <div>规定如果企划方对稿件不满意，双方应如何协商解决，以及是否可以申请平台介入处理。</div>
        </div>
        <div className="title">七、违约责任</div>
        <div className="content">
            <div>1.用户违约</div>
            <div>若用户违反协议约定，如未按时支付稿酬、侵犯创作者知识产权、发布违法违规内容等，应承担相应的违约责任，包括但不限于赔偿损失、支付违约金、承担法律责任等。</div>
            <div>2.创作者违约</div>
            <div>若创作者违反协议约定，如未按时交付作品、作品质量不符合要求、侵犯他人知识产权等，应承担相应的违约责任，如退还稿酬、支付违约金、赔偿用户损失等。</div>
            <div>3.平台免责</div>
            <div>规定在某些情况下，平台可以免除责任，如不可抗力因素导致的服务中断、用户自身原因导致的损失等，但平台应尽到合理的通知和协助义务。</div>
        </div>
        <div className="title">八、争议解决</div>
        <div className="content">
            <div>1.协商解决</div>
            <div>鼓励用户和创作者在发生争议时首先通过友好协商的方式解决问题，自行达成和解协议。</div>
            <div>2.平台调解</div>
            <div>如果协商不成，用户可以向平台申请调解，平台将根据相关规则和事实进行协调处理，但平台的调解结果不具有强制执行力。</div>
            <div>若争议无法通过协商或平台调解解决，双方同意将争议提交至有管辖权的人民法院通过诉讼解决，适用的法律通常为中华人民共和国法律。</div>
        </div>
        <div className="title">九、其他条款</div>
        <div className="content">
            <div>1.协议变更</div>
            <div>平台有权根据业务发展和法律法规的变化对用户协议进行修改和更新，并在平台上公布。用户应定期关注协议的变更内容，若继续使用平台服务则视为接受变更后的协议。</div>
            <div>2.通知与送达</div>
            <div>规定平台向用户发送通知的方式，如在平台上发布公告、发送电子邮件、短信通知等，用户应确保提供的联系方式准确有效。</div>
            <div>3.协议的生效与终止</div>
            <div>明确协议的生效条件和期限，以及协议终止的情形，如用户主动注销账号、违反协议被平台封禁账号、平台停止运营等</div>
            <div>4.法律适用与管辖</div>
            <div>如前所述，明确协议的法律适用和争议解决的管辖法院，以确保在发生法律纠纷时能够确定适用的法律和解决争议的地点。</div>
        </div>
        <div>以上仅为一般情况下GGAC G市约稿用户协议可能包含的内容，具体条款可能因平台政策的调整和变化而有所不同。在使用平台功能之前，用户应仔细阅读并理解平台的用户协议，如有疑问或不明确之处，可以咨询平台客服或专业法律人士。如果你需要获取最准确和详细的创作者用户协议内容，建议直接访问GGAC官方网站或相关应用程序查看其最新版本的用户协议文本。</div>
    </ShopYuegaoAgreementStyle>
}

export default ShopYuegaoAgreement