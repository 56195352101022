import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import WorkEdit from "views/work/update";
import WorkDetail from "views/work/detail";
import WorkPreDetail from 'views/work/preview/index'
import PromoteToSell from "views/work/update/promoteToSell";

export const workRoutes: RouteObject[] = [
    {
        path: '/work',
        element: <Layout></Layout>,
        children: [
            {
                path: 'detail/:id',
                element: <WorkDetail></WorkDetail>
            },
            {
                path: "update/:id",
                element: <WorkEdit></WorkEdit>,
            },
            {
                path: "create",
                element: <WorkEdit></WorkEdit>,
            },
            {
                path: 'preview/:id',
                element: <WorkPreDetail></WorkPreDetail>
            }
            ,
            {
                path: 'promote-to-sell/:id',
                element: <PromoteToSell></PromoteToSell>
            }
        ]
    }
]