import { ArticleListItem } from "types/article";
import { PublicCardStatusStyle } from "./style";
import { GlobalSearchPostResponse } from "types/common";
import { FavoriteDataListItem } from "types/favorite";
import { WorkLikeListsItem } from "types/user/work";
import { WorkListItem } from 'types/work'

function PublicCardStatus({ item }: {item: GlobalSearchPostResponse | FavoriteDataListItem | WorkLikeListsItem |WorkListItem|ArticleListItem }) {
  return (
    <PublicCardStatusStyle>
       {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
    
    </PublicCardStatusStyle>
  );
}

export default PublicCardStatus;
