import styled from 'styled-components'

export const UploadStyle = styled.div`
.drop-wrap{
    margin-bottom: ${props=> props.theme.space.marginXS};
    align-items: center;
    .ant-upload-wrapper{
        width: 104px;
        .ant-upload{
            margin-bottom: 0 !important;
        }
    }
}
.ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.drop{
    .ant-upload-list-item {
        position: relative;
        border: 1px solid #d9d9d9;
        padding: ${props=> props.theme.space.paddingXS};
        width: 104px;
        height: 104px;
        border-radius: 8px;
        box-sizing: border-box;
    }
    img{
        width: 100%;
        height: 100%;
    }
    
    .ant-upload-wrapper{
        width: 104px;
    }
    .ant-upload-list-item:hover{
        
        .ant-upload-list-item-actions{
            display: block;
        }
    }
    .ant-upload-list-item-name{
        display: none;
    }
    .ant-upload-list-item-actions{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.4);
        display: none;
        text-align: center;
        line-height: 104px;
    }
    a{
        color: ${props=> props.theme.colors.colorTextLightSolid};
    }
    button{
        color: ${props=> props.theme.colors.colorTextLightSolid};
        &:hover{
            color: ${props=> props.theme.colors.colorTextLightSolid};
        }
    }

}
.remark{
        color: ${props=> props.theme.colors.colorTextSecondary};
        font-size:  ${props=> props.theme.fontSizes.fontSizeSM}px;
    }
`