import TencentCloudChat, { Message } from "@tencentcloud/chat";
import { MyMessageItemStyle } from "./style";
import { Avatar, Image } from "antd";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { getDateString } from "utils/functions/common";
import { RiFile2Line, RiFolder3Line } from "react-icons/ri";

export const MyMessageItem = (props: { msg: Message }) => {
  const msg = props.msg;
  let userId = JSON.parse(localStorage.getItem('user_info')!).id

  const typeText = ["场景", "人物", "2D", "3D"];

  const toOrderDetail = ({ orderNumber, sellerUserId }: any) => {
    if (Number(userId) === Number(sellerUserId)) {
      window.open(`/user-center/shop/seller-order/detail/${orderNumber}?from=all`)
      return
    }
    window.open(`/user-center/shop/order/detail/${orderNumber}?from=all`)
  }

  return (
    <MyMessageItemStyle>
      <div className="my-msg-wrapper">
        <div className="msg">
          <div className="msg-left" style={{ marginTop: "-10px" }}>
            <div className="time">{getDateString(msg.time)}</div>
            {msg.type === TencentCloudChat.TYPES.MSG_TEXT && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: msg.payload.text }}
              ></div>
            )}
            {msg.type === TencentCloudChat.TYPES.MSG_IMAGE && (
              <div>
                {/* <PhotoProvider>
                  <PhotoView
                    src={
                      msg.payload.imageInfoArray &&
                      msg.payload.imageInfoArray.length > 0 &&
                      msg.payload.imageInfoArray[0].imageUrl
                    }
                  >
                    <Image
                      preview={false}
                      width={150}
                      src={
                        msg.payload.imageInfoArray &&
                        msg.payload.imageInfoArray.length > 0 &&
                        msg.payload.imageInfoArray[0].imageUrl
                      }
                    />
                  </PhotoView>
                </PhotoProvider> */}

                <Image
                  preview={true}
                  width={150}
                  src={
                    msg.payload.imageInfoArray &&
                    msg.payload.imageInfoArray.length > 0 &&
                    msg.payload.imageInfoArray[0].imageUrl
                  }
                />
              </div>
            )}
            {msg.type === TencentCloudChat.TYPES.MSG_VIDEO && (
              <div>
                <video
                  width={150}
                  controls

                  src={
                    msg.payload &&
                    msg.payload.videoUrl
                  }
                />
              </div>
            )}

            {msg.type === TencentCloudChat.TYPES.MSG_AUDIO && (
              <div>
                <audio
                  controls
                  src={
                    msg.payload &&
                    msg.payload.remoteAudioUrl
                  }
                />
              </div>
            )}

            {msg.type === TencentCloudChat.TYPES.MSG_FILE && (
              <div className="content file" onClick={() => window.open(msg.payload && msg.payload.fileUrl)}>
                <div className="detail">
                  <div className="name">
                    {msg.payload && msg.payload.fileName}
                  </div>
                  <div className="size">大小：{(msg.payload.fileSize / 1024 / 1024).toFixed(2)}M</div>
                </div>
                <RiFolder3Line size={20} />
              </div>
            )}

            {(msg.type === TencentCloudChat.TYPES.MSG_CUSTOM && msg.payload.data === 'orderCreateSendMessage') &&
              <div className="content custome-msg orderCreateSendMessage">
                <div className="title">我已拍下，未付款</div>
                <div className="order-detail">
                  <div className="cover" style={{ backgroundImage: `url(${JSON.parse(msg.payload.extension).coverUrl})` }}></div>
                  <div className="right">
                    <div className="name">{JSON.parse(msg.payload.extension).name}</div>
                    <div className="price-wrap">
                      <span>￥{(JSON.parse(msg.payload.extension).amount / 100)}</span>
                    </div>
                  </div>
                </div>
                <div className="tip">
                  <div className="tip-word">请双方沟通及时确认价格</div>
                  <div className="link" onClick={() => toOrderDetail(JSON.parse(msg.payload.extension))}>订单详情 {`>`}</div>
                </div>
              </div>}

            {(msg.type === TencentCloudChat.TYPES.MSG_CUSTOM && msg.payload.data === 'orderPayedSendMessage') &&
              <div className="content custome-msg orderCreateSendMessage">
                <div className="title">我已经付款，期待您的交付</div>
                <div className="order-detail">
                  <div className="cover" style={{ backgroundImage: `url(${JSON.parse(msg.payload.extension).coverUrl})` }}></div>
                  <div className="right">
                    <div className="name">{JSON.parse(msg.payload.extension).name}</div>
                    <div className="price-wrap">
                      <div>
                        ￥{((JSON.parse(msg.payload.extension)?.amount + (JSON.parse(msg.payload.extension)?.serviceCharge || 0)) / 100)}
                      </div>
                      <div>{
                        (JSON.parse(msg.payload.extension)?.serviceCharge || 0) > 0 && <span style={{ fontSize: "12px" }}>（包含服务费：￥{(JSON.parse(msg.payload.extension)?.serviceCharge || 0) / 100}）</span>
                      }</div>
                    </div>
                  </div>
                </div>
                <div className="tip">
                  <div className="tip-word"></div>
                  <div className="link" onClick={() => toOrderDetail(JSON.parse(msg.payload.extension))}>订单详情 {`>`}</div>
                </div>
              </div>}

            {(msg.type === TencentCloudChat.TYPES.MSG_CUSTOM && msg.payload.data === 'orderReceiptOfGoodsSendMessage') &&
              <div className="content custome-msg orderCreateSendMessage">
                <div className="title">我已确定收货，合作愉快</div>
                <div className="order-detail">
                  <div className="cover" style={{ backgroundImage: `url(${JSON.parse(msg.payload.extension).coverUrl})` }}></div>
                  <div className="right">
                    <div className="name">{JSON.parse(msg.payload.extension).name}</div>
                    <div className="price-wrap">
                      <div>
                        ￥{((JSON.parse(msg.payload.extension)?.amount + (JSON.parse(msg.payload.extension)?.serviceCharge || 0)) / 100)}
                      </div>
                      <div>{
                        (JSON.parse(msg.payload.extension)?.serviceCharge || 0) > 0 && <span style={{ fontSize: "12px" }}>（包含服务费：￥{(JSON.parse(msg.payload.extension)?.serviceCharge || 0) / 100}）</span>
                      }</div>
                    </div>
                  </div>
                </div>
                <div className="tip">
                  <div className="tip-word"></div>
                  <div className="link" onClick={() => toOrderDetail(JSON.parse(msg.payload.extension))}>订单详情 {`>`}</div>
                </div>
              </div>}

            {(msg.type === TencentCloudChat.TYPES.MSG_CUSTOM && msg.payload.data === 'orderUpdatePriceSendMessage') &&
              <div className="content custome-msg orderCreateSendMessage">
                <div className="title">我已经修改价格，等你付款</div>
                <div className="order-detail">
                  <div className="cover" style={{ backgroundImage: `url(${JSON.parse(msg.payload.extension).coverUrl})` }}></div>
                  <div className="right">
                    <div className="name">{JSON.parse(msg.payload.extension).name}</div>
                    <div className="price-wrap">
                      <div>
                        ￥{((JSON.parse(msg.payload.extension)?.amount + (JSON.parse(msg.payload.extension)?.serviceCharge || 0)) / 100)}
                      </div>
                      <div>{
                        (JSON.parse(msg.payload.extension)?.serviceCharge || 0) > 0 && <span>（包含服务费：￥{(JSON.parse(msg.payload.extension)?.serviceCharge || 0) / 100}）</span>
                      }</div>
                    </div>
                  </div>
                </div>
                <div className="tip">
                  <div className="tip-word"></div>
                  <div className="link" onClick={() => toOrderDetail(JSON.parse(msg.payload.extension))}>订单详情 {`>`}</div>
                </div>
              </div>}

            {(msg.type === TencentCloudChat.TYPES.MSG_CUSTOM && msg.payload.data === 'orderConfirmSubmissionSendMessage') &&
              <div className="content custome-msg orderCreateSendMessage">
                <div className="title">我已交稿，请您确认</div>
                <div className="order-detail">
                  <div className="cover" style={{ backgroundImage: `url(${JSON.parse(msg.payload.extension).coverUrl})` }}></div>
                  <div className="right">
                    <div className="name">{JSON.parse(msg.payload.extension).name}</div>
                    <div className="price-wrap">
                      <div>
                        ￥{((JSON.parse(msg.payload.extension)?.amount + (JSON.parse(msg.payload.extension)?.serviceCharge || 0)) / 100)}
                      </div>
                      <div>{
                        (JSON.parse(msg.payload.extension)?.serviceCharge || 0) > 0 && <span style={{ fontSize: "12px" }}>（包含服务费：￥{(JSON.parse(msg.payload.extension)?.serviceCharge || 0) / 100}）</span>
                      }</div>
                    </div>
                  </div>
                </div>
                <div className="tip">
                  <div className="tip-word" style={{ fontSize: "12px" }}>买方10天内未确认将自动确认收货</div>
                  <div className="link" onClick={() => toOrderDetail(JSON.parse(msg.payload.extension))}>订单详情 {`>`}</div>
                </div>
              </div>}

            {(msg.type === TencentCloudChat.TYPES.MSG_CUSTOM && !["orderPayedSendMessage", "orderCreateSendMessage", "orderReceiptOfGoodsSendMessage", "orderUpdatePriceSendMessage", "orderConfirmSubmissionSendMessage"].includes(msg.payload.data)) && (
              <div className="content custome-msg">
                <div className="type">
                  创作领域： {typeText[+msg.payload.data - 1]}
                </div>
                <div className="desc">问题描述： {msg.payload.description}</div>
                <PhotoProvider>
                  <div className="photo-wrapper">
                    {msg.payload.extension &&
                      JSON.parse(msg.payload.extension).map((item: string) => (
                        <PhotoView src={item}>
                          <Image
                            preview={false}
                            width={150}
                            height={150}
                            src={item}
                            style={{ padding: "10px" }}
                          />
                        </PhotoView>
                      ))}
                  </div>
                </PhotoProvider>
              </div>
            )}
          </div>

          <div className="msg-right">
            <Avatar size={40} shape="square" src={msg.avatar}></Avatar>
          </div>
        </div>

        <div style={{ clear: "both" }}></div>
      </div>
    </MyMessageItemStyle>
  );
};
