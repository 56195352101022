import styled from "styled-components";

export const RedeemHistoryStyle = styled.div`
    .redeem-model {
        .title {
            font-weight: bold;
            text-align: center;
            margin-bottom: ${props => props.theme.space.margin}px;
        }
        .wrap {
            min-height: 220px;
            max-height: 570px;
            overflow: auto;
        }
        .list {
            &-item {
                display: flex;
                margin-bottom: ${props => props.theme.space.margin}px;
                border-bottom: 1px solid ${props => props.theme.colors.colorBorder};
                padding-bottom: ${props => props.theme.space.padding}px;
                width: 100%;
                &:last-child {
                    border: none;
                    padding-bottom: 0;
                }
                align-items: center;
                &:last-child {
                    margin-bottom: 0;
                }
                .info {
                    width: calc(100% - 100px);
                    &-item {
                        margin-bottom: ${props => props.theme.space.marginXXS}px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        span {
                            margin-right: ${props => props.theme.space.margin}px;
                        }
                    }
                }
                .cover {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-size: cover;
                    margin-right: ${props => props.theme.space.margin}px;
                }
                .name {
                    display: flex;
                    justify-content: space-between;
                }
                .address {
                    color: ${props => props.theme.colors.colorTextSecondary};
                    width: 100%;
                }
            }
        }
    }
`