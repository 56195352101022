
import { Pagination, Select, Spin } from 'antd';
import RedeemCard from "../redeem-card";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiGetRedeemList } from "api/redeem";
import { redeemItem } from "types/redeem"
import { NoData } from "components/no-data";
import { RedeemListStyle } from './style';

const options = [
    {
        value: 'rank',
        label: "默认排序",
    },
    {
        value: 'price_desc',
        label: "G分从高到低",
    },
    {
        value: 'price_asc',
        label: "G分从底到高",
    }
];


const RedeemList = ({ updateWalletDetail }: { updateWalletDetail?: () => void }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [list, setList] = useState<redeemItem[]>([])
    const [total, setTotal] = useState(0)
    const [sortField, setSortField] = useState('rank')
    const [loading, setLoading] = useState(false)
    const params = useMemo(() => {
        return {
            pageNumber,
            pageSize: 21,
            sortField: sortField
        }
    }, [pageNumber, sortField])

    const changePage = (e: number) => {
        setPageNumber(e)
    }

    const getList = useCallback(() => {
        setLoading(true)
        apiGetRedeemList(params).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const changeValue = (e: string) => {
        setSortField(e)
    }

    return <RedeemListStyle>
        <Select defaultValue='rank' onChange={changeValue} options={options} style={{ width: 130 }} />
        {
            list.length > 0 && <Spin spinning={loading}>
                <RedeemCard updateWalletDetail={updateWalletDetail} list={list} />
            </Spin>
        }
        {
            list.length === 0 && <NoData />
        }
        <Pagination
            style={{ textAlign: "center", margin: "32px 0" }}
            hideOnSinglePage={true}
            current={pageNumber}
            onChange={changePage}
            pageSize={params.pageSize}
            total={total}
        />
    </RedeemListStyle>
}

export default RedeemList