import { ConfigProvider, Modal } from "antd";
import { Locale } from "antd/es/locale";
import { createContext, useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { routes } from "router";
import { GlobalStyle } from "styles";
import { ThemeProvider } from "styled-components";
import { UseDarkColors } from "styles/var-color-dark";
import { UseLightColors } from "styles/var-color-light";
import { LanguageType } from "types/enums/config-language-type";
import { ThemeType } from "types/enums/config-theme-type";
import { Colors } from "types/styles/color";
import { i18nChangelanguage } from "utils/i18n";
import { store } from "utils/store";
import { changeStoreTheme } from "utils/store/reducer/config";
import zhCN from 'antd/locale/zh_CN';
import enGB from 'antd/locale/en_GB';
import ja_JP from 'antd/locale/ja_JP';
import ko_KR from 'antd/locale/ko_KR'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import "react-chat-elements/dist/main.css"

import { UseFontSize } from "styles/var-font-size";
import { FontSizes } from "types/styles/fontSize";
import { Space } from "types/styles/space";
import { UseSpace } from "styles/var-space";
import { Border } from "types/styles/border";
import { UseBorder } from "styles/var-border";
import { UseShadow } from "styles/var-shadow";
import { Shadow } from "types/styles/shadow";
import { App as AntAppContext } from 'antd';
import { apiGetWebsiteInfo } from "api/common";
import transLanguage from "config/utils/trans";

//const { useToken } = theme;

type ConfigFuncObj = {
  changeTheme: Function
  changeLanguage: (lang: LanguageType) => void
}

export const ConfigContext = createContext<ConfigFuncObj>(
  {
    changeTheme: () => { },
    changeLanguage: (lang: LanguageType) => { }
  }
)


export const App = () => {
  let element = useRoutes(routes);
  const location = useLocation()
  //const { token } = useToken();
  let localStorageLang = localStorage.getItem('lang')
  let [language, setLanguage] = useState<Locale>(zhCN)

  let darkColors = UseDarkColors()
  let LightColors = UseLightColors()
  let fontSizes: FontSizes = UseFontSize()
  let space: Space = UseSpace()
  let border: Border = UseBorder()
  let shadow: Shadow = UseShadow()

  let [mode, setMode] = useState<Colors>(LightColors)


  const changeTheme = (theme: ThemeType) => {
    setMode(theme === ThemeType.dark ? darkColors : LightColors)
    store.dispatch(changeStoreTheme(theme))
  }


  const setAntdLanguage = (lang: string) => {
    if (lang === LanguageType.en_GB) {
      setLanguage(enGB)
    } else if (lang === LanguageType.zh_CN) {
      setLanguage(zhCN)
    } else if (lang === LanguageType.ja_JP) {
      setLanguage(ja_JP)
    } else if (lang === LanguageType.ko_KR) {
      setLanguage(ko_KR)
    }
  }


  useEffect(() => {
    setAntdLanguage(localStorageLang!)
 
  }, [])

  useEffect(() => {
    Modal.destroyAll()
    document.documentElement.style.filter = ''
    apiGetWebsiteInfo().then(res => {
        localStorage.setItem('websiteInfo',JSON.stringify(res))
        if(res.sadThemeFlag) {
          if (["/home", "/hot", "/recommend", "/follow"].includes(location.pathname) ||location.pathname.includes('billboard')) {
            document.documentElement.style.filter = 'grayscale(100%)'
          } 
        }
       })
       transLanguage()
      
  }, [window.location.pathname])

  const changeLanguage = (lang: LanguageType) => {
    setAntdLanguage(lang)
    i18nChangelanguage(lang)
  }




  return <ConfigProvider locale={language}>
    <ConfigContext.Provider value={{ changeTheme, changeLanguage }} >
      <ThemeProvider theme={{ colors: mode, fontSizes, space, border, shadow }}>
        <GlobalStyle />
        <AntAppContext>
         
          {element}
      
        </AntAppContext>
      </ThemeProvider>
    </ConfigContext.Provider>
  </ConfigProvider>

}