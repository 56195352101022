import { getAuthorItem } from "types/user";
import { UserCardStyle } from "./style";
import { Button, Tooltip, message } from "antd";
import { useState } from "react";
import { postFollowUser } from "api/user";
import { findUserRole, prettifyNumber } from "utils/functions/common";
import {  RiEyeLine, RiHeart3Line,  RiUser3Line } from "react-icons/ri";
import { ImageObjectCloudResize } from "utils/functions/file";
import UiAvatar from "ui-lib/avatar";

function UserCard({ list }: { list: getAuthorItem[] }) {
  const [isLoading, setLoading] = useState(false);
  const FollowUser = (detail: getAuthorItem) => {
    setLoading(true);
    const flag = detail.isFollow === 1 ? 0 : 1;
    postFollowUser({
      followee: detail.userInfo.id,
      flag,
    })
      .then(() => {
        const index = list.findIndex((item) => {
          return item.id === detail.id;
        });
        detail.isFollow = flag;
        list[index] = detail;
        message.success("操作成功");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
        return "yellow";

      case 3:
        return "blue";

      default:
        return "hide";
    }
  };
  return (
    <UserCardStyle className="card-list">
      {list.map((item, index) => {
        return (
          <div className="card" key={index}>
            <div className="thumbnails">
              {
                item.workList?.[0]?.id && <a href={`/work/detail/${item.workList?.[0]?.id}`} target="_blank">
                  <div
                    className="img-item"
                    key={index}
                    style={{
                      backgroundImage: `url(${ImageObjectCloudResize(item.workList?.[0]?.coverUrl, 500)})`,
                    }}
                  />
                </a>
              }
              {
                !item.workList?.[0]?.id && <div
                  className="img-item"
                  key={index}
                  style={{
                    backgroundImage: `url(${ImageObjectCloudResize(item.workList?.[0]?.coverUrl, 500)})`,
                  }}
                />
              }
              {
                item.workList?.[1]?.id && <a href={`/work/detail/${item.workList?.[1]?.id}`} target="_blank">
                  <div
                    className="img-item"
                    style={{
                      backgroundImage: `url(${ImageObjectCloudResize(item.workList?.[1]?.coverUrl, 500)})`,
                    }}
                  />
                </a>
              }

              {
                !item.workList?.[1]?.id && <div
                  className="img-item"
                  style={{
                    backgroundImage: `url(${ImageObjectCloudResize(item.workList?.[1]?.coverUrl, 500)})`,
                  }} />
              }

              <div className="avatar">
                <a href={`/user/${item.userId}/works`} target="_blank">
                <UiAvatar border={false} size={68} url={item?.userInfo?.avatarUrl} certifiedRoleId={item?.userInfo?.certifiedRoleId} />
                </a>
              </div>
            </div>
            <a href={`/user/${item.userId}/works`} target="_blank">
              <div
                className="user-name"
              >
                {item?.userInfo?.username}
              </div>
            </a>
            {/* <div className="sub-title">{item.userInfo.profession}</div> */}
            <div className="info-grid">
              <div className="item">
                <div className="num">{prettifyNumber(Number(item.likeCount))}</div>
                <div className="name">
                  <RiHeart3Line size={16} />
                </div>
              </div>
              <div className="item">
                <div className="num">{prettifyNumber(Number(item.fansCount))}</div>
                <div className="name"><RiUser3Line size={16} /></div>
              </div>
              <div className="item">
                <div className="num">{prettifyNumber(Number(item.viewCount))}</div>
                <div className="name"><RiEyeLine size={16} /></div>
              </div>
            </div>
            <div className="btn">
              <Button
                type="primary"
                ghost={item.isFollow === 1}
                onClick={() => FollowUser(item)}
                className="action"
              >
                {item.isFollow === 1 ? "取消关注" : "关注"}
              </Button>
            </div>
          </div>
        );
      })}
    </UserCardStyle>
  );
}

export default UserCard;
