import styled from "styled-components";

export const UserCenterHomeStyle = styled.div`
  .sub-container{
  
  }
`
export const UserCenterHomeStyle1 = styled.div`
  .action {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding: ${(props) => props.theme.space.paddingLG}px  ${(props) => props.theme.space.paddingLG}px 0  ${(props) => props.theme.space.paddingLG}px;
    .btn {
      font-size: ${(props) => props.theme.fontSizes.fontSizeLG * 1.2}px;
    }
  }
  .wrapper{
    padding: ${(props) => props.theme.space.paddingLG}px;
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${(props) => props.theme.space.padding}px;
    .section-tip {
      color: ${props=>props.theme.colors.colorPrimary};
      cursor: pointer;
    }
  }
  .section-title{
        
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
  }
  .box{
    width: 100%;
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    .part{
      width: 50%;
      &.padding-lg{
        padding: ${(props) => props.theme.space.paddingLG}px;
      }
      .calendar-wrap{
        padding-top: ${(props) => props.theme.space.paddingLG}px;
      }
    }
  }
`;
