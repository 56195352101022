import styled from "styled-components";

export const OrderStateStyle = styled.div`
    .state {
        /* display: flex; */
        align-items: center;
        padding: 30px;
        .tip {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: ${props => props.theme.space.margin}px;
            .red{
              color:${props => props.theme.colors.colorError}
            }
            .label{
                min-width: 70px;
              
            }
        }
    }
    .btns {
        display: flex;
        gap: 15px;
    }
    .item{
        display: flex;
        margin-bottom: 15px;
        gap: 10px;
        align-items: center;
        .label {
            color: #8A8A8B;
            font-size: 14px;
        }
        .value{
            display: flex;
            align-items: center;
            gap: ${props => props.theme.space.margin}px;
            .active {
                border: 1px solid #FF9A16;
                .name {
                    color: #FF9A16 !important;
                }
            }
            .pay {
                display: flex;
                &-item {
                  width: 150px;
                  height: 55px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: solid 1px #e0e0e0;
                  margin-right: 40px;
                  cursor: pointer;
                  gap: 10px;
                  &:last-child {
                    margin-right: 0;
                  }
                  .type {
                    width: 36px;
                    height: 36px;
                    border-radius: 6px;
                    margin-right: 11px;
                  }
                  div {
                    font-size: 16px;
                    color: #2b2e36;
                  }
                }
                .active {
                  border: solid 1px #f4aa2f;
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    background: url("https://cdn.ggac.com/img/copyright-pay-active.png")
                      center center no-repeat;
                    background-size: 100% 100%;
                  }
                }
            }
        }
    }
    .btns {
      display: flex;
      gap: 15px;
    }
    .logistic{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        .express{
          color:  ${props => props.theme.colors.colorLinkDefault};
          display: flex;
          gap:${props => props.theme.space.marginLG}px;
        }
        .time {
          display: flex;
          align-items: center;
          margin-bottom: ${props => props.theme.space.margin}px;
          .ant-statistic-content {
            color: #C70200;
            font-size: 16px;
        }
      }
    }
`