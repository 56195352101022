import styled from "styled-components";

export const ExtraInfoStyle = styled.div`
    width: 100%;
    height: 60px;
    background-color:  ${(props) => props.theme.colors.colorBgLayout};
    border-radius:${(props) => props.theme.border.borderRadiusLG}px ;
    margin: ${(props) => props.theme.space.marginXS}px 0 0 0;
    border: 0px solid ${(props) => props.theme.colors.colorBorder};
    display: flex;
    justify-content: center;
    align-items: center;
    .data-item{
        .title{
            width: max-content;
            color: ${(props) => props.theme.colors.colorTextTertiary};
        }
        .data{
            text-align: center;
        }
    }
`