import Masonry from "react-masonry-css";
import { MasonaryImagesStyle } from "./style";
import HomeMediaCard from "components/cards/media";
import { GlobalSearchImageResponse } from "types/media";

const breakpointColumnsObj = {
  default: 6,
  1100: 3,
  700: 2,
  500: 1,
};

type Props = {
  imgList: GlobalSearchImageResponse [];
};


function MasonaryImages({ imgList }: Props) {
  return (
    <MasonaryImagesStyle>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {imgList.map((item, index) => {
          return (
            <div key={index} className="list-item" onClick={() => {window.open(`/work/detail/${item.dataId}`)}}>
              <HomeMediaCard key={item.id} info={item} ></HomeMediaCard>
            </div>
          );
        })}
      </Masonry>
    </MasonaryImagesStyle>
  );
}

export default MasonaryImages;
