import styled from "styled-components";

export const MallGuideStyle = styled.div`
    @font-face {
      font-family: 'DouyinSansBold';
      src: url('https://cdn-prd.ggac.com/ggac/font/DouyinSansBold.otf');
    }
    @font-face {
      font-family: 'AlibabaPuHuiTiR';
      src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    font-family: 'AlibabaPuHuiTiR';
    .banner {
        min-width: 1300px;
        position: relative;
        img {
            width: 100%;
        }
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            color: #fff;
            .first-title {
                font-size: 70px;
	            letter-spacing: 3px;
	            color: #fff;
                font-family: 'DouyinSansBold';
            }
            .second-title {
                font-size: 32px;
	            letter-spacing: 3px;
	            color: #fff;
            }
            .banner-btn {
                width: 250px;
	            height: 56px;
	            background-image: linear-gradient(130deg, 
	            	rgba(255, 141, 18, 0.6) 0%, 
	            	rgba(255, 200, 18, 0.6) 100%), 
	            linear-gradient(
	            	#ffffff, 
	            	#ffffff);
	            background-blend-mode: normal, 
	            	normal;
	            border-radius: 10px;
                margin: 20px auto 0;
                font-size: 26px;
	            color: #070240;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .title {
        width: max-content;
        margin: 80px auto 50px;
        font-size: 34px;
        font-family: 'DouyinSansBold';
        position: relative;
        div {
            position: relative;
            z-index: 2;
        }
        &::after{
            content: "";
            position: absolute;
            width: calc(100% + 6px);
            bottom: 12px;
            left: -3px;
            background-image: linear-gradient(4deg, 
	        	rgba(255, 141, 18, 0.6) 0%, 
	        	rgba(255, 200, 18, 0.6) 100%), 
	        linear-gradient(
	        	#ffffff, 
	        	#ffffff);
	        background-blend-mode: normal, 
	        	normal;
            height: 6px;
            z-index: 1;
        }
    }

    .part1 {
        .list {
            display: flex;
            justify-content: center;
            gap: 90px;
            &-item {
                text-align: center;
            }
            img {
                width: 100px;
                margin-bottom: 20px;
            }
            .name {
                text-align: center;
                font-size: 18px;
                margin-bottom: 10px;
                font-weight: bold;
            }
            .desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
	            color: #959595;
            }
        }
    }

    .second-part-style {
        padding: 100px 0;
        background-image: linear-gradient(130deg, #f5f3fe 0%, rgba(255, 255, 255, 0.44) 50%, rgba(251, 250, 255, 0.72) 75%, #f7f5fe 100%), 
	    linear-gradient(#f5f5f7, #f5f5f7);
	    background-blend-mode: normal, normal;
    }

    .part2 {
        padding-bottom: 50px;
        .list {
            display: flex;
            justify-content: center;
            gap: 50px;
            &-item {
                width: 360px;
	            height: 240px;
                background-image: linear-gradient(175deg, #454a52 0%, #363b40 51%, #262b2e 100%), 
	            linear-gradient(#000000, #000000);
	            background-blend-mode: normal, normal;
	            border-radius: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fff;
                img {
                    height: 55px;
                    margin-bottom: 40px;
                }
                .name {
                    font-size: 22px;
	                color: #fff;
                }
                .desc {
                    font-size: 16px;
                    margin-top: 16px;
                }
            }
        }
    }
    .part3 {
        padding: 63px 0px 66px;
        .title {
            margin-top: 0;
            height: max-content;
        }
        &-inner {
            display: flex;
            justify-content: center;
            gap: 50px;
        }
        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 286px;
            .title {
                margin: 0 0 40px 0;
            }
            .tip {
                display: flex;
                font-size: 14px;
                margin-bottom: 30px;
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                }
                &-one {
                    background-image: linear-gradient(135deg, 
	            	rgba(255, 148, 32, 0.6) 0%, 
	            	rgba(255, 174, 25, 0.6) 51%, 
	            	rgba(255, 200, 18, 0.6) 100%), 
	                linear-gradient(
	                	#ffc812, 
	                	#ffc812);
	                background-blend-mode: normal, 
	                	normal;
	                border-radius: 6px 0px 0px 6px;
                    width: 280px;
	                height: 30px;
                }
                &-two {
                    width: 60px;
	                height: 30px;
	                border-radius: 0px 6px 6px 0px;
	                border: solid 1px #ffc812;
                }
                &-three {
                    margin: 0 10px;
                    font-size: 20px;
                }
                &-four {
                    font-size: 16px;
                }
            }
            .content {
                width: 573px;
                font-size: 15px;
	            color: #959595;
                display: flex;
                flex-direction: column;
                gap: 20px;
                div {
                    position: relative;
                    padding-left: 13px;
                    &::after {
                        content: "";
                        background: url("https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part3-arrow.png") center center no-repeat;
                        background-size: 100% 100%;
                        width: 7px;
                        height: 10px;
                        position: absolute;
                        left: 0;
                        top: 7px;
                    }
                }
            }
        }
    }
    .part4 {
        .list {
            display: flex;
            gap: 27px;
            width: 1200px;
            margin: 0 auto;
            &-item {
                width: 280px;
	            height: 360px;
	            background-image: linear-gradient(130deg, #f5f3fe 0%, rgba(255, 255, 255, 0.44) 50%, rgba(251, 250, 255, 0.72) 75%, #f7f5fe 100%), 
	            linear-gradient(#f8f6fd, #f8f6fd);
	            background-blend-mode: normal, normal;
	            border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .name {
                    font-size: 22px;
	                color: #000;
                    font-weight: bold;
                }
                img {
                    margin: 20px 0;
                }
                .desc {
                    font-size: 18px;
	                color: #959595;  
                }
            }
        }
    }
    .part5 {
        padding-bottom: 80px;
        .list {
            display: flex;
            justify-content: center;
            gap: 210px;
            &-item:last-child .circle {
                &::after {
                    display: none;
                }
            }
            &-item {
                text-align: center;
                .circle {
                    width: 190px;
	                height: 190px;
	                background-image: linear-gradient(130deg, #ff8d12 0%, #ffc812 100%), 
	                linear-gradient(#9e9ea2, #9e9ea2);
	                background-blend-mode: normal, normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: relative;
                    img {
                        height: 63px;
                    }
                    &::after {
                        content: "";
                        background: url("https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part5-arrow.png") center center no-repeat;
                        background-size: 100% 100%;
                        width: 18px;
                        height: 29px;
                        position: absolute;
                        right: -110px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .name {
                    font-size: 22px;
                    font-weight: bold;
                    margin: 18px 0;
                }
                .desc {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    color: #9e9ea2;
                    line-height: 20px;
                }
            }
        }
    }

    .part6 {
        .title {
            margin-top: 0;
        }
        .list {
            display: flex;
            justify-content: center;
            gap: 50px;
            margin-bottom: 100px;
            &-item:nth-child(2) img {
                height: 47px;
                top: 56px;
            }
            &-item {
                width: 360px;
	            height: 264px;
                background-image: linear-gradient(175deg, #454a52 0%, #363b40 51%, #262b2e 100%), 
	            linear-gradient(#000000, #000000);
	            background-blend-mode: normal, normal;
	            border-radius: 20px;
                text-align: center;
                position: relative;
                padding-top: 135px;
                text-align: center;
                color: #fff;
                img {
                    height: 55px;
                    position: absolute;
                    top: 48px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .name {
                    font-size: 22px;
                }
                .desc {
                    font-size: 16px;
                    margin-top: 15px;
                    div {
                        line-height: 20px;
                    }
                }
            }
        }

        .content {
            width: 710px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            div {
                font-size: 18px;
                padding-left: 16px;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    background: url("https://cdn-prd.ggac.com/ggac/store-guide/store-guide-part7-arrow.png") center center no-repeat;
                    background-size: 100% 100%;
                    width: 7px;
                    height: 11px;
                    left: 0;
                    top: 8px;
                }
            }
        }

        .tip {
            font-size: 16px;
	        color: #9e9ea2;
            margin-top: 20px;
            text-align: center;
            color: #000;
            span {
                color: #ffa433;
                text-decoration: underline;
                text-underline-offset: 4px;
                cursor: pointer;
            }
        }
    }
`