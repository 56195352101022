import { UserFavoritesStyle } from "./style";
import { Pagination, Spin } from "antd"
import FavoritesCard from "components/favorite/favorites-card"
import { useCallback, useEffect, useState, useMemo } from "react";
import { apiFavoriteList } from "api/favorite";
import { favoriteListItem } from "types/favorite";
import { PaginationResponse } from "types/common/axios"
import { useNavigate, useParams } from "react-router";
import { NoData } from "components/no-data";

const UserFavorites = () => {
    const [favoriteList, setFavoriteList] = useState<favoriteListItem[]>([])
    const [favoriteData, setFavoriteData] = useState<PaginationResponse<favoriteListItem>>()
    const [pageNumber, setPageNumber] = useState(1);
    // let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)

    const params = useMemo(() => {
        return {
            pageNumber,
            userId: Number(id),
            pageSize: 20
        }
    }, [pageNumber])

    const getList = useCallback(() => {
        setLoading(true)
        apiFavoriteList(params).then(res => {
            setFavoriteList(res.pageData)
            setFavoriteData(res)
        }).finally(() => {
            setLoading(false)
        })
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const updateList = () => {
        getList()
    }

    const toDetail = (detail: favoriteListItem) => {
        navigate(`/user/${id}/favorites/detail/${detail.id}?type=${detail.type}`)
    }

    const changePage = (e: number) => {
        setPageNumber(e);
    };

    return <UserFavoritesStyle>
        <Spin spinning={loading}>
            <FavoritesCard toDetail={toDetail} isPublic={true} list={favoriteList} updateList={updateList} />
            {
                favoriteList.length === 0 && <NoData />
            }
        </Spin>
        <Pagination
            style={{ textAlign: "center", marginTop: "15px" }}
            hideOnSinglePage={true}
            current={pageNumber}
            showSizeChanger={false}
            onChange={changePage}
            pageSize={params.pageSize}
            total={favoriteData?.totalSize}
        />
    </UserFavoritesStyle>
}

export default UserFavorites