import styled from "styled-components";

export const OfficialDetailStyle = styled.div`
    padding: ${props => props.theme.space.paddingLG * 3}px 0 ${props => props.theme.space.paddingLG}px;
    width: 1200px;
    margin: 0 auto;
    .nav{
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1000px;
      margin: 0 auto;
    }
    .article-title{
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-top: ${props => props.theme.space.marginLG}px;
    }
    .date {
        text-align: center;
        color: #94969f;
        margin: ${props => props.theme.space.margin}px 0;
    }
    .content {
      max-width: 1000px;
      margin: 0 auto;
      padding-bottom: 40px;
        p,div {
            text-align: center;
        }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      max-width: 1000px;
      margin: 0 auto;
      .second {
        text-align: right;
      }
      &-item {
        cursor: pointer;
      }
      .top {
        color: ${props => props.theme.colors.colorPrimary};
        display: flex;
        align-items: center;
        margin-bottom:${props => props.theme.space.marginXS}px;
      }
      .name{

      }
    }
    .comment-wrapper {
      padding: ${(props) => props.theme.space.padding}px 0 ${(props) => 2 * props.theme.space.paddingLG}px;
      max-width: 1000px;
      margin: 0 auto;

      .input-wrapper {
        margin-top: ${(props) => props.theme.space.marginXS}px;
        position: relative;
        display: flex;
        flex-direction: column;
        .input {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .confirm-btn {
          width: 80px;
          margin-top: ${(props) => props.theme.space.marginXS}px;
          align-self: flex-end;
        }
      }
    }
`