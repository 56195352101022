import { useMenuAnimation } from "utils/hooks/useMenuAnimation";
import { UserDropdownStyle } from "./style"
import { apiUserLogout } from "api/user";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiUserStatistics } from "api/user/detail";
import { UserStatistics } from "types/user/detail";


function UserDropdown({isOpen,userInfo}:{isOpen:boolean,userInfo:any}) {
    const scope = useMenuAnimation(isOpen,'.dropdown-item');
    const [detail, setDetail] = useState<UserStatistics>()
    const navigate = useNavigate()
    const logout = async () => {
       await  apiUserLogout();
       localStorage.clear(); 
       navigate(`/auth/login?redirect=${window.location.href}`)
    }

    useEffect(()=>{
        apiUserStatistics().then(res=>{
            setDetail(res)
        })
    },[])
  return (
    <UserDropdownStyle ref={scope}>
        <div className="inner-container">
        <div className="infoGrid">
            
            <div className="grid-item">
                <div className="number">{detail?.fansCount || 0}</div>
                <div className="title">粉丝数</div>
            </div>
            <div className="grid-item">
                <div className="number">{detail?.likeCount! || 0}</div>
                <div className="title">点赞</div>
            </div>
            <div className="grid-item">
                <div className="number">{detail?.gpBalance || 0}</div>
                <div className="title">G分</div>
            </div>
            
        </div>
        {/* <div className='dropdown-item' onClick={()=>navigate(`/user/${userInfo.id}`)}>我的主页</div> */}
        <div className='dropdown-item' onClick={()=>navigate('/user-center/home/work/list')}>个人中心</div>
        <div className='dropdown-item' onClick={()=>navigate('/user-center/shop/product-list')}>我的店铺</div>
        {/* <div className='dropdown-item'>管理商品</div> */}
        <div className='dropdown-item' onClick={()=>logout()}>退出登录</div>
        </div>
    </UserDropdownStyle>
  )
}

export default UserDropdown