import { Avatar, Button, Tooltip } from "antd";
import { CollabrationStyle } from "./style";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import AddModal, { collabrationUserObj } from "./addModal";
import { useEffect, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import { findUserRole } from "utils/functions/common";

type Props = {
  onListChange: (list: collabrationUserSimple[]) => void;
  creators: { //共创者组
    isFollow: number //是否关注
    avatarUrl: string //头像
    id: number //id
    role: number //共创身份：1（创作者）、2（参与人）、3（导师）
    userId: number //共创人ID
    certifiedRoleId: number //角色Id: 1认证作者, 2签约作者, 3团队账号, 4特邀作者
    username: string //用户昵称
  }[] | undefined;
};
export type collabrationUserSimple = {
  userId: number;
  role: number;
};

function Collabration({ onListChange, creators }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState<collabrationUserObj[]>([]);
  const handleOk = (user: collabrationUserObj) => {
    if (!list.find((item) => item.userId === user.userId)) {
      setList([...list, user]);
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDelete = (item: collabrationUserObj) => {
    setList(list.filter((i) => i.userId !== item.userId));
  };

  useEffect(() => {
    onListChange(
      list.map((item) => {
        return {
          userId: item.userId,
          role: item.role,
        };
      })
    );
  }, [list]);



  useEffect(() => {
    if (creators) {
      setList(
        creators.map((item) => {
          return {
            userId: item.id,
            role: item.role,
            roleText: item.role === 1 ? '创作者' : item.role === 2 ? '参与人' : item.role === 3 ? '导师' : "原作者",
            username: item.username,
            avatarUrl: item.avatarUrl,
            certifiedRoleId: item.certifiedRoleId,
          };
        })
      );
    }

  }, [creators]);

  const reorderList = (sourceIndex: number, destinationIndex: number) => {
    console.log("reorderList", sourceIndex, destinationIndex);
    if (!list) return;
    if (destinationIndex === sourceIndex) {
      return;
    }
    const targetItem = list[sourceIndex];
    if (destinationIndex < sourceIndex) {
      const a = list.slice(0, destinationIndex)
      const b = list.slice(destinationIndex)
      const aRemoveTarget = a.filter(item => item.userId !== targetItem.userId);
      const bRemoveTarget = b.filter(item => item.userId !== targetItem.userId);
      const sortedList = [...aRemoveTarget, targetItem, ...bRemoveTarget]
      setList(sortedList);
    }

    if (destinationIndex > sourceIndex) {
      const a = list.slice(0, destinationIndex + 1)
      const b = list.slice(destinationIndex + 1)
      const aRemoveTarget = a.filter(item => item.userId !== targetItem.userId);
      const sortedList = [...aRemoveTarget, targetItem, ...b]
      setList(sortedList);

    }

  };

  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
        return 'yellow'

      case 3:
        return 'blue'

      default:
        return 'hide'
    }
  };

  return (
    <CollabrationStyle className="CollabrationStyle">
      <div className="btn">
        <Button
          onClick={() => {
            setIsModalOpen(true);
          }}
          block
          icon={<PlusOutlined />}
          disabled={list.length > 4}
        ></Button>
      </div>
      {/* <div className="list">
        {list &&
          list.map((item, index) => {
            return (
              <div className="list-item" key={index}>
                <div
                  className="avatar"
                  onClick={() => window.open(`/user/${item.userId}`)}
                >
                  <Avatar src={item.avatarUrl} size={38}></Avatar>
                  <div
                    className="overflow-hidden"
                    title={item.username}
                    style={{ width: 100 }}
                  >
                    {item.username}
                  </div>
                </div>
                <div>{item.roleText}</div>
                <div title="删除">
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={() => handleDelete(item)}
                  ></Button>
                </div>
              </div>
            );
          })}
      </div> */}
      {list && (
        <div className="list">
          <ListManager
            items={list}
            direction="vertical"
            maxItems={4}

            render={(item) => <div className="list-item" key={item.userId}>
              <div
                className="avatar"
                onClick={() => window.open(`/user/${item.userId}`)}
              >
                <Avatar src={item.avatarUrl} size={38}></Avatar>
                {item.certifiedRoleId && (
                  <Tooltip
                    title={findUserRole(item.certifiedRoleId)?.name}
                    placement="bottom"
                  >
                    <div className={`role ${renderColor(findUserRole(item.certifiedRoleId)?.value)}`}>
                      V
                    </div>

                  </Tooltip>
                )}
                <div
                  className="overflow-hidden"
                  title={item.username}
                  style={{ width: 100 }}
                >
                  {item.username}
                </div>
              </div>
              <div>{item.roleText}</div>
              <div title="删除">
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  onClick={() => handleDelete(item)}
                ></Button>
              </div>
            </div>}
            onDragEnd={reorderList}
          />
        </div>
      )}
      <AddModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      ></AddModal>
    </CollabrationStyle>
  );
}

export default Collabration;
