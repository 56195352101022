import { SignupFieldOptions } from "types/sub/application"
import { SubChildrenSelectStyle } from "./style"
import { useEffect, useState } from "react"



type SelectItem = {
    label: number[], children: number[]
}

type SelectObj = {
    label: number, children: number
}

export const SubChildrenSelect = (props: { value?: number[], onChange?: (val: SelectItem) => void, multiple?: boolean, data?: SignupFieldOptions[] }) => {
    const [choosedList, setChoosedList] = useState<SelectObj[]>([])

    const finalValue: SelectItem = {
        label: [],
        children: []
    }

    const onClickItem = (firstId: number, item: SignupFieldOptions) => {
        if (props.multiple) {
            let arr: SelectObj[] = []
            let index = choosedList.findIndex(sub => {
                return sub.label === firstId && sub.children === item.id
            })
            if (index >= 0) {
                choosedList.splice(index, 1)
                arr = choosedList.concat([])
            } else {
                arr = choosedList.concat([{ label: firstId, children: item.id }])
            }
            arr.forEach(sub => {
                finalValue.label.push(sub.label)
                finalValue.children.push(sub.children)
            })
            finalValue.label = Array.from(new Set(finalValue.label))
            finalValue.children = Array.from(new Set(finalValue.children))
            props.onChange!(finalValue)
            setChoosedList(arr)
        } else {
            setChoosedList([{ label: firstId, children: item.id }])
            props.onChange!({
                label: [firstId],
                children: [item.id]
            })
        }
    }

    useEffect(() => {
        if (!props.value) {
            return
        }
        if(props.value.constructor !== Object){
            setChoosedList([{ label: props.value[0], children: props.value[1] }])
            props.onChange!({ label: [props.value[0]], children: [props.value[1]] })
        }

    }, [props.value])

    return <SubChildrenSelectStyle>
        <div className="second-select-container">
            {props.data?.map(first =>
                <div className="item-wrapper" key={first.id}>
                    <div className="first-wrapper">{first.name}</div>
                    <div className="second-wrapper">
                        {first.children?.map(second =>
                            <div key={second.id} className={choosedList?.findIndex(item => first.id === item.label && item.children === second.id) >= 0 ? 'second-wrapper-item active' : 'second-wrapper-item'} onClick={() => onClickItem(first.id, second)}>
                                {second.name}
                            </div>)}
                    </div>
                </div>)}
        </div>
    </SubChildrenSelectStyle>
}