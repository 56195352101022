import styled from "styled-components";

export const WorkCollectionStyle = styled.div`
  
    max-width: ${(props) => props.theme.space.maxContainer}px;
    padding: 0 ${props => props.theme.space.padding}px;
    background-color: ${(props) => props.theme.colors.colorBgLayout};
    margin: 0 auto;
    .title-wrap {
      position: relative;
      width: 100%;
      margin-top: ${(props) => props.theme.space.padding}px;
      .tip {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        .icon{
          font-size: 16px;
          padding-left: 4px;
        }
        color: ${props => props.theme.colors.colorLinkDefault};
        &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
      }
    }
  .container_ {
    padding-bottom:${(props) => props.theme.space.paddingLG}px;
  
    max-width: ${(props) => props.theme.space.maxContainer}px;
    .wrap {
      display: flex;
      align-items: center;
    }
  }
`;

export const CardStyle = styled.div`
  /* z-index: 100; */
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: ${(props) => props.theme.space.padding}px;
  margin: 0 auto;
  .card {
    background-color: #fff;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    overflow: hidden;
    
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colors.colorBorder};
    margin-bottom: ${(props) => props.theme.space.paddingLG * 1.3}px;
    &:hover{
      box-shadow: ${(props) => props.theme.shadow.boxShadowThin};
    }

    .cover {
      width: 100%;
      aspect-ratio: 223/176;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${(props) => props.theme.colors.colorBlack6Ggac};
    }
    .content {
      padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingMD}px;

      .flex {
        display: flex;
        flex-direction: column;
        .upper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - '60px');
            color: ${(props) => props.theme.colors.colorText};
            &:hover{
              color: ${(props) => props.theme.colors.colorLinkHover};
            }
          }
          .view {
            display: flex;
            align-items: center;
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            .icon{
              color: ${(props) => props.theme.colors.colorTextSecondary};
              padding-bottom: 2px;
            }
            span {
              padding-left: ${(props) => props.theme.space.paddingXXS}px;
              color: ${(props) => props.theme.colors.colorTextSecondary};
            }
          }
        }
        .bottom{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: fit-content;
          a {
            display: flex;
            align-items: center;
          }
          .avatar{
            min-width: 24px;
            margin-right: ${props => props.theme.space.marginXXS}px;
          }
          .user-name {
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
  
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
            width: calc(100% - '60px')
        }
        }
        
      }
    }
  }
`;
