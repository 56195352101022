import styled from "styled-components";

export const UpadtePriceStyle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    button {
        height: 35px;
        line-height: 35px;
        padding-top: 0;
    }
    .button-wrap {
        text-align: center;
        margin-top: 20px;
        button {
            padding-top: 7px !important;
        }
    }
`