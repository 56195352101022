import styled from "styled-components";

export const FloatChatInputStyle = styled.div`
    width: 100%;
    /* background-color:${(props) => props.theme.colors.colorBlack1Ggac}; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    z-index: 1000;
    .inputBar{
        display: flex;
        .input{
            flex: 1;
        }
        .btn{
            width: 80px;
            margin-left: ${props=> props.theme.space.marginXS}px;
        }
    }
`