export const calcStyle = (theme:string)=>{
    let bgColor = "#fff";
    let textColor = "#fff";
    let border = "";
    switch (theme) {
        case "green-1":
          bgColor = "#8bd0b7";
          textColor = "#fff";
          break;
        case "green-2":
          bgColor = "#BADFD0";
          textColor = "#28654E";
          break;
        case "green-3":
          bgColor = "#fff";
          textColor = "#28654E";
          border = "1px solid #BEDED1";
          break;

          case "yellow-1":
            bgColor = "#FCC712";
            textColor = "#fff";
            break;
            case "yellow-3":
              bgColor = "#fff";
              textColor = "#cb9f10";
              border = "1px solid #cb9f10";
              break;

          case "red-1":
            bgColor = "#EB403E";
            textColor = "#fff";
            break;
          case "red-2":
            bgColor = "#F39C9A";
            textColor = "#6F1211";
            break;
          case "red-3":
            bgColor = "#fff";
            textColor = "#EB413F";
            border = "1px solid #F39D9B";
            break;

          case "blue-1":
            bgColor = "#3EA0EB";
            textColor = "#fff";
            break;
          case "blue-2":
            bgColor = "#A2D7FF";
            textColor = "#2E5877";
            break;
          case "blue-3":
            bgColor = "#fff";
            textColor = "#3EA0EB";
            border = "1px solid #A2D7FF";
            break;

         
            case "gray-1":
              bgColor = "#8D9CA7";
              textColor = "#fff";
              break;
            case "gray-2":
              bgColor = "#DBE3EA";
              textColor = "#4D575E";
              break;
            case "gray-3":
              bgColor = "#fff";
              textColor = "#8D9CA7";
              border = "1px solid #DBE3EA";
              break;   
      }

    return {bgColor,textColor,border}  
} 