import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import WorkList from "views/user-creator-center/work";
import DraftWorkList from "views/user-creator-center/work/draftWork";
import ArticleList from "views/user-creator-center/article";
import DraftArticleList from "views/user-creator-center/article/draftWork";
import CreatorCenterLayout from "views/user-creator-center/layout";
import AlbumList from "views/user-creator-center/album"
import AlbumDetail from "views/user-creator-center/album/detail";
import CertifiedAuthor from 'views/user-creator-center/certified-author'
import CertifiedAuthorMaterial from 'views/user-creator-center/certified-author/material'
import SignedAuthor from "views/user-creator-center/signed-author"
import SignedAuthorMaterial from "views/user-creator-center/signed-author/material";
import OffShelf from "views/user-creator-center/work/offShelf"
import OffShelfArticle from "views/user-creator-center/article/offShelf"

//单个路由规则

export const CreatorCenterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Layout></Layout>,
    children: [{
      path: "/user-creator-center",
      element: <CreatorCenterLayout></CreatorCenterLayout>,
      children: [
        {
          path: "work/list",
          element: <WorkList></WorkList>,
        },
        {
          path: "work/offShelf/list",
          element: <OffShelf></OffShelf>,
        },
        {
          path: "work/draft/list",
          element: <DraftWorkList></DraftWorkList>,
        },
        {
          path: "article/list",
          element: <ArticleList></ArticleList>,
        },
        {
          path: "article/draft/list",
          element: <DraftArticleList></DraftArticleList>,
        },
        {
          path: "article/offShelf/list",
          element: <OffShelfArticle></OffShelfArticle>,
        },
        {
          path: "work-album",
          element: <AlbumList></AlbumList>,
        },
        {
          path: "work-album/detail/:id",
          element: <AlbumDetail></AlbumDetail>,
        },
        {
          path: "certified-author",
          element: <CertifiedAuthor />
        },
        {
          path: "signed-author",
          element: <SignedAuthor />
        },
        {
          path: "signed-author/material",
          element: <SignedAuthorMaterial />
        },
        {
          path: "certified-author/material",
          element: <CertifiedAuthorMaterial />
        }
      ],
    }]
  },

]