import styled from "styled-components";

export const XnfwUserRankStyle = styled.div`
    @font-face {
        font-family: 'nzgrKangxi';
        src: url('https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/nzgrKangxi.ttf');
    }
    .cover {
        img {
            width: 100%;
        }
    }
    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 200px;
        padding: 100px 0;
        &-item {
            font-family: 'nzgrKangxi';
            width: 410px;
            height: 117px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-tab.png") center center no-repeat;
            background-size: cover;
            font-size: 46px;
	        letter-spacing: 5px;
	        color: #6b1e03;
            cursor: pointer;
        }
    }
    .active,.tab-item:hover {
        color: #fff !important;
        background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-tab-active.png") center center no-repeat !important;
        background-size: cover !important;
    }
    .content {
        background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-bg.png") center center no-repeat;
        background-size: cover;
        margin-top: -6px;
        .dw {
            position: absolute;
            
        }
        .dw1 {
            left: -250px;
            top: -400px;
        }
        .dw2 {
            bottom: -800px;
            z-index: -1;
            left: -250px;
        }
        .dw3 {
            right: -250px;
            z-index: -1;
            top: -300px;
        }
        .dw4 {
            right: -250px;
            z-index: -1;
            bottom: -350px; 
        }
        .dw5 {
            left: -254px;
            z-index: -1;
            top: -820px;
        }
        .inner {
            max-width: 1920px;
            margin: -7px auto 0;
            position: relative;
        }
        .title {
            text-align: center;
            margin-bottom: 150px;
            img {
                width: 900px;
            }
        }
        .work1,.work4 {
            margin-bottom: 700px !important;
        }
        .work4 {
            margin-bottom: 750px !important;
        }
        .work5{
            margin-bottom: 0 !important;
        }
        .work2,.work3,.work4 {
            .cover-wrap{
                background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-circle2.png") center center no-repeat !important;
                background-size: cover !important;
            }
            .user-name {
                color: #fff !important;
            }
        }
        .work {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 1400px;
            min-width: 1100px;
            margin: 0 auto 200px;
            position: relative;
            z-index: 5;
            row-gap: 3vw;
            column-gap: 5vw;
            &-item {
                min-width: 105px;
                width: 175px;
                cursor: pointer;
                .cover-wrap {
                    width: 100%;
                    aspect-ratio: 175 / 175;
                    background-color: red;
                    padding: 10px;
                    width: 100%;
                    background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-user-circle1.png") center center no-repeat;
                    background-size: cover;
                }
                .work-cover {
                    aspect-ratio: 1 / 1;
                    width: 100%;
                    background-size: cover;
                    border-radius: 50%;
                }
                .user-name {
                    font-size: 22px;
	                color: #a93c1c;
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }
        .bottom {
            position: relative;
            padding-top: 350px;
            img {
                width: 100%;
            }
            .code {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 50px;
                    display: flex;
                    justify-content: center;
                    &-item {
                        width: 324px;
                        height: 349px;
                        position: relative;
                        background: url('https://cdn.ggac.com/img/summary-2023-rank-pc-part-code-bg.png')
                          center center no-repeat;
                        background-size: 100% 100%;
                        margin: 0 75px;
                        text-align: center;
                        .code-tip {
                          font-size: 17px;
                          color: #fee29b;
                          margin-top: 5px;
                        }
                        img {
                          margin-top: 16px;
                          width: 260px;
                        }
                        .code-word {
                          font-size: 20px;
                          color: #a93c1c;
                          text-align: center;
                          position: absolute;
                          bottom: -37px;
                          width: 100%;
                        }
                    }
                }
        }
    }
`