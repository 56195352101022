import { Button, Checkbox, Form, Input, Modal, Space, message } from "antd";
import { apiCreateForumTopic, apiUpdateForumTopic } from "api/forum";
import { ForumTopicDetail } from "api/forum/type";
import { TagSelect } from "components/tagSelect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopicEditFooterStyle } from "./style";
import TinyEditor from "ui-lib/tinyMCE";
import { CosDirectoryType } from "types/enums/media-type";
import { extractPathnameAndSearchParams } from "utils/functions/common";

type Props = {
  isModalOpen: boolean;
  handleOk: (topic: any) => void;
  handleCancel: () => void;
  topicItem?: ForumTopicDetail;
};

function TopicCreateAndUdate({
  isModalOpen,
  handleOk,
  handleCancel,
  topicItem,
}: Props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isAnonymity, setIsAnonymity] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onFinish = () => {
    
    if (topicItem) {
      form.validateFields().then((values) => {
        const {title,description,tagList}= values
        setConfirmLoading(true);
        apiUpdateForumTopic({ id: topicItem.id, isAnonymity,description: description?.html, title,tagList }).then((res) => {
          message.success("编辑成功");
          //navigate(`/forum/topic/${res.id}?back=${extractPathnameAndSearchParams(window.location.href)}`);
          setConfirmLoading(false);
          form.resetFields();
          handleOk(values);
        }).finally(() => {
          setConfirmLoading(false);
        });
      });
    } else {
      form.validateFields().then((values) => {
        const {title,description,tagList}= values
        setConfirmLoading(true);
        apiCreateForumTopic({ isAnonymity,description: description?.html, title,tagList}).then((res) => {
          message.success("发布成功");
          navigate(`/forum/question/topic/${res.id}?back=${extractPathnameAndSearchParams(window.location.href)}`);
          form.resetFields();
          setConfirmLoading(false);
          handleOk(values);
        }).finally(() => {
          setConfirmLoading(false);
        });
      });
    }
  };

  useEffect(() => {
    if (topicItem) {
    
      const params = {
        tagList: topicItem.tagList.map((item) => item.name),
        title: topicItem.title,
        description: {
          html: topicItem.description,
        },
       
      };
      setIsAnonymity(topicItem.isAnonymity)
      form.setFieldsValue(params);
    }
  }, [topicItem,isModalOpen]);

  const footer = () => {
    return (
      <TopicEditFooterStyle className="TopicEditFooterStyle">
        <div className="left"></div>
        <div className="right">
          <Space>
            <Checkbox
              checked={Boolean(isAnonymity)}
              onChange={(e) => {
                setIsAnonymity(e.target.checked ? 1 : 0);
              }}
            >
              匿名
            </Checkbox>
            <Button
              className="btn"
              onClick={() => {
                form.resetFields();
                handleCancel();
              }}
            >
              取消
            </Button>
            <Button type="primary" className="btn" onClick={onFinish} loading={confirmLoading}>
              发布
            </Button>
          </Space>
        </div>
      </TopicEditFooterStyle>
    );
  };
  return (
    <Modal
      title={topicItem?.id ? "编辑话题" : "发布话题"}
      open={isModalOpen}
      onOk={onFinish}
      onCancel={handleCancel}
      width={800}
      footer={footer()}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        name={Date.now().toString()}
        style={{ maxWidth: 800, marginTop: 20 }}
        requiredMark={false}
      >
        <Form.Item
          name="title"
          rules={[{ required: true, message: "请输入话题" }]}
        >
          <Input placeholder="输入你感兴趣的话题"></Input>
        </Form.Item>
        <Form.Item name="description">
          <TinyEditor
            cosImagePath={CosDirectoryType.forumImage}
            cosVideoPath={CosDirectoryType.forumVideo}
          ></TinyEditor>
        </Form.Item>
        <Form.Item name="tagList">
          <TagSelect dataTable="forum_topic" maxCount={5}></TagSelect>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default TopicCreateAndUdate;
