import { AccountStyle } from "./style"
import { Button, Modal, message } from "antd"
import { useEffect, useCallback, useState } from 'react'
import UpdateMobileDialog from "./updateMobile"
import UpdatePasswordDialog from "./updatePassword"
import { apiGetUserAccountList, apiGetUserBaseInfo } from "api/user"
import { UserBaseInfo, UserUserAccountList } from "types/user"
import { apiUnbindWeixin, apiBindWeixin } from "api/wechat"
import { useSearchParams } from "react-router-dom"
import { apiBindQQ, apiUnbindQQ } from "api/qq"
import BindEmail from "./bindEmail"
import { postEmailUnbind } from "api/common"


const Account = () => {
    const [userDetail, setUserDetail] = useState<UserBaseInfo>()
    const [updateMobileVisible, setUpdateMobileVisible] = useState<boolean>(false)
    const [bindEmailVisible, setBindEmailVisible] = useState<boolean>(false)
    const [updatePasswordVisible, setUpdatePasswordVisible] = useState<boolean>(false)
    let [searchParams, setSearchParams] = useSearchParams();
    const [code, setCode] = useState(searchParams.get("code"))
    const stage = searchParams.get("stage")
    const [accountDetail, setAccountDetail] = useState<UserUserAccountList>()
    useEffect(() => {
        if (code) {
            if (stage === 'weixin') {
                apiBindWeixin(code).then(async res => {
                    message.success('绑定成功！');
                    const result = await apiGetUserBaseInfo()
                    let obj = JSON.parse(JSON.stringify(result))
                    obj.bindWx = true
                    setUserDetail(obj)

                }).finally(() => {
                    setSearchParams('')
                    setCode('')
                })
            } else if (stage === 'qq') {
                apiBindQQ(code).then(async res => {
                    message.success('绑定成功！');
                    const result = await apiGetUserBaseInfo()
                    let obj = JSON.parse(JSON.stringify(result))
                    obj.bindQq = true
                    setUserDetail(obj)

                }).finally(() => {
                    setSearchParams('')
                    setCode('')
                })
            }
        }
    }, [code])



    const getDetail = async () => {
        if (code) return
        const result = await apiGetUserBaseInfo()
        setUserDetail(result)
    }

    const getAccountDetail = async () => {
        if (code) return
        const result = await apiGetUserAccountList()
        setAccountDetail(result)
    }

    const updateMobile = () => {
        setUpdateMobileVisible(true)
    }
    const closeUpdateMobileDialog = (value: boolean) => {
        setUpdateMobileVisible(value)
    }

    const updatePassword = () => {
        setUpdatePasswordVisible(true)
    }
    const closeUpdatePasswordDialog = (value: boolean) => {
        setUpdatePasswordVisible(value)
    }
    const UpdateSuccess = () => {
        getDetail()
    }

    const BindEmailSuccess = () => {
        getAccountDetail()
    }

    const bindQQ = () => {
        if (userDetail?.bindQq) {
            Modal.confirm({
                title: '温馨提示',
                content: '解绑后将不可使用该平台快速登陆，确定解绑么？',
                okText: "确定",
                // okType: "danger",
                okType: "danger",
                cancelButtonProps: { type: 'text' },
                cancelText: '取消',
                centered: true,
                onOk() {
                    apiUnbindQQ(3).then(res => {
                        let obj = JSON.parse(JSON.stringify(userDetail))
                        obj.bindQq = false
                        setUserDetail(obj)
                        message.success('解绑成功!')
                    })
                },
                onCancel() {
                }
            });

        } else {
            var location = document.location
            let url = encodeURIComponent(`https://www.ggac.com/user-center/account?stage=qq`)
            window.location.href = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=101955030&redirect_uri=${url}&state=989735`
        }
    }

    const bindWeixin = () => {
        if (userDetail?.bindWx) {
            Modal.confirm({
                title: '温馨提示',
                content: '解绑后将不可使用该平台快速登陆，确定解绑么？',
                okText: "确定",
                // okType: "danger",
                okType: "danger",
                cancelButtonProps: { type: 'text' },
                cancelText: '取消',
                centered: true,
                onOk() {
                    apiUnbindWeixin(2).then(res => {
                        let obj = JSON.parse(JSON.stringify(userDetail))
                        obj.bindWx = false
                        setUserDetail(obj)
                        message.success('解绑成功!')
                    })
                },
                onCancel() {
                }
            });

        } else {
            let url = encodeURIComponent(`https://www.ggac.com/user-center/account?stage=weixin`);
            window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wxe3c99841583fc124&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
        }
    }

    const bindEmail = () => {
        if (!!accountDetail?.bindEmail) {
            Modal.confirm({
                title: '确定解绑邮箱？',
                content: '',
                okText: "确定",
                cancelText: "取消",
                centered: true,
                onOk() {
                    postEmailUnbind().then(() => {
                        setTimeout(() => {
                            message.success("解绑成功")
                            getAccountDetail()
                        }, 100)
                    })
                },
            });

        } else {
            setBindEmailVisible(true)
        }

    }

    useEffect(() => {
        getDetail()
        getAccountDetail()
    }, [])
    return <AccountStyle>
        <div className="account">
            <div className="title">账号设置</div>
            <div className="content">
                <div className="content-item">
                    <div className="label">手机号</div>
                    <div className="value">{userDetail?.mobile?.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}</div>
                    <Button className="btn" type="text" size="small" onClick={() => updateMobile()}>修改</Button>
                </div>
                <div className="content-item">
                    <div className="label">登录密码</div>
                    <div className="value">{userDetail?.hasPassword ? '建议您经常修改密码，以保证账号更加安全' : '您当前还没有设置密码，为了您的账号安全，请尽快设置密码'}</div>
                    <Button className="btn" type="text" size="small" onClick={() => updatePassword()}>{userDetail?.hasPassword ? '修改' : '设置'}</Button>
                </div>
                <div className="content-item">
                    <div className="label">微信</div>
                    <Button className="btn" type="text" size="small" onClick={() => bindWeixin()}>{accountDetail?.bindWx ? '解绑' : '绑定'}</Button>
                </div>
                <div className="content-item">
                    <div className="label">QQ</div>
                    <Button className="btn" type="text" size="small" onClick={() => bindQQ()}>{accountDetail?.bindQq ? '解绑' : '绑定'}</Button>
                </div>
                <div className="content-item">
                    <div className="label">邮箱</div>
                    {
                        accountDetail?.email && <div className="value">{accountDetail?.email}</div>
                    }
                    <Button className="btn" type="text" size="small" onClick={() => bindEmail()}>{accountDetail?.bindEmail ? '解绑' : '绑定'}</Button>
                </div>
            </div>
        </div>


        <UpdateMobileDialog params={{ UpdateSuccess, visible: updateMobileVisible, visibleChange: closeUpdateMobileDialog }} />
        <BindEmail params={{ BindEmailSuccess, visible: bindEmailVisible, visibleChange: () => setBindEmailVisible(false) }} />
        <UpdatePasswordDialog params={{ UpdateSuccess, visible: updatePasswordVisible, visibleChange: closeUpdatePasswordDialog, hasPassword: Boolean(userDetail?.hasPassword), mobile: String(userDetail?.mobile) }} />
    </AccountStyle>
}

export default Account