import styled from "styled-components";

export const ReportModalStyle = styled.div`
    .container{
            .report-modal-container{
                .ant-modal-title{
                    margin-bottom: 10px;
                }
            .reason-item-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid ${props => props.theme.colors.colorBorder};
                width: 352px;
                height: 40px;
                .report-radio{
                    &:hover{
                        color: ${props => props.theme.colors.colorPrimaryText} !important
                    }
                }
        }
        .label{
            margin: ${(props)=> props.theme.space.marginXS}px 0;
        }
        
        .report-foot-wrapper{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: ${props => 1.5 * props.theme.space.margin}px;
            margin-bottom: ${props => props.theme.space.marginXS}px;
        }
        }
        
        
    }
`