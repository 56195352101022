export enum PayType {
    COMMODITY_PAY_WEIXIN = 1,
    COMMODITY_PAY_ALI = 2
}

export const PayTypeArr = [
    { name: '微信支付', value: PayType.COMMODITY_PAY_WEIXIN },
    { name: '支付宝支付', value: PayType.COMMODITY_PAY_ALI }
]



