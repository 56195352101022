import { getReportResonList, postReport } from "api/common/favour";
import { ReasonItem } from "types/common/favour";
import { Button, Modal, Radio, Space, Spin, message, Form } from "antd";
import { useEffect, useRef, useState } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import { ReportModalStyle } from "./style";
import { Input } from 'antd'
import { TextAreaRef } from "rc-textarea";
import ImageUpload from "components/uploadImage";
import { getFileName } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { getFileUrlByCosUpload } from "config/cos";

// type UploadImg = { file?: File,
//   imgUrl: string}

const { TextArea } = Input

export const ReportModal = (props: { show: boolean, close: () => void, params: { dataId: number, dataTable: string } }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [radioValue, setRadioValue] = useState<number>()
  const [radioName, setRadioName] = useState<string>('')
  const [reasonList, setReasonList] = useState<ReasonItem[]>([])
  const [secondReasonList, setSecondReasonList] = useState<{ id: number, name: string }[]>([])
  const [firstShow, setFirstShow] = useState(true)
  const [footer, setFooter] = useState<JSX.Element>()
  const [form] = Form.useForm<any>();
  const  textRef = useRef<TextAreaRef>(null)
  // const [imgUrl, setImgUrl] = useState('')
  // const [evidenceImg, setEvidenceImg] = useState<UploadImg>({
  //     file: undefined,
  //     imgUrl:''
  //   })

    // const onChangeEvidenceUrl = (img: UploadImg) =>{
    //   console.log(img)
    //     setEvidenceImg(img)
    //     if(img.file) {
    //       let urlKey = getFileName(CosDirectoryType.reportImage, img.file.name)
    //         getFileUrlByCosUpload(
    //           img.file!,
    //             urlKey
    //         ).then(res=>{
    //           setImgUrl(res.url)
    //         })
    //     }
        
    // }

  const onCancel = () => {
    props.close();
  };


  const onClickSubmit = async () => {
    setConfirmLoading(true);

    form.validateFields().then(async res => {
      let evidenceImg = ''
      if(res.evidenceImg&&res.evidenceImg.file) {
        let urlKey = getFileName(CosDirectoryType.reportImage, res.evidenceImg.file.name)
       let url =  await getFileUrlByCosUpload(
          res.evidenceImg.file!,
            urlKey
        )
        evidenceImg = url.url
      }

      let params = {
        ...props.params,
        type: Number(radioValue),
        typeName: radioName,
        description: res.description,
        proofImage: evidenceImg
      }
    
    postReport(params).then(() => {
      message.success('举报成功')
    }).finally(() => {
      props.close()
      setConfirmLoading(false);
    })
  })

  }

  const onClickBack = () => {
    setFirstShow(true)
    setSecondReasonList([])
    setRadioValue(undefined)
    setRadioName('')
  }

  useEffect(() => {
    let footerNode = null
    if (firstShow) {
      footerNode = <div className="report-foot-wrapper" >
        <Button disabled={!radioValue} type="primary" onClick={onClickSubmit}>确认</Button>
        <Button onClick={onCancel}>取消</Button>
      </div>
    } else {
      footerNode = <div className="report-foot-wrapper" >
        <Button disabled={!radioValue} type="primary" onClick={onClickSubmit}>确认</Button>
        <Button onClick={onClickBack}>返回</Button>
      </div>
    }

    setFooter(footerNode)
  }, [firstShow, radioValue])

  useEffect(() => {
    getReportResonList().then(res => {
      setReasonList(res)
      setFirstShow(true)
    })
  }, [])

  const onClickChoose = (item: ReasonItem) => {
    setRadioValue(item.id)
    setRadioName(item.name)
    if (item.children && item.children.length > 0) {
      setSecondReasonList(item.children)
      setFirstShow(false)
      setRadioValue(undefined)
      setRadioName('')
    }
  }


  return <ReportModalStyle>

    <div className="container">
      <Modal
        title="举报"
        open={props.show}
        cancelText="取消"
        onCancel={props.close}
        okText="保存"
        onOk={onClickSubmit}
        confirmLoading={confirmLoading}
        width={400}
        maskClosable={false}
        footer={footer}
        getContainer={false}
        className="report-modal-container"
      >
        <Spin spinning={confirmLoading}>
          <Radio.Group value={radioValue}>
            {firstShow && <Space direction="vertical">
              {reasonList.map(item => <div onClick={() => onClickChoose(item)} className="reason-item-wrapper" key={item.id}><Radio className="report-radio" value={item.id}>{item.name}</Radio>
                {item.children && item.children.length > 0 && <RiArrowRightSLine style={{ fontSize: '20px' }}></RiArrowRightSLine>}
              </div>)}
            </Space>
            }
            {!firstShow && <Space direction="vertical">
              {secondReasonList.map(item => <div onClick={() => onClickChoose(item)} className="reason-item-wrapper" key={item.id}><Radio value={item.id}>{item.name}</Radio></div>)}
            </Space>
            }
          </Radio.Group>
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            requiredMark={true}
            className="form-area"
            size="large"
            form={form}
          >
            <div className="form-section">
            <Form.Item
              label="举报描述"
              name="description"
            >
          <TextArea  autoSize={{ minRows: 2, maxRows: 6 }} ref={textRef} placeholder="请输入举报描述"/>
          </Form.Item>
          <Form.Item
              label="举报证据"
              name="evidenceImg"
            >
          <ImageUpload   />
          </Form.Item>
          </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  </ReportModalStyle>

}