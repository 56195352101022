import styled from "styled-components";

export const MyRewardStyle = styled.div`
    .list {

        &-title {
            padding: ${props => props.theme.space.padding}px 0 ${props => props.theme.space.padding}px 20px;
            display: flex;
            cursor: pointer;

            span {
                padding: 0 5px;
            }

            .link {
                color: #808080;
                cursor: pointer;
                &:hover {
                    color: #FF9A16;
                }
            }
        }

        &-item {
            border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
            padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
            min-height: 44px;
            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 35px;
                &-word {
                    min-width: 0;
                    flex: 1;
                    font-size: 18px;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }
                .status {
                    display: flex;
                    color: #FF9A16;
                    gap: 10rpx;
                    align-items: center;
                    flex: 0 0 auto;
                    font-size: 15px;
                }
            }

            .desc {
                color: #808080;
                display: flex;
                margin: 10px 0;
                justify-content: space-between;
                .desc-left {
                    min-width: 0;
                }

                .desc-right {
                    cursor: pointer;
                }
            }
            .bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price {
                    display: flex;
                    color: #FF9A16;
                    gap: 10rpx;
                    align-items: center;
                    font-size: 15px;
                    gap: 5px;
                    &-icon {
                        background: url(https://cdn-prd.ggac.com/ggac/reward/reward-price.png) center center no-repeat;
                        background-size: 100% 100%;
                        width: 15px;
                        height: 15px;
                    }
                }
                .btns {
                    display: flex;
                    gap: 10px;
                    div {
                        font-size: 12px;
                        color: #000;
                        background: #ffc712;
                        border-radius: 50px;
                        line-height: 25px;
                        width: 68px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
            
        }
    }
`