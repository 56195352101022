import { SignupFieldOptions } from "types/sub/application"
import { CateSelectStyle } from "./style"
import { useEffect, useState } from "react"
type SelectItem = {
    label: number[], category: number[]
}

type SelectObj = {
    label: number, category: number
}
export const SubSecondSelect = (props: { value?: SelectItem, onChange?: (val: SelectItem) => void, multiple?: boolean, data?: SignupFieldOptions[] }) => {
    const [choosedList, setChoosedList] = useState<SelectObj[]>([])
    const finalValue: SelectItem = {
        label: [],
        category: []
    }

    const onClickItem = (firstId: number, item: { //类目列表
        optionId: number //选项ID
        categoryName: string //类目名称
        categoryId: number //类目ID
    }) => {
        if (props.multiple) {
            let arr: SelectObj[] = []
            let index = choosedList.findIndex(sub => {
                return sub.label === firstId && sub.category === item.categoryId
            })
            if (index >= 0) {
                choosedList.splice(index, 1)
                arr = choosedList.concat([])
            } else {
                arr = choosedList.concat([{ label: firstId, category: item.categoryId }])
            }
            arr.forEach(sub => {
                finalValue.label.push(sub.label)
                finalValue.category.push(sub.category)
            })
            finalValue.label = Array.from(new Set(finalValue.label))
            finalValue.category = Array.from(new Set(finalValue.category))
            props.onChange!(finalValue)
            setChoosedList(arr)
        } else {
            setChoosedList([{ label: firstId, category: item.categoryId }])
            props.onChange!({
                label: [firstId],
                category: [item.categoryId]
            })
        }
    }

    useEffect(() => {
        // setChoosedList(props.value ?? [])
    }, [props.value])

    return <CateSelectStyle>
        <div className="second-select-container">
            {props.data?.map(first =>
                <div className="item-wrapper" key={first.id}>
                    <div className="first-wrapper">{first.name}</div>
                    <div className="second-wrapper">
                        {first.categories?.map(second =>
                            <div key={second.categoryId}
                                className={choosedList?.findIndex(item => first.id === item.label && item.category === second.categoryId) >= 0 ? 'second-wrapper-item active' : 'second-wrapper-item'}
                                onClick={() => onClickItem(first.id, second)}>
                                {second.categoryName}
                            </div>)}
                    </div>
                </div>)}
        </div>
    </CateSelectStyle>
}