import { Modal } from "antd"
import CouponCard from "components/coupon-card"
import { CouponDrawItem } from "types/user"
import { CouponModalStyle, CouponModalWrapStyle } from "./style"
import { formatMoney } from "views/user-shop/productManage/create/fn"

type Props={
    isModalOpen: boolean
    handleCancel: () => void
    list:CouponDrawItem[]
}
function CouponModal({isModalOpen,handleCancel,list}:Props) {
  return (
    <CouponModalWrapStyle >
    <Modal  open={isModalOpen}  onCancel={handleCancel} 
    footer={null}
    closeIcon={null}
    className="coupon-modal"
    >
        <CouponModalStyle>
          <div className="close" onClick={handleCancel}>
            <img src="https://cdn-prd.ggac.com/ggac/assets/image/shop/packet-close.png" alt="" />
          </div>
          <div className="headline"></div>
          <div className="list">
            {list && list.map((item,index)=>{
              return  <div className="list-item" key={index}>
                 <div className="card-left">
        <div className="money">
          <span className="sign">¥</span>
          <span className="amount">{formatMoney(item.amount)}</span>
        </div>
       
      </div>
      <div className="card-right">
          <div className="a">
          <div className="tip">满{formatMoney(item.limitAmount)}元可用</div>
          </div>
          <div className="b" onClick={()=>{window.open('https://www.ggac.com/mall/market')}}>
              去使用
          </div>
      </div>
              </div>
            })}
          </div>
          <div className="bottom">
            <div className="click-area" onClick={()=>{window.open('https://www.ggac.com/mall/market')}}></div>
            <a href="https://www.ggac.com/official/2561" target="_blank">查看活动详情</a>
          </div>
        </CouponModalStyle>


     
    </Modal>
    </CouponModalWrapStyle>
  )
}

export default CouponModal