import { Button, Form, Input, Modal, message } from "antd";
import {  apiRefundApplyAdmin } from "api/shop";
import { useState } from "react";
import { OrderListResponse } from "types/product";
import { ProductPicturesWall } from "views/user-shop/productManage/uploadImageList";
const { TextArea } = Input;

type Props = {
  visible: boolean;
  onSubmit: () => void;
  onClose: () => void;
  detail: OrderListResponse;
};

function MediationApplyModal({ visible, onSubmit, onClose, detail }: Props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    
    form.validateFields().then((values)=>{
      const {applyAdminImage,applyAdminReason} = values;
      setLoading(true);
      apiRefundApplyAdmin({
        orderNumber: detail.orderNumber,
        applyAdminImage: applyAdminImage?applyAdminImage[0].url:undefined,
        applyAdminReason:applyAdminReason
      })
        .then(() => {
          message.success("申请成功");
          onSubmit();
          onClose();
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    })

   
  };

  return (
    <div>
      <Modal
        className="modal"
        title="申请平台介入"
        onCancel={onClose}
        centered={true}
        footer={null}
        getContainer={false}
        open={visible}
        destroyOnClose
      >
        <div className="container" style={{marginTop:24}}>
          <Form form={form} name="MediationApply" preserve={false}  layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="applyAdminReason"
              label="情况描述"
              rules={[
                {
                    required: true,
                    message: '请填写平台介入退款情况描述',
                }
            ]}
            >
              <TextArea
                placeholder="请填写平台介入退款情况描述"
              />
            </Form.Item>
         
          <Form.Item
              name="applyAdminImage"
              label="上传图片"
            >
             <ProductPicturesWall maxLength={1} />
            </Form.Item>
            </Form>
          <div className="btn" style={{ marginTop: "24px" }}>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              提交
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MediationApplyModal;
