import { get } from "api"
import { PaginationResponse } from "types/common/axios"
import { NotificationItemCount, NotificationListItem, NotificationListRequest, OfficialArticleDetail, OfficialArticleItem, OfficialArticleListRequest } from "types/notification"

export const apiGetNotificationCount = () => {
    return get<never, { count: number }>('/api/notification/count')
}

export const apiGetNotificationList = (params: NotificationListRequest) => {
    return get<NotificationListRequest, PaginationResponse<NotificationListItem>>('/api/notification/list', params)
}

export const apiGetOfficialArticleList = (params: OfficialArticleListRequest) => {
    return get<OfficialArticleListRequest, PaginationResponse<OfficialArticleItem>>('/api/official_article/list', params)
}

export const apiGetOfficialDetail = (id: number) => {
    return get<{ id: number }, OfficialArticleDetail>(`/api/official_article/detail/${id}`)
}

// 获取未读消息列表
export const apiNotificationItemCount = () => {
    return get<{}, NotificationItemCount>(`/api/notification/item_count`)
}

// 全部标注为已读
export const postReadAll = () => {
    return get<{}, {}>(`/api/notification/read_all`)
}

// 单条标注为已读
export const postReadSingle = (id: number) => {
    return get<{}, {}>(`/api/notification/read_item/${id}`)
}
