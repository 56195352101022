import styled from "styled-components";

export const ShopAlbumStyle = styled.div`
    

    .cover {
        aspect-ratio: 266 / 191;
    }

    .btn {
        display: flex;
        justify-content: flex-end;
        margin: ${props => props.theme.space.margin - 4}px 0 ${props => props.theme.space.margin}px;
        button{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`