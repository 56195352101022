import { WorkListItem } from "types/work";
import { HomePageWorkStatusStyle } from "./style";

function homePageWorkStatus({ item }: { item: WorkListItem }) {
  return (
    <HomePageWorkStatusStyle>
        {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
      {item.isContest === 1 && item.publishType === 1 && (
        <div className="status">阶段性作品</div>
      )}
     
    
    </HomePageWorkStatusStyle>
  );
}

export default homePageWorkStatus;
