import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { RiWechatFill, RiMessage2Fill, RiQqFill, RiArrowLeftSLine, RiMailFill } from "react-icons/ri";
import RegisteForm from "./registeForm";
import { WexinLogin } from './weixin-login'
import { QQLogin } from "./qq-login";
import RegisteEmailForm from "./registeEmailForm";

function Register() {
  const navigate = useNavigate();
  //1 手机 2 微信  4 qq
  const [currentRegisteMethod, setCurrentRegisteMethod] = useState(1);
  let [searchParams, setSearchParams] = useSearchParams();
  const stage = searchParams.get("stage");
  const redirect = new URLSearchParams(window.location.search).get(
    "redirect"
  );

  const onClickRegisterByQQ = () => {
    const channelCode = searchParams.get('channelCode')
    if (channelCode) {
      localStorage.setItem('channelCode', channelCode)
    }
    // setSearchParams('');
    searchParams.delete('stage')
    let url
    if (redirect) {
      url = encodeURIComponent(`https://www.ggac.com/auth/login?stage=qq&redirect=${redirect}`)
    } else {
      url = encodeURIComponent("https://www.ggac.com/auth/login?stage=qq")
    }
    window.location.href = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=101955030&redirect_uri=${url}&state=989735`
  }
  useEffect(() => {
    if (stage === 'weixin') {
      setCurrentRegisteMethod(2)
    } else {
      if (stage === 'qq') {
        setCurrentRegisteMethod(4)
      }
    }
  }, [stage])

  const toLogin = () => {
    navigate(`/auth/login?redirect=${redirect}`)
  }

  const handleWeixinClick = () => {
    const channelCode = searchParams.get('channelCode')
    if (channelCode) {
      localStorage.setItem('channelCode', channelCode)
    }
    // setSearchParams('');
    searchParams.delete('stage')
    setCurrentRegisteMethod(2)
  }
  return (
    <div className="formContainer full">
      <div className="meta">
        <span className="back" onClick={() => navigate('/')}><RiArrowLeftSLine></RiArrowLeftSLine> 返回首页</span>  已有账号？立即
        <span className="em" onClick={toLogin}>
          登录
        </span>
      </div>
      <div className="left wide">
        <div className="title">创建GGAC账号</div>
        {currentRegisteMethod === 1 && <RegisteForm></RegisteForm>}
        {currentRegisteMethod === 2 && <WexinLogin></WexinLogin>}
        {currentRegisteMethod === 4 && <QQLogin></QQLogin>}
        {currentRegisteMethod === 5 && <RegisteEmailForm />}

        <div className="divider div-transparent">
          <span className="text">更多注册方式</span>
        </div>
        {currentRegisteMethod !== 2 && (
          <div className="bar" onClick={() => { handleWeixinClick() }}>
            <RiWechatFill className="icon" size={20} /> <span>通过微信注册</span>{" "}
          </div>
        )}

        {currentRegisteMethod !== 4 && (
          <div className="bar" onClick={() => { onClickRegisterByQQ() }}>
            <RiQqFill className="icon" size={20} /> <span>通过QQ注册</span>{" "}
          </div>
        )}

        {currentRegisteMethod !== 1 && (
          <div className="bar" onClick={() => { searchParams.delete('stage'); setCurrentRegisteMethod(1) }}>
            <RiMessage2Fill className="icon" size={18} />
            <span>通过手机注册</span>
          </div>
        )}
        {currentRegisteMethod !== 5 && (
          <div className="bar" onClick={() => { searchParams.delete('stage'); setCurrentRegisteMethod(5) }}>
            <RiMailFill className="icon" size={18} />
            <span>通过邮箱注册</span>
          </div>
        )}
      </div>
    </div>

  );
}

export default Register;
