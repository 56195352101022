import styled from "styled-components";

export const SubMatchStyle = styled.div`
  background-color: ${(props) => props.theme.colors.colorBgLayout};
  padding-top: 50px;
  .form-section {
    background-color: ${(props) => props.theme.colors.colorBgContainer};
    padding: ${(props) => props.theme.space.paddingLG}px
      ${(props) => props.theme.space.paddingLG}px
      ${(props) => props.theme.space.paddingSM}px
      ${(props) => props.theme.space.paddingLG}px;
    margin-bottom: ${(props) => props.theme.space.paddingLG}px;
    border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
    .title {
      padding-bottom: 10px;
      display: block;
      font-weight: 600;
    }
  }
  .sub-match-container {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    .form-wrapper {
      position: relative;
      width: 880px;
      padding: ${(props) => props.theme.space.paddingLG}px;
      .submit-btn {
        position: relative;
        width: 100%;
        background: ${(props) => props.theme.colors.colorPrimary};
      }
    }
    .flex-test {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .item {
        margin: 10px 0;
        width: calc(20% - calc(5px * 2));
        height: 150px;
        background: pink;
      }
    }
    .flex-test::after {
      content: "";
      flex: auto;
    }
  }
`;
