import styled from "styled-components";
import { dynamicColumns } from "utils/functions/common";

export const UserFansListStyle = styled.div`
    .card-list {
        width: 100%;
        --auto-grid-min-size: 18rem;
        grid-template-columns: repeat(${dynamicColumns()},  minmax(var(--auto-grid-min-size), 1fr));
        gap: 1rem;
    }
`
