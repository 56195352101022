import { useEffect, useState } from "react";
import { SmallBannerStyle } from "./style";
import { CarouselListItem } from "types/common/carousel";
import { getSmallBannerList, sendCarouselLog } from "api/common/carousel";
import { CoverImageObjectCloudResize } from "utils/functions/file";
import useWindowSize from "hooks/useWindowSize";

function SmallBanner() {
  const [bannerList, setBannerList] = useState<CarouselListItem[]>([]);
  const [dataList, setDataList] = useState<CarouselListItem[]>([]);
  const [screenSize] = useWindowSize();
  
  useEffect(() => {
    getSmallBannerList({typeList:[12,13,14,15,16,17,23,24],lang:1}).then((res) => {
      setDataList(res)
      resizeCalc(res)
    });
  }, []);

  useEffect(() => {
    resizeCalc(dataList);
  },[screenSize])


  const resizeCalc = (dataList:CarouselListItem[])=>{
    isWideScreeen()?setBannerList(dataList): setBannerList(dataList.slice(0, -2))
  }

  const isWideScreeen = ()=>{
    if((screenSize.dynamicWidth/screenSize.dynamicHeight) > 16/9 && screenSize.dynamicWidth> 1920){
      return true
    }else{
      return false
    }
  }


  return (
    <SmallBannerStyle isWideScreen={isWideScreeen()}>
      <div className="container_">
        <div className="wrap">
          <div className="card-list">
            {bannerList &&
              bannerList.map((item,index) => {
                return (
                  <a href={item?.webUrl} key={index} target="_blank" rel="noreferrer" onClick={()=> sendCarouselLog({carouselId:item.id})}>
                      <div
                        className="banner"
                        style={{ backgroundImage: `url(${CoverImageObjectCloudResize(item?.displayImage,500)})` }}
                    ></div>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </SmallBannerStyle>
  );
}

export default SmallBanner;
