import { useNavigate, useParams } from 'react-router-dom'
import { NftAlbumDetailStyle } from './style'
import { useEffect, useMemo, useState } from 'react'
import {
  apiGetNftDetail,
  apiGetNftShareImage,
  apiGetCertificateShareImage,
} from 'api/nft-album'
import { NftDetailResponse } from 'types/nft-album'
import UserCardGlobal from 'components/userBlock'
import { RiFileCopy2Line } from 'react-icons/ri'
import copy from 'copy-to-clipboard'
import { Button, Divider, Popover, message } from 'antd'

const NftAlbumDetail = () => {
  const id = useParams().id

  const navigate = useNavigate()

  const [detail, setDetail] = useState<NftDetailResponse>()

  const [isLoading, setIsLoading] = useState(false)

  const [isShareLoading, setIsShareLoading] = useState(false)

  useEffect(() => {
    apiGetNftDetail(Number(id)).then((res) => {
      setDetail(res)
    })
  }, [id])

  const copyText = (value: string) => {
    copy(value)
    message.success('复制成功')
  }

  const createCertificate = () => {
    setIsLoading(true)
    apiGetCertificateShareImage(Number(detail?.id))
      .then((res) => {
        window.open(res)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const createShareImage = () => {
    setIsShareLoading(true)
    apiGetNftShareImage(Number(detail?.productId))
      .then((res) => {
        window.open(res)
      })
      .finally(() => {
        setIsShareLoading(false)
      })
  }

  const newPrivileges = useMemo(() => {
    if (!detail?.privileges || detail.privileges.length === 0) {
      return []
    }

    const result = detail?.privileges.filter((item) => {
      return item.code !== 'BZ' && item.code !== 'GQ' && item.code !== 'PSD'
    })

    result.forEach((item) => {
      if (item.code === 'TX') {
        item.name = '头像'
      }
    })

    return result
  }, [detail])

  const content = (
    <div
      className="btn-content"
      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
    >
      {newPrivileges.length > 0 &&
        newPrivileges.map((item) => {
          return (
            <Button onClick={() => window.open(item.url)}>{item.name}</Button>
          )
        })}
      {detail?.downloadUrl && (
        <Button onClick={() => window.open(detail.downloadUrl)}>
          下载原图
        </Button>
      )}
      {detail?.wallpaperUrl && (
        <Button onClick={() => window.open(detail.wallpaperUrl)}>
          下载壁纸
        </Button>
      )}

      {detail?.psdUrl && (
        <Button onClick={() => window.open(detail.psdUrl)}>下载psd</Button>
      )}
    </div>
  )

  return (
    <NftAlbumDetailStyle>
      <div className="album">
        {detail && (
          <div className="album-inner">
            <div className="top">
              <div className="name">{detail.name}</div>
              <div className="tag">
                {detail.state === 2 && (
                  <div className="nft-count">
                    {`${detail.seriesIndex}/${detail.seriesTotalCount === 0
                      ? '∞'
                      : detail.seriesTotalCount
                      }`}
                  </div>
                )}
                {detail.state === 1 && <div className="nft-count">生成中</div>}

                <div className="chain-wrap">
                  <div className="chain">
                    {detail.chainType === 1 ? '至信链' : '文昌链'}
                  </div>
                </div>
              </div>
            </div>
            <div className="album-content">
              <div className="left">
                <div className="nft-title">图鉴卡描述</div>
                {detail.customDescription && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: detail.customDescription,
                    }}
                  ></div>
                )}
                <div className="image">
                  {detail.descImageListMyNftDetail &&
                    detail.descImageListMyNftDetail.map((item) => {
                      return (
                        <img src={item} key={item} className="image-item"></img>
                      )
                    })}
                </div>
              </div>
              <div className="right">
                <UserCardGlobal userId={detail?.userId} />
                {detail.privileges && (
                  <>
                    <Divider plain>可体验权益</Divider>
                    <div className="privileges">
                      {detail.privileges.map((item) => {
                        return (
                          <div
                            className="privileges-item"
                            style={{
                              width: `calc(${100 / detail.privileges.length}%)`,
                            }}
                          >
                            {
                              item.code === 'TX' && <img src="https://cdn-prd.ggac.com/ggac/icon/nft-default-icon3.png" />
                            }
                            {
                              item.code === 'PSD' && <img src="https://cdn-prd.ggac.com/ggac/icon/nft-default-icon2.png" />
                            }
                            {
                              item.code === 'BZ' && <img src="https://cdn-prd.ggac.com/ggac/icon/nft-default-icon.png" />
                            }
                            {
                              !(['PSD', 'TX', 'BZ'].includes(item.code)) && <img src="https://cdn-prd.ggac.com/ggac/icon/nft-default-icon3.png" />
                            }

                            <div className="privileges-name">{item.name}</div>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}

                <div className="info">
                  <div className="item">
                    <div className="label">收藏者</div>
                    <div className="value">{detail.ownerName}</div>
                  </div>
                  {detail.ownerGainedTime && (
                    <div className="item">
                      <div className="label">获得时间</div>
                      {detail.state === 2 && (
                        <div className="value">{detail.ownerGainedTime}</div>
                      )}
                      {detail.state !== 2 && (
                        <div className="value">生成中</div>
                      )}
                    </div>
                  )}

                  <div className="item">
                    <div className="label">区块链</div>
                    {detail.chainType && (
                      <div className="value">
                        {detail.chainType === 1 ? '至信链' : '文昌链'}
                      </div>
                    )}
                  </div>
                  <div className="item">
                    <div className="label">唯一编号</div>
                    {detail.state === 2 && (
                      <div className="value">
                        <div>{detail.uid}</div>{' '}
                        <RiFileCopy2Line onClick={() => copyText(detail.uid)} />
                      </div>
                    )}
                    {detail.state !== 2 && <div className="value">生成中</div>}
                  </div>

                  <div className="item">
                    <div className="label">作品Hash</div>
                    {detail.state === 2 && (
                      <div className="value">
                        <div>{detail.hash}</div>{' '}
                        <RiFileCopy2Line
                          onClick={() => copyText(detail.hash)}
                        />
                      </div>
                    )}
                    {detail.state !== 2 && <div className="value">生成中</div>}
                  </div>

                  <div className="item">
                    <div className="label">交易Hash</div>
                    {detail.state === 2 && (
                      <div className="value">
                        <div>{detail.lastTxHash}</div>{' '}
                        <RiFileCopy2Line
                          onClick={() => copyText(detail.lastTxHash)}
                        />
                      </div>
                    )}
                    {detail.state !== 2 && <div className="value">生成中</div>}
                  </div>
                </div>

                <div className="btn-wrap">
                  <div className="btns">
                    <Button
                      className="btn"
                      loading={isLoading}
                      onClick={createCertificate}
                    >
                      查看证书
                    </Button>
                    <Popover placement="bottom" content={content}>
                      <Button className="btn">下载</Button>
                    </Popover>

                    {detail.planType === 1 && (
                      <Button
                        className="btn share-btn"
                        loading={isShareLoading}
                        onClick={createShareImage}
                      >
                        分享
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </NftAlbumDetailStyle>
  )
}

export default NftAlbumDetail
