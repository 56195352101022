import { Checkbox } from "antd"
import { WorkFilterStyle } from "./style"
import { useEffect, useState } from "react"
import { ContestDetailResponse, WorkContestListRequest } from "types/contest"
import { apiGetSignUpField } from "api/sub";
import { SignupField, SignupFieldOptions } from "types/sub/application";
import { queryUrl } from "utils/functions/common";
import { useLocation, useNavigate } from "react-router";
import { apiGetContestTagList } from "api/contest";

const ContestWorkFilter = ({ subDetail, showVoteFilter }: { updateValue: (value: WorkContestListRequest) => void, subDetail: ContestDetailResponse, showVoteFilter?: boolean }) => {
    const [teamList, setTeamList] = useState<SignupFieldOptions[]>([])
    const [mediaCategoryList, setMediaCategoryList] = useState<SignupFieldOptions[]>([])
    const [themeList, setThemeList] = useState<SignupFieldOptions[]>([])
    const [categoryList, setCategoryList] = useState<SignupFieldOptions[]>([])
    const [themeAList, setThemeAList] = useState<SignupFieldOptions[]>([])
    const pathname = window.location.pathname
    const navigate = useNavigate()
    const [hasSecondOption, setHasSecondOption] = useState(false)
    const mediaCategoryId = new URLSearchParams(useLocation().search).get("mediaCategoryId")
    const teamId = new URLSearchParams(useLocation().search).get("teamId")
    const themeId = new URLSearchParams(useLocation().search).get("themeId")
    const theme2Id = new URLSearchParams(useLocation().search).get("theme2Id")
    const sortField = new URLSearchParams(useLocation().search).get("sortField") || 'lastSubmitTime'
    let categoryId = new URLSearchParams(useLocation().search).get("categoryId")
    let tagId = new URLSearchParams(useLocation().search).get("tagId")
    let themeA = new URLSearchParams(useLocation().search).get("themeA")
    let publishType = new URLSearchParams(useLocation().search).get("publishType") || ''
    const [checked, setCheckout] = useState("")
    const [isShow, setIsShow] = useState(false)
    const [detail, setDetail] = useState<SignupField[]>([])
    const detailId = subDetail?.id
    const [tagList, setTagList] = useState<{ name: string, id: number }[]>([])

    useEffect(() => {
        setCheckout(publishType)
    }, [publishType])

    const getContestTypeList = () => {
        apiGetSignUpField(detailId).then(res => {
            const team = res.find(item => {
                return item.code === 'team'
            })
            setTeamList(team?.options! || [])
            const mediaCategory = res.find(item => {
                return item.code === 'mediaCategory'
            })
            setMediaCategoryList(mediaCategory?.options! || [])
            const theme = res.find(item => {
                return item.code === 'theme'
            })
            const category = res.find(item => {
                return item.code === 'category'
            })
            const themeA = res.find(item => {
                return item.code === 'themeA'
            })
            setThemeAList(themeA?.options || [])
            setThemeList(theme?.options! || [])
            setHasSecondOption(!!theme?.hasSecondOption)
            setDetail(res)
            setCategoryList(category?.options || [])
        })
    }

    useEffect(() => {
        if (!detailId) {
            return
        }
        getContestTypeList()
    }, [detailId])


    useEffect(() => {
        if (subDetail?.code === 'ydbz') {
            apiGetContestTagList(subDetail.id).then(res => {
                setTagList(res)
            })
        }

    }, [subDetail?.code])

    const handleChangeCategory = (pid: number, id: number, type: string) => {
        let query
        if (type === 'mediaCategory') {
            query = queryUrl({ mediaCategoryId: pid, categoryId: id, pageNumber: 1 })
        } else if (type === 'theme') {
            query = queryUrl({ themeId: pid, categoryId: id, pageNumber: 1 })
        } else if (type === 'team') {
            query = queryUrl({ teamId: pid, categoryId: id, pageNumber: 1 })
        } else if (type === 'theme2') {
            query = queryUrl({ themeId: pid, theme2Id: id, pageNumber: 1 })
        }


        navigate(`${pathname}?${query}`)
    }

    const handleChangeSort = (value: string) => {
        const query = queryUrl({ sortField: value, pageNumber: 1 })
        navigate(`${pathname}?${query}`)
    }

    const isHasCategory = (value: string) => {
        const result = detail.find(item => item.code === value)
        const canClearCategoryId = result?.hasSecondOption || result?.hasCategory
        return !!canClearCategoryId
    }


    const handleChange = (value: number, type: string) => {
        let query
        if (type === 'team') {
            const result = detail.find((item) => item.code === 'team')
            const canClearCategoryId = result?.hasSecondOption || result?.hasCategory
            if (value === 0) {
                query = queryUrl({ teamId: '', categoryId: canClearCategoryId ? "" : categoryId, pageNumber: 1 })
            } else {
                query = queryUrl({ teamId: value, categoryId: canClearCategoryId ? "" : categoryId, pageNumber: 1 })
            }
        } else if (type === 'mediaCategory') {
            const result = detail.find((item) => item.code === 'mediaCategory')
            const canClearCategoryId = result?.hasSecondOption || result?.hasCategory
            if (value === 0) {
                query = queryUrl({ mediaCategoryId: '', categoryId: canClearCategoryId ? "" : categoryId, pageNumber: 1 })
            } else {
                query = queryUrl({ mediaCategoryId: value, categoryId: canClearCategoryId ? "" : categoryId, pageNumber: 1 })
            }
        } else if (type === 'theme') {
            const result = detail.find((item) => item.code === 'theme')
            const canClearCategoryId = result?.hasSecondOption || result?.hasCategory
            if (value === 0) {
                query = queryUrl({ themeId: '', theme2Id: canClearCategoryId ? "" : theme2Id, categoryId: canClearCategoryId ? "" : categoryId, pageNumber: 1 })
            } else {
                query = queryUrl({ themeId: value, theme2Id: canClearCategoryId ? "" : theme2Id, categoryId: canClearCategoryId ? "" : categoryId, pageNumber: 1 })
            }
        } else if (type === 'category') {
            if (value === 0) {
                query = queryUrl({ categoryId: '', pageNumber: 1 })
            } else {
                query = queryUrl({ categoryId: value, pageNumber: 1 })
            }
        } else if (type === 'tagId') {
            query = queryUrl({ tagId: value, pageNumber: 1 })
        } else if (type === 'themeA') {
            query = queryUrl({ themeA: value, pageNumber: 1 })
        }
        navigate(`${pathname}?${query}`)
    }
    let list = [
        // {
        //     label: "全部",
        //     value: ""
        // },
        {
            label: "最新",
            value: "lastSubmitTime"
        },
        {
            label: "最热",
            value: "threeDaysHot"
        },

    ]

    if (showVoteFilter) {
        list = [...list, {
            label: "票数",
            value: "ticketCount"
        }]
    }

    const onCheckoutChange = (e: boolean) => {
        const query = queryUrl({ publishType: e ? '2' : '', pageNumber: 1 })
        setCheckout(e ? '2' : '')
        navigate(`${pathname}?${query}`)
    }

    const isShowFilter = () => {
        setIsShow(!isShow)
    }

    return <WorkFilterStyle>
        <div className="type-list">
            <div className="type-list-item">
                <div className="type-wrap">
                    <div className="type">
                        {
                            list.map(item => {
                                return <div key={item.value} className={`type-item ${item.value === sortField ? 'active' : ''}`} onClick={() => handleChangeSort(item.value)}>
                                    <span>{item.label}</span>
                                </div>
                            })
                        }
                        <div className="type-item">
                            <Checkbox checked={checked === '2' ? true : false} onChange={(e) => onCheckoutChange(e.target.checked)}>
                                只看完成作品
                            </Checkbox>
                        </div>
                    </div>
                    {
                        !["ydbz", "ly3d", "xrtz"].includes(subDetail?.code) && <div className="right" onClick={isShowFilter}>更多筛选</div>
                    }
                </div>
            </div>
            <div className="type-list2" style={{ display: isShow ? 'block' : "none" }}>
                {
                    themeList.length > 0 && <div className="type-list-item">
                        <div className="title">{subDetail?.code === 'ggac6' ? "GGAC创作奖" : "主题"}</div>
                        <div className="type fwb">
                            <div className={`type-item ${!themeId ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'theme')}><span>全部</span></div>
                            <div style={{ display: themeList.some(item => item.categories && item.categories.length > 0) || themeList.some(item => item.children && item.children.length > 0) ? 'block' : 'flex' }}>
                                {
                                    themeList.map(item => {
                                        return <div key={item.id} className={`type-item ${((item.id === Number(themeId) && (!categoryId && !theme2Id))) || (item.id === Number(themeId) && !isHasCategory("theme")) ? 'active' : ''}`}>
                                            <span onClick={() => handleChange(item.id, 'theme')}>{item.name}</span>
                                            <div className="second-type">
                                                {
                                                    !hasSecondOption && item.categories && item.categories.length > 0 && item.categories.map(sub => {
                                                        return <div key={sub.categoryId} onClick={() => handleChangeCategory(item.id, sub.categoryId, "theme")} className={`second-type-item ${sub.categoryId === Number(categoryId) && item.id === Number(themeId) ? 'second-active' : ''}`}>{sub.categoryName}</div>
                                                    })
                                                }
                                                {
                                                    hasSecondOption && item.children && item.children.length > 0 && item.children.map(sub => {
                                                        return <div key={sub.id} onClick={() => handleChangeCategory(item.id, sub.id, 'theme2')} className={`second-type-item ${sub.id === Number(theme2Id) && item.id === Number(themeId) ? 'second-active' : ''}`}>{sub.name}</div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    teamList.length > 0 && <div className="type-list-item">
                        <div className="title">组别</div>
                        <div className="type">
                            <div className={`type-item ${!teamId ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'team')}><span>全部</span></div>
                            <div style={{ display: teamList.some(item => item.categories && item.categories.length > 0) ? 'block' : 'flex' }}>
                                {
                                    teamList.map(item => {
                                        return <div key={item.id} className={`type-item ${(item.id === Number(teamId) && !categoryId) || (item.id === Number(teamId) && !isHasCategory("team")) ? 'active' : ''}`}
                                        >
                                            <span onClick={() => handleChange(item.id, 'team')}>{item.name}</span>
                                            <div className="second-type">
                                                {
                                                    item.categories && item.categories.length > 0 && item.categories.map(sub => {
                                                        return <div key={sub.categoryId} onClick={() => handleChangeCategory(item.id, sub.categoryId, 'team')} className={`second-type-item ${sub.categoryId === Number(categoryId) && item.id === Number(teamId) ? 'second-active' : ''}`}>{sub.categoryName}</div>
                                                    })
                                                }
                                                {
                                                    item.children && item.children.length > 0 && item.children.map(sub => {
                                                        return <div key={sub.id} onClick={() => handleChangeCategory(item.id, sub.id, 'team')} className={`second-type-item ${sub.id === Number(categoryId) && item.id === Number(teamId) ? 'second-active' : ''}`}>{sub.name}</div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>

                        </div>
                    </div>
                }
                {
                    mediaCategoryList.length > 0 && detailId !== 36 && <div className="type-list-item">
                        <div className="title">创作类型</div>
                        <div className="type fwb">
                            <div className={`type-item ${!mediaCategoryId ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'mediaCategory')}><span>全部</span></div>
                            <div className={mediaCategoryList.some(item => item.categories && item.categories.length > 0) ? 'block' : 'flex'}>
                                {
                                    mediaCategoryList.map(item => {
                                        return <div key={item.id} className={`type-item ${(item.outerId === Number(mediaCategoryId) && !categoryId) || (item.outerId === Number(mediaCategoryId) && !isHasCategory("mediaCategory")) ? 'active' : ''}`}>
                                            <span onClick={() => handleChange(item.outerId, 'mediaCategory')}>{item.name}</span>
                                            <div className="second-type">
                                                {
                                                    item.categories && item.categories.length > 0 && item.categories.map(sub => {
                                                        return <div key={sub.categoryId} onClick={() => handleChangeCategory(item.outerId, sub.categoryId, "mediaCategory")} className={`second-type-item ${sub.categoryId === Number(categoryId) && item.outerId === Number(mediaCategoryId) ? 'second-active' : ''}`}>{sub.categoryName}</div>
                                                    })
                                                }
                                                {
                                                    item.children && item.children.length > 0 && item.children.map(sub => {
                                                        return <div key={sub.id} onClick={() => handleChangeCategory(item.outerId, sub.id, "mediaCategory")} className={`second-type-item ${sub.id === Number(categoryId) && item.outerId === Number(mediaCategoryId) ? 'second-active' : ''}`}>{sub.name}</div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    !["ydbz", "ggac6", "ly3d", "xrtz"].includes(subDetail?.code) && categoryList.length > 0 && <div className="type-list-item">
                        <div className="title">内容分类</div>
                        <div className="type fwb">
                            <div className={`type-item ${!categoryId ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'category')}><span>全部</span></div>
                            <div className={categoryList.some(item => item.categories && item.categories.length > 0) ? 'block' : 'flex'} style={{ gap: "20px" }}>
                                {
                                    categoryList.map(item => {
                                        return <div key={item.id} className={`type-item ${(item.id === Number(categoryId)) ? 'active' : ''}`}>
                                            <span onClick={() => handleChange(item.id, 'category')}>{item.name}</span>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    subDetail?.code === 'ggac6' && themeAList.length > 0 && <div className="type-list-item">
                        <div className="title">GGAC学院奖</div>
                        <div className="type">
                            <div className={`type-item  fwb ${!themeA ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'themeA')}><span>全部</span></div>
                            {
                                themeAList.map(item => {
                                    return <div key={item.id} className={`type-item ${(item.id === Number(themeA)) ? 'active' : ''}`}>
                                        <span onClick={() => handleChange(item.id, 'themeA')}>{item.name}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                }

                {/* {
                    subDetail?.code === 'ydbz' && tagList.length > 0 && <div className="type-list-item">
                        <div className="title" style={{ minWidth: "auto" }}>参与院校</div>
                        <div className="type" style={{ flexWrap: "wrap",width:`calc(100% - 80px )` }}>
                            <div className={`type-item  fwb ${!tagId ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'tagId')}><span>全部</span></div>
                                {
                                    tagList.map(item => {
                                        return <div key={item.id} className={`type-item ${(item.id === Number(tagId)) ? 'active' : ''}`}>
                                            <span onClick={() => handleChange(item.id, 'tagId')}>{item.name}</span>
                                        </div>
                                    })
                                }
                        </div>
                    </div>
                } */}

            </div>
        </div>
        {/* {
            teamList.length > 0 && <Select
                style={{ width: 120 }}
                defaultValue={Number(teamId) || null}
                onChange={(e) => handleChange(e, 'team')}
                className="select"
                options={teamList}
                fieldNames={{ label: 'name', value: 'outerId' }}
                placeholder="组别"
                allowClear={true}
            />
        }
        {
            mediaCategoryList.length > 0 && <Select
                style={{ width: 120 }}
                defaultValue={Number(mediaCategoryId) || null}
                onChange={(e) => handleChange(e, 'mediaCategory')}
                className="select"
                options={mediaCategoryList}
                fieldNames={{ label: 'name', value: 'outerId' }}
                placeholder="媒体分类"
                allowClear={true}
            />
        }
        {
            themeList.length > 0 && <Select
                style={{ width: 120 }}
                defaultValue={Number(themeId) || null}
                className="select"
                onChange={(e) => handleChange(e, 'theme')}
                options={themeList}
                fieldNames={{ label: 'name', value: 'id' }}
                placeholder="主题"
                allowClear={true}
            />
        }
        {
            categoryList.length > 0 && <Select
                style={{ width: 120 }}
                defaultValue={Number(categoryId)}
                className="select"
                onChange={(e) => handleChange(e, 'category')}
                options={categoryList}
                fieldNames={{ label: 'name', value: 'id' }}
                placeholder="类型"
                allowClear={true}
            />
        } */}
        {/* <Select
            defaultValue={sortField}
            style={{ width: 120 }}
            onChange={handleChange}
            className="select"
            options={list}
        /> */}
    </WorkFilterStyle>
}

export default ContestWorkFilter