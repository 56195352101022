import { WorkDraftItem } from "types/work"
import UniCardLayout from "../layout";
import CardTitleRender from "../common/card-title/work";
import { translateCardCategoryText } from "utils/functions/common";
import MediaIconsWork from "../common/media-icons/work";
import DraftWorkCardAction from "./draftWorkCardAction";
import DrafWorkCardStatus from "./drafWorkCardStatus";

function DraftWorkCard({item,shouldRefresh}:{item:WorkDraftItem,shouldRefresh:()=>void}) {
  const UiObject ={
    coverUrl:item.coverUrl,
    title:<CardTitleRender dataType={item.status === 0?'draft':'noLink'} item={item}/>,
    subtitle:<span> {item.dictMap.mediaCategory} | {translateCardCategoryText(item.categoryList)}</span>,
    date:item.updateTime, //最后一次保存时间
    centerNode:<DraftWorkCardAction item={item} shouldRefresh={shouldRefresh}></DraftWorkCardAction>,
    rightBottomNode:MediaIconsWork({item}),
    leftBottomNode:<DrafWorkCardStatus item={item}></DrafWorkCardStatus>,
    hot:item.hot,
    view:item.viewCount,
    isPublish: true,
    workLink: (item.status === 0 || item.status === 3 )? `/work/preview/${item.id}` : '',
  }
  
  
return UniCardLayout({item:UiObject});
}

export default DraftWorkCard