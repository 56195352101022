import { MenuItem } from "types/common";
import { SecondMenuStyle } from "./style";
import ThirdMeus from "./thirdMeus";
import { useState } from "react";
import { RiArrowDropRightFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { CoverImageObjectCloudResize } from "utils/functions/file";
type Props = {
  menus: MenuItem[];
  isOpen: boolean;
};

function SecondMeus({ menus, isOpen }: Props) {
  const navigate = useNavigate()
  const [currentHoverMenu, setCurrentHoverMenu] = useState<
    MenuItem | undefined
  >();
  //每个菜单都没有cover,CSS样式适配问题
  const flag = menus.every((menu) => !menu.coverUrl);
  const handleMenuClick = (menu: MenuItem)=>{
    if(menu.children.length===0){
      if( menu.isNewWeb===1){
        window.open(menu.url,"_blank"); 
      }else{
        if(menu.isHttp===1 ){
          window.open(menu.url,"_self"); 
        }else{
          navigate(menu.url)
        }
      }
    }else if(menu.customCode === '2024') {
      window.open(menu.url,"_blank"); 
    }
  }

  const textShouldCenter = (key:string)=>{
    return ['画历打卡','往期活动','全部活动'].includes(key)
  }


  return (
    <SecondMenuStyle>
      <div className="second-menu">
        {menus.map((menu, index) => (
          <div className="item-wrap"  key={index}>
          <div
            className={menu.coverUrl ? "second-item" : "second-item no-cover"}
           
            onMouseEnter={() => setCurrentHoverMenu(menu)}
            onMouseLeave={() => setCurrentHoverMenu(undefined)}
            onClick={ () => handleMenuClick(menu)}
          >
            {menu.coverUrl && !flag && (
              <div
                className="a"
                style={{ backgroundImage: `url(${CoverImageObjectCloudResize(menu.coverUrl,500)})` }}
              ></div>
            )}
            <div className="b" style={{textAlign:textShouldCenter(menu.name)?'center':'left'}}>
              <span>{menu.name}</span>
            </div>   
            <div className="c">
              <span style={{visibility:menu.children.length > 0?'visible':'hidden'}}> <RiArrowDropRightFill /></span>
            </div>
            {menu.children.length > 0 && currentHoverMenu?.id === menu.id && (
              <ThirdMeus menus={menu.children}></ThirdMeus>
            )} 
          </div>
          </div>
        ))}
      </div>
    </SecondMenuStyle>
  );
}

export default SecondMeus;
