import styled from "styled-components";

export const TopicDetailStyle = styled.div`
  .header {
    width: 100%;
    padding: ${(props) => props.theme.space.paddingLG}px;
    padding-bottom: ${(props) => props.theme.space.padding}px;
    background-color: #fff;
    width: 1032px;
    margin:  ${(props) => props.theme.space.marginLG * 2}px auto 0;
    .container {
      width: 100%;
      margin: 0 auto;
      .list-title {
        display: flex;
        margin-bottom: 20px;
        span {
            padding: 0 5px;
        }
        .link {
            color: #808080;
            cursor: pointer;
            &:hover {
                color: #FF9A16;
            }
        }
      }
    }
    .tags {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
    }
    .row_1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        max-width: 90%;
      }
      .data {
        display: flex;
        align-items: center;
        gap: 3em;
        .data-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .text {
          }
          .num {
            font-size: 18px;
          }
        }
      }
    }
    .row_2 {
      padding: ${(props) => props.theme.space.padding}px 0;
      padding-top: ${(props) => props.theme.space.paddingXS}px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 14px;
      .thumbnail{
          height: 88px;
          flex: 0 0 200px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          cursor: pointer;
        }
        .video{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 28px;
        }
      .desc {
        height: 65px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
       
        .text {
          font-size: 14px;
          font-weight: 400;
          color: ${(props) => props.theme.colors.colorTextSecondary};
          display: -webkit-box;
          -webkit-line-clamp: 3; /* Number of lines to show */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 0;
          height: 65px;
        }
      }
      .desc-1 {
        img{
          max-width: 100%;
        }
      }
      .more-text {
      position: absolute;
      right: 0;
      bottom:-10px;

        color: ${(props) => props.theme.colors.colorPrimaryActive};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimaryActive};
      }
      }
    }
    .row_3 {
      display: flex;
      align-items: center;
      gap: 24px;
      .btns {
        .ant-btn {
          display: flex;
          align-items: center;
        }
      }
      .interaction {
        display: flex;
        align-items: center;
        .icon-btn {
          display: flex;
          align-items: center;
          gap: 8px;
          color: ${(props) => props.theme.colors.colorTextSecondary};
          cursor: pointer;

          &:hover {
            color: ${(props) => props.theme.colors.colorPrimaryActive};
          }
          &.active {
          }
        }
      }
    }
  }
  .container_ {
    width: 1032px;
    margin: 0 auto;
    margin-top: ${(props) => props.theme.space.padding}px;
    display: flex;
    gap: 14px;
    /* min-height: 100vh; */
    padding-bottom: ${(props) => props.theme.space.paddingLG}px;
    
    .left {
      flex: 1;
      background-color: ${(props) => props.theme.colors.colorTextLightSolid};
        border-radius: ${(props) => props.theme.border.borderRadiusLG}px  ${(props) => props.theme.border.borderRadiusLG}px 0 0;
      height: max-content;
      .top-mine{
        border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      }
      .all-list{
        background-color: #FBFBFD;
        width: 100%;
        border-left: 1px solid #FBFBFD;
        border-right: 1px solid #FBFBFD;
        text-align: center;
        padding: 8px 0;
        color:  ${(props) => props.theme.colors.colorTextTertiary};
      }
    }
    .right {
      background-color: #fff;
      width: 296px;
      padding: ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.padding}px;
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      height: max-content;
    }
  }
`;
