import styled from "styled-components";

export const RewardSearchStyle = styled.div`
    .list-title {
        padding: ${props => props.theme.space.padding}px 0 ${props => props.theme.space.padding}px 20px;
        display: flex;
        cursor: pointer;
        span {
            padding: 0 5px;
        }
        .link {
            color: #808080;
            cursor: pointer;
            &:hover {
                color: #FF9A16;
            }
        }
    }

    .keyword-wrap {
        padding: 0 ${props => props.theme.space.padding}px ${props => props.theme.space.padding}px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.colors.colorBorderSecondary};
        .active {
            color: ${props => props.theme.colors.colorPrimary};
        }
    }
`