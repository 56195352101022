import { Button, Input, Select, Modal, message, Pagination, Popover } from 'antd'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { OrderStyle } from './style'
import { OrderSearchType, orderSearchArr } from 'types/enums/order-types'
import { useNavigate } from 'react-router-dom'
import CommonTabs from 'ui-lib/tab/common-tab'
import {
  apiAgreeOrRefuseRefund,
  apiGetSellerOrderList,
  getShopDetail,
  postDelivery,
  postSellerResetOrder,
} from 'api/shop'
import { MallShopDetailResponse, SellerOrderListRequest, SellerOrderListResponse } from 'types/product'
import { getLocalUserInfo, queryUrl } from 'utils/functions/common'
import { NoData } from 'components/no-data'
import ShopTopLine from '../componnet/shop-top-line'
import { ChatRoom } from 'components/chat'
import { RiChat1Line } from 'react-icons/ri'
import RefuseRefundModal from './refuseRefundModal'
import { translateRefundStatus } from './fn'
import UpdatePrice from './updatePrice'
import dayjs from 'dayjs'
import { initChatService, logoutChat, orderConfirmSubmissionSendMessage, orderUpdatePriceSendMessage } from "utils/functions/chat";
import ShopApplicant from 'views/user-center/shop/applicant'
const { Option } = Select

const UploadList = () => {
  const [list, updateList] = useState<SellerOrderListResponse[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentOrder, setCurrentOrder] = useState<SellerOrderListResponse>()
  const [modal, contextHolder] = Modal.useModal()
  const [refundRefuseModalOpen, setRefundRefuseModalOpen] = useState(false)
  const [chatModalOpen, setChatModalOpen] = useState(false)
  const [isShowUpdatePriceModal, setIsShowUpdatePriceModal] = useState(false)
  const [toUserId, setToUserId] = useState<number>()
  const { Search } = Input
  const [searchValue, setSearchValue] = useState('')
  const [isHasShop, setIsHasShop] = useState(false)

  const [shopDetail, setShopDetail] = useState<MallShopDetailResponse>()
  const navigate = useNavigate()
  let pageNumber =
    new URLSearchParams(window.location.search).get('pageNumber') || 1
  let name = new URLSearchParams(window.location.search).get('name')
  let state = new URLSearchParams(window.location.search).get('state') || -1
  let productName =
    new URLSearchParams(window.location.search).get('productName') || ''
  let username =
    new URLSearchParams(window.location.search).get('username') || ''
  let orderNumber =
    new URLSearchParams(window.location.search).get('orderNumber') || ''
  const pageSize = 5

  const breadcrumbs = [
    {
      title: "商品订单",
    },
  ];


  const submitParams: SellerOrderListRequest = useMemo(() => {
    return {
      pageNumber: Number(pageNumber),
      pageSize,
      state: Number(state),
      name,
    }
  }, [pageNumber, state, name])

  const getList = useCallback(
    async (params?: SellerOrderListRequest) => {
      const { pageNumber, pageSize, state } = submitParams
      const paramsReq = Object.assign(
        {
          pageNumber,
          pageSize,
          name,
          state,
          orderNumber,
          productName,
          username,
        },
        params
      )
      if (state === undefined || state === -1 || state === -2) {
        delete paramsReq.state
      }

      if (state === -2) {
        paramsReq.isRefund = 1
      }
      const result = await apiGetSellerOrderList(paramsReq)
      updateList(result.pageData)
      setTotal(result.totalSize)
      if (productName) {
        setSelectOrderType(OrderSearchType.ORDER_SEARCH_COMMODITY_NAME)
        setSearchValue(productName)
      } else if (username) {
        setSelectOrderType(OrderSearchType.ORDER_SEARCH_USERNAME)
        setSearchValue(username)
      } else if (orderNumber) {
        setSelectOrderType(OrderSearchType.ORDER_SEARCH_PRODUCT_NAME)
        setSearchValue(orderNumber)
      }
      return Promise.resolve(result.pageData)
    },
    [submitParams, orderNumber, productName, username]
  )

  useEffect(() => {
    getShopDetailFun()
  }, [])

  const getShopDetailFun = () => {
    getShopDetail().then(res => {
      if (res.id) {
        setIsHasShop(true)
        getList();
      } else {
        setIsHasShop(false)
      }
      setShopDetail(res)
    })
  }

  useEffect(() => {
    getList()
  }, [getList])

  // 分页
  const changePage = (detail: any) => {
    const query = queryUrl({ pageNumber: detail })
    navigate(`/user-center/shop/order-list?${query}`)
  }

  const [selectOrderType, setSelectOrderType] = useState(1)
  const selectSearchOrderTyle = (e: number) => {
    setSelectOrderType(e)
  }

  const selectBefore = (
    <Select value={selectOrderType} onSelect={(e) => selectSearchOrderTyle(e)}>
      {orderSearchArr.map((item) => {
        return (
          <Option key={item.value} value={item.value}>
            {item.name}
          </Option>
        )
      })}
    </Select>
  )

  const toDetail = (detail: SellerOrderListResponse) => {
    navigate(`/product/detail/${detail.productId}`)
  }

  const onSearch = (value: string) => {
    if (selectOrderType === OrderSearchType.ORDER_SEARCH_COMMODITY_NAME) {
      const query = queryUrl({
        productName: value,
        pageNumber: 1,
        username: '',
        orderNumber: '',
      })
      navigate(`?${query}`)
    } else if (selectOrderType === OrderSearchType.ORDER_SEARCH_USERNAME) {
      const query = queryUrl({
        username: value,
        pageNumber: 1,
        productName: '',
        orderNumber: '',
      })
      navigate(`?${query}`)
    } else if (selectOrderType === OrderSearchType.ORDER_SEARCH_PRODUCT_NAME) {
      const query = queryUrl({
        orderNumber: value,
        pageNumber: 1,
        productName: '',
        username: '',
      })
      navigate(`?${query}`)
    }
  }

  const reset = (detail: SellerOrderListResponse) => {
    Modal.confirm({
      title: '确定取消？',
      content: '取消订单后 订单失效！',
      okText: '确定',
      cancelText: '取消',
      onCancel() { },
      centered: true,
      onOk() {
        setLoading(true)
        postSellerResetOrder(detail.orderNumber)
          .then(() => {
            message.success('取消成功')
            getList()
          })
          .finally(() => {
            setLoading(false)
          })
      },
    })
  }

  const checkTab = (item: { key: number | string; title: string }) => {
    navigate(`?state=${item.key}`)
  }

  const toOrderDetail = (detail: SellerOrderListResponse) => {
    navigate(
      `/user-center/shop/seller-order/detail/${detail.orderNumber}?from=${submitParams.state === -1 ? 'all' : ''
      }`
    )
  }

  const toDelivery = (detail: SellerOrderListResponse) => {
    navigate(
      `/user-center/shop/seller-order/detail/${detail.orderNumber}?state=1`
    )
  }

  const statusTabs = [
    {
      key: -1,
      title: '全部',
    },
    {
      key: 0,
      title: '待支付',
    },
    {
      key: 1,
      title: '已支付',
    },
    {
      key: 2,
      title: '已发货',
    },
    {
      key: 3,
      title: '已完成',
    },
    {
      key: 4,
      title: '已取消',
    },
    {
      key: -2,
      title: '售后中',
    },
  ]

  const toUpdatePrice = (item: SellerOrderListResponse) => {
    setIsShowUpdatePriceModal(true)
    setCurrentOrder(item)
    if (item.isManuscript) {
      initChatService(item.userId)
    }
  }

  const refreshOrder = () => {
    getList().then(res => {
      const result = res.find(item => item.orderNumber === currentOrder?.orderNumber)
      if (!!result && !!currentOrder) {
        orderUpdatePriceSendMessage(result, result.amount)
      }
    })
    setIsShowUpdatePriceModal(false)
  }

  const handleRefund = async (item: SellerOrderListResponse, flag: number) => {
    setCurrentOrder(item)

    if (flag === 1) {
      modal.confirm({
        title: '是否确认同意退款',
        content: '同意后系统将自动退款给用户',
        onOk: () => {
          setLoading(true)
          apiAgreeOrRefuseRefund({
            orderNumber: item.orderNumber,
            type: 1,
          })
            .then(() => {
              message.success('操作成功')
              getList()
              setLoading(false)
            })
            .finally(() => {
              setLoading(false)
            })
        },
      })
    }
    if (flag === 0) {
      setRefundRefuseModalOpen(true)
    }
  }

  const confirmSubmission = (detail: SellerOrderListResponse) => {
    initChatService(detail.userId)
    Modal.confirm({
      title: '提示',
      content: '是否确定交稿',
      okText: "确定",
      cancelText: "取消",
      onCancel() { },
      centered: true,
      onOk() {
        postDelivery({
          expressNumber: detail.orderNumber,
          orderNumber: detail.orderNumber,
          expressCode: detail.orderNumber
        }).then(() => {
          message.success("交稿成功")
          getList()
          orderConfirmSubmissionSendMessage(detail)
        })

      },
    });

  }

  return (
    <OrderStyle>
      {contextHolder}
      {
        isHasShop && <>
          <div className="table-wrap">
            {chatModalOpen && (
              <ChatRoom
                show={chatModalOpen}
                close={() => setChatModalOpen(false)}
                toUserId={toUserId}
              ></ChatRoom>
            )}
            <div className="title">
              <ShopTopLine defaultValue="order" breadcrumbs={breadcrumbs} />
              {
                !shopDetail?.isWhite && !shopDetail?.isIdentificated && <div className='sub-title' onClick={() => navigate("/user-center/authentication/idCard")}>请及时完成实名认证，否则影响您的订单结算，<span>立即去实名</span></div>
              }
            </div>
            <div className="main">
              <div className="main-top">
                <CommonTabs
                  tabs={statusTabs}
                  defaultKey={Number(state)}
                  onClick={(item) => checkTab(item)}
                ></CommonTabs>
                <div className="actions">
                  <Search
                    addonBefore={selectBefore}
                    placeholder="请输入关键字"
                    allowClear
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSearch={(e) => onSearch(e)}
                  />
                </div>
              </div>
              <div className="main-list">
                <div className="heading">
                  <span>商品类型</span>
                  <span>商品信息</span>
                  <span>单价</span>
                  <span>数量</span>
                  <span>买家</span>
                  <span>订单状态</span>
                  <span>售后状态</span>
                  <span>实收款</span>
                </div>
                <div className="list">
                  {list.length === 0 && <NoData />}
                  {list.map((item) => {
                    return (
                      <div key={item.orderNumber}>
                        <div className="list-item">
                          <div className="top">
                            <span>订单编号：{item.orderNumber}</span>
                            <span>下单时间：{item.createTime}</span>
                          </div>
                          <div className="row">
                            <div className="row-cell">
                              {!!item.categoryList ? item.categoryList[1].name : '--'}
                            </div>
                            <div className="row-cell">
                              <div className="cell-card">
                                <img
                                  className="thumbnail"
                                  onClick={() => toDetail(item)}
                                  src={item.productImage}
                                  alt=""
                                />
                                <div className="title_">
                                  <a href={`/product/detail/${item.productId}`}>
                                    <span style={{ color: '#000' }}>
                                      {item.productName}
                                    </span>{' '}
                                    <br />
                                    {item.skuName}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="row-cell">
                              ￥{(item.rmbPrice / 100).toFixed(2)}
                            </div>
                            <div className="row-cell">{item.num}</div>
                            <div
                              className="row-cell user-chat-label"
                              onClick={() => {
                                setToUserId(item.userId)
                                setChatModalOpen(true)
                              }}
                            >
                              <RiChat1Line className="icon"></RiChat1Line>{' '}
                              <div> {item.username}</div>
                            </div>
                            {item.state === 0 && (
                              <div className="row-cell">待付款</div>
                            )}
                            {item.state === 1 && (
                              <div className="row-cell status-cell">
                                已支付
                                {
                                  !!item.lastSendTime && !item.isManuscript && <div className='lastSendTime'>
                                    {dayjs(item.lastSendTime).format("YYYY年MM月DD日 HH时")}前发货
                                  </div>
                                }
                              </div>
                            )}
                            {item.state === 2 && (
                              <div className="row-cell">
                                {
                                  !!item.isAuthorization && <>已支付</>
                                }
                                {
                                  !!item.isManuscript && <>已提交，待确认</>
                                }
                                {
                                  !item.isAuthorization && !item.isManuscript && <>已发货</>
                                }
                              </div>
                            )}
                            {item.state === 3 && (
                              <div className="row-cell">已完成</div>
                            )}
                            {item.state === 4 && (
                              <div className="row-cell">
                                {item.closeType === 1 ? '逾期未支付' : ''}
                                {item.closeType === 2 ? '买家取消' : ''}
                                {item.closeType === 3 ? '卖家取消' : ''}
                                {item.closeType === 4 ? '平台取消' : ''}
                              </div>
                            )}
                            {item.refundFlag > 0 && (
                              <div className="row-cell" style={{ color: 'red' }}>
                                {translateRefundStatus(item.refundStatus)}
                              </div>
                            )}

                            {item.refundFlag === 0 && (
                              <div className="row-cell">无</div>
                            )}

                            <div className="row-cell">
                              ￥{((item.amount + item.serviceCharge) / 100).toFixed(2)}
                            </div>
                          </div>
                          <div className="actions btns">
                            {!!((item.state === 0 && item.isAuthorization === 1 && item.isPrivateInquiry === 1) || (item.state === 0 && item.isManuscript === 1)) && <Button
                              type="primary"
                              onClick={() => toUpdatePrice(item)}
                            >
                              修改价格
                            </Button>

                            }

                            <Button
                              type="primary"
                              onClick={() => toOrderDetail(item)}
                            >
                              查看详情
                            </Button>
                            {item.state === 0 && (
                              <Button onClick={() => reset(item)} type="primary">
                                取消订单
                              </Button>
                            )}
                            {item.state === 1 && item.refundFlag === 0 && !item.isManuscript && (
                              <Button
                                onClick={() => toDelivery(item)}
                                type="primary"
                              >
                                立即发货
                              </Button>
                            )}

                            {item.state === 1 && !!item.isManuscript && (
                              <Button
                                onClick={() => confirmSubmission(item)}
                                type="primary"
                              >
                                确定交稿
                              </Button>
                            )}

                            {item.refundFlag === 1 && item.refundStatus === 1 && (
                              <>
                                <Button
                                  onClick={() => handleRefund(item, 1)}
                                  type="primary"
                                >
                                  同意退款
                                </Button>
                                <Button
                                  onClick={() => handleRefund(item, 0)}
                                  type="primary"
                                >
                                  拒绝退款
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <Pagination
                  style={{ textAlign: 'center', margin: '24px 0 0 0' }}
                  hideOnSinglePage={true}
                  current={Number(pageNumber)}
                  onChange={changePage}
                  total={total}
                  pageSize={pageSize}
                  showSizeChanger={false}
                />
                {currentOrder && (
                  <RefuseRefundModal
                    detail={currentOrder}
                    visible={refundRefuseModalOpen}
                    onSubmit={() => {
                      getList()
                      setRefundRefuseModalOpen(false)
                    }}
                    onClose={() => setRefundRefuseModalOpen(false)}
                  ></RefuseRefundModal>
                )}
              </div>
            </div>
          </div>
          <Modal className='update' footer={null} width={300} getContainer={false} destroyOnClose={true} onCancel={() => { setIsShowUpdatePriceModal(false); logoutChat() }} centered={true} open={isShowUpdatePriceModal}>
            {
              currentOrder && <UpdatePrice isYuegao={!!currentOrder.isManuscript} refreshOrder={() => refreshOrder()} detail={currentOrder} />
            }

          </Modal>
        </>
      }
      {
        !isHasShop && shopDetail && <ShopApplicant updateDetail={getShopDetailFun} detail={shopDetail} />
      }
    </OrderStyle>
  )
}

export default UploadList
