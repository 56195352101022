import { NavigateOptions, useNavigate } from "react-router-dom"

export const useGRoutes = () =>{
    const navigate = useNavigate()
    const push = (to: string| number, option?: NavigateOptions) =>{
        if(typeof to === 'number') {
            navigate(to)
        } else if(typeof to === 'string') {
            if(to.includes('v3')) {
                window.location.href = to
            } else {
                navigate(to, option)
            }
        }
    }
        
    return push
    }
    
   
