import { RiArrowRightSLine } from 'react-icons/ri'
import EventCard from './card'
import { LatestEventStyle } from './style'
import SectionTitle from 'components/section-title'

function LatestEvent() {
  const toMore = () => {
    window.open("/events")
  }
  return (
    <LatestEventStyle>
      <div className="title-wrap">
        <SectionTitle text={'最新活动'}></SectionTitle>
        <div className="tip" onClick={toMore}>查看更多 <RiArrowRightSLine className="icon"></RiArrowRightSLine></div>
      </div>
      <div className="container_">
        <div className="wrap">
          <EventCard></EventCard>
        </div>
      </div>
    </LatestEventStyle>
  )
}

export default LatestEvent