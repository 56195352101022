export enum UserRole {
    certifiedAuthor = 1,
    signedAuthor = 2,
    teamAccount = 3,
    invitedAuthor = 4
}

export const UserRoleArr = [
    { name: '认证作者', value: UserRole.certifiedAuthor },
    { name: '签约作者', value: UserRole.signedAuthor },
    { name: '团队账号', value: UserRole.teamAccount },
    { name: '特邀作者', value: UserRole.invitedAuthor }
]



