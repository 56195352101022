import styled from "styled-components";

export const RewardIndexStyle = styled.div`
    
    .tabs-wrap {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
        border-bottom: 1px solid rgb(240, 240, 240);
        & > div {
            width: max-content;
            border-bottom: none;
        }
        .tabs-left{
            min-width: 0;
        }
        .tabs-right {
            display: flex;
            align-items: center;
            gap: 5px;
            padding-right: ${props => props.theme.space.padding}px;
        }
    }
`