import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel"
import { AlbumListStyle } from "./style"
import { useEffect, useState } from "react"
import { apiGetAlbumList } from "api/nft-album"
import { NftSalesPlanListResponse } from "types/nft-album"
import { Pagination, Spin } from "antd"
import AlbumCard from "../components/album-card"
import { NoData } from "components/no-data"

const AlbumList = () => {

    const filterOptions = [{ key: 'album', text: '图鉴' }]

    const [currentKey, setCurretnKey] = useState('album')

    const [pageNumber, setPageNumber] = useState(1)

    const [list, setList] = useState<NftSalesPlanListResponse[]>([])

    const [loading, setLoading] = useState(false)

    const [total, setTotal] = useState(0)

    const pageSize = 24

    const changePage = (e: any) => {
        setPageNumber(e)
    }

    useEffect(() => {
        apiGetAlbumList({ pageNumber, pageSize }).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        })
    }, [pageNumber])

    return <AlbumListStyle>
        <div className="wrap">
            <GuanggaoBannerCarousel positionCode={9}></GuanggaoBannerCarousel>
            <div className='fliter'>
                <div className='tabs'>
                    {filterOptions.map((item, index) => {
                        return <div className={`tab ${currentKey === item.key ? 'active' : ''}`} key={index} onClick={() => setCurretnKey(item.key)}>{item.text}</div>
                    })}
                </div>
            </div>
            <Spin spinning={loading}>


                <div className="data-list">
                    {list &&
                        list.map((item, index) => {
                            return <AlbumCard item={item} key={index} />

                        })}
                </div>
                {list?.length === 0 && (
                    <div style={{ padding: "100px 0" }}>
                        <NoData />
                    </div>
                )}
            </Spin>
            <Pagination
                style={{ textAlign: "center", marginTop: "28px" }}
                hideOnSinglePage={true}
                current={pageNumber}
                onChange={changePage}
                showSizeChanger={false}
                pageSize={pageSize}
                total={total}
            />
        </div>
    </AlbumListStyle>
}

export default AlbumList