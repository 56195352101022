import styled from "styled-components";

export const MyBadgesStyle = styled.div`
  padding: ${(props) => props.theme.space.paddingLG}px;
  max-width: 1280px;
    margin: 0 auto;
  .section-block {
    padding: ${(props) => props.theme.space.paddingLG}px;
    padding-top: 0;
    margin: ${(props) => props.theme.space.paddingLG}px 0;
    border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
    .section-title {
      font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
      padding-bottom: ${(props) => props.theme.space.paddingLG}px;
    }
    .section-content {

    }
  }
`;
