import { get, post } from "api";
import { getWalletDetailType, getRmbFlowListRequest, WalletRmbFlowListItem, postWithdrawalRequest } from "types/wallet";
import { PaginationResponse } from "types/common/axios";

export const getWalletDetail = () =>
    get<{}, getWalletDetailType>("/api/wallet/detail")

// 钱包-人民币流水列表
export const getRmbFlowList = (params: getRmbFlowListRequest) =>
    get<getRmbFlowListRequest, PaginationResponse<WalletRmbFlowListItem>>("/api/wallet/rmb_flow_list", params)

// 支付宝提现
export const postWithdrawal = (params: postWithdrawalRequest) =>
    post<postWithdrawalRequest, {}>("/api/wallet/ali_draw", params)