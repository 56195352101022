import { theme } from 'antd';
const { useToken } = theme;

export const UseShadow = () => {

    const { token } = useToken();

    return {
        boxShadow:token.boxShadow,
        boxShadowSecondary:token.boxShadowSecondary,
        boxShadowThin:"rgba(0, 0, 0, 0.12) 0px 1px 3px 0px,rgba(0, 0, 0, 0.12) 0px 0px 1px 0px;"
    }
}