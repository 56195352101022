import { Table,  message, Segmented } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { commodityOrderItem, commodityOrderParams } from "types/commodity";
import { useState, useEffect, useCallback, useMemo } from "react";
import { UserOrderStyle } from './style'
import { apiGetUserCommodityOrderList } from 'api/commodity'
import { UserOrderStatusTypeArr, UserOrderStatusType, CommodityStatusTypeArr } from 'types/enums/commodity-status-type'
import { PayTypeArr } from 'types/enums/commodity-pay-type'
import { useGRoutes } from 'utils/hooks/useGRoute'

  const UserOrderList = () => {
    const [list, updateList] = useState<commodityOrderItem[]>([])
    const [total, setTotal] = useState(0)
    const gRouter = useGRoutes()
    const [messageApi, contextHolder] = message.useMessage();

    const submitParams: commodityOrderParams = useMemo(() => {
        return {
            pageNumber: 1,
            pageSize: 10,
        }
    }, [])

    type tabType = {
        label: string,
        value: number
    }


    const orderStatusList: tabType[] = UserOrderStatusTypeArr

    const TabList = () => {
        const [currentId, setCurrentId] = useState(UserOrderStatusType.COMMODITY_ORDER_STATUS_ALL)
        const changeTab = (value:(string | number)) => {
            let params: commodityOrderParams = {}
            if (value === UserOrderStatusType.COMMODITY_ORDER_STATUS_ALL) {
                params = {}
            } else {
                params.state = Number(value)
            }
            setCurrentId(Number(value))
            getList(params)
        }

        return <Segmented options={orderStatusList} value={currentId} onChange={changeTab} />
    }

    const getList = useCallback(async (params?: commodityOrderParams) => {
        const { pageNumber, pageSize } = submitParams
        const result = await apiGetUserCommodityOrderList(Object.assign({ pageNumber, pageSize }, params))
        updateList(result.pageData)
        setTotal(result.totalSize)
    }, [submitParams])

    const [importAppendixProps, setImportAppendixProps] = useState({
        visible: false,
        visibleChange: (visible: boolean, needRefesh?: boolean) => {
            setImportAppendixProps({ ...Object.assign(importAppendixProps, { visible: visible, defaultParams: {} }) })
        },
        createSuccess: () => {
            getList()
        }
    })

    useEffect(() => {
        getList()
    }, [getList])

    // 分页
    const listChange = (detail: any) => {
        getList({ pageNumber: detail.current })
    }

    // 筛选支付方式
    const filterPayment = (value: number) => {
        return PayTypeArr.find(item => {
            return item.value === value
        })?.name

    }

    // const filterOrderType = (type: number) => {
    //     return OrderTypeArr.find((item) => {
    //         return type === item.value
    //     })?.name
    // }

    // 查看商品
    const toDetail = (detail: commodityOrderItem) => {
        gRouter(`/commodity/order/detail/${detail.productId}`)
    }

    // 查看商品详情
    // const toOrderDetail = (detail: commodityOrderItem) => {
    //     gRouter(`/commodity/order/detail/${detail.orderNumber}`)
    // }

    // 取消订单
    // const cancelOrder = async (detail: commodityOrderItem) => {
    //     await apiPostCancelCommodityOrder(detail.orderNumber)
    //     messageApi.open({
    //         type: "success",
    //         content: "取消成功"
    //     })
    //     getList()
    // }

    // 前往支付
    // const toPay = (detail: commodityOrderItem) => {
    //     gRouter(`/commodity/order/detail/${detail.orderNumber}`)
    // }


    const fliterOrderStatus = (state: number) => {
        const result = CommodityStatusTypeArr.find(item => {
            return item.value === state
        })
        return result?.name
    }

    const columns: ColumnsType<commodityOrderItem> = [
        { title: '订单编号', dataIndex: "orderNumber", key: "orderNumber", width: 120, align: "center" },
        {
            title: '商品名称', dataIndex: "productName", key: "productName", width: 80, align: "center", render: (_, record) => (
                <div onClick={() => toDetail(record)}>{record.productName}</div>
            )
        },
        // {
        //     title: '商品封面', dataIndex: "productImage", key: "coverUrl", width: 80, align: "center", render: (_, record) => (
        //         <div className='wrap' onClick={() => toDetail(record)}>
        //             <img src={record.productImage} alt="" />
        //         </div>
        //     )
        // },
        {
            title: '支付方式', dataIndex: "paymentChannel", key: "paymentChannel", width: 60, align: "center", render: (_, record) => (
                <div>{filterPayment(record.paymentChannel)}</div>
            )
        },
        // {
        //     title: '订单类型', dataIndex: "orderType", key: "orderType", width: 60, align: "center", render: (_, record) => (
        //         <div>{filterOrderType(record.orderType)}</div>
        //     )
        // },
        {
            title: '支付金额', dataIndex: "amount", key: "amount", width: 80, align: "center", render: (_, record) => (
                <div>{record.amount / 100}</div>
            )
        },
        // { title: '支付金额', dataIndex: "amount", key: "amount", width: 50, align: "center" },
        { title: '创建时间', dataIndex: "createTime", key: "createTime", width: 70, align: "center" },
        {
            title: '订单状态', dataIndex: "updateTime", key: "updateTime", width: 50, align: "center", render: (_, record) => (
                <div className='stats'>{fliterOrderStatus(record.state)}</div>
            )
        },
        // {
        //     title: "操作", dataIndex: "action", key: "action", width: 100, align: "center", render: (_, record) => (
        //         <div className='btns'>
        //             {record.state === CommodityStatus.COMMODITY_ORDER_STATUS_PAYING && <Button size='small' onClick={() => toPay(record)}>立即支付</Button>}
        //             {record.state === CommodityStatus.COMMODITY_ORDER_STATUS_PAYING && <Button size='small' onClick={() => { cancelOrder(record) }}>取消订单</Button>}
        //             {<Button size='small' onClick={() => toOrderDetail(record)}>查看详情</Button>}
        //         </div>
        //     )
        // }
    ]

    return <UserOrderStyle>
        {contextHolder}
        <div className="table-wrap">
            <div className='top'>
                <div className="header">交易管理</div>
                <div className='tag'>
                {TabList()}
            </div>
            </div>
      
            <Table  className='table' rowKey="id" scroll={{ y: 600 }} pagination={{ position: ['bottomCenter'], pageSize: submitParams.pageSize, total }} onChange={(page) => listChange(page)}  rowClassName='table' columns={columns} dataSource={list}></Table>
        </div>
    </UserOrderStyle>
}


export default UserOrderList