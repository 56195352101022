import styled from "styled-components";

export const BottomGgStyle = styled.div`
  .container_ {
    padding: 0 ${(props) => props.theme.space.padding}px;
    padding-bottom: ${(props) => props.theme.space.paddingLG * 2}px;
    min-width: 1200px;
    max-width: ${(props) => props.theme.space.maxContainer}px;
    margin: 0 auto;
    .wrap {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      &.biz-contact {
        margin: ${(props) => props.theme.space.paddingLG}px 0;
        background-color: black;
        border-radius: ${(props) => props.theme.border.borderRadius}px;
        display: block;
        .title {
          font-size:  ${(props) => props.theme.fontSizes.fontSizeLG}px;
          color:#fff;
          width: 100%;
          display: grid;
          grid-template-columns: 4fr 2fr;
          gap:90px;
          padding: 0 ${(props) => props.theme.space.paddingLG}px;
          padding-top: ${(props) => props.theme.space.paddingLG}px;

        }
        .grid-box {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
          
          .item {
            display: flex;
            margin: ${(props) => props.theme.space.paddingLG}px
              ${(props) => props.theme.space.paddingLG}px;
            border: 1px solid #fff;
            border-radius: ${(props) => props.theme.border.borderRadius}px;
            color: #fff;
            .qr-code {
              width: 96px;
              aspect-ratio: 1;
              background-position: center;
              background-size: cover;
              border-radius: ${(props) => props.theme.border.borderRadius}px;
              background-color: #000;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                font-size: 42px;
              }
            }
            .right {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0 20px;
              .icon {
                font-size: 42px;
              }
              
              span {
                padding: ${(props) => props.theme.space.paddingXS}px 0;
              }
            }
            .info{
              display: flex;
              align-items: center;
              gap:20px;
              .mail{
                cursor: pointer;
                &:hover{
                  color: ${(props) => props.theme.colors.colorPrimary};

                }
              }
              .link{
                cursor: pointer;
                text-decoration: underline;
                &:hover{
                  color: ${(props) => props.theme.colors.colorPrimary};

                }
              }
            }
          }
        }
      }
    }
  }
`;
