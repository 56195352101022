import { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DetailStyle } from './style'
import PageHeader from 'views/user-shop/layout/pageHeader'
import {
  apiGetOrderDetail,
  apiGetSellerOrderDetail,
  postSearchOrderSuccess,
} from 'api/shop'
import { orderDetailResponse } from 'types/product'
import SellerOrderState from './detail'
import { Modal, QRCode, message } from 'antd'
import { apiPostWeixinPayCommodity } from 'api/commodity'
import { getFileNameFromURL } from 'utils/functions/common'

const breadcrumbs = [
  {
    title: '交易管理',
  },
  {
    title: '订单详情',
  },
]

let weixinTimer: any = null // 微信定时器
const TjDetail = () => {
  const { orderNumber } = useParams()
  const [detail, setDetail] = useState<orderDetailResponse>()
  const [weixinVisible, setWeixinVisible] = useState(false)
  const [weixinCode, setWeixinCode] = useState<string>('')
  const navigate = useNavigate()
  const [codeStatus, setCodeStatus] = useState<
    'active' | 'expired' | 'loading'
  >('loading')
  const from = new URLSearchParams(useLocation().search).get('from')
  const getDetail = useCallback(async () => {
    apiGetSellerOrderDetail(String(orderNumber)).then((res) => {
      setDetail(res)
    })
  }, [orderNumber])

  useEffect(() => {
    getDetail()
  }, [getDetail])

  useEffect(() => {
    return () => {
      clearInterval(weixinTimer)
    }
  }, [])

  const clear = () => {
    clearInterval(weixinTimer)
  }

  const getWeixinCode = (value: string) => {
    setWeixinCode(value)
    setWeixinVisible(true)
    clearInterval(weixinTimer)
    loopCheckOrderStatus(value)
    setCodeStatus('active')
  }

  const onRefreshCode = () => {
    apiPostWeixinPayCommodity(String(orderNumber)).then((res) => {
      getWeixinCode(res.code_url)
      setCodeStatus('active')
    })
  }

  const link = () => {
    navigate('/user-center/tj-sold')
  }

  // 查询订单支付状态，最多查询100次
  const loopCheckOrderStatus = useCallback(
    (value: string) => {
      let num = 0
      weixinTimer = setInterval(() => {
        // 开启定时器
        if (num >= 100) {
          clearInterval(weixinTimer)
          return
        }
        searchOrderSuccess(String(orderNumber))
        num++
      }, 1500)
    },
    [weixinTimer]
  )

  // 查询订单是否支付成功
  const searchOrderSuccess = async (value: string) => {
    const result = await postSearchOrderSuccess(value)
    if (result.state === 1) {
      message.success('支付成功')
      setWeixinVisible(false)
      clearInterval(weixinTimer)
      getDetail()
    }
  }

  return (
    <DetailStyle>
      <PageHeader breadcrumbs={breadcrumbs} link={link}></PageHeader>
      <div className="wrap">
        <div className="top">
          <div className="order-detail">
            <div className="order-title">订单信息</div>
            <div className="detail">
              <div className="detail-item">
                <div className="label">订单编号：</div>
                <div className="value">{detail?.orderNumber}</div>
              </div>
              {!!detail?.mallProduct &&
                detail?.mallProduct?.productType === 2 && (
                  <>
                    <div className="flex">
                      <div className="detail-item">
                        <div className="label">收件人：</div>
                        <div className="value">{detail?.receiver}</div>
                      </div>
                      <div className="detail-item">
                        <div className="label">手机号：</div>
                        <div className="value">{detail?.phone}</div>
                      </div>
                    </div>
                    <div className="detail-item">
                      <div className="label">收货地址：</div>
                      <div className="value">{detail?.fullAddress}</div>
                    </div>
                  </>
                )}

              <div className="detail-item">
                <div className="label">拍下时间：</div>
                <div className="value">{detail?.createTime}</div>
              </div>
              {detail && detail?.state > 0 && detail?.paidTime && (
                <div className="detail-item">
                  <div className="label">支付时间：</div>
                  <div className="value">{detail?.paidTime}</div>
                </div>
              )}
            </div>
          </div>
          <div className="order-status">
            <div className="order-title">订单状态</div>
            <div className="content">
              {detail && (
                <SellerOrderState update={getDetail} detail={detail} />
              )}
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="bottom-item">
            <div>图鉴</div>
            <div>单价</div>
            <div>数量</div>
          </div>
          <div className="bottom-item">
            <div className="sku">
              <div
                className="sku-cover"
                style={{ backgroundImage: `url(${detail?.productImage})` }}
              />
              <div className="sku-info">
                <div className="name">{detail?.productName} </div>
                <div className="sku-name">{detail?.mallProduct?.skuName}</div>
              </div>
            </div>
            {detail && (
              <div>￥ {(detail?.mallProduct?.rmbPrice / 100).toFixed(2)}</div>
            )}
            <div>{detail?.mallProduct?.num}</div>
          </div>
          {detail?.mallProduct?.attachmentList &&
            detail?.mallProduct?.attachmentList?.length > 0 && (
              <div className="downloads">
                <span>可下载：</span>
                {detail?.mallProduct.attachmentList.map((url) => {
                  return (
                    <a href={url} target="_blank" key={url}>
                      {getFileNameFromURL(url)}
                    </a>
                  )
                })}
              </div>
            )}
        </div>
        <div className="bottom-info">
          {detail && detail?.discountAmount > 0 && (
            <div className="rmb deliveryFee">
              优惠券:<span>- ￥{(detail.discountAmount / 100).toFixed(2)}</span>
            </div>
          )}
          {Boolean(detail?.deliveryFee) &&
            detail?.deliveryFee &&
            detail?.deliveryFee > 0 && (
              <div className="rmb deliveryFee">
                运费:<span>￥{(detail.deliveryFee / 100).toFixed(2)}</span>
              </div>
            )}
          {detail && (
            <div className="rmb">
              实付款：<span>￥{(detail.amount / 100).toFixed(2)}</span>
            </div>
          )}
        </div>
      </div>
      <Modal
        className="modal"
        centered={true}
        footer={null}
        getContainer={false}
        onCancel={() => {
          setWeixinVisible(false)
          clearInterval(weixinTimer)
        }}
        open={weixinVisible}
      >
        <div className="title">请扫码进行微信支付</div>
        <div className="code-wrap">
          {weixinCode && (
            <QRCode
              errorLevel="H"
              value={weixinCode}
              size={200} // 二维码的大小
              onRefresh={onRefreshCode}
              status={codeStatus}
            />
          )}
        </div>
      </Modal>
    </DetailStyle>
  )
}

export default TjDetail
