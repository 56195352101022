import { Pagination, Spin } from "antd"
import { AlbumListStyle } from "./style"
import { useState, useEffect, useCallback, useMemo } from "react"
import { albumListItem } from "types/album"
import { apiGetAlbumList } from "api/album"
import { NoData } from "components/no-data"
import AlbumCard from "components/album/album-card"
import { useParams } from "react-router"
import { dynamicColumns } from "utils/functions/common"
const AlbumList = () => {
    const [albumList, setAlbumList] = useState<albumListItem[]>([])
    const [pageNumber, setPageNumber] = useState(1)
    const [totalSize, setTotalSize] = useState(1)
    const [loading, setLoading] = useState(false)
    const id = useParams()?.id

    const params = useMemo(() => {
        return {
            userId: Number(id),
            pageNumber,
            pageSize:  dynamicColumns()*3,
        }
    }, [pageNumber])

    const getList = useCallback(() => {
        setLoading(true)
        apiGetAlbumList(params).then(res => {
            setAlbumList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const changePage = (e: number) => {
        setPageNumber(e);
    };

    return <AlbumListStyle>
        <Spin spinning={loading}>
            {
                albumList.length === 0 &&
                <div style={{ padding: '100px 0' }}>
                    <NoData />
                </div>
            }
            {
                albumList && albumList.length > 0 && <AlbumCard list={albumList} isShowPopup={false} />
            }
        </Spin>

        <Pagination
            pageSize={params.pageSize}
            style={{ textAlign: "center",marginTop:28 }}
            hideOnSinglePage={true}
            onChange={changePage}
            total={totalSize}
            showSizeChanger={false}
            current={pageNumber}
        />
    </AlbumListStyle>
}


export default AlbumList