import { FavoriteDataListItem } from 'types/favorite';
import { FavoriteCardActionStyle } from './style';
import { Button } from 'antd';
import { RiDeleteBin2Line, RiEyeLine } from 'react-icons/ri';


const FavoriteCardAction = ({ item, workLink, shouldRefresh }: { item: FavoriteDataListItem, workLink: string, shouldRefresh: (item: FavoriteDataListItem) => void }) => {

    const viewFavorites = (item: FavoriteDataListItem) => {
        window.open(workLink)
    }

    return <FavoriteCardActionStyle>
        <div className="action-list">
            <Button
                size="large"
                shape="circle"
                onClick={(e) => {
                    e.stopPropagation()
                    shouldRefresh && shouldRefresh(item)
                }
                }
            >
                <RiDeleteBin2Line  style={{marginTop:5}}></RiDeleteBin2Line>
            </Button>
            {
                (item.expired === 0) && <Button
                    onClick={(e) => { e.stopPropagation(); viewFavorites(item) }}
                    size="large"
                    shape="circle"
                >
                    <RiEyeLine  style={{marginTop:5}}></RiEyeLine>
                </Button>
            }
        </div>
    </FavoriteCardActionStyle>
}


export default FavoriteCardAction