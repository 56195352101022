import { useCallback, useEffect, useMemo, useState } from "react";
import { SubTypeListStyle } from "./style";
import { getWorkCategory } from "api/work";
import { CategoryItem } from "types/user/work";
import { CarouselListItem } from "types/common/carousel";
import {  getSmallBannerList, sendCarouselLog } from "api/common/carousel";
import { useNavigate } from "react-router-dom";
import { CoverImageObjectCloudResize } from "utils/functions/file";

const SubTypeList = ({ isOpen }: { isOpen?: boolean }) => {
  const [currentId, setCurrentId] = useState(0);
  const [typeList, setTypeList] = useState<CategoryItem[]>([]);
  const [banners, setBanners] = useState<CarouselListItem[]>([]);
  const [articleCategoryItems,setArticleCategoryItems] = useState<CategoryItem[]>([]);
  const navigate = useNavigate()
  const getSlideList = () => {
    getSmallBannerList({ typeList: [21,25,26],lang: 1 }).then((res) => {
      setBanners(res);
    });
  };

  useEffect(() => {
    getSlideList();
     //获取文章内容分类
     getWorkCategory({ type: 1, useArticleUpload: 1, level: 2 }).then((res) =>
     setArticleCategoryItems(res)
   );
  }, []);

  const currentType = useMemo(() => {
    // if(currentId === 9999) return 
    const index = typeList.findIndex(item => item.id === currentId)
    if (index === -1) {
      return {
        name: "",
        children: []
      }
    }
    return typeList[index]
  }, [typeList, currentId])

  const getList = useCallback(() => {
    getWorkCategory({ subType: 1, useWork: 1 }).then(res => {
      res.unshift(
        {
          code: "精选",
          coverUrl: "https://cdn-dev.ggac.com/site/menu/edit/coverImage/AbmcSwiCWRiHnk8sYCBkePjpyyxiCfS81694005646951.jpeg",
          id: 1001,
          level: 1,
          name: "精选",
          pid: 0,
          rank: 366,
          type: 1,
          children: []
        },
        {
          code: "全部",
          coverUrl: "https://cdn-dev.ggac.com/site/menu/edit/coverImage/AbmcSwiCWRiHnk8sYCBkePjpyyxiCfS81694005646951.jpeg",
          id: 1000,
          level: 1,
          name: "全部",
          pid: 0,
          rank: 366,
          type: 1,
          children: []
        },
      )
      res.splice(res.length - 1, 0, {
        code: "article",
        coverUrl: "https://cdn-dev.ggac.com/site/menu/edit/coverImage/PQAxz6fKszQTybRrQzwmemjySCnaS25f1690785562428.png",
        id: 9999,
        level: 1,
        name: "文章",
        pid: 56,
        rank: 356,
        type: 1,
        children: []
      });
 
      setTypeList(res)
      setCurrentId(res[0].id)
    })
  }, [])

  const toSearch = (detail: CategoryItem) => {
    if(currentType.name === "文章"){
      window.open(`/explore?categoryId=${detail.id}&categoryPid=9999&pageNumber=1&type=article`);
    }else{
      window.open(`/explore?typeId=${detail.id}`);
    }
  };

  const toArticle = (detail: any) => {
    if (detail.id === 9999) {
      window.open(`/explore?categoryPid=9999&pageNumber=1&type=article`);
      return;
    }
    if (detail.id === 1001) {
      window.open(`/explore?categoryPid=10000&sortField=recommendUpdateTime`)
      return
    }
    window.open(`/explore?categoryPid=${detail.id}`)
  };

  useEffect(() => {
    getList();
  }, []);

  const MouseEnter = (sub: CategoryItem) => {
    setCurrentId(sub.id);
  };

  return (
    <SubTypeListStyle>
      <div className="type">
        <div className="type-first scrollbar" >
          {typeList.map((sub) => (
            <div
              className={`type-first-item ${sub.id === currentId ? "active" : ""}`}
              onClick={() => toArticle(sub)}
              key={sub.id}
              onMouseEnter={() => MouseEnter(sub)}
            >
              {sub.name}
            </div>
          ))}
        </div>
        <div className="type-right">
          <div className="top">
            <div className="top-type">{currentType?.name !== '文章' ? currentType?.name : '文章'}</div>
          </div>
          <div className="sub-type">
            {currentType.name !== '文章' && currentType.children &&
              currentType.children.length > 0 &&
              currentType.children.map((sub) => (
                <div
                  onClick={() => toSearch(sub)}
                  className={`sub-type-item`}
                  key={sub.id}
                >
                  {sub.name}
                </div>
              ))}
                {currentType.name === '文章' && articleCategoryItems.map((sub) => (
                <div
                  onClick={() => toSearch(sub)}
                  className={`sub-type-item`}
                  key={sub.id}
                >
                  {sub.name}
                </div>
              ))}
          </div>
        </div>
        <div className="type-gg">
          {banners && banners.map((item, index) => {
            return <div className="gg-item" key={index} style={{ backgroundImage: `url(${CoverImageObjectCloudResize(item.displayImage, 500)})` }} onClick={() => {
              sendCarouselLog({ carouselId: item.id })
              navigate(item.webUrl)
            }
            }


            >
            </div>
          })}
        </div>
      </div>
    </SubTypeListStyle>
  );
};

export default SubTypeList;
