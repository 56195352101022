import { ArticleWorkStyle } from "./style"
import { CoverImageObjectCloudResize } from "utils/functions/file"
import { ArticleDetailCardInfo } from "views/article/type"

export const ArticleWorkCard = (props: {info: ArticleDetailCardInfo}) =>{
    let info = props.info
    return <ArticleWorkStyle>
        <div className="container">
            <div className="top-wrapper" style={{backgroundImage:  `url(${CoverImageObjectCloudResize(info.coverUrl,500)})`}}>
            </div>
            <div className="middle-wrapper">
                <div className="middle-top">
                    <div className="title">{info.title}</div>
                    {/* <div className="type">{info.categoryList && info.categoryList[1]?.name}</div> */}
                </div>
                {/* <div className="middle-bottom">
                    <div className="userinfo">
                        <div className="avatar">
                            <Avatar src={info.userInfo.avatarUrl}></Avatar>
                        </div>
                        <div className="username">{info.userInfo.username}</div>
                    </div>
                </div> */}
            </div>
            <div className="bottom-wrapper"></div>
        </div>
    </ArticleWorkStyle>
}