import styled from "styled-components";

export const HonorListStyle = styled.div`
.list {
    display: grid;
    /* --auto-grid-min-size: 13rem; */
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-top: ${props => props.theme.space.margin}px;
}
`