import styled from "styled-components";

interface MyStyledComponentProps {
  isActive?: boolean;
}

export const CouponCardStyle = styled.div<MyStyledComponentProps>`
  margin: 8px auto;
  width: 328px;
  border-radius: 12px;
  border: ${(props) =>"1px solid " + props.theme.colors.colorBorder};
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  position: relative;

  .card-left {
    flex:1;
    border-right: 2px dashed  ${(props) => props.theme.colors.colorBorder};
    position: relative;
    padding: 4px 12px;
    padding-bottom: 16px;
    &:before{
        content: "";
      position: absolute;
      display: block;
      width: 1.2em;
      height: 0.6em;
      background: #ffffff;
      border-radius:0 0  0.6em 0.6em;
      left: calc(100% - 0.6em);
      border: ${(props) =>"1px solid " + props.theme.colors.colorBorder};
      border-top-color: #fff;
    }
    &:after {
        
      content: "";
      position: absolute;
      display: block;
      width: 1.2em;
      height: 0.6em;
      background: #ffffff;
      border-radius: 0.6em 0.6em 0 0;
      left: calc(100% - 0.6em);
      border: ${(props) =>"1px solid " + props.theme.colors.colorBorder};
      border-bottom-color: #fff;
    }
    &:before {
      top: -1px;
    }
    &:after {
      bottom: -1px;
    }
    .money {
      color: #ff5132;
      font-weight: 600;
      display: flex;
      gap: 4px;
      align-items: flex-end;
      .sign {
        font-size: 22px;
        padding-bottom: 4px;
      }
      .amount {
        font-size: 34px;
      }
    }
    .tip {
      color: ${(props) => props.theme.colors.colorTextTertiary};
      text-align: center;
    }
  }

  .card-right {
    width: 220px;
    padding-left: 18px;
    display: flex;
    .a{
        flex:1;
        display: flex;
        flex-direction: column;
  
        justify-content: center;
        .title{
            font-size: 16px;
            text-align: left;
            font-weight: 600;
        }
        .desc{
            color: ${(props) => props.theme.colors.colorTextTertiary};
            font-size: 12px;
        }
    }
    .b{
        width: 40px;
        display: flex;
        justify-content: center;
        align-content: center;
    }
  
  }
`;
