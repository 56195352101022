import styled from "styled-components";

export const ApplicantPopupStyle = styled.div`
    .outer {
        position: relative;
        padding-top: ${props => props.theme.space.paddingLG}px ;
        .flex {
            display: flex;
            gap:20px;
            width: 365px;
            flex-wrap: wrap;
            width: 100%;
            max-height: 400px;
            overflow: auto;
            padding-left: 30px;
            ::-webkit-scrollbar {
                width: 10px; /* 设置滚动条的宽度 */
                background-color: transparent; /* 滚动条的背景色 */
            }
            ::-webkit-scrollbar-track {
              background: #e1e1e1; /* 轨道的背景色 */
              border-radius: 10px; /* 轨道的圆角 */
            }
            /* 自定义滚动条的滑块（thumb） */
            ::-webkit-scrollbar-thumb {
              border-radius: 10px; /* 滑块的圆角 */
              border: 2px solid #ffffff; /* 滑块边框 */
            }
            /* 滑块在悬停或者点击时的样式 */
            ::-webkit-scrollbar-thumb:hover {
              background: #a8a8a8; /* 悬停时滑块的背景色 */
            }
            ::-webkit-scrollbar-thumb:active {
              background-color: #919191; /* 点击时滑块的背景色 */
            }
        }
        
    }
`