import { useState } from "react";
import { WorkDetailNavStyle } from "./style";
import { Affix } from "antd";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";


function WorkDetailNav({ id }: { id: number }) {

  const [top] = useState(260);
  const ids = JSON.parse(localStorage.getItem("workIdsForNav")!);
  if (!ids) return <></>;
  const currentIndex = ids.indexOf(id);
  if (currentIndex === -1) return <></>;

  const getPrev = () => {
   // navigate(`/work/detail/${ids[currentIndex - 1]}`);
   window.location.href = `/work/detail/${ids[currentIndex - 1]}`
  };
  const getNext = () => {
   // navigate(`/work/detail/${ids[currentIndex + 1]}`);
   window.location.href = `/work/detail/${ids[currentIndex + 1]}`
  };
  return (
    <Affix offsetTop={top}>
      <WorkDetailNavStyle>
        <div
          className="prev arrow"
          title="上一篇"
          onClick={() => getPrev()}
          style={{
            visibility: currentIndex === 0 ? "hidden" : "visible",
          }}
        >
          <RiArrowLeftSLine color="#fff"></RiArrowLeftSLine>
        </div>
        <div
          className="next arrow"
          title="下一篇"
          onClick={() => getNext()}
          style={{
            visibility: currentIndex === ids.length - 1 ? "hidden" : "visible",
          }}
        >
          <RiArrowRightSLine color="#fff"></RiArrowRightSLine>
        </div>
      </WorkDetailNavStyle>
    </Affix>
  );
}

export default WorkDetailNav;
