import React from "react";
import { UiTabStyle } from "./style";
import { useLocation, useNavigate } from "react-router-dom";

interface Tab {
  key: number | string;
  title: string;
  url?:string;
  urlGroup?:string[]
}

interface TabsProps {
  tabs: Tab[];
}

const UiTabs: React.FC<TabsProps> = ({ tabs }) => {
  let location = useLocation();
 const navigate = useNavigate();
  const handleTabClick = (tab: Tab) => {
    tab.url && navigate(tab.url)
  };

  return (
    <UiTabStyle>
      <div className="tabs">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={`tab ${tab.urlGroup?.includes(location.pathname.replace(/\d+/g, '')) ? "active" : ""}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.title}
          </div>
        ))}
      </div>
    </UiTabStyle>
  );
};

export default UiTabs;