import GoBackTitle from "ui-lib/go-back";
import { ShippingTemplateStyle } from "./style";
import { Button, Divider, Popconfirm, Space, message } from "antd";
import { useEffect, useState } from "react";
import { ShippingFeeTemplateListItem } from "types/product";
import {
  apiDeleteShippingFeeTemplate,
  apiGetShippingFeeTemplateList,
} from "api/shop";
import { useNavigate } from "react-router-dom";

function ShippingTemplate() {
  const [list, setList] = useState<ShippingFeeTemplateListItem[]>();
  const navigate = useNavigate();
  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    apiGetShippingFeeTemplateList().then((res) => setList(res));
  };

  const deleteRow = (item: ShippingFeeTemplateListItem) => {
    apiDeleteShippingFeeTemplate({ id: item.id }).then((res) => {
      message.success("删除成功");
      getList();
    });
  };

  return (
    <ShippingTemplateStyle className="ShippingTemplateStyle">
      <GoBackTitle
        onClick={() =>
          (window.location.href = "/user-center/shop/product-list")
        }
      >
        返回商品列表
      </GoBackTitle>
      <div className="row">
        <div className="title">运费设置</div>
        <Button
          type="primary"
          onClick={() => {
            navigate("/user-center/shop/shipping-template/create");
          }}
        >
          新建运费模版
        </Button>
      </div>

      <div className="list">
        {list &&
          list.map((item, index) => {
            if (item.id === 1) {
              return (
                <div className="list-item" key={index}>
                  <div className="header">
                    <div className="left">包邮</div>
                  </div>
                  <div className="content">全国包邮</div>
                </div>
              );
            } else {
              return (
                <div className="list-item" key={index}>
                  <div className="header">
                    <div className="left">{item.name}</div>
                    <div className="right">
                      <Space split={<Divider type="vertical" />}>
                        <span className="a-like" onClick={()=>navigate("/user-center/shop/shipping-template/update/"+item.id)}>
                          编辑
                        </span>
                        <Popconfirm
                          title="确认要删除吗?"
                          onConfirm={() => deleteRow(item)}
                          okText="确认"
                          cancelText="取消"
                        >
                          <span className="a-like"
                          >
                            删除
                          </span>
                        </Popconfirm>
                      </Space>
                    </div>
                  </div>
                  <div className="content">
                    <p>
                      默认运费（除指定地区外）：{item.defaultRule.baseNum}件内
                      {item.defaultRule.baseFee}元，每增加
                      {item.defaultRule.incNum}件，增加运费
                      {item.defaultRule.incFee}元。
                      {item.defaultRule.freeNum > 0 &&
                        `满${item.defaultRule.freeNum}件包邮`}
                    </p>
                    {item.specialRules && item.specialRules.map((rule, index) => {
                      return (
                        <p key={index}>
                          {rule.provinceList
                            .map((item) => item.name)
                            .join(", ")}
                          <br />
                          {`${rule.baseNum}件内${rule.baseFee}元，每增加${rule.incNum}件，增加运费${rule.incFee}元。满${rule.freeNum}件包邮`}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </ShippingTemplateStyle>
  );
}

export default ShippingTemplate;
