import PhotoWall from "./photo-wall/index";
import HomePageRank from "./rank";
import RecommandUser from "./recommand-user";
import LatestEvent from "./event";
import WorkCollection from "./collection";
import Articles from "./article";
import { HomeStyle } from "./style";
import BottomGg from "./bottom-gg";
import HomeFooter from "views/layout/homeFooter";
import { useEffect } from "react";
import { isMobile } from "utils/functions/common";
import MonthSelection from "./monthSelection";

function Home() {
  useEffect(() => {
    const checkIsMobile = isMobile()
    if (checkIsMobile) {
      window.location.href = `https://m.ggac.com`
      return
    }
  }, [])
  return (  
    <HomeStyle>
      {/* //照片墙 */}
      <PhotoWall ></PhotoWall>
      {/* //排行榜 */}
      <HomePageRank></HomePageRank>
      {/* //推荐作者 */}
      <RecommandUser></RecommandUser>
      {/* //最新活动 */}
      <LatestEvent></LatestEvent>
      {/* //专题作品 */}
      <WorkCollection></WorkCollection>
      {/* 本月精选 */}
      <MonthSelection />
      {/* //文章 */}
      <Articles></Articles>
      {/* //底部广告位 和商务联系*/}
      <BottomGg></BottomGg>
      {/* 底部Footer*/}
      <HomeFooter></HomeFooter>
    </HomeStyle>
  );
}

export default Home;
