import styled from "styled-components";

export const TagsStyle = styled.div`
 display: flex;
 flex-direction: column;
 .inlineTags{
   padding: ${props => props.theme.space.paddingXS}px;
    display: flex;
    align-items: flex-start;
 
   
    .tag-list{
      display: inline-flex;
      flex-wrap: wrap;
      align-items: flex-start;
      img{
      width: 24px;
      height: 24px;
    }

       .no-image-tag{
      background-color: #F1F1F3;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    }
    .title {
      min-width: 45px;
    }
    .ant-tag {
      margin-bottom: ${props => props.theme.space.marginXXS}px;
    }
 }
`