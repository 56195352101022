import { ContentCategoryCarouselStyle } from "./style";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { RiArrowLeftSLine, RiRefreshLine, RiArrowRightSLine, RiMoreLine } from "react-icons/ri";
import { useCallback, useEffect, useState } from "react";
import PopoverContent from "./tag-popover";
import { getHomeTypeList } from "api/work";
import { HomeTypeListItem } from "types/work";
import { useLocation, useNavigate } from "react-router-dom";
import { UserBaseInfo } from "types/user";
import { hasToken, queryUrl } from "utils/functions/common";
import { Tooltip } from 'react-tooltip';
import { ImageObjectCloudResize } from "utils/functions/file";
import { Modal } from "antd";
import LoginModal from "components/login-modal";

function ContentCategoryCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);

  const [typeList, setTypeList] = useState<HomeTypeListItem[]>([])
  const id = new URLSearchParams(useLocation().search).get("id");
  const type = new URLSearchParams(useLocation().search).get("type") || 1;
  const refreshNumber = new URLSearchParams(useLocation().search).get("refresh") || 0;
  const loadingWall = new URLSearchParams(useLocation().search).get("loadingWall") || "";
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!);

  const [isShowLoginModal, setIsShowLoginModal] = useState(false)

  const [refreshLoading, setRefreshLoading] = useState(false)

  const token = hasToken()


  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    mode: "free-snap",
    initial: 0,
    slides: {
      perView: 13,
      spacing: 12,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    if (!!loadingWall) {
      setRefreshLoading(true)
    } else {
      setRefreshLoading(false)
    }
  }, [loadingWall])

  const location = useLocation()
  const navigate = useNavigate()
  const move = (type: string) => {
    if (type === "prev") {
      instanceRef.current?.prev();
    }
    if (type === "next") {
      console.log(instanceRef.current);
      instanceRef.current?.next();
    }
  };

  // const reorder = (list:HomeTypeListItem[])=>{
  //   const newArr:any = [];
  //   const correct = ['游戏角色','游戏场景','游戏美宣','游戏动作','游戏戏UI','影视角色','原创短片','文创潮玩手办','二次元作品','同人创作','大咖秀','水区']
  //   correct.forEach(item=>{
  //     const res =  list.find(i=>i.name === item)
  //     if(res){
  //       newArr.push(res)
  //     }
  //   })
  //   return newArr

  // }

  const getTypeList = useCallback(() => {
    getHomeTypeList({}).then(res => {
      let result
      if (userInfo?.id) {
        result = res.filter((item) => {
          return item.status === 1
        })
      } else {
        result = res
      }
      setTypeList(result)
    })
  }, [])


  const { pathname } = location

  const toDetail = (detail: HomeTypeListItem) => {
    if (Number(id) === detail.dataId && Number(type) === detail.type) {
      navigate(`${pathname}`)
      return
    }

    if (pathname === '/home') {
      navigate(`/recommend?id=${detail.dataId}&type=${detail.type}`)
    } else if (['/recommend', '/hot', '/follow'].includes(pathname)) {
      navigate(`${pathname}?id=${detail.dataId}&type=${detail.type}`)
    }



  }

  const updateList = () => {
    if (pathname === '/home') {
      navigate(`/recommend`)
    } else if (['/recommend', '/hot', '/focus'].includes(pathname)) {
      navigate(`${pathname}`)
    }
    getTypeList()
  }

  useEffect(() => {
    getTypeList()
  }, [])

  useEffect(() => {
    instanceRef && instanceRef.current?.update();
  }, [typeList])

  const refreshWallCount: string = JSON.parse(localStorage.getItem("Refresh-Wall-Count") || "0")


  const refresh = () => {
    if (!!refreshLoading) {
      return
    }
    if (!token && Number(refreshWallCount) < 2) {
      localStorage.setItem("Refresh-Wall-Count", JSON.stringify(refreshWallCount + 1))
    } else if (!token && Number(refreshWallCount) >= 2) {
      setIsShowLoginModal(true)
      return
    }
    const number = Number(refreshNumber) + 1
    const query = queryUrl({ refresh: number })
    navigate(`${pathname}?${query}`)
  }

  return (
    <ContentCategoryCarouselStyle>
      <div className="control prev" onClick={() => move("prev")}>
        <RiArrowLeftSLine></RiArrowLeftSLine>
      </div>

      <div ref={sliderRef} className="keen-slider">
        {typeList.map((item) => {
          return <div className="keen-slider__slide number-slide1" onClick={() => toDetail(item)} key={item.id}>
            <div className="item">
              <div
                className="bg"
                style={{
                  backgroundImage: `url(${ImageObjectCloudResize(item.coverUrl, 300)})`,
                }}
              ></div>
              <div className={`text ${Number(id) === item.dataId && item.type === Number(type) ? 'active' : ''}`}>{item.name}</div>

            </div>
          </div>
        })}

      </div>
      <div className="right">
        <div className="control next" onClick={() => move("next")}>
          <RiArrowRightSLine></RiArrowRightSLine>
        </div>
        <Tooltip id="carousel-popover" afterHide={() => setShow(false)} clickable >
          {show && <PopoverContent updateList={updateList}></PopoverContent>}
        </Tooltip>
        {
          userInfo?.id && <div className="setting">
            <div className="item" onMouseEnter={() => setShow(true)} data-tooltip-id="carousel-popover">
              <div className="icon"> <RiMoreLine size={16}></RiMoreLine></div>
              {/* <div className="text">管理关注</div> */}
            </div>
          </div>
        }
        {
          pathname === '/home' && typeList.length > 0 && <div className="setting" onClick={refresh}>
            <div className="item">
              <div className="icon"> <RiRefreshLine size={16}></RiRefreshLine></div>
            </div>
          </div>
        }

      </div>
      <Modal width={450} footer={<></>} title="" destroyOnClose={true} closable={true} onCancel={() => setIsShowLoginModal(false)} centered={true} open={isShowLoginModal}>
        <LoginModal />
      </Modal>
    </ContentCategoryCarouselStyle>
  );
}

export default ContentCategoryCarousel;
