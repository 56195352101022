import styled from "styled-components";

export const HomeMediaStyle = styled.div`
  .container {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius:${(props) => props.theme.border.borderRadius}px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 20px;
    &:hover {
      .icon-wrap {
        visibility: visible;
      }
    }
    .icon-wrap {
      position: absolute;
      display: flex;
      visibility:hidden;
      align-items: center;
      gap: 2px;
      left: 10px;
      top: 10px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.65);
      border-radius: 8px;
      padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
      cursor: pointer;
    }
    img{
      border-radius:${(props) => props.theme.border.borderRadius}px;
    }
    &:hover{
      .bottom{
        display: block;
      }
    }
    .bottom{
        display: none;
        position:absolute;
        bottom:0;
        color: #fff;
        width:100%;
        background: linear-gradient(to top, #000000 0%,transparent);
        padding: ${(props) => props.theme.space.paddingXS}px;
        .title{
           font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            padding: ${(props) => props.theme.space.paddingXS}px 0;
            span{
              color:${(props) => props.theme.colors.colorBlack2Ggac};
            }
        }
        .userinfo{
            display:flex;
            align-items: center;
            .username{
             overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: ${(props) => props.theme.space.marginXS}px;
            color: #fff;
            }
        }
    }
  }
`;
