import { CommentsStyle } from "./style";
import { CommentListItem } from "types/comment"
import { PaginationResponse } from 'types/common/axios'
import { getCommentList, } from "api/work/comment";
import { useParams } from 'react-router-dom';
import { useState, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react'
import CommentItem from './comment-item'
import {
  RiArrowDownFill, RiArrowDownSLine
} from "react-icons/ri";

type CommentParamsType = {
  params: {
    commentCount?: number,
    updateCommentCount?: () => void,
    type: string,
    id?: number
    word?: string
    anonymity?: boolean
  }
}

const Comments = forwardRef((props: CommentParamsType, ref) => {
  const { id } = useParams<any>();
  const { params } = props
  const [commentData, setCommentData] = useState<PaginationResponse<CommentListItem>>({
    currentSize: 0,
    pageData: [],
    pageNumber: 0,
    pageSize: 0,
    totalPage: 0,
    totalSize: 0
  })
  const [commentList, setCommentList] = useState<CommentListItem[]>([])
  // 一级评论加载中
  const [sendLoading, setSendLoading] = useState<boolean>(false)
  //发送二级评论加载中 防连点
  const [subSendLoading, setSubSendLoading] = useState<boolean>(false)

  useEffect(() => {
    setCommentList([])
    commentData.pageNumber = 1
    setCommentData(commentData)
  }, [id, props.params.id])

  // 获取评论列表
  const getCommentsList = useCallback((page: number = 1) => {
    getCommentList({ dataId: props.params.id ? props.params.id : Number(id), dataTable: params.type, level: 1, pageNumber: page, pageSize: 10 }).then(result => {
      setCommentData(result)
      setCommentList((val) => [...val, ...result.pageData])
    }).finally(() => {
      setSendLoading(false)
    })
  }, [id, params.type])
  // 向外暴露添加评论方法
  useImperativeHandle(ref, () => ({
    addComment, resetComment
  }))

  // 一级评论插入次数
  const [insertNumber, setInsertNumber] = useState<number>(0)

  // 添加一级评论
  const addComment = (obj: CommentListItem) => {
    // if (props.params.type === 'user') {
    //   commentList.unshift(obj)
    // } else {
    //   commentList.push(obj)
    // }
    commentList.unshift(obj)
    setCommentList([...commentList])
    setInsertNumber(insertNumber + 1)
    params.updateCommentCount && params.updateCommentCount()
  }

  const resetComment = () => {
    setCommentList([])
    setInsertNumber(0)
  }

  // 添加二级评论
  const addSubComment = (detail: CommentListItem, pid: number) => {
    commentList.forEach(item => {
      if (item.id === pid) {
        item.insertNumber = (item.insertNumber || 0) + 1
        item.children = item.children ? item.children : []
        item.commentCount = item.commentCount + 1
        item.children.push(detail)
        params.updateCommentCount && params.updateCommentCount()
        return
      }
    })
    setCommentList([...commentList])
  }

  useEffect(() => {
    getCommentsList()
  }, [getCommentsList])

  // 删除评论
  const deleteComment = (commentId: number, pid: number) => {
    const index = commentList.findIndex(item => {
      return item.id === pid
    })
    // 一级评论
    if (commentId === pid) {
      commentList.splice(index, 1)
      setCommentList([...commentList])
    } else {
      const subIndex = commentList[index].children.findIndex(sub => {
        return sub.id === commentId
      })
      commentList[index].commentCount = commentList[index].commentCount - 1
      commentList[index].children.splice(subIndex, 1)
      setCommentList([...commentList])
    }
    params.updateCommentCount && params.updateCommentCount()
  }

  // 点赞
  const likeComment = (commentId: number, pid: number) => {
    const index = commentList.findIndex(item => {
      return item.id === pid
    })
    // 一级评论
    if (commentId === pid) {
      commentList[index].isZan = commentList[index].isZan === 1 ? 0 : 1
      if (commentList[index].isZan === 1) {
        commentList[index].zanCount = commentList[index].zanCount + 1
      } else {
        commentList[index].zanCount = commentList[index].zanCount - 1
      }
      setCommentList([...commentList])
    } else {
      const subIndex = commentList[index].children.findIndex(sub => {
        return sub.id === commentId
      })
      const subRes = commentList[index].children[subIndex]
      commentList[index].children[subIndex].isZan = subRes.isZan === 1 ? 0 : 1
      if (subRes.isZan === 1) {
        commentList[index].children[subIndex].zanCount = subRes.zanCount + 1
      } else {
        commentList[index].children[subIndex].zanCount = subRes.zanCount - 1
      }
      setCommentList([...commentList])
    }
  }


  // 获取二级评论
  const getSecondCommentList = (detail: CommentListItem) => {
    if (subSendLoading) {
      return
    }
    setSubSendLoading(true)
    const { subPageNumber = 0 } = detail
    getCommentList({ dataId: props.params.id ? props.params.id : Number(id), pid: detail.id, dataTable: params.type, pageSize: 10, level: 2, pageNumber: Number(subPageNumber + 1) }).then((result) => {
      detail.subPageNumber = result.pageNumber
      detail.subTotalPage = result.totalPage
      commentList.forEach(item => {
        if (item.id === detail.id) {
          if (!item.init) {
            item.children = []
            item.init = 1
            item.insertNumber = 0
          }
          if (item.insertNumber > 0) {
            item.children.splice(item.children.length - item.insertNumber, item.insertNumber)
            item.insertNumber = 0
          }
          item.children.push(...result.pageData)
          return
        }
      })
      setCommentList([...commentList])
    }).finally(() => {
      setSubSendLoading(false)
    })
  }

  // 下拉加载更多
  const loadMore = () => {
    if (commentData.pageNumber < commentData.totalPage) {
      if (sendLoading) {
        return
      }
      setSendLoading(true)
      if (insertNumber > 0) {
        // if (props.params.type === "user") {
        //   // 加载前清除动态添加的数据
        //   setCommentList([...commentList.slice(0, commentList.length - insertNumber)])

        // } else {
        //   setCommentList([...commentList.splice(commentList.length - insertNumber, insertNumber)])
        // }
        setCommentList([...commentList.slice(0, commentList.length - insertNumber)])
        setInsertNumber(0)
      }
      getCommentsList(commentData.pageNumber + 1)
    }
  }

  return (
    <CommentsStyle>
      {
        params.commentCount !== undefined && params.commentCount > 0 && <div className="title">{params.word ? params.word : '全部评论'} <span>{params.commentCount}</span> </div>
      }
      {
        commentList && commentList.length > 0 && commentList.map(item => {
          return <div className="commentSection" key={item.id}>
            <div className="comment">
              <CommentItem params={{ dataId: props.params.id, anonymity: params.anonymity ? true : false, detail: item, word: params.word, pid: item.id, addSubComment, deleteComment, type: params.type, likeComment }} />
            </div>
            {
              item && item.children && item.children.length > 0 && item.children.map((child) => {
                return <div className="comment indent" key={child.id}>
                  <CommentItem params={{ dataId: props.params.id, anonymity: params.anonymity ? true : false, detail: child, word: params.word, pid: item.id, addSubComment, deleteComment, type: params.type, likeComment }} />
                </div>
              })
            }
            {
              item.commentCount > 2 && (item.commentCount > (item && item.children && item.children.length)) && <div className="moreReply" onClick={() => getSecondCommentList(item)}>
                查看更多回复 <RiArrowDownFill />
              </div>
            }
          </div>
        })
      }
      {
        commentData.pageNumber < commentData.totalPage && <div className="moreComment" onClick={() => loadMore()}>
          加载更多 <RiArrowDownSLine fontSize={20}></RiArrowDownSLine>
        </div>
      }


    </CommentsStyle>
  );
})

export default Comments;
