import styled from 'styled-components'

export const OrderStyle = styled.div`
  .table-wrap {
    /* width: 1200px; */
    padding-top: ${(props) => props.theme.space.paddingXS}px;
    .title {
      font-size: ${(props) => props.theme.fontSizes.fontSizeHeading5}px;
      font-weight: bold;
    }
    .table-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${(props) => props.theme.colors.colorBgContainer};
      padding: 0 ${(props) => props.theme.space.padding}px;
      margin: ${(props) => props.theme.space.margin}px 0;
      border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
    }
    .main {
      margin-top: ${(props) => props.theme.space.paddingLG}px;
      padding-bottom: ${(props) => props.theme.space.padding * 2.4}px;
      border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      background-color: ${(props) => props.theme.colors.colorTextLightSolid};
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      .main-top {
        padding-top: 14px;
        display: flex;
        justify-content: space-between;
        position: relative;
        .tab {
          padding-bottom: 24px !important;
        }
        .actions {
          display: flex;
          gap: ${(props) => props.theme.space.padding}px;
          position: absolute;
          right: 0;
          top: 20px;
          margin-right: ${(props) => props.theme.space.padding}px;
          .ant-input-group-wrapper {
            padding: 0 7px;
            input {
              padding: 0 10px;
            }
            .ant-input-group-addon {
              border-radius: 50px 0 0 50px;
            }
            .ant-input-affix-wrapper {
              border-radius: 0;
            }

            .ant-btn {
              border-radius: 0 50px 50px 0;
            }
          }
        }
      }
      .main-list {
        padding: ${(props) => props.theme.space.padding}px;
        .heading {
          background-color: #f1f1f3;
          color: ${(props) => props.theme.colors.colorTextSecondary};
          border-radius: ${(props) => props.theme.border.borderRadius}px;
          display: grid;
          grid-template-columns: 1fr 2fr 1fr 1fr 2fr 1fr 1fr 2fr;
          text-align: center;
          padding: ${(props) => props.theme.space.paddingXS}px 0;
          padding-left: ${(props) => props.theme.space.paddingLG}px;
        }
        .list {
          .list-item {
            border: 1px solid
              ${(props) => props.theme.colors.colorBorderSecondary};
            margin-top: ${(props) => props.theme.space.paddingLG}px;
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
            .top {
              padding: ${(props) => props.theme.space.paddingSM}px
                ${(props) => props.theme.space.paddingLG}px;
              border-bottom: 1px solid
                ${(props) => props.theme.colors.colorBorderSecondary};
              display: flex;
              gap: 30px;
            }
            .row {
              display: grid;
              grid-template-columns: 1fr 2fr 1fr 1fr 2fr 1fr 1fr 2fr;
              padding: ${(props) => props.theme.space.paddingLG}px 0;
              padding-left: ${(props) => props.theme.space.paddingLG}px;
              .btns {
                display: flex;
                flex-wrap: wrap;
              }
              .status-cell {
                flex-direction: column;
                .lastSendTime {
                  font-weight: bold;
                }
              }
              .row-cell {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                &.user-chat-label {
                  cursor: pointer;
                  color: ${(props) => props.theme.colors.colorLinkDefault};
                  display: flex;
                  align-items: center;
                  div {
                    max-width: 140px;
                  }
                  &:hover {
                    color: ${(props) => props.theme.colors.colorLinkHover};
                  }
                  .icon {
                    font-size: 18px;
                    color: ${(props) => props.theme.colors.colorPrimary};
                    padding-right: ${(props) => props.theme.space.paddingXXS}px;
                  }
                }
                .no_ {
                  font-size: 20px;
                }
                .cell-card {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  gap: ${(props) => props.theme.space.padding}px;
                  .title_ {
                    word-wrap: break-word;
                    width: calc(100% - 80px);
                    cursor: pointer;
                    text-align: left;
                  }
                  .thumbnail-wrap {
                    width: 80px;
                    height: 80px;
                    border-radius: ${(props) =>
                      props.theme.border.borderRadius}px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .icon {
                      width: 50px;
                    }
                  }
                  .thumbnail {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    cursor: pointer;
                    object-fit: cover;
                  }
                }
              }
            }
          }
          .actions {
            display: inline-flex;
            justify-content: flex-end;
            width: 100%;
            padding-bottom: ${(props) => props.theme.space.padding}px;
            padding-right: ${(props) => props.theme.space.paddingXS}px;
          }
        }
      }
    }
    .wrap {
      display: flex;
    }
  }

  .btns {
    button {
      margin: 0 ${(props) => props.theme.space.marginXS}px;
    }
  }
`
