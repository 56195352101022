import styled from "styled-components";

export const PublishTopicStyle = styled.div`
    
    .icon-btns{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin-top: 28px;
               
                .icon-btn{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap:10px;
                    cursor: pointer;
                    &:hover{
                    .round-icon{
                        background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
                    }
                    span{
                        color: ${(props) => props.theme.colors.colorText};
                    }
                   
                }
                    .round-icon{
                        width: 48px;
                        height: 48px;
                        font-size: 18px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: ${(props) => props.theme.colors.colorFillQuaternary};
                    }
                    span{
                        color: ${(props) => props.theme.colors.colorTextSecondary};
                    }
                    
                }
            }
`