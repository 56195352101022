import UserCard from 'components/userCard'
import { RecommandUserStyle } from './style'
import SectionTitle from 'components/section-title'
import { useEffect, useState } from 'react'
import { getAuthorItem } from 'types/user'
import { getHomeRecommendAuthorList } from 'api/user'
import { RiRestartLine } from 'react-icons/ri'

function RecommandUser() {
  const [list, setList] = useState<getAuthorItem[]>([])

  useEffect(() => {
    getList()
  }, [])


  const getList = () => {
    getHomeRecommendAuthorList(6).then(res => {
      setList(res)
      // setData(res)
    })
  }


  return (
    <RecommandUserStyle>
      <div className="title-wrap">
        <SectionTitle text={'推荐作者'}></SectionTitle>
        <div className='update'>
          <div className="inner" onClick={getList}>
           换一换 <RiRestartLine className='icon'/>
          </div>
        </div>
      </div>

      <div className="container_">
        <div className="wrap">

          <UserCard list={list}></UserCard>
        </div>
      </div>
    </RecommandUserStyle>
  )
}

export default RecommandUser