import { getWorkCategory, getWorkList } from "api/work";
import { useEffect, useState } from "react";
import { CategoryItem } from "types/user/work";
import { WorkListItem } from "types/work";
import { DiscoverListStyle } from "./style";
import { Checkbox, Modal, Pagination, Select, Spin, Tabs } from "antd";
import { NoData } from "components/no-data";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SearchOption } from "components/search-option";
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
import { apiGetDictList, postGlobalSearch } from "api/common";
import { hasToken, queryUrl, setWorkListParamsForNav } from "utils/functions/common";
import PublicCard from "components/uni-card/public-cards/publicWorkCard";
import PublicEsCard from "components/uni-card/public-cards/publicESCard";
import { CoverImageObjectCloudResize } from "utils/functions/file";
import { GlobalSearchPostResponse } from "types/common";
import LoginModal from "components/login-modal";

export const DiscoverListMysql = () => {
  const t_id = Date.now();
  const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
  const [categoryId, setCategoryId] = useState(
    new URLSearchParams(window.location.search).get("categoryId") || -1
  );
  const articleType =
    new URLSearchParams(window.location.search).get("articleType") || 0;
  const [categoryPid, setCategoryPid] = useState(
    new URLSearchParams(window.location.search).get("categoryPid") || 10000
  );
  const [workList, setWorkList] = useState<WorkListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [articleList, setArticleList] = useState<GlobalSearchPostResponse[]>(
    []
  );
  const mediaCategory = new URLSearchParams(useLocation().search).get("mediaCategory")
  const navigate = useNavigate();
  const sortField =
    new URLSearchParams(window.location.search).get("sortField") || "";
  const timeField =
    new URLSearchParams(window.location.search).get("timeField") || "";
  const mediaType = new URLSearchParams(window.location.search).get(
    "mediaType"
  );
  const hasVideo = new URLSearchParams(window.location.search).get("hasVideo");

  const pageNumber = Number(
    new URLSearchParams(useLocation().search).get("pageNumber") || 1
  );
  const pageSize = 48;
  const typeId = new URLSearchParams(useLocation().search).get("typeId");

  const [articleTypeList, setArticleTypeList] = useState<CategoryItem[]>([]);

  const type = new URLSearchParams(useLocation().search).get("type") || "";

  const [listParams, setListParams] = useState<any>([]);

  let [searchParams, setSearchParams] = useSearchParams();

  const token = hasToken()

  const [isShowLoginModal, setIsShowLoginModal] = useState(false)

  const appendParam = (paramKey: string, paramValue: string) => {
    const params = new URLSearchParams(searchParams.toString());
    params.append(paramKey, paramValue);
    setSearchParams(params);
  };

  type AntSelectOption = {
    label: string;
    value: string | number;
  };
  const [mediaCategoryOptions, setMediaCategoryOptions] =
    useState<AntSelectOption[]>();

  const saveWorkListParamsForNav = (index: number) => {
    const params = { number: index, ...listParams }
    setWorkListParamsForNav(params, t_id);
  };

  useEffect(() => {
    setLoading(true);
    setWorkList([]);
  }, [categoryPid, categoryId, sortField, timeField, mediaType, mediaCategory]);

  useEffect(() => {
    // 获取媒体分类下拉列表
    apiGetDictList({ type: "mediaCategory" }).then((res) => {
      const options = res
        .filter((item) => (item.name !== "文章" && item.name !== '音乐'))
        .map((item) => {
          return { value: String(item.code), label: item.name };
        });
      const options_ = [{ value: "全部创作类型", label: "全部创作类型" }, ...options];
      // setMediaCategory("全部创作类型")
      setMediaCategoryOptions(options_);
    });
  }, []);

  useEffect(() => {
    console.log("type", type)
    if (!!type) {
      //获取文章内容分类
      getWorkCategory({ type: 1, useArticleUpload: 1, level: 2 }).then((res) =>
        setArticleTypeList(res)
      );
      setCategoryPid(9999);
      setCategoryId(Number(categoryId));
    }
  }, [type]);

  //点分页滚到到最上面
  useEffect(() => {
    const scrollableDiv = document.querySelector("#scrollableDiv");
    if (!scrollableDiv) {
      return;
    }
    scrollableDiv.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pageNumber]);

  // 切换分页
  const changePage = (e: any) => {
    // setCurrentPage(e)
    if (!token && e > 3) {
      setIsShowLoginModal(true)
      return
    }
    setLoading(true);
    const query = queryUrl({
      pageNumber: e,
    });
    navigate(`?${query}`);
  };

  //获取作品内容分类
  useEffect(() => {
    const params: any = {
      useWork: 1,
      hasSelected: 1,
    };
    getWorkCategory(params)
      .then((res) => {
        if (res.length === 0) {
          setCategoryList([]);
          return;
        }

        res.unshift(
          {
            code: "精选",
            coverUrl:
              "https://cdn-dev.ggac.com/site/menu/edit/coverImage/AbmcSwiCWRiHnk8sYCBkePjpyyxiCfS81694005646951.jpeg",
            id: 10000,
            level: 1,
            name: "精选",
            pid: 0,
            rank: 366,
            type: 1,
            children: [],
          },
          {
            code: "全部",
            coverUrl:
              "https://cdn-dev.ggac.com/site/menu/edit/coverImage/AbmcSwiCWRiHnk8sYCBkePjpyyxiCfS81694005646951.jpeg",
            id: 1000,
            level: 1,
            name: "全部",
            pid: 0,
            rank: 366,
            type: 1,
            children: [],
          }
        );
        res.splice(res.length - 1, 0, {
          code: "article",
          coverUrl:
            "https://cdn-dev.ggac.com/site/menu/edit/coverImage/PQAxz6fKszQTybRrQzwmemjySCnaS25f1690785562428.png",
          id: 9999,
          level: 1,
          name: "文章",
          pid: 0,
          rank: 356,
          type: 1,
          children: [],
        });

        setCategoryList(res);
        if (typeId) {
          setCategoryId(Number(typeId));
          const result = res.find((item) => {
            return item.children.find((sub) => sub.id === Number(typeId));
          });
          if (result) {
            setCategoryPid(result.id);
          }
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [typeId]);

  //获取数据列表，包括作品和文章
  useEffect(() => {
    if (!token && pageNumber > 3) {
      setIsShowLoginModal(true)
      return
    }
    let params: any = {
      pageNumber,
      pageSize: pageSize,
      isPublic: 1,
    };

    if (categoryPid === 10000) {
      params.sortField = "recommendUpdateTime";
      params.isRecommend = 1;
      delete params.categoryId;
    } else if (categoryPid === 1000) {
      params.sortField = "lastSubmitTime";
      delete params.categoryId;
    } else if (categoryId === -1 || !categoryId) {
      params.categoryId = Number(categoryPid);
    } else if (categoryId) {
      params.categoryId = Number(categoryId);
    } else if (!categoryId) {
      delete params.categoryId;
      setCategoryPid(10000);
    }
    if (params.categoryId === 1000) {
      delete params.categoryId;
    }

    if (params.categoryId === 10000) {
      params.isRecommend = 1;
      delete params.categoryId;
    }

    if (sortField) {
      params.sortField = sortField;
    }
    if (timeField) {
      params.timeField = Number(timeField);
    }

    if (mediaType) {
      params.mediaType = Number(mediaType);
    }
    if (hasVideo) {
      params.hasVideo = Number(hasVideo);
    }

    if (mediaCategory) {
      if (mediaCategory === "全部创作类型") {
        delete params.mediaCategory
      } else {
        params.mediaCategory = Number(mediaCategory);
      }

    }
    setLoading(true);
    if (!type) {
      setListParams(params)
      getWorkList(params)
        .then((res) => {
          setWorkList([...res.pageData]);
          setTotalSize(res.totalSize);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const articleParams: any = {
        pageSize: pageSize,
        pageNumber,
        dataTable: "article",
      };
      if (articleType) {
        articleParams.type = articleType;
      }
      if (sortField) {
        articleParams.sortField = sortField;
      }
      if (categoryId && Number(categoryId) > 0) {
        articleParams.categoryId = categoryId;
      }

      postGlobalSearch(articleParams)
        .then((res) => {
          setArticleList([...res.pageData]);
          setTotalSize(res.totalSize);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    categoryPid,
    categoryId,
    currentPage,
    sortField,
    timeField,
    mediaType,
    mediaCategory,
    pageNumber,
    type,
    articleType,
  ]);

  const onClickChooseFirst = (item: CategoryItem) => {
    if (item.name === "文章") {
      setCategoryId("");
      setCategoryPid("");
      const query = queryUrl({
        categoryPid: "",
        categoryId: "",
        pageNumber: 1,
        type: "article",
      });
      navigate(`?${query}`);
    } else if (item.id === 10000) {
      setCategoryId(10000);
      setCategoryPid(item.id);
      const query = queryUrl({
        categoryPid: 10000,
        categoryId: "",
        sortField: "recommendUpdateTime",
        pageNumber: 1,
        mediaCategory: "",
        type: "",
        articleType: "",
      });
      navigate(`?${query}`);
    } else if (item.id === 1000) {
      setCategoryId(1000);
      setCategoryPid(item.id);
      const query = queryUrl({
        categoryPid: 1000,
        categoryId: "",
        sortField: "lastSubmitTime",
        pageNumber: 1,
        mediaCategory: "",
        type: "",
        articleType: "",
      });
      navigate(`?${query}`);
    } else {
      setCategoryId(-1);
      setCategoryPid(item.id);
      const query = queryUrl({
        categoryPid: item.id,
        articleType: "",
        categoryId: "",
        sortField: "recommendUpdateTime",
        timeField: "",
        mediaCategory: "",
        pageNumber: 1,
        type: "",
      });
      navigate(`?${query}`);
    }
    setCurrentPage(1);
  };

  const onClickChooseSecond = (item: CategoryItem) => {
    if (type === "article") {
      setCategoryPid(9999);
    } else {
      setCategoryPid(item.pid);
    }

    let query: string;
    if (categoryId === item.id) {
      query = queryUrl({ categoryId: "", pageNumber: 1 });
      setCategoryId(-1);
    } else {
      query = queryUrl({
        categoryPid: item.pid,
        categoryId: item.id,
        pageNumber: 1
      });
      setCategoryId(item.id);
    }
    setCurrentPage(1);
    navigate(`?${query}`);
  };

  const handleChangeMediaType = (value: boolean) => {
    // console.log(`handleChangeMediaType`,value)
    const query = queryUrl({
      mediaType: value ? "2" : "",
      pageNumber: 1,
      hasVideo: value ? "1" : undefined,
    });
    navigate(`?${query}`);
  };

  const handleChangeMediaCategory = (value: string) => {
    const query = queryUrl({ mediaCategory: value, pageNumber: 1 });
    // setMediaCategory(value)
    navigate(`?${query}`);
  };

  const onClickArticleAll = () => {
    if (!categoryId || categoryId == -1) {
      return
    }
    const query = queryUrl({ categoryId: "", pageNumber: 1 });
    setCategoryId(-1);
    navigate(`?${query}`);
  };


  return (
    <DiscoverListStyle >
      <div className="category-search-container" id="scrollableDiv">
        <div className="guanggao-banner-wrapper">
          <GuanggaoBannerCarousel isAutoplay positionCode={9}></GuanggaoBannerCarousel>
        </div>
        <Spin spinning={loading}>
          <div className={`search-wrapper ${!type ? "" : "article-wrapper"}`}>
            <Tabs
              size="large"
              activeKey={`${categoryPid}`}
              items={categoryList?.map((item) => {
                return {
                  label: (
                    <div
                      className="tab-name"
                      onClick={() => onClickChooseFirst(item)}
                    >
                      {item.name}
                    </div>
                  ),
                  key: String(item.id),
                  children: (
                    <div className="second-wrapper">
                      {item.children?.map((child) => {
                        return (
                          <div
                            onClick={() => onClickChooseSecond(child)}
                            className={`second-item ${Number(categoryId) === child.id
                              ? "second-item-active"
                              : ""
                              }`}
                            key={child.id}
                          >
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(${CoverImageObjectCloudResize(
                                  child.coverUrl,
                                  500
                                )})`,
                              }}
                            ></div>
                            <div className="name">{child.name}</div>
                          </div>
                        );
                      })}
                    </div>
                  ),
                };
              })}
            ></Tabs>
          </div>
          {type && (
            <div className="article-type">
              <div className="article-list">
                <div
                  className={`article-list-item ${!categoryId || categoryId === -1 ? "active" : ""
                    }`}
                  onClick={onClickArticleAll}
                >
                  <span>全部</span>
                  <div
                    className="bg"
                    style={{
                      backgroundImage: "url('https://cdn-prd.ggac.com/ggacadmin/content/category/edit/coverImage/all_bg.jpg')"
                    }}
                  ></div>
                </div>
                {articleTypeList &&
                  articleTypeList.map((item, index) => {
                    return (
                      <div
                        onClick={() => onClickChooseSecond(item)}
                        className={`article-list-item ${item.id === categoryId ? "active" : ""
                          }`}
                        key={index}
                      >
                        <span>{item.name}</span>
                        <div
                          className="bg"
                          style={{
                            backgroundImage: `url(${CoverImageObjectCloudResize(
                              item.coverUrl,
                              500
                            )})`,
                          }}
                        ></div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {!type && (
            <>
              <div className="select">
                <div style={{ display: "flex" }}>
                  <SearchOption isShowTypeList={false} />
                  <Select
                    style={{ width: 200, marginLeft: "15px" }}
                    onChange={handleChangeMediaCategory}
                    options={mediaCategoryOptions}
                    placeholder="创作类型"
                    value={mediaCategory}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <Checkbox style={{ marginRight: "10px" }} checked={dataTable === "article" ? true : false} onChange={(e) => changeIsArticle(e.target.checked)}>文章</Checkbox> */}
                  <Checkbox
                    checked={mediaType === "2" ? true : false}
                    onChange={(e) => handleChangeMediaType(e.target.checked)}
                  >
                    视频
                  </Checkbox>
                </div>
              </div>
              <div className="list-wrapper" >
                {workList.length === 0 && !loading && (
                  <div style={{ marginTop: "200px" }}>
                    <NoData></NoData>
                  </div>
                )}
                {workList.length > 0 && (
                  <div className="list">
                    {workList.map((item, index) => {
                      return <div onClick={() => saveWorkListParamsForNav(index)} key={index} >

                        <PublicCard item={item} t_id={t_id}></PublicCard>
                      </div>
                        ;
                    })}
                  </div>
                )}
              </div>
            </>
          )}
          {type && (
            <div className="list-wrapper">
              <div style={{ display: "flex" }}>
                <SearchOption isShowTypeList={false} />
              </div>
              {articleList.length === 0 && !loading && (
                <div style={{ marginTop: "200px" }}>
                  <NoData></NoData>
                </div>
              )}
              {articleList.length > 0 && (
                <div className="list article">
                  {articleList.map((item, index) => {
                    return (
                      <PublicEsCard key={index} item={item}></PublicEsCard>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {(workList.length > 0 || articleList.length > 0) && (
            <Pagination
              style={{ textAlign: "center", margin: "12px 0 42px 0" }}
              hideOnSinglePage={true}
              current={pageNumber}
              onChange={changePage}
              pageSize={pageSize}
              total={totalSize}
              showSizeChanger={false}
              showQuickJumper
            />
          )}
        </Spin>
      </div>
      <Modal width={450} footer={<></>} title="" destroyOnClose={true} closable={true} onCancel={() => setIsShowLoginModal(false)} centered={true} open={isShowLoginModal}>
        <LoginModal />
      </Modal>
    </DiscoverListStyle>
  );
};
