import { Outlet, useNavigate } from "react-router-dom"
import { ForumLayoutStyle } from "./style"
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel"
import { Button, Modal } from "antd"
import { useEffect, useState } from "react"
import { getAnswererDetail } from "api/forum"
import { hasToken } from "utils/functions/common"
import ToBeAnswerer from "../reward/components/toBeAnswerer"
import { loginPathWithRedirect } from "utils/functions/user"

const ForumLayout = ({ value }: { value: string }) => {

    const navigate = useNavigate()

    const [isShowAnswererBtn, setIsShowAnswererBtn] = useState(false)

    const [isShowAnswerer, setIsShowAnswerer] = useState(false)

    const token = hasToken()

    useEffect(() => {
        if (!token) {
            return
        }
        getIsShowAnswererBtnStatus()
    }, [])

    const getIsShowAnswererBtnStatus = () => {
        getAnswererDetail().then(res => {
            if (!res) {
                setIsShowAnswererBtn(true)
            } else {
                setIsShowAnswererBtn(false)
            }
        })
    }

    const saveSuccess = () => {
        setIsShowAnswerer(false)
        getIsShowAnswererBtnStatus()
    }

    const toBeAnswerer = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        setIsShowAnswerer(true)
    }

    const tabList = [
        { name: "话题", value: "question", id: 1 },
        { name: "悬赏", value: "reward", id: 2 },
        { name: "答主", value: "answerer", id: 3 }
    ]

    const toChange = (detail: { name: string, value: string, id: number }) => {
        navigate(`/forum/${detail.value}/index`)
    }

    return <ForumLayoutStyle>
        <div className="container">
            <div className="guanggao-banner-wrapper">
                <GuanggaoBannerCarousel positionCode={27}></GuanggaoBannerCarousel>
            </div>
            <div className="tab">
                {
                    tabList.map(item => {
                        return <div onClick={() => toChange(item)} key={item.id} className={`tab-item ${value === item.value ? 'active' : ''}`}>{item.name}</div>
                    })
                }
                {
                    isShowAnswererBtn && ["/forum/reward/index", "/forum/answerer/index"].includes(window.location.pathname) && <Button type="primary" className="btn" onClick={toBeAnswerer}>成为答主</Button>
                }
            </div>
        </div>
        <Outlet></Outlet>

        <Modal getContainer={false} onCancel={() => setIsShowAnswerer(false)} title='成为答主' footer={<></>} width={650} destroyOnClose={true} centered={true} open={isShowAnswerer}>
            <ToBeAnswerer saveSuccess={saveSuccess} />
        </Modal>

    </ForumLayoutStyle>
}

export default ForumLayout