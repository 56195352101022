import styled from "styled-components";

export  const  SubChildrenSelectStyle = styled.div`
    .second-select-container {
        .item-wrapper {
            display: flex;
            
            flex-direction: column;
            .first-wrapper{
                 padding: ${props => props.theme.space.paddingSM}px 0;
                 width: 220px;
            }
           .second-wrapper{
            display: flex;
            flex-wrap: wrap;
            .second-wrapper-item {
                margin: ${props => props.theme.space.marginXS}px;
                padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
                background-color: ${props => props.theme.colors.colorBgContainer};
                border-radius: ${props => props.theme.border.borderRadius}px;
                border: 1px solid ${props => props.theme.colors.colorBorder};
                position: relative;
                cursor: pointer;
                &:first-child{
                    margin-left: 0;
                }
            }
            .active {
                background-color: ${props => props.theme.colors.colorPrimary};
            }
           }
        }
        
    }
`