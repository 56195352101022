import { ArticleListItem } from "types/article";
import { translateCardCategoryText } from "utils/functions/common";
import CardTitleRender from "components/uni-card/common/card-title/article";
import UniCardLayout from "components/uni-card/layout";
import PublicArticleAction from "./publicArticleAction";
import PublicArticleStatus from "./publicArticleStatus";

const UserCenterArticleCard = ({ item, deleteWork }: { item: ArticleListItem, deleteWork: (id: number) => void }) => {

    const workLink = `/article/detail/${item.id}`

    const UiObject = {
        coverUrl: item.coverUrl,
        title: <CardTitleRender item={item} />,
        subtitle: <span> {translateCardCategoryText(item.categoryList)}</span>,
        view: item.viewCount,
        hot: item.hot,
        date:item.lastSubmitTime,
        workLink:item.onSale === 1 ? workLink: '',
        userLink: `/user/${item.userId}`,
        centerNode: <PublicArticleAction item={item} workLink={workLink} deleteWork={deleteWork} />,
        leftBottomNode:<PublicArticleStatus item={item} />,
        isPublish: true
    }
    return UniCardLayout({ item: UiObject });
}


export default UserCenterArticleCard