import { Modal, Pagination, Spin, message } from "antd"
import { AlbumDetailStyle } from "./style"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import { WorkListItem } from "types/work"
import { getAlbumWorkList, postAddToAlbum } from "api/album"
import { NoData } from "components/no-data"
import { PaginationResponse } from "types/common/axios"
import GoBackTitle from "ui-lib/go-back"
import UserCenterAlbumCard from "components/uni-card/self-cards/userCenterAlbumCard"
import { useNavigate } from "react-router-dom"
import useWindowSize from "hooks/useWindowSize"


const AlbumDetail = () => {
    const [albumList, setAlbumList] = useState<WorkListItem[]>([])
    const [pageNumber, setPageNumber] = useState(1)
    const [albumData, setAlbumData] = useState<PaginationResponse<WorkListItem>>()
    const id = useParams().id
    const [screenSize] = useWindowSize();
    const params = { id: Number(id), pageNumber, pageSize:30 }
    const getList = useCallback(() => {
        setLoading(true)
        getAlbumWorkList(params).then(res => {
            setAlbumList(res.pageData)
            setAlbumData(res)
        }).finally(() => {
            setLoading(false)
        })
    }, [id,pageNumber])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getList()
    }, [pageNumber])

    const update = () => {
        getList()
    }

    const changePage = (e: number) => {
        console.log(e)
        setPageNumber(e);
    };

    const navigate = useNavigate()

    const toBack = () => {
        navigate(`/user-center/home/work-album`, { replace: true })
    }

    const deleteAlbum = (item: WorkListItem) => {
        Modal.confirm({
            title: '移出作品集？',
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                const params = {
                    workId: item.id,
                    portfolioId: Number(id),
                    flag: 0
                }
                postAddToAlbum(params).then(() => {
                    message.success('移出成功')
                    update()
                })
            },
        });
    }

    return <AlbumDetailStyle>
        <div className="top">
            <div onClick={toBack}> <GoBackTitle >返回作品集列表</GoBackTitle></div>
            <Spin spinning={loading}>
                {
                    albumList.length === 0 &&
                    <div style={{ padding: '200px 0', top: "50px" }}>
                        <NoData />
                    </div>
                }
                <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
                    {
                        albumList.length > 0 && albumList.map((item,index) => {
                            return <UserCenterAlbumCard deleteAlbum={deleteAlbum} item={item} key={index}/>
                        })
                    }
                </div>
            </Spin>
            <Pagination
                style={{ textAlign: "center", marginTop: "28px" }}
                hideOnSinglePage={true}
                current={pageNumber}
                showSizeChanger={false}
                onChange={changePage}
                pageSize={params.pageSize}
                total={albumData?.totalSize}
            />
        </div>
    </AlbumDetailStyle>
}

export default AlbumDetail