import { get, post } from "api";

import {
  userAllDetail,
  userCenterDetail,
  updateAvatarRequest,
  UserStatistics,
  publicUserDetailType,
  PublicUserStatistics,
} from "types/user/detail";

//获取用户所有信息
export const apiUserDetail = async () =>
  await get<{}, userAllDetail>(`/api/user/full_info`);

// 获取其他用户所有信息
export const apiPublicUserDetail = (userId: number) =>
  get<{}, publicUserDetailType>(`/api/all_user/detail/${userId}`);

//获取用户统计信息
export const apiUserStatistics = async () =>
  await get<{}, UserStatistics>(`/api/user/data_info`);

//获取用户统计信息
export const apiPublicUserStatistics = async ({ id }: { id: number }) =>
  await get<{ id: string }, PublicUserStatistics>(
    `/api/all_user/data_info/${id}`
  );

// 修改用户个人中心信息
export const postUserDetail = async (params: userCenterDetail) =>
  await post<{}, userCenterDetail>(`/api/user/info_update`, params);

// 修改用户头像
export const postUpdateUserAvatar = async (params: updateAvatarRequest) =>
  await post<updateAvatarRequest, {}>(`/api/user/avatar_update`, params);
