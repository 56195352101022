import { PublicProductDetail } from "types/product"
import { ProductCommentStyle } from "./style"
import Comments from "components/comments"
import { useRef } from "react";
import { useParams } from "react-router-dom";
import FloatChatInput from "views/work/detail/right/floatChatInput";
import { CommentListItem } from "types/comment";

const ProductComment = ({ detail, update }: { detail: PublicProductDetail, update: () => void }) => {
    const commentRef = useRef() as React.MutableRefObject<any>;
    const { productId } = useParams<any>();
    const updateCommentCount = () => {
        update && update()
    }

    // 调用commet创建方法
    const createComment = (val: CommentListItem) => {
        commentRef.current?.addComment(val)
    }

    return <ProductCommentStyle>
        <div className="container">
            <div id="input-wrap">
                <FloatChatInput params={{ createComment, dataTable: "mall_product", subId: Number(productId) }}></FloatChatInput>
            </div>
            <Comments ref={commentRef} params={{ commentCount: detail.commentCount, updateCommentCount, type: 'mall_product', id: Number(productId) }}></Comments>

        </div>
    </ProductCommentStyle>
}

export default ProductComment