import { Modal } from "antd"
import { CzwjModalStyle } from "./style"

const CzwjModal = ({ isShow = false, close, finallysubmit }: { isShow: boolean, close: () => void, finallysubmit: (value: number, isShow: boolean) => void }) => {
    const handleOk = () => {
        close()
        finallysubmit(2, false)
    }
    const handleCancel = () => {
        close()
    }
    return <CzwjModalStyle>
        <Modal title="" centered className="modal" getContainer={false} okText="我确认已完成排查" open={isShow} onOk={handleOk} onCancel={handleCancel}>
            <div className="wrap">
                <div>亲爱的侠士您好，</div>
                <div>非常感谢您的参与，请确保您已经认真阅读并理解全新活动规则，且已经根据活动规则要求完成风险排查。</div>
                <div>1.请确保投稿作品为您本人原创，不存在侵权内容。</div>
                <div>2.如使用了第三方有版权素材，已经在作品图上展示了相应的授权证明。</div>
                <div>3.如使用无版权素材（即进入公有领域的作品，比如历史文物等），已经在作品图上标注引用出处，以及作品简介资料。</div>
                <div>4.为避免后续争议，请确保授权证明已打好水印。</div>
                <div>5.如有特殊需要，愿意配合主办方要求提供包括不限于创作过程等资料，以证明作品的原创性和独创性。</div>
                <div>重新投稿时间：9.28-10.15晚23:59:59</div>
            </div>
        </Modal>
    </CzwjModalStyle>
}


export default CzwjModal