import {TagSelectStyle} from './style'
import React, { useEffect, useMemo, useState } from 'react';
import { Select, Tag, message } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import UiTag from 'ui-lib/tag';
import { RiRefreshLine } from 'react-icons/ri';
import {  getRecommendCategoryTagList } from 'api/category';
import { TagItem } from 'types/category';
import { debounce } from 'lodash';



type TagsData =  {id: number, name: string}

export const ProductTagSelect = (props:{value?: string[], categoryId?:number, dataTable: string, onChange?:(tags: string[])=>void, maxCount?: number}) => {
    const [topList, setTopList] = useState<TagItem[]>([])
    const [bottomList, setBottomList] = useState<TagItem[]>([])
    const [tagValue, setTagValue] = useState<string[]>([])



    const [loading, setLoading] = useState(false)
    const [categoryId, setCategoryId] = useState<number>()

    const  pageSize = 20

    const getEntityList = async (searchValue?: string) =>{
      let params = {
        pageSize: pageSize,
        dataTable: props.dataTable,
        flag: 1,
        name: searchValue
      }
      await getRecommendCategoryTagList(params).then(res=> {
        if(res.length>=20){
          setTopList(res.slice(0,9))
          setBottomList(res.slice(9,19))
        }else{
          setBottomList(res.slice(0,9))
        }
 
      })
    }


    const getNameSearchList = async (searchValue?: string) =>{
      let params = {
        pageSize: pageSize,
        dataTable: props.dataTable,
        flag: 1,
        name: searchValue
      }
      await getRecommendCategoryTagList(params).then(res=> {
       setTopList(res)
      })
    }

    useEffect(()=>{
      !categoryId && getEntityList()
    }, [])

    const getCateList = async () =>{
      let params = {
        pageSize: pageSize,
        dataTable: 'category',
        dataId: props.categoryId,
        flag: 0,
      }
      await getRecommendCategoryTagList(params).then(res=> {
        if(res.length > 0) {
            setBottomList(res)
        }
      })
    }



    useEffect(()=>{
      if(JSON.stringify(props.categoryId) === JSON.stringify(categoryId)) {
        return 
      }
      setCategoryId(categoryId)
      getCateList()
    }, [props.categoryId])

    const getSearchList = (value: string) =>{
      if(loading) return 
      setLoading(true)
       getNameSearchList(value)
      setLoading(false)
    }

 


    // const debounceFetcher = useMemo(() => {
    //   const loadOptions = (value: string) => {

    const  onSearchTagsList= useMemo(() => {
      getSearchList('')
      const loadOptions = (value: string) => {
        getSearchList(value)
      }
      return debounce(loadOptions, 500)
    },[])

      
      // setSearchValue(val)
      // let s = debounce(()=>getSearchList())
      // s()
      // }
    useEffect(()=>{
        if(props.value) {
            setTagValue([...props.value])
        }
    }, [props.value])

    const onClickAddTag = (item: TagsData) =>{
      if(tagValue.includes(item.name)) {
        message.error('重复添加！')
        return 
      }
      if(tagValue.length >= props.maxCount! )  {
          message.error(`超过标签最大数量：${props.maxCount}`)
        return
      }
        let arr = [...tagValue]
        arr?.push(item.name)
        setTagValue(arr)
        props.onChange && props.onChange(arr!)
    }

    const handleChange = (value: string[]) => {
      if(value.length>5) {
        message.error(`超过标签最大数量：${props.maxCount}`)
        return 
      }
        let arr = [...value]
        setTagValue(arr)
        props.onChange && props.onChange(arr)
      };

    const  onclickChangeTag = () =>{
       getEntityList()
    }


    return <TagSelectStyle>
            <div className='container'>
                <Select
                  filterOption={true}
                    mode="tags"
                    loading={loading}
                    onSearch={onSearchTagsList}
                   
                    value={tagValue}
                    // searchValue={searchValue}
                    onChange={handleChange}
                    tagRender={tagRender}
                    size='middle'
                    fieldNames={{ label: 'name', value: 'name' }}
                    options={topList}
                />
                <div className='tag-list-wrapper'>
                  {
                    <div className='title'>
                      <span>推荐标签</span>
                      <div className='alternative' onClick={onclickChangeTag}>
                       <RiRefreshLine></RiRefreshLine> <span>换一组</span>
                      </div>
                  </div>
                  }
                    
                    <div className='list-wrapper'>
                            {bottomList.map((item)=>{
                                return <UiTag clickable key={item.id}  onClick={()=>onClickAddTag(item)} className="tag" theme="gray-3" >{item.name}</UiTag>
                            })}
                    </div>
                </div>
            </div>
        </TagSelectStyle>
}

const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3,fontSize:14,padding:'4px 8px' }}
    >
      {label}
    </Tag>
  );
};
