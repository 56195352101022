import styled from "styled-components";

export const MediaIconsStyle = styled.div`
    display: flex;
    .item{
      padding:  ${(props) => props.theme.space.paddingXXS}px;
      background-color: rgba(0, 0, 0, 0.3);
      height: 26px;
      width: 26px;
      color:  ${(props) => props.theme.colors.colorTextLightSolid};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      &:first-child{
        border-radius: 5px 0 0 0;
      }
    }
`