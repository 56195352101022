import styled from "styled-components";

export const ForumIndexStyle = styled.div`
    .container{
        width: 1032px;
        margin: 0 auto;
        display: grid;
        grid-template-columns:  minmax(737px, 5fr)  minmax(281px, 2fr);
        gap:14px;
        min-height: 100vh;
        .left{
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            padding: ${(props) => props.theme.space.paddingXS}px 0 ${(props) => props.theme.space.paddingLG}px;
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px  ${(props) => props.theme.border.borderRadiusLG}px 0 0;
        }
        
        .right{
            width: 281px;
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            padding: ${(props) => props.theme.space.paddingLG}px;
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px  ${(props) => props.theme.border.borderRadiusLG}px 0 0;

        }
    }
`