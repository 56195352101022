import styled from "styled-components";

export const EventStyle = styled.div`
  color: #a93c1c;
  overflow: hidden;
  .bold-red {
    color: #e6400f;
    text-decoration: underline;
  }
  .logo-title {
    height: fit-content;
    width: fit-content;
    position: relative;
    margin: 0 auto;
    padding: 24px 0;
    .text_ {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      top: 50%;
      transform: translateY(-54%);
      color: #e6400f;
      font-size: 24px;
    }
  }
  section {
    background-repeat: no-repeat;
    background-size: contain;
  }
  .top_ {
    width: 100vw;
    background-color: #f9f1e7;
    height: fit-content;
    position: relative;
    .top-bg {
      width: 100%;
      object-fit: cover;
      display: block;
    }
    .top-btn {
      position: absolute;
      bottom: 32%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .content {
    background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    /* aspect-ratio: 1920/9759; */
    padding-top: 80px;
    .inner-container {
      max-width: 1920px;
      margin: 0 auto;
      .section-title {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .welcome {
        width: 100%;
        max-width: 1306px;
        margin: 0 auto;
        aspect-ratio: 1306/528;
        background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/welcome-bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .left-deco {
          position: absolute;
          left: -200px;
          bottom: -40px;
        }
        .right-deco {
          position: absolute;
          right: -200px;
          bottom: 0px;
        }
        .text {
          text-align: center;
          padding-bottom: 48px;
          font-size: 20px;
          line-height: 20px;
        }
      }
      .quanjiatong {
        padding-top: 90px;
        .item-list {
          padding-top: 80px;
          width: 1404px;
          margin: 0 auto;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          &.sp {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            padding-top: 36px;
          }
          .item {
            position: relative;
            width: fit-content;
            height: fit-content;
            position: relative;
            margin: 0 auto;
            .overlay {
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              z-index: 10;
              padding: 11px;

              .cover {
                width: 100%;
                aspect-ratio: 204/195;
                background-size: cover;
              }
              .name {
                font-size: 18px;
                text-align: center;
                line-height: 42px;
              }
            }
          }
        }
        .more {
          width: 100%;
          margin-top: 50px;
          display: flex;
          justify-content: center;
          img {
          }
        }
      }
      .huodongshijian {
        padding-top: 90px;
        .time-content {
          margin: 0 auto;
          margin-top: 60px;
          width: fit-content;
          .time-grid {
            width: 100%;
            /* display: flex;
                    justify-content: space-between;
                    align-items: center; */
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            transform: scaleX(1.11);
            span {
              font-size: 18px;
              padding-top: 4px;
              text-align: center;
            }
          }
        }
      }
      .red-part {
        padding-top: 150px;
        max-width: 1920px;
        aspect-ratio: 1920/5787;
        margin: 0 auto;
        height: fit-content;
        background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1227/red-bg-new.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 100px;
        &.sp{
          background-image:none;
          aspect-ratio: 1920/920;
          margin-top: 0;
        }
        .container_ {
          max-width: 1306px;
          margin: 0 auto;
          margin-top: 40px;
          font-size: 20px;
          .time {
            padding: 40px 0;
            padding-bottom: 0px;
            text-decoration: underline;
          }
          .logo-title {
            margin-top: 20px;
          }
          .text {
            p {
              line-height: 16px;
            }
          }

          .sp {
            text-align: left;
            p {
              padding: 2px 0;
              line-height: 32px;
            }
          }
          .tip{
              margin-top: 40px;
          }
        }
        .zaoniaofu {
          aspect-ratio: 1315/2046;
          background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-zaoniaofu-bg.png");
          background-size: contain;
          background-repeat: no-repeat;
          text-align: center;
          padding: 100px 200px;
          position: relative;
          .left-deco{
            position: absolute;
            left: -300px;
            top:300px;
          }
          .cards-1 {
            background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-zaoniaofu-card-1.png");
            background-size: contain;
            background-repeat: no-repeat;
            aspect-ratio: 650/291;
            width: 650px;
            margin: 0 auto;
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;
            .item{
              cursor: pointer;
              display: flex;
              flex-direction: column;
              .a{
                flex:1;
              

              }
              .b{
                height: 30px;
               
                width: 50%;
                margin-bottom:15px;
              }
            }
          }
          .cards-2 {
            background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-zaoniaofu-card-2.png");
            background-size: contain;
            background-repeat: no-repeat;
            aspect-ratio: 866/312;
            width: 866px;
            margin: 0 auto;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            a{display: block;}
            .top{
              height: 48px;
              width: 290px;
              margin: 0 auto;
              cursor: pointer;
            }
            .row{
              width: 100%;
              flex:1;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              a{
                width: 100%;
               
              }
            }
          }
          .cards-3 {
            background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-zaoniaofu-card-3.png");
            background-size: contain;
            background-repeat: no-repeat;
            aspect-ratio: 866/312;
            width: 866px;
            margin: 0 auto;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            a{display: block;}
            .top{
              height: 48px;
              width: 290px;
              margin: 0 auto;
              cursor: pointer;
            }
            .row{
              width: 100%;
              flex:1;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              a{
                width: 100%;
               
              }
            }
          }
        }
        .jingyefu {
          position: relative;
          aspect-ratio: 1315/1186;
          background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-jingyefu-bg.png");
          background-size: contain;
          background-repeat: no-repeat;
          padding: 100px 200px;
          .right-deco{
            position: absolute;
            right: -200px;
            bottom:10px;
          }
          .overlay {
            color: #7c2001;
            text-align: center;
            .btn {
              margin-top: 70px;
              cursor: pointer;
              &:hover {
                img {
                  filter: contrast(1.5);
                }
              }
            }
          }
        }
        .hualifu {
          position: relative;
          aspect-ratio: 1315/1949;
          background-image: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/pc-new-1218/red-part-hualifu-bg.png");
          background-size: contain;
          background-repeat: no-repeat;
          padding: 100px 200px;
          .left-deco{
            position: absolute;
            left: -300px;
            bottom:440px;
          }
          .overlay {
           
            color: #7c2001;
            text-align: center;
          
            .list {
              position: relative;
              width: fit-content;
              margin: 0 auto;
              margin-bottom: 40px;
              &.three {
                cursor: pointer;
                margin-bottom: 30px;
              }
              .over-lay {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                .click-item {
                  height: 280px;
                  width: 230px;
                  cursor: pointer;
                  &:nth-child(2) {
                    margin-left: 28px;
                  }
                  &:nth-child(3) {
                    margin-left: 58px;
                  }
                }
              }
            }
          }
        }
      }
      .jingqingqidai-1 {
        margin-top: 120px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-right: 106px;
      }
      .fuli {
        margin-top: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .kaidian {
        margin: 0 auto;
        margin-top: 140px;
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .btn {
          margin-top: 60px;
          cursor: pointer;
          &:hover {
            img {
              filter: contrast(1.5);
            }
          }
        }
      }
      .gengduohuodong {
        margin: 0 auto;
        margin-top: 140px;
        width: fit-content;
        height: fit-content;
        .more-event {
          margin: 0 auto;
          margin-top: 80px;
          width: fit-content;
          height: fit-content;
          position: relative;
          .overlay {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2px;
            .click-item {
              height: 260px;
              cursor: pointer;
            }
          }
        }
      }
      .statement {
        margin-top: 140px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 18px;
      }
      .footer {
        margin: 0 auto;
        margin-top: 20px;
        max-width: 1920px;
        position: relative;
        img {
          width: 100%;
        }
        .logo {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
`;
