import styled from "styled-components";


export const ShareStyle = styled.div`

    .container_{
        display: flex;
        .left{
            width: 320px;
            display: flex;
            align-items: center;
            .share-img{
                object-fit: contain;
                width: 100%;
                border-radius:  ${props=> props.theme.border.borderRadius}px;
                box-shadow: ${props=> props.theme.shadow.boxShadow};
            }
        }

        .right{
           flex: 1;
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           .up{
            text-align: center;
            padding:${props=> props.theme.space.paddingLG}px;
           }
           .qr-code {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                margin-bottom: 20px;
                img {
                    width: 150px;
                }
           }
           .down{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap:  ${props=> props.theme.space.paddingLG}px;
            margin-left: ${props=> props.theme.space.paddingLG }px;
            padding: 0 ${props=> props.theme.space.padding}px;
           
            .item{
                font-size: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                .icon{
                    width: 100%;
                    aspect-ratio: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color:#fff;
                    background-color:${props=> props.theme.colors.colorPrimary};
                    border-radius: 50%;
                }
                span{
                    font-size: ${props=> props.theme.fontSizes.fontSize}px;
                    padding:${props=> props.theme.space.padding}px 0;
                }
            }
           }
        }

        .wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }
    }

`