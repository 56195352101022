export const notification = 
{ 
    type: {
        all: 'all',
        system: 'system',
        favour: 'favour',
        comment: 'comment',
        subscribe: 'subscribe'
    },
    messageBox: 'message box',
    
}
