import CommonTabs, { Tab } from "ui-lib/tab/common-tab";
import { MyDataListStyle } from "./style";
import { myDataTabs } from "../config";
import { useNavigate, useParams } from "react-router-dom";
import MyTopicList from "./myTopicList";
import MyReplyList from "./myReplyList";
import PublishTopic from "../common/publish";
import GoBackTitle from "ui-lib/go-back";
import SearchInput from "../common/search-input";
import HotTags from "../common/hot-tags";


function MyForumData() {
  const { tab } = useParams();
  const navigate = useNavigate()

  const link = () => {
    navigate(`/forum/question/index`, { replace: true })
  }

  const checkTab = (item: Tab) => {
    navigate("/forum/question/my/" + item.key);
  };
  return (
    <MyDataListStyle>
      <div className="container">
        <div className="left">
          <div className="list-title">
            <div className="link" onClick={() => link()}>
              返回 话题主页
            </div>
            <span>{`>`}</span>
            {
              tab === "topic" && <>我的发布</>
            }
            {
              tab === "reply" && <>我的回复</>
            }
            {
              tab === "favorite" && <>我的收藏</>
            }
          </div>

          <div className="tabs">
            <CommonTabs
              tabs={myDataTabs}
              onClick={(item) => checkTab(item)}
              defaultKey={tab}
            ></CommonTabs>
          </div>
          <div className="topic-list">
            {tab === "topic" && <MyTopicList></MyTopicList>}
            {tab === "reply" && <MyReplyList></MyReplyList>}
            {tab === "favorite" && <MyReplyList></MyReplyList>}
          </div>
        </div>
        <div className="right">
          <SearchInput></SearchInput>
          <PublishTopic></PublishTopic>
          <HotTags></HotTags>
        </div>
      </div>
    </MyDataListStyle>
  );
}

export default MyForumData;
