import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import EventList from "views/events";
import EventContribution from "views/events/contribution"
import EventExhibition from "views/events/exhibition";
import ExhibitionList from "views/events/exhibition/list";
import Xnwf from "views/events/custom-event/20231201-xnwf";
import Xnwf0 from "views/events/custom-event/20231201-xnwf/index_0";
import XnwfWork from "views/events/custom-event/20231201-xnwf/rank/work";
import XnwfUser from "views/events/custom-event/20231201-xnwf/rank/user";


export const eventRoutes: RouteObject[] = [
    {
        path: '/',
        element: <Layout></Layout>,
        children: [
            {
                path: "events",
                element: <EventList />
            },
            {
                path: "events/contribution",
                element: <EventContribution />
            },
            {
                path: "events/exhibition/:id",
                element: <EventExhibition />
            },
            {
                path: "events/exhibition/list",
                element: <ExhibitionList />
            },
            // {
            //     path: "events/custom/xnwf",
            //     element: <Xnwf />
            // },
            {
                path: "events/custom/xnwf",
                element: <Xnwf0 />
            },
            {
                path: "events/custom/xnwf/work",
                element: [<XnwfWork />]
            },
            {
                path: "events/custom/xnwf/user",
                element: <XnwfUser />
            }
        ]
    },
]