import { MenuItem } from "types/common";
import { ThirdMenuStyle } from "./style";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  menus: MenuItem[];
};

function ThirdMeus({ menus }: Props) {
  const navigate = useNavigate();
  const domRef = useRef<any>(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(domRef?.current?.offsetWidth);
  }, [width]);

  const handleMenuClick = (menu: MenuItem)=>{
    if(menu.children.length===0){
      if( menu.isNewWeb===1){
        window.open(menu.url,"_blank"); 
      }else{
        if(menu.isHttp===1 ){
          window.open(menu.url,"_self"); 
        }else{
          navigate(menu.url)
        }
      }
    }
  }

  return (
    <ThirdMenuStyle ref={domRef} offWidth={width ?? 0}>
      <div className="third-menu">
        {menus.map((menu, index) => (
          <div
            className="third-item no-cover"
            key={index}
            onClick={()=>handleMenuClick(menu)}
          >
           
             {menu.name}
           
          </div>
        ))}
      </div>
    </ThirdMenuStyle>
  );
}

export default ThirdMeus;
