import styled from "styled-components";

export const MyDataListStyle = styled.div`
 .guanggao-banner-wrapper{
        width: 1032px;
        margin: 0 auto;
        padding: ${(props) => props.theme.space.paddingLG}px 0;
        padding-top: 10px;
    }
    .container{
        width: 1032px;
        margin: 0 auto;
        display: grid;
        grid-template-columns:  minmax(737px, 5fr)  minmax(281px, 2fr);
        gap:14px;
        min-height: 100vh;
        .list-title {
          display: flex;
          margin-bottom: 20px;
          padding: 0 ${(props) => props.theme.space.paddingLG}px;
          span {
              padding: 0 5px;
          }
          .link {
              color: #808080;
              cursor: pointer;
              &:hover {
                  color: #FF9A16;
              }
          }
        }
        .left{
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            padding: ${(props) => props.theme.space.paddingLG}px 0;
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px  ${(props) => props.theme.border.borderRadiusLG}px 0 0;
            .back{
                padding-left: ${(props) => props.theme.space.padding}px;
            }
        }
        .right{
            width: 281px;
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            padding: ${(props) => props.theme.space.paddingLG}px;
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px  ${(props) => props.theme.border.borderRadiusLG}px 0 0;
        }
    }
`