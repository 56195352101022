import { get, post } from "api"
import { ArticleDetailResponse, ArticleDraftCreateRequest, ArticleDraftDetailResponse, ArticleDraftUpdateRequest, ArticleListItem, ArticleListRequest, RecommendArticleListItem, RecommendArticleListRequest } from "types/article"
import { PaginationResponse } from "types/common/axios"
// import { PaginationResponse } from 'types/common/axios'


// export const apiGetArticleDetail = async () => {

// }

export const apiGetArticleDraftDetail = async (id: number) => {
    return get<{ id: number }, ArticleDraftDetailResponse>("/api/article_draft/detail/" + id)
}


// // 获取文章标签列表
// export const getArticleTag = () => {
//     return get<never, GetArticleTagResponse>("/api/tag/list")
// }

// 创作草稿
export const createArticleDraft = (params: ArticleDraftCreateRequest) => {
    return post<ArticleDraftCreateRequest, { id: number }>("/api/article_draft/create", params)
}

// // 创作草稿
export const updateArticleDraft = (params: ArticleDraftUpdateRequest) => {
    return post<ArticleDraftUpdateRequest, { id: number }>("/api/article_draft/update", params)
}

//文章列表
export const getArticleList = async (params: ArticleListRequest) => {
    return get<ArticleListRequest, PaginationResponse<ArticleListItem>>("/api/article/list", params)
}

export const getMyArticleList = async (params: ArticleListRequest) => {
    return get<ArticleListRequest, PaginationResponse<ArticleListItem>>("/api/article/mine_list", params)
}

// 获取文章草稿列表
export const getDraftArticleList = async (params: ArticleListRequest) => {
    return get<ArticleListRequest, PaginationResponse<ArticleListItem>>("/api/article_draft/list", params)
}

// 删除文章草稿
export const postDeleteDraftArticle = async (id: number) =>
    post<{ id: number }, ArticleDetailResponse>(`/api/article_draft/delete/${id}`)

// 删除文章
export const postDeleteArticle = async (id: number) =>
    post<{ id: number }, ArticleDetailResponse>(`/api/article/delete/${id}`)

// 获取文章详情
export const getArticleDetail = async (id: number) =>
    get<{ id: number }, ArticleDetailResponse>(`/api/article/detail/${id}`)



export const  getArticleDraftDetail = async(id: number) =>
    get<{ id: number }, ArticleDraftDetailResponse>(`/api/article_draft/detail/${id}`)


export const getRecommendArticleList = (params:RecommendArticleListRequest) =>{
    return get<RecommendArticleListRequest, PaginationResponse<RecommendArticleListItem>>('/api/recommend/article_list', params)
}


// // 提交草稿
// export const submitArticleDraft = (id: number) => {
//     return post<number, never>(`/api/article_draft/submit/${id}`)
// }






