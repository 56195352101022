import styled from "styled-components";

export const DetailStyle = styled.div`
  margin: ${(props) => props.theme.space.marginXS}px 0;
  width: 100%;
  background-color: #fff;
  padding:${(props) => props.theme.space.paddingLG}px;
  .wrap {
    margin-top: ${(props) => props.theme.space.marginLG}px;
    .top {
      display: flex;
      border: 1px solid #E4E4E4;
      background-color: ${(props) => props.theme.colors.colorBgContainer};
      border-radius: 10px;
      .order-detail {
        flex: 1;
        border-right:1px solid #E4E4E4;
      }
      .order-title {
        line-height: 50px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #E4E4E4;
        padding: 0 30px;
      }
      .detail {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .flex {
          display: flex;
          gap: 50px;
        }
        .detail-item {
          display: flex;
          /* height: 40px; */
          align-items: center;
          .label {
            width: 70px;
          }
          .value {
            max-width: 360px;
            word-wrap: break-word;

            &.user-chat-label {
              cursor: pointer;
              color: ${(props) => props.theme.colors.colorLinkDefault};
              display: flex;
              align-items: center;
              div {
                max-width: 140px;
              }
              &:hover {
                color: ${(props) => props.theme.colors.colorLinkHover};
              }
              .icon {
                font-size: 18px;
                color: ${(props) => props.theme.colors.colorPrimary};
                padding-right: ${(props) => props.theme.space.paddingXXS}px;
              }
            }
          }
        }
      }
      .order-status {
        flex: 1;
      }
    }
    .bottom {
      margin-top: ${props => props.theme.space.margin}px;
      border: 1px solid #E4E4E4;
      background-color: ${(props) => props.theme.colors.colorBgContainer};
      margin-top: 50px;
      border-radius: 10px;
      .bottom-item {
        display: flex;
        padding: 30px 0;
        border-bottom: 1px solid #E4E4E4;
        &>div {
          text-align: center;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .sku {
          display: flex;
          gap: 15px;
          justify-content: center;
          align-items: center;
          div {
            text-align: left !important;
          }
          &-info {
            max-width: 200px;
            word-wrap: break-word;
            .name {
              max-width: 200px;
            }
          }
          &-cover {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            background-size: cover;
          }
        }
        &:first-child {
          padding: 10px 0 !important;
          div {
            font-size: 16px;
            font-weight: bold;
          }
        }
        &:last-child {
          border-bottom: transparent !important;
        }
        
      }
      .downloads{
          display: flex;
          flex-direction: column;
          gap:${props => props.theme.space.padding}px;
          padding:${props => props.theme.space.padding}px ${props => props.theme.space.paddingLG}px;
        }
        a{
            color: ${props => props.theme.colors.colorPrimary};
            font-weight: 600;
            &:hover{
              color: ${props => props.theme.colors.colorPrimaryActive};
            }
        }
      
    }
  }
  .modal{
      .title{
          font-size: 25px;
          text-align: center;
      }
      .sub-title {
        font-size: 20px;
        color: red;
        text-align: center;
        margin-bottom: 10px;
      }
      .second-title {
        margin-top: 10px;
        text-align: center;
      }
      .third-title {
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: ${(props) => props.theme.colors.colorLinkDefault};
        display: flex;
        align-items: center;
        width: max-content;
        margin: 0 auto ${props => props.theme.space.marginXS}px;
        div {
          max-width: 140px;
          padding-bottom: 2px;
        }
        .username:hover {
          color: ${(props) => props.theme.colors.colorLinkHover};
        }
        .icon {
          font-size: 18px;
          color: ${(props) => props.theme.colors.colorPrimary};
          padding-right: ${(props) => props.theme.space.paddingXXS}px;
        }
      }
      .code-wrap{
          text-align: center;
          .ant-qrcode {
              margin: 0 auto;
          }
      }

      .code-time {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .ant-statistic-content {
          display: flex;
          align-items: center;
        }

        .ant-statistic-content-value {
          font-size: 16px;
        }
      }
  }
  .bottom-info{
    padding-top:${props => props.theme.space.paddingLG}px;
    .rmb {
    font-size: 14px;
    font-weight: 500;
    color: #202020;
    line-height: 32px;
    text-align: right;
    span {
      color: #C70200;
      font-size: 16px;
      margin-left: ${props => props.theme.space.marginXS}px;
    }
  }
  }


`;
