import { Space, Tooltip } from "antd"
import { GlobalSearchPostResponse } from "types/common";
import { WorkIconsStyle } from "./style";
import { WorkListItem } from "types/work";
import { FavoriteDataListItem } from "types/favorite";
import { WorkContestListItem } from "types/contest";
import { ArticleListItem } from "types/article";
import { WorkLikeListsItem } from "types/user/work";

function HotIcons({ item }: { item: (GlobalSearchPostResponse | WorkListItem | FavoriteDataListItem | WorkContestListItem | ArticleListItem | WorkLikeListsItem) }) {
  return (
    <WorkIconsStyle>
      <Space size={0}>
        {item.hotLevel === 1 && (
          <div className="roundIcon red-light">
            <Tooltip title="热门作品">
              <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-hot-icon.png" alt=""/>
            </Tooltip>
          </div>
        )}
        {item.hotLevel === 2 && (
          <div className="roundIcon red">
            <Tooltip title="超火作品">
              <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-hot-icon2.png" alt=""/>
            </Tooltip>
          </div>
        )}
        {item.hotLevel === 3 && (
          <div className="roundIcon rank-yellow">
            <Tooltip title="榜单作品">
              <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-rank-icon.png" alt=""/>
            </Tooltip>
          </div>
        )}
        {item.recommendLevel === 1 && (
          <div className="roundIcon yellow selected">
            <Tooltip title="精选作品">
              <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-photo-wall-icon.png" alt=""/>
            </Tooltip>
          </div>
        )}
        {item.recommendLevel === 2 && (
          <div className="roundIcon blue">
            <Tooltip title="图墙推荐">
              <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-recommend-icon.png" alt="" />
            </Tooltip>
          </div>
        )}
        {item.recommendLevel === 3 && (
          <div className="roundIcon yellow">
            <Tooltip title="首页推荐">
              <img style={{ cursor: 'pointer' }} src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-photo-wall-icon2.png" alt=""/>
            </Tooltip>
          </div>
        )}
      </Space>
    </WorkIconsStyle>
  )
}

export default HotIcons