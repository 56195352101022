import styled from "styled-components";

export const AdStyle = styled.div`
    margin: ${props=> props.theme.space.paddingLG}px 0;
    .holder{
        height: 168px;
        width: 100%;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        border-radius: ${props=> props.theme.border.borderRadiusSM}px;
        .tag{
            background-color: rgba(255, 255, 255,0.5);
            color: ${props=> props.theme.colors.colorPrimary};
            position: absolute;
            margin:  ${props=> props.theme.space.marginXXS}px;
            padding:  ${props=> props.theme.space.paddingXXS}px ${props=> props.theme.space.paddingXXS}px;
            right: 0;
            bottom: 0;
            font-weight: ${props=> props.theme.fontSizes.fontWeightHeading4};
            border-radius:${props=> props.theme.border.borderRadiusSM}px;
        }
    }
`