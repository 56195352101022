import styled from "styled-components";

export const BadgeRightStyle = styled.div`
    max-height: 400px;
    overflow-y: scroll;
    width: calc(100% - 300px);

    .ant-tooltip {
      z-index: 10 !important;
    }
    .section-block {
        padding: ${(props) => props.theme.space.paddingLG}px;
        padding-top: 0;
        margin: ${(props) => props.theme.space.paddingLG}px 0;
        border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
        .section-title {
          font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
          padding-bottom: ${(props) => props.theme.space.paddingLG}px;
        }
        .section-content {
            div {
                grid-template-columns: 1fr 1fr 1fr !important;
            }
        }
        &:first-child {
            margin-top: 0 !important;
        }
  }

  .no-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .list-item {
    min-width: 120px;
  }
`