import styled from "styled-components";

export const ComboUploadStyle = styled.div`
.view3d-wrapper{
          width: 800px;
          min-height: 800px;
        }
    .tabs {
      display: flex;
      border: 1px solid ${(props) => props.theme.colors.colorBorder};
      border-radius:  ${(props) => props.theme.border.borderRadiusLG}px;
      overflow: hidden;
      .tab-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: ${(props) => props.theme.fontSizes.fontSize}px;
        width: 96px;
        box-sizing: border-box;
        cursor: pointer;
        padding: ${(props) => props.theme.space.paddingSM}px
          ${(props) => props.theme.space.padding}px;
        border-right: 1px solid
          ${(props) => props.theme.colors.colorBorder};
        &:hover {
          background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
        }
        .icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: ${(props) => props.theme.fontSizes.fontSize * 1.4}px;
          padding: ${(props) => props.theme.space.paddingXXS}px 0;
        }
        .text {
          text-align: center;
        }
      }
    }
    .ant-upload-wrapper .ant-upload-drag {
      border: none;
      border-radius: 0;
      border: 1px solid ${(props) => props.theme.colors.colorBorder};

      .wrap {
        height: 180px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .content {
          color: ${(props) => props.theme.colors.colorTextTertiary};
          .icon {
            font-size: ${(props) => props.theme.fontSizes.fontSizeLG * 2}px;
          }
        }
      }
    }
  
`;


export const UploadListStyle = styled.div`
    padding:${(props) => props.theme.space.padding}px;
    display: flex;
    flex-direction: column;
    div{
      gap:10px;
    }
    .card{
      width: 100%;
      position: relative;
      margin-bottom: 10px ;
      box-sizing: border-box;
      background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
      border-radius:  ${(props) => props.theme.border.borderRadius}px;
      overflow: hidden;
     
      .progress{
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%,-50%);
            width:70%;
            padding: 0;
            margin: 0;
            z-index: 100;
          }
        .content{
          /* height: 180px; */
         height: 160px;
         width: 160px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
          .image-icon-wrapper {
              top: 0;
              left: 0;
              display: flex;
            
            }
            .icon{
              cursor: pointer;
              /* position: absolute; */
              font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
              color: ${(props) => props.theme.colors.colorTextLightSolid};
              background-color: rgba(0, 0, 0,.3);
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius:  ${(props) => props.theme.border.borderRadius}px 0  ${(props) => props.theme.border.borderRadius}px 0;
            }

          .play{
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%,-50%);
            cursor: pointer;
            width: 40px;
            height: 40px;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.8;
            border-radius: 8px;
            &:hover{
              scale: 1.04;
            }
          }
          
        }
        .edit{
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 26px;
            font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
            color:${(props) => props.theme.colors.colorTextLightSolid};
            background-color: rgba(0, 0, 0, 0.3);
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .operate{
            cursor: pointer;
            position: absolute;
            font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
            color:${(props) => props.theme.colors.colorTextLightSolid};
            background-color: rgba(0, 0, 0, 0.3);
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .delete{
            top: 0;
            right: 0;
            border-radius: 0  ${(props) => props.theme.border.borderRadius}px  0 0;
          }
          .edit{
            top: 0;
            right: 25px;
          }
          .effect{
            top: 0;
            right: 50px;
          }
        .caption{
          padding: ${(props) => props.theme.space.paddingXS}px;
          
        }
        .foot{

        }
    }
`

export const UpLoadStyle = styled.div`

`