import styled from "styled-components";

export const CouponListStyle = styled.div`
    .list {
        
        &-item:first-child{
            border-top: 1px solid  ${(props) => props.theme.colors.colorBorder};
            div {
                font-weight: bold;
            }
        }
        &-item {
            display: flex;
            border-left: 1px solid  ${(props) => props.theme.colors.colorBorder};
            border-right: 1px solid  ${(props) => props.theme.colors.colorBorder};
            div {
                flex: 1;
                text-align: center;
            }
        }
    }
`