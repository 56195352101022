import styled from "styled-components";

export const ContentCategoryCarouselStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%; 
  position: relative;
  .right {
    display: flex;
    align-items: center;
    margin-left:${props=>props.theme.space.marginXS}px;
  }
  .control {
    font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
    border-radius: 50% 50%;
    width: 28px;
    height: 28px;
    background-color: #F1F1F3;

    &:hover {
     
    }
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* &.next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right:-28px;
    } */
    &.prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -34px;
    }
    &.next {
      margin-right: ${props=>props.theme.space.marginXS}px;
    }
  }
  .setting {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :last-child {
      .item {
        margin-right: 0;
      }
    }
    .item{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: ${props=>props.theme.space.marginXS}px;
      
      .icon{
        background-color: rgb(241, 241, 243);
        width: 35px;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${(props) => props.theme.fontSizes.fontSizeXL * 1.1}px;
        color: ${(props) => props.theme.colors.colorTextSecondary};
        border-radius: 5px;
      }
      .text{
       padding-top: ${(props) => props.theme.space.paddingXS}px ;
       color: ${(props) => props.theme.colors.colorText} ;
      }
    }
  }

  .react-tooltip {
    opacity: 1 !important;
    background-color: #fff;
    z-index: 1000 !important;
    padding: 16px;
  }

  [class^="number-slide"],
  [class*=" number-slide"] {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100px;
    max-height: 100vh;    
    .item{
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
      padding: ${(props) => props.theme.space.paddingXS}px 0;
      border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
      position: relative;
      .bg{
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center;
                  border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
                  z-index: 1;
                  filter: brightness(40%)  saturate(220%);
                }
      .text{
        width: 100%;
        padding:0  ${(props) => props.theme.space.paddingXS}px ;

       color: ${(props) => props.theme.colors.colorTextLightSolid} ;
       font-size:  ${(props) => props.theme.fontSizes.fontSizeSM}px;
       white-space: nowrap;
       line-height: 19px;
       z-index: 11;
      }
    }
  }
`;

type ContentCategoryStyleType = {
  headerType: string,
  isHome?: boolean,
}


export const ContentCategoryStyle = styled.div<ContentCategoryStyleType>`
 position: ${(props) => props.headerType === 'morph' && !props.isHome ? 'fixed' : 'inherit'};
 top: 50px;
 left: 50%;
 z-index: 10;

 //background-color: ${(props) => props.theme.colors.colorBgLayout}; 
 background-color: ${(props) => props.headerType === 'morph' ? props.theme.colors.colorHomeBgContainer:props.theme.colors.colorBgLayout};
 transform:  ${(props) => props.headerType === 'morph' && !props.isHome ? 'translateX(-50%)' : 'none'};
 width: 100%;
.active {
  color: ${props => props.theme.colors.colorPrimary} !important;
}
.text {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 100%;
  text-align: center;
}

  .container_ {
    /* padding: ${(props) => props.headerType === 'morph' && !props.isHome ? '0' : `0 ${props.theme.space.padding}px`}; */
     padding:0 ${(props) => props.theme.space.padding}px;
     max-width: ${(props) => props.theme.space.maxContainer}px;
     margin: 0 auto;
     width: 100%;
    .wrap {
      width:100%;
      padding-left:${(props) => props.theme.space.padding * 2.5}px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      height: 76px;
      border-radius:${(props) => props.headerType === 'morph' && !props.isHome ? '0' : `${props.theme.border.borderRadius}px ${props.theme.border.borderRadius}px 0 0`};
    }
  }
`;
