import styled from "styled-components";

export const SdTagStyle = styled.div`
  color: #8C8C8C;
  /* border: 1px solid#c1c5c9;
  background-color: #edf2f5; */
  padding: 0 ${(props) => props.theme.space.paddingXS}px;
  width: fit-content;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-bottom: 8px;
`;

type TagProps = {
  bgColor: string;
  textColor: string;
  border?: string;
  clickable: boolean;
};
export const UiTagStyle = styled.div<TagProps>`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
  padding: 2px 6px;
  padding-top: 3px;
  width: fit-content;
  white-space: nowrap;
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  &:hover{
    white-space: normal;
  }
`;
