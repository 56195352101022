export const getRandomKey = (fileName: string) =>{
    var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            var maxPos = chars.length;
            var pwd = '';
            for (let i = 0; i < 32; i++) {
                 pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            let index= fileName.lastIndexOf(".");
            //获取后缀
            let ext = fileName.substring(index);

    return pwd +new Date().getTime()+ ext;
}