import styled from "styled-components";

export const RecommandUserStyle = styled.div`
 
 .title-wrap {
  position: relative;
  .update{
      text-align: right;
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.colorLinkDefault};
        &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
      margin-bottom: ${props => props.theme.space.marginXS}px;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 ${props => props.theme.space.padding}px;
      .inner {
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon{
          padding-left: 6px;
        }
      }
      svg {
        cursor: pointer;
        margin-right: ${props => props.theme.space.marginXS}px;
        cursor: pointer;
      }
    }
 }

  .container_ {
    background-color: ${(props) => props.theme.colors.colorBgLayout};
    margin: 0 auto;   
    .wrap {
      max-width: ${(props) => props.theme.space.maxContainer}px;
      padding: 0 ${props => props.theme.space.padding}px;
      margin: 0 auto;
      
      &>div {
        width: 100%;
      }
    }
  }
`;
