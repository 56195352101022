import { WorkListStyle } from "../style";
import { useCallback, useEffect, useState } from "react";
import { getMyArticleList, postDeleteArticle, postDeleteDraftArticle } from "api/article";
import { Pagination, Modal, message, Spin } from "antd";
import TagList from "../tag-list";
import { filterValueType } from "../workType";
import { useMemo } from "react";
import { ArticleListItem, ArticleListRequest } from "types/article";
import { PaginationResponse } from "types/common/axios";
import { useNavigate } from "react-router";
import { NoData } from "components/no-data";
import UISegmentedControl from "ui-lib/segmented-control";
import UserCenterArticleCard from "components/uni-card/self-cards/userCenterArticleCard";
import useWindowSize from "hooks/useWindowSize";

function WorkList() {
  const [articleList, setArticleList] = useState<ArticleListItem[]>([]);
  const [filterValue, setFilterValue] = useState<filterValueType>();
  const [pageNumber, setPageNumber] = useState(1);
  const [articleData, setArticleData] = useState<PaginationResponse<ArticleListItem>>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [screenSize] = useWindowSize();
  
  const params: ArticleListRequest = useMemo(() => {
    const data: ArticleListRequest = {
      pageSize:  30,
      pageNumber,
      isPublic: filterValue?.isPublic,
      onSale: 0
    };
    return data;
  }, [pageNumber, filterValue?.isPublic]);

  const getList = useCallback(async () => {
    setLoading(true)
    getMyArticleList(params).then(res => {
      setArticleList(res.pageData);
      setArticleData(res);
    }).finally(() => {
      setLoading(false)
    })

  }, [params]);

  useEffect(() => {
    getList();
  }, [getList]);

  const updateFilterValue = (value: filterValueType) => {
    setFilterValue(value);
  };

  const deleteWork = (id: number, state?: number) => {
    Modal.confirm({
      title: "确定删除？",
      content: "删除该草稿文章后，将无法找回",
      okText: "确定",
      cancelText: "取消",
      onCancel() { },
      centered: true,
      onOk() {
        const func =
          state && state === 2 ? postDeleteArticle : postDeleteDraftArticle;
        func(id).then(() => {
          const index = articleList.findIndex((item) => item.id === id);
          articleList.splice(index, 1);
          setArticleList([...articleList]);
          message.success("删除成功");
        });
      },
    });
  };

  const tabOption = [
    {
      label: "已通过",
      value: "work"
    },
    {
      label: "审核和草稿",
      value: "draft"
    },
    {
      label: "下架",
      value: "offShelf"
    },
  ];

  const changePage = (e: number) => {
    setPageNumber(e);
  };

  const changeWorkType = (e: any) => {

    if (e === "draft") {
      navigate("/user-center/home/article/draft/list");
    } else if (e === "work") {
      navigate("/user-center/home/article/list");
    }
  };

  return (
    <WorkListStyle>
      <div className="top-bar">
        <div className="left">
          <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={'offShelf'}></UISegmentedControl>
        </div>
        <div className="right">
          <TagList change={updateFilterValue} />
        </div>
      </div>

      <Spin spinning={loading}>
        {articleList.length === 0 && (
          <div
            style={{ width: "100%", marginTop: "200px", position: "relative" }}
          >
            <NoData></NoData>
          </div>
        )}
        <div className="list" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
          {
            articleList.length > 0 && articleList.map(item => {
              return <UserCenterArticleCard key={item.id} item={item} deleteWork={deleteWork} />
            })
          }
        </div>
      </Spin>
      <Pagination
        pageSize={params.pageSize}
        style={{ textAlign: "center" }}
        hideOnSinglePage={true}
        showSizeChanger={false}
        onChange={changePage}
        total={articleData?.totalSize}
        current={pageNumber}
      />
    </WorkListStyle>
  );
}

export default WorkList;
