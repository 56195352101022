import { Button, Input, message } from "antd"
import { CreateAlbumStyle } from "./style"
import { useEffect, useState } from "react"
import { postCreateAlbum, postUpdateAlbum } from "api/album"
import { albumListItem } from "types/album"

const CreateAlbum = ({ close, success, detail }: { close: () => void, success: () => void, detail?: albumListItem }) => {
    const [value, setValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(detail){
            setValue(detail.name)
        }
    }, [detail])

    const submit = () => {
        if (!value.trim()) {
            message.warning('请输入作品集名称')
            return
        }
        setIsLoading(true)
        if (detail && detail.id) {
            postUpdateAlbum({
                name: value,
                id: detail.id
            }).then(() => {
                close()
                success()
            }).finally(() => {
                setIsLoading(false)
            })
            return
        }
        postCreateAlbum(value).then(() => {
            close()
            success()
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onChange = (value: string) => {
        setValue(value)
    }

    return <CreateAlbumStyle>
        <div className="title">{detail ? '编辑' : '添加'}作品集</div>
        <Input type="text" value={value} onChange={(e) => onChange(e.target.value)} placeholder="请输入作品集名称" />
        <div className="btns">
            <Button type="primary" loading={isLoading} onClick={submit}>确定</Button>
            <Button onClick={close}>取消</Button>
        </div>
    </CreateAlbumStyle>
}

export default CreateAlbum