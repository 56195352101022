import styled from "styled-components";

export const HomeFooterStyle = styled.div`
  background-color:${props => props.theme.colors.colorPrimary};
 
  .container{
    max-width: ${props => props.theme.space.maxContainer}px;
    min-width: 1430px;
    width: 100%;
    margin: 0 auto;
    padding: 0 ${props => props.theme.space.padding*2}px;
    height: 80px;
    font-size: 12px;  
    display:flex;
    align-items: center;
    justify-content: center;
    .a{
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
     .list{
      display: flex;
      align-items: center;
      &.up{
        gap: 40px;
      }
      gap: 20px;
      &:first-child{
        margin-bottom: 13px;
      }
      .list-item{
        display: flex;
        align-items: center;
        cursor: pointer;
      }
     }
    }
    .b{
      display: flex;
      flex-direction: column;
      justify-content: center;
     .list{
      display: flex;
      align-items: center;
      &.up{
        gap: 8px;
      }
      &:first-child{
        margin-bottom: 10px;
      }
      .list-item{
        display: flex;
        align-items: center;
      }
     }
     .contact{
      cursor: pointer;
      text-decoration: underline;
     }
    }
    
    .c{
      height: 100%;
      display: flex;
      align-items: center;
      .follow-up{
        display: flex;
        align-items: center;
        gap:10px;
        transform: translateY(-16px);
      }
      .blocks{
        display: flex;
        padding-left: ${props => props.theme.space.paddingLG *2}px;
        gap:40px;
        .block{
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          cursor: pointer;
         
          .icon{
            font-size: 28px;
            margin-bottom: 4px;
            
          }
        }
      }
    }
    .divider{
      height: 38px;
      width: 1px;
      background-color: #000;
      margin: 0 38px;
    }
  }

`