import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const MarketStyle = styled.div`
  @font-face {
      font-family: 'AlibabaPuHuiTiR';
      src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
  }

  padding: ${(props) => props.theme.space.paddingLG}px;
  padding-top: 12px;
  max-width: 2680px;
  margin: 0 auto;
  .top {
    text-align: center;
    margin: ${props => props.theme.space.margin * 2}px 0;
    .title {
      font-size: 40px;
	    font-family: 'AlibabaPuHuiTiR';
	    color: #2b2e36;
      font-weight: bold;
    }
    .word {
      color: #8c8c8c;
    }
  }
  .fliter{
    padding:  ${(props) => props.theme.space.paddingLG}px 0 0 0;
    margin-bottom: ${(props) => props.theme.space.paddingLG}px;
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
  }
  .sort{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${props => props.theme.space.margin}px;
    .select {
      width: 200px;
    }
    .ant-radio-group {
      display: flex;
      align-items: center;
    }
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }
  }
  .data-list {
    display: grid;
    grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
    gap: ${(props) => props.theme.space.padding}px;
    margin-top: 20px;
  }
  .tabs{
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0 20px;

    .tab{
        color: #8F8F8F;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        padding:  ${(props) => props.theme.space.paddingXXS}px ${(props) => props.theme.space.padding}px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding-bottom: 17px;
        &:hover {
        color: ${(props) => props.theme.colors.colorText} !important;
        border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
      }
        &.active{
            border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
            color:${(props) => props.theme.colors.colorText};
        }
    }
}
.no-data{
  display: flex;
  justify-content: center;
}
.jump-btn{
  margin-top:15px;
}
`;
