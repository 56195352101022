import NotFound from "components/notFound";
import { RouteObject, Navigate } from "react-router-dom";
import HomeLayout from "views/home/home-layout";
import Home from "views/home";
import { TestHome } from 'views/home/test-home'
import Layout from "views/layout";
import RecommendList from "views/home/recommend";   
import { WorkBillboard } from 'views/home/billboard/work-board/work-board'
import { UserBillboard } from "views/home/billboard/user-board/user-board";
import TinyEditor from "ui-lib/tinyMCE";
import BizForm from "views/biz";


export const homeRoutes: RouteObject[] = [
    {
        path: '/demo',
        element: <TestHome></TestHome>
    },
    {
        path: '/',
        element: <Navigate to="/home"></Navigate>,
    },
    {
        path: '/',
        element: <Layout></Layout>,
        children: [
            {
                path: "/",
                element: <HomeLayout></HomeLayout>,
                children: [
                    {
                        path: '/home',
                        element: <Home></Home>,
                    },
                    {
                        path: '/recommend',
                        element: <RecommendList name='recommend'></RecommendList>,
                    },
                    {
                        path: '/hot',
                        element: <RecommendList name='hot'></RecommendList>,
                    },
                    {
                        path: '/follow',
                        element: <RecommendList name='follow'></RecommendList>,
                    },
                    {
                        path: '/billboard/work/:type',
                        element: <WorkBillboard></WorkBillboard>
                    },
                    {
                        path: '/billboard/user/:type',
                        element: <UserBillboard></UserBillboard>
                    }
                ]
            },

        ]
    },
    {
        path: '/biz',
        element: <BizForm></BizForm>
    },
    {
        path: '/error',
        element: <NotFound></NotFound>
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    },

]