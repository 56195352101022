import { createRef, useState } from "react"
import { BadgeLeftStyle } from "./style"
import { Carousel, Switch, Image, message } from "antd"
import { certificateItem } from "types/user/certificate"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import type { CarouselRef } from 'antd/lib/carousel'
import { NoData } from "components/no-data";
import { postCertificateFlag } from "api/user/certificate";

const BadgeLeft = ({ certificateList, userId, updateList, isShowRodio = false }: { certificateList: certificateItem[], userId: number, updateList: (value: certificateItem[]) => void, isShowRodio: boolean }) => {

    const carouselRef = createRef<CarouselRef>()

    const tabList = [
        { name: "大赛证书", id: 1 }
    ]

    const [currentId] = useState(1)

    const changeSwitch = (detail: certificateItem) => {
        const flag = detail.isPublic === 1 ? 0 : 1
        postCertificateFlag({ id: detail.id, flag }).then(() => {
            message.success("修改成功")
            const index = certificateList.findIndex((item) => detail.id === item.id)
            certificateList[index].isPublic = flag
            updateList(certificateList)
        })
    }

    const move = (type: string) => {
        if (type === "prev") {
            carouselRef.current?.prev();
        }
        if (type === "next") {
            carouselRef.current?.next();
        }
    };


    return <BadgeLeftStyle>

        <div className="left">
            <div className="tab">
                {
                    tabList.map(item => {
                        return <div key={item.id} className={`tab-item ${item.id === currentId ? 'active' : ''}`}>
                            {item.name}
                        </div>
                    })
                }
            </div>

            {
                certificateList.length === 0 && <div className="no-data-wrap">
                    <NoData />
                </div>
            }
            {
                certificateList.length > 0 && <div className="carousel-wrap">
                    <Carousel ref={carouselRef} className="carousel" dots={false}>
                        {
                            certificateList.map(item => {
                                return <div className="carousel-content">
                                    <Image
                                        width={200}
                                        height={300}
                                        src={item.cnUrl}
                                    />
                                    {
                                        isShowRodio && <div className="switch-wrap">
                                            是否公开<Switch checked={item.isPublic === 1} onChange={() => changeSwitch(item)} />
                                        </div>
                                    }

                                </div>
                            })
                        }
                    </Carousel>
                    {
                        certificateList.length > 1 && <>
                            <div className="right-arrow" onClick={() => move("next")}>
                                <RiArrowRightSLine size={25} />
                            </div>
                            <div className="left-arrow" onClick={() => move("prev")}>
                                <RiArrowLeftSLine size={25} />
                            </div>
                        </>
                    }
                </div>
            }
        </div>
    </BadgeLeftStyle >
}

export default BadgeLeft