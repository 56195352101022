import { Regulation2024PreviousStyle } from "./style"

const Regulation2024Previous = () => {

    const fourList = [
        '“星空，就像一个巨大而古老的时钟，人类通过它，认识了——时间”',
        '2020年12月21日凌晨2:22，土星木星合相落入水瓶座0°29′，每200年转动一次的星轮再次开启，世界',
        '即将迎来崭新的风元素时代。然而驱动星轮的原石，却消耗殆尽……',
        '2021年，GGAC探索计划重启，',
        '探员们将分别去往【水】【火】【土】【风】【熵】五个元素时代 ，在元素师们的引导与帮助下寻找原石，以及星轮的秘密，日月星辰，神仙异兽，过去未来，幻境现实，触手可及，却遥不相望。',
        '文明交替往复，唯有丰碑永存。',
        '崭新的纪元即将开启',
        '你，准备好了么？',
    ]

    const threeList = [
        '“起初，没有人在意这一场灾难，这不过是一场山火，一次瘟疫，一个物种的灭绝，一座城市的消失，直到这场灾难和每个人息息相关……”——《流浪地球》',
        '2020年GGAC地球探索计划启动，某些前所未见的超智慧非人生灵逐渐被发现，他们来自神秘的海域、无边的雨林、冰雪覆盖的深山……也来自繁闹的城镇、古老的部落、甚至未知位面的时空。',
        '如同上古的神祗，他们形貌各异，亿万年来默默潜藏于世，偶尔也会幻化人形。他们拥有超绝的能力与智慧，在世界危急的时刻现身，试图帮助或惩戒迷失的人类。',
        '于是GGAC探索站（简称G站）在世界各地招募GGAC探索员，探索新的非人生灵、不明地域和奇闻异事。',
        '探索员们合力修复生态的平衡，也预警未知的凶险。他们竭尽所能用图像、视频、文字，记录成GGAC探索档案，并发布到G站公诸于世，告诉人们——世界并非人类主宰，万灵本该相和共存。',
    ]

    const twoList = [
        '楼兰，世称沙漠庞贝，地处神秘的罗布泊西北部、孔雀河南岸；北临车师西接焉耆，南当精绝城，东经白龙堆，由敦煌通汉，曾是西域三十六城邦中最璀璨的沙漠明珠。',
        '“驰命走驿，不绝于时月；胡商贩客，日款塞下。”丝绸之路由此南北分道驼队络绎不绝，街市各族云集，寺庙里钟声洪亮、梵音悠扬，东西文化于此融汇远播。',
        '公元5世纪，古楼兰王国突然神秘消失，上无飞鸟，下无走兽，遍及望目唯以死人枯骨为标识耳。',
        '楼兰故地湮灭何处，楼兰子民去向何方？千年来人们对它的探索从未停歇将军铁衣铮铮，胡舞飞旋不停，传说楼兰城仍藏于大漠深处，千年之后，这座沙海中的庞贝可会再现于世？',
    ]

    const oneList = [
        '初始，一切都是混沌的领土，诸神在第一抹光明中诞生。他们创造日月与星辰，让它们拥有属于自己的光；他们分离大地与海洋，并将之以“世界”命名暴怒的凶兽在虚空中被孕育，它们是混沌的尊严。秩序与虚无这是它们的永恒战争！',
        '诸神预感到了“永夜”的到来，为了抵御凶猛的兽潮，诸神创造百族，赐予他们不同的科技与魔法，并让人类领导他们那是一场浩劫般的战争，科技与魔法如同玩具一样不堪一击。星辰从苍穹中落下，日月暗淡无光，时间不复存在整个世界开始四分五裂，最终，混沌被封印，残存的神明也陷入沉睡，而人类，成为了世界的主人。',
        '神明创造人类，可人类却并非世界的礼物。掌握神明知识的人类，不断被混沌吸引，人类是失败的产物。他们愚昧、猜忌、贪婪、傲慢，他们更像是属于混沌，在失去诸神的世界里，人类与各族之间的战争才刚刚开始！',
    ]

    const fiveList = [
        '神级文明最终重启了宇宙，但文明的种子仍在…… ',
        "于是无数个小宇宙带着生态球和文明印记，进入新的轮回。",
        '地球上存在过的文明亦随之而往，博大兴盛的东方文明也被刻录在文明印记中，等待轮回的播种。',
        '但巨量的小宇宙轮回，导致平行世界产生随机合轨，多元宇宙的规则濒临失控。',
        "各种文明在碰撞间产生了新的火花和不可思议的融合……",
        '文明交替往复，唯有丰碑永存。欢迎进入文明大厅，创建文明的种子'
    ]

    const lang = localStorage.getItem('lang')

    return <Regulation2024PreviousStyle>
        <div className={`container-wrap ${lang === 'en_GB' ? 'container-wrap-en' : ''}`}>
            <div className="previous_wrapper">
                <div className="title">
                    <div className="title-top" lang="t_lang">第五届</div>
                    <div className="title-bottom" lang="t_lang">GGAC全球游戏动漫美术概念大赛</div>
                </div>
                <div className="match-item match-five">
                    <div className="content left">
                        <div className="match-title" lang="t_lang">东方文明幻想志</div>
                        <div className="match-text-wrap">
                            {
                                fiveList.map(item => {
                                    return <div className="match-text" key={item} lang="t_lang">{item}</div>
                                })
                            }
                        </div>

                        <div className="btn">
                            <a href="https://www.ggac.com/v2/regulation" target="_blank" lang="t_lang">赛事详情</a>
                            <a href="https://www.ggac.com/v2/work/competition?match_conditions=5" target="_blank" lang="t_lang">参赛作品</a>
                        </div>
                    </div>
                </div>
                <div className="title">
                    <div className="title-top" lang="t_lang">第四届</div>
                    <div className="title-bottom" lang="t_lang">GGAC全球游戏动漫美术概念大赛</div>
                </div>
                <div className="match-item match-four">
                    <div className="content right">
                        <div className="match-title" lang="t_lang">元宇宙：GGAC探索计划II</div>
                        <div className="match-text-wrap">
                            {
                                fourList.map(item => {
                                    return <div className="match-text" lang="t_lang" key={item}>{item}</div>
                                })
                            }
                        </div>
                        <div className="btn">
                            <a href="https://www.ggac.com/v2/2021/regulation" target="_blank" lang="t_lang">赛事详情</a>
                            <a href="https://www.ggac.com/v2/work/competition?match_conditions=4" target="_blank" lang="t_lang">参赛作品</a>
                        </div>
                    </div>
                </div>
                <div className="title">
                    <div className="title-top" lang="t_lang">第三届</div>
                    <div className="title-bottom" lang="t_lang">GGAC全球游戏动漫美术概念大赛</div>
                </div>
                <div className="match-item match-three">
                    <div className="content left">
                        <div className="match-title" lang="t_lang">山海有灵</div>
                        <div className="match-text-wrap">
                            {
                                threeList.map(item => {
                                    return <div className="match-text" key={item} lang="t_lang">{item}</div>
                                })
                            }
                        </div>
                        <div className="btn">
                            <a href="https://achive.ggac.com/2020/regulation" target="_blank" lang="t_lang">赛事详情</a>
                            <a href="https://www.ggac.com/v2/work/competition?match_conditions=3" target="_blank" lang="t_lang">参赛作品</a>
                        </div>
                    </div>
                </div>
                <div className="title">
                    <div className="title-top" lang="t_lang">第二届</div>
                    <div className="title-bottom" lang="t_lang">GGAC全球游戏动漫美术概念大赛</div>
                </div>
                <div className="match-item match-two">
                    <div className="content right">
                        <div className="match-title" lang="t_lang">楼兰</div>
                        <div className="match-text-wrap">
                            {
                                twoList.map(item => {
                                    return <div className="match-text" key={item} lang="t_lang">{item}</div>
                                })
                            }
                        </div>

                        <div className="btn">
                            <a href="https://achive.ggac.com/2019/regulation" target="_blank" lang="t_lang">赛事详情</a>
                            <a href="https://www.ggac.com/v2/work/competition?match_conditions=2" target="_blank" lang="t_lang">参赛作品</a>
                        </div>
                    </div>
                </div>
                <div className="title">
                    <div className="title-top" lang="t_lang">第一届</div>
                    <div className="title-bottom" lang="t_lang">GGAC全球游戏动漫美术概念大赛</div>
                </div>
                <div className="match-item match-one">
                    <div className="content left">
                        <div className="match-title" lang="t_lang">诸神的陨落</div>
                        <div className="match-text-wrap">
                            {
                                oneList.map(item => {
                                    return <div className="match-text" key={item} lang="t_lang">{item}</div>
                                })
                            }
                        </div>

                        <div className="btn">
                            <a href="https://achive.ggac.com/2018/regulation" target="_blank" lang="t_lang">赛事详情</a>
                            <a href="https://www.ggac.com/v2/work/competition?match_conditions=1" target="_blank" lang="t_lang">参赛作品</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Regulation2024PreviousStyle >
}

export default Regulation2024Previous