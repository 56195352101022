import { get, post } from "api"
import { PaginationResponse } from "types/common/axios";
import { WorkDraftItem, WorkDraftItemRequest, WorkListItem, WorkListRequest } from "types/work";

//获取用户作品列表
export const apiUserWorkList = async (params: WorkListRequest) => {
    return await get<WorkListRequest, PaginationResponse<WorkListItem>>(`/api/work/list`, params);
}

//获取全部用户作品ids
export const apiUserWorkIds = async (params: {userId:number}) => {
    return await get<{userId:number},{workIds:number[]} >(`/api/user/user_work_ids`, params);
}

//获取我的作品列表
export const apiMyWorkList = async (params: WorkListRequest) => {
    return await get<WorkListRequest, PaginationResponse<WorkListItem>>(`/api/work/mime_list`, params);
}

// 草稿列表
export const apiWorkDraftList = (params: WorkDraftItemRequest) =>
    get<WorkDraftItemRequest, PaginationResponse<WorkDraftItem>>(`/api/work_draft/list`, params)

// 删除草稿作品
export const postDeleteDraftWork = (id: number) =>
    post<number, any>(`/api/work_draft/delete/${id}`)

    // 撤销作品审核
export const apiRevertWork = (id: number) =>
post<number, any>(`/api/work_draft/cancel_audit/${id}`)

// 删除作品
export const postDeleteWork = (id: number) =>
    post<number, any>(`/api/work/delete/${id}`)