import styled from "styled-components";

export const RedeemUserInfoStyle = styled.div`
    @font-face {
      font-family: "DIN";
      src: url("https://cdn.ggac.com/v2/fonts/DINCondensedC-2.e5aeb766.ttf");
    }
    width: 100%;

    .user-info {
        display: flex;
        padding:${props => props.theme.space.padding}px  ${props => props.theme.space.paddingLG}px;
        width: 100%;
        margin-bottom: ${props => props.theme.space.margin}px;
        justify-content: space-between;
        align-items: center;
        .user {
            &-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 400px;
            }
            .number {
                margin: ${props => props.theme.space.marginXS}px 0;
                span {
                    font-size: 40px;
                    color: #f4aa2f;
                    font-family: DIN;
                    margin-right: 10px;
                }
            }
            .info {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
 
            .desc {
                color: #94969f;
                display: flex;
                align-items: center;
                span {
                    margin-right: ${props => props.theme.space.marginXXS}px;
                }
                svg {
                    cursor: pointer;
                }
            }
        }
        .history {
                border-radius: 50px;
                &:hover {
                    background-color: ${props => props.theme.colors.colorPrimary};
                    color: #fff !important;
                }
            }
    }


`