
import { translateCardCategoryText } from 'utils/functions/common';
import HotIcons from '../../common/hot-icons';
import PrizeIcons from '../../common/prize-icons';
import UniCardLayout from '../../layout';
import CardTitleRender from '../../common/card-title/work';
import { FavoriteDataListItem } from 'types/favorite';
import MediaIconsES from '../../common/media-icons/es';
import FavoriteCardAction from './favoriteCardAction';
import FavoriteCardStatus from './favoriteCardStatus';

function UserCenterFavoriteCard({ item, shouldRefresh }: { item: FavoriteDataListItem, shouldRefresh: (item: FavoriteDataListItem) => void }) {
    const renderSubtitle = () => {
        if (item.dataTable === 'work' || item.dataTable ==='media') {
            return <span> {item.dictMap?.mediaCategory ? item.dictMap?.mediaCategory + ' | ' : ''} {translateCardCategoryText(item.categoryList)}</span>
        }
        if (item.dataTable === 'article') {
            return <span> {translateCardCategoryText(item.categoryList)}</span>
        }
        return <></>
    }

    const id = item.dataTable === 'media' ? item.id : item.dataId

    const workLink = (item.dataTable === 'work' ||  item.dataTable ==='media')? `/work/detail/${id}` : item.dataTable === 'article' ? `/article/detail/${id}` : `/${item.mediaDataTable}/detail/${id}`

    const getDataType = ()=>{
        switch (item.dataTable) {
          case 'work':
            return 'work_mix'
          case 'article':
            return 'article'
          case 'media':
            return 'media'  
          default:
            return '' 
        }
      }

    const UiObject = {
        coverUrl: item.coverUrl,
        title: <CardTitleRender item={item} dataType={getDataType()} />,
        subtitle: renderSubtitle(),
        view: item.viewCount,
        hot: item.hot,
        userName: item.userInfo?.username,
        avatarUrl: item.userInfo?.avatarUrl,
        icons: HotIcons({ item }),
        workLink,
        userLink: `/user/${item.userId}`,
        rightTopNode: PrizeIcons({ item }),
        rightBottomNode: MediaIconsES({ item }),
        centerNode: <FavoriteCardAction item={item} workLink={workLink} shouldRefresh={shouldRefresh}></FavoriteCardAction>,
        leftBottomNode: <FavoriteCardStatus item={item}></FavoriteCardStatus>,
        isPublish: true
    }


    return UniCardLayout({ item: UiObject });
}

export default UserCenterFavoriteCard