import { getLocalUserInfo } from "utils/functions/common";

export const localUser = getLocalUserInfo();

export const isMe = (id?: number) => {
    if(!localUser) return false;
    return id === localUser?.id;
}

export const isLogin = () => {
    if(localUser) return true;
    return false;
}

export const canSuperManage = () => {   

    if(localUser && localUser?.permissions?.includes("forumRecommend")){
      return true;
    }else{
     return false;
    }
}