import { orderDetailResponse } from "types/product";

export const translateRefundStatus = (code: any) => {
  switch (code) {
    case 1:
      return "买家申请";
    case 2:
      return "退款中";
    case 3:
      return "退款成功";
    case 4:
      return "卖家拒绝";
    case 5:
      return "平台介入";
    case 6:
      return "平台拒绝";
    case 7:
      return "买家关闭";
    default:
      break;
  }
};


export const translateRefundDetailStatus = (detail: orderDetailResponse,role:string) => {
  const seller = role==='seller' ?true :false;
  if(detail.refund.handlerNotice ===1){
    return seller?`退款中，您24小时未响应，买家可申请平台介入`:`退款中，卖家24小时未响应，可申请平台介入 `;
  }
  switch (detail.refund.status) {
    case 1:
      return seller?`退款中，买家申请退款 【退款原因：${detail.refund.refundReason}】`:`退款中，等待卖家同意【退款原因：${detail.refund.refundReason}】`;
    case 2:
      return "退款处理中";
    case 3:
      return "退款成功";
    case 4:
      return seller?`退款失败，您拒绝了买家的退款申请 【拒绝理由：${detail.refund.sellerRefuseReason}】`:`退款失败，卖家拒绝了您的退款申请 【拒绝理由：${detail.refund.sellerRefuseReason}】`;
    case 5:
      return seller?"退款中，买家申请了平台介入退款":"退款中，您已申请平台介入退款";
    case 6:
      return seller?`退款失败，平台拒绝了买家的退款申请。【拒绝理由：${detail.refund.adminRefuseReason}】`:`退款失败，平台拒绝了您的退款申请。【拒绝理由：${detail.refund.adminRefuseReason}】`;
    case 7:
      return "买家关闭";
    default:
      break;
  }
};