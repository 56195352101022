import { useEffect, useState } from "react";
import { ProductShowcaseStyle } from "./style";
import { apiGetMallShareImage, apiGetPublicProductList, apiGetUserProductListCategory } from "api/shop";
import { PublicProductListItem } from "types/product";
import PublicProductCard from "components/product-card/public-product-card";
import { NoData } from "components/no-data";
import { Pagination, Spin, Tabs } from "antd";
import { useOutletContext, useParams } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import { RiShareForwardBoxLine } from "react-icons/ri";
import GlobalShare from "components/share";
import { publicUserDetailType } from "types/user/detail";
import UISegmentedControl from "ui-lib/segmented-control";

function ProductShowCase() {
  const { detail } = useOutletContext<{ detail: publicUserDetailType }>()
  const [productList, setProductList] = useState<PublicProductListItem[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [screenSize] = useWindowSize();
  const pageSize = 30;
  const [showShare, setShowShare] = useState(false)
  const [shareUrl, setShareUrl] = useState("")
  const [shareImageUrl, setShareImageUrl] = useState('')
  const [shareQrCode, setShareQrCose] = useState("")
  const { id } = useParams()
  const [categories,setCategories] = useState<{label:string,value:number}[]>()
  const [currentCategoryId, setCurrentCategoryId] = useState<number>(0)
  useEffect(() => {
    getProductList();
  }, [pageNumber,currentCategoryId]);

  useEffect(() => {
    getCategories()
  },[])

  const getCategories = async ()=>{
   await apiGetUserProductListCategory({ userId: Number(id) }).then((res) => {
      const list = res.map(category => {
        return {label:category.name,value:category.id}
      })
      setCategories([{label:'全部',value:0},...list])
    })
  }
  const getProductList = () => {
    setLoading(true)

    apiGetPublicProductList({ userId: Number(id), pageNumber, pageSize ,categoryId:Number(currentCategoryId)===0?undefined:currentCategoryId}).then((res) => {
      setProductList(res.pageData);
      setLoading(false)
      setTotal(res.totalSize)
    });
  };


  const changePage = (e: number) => {
    setPageNumber(e);
  };

  let userId: number
  if (!!JSON.parse(localStorage.getItem('user_info')!)) {
    userId = JSON.parse(localStorage.getItem('user_info')!).id
  }

  const share = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true)
    apiGetMallShareImage(Number(detail.shopId)).then(res => {
      let currentHostWithPrefix = window.location.protocol + '//' + window.location.host;
      setShareUrl(`${currentHostWithPrefix}/user/${id}/shop?shareUserId=${userId}`)
      setShowShare(true);
      setShareImageUrl(res.minaImage)
      setShareQrCose(res.minaQrCode)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleTabChange = (e: any) => {
    console.log('handleTabChange ',e)
    setCurrentCategoryId(e)
  }
  return (
    <ProductShowcaseStyle>
      <div className="categories">
      {categories && <UISegmentedControl options={categories} onValueChange={handleTabChange} value={currentCategoryId ??0}></UISegmentedControl>}  

    {/* {categories && <Tabs
        defaultActiveKey="0"
        tabPosition={'top'}
        activeKey={String(currentCategoryId) ??"0"}
        items={categories.map((item, i) => {
          return {
            label: item.label,
            key: String(item.value),
          };
        })}
        onChange={handleTabChange}
        children={null}
      /> }   */}
      <div className="share" onClick={(e) => share(e)}>
          <a href="">
            <RiShareForwardBoxLine></RiShareForwardBoxLine>
            <span>分享店铺</span>
          </a>
        </div>
      </div>
      <Spin spinning={loading}>

        
        <div className="data-list" style={{ gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)` }}>
          {productList &&
            productList.map((item, index) => {
              return <PublicProductCard item={item} key={index} />;
            })}
        </div>
        {productList?.length === 0 && (
          <div style={{ padding: "100px 0" }}>
            <NoData />
          </div>
        )}
      </Spin>
      <Pagination
        style={{ textAlign: "center", marginTop: "28px" }}
        hideOnSinglePage={true}
        current={pageNumber}
        onChange={changePage}
        showSizeChanger={false}
        pageSize={pageSize}
        total={total}
      />
      <GlobalShare
        info={{
          title: "",
          cover_image: "",
          desc: "",
        }}
        shareImageInfo={{ qrCode: shareQrCode, shareImageUrl: shareImageUrl }}
        shareType="shop"
        isShow={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        shareUrl={shareUrl}
      ></GlobalShare>
    </ProductShowcaseStyle>
  );
}

export default ProductShowCase;
