import { Select } from "antd"
import { useEffect, useMemo, useState } from "react"

import { TagListProps, filterValueType } from "../../workType"
import { WorkTagList } from "types/enums/work-tag-type"

const Filter = ({ change }: TagListProps) => {
    const [status, setStatus] = useState<number>(4)
    const filterValue: filterValueType = useMemo(() => {
        var params: filterValueType = {}
        if (status === 4) {
            delete params.status
        }else {
            params.status = status
        }
        return params
    }, [status])
    const handleChange = (e: number) => {
        setStatus(e)
    }
    useEffect(() => {
        change(filterValue)
    }, [filterValue])

    return <div>
        <Select
            defaultValue={4}
            style={{ width: 120 }}
            onChange={handleChange}
            className="select"
            options={WorkTagList}
        />
    </div>
}

export default Filter