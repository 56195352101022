import { useEffect, useMemo, useState } from "react"
import { UserFansListStyle } from "./style"
import { useParams } from "react-router"
import { getAuthorList } from "api/user"
import { getAuthorItem } from "types/user"
import UserCard from "components/userCard"
import { Pagination, Spin } from "antd"
import { NoData } from "components/no-data"
import { dynamicColumns } from "utils/functions/common"

const UserFolloweeList = () => {

    const { id } = useParams()
    const [pageNumber, setPageNumber] = useState(1)
    const [list, setList] = useState<getAuthorItem[]>([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const params = useMemo(() => {
        return {
            follower: Number(id),
            pageNumber,
            pageSize: dynamicColumns()*3
        }
    }, [id, pageNumber])
    useEffect(() => {
        setLoading(true)
        getAuthorList(params).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [params])

    const changePage = (e: number) => {
        setPageNumber(e);
    };

    return <UserFansListStyle>
        <>
            <Spin spinning={loading}>
                <UserCard list={list} />
            </Spin>
            <Pagination
                pageSize={params.pageSize}
                style={{ textAlign: "center" ,marginTop: 24}}
                hideOnSinglePage={true}
                onChange={changePage}
                total={total}
                showSizeChanger={false}
                current={pageNumber}
            />
            {
                list.length === 0 && <div style={{ padding: "100px 0" }}><NoData /></div>
            }
        </>
    </UserFansListStyle>
}

export default UserFolloweeList