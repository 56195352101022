import { Form, Input, Cascader,  FormInstance, message,  Divider } from 'antd';
import { useState, useCallback, useRef, useEffect } from 'react'
import { apiGetCountryList, } from 'api/common'
import { countryItem, countryRequest } from 'types/common'
import { AuthWriterCreateStyle } from './style';
import { apiCreateAuthWriter } from 'api/user/auth-writer';
import { apiUserDetail } from 'api/user/detail';
import { useNavigate } from 'react-router-dom';



export const AuthWriterCreate = () => {
    const [countryId, setCountryId] = useState(0)
    const [provinceId, setProvinceId] = useState(0)
    const [cityId, setCityId] = useState(0)
    const [defaultCountry, setdefaultCountry] = useState<number[]>([])
    const [countryList, setCountryList] = useState<countryItem[]>([])
    const formRef = useRef<FormInstance>(null);
    const [form] = Form.useForm<any>();
    const [address, setAddress] = useState('')
    const navigate = useNavigate()
  
    const getCountryList = useCallback(async (params: countryRequest) => {
        const result = await apiGetCountryList(params)
        result.map((item => {
            item.isLeaf = false
            return item
        }))
        setCountryList(result)
    }, [])
    const onChangeCountry = (selectedOptions: countryItem[] | any[]) => {
        console.log(selectedOptions[1])
        if (selectedOptions[0]) {
            setCountryId(selectedOptions[0])
        }
        if (selectedOptions[1]) {
            setProvinceId(selectedOptions[1])
        }
        if (selectedOptions[2]) {
            setCityId(selectedOptions[2])
        }
    }
    // 提交
    const save = () => {
        form.validateFields().then(async res => {
            const obj = Object.assign(res, { countryId, provinceId, cityId, type: 1 })
            apiCreateAuthWriter({ ...obj }).then(res => {
                message.success("保存成功")
                navigate('/auth-writer')
            })
        }).catch(err=> {
            console.error(err)
        })
    }
    const loadData = async (selectedOptions: countryItem[] | any[]) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const result = await apiGetCountryList({ pid: String(targetOption?.id), type: String(targetOption?.type + 1) })
        result.map(item => {
            if (targetOption?.id === 1 && item.type === 2) {
                item.isLeaf = false
            }
            return item
        })
        targetOption.loading = false;
        targetOption.children = result
        setCountryList([...countryList]);
    }
  
    // 获取用户信息
    const getUserDetail = useCallback(async () => {
        const result = await apiUserDetail()
        const { userInfo } = result
        setdefaultCountry([userInfo.countryId, userInfo.provinceId, userInfo.cityId])
        const { regionDictMap } = userInfo
        if (!regionDictMap.countryName && !regionDictMap.provinceName && !regionDictMap.cityName) {
            setAddress('')
        } else {
            setAddress(`${regionDictMap.countryName} / ${regionDictMap.provinceName} ${regionDictMap.cityName ? "/" : ''} ${regionDictMap.cityName}`)
        }
        console.log(userInfo)
        form.setFieldsValue({
            realName: userInfo.realName,
            penName: userInfo.penName,
            hometown: userInfo.hometown,
            company: userInfo.company,
            university: userInfo.university,
            qq: userInfo.qq,
            wx: userInfo.wx,
            weibo: userInfo.weibo,
            artstation: userInfo.artstation,
            bilibili: userInfo.bilibili,
            xiaohongshu: userInfo.xiaohongshu,
            projectExperience: userInfo.artstation
            
        })
    }, [form])
    useEffect(() => {
        form.resetFields()
        getCountryList({
            pid: '0',
            type: '1'
        })
        getUserDetail()
    }, [getCountryList, getUserDetail, form])
    
    return <AuthWriterCreateStyle>
        <div className='auth-writer-container'>
            <div className='form-wrapper'>
                <Form
                    labelCol={{ span: 8 }}
                    style={{ width: 800 }}
                    autoComplete="off"
                    ref={formRef}
                    form={form}
                    size="large"
                >
                    <Form.Item
                        label="真实姓名"
                        name="realName"
                        rules={[{ required: true, message: '请输入真实姓名' }]}
                    >
                        <Input placeholder="请输入真实姓名" />
                    </Form.Item>
                    <Form.Item
                        label="常用笔名"
                        name="penName"
                        rules={[{ required: true, message: '请输入常用笔名' }]}
                        
                    >
                        <Input placeholder="请输入常用笔名" />
                    </Form.Item>

                    <Form.Item
                        label="所在地"
                        rules={[{ required: true, message: '请选择您的所在地' }]}
                        
                    >
                        <Cascader
                            placeholder={address ? address : "请选择您的所在地"}
                            options={countryList}
                            loadData={loadData}
                            onChange={onChangeCountry}
                            defaultValue={defaultCountry}
                            fieldNames={{
                                label: 'name',
                                value: 'id',
                            }}
                            changeOnSelect
                        />
                    </Form.Item>

                    <Form.Item
                        label="公司信息"
                        name="company"
                        rules={[{ required: true, message: '请输入公司信息' }]}
                    >
                        <Input placeholder="请输入公司信息" />
                    </Form.Item>

                    <Form.Item
                        label="院校信息"
                        name="university"
                        rules={[{ required: true, message: '请输入公司院校' }]}

                    >
                        <Input placeholder="请输入公司院校" />
                    </Form.Item>

                    <Form.Item
                        label="参与项目"
                        name="projectExperience"
                        rules={[{ required: true, message: '请输入参与项目' }]}
                        
                    >
                        <Input placeholder="请输入参与项目" />
                    </Form.Item>

                    <Divider >社交媒体</Divider>
                    <Form.Item
                        label="QQ"
                        name="qq"
                    >
                        <Input placeholder="请输入您的QQ" />
                    </Form.Item>
                    <Form.Item
                        label="微信"
                        name="wx"
                    >
                        <Input placeholder="请输入您的微信" />
                    </Form.Item>
                    <Form.Item
                        label="新浪微博"
                        name="weibo"
                    >
                        <Input placeholder="请输入您的新浪微博个人主页链接" />
                    </Form.Item>
                    <Form.Item
                        label="Artstation"
                        name="artstation"
                    >
                        <Input placeholder="请输入您的Artstation个人主页链接" />
                    </Form.Item>
                    <Form.Item
                        label="Bilibili"
                        name="bilibili"
                    >
                        <Input placeholder="请输入您的Bilibili个人主页链接" />
                    </Form.Item>
                    <Form.Item
                        label="小红书"
                        name="xiaohongshu"
                    >
                        <Input placeholder="请输入您的小红书个人主页链接" />
                    </Form.Item>
                </Form>
                    <div className="btn" onClick={save}>确认</div>
            </div>
            
        </div>

    </AuthWriterCreateStyle >
}

