import { useEffect, useState } from "react"
import { AnswererListStyle } from "./style"
import { getAnsererList, getAnswererDetail } from "api/forum"
import { useLocation, useNavigate } from "react-router-dom"
import { ForumConsultListItem, ForumUserModal } from "api/forum/type"
import { Modal, Pagination, Spin, message } from "antd"
import { getUserId, hasToken, queryUrl } from "utils/functions/common"
import { NoData } from "components/no-data"
import CreateAndUpdateReward from "../components/createAndUpdateReward"
import { loginPathWithRedirect } from "utils/functions/user"

const AnswererList = () => {

    const pageNumber = new URLSearchParams(useLocation().search).get("pageNumber") || 1
    const [list, setList] = useState<ForumConsultListItem[]>([])
    const pageSize = 10
    const [totalSize, setTotalSize] = useState(0)

    const [isShowReward, setIsShowReward] = useState(false)

    const navigate = useNavigate()

    const [selectDetail, setSelectUserInfo] = useState<ForumUserModal>()


    const [loading, setLoading] = useState(false)

    const listOnFlush = () => {

    }

    useEffect(() => {
        setLoading(true)
        getAnsererList({ pageNumber: Number(pageNumber), pageSize }).then((res) => {
            setList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [pageNumber])


    const changePage = (e: number) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(`?${queryUrl({ pageNumber: e })}`)
    }

    const token = hasToken()

    const createReward = (detail: ForumConsultListItem) => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        if (detail.userInfo.id === getUserId()) {
            message.warning("不能邀约自己")
            return
        }
        setIsShowReward(true)
        setSelectUserInfo({
            userInfo: detail.userInfo,
            wage: detail.wage
        })
    }

    return <AnswererListStyle>

        <div className="answerer">
            <Spin spinning={loading}>
                {
                    list.length > 0 && <>
                        <div className="list">
                            {
                                list.map(item => {
                                    return <div className="list-item" key={item.id}>
                                        <div className="list-left">
                                            {
                                                !!item.userInfo && <div className="user-info">
                                                    <div className="user-avatar" onClick={() => window.open(`/user/${item.userId}`)} style={{ backgroundImage: `url(${item.userInfo.avatarUrl})` }} />
                                                    <div className="user-name" onClick={() => window.open(`/user/${item.userId}`)}>{item.userInfo.username}</div>
                                                </div>
                                            }
                                            <div className="user-introduce" title={item.briefIntroduction}>
                                                {
                                                    !!item.briefIntroduction && <>个人简介：{item.briefIntroduction}</>
                                                }
                                            </div>
                                            <div className="price">
                                                <div className="price-word">指导价格：{item.wage}元/次</div>
                                                <div className="btn" onClick={() => createReward(item)}>立即提问</div>
                                            </div>
                                        </div>

                                        <div className="list-right">
                                            <div className="work">
                                                {
                                                    item.workList && item.workList.length > 0 && item.workList.map(item => {
                                                        return <div className="work-item" key={item.id} onClick={() => window.open(`/work/detail/${item.id}`)} style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                                                    })
                                                }
                                            </div>

                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <Pagination
                            style={{ textAlign: "center", margin: "24px 0 12px 0" }}
                            hideOnSinglePage={true}
                            current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                            onChange={changePage}
                            pageSize={pageSize}
                            total={totalSize}
                            showSizeChanger={false}
                            size="default"
                        />
                    </>
                }

                {
                    list.length === 0 && <NoData />
                }
            </Spin>

            <Modal getContainer={false} title='悬赏提问' footer={<></>} width={650} destroyOnClose={true} closable={false} centered={true} open={isShowReward}>
                <CreateAndUpdateReward answererDetail={selectDetail} close={() => setIsShowReward(false)} listOnFlush={listOnFlush} />
            </Modal>

        </div>
    </AnswererListStyle>
}

export default AnswererList