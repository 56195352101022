import { Checkbox, CheckboxProps } from "antd"
import { AutoPublishStyle } from "./style"
import { useEffect, useState } from "react";
import { CfgCopyContestWorkSettingResponse } from "types/common";
import { apiAutoCopyWorksCfg } from "api/common";

type Props = {
    onCheckBoxChange: (flag:number) => void
}
function AutoPublish({onCheckBoxChange}:Props) {
  const [cfg,setCfg] = useState<CfgCopyContestWorkSettingResponse>();
  const [isChecked, setIsChecked] = useState(false);
  const onChange: CheckboxProps['onChange'] = (e) => {
    setIsChecked(e.target.checked)
    onCheckBoxChange(e.target.checked?1:0)
  };
  useEffect(()=>{
    apiAutoCopyWorksCfg().then((res) => {
      setCfg(res)
      onCheckBoxChange(0)
    })
  },[])
  return (
    <AutoPublishStyle className="AutoPublishStyle">
       {cfg?.flag ===1 && <div>
        <Checkbox onChange={onChange} checked={isChecked} className="check-item">{cfg?.title}</Checkbox>
        <a href={cfg?.url} rel="noreferrer" target="_blank">
        <div className="cover" style={{backgroundImage:`url(${cfg?.coverUrl})`}}></div>
        </a>
        </div>}  
    </AutoPublishStyle>
  )
}

export default AutoPublish