import { Button, Form, Input, Modal, Select } from "antd"
import { DeliveryOrderStyle } from "./style"
import { useEffect, useState } from "react"
import { SellerOrderDeliveryRequest } from "types/product";
import { apiGetExpressCodeList } from "api/common";
import { CfgExpressCodeResponse } from "types/common";
import { postDelivery } from "api/shop";

const DeliveryOrder = ({ isDeliveryShow, orderNumber, onCancel, update }: { isDeliveryShow: boolean, orderNumber: string, update: () => void, onCancel: () => void }) => {
    const [form] = Form.useForm<SellerOrderDeliveryRequest>();
    const [list, setList] = useState<CfgExpressCodeResponse[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        apiGetExpressCodeList().then(res => {
            setList(res)
        })

    }, [])

    useEffect(() => {
        if (list && list.length > 0) {
            form.setFieldsValue({
                expressCode: String(list[0].code),
                expressNumber: ""
            })
        }
    }, [list])

    const submit = () => {
        form.validateFields().then(async res => {
            setLoading(true)
            postDelivery({ ...res, orderNumber }).then(() => {
                setTimeout(() => {
                    onCancel()
                    update()
                }, 500)
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const filterOption = (input: string, option?: { name: string; code: string }) =>
        (option?.name ?? '').toLowerCase().includes(input.toLowerCase());

    return <DeliveryOrderStyle>
        <Modal className='modal' width={550} title='发货信息' onCancel={onCancel} centered={true} footer={null} getContainer={false} open={isDeliveryShow}>
            <Form form={form}>
                <Form.Item label="物流编码" rules={[{ required: true, message: '请输入物流' }]} name="expressCode">
                    <Select showSearch filterOption={filterOption} options={list} fieldNames={{ label: "name", value: "code" }} placeholder="请选择物流" />
                </Form.Item>
                <Form.Item label="物流单号" rules={[{ required: true, message: '请输入物流单号' }]} name="expressNumber">
                    <Input placeholder="请输入物流单号" />
                </Form.Item>

                <div className="tip">* 物流单必须对应本订单的收件人信息，填写非本订单物流单号将无法查询</div>

                <div className="btn">
                    <Button type="primary" loading={loading} onClick={submit}>确定提交</Button>
                </div>
            </Form>
        </Modal>
    </DeliveryOrderStyle>
}

export default DeliveryOrder