
import { useEffect } from "react";
import { GridWallStyle } from "./style";
import useCoordinates from "hooks/useCoordinates";
import { WorkWallItem } from "types/user/work";
import { CoverImageObjectCloudResize, isValidURL } from "utils/functions/file";

function GirdWall({ data }: { data: WorkWallItem[] | [] }) {
  const [screenSize, colCount, bigMatrix, midMatrixes] = useCoordinates(120)
  useEffect(() => {
  }, [screenSize, colCount, bigMatrix, midMatrixes])

  const determinedCover = (item: WorkWallItem)=>{
    let path = item.coverUrl
    if(!isValidURL(path)){
      return ''
    }
    const url = new URL(path);
    const hostname = url.hostname;
    if(hostname ==='cdn.ggac.com'){
      return item.originalCoverUrl ?? item.coverUrl
    }

    return item.coverUrl
  }
  
  const getGridItemAttr = (item: WorkWallItem) => {
    switch (item.size) {
      case 'big':
        return {
          backgroundImage: "url(" + item.coverUrl ?? item.originalCoverUrl + ")",
          gridColumnStart: bigMatrix[0],
          gridColumnEnd: bigMatrix[1],
          gridRowStart: bigMatrix[2],
          gridRowEnd: bigMatrix[3],
          aspectRatio: '1/1'

        }
   
      case 'middle':
        let matrix = midMatrixes.pop()
        let obj = {
          backgroundImage: "url(" + item.coverUrl ?? item.originalCoverUrl + ")",
          gridColumnStart: matrix ? matrix[0] : 'auto',
          gridColumnEnd: matrix ? matrix[1] : 'auto',
          gridRowStart: matrix ? matrix[2] : 'auto',
          gridRowEnd: matrix ? matrix[3] : 'auto',
          aspectRatio: '1/1'
        }

        return obj
      
      default:
        return {
          backgroundImage: "url(" + item.coverUrl ?? item.originalCoverUrl + ")",
          gridColumnStart: 'auto',
          gridColumnEnd: 'auto',
          gridRowStart: 'auto',
          gridRowEnd: 'auto',
          aspectRatio: '1/1'
        }
     
    }
  }

  return (
    <>
      <GridWallStyle columnsCount={colCount}>

        {data.map((item, index) => (
          <div
            className="gridBlock"
            style={getGridItemAttr(item)}
            key={index}
          ><a href={`/work/detail/${item.id}`} key={index} target="_blank" rel="noreferrer" /></div>

        ))}
      </GridWallStyle></>
  );
}

export default GirdWall;
