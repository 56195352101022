export enum MoveType {
    COMMODITY_MOVE_TOP = 'top',
    COMMODITY_MOVE_BOTTOM = 'bottom',
    COMMODITY_MOVE_UP = 'up',
    COMMODITY_MOVE_DOWN = 'down'
}

export const MoveTypeArr = [
    { name: '置顶', value: MoveType.COMMODITY_MOVE_TOP },
    { name: '置底', value: MoveType.COMMODITY_MOVE_BOTTOM },
    { name: '上移', value: MoveType.COMMODITY_MOVE_UP },
    { name: '下移', value: MoveType.COMMODITY_MOVE_DOWN }
]



