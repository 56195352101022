import { Button, Form, Input, Modal, message } from "antd"
import { UpdateUserNameStyle } from "./style"
import {  useEffect, useState } from "react"
import { postUpdateUsername } from "api/user"

const UpdateUserName = ({ visible, updateVisible, username, updateName }: { visible: boolean, updateName: () => void, updateVisible: () => void, username: string }) => {
    const [isLoading, setIsLoading] = useState(false)

    const [form] = Form.useForm<any>();

    const submit = () => {
        form.validateFields().then(res => {
            setIsLoading(true)
            postUpdateUsername(res.username).then(() => {
                updateName()
                updateVisible()
                message.success("修改成功")
            }).finally(() => {
                setIsLoading(false)
            })
        })

    }

    useEffect(() => {
        if (!visible) {
            return
        }
        form.setFieldValue('username', username)
    }, [visible])

    const onCancel = () => {
        form.setFieldValue("username", '')
        updateVisible()
    }

    useEffect(() => {

    }, [])
    return <UpdateUserNameStyle>
        <Modal className='modal' title="用户名" centered={true} destroyOnClose footer={null} onCancel={onCancel} open={visible} width={420}>
            <Form form={form} className="form">
                <Form.Item name='username' label="用户名" rules={[
                {
                  required: true,
                  message: "请填写用户名",
                },
                {
                  pattern:/^[\u4e00-\u9fa5a-zA-Z0-9\s_\\(\\)\\（\\）]{2,18}$/,
                  message:"用户名支持中文，英文，数字，下划线和中英文括号，2-18个字符"
                }
              ]}>
                    <Input max={18} placeholder="请输入用户名" />
                </Form.Item>
                <div className="btn"><Button block type="primary" loading={isLoading} onClick={() => submit()}>提交</Button></div>
            </Form>
        </Modal>
    </UpdateUserNameStyle>
}
export default UpdateUserName