import styled from "styled-components";

export const RewardIndexStyle = styled.div`
    .container{
        width: 1032px;
        margin: 0 auto;
        display: grid;
        grid-template-columns:  minmax(737px, 5fr)  minmax(281px, 2fr);
        gap:14px;
        min-height: 100vh;
        .left{
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            padding: ${(props) => props.theme.space.paddingXS}px 0 ${(props) => props.theme.space.paddingLG}px;
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px  ${(props) => props.theme.border.borderRadiusLG}px 0 0;
        }
        
        .right{
            width: 281px;
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            background-color:  ${(props) => props.theme.colors.colorTextLightSolid};
            padding: ${(props) => props.theme.space.paddingLG}px 0;
            border-top: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-right: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-left: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px  ${(props) => props.theme.border.borderRadiusLG}px 0 0;
        }
    }

    .reward-btn {
        width: 100%;
        margin-bottom:${props => props.theme.space.marginLG}px;
    }

    .btn-wrap {
      padding: 0 ${props => props.theme.space.paddingLG}px;
    }

    .border-title {
        margin-bottom: 10px;
        font-weight: bold;
        padding: 0 ${(props) => props.theme.space.paddingLG}px;
    }

    .second-border {
        padding-top: 30px;
    }

    .border {
        display: flex;
        height: 100px;
        border-bottom: 1px solid rgb(240, 240, 240);
        padding: 0 ${props => props.theme.space.paddingLG}px;
        &>div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            
            cursor: pointer;
            .name {
                font-size: 13px;
            }
            .img {
                background-size: 100% 100%;
            }
            .border-top {
              width: max-content;
              margin: 0 auto;
              position: relative;
              width: 48px;
	            height: 48px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(241, 241, 243,0.5);
            }
            .count {
              position: absolute;
              right: -10px;
              top: -4px;
              border-radius: 50%;
              background-color: rgb(255, 154, 22);
              width: max-content;
              height: max-content;
              padding: 3px;
              font-size: 12px;
              width: 18px;
              height: 18px;
              font-size: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
            }
        }
        .qustion {
            .img {
              width: calc(45px / 2);
              height: calc(42px / 2);
            }
        }
        .payed-order {
            .img {
              width: 22px;
              height: 23px;
            }
        }
        .applicant {
          .img {
            width: calc(45px / 2);
            height: calc(42px / 2);
          }
        }

        .invitation {
          .img {
            width: calc(43px / 2);
            height: calc(41px / 2);
          }
        }

        .received-order {
          .img {
            width: calc(45px / 2);
            height: calc(43px / 2);
          }
        }

        .collect {
          .img {
            width: calc(45px / 2);
            height: calc(43px / 2);
          }
        }
    }

    .bottom-tip-price {
      padding: ${props => props.theme.space.padding}px ${props => props.theme.space.paddingLG}px ${props => props.theme.space.paddingLG}px !important;
      border-bottom: 1px solid rgb(240, 240, 240);
    }

    .bottom-tip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${props => props.theme.space.paddingLG}px ${props => props.theme.space.paddingLG}px 0;

      .income {
        font-weight: bold;
        display: flex;
        align-items: center;
        min-width: 0;
        gap: 3px;
      }

      .wenhao {
        cursor: pointer;
        width: 16px;
      }

      .wallet {
        color: #FF9A16;
        cursor: pointer;
      }
    }

    .answerer-btn {
      display: flex;
      margin: ${props => props.theme.space.marginLG}px 0;
      padding: 0 ${props => props.theme.space.paddingLG}px;
      button {
        width: 100%;
      }
    }
`