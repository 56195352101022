import { ReactNode } from "react";
import { SdTagStyle } from "./style"

interface Props extends React.ComponentProps<"div"> { children: ReactNode,}
function SdTag({ children,...rest }: Props) {
  return (
    <SdTagStyle>
       {children}
    </SdTagStyle>
  )
}

export default SdTag