import styled from "styled-components";

export const RewardInvitationListStyle = styled.div`
    .list {
        &-title {
          padding: ${props => props.theme.space.padding}px 0 ${props => props.theme.space.padding}px 20px;
          display: flex;
          span {
              padding: 0 5px;
          }
          .link {
              color: #808080;
              cursor: pointer;
              &:hover {
                color: #FF9A16;
              }
          }
      }

        &-item {
            border-bottom: 1px solid  ${(props) => props.theme.colors.colorBorderSecondary};
            padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
            min-height: 44px;

            .wrap {
                display: flex;
                justify-content: space-between;
                .price {
                    display: flex;
                    color: #FF9A16;
                    gap: 10rpx;
                    align-items: center;
                    flex: 0 0 auto;
                    font-size: 15px;
                    &-icon {
                        background: url(https://cdn-prd.ggac.com/ggac/reward/reward-price.png) center center no-repeat;
                        background-size: 100% 100%;
                        width: 15px;
                        height: 15px;
                        margin: 0 5px;
                    }
                }
            }

            .topic {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                cursor: pointer;
                gap: 30px;
                &-title {
                    flex: 1;
                    min-width: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: bold;
                    font-size: 18px;
                }
                .status {
                    flex: 0 0 auto;
                    display: flex;
                    align-items: center;
                }
            }

            .invitation-wrap {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                div {
                    font-size: 12px;
                    color: #000;
                    background: #ffc712;
                    border-radius: 50px;
                    line-height: 25px;
                    width: 68px;
                    text-align: center;
                    cursor: pointer;
                }
            }

            .word {
                color: #808080;
            }
            
            .state {
                color: #FF9A16;
            }
        }
    }
`