import React, { useEffect, useState } from "react";
import { CommonTabStyle } from "./common-tab-style";

export interface Tab {
  key: number | string;
  title: string;
  number?: number | null | undefined;
  dot?: number
}

interface TabsProps {
  tabs: Tab[];
  onClick: (tab: Tab) => void
  defaultKey?: number | string;
}

const CommonTabs: React.FC<TabsProps> = ({ tabs, onClick, defaultKey = tabs[0].key }) => {
  const [currentTab, setCurrentTab] = useState<Tab>()

  useEffect(() => {
    let tab = tabs.find(tab => tab.key === defaultKey)
    tab && setCurrentTab(tab)
  }, [defaultKey, tabs])
  const handleClick = (tab: Tab) => {
    onClick(tab)
    setCurrentTab(tab)
  }
  return (
    <CommonTabStyle>
      <div className="common-tabs">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={`common-tabs-item ${(tab.key === currentTab?.key) ? "active" : ""}`}
            onClick={() => handleClick(tab)}
          >
            {tab.title}
            {tab.number && ` ( ${tab.number} )`}
            {
              !!(tab?.dot && tab?.dot > 0) && <div className="dot"></div>
            }
          </div>
        ))}
      </div>
    </CommonTabStyle>
  );
};

export default CommonTabs;