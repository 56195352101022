import styled from "styled-components";

export const ShopApplicantStyle = styled.div`
    .applicant-content {
        width: 80%;
        background-color: #fff;
        margin: 0 auto;
        padding-bottom: 50px;
        .applicant-title {
            text-align: center;
            font-size: 25px;
            padding: 50px 0 20px;
            font-weight: bold;
        }
        .applicant-tip {
            text-align: center;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .applicant-step {
            display: flex;
            gap: 100px;
            justify-content: center;
            text-align: center;
            

            &-item {
                max-width: 200px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
                .icon {
                    height: 64px;
                    width: 69px;
                }
            }

            button {
                width: 150px;
                height: 40px;
            }
        }
        
    }

    .wrap {
        display: flex;
        justify-content: flex-end;
        margin: 0 50px;
    }
    .tip {
        width: 400px;
        margin-top: 5px;
        text-align: center;
    }
`