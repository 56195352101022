import { Divider, List, Modal, Skeleton } from "antd"
import { RedeemHistoryStyle } from "./style"
import { useCallback, useEffect, useMemo, useState } from "react"
import { apiGetRedeemOrderList } from "api/redeem"
import { redeemListOrderItem } from "types/redeem"
import InfiniteScroll from "react-infinite-scroll-component"

const RedeemHistory = ({ isShow, updatePopup }: { isShow: boolean, updatePopup: (value: boolean) => void }) => {
    const [list, setList] = useState<redeemListOrderItem[]>([])
    let [pageNumber, setPageNumber] = useState(1)
    const [totalPage, setTotalPage] = useState(1)

    const params = useMemo(() => {
        return {
            pageNumber,
            pageSize: 7
        }
    }, [pageNumber])
    const getList = useCallback(() => {
        apiGetRedeemOrderList(params).then(res => {
            list.push(...res.pageData)
            setList([...list])
            setTotalPage(res.totalPage)
        })
    }, [params])

    const loadMoreData = () => {
        pageNumber = pageNumber + 1
        setPageNumber(pageNumber)
    }
    useEffect(() => {
        getList()
    }, [getList])
    return <RedeemHistoryStyle>
        <Modal footer={<></>} width={900} className="redeem-model" getContainer={false} onCancel={() => updatePopup(false)} destroyOnClose={true} centered={true} open={isShow}>
            <div className="title">兑换记录</div>
            <div
                id="scrollableDiv"
                className="wrap"
            >
                <InfiniteScroll
                    dataLength={list.length}
                    next={loadMoreData}
                    hasMore={pageNumber < totalPage}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={list.length > 0 && <Divider plain>已经到底了 🤐</Divider>}
                    scrollableTarget="scrollableDiv">
                    <List
                        className="list"
                        itemLayout="horizontal"
                        dataSource={list}
                        renderItem={(item) => (
                            <List.Item>
                                <div className="list-item" key={item.id}>
                                    <div className="cover" style={{ backgroundImage: `url(${item.productImage})` }}></div>
                                    <div className="info">
                                        <div className="name info-item">
                                            <span>{item.productName}</span>
                                            <div>{item.updateTime}</div>
                                        </div>
                                        <div className="receiver info-item">
                                            <span>收件人：{item.receiver} {item.phone}</span><span>{item.address}</span>
                                        </div>
                                        <div className="order info-item">
                                            <span>订单状态：{item.status === 1 ? "已支付" : item.status === 2 ? '已完成' : ''}</span>
                                            <span>订单编号：{item.orderNumber} </span>
                                            {
                                                item.isVirtualProduct === 0 && !!item.expressNo && <span>物流单号：{item.expressNo}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )}>

                    </List>
                </InfiniteScroll>
            </div>
        </Modal>
    </RedeemHistoryStyle >
}

export default RedeemHistory