import styled from "styled-components";

export const ArticleDetailStyle = styled.div`
  .guanggao-banner-wrapper {
    max-width: 1280px;
    margin: 0 auto;
  }
  .container {
    max-width: 1280px;
    position: relative;
    margin: 0 auto;
    .section-holder {
      display: flex;
      .left {
        width: 900px;
        overflow: hidden;
        margin-right: ${(props) => props.theme.space.paddingLG}px;
        .content-wrapper {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          .html-content {
            width: 100%;
            margin: 0 auto;
            word-wrap: break-word;
            img {
             max-width: 100%;
             height: auto;
            }
            p {
              video {
  
              }
            }
          }
        }
      }
      .right {
        flex: 1;
        .section-block {
          padding: ${(props) => props.theme.space.paddingLG}px;
          border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
          border: 1px solid ${(props) => props.theme.colors.colorBorder};
          background-color: #fff;
          margin-bottom: ${(props) => props.theme.space.paddingLG}px;
        }
      }
    }

    .works-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: ${(props) => props.theme.space.paddingLG}px;
      .work-list-title {
        padding-bottom: ${(props) => props.theme.space.paddingLG}px;
        font-weight: bold;
      }

      .work-list-wrapper {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        gap:${(props) => props.theme.space.padding}px;
        margin-bottom: 60px;
        .card-item {
          margin: ${(props) => props.theme.space.marginXXS}px;
        }
      }
    }
    .comment-wrapper {
      padding: ${(props) => 2 * props.theme.space.paddingLG}px 0;
      max-width: 900px;

      .input-wrapper {
        margin-top: ${(props) => props.theme.space.marginXS}px;
        position: relative;
        display: flex;
        flex-direction: column;
        .input {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .confirm-btn {
          width: 80px;
          margin-top: ${(props) => props.theme.space.marginXS}px;
          align-self: flex-end;
        }
      }
    }
  }
`;
