export enum WorkTag {
    ALLWORK = 4,
    DraftWork = 0,
    EXAMINEWORK = 1,
    FAILEDWORK = 3
}

export const WorkTagList = [
    { value: WorkTag.ALLWORK, label: "全部" },
    { value: WorkTag.DraftWork, label: "草稿" },
    { value: WorkTag.EXAMINEWORK, label: "审核中" },
    { value: WorkTag.FAILEDWORK, label: "未通过" },
]

export const WorkTagStatusList = [
    { value: WorkTag.ALLWORK, label: "全部" },
    { value: WorkTag.DraftWork, label: "草稿" },
    { value: WorkTag.EXAMINEWORK, label: "审核中" },
    { value: WorkTag.FAILEDWORK, label: "未通过" },
]

export const getWorkTagText = (val: WorkTag) =>{
    return WorkTagStatusList.find(item =>  item.value=== val )?.label
}