import { Col, InputNumber, Modal, Row, Slider } from "antd";

function ScoreWidget({name,key_,inputValue,onUpdate}:{name:string,key_:string,inputValue:number,onUpdate:(key_:string,value:number) => void}) {
    const onChange = (newValue: number | null) => {
        newValue && onUpdate(key_,newValue)
    };
  return (
    <Row gutter={30}>
    <Col span={4}>
    <span style={{lineHeight:'32px'}}>{name}</span>
  </Col>    
    <Col span={12}>
      <Slider
        min={0.1}
        max={10}
        onChange={onChange}
        value={typeof inputValue === "number" ? inputValue : 0}
        style={{width: 280}}
        step={0.1}
      />
    </Col>
    <Col span={4}>
      <InputNumber
        min={0.1}
        max={10}
        style={{ margin: "0 16px" }}
        value={inputValue}
        onChange={onChange}
        step={0.1}
      />
    </Col>
  </Row>
  )
}

export default ScoreWidget