import { get, post } from "api"
import { IMUserInfo, IMLoginInfo, ImChatHistoryRequest, ImChatHistoryResponse, OrderQACreateRequest, OrderQADetail } from "types/im"


export const getImAppid = () =>{
    return get<{},{ appId: number}>("/api/im/get_appId")
}

//获取自己IM信息
export const getIMLoginInfo = async () => {
    return get<{},IMLoginInfo>("/api/im/login")
}

// 注册IM用户
export const apiRegisterImUser = (id: number) => {
    return get<{}, IMUserInfo>("/api/im/get_room_user", {toUserId: id})
}

// 获取历史消息
export const  apiGetChatHistory = (params: ImChatHistoryRequest) =>{
    return get<ImChatHistoryRequest, ImChatHistoryResponse>('/api/im/chat_history', params)
}

// 获取未读消息个数
export const  apiGetImUnreadCount = () =>{
    return get<{}, {count: number}>('/api/im/unread_count')
}

// 获取订单咨询详情
export const  apiGetOrderQADetail = (orderNumber: string) =>{
    return get<{}, OrderQADetail>(`/api/mall_consultation/detail/${orderNumber}`)
}

// 创建订单咨询
export const  apiCreateOrderQADetail = (params: OrderQACreateRequest) =>{
    return post<OrderQACreateRequest, {}>(`/api/mall_consultation/create`, params)
}

