import styled from 'styled-components'

export const PageHeaderStyle = styled.div`
height: 100px;
background-color: ${props => props.theme.colors.colorBgContainer};
left: 0;
top: 0;
width: 100%;
padding: ${props => props.theme.space.padding}px;
.back{
    display: flex;
    align-items: center;
    cursor: pointer;
    span{
        padding: 0 ${props => props.theme.space.paddingXXS}px;
    }
}
`