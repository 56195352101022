
import { WorkListItem } from 'types/work'
import { getWorkTidUrl, translateCardCategoryText } from 'utils/functions/common';
import HotIcons from '../../common/hot-icons';
import UniCardLayout from '../../layout';
import MediaIconsWork from 'components/uni-card/common/media-icons/work';
import CardTitleRender from 'components/uni-card/common/card-title/work';
import PrizeIcons from 'components/uni-card/common/prize-icons';
import HomePageWorkStatus from './homePageWorkStatus';

function HomePageWorkCard({ item,t_id}: { t_id?:number,item: WorkListItem }) {
    const UiObject = {
        coverUrl: item.coverUrl,
        title: <CardTitleRender item={item} />,
        subtitle: <span> {item.dictMap?.mediaCategory} |  {translateCardCategoryText(item.categoryList)}</span>,
        view: item.viewCount,
        hot: item.hot,
        // userName: item.userInfo?.username,
        // avatarUrl: item.userInfo?.avatarUrl,
        date: item.lastSubmitTime,
        icons: HotIcons({ item }),
        workLink:  getWorkTidUrl(item.id,t_id),
        userLink: `/user/${item.userId}`,
        rightTopNode: PrizeIcons({ item }),
        rightBottomNode: MediaIconsWork({ item }),
        leftBottomNode:<HomePageWorkStatus item={item}></HomePageWorkStatus>,
        isPublish: true
    }


    return UniCardLayout({ item: UiObject });
}

export default HomePageWorkCard