import { ArticleListItem } from "types/article"
import { DraftArticleStatusStyle } from "./styled"
import { WorkTagStatusList } from "types/enums/work-tag-type";

const DraftArticleStatus = ({ item }: { item: ArticleListItem }) => {
    const filterTag = (detail: ArticleListItem) => {
        return WorkTagStatusList.find((item) => {
            return item.value === detail.status;
        })?.label;
    };
    return <DraftArticleStatusStyle>
           {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
        {item.isDraft === 1 && <div className="status">{filterTag(item)}</div>}
        {item.onSale === 0 && <div className="status">下架</div>}
     
    </DraftArticleStatusStyle>
}

export default DraftArticleStatus