import { Avatar, Button, Tooltip, message } from "antd";
import { ProductSellerProfileStyle } from "./style";
import { useEffect, useState } from "react";
import { publicUserDetailType } from "types/user/detail";
import { apiPublicUserDetail } from "api/user/detail";
import { postFollowUser } from "api/user";
import { findUserRole, getLocalUserInfo } from "utils/functions/common";
import { determineUserLocation } from "utils/functions/user";
import { ChatRoom } from "components/chat";

type Props = { userId: number, chatModalOpen: boolean, openChat: (value: boolean) => void };
function ProductSellerProfile({ userId, openChat, chatModalOpen }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [useCardDetail, setUserCardDetail] = useState<publicUserDetailType>();
  const localUser = getLocalUserInfo();
  useEffect(() => {
    apiPublicUserDetail(userId).then((res) => {
      setUserCardDetail(res);
    });
  }, [userId]);

  const handleCloseModal = () => {
    openChat(false);
  };

  const onClickOpenChat = () => {
    openChat(true);
  };

  // 关注用户
  const followUser = () => {
    if (useCardDetail) {
      setIsLoading(true);
      const flag = useCardDetail.isFollow === 1 ? 0 : 1;
      postFollowUser({
        followee: userId,
        flag,
      })
        .then(() => {
          useCardDetail.isFollow = flag;
          message.success("操作成功");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const toDetail = () => {
    useCardDetail?.id && window.open(`/user/${useCardDetail?.id}`);
  };

  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
        return "yellow";

      case 3:
        return "blue";

      default:
        return "hide";
    }
  };
  return (
    <ProductSellerProfileStyle>
      <div className="avatar">
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={toDetail}
          size={80}
          src={useCardDetail && useCardDetail.avatarUrl}
        />
        {useCardDetail?.certifiedRoleId && (
          <Tooltip
            title={findUserRole(useCardDetail.certifiedRoleId)?.name}
            placement="bottom"
          >
            <div
              className={`role ${renderColor(useCardDetail.certifiedRoleId)}`}
            >
              V
            </div>
          </Tooltip>
        )}
      </div>

      <div className="wrap_">
        <div className="title" onClick={toDetail}>
          <span>{useCardDetail?.username}</span>
        </div>
        <div className="meta">
          {useCardDetail && determineUserLocation(useCardDetail)}
        </div>
        <div className="btns">
          <Button
            size="small"
            type="primary"
            className={`btn ${useCardDetail?.isFollow === 1 ? "active" : ""}`}
            loading={isLoading}
            onClick={() => followUser()}
          >
            {useCardDetail?.isFollow === 1 ? "已关注" : "关注"}
          </Button>

          {localUser && localUser.id !== userId && (
            <Button size="small" className="btn im" onClick={onClickOpenChat}>
              私信
            </Button>
          )}

          {chatModalOpen && (
            <ChatRoom
              show={chatModalOpen}
              close={handleCloseModal}
              toUserId={useCardDetail?.id!}
            ></ChatRoom>
          )}
        </div>
      </div>
    </ProductSellerProfileStyle>
  );
}

export default ProductSellerProfile;
