import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  message,
} from "antd";
import { FileUpload } from "components/fileUpload";
import { getFileUrlByCosUpload } from "config/cos";
import { ProgressInfo } from "cos-js-sdk-v5";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CosDirectoryType, MediaType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";
import { apiCreateMedia, apiUpdateMedia } from "api/media";
import { ListItem, MarmosetEditForm } from "../type";


const { TextArea } = Input;

type Props = {
  show: boolean;
  params: MarmosetEditForm
  close: () => void;
  refreshMediaList:  (mediaItem: ListItem, type: 'add'| 'update') => void
  mark?:string
};

export const ComboMarmosetUpload = (props: Props) => {
  const [form] = Form.useForm<MarmosetEditForm>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { id } = useParams();
 
  useEffect(() => {
    setConfirmLoading(false);
    form.resetFields();
    form.setFieldsValue({
     ...props.params
    });
  }, [props.show]);

  const onCancel = () => {
    props.close();
  };

  const onClickSubmit = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        let { model, caption} = values
        
          let modelFile = model.file
          const uploadUUid = getFileName(CosDirectoryType.workModel,modelFile!.name)
          let newItem = { id: 0, url: "", coverUrl: '', type: MediaType.marmoset, key: uploadUUid, percent: 0,caption: '' };

          props.refreshMediaList(newItem, 'add')

          props.close()

          props.refreshMediaList(newItem, 'update')
        
          const resultThreeD = await getFileUrlByCosUpload(
            model.file as File,
            uploadUUid,
            onProgress
          );
       

          //绑定媒体，写入数据库
          const dataItem = await apiCreateMedia({
            coverUrl:  '',
            dataId: Number(id),
            dataTable: "work",
            md5: resultThreeD.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
            name: model.file!.name.split(".").shift()+'',
            rank: 0,
            size: model.file!.size,
            type: MediaType.marmoset,
            url: resultThreeD.url,
            mimeType: 'model/mview',
            uuid: uploadUUid,
            caption: caption,
            mark:props.mark
          });
          newItem.url = dataItem.url
          newItem.id = dataItem.id;
          newItem.coverUrl = dataItem.coverUrl;
          newItem.percent=100;
          newItem.caption =dataItem.caption
          props.refreshMediaList(newItem, 'update')
      })
      .catch((err) => {
        setConfirmLoading(false);
      });
  };

    //上传进度回调
    const onProgress = (progressData: ProgressInfo, key: string) => {
      props.refreshMediaList(
          { id: 0, url: "",coverUrl: '',  key: key, type: MediaType.marmoset, percent:  progressData.percent * 100, caption: '' }, 'update'
      ) 
    };

  const onClickSave = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        let {  caption} = values
        
        let newItem = { id: 0, url: "", coverUrl: '', type: MediaType.marmoset, key: '', percent: 0,caption: caption}; 
          //绑定媒体，写入数据库
          const dataItem = await apiUpdateMedia({
            id: props.params.id!,
            caption: caption
          });
          newItem.id = dataItem.id;
          newItem.caption=dataItem.caption
          newItem.coverUrl = dataItem.coverUrl
          newItem.key = dataItem.uuid
          newItem.percent = 100
          newItem.url = dataItem.url
          props.refreshMediaList(newItem, 'update')
          message.success('修改成功!')
          props.close()
      })
      .catch((err) => {
        setConfirmLoading(false);
      });
    
  };


  return (
    <div>
      <Modal
        title="3D文件"
        open={props.show}
        cancelText="取消"
        onCancel={onCancel}
        okText="保存"
        onOk={ props.params.id ? onClickSave : onClickSubmit } 
        confirmLoading={confirmLoading}
        width={500}
        maskClosable={false}
        forceRender
      >
        <Spin spinning={confirmLoading}>
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              
              <Col span={24} > 
                <Form.Item
                  name="model"
                  tooltip="支持.mview格式,可通过Marmoset Toolbag软件导出"
                  label="八猴渲染器文件"
                  rules={[{ required: true, message: '模型文件必填' }, 
                  
                  { validator:  (rule, val, callback) => {
                    if(val.fileUrl){
                      return Promise.resolve()
                    } else {
                      return Promise.reject('模型文件必填')
                    }
                  }}
                ]}
                  >
                  <FileUpload  disabled={!!props.params.id} fileType="mview"  />
                </Form.Item>
              </Col>
              
              <Col span={24}> 
                <Form.Item
                  name="caption"
                  label="描述"
                 
                      >
                    <TextArea placeholder='请输入描述' maxLength={500} rows={2} showCount={true}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};
