import { SearchStyle } from './search'
import { RiCloseFill } from "react-icons/ri";
import SearchPopup from './search-popup';
import { useState, useRef } from 'react'
import SearchList from './search-list';
import { useNavigate } from 'react-router';
import { Input } from 'antd';
import { debounce } from "lodash"

const { Search } = Input

type propsModalType = {
    setIsShowInput: (visible: boolean, needRefesh?: boolean) => void,
}

function SearchContent(props: propsModalType) {
    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState('')
    const inputRef = useRef() as React.MutableRefObject<any>;
    const navigate = useNavigate()
    const close = () => {
        props.setIsShowInput(false)
    }
    const onFocus = () => {
        setVisible(true)
    }
    const onBlur = () => {
        setVisible(false)
    }

    const debounceSetText = debounce((value) => {
        setValue(value);
    }, 500);


    const changeValue = (value: string) => {
        debounceSetText(value);
    }

    const closePopup = () => {
        setVisible(false)
        props.setIsShowInput(false)
    }

    const deleteHistory = () => {
        localStorage.removeItem('search-history')
    }

    const toSearch = (value: string) => {
        const history: string = localStorage.getItem('search-history') || '[]'
        let arr: string[] = JSON.parse(history) || []
        if (value) {
            if (arr.includes(value)) {
                const index = arr.findIndex((e: string) => e === value)
                arr.splice(index, 1)
            }
            arr.unshift(value)
            arr = arr.slice(0, 5)
            localStorage.removeItem('search-history')
            localStorage.setItem('search-history', JSON.stringify(arr))
        }
        navigate(`/search/keyword?name=${value}&type=work`)
        setVisible(false)
        setValue('')
        inputRef.current.blur()
        props.setIsShowInput(false)
    }

    const onkeydown = (e: any) => {
        if (e.keyCode === 13) {
            toSearch(e.target.value)
        }
    }
    return (
        <SearchStyle>
            <div className="header-search-wrap">
                <div className="input-wrap">
                    <Search allowClear={true} type="text" ref={inputRef} placeholder='请输入关键字' onSearch={toSearch} onChange={(e) => changeValue(e.target.value)} onKeyDown={onkeydown} onFocus={() => onFocus()} onBlur={() => onBlur()} />
                    {/* <div className="search" onClick={() => toSearch(value)}>搜索</div> */}
                </div>
                <RiCloseFill className="search-icon" size={22} onClick={() => close()} />
                {
                    !value && <SearchPopup params={{ visible, close: closePopup, toSearch, deleteHistory }} />
                }
                {
                    value && <SearchList visible={visible} close={closePopup} value={value} />
                }
            </div>
        </SearchStyle>

    )
}

export default SearchContent