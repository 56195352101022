import styled from "styled-components";

export const InteractionStyle = styled.div`
  .wrapper {
    margin-top:  ${(props) => props.theme.space.paddingSM}px;
    padding: ${(props) => props.theme.space.paddingSM}px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
    background-color: ${(props) => props.theme.colors.colorHomeBgContainer};
    pointer-events: none;
    .icon-warp {
      display: flex;
      align-items: center;
      .actionIcon {
        margin: 0 ${(props) => props.theme.space.margin}px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50% 50%;
        margin-right: 6px;
        cursor: pointer;
        .icon {
          width: ${(props) => props.theme.fontSizes.fontSizeXL}px;
          height: ${(props) => props.theme.fontSizes.fontSizeXL}px;
          color: #61666d;
        }
        &.active {
          .icon {
            color: ${(props) => props.theme.colors.colorPrimary};
          }
        }
        &:hover {
          .icon {
            color: ${(props) => props.theme.colors.colorPrimary};
          }
        }
      }
      .text {
      }
    }
  }
  .divideText {
    padding: ${(props) => props.theme.space.paddingSM}px 0;
    color: ${(props) => props.theme.colors.colorTextTertiary};
    text-align: center;
  }

  .avatarList {
    padding: ${(props) => 1.2*props.theme.space.paddingLG}px ${(props) => props.theme.space.paddingXS}px 0 ${(props) => props.theme.space.paddingXS}px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    .avatar-item {
      width: 32px;
      aspect-ratio: 1;
    }
    .more {
      width: 30px;
      height: 30px;
      background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
      border-radius: 50% 50%;
      margin-top: 3px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: ${(props) => props.theme.colors.colorPrimary};
    }
  }
`;
