import styled from "styled-components";

export const SideBarStyle = styled.div`
    width: 280px;
    .top-item{
        color:${(props) => props.theme.colors.colorText};
    }
    .ant-menu-sub{
        border-radius: ${(props) => props.theme.border.borderRadius}px !important;
    }
`