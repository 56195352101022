import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TrackScoreListStyle } from "./style";
import { Button, Modal, Spin, Tabs, message } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { queryUrl } from "utils/functions/common";
import { apiGetNewScoreWorkList, apiGetScoreWorkTaskDetail, apiPostUpdateScoreList } from "api/contest";
import { ScoreTaskDetail, ScoreWorkListItem, ScoreWorkListRequest } from "types/contest";
import ScoreWorkCard from "components/uni-card/self-cards/scoreWorkCard";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { NoData } from "components/no-data";

type ScoreListItem = {
  name: string, level: string, sortScore: number, basicScore: number
}

type CopyTabListItem = {
  workList: ScoreWorkListItem[]
} & ScoreListItem

function TrackScoreList() {
  const navigate = useNavigate();
  const localUser = JSON.parse(localStorage.getItem("user_info") || "{}");
  const { id } = useParams();
  const [list, setList] = useState<ScoreWorkListItem[]>([]);
  const [taskDetail, setTaskDetail] = useState<ScoreTaskDetail>();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isDragDisabled, setIsDragDisabled] = useState(true)

  let [searchParams] = useSearchParams();
  let tab = searchParams.get("tab") || 'D';
  let cat = Number(searchParams.get("cat"));
  let theme = Number(searchParams.get("theme"));
  let themeA = Number(searchParams.get("themeA"));
  let scoreFilter = Number(searchParams.get("scoreFilter")) || undefined;
  const [scoreList, setScoreList] = useState<ScoreListItem[]>([])
  const [copyTabList, setCopyTabList] = useState<CopyTabListItem[]>()

  const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
    background: isDraggingOver ? "lightblue" : "#fff",
    overflow: "hidden",
  });

  useEffect(() => {
    getTaskDetail();
  }, [])
  useEffect(() => {
    if (!localUser?.permissions?.includes('workScore')) {
      messageApi.open({
        type: 'error',
        content: '当前登录用户没有权限！',
      });
      return
    }

    if (tab !== 'all') {
      getList();
    }
  }, [id, tab]);

  useEffect(() => {
    if (tab !== 'all') return
    if (scoreList.length === 0) return
    let copyList: CopyTabListItem[] = []
    copyList = JSON.parse(JSON.stringify(scoreList))
    copyList.shift()
    setLoading(true);
    const params: ScoreWorkListRequest = {
      needTotal: 0, //是否需要总数；默认需要，不需要总时，效率高
      taskId: Number(id), //任务ID
      scoreFilter: scoreFilter
    }
    if (!!theme) {
      params.theme = theme
    } else if (!!themeA) {
      params.themeA = themeA
    }
    apiGetNewScoreWorkList(params).then(res => {
      setLoading(false);
      copyList.forEach((item) => {
        if (item.level === 'D') {
          item.workList = res.filter(sub => !['A', 'B', 'C'].includes(sub.scoreLevel))
        } else {
          item.workList = res.filter(sub => sub.scoreLevel === item.level)
        }
      })
      setCopyTabList(copyList)
    })

  }, [tab, scoreList, theme, themeA])

  const getList = async () => {
    setLoading(true);
    const params: ScoreWorkListRequest = {
      needTotal: 0, //是否需要总数；默认需要，不需要总时，效率高
      taskId: Number(id), //任务ID
      scoreFilter: scoreFilter,
      level: tab
    }
    if (!!theme) {
      params.theme = theme
    } else if (!!themeA) {
      params.themeA = themeA
    }
    const res = await apiGetNewScoreWorkList(params);
    setList(res);
    setLoading(false);
  };

  const getTaskDetail = () => {
    apiGetScoreWorkTaskDetail({ taskId: Number(id) }).then((res) => {
      setTaskDetail(res);
      if (!res?.scoreRules) {
        return
      }
      const scoreRules = JSON.parse(res.scoreRules)
      scoreRules.unshift({
        name: "总览",
        level: "all",
        sortScore: "",
        basicScore: ""
      })
      setScoreList(scoreRules)
    });
  };

  const reorder = (
    list: ScoreWorkListItem[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }

    const sourceIndex = list.findIndex(
      (item) => item.id === Number(source.droppableId)
    );
    const destinationIndex = list.findIndex(
      (item) => item.id === Number(destination.droppableId)
    );

    if (sourceIndex !== destinationIndex) {
      const newFileList = reorder(list, sourceIndex, destinationIndex);
      setList(newFileList);
    }

  };

  const changeTab = (e: any) => {
    if (!isDragDisabled) {
      Modal.confirm({
        title: '提示',
        content: '当前处于编辑模式，是否保存当前内容',
        okText: "确定",
        cancelText: "取消",
        onCancel() {
          const query = queryUrl({ tab: e })
          navigate(`?${query}`)
          setIsDragDisabled(true)
        },
        centered: true,
        onOk() {
          apiPostUpdateScoreList({ track: theme || themeA, scoreLevel: tab, taskId: Number(id), workIds: list.map(item => item.id) }).then(() => {
            message.success("保存成功")
            const query = queryUrl({ tab: e })
            navigate(`?${query}`)
            setIsDragDisabled(true)
          })
        },
      });
      return
    }
    const query = queryUrl({ tab: e })
    navigate(`?${query}`)
  }

  // 保存拖拽后顺序
  const saveUpdateList = () => {
    apiPostUpdateScoreList({ track: theme || themeA, scoreLevel: tab, taskId: Number(id), workIds: list.map(item => item.id) }).then(() => {
      setIsDragDisabled(true)
      message.success("保存成功")
    })
  }

  const clickToTop = (detail: ScoreWorkListItem) => {
    const index = list.findIndex(item => item.id === detail.id)
    if (index >= 0) {
      const copyList: ScoreWorkListItem[] = JSON.parse(JSON.stringify(list))
      copyList.splice(index, 1)
      copyList.unshift(detail)
      setList([...copyList])

      if (!!isDragDisabled) {
        apiPostUpdateScoreList({ track: theme || themeA, scoreLevel: tab, taskId: Number(id), workIds: copyList.map(item => item.id) }).then(() => {
          message.success("置顶成功")
        })
      }
    }
  }

  const saveListId = () => {
    localStorage.setItem("scoreListIds", JSON.stringify(list.map(item => item.id)))
  }


  return (
    <TrackScoreListStyle className="score-list">
      {contextHolder}
      <div className="header">
        {taskDetail?.themes.find(item => item.id === (theme || themeA))?.name}
        作品打分列表
      </div>
      <div className="task-info">
        <div className="info-item">
          <div className="label">赛道主题</div>
          <div className="content">
            {taskDetail?.themes.find(item => item.id === (theme || themeA))?.name}
          </div>
        </div>

        <div className="info-item">
          <div className="label">开始时间</div>
          <div className="content">
            {taskDetail?.startTime.substring(0, 10)}
          </div>
        </div>
        <div className="info-item">
          <div className="label">结束时间</div>
          <div className="content">{taskDetail?.endTime.substring(0, 10)}</div>
        </div>
        <div className="info-item">
          <div className="label">打分评委</div>
          <div className="content">{localUser.username}</div>
        </div>
        <div className="info-item">
          <div className="label">比赛</div>
          <div className="content overflow-hidden" >
            {taskDetail?.dataName}</div>
        </div>
      </div>
      {
        tab !== 'all' && <div className="word-wrap">
          <div className="tip">
            <div>所有待评审作品初始默认为D档。【All works to be reviewed are initially assigned to Grade D.】</div>
            <div>第一步，请评委老师点击作品封面，前往作品详情后，对作品进行分类ABC档等级分类。（A档为最高评分档作品，以此类推，即A＞B＞C）<br />【{`Step 1: Please click on the cover of the work to go to the work details, and then classify the work into Grades A, B, or C. (Grade A is the highest-rated work, and so on, i.e., A > B > C)`}】</div>
            <div>第二步，分档结束后，评委老师可前往对应档位列表中，点击右侧「编辑排序」按钮，即可拖拽调整作品排序（同档位下，作品排序越靠前，则代表其排名分数越高）。<span>拖拽排序后，务必点击保存排序。</span><br />【Step 2: After the Selecting is completed, judges can go to the corresponding grade list, click on the 'Edit' button on the right, and then drag to adjust the order of the works (within the same grade, the higher the work is ranked, the higher its score). After dragging to sort, be sure to click 'Save'.】</div>
            <div>感谢评委老师的辛勤评审与专业意见！【Thank you to the judges for your hard work and professional opinions!】</div>
          </div>
        </div>
      }
      <div className="wrap">
        <div className="score-tab">
          <Tabs
            style={{ width: "100%" }}
            size="large"
            centered
            activeKey={tab}
            onChange={changeTab}
            items={scoreList.map(item => {
              return {
                label: <div className="score-itme" key={item.name}>{item.name}</div>,
                key: item.level
              }
            })}
          />
        </div>
        {
          tab !== 'all' && tab !== 'D' && <div className="tip-wrap">
            {
              isDragDisabled && !!list.length && tab !== 'D' && <Button type="primary" onClick={() => setIsDragDisabled(false)}>编辑排序（Edit）</Button>
            }
            {
              !isDragDisabled && list.length && tab !== 'D' && <Button type="primary" onClick={saveUpdateList}>保存排序（Save）</Button>
            }
            {
              !isDragDisabled && <div className="word">当前处于编辑模式，可拖拽调整作品排序</div>
            }
          </div>
        }
      </div>
      {
        tab !== 'all' && <div className="work-list">
          <Spin spinning={loading}>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="list">
                {list.map((item, index) => {
                  return <Droppable direction="horizontal" key={item.id} droppableId={item.id + ""} type="ITEM">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}
                        className="drop-wrap"
                      >
                        {taskDetail && <>
                          <Draggable
                            key={item.id}
                            draggableId={item.id + ""}
                            index={index}
                            isDragDisabled={isDragDisabled}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="drop"
                                onClick={saveListId}
                              >
                                <ScoreWorkCard clickToTop={clickToTop} isShowScore={false} taskMediaCategory={cat} key={index} item={item} needRefresh={getList} scoreTask={taskDetail} isTop={tab !== 'D' && !isDragDisabled}></ScoreWorkCard>
                              </div>
                            )}
                          </Draggable>
                        </>
                        }
                      </div>
                    )}
                  </Droppable>
                })
                }
              </div>

            </DragDropContext>
          </Spin>
          {
            list.length === 0 && <NoData />
          }
        </div>
      }

      {
        tab === 'all' && !!taskDetail && copyTabList && copyTabList.length > 0 && <>
          <Spin spinning={loading}>
            {
              copyTabList.map((item, index) => {
                return <div key={index} style={{ marginTop: "16px" }}>
                  <div className="list-title ">{item.name}</div>
                  {
                    item.workList && item.workList.length > 0 && <div className="list">
                      {
                        item.workList && item.workList.map(sub => {
                          return <ScoreWorkCard clickToTop={clickToTop} isShowScore={false} taskMediaCategory={cat} key={sub.id} item={sub} needRefresh={getList} scoreTask={taskDetail}></ScoreWorkCard>
                        })
                      }
                    </div>
                  }
                </div>
              })
            }
          </Spin>
        </>
      }
    </TrackScoreListStyle>
  );
}

export default TrackScoreList;
