import styled from "styled-components";

export const AddressStyle = styled.div`
.form-wrap{
    padding: 5px ${props => props.theme.space.paddingLG}px;
    .form{
      padding: ${props => props.theme.space.paddingLG}px 0;
    }
    .btn{
        margin-bottom: ${props => props.theme.space.margin}px;
        justify-content: center;
        display: flex;
        align-items: center;
        span{
            margin-right: ${props => props.theme.space.marginXXS}px;
        }
    }
    .btn-wrap{
        display: flex;
        justify-content: center;
    }
}
`