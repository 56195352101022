import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
import { ArticleListStyle } from "./style";
import { SearchOption } from "components/search-option";
import { useEffect, useState } from "react";
import { NoData } from "components/no-data";
import { apiGetDictList, postGlobalSearch } from "api/common";
import { DictItem, GlobalSearchPostResponse } from "types/common";
import { useLocation, useNavigate } from "react-router";
import { queryUrl } from "utils/functions/common";
import PublicCard from "components/uni-card/public-cards/publicESCard";
import { Pagination, Spin } from "antd";

const ArticleList = () => {
  const [articleList, setArticleList] = useState<GlobalSearchPostResponse[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const pageSize = 48;
  const sortField =
    new URLSearchParams(useLocation().search).get("sortField") || "";
  const timeField =
    new URLSearchParams(useLocation().search).get("timeField") || "";
  const code = new URLSearchParams(useLocation().search).get("code") || "";
  const [articleTypeList, setArticleTypeList] = useState<DictItem[]>();
  const navigate = useNavigate();

  const selectType = (value: number) => {
    setCurrentPage(1);
    let query;
    if (value === Number(code)) {
      query = queryUrl({ code: "" });
    } else {
      query = queryUrl({ code: value });
    }
    navigate(`?${query}`);
  };

  const onChange = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    setArticleList([]);
  }, [sortField, timeField, code]);

  useEffect(() => {
    setLoading(true);
    const params: any = {
      pageSize: pageSize,
      pageNumber: currentPage,
      dataTable: "article",
    };
    if (sortField) {
      params.sortField = sortField;
    }
    if (timeField) {
      params.timeField = Number(timeField);
    }
    if (code) {
      params.type = Number(code);
    }
    postGlobalSearch(params)
      .then((res) => {
        setArticleList([ ...res.pageData] );
        setTotalSize(res.totalSize);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage, sortField, timeField, code]);

  useEffect(() => {
    apiGetDictList({ type: "articleType" }).then((res) => {
      setArticleTypeList(res);
    });
  }, []);

  const onClickAll = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("code");
    navigate(`?${params.toString()}`);
  };

  // 切换分页
  const changePage = (e: any) => {
    // setCurrentPage(e)
    const query = queryUrl({
      pageNumber: e,
    });
    setCurrentPage(e);
    navigate(`?${query}`);
  };

  useEffect(() => {
    const scrollableDiv = document.querySelector("#scrollableDiv")
    if (!scrollableDiv) {
      return
    }
    scrollableDiv.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage])

  return (
    <ArticleListStyle>
      <div className="artilce-container" id="scrollableDiv">
        <div className="line"></div>
        <div className="guanggao-banner-wrapper">
          <GuanggaoBannerCarousel positionCode={18}></GuanggaoBannerCarousel>
        </div>
        <div className="article-type">
       
          <div className="list">
            <div
              className={`list-item ${code === "" ? "active" : ""}`}
              onClick={onClickAll}
            >
              全部
            </div>
            {articleTypeList &&
              articleTypeList.map((item, index) => {
                return (
                  <div
                    onClick={() => selectType(Number(item.code))}
                    className={`list-item ${
                      item.code === Number(code) ? "active" : ""
                    }`}
                    key={index}
                  >
                    <span>{item.name}</span>{" "}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="select">
          <SearchOption onChange={onChange} isShowTypeList={false} />
        </div>
        <div className="list-wrapper">
          {articleList.length === 0 && !loading && (
            <div style={{ marginTop: "200px" }}>
              <NoData></NoData>
            </div>
          )}
        <Spin spinning={loading}>
          <div className="list">
            {articleList?.map((item) => {
              return (
                <>
                  {/* <CommonCard key={item.id} item={item}></CommonCard> */}
                  <PublicCard key={item.id} item={item}></PublicCard>
                </>
              );
            })}
          </div>
          </Spin>
          {articleList.length > 0 && (
            <Pagination
              style={{ textAlign: "center", margin: "42px 0 42px 0" }}
              hideOnSinglePage={true}
              current={currentPage}
              onChange={changePage}
              pageSize={pageSize}
              total={totalSize > 9600 ? 9600 : totalSize}
              showSizeChanger={false}
              showQuickJumper
            />
          )}
        </div>
      </div>
    </ArticleListStyle>
  );
};

export default ArticleList;
