import styled from "styled-components";

export const AnswererListStyle = styled.div`
    .answerer {
        width: 1032px;
        margin: 0 auto;
        padding-bottom: ${props => props.theme.space.padding}px;

        .btn-wrap {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            div {
                background-color: ${props => props.theme.colors.colorPrimary};
                padding: 5px 20px;
                border-radius: 5px;
                cursor: pointer;
            }
        }

        .list {
            &-item {
                padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingLG}px;
                background-color: #fff;
                margin-bottom: 20px;
                display: flex;

                .list-left {
                    width: 400px;
                    .user-info {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .user-avatar {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-size: cover;
                            background-position: center;
                            cursor: pointer;
                        }
                        .user-name {
                            max-width: calc(100% - 70px);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                        }
                    }

                    .user-introduce{
                        margin-top: 10px;
                        color: #94969f;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        min-height: 22px;
                    }

                    .price {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        gap: 30px;
                        justify-content: space-between;
                        .btn {
                            background-color: ${props => props.theme.colors.colorPrimary};
                            padding: 5px 20px;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    }
                }
                .list-right {
                    width: calc(100% - 400px);
                    height: auto;
                    .work {
                        display: flex;
                        gap: 30px;
                        margin-left: 30px;      
                        height: 100%;               
                        &-item {
                            width: calc((100% - 2 * 30px) / 3);
                            height: 100%;
                            border-radius: 10px;
                            background-size: cover;
                            background-position: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
`