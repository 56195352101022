import { RankingAuthorItem } from 'types/billboard';
import { ExtraInfoStyle } from './style'
import { Divider, Space } from 'antd';

type Props ={
    item: RankingAuthorItem 
}
function ExtraInfo({item}:Props) {
  return (
    <ExtraInfoStyle>
          <Space split={<Divider type="vertical" />}>
          <div className='data-item'>
                <div className='title'>上周排名</div>
                <div className='data'>{item.top}</div>
            </div>
            <div className='data-item'>
                <div className='title'>本周排名</div>
                <div className='data'>{item.top}</div>
            </div>
            <div className='data-item'>
                <div className='title'>评论数</div>
                <div className='data'>{item.commentCount}</div>
            </div>
            <div className='data-item'>
                <div className='title'>点赞数</div>
                <div className='data'>{item.likeCount}</div>
            </div>
  </Space>

    </ExtraInfoStyle>
  )
}

export default ExtraInfo