import { TagsStyle } from "./style";
import SdTag from "ui-lib/tag/sd-tag";

function Tags(props: {
  tagList: { id: number; name: string; type: number }[];
  hardwareTagList: { id: number; name: string; type: number, coverUrl: string }[];
  softwareTagList: { id: number; name: string; type: number, coverUrl: string }[];
}) {



  if (!props.softwareTagList && !props.hardwareTagList && props.tagList?.length === 0) { return <></> } else {

    return (
      <div >
        <TagsStyle>
          {props.tagList && props.tagList.length > 0 && (
            <div className="inlineTags tags">
              <div className="title">标签：</div>
              <div className="tag-list">

                {props.tagList.map((item, index) => {
                  return (
                    <SdTag key={index}>
                      <div onClick={() => window.open(`/search/keyword?type=work&name=${item.name}`)}>{item.name}</div>
                    </SdTag>
                  );
                })}
              </div>
            </div>
          )}

          {props.hardwareTagList && props.hardwareTagList.length > 0 && (
            <div className="inlineTags">
              <div className="title">硬件：</div>
              <div className="tag-list">
                {props.hardwareTagList.map((item, index) => {
                  return <div key={index}>
                    {item.coverUrl && <SdTag key={index} title={item.name}>
                      <img src={item.coverUrl} alt="" title={item.name} />
                    </SdTag>}
                    {!item.coverUrl && <SdTag key={index} title={item.name}>
                      <div className="no-image-tag" title={item.name}>{item.name.charAt(0).toUpperCase()}</div>
                    </SdTag>}
                  </div>
                })}
              </div>
            </div>
          )}

          {props.softwareTagList && props.softwareTagList.length > 0 && (
            <div className="inlineTags">
              <div className="title">软件：</div>
              <div className="tag-list">
                {props.softwareTagList.map((item, index) => {
                  return <div key={index}>
                    {item.coverUrl && <SdTag key={index} title={item.name}>
                      <img src={item.coverUrl} alt="" title={item.name} />
                    </SdTag>}
                    {!item.coverUrl && <SdTag key={index} title={item.name}>
                      <div className="no-image-tag" title={item.name}>{item.name.charAt(0).toUpperCase()}</div>
                    </SdTag>}
                  </div>
                })}
              </div>
            </div>
          )}
        </TagsStyle>
      </div>
    );
  }

}

export default Tags;
