import { Button, Form, Input, message } from "antd"
import { AuthenticationIdCardStyle } from "./style"
import ImageUpload from "../components/uploadImage"
import { IdCardResponse } from "types/common"
import { getUserIdentification, postUserIdentification } from "api/user"
import { useEffect, useState } from "react"
import { UserIdentificationDetailResponse } from "types/user"

type submitParams = {
    backUrl: {
        file?: File,
        imgUrl?: string
    },
    frontUrl: {
        file?: File,
        imgUrl?: string
    },
    idCard: string
    name: string
}

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};

const AuthenticationIdCard = () => {
    const [form] = Form.useForm<submitParams>();
    let userId = JSON.parse(localStorage.getItem('user_info')!).id
    const [detail, setDetail] = useState<UserIdentificationDetailResponse>()
    const [loading, setLoading] = useState(false)
    const [canEdit, setCanEdit] = useState(false)

    const getMessage = (value: IdCardResponse) => {
        form.setFieldValue("name", value.name)
        form.setFieldValue("idCard", value.idNum)
    }

    const getDetail = () => {
        getUserIdentification().then(res => {
            setDetail(res)
            if (res) {
                form.setFieldsValue({
                    frontUrl: {
                        file: undefined,
                        imgUrl: res.frontUrl || ''
                    },
                    backUrl: {
                        file: undefined,
                        imgUrl: res.backUrl || ''
                    },
                    idCard: res.idCard,
                    name: res.name
                })
            }

        })
    }

    useEffect(() => {
        getDetail()
    }, [])

    const save = () => {
        if (detail?.status !== 0 && !canEdit) {
            setCanEdit(true)
            return
        }
        form.validateFields().then(async res => {
            const { backUrl, frontUrl, idCard, name } = res
            if (!frontUrl.imgUrl) {
                message.error("请上传身份证正面照片")
                return
            }
            if (!backUrl.imgUrl) {
                message.error("请上传身份证反面照片")
                return
            }
            setLoading(true)
            postUserIdentification({ backUrl: backUrl.imgUrl || "", frontUrl: frontUrl.imgUrl || "", idCard, name }).then(res => {
                message.success("提交成功")
                setCanEdit(false)
                getDetail()
            }).finally(() => {
                setLoading(false)
            })
        })

    }

    return <AuthenticationIdCardStyle>
        <Form
            {...formItemLayout}
            className='form'
            style={{ maxWidth: 800 }}
            labelAlign='left'
            form={form}
            disabled={detail?.status !== 0 && !canEdit}
        >
            {
                detail && detail.status !== 0 && <Form.Item
                    label="审核状态"
                >
                    {detail.status === 1 ? '审核中' : detail.status === 2 ? '已认证' : detail.status === 3 ? '已驳回' : ''}
                </Form.Item>
            }
            <Form.Item
                name="frontUrl"
                label="身份证正面照片"
                rules={[{ required: true, message: '请上传身份证正面照片' }]}
                className="frontUrl"
            >
                <ImageUpload type='frontUrl' getMessage={getMessage} />
            </Form.Item>
            <Form.Item
                name="backUrl"
                label="身份证反面照片"
                rules={[{ required: true, message: '请上传身份证反面照片' }]}
                className="url"
            >
                <ImageUpload type='backUrl' getMessage={getMessage} />
            </Form.Item>
            <Form.Item
                name="name"
                label="真实姓名"
                rules={[{ required: true, message: '请输入真实姓名' }]}
            >
                <Input type="text" maxLength={30} placeholder='请输入真实姓名' />
            </Form.Item>
            <Form.Item
                name="idCard"
                label="身份证号"
                rules={[{ required: true, message: '请输入身份证号' }]}
            >
                <Input type="text" maxLength={30} placeholder='请输入身份证号' />
            </Form.Item>
            <Form.Item className="btn">

                <div className="btn-wrap">
                    {
                        detail && detail.refuseReason && detail.status === 3 && <div className="refuseReason">驳回原因：{detail?.refuseReason}</div>
                    }
                    <Button disabled={detail?.status === 1} loading={loading} type="primary" onClick={save}>{detail?.status === 1 ? '审核中' : detail?.status === 2 || detail?.status === 3 ? '修改信息' : '提交'}</Button>
                </div>
            </Form.Item>
        </Form>
    </AuthenticationIdCardStyle>
}

export default AuthenticationIdCard