import styled from "styled-components";

export const MonthlyCardStyle = styled.div`
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      column-gap: ${(props) => props.theme.space.padding}px;
      margin: 0 auto;
    .card {
    background-color: #fff;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.colors.colorBorder};
    cursor: pointer;
    &:hover{
      box-shadow: ${(props) => props.theme.shadow.boxShadowThin};
    }
    .cover {
      width: 100%;
      aspect-ratio: 223/176;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${(props) => props.theme.colors.colorBlack6Ggac};
    }
    .content {
      padding:  ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.paddingMD}px;
      .flex {
        display: flex;
        flex-direction: column;
        .upper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            margin-bottom: ${(props) => props.theme.space.paddingXXS}px;
            color: ${(props) => props.theme.colors.colorText};
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            width: 230px;
            white-space: nowrap;
            width: calc(100% - '60px');
            &:hover{
          color: ${props => props.theme.colors.colorLinkHover};
        }
          }
          .view {
            display: flex;
            align-items: center;
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            color: ${(props) => props.theme.colors.colorTextTertiary};
            .icon{
              font-size: ${(props) => props.theme.fontSizes.fontSize}px;
              padding-bottom: 2px;
            }
            span {
              padding-left: ${(props) => props.theme.space.paddingXXS}px;
              
            }
          }
        }
        .bottom{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: fit-content;
          a {
            display: flex;
            align-items: center;
          }
          .avatar{
            min-width: 24px;
            margin-right: ${props => props.theme.space.marginXXS}px;
          }
          .user-name {
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
           
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
            width: calc(100% - '60px')
        }
        }
        
      }
    }
  }
`