import { Button, Modal, Pagination, Spin, message } from "antd"
import { FolderStyle } from "./style"
import FavoritesCard from "components/favorite/favorites-card"
import { RiAddFill } from "react-icons/ri";
import FavoriteCreate from "components/favorite/create-favorite";
import { useCallback, useEffect, useState, useMemo } from "react";
import { apiFavoriteList } from "api/favorite";
import { UserBaseInfo } from "types/user";
import { favoriteListItem } from "types/favorite";
import { PaginationResponse } from "types/common/axios"
import { NoData } from "components/no-data";
import { useNavigate } from "react-router-dom";


export const FavoriteList = () => {
    const [isShowFavoriteModal, setIsShowFavoriteModal] = useState(false)
    const [favoriteList, setFavoriteList] = useState<favoriteListItem[]>([])
    const [favoriteData, setFavoriteData] = useState<PaginationResponse<favoriteListItem>>()
    const [editItem, setEditItem] = useState<favoriteListItem | undefined>()
    const [pageNumber, setPageNumber] = useState(1);
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)


    const navigate = useNavigate()

    const params = useMemo(() => {
        return {
            pageNumber,
            userId: userInfo.id,
            pageSize: 30
        }
    }, [pageNumber])

    const getList = useCallback(() => {
        setLoading(true)
        apiFavoriteList(params).then(res => {
            setFavoriteList(res.pageData)
            setFavoriteData(res)
        }).finally(() => {
            setLoading(false)
        })
    }, [params])

    useEffect(() => {
        getList()
    }, [getList])

    const favoriteSuccess = () => {
        setIsShowFavoriteModal(false)
        getList()
        message.success("操作成功")
    }

    const openFavoritePopup = () => {
        setDisabled(false)
        setEditItem(undefined)
        setIsShowFavoriteModal(true)
    }

    const updateList = () => {
        getList()
    }

    const editFavoriteId = (details: favoriteListItem) => {
        setIsShowFavoriteModal(true)
        setDisabled(true)
        setEditItem(details)
    }

    const toDetail = (detail: favoriteListItem) => {
        navigate(`/user-center/home/favorites/detail/${detail.id}?type=${detail.type}`);
    }

    const changePage = (e: number) => {
        setPageNumber(e);
    };

    return <FolderStyle>
        <div className="top">
            <div className="top-right">
                <Button onClick={() => openFavoritePopup()} className="button" type="primary">
                    <RiAddFill size={18}></RiAddFill>创建收藏夹
                </Button>
            </div>
        </div>
        <Spin spinning={loading}>
            <FavoritesCard toDetail={toDetail} list={favoriteList} edit={editFavoriteId} updateList={updateList} />
        </Spin>
        <Modal footer={null} closable={false} destroyOnClose={true} centered={true} open={isShowFavoriteModal}>
            <FavoriteCreate disabled={disabled} details={editItem} onClose={() => setIsShowFavoriteModal(false)} success={favoriteSuccess} />
        </Modal>
        {
            favoriteList.length === 0 && <div style={{ padding: "200px 0" }}><NoData /></div>
        }
        <Pagination
            style={{ textAlign: "center", marginTop: "15px" }}
            hideOnSinglePage={true}
            current={pageNumber}
            onChange={changePage}
            pageSize={params.pageSize}
            total={favoriteData?.totalSize}
        />
    </FolderStyle>
}