import styled from "styled-components";

export const UserWorksStyle = styled.div`
  width: 100%;

  .data-list {
    display: grid;
    gap: ${(props) => props.theme.space.padding}px;
    min-width: 1050px;
    & > div {
      display: grid;
    }
  }
  
 
`;

export const ContestCardStyle = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  background-color: ${(props) => props.theme.colors.colorBlack1Ggac};
  border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
  padding: 12px;
  .title {
    font-weight: 800;
    font-size: 16px;
  }
 
  .wrap {
    max-height: 80px;
    overflow-y: auto;
  }
  .footer {
    display: flex;
    margin-top:  ${(props) => props.theme.space.margin}px;
    .btn {
      color: ${(props) => props.theme.colors.colorTextSecondary};
      &:hover {
        color: ${(props) => props.theme.colors.colorPrimary};
        cursor: pointer;
      }
    }
  }
`;
