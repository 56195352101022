import { useState } from 'react'
import { PublishTopicStyle } from './style'
import TopicCreateAndUdate from 'views/forum/topic-edit';
import { Button } from 'antd';
import { isLogin } from 'views/forum/fn';
import { apiGetUserBaseInfo } from 'api/user';

function PublishTopic() {
    const [showEdit, setShowEdit] = useState(false);
  return (
    <PublishTopicStyle>
      {!isLogin() && <Button
              type="primary"
              onClick={() => {
                apiGetUserBaseInfo()
              }}
              block
            >
              登录发布话题
            </Button>}
      {isLogin() &&  <div className="publish">
            <Button
              type="primary"
              onClick={() => {
                setShowEdit(true);
              }}
              block
            >
              发布话题
            </Button>
            <div className="icon-btns">
              <div className="icon-btn" onClick={() => {window.location.href = "/forum/question/my/topic"}}>
                <div className="round-icon" style={{backgroundColor:"rgba(241, 241, 243,0.5)"}}>
                 <img src="https://cdn-prd.ggac.com/ggac/icon/forum-icon10.png" alt="" width={23} style={{marginRight:4}}/>
                </div>
                <span>我的发布</span>
              </div>
              <div className="icon-btn" onClick={() => {window.location.href = "/forum/question/my/reply"}}>
                <div className="round-icon" style={{backgroundColor:'rgba(241, 241, 243,0.5)'}}>
                <img src="https://cdn-prd.ggac.com/ggac/icon/forum-icon11.png" alt="" width={22}/>
                </div>
                <span>我的回复</span>
              </div>
              {/* <div className="icon-btn" onClick={() => {window.location.href = "/forum/question/my/favorite"}}>
                <div className="round-icon" style={{backgroundColor:'#fff3e5'}}>
                <img src="https://cdn-prd.ggac.com/ggac/icon/forum-icon3.png" alt="" width={22}/>
                </div>
                <span>我的收藏</span>
              </div> */}
            </div>
          </div>}
       
          <TopicCreateAndUdate
        isModalOpen={showEdit}
        handleOk={() => {
          setShowEdit(false);
        }}
        handleCancel={() => {
          setShowEdit(false);
        }}
      ></TopicCreateAndUdate>
    </PublishTopicStyle>
  )
}

export default PublishTopic