import styled from "styled-components";

export const ContributionDetailStyle = styled.div`
    .detail {
        .cover {
            width: 100%;
            border-radius: ${props => props.theme.border.borderRadius}px;
        }
        .part {
            width: 500px;
            margin: ${props => props.theme.space.marginLG}px auto;
            .section-title {
                text-align: center;
                font-weight: bold;
                font-size: 18px;
            }
        }
        .btn {
            text-align: center;
            margin: ${props => props.theme.space.margin}px auto;
        }

        .content {
            text-align: center;
            margin: ${props => props.theme.space.marginLG}px 0;
        }
    }
`