import { MallReviewListResponse, orderDetailResponse } from "types/product"
import { SellerOrderStateStyle } from "./style"
import { RiInformationLine } from "react-icons/ri"
import { Button, Modal, message } from "antd"
import { apiGetSellerOrderDetail, getMallReviewDetail, postDelivery, postSellerResetOrder } from "api/shop"
import { useEffect, useState } from "react"
import DeliveryOrder from "./delivery"
import { useNavigate } from "react-router-dom"
import Countdown from "antd/es/statistic/Countdown"
import EvaluateOrder from "../orderState/evaluate"
import ExpressInfo from "components/expressInfo"
import { translateRefundDetailStatus } from "../../fn"
import dayjs from "dayjs"
import UpdatePrice from "../../updatePrice"
import { initChatService, logoutChat, orderConfirmSubmissionSendMessage, orderUpdatePriceSendMessage } from "utils/functions/chat"

const SellerOrderState = ({ detail, update }: { detail: orderDetailResponse, update: () => void }) => {
    const [loading, setLoading] = useState(false)
    const [isDeliveryShow, setIsDeliveryShow] = useState(false)
    let state = new URLSearchParams(window.location.search).get("state");
    const navigate = useNavigate()
    const [reviewDetail, setReviewDetail] = useState<MallReviewListResponse>()
    const [disabled, setDisabled] = useState(false)
    const [isEvaluateShow, setIsEvaluateShow] = useState(false)
    const [isShowUpdatePriceModal, setIsShowUpdatePriceModal] = useState(false)
    const reset = () => {
        Modal.confirm({
            title: '确定取消？',
            content: '取消订单后,订单将会失效！',
            okText: "确定",
            cancelText: "取消",
            onCancel() { },
            centered: true,
            onOk() {
                setLoading(true)
                postSellerResetOrder(detail.orderNumber).then(() => {
                    message.success("取消成功")
                    update()
                }).finally(() => {
                    setLoading(false)
                })
            },
        });
    }

    const lookReview = () => {
        setIsEvaluateShow(true)
    }

    useEffect(() => {
        if (detail.state === 3 && detail.reviewState === 1 && detail.orderNumber) {
            getMallReviewDetail(detail.orderNumber).then(res => {
                setReviewDetail(res)
                setDisabled(true)
            })
        }
    }, [detail])

    useEffect(() => {
        if (state === undefined || state === null) {
            return
        }
        if (!detail) {
            return
        }
        const { pathname } = window.location
        if (Number(state) === 1) {
            setIsDeliveryShow(true)
        }
        navigate(pathname, { replace: true })
    }, [state])

    const deliveryOrder = () => {
        setIsDeliveryShow(true)
    }

    const toUpdatePrice = () => {
        setIsShowUpdatePriceModal(true)
        if (detail.mallProduct.categoryList[0].code === 'yuegao') {
            initChatService(detail.userId)
        }
    }

    const refreshOrder = () => {
        setIsShowUpdatePriceModal(false);
        update()
        if (detail.mallProduct.categoryList[0].code === 'yuegao') {
            apiGetSellerOrderDetail(detail.orderNumber).then(res => {
                orderUpdatePriceSendMessage(res)
            })
        }
    }

    const confirmSubmission = () => {
        initChatService(detail.userId)
        Modal.confirm({
            title: '提示',
            content: '确定交稿吗？',
            okText: "确定",
            cancelText: "取消",
            onCancel() { },
            centered: true,
            onOk() {
                postDelivery({
                    expressNumber: detail.orderNumber,
                    orderNumber: detail.orderNumber,
                    expressCode: detail.orderNumber
                }).then(() => {
                    message.success("交稿成功")
                    update && update()
                    orderConfirmSubmissionSendMessage(detail)
                })

            },
        });

    }

    return <SellerOrderStateStyle>
        <div className="state">
            <div className="tip">
                <RiInformationLine />
                {
                    detail.state === 0 && <div>等待买家支付</div>
                }

                {
                    detail.state === 1 && <div>{detail.mallProduct.isManuscript ? '已支付，待交稿' : '订单已支付，等待卖家发货'}</div>
                }
                {
                    detail.state === 1 && !!detail.mallProduct.lastSendTime && !detail.mallProduct.isManuscript && <div className="value" style={{ paddingLeft: 4, fontWeight: "bold" }}>
                        {detail.mallProduct.lastSendType === 3 ? '预售' : ''} {dayjs(detail.mallProduct.lastSendTime).format("YYYY年MM月DD日 HH时")}前发货
                    </div>
                }
                {
                    detail.state === 2 && <>
                        {
                            !!detail.mallProduct.isAuthorization && <>已支付</>
                        }
                        {
                            !!detail.mallProduct.isManuscript && <>已提交，待确认</>
                        }
                        {
                            !detail.mallProduct.isAuthorization && !detail.mallProduct.isManuscript && <>等待确定收货</>
                        }
                    </>
                }
                {
                    detail.state === 3 && <div>订单已完成</div>
                }
                {
                    detail.state === 4 && <div className="row-cell">
                        {detail.closeType === 1 ? "逾期未支付" : ""}
                        {detail.closeType === 2 ? "买家取消" : ""}
                        {detail.closeType === 3 ? "卖家取消" : ""}
                        {detail.closeType === 4 ? "平台取消" : ""}
                    </div>
                }
            </div>
            {detail && detail.refund && (
                <div className="tip">
                    <span className="label">售后状态：</span> <span className="red">{translateRefundDetailStatus(detail, 'seller')}</span>
                </div>
            )}
            <div className="btn-wrap">
                {
                    detail.state === 0 && (!!detail.mallProduct.isAuthorization || !!detail.mallProduct.isManuscript) && <>
                        <Button type="primary" onClick={() => toUpdatePrice()}>修改价格</Button>
                    </>
                }
                {
                    detail.state === 0 && <>
                        <Button type="primary" loading={loading} onClick={reset}>取消订单</Button>
                    </>
                }
                {
                    detail.state === 1 && !!detail.mallProduct.isManuscript && <>
                        <Button type="primary" onClick={confirmSubmission}>确定交稿</Button>
                    </>
                }
                {
                    detail.state === 1 && detail.refundFlag === 0 && !detail.mallProduct.isManuscript && <>
                        <Button type="primary" onClick={deliveryOrder}>提交物流信息</Button>
                    </>
                }
            </div>
            {
                (detail.mallProduct?.productType === 2 || !!detail.mallProduct.isManuscript) && <div className="logistic">
                    {
                        (detail.state === 2 || detail.state === 3) && !detail.mallProduct.isManuscript && <>
                            <div className="express">
                                <div className="item">
                                    <div className="label">物流名称</div>
                                    <div className="valie">{detail?.expressName}</div>
                                </div>
                                <div className="item">
                                    <div className="label">物流单号</div>
                                    <div className="valie">{detail?.expressNumber}</div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        detail.state === 2 && <div className="time">
                            <span >
                                <Countdown value={Date.now() + (detail.autoConfirmSecond * 1000)} format="D 天 HH : mm : ss " />
                            </span>&nbsp;&nbsp;后自动收货
                        </div>
                    }
                </div>
            }
            {
                detail.expressNumber && detail.mallProduct.productType === 2 && <ExpressInfo orderNumber={detail.orderNumber}></ExpressInfo>
            }
            {
                detail.state === 3 && detail.reviewState === 1 && <>
                    <Button type="primary" onClick={lookReview}>查看评价</Button>
                </>
            }
        </div>
        <EvaluateOrder disabled={disabled} reviewDetail={reviewDetail} isEvaluateShow={isEvaluateShow} update={update} onCancel={() => setIsEvaluateShow(false)} orderNumber={detail.orderNumber} />
        <DeliveryOrder isDeliveryShow={isDeliveryShow} update={update} onCancel={() => setIsDeliveryShow(false)} orderNumber={detail.orderNumber} />
        <Modal footer={null} width={300} getContainer={false} destroyOnClose={true} onCancel={() => { setIsShowUpdatePriceModal(false); logoutChat() }} centered={true} open={isShowUpdatePriceModal}>
            <UpdatePrice isYuegao={!!detail.mallProduct.isManuscript} refreshOrder={() => refreshOrder()} detail={detail} />
        </Modal>
    </SellerOrderStateStyle >
}

export default SellerOrderState