import { apiGetCosCredential } from "api/tencent/cos";
import COS, { ProgressInfo } from "cos-js-sdk-v5";
// import { DEF_ENVVAR_REACT_APP_COS_BUCKET, DEF_ENVVAR_REACT_APP_CDN_LINK } from 'config/const-envvar'
// console.log(DEF_ENVVAR_REACT_APP_CDN_LINK);


//获取临时密钥, 然后组成一个回调函数，传给 COS构造函数
// const getAuthorization = async function (
// 	options: any,
// 	callback: Function
// ) {
// 	const { credentials, startTime, expiredTime } = await apiGetCosCredential();
// 	callback({
// 		TmpSecretId: credentials.tmpSecretId,
// 		TmpSecretKey: credentials.tmpSecretKey,
// 		XCosSecurityToken: credentials.sessionToken,
// 		StartTime: startTime,
// 		ExpiredTime: expiredTime,
// 	});
// };

let BucketName = ''
let CdnLink = ''

const getCosConfig = async () =>{
	const { credentials, startTime, expiredTime, bucketName,cdnHost  } = await apiGetCosCredential();
	BucketName = bucketName;
	CdnLink = cdnHost;
	return {
				SecretId: credentials.tmpSecretId,
				SecretKey: credentials.tmpSecretKey,
				XCosSecurityToken: credentials.sessionToken,
				StartTime: startTime,
				ExpiredTime: expiredTime,
		}
}




//用临时密钥 初始化COS 操作对象
// export const cos = async () => {
// 	return new COS({
// 		getAuthorization
// 	});
// };

export const getFileUrlByCosUpload = async (file: File, key: string,onProgress:(progressData: ProgressInfo,key:string)=>void =()=>{}): Promise<{ url: string, md5: string}> => {
	let obj = await getCosConfig()
	
	let res = new COS(obj)
	return new Promise((resolve, reject) => {
		res.putObject({
			Bucket: BucketName,
			Region: 'ap-shanghai',
			Key: key,
			Body: file,
			onProgress: (progressData: ProgressInfo) => { // 进度回调函数
				onProgress(progressData,key)
			}
		}, (err: COS.CosError, data: COS.PutObjectResult) => {
			//console.log(data,9999999)
			if (!err) {
				let linkIndex = data.Location.lastIndexOf('.com/') + 5
				//console.log(linkIndex)
				let link = data.Location.substring(linkIndex)
				//console.log(link)
				resolve({ url: `${CdnLink}${link}`,md5:data.ETag})
			} else {
				reject({ err: err });
			}
		})
	})
}