import { Col, Form,  Modal, Row,  Spin, message, UploadFile } from "antd";
import { apiCreateMedia } from "api/media";
import { getFileUrlByCosUpload } from "config/cos";
import { ProgressInfo } from "cos-js-sdk-v5";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CosDirectoryType, MediaType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";
import { ImageDescUploadList } from "../../imageDescUploadList";
import { ImageEditForm, ImageUploadProps, ListItem } from "../type";
import { ImageUploadStyle } from "./style";
import { isFileOversized, isImage, isImageResolutionTooLarge } from "utils/functions/file";


export const ComboImageUpload = (props: ImageUploadProps) => {
  const [form] = Form.useForm<ImageEditForm[]>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { id } = useParams();
  const [picList, setPicList] = useState<UploadFile[]>([])


  useEffect(() => {
    setConfirmLoading(false);
    form.resetFields();
    form.setFieldsValue(props.params);
  }, [props.show]);

  const onCancel = () => {
    props.close();
  };


 

  const onClickSubmit = async () => {
    if(picList.length === 0) {
      return
    }
    setConfirmLoading(true);
    let index = 0
    props.close()
    while(index<=picList.length) {
         
          if(!picList[index]){
            index++ ;
            continue
          }

          let { response, originFileObj } = picList[index];

          let imgFile = originFileObj;
          

          if(imgFile && !isImage(imgFile)){
            message.warning('上传文件中包含不支持的图片格式,请检查后重新上传');
            setConfirmLoading(false);
            return
          }

          if(imgFile && isFileOversized(imgFile,20)){
            message.warning('上传文件中包含尺寸超出20M文件,请检查后重新上传');
            setConfirmLoading(false);
            return
          }
          
          if(imgFile &&  await isImageResolutionTooLarge(imgFile, 7680 , 7680)) {
            message.warning('包含超大分辨率图片,请检查后重新上传');
            setConfirmLoading(false);
            return
          }

          const uploadUUid = getFileName(CosDirectoryType.workImage, imgFile!.name);
          let newItem:ListItem  = {
            id: 0,
            url: "",
            coverUrl: "",
            type: MediaType.image,
            key: uploadUUid,
            percent: 0,
            caption: response,
          };
          
          await props.refreshMediaList(newItem, "add");
      
          // props.refreshMediaList(newItem, "update");
          const resultImg = await getFileUrlByCosUpload(
            imgFile as File,
            uploadUUid,
            onProgress
          );
      
          //绑定媒体，写入数据库
          const dataItem = await apiCreateMedia({
            coverUrl: "",
            dataId: Number(id),
            dataTable: "work",
            md5: resultImg.md5.replace(/["\\]/g, ""), // 去除cos md5多余的字符
            name: imgFile!.name.split(".").shift() + "",
            rank: 0,
            size: imgFile!.size,
            type: MediaType.image,
            url: resultImg.url,
            mimeType: imgFile!.type,
            uuid: uploadUUid,
            caption: response,
            mark:props.mark
          });
          newItem.url = dataItem.url;
          newItem.id = dataItem.id;
          newItem.percent = 100;
          newItem.caption = dataItem.caption;
          await props.refreshMediaList(newItem, "update");
          index++
          if(index >= picList.length) {
            console.log("全部上传完毕")
            setPicList([])
            props.close()
          }
    }
     
      
  };


  const  onFileChange = (val: any) =>{
    setPicList(val)
 }

  //上传进度回调
  const onProgress = (progressData: ProgressInfo, key: string) => {
    console.log(progressData)
    props.refreshMediaList(
      {
        id: 0,
        url: "",
        coverUrl: "",
        key: key,
        type: MediaType.image,
        percent: progressData.percent * 100 >10? progressData.percent * 100 -10: progressData.percent,
        caption: '',
      },
      "update"
    );
  };

  return (
    <ImageUploadStyle>
      <Modal
        getContainer={false}
        className="upload-image-modal"
        title="上传图片"
        open={props.show}
  
        cancelText="取消"
        onCancel={onCancel}
        okText="保存"
        onOk={ onClickSubmit }
        confirmLoading={confirmLoading}
        width={780}
        maskClosable={false}
      >
        <Spin spinning={confirmLoading}>
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="img"
                  label="单次最多上传8张图片，单张图片大小不超过20M，单张图片分辨率不超过7680x7680"
                  rules={[
                    {
                      required: true,
                      message: "图片必填",
                    },
                    {
                      validator: (rule, val, callback) => {
                        if (val&& val.length>0) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("");
                        }
                      },
                    },
                  ]}
                >
                  <ImageDescUploadList
                    listType="picture-card"
                    maxCount={8}
                    value={picList}
                    onChange={onFileChange}
                  ></ImageDescUploadList>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>

    </ImageUploadStyle>
  );
};
