import { Input, Radio, Form, Button } from "antd"
import { CreateFavoriteStyle } from "./style"
import { useEffect, useState } from "react"
import TextArea from "antd/es/input/TextArea"
import { postCreateFavorite, postEditFavorite } from "api/favorite"
import { favoriteListItem } from "types/favorite"

type Props = {
    type?: number, // 1（代表作品和文章），2(代表图片)
    onClose: () => void,
    success: (detail: favoriteListItem) => void,
    details?: favoriteListItem,
    disabled?: boolean
}
const FavoriteCreate = ({ type = 1, success, onClose, details, disabled = false }: Props) => {
    const [form] = Form.useForm<any>();
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (details) {
            form.setFieldsValue({
                name: details?.name || "",
                isPrivate: details?.isPrivate || 0,
                type: details?.type || type,
                description: details?.description || ""
            })
            return
        }
        form.setFieldsValue({
            name: "",
            isPrivate: 0,
            type,
            description: ""
        })
        console.log(type)
    }, [type, details])
    const submit = async () => {
        const value = await form.validateFields();
        setIsLoading(true)
        if (details) {
            value.id = details.id
        }
        const func = details ? postEditFavorite : postCreateFavorite
        func(value).then(res => {
            success(res)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    return <CreateFavoriteStyle>
        <div className="favorite-model">
            <div className="title">{details ? '编辑' : '创建'}收藏夹</div>
            <Form form={form} requiredMark={false}>
                <Form.Item label="收藏夹名称" name="name" rules={[{ required: true, message: '请输入收藏夹名称' }]}>
                    <Input type="text" placeholder="收藏夹名称" />
                </Form.Item>
                <Form.Item label='是否公开&nbsp;&nbsp;&nbsp;&nbsp;' name="isPrivate" rules={[{ required: true, message: '请选择是否是私密收藏夹' }]}>
                    <Radio.Group>
                        <Radio value={0}>公开</Radio>
                        <Radio value={1}>私密</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='收藏夹类型' name="type" rules={[{ required: true, message: '请选择收藏夹类型' }]}>
                    <Radio.Group disabled={disabled}>
                        <Radio value={1}>作品/文章</Radio>
                        <Radio value={2}>图片</Radio>
                        <Radio value={3}>商品</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="收藏夹描述" name="description">
                    <TextArea placeholder="收藏夹描述" />
                </Form.Item>
                <div className="btns">
                    <Button type="primary" onClick={submit} loading={isLoading}>确定</Button>
                    <Button onClick={onClose} type="default">取消</Button>
                </div>
            </Form>
        </div>
    </CreateFavoriteStyle>
}
export default FavoriteCreate