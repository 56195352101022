import { Button, Form, Input, Modal, Select, message } from "antd"
import { BusinessContactStyle, FormStyle } from "./style"
import { useEffect, useState } from "react";
import { apiGetDictList } from "api/common";
import { DictItem } from "types/common";
import { postJobBusinessContact } from "api/common";
import { Checkbox } from 'antd';
const CheckboxGroup = Checkbox.Group;

const UserBusinessContact = ({ isShow = false, onClose, partnerUser }: { isShow?: boolean, onClose: () => void, partnerUser?: { name?: string, id?: number } }) => {
    const [form] = Form.useForm<any>();
    const [typeList, setTypeList] = useState<DictItem[]>()

    const submit = () => {
        form.validateFields().then(res => {
            if (res.type.includes("contractAward")) {
                res.contractAward = 1
            } else {
                res.contractAward = 0
            }
            if (res.type.includes("copyrightCooperation")) {
                res.copyrightCooperation = 1
            } else {
                res.copyrightCooperation = 0
            }
            delete res.type
            postJobBusinessContact({ ...res, partnerUserId: partnerUser?.id }).then(() => {
                message.success("提交成功")
                onClose()
            })
        })
    }

    useEffect(() => {
        apiGetDictList({ type: "cooperativeNeedType" }).then(res => {
            setTypeList(res)
        })
    }, [])
    useEffect(() => {
        form.setFieldValue("partnerUserName", partnerUser?.name)
    }, [partnerUser])

    useEffect(() => {
        if (!isShow) {
            form.resetFields()
        }
    }, [isShow])

    const onCheckboxChange = () => {

    }

    return <BusinessContactStyle>
        <Modal className='modal' onCancel={onClose} footer={<></>} title="合作邀约" destroyOnClose={true} centered={true} open={isShow} width={640}>
            <FormStyle>
                <div className="tip">请尽可能详细的填写以下内容，G站小助手将尽快为您联系作者对接需求:</div>

                <Form labelCol={{ span: 5 }} form={form} className="form">
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "请选择合作类型",
                        }
                    ]} name="type" label="合作类型">
                        <Checkbox.Group style={{ width: '100%' }} onChange={onCheckboxChange}>
                            <Checkbox value="contractAward">我要发包</Checkbox>
                            <Checkbox value="copyrightCooperation">版权合作</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                    {/* {
                        partnerUser?.name && <Form.Item name="partnerUserName" label="合作人名称">
                            <Input disabled placeholder="合作人名称"></Input>
                        </Form.Item>
                    } */}
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "姓名",
                        }
                    ]} name="name" label="姓名">
                        <Input placeholder="请输入姓名"></Input>
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "请输入手机号",
                        },
                        {
                            pattern: /^[1][3,4,5,6.7,8,9][0-9]{9}$/,
                            message: "手机号格式有误"
                        }
                    ]} name="phone" label="手机号">
                        <Input placeholder="请输入手机号"></Input>
                    </Form.Item>

                    <Form.Item name="other_contact" label="其他联系方式">
                        <Input placeholder="您可填写微信、QQ、邮箱等常用联系方式"></Input>
                    </Form.Item>

                    <Form.Item rules={[
                        {
                            required: true,
                            message: "公司或者团队",
                        }
                    ]} name="companyName" label="公司或者团队">
                        <Input placeholder="请输入公司或者团队"></Input>
                    </Form.Item>

                    <Form.Item rules={[
                        {
                            required: true,
                            message: "您的职位",
                        }
                    ]} name="position" label="您的职位">
                        <Input placeholder="请输入您的职位"></Input>
                    </Form.Item>

                    <Form.Item rules={[
                        {
                            required: true,
                            message: "需求类型",
                        }
                    ]} name="projectType" label="需求类型">
                        <Select
                            style={{ width: 160 }}
                            options={typeList}
                            fieldNames={{ label: 'name', value: 'code' }}
                            placeholder="请输入所属行业"
                        />
                    </Form.Item>

                    <Form.Item name="projectNeed" rules={[
                        {
                            required: true,
                            message: "需求描述",
                        }
                    ]} label="需求描述">
                        <Input.TextArea placeholder="建议您详细填写制作项目、风格、所需职位等信息"></Input.TextArea>
                    </Form.Item>

                    <div className="btn" style={{ textAlign: 'center' }}>
                        <Button onClick={submit} type="primary" block>提交</Button>
                    </div>
                </Form>
            </FormStyle>
        </Modal >
    </BusinessContactStyle >
}

export default UserBusinessContact