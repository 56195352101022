import styled from "styled-components";

interface ManageStyleProps {
  flag?: number;
}

export const ManageStyle = styled.div<ManageStyleProps>`
  .table-wrap {
    /* width: 1200px; */
    padding-top: ${(props) => props.theme.space.paddingXS}px;
    .title {
      font-size: ${(props) => props.theme.fontSizes.fontSizeHeading5}px;
      font-weight: bold;
    }
    .table-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${(props) => props.theme.colors.colorBgContainer};
      padding: 0 ${(props) => props.theme.space.padding}px;
      margin: ${(props) => props.theme.space.margin}px 0;
      border-radius: ${(props) => props.theme.border.borderRadiusSM}px;
    }
    .main {
      margin-top: ${(props) => props.theme.space.paddingLG}px;
      padding-bottom: ${(props) => props.theme.space.padding*2.4}px;
      border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
      background-color: ${(props) => props.theme.colors.colorTextLightSolid};
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      .main-top {
        padding-top:14px;
        display: flex;
        justify-content: space-between;
        position: relative;
        .tab{
            padding-bottom: 24px !important;
          }
        .actions {
          display: flex;
          gap: ${(props) => props.theme.space.padding}px;
          position: absolute;
          right: 0;
          top: 20px;
          margin-right: ${(props) => props.theme.space.padding}px;
        
          .ant-input-group-wrapper {
            padding: 0 7px;
            input {
              padding: 0 10px;
            }
            .ant-input-affix-wrapper {
              border-radius: 50px 0 0 50px;
            }
            .ant-input {
              border-radius: 50px 0 0 50px;
            }
            .ant-btn {
              border-radius: 0 50px 50px 0;
            }
          }
        }
      }

      .tip {
        margin-bottom: ${(props) => props.theme.space.marginLG}px;
      }

      .main-list {
        padding: ${(props) => props.theme.space.padding}px;
        padding-top: ${(props) => props.theme.space.paddingLG}px;
        .heading {
          background-color: #f1f1f3;
          color: ${(props) => props.theme.colors.colorTextSecondary};
          border-radius: ${(props) => props.theme.border.borderRadius}px;
          display: grid;
          grid-template-columns: ${(props) => 
          
           {
       
            return ( props.flag===1 ? '0.4fr 0.5fr 1.5fr 2fr 1fr 1fr 1fr 1fr 2fr' : ' 0.4fr 1.5fr 2fr 1fr 1fr 1fr 1fr  2fr')
           }
         };
          
          
          text-align: center;
          padding: ${(props) => props.theme.space.paddingXS}px 0;
          padding-left: ${(props) => props.theme.space.paddingLG}px;
        }
        .list {
          .list-item {
            border: 1px solid
              ${(props) => props.theme.colors.colorBorderSecondary};
            margin-top: ${(props) => props.theme.space.paddingLG}px;
            border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
            padding-bottom:  ${(props) => props.theme.space.paddingLG}px;
            .top {
              padding: ${(props) => props.theme.space.paddingSM}px
                ${(props) => props.theme.space.paddingLG}px;
              border-bottom: 1px solid
                ${(props) => props.theme.colors.colorBorderSecondary};
            }
            .row {
              display: grid;
              grid-template-columns: ${(props) => props.flag===1 ? '0.4fr 0.5fr 1.5fr 2fr 1fr 1fr 1fr 1fr 2fr' : ' 0.4fr 1.5fr 2fr 1fr 1fr 1fr 1fr  2fr'};
             
              padding: ${(props) => props.theme.space.paddingLG}px 0;
              padding-left: ${(props) => props.theme.space.paddingLG}px;
              .expand{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: ${(props) => props.theme.colors.colorTextTertiary};
                font-size: 16px;
                cursor: pointer;
                &:hover{
                  color: ${(props) => props.theme.colors.colorPrimary};
                }
              }
              .btns {
                display: flex;
                gap: 5px;
                flex-wrap: wrap;
              }
              
              .row-cell {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                .no_ {
                  font-size: 20px;
                }
                .refuse{
                  display: flex;
                  align-content: center;
                  gap: 2px;
                  &:hover{
                    .icon{
                    color: ${(props) => props.theme.colors.colorPrimary};
                  }
                  }
                  .icon{
                    color: ${(props) => props.theme.colors.colorError};
                    font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
                    cursor: pointer;
                    padding-top: 3px;
                  }
                }
                .cell-card {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  gap: ${(props) => props.theme.space.padding}px;
                  
                  .title_ {
                    word-wrap: break-word;
                    width: 125px;
                    text-align: left;
                    padding-left: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .thumbnail {
                    width: 80px;
                    height: 80px;
                    border-radius: ${(props) =>
                      props.theme.border.borderRadius}px;
                    overflow: hidden;
                    object-fit: cover;
                  }
                }
              }
            }
            .expand-content{
             
   
       
             
              .sku{
                padding: ${(props) => props.theme.space.paddingLG}px 0;
                padding-left: ${(props) => props.theme.space.paddingLG}px;
                display: grid;
        
                grid-template-columns: ${(props) => props.flag===1 ? '0.4fr 0.5fr  1.5fr 2fr 1fr 1fr 1fr 1fr 2fr' : ' 0.4fr 1.5fr 2fr 1fr 1fr 1fr 1fr  2fr'};
                gap: 2px;
                justify-content: center;
                align-items: center;
                .info{
                  display: flex;
                  gap: 4px;
                  align-items: center;
                  .cover{
                  height: 50px;
                  width: 50px;
                  background-position: center;
                  background-size: cover;
                  border-radius: ${(props) =>
                      props.theme.border.borderRadius}px;
                }
                .name_{
                  padding-left: 60px;
                }
                }
              
                span{
              
                  text-align: center;
                }
               
              }
            
            }
       
          }
        }
      }
    }
    .wrap {
      display: flex;
    }
  }
  .popover-btn {
    display: flex;
    flex-direction: column;
    gap: 5px;
    button {
      margin: 0 ${(props) => props.theme.space.marginXXS}px;
    }
  }
  .btn {
    display: flex;
    align-items: center;
  }
`;
