import styled from "styled-components";

export const SpecificationStyle = styled.div`
    .btn {
        margin-bottom: ${props => props.theme.space.margin}px;
    }
    .wrap {
        width: 100%;
        .card {
            display: flex;
            border: 1px solid  ${props=>props.theme.colors.colorPrimary};
            padding: ${props=>props.theme.space.padding}px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: ${props=>props.theme.space.margin}px;
            border-radius: ${props=>props.theme.border.borderRadiusLG}px;
            .left {
                display: flex;
                align-items: center;
                .cover {
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    background-size: cover;
                    margin: ${props=>props.theme.space.margin}px;
                }
            }
            .name {
                max-width: 300px;
            }
            .right {
                color: ${props=>props.theme.colors.colorPrimary};
                cursor: pointer;
            }
        }
    }
`