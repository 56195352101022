import { RouteObject } from "react-router-dom";
import NftAlbumDetail from "views/album/detail";
import Layout from "views/layout";

export const albumRoutes: RouteObject[] = [

    {
        path: "/album",
        element: <Layout></Layout>,
        children: [
            {
                path: "detail/:id",
                element: <NftAlbumDetail />
            }
        ]

    }
]