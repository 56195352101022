
import { UserCenterLayoutStyle } from "./style";
import { Outlet, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { apiPublicUserDetail, apiPublicUserStatistics } from "api/user/detail";
import { publicUserDetailType } from "types/user/detail";
import { determineUserBackground } from "views/user-center/layout/fn";
import UserCenterProfile from "views/user-center/layout/user-profile";
import UiTabs from "ui-lib/tab";
import LatestVisits from "../latest-visits";
import Comments from "components/comments";
import { Button, Modal, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import EmojiPicker from "components/emoji-picker";
import { postCreateComment } from "api/work/comment";
import { emojiParse } from "utils/functions/common";
import { UserBaseInfo } from "types/user";


function UserFrontPageLayout() {
  const [userDetail, setUserDetail] = useState<publicUserDetailType>();
  const params = useParams();
  const commentRef = useRef() as React.MutableRefObject<any>;
  const [commentValue, setCommentValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)

  const [showModal, setShowModal] = useState(false)
  // 调用commet创建方法
  const createComment = () => {
    if (!commentValue.trim()) {
      message.error("请输入留言内容！")
      return
    }
    setLoading(true)
    postCreateComment({
      content: emojiParse(commentValue),
      dataId: Number(params.id),
      dataTable: 'user'
    }).then((res) => {
      message.success('创建留言成功')
      commentRef.current?.addComment(res)
      setCount(count + 1)
    }).finally(() => {
      setCommentValue('')
      setLoading(false)
    })
  }

  useEffect(() => {
    commentRef.current.resetComment()
    getUserDetail();
    document.documentElement.scrollTop = 0
  }, [params.id])

  const setCommentCount = (value: number) => {
    setCount(value)
  }
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

  const userFrontPageTabs = [
    { key: 1, title: "作品", url: '/user/' + params.id, urlGroup: ['/user/', '/user//works'] },
    { key: 2, title: "作品集", url: '/user/' + params.id + '/work-album', urlGroup: ['/user//work-album', '/user//work-album/detail/'] },
    { key: 3, title: "赛事 ", url: '/user/' + params.id + '/contest-works', urlGroup: ['/user//contest-works'] },
    { key: 4, title: "文章 ", url: '/user/' + params.id + '/articles', urlGroup: ['/user//articles'] },
    // { key: 5, title: "店铺 ", url: '/user/' + params.id + '/shop', urlGroup: ['/user//shop'] }
    { key: 5, title: "收藏 ", url: '/user/' + params.id + '/favorites', urlGroup: ['/user//favorites', '/user//favorites/detail/'] }
  ];

  const [tab, setTab] = useState(userFrontPageTabs)

  useEffect(() => {
    // if (!userInfo) {
    //   return
    // }
    if (!!(userDetail?.nftPublisherId && userDetail?.nftPublisherId > 0)) {
      const index = tab.findIndex(item => item.key === 9)
      if (index > -1) {
        return
      }
      setTab([...tab, { key: 9, title: "图鉴 ", url: '/user/' + params.id + '/album', urlGroup: ['/user//album'] }])
    }


    if (!!(userDetail?.shopId && userDetail?.shopId > 0)) {
      const index = tab.findIndex(item => item.key === 8)
      if (index > -1) {
        return
      }
      setTab([...tab, { key: 8, title: "店铺 ", url: '/user/' + params.id + '/shop', urlGroup: ['/user//shop'] }])
    }


  }, [userInfo, userDetail, tab])

  const getUserDetail = () => {
    apiPublicUserDetail(Number(params.id)).then((res) => {
      setUserDetail(res);
    });
  };

  const updateCommentCount = () => {
    apiPublicUserStatistics({ id: Number(params.id) }).then((res) => {
      setCount(res.commentCount)
    });
  }

  const appendEmoji = (item: { url: string, title: string }) => {
    setCommentValue((res) => res + item.title)
  }



  return (
    <UserCenterLayoutStyle>
      {/* <div className="layout-padding"></div> */}
      <div
        className="banner"
        style={{
          backgroundImage: `url(${determineUserBackground(
            userDetail?.userInfo?.bgImage ?? ""
          )})`,
        }}
      >
      </div>
      <div className="main-container">
        <div className="left-pannel">
          <UserCenterProfile from="user-frontpage" setCommentCount={setCommentCount} type="user-frontpage"></UserCenterProfile>
          <LatestVisits></LatestVisits>
        </div>
        <div className="right-pannel">
          <UiTabs tabs={tab}></UiTabs>
          {params.id === '25' && <span className="zizhi" onClick={() => setShowModal(true)}> 资质信息</span>}
          <Outlet context={{ detail: userDetail }} />
          <div className="comment-wrap">
            <div className="comment-top">
              <TextArea placeholder="请输入您的留言" value={commentValue} onChange={(e) => setCommentValue(e.target.value)} />
              <div className="bottom">
                <EmojiPicker appendEmoji={appendEmoji} />
                <Button type="primary" onClick={createComment} loading={loading}>发布留言</Button>
              </div>
            </div>
            <Comments ref={commentRef} params={{ commentCount: count, updateCommentCount, type: 'user', word: '留言' }}></Comments>
          </div>
        </div>
      </div>

      <Modal
        centered
        onCancel={() => { setShowModal(false) }}
        width="928px"
        open={showModal}
        title={'资质信息'}
        footer={<Button type="primary" onClick={() => {
          setShowModal(false)
        }}>确认</Button>}
      >

        <img src="https://cdn-prd.ggac.com/ggac/assets/image/shop/zizhi.jpg" alt="" style={{ width: '90%', margin: '0 auto', display: 'block' }} />


      </Modal>
    </UserCenterLayoutStyle>
  );
}

export default UserFrontPageLayout;
