import { useEffect, useState } from "react";
import { WorkDetailNavStyle } from "./style";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { WorkNextIdResponse } from "types/work";
import { getNextWork } from "api/work";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import { setWorkListParamsForNav } from "utils/functions/common";

function WorkDetailNav({ id, userId }: { id: number; userId: number }) {
  const [params,setParams] = useState<any>();
  const [isLast,setIsLast] = useState(false);
  let [searchParams] = useSearchParams();
  const t_id = searchParams.get('t_id');

  useEffect(() => {
    if(t_id){
      const workListParamsForNav = localStorage.getItem("workListParamsForNav");
    if (workListParamsForNav) {

      const theMap = new Map(JSON.parse(workListParamsForNav ?? "{}"));

      const params =theMap.get(Number(t_id))
      params && setParams(params);
    } 
    }
    
  }, []);

  const getNextId = (opt:string,params:WorkNextIdResponse)=>{
   return getNextWork({opt:opt,...params}).then(res=>{
      params['id']= res.id
      params.number = res.number
      params.pageSize = res.pageSize
      params.pageNumber = res.pageNumber
      setParams(params)
      if(params.id === -1){
        message.warning('已经是最后一篇了')
        params['id']= id
        params['number']= params['number'] - 1
        setIsLast(true)
        return
      }else{
        setIsLast(false)
      }
      if(res.id>0){
        window.location.href =`/work/detail/${res.id}?t_id=${t_id}`
       }
      setWorkListParamsForNav(params,Number(t_id))
    })
    
  }

  const getPrev = async () => {
    if(params){
     await getNextId("back",params)
    }

  };
  const getNext = async () => {
    if(params){
      await getNextId("forward",params)
     }
  };
  return <>
 {t_id && params &&
        <WorkDetailNavStyle>
          <div
            className="prev arrow"
            title="上一篇"
            onClick={() => getPrev()}
            style={{
              visibility: params.id === 0 ||(params.number===0 && params.pageNumber===1)? "hidden" : "visible",
            }}
          >
            <RiArrowLeftSLine color="#fff"></RiArrowLeftSLine>
          </div>
          <div
            className="next arrow"
            title="下一篇"
            onClick={() => getNext()}
            style={{
              visibility:
              isLast? "hidden" : "visible",
            }}
          >
            <RiArrowRightSLine color="#fff"></RiArrowRightSLine>
          
          </div>
        </WorkDetailNavStyle>
}
    
  
  </>
}


export default WorkDetailNav;
