import styled from "styled-components";

export const NfAlbumListStyle = styled.div`
    @media (max-width: 1700px) and (min-width: 1414px) {
      .card{
          grid-template-columns: repeat(5, 1fr) !important;
      }
    }

    @media (max-width: 1414px) and (min-width: 1050px) {
      .card{
          grid-template-columns: repeat(4, 1fr) !important;
      }
    }

    @media (max-width: 1050px) {
      .card{
          grid-template-columns: repeat(4, 1fr) !important;
      }
    }
    .section-title {
          font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
          padding-bottom: ${(props) => props.theme.space.paddingLG}px;
        }
    .card {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: ${(props) => props.theme.space.padding}px;
        padding: ${props => props.theme.space.padding}px 0;
        &-item {
            border: 1px solid ${(props) => props.theme.colors.colorBorder};
            border-radius: 6px;
            width: 100%;
            cursor: pointer;
            min-width: 200px;
            .cover {
                background-size: cover;
                background-position: center;
                width: 100%;
                aspect-ratio: 266 / 226;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
            }
            .bottom {
                padding: ${props => props.theme.space.padding}px;
                .name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
                .nft-count {
                    background: url("https://cdn-prd.ggac.com/ggac/nft/nft-ablum/nft-list-item-bg.png") center center no-repeat;
                    background-size: cover;
                    width: 130px;
                    aspect-ratio: 237 / 40;
                    margin-top: 5px;
                    text-align: center;
                }
            }
        }
    }
`