import styled from "styled-components";

export const SellerOrderStateStyle = styled.div`
    .state {
        /* display: flex; */
        align-items: center;
        padding: 30px;
        .tip {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: ${props => props.theme.space.margin}px;
            .red{
              color:${props => props.theme.colors.colorError}
            }
            .label{
                min-width: 70px;
              
            }
        }
        .logistic{
            display: flex;
            flex-direction: column;
            gap:${props => props.theme.space.margin}px;
            .express{
                color:  ${props => props.theme.colors.colorLinkDefault};
            }
            .time {
                display: flex;
                align-items: center;
                .ant-statistic-content {
                    color: #C70200;
                    font-size: 16px;
                }
            
            font-size: 14px;
        }
        }
        .express {
            display: flex;
            gap:${props => props.theme.space.marginLG}px;
            .item {
                display: flex;
                gap: 10px;
            }
        }

    }
`