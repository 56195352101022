import { ArticleListItem } from "types/article"
import { PublicArticleStatusStyle } from "./styled"
import { WorkTagStatusList } from "types/enums/work-tag-type";

const PublicArticleStatus = ({ item }: { item: ArticleListItem }) => {
    const filterTag = (detail: ArticleListItem) => {
        return WorkTagStatusList.find((item) => {
            return item.value === detail.status;
        })?.label;
    };
    return <PublicArticleStatusStyle>
           {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
        {item.isDraft === 0 && item.status !== 2 && <div className="status">{filterTag(item)}</div>}
        {item.onSale === 0 && <div className="status">下架</div>}
    </PublicArticleStatusStyle>
}

export default PublicArticleStatus