import { CardStyle } from './style'

import { EventExhibitionDetailResponse } from 'types/events'
import { CoverImageObjectCloudResize } from 'utils/functions/file'

type CardType = {
  item: EventExhibitionDetailResponse
}
function Card({ item }: CardType) {
  const jumpUrl = () => {
    if (item.webUrl) {
      return item.webUrl
    } else {
      return `/events/exhibition/${item.id}`
    }
  }
  return (
    <CardStyle>
      <div className="card">
        <a href={jumpUrl()} target='_blank' rel="noreferrer">
          <div
            className="cover"
            style={{
              backgroundImage: `url(${CoverImageObjectCloudResize(item.coverUrl, 500)})`,
            }}
          ></div>
        </a>
        <a href={jumpUrl()} target='_blank' rel="noreferrer">
          <div className="content">
            <div className="flex">
              <div className="upper" >
                <div className="title" title={item.name}>{item.name}</div>
                <div className="view">
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </CardStyle>
  )
}

export default Card