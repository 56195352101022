import { ForumOrderAttachmentListItem } from "api/forum/type"
import { RewardAnswererItemStyle } from "./style"
import { Image } from "antd"

const RewardAnswererItem = ({ detail }: { detail: ForumOrderAttachmentListItem }) => {
    return <RewardAnswererItemStyle>
        <div className="user-info">
            <div className="user-avatar" onClick={() => window.open(`/user/${detail.sellerUserInfo.id}`)} style={{ backgroundImage: `url(${detail.sellerUserInfo.avatarUrl})` }} />
            <div className="user-name" onClick={() => window.open(`/user/${detail.sellerUserInfo.id}`)}>{detail.sellerUserInfo.username}</div>
        </div>

        {
            detail.canView === 0 && <div className="tip">内容不可见</div>
        }

        {
            !!detail.content && <div className="content">
                答案描述：{detail.content}
            </div>
        }

        {
            detail.imageList.length > 0 && <div className="image">
                {
                    detail.imageList.map(item => {
                        return <Image src={item} key={item} />
                    })
                }
            </div>
        }
        {
            detail.fileList.length > 0 && <>
                <div className="file">
                    <div className="file-title">答案源文件列表：</div>
                    {
                        detail.fileList.map((item, index) => {
                            return <span className="file-item" onClick={() => window.open(item)} key={item}>
                                附件{index + 1}
                            </span>
                        })
                    }
                </div>
            </>
        }

        {
            !!detail.fileSupplement && <div className="fileSupplement">
                <div className="label">答案源文件链接：</div>
                <div className="value">{detail.fileSupplement}</div>
            </div>
        }
    </RewardAnswererItemStyle>
}

export default RewardAnswererItem