import styled from "styled-components";

export const UserCardStyle = styled.div`
display: flex;
align-items: center;
    .avatar{
    
        position: relative;
        display: flex;
        align-items: end;
        .crown{
            position: absolute;
            top: -8px;
            color: ${props => props.theme.colors.colorPrimary};
            display: none;
        }
        .role {
            position: absolute;
            /* bottom: 13px; */
            width: 20px;
            height: 20px;
            background-color: ${props => props.theme.colors.colorPrimary};
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 50%;
            right: 0;
            cursor: pointer;
            font-weight: bold;
            &.yellow {
              background-color: ${(props) => props.theme.colors.colorPrimary};
            }
            &.green {
              background-color: #52c41a;
            }
            &.purple {
              background-color: #722ed1;
            }
            &.blue {
              background-color: #1677ff;
            }
            &.hide {
              display: none;
            }
        }
        .verified{
            position: absolute;
            bottom: 8px;
            right: 10px;
            background-color: ${props => props.theme.colors.colorPrimary};
            color: ${props => props.theme.colors.colorTextLightSolid};
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid ${props => props.theme.colors.colorTextLightSolid};
            font-weight: ${props => props.theme.fontSizes.fontWeightHeading4};
        }
    }
    .wrap{
        margin-left: ${props => props.theme.space.padding}px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title{
            cursor: pointer;
            line-height: ${props => props.theme.fontSizes.lineHeightHeading5};
            span {
                cursor: pointer;
            }
        }
        .meta{
            color:${props => props.theme.colors.colorTextTertiary};
            font-size: ${props => props.theme.fontSizes.fontSize}px;
        }
        .btns{
            margin:${props => props.theme.space.marginXS}px 0 0 0;
            .btn{
                margin-right: ${props => props.theme.space.marginXS}px ;
                min-width: 68px;
                height: 28px;
                padding: 2px 8px;
            }
            .invite{
                border-color: ${(props) => props.theme.colors.colorPrimary};
                color:${(props) => props.theme.colors.colorPrimary};
            }
            .active {
                background-color: transparent;
                border-color: ${(props) => props.theme.colors.colorPrimary};
                color:${(props) => props.theme.colors.colorPrimary};
            }
        }
    }
`