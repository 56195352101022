import styled from "styled-components";

export const GoBackTitleStyle = styled.div`
    display: flex;
    align-items: center;
    gap:  ${(props) => props.theme.space.paddingSM}px;
    padding-bottom: ${(props) => props.theme.space.padding}px;
    cursor: pointer;
    .icon{
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
    }
    &:hover{
        color:${(props) => props.theme.colors.colorTextSecondary}
    }
`