import { Button, Form, Input, Modal, Radio, message } from "antd";
import { useEffect, useState } from "react";
import { SubMatchStyle } from "./style";
import { SingleSelect } from "../components/single-select";
import { SignupField } from "types/sub/application";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiGetSignUpField, apiPostSub } from "api/sub";
import { SubSecondSelect } from "../components/second-select";
import GoBackTitle from "ui-lib/go-back";
import { SubChildrenSelect } from "../components/children-select";
import { apiUserDetail } from "api/user/detail";
import { apiGetSignUpDetail, apiUpdateSignUp, postRegultionShareImage } from "api/contest";
import ApplySuccess from "./applysuccess";

export const GContestApplication = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [list, setList] = useState<SignupField[]>();
  const [searchParams] = useSearchParams();
  const signUpId = searchParams.get('signUpId');
  const contestCode = searchParams.get('contestCode');
  const code = searchParams.get('code');
  const invitationCode = searchParams.get('invitationCode');
  const navigate = useNavigate()
  const [schoolType, setSchoolType] = useState(1)
  const [shareImage, setShareImage] = useState("")
  const [style3DSpecialAward, setStyle3DSpecialAward] = useState(0)

  const [isApplicantSuccess, setIsApplicantSuccess] = useState(false)

  const validateMessages = {
    required: "${label} 不能为空",
    pattern: {
      mismatch: "${label} 格式不正确",
    },
  };

  const isLongarts = Number(id) === 36;

  const [userInfo, sertUserInfo] = useState({ username: "", mobile: "" });

  useEffect(() => {
    apiUserDetail().then((res) => {
      // setUserDetail(userDetail);
      sertUserInfo({
        username: res.username,
        mobile: res.mobile,
      });
    });

    if (invitationCode) {
      form.setFieldValue("invitationCode", invitationCode)
    }

    if (signUpId) {
      apiGetSignUpDetail({ id: Number(signUpId) }).then((res: any) => {
        form.setFieldsValue(res)
        setSchoolType(res.organizationType)
        setStyle3DSpecialAward((!!res.otherContact && res.otherContact === "1") ? 1 : 0)
      })
    }
  }, []);

  useEffect(() => {
    apiGetSignUpField(+id!).then((resField) => {
      setList(resField);
    });
  }, [id]);

  const onClickSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj: any = { contestId: +id!! };
        if (isLongarts) {
          obj.mediaCategory = [6];
        }
        Object.assign(obj, values);

        const newArr = JSON.parse(JSON.stringify(obj));
        if (newArr?.team) {
          if (newArr?.team.constructor === Object) {
            obj.team = newArr.team.label;
            if (newArr.team.category) {
              obj.category = newArr.team.category;
            }
          } else {
            obj.team = newArr.team;
          }
        }
        if (newArr?.mediaCategory) {
          if (newArr?.mediaCategory.constructor === Object) {
            obj.mediaCategory = newArr.mediaCategory.label;
            if (newArr.mediaCategory.category) {
              obj.category = newArr.mediaCategory.category;
            }
          } else {
            obj.mediaCategory = newArr.mediaCategory;
          }
        }

        if (newArr?.theme) {
          if (newArr?.theme.constructor === Object) {
            obj.theme = newArr.theme.label;
            if (newArr.theme.category) {
              obj.category = newArr.theme.category;
            }
            if (newArr.theme?.children) {
              obj.theme2 = newArr.theme?.children;
            }
          } else {
            obj.theme = newArr.theme;
          }
        }

        if (newArr.category) {
          if (newArr.category.length > 0) {
            obj.category = newArr.category;
          }
        }

        if (code === 'sims' && list && list?.length > 0) {
          const mediaCategory = list?.find(item => item.code === 'mediaCategory')
          if (mediaCategory?.options && mediaCategory?.options.length === 1) {
            obj.mediaCategory = [mediaCategory?.options[0].id]
          }
        }


        if (signUpId) {
          apiUpdateSignUp({
            id: Number(signUpId),
            ...Object.assign(obj)
          }).then(() => {
            message.success("修改成功！");
            //window.history.back();
            if (code === 'ggac6') {
              navigate(`/2024/regulation`, { replace: true })
            } else {
              navigate(`/contest/${contestCode}/detail`, { replace: true })
              window.scrollTo({ top: 0 })
            }

          });
        } else {
          apiPostSub(Object.assign(obj)).then(() => {
            message.success("报名成功！");
            window.scrollTo({ top: 0 })
            if (["ggac6", "ly3d", "xrtz"].includes(String(code))) {
              postRegultionShareImage(Number(id)).then(res => {
                setShareImage(res.shareImageUrl)
                setIsApplicantSuccess(true)
              })

            } else {
              if (window.history && window.history.length <= 2) {
                navigate("/")
              } else {
                window.history.back();
                window.scrollTo({
                  top: 0
                })
              }
            }
          });
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOrganizationChange = (e: any) => {
    setSchoolType(e.target.value)
  }
  const getComponent = (item: SignupField) => {
    if (item.code === "category") {
      return
    }

    if (
      item.code === "theme" ||
      item.code === "themeA" ||
      item.code === "team" ||
      item.code === "creativeLab" ||
      item.code === "style3DSpecialAward" ||
      (item.code === "mediaCategory" && !isLongarts)
    ) {
      if (item.hasCategory) {

        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SubSecondSelect
              data={item.options}
              multiple={!!item.isMulCheck}
            ></SubSecondSelect>
          </Form.Item>
        );
      } else if (item.hasSecondOption) {

        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SubChildrenSelect
              data={item.options}
              multiple={!!item.isMulCheck}
            ></SubChildrenSelect>
          </Form.Item>
        );
      } else {
        if (signUpId && item.code === "creativeLab") {
          return
        }

        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SingleSelect
              data={item.options}
              multiple={!!item.isMulCheck}
            ></SingleSelect>
          </Form.Item>
        );
      }
    } else if (item.code === "mediaCategory" && isLongarts) {
    } else {
      // const userInfo = JSON.parse(localStorage.getItem('user_info')!)
      if (item.code === "mobile") {
        form.setFieldValue(item.code, userInfo.mobile);
      }
      if (item.code === "nickname") {
        form.setFieldValue(item.code, userInfo.username);
      }
      if (item.code === "organizationType") {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <Radio.Group onChange={handleOrganizationChange}>
              <Radio value={1}>公司</Radio>
              <Radio value={2}>院校</Radio>
              <Radio value={3}>自由职业</Radio>
            </Radio.Group>
          </Form.Item>
        );
      }
      if (item.code === "organization" && schoolType !== 3) {
        return (
          <Form.Item
            key={item.code}
            label={schoolType === 1 ? "公司" : schoolType === 2 ? '院校' : ''}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
            required={true}
          >
            <Input
              placeholder=
              {`请填写您的${schoolType === 1 ? '公司' : schoolType === 2 ? '院校' : ''}`} max={200}
            ></Input>
          </Form.Item>
        );
      } else if (item.code === "organization" && schoolType === 3) {
        return <div></div>
      }
      return (
        <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <Input
            disabled={item.code === "mobile" || item.code === "nickname" || (item.code === 'invitationCode' && !!signUpId)}
            placeholder={`请填写您的${item.name}`} max={200}
          ></Input>
        </Form.Item>
      );
    }
  };

  const onCancel = () => {
    if (code === 'ggac6') {
      navigate("/2024/regulation", { replace: true })
    } else {
      navigate(`/contest/${code}`, { replace: true })
    }

  }

  return (
    <SubMatchStyle>
      <div className="sub-match-container">
        <div className="form-wrapper">
          <GoBackTitle
            onClick={() => {
              if (contestCode === 'ggac6') {
                window.location.href = `/2024/regulation/detail`;
              } else if (contestCode) {
                window.location.href = `/contest/${contestCode}/detail`;
              } else {
                window.history.back();
              }
            }}
          >
            返回赛事首页
          </GoBackTitle>
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true, organizationType: 1 }}
            autoComplete="off"
            requiredMark={true}
            className="form-area"
            form={form}
            style={{ width: "100%" }}
            validateMessages={validateMessages}
          >
            {
              (!['ly3d', 'xrtz', 'sims'].includes(String(code))) && <div className="form-section">
                <label className="title" title="参赛类型">参赛类型</label>
                {list &&
                  list
                    .filter((item) => item.fieldFlag === 2)
                    .map((item) => getComponent(item))}
              </div>
            }
            <div className="form-section">
              <label className="title" title="个人信息">个人信息</label>
              {list &&
                list
                  .filter((item) => item.fieldFlag === 1)
                  .map((item) => getComponent(item))}
            </div>

            {/* <Form.Item
                            label="描述"
                            name="desc"
                            rules={[{ required: true },{pattern: new RegExp('')}]}
                            >
                            <SubTextArea></SubTextArea>
                        </Form.Item> */}
          </Form>

          <Button type="primary" onClick={onClickSubmit} block>
            提交报名
          </Button>
          <Modal width={800} onCancel={onCancel} footer={<></>} maskClosable={false} destroyOnClose={true} centered={true} open={isApplicantSuccess}>
            <ApplySuccess shareImage={shareImage} />
          </Modal>
        </div>
      </div>
    </SubMatchStyle>
  );
};
