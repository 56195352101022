import styled from "styled-components";

export const CommentItemStyle = styled.div`
    width: 100%;
    display: flex;
    .avatar{
        cursor: pointer;
        pointer-events: all;
    }
    .commentBody {
        padding-left: ${(props) => props.theme.space.paddingXS}px;
        width: 100%;
        .userName {
            padding-top: ${(props) => props.theme.space.paddingXXS}px;
            cursor: pointer;
            pointer-events: all; 
            color: #202020;
            font-weight: bold;
            font-size: 14px;
        }
        .content {
            padding-top: 0;
            color: ${(props) => props.theme.colors.colorTextSecondary};
            margin:${props=>props.theme.space.marginXS}px 0;
            color: #202020;
            font-size: 12px;
            .content-reply{
                color: ${(props) => props.theme.colors.colorPrimary};
            }
            .text {
                word-break: break-all;
            }
        }
        .commentMeta{
            display: flex;
            /* justify-content: space-between; */
            align-items: center;
            gap: 15px;
            font-size: 12px;
            color: #8A8A8B;
            .commentDate{
                color: ${(props) => props.theme.colors.colorTextTertiary};
            }
            .active{
                color: ${(props) => props.theme.colors.colorPrimary} !important;
            }
            .favor-active{
                color: ${(props) => props.theme.colors.colorPrimary} !important;
            }
            .commentActions{
                cursor: pointer;
                display: flex;
                align-items: center;
                color:${(props) => props.theme.colors.colorTextTertiary};
                gap: 3px;
                .icon{
                  width: ${props => props.theme.fontSizes.fontSizeLG}px;
                  height: ${props => props.theme.fontSizes.fontSizeLG}px;
                }
            }
        }  
    }
  
`