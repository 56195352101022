export const notification = 
{ 
    type: {
        all: '全部',
        system: '系统',
        favour: '点赞',
        comment: '评论',
        subscribe: '关注',
        officialNotification: '官方公告',
        shop: '店铺',
        forum: 'TALK'
    },
    messageBox: '消息盒', 
}
