import { Button, Modal, Space,  Tooltip, message } from "antd";
import { FavoritesCardStyle } from "./style";
import { favoriteListItem } from "types/favorite";
import {
  RiDeleteBin2Line,
  RiEdit2Line,
  RiEyeLine,
  RiLockFill,
} from "react-icons/ri";
import { postDelteFavorite } from "api/favorite";
import { useNavigate } from "react-router";
import UiTag from "ui-lib/tag";
import { CoverImageObjectCloudResize } from "utils/functions/file";
import useWindowSize from "hooks/useWindowSize";

const FavoritesCard = ({
  list,
  edit,
  updateList,
  isPublic = false,
  toDetail
}: {
  list: favoriteListItem[];
  edit?: (detail: favoriteListItem) => void;
  updateList: () => void;
  isPublic?: boolean;
  toDetail?: (detail: favoriteListItem) => void
}) => {
  const editFavorites = (item: favoriteListItem) => {
    edit && edit(item);
  };
  const navigate = useNavigate();
  const [screenSize] = useWindowSize();
  const deleteFavorites = (item: favoriteListItem) => {
    Modal.confirm({
      title: "确定删除？",
      content: "删除该收藏夹后，收藏夹的内容将会随之删除，并且无法找回！",
      okText: "确定",
      cancelText: "取消",
      onCancel() { },
      centered: true,
      maskClosable: true,
      onOk() {
        postDelteFavorite(item.id).then(() => {
          message.success("删除成功");
          updateList();
        });
      },
    });
  };
  const viewFavorites = (detail: favoriteListItem) => {
    navigate(`/user-center/home/favorites/detail/${detail.id}?type=${detail.type}`);
  };

  const toLook = (detail: favoriteListItem) => {
    toDetail && toDetail(detail)
  }

  const tranlateType = (type: number) => {
    if (type === 1) {
      return "创作"
    } else if (type === 2) {
      return "图片"
    }else if (type === 3) {
      return "商品"
    }
    
  }
  const tranlateTypeColor = (type: number) => {
    if (type === 1) {
      return "green-1"
    } else if (type === 2) {
      return "blue-1"
    }else if (type === 3) {
      return "yellow-1"
    }
    return ""
  }
  return (
    <FavoritesCardStyle>
      <div className="favorite" style={{gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`}}>
        {list &&
          list.length > 0 &&
          list.map((item) => {
            return (
              <div className="favorite-card" onClick={() => toLook(item)} key={item.id}>
                <div className="cover">
                  {
                    !isPublic && <div className="center">
                      <Space>
                        {
                          <Tooltip title="编辑" className="tooltip">
                            <Button
                              size="large"
                              shape="circle"
                              style={{ paddingTop: 9 }}
                              onClick={(e) => { e.stopPropagation(); editFavorites(item) }}
                            >
                              <RiEdit2Line></RiEdit2Line>
                            </Button>
                          </Tooltip>
                        }
                        <Tooltip title="删除" className="tooltip">
                          <Button
                            size="large"
                            shape="circle"
                            style={{ paddingTop: 9 }}
                            onClick={(e) => { e.stopPropagation(); deleteFavorites && deleteFavorites(item) }}
                          >
                            <RiDeleteBin2Line></RiDeleteBin2Line>
                          </Button>
                        </Tooltip>
                        <Tooltip title="查看" className="tooltip">
                          <Button
                            onClick={() => viewFavorites(item)}
                            size="large"
                            shape="circle"
                            style={{ paddingTop: 9 }}
                          >
                            <RiEyeLine></RiEyeLine>
                          </Button>
                        </Tooltip>
                      </Space>
                    </div>
                  }

                  {item.coverList &&
                    item.coverList.length > 0 &&
                    item.coverList?.slice(0, 4).map((cover, index) => {
                      return (
                        <div
                          className="cover-item"
                          key={index}
                          style={{ backgroundImage: `url(${CoverImageObjectCloudResize(cover,500)})` }}
                        ></div>
                      );
                    })}
                </div>
                <div className="info">
                  <div className="name">
                    {item.name}

                    {item.isPrivate === 1 && <RiLockFill></RiLockFill>}
                  </div>

                  <div className="type">
                    <UiTag theme={tranlateTypeColor(item.type)}> {tranlateType(item.type)}</UiTag>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </FavoritesCardStyle>
  );
};

export default FavoritesCard;
