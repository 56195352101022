import { Modal, Form, Input, Button, message } from "antd"
import { WithdrawalSubmitStyle } from "./style"
import { getCaptchaType } from "types/common"
import { useState } from "react";
import { postWithdrawal } from "api/wallet";

const WithdrawalSubmit = (
    { isShow, detail, amount, updateImage, updateIsShow, withdrawalSuccess }:
        {
            isShow: boolean, detail?: getCaptchaType, amount: string,
            updateImage: () => void,
            updateIsShow: (value: boolean) => void,
            withdrawalSuccess: () => void
        }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const submit = () => {
        form.validateFields().then(res => {
            setLoading(true)
            const params = Object.assign(res, { amount: Number(amount) * 100, verUid: detail?.uid })
            postWithdrawal(params).then(() => {
                updateIsShow(false)
                message.success("提现成功")
                withdrawalSuccess()
            }).finally(() => {
                setLoading(false)
            })
        })
    }

    return <WithdrawalSubmitStyle>
        <Modal className="withdrawal-modal" onCancel={() => updateIsShow(false)} getContainer={false} footer={<></>} title="提现至支付宝" destroyOnClose={true} centered={true} open={isShow}>
            <Form labelCol={{ span: 5 }} size="large" className="submit-form" form={form}>
                <Form.Item name="identity" label="支付宝账号" rules={[
                    {
                        required: true,
                        message: '请输入您的支付宝账号',
                    }
                ]}>
                    <Input placeholder="请输入您的支付宝账号"></Input>
                </Form.Item>
                <Form.Item name="name" label="真实姓名" rules={[
                    {
                        required: true,
                        message: '请输入您的真实姓名',
                    }
                ]}>
                    <Input placeholder="请输入您的真实姓名"></Input>
                </Form.Item>
                <div className="flex">
                    <Form.Item labelCol={{ span: 8 }} name="verCode" label="图形验证码" rules={[
                        {
                            required: true,
                            message: '请输入图形验证码',
                        }
                    ]}>
                        <Input placeholder="请输入图形验证码"></Input>
                    </Form.Item>
                    <img src={detail?.base64} onClick={updateImage} alt="" />
                </div>
                <Form.Item>
                    <div className="btns">
                        <Button type="primary" onClick={submit} loading={loading}>确定</Button>
                        <Button ghost={true} type="primary" onClick={() => updateIsShow(false)}>取消</Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    </WithdrawalSubmitStyle>
}

export default WithdrawalSubmit