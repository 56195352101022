import styled from "styled-components";
import { dynamicColumns } from "utils/functions/common";

export const AlbumCardStyle = styled.div`
  
.ablum {
    width: 100%;
    display: grid;
    --auto-grid-min-size: 18rem;
    grid-template-columns: repeat(${dynamicColumns()}, 1fr);
    gap: 1rem;


    &-card {
        border: 1px solid ${(props) => props.theme.colors.colorBorder};
      &:hover{
        box-shadow:1px 0px 1px rgba(0, 0, 0, 0.07);
      }
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      overflow: hidden;
      padding: ${(props) => props.theme.space.paddingSM}px;
    }
    .cover{
       background-color:${(props) => props.theme.colors.colorBgLayout} ;
      width: 100%;
      aspect-ratio: 1 / 1;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      /* display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2px; */
      display: flex;
      flex-wrap: wrap;
      gap: 2px;
        &-item {
            /* width: 100%; */
            width: calc(50% - 2px);
            height: calc(50% - 2px);
        aspect-ratio: 1 / 1;
        background-size: cover;
        background-position: center center;
        border-radius: ${(props) => props.theme.border.borderRadiusXS}px;
        box-sizing: border-box;
        }

            &:hover {
                .center{
                    display: block;
                }
            }
            .center {
                display: none;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
                align-items: center;
                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
    }
    .info {
        padding-top: ${props => props.theme.space.padding}px;
        padding-bottom:  ${props => props.theme.space.paddingXS}px;
        .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    
}
`