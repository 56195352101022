import styled from "styled-components";

export const AuthorizedProductStyle = styled.div`
    .title{
          font-size: ${props => props.theme.fontSizes.fontSizeHeading4};
          font-weight:${props => props.theme.fontSizes.fontWeightHeading4};
          margin-bottom: ${props => props.theme.space.marginXS}px;
        }
    .list-wrap {
        position: relative;
    }
    .list{
        position: relative;
        .list-item{
            display: flex;
            align-items: center;
            margin-bottom: ${props => props.theme.space.marginXS}px;
            cursor: pointer;
            .thumbnail{
                width: 45px;
                height: 45px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius:${props => props.theme.border.borderRadius}px;
            }
            .wrap{
                width: calc( 100% - 45px );
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: ${props => props.theme.space.padding}px;
                padding-right: ${props => props.theme.space.paddingXXS}px;
                gap: 5px;
                .product-name{
                   overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .product-price{
                    font-weight: ${props => props.theme.fontSizes.fontWeightHeading4};
                    text-align: right;
                    white-space: nowrap;
                }
            }
        }
    }
    .left-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -29px;
        cursor: pointer;
        padding: ${props => props.theme.space.paddingXS}px;
    }
    .right-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -29px;
        cursor: pointer;
        padding: ${props => props.theme.space.paddingXS}px;
    }
`