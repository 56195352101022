import styled from "styled-components";

export const ProductSellerProfileStyle = styled.div`
    display: flex;
    .avatar{
        position: relative;
        .role {
            position: absolute;
            bottom: 4px;
            width: 20px;
            height: 20px;
            background-color: ${props => props.theme.colors.colorPrimary};
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 50%;
            right: 0;
            cursor: pointer;
            font-weight: bold;
            &.yellow {
              background-color: ${(props) => props.theme.colors.colorPrimary};
            }
            &.green {
              background-color: #52c41a;
            }
            &.purple {
              background-color: #722ed1;
            }
            &.blue {
              background-color: #1677ff;
            }
            &.hide {
              display: none;
            }
        }
    }
   
    .wrap_{
        display: flex;
        flex-direction: column;
        gap:4px;
        padding-left:${(props) => props.theme.space.paddingSM}px;
        .meta{
            color: ${(props) =>props.theme.colors.colorTextSecondary};
        }
 
        .btns{
           
            .btn{
                margin-right: ${props => props.theme.space.marginXS}px ;
                min-width: 68px;
                height: 28px;
                padding: 2px 8px;
            }
            .im{
                border-color: ${(props) => props.theme.colors.colorPrimary};
                color:${(props) => props.theme.colors.colorPrimary};
            }
            .active {
                background-color: transparent;
                border-color: ${(props) => props.theme.colors.colorPrimary};
                color:${(props) => props.theme.colors.colorPrimary};
            }
        }
    }
`