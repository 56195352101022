import { ContestSignupListItem, postRegultionShareImage } from "api/contest";
import { ContestCardStyle } from "./style";
import { Modal, Space } from "antd";
import { useState } from "react";
import ApplySuccess from "views/grand-contest-form/apply/applysuccess";

function ContestCard({ item }: { item: ContestSignupListItem }) {

  const [shareImage, setShareImage] = useState("")

  const [isApplicantSuccess, setIsApplicantSuccess] = useState(false)

  const toMatchApplicant = () => {
    window.open(
      `/g-contest/apply/${item.contestId}?signUpId=${item.id}&contestCode=${item.contestCode}&code=${item.contestCode}`
    );
  }

  const toMatchDetail = () => {
    if (item.contestCode === 'ggac6') {
      window.open(`/2024/regulation/detail`);
      return
    }

    window.open(`/contest/${item.contestCode}/detail`);

  }

  const share = () => {
    if (["ggac6", "ly3d", "xrtz"].includes(String(item.contestCode))) {
      postRegultionShareImage(Number(item.contestId)).then(res => {
        setShareImage(res.shareImageUrl)
        setIsApplicantSuccess(true)
      })

    }
  }

  return (
    <ContestCardStyle>
      <div>
        <div className="title overflow-hidden" title={item.contestName}>
          {item.contestName}
        </div>

        <div className="wrap">
          {item?.subContestSignupList?.map((item, index) => (
            <div key={index} style={{ paddingLeft: 8 }}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="footer">

        <Space>
          <div
            className="btn"
            onClick={() => {
              toMatchDetail()

            }}
          >
            查看
          </div>
          {item.updateStatus === 1 && (
            <div
              className="btn"
              onClick={() => { toMatchApplicant() }}
            >
              修改
            </div>
          )}

          {
            ["ggac6", "ly3d", "xrtz"].includes(String(item.contestCode)) && <div
              className="btn"
              onClick={share}
            >
              分享
            </div>
          }
        </Space>
      </div>
      <Modal width={800} onCancel={() => setIsApplicantSuccess(false)} footer={<></>} maskClosable={false} destroyOnClose={true} centered={true} open={isApplicantSuccess}>
        <ApplySuccess shareImage={shareImage} />
      </Modal>
    </ContestCardStyle>
  );
}

export default ContestCard;
