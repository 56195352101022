import styled from "styled-components";

export const BusinessContactStyle = styled.div`

    
`

export const FormStyle = styled.div`
padding-top: ${(props) => props.theme.space.padding}px;
padding-right: ${(props) => props.theme.space.paddingLG}px;
.tip {
        text-align: center;
        font-weight: bold;
        margin-bottom: ${(props) => props.theme.space.marginLG}px;
        font-size: 15px;
}
.btn {
        text-align: center;
        padding-top: ${(props) => props.theme.space.paddingXXS}px;
        padding-left: 38px;
 }
`