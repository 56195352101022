import { GlobalSearchPostResponse } from "types/common"
import { WorkDraftItem, WorkListItem } from "types/work"
import { CardTitleStyle } from "./style"
import { FavoriteDataListItem } from "types/favorite"
import { WorkContestListItem } from "types/contest"
import { Tooltip } from "antd"
import { WorkLikeListsItem } from "types/user/work"

function CardTitleRender({ item, dataType = 'work' }: { item: (GlobalSearchPostResponse | WorkListItem | WorkDraftItem | FavoriteDataListItem | WorkContestListItem | WorkLikeListsItem), dataType?: string }) {

    const renderContenstIcon = () => {
        if (item.contest) {
            if (item.contest?.type === 2) {
                return <Tooltip title={["creativeLab", "ly3d", "xrtz"].includes(item.contest.code) ? '第六届GGAC大赛' : item.contest.name}>
                    <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/contest-gold.svg" width={16} alt="" />
                </Tooltip>
            } else {
                return <Tooltip title={item.contest.name} >
                    <img src="https://cdn-prd.ggac.com/ggac/assets/image/icon/contest-silver.svg" width={16} alt="" />
                </Tooltip>
            }
        } else {
            return <></>
        }
    }

    const renderUrl = () => {
        if (dataType === 'draft') {
            return `/work/preview/${item.id}`
        }

        if (dataType === 'noLink') {
            return ''
        }

        if (dataType === 'work') {
            return `/work/detail/${item.id}`
        }
        if (dataType === 'work_mix') {
            return `/work/detail/${(item as FavoriteDataListItem).dataId}`
        }
        if (dataType === 'article') {
            return `/article/detail/${(item as GlobalSearchPostResponse).dataId}`
        }
        if (dataType === 'media') {
            return `/work/detail/${(item as FavoriteDataListItem).id}`
        }

    }

    return (
        <CardTitleStyle>
            <>
                {renderContenstIcon()}
                {
                    renderUrl() && <a className="overflow-hidden" href={renderUrl()} target="_blank" title={item.title} rel="noreferrer">{item.title}</a>
                }
                {
                    !renderUrl() && <span className="overflow-hidden" title={item.title} rel="noreferrer">{item.title}</span>
                }
            </>
        </CardTitleStyle>
    )
}

export default CardTitleRender