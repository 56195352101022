import { useEffect, useState } from "react"
import { AuthorizedProductStyle } from "./style"
import { WorkAbleProductListItem } from "types/work"
import { getWorkAuthorizedProductList } from "api/work"
import { useParams } from "react-router-dom"
import { useKeenSlider } from "keen-slider/react"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"

function AuthorizedProducts() {
  const [list, setList] = useState<WorkAbleProductListItem[]>([])
  const [extend, setExtend] = useState(false)
  const { id } = useParams<any>();

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 1,
      spacing: 0
    },
  });

  useEffect(() => {
    instanceRef && instanceRef.current?.update();
  }, [list])

  const getList = async () => {
    const res = await getWorkAuthorizedProductList({ id: Number(id) })
    setList(res)
  }

  useEffect(() => {
    getList()
  }, [extend])


  const move = (type: string) => {
    if (type === "prev") {
      instanceRef.current?.prev();
    }
    if (type === "next") {
      instanceRef.current?.next();
    }
  };

  const toDetail = (id: number) => {
    window.open(`/product/detail/${id}`)
  }



  return (
    <AuthorizedProductStyle className="related-products-style">
      {list && list?.length > 0 && <div className="title">作品授权</div>}
      <div className="list-wrap">
        <div ref={sliderRef} className="keen-slider list">
          {
            list && list.length > 0 && list?.map((item, index) => {
              return <div className="list-item keen-slider__slide" onClick={() => toDetail(item.id)} key={index} rel="noreferrer" >
                <div className="thumbnail" style={{ backgroundImage: `url(${item.coverUrl})` }}></div>
                <div className="wrap">
                  <div className="product-name" title={item.name}>{item.name}</div>
                  {item.isPrivateInquiry == 1 && <div className="product-price" >私聊询价</div>}
                  {item.isPrivateInquiry == 0 && <div className="product-price" >￥ {(item.rmbPrice / 100).toFixed(2)}</div>}
                </div>
              </div>
            })
          }
        </div>
        {
          list.length > 1 && <>
            <div className="left-arrow" onClick={() => move("prev")}>
              <RiArrowLeftSLine size={20} />
            </div>
            <div className="right-arrow" onClick={() => move("next")}>
              <RiArrowRightSLine size={20} />
            </div>
          </>
        }
      </div>
    </AuthorizedProductStyle>
  )
}

export default AuthorizedProducts