import React, { useState } from 'react'
import { SearchInputStyle } from './style'
import { Input } from 'antd'
import { useNavigate } from 'react-router-dom'

function SearchInput() {
    const [keyword,setKeyword] = useState<string | undefined>()
    const navigate = useNavigate()

  return (
    <SearchInputStyle className='SearchInputStyle'>
        <Input placeholder="请输入搜索关键词" onChange={e=>setKeyword(e.target.value)} allowClear 
        onPressEnter={()=>
                {
                    if(!keyword) return
                    navigate('/forum/question/search?keyword='+keyword)
                }
           
        }
        />
    </SearchInputStyle>
  )
}

export default SearchInput