import styled from "styled-components";

export const CommonTabStyle = styled.div`
border-bottom: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
width: 100%;
.common-tabs{
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0 20px;
    
    .common-tabs-item{
        color: #8F8F8F;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        padding:  ${(props) => props.theme.space.paddingXS + 3}px ${(props) => props.theme.space.padding}px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        position: relative;
        .dot{
            position: absolute;
            right: 11px;
            top: 11px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: red;
        }
        &:hover {
            color: ${(props) => props.theme.colors.colorText} !important;
        }
        &.active{
            border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
            color:${(props) => props.theme.colors.colorText};
        }
    }
}

`