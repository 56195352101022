import styled from "styled-components";

export const UpdatePasswordStyle = styled.div`
.form{
  padding-top:  ${(props) => props.theme.space.padding}px ;
}
    .code{
        display: flex;
    }
    .btn {
      text-align: center;
     
      button{
        width: 100%;
      }
    }
`