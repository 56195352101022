import styled from "styled-components";

export const AutoPublishStyle = styled.div`
    .cover{
        margin-top: ${(props) => props.theme.space.margin}px;
        border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
`