import { GlobalSearchPostResponse } from "types/common"
import { translateCardCategoryText } from "utils/functions/common"
import UniCardLayout from "../layout";
import HotIcons from "../common/hot-icons";
import MediaIconsES from "../common/media-icons/es";
import PrizeIcons from "../common/prize-icons";
import CardTitleRender from "../common/card-title/work";
import { FavoriteDataListItem } from "types/favorite";
import { WorkLikeListsItem } from "types/user/work";
import PublicCardStatus from "./PublicCardStatus";
//
function PublicESCard({ item }: { item: GlobalSearchPostResponse | FavoriteDataListItem | WorkLikeListsItem}) {

  const renderSubtitle = ()=>{
    if(item.dataTable ==='work'){
      return <span> {item.dictMap?.mediaCategory?item.dictMap?.mediaCategory+' | ':''} {translateCardCategoryText(item.categoryList)}</span>
    }
    if(item.dataTable ==='article'){
      return <span> {translateCardCategoryText(item.categoryList)}</span>
    }
    return <></>
  }

  const UiObject ={
    coverUrl:item.coverUrl,
    title:<CardTitleRender item={item} dataType={(item.expired && item.expired === 1) ? 'noLink' : item.dataTable ==='work'?'work_mix':'article'}/>,
    subtitle:renderSubtitle(),
    view:item.viewCount,
    hot:item.hot,
    userName:item.userInfo?.username,
    avatarUrl:item.userInfo?.avatarUrl,
    icons:HotIcons({item}),
    workLink:(item.expired && item.expired === 1) ? "" : item.dataTable==='work'?`/work/detail/${item.dataId}`:`/article/detail/${item.dataId}`,
    userLink:`/user/${item.userId}`,
    rightTopNode:PrizeIcons({item}),
    rightBottomNode:MediaIconsES({item}),
    leftBottomNode:<PublicCardStatus item={item}></PublicCardStatus>,
    isPublish: true,
    expired: item.expired
  }
  
  return UniCardLayout({item:UiObject});
}

export default PublicESCard