
import { createSlice } from '@reduxjs/toolkit'


import { UserBaseInfo } from 'types/user/index'
const userSlice = createSlice({
    name: 'user',
    initialState: {
        userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}') as unknown as UserBaseInfo,
        userNoticeCount: 0
    },
    reducers: {
        changeUerNoticeCount(state, action) {
            return {
                ...state,
                userNoticeCount: action.payload
            }
        },
    },
})

export const { changeUerNoticeCount } = userSlice.actions

export default userSlice.reducer
