import { ApiGetExpressInfo } from "api/shop"
import { ExpressInfoStyle } from "./style"
import { useEffect, useState } from "react"
import { ExpressInfoResponse } from "types/product"
import { Timeline } from 'antd';

function ExpressInfo({orderNumber}: {orderNumber: string}) {
    const [expressInfo,setExpressInfo] =useState<ExpressInfoResponse>()
    const [showMore,setShowMore] = useState<boolean>(true)

    const getExpressInfo = () => {
        ApiGetExpressInfo({orderNumber}).then((res) => {

           setExpressInfo(res)
        })
    }

    useEffect(() => {
        getExpressInfo()
    },[])


  return (
    <ExpressInfoStyle>
       <div className="wrap_">
        <div className="status">物流状态: <span className="label">{expressInfo?.statusName}</span> </div>
        <Timeline
    items={expressInfo?.logisticsList?.filter((item,index) => {
        if(showMore){
            return index <2
        }else{
            return true
        }  
    }).map((item) => ({
        children:<><span className="time">{item.time}</span><br/><span className="context">{item.context}</span></>
    }))}
  />
            <div className="more" onClick={() => {setShowMore(!showMore)}}>{showMore ? "查看更多" : "收起"}</div>
       </div>
      
    </ExpressInfoStyle>
  )
}

export default ExpressInfo