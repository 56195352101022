import { InteractionStyle } from "./style";
import {
  RiStarFill,
  RiShareForward2Fill,
  RiHeart3Fill,
} from "react-icons/ri";
import { Avatar, Tooltip, message } from "antd";
import AddFavorite from "components/favorite/add-favorite";
import { useCallback, useEffect, useState } from "react";
import { getUserLikeList, postLikeWork } from "api/common/favour";
import { useParams, useNavigate } from "react-router-dom";
import { InteractionTypeWrap } from "./interaction-type";
import { workUserLikeItem } from "types/common/favour";
import { UserBaseInfo } from "types/user";
import GlobalShare from "components/share";
import LoadingIcon from "ui-lib/loading";
import { getWorkShareImage } from "api/work";
import { loginPathWithRedirect } from "utils/functions/user";

function Interaction(props: InteractionTypeWrap) {
  const { defaultInteraction, detail } = props;
  const [showLike, setShowLike] = useState(false);
  const [showShare, setShowShare] = useState(false);
  let { id } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [favourList, setFavourList] = useState<workUserLikeItem[]>([]);
  const [totalFavour, setTotalFavour] = useState(0);

  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!);

  const navigate = useNavigate();

  const [shareImageInfo, setShareImageInfo] = useState({
    qrCode: "",
    shareImageUrl: "",
  });

  const getFavourListMethod = useCallback(async () => {
    setLoading(true);
    let res = await getUserLikeList({
      dataId: detail.twinId,
      dataTable: "work",
      pageNumber: pageNumber,
      pageSize: pageNumber ===1? 26: 27,
    });
    setFavourList([...favourList, ...res.pageData]);
    setTotalFavour(res.totalSize);
    setLoading(false);
  }, [id, pageNumber]);

  useEffect(() => {
    getFavourListMethod();
  }, [getFavourListMethod]);

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const onClickFavour = async (flag: 0 | 1) => {
    if (!userInfo?.id) {
      navigate(loginPathWithRedirect());
      return;
    }
    let params = {
      dataId: Number(id),
      dataTable: "work",
      flag: flag,
    };
    await postLikeWork(params);
    if (flag === 0) {
      defaultInteraction.actionCount.likeCount =
        defaultInteraction.actionCount.likeCount - 1;
    } else {
      defaultInteraction.actionCount.likeCount =
        defaultInteraction.actionCount.likeCount + 1;
    }
    message.success("操作成功！");
    getFavourListMethod();
    props.update &&
      props.update(
        Object.assign(props.defaultInteraction, {
          isLike: flag,
          likeCount: defaultInteraction.actionCount,
        })
      );
  };

  const showFavorite = () => {
    if (!userInfo?.id) {
      navigate(loginPathWithRedirect());
      return;
    }
    setShowLike(true);
    document.body.style.overflowY = 'hidden'
  };

  const toDetail = (id: number) => {
    window.open(`/user/${id}/works`);
  };

  const closePopup = () => {
    setShowLike(false);
    props.updateFavoriteStatus && props.updateFavoriteStatus();
    let node = document.getElementById('scroll-right')
    document.body.style.overflowY = 'auto'
    if(node) {
      node.style.height = '100vh'
    }
  };

  const openShare = () => {
    props.updateLoading(true);
    getWorkShareImage(Number(id))
      .then((res) => {
        setShowShare(true);
        setShareImageInfo(res);
      })
      .finally(() => {
        props.updateLoading(false);
      });
  };

  return (
    <InteractionStyle>


{favourList && favourList.length > 0 && (
        <div className="avatarList">
          {favourList.map((item, index) => {
            return (
              <div className="avatar-item" key={index}>
                <Tooltip title={item.username}>
                  <Avatar
                    src={item.avatarUrl}
                    onClick={() => toDetail(item.id)}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            );
          })}

          {totalFavour - favourList.length > 0 && (
            <Tooltip
              title={`还有更多${
                totalFavour - favourList.length
              }位G粉点赞，点击展开`}
            >
              <div className="more" onClick={() => loadMore()}>
                {/* {prettifyNumber(totalFavour - favourList.length)}+ */}
                <span style={{marginTop: '-10px'}}>...</span>
              </div>
            </Tooltip>
          )}
        </div>
      )}
      <div className="divideText">
        - {defaultInteraction.actionCount.likeCount}位G粉为该作品点赞 -
      </div>
      {showLike && (
        <AddFavorite
          type="work"
          FavoriteTypeDisabled={true}
          onClose={() => closePopup()}
        ></AddFavorite>
      )}
      <div className="wrapper">
        {!defaultInteraction.isLike && (
          <div className="icon-warp">
            <div
              className="actionIcon"
              title={String(defaultInteraction.actionCount.likeCount)}
              onClick={() => onClickFavour(1)}
            >
              <RiHeart3Fill className="icon" />
            </div>

            <span className="text">
              {String(defaultInteraction.actionCount.likeCount)}
            </span>
          </div>
        )}

        {!!defaultInteraction.isLike && (
          <div className="icon-warp">
            <div
              className="actionIcon active"
              title={String(defaultInteraction.actionCount.likeCount)}
              onClick={() => onClickFavour(0)}
            >
              <RiHeart3Fill className="icon" />
            </div>
            <span className="text">
              {String(defaultInteraction.actionCount.likeCount)}
            </span>
          </div>
        )}

        {!defaultInteraction.isFavorite && (
          <div className="icon-warp">
            <div
              className="actionIcon"
              title={String(defaultInteraction.actionCount.favoriteCount)}
              onClick={() => showFavorite()}
            >
              <RiStarFill className="icon" />
            </div>
            <span className="text">
              {String(defaultInteraction.actionCount.favoriteCount)}
            </span>
          </div>
        )}
        {!!defaultInteraction.isFavorite && (
          <div className="icon-warp">
            <div
              className="actionIcon active"
              title={String(defaultInteraction.actionCount.favoriteCount)}
              onClick={() => showFavorite()}
            >
              <RiStarFill className="icon" />
            </div>
            <span className="text">
              {String(defaultInteraction.actionCount.favoriteCount)}
            </span>
          </div>
        )}
        <div className="icon-warp">
          <div className="actionIcon" onClick={openShare}>
            <RiShareForward2Fill className="icon" />
          </div>
        </div>
      </div>
      
      
      {loading && <LoadingIcon />}
      <GlobalShare
        info={{
          title: detail?.title,
          cover_image: detail?.coverUrl,
          desc: detail?.description || detail?.title,
        }}
        shareImageInfo={shareImageInfo}
        shareType="work"
        isShow={showShare}
        onClose={() => {
          setShowShare(false);
        }}
      ></GlobalShare>
    </InteractionStyle>
  );
}

export default Interaction;
