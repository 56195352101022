import { ReactNode } from "react";
import { UniCardLayoutStyle } from "./style";
import { ImageObjectCloudResize } from "utils/functions/file";
import { useLocation } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from "antd";


export type ProductUiObject = {
  coverUrl: string;
  title: string;
  subtitle: string;
  productLink?: string;
  centerNode?: ReactNode;
  leftBottomNode?: ReactNode;
  rightBottomNode?: ReactNode;
  rightTopNode?: ReactNode;
  realPrice?: number;
  date?: string;
  id: number,
  isSoldOut?: number
  bottomLeftNode?: ReactNode;
  bottomRightNode?: ReactNode;
  hasOuterUrl?: number;
  ourterUrl?: string;
  expired?: number
  simple?: boolean
};

function ProductCardLayout({ item }: { item: ProductUiObject }) {
  const location = useLocation()
  const [modal, contextHolder] = Modal.useModal();
  const channelCode = new URLSearchParams(useLocation().search).get("channelCode")
  const toDetail = () => {
    if(item.expired && item.expired === 1) {
      return
    }
    if (item.hasOuterUrl === 1 && location.pathname === '/mall/market' && item.ourterUrl) {
      const urlArr = item.ourterUrl.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/)
      const url = urlArr ? urlArr[0] : ''
      modal.confirm({
        title: '非站内购买',
        icon: <ExclamationCircleOutlined />,
        content: `您将前往 ${url} 进行购买，购买后将由该网站运营方进行相关商品的交付。`,
        okText: '立即前往',
        cancelText: '取消',
        onOk() {
          window.open(item.ourterUrl)
        }
      });

    } else {
      if (channelCode) {
        window.open(`/product/detail/${item.id}?channelCode=${channelCode}`)
      } else {
        window.open(`/product/detail/${item.id}`)
      }

    }
  }
  return (
    <UniCardLayoutStyle>
      {contextHolder}
      <div className="card-container" onClick={toDetail}>
        <div
          className="up-part cover"
          style={{
            backgroundImage: `url(${ImageObjectCloudResize(
              item.coverUrl,
              500
            )})`,
          }}
        >
          <div className={item.simple?`subtitle-re overflow-hidden`:`subtitle`} title={item.simple?item.subtitle:''}>{item.subtitle}</div>
          <div className="left-top"></div>
          <div className="left-bottom">{item.leftBottomNode}</div>
          <div className="right-top">{item.rightTopNode}</div>
          <div className="right-bottom">{item.rightBottomNode}</div>
          <div className="center">{item.centerNode}</div>
        </div>
        {
          item.productLink && <a href={item.productLink} target="_blank" className="click-a-tag" rel="noreferrer"></a>
        }

      {!item.simple && <div className="bottom-part">
          <div className="row">
            <div className="title overflow-hidden" onClick={toDetail} title={item.title}>{item.title}</div>

          </div>
          <div className="bottom">
            <div className="left">
              {item.bottomLeftNode}
            </div>
            <div className="right">
              {item.bottomRightNode}
            </div>
          </div>
        </div>}  
      </div>
    </UniCardLayoutStyle>
  );
}

export default ProductCardLayout;
