import { CardStyle } from "./style";
import { Avatar } from "antd";
import { RiEyeLine } from "react-icons/ri";
import { RecommendArticleListItem } from "types/article";
import { prettifyNumber } from "utils/functions/common";
import { ImageObjectCloudResize } from "utils/functions/file";

function ArticleCard(props: { list: RecommendArticleListItem[] }) {

  return (
    <CardStyle>
      {props.list.map((item, index) => {
        return (
          <div
            className="card"
            key={index}
          >
            <a href={`/article/detail/${item.id}`} target="_blank" rel="noreferrer">
              <div
                className="cover"
                style={{
                  backgroundImage: `url(${ImageObjectCloudResize(
                    item.coverUrl,
                    500
                  )})`,
                }}
              ></div>

              <div className="content">
                <div className="flex">
                  <div className="upper">
                   
                    <div className="title" title={item.title}>{item.title}</div>
                   
                    <div className="view" style={{ display: "none" }}>
                      <RiEyeLine className="icon"></RiEyeLine>
                      <span>{prettifyNumber(item.viewCount)}</span>
                    </div>
                  </div>

                  <div
                    className="bottom"
                  >
                    <a href={`/user/${item?.userId}`} target="_blank" rel="noreferrer">
                      <Avatar
                        className="avatar"
                        size="small"
                        src={item?.userInfo?.avatarUrl}
                      />
                      <div className="user-name">{item?.userInfo?.username}</div>
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      })}
    </CardStyle>
  );
}

export default ArticleCard;
