import { useEffect, useState } from "react";
import { NfAlbumListStyle } from "./style";
import { NftListResponse } from "types/nft-album";
import { apiGetMyNftList } from "api/nft-album";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { queryUrl } from "utils/functions/common";
import { NoData } from "components/no-data";
import UISegmentedControl from "ui-lib/segmented-control";

const NftAlbumList = () => {
  const [albumList, setAlbumList] = useState<NftListResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const pageNumber = Number(
    new URLSearchParams(useLocation().search).get("pageNumber") || 1
  );
  const [total, setTotal] = useState(0);
  const pageSize = 30;
  const navigate = useNavigate();

  const changePage = (e: number) => {
    const url = queryUrl({ pageNumber: e });
    navigate(`?${url}`);
  };

  const toDetail = (detail: NftListResponse) => {
    window.open(`/album/detail/${detail.id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    apiGetMyNftList({ pageNumber, pageSize })
      .then((res) => {
        setTotal(res.totalSize);
        setAlbumList(res.pageData);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pageNumber]);

  const location = useLocation();
  const tabOption = [
    {
      label: "图鉴收藏",
      value: "nft-album",
    },
    {
      label: "我发布的",
      value: "tj-publish",
    },
    {
      label: "我卖出的",
      value: "tj-sold",
    },
  ];
  const changeTab = (e: any) => {
    navigate("/user-center/" + e);
  };

  const getSecondPathSeg = () => {
    const regex = /\/user-center\/([^?/]+)/;
    const matches = location.pathname.match(regex);
    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      return '';
    }
  };
  console.log(location.pathname, "00099990---0009900");
  return (
    <NfAlbumListStyle>
      <UISegmentedControl
        onValueChange={changeTab}
        options={tabOption}
        value={getSecondPathSeg()}
      ></UISegmentedControl>
      <Spin spinning={isLoading}>
        <div className="card">
          {albumList.map((item) => {
            return (
              <div
                className="card-item"
                key={item.id}
                onClick={() => toDetail(item)}
              >
                <div
                  className="cover"
                  style={{ backgroundImage: `url(${item.imageMyNftList})` }}
                />
                <div className="bottom">
                  <div className="name" title={item.name}>{item.name}</div>
                  {item.state === 2 && (
                    <div className="nft-count">
                      {`${item.seriesIndex}/${
                        item.seriesTotalCount === 0
                          ? "∞"
                          : item.seriesTotalCount
                      }`}
                    </div>
                  )}
                  {item.state === 1 && <div className="nft-count">生成中</div>}
                </div>
              </div>
            );
          })}
        </div>
        {albumList.length === 0 && <NoData />}
      </Spin>
      <Pagination
        style={{ textAlign: "center" }}
        hideOnSinglePage={true}
        onChange={changePage}
        pageSize={pageSize}
        showSizeChanger={false}
        total={total}
        current={pageNumber}
      />
    </NfAlbumListStyle>
  );
};

export default NftAlbumList;
