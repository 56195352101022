import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const MallInvitationStyle = styled.div`
    @font-face {
      font-family: 'AlibabaPuHuiTiR';
      src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
  }
  padding: ${(props) => props.theme.space.paddingLG}px;
  padding-top: 12px;
  max-width: 2680px;
  margin: 0 auto;
  .top {
    text-align: center;
    margin: ${props => props.theme.space.margin * 2}px 0;
    .title {
      font-size: 40px;
	    font-family: 'AlibabaPuHuiTiR';
	    color: #2b2e36;
      font-weight: bold;
    }
    .word {
      color: #8c8c8c;
    }
  }

  .sort{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${props => props.theme.space.margin}px;
    .select {
      width: 200px;
    }
    .ant-radio-group {
      display: flex;
      align-items: center;
    }
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }
    .search-wrap {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }
  .first-select {
      .row-2 {
        border-bottom: none !important;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .select {
      width: 100%;
      position: relative;
      .consult {
        position: absolute;
        right: 0;
        bottom: 16px;
      }
      .row-2 {
        justify-content: flex-start;
        gap: 54px;
        .tag {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
    .row-2 {
    margin: ${(props) => props.theme.space.margin}px auto;
    display: flex;
    justify-content: center;
    gap: 12px;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: ${props => props.theme.space.padding}px;
    position: relative;
    .tag {
      padding: 6px 0;
      cursor: pointer;
      text-align: center;
      color: #8f8f8f;
      width: max-content;
      &.active {
        color: #2b2e36;
        font-weight: bold;
      }
      &:hover {
        color: #2b2e36;
        font-weight: bold;
      }
      border-radius: 26px;
      text-align: center;
      width: auto;
    }
  }
  .data-list {
    display: grid;
    grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
    gap: ${(props) => props.theme.space.padding}px;
    margin-top: 20px;
  }
  .tabs{
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0 20px;

    .tab{
        color: #8F8F8F;
        font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        padding:  ${(props) => props.theme.space.paddingXXS}px ${(props) => props.theme.space.padding}px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding-bottom: 17px;
        &:hover {
        color: ${(props) => props.theme.colors.colorText} !important;
        border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
      }
        &.active{
            border-bottom: 2px solid ${(props) => props.theme.colors.colorPrimary};
            color:${(props) => props.theme.colors.colorText};
        }
    }
}
.no-data{
  display: flex;
  justify-content: center;
}
`