import styled from "styled-components";


export  const  GrandContestSelectStyle = styled.div`
    .title{
        &::before{
            display: inline-block;
            margin-inline-end: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
        }
    }
    .ant-form-item {
        margin-bottom: 15px;
    }
    .ant-tabs-tab {
        .ant-tabs-tab-remove:active {
            color: rgba(0, 0, 0, 0.88) !important;
        }
    }
`