import styled from "styled-components";

export const BizFormStyle = styled.div`
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }

    width: 100vw;
    height: 100vh;
    background: url("https://cdn-prd.ggac.com/ggac/common/biz-bg.png") center center no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1300px;
    .title {
        font-size: 28px;
        color: #13192d;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    .ant-form-item-label {
        width: 80px;
        text-align: left;
    }
    .right {
        .ant-form-item-label {
            text-align: right !important;
        }
    }
    .title2 {
        margin: 10px 0 30px;
        text-align: center;
    }
    .flex {
        display: flex;
        justify-content: center;
        .ant-form-item-control {
            width: 203px !important;
        }
        input {
            width: 203px;
        }
    }
    .form {
        width: 48vw;
        min-width: 700px;
        background-color: #fff;
        padding: 30px 0;
        box-shadow: 0px 1px 22px 2px 
		rgba(184, 184, 184, 0.3);
	    border-radius: 20px;
        margin: 0 auto;
        max-height: 820px;
    }
    .word1 {
        position: absolute;
        top: 1.5vw;
        left: 2vw;
        div {
            font-family: AlibabaPuHuiTiR;
            font-weight: bold;
            color: #ffc712;
            &:first-child {
	            font-size: 4.6vw;
	            letter-spacing: 0.3vw;
            }
            &:nth-child(2) {
                font-size: 3.3vw;
                letter-spacing: 0.2vw;
                margin-top: -1.5vw;
            }
        }
        .sub {
            padding-left: 2vw;
            margin-top: 1vw;
            div {
                font-size: 2vw;
                margin-top: 0;
                line-height: 2.6vw;
                transform: skew(-10deg);
                color: #B8AA78;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 0.5vw;
                    height: 0.5vw;
                    border-radius: 50%;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -1.5vw;
                    z-index: 1;
                    background-color: #B8AA78;
                }
            }
        }
    }

    .word2 {
        position: absolute;
        bottom: 70px;
        right: 85px;
        width: 2.2vw;
    }

    input,textarea {
        width: 480px;
        resize: none;
    }
    .ant-row {
        justify-content: center;
    }
    .ant-form-item-control {
        width: 480px;
        flex: none !important;
    }
    .ant-form-item-label {
        label {
            color: #94969f;
            padding-left: 11px;
        }
        .ant-form-item-required {
            padding: 0;
        }
    }
    .btn {
        width: 180px;
	    height: 42px;
        margin: 0 auto;
        button {
            border-radius: 21px;
        }
    }

    .ant-radio-group {
        display: flex;
        gap: 10px;
        .ant-radio-input {
            width: 13px;
        }
        .radio-item {
            width: 115px;
	        height: 42px;
	        border-radius: 6px;
	        border: solid 1px #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
            .ant-radio-wrapper {
                margin-right: 0 !important;
            }
            span {
                padding-right: 0 !important;
            }
        }
    }
    .tip {
        text-align: center;
        margin-top: 10px;
        color: #94969f;
    }
`