import styled from "styled-components";

export const BadgeListItemStyle = styled.div`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap:${(props) => props.theme.space.padding}px;
    .list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0px solid #ffdfbb;
        background-color: ${(props) => props.theme.colors.colorBgContainer};
        padding: ${(props) => props.theme.space.padding}px
          ${(props) => props.theme.space.padding}px;
        cursor: pointer;
        position: relative;
        border: 1px solid ${(props) => props.theme.colors.colorBorder};
        border-radius: ${(props) => props.theme.border.borderRadius}px;
        &:hover{
          box-shadow: ${(props) => props.theme.shadow.boxShadowThin};
        
          background-color: ${(props) => props.theme.colors.colorBgContainer};
        }
        .level_count {
          position: absolute;
          width: 32px;
          aspect-ratio: 1/1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          right: 10px;
          border-radius: 50% 50%;
          font-size: 12px;
          color: ${(props) => props.theme.colors.colorTextTertiary};
          .a{
            border-bottom: 1px solid ${(props) => props.theme.colors.colorTextTertiary};
          }
        }
        .badge-image {
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 1/0.6;
          width: 100%;
          img {
            object-fit: contain;
            width: 50%;
          }
        }
        .badge-name {
          padding-top: ${(props) => props.theme.space.paddingXS}px;
        }

        .badge-count {
          width: 15px;
          height: 15px;
          background-color: #c2c4cc;
          color: #fff;
          border-radius: 50%;
          margin: 10px auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
`
      