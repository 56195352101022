import { getStorage, isNavLang } from "utils/functions/common";
import axios from "axios";
import QS from "qs";

const language = getStorage("lang") || isNavLang();

function addForEachToNodeList() {
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
}
addForEachToNodeList();
//使用方法 标签加自定义属性 _tranL="t_lang"

// 非中文语言时翻译
function transLanguage() {
    if (language == "zh_CN") {
        return;
    } else {
        setTimeout(() => {
            let tans_dom = document.querySelectorAll('[lang="t_lang"]');
            console.log(tans_dom)
            console.log("tans_dom", tans_dom);
            tans_dom.forEach((e, i) => {
                startTrans(e);
            });
        }, 1500);
    }
}

// 调用翻译接口赋值
function startTrans(dom: any) {
    let isHtml = false;

    if (dom != undefined && dom.innerHTML != undefined && (dom.innerHTML.includes("<img") || dom.innerHTML.includes("<img>") || dom.innerHTML.includes("<p>") || dom.innerHTML.includes("<video>") || dom.innerHTML.includes("&nbsp;"))) {
        isHtml = true;
    }

    let transApi = isHtml ? "https://trans.ggac.com/api/trans/html" : "https://trans.ggac.com/api/trans";

    let content;

    if (isHtml) {
        content = dom.innerHTML;
    } else {
        content = dom.innerText;
    }
    content = content.replace(/&quot;/g, '')
    content = content.replace(/&#39;/g, '')
    axios
        .post(
            transApi,
            QS.stringify({
                q: content,
                from: "auto",
                to: "en",
            }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                },
            }
        )
        .then((res) => {
            let transT = res.data.trans_result[0].dst
            transT = transT.replace(/&quot;/g, '')
            transT = transT.replace(/&#39;/g, '')
            dom.innerText = transT;
        })
        .catch((err) => {
            console.log(err);
        });
}

export default transLanguage;
