import { useEffect, useState } from 'react'
import { NftAlbumStyle } from './style'
import { useParams } from 'react-router-dom'
import { apiGetAlbumList } from 'api/nft-album'
import { NftSalesPlanListResponse } from 'types/nft-album'
import { Pagination, Spin } from 'antd'
import { NoData } from 'components/no-data'
import AlbumCard from 'views/mall/components/album-card'
import useWindowSize from 'hooks/useWindowSize'

const NftAlbum = () => {
  const { id } = useParams<any>()

  const [list, setList] = useState<NftSalesPlanListResponse[]>([])

  const [loading, setLoading] = useState(false)

  const pageSize = 30
  const [screenSize] = useWindowSize()

  const [totalSize, setTotalSize] = useState(0)

  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    setLoading(true)
    apiGetAlbumList({ publisherUserId: Number(id), pageNumber, pageSize })
      .then((res) => {
        setList(res.pageData)
        setTotalSize(res.totalSize)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  const changePage = (e: number) => {
    setPageNumber(e)
  }

  return (
    <NftAlbumStyle>
      <Spin spinning={loading}>
        {list.length === 0 && (
          <div style={{ padding: '100px 0' }}>
            <NoData />
          </div>
        )}
        <div
          className="data-list"
          style={{
            gridTemplateColumns: `repeat(${screenSize.dynamicColumns}, 1fr)`,
          }}
        >
          {list &&
            list.map((item, index) => {
              return <AlbumCard item={item} isShowUser={false} key={index} />
            })}
        </div>
      </Spin>

      <Pagination
        pageSize={pageSize}
        style={{ textAlign: 'center', marginTop: 28 }}
        hideOnSinglePage={true}
        total={totalSize}
        showSizeChanger={false}
        current={pageNumber}
        onChange={changePage}
      />
    </NftAlbumStyle>
  )
}

export default NftAlbum
