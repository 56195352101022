import styled from "styled-components";

export const ApplySuccessStyle = styled.div`
    .applicant-sucess {
        display: flex;
        height: 596px;
        width: 800px;
        gap: 30px;
        text-align: center;

        .left {
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            align-items: center;
            justify-content: center;
            .share-image {
                width: 100%;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
        }


        .title {
            font-weight: bold;
            line-height: 30px;
            font-size: 18px;
        }

        .sub-title {
            line-height: 30px;
            margin: ${props => props.theme.space.margin}px 0;
            padding: 0 50px;
            span {
                cursor: pointer;
                text-decoration: underline;
                text-underline-offset: 4px;
                font-weight: bold;
                &:hover {
                    color: ${props => props.theme.colors.colorPrimary};
                    text-decoration-color: ${props => props.theme.colors.colorPrimary};
                }
            }
            
        }

        .tip {
            margin: ${props => props.theme.space.marginLG}px 0;
        }

        .img-list {
            display: flex;
            gap: 20px;
            img {
                width: 150px;
            }
        }
    }
`