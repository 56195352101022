import { WorkInfoStyle } from "./style";
import {  Tooltip } from "antd";
import {  RiEyeLine, RiFireLine, RiInformationLine } from "react-icons/ri";
import { WorkDetailType } from "types/work";


function WorkInfo(props: WorkDetailType
) {  
  return (
    <WorkInfoStyle>
      <div className="date">作品更新于 : {props?.lastSubmitTime}
        <Tooltip title={'作品创建于 : ' + props?.submitTime}>
        <RiInformationLine style={{ marginLeft: '10px' }} size={16}></RiInformationLine>
        </Tooltip>
      </div>
      <div className="meta">
          <div className="icon">
            <RiEyeLine size={"18px"} /> <span>{props?.actionCount?.viewCount!}</span>
          </div>
          <div className="icon">
            <RiFireLine size={"18px"} /> <span>{props?.actionCount?.hot!}</span>
          </div>
      </div>
      <div className="text">
      </div>

    </WorkInfoStyle>
  );
}

export default WorkInfo;
