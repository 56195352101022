import { BadgeItem } from "types/user/badge";
import { BadgeListItemStyle } from "./style";
import { Tooltip } from "antd";

const BadgeListItems = (items: BadgeItem[], isShowDescription: boolean = true) => {
  const determineImageUrl = (item: BadgeItem) => {
    if (item.enabledIconList.length === 0) {
      return "";
      //此处应该给一个缺省图
    }
    if (item.hasLevel) {
      if (item.enabledIconList.length === 1) {
        return item.enabledIconList[0];
      }
      if (item.enabledIconList.length > 1 && item.isActivated > 0) {
        return item.enabledIconList[item.level - 1];
      } else {
        return item.enabledIconList[0];
      }
    } else {
      return item.enabledIconList[0];
    }
  };
  return (
    <BadgeListItemStyle>
      {items.map((item, index) => {
        return (
          <Tooltip title={isShowDescription ? item.description : ''}>
            <div className="list-item" key={index}>
              {item.hasLevel > 0 && (
                <div className="level_count">
                  <div className="a">Lv.</div>
                  <div className="b">{item.level}/{item.levelCount}</div>
                </div>
              )}
              <div className="badge-image">
                <img
                  src={determineImageUrl(item)}
                  alt=""
                  style={{
                    filter: item.isActivated > 0 ? "" : "grayscale(100%)",
                  }}
                />
              </div>
              <div className="badge-name">{item.name}</div>
              {
                item.count > 1 && <div className="badge-count">{item.count}</div>
              }
            </div>
          </Tooltip>
        );
      })}

    </BadgeListItemStyle>
  );
};

export default BadgeListItems;
