import styled from "styled-components";

export const XnfwWorkRankStyle = styled.div`
    overflow: hidden;
    /* @media screen and (max-width: 1440px) {
        .work-item {
          max-width: 200px !important;
        }
    } */
    @font-face {
        font-family: 'nzgrKangxi';
        src: url('https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/nzgrKangxi.ttf');
    }
    .cover {
        img {
            width: 100%;
        }
    }
    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 200px;
        padding: 100px 0;
        &-item {
            font-family: 'nzgrKangxi';
            width: 410px;
            height: 117px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-tab.png") center center no-repeat;
            background-size: cover;
            font-size: 46px;
	        letter-spacing: 5px;
	        color: #6b1e03;
            cursor: pointer;
        }
    }
    .active,.tab-item:hover {
        color: #fff !important;
        background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-tab-active.png") center center no-repeat !important;
        background-size: cover !important;
    }
    .content {
        background: url("https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/xnwf-rank-work-bg.png") center center no-repeat;
        background-size: cover;
        margin-top: -6px;
        .inner {
            max-width: 1920px;
            margin: -7px auto 0;
            position: relative;
            
            .title {
                text-align: center;
                margin-bottom: 100px;
                img {
                    height: 129px;
                }
            }
            .dw {
                position: absolute;
            }
            .dw1 {
                left: 0;
                top: -100px;
            }
            .dw2 {
                right: -220px;
                top: -320px;
                z-index: -1;
            }
            .dw3{
                right: 0;
                top: -60px;
                width: 450px;
            }
            .dw4 {
                width: 300px;
                top: -330px;
                left: -260px;
                z-index: -1;
            }
            .dw5 {
                width: 300px;
                top: -330px;
                right: -260px;
                z-index: -1;
            }
            .dw6 {
                width: 300px;
                top: -330px;
                left: -260px;
                z-index: -1;
            }
            .dw7 {
                width: 400px;
                top: -250px;
                left: 0;
            }
            .work-wrap {
                position: relative;
            }

            .work1 {
                margin-bottom: 20px !important;
            }
            .tip {
                margin-bottom: 100px;
                text-align: center;
                font-size: 16px;
                span {
                    color: #a93c1c;
                    cursor: pointer;
                }
            }
            .work {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                max-width: 1400px;
                min-width: 1100px;
                margin: 0 auto 100px;
                position: relative;
                z-index: 5;
                /* display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-gap: 0; */
                &-item {
                    width: 14.52vw;
                    min-width: 200px;
                    aspect-ratio: 280 / 202;
                    background-color: red;
                    background-size: cover;
                    cursor: pointer;
                    /* background-position: center; */
                }
            }
            .bottom {
                width: 100%;
                position: relative;
                margin-top: 300px;
                position: relative;
                .code {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -240px;
                    display: flex;
                    justify-content: center;
                    &-item {
                        width: 324px;
                        height: 349px;
                        position: relative;
                        background: url('https://cdn.ggac.com/img/summary-2023-rank-pc-part-code-bg.png')
                          center center no-repeat;
                        background-size: 100% 100%;
                        margin: 0 75px;
                        text-align: center;
                        .code-tip {
                          font-size: 17px;
                          color: #fee29b;
                          margin-top: 5px;
                        }
                        img {
                          margin-top: 16px;
                          width: 260px;
                        }
                        .code-word {
                          font-size: 20px;
                          color: #a93c1c;
                          text-align: center;
                          position: absolute;
                          bottom: -37px;
                          width: 100%;
                        }
                    }
                }
            }
        }
    }
`