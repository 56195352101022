import styled from "styled-components";

export const AddressListStyle = styled.div`
.table-wrap{
    padding: ${props => props.theme.space.paddingLG}px;
    .button{
        margin-bottom: ${props => props.theme.space.marginXXS}px;
        /* width: 120px; */
        display: flex;
        align-items: center;
    }
    .tag {
        background-color:${props => props.theme.colors.colorPrimary};
        color: #fff;
        width: 70px;
        text-align: center;
        margin: 0 auto;
    }
    .btns{
        display: flex;
        justify-content: center;
        gap: ${props => props.theme.space.marginXXS}px;
    }

    .table {
        margin-top: ${props => props.theme.space.paddingLG}px;
        border: 1px solid ${props => props.theme.colors.colorBorder};
        border-radius: ${props => props.theme.border.borderRadius}px;
        overflow: hidden;
    }
    .receiver_phone{
        display: flex;
        margin: 0 auto;
        gap: 5px;
        align-items: center;
        .icon{
            padding-bottom: 5px;
        }
    }
}
`