import { orderDetailResponse, SellerOrderListResponse } from "types/product"
import { UpadtePriceStyle } from "./style"
import { formatMoney } from "views/user-shop/productManage/create/fn"
import { Button, InputNumber, message, Radio } from "antd"
import { useEffect, useState } from "react"
import { updateOrderAmount } from "api/shop"

const UpadtePrice = ({ detail, refreshOrder, isYuegao = false }: { detail: SellerOrderListResponse | orderDetailResponse, refreshOrder: () => void, isYuegao?: boolean }) => {
    const [price, setPrice] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [serviceChargePayer, setServiceChargePayer] = useState(1)
    const onChange = (e: number) => {
        setPrice(e)
    }

    useEffect(() => {
        setPrice(Number(formatMoney(detail.amount)))
    }, [detail.amount])

    useEffect(()=>{
        setServiceChargePayer(detail.serviceChargePayer)
    },[detail])

    const submit = () => {
        setIsLoading(true)
        const params: any = { orderNumber: detail.orderNumber, amount: price }
        if (isYuegao) {
            params.serviceChargePayer = serviceChargePayer
        }
        updateOrderAmount(params).then(() => {
            message.success("修改成功")
            refreshOrder()
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return <UpadtePriceStyle>
        <div>当前价格：{formatMoney(detail.amount)}</div>
        <div style={{ margin: "15px 0" }}>改后价格：<InputNumber style={{ margin: "0 8px" }} min={0.01} max={100000} value={price} onChange={(e) => onChange(Number(e))} />元</div>

        {
            isYuegao && <div style={{ margin: "15px 0" }}>服务费支付方：
                <Radio.Group onChange={(e)=>setServiceChargePayer(e.target.value)} value={serviceChargePayer}>
                    <Radio value={1}>卖家</Radio>
                    <Radio value={2}>买家</Radio>
                </Radio.Group>
            </div>
        }
        <div className="button-wrap"><Button onClick={submit} size="middle" type="primary" loading={isLoading}>提交改价</Button></div>
    </UpadtePriceStyle>
}

export default UpadtePrice