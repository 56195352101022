import styled from "styled-components";

export const UserCardStyle = styled.div`
  /* z-index: 100; */
  display: grid;
  max-width: ${(props) => props.theme.space.maxContainer}px;
  width: 93vw;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: ${(props) => props.theme.space.padding}px;
  row-gap: ${(props) => props.theme.space.padding}px;
  margin: 0 auto;
  .card {
    background-color: ${(props) => props.theme.colors.colorTextLightSolid};
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    overflow: hidden;
    padding-bottom: ${(props) => props.theme.space.paddingMD}px;
    border: 1px solid ${(props) => props.theme.colors.colorBorder};
    .thumbnails {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 4px;
      position: relative;
      .img-item {
        width: 100%;
        aspect-ratio: 290/214;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #000;
      }
      .avatar {
        position: absolute;
        left: 50%;
        bottom: -30px;
        transform: translate(-50%);
        border: 2px solid #fff;
        border-radius: 50%;
        .avatar-item {
          box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px 0px,
            rgba(0, 0, 0, 0.16) 0px 0px 1px 0px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          background-color: ${(props) =>
            props.theme.colors.colorTextLightSolid};
          max-width: 83px;
          min-width: 70px;
          aspect-ratio: 1/1;
          border-radius: 50%;
          position: relative;
          .role {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            right: -1px;
            bottom: -1px;
            cursor: pointer;
            font-weight: bolder;
            &.yellow {
              background-color: ${(props) => props.theme.colors.colorPrimary};
            }
            &.green {
              background-color: #52c41a;
            }
            &.purple {
              background-color: #722ed1;
            }
            &.blue {
              background-color: #1677ff;
            }
            &.hide {
              display: none;
            }
          }
        }
      }
    }

    .user-name {
      padding: ${(props) => props.theme.space.paddingLG * 1.5}px ${(props) => props.theme.space.padding}px ${(props) => props.theme.space.padding}px;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-align: center;
      font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
      color: ${(props) => props.theme.colors.colorText};
      &:hover{
        color: ${(props) => props.theme.colors.colorLinkHover};
      }
    }
    .sub-title {
      display: flex;
      justify-content: center;
      color: ${(props) => props.theme.colors.colorTextTertiary};
      line-height: 30px;
      height: 30px;
      width: 100%;
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${(props) => props.theme.space.marginXS}px 0;
      aspect-ratio: 260/30;
      cursor: pointer;
      width: 100%;
      border-radius: ${(props) => props.theme.border.borderRadius}px;
    }
    .isFollowed {
      background-color: ${(props) =>
        props.theme.colors.colorPrimaryActive} !important;
      color: #fff !important;
    }
    &:hover {
      .info-grid {
        display: none;
      }
      .btn {
        display: block;
      }
    }
    .btn {
      display: none;
      width: 90%;
      height: 40px;
      margin: 0 auto;
      transition: all 2s;
    }
    .info-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      color: ${(props) => props.theme.colors.colorTextSecondary};
      height: 40px;
      align-items: center;
      .item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        /* border-left: 1px solid ${(props) =>
          props.theme.colors.colorBlack2Ggac}; */
        &:first-child {
          border-left: none;
        }
        .num {
          font-size: ${(props) => props.theme.fontSizes.fontSize}px;
        }
        .name {
          font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
          color: ${(props) => props.theme.colors.colorTextTertiary};
          svg {
            color: rgb(133, 133, 133);
          }
        }
      }
    }
  }
`;
