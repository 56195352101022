import styled from 'styled-components'

export const LayoutStyle = styled.div`
   .is-home {
      height: 100vh;
      overflow-y: scroll;
   }
   .add-block {
      &::before {
         content: "";
         display: block;
         width: 100%;
         height: 50px;
         background-color: #232424 !important;
         /* background: url("https://cdn-prd.ggac.com/ggac/assets/image/home/menu-new-bg.png") center center no-repeat;
         background-size: cover; */
      }
   }
   .version-tip{
         position: fixed;
         z-index: 999;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         background-color: #fff;
         padding: 20px;
         border-radius: 16px;
         .close{
            position: absolute;
            right: 10px;
            top:10px;
            cursor: pointer;
            font-size: 20px;
         }
      }
   .layout-content {
      min-height: calc(100vh - 50px);
      /* overflow-x: hidden; */
      
      &>div{
         /* padding-top: 50px; */
      }
      &>div.no-need-padding{
         padding-top: 0;
      }
      &::-webkit-scrollbar {
         display: none; /* Chrome Safari */
      }
      
   }
`