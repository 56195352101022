import { Form, Input, Cascader,  FormInstance, message, Button } from 'antd';
import { useState, useCallback, useRef, useEffect } from 'react'
import { apiGetCountryList, } from 'api/common'
import { countryItem, countryRequest } from 'types/common'
import { MaterialContentStyle } from './style';
import { apiCreateAuthWriter } from 'api/user/auth-writer';
import { apiUserDetail } from 'api/user/detail';
import GoBackTitle from 'ui-lib/go-back';

export const MaterialContent = ({ type = 1 }: { type?: number }) => {
    const [countryId, setCountryId] = useState(0)
    const [provinceId, setProvinceId] = useState<number | null>(0)
    const [cityId, setCityId] = useState<number | null>(0)
    const [defaultCountry, setdefaultCountry] = useState<number[]>([])
    const [countryList, setCountryList] = useState<countryItem[]>([])
    const formRef = useRef<FormInstance>(null);
    const [form] = Form.useForm<any>();
    const [address, setAddress] = useState('')

    const back = () => {
        window.history.back()
    }

    const getCountryList = useCallback(async (params: countryRequest) => {
        const result = await apiGetCountryList(params)
        result.map((item => {
            item.isLeaf = false
            return item
        }))
        setCountryList(result)
    }, [])
    const onChangeCountry = (selectedOptions: countryItem[] | any[]) => {
        console.log(selectedOptions[1])
        if (selectedOptions[0]) {
            setCountryId(selectedOptions[0])
            setProvinceId(null)
            setCityId(null)
        }
        if (selectedOptions[1]) {
            setProvinceId(selectedOptions[1])
            setCityId(null)
        }
        if (selectedOptions[2]) {
            setCityId(selectedOptions[2])
        }
    }
    // 提交
    const save = () => {
        form.validateFields().then(async res => {
            const obj = Object.assign(res, { countryId, provinceId, cityId, type })
            apiCreateAuthWriter({ ...obj }).then(res => {
                message.success("保存成功")
                window.history.back()
            })
        }).catch(err => {
            console.error(err)
        })
    }
    const loadData = async (selectedOptions: countryItem[] | any[]) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const result = await apiGetCountryList({ pid: String(targetOption?.id), type: String(targetOption?.type + 1) })
        result.map(item => {
            if (targetOption?.id === 1 && item.type === 2) {
                item.isLeaf = false
            }
            return item
        })
        targetOption.loading = false;
        targetOption.children = result
        setCountryList([...countryList]);
    }

    // 获取用户信息
    const getUserDetail = useCallback(async () => {
        const result = await apiUserDetail()
        const { userInfo } = result
        setdefaultCountry([userInfo.countryId, userInfo.provinceId, userInfo.cityId])
        const { regionDictMap } = userInfo
        if (!regionDictMap.countryName && !regionDictMap.provinceName && !regionDictMap.cityName) {
            setAddress('')
        } else {
            setAddress(`${regionDictMap.countryName} ${regionDictMap.provinceName ? "/" + regionDictMap.provinceName : ""} ${regionDictMap.cityName ? "/" + regionDictMap.cityName : ''}`)
        }
        form.setFieldsValue({
            realName: userInfo.realName,
            penName: userInfo.penName,
            hometown: userInfo.hometown,
            company: userInfo.company,
            university: userInfo.university,
            qq: userInfo.qq,
            wx: userInfo.wx,
            weibo: userInfo.weibo,
            artstation: userInfo.artstation,
            bilibili: userInfo.bilibili,
            xiaohongshu: userInfo.xiaohongshu,
            projectExperience: userInfo.artstation,
            award: userInfo.award,
            addressArr: []
        })
    }, [form])
    useEffect(() => {
        form.resetFields()
        getCountryList({
            pid: '0',
            type: '1'
        })
        getUserDetail()
    }, [getCountryList, getUserDetail, form])

    const limitMessage = (rule: any, value: any, callback: any) => {
        if (!form.getFieldValue('university') && !form.getFieldValue('company')) {
            callback('公司信息和院校信息需填写一个')
        } else {
            callback()
        }
    }

    return <MaterialContentStyle>
        <div className='auth-writer-container'>

            <div onClick={() => back()}> <GoBackTitle >返回</GoBackTitle></div>
            <div className='form-wrapper'>
                <Form
                    labelCol={{ span: 5 }}
                    autoComplete="off"
                    ref={formRef}
                    form={form}
                    labelAlign="left"
                >
                    <div className='parter'>
                        <div className='left'>
                            <Form.Item
                                label="真实姓名"
                                name="realName"
                                rules={[{ required: true, message: '请输入真实姓名' }]}
                            >
                                <Input placeholder="请输入真实姓名" allowClear />
                            </Form.Item>
                            {
                                type === 1 && <Form.Item
                                    label="常用笔名"
                                    name="penName"
                                    rules={[{ required: true, message: '请输入常用笔名' }]}

                                >
                                    <Input placeholder="请输入常用笔名" allowClear />
                                </Form.Item>
                            }

                            <Form.Item
                                label="所在地"
                                name="addressArr"
                                // rules={[{ required: true, message: '请选择您的所在地' }]}
                                rules={[{ required: true, message: '请选择您的所在地' }]}
                            >
                                <Cascader
                                    placeholder={address ? address : "请选择您的所在地"}
                                    options={countryList}
                                    loadData={loadData}
                                    onChange={onChangeCountry}
                                    defaultValue={defaultCountry}
                                    fieldNames={{
                                        label: 'name',
                                        value: 'id',
                                    }}
                                    changeOnSelect
                                />
                            </Form.Item>

                            <Form.Item
                                label="公司信息"
                                name="company"
                                rules={[{ validator: (rules, value, callback) => limitMessage(rules, value, callback) }]}
                            >
                                <Input placeholder="请输入公司信息" allowClear />
                            </Form.Item>

                            <Form.Item
                                label="院校信息"
                                name="university"
                                rules={[{ validator: (rules, value, callback) => limitMessage(rules, value, callback) }]}

                            >
                                <Input placeholder="请输入院校信息" allowClear />
                            </Form.Item>

                            <Form.Item
                                label="参与项目"
                                name="projectExperience"

                            >
                                <Input placeholder="请输入参与项目" allowClear />
                            </Form.Item>

                            {
                                type === 2 && <Form.Item
                                    label="所获奖项"
                                    name="award"

                                >
                                    <Input placeholder="请输入所获奖项" allowClear />
                                </Form.Item>
                            }
                        </div>
                        <div className='right'>

                            <Form.Item
                                label="QQ"
                                name="qq"
                            >
                                <Input placeholder="请输入您的QQ" allowClear />
                            </Form.Item>
                            <Form.Item
                                label="微信"
                                name="wx"
                            >
                                <Input placeholder="请输入您的微信" allowClear />
                            </Form.Item>
                            <Form.Item
                                label="新浪微博"
                                name="weibo"
                            >
                                <Input placeholder="请输入您的新浪微博个人主页链接" allowClear />
                            </Form.Item>
                            <Form.Item
                                label="Artstation"
                                name="artstation"
                            >
                                <Input placeholder="请输入您的Artstation个人主页链接" allowClear />
                            </Form.Item>
                            <Form.Item
                                label="Bilibili"
                                name="bilibili"
                            >
                                <Input placeholder="请输入您的Bilibili个人主页链接" allowClear />
                            </Form.Item>
                            <Form.Item
                                label="小红书"
                                name="xiaohongshu"
                            >
                                <Input placeholder="请输入您的小红书个人主页链接" allowClear />
                            </Form.Item>
                            <Button type='primary' className="btn" onClick={save}>提交</Button>
                        </div>
                    </div>
                </Form>

            </div>

        </div >

    </MaterialContentStyle >
}

export default MaterialContent

