import styled from "styled-components";

export const SubTypeListStyle = styled.div`
  
    .type{
        display: flex;
        padding: ${props => props.theme.space.paddingSM}px ${props => props.theme.space.paddingXS}px;
       
        &-first {
            width:180px;
            overflow-y: auto;
            overflow-x: hidden;
            border-right: 1px solid ${props => props.theme.colors.colorBorder};
            padding:0 0 0 ${props => props.theme.space.paddingXS-3}px;
            //border-radius:  ${props => props.theme.border.borderRadius}px;
            &::-webkit-scrollbar-track {
            	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	            background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
            	background-color: #F5F5F5;
            }
            &::-webkit-scrollbar {
            	width: 6px;
	            background-color: transparent
            }
            &-item {
                width: 150px;
                padding: ${props => props.theme.space.paddingXS}px 0;
                padding-left: ${props => props.theme.space.padding}px;
                border-radius: 5px;
                cursor: pointer;
              
            }
            .active {
                background-color: ${props => props.theme.colors.colorPrimary};
            }
        }
        &-right {
            padding: ${props => props.theme.space.paddingLG}px;
            padding-top: 0;
            width: 260px;
            .top {
                .top-type {
                    border-bottom: 1px solid ${props => props.theme.colors.colorBorder};
                    padding-bottom: ${props => props.theme.space.paddingSM}px;
                   
                }
                margin-bottom: ${props => props.theme.space.padding}px;
            }
        }
        &-gg{
            flex:1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            /* margin-top:${props => props.theme.space.padding * 3 + 8}px; */
            .gg-item{
                width: 150px;
                height: 100px;
                cursor: pointer;
                margin-bottom: ${props => props.theme.space.margin}px;
                border-radius: ${props => props.theme.border.borderRadius}px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        .sub-type-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .sub-type {
            height: 100%;
            width: 300px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            &-item {
                padding: ${props => props.theme.space.paddingXS}px 0;
                cursor: pointer;
                color: ${props => props.theme.colors.colorLinkDefault};
                &:hover {
                    color: ${props => props.theme.colors.colorLinkHover};
                }
            }
        }
        
    }
`