import Layout from "views/layout";
import { RouteObject } from "react-router-dom";
import { DiscoverList } from "views/explore";
import { DiscoverListMysql } from "views/explore/mysql_index";
import { DiscoverListMysqlTest } from "views/explore/mysql-test";


export const discoverRoutes:RouteObject[] = [
    {
        path: "/",
        element: <Layout></Layout>,
        children:[{
            path: "explore-re",
            element:<DiscoverList></DiscoverList>,
        },{
            path: "explore",
            element:<DiscoverListMysql></DiscoverListMysql>,
        },
        {
            path: "explore-test",
            element:<DiscoverListMysqlTest />,
        }]
    }
]