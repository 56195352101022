import styled from "styled-components";

export const SubUploadImageListStyle = styled.div`
.drop-wrap{
    margin-bottom: ${props=> props.theme.space.marginXS};
    align-items: center;
    .ant-upload-wrapper{
        width: 150px;
        height: 150px;
        .ant-upload{
            width: 150px;
            height: 150px;
            margin-bottom: 0 !important;
        }
    }
}
.drop{
    .card-wrapper {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${props => props.theme.colors.colorPrimary};
        border-radius: ${props => props.theme.border.borderRadius}px;
        flex-direction: column;
        position: relative;
        .image{
            position: relative;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center center;
            position: relative;
            border-radius: ${props => props.theme.border.borderRadius}px;
        }
        .operation-container{
            display: flex;
            position: absolute;
            right: 0;
            top: 0;
            .operation-item{
                background-color: rgba(0, 0, 0, 0.3);
                width: 22px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
            }
        }
        .desc{
            padding: 0 ${props => props.theme.space.paddingXXS}px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 40px;
            position: absolute;
            bottom: 0;
            line-height: 40px;
            color: #fff;
            cursor: pointer;
            text-align: center;
            background: rgba(0,0,0,.3);
            border-bottom-left-radius: ${props => props.theme.border.borderRadius}px;
            border-bottom-right-radius: ${props => props.theme.border.borderRadius}px;
        }
    }
    

}
`