import { ArticleDetailResponse } from 'types/article';
import { ArticleInteractionStyle } from './style'
import { Avatar, Badge, Spin, message } from 'antd';
import { RiAlarmWarningLine, RiHeart3Fill, RiHeart3Line,  RiStarFill, RiStarLine } from 'react-icons/ri';
import AddFavorite from 'components/favorite/add-favorite';
import { useEffect, useState } from 'react';
import { workUserLikeItem } from 'types/common/favour';
import { UserBaseInfo } from 'types/user';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserLikeList, postLikeWork } from 'api/common/favour';
import { ReportModal } from 'components/report';
import GlobalShare from 'components/share';
import { loginPathWithRedirect } from 'utils/functions/user';


type Props = {
  detail: ArticleDetailResponse,
  onDetailChange: (detail: ArticleDetailResponse) => void,
  shouldGetDetail: () => void
}

function ArticleIntercation({ detail, onDetailChange, shouldGetDetail }: Props) {
  const [favourList, setFavourList] = useState<workUserLikeItem[]>();
  const [showFavorite, setShowFavorite] = useState(false);
  const navigate = useNavigate();
  const [showReport, setShowReport] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showShare, setShowShare] = useState(false)
  let userAllInfo: UserBaseInfo = JSON.parse(
    localStorage.getItem("user_info")!
  );

  useEffect(() => {
    let params = {
      dataId: Number(id),
      dataTable: "article",
      pageNumber: 1,
      pageSize: 10,
    };
    getUserLikeList(params).then((res) => {
      setFavourList(res.pageData);
    });
  }, [detail?.isLike]);

  const onClickFavour = (flag: number) => {
    if (!userAllInfo?.id) {
      navigate(loginPathWithRedirect());
      return;
    }
    setLoading(true);
    let params = {
      dataId: +id!,
      dataTable: "article",
      flag: flag,
    };

    postLikeWork(params)
      .then((res) => {
        let obj = JSON.parse(JSON.stringify(detail));
        flag === 1 ? obj.likeCount++ : obj.likeCount--;
        obj!.isLike = flag;
        onDetailChange(obj);
        message.success("操作成功!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickCollect = () => {
    if (!userAllInfo?.id) {
      navigate(loginPathWithRedirect());
      return;
    }
    setShowFavorite(true);
  };
  const onClickCloseCollect = () => {
    setShowFavorite(false);
    //getDetail();
    shouldGetDetail()
  };

  const toDetail = (id: number) => {
    window.open(`/user/${id}/works`)
  }



  const onClickReport = () => {
    setShowReport(true);
  };

  const onCloseReport = () => {
    setShowReport(false);
  };
  return (
    <ArticleInteractionStyle>
      <Spin spinning={loading}>
        <div className="interaction-wrapper">
          <div className="operation-wrapper">
            <Badge count={detail?.likeCount} offset={[-18, 6]} >
              {!detail?.isLike && (
                <div className="actionIcon" onClick={() => onClickFavour(1)}>
                  <RiHeart3Line className="icon" />
                </div>
              )}
              {!!detail?.isLike && (
                <div
                  className="actionIcon active"
                  onClick={() => onClickFavour(0)}
                >
                  <RiHeart3Fill className="icon" />
                </div>
              )}
            </Badge>
            <Badge count={detail?.favoriteCount} offset={[-18, 6]}>
              {!detail?.isFavorite && (
                <div className="actionIcon" onClick={() => onClickCollect()}>
                  <RiStarLine className="icon" />
                </div>
              )}
              {!!detail?.isFavorite && (
                <div
                  className="actionIcon active"
                  onClick={() => onClickCollect()}
                >
                  <RiStarFill className="icon" />
                </div>
              )}
            </Badge>

            {showFavorite && (
              <AddFavorite
                type="article"
                FavoriteTypeDisabled={true}
                onClose={onClickCloseCollect}
              ></AddFavorite>
            )}
            {/* <div className="actionIcon" onClick={toShare}>
              <RiShareForward2Fill className="icon"></RiShareForward2Fill>
            </div> */}

            <div className="alarm-item" onClick={onClickReport}>
              <RiAlarmWarningLine size={18}></RiAlarmWarningLine>
              <div>举报</div>
            </div>
          </div>
          {favourList && favourList.length > 0 && (
            <div className="favour-list-wrapper">
              <div className="title">- {favourList?.length}位G友点赞 -</div>
              <div className="avatar-list">
                {favourList?.map((item) => {
                  return (
                    <div key={item.id} style={{ cursor: "pointer" }}>
                      <Avatar src={item.avatarUrl} onClick={() => toDetail(item.id)}></Avatar>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <GlobalShare info={{ title: detail?.title, cover_image: detail?.coverUrl, desc: detail?.description || detail?.title }} shareType="article" isShow={showShare} onClose={() => { setShowShare(false) }}></GlobalShare>
        {showReport && (
          <ReportModal
            show={showReport}
            close={onCloseReport}
            params={{ dataId: Number(detail?.id), dataTable: "article" }}
          ></ReportModal>
        )}
      </Spin>
    </ArticleInteractionStyle>
  )
}

export default ArticleIntercation